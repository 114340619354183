import { useMutation } from '@tanstack/react-query';

import { validateKeyTagBulkRegistrationFile } from '@/api/keyTag';

export const useValidateKeyTagBulkFile = () => {
  return useMutation({
    mutationFn: (file: File) => {
      return validateKeyTagBulkRegistrationFile(file);
    },
  });
};
