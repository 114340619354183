import React from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Button,
  IconButton,
  InputAdornment,
  LinearProgress,
  Typography,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import qs from 'qs';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { z } from 'zod';

import { resetPassword } from '@/api/forgotPassword';
import { config } from '@/helpers/config';
import { Textfield } from '@/modules/components';

import { Box } from './Box';

const validationSchema = z
  .object({
    new_password: z.string().min(6, 'Lösenordet måste bestå av minst 6 tecken'),
    confirm_password: z.string(),
  })
  .refine((form) => form.confirm_password === form.new_password, {
    message: 'Lösenorden stämmer inte överrens',
    path: ['confirm_password'],
  });

type Values = z.infer<typeof validationSchema>;

type Payload = Values & { token: string };

export const ResetPasswordForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Values>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      new_password: '',
      confirm_password: '',
    },
  });
  const { search } = useLocation();
  const [showPassword, setShowPassword] = React.useState(false);
  const { token } = qs.parse(search, { ignoreQueryPrefix: true });

  const resetPasswordMutation = useMutation({
    mutationFn: async (payload: Payload) => resetPassword(payload),
    onSuccess: () => {
      window.location.assign(config.siteUri!);
    },
  });

  const onSubmit = (values: any) => {
    const payload = {
      ...values,
      token,
    };
    resetPasswordMutation.mutate(payload);
  };

  const { isPending, isError, isIdle, isSuccess } = resetPasswordMutation;

  React.useEffect(() => {
    if (!token) {
      window.location.assign('http://localhost:5173/sign-in');
    }
  }, [token]);

  const inputProps = {
    type: showPassword ? 'text' : 'password',
    required: true,
    endAdornment: (
      <InputAdornment position="end">
        <IconButton onClick={() => setShowPassword(!showPassword)} size="large">
          {showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>
    ),
  };

  return (
    <div>
      <Box>
        {isError && (
          <>
            <Typography variant="h1" color="error">
              Något gick fel
            </Typography>
            <Typography variant="body1">
              Försök igen, kontakta IT om felet kvarstår
            </Typography>
          </>
        )}
        {isSuccess && (
          <>
            <Typography variant="h1">Lösenordet ändrat!</Typography>
            <Typography variant="body1">
              Du kommer strax bli omdirigerad till inloggningsidan
            </Typography>
          </>
        )}
      </Box>
      {isPending && <LinearProgress />}
      {isIdle && (
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <Typography variant="h3" component="h1">
            Ange ett nytt lösenord
          </Typography>
          <Textfield
            label="Lösenord"
            error={errors.new_password}
            fullWidth
            inputProps={{
              ...inputProps,
              ...register('new_password'),
            }}
          />
          <Textfield
            label="Verifiera lösenord"
            error={errors.confirm_password}
            fullWidth
            inputProps={{
              ...inputProps,
              ...register('confirm_password'),
            }}
          />
          <Button
            type="submit"
            fullWidth
            color="primary"
            variant="contained"
            sx={{ marginBottom: 1 }}
            disabled={isPending}
          >
            Spara
          </Button>
          <Link to="/sign-in">Avbryt</Link>
        </Box>
      )}
    </div>
  );
};
