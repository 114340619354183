import { getObjectKeys } from '@/helpers/tsHelpers';
import {
  DeviceStatus,
  DeviceStatusCode,
  sinneDeviceStatusMap,
} from '@/types/services';

export const sinneDeviceStatusCodeMap: Record<DeviceStatusCode, DeviceStatus> =
  getObjectKeys(sinneDeviceStatusMap).reduce(
    (acc, key) => {
      const status = key as DeviceStatus;
      const code = sinneDeviceStatusMap[status];
      acc[code] = status;
      return acc;
    },
    {} as Record<DeviceStatusCode, DeviceStatus>,
  );
