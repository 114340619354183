import { Box, Card } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { getUserById } from '@/api';
import { PageWrapper } from '@/components';
import { Tabs } from '@/components/Tabs/Tabs';
import useUser from '@/hooks/useUser';
import {
  AccountManagerArrSummary,
  AccountManagerOnboardingStatistics,
  CustomerOverviewList,
} from '@/modules/accountManagerSystem/components/Overview';

interface Customer {
  '@id': string;
  '@type': string;
  id: number;
}

interface StatisticsOverview {
  customers: Customer[];
  coProtectionOnboardingPercentage: string;
  firetextOnboardingPercentage: string;
  managedCustomersARR: {
    firetext: string;
    protection: string;
    'co-protection': string;
    sticker: string;
    'key-tag': string;
    homegate: string;
    sinne: string;
    totalARR: string;
    meanARR: string;
  };
}

const tabMap = [
  {
    title: 'Översikt',
    view: () => (
      <Box marginTop="1rem">
        <Card style={{ margin: '1rem 0' }}>
          <AccountManagerArrSummary />
        </Card>
        <CustomerOverviewList />
      </Box>
    ),
  },
  {
    title: 'Onboarding',
    view: () => <AccountManagerOnboardingStatistics />,
  },
];

const fieldsToFetch = {
  name: true,
  coProtectionOnboardingPercentage: true,
  firetextOnboardingPercentage: true,
  managedCustomersARR: true,
  managedCustomers: {
    id: true,
  },
};

export const StatisticsOverview = () => {
  const { id: userId } = useUser();

  const { data: user } = useQuery({
    queryKey: ['getUser', userId],

    queryFn: async () => {
      const data = await getUserById(userId, fieldsToFetch, true);

      return data;
    },

    enabled: !!userId,
  });

  return (
    <PageWrapper
      pageTitle="Min Statistik"
      breadCrumbs={[
        { label: 'Account Manager System' },
        { label: 'Statistik' },
      ]}
    >
      <Box sx={{ maxWidth: 'lg' }}>
        <Tabs tabs={tabMap} viewProps={{ user }} />
      </Box>
    </PageWrapper>
  );
};

export default StatisticsOverview;
