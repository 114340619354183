import { useState } from 'react';

import { Card, CardContent } from '@mui/material';
import { Helmet } from 'react-helmet';

import { Breadcrumbs } from '@/components/Breadcrumbs';
import { PageHeader } from '@/components/PageHeader';
import { winbackCategoriesOptions } from '@/constants';
import { TaskCategorySelect, TasksList } from '@/modules/tasks';
import { TaskTeamTypes } from '@/modules/tasks/constants/tasksModalsConstants';

const crumbs = [{ label: 'Kundservice' }, { label: 'Winback' }];

export const WinbackTasks = () => {
  const team = TaskTeamTypes.CUSTOMER_SUPPORT;
  const [categories, setCategories] = useState<string[]>([]);

  const handleFilterChange = (values: string[]) => {
    setCategories(values);
  };

  return (
    <>
      <Helmet title="Ärenden | Kundservice" />
      <Breadcrumbs crumbs={crumbs} />

      <PageHeader title="Winback" margin />

      <Card>
        <CardContent>
          <TaskCategorySelect
            options={winbackCategoriesOptions}
            label="Kategori"
            onChange={handleFilterChange}
          />
        </CardContent>
      </Card>

      <TasksList
        filter={{
          type: categories,
        }}
        isWinback
        team={team}
      />
    </>
  );
};

export default WinbackTasks;
