import React from 'react';

import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { Spacing } from '@/components/Spacing';
import { usePrivateSettings } from '@/hooks';

import { MarketingCheckType } from '../hooks/useAddressWash';
import { FormAndInfo } from './FormAndInfo';

type Props = {
  children: React.ReactNode;
  onSubmit: (payload: { pins: string; type: MarketingCheckType }) => void;
};

export function AvoidWash({ children, onSubmit }: Props) {
  const { servicesNames } = usePrivateSettings();

  return (
    <Card>
      <CardHeader title="Avoid Tvätt" />
      <Divider />
      <CardContent>
        <Typography>
          Tvätta bort leads från befintligt kundregister genom person/org nr
        </Typography>
        <Spacing />
        <FormAndInfo
          onSubmit={onSubmit}
          type="all"
          info={
            <>
              <BoldText>Tvättar bort allmänt</BoldText>
              <Typography>Kundkort: Do not disturb (DND)</Typography>
              <Spacing />

              <BoldText>Tvättar bort {servicesNames.firetext}</BoldText>
              <Typography>
                Status: Aktiva, Skickade, Nyregistrerade, Konfigureras, Goodwill
              </Typography>
              <Spacing />

              <BoldText>Tvättar bort {servicesNames.protection}</BoldText>
              <Typography>Status: Aktiva, Goodwill</Typography>
              <Spacing />

              <BoldText>Tvättar bort {servicesNames.coProtection}</BoldText>
              <Typography>Status: Aktiva, Goodwill</Typography>
            </>
          }
        />
        <Spacing spacing={4} />
        {children}
      </CardContent>
    </Card>
  );
}

const BoldText = styled(Typography)({
  fontWeight: 'bold',
});
