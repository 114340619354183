import { useEffect, useState } from 'react';

import { Box, Card, CardHeader, Divider, Grid } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { createNote } from '@/api/notes';
import { LabelAndContentRow } from '@/components';
import { CustomerCategoryTypes } from '@/constants';
import { Customer } from '@/types/customers';
import { getCustomerName } from '@/utils/customer';

type Props = {
  customer: Pick<
    Customer,
    'id' | 'category' | 'fullName' | 'companyName' | 'pin' | 'mobile'
  >;
  callerPin: string;
};

export const CustomerInfo = ({ customer, callerPin }: Props) => {
  const [noteSent, setNoteSent] = useState(false);

  const queryClient = useQueryClient();
  const customerName = getCustomerName(customer);

  const noteMutation = useMutation({
    mutationFn: createNote,
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ['getNotes'],
      });
    },
  });

  useEffect(() => {
    if (!!callerPin && !noteSent && customer) {
      setNoteSent(true);
      noteMutation.mutate({
        type: 14,
        customerId: customer.id!,
        text: `Inloggning genom Spärrservice systemet för att hjälpa användare med personnummer ${callerPin}`,
      });
    }
  }, [callerPin, noteSent, customer]);

  return (
    <Grid item md={6} xs={12}>
      <Card>
        <CardHeader title="Ägare av tjänsten" />
        <Divider />
        <Box>
          <LabelAndContentRow label="Namn" content={customerName} />
          <Divider />
          <LabelAndContentRow
            label={
              customer?.category === CustomerCategoryTypes.COMPANY
                ? 'Organisationsnummer'
                : 'Personnummer'
            }
            content={customer?.pin}
          />
          <Divider />
          <LabelAndContentRow label="Mobil" content={customer?.mobile} />
        </Box>
      </Card>
    </Grid>
  );
};
