import React from 'react';

import { Clear } from '@mui/icons-material';
import { Grid, IconButton, InputAdornment } from '@mui/material';
import { Field, Form } from 'react-final-form';

import { TextInput } from '@/components/form';
import { AutoSave } from '@/components/form/AutoSave/AutoSave';
import { NotesFilter } from '@/modules/common/components';
import { FilterValues } from '@/pages/SupportNotes';

type Props = {
  onChange: (values: FilterValues) => Promise<void>;
  initialValues: FilterValues;
};

export const Filter = ({ onChange, initialValues }: Props) => {
  const save = async (values: FilterValues) => {
    onChange(values);
  };

  return (
    <>
      <Form onSubmit={save} initialValues={initialValues}>
        {({ form }) => (
          <>
            <AutoSave<FilterValues>
              debounce={500}
              save={save}
              debounced={['customer', 'dateAfter', 'dateBefore', 'types']}
            />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextInput
                  variant="outlined"
                  name="dateAfter"
                  label="Från"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextInput
                  variant="outlined"
                  type="date"
                  label="Till"
                  name="dateBefore"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextInput
                  fullWidth
                  label="Kundnummer"
                  name="customer"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          size="small"
                          onClick={() => form.change('customer', '')}
                        >
                          <Clear />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <Field name="types">
                  {(props) => (
                    <NotesFilter
                      onChange={props.input.onChange}
                      value={props.input.value}
                    />
                  )}
                </Field>
              </Grid>
            </Grid>
          </>
        )}
      </Form>
    </>
  );
};
