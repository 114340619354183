import { getElementFromArray } from '@/helpers/getElementFromArray';

import { PersonnummerError } from './errors';
import { diffInYears, luhn, testDate } from './utils';

export class Personnummer {
  #century = '';
  #fullYear = '';
  #year = '';
  #month = '';
  #day = '';
  #sep = ''; // Personnummer separator
  #num = ''; // Personnummer first three of the last four numbers
  #check = ''; // The last number of the personnummer

  get century(): string {
    return this.#century;
  }

  get fullYear(): string {
    return this.#fullYear;
  }

  get year(): string {
    return this.#year;
  }

  get month(): string {
    return this.#month;
  }

  get day(): string {
    return this.#day;
  }

  get sep(): string {
    return this.#sep;
  }

  get num(): string {
    return this.#num;
  }

  get check(): string {
    return this.#check;
  }

  constructor(ssn: string) {
    this.parse(ssn);
  }

  static parse(ssn: string): Personnummer {
    return new Personnummer(ssn);
  }

  static valid(ssn: string): boolean {
    try {
      Personnummer.parse(ssn);
      return true;
    } catch (e) {
      return false;
    }
  }

  private parse(ssn: string) {
    const reg = /^(\d{2}){0,1}(\d{2})(\d{2})(\d{2})([+-]?)((?!000)\d{3})(\d)$/;
    const match = reg.exec(ssn);

    if (!match) {
      throw new PersonnummerError();
    }

    const century = match[1];
    const year = getElementFromArray(match[2]);
    const month = getElementFromArray(match[3]);
    const day = getElementFromArray(match[4]);
    const sep = match[5];
    const num = getElementFromArray(match[6]);
    const check = getElementFromArray(match[7]);

    if (typeof century === 'undefined' || !century.length) {
      const d = new Date();
      let baseYear = 0;

      if (sep === '+') {
        this.#sep = '+';
        baseYear = d.getFullYear() - 100;
      } else {
        this.#sep = '-';
        baseYear = d.getFullYear();
      }

      this.#century = (
        '' +
        (baseYear - ((baseYear - parseInt(getElementFromArray(year))) % 100))
      ).substr(0, 2);
    } else {
      this.#century = century;

      if (new Date().getFullYear() - parseInt(century + year, 10) < 100) {
        this.#sep = '-';
      } else {
        this.#sep = '+';
      }
    }

    this.#year = year;
    this.#fullYear = this.#century + year;
    this.#month = month;
    this.#day = day;
    this.#num = num;
    this.#check = check;

    if (!this.valid()) {
      throw new PersonnummerError();
    }
  }

  private valid(): boolean {
    const valid =
      luhn(this.year + this.month + this.day + this.num) === +this.check &&
      !!this.check;

    if (
      valid &&
      testDate(parseInt(this.century + this.year), +this.month, +this.day)
    ) {
      return valid;
    }

    return (
      valid &&
      testDate(parseInt(this.century + this.year), +this.month, +this.day - 60)
    );
  }

  /**
   * Format a Swedish personal identity number as one of the official formats,
   * A long format or a short format.
   *
   * If the input number could not be parsed a empty string will be returned.
   */
  format(longFormat = false): string {
    if (longFormat) {
      return `${this.century}${this.year}${this.month}${this.day}${this.num}${this.check}`;
    }

    return `${this.year}${this.month}${this.day}${this.sep}${this.num}${this.check}`;
  }

  /**
   * Get age from a Swedish personal identity number.
   */
  getAge(): number {
    let ageDay = +this.day;
    if (this.isCoordinationNumber()) {
      ageDay -= 60;
    }

    const ageDate =
      this.century +
      this.year +
      '-' +
      this.month +
      '-' +
      (ageDay < 10 ? '0' + ageDay : ageDay);

    return diffInYears(new Date(Date.now()), new Date(ageDate));
  }

  /**
   * Check if a Swedish personal identity number is a coordination number or not.
   */
  isCoordinationNumber(): boolean {
    return testDate(
      parseInt(this.century + this.year),
      +this.month,
      +this.day - 60,
    );
  }

  /**
   * Check if a Swedish personal identity number is for a female.
   */
  isFemale(): boolean {
    return !this.isMale();
  }

  /**
   * Check if a Swedish personal identity number is for a male.
   */
  isMale(): boolean {
    const sexDigit = parseInt(this.num.substr(-1));

    return sexDigit % 2 === 1;
  }
}
