import { PriceItem, PriceItemWithType, PricesResponse } from '@/types/prices';
import httpClient from '@/utils/httpClient';

export async function getPrices(params: any) {
  const { data } = await httpClient.get<PricesResponse<PriceItemWithType>>(
    '/prices',
    {
      params: {
        ...params,
      },
    },
  );
  return data['hydra:member'];
}

export async function getPriceById(priceId: string) {
  const { data } = await httpClient.get<PriceItem>(priceId);

  return data;
}

export async function updatePrice({
  id,
  active,
}: {
  id: string;
  active: boolean;
}) {
  const { data } = await httpClient.put<PriceItem>(id, { active });

  return data;
}

export type CreatePricePayload = {
  price: number;
  vat: number;
  currency: string;
  type: string;
  service: string;
  active: true;
};
export async function createPrice(payload: CreatePricePayload) {
  const { data } = await httpClient.post<PriceItem>('/prices', payload);

  return data;
}

export async function deletePrice(iri: string) {
  const { data } = await httpClient.delete(iri);

  return data;
}
