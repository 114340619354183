import { Card, CardContent, Divider, List } from '@mui/material';

import { CustomerProps } from '@/modules/barringService/types';

import { ChecklistTitle } from '../../../commonStyles';
import { MessageCustomer } from './MessageCustomer';
import { Todo } from './Todo';

// Sixth tab for Protection Checklist
export const SixthTab = (props: CustomerProps) => {
  return (
    <Card>
      <ChecklistTitle variant="h6">
        Kund har förlorat Id-handling, men ej blivit Id-kapad
      </ChecklistTitle>
      <Divider />
      <CardContent>
        <List>
          <MessageCustomer />
          <Todo {...props} />
        </List>
      </CardContent>
    </Card>
  );
};
