import isObject from 'lodash/isObject';

type PreparePricePlanOptionsArgs = {
  pricePlans: Record<string, number>;
  isCompany?: boolean;
};

export function preparePricePlanOptions({
  pricePlans,
  isCompany = false,
}: PreparePricePlanOptionsArgs) {
  if (!pricePlans) {
    return [];
  }

  if (isObject(pricePlans)) {
    return Object.keys(pricePlans)
      .map((key) => {
        const multiplier = isCompany ? 1 : 1.25;
        const displayValue = pricePlans[key]! * multiplier;
        return {
          value: key,
          text: `${displayValue} ${isCompany ? 'exkl.' : 'inkl.'} moms`,
          displayValue,
        };
      })
      .sort((a, b) => a.displayValue - b.displayValue);
  } else {
    return [];
  }
}
