import { DialogActions as MuiDialogActions } from '@mui/material';
import { styled } from '@mui/material/styles';

import { getNumberOfChildren } from '@/utils/misc';

type Props = {
  children: React.ReactNode | [];
};

type ActionContainer = { numberofchildren: number | undefined };

const Actions = styled(MuiDialogActions)<ActionContainer>`
  padding: ${({ theme }) => theme.spacing(2)};
  justify-content: ${({ numberofchildren }) =>
    numberofchildren! > 1 ? 'space-between' : 'flex-end'};
`;

export function DialogActions({ children }: Props) {
  return (
    <Actions numberofchildren={getNumberOfChildren(children)}>
      {children}
    </Actions>
  );
}
