import React from 'react';

import { Helmet } from 'react-helmet';

import { PageHeader } from '@/components/PageHeader';
import {
  AddressWashBreadcrumbs,
  AddressWashContainer,
} from '@/modules/addressWash';

export const AddressWash = () => {
  return (
    <>
      <Helmet title="Adresstvätt" />
      <AddressWashBreadcrumbs />
      <PageHeader title="Adresstvätt" margin />
      <AddressWashContainer />
    </>
  );
};

export default AddressWash;
