import React from 'react';

import { Button, Grid, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { deleteUser } from '@/api/users';
import { Can } from '@/components/Can';
import { ConfirmationDialog } from '@/components/ConfirmationDialog';
import { useSnackbar } from '@/hooks';
import { useAuth } from '@/hooks/useAuth';
import { UserItem } from '@/types/users';

type Props = {
  user: UserItem;
  isCurrentUser: boolean;
};

export const UserToolbar = ({ user, isCurrentUser }: Props) => {
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const [deleteModalOpened, setDeleteModalOpened] = React.useState(false);

  const { getAuthenticatedUserRoles } = useAuth();
  const userRoles = getAuthenticatedUserRoles();

  const deleteUserMutation = useMutation({
    mutationFn: deleteUser,
    onSuccess: () => {
      snackbar({
        type: 'success',
        message: 'Användaren har tagits bort.',
      }),
        navigate('/users');
    },
    onError: () => {
      snackbar({
        type: 'error',
        message: 'Borttagning av användare misslyckades.',
      });
    },
  });
  function handleDeleteConfirm() {
    deleteUserMutation.mutate(user.id);
    setDeleteModalOpened(false);
  }

  function handleDeleteCancel() {
    setDeleteModalOpened(false);
  }

  return (
    <>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Typography variant="h3">{user?.name}</Typography>
        </Grid>

        {!isCurrentUser && userRoles && (
          <Grid item>
            <Can
              userRoles={userRoles}
              action="users.delete"
              yes={() => (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={deleteUserMutation.isPending}
                  onClick={() => setDeleteModalOpened(true)}
                >
                  Arkivera
                </Button>
              )}
            />
          </Grid>
        )}
      </Grid>

      <ConfirmationDialog
        dialogText={
          <>
            Är du säker på att du vill ta bort <strong>{user?.name}</strong>?
          </>
        }
        isOpened={deleteModalOpened}
        onConfirm={handleDeleteConfirm}
        onClose={handleDeleteCancel}
        confirmationButtonText="Ja"
        cancellationButtonText="Avbryt"
      />
    </>
  );
};
