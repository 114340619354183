import httpClient from '@/utils/httpClient';

export async function getCustomSMSMessages() {
  const {
    data: { value },
  } = await httpClient.get('/constants/customSmsMessages');

  return value;
}

export type SendBulkSmsParams = {
  recipients: string[];
  message: string;
  isTwoWay: boolean;
  listType: string;
};
export async function sendBulkSms({
  recipients,
  message,
  isTwoWay,
  listType,
}: SendBulkSmsParams) {
  const { data } = await httpClient.post('/rpc/send.bulk.sms', {
    recipients,
    message,
    isTwoWay,
    listType,
  });

  return data;
}

export async function sendCoProtectionActivationSms({
  coProtectionId,
  message,
}: {
  coProtectionId: string;
  message: string;
}) {
  const { data } = await httpClient.post('/rpc/send.sms/co-protection', {
    coProtectionId,
    message,
  });

  return data;
}
