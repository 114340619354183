import { PageHeader } from '@/components/PageHeader';
import { Tabs } from '@/components/Tabs/Tabs';
import { usePrivateSettings } from '@/hooks/usePrivateSettings';
import { Configuration } from '@/modules/admin/components/Configuration';
import { DiscardFlow } from '@/modules/sinne/components/sinneDiscardFlow';
import { ReturnFlow } from '@/modules/sinne/components/sinneReturnFlow';
import { Tabmap } from '@/types/tabs';

import { SinneAdminToolsBreadcrumbs } from './SinneAdminToolsBreadcrumbs';

export const SinneAdmin = () => {
  const { servicesNames } = usePrivateSettings();
  const tabMap: Tabmap[] = [
    {
      title: 'Återanvänd enhet',
      view: () => <ReturnFlow />,
      authRule: 'admin.tools.sinneTools',
    },
    {
      title: 'Ersätt enhet',
      view: () => <Configuration />,
      authRule: 'admin.tools.sinneTools',
    },
    {
      title: 'Kassera enhet',
      view: () => <DiscardFlow />,
      authRule: 'admin.tools.sinneTools',
    },
    {
      title: 'Felsök enhet',
      view: () => <div>Under utveckling</div>,
      authRule: 'admin.tools.sinneTools',
    },
  ];
  return (
    <div>
      <SinneAdminToolsBreadcrumbs />
      <PageHeader title={`Verktyg för ${servicesNames.sinne}`} margin />
      <Tabs tabs={tabMap} />
    </div>
  );
};
