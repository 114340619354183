import { useQuery } from '@tanstack/react-query';

import { getCoProtections } from '@/api/coProtection';
import { queryKeys } from '@/constants/queryKeys';

export const useGetCoProtections = (pin: string) => {
  return useQuery({
    queryKey: ['findProduct', queryKeys.getCoProtections, pin],

    queryFn: async () => {
      const data = await getCoProtections({ pin });
      return data['hydra:member'].map((item) => {
        return {
          ['@type']: item['@type'],
          customer: item.customer,
          id: item.id,
        };
      });
    },

    enabled: !!pin,
  });
};
