import React, { useState } from 'react';

import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  LinearProgress,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import { Helmet } from 'react-helmet';

import { getSupportRetetionStatistics } from '@/api/stats';
import { Alert } from '@/components/Alert';
import { PageHeader } from '@/components/PageHeader';
import { queryKeys } from '@/constants/queryKeys';
import { getObjectKeys } from '@/helpers/tsHelpers';
import { useSnackbar } from '@/hooks';

import { Breadcrumbs } from './Breadcrumbs';
import { DegreeOfRescue } from './DegreeOfRescue';
import { Filter } from './Filter';
import { ManagerTable } from './ManagerTable';
import { ServiceTable } from './ServiceTable';

export const RetentionStatistics = () => {
  const snackbar = useSnackbar();

  const today = new Date();

  const [filter, setFilter] = useState({
    start: format(startOfMonth(today), 'yyyy-MM-dd'),
    end: format(endOfMonth(today), 'yyyy-MM-dd'),
  });

  const { data, isLoading, isError } = useQuery({
    queryKey: [queryKeys.getRetentionStatistics, filter],
    queryFn: () => {
      return getSupportRetetionStatistics(filter);
    },
    enabled: !!filter,
  });

  React.useEffect(() => {
    if (isError) {
      snackbar({
        type: 'error',
        message: 'Kunde inte hämta statistik',
      });
    }
  }, [snackbar, isError]);

  // TODO: use it for some nice empty state inside tables
  const noValues =
    data !== undefined &&
    getObjectKeys(data)
      .map((serviceName) => {
        return data[serviceName].handlers.length;
      })
      .reduce((previousValue, currentValue) => {
        return previousValue + currentValue;
      }, 0) === 0;

  return (
    <>
      <Helmet title="Statistik | Kundservice" />
      <Breadcrumbs />
      <PageHeader title="Statistik" margin />
      <Grid container spacing={4}>
        <Grid item lg={8} md={7} sm={6} xl={9} xs={12}>
          <Card>
            <CardHeader title="Period" />
            <Divider />
            <CardContent>
              <Filter
                onChange={(values: typeof filter) =>
                  setFilter((prevFilter) => ({ ...prevFilter, ...values }))
                }
                initialValues={filter}
              />
            </CardContent>
          </Card>
        </Grid>

        <Grid item lg={4} md={5} sm={6} xl={3} xs={12}>
          <DegreeOfRescue data={data} loading={isLoading} />
        </Grid>
        <Grid item xs={12}>
          {isLoading ? (
            <LinearProgress />
          ) : isError ? (
            <Alert severity="error">Kunde inte hämta statistik</Alert>
          ) : (
            data && <ServiceTable data={data} />
          )}
        </Grid>
        <Grid item xs={12}>
          {isLoading ? (
            <LinearProgress />
          ) : isError ? (
            <Alert severity="error">Kunde inte hämta statistik</Alert>
          ) : (
            data && <ManagerTable data={data} />
          )}
        </Grid>
      </Grid>
    </>
  );
};
