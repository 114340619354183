export const monthOptions = [
  { value: 0, text: 'Januari' },
  { value: 1, text: 'Februari' },
  { value: 2, text: 'Mars' },
  { value: 3, text: 'April' },
  { value: 4, text: 'Maj' },
  { value: 5, text: 'Juni' },
  { value: 6, text: 'Juli' },
  { value: 7, text: 'Augusti' },
  { value: 8, text: 'September' },
  { value: 9, text: 'Oktober' },
  { value: 10, text: 'November' },
  { value: 11, text: 'December' },
];
