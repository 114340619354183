import React from 'react';

import {
  Alert,
  AlertTitle,
  Card,
  CardContent,
  CardHeader,
  Divider,
  LinearProgress,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { getInvoiceRoyalties } from '@/api/costs';
import { LabelAndContentRow } from '@/components';
import { SimpleTable } from '@/components/SimpleTable';
import { usePrivateSettings } from '@/hooks';
import { formatValueTotal } from '@/modules/common/utils/currency';

import { columns } from './FeesTableColumnRows';

export const MonthlyFees = () => {
  const { privateSettings } = usePrivateSettings();
  const companyCurrency = privateSettings?.companyCurrency.symbol;

  const { data, isError, isLoading } = useQuery({
    queryKey: ['invoiceRoyalties'],

    queryFn: async () => {
      const data = await getInvoiceRoyalties();
      return data;
    },
  });

  const combinedData = React.useMemo(() => {
    if (data && data.royalties && data.discounts) {
      const combined = [...data.royalties];
      const index = combined.findIndex((item) => item.name === 'Monitum CRM');
      if (index !== -1) {
        combined.splice(index + 1, 0, {});
      }
      combined.push({}, ...data.discounts);

      return combined.sort((a, b) => {
        if (a.name === 'Monitum CRM') {
          return -1;
        } else if (b.name === 'Monitum CRM') {
          return 1;
        } else if (a.name && b.name) {
          return a.name.localeCompare(b.name);
        }
        return 0;
      });
    }
    return [];
  }, [data]);

  return (
    <Card style={{ marginBottom: '1rem' }}>
      <CardHeader title="Månadsavgifter" />
      <Divider />
      <CardContent>
        {isLoading ? (
          <LinearProgress />
        ) : isError ? (
          <Alert severity="info">
            <AlertTitle>Ingen information hittad</AlertTitle>
          </Alert>
        ) : (
          <>
            <SimpleTable rows={combinedData} columns={columns} />

            <div>
              <LabelAndContentRow
                content={
                  <Typography sx={{ fontWeight: 500 }}>
                    {' '}
                    {formatValueTotal(data?.totalSum)} {companyCurrency}
                  </Typography>
                }
                label="Total"
              />
            </div>
          </>
        )}
      </CardContent>
    </Card>
  );
};
