import invariant from 'tiny-invariant';
import { DoneInvokeEvent, assign, createMachine } from 'xstate';
import { z } from 'zod';

import {
  SveaFeePayload,
  createFeeInSvea,
  sveaFeePayloadSchema,
} from '@/api/customers';
import { sendEmailToCustomerService } from '@/api/email';
import { AddFeePayload, addFee } from '@/api/fees';
import {
  getSinneDeviceFromNoClip,
  updateSinneDeviceInNoClip,
} from '@/api/noClip';
import { CreateNotePayload, createNote } from '@/api/notes';
import { updateService } from '@/api/services';
import { getPrivateSettings } from '@/api/settings';
import {
  deactivateDeviceInEldes,
  getMostRecentSinneCustomer,
  getSinneDeviceSimcardInfoByImei,
  newGetSinneDeviceInfo,
  terminateSimCard,
} from '@/api/sinne';
import { getFormattedDate } from '@/helpers/dates';
import { queryClient } from '@/index';
import { basicCustomerDataSchema } from '@/types/customers';
import { NoClipSinneDeviceData } from '@/types/noclip';
import {
  FlatSinneDeviceInfo,
  SinneDeviceSimInfoV2,
  sinneDeviceStatusMap,
} from '@/types/services';

export type SinneReturnFlowMachineContext = {
  cardTitle: string;
  customer: {
    id: number | undefined;
    name: string | undefined;
  };
  device: Partial<FlatSinneDeviceInfo>;
  discardDeviceFee: number;
  imei: number;
  invoiceProvider: string; // TODO: this can be typed keyof typeof INVOICE_PROVIDERS | 'none' | undefined
  isCustomerFeeCreated: boolean;
  isCustomerServiceEmailSent: boolean;
  isDeviceDataFetchComplete: boolean;
  isEldesEmailSent: boolean;
  isNoClipUpdated: boolean;
  isNoteCreated: boolean;
  isSimCardDeactivated: boolean;
  isSinneServiceUpdated: boolean;
  isSveaFeeCreated: boolean;
  macAddress: string;
  noClipData: NoClipSinneDeviceData | undefined;
  noteText: string;
  simcard: Partial<SinneDeviceSimInfoV2>;
};

//#region actions

type CREATE_NOTE = {
  type: 'CREATE_NOTE';
  payload: CreateNotePayload;
};

type RETRY_SIM_CARD_CHECK = {
  type: 'RETRY_SIM_CARD_CHECK';
  imei: number;
};

type SET_DEVICE_INFORMATION = {
  type: 'SET_DEVICE_INFORMATION';
  connected: boolean;
  sinneEntityId: number | null;
};

type RETRY_DEVICE_CHECK = {
  type: 'RETRY_DEVICE_CHECK';
  macAddress: string;
};

type RETRY_CUSTOMER_CHECK = {
  type: 'RETRY_CUSTOMER_CHECK';
};

type RETRY_ADD_FEE = {
  type: 'RETRY_ADD_FEE';
  value: number;
};

type RETRY_ADD_SVEA_FEE = {
  type: 'RETRY_ADD_SVEA_FEE';
  value: number;
};
type RETRY_SEND_CUSTOMER_SERVICE_EMAIL = {
  type: 'RETRY_SEND_CUSTOMER_SERVICE_EMAIL';
};
type RETRY_SEND_ELDES_EMAIL = {
  type: 'RETRY_SEND_ELDES_EMAIL';
};
type RETRY_DEACTIVATE_SIM_CARD = {
  type: 'RETRY_DEACTIVATE_SIM_CARD';
};

type SUBMIT_DEVICE_DATA_FORM = {
  type: 'SUBMIT_DEVICE_DATA_FORM';
  macAddress: string;
  imei: number;
};

type REFETCH_DATA = {
  type: 'REFETCH_DATA';
};

// #endregion

export type SinneReturnFlowMachineEvent =
  | CREATE_NOTE
  | RETRY_ADD_FEE
  | RETRY_ADD_SVEA_FEE
  | RETRY_CUSTOMER_CHECK
  | RETRY_DEACTIVATE_SIM_CARD
  | RETRY_DEVICE_CHECK
  | RETRY_SEND_CUSTOMER_SERVICE_EMAIL
  | RETRY_SEND_ELDES_EMAIL
  | RETRY_SIM_CARD_CHECK
  | SET_DEVICE_INFORMATION
  | SUBMIT_DEVICE_DATA_FORM
  | REFETCH_DATA
  | { type: 'SET_FEE_VALUE'; value: number }
  | { type: 'COMPLETE_FLOW' }
  | { type: 'SET_CARD_TITLE'; cardTitle: string };

const initialContext: SinneReturnFlowMachineContext = {
  cardTitle: 'Flöde för kassering av enhet',
  customer: {
    id: undefined,
    name: undefined,
  },
  device: {
    connected: false,
    sinneEntityId: null,
  },
  discardDeviceFee: 0,
  imei: 0,
  invoiceProvider: '',
  isCustomerFeeCreated: false,
  isCustomerServiceEmailSent: false,
  isDeviceDataFetchComplete: false,
  isEldesEmailSent: false,
  isNoClipUpdated: false,
  isNoteCreated: false, // TODO: redundant
  isSimCardDeactivated: false, // TODO: change naming to isSimCardTerminated
  isSinneServiceUpdated: false,
  isSveaFeeCreated: false,
  macAddress: '',
  noClipData: undefined,
  noteText: '',
  simcard: {},
};

const isServerError = (
  _: SinneReturnFlowMachineContext,
  event: DoneInvokeEvent<any>,
) =>
  event.data.status && event.data.status !== 200 && event.data.status !== 404;

const isNotFoundError = (
  _: SinneReturnFlowMachineContext,
  event: DoneInvokeEvent<any>,
) => event.data.status === 404;

export const discardFlowMachine = createMachine(
  {
    id: 'sinneDiscardFlow',
    tsTypes: {} as import('./discardFlowMachine.typegen').Typegen0,
    schema: {
      context: {} as SinneReturnFlowMachineContext,
      events: {} as SinneReturnFlowMachineEvent,
      services: {} as {
        createFee: { data: any };
        createNote: { data: any };
        createSveaFee: { data: any };
        fetchDeviceInfo: { data: any };
        fetchNoclipData: { data: any };
        fetchSimCardInfo: { data: any };
        getMostRecentCustomer: { data: any };
        sendEmailToCustomerService: { data: any };
        sendEmailToEldes: { data: any };
        setInvoiceProvider: { data: any };
        terminateSimCard: { data: any };
        updateNoclip: { data: any };
        updateSinneService: { data: NoClipSinneDeviceData };
      },
    },
    context: {
      ...initialContext,
    },
    on: {
      SET_CARD_TITLE: {
        actions: assign((_, event) => ({ cardTitle: event.cardTitle })),
      },
      SET_DEVICE_INFORMATION: {
        actions: assign((ctx, event) => ({
          device: {
            ...ctx.device,
            connected: event.connected,
            sinneEntityId: event.sinneEntityId,
          },
        })),
      },
      COMPLETE_FLOW: {
        target: 'DEVICE_DATA_FORM',
        actions: 'resetContext',
      },
    },
    initial: 'DEVICE_DATA_FORM',
    states: {
      DEVICE_DATA_FORM: {
        initial: 'editing',
        on: {
          SUBMIT_DEVICE_DATA_FORM: {
            actions: assign((_, event) => ({
              macAddress: event.macAddress,
              imei: event.imei,
            })),
            target: 'FETCH_DEVICE_SIM_AND_CUSTOMER_DATA',
          },
        },
        states: {
          editing: {},
        },
      },

      FETCH_DEVICE_SIM_AND_CUSTOMER_DATA: {
        type: 'parallel',
        onDone: [
          {
            target: 'FEE_HANDLER',
            cond: (context) =>
              !!context.noClipData && context.noClipData.customerPrice === null,
            actions: assign(() => ({
              isDeviceDataFetchComplete: true,
            })),
          },
          {
            target: 'UPDATE_NOCLIP',
            cond: (context) =>
              !!context.noClipData && context.noClipData.customerPrice !== null,
            actions: assign(() => ({
              isDeviceDataFetchComplete: true,
            })),
          },
        ],
        states: {
          DEVICE: {
            on: {
              RETRY_DEVICE_CHECK: {
                target: '.loading',
              },
            },
            initial: 'loading',
            states: {
              loading: {
                invoke: {
                  src: 'fetchDeviceInfo',
                  onDone: {
                    target:
                      '#sinneDiscardFlow.FETCH_DEVICE_SIM_AND_CUSTOMER_DATA.DEVICE.success',
                    actions: assign((context, event) => ({
                      device: {
                        ...context.device,
                        ...event.data,
                      },
                    })),
                  },
                  onError: [
                    {
                      target:
                        '#sinneDiscardFlow.FETCH_DEVICE_SIM_AND_CUSTOMER_DATA.DEVICE.error.serverError',
                      cond: { type: 'isServerError' },
                    },
                    {
                      target:
                        '#sinneDiscardFlow.FETCH_DEVICE_SIM_AND_CUSTOMER_DATA.DEVICE.error.notFound',
                      cond: { type: 'isNotFoundError' },
                    },
                  ],
                },
              },
              error: {
                states: {
                  serverError: {},
                  notFound: {},
                },
              },
              success: {
                type: 'final',
              },
            },
          },
          SIM_CARD: {
            initial: 'loading',
            on: {
              RETRY_SIM_CARD_CHECK: {
                target: '.loading',
              },
            },
            states: {
              loading: {
                invoke: {
                  src: 'fetchSimCardInfo',
                  onDone: {
                    target:
                      '#sinneDiscardFlow.FETCH_DEVICE_SIM_AND_CUSTOMER_DATA.SIM_CARD.success',
                    actions: assign((context, event) => ({
                      simcard: {
                        ...context.simcard,
                        ...event.data,
                      },
                    })),
                  },
                  onError: [
                    {
                      target:
                        '#sinneDiscardFlow.FETCH_DEVICE_SIM_AND_CUSTOMER_DATA.SIM_CARD.error.serverError',
                      cond: { type: 'isServerError' },
                    },
                    {
                      target:
                        '#sinneDiscardFlow.FETCH_DEVICE_SIM_AND_CUSTOMER_DATA.SIM_CARD.error.notFound',
                      cond: { type: 'isNotFoundError' },
                    },
                  ],
                },
              },
              error: {
                states: {
                  serverError: {},
                  notFound: {}, // TODO: implement
                },
              },
              success: {
                type: 'final',
              },
            },
          },
          CUSTOMER: {
            initial: 'loading',
            states: {
              loading: {
                invoke: {
                  src: 'getMostRecentCustomer',
                  onDone: {
                    target: 'success',
                    actions: assign((ctx, event) => ({
                      customer: {
                        ...ctx.customer,
                        id: event.data.id,
                        name: event.data.companyCategory
                          ? event.data.companyName
                          : event.data.fullName,
                      },
                    })),
                  },
                  onError: [
                    {
                      target:
                        '#sinneDiscardFlow.FETCH_DEVICE_SIM_AND_CUSTOMER_DATA.CUSTOMER.error.serverError',
                      cond: { type: 'isServerError' },
                    },
                    {
                      target:
                        '#sinneDiscardFlow.FETCH_DEVICE_SIM_AND_CUSTOMER_DATA.CUSTOMER.error.notFound',
                      cond: { type: 'isNotFoundError' },
                    },
                  ],
                },
              },
              error: {
                on: {
                  RETRY_CUSTOMER_CHECK: {
                    target:
                      '#sinneDiscardFlow.FETCH_DEVICE_SIM_AND_CUSTOMER_DATA.CUSTOMER.loading',
                  },
                },
                states: {
                  serverError: {},
                  notFound: {},
                },
              },
              success: {
                type: 'final',
              },
            },
          },
          NOCLIP: {
            initial: 'loading',
            states: {
              loading: {
                invoke: {
                  src: 'fetchNoclipData',
                  onDone: {
                    target: 'success',
                    actions: assign<
                      SinneReturnFlowMachineContext,
                      DoneInvokeEvent<NoClipSinneDeviceData>
                    >((ctx, event) => ({
                      noClipData: { ...ctx.noClipData, ...event.data },
                    })),
                  },
                  onError: [
                    {
                      target: 'error.serverError',
                      cond: { type: 'isServerError' },
                    },
                    {
                      target: 'error.notFound',
                      cond: { type: 'isNotFoundError' },
                    },
                  ],
                },
              },
              success: {
                type: 'final',
              },
              error: {
                states: {
                  serverError: {},
                  notFound: {},
                },
              },
            },
          },
        },
      },
      UPDATE_NOCLIP: {
        initial: 'loading',
        states: {
          loading: {
            invoke: {
              src: 'updateNoclip',
              onDone: {
                target: '#sinneDiscardFlow.DEACTIVATION_DISPATCH',
                actions: assign({ isNoClipUpdated: true }),
              },
              onError: '#sinneDiscardFlow.UPDATE_NOCLIP.error',
            },
          },
          error: {
            always: { actions: assign({ isNoClipUpdated: false }) },
          },
        },
      },
      FEE_HANDLER: {
        initial: 'FEE_FORM',
        states: {
          FEE_FORM: {
            initial: 'loading',
            on: {
              SET_FEE_VALUE: {
                target: 'ADD_CUSTOMER_FEE',
                actions: 'setFeeValue',
              },
            },
            states: {
              loading: {
                invoke: {
                  src: 'setInvoiceProvider',
                  onDone: {
                    target: '#sinneDiscardFlow.FEE_HANDLER.FEE_FORM.editing',
                    actions: assign({
                      invoiceProvider: (_, event) => event.data,
                    }),
                  },
                  onError: '#sinneDiscardFlow.FEE_HANDLER.FEE_FORM.error',
                },
              },
              editing: {},
              error: {},
            },
          },
          ADD_CUSTOMER_FEE: {
            initial: 'loading',
            invoke: {
              src: 'createFee',
              description:
                "A fee is always created in Monitum, if the customer's invoice provider is Svea, a fee is also created in Svea",
              onDone: [
                {
                  target: '#sinneDiscardFlow.UPDATE_NOCLIP',
                  actions: assign({ isCustomerFeeCreated: true }),
                  cond: (context) => context.invoiceProvider !== 'svea',
                },
                {
                  target: '#sinneDiscardFlow.FEE_HANDLER.ADD_SVEA_FEE',
                  cond: (context) => context.invoiceProvider === 'svea',
                },
              ],
              onError: '.error',
            },
            states: {
              loading: {},
              error: {
                on: {
                  RETRY_ADD_FEE: {
                    target: '#sinneDiscardFlow.FEE_HANDLER.ADD_CUSTOMER_FEE',
                  },
                },
              },
              success: {},
            },
          },
          ADD_SVEA_FEE: {
            initial: 'loading',
            invoke: {
              src: 'createSveaFee',
              onDone: {
                target: '#sinneDiscardFlow.UPDATE_NOCLIP',
                actions: assign({ isSveaFeeCreated: true }),
              },
              onError: '.error',
            },
            states: {
              loading: {},
              error: {
                on: {
                  RETRY_ADD_SVEA_FEE: {
                    target:
                      '#sinneDiscardFlow.FEE_HANDLER.ADD_SVEA_FEE.loading',
                  },
                },
              },
              success: {},
            },
          },
        },
      },
      DEACTIVATION_DISPATCH: {
        onDone: {
          target: 'UPDATE_SINNE_SERVICE',
        },
        type: 'parallel',
        states: {
          SEND_CUSTOMER_SERVICE_EMAIL: {
            initial: 'loading',
            states: {
              loading: {
                description:
                  'Here we send an email to customer service that this devices is being discarded.',
                invoke: {
                  src: 'sendEmailToCustomerService',
                  onDone: [
                    {
                      target: 'success',
                    },
                  ],
                  onError: [
                    {
                      target: 'error',
                    },
                  ],
                },
              },
              error: {
                on: {
                  RETRY_SEND_CUSTOMER_SERVICE_EMAIL:
                    '#sinneDiscardFlow.DEACTIVATION_DISPATCH.SEND_CUSTOMER_SERVICE_EMAIL.loading',
                },
              },
              success: {
                entry: assign({ isCustomerServiceEmailSent: true }),
                type: 'final',
              },
            },
          },
          SEND_ELDES_EMAIL: {
            initial: 'loading',
            states: {
              loading: {
                description:
                  'Here we send an email to eldes that device is being discarded.',
                invoke: {
                  src: 'sendEmailToEldes',
                  onDone: [
                    {
                      target: 'succes',
                    },
                  ],
                  onError: [
                    {
                      target: 'error',
                    },
                  ],
                },
              },
              error: {
                on: {
                  RETRY_SEND_ELDES_EMAIL:
                    '#sinneDiscardFlow.DEACTIVATION_DISPATCH.SEND_ELDES_EMAIL.loading',
                },
              },
              succes: {
                entry: assign({ isEldesEmailSent: true }),
                type: 'final',
              },
            },
          },
          DEACTIVATE_SIM_CARD: {
            initial: 'loading',
            states: {
              loading: {
                description:
                  'Here we send an email to eldes that device is being discarded. Include deviceId in email.',
                invoke: {
                  src: 'terminateSimCard',
                  onDone: [
                    {
                      target: 'success',
                    },
                  ],
                  onError: [
                    {
                      target: 'error',
                    },
                  ],
                },
              },
              success: {
                entry: assign({ isSimCardDeactivated: true }),
                type: 'final',
              },
              error: {
                on: {
                  RETRY_DEACTIVATE_SIM_CARD:
                    '#sinneDiscardFlow.DEACTIVATION_DISPATCH.DEACTIVATE_SIM_CARD.loading',
                },
              },
            },
          },
        },
      },
      UPDATE_SINNE_SERVICE: {
        initial: 'loading',
        states: {
          loading: {
            invoke: [
              {
                id: '1',
                src: 'updateSinneService',
                onDone: 'success',
                onError: 'error',
              },
              {
                id: '2',
                src: 'refetchQueries',
                onDone: 'success',
                onError: 'error',
              },
            ],
          },
          success: {
            entry: assign({ isSinneServiceUpdated: true }),
            always: '#sinneDiscardFlow.CREATE_NOTE',
          },
          error: {
            entry: assign({ isSinneServiceUpdated: false }),
          }, // Todo: could add retry here
        },
      },
      CREATE_NOTE: {
        initial: 'editing',
        states: {
          editing: {
            on: {
              CREATE_NOTE: {
                actions: assign({ noteText: (_, event) => event.payload.text }),
                target: '#sinneDiscardFlow.CREATE_NOTE.loading',
              },
            },
          },
          loading: {
            invoke: {
              src: 'createNote',
              onDone: '#sinneDiscardFlow.CREATE_NOTE.success',
              onError: '#sinneDiscardFlow.CREATE_NOTE.error',
            },
          },
          success: {
            entry: assign({ isNoteCreated: true }),
          },
          error: {},
        },
      },
    },
    predictableActionArguments: true,
    preserveActionOrder: true,
  },
  {
    actions: {
      setFeeValue: assign((_, event) => ({
        discardDeviceFee: event.value,
      })),
      resetContext: assign({ ...initialContext }),
    },
    guards: {
      isServerError,
      isNotFoundError,
    },
    services: {
      updateNoclip: async (context) => {
        const payload = {
          deviceId: context.macAddress,
          deviceStatus: sinneDeviceStatusMap.DISCARDED,
          sinneEntityId: null,
          customerPrice: context.discardDeviceFee,
        };

        return await updateSinneDeviceInNoClip(payload);
      },
      fetchNoclipData: async (context) => {
        return queryClient.fetchQuery({
          queryKey: ['fetchNoclipData', context.macAddress],
          queryFn: async () => {
            return await getSinneDeviceFromNoClip({
              macAddress: context.macAddress,
            });
          },
        });
      },
      getMostRecentCustomer: (context) => {
        return queryClient.fetchQuery({
          queryKey: ['getMostRecentCustomer', context.macAddress],
          queryFn: async () => {
            const data = await getMostRecentSinneCustomer(context.macAddress);
            const customer = basicCustomerDataSchema.passthrough().parse(data);

            return customer;
          },
        });
      },
      createFee: async (context, event) => {
        const payload: AddFeePayload = {
          count: 1,
          customer: `/customers/${context.customer.id}/`,
          title: 'Kassering av Notice-enhet',
          description: `Avgift för kassering av Notice-enhet med mac-address: ${context.macAddress}`,
          price: event.value.toString(),
          invoiceAt: new Date().toISOString(),
        };
        return queryClient.fetchQuery({
          queryKey: ['createFee', payload],
          queryFn: async () => {
            return addFee(payload);
          },
        });
      },
      createSveaFee: async (context) => {
        const payload: SveaFeePayload = sveaFeePayloadSchema.parse({
          amount_excl_vat: context.discardDeviceFee,
          customerId: context.customer.id,
          description: `Avgift för kassering av Notice-enhet med mac-address: ${context.macAddress}`,
          start_date: getFormattedDate(new Date()),
        });
        return queryClient.fetchQuery({
          queryKey: ['createSveaFee', payload],
          queryFn: async () => {
            const data = await createFeeInSvea(payload);
            return data;
          },
        });
      },
      createNote: async (_, event) => {
        const { payload } = event;
        return queryClient.fetchQuery({
          queryKey: ['createNote', payload],
          queryFn: async () => {
            return await createNote(payload);
          },
        });
      },
      terminateSimCard: async (context) => {
        return queryClient.fetchQuery({
          queryKey: ['terminateSimCard', context.imei],
          queryFn: async () => {
            return await terminateSimCard({ imei: context.imei });
          },
        });
      },
      fetchSimCardInfo: (_, event) => {
        const { imei } = event;

        return queryClient.fetchQuery({
          queryKey: ['fetchSimCardInfo', imei],
          queryFn: async () => await getSinneDeviceSimcardInfoByImei(imei),
        });
      },
      fetchDeviceInfo: (_, event) => {
        const { macAddress } = event;

        return queryClient.fetchQuery({
          queryKey: ['sinneDeviceInfo', macAddress],
          queryFn: async () => await newGetSinneDeviceInfo({ macAddress }),
        });
      },
      sendEmailToCustomerService: ({
        customer,
        macAddress,
        discardDeviceFee,
      }) => {
        const { id: customerId, name: customerName } = customer;
        return queryClient.fetchQuery({
          queryKey: [
            'sendEmailToCustomerService',
            customerId,
            customerName,
            discardDeviceFee,
            macAddress,
          ],
          queryFn: async () => {
            invariant(customerId, 'Customer ID is required');
            const message = `Notice enhet med mac-address: ${macAddress} som tillhörde kund med id: ${customerId} har kasserats`;
            const title = `${customerName} - ${customerId} har debiterats ${discardDeviceFee} för kasserad Notice-enhet.`;
            const payload = { message, title, customerId };
            return await sendEmailToCustomerService(payload);
          },
        });
      },
      sendEmailToEldes: async (ctx) => {
        const message = `Notice unit with mac-address: ${ctx.macAddress} has been discarded`;
        const payload = { macAddress: ctx.macAddress, message };

        return queryClient.fetchQuery({
          queryKey: ['sendEmailToEldes', payload],
          queryFn: async () => {
            return await deactivateDeviceInEldes(payload);
          },
        });
      },
      setInvoiceProvider: () => {
        return queryClient.fetchQuery({
          queryKey: ['setInvoiceProvider'],
          queryFn: async () => {
            const settings = await getPrivateSettings();
            return settings.invoiceProvider.name;
          },
        });
      },
      updateSinneService: (context) => {
        const serviceId = z.number().parse(context.device.sinneEntityId);

        return queryClient.fetchQuery({
          queryKey: ['updateSinneService', context.device.sinneEntityId],
          queryFn: async () => {
            return await updateService({
              serviceName: 'sinne',
              serviceId,
              values: { unitReturned: true },
            });
          },
        });
      },
      refetchQueries: async (context) => {
        await queryClient.refetchQueries({
          queryKey: ['fetchNoclipData', context.macAddress],
        });
        await queryClient.refetchQueries({
          queryKey: ['fetchSimCardInfo', context.imei],
        });
        return;
      },
    },
  },
);
