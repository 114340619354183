// TODO: These numbers are before the api exists, so numbers/values are wrong.
export const PdfTypes = {
  WELCOME_FIRETEXT: 0,
  WELCOME_ID_PROTECTION_PREMIUM: 1,
  WELCOME_ID_PROTECTION_PREMIUM_UPSALE: 2,
  WELCOME_SECURITY_SERVICE: 3,
  CUSTOM_BULK_PDF: 4,
};

export function pdfTypeToString(pdfType: number) {
  switch (pdfType) {
    case PdfTypes.WELCOME_FIRETEXT:
      return 'Välkomstbrev - Brandlarm';

    case PdfTypes.WELCOME_ID_PROTECTION_PREMIUM:
      return 'Välkomstbrev - ID-Skydd Premium';

    case PdfTypes.WELCOME_ID_PROTECTION_PREMIUM_UPSALE:
      return 'Välkomstbrev - ID-Skydd Premium Upsale';

    case PdfTypes.WELCOME_SECURITY_SERVICE:
      return 'Välkomstbrev - Spärrservice';

    case PdfTypes.CUSTOM_BULK_PDF:
      return 'Custom Bulk PDF';

    default:
      return pdfType;
  }
}

// Supposed to be used with Dropdown fields
export const pdfTypeOptions = Object.values(PdfTypes)
  .map((value) => ({
    value,
    text: pdfTypeToString(value),
  }))
  .sort((a, b) => {
    if (a.text < b.text) {
      return -1;
    }
    if (a.text > b.text) {
      return 1;
    }
    return 0;
  });
