import React from 'react';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import Collapse from '@mui/material/Collapse';
import kebabCase from 'lodash/kebabCase';

import { capitalizeFirstLetter } from '@/helpers/strings';
import { getObjectKeys } from '@/helpers/tsHelpers';
import { usePrivateSettings } from '@/hooks';
import { CollapsableMenuList } from '@/layouts/Main/components/Sidebar/components/SidebarNav/components/CollapsableMenuList';
import { MenuListItem } from '@/layouts/Main/components/Sidebar/components/SidebarNav/components/MenuListItem';
import { MenuListItemButton } from '@/layouts/Main/components/Sidebar/components/SidebarNav/components/MenuListItemButton';
import { PascalCaseServiceName } from '@/modules/common/utils/services';

import { SubMenuListItem } from './SubMenuListItem';

export const ServiceMenu = () => {
  const [open, setOpen] = React.useState(false);
  const { services, servicesNames } = usePrivateSettings();

  return (
    <>
      <MenuListItem>
        <MenuListItemButton
          onClick={() => setOpen(!open)}
          data-testid="services-menu-btn"
        >
          Tjänster
          {open ? <ExpandLess /> : <ExpandMore />}
        </MenuListItemButton>
      </MenuListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <CollapsableMenuList>
          {services &&
            getObjectKeys(services).map((service) => {
              if (services[service]) {
                return (
                  <SubMenuListItem
                    key={service}
                    title={servicesNames[service]}
                    href={`/products/${kebabCase(service)}`}
                    icon={`${
                      capitalizeFirstLetter(service) as PascalCaseServiceName
                    }Icon`}
                    data-testid={`${service}-menu-btn`}
                  />
                );
              }
              return null;
            })}
        </CollapsableMenuList>
      </Collapse>
    </>
  );
};
