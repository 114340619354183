import { useContext } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useSelector } from '@xstate/react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { Textfield } from '@/modules/components';
import { DiscardStateContext } from '@/modules/sinne/components/sinneDiscardFlow/context/DiscardStateProvider';
import { _64BitMacAddressRegex } from '@/utils/validation';

const formSchema = z.object({
  macAddress: z.coerce
    .string({})
    .min(1, 'Obligatoriskt fält')
    .refine((value) => value.match(_64BitMacAddressRegex), {
      message: 'Ogiltig MAC-adress',
    }),
  imei: z.coerce
    .number({ invalid_type_error: 'IMEI består av siffror' })
    .min(1, 'Obligatoriskt fält'),
});
type Form = z.infer<typeof formSchema>;

export const DeviceDataForm = () => {
  const discardFlowService = useContext(DiscardStateContext);
  const state = useSelector(discardFlowService, (state) => state);

  const onSubmit = ({ macAddress, imei }: Form) =>
    discardFlowService.send({
      type: 'SUBMIT_DEVICE_DATA_FORM',
      macAddress,
      imei,
    });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<Form>({
    shouldUnregister: true,
    resolver: zodResolver(formSchema),
  });

  if (state.matches('DEVICE_DATA_FORM.editing')) {
    return (
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Grid container maxWidth="50%">
          <Grid item xs={12}>
            <Typography variant="h5">
              Skanna MAC samt IMEI på en enhet
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Textfield
              label="MAC-Adress"
              error={errors.macAddress}
              autoComplete="off"
              fullWidth
              autoFocus
              sx={{ marginBottom: 2 }}
              inputProps={{
                ...register('macAddress', {
                  onChange(event) {
                    event.target.value = event.target.value
                      .replaceAll(':', '')
                      .trim();
                  },
                }),
                'data-testid': 'macAddress',
              }}
            />
            <Textfield
              label="IMEI"
              error={errors.imei}
              autoComplete="off"
              fullWidth
              autoFocus
              sx={{ marginBottom: 2 }}
              inputProps={{
                ...register('imei', {
                  onChange(event) {
                    event.target.value = event.target.value.trim();
                  },
                }),
                'data-testid': 'imei',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="outlined">
              Sök
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  }
  return null;
};
