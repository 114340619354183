import { Campaign } from '@/types/campaigns';

export function prepareCampaignOptions(campaigns: Campaign[]) {
  if (campaigns) {
    return campaigns
      .filter((campaign) => campaign.active)
      .map((campaign) => ({
        value: campaign['@id'],
        text: `${campaign.id} - ${campaign.name}`,
      }));
  }
  return [];
}
