import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { FormApi } from 'final-form';

import { getCampaigns } from '@/api/campaigns';
import { BasicAlert } from '@/components/BasicAlert';
import { Autocomplete, DatePicker, Dropdown } from '@/components/form';
import { queryKeys } from '@/constants/queryKeys';
import { usePrivateSettings } from '@/hooks';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { CampaignTypes, ProtectionCampaign } from '@/types/campaigns';
import { SelectFieldOptions } from '@/types/common';
import { GenericHttpError } from '@/types/errors';
import { CamelCaseServiceName, ServiceName } from '@/types/services';
import { FEATURE_FLAGS } from '@/utils/featureFlags';

export type FormValues = {
  product: Extract<ServiceName, 'sinne' | 'homegate' | 'protection'>;
  regDate: string;
  campaignIds: Array<number>;
};

type Props = {
  handleSubmit: () => void;
  submitting: boolean;
  values: FormValues;
  form: FormApi<FormValues, Partial<FormValues>>;
};

export const WelcomeLetterForm = ({
  handleSubmit,
  submitting,
  values,
  form,
}: Props) => {
  const { servicesNames } = usePrivateSettings();
  const protectionCampaignV2Enabled = useFeatureFlag({
    name: FEATURE_FLAGS.protectionCampaignV2.name,
  });

  const welcomeLetterEnabledServices: ServiceName[] =
    protectionCampaignV2Enabled
      ? ['sinne', 'homegate', 'protection']
      : ['sinne', 'homegate'];

  const options = Object.keys(servicesNames)
    .filter((service) => welcomeLetterEnabledServices.includes(service))
    .map((service) => ({
      value: service,
      text: servicesNames[service as CamelCaseServiceName],
    }));

  const {
    data: campaigns,
    isError: errorCampaigns,
    error: campaignError,
  } = useQuery<SelectFieldOptions, GenericHttpError>({
    queryKey: [queryKeys.getCampaigns, values.product, 'sendings'],

    queryFn: async () => {
      const service = values.product;
      let campaignPrefix: string = service;

      if (service === 'protection') {
        campaignPrefix = 'protections';
      }

      const campaigns = await getCampaigns(
        `${campaignPrefix}_campaigns` as CampaignTypes,
        {
          pagination: false,
          properties: {
            id: true,
            name: true,
            bulkPrintEnabled: true,
          },
        },
      );

      if (campaignPrefix === 'protections') {
        return (campaigns as unknown as ProtectionCampaign[])
          .filter((campaign) => campaign.bulkPrintEnabled)
          .map((campaign) => ({
            value: campaign.id,
            text: campaign.name,
          }));
      }
      return campaigns.map((campaign) => ({
        value: campaign.id,
        text: campaign.name,
      }));
    },

    initialData: [],
    enabled: !!values.product,
  });

  const campaignIds = campaigns.map((campaign) => Number(campaign.value));

  return (
    <Card component="form" onSubmit={handleSubmit}>
      <CardHeader title="Välkomstbrev" />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Dropdown
              label="Tjänst"
              name="product"
              options={options}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker label="Orderdatum" name="regDate" required />
          </Grid>
          <Grid item xs={6}>
            {errorCampaigns ? (
              <Box marginTop="16px" marginBottom="8px">
                <BasicAlert
                  message={`${campaignError?.status}: ${campaignError?.statusText}`}
                />
              </Box>
            ) : (
              <>
                <Autocomplete
                  key={campaigns.map((option) => option.value).join('-')}
                  name="campaignIds"
                  label="Kampanj"
                  multiple
                  getOptionValue={(option) => option.value}
                  getOptionLabel={(option) =>
                    typeof option === 'string' ? option : option.text
                  }
                  options={campaigns}
                  textFieldProps={{ variant: 'outlined' }}
                  disableCloseOnSelect
                  limitTags={2}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        checked={
                          selected ||
                          values.campaignIds?.includes(Number(option.value))
                        }
                      />
                      {option.text}
                    </li>
                  )}
                />
              </>
            )}
          </Grid>
          <Grid item xs={6} style={{ display: 'flex-end' }}>
            <Button
              variant="outlined"
              onClick={() => form.change('campaignIds', campaignIds)}
              style={{ marginTop: '16px' }}
            >
              Välj Alla Kampanjer
            </Button>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardActions>
        <Button
          disabled={submitting}
          color="primary"
          variant="outlined"
          type="submit"
          onClick={handleSubmit}
        >
          Skapa brev
        </Button>
      </CardActions>
    </Card>
  );
};
