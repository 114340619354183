import { KebabCaseServiceName } from '@/modules/common/utils/services';
import {
  CreateResellerParams,
  GetResellerParams,
  ResellerItem,
  ResellersResponse,
  UpdateResellerParams,
  UpdateResellerProfileParams,
} from '@/types/resellers';
import httpClient from '@/utils/httpClient';

export async function getResellers(
  { pagination = false, ...rest }: GetResellerParams = { pagination: false },
) {
  const { data } = await httpClient.get<ResellersResponse>('/resellers', {
    params: {
      pagination,
      ...rest,
    },
  });

  return { data: data['hydra:member'], totalCount: data['hydra:totalItems'] };
}

export async function getResellerById(id: number) {
  const { data } = await httpClient.get<ResellerItem>(`/resellers/${id}`);

  return data;
}

export async function getResellerServices(id: number) {
  const { data } = await httpClient.get<Record<KebabCaseServiceName, string>>(
    `/resellers/get-services/${id}`,
  );

  return data;
}

export async function createReseller(payload: CreateResellerParams) {
  const { data } = await httpClient.post<ResellerItem>(`/resellers`, payload);

  return data;
}

export async function updateReseller({ id, ...payload }: UpdateResellerParams) {
  const { data } = await httpClient.put<ResellerItem>(
    `/resellers/${id}`,
    payload,
  );

  return data;
}

export async function updateResellerProfile({
  id,
  ...payload
}: UpdateResellerProfileParams) {
  const { data } = await httpClient.put<ResellerItem>(
    `/resellers/profile/${id}`,
    payload,
  );

  return data;
}

export async function deleteReseller(id: string | number) {
  return await httpClient.delete(`/resellers/${id}`);
}

export async function sendNewPassword(id: string | number) {
  const { data } = await httpClient.get<{ message: string }>(
    `/resellers/password/send/${id}`,
  );

  return data;
}
