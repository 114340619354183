import { useQuery } from '@tanstack/react-query';
import axios, { AxiosRequestConfig } from 'axios';
import { parse, stringify } from 'qs';

import { config } from '@/helpers/config';
import useUser from '@/hooks/useUser';
import { FeatureFlag, featureFlagsLocalStorageKey } from '@/types/featureFlags';

export const useFeatureFlags = () => {
  const user = useUser();
  const wlId = config.whitelabelId;

  const httpConfig: AxiosRequestConfig = {
    method: 'get',
    maxBodyLength: Infinity,
    url: import.meta.env.VITE_UNLEASH_URL,
    paramsSerializer: {
      encode: (param) => parse(param),
      serialize: (params) => stringify(params),
    },
    params: {
      wlId,
      userRoles: user?.roles?.toString() ?? '',
    },
    headers: {
      Accept: 'application/json',
      Authorization: import.meta.env.VITE_UNLEASH_CLIENT_KEY,
    },
  };

  return useQuery<FeatureFlag[]>({
    queryKey: ['unleash-feature-flag', httpConfig],
    queryFn: async () => {
      return await axios(httpConfig)
        .then((response) => {
          const flags = response.data.toggles as FeatureFlag[];
          localStorage.setItem(
            featureFlagsLocalStorageKey,
            JSON.stringify(flags),
          );
          return flags;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    refetchOnWindowFocus: true,
  });
};
