import pick from 'lodash/pick';

import {
  SinneData,
  TransformedSinneData,
} from '@/modules/sinne/components/sinneConfigFlow/context/sinneConfigStateMachine';
import { Campaign } from '@/types/campaigns';
import { ResellerItem } from '@/types/resellers';

export const transformSinneConfigData = (
  data: SinneData & { reseller: ResellerItem | null; campaign: Campaign },
): TransformedSinneData => {
  const fieldsToPick = [
    'reseller',
    'salesRep',
    'price.priceInCurrency',
    'price.priceWithVatInCurrency',
    'campaign',
    'customer.id',
    'customer.companyCategory',
    'customer.companyName',
    'customer.fullName',
    'customer.address',
    'customer.zip',
    'customer.city',
    'customer.email',
    'customer.mobile',
    'customer.pin',
  ];
  const flattened = pick(data, fieldsToPick);
  const { customer, price, ...rest } = flattened;

  let transformed: TransformedSinneData;
  if (customer?.companyCategory) {
    transformed = {
      ...rest,
      campaign: data?.campaign?.name,
      reseller: data?.reseller?.name,
      price: price?.priceWithVatInCurrency,
      ...customer,
      customerId: customer?.id,
    };
  } else {
    transformed = {
      ...rest,
      ...customer,
      campaign: data?.campaign?.name,
      reseller: data?.reseller?.name,
      price: price?.priceInCurrency,
      customerId: customer?.id,
    };
  }
  return transformed;
};
