import isPropValid from '@emotion/is-prop-valid';
import { TableBody, TableCell, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';

import { SimpleTableColumn } from './SimpleTable';

type Props = {
  columns: SimpleTableColumn[];
  rows: any[];
  onRowClick?: (row: any) => void;
};

const StyledTableRow = styled(TableRow, {
  shouldForwardProp: (prop: string) => isPropValid(prop),
})<{
  isClickable: boolean;
  useWarningColors?: boolean;
}>`
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'auto')};
  background-color: ${({ theme, useWarningColors }) =>
    useWarningColors ? theme.palette.warning.deadline : undefined};
  color: ${({ theme, useWarningColors }) =>
    useWarningColors
      ? theme.palette.getContrastText(theme.palette.warning.deadline)
      : 'inherit'};
`;

export const BodySection = ({ columns, rows, onRowClick }: Props) => {
  return (
    <TableBody>
      {rows.map((row, index) => (
        <StyledTableRow
          data-testid={`table-row-${index}`}
          key={row['@id'] || row.id || index}
          isClickable={Boolean(onRowClick)}
          hover={Boolean(onRowClick)}
          onClick={() => onRowClick && onRowClick(row)}
          useWarningColors={row.showWarning}
        >
          {columns.map((column, index) => (
            <TableCell
              sx={{ color: 'inherit' }}
              key={index}
              align={column.align || 'inherit'}
            >
              {column.renderer(row)}
            </TableCell>
          ))}
        </StyledTableRow>
      ))}
    </TableBody>
  );
};
