import React, { useEffect, useState } from 'react';

import {
  Box,
  Grid,
  LinearProgress,
  Typography as MuiTypography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Helmet } from 'react-helmet';
import { useHotkeys } from 'react-hotkeys-hook';
import { useNavigate, useParams } from 'react-router-dom';

import { getCustomerById } from '@/api/customers';
import { Alert } from '@/components/Alert';
import { Breadcrumbs } from '@/components/Breadcrumbs';
import { PageWithDrawer } from '@/components/PageWithDrawer';
import { Tabs } from '@/components/Tabs/Tabs';
import { CustomerCategoryTypes } from '@/constants';
import { ApplicationRoutes } from '@/constants/applicationRoutes';
import { queryKeys } from '@/constants/queryKeys';
import { useModal } from '@/hooks/useModal';
import { CommunicationDrawer, CopyButton } from '@/modules/common/components';
import {
  Billing,
  Costs,
  CustomerOverview,
  CustomerServicesTab,
  CustomerToolbar,
  Tasks,
} from '@/modules/customer/components';
import { GenerateLetterTab } from '@/modules/customer/components/GenerateLetter/GenerateLetterTab';
import { CreateTaskModal } from '@/modules/tasks';
import { TaskTeamTypes } from '@/modules/tasks/constants/tasksModalsConstants';
import { ModalTypes } from '@/state/modalManagementAtom';
import { Tabmap } from '@/types/tabs';
import { checkRolesAccessFromJwt } from '@/utils/authorization';
import { extractIdFromIRI } from '@/utils/common';
import { getCustomerName } from '@/utils/customer';

const CUSTOMER_TABS: Tabmap[] = [
  {
    title: 'Översikt',
    view: ({ customer, handleCreateTaskModalVisibility }) => (
      <CustomerOverview
        handleCreateTaskModalVisibility={handleCreateTaskModalVisibility}
        customer={customer}
      />
    ),
  },
  {
    title: 'Tjänster',
    view: ({ customer }) => <CustomerServicesTab customer={customer} />,
  },
  {
    title: 'Fakturering',
    view: ({ companyCustomer }) => (
      <Billing companyCustomer={companyCustomer} />
    ),
  },
  {
    title: 'Generera',
    view: ({ customer }) => <GenerateLetterTab customer={customer} />,
  },
  {
    title: 'Ärenden',
    view: ({ customer, handleCreateTaskModalVisibility }) => (
      <Tasks
        customer={customer}
        handleCreateTaskModalVisibility={handleCreateTaskModalVisibility}
      />
    ),
  },
  {
    title: 'Kostnader',
    view: () => <Costs />,
  },
];

export const Customer = () => {
  const { openModal } = useModal();
  const navigate = useNavigate();
  const { id } = useParams();
  const customerId = parseInt(id!, 10);

  const [createTaskModalOpen, setCreateTaskModalOpen] = useState(false);
  const [createTaskModalDefaults, setCreateTaskModalDefaults] = useState<
    | {
        team: number;
        status: number;
      }
    | undefined
  >(undefined);

  useEffect(() => {
    if (createTaskModalOpen === false) {
      setCreateTaskModalDefaults(undefined);
    }
  }, [createTaskModalOpen]);

  const fieldsToFetch = {
    id: true,
    category: true,
    firstName: true,
    lastName: true,
    fullName: true,
    address: true,
    address2: true,
    zip: true,
    city: true,
    email: true,
    phone: true,
    mobile: true,
    pin: true,
    invoiceEmail: true,
    validInvoiceEmail: true,
    invoicePeriod: true,
    bankName: true,
    bankAccount: true,
    autogiroAgreement: true,
    autogiroSource: true,
    debtCollection: true,
    latitude: true,
    longitude: true,
    companyName: true,
    company: true,
    doNotDisturb: true,
    lastLogin: true,
    password: true,
    passwordType: true,
    saveStatus: true,
    savedAt: true,
    partnerDate: true,
    coProtections: true,
    firetexts: true,
    homegates: true,
    keyTags: true,
    protections: true,
    stickers: true,
    fees: true,
    feesForBilling: true,
    partnerCompany: {
      id: true,
      name: true,
    },
    agreements: true,
    savedBy: {
      userName: true,
      name: true,
      email: true,
      id: true,
    },
    activeServiceTypes: true,
    activeProtections: true,
  };

  const {
    data: customer,
    isError,
    error,
  } = useQuery({
    queryKey: [queryKeys.getCustomerById, id, fieldsToFetch],

    queryFn: async () => {
      return getCustomerById(id!, fieldsToFetch);
    },
  });

  React.useEffect(() => {
    // Navigate to not-found if customer not found
    if ((error as { status?: number })?.status === 404) {
      navigate('/not-found');
    }
  }, [error]);

  const tabRenderProps = {
    handleCreateTaskModalVisibility: () =>
      setCreateTaskModalOpen(!createTaskModalOpen),
    customer,
    companyCustomer: customer?.category === CustomerCategoryTypes.COMPANY,
  };

  //#region Hotkeys
  useHotkeys(
    'alt+s',
    () => {
      if (customer) {
        openModal({
          modalType: ModalTypes.SMS_CUSTOMER,
          extra: { customerId: customer.id },
        });
      }
    },
    [customer],
  );

  useHotkeys(
    'alt+m',
    () => {
      if (customer) {
        openModal({
          modalType: ModalTypes.MAIL_CUSTOMER,
          extra: { customerId: customer.id },
        });
      }
    },
    [customer],
  );

  useHotkeys(
    'alt+k',
    () => {
      if (customer) {
        openModal({
          modalType: ModalTypes.ADD_COST,
          extra: { customerId: customer.id },
        });
      }
    },
    [customer],
  );

  useHotkeys(
    'alt+t',
    () => {
      if (customer) {
        setCreateTaskModalDefaults({
          team: TaskTeamTypes.CUSTOMER_SUPPORT,
          status: 0,
        });
        setCreateTaskModalOpen(true);
      }
    },
    {},
    [customer],
  );

  useHotkeys(
    'alt+h',
    () => {
      if (customer) {
        openModal({
          modalType: ModalTypes.CREATE_ADMIN_TASK,
          extra: { customerId: customer.id, pin: customer.pin },
        });
      }
    },
    {},
    [customer],
  );
  //#endregion

  if (isError) {
    return <Alert severity="error">Misslyckades hämta kund</Alert>;
  }
  if (!customer) {
    return <LinearProgress />;
  }

  return (
    <>
      <Helmet title={`${customer.id} - ${getCustomerName(customer)}`} />

      <PageWithDrawer
        key={customerId}
        pageContent={
          <Box sx={{ maxWidth: 'lg' }}>
            <Breadcrumbs
              crumbs={[
                { label: 'Kunder', url: ApplicationRoutes.customers() },
                { label: getCustomerName(customer) },
              ]}
            />

            <Grid container spacing={3} alignItems="center">
              <Grid item>
                <MuiTypography variant="h2">
                  {`${customer.id} - ${getCustomerName(customer)}`}
                </MuiTypography>
              </Grid>

              <Grid item>
                <CopyButton
                  value={extractIdFromIRI(customer['@id']).toString()}
                  onlyIcon
                />
              </Grid>

              {checkRolesAccessFromJwt('customers.debtCollection') &&
                !customer.debtCollection && (
                  <Grid item>
                    <MuiTypography variant="h4" color="error">
                      Ej Inkasso
                    </MuiTypography>
                  </Grid>
                )}
            </Grid>

            <CustomerToolbar
              customerId={extractIdFromIRI(customer['@id'])}
              customerPin={customer.pin}
            />

            <Tabs tabs={CUSTOMER_TABS} viewProps={tabRenderProps} />

            {createTaskModalOpen && (
              <CreateTaskModal
                isBarringService={false}
                open={createTaskModalOpen}
                handleVisibility={() =>
                  setCreateTaskModalOpen(!createTaskModalOpen)
                }
                customer={customer}
                defaults={createTaskModalDefaults}
              />
            )}
          </Box>
        }
        drawerContent={
          <CommunicationDrawer
            isInSecurityService={false}
            customerId={customer.id!}
          />
        }
      />
    </>
  );
};

export default Customer;
