import { differenceInDays, parseISO } from 'date-fns';
import invariant from 'tiny-invariant';

import { CustomerCategoryTypes } from '@/constants';
import { UserRole } from '@/types/auth';
import { ServiceParams } from '@/types/services';
import { checkRolesAccess } from '@/utils/authorization';

export function isServiceStatusChangeEnabled(
  service: ServiceParams,
  userRoles: UserRole[],
): boolean {
  // Godmode and administrator can always change status, no further checks needed
  if (
    userRoles.includes('ROLE_GODMODE') ||
    userRoles.includes('ROLE_ADMINISTRATOR')
  ) {
    return true;
  }

  // Customer service can always change key-tag status
  if (
    userRoles.includes('ROLE_CUSTOMER_SUPPORT') &&
    service['@type'] === 'KeyTag'
  ) {
    return true;
  }

  // Check if service status change is part of user role access
  const hasSufficientAccess = checkRolesAccess(
    userRoles,
    'services.changeStatus',
  );

  // Customer service should be able to change status only of private customer
  const isPrivateCustomer =
    service.customer.category === CustomerCategoryTypes.PRIVATE;

  try {
    // Get number of days since service was created
    invariant(service.regDate, 'Service regDate is missing');

    const regDate = parseISO(service.regDate);
    const today = new Date();
    const daysDiff = differenceInDays(today, regDate);

    // For non-godmode and non-admin
    return hasSufficientAccess && isPrivateCustomer && daysDiff <= 14;
  } catch (error) {
    console.error('Error in isServiceStatusChangeEnabled:', error);
    return false;
  }
}
