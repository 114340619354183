import { useMutation, useQueryClient } from '@tanstack/react-query';

import { reactivateUser } from '@/api/users';
import { ConfirmationDialog } from '@/components/ConfirmationDialog';
import { queryKeys } from '@/constants/queryKeys';
import { useSnackbar } from '@/hooks';
import { UserItem } from '@/types/users';

type Props = {
  open: boolean;
  handleClose: () => void;
  user: Partial<UserItem>;
};
export const ReactivationDialog = ({ open, handleClose, user }: Props) => {
  const queryClient = useQueryClient();
  const snackbar = useSnackbar();

  const mutation = useMutation({
    mutationFn: reactivateUser,
    onSuccess: () => {
      handleClose();
      queryClient.refetchQueries({
        queryKey: [queryKeys.getDeletedUsers],
      });
      snackbar({
        type: 'success',
        message: 'Användaren har återaktiverats',
      });
    },
    onError: () => {
      snackbar({
        type: 'error',
        message: 'Det gick inte att återaktivera användaren',
      });
    },
  });

  return (
    <ConfirmationDialog
      dialogText={`Är du säker på att du vill återaktivera användaren ${user.name}?`}
      isOpened={open}
      onConfirm={() => mutation.mutate(user.id!)}
      onClose={handleClose}
      confirmationButtonText="Ja"
      cancellationButtonText="Nej"
    />
  );
};
