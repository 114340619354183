import { Helmet } from 'react-helmet';

import { Breadcrumbs } from '@/components/Breadcrumbs';
import { PageHeader } from '@/components/PageHeader';
import { Table } from '@/modules/accountManagerSystem/components/Table';

export const Upsale = () => {
  const queryProps = {
    churnDateExists: false,
    hasActiveService: true,
    onboarded: true,
  };

  return (
    <>
      <Helmet title="Upsale" />
      <Breadcrumbs
        crumbs={[{ label: 'Account Manager System' }, { label: 'Upsale' }]}
      />
      <PageHeader title="Upsale" margin />
      <Table queryProps={queryProps} />
    </>
  );
};

export default Upsale;
