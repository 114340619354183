import React from 'react';

import {
  FormControl,
  FormControlProps,
  InputBaseComponentProps,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

type Props = FormControlProps & {
  label: string;
  labelId: string;
  value: any;
  onChange: (event: SelectChangeEvent<HTMLInputElement>) => void;
  options: Array<{
    id?: number | string;
    value: any;
    text: string;
  }>;
  fullWidth?: boolean;
  inputProps?: InputBaseComponentProps;
};

export const PureDropdown = ({
  label,
  labelId,
  value,
  onChange,
  options,
  fullWidth = true,
  inputProps,
  ...rest
}: Props) => {
  return (
    <FormControl fullWidth={fullWidth} {...rest}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        labelId={labelId}
        label={label}
        value={value}
        onChange={onChange}
        inputProps={inputProps}
        MenuProps={{
          // @ts-expect-error data-testid does not exist in type Partial<MenuProps>
          'data-testid': `${labelId}-pure-dropdown-select`,
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.id ?? option.value} value={option.value}>
            {option.text ?? option.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
