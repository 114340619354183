import { LinearProgress } from '@mui/material';

import { SimpleCard } from '@/components/SimpleCard';
import { ContactListSection } from '@/modules/services/components/sections';

type Props = {
  isLoading: boolean;
};

export const ContactListForm = ({ isLoading }: Props) => {
  return (
    <SimpleCard
      title="Kontaktlista"
      marginBottom="0"
      cardContent={
        <>
          <section>
            <ContactListSection />
          </section>

          {isLoading && <LinearProgress />}
        </>
      }
    />
  );
};
