import styled from '@emotion/styled';
import { Button, Card, CardHeader, Divider, Grid } from '@mui/material';
import { Form } from 'react-final-form';

import { usePrivateSettings } from '@/hooks/usePrivateSettings';

import { useDownloadExportFile } from '../../hooks/useDownloadExportFile';
import { SinneEconomyFormValues } from './SinneEconomyOverview';

type MutationParams = {
  end: string | undefined;
};

type Props = {
  filter: SinneEconomyFormValues;
};

export const ExportEconomy = ({ filter }: Props) => {
  const { servicesNames } = usePrivateSettings();
  const serviceName = servicesNames.sinne;

  const mutation = useDownloadExportFile();

  const handleSubmit = () => {
    const { end } = filter;
    const params: MutationParams = {
      end,
    };
    mutation.mutate({
      params,
      type: 'sinne.economy',
      whiteLableExportName: serviceName,
    });
  };

  return (
    <Grid>
      <Card>
        <CardHeader title="Exportera" />
        <Divider />

        <Form onSubmit={handleSubmit}>
          {({ handleSubmit, invalid }) => (
            <Card component="form" onSubmit={handleSubmit}>
              <StyledButton
                disabled={invalid || mutation.isPending}
                variant="outlined"
                color="primary"
                type="submit"
              >
                EXPORTERA
              </StyledButton>
            </Card>
          )}
        </Form>
      </Card>
    </Grid>
  );
};

const StyledButton = styled(Button)({
  margin: '10px 0 10px 15px',
});
