import {
  CustomerCategoryTypes,
  PRICE_TRANSLATIONS,
  PRICE_TYPES,
} from '@/constants';
import { PriceItemWithType } from '@/types/prices';

type PricesOptionsArgs = {
  allPrices: PriceItemWithType[];
  customerType: number;
};

export function preparePricesOptions(
  allPrices: PricesOptionsArgs['allPrices'] | undefined,
  customerType: PricesOptionsArgs['customerType'],
) {
  if (!allPrices || !customerType) {
    return [];
  }

  const prices = allPrices.filter(
    (price) => price.type === PRICE_TYPES.PRICE && price.active,
  );

  return prices.map((price) => ({
    value: price['@id'],
    text:
      customerType === CustomerCategoryTypes.COMPANY
        ? `${price.priceInCurrency} exkl. moms - ${
            PRICE_TRANSLATIONS[price.type]
          }`
        : `${price.priceWithVatInCurrency} inkl. moms - ${
            PRICE_TRANSLATIONS[price.type]
          }`,
  }));
}
