import React from 'react';

import { Button } from '@mui/material';

type Props = { onClick: () => void; text?: string };
export const RetryButton = ({ onClick, text = 'Försök igen' }: Props) => (
  <Button size="small" variant="outlined" onClick={() => onClick()}>
    {text}
  </Button>
);
