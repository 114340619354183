import React from 'react';

import {
  Box,
  Card,
  CardProps,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Theme,
  useMediaQuery,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import {
  endOfMonth,
  isAfter,
  isBefore,
  isEqual,
  startOfMonth,
  subMonths,
} from 'date-fns';

import { getFormattedDate } from '@/helpers/dates';

export type Dates = {
  start: string | null;
  end: string | null;
};

type Props = {
  cardProps?: CardProps;
  dates: Dates;
  setDates: ({ start, end }: Dates) => void;
};

const PERIOD_OPTIONS = [
  { id: 1, value: 'thisMonth', label: 'Den här månaden' },
  { id: 2, value: 'lastMonth', label: 'Förra månaden' },
  { id: 3, value: 'custom', label: 'Anpassad' },
] as const;

type Period = (typeof PERIOD_OPTIONS)[number]['value'];

export const DatePeriodToolbar = ({ cardProps, setDates, dates }: Props) => {
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );

  const [period, setPeriod] = React.useState<Period>('thisMonth');

  function handlePeriodChange(event: SelectChangeEvent) {
    const period = event.target.value as Period;
    setPeriod(period);

    if (period === 'thisMonth') {
      setDates({
        start: getFormattedDate(startOfMonth(new Date())),
        end: getFormattedDate(endOfMonth(new Date())),
      });
    }
    if (period === 'lastMonth') {
      setDates({
        start: getFormattedDate(startOfMonth(subMonths(new Date(), 1))),
        end: getFormattedDate(endOfMonth(subMonths(new Date(), 1))),
      });
    }
  }

  function handleStartDateChange(date: string | null) {
    if (date === null) {
      return;
    }
    const start = new Date(date);
    const end = new Date(dates.end!);
    if (isBefore(start, end) || isEqual(start, end)) {
      setDates({
        end: dates.end,
        start: getFormattedDate(start),
      });
      setPeriod('custom');
    }
  }

  function handleEndDateChange(date: string | null) {
    if (date === null) {
      return;
    }
    const start = new Date(dates.start!);
    const end = new Date(date);
    if (isAfter(end, start) || isEqual(end, start)) {
      setDates({
        start: dates.start,
        end: getFormattedDate(end),
      });
      setPeriod('custom');
    }
  }

  return (
    <Box component={Card} {...cardProps}>
      <Grid container spacing={isSmallScreen ? 0 : 2} px={2}>
        <Grid item xs={12} md>
          <DatePicker
            label="Från"
            onChange={(date) => handleStartDateChange(date!)}
            value={dates.start}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </Grid>
        <Grid item xs={12} md>
          <DatePicker
            label="Till"
            onChange={(date) => handleEndDateChange(date)}
            value={dates.end}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </Grid>
        <Grid item xs={12} md>
          <FormControl fullWidth>
            <InputLabel>Period</InputLabel>
            <Select value={period} label="Period" onChange={handlePeriodChange}>
              {PERIOD_OPTIONS.map((item) => (
                <MenuItem key={item.id} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};
