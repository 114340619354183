import React from 'react';

import { Helmet } from 'react-helmet';

import { PageHeader } from '@/components/PageHeader';
import { UsersBreadcrumbs } from '@/modules/users/components/UsersBreadCrumbs';
import { UsersContainer } from '@/modules/users/components/UsersContainer';

export const Users = () => {
  return (
    <>
      <Helmet title="Användare" />
      <UsersBreadcrumbs />
      <PageHeader title="Användare" />
      <UsersContainer />
    </>
  );
};

export default Users;
