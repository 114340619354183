import React from 'react';

import {
  Button,
  Checkbox,
  DialogActions,
  FormControlLabel,
  Grid,
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FormApi } from 'final-form';
import snakeCase from 'lodash/snakeCase';
import { Form } from 'react-final-form';

import { queryKeys } from '@/constants/queryKeys';
import { useSnackbar } from '@/hooks';
import { BaseModal } from '@/modules/common/components';
import httpClient from '@/utils/httpClient';

import { CardFormFields } from './CardFormFields';
import { MobileFormFields } from './MobileFormFields';

type Props = {
  open: boolean;
  onClose: () => void;
  extra?: any;
  create?: boolean;
  productIri?: string;
  registerType?: string;
  initialValues?: any;
};

export const RegisteredCardsAndMobilesModal = ({
  initialValues,
  extra,
  create,
  productIri,
  registerType,
  open,
  onClose,
}: Props) => {
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();

  const showErrorSnackbar = () =>
    snackbar({ type: 'error', message: 'Något gick fel.' });

  const queryKey =
    registerType === 'Kort' ? queryKeys.getCards : queryKeys.getMobiles;
  const [multipleEntries, setMultipleEntries] = React.useState(false);

  const editMutation = useMutation({
    mutationFn: ({ url, payload }: { url: string; payload: any }) =>
      httpClient.put(url, payload),
    onSuccess: () => {
      snackbar({
        type: 'success',
        message: `${registerType} uppdaterad`,
      });
      queryClient.invalidateQueries({
        queryKey: [queryKey, productIri],
      });
    },
    onError: (e) => {
      // eslint-disable-next-line no-console
      console.log('ERROR', e);
      showErrorSnackbar();
    },
    onSettled: () => {
      onClose();
    },
  });

  const createMutation = useMutation({
    mutationFn: ({ url, payload }: { url: string; payload: any }) =>
      httpClient.post(url, payload),
    onSuccess: () => {
      snackbar({
        type: 'success',
        message: `${registerType} tillagd`,
      });
      queryClient.invalidateQueries({
        queryKey: [queryKey, productIri],
      });

      !multipleEntries && onClose();
    },
    onError: () => {
      showErrorSnackbar();
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (url: string) => httpClient.delete(url),
    onSuccess: () => {
      snackbar({
        type: 'success',
        message: `${registerType} togs bort.`,
      });
      queryClient.invalidateQueries({
        queryKey: [queryKey, productIri],
      });
      onClose();
    },
    onError: () => {
      showErrorSnackbar();
    },
  });
  const onSubmit = (values: any, form: FormApi) => {
    const payload = {
      ...values,
      ...extra.params,
    };

    if (create) {
      const url = snakeCase(extra['@type']) + 's';
      createMutation.mutate({ url, payload });
    } else {
      editMutation.mutate({ url: extra?.['@id'], payload });
    }

    if (multipleEntries) {
      form.restart({
        fullName: values.fullName,
        pin: values.pin,
      });
    }
  };

  const isLoading =
    createMutation.isPending ||
    editMutation.isPending ||
    deleteMutation.isPending;

  return (
    <>
      <BaseModal
        title={
          create
            ? `Lägg till ${registerType?.toLowerCase()}`
            : `Redigera ${registerType?.toLowerCase()}`
        }
        open={open}
        onClose={onClose}
      >
        <>
          {create && (
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={multipleEntries}
                    onChange={(e) => setMultipleEntries(e.target.checked)}
                    name="multipleEntries"
                    color="primary"
                  />
                }
                label="Flera poster"
              />
            </Grid>
          )}
          <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            render={({ handleSubmit, pristine }) => (
              <form onSubmit={handleSubmit} noValidate>
                <>
                  {registerType === 'Mobil' ? (
                    <MobileFormFields />
                  ) : (
                    <CardFormFields />
                  )}
                </>
                <DialogActions>
                  {!create && (
                    <Button
                      color="primary"
                      onClick={() => deleteMutation.mutate(extra['@id'])}
                    >
                      Ta bort
                    </Button>
                  )}
                  <Button
                    color="primary"
                    type="submit"
                    disabled={pristine || isLoading}
                  >
                    {create ? 'Skapa' : 'Uppdatera'}
                  </Button>
                </DialogActions>
              </form>
            )}
          />
        </>
      </BaseModal>
    </>
  );
};
