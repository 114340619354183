import React from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

import { queryKeys } from '@/constants/queryKeys';
import { useSnackbar } from '@/hooks';
import { Textfield } from '@/modules/components';
import { ResellerItem } from '@/types/resellers';

import { useUpdateResellerProfile } from '../../hooks/useUpdateResellerProfile';
import { validationSchema } from './validationSchema';

const resellerAccountSchema = validationSchema.omit({
  businessDevelopmentManager: true,
});

type FormValues = z.infer<typeof resellerAccountSchema>;

type Props = {
  data: ResellerItem;
};

export const ResellerAccountSettingsForm = ({ data }: Props) => {
  const queryClient = useQueryClient();
  const snackbar = useSnackbar();
  const updateResellerMutation = useUpdateResellerProfile();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      username: data.username,
      email: data.email,
      name: data.name,
    },
    resolver: zodResolver(resellerAccountSchema),
  });
  const onSubmit: SubmitHandler<FormValues> = (values) => {
    updateResellerMutation.mutate(
      { id: data.id, ...values },
      {
        onSuccess: async () => {
          queryClient.invalidateQueries({
            queryKey: queryKeys.reseller.detail(data.id),
          });
          snackbar({
            type: 'success',
            message: 'Inställningarna sparades',
          });
        },
        onError: () => {
          snackbar({
            type: 'error',
            message: 'Kunde inte spara inställningarna',
          });
        },
      },
    );
  };

  return (
    <Card component="form" onSubmit={handleSubmit(onSubmit)}>
      <CardHeader title="Redigera inställningar" />
      <Divider />
      <CardContent>
        <Textfield
          label="Namn"
          error={errors.name}
          fullWidth
          inputProps={{
            ...register('name'),
            'data-testid': 'edit-reseller-account-name',
          }}
        />
        <Textfield
          label="Användarnamn"
          error={errors.username}
          fullWidth
          inputProps={{
            ...register('username'),
            'data-testid': 'edit-reseller-username-account-username',
          }}
        />
        <Textfield
          label="Email"
          error={errors.email}
          fullWidth
          inputProps={{
            ...register('email'),
            'data-testid': 'edit-reseller-email-account-email',
          }}
        />
      </CardContent>
      <Divider />
      <CardActions sx={{ p: 2, justifyContent: 'space-between' }}>
        <Button type="submit" variant="contained">
          Spara
        </Button>
      </CardActions>
    </Card>
  );
};
