import { Typography } from '@mui/material';

type Props = {
  title: string;
  margin?: boolean;
};

export const PageHeader = ({ title, margin }: Props) => {
  return (
    <Typography
      variant="h3"
      component="h1"
      sx={{
        marginBottom: !!margin ? '1rem' : 0,
      }}
    >
      {title}
    </Typography>
  );
};
