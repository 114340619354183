import { useQueries } from '@tanstack/react-query';

import { fetchUser } from '@/api';
import { queryKeys } from '@/constants/queryKeys';
import { UserItem } from '@/types/users';

export const useGetUsersByIds = (ids: number[]) =>
  useQueries({
    queries: ids.map((id) => {
      return {
        queryKey: queryKeys.user.detail(id),
        queryFn: () => fetchUser({ id }),
      };
    }),
  });
