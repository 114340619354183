import React from 'react';

import { List, ListItem, ListItemText, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

import { CustomerCategoryTypes } from '@/constants';
import { getFormattedDate } from '@/helpers/dates';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { Service } from '@/modules/common/hooks/useActiveServices';
import { formatCurrency } from '@/modules/common/utils/currency';
import { CampaignInfoListItem } from '@/modules/customer/components/CustomerOverview/Tooltip/CampaignInfoListItem';
import { useGetPriceFromPricePlan } from '@/modules/customer/components/CustomerOverview/Tooltip/hooks/useGetPriceFromPricePlan';
import { ExistingCustomer } from '@/types/customers';
import { FEATURE_FLAGS } from '@/utils/featureFlags';

type Props = {
  customer: ExistingCustomer;
  service: Service;
};
const TooltipList = styled(List)(() => ({
  '& .MuiListItem-root': {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

export const TooltipContent = ({ service, customer }: Props) => {
  const protectionCampaignV2Enabled = useFeatureFlag({
    name: FEATURE_FLAGS.protectionCampaignV2.name,
  });

  const priceplanPrice = useGetPriceFromPricePlan(service);

  return (
    <Paper elevation={8} square>
      <TooltipList>
        <ListItem>
          <ListItemText
            primary={
              <>
                <b>ID: </b>
                {service?.id}
              </>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <>
                <b>Orderdatum: </b>
                {getFormattedDate(service.regDate)}
              </>
            }
          />
        </ListItem>
        {service.campaign && (
          <CampaignInfoListItem
            campaign={service.campaign}
            serviceType={service['@type']}
          />
        )}
        {protectionCampaignV2Enabled && service.protectionsCampaign && (
          <CampaignInfoListItem
            campaign={service.protectionsCampaign}
            serviceType={service['@type']}
          />
        )}
        <ListItem>
          {priceplanPrice && (
            <ListItemText
              primary={
                <>
                  <b>Pris: </b>
                  {formatCurrency(priceplanPrice)}
                </>
              }
            />
          )}
        </ListItem>
        {'price' in service && (
          <ListItem>
            <ListItemText
              primary={
                <>
                  <b>Pris: </b>
                  {service?.price &&
                    (customer.category === CustomerCategoryTypes.COMPANY
                      ? formatCurrency(service?.price?.priceInCurrency)
                      : formatCurrency(service?.price?.priceWithVatInCurrency))}
                </>
              }
            />
          </ListItem>
        )}
      </TooltipList>
    </Paper>
  );
};
