import { useAtom } from 'jotai';

import { initialState, sendingsAtom } from '@/state/sendingsAtom';

export const useSendingsState = () => {
  const [sendingsState, setSendingsState] = useAtom(sendingsAtom);

  const resetSendingsState = () => {
    setSendingsState(initialState);
  };

  return {
    sendingsState,
    setSendingsState,
    resetSendingsState,
  };
};
