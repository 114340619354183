import { Breadcrumbs } from '@/components/Breadcrumbs';
import { PageHeader } from '@/components/PageHeader';
import { Tabs } from '@/components/Tabs/Tabs';
import { ActiveAlarmsView, HandledAlarmsView } from '@/modules/alarms';
import { Tabmap } from '@/types/tabs';

export const BREADCRUMBS_CONFIG = [{ label: 'Larmcentral' }];

const AlarmsPage = () => {
  const TabMap: Tabmap[] = [
    { title: 'Aktiva', view: () => <ActiveAlarmsView /> },
    { title: 'Hanterade', view: () => <HandledAlarmsView /> },
  ];

  return (
    <>
      <Breadcrumbs crumbs={BREADCRUMBS_CONFIG} />

      <PageHeader title="Larmcentral" margin />

      <Tabs tabs={TabMap} />
    </>
  );
};

export default AlarmsPage;
