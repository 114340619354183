export const featureFlagsLocalStorageKey = 'feature-flags' as const;

export type FeatureFlag = {
  name: string;
  enabled: boolean;
  variant: {
    name: string;
    payload: {
      type: string;
      value: string;
    };
    enabled: boolean;
    feature_enabled: boolean;
    featureEnabled: boolean;
  };
  impressionData: boolean;
};
