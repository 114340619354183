import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Typography,
} from '@mui/material';
import * as Sentry from '@sentry/react';
import { useForm } from 'react-hook-form';

import { KeyTagBulkRegistrationParams } from '@/api/keyTag';
import { useBulkRegistrationState } from '@/hooks/useBulkRegistrationState';
import { usePrivateSettings } from '@/hooks/usePrivateSettings';
import { useSnackbar } from '@/hooks/useSnackbar';
import { useBulkRegisterKeyTags } from '@/modules/services/components/KeyTag/hooks/useBulkRegisterKeyTags';
import { FileImportValidation } from '@/types/files';

type Props = {
  data?: FileImportValidation;
  file: File;
};

export const KeyTagBulkRegistrationForm = ({ data, file }: Props) => {
  const { bulkRegistrationState, setBulkRegistrationState } =
    useBulkRegistrationState();

  const snackbar = useSnackbar();

  const { servicesNames } = usePrivateSettings();

  const uploadMutation = useBulkRegisterKeyTags();

  const {
    handleSubmit,
    formState: { errors, isValid },
    register,
    setError,
  } = useForm<KeyTagBulkRegistrationParams>({
    defaultValues: {
      file,
    },
  });

  if (!data) return null;

  if (!file) {
    setError('file', {
      message: 'Något har gått fel. Tryck avbryt och ladda upp filen igen',
    });
  }

  const onSubmit = (values: KeyTagBulkRegistrationParams) => {
    uploadMutation.mutate(values, {
      onSuccess: ({ data }) => {
        snackbar({
          message: `Registrering påbörjas för ${servicesNames.keyTag}`,
          type: 'success',
        });
        setBulkRegistrationState({
          ...bulkRegistrationState,
          serviceRegistrationStatus: 'pending',
          bulkRegistrationStep: 'serviceRegistrationPending',
          serviceRegistrationUuid: data.uuid,
        });
      },
      onError: (error: any) => {
        snackbar({
          message: error?.message || 'Något gick fel',
          type: 'error',
        });
        Sentry.captureException(error);
        setBulkRegistrationState({
          ...bulkRegistrationState,
          serviceRegistrationStatus: 'failed',
          bulkRegistrationStep: 'serviceRegistrationError',
        });
      },
    });
  };

  return (
    <>
      <Card>
        <CardHeader title={file?.name} />
        <Divider />
        <CardContent>
          <List>
            <ListItem disablePadding>
              <ListItemText disableTypography>
                <Typography variant="h6">{`${data?.rowCount} orderrader identifierade`}</Typography>
              </ListItemText>
            </ListItem>
          </List>
          {/* File processing errors */}
          {Boolean(data?.errors.length) && (
            <List
              subheader={
                <ListSubheader disableGutters>
                  Felmeddelanden från filanalysen:
                </ListSubheader>
              }
              sx={{
                '& .MuiListItemText-primary': {
                  color: (theme) => theme.palette.error.main,
                },
              }}
            >
              {data?.errors.map((error) => (
                <ListItem key={error}>
                  <ListItemText>{error}</ListItemText>
                </ListItem>
              ))}
            </List>
          )}

          {/* Form errors */}
          {!isValid && (
            <List
              subheader={
                <ListSubheader>Felmeddelanden i formuläret:</ListSubheader>
              }
              sx={{
                '& .MuiListItemText-primary': {
                  color: (theme) => theme.palette.error.main,
                },
              }}
            >
              {Object.entries(errors).map(([fieldName, error]) => (
                <ListItem disablePadding key={fieldName}>
                  <ListItemText>
                    {fieldName}: {error.message || 'Error'}
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          )}
        </CardContent>
      </Card>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <FormControl>
          <FormLabel component="legend">Tvingad insättning</FormLabel>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox {...register('keyTagDuplicate')} />}
              label="Ignorera om kund redan finns i systemet och skapa nyckelbricka oavsett"
            />
          </FormGroup>
        </FormControl>
        <FormControl>
          <FormLabel component="legend">Faktureras</FormLabel>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox {...register('keyTagBilling')} />}
              label="Kryssa i om du önskar att fakturering skall vara ikryssat för dessa tjänster"
            />
          </FormGroup>
        </FormControl>

        <Divider sx={{ mx: '-1rem' }} />

        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 2 }}
          disabled={uploadMutation.isPending}
        >
          Kör
        </Button>

        {uploadMutation.isPending && <LinearProgress sx={{ mt: 2 }} />}
      </Box>
    </>
  );
};
