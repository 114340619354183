import { Helmet } from 'react-helmet';

import {
  AgreementManagementBreadcrumbs,
  AgreementsForm,
} from '@/modules/agreementManagement';
import { PageHeader } from '@/modules/common/components/PageHeader';

const AgreementManagement = () => {
  return (
    <>
      <Helmet title="Avtalshantering" />
      <AgreementManagementBreadcrumbs />
      <PageHeader title="Avtalshantering" margin />
      <AgreementsForm />
    </>
  );
};

export default AgreementManagement;
