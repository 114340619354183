import { Tabs } from '@/components/Tabs/Tabs';
import { Tabmap } from '@/types/tabs';

import { MarketingCheckType, useAddressWash } from '../hooks/useAddressWash';
import { AnswerTable } from './AnswerTable';
import { AvoidWash } from './AvoidWash';
import { CrossSell } from './CrossSell';
import { CustomerExists } from './CustomerExists';

const tabMap: Tabmap[] = [
  {
    title: 'Avoid tvätt',
    view: ({ isSuccess, isLoading, isError, error, data, submitHandler }) => (
      <AvoidWash onSubmit={submitHandler}>
        <AnswerTable
          type="all"
          data={data?.['all']}
          isSuccess={isSuccess}
          isLoading={isLoading}
          isError={isError}
          error={error}
        />
      </AvoidWash>
    ),
  },
  {
    title: 'Finns kund',
    view: ({ isSuccess, isLoading, isError, error, data, submitHandler }) => (
      <CustomerExists onSubmit={submitHandler}>
        <AnswerTable
          type="exists"
          data={data?.['exists']}
          isSuccess={isSuccess}
          isLoading={isLoading}
          isError={isError}
          error={error}
        />
      </CustomerExists>
    ),
  },
  {
    title: 'Korssälj',
    view: ({ isSuccess, isLoading, isError, error, data, submitHandler }) => (
      <CrossSell onSubmit={submitHandler}>
        <AnswerTable
          type="firetext"
          data={data?.['firetext']}
          isSuccess={isSuccess}
          isLoading={isLoading}
          isError={isError}
          error={error}
        />
      </CrossSell>
    ),
  },
];

export function AddressWashContainer() {
  const { sendMarketingCheck, data, error } = useAddressWash();

  const submitHandler = ({
    pins,
    type,
  }: {
    pins: string;
    type: MarketingCheckType;
  }) => {
    const pinArray = pins
      .split('\n')
      .map((pin) => pin.trim())
      .filter((pin) => !!pin);

    sendMarketingCheck.mutate({ type, pins: pinArray });
  };

  const { isSuccess, isPending: isLoading, isError } = sendMarketingCheck;

  return (
    <Tabs
      tabs={tabMap}
      viewProps={{ isSuccess, isLoading, isError, error, data, submitHandler }}
    />
  );
}
