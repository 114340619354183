import { SvgIcon, SvgIconProps } from '@mui/material';

export const StickerIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 96 96" width="96px" height="96px">
      <g id="surface196173500">
        <path d="M 45.988281 8.273438 C 45.726562 8.253906 45.382812 8.261719 44.921875 8.320312 C 25.230469 10.816406 10 27.664062 10 48 C 10 70.054688 27.945312 88 50 88 C 70.335938 88 87.179688 72.769531 89.679688 53.082031 C 89.914062 51.246094 89.375 51.238281 89.203125 50.941406 C 89.03125 50.644531 88.875 50.425781 88.691406 50.183594 C 88.328125 49.699219 87.875 49.15625 87.308594 48.503906 C 86.175781 47.203125 84.605469 45.496094 82.714844 43.496094 C 78.929688 39.492188 73.878906 34.332031 68.773438 29.226562 C 63.667969 24.121094 58.511719 19.070312 54.507812 15.285156 C 52.507812 13.394531 50.796875 11.824219 49.496094 10.691406 C 48.84375 10.125 48.304688 9.675781 47.820312 9.3125 C 47.578125 9.128906 47.355469 8.96875 47.058594 8.796875 C 46.835938 8.667969 46.777344 8.335938 45.988281 8.273438 Z M 42.019531 15.035156 C 41.769531 25.59375 45.671875 36.242188 53.714844 44.285156 C 61.757812 52.328125 72.40625 56.230469 82.964844 55.980469 C 79.375 70.898438 66.050781 82 50 82 C 31.1875 82 16 66.8125 16 48 C 16 31.949219 27.101562 18.625 42.019531 15.035156 Z M 48.03125 17.484375 C 48.828125 18.21875 49.425781 18.738281 50.386719 19.644531 C 54.324219 23.367188 59.453125 28.390625 64.53125 33.46875 C 69.609375 38.546875 74.632812 43.679688 78.351562 47.617188 C 79.257812 48.578125 79.777344 49.171875 80.511719 49.96875 C 72.3125 49.617188 64.230469 46.316406 57.957031 40.042969 C 51.683594 33.769531 48.382812 25.683594 48.03125 17.484375 Z M 48.03125 17.484375 " />
      </g>
    </SvgIcon>
  );
};
