import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import mapValues from 'lodash/mapValues';

import { hasOwnProperty } from '@/utils/typeHelpers';

type Values = Record<string, unknown>;

export function prepareInitialValues(values: Values) {
  return mapValues(values, (value) => {
    if (isArray(value)) {
      return value;
    }

    if (isObject(value) && hasOwnProperty(value, '@id')) {
      return value['@id'];
    }

    if (typeof value === 'number') {
      return value.toString();
    }

    return value;
  });
}
