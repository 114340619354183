import { Card, CardContent, CardHeader, Divider } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FormApi } from 'final-form';
import { Form } from 'react-final-form';

import { Dropdown, TextInput } from '@/components/form';
import { useSnackbar } from '@/hooks';
import httpClient from '@/utils/httpClient';
import { composeValidators, fieldRequired } from '@/utils/validation';

import { Team } from '../../types';
import { MembersAutoComplete } from './MembersAutoComplete';
import { SaveButton } from './SaveButton';

type FormValues = {
  members: string[];
  manager: string;
  name: string;
};

type Props = {
  managerOptions?: { text: string; value: string }[];
  memberOptions?: { text: string; value: string }[];
  teamNames: string[];
};

export const CreateTeamCard = ({
  managerOptions,
  memberOptions,
  teamNames,
}: Props) => {
  const snackBar = useSnackbar();
  const queryClient = useQueryClient();

  const createTeamMutation = useMutation({
    mutationFn: async (payload: FormValues) =>
      await httpClient.post('/teams', payload),
    onSuccess: ({ data }) => {
      queryClient.setQueryData<Team[]>(['amsTeams'], (oldData) =>
        oldData!.concat(data),
      );
      snackBar({ type: 'success', message: 'Nytt team har skapats.' });
    },
    onError: () =>
      snackBar({
        type: 'error',
        message: 'Misslyckades med att skapa ett nytt team.',
      }),
  });

  const checkIfNameExists = (v: string) => {
    if (!v) {
      return undefined;
    }

    return teamNames.includes(v.toUpperCase())
      ? 'Namnet används redan'
      : undefined;
  };

  const submitHandler = (values: FormValues, form: FormApi<FormValues>) => {
    createTeamMutation.mutate(values);
    form.restart();
  };

  if (!managerOptions || !memberOptions) return null;

  return (
    <Card>
      <CardHeader title="Skapa ett team" />
      <Divider />
      <CardContent>
        <Form<FormValues>
          onSubmit={(values, form) => submitHandler(values, form)}
          initialValues={{ members: [] }}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <TextInput
                name="name"
                label="Namn"
                fieldProps={{
                  validate: composeValidators(fieldRequired, checkIfNameExists),
                }}
              />

              <Dropdown
                name="manager"
                label="Välj manager"
                options={managerOptions}
                fieldProps={{
                  validate: fieldRequired,
                }}
              />
              <MembersAutoComplete
                multiple
                name="members"
                label="Välj medlemmar"
                options={memberOptions}
              />
              <SaveButton />
            </form>
          )}
        />
      </CardContent>
    </Card>
  );
};
