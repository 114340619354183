import React from 'react';

import Delete from '@mui/icons-material/Delete';
import { Alert, IconButton, LinearProgress } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { deleteCustomLetterTemplate } from '@/api/customLetterTemplates';
import { SimpleTable, SimpleTableColumn } from '@/components/SimpleTable';
import { queryKeys } from '@/constants/queryKeys';
import { useSnackbar } from '@/hooks';
import { useGetCustomLetters } from '@/hooks/useGetCustomLetters';
import { GenericHttpError } from '@/types/errors';
import { CustomTemplate } from '@/types/files';

import { EditTemplateForm } from './EditTemplateForm';

export const UploadedTemplatesList = () => {
  const queryClient = useQueryClient();
  const snackbar = useSnackbar();
  const { data, isPending, isError } = useGetCustomLetters();

  const deleteMutation = useMutation({
    mutationFn: (id: number) => deleteCustomLetterTemplate(id),
    onSuccess: () => {
      snackbar({ message: 'Brevmallen har tagits bort', type: 'success' });
      return queryClient.invalidateQueries({
        queryKey: queryKeys.getCustomLetters.all,
      });
    },
    onError: (error: GenericHttpError) => {
      snackbar({
        message: `${error.status}: ${error.statusText}`,
        type: 'error',
      });
      return queryClient.invalidateQueries({
        queryKey: queryKeys.getCustomLetters.all,
      });
    },
  });

  const columns: SimpleTableColumn<CustomTemplate>[] = [
    {
      label: 'Namn',
      renderer: (row) => row.name,
    },
    {
      label: 'Redigera',
      align: 'left',
      renderer: (row) => <EditTemplateForm template={row} />,
    },
    {
      label: 'Ta bort',
      renderer: (row) => (
        <IconButton onClick={() => deleteMutation.mutate(row.id)}>
          <Delete />
        </IconButton>
      ),
    },
  ];

  return (
    <>
      {(isPending || deleteMutation.isPending) && <LinearProgress />}
      {isError && <Alert severity="error">Något gick fel</Alert>}
      <SimpleTable columns={columns} rows={data || []} />
    </>
  );
};
