import { isValid } from 'date-fns';
import { setIn } from 'final-form';
import uniq from 'lodash/uniq';
import * as Yup from 'yup';

import { Personnummer } from '@/helpers/validators/personnummer';
import { SelectFieldOptions } from '@/types/common';

type ValidatorFunction = (value: string) => string | undefined;
type Validators = Array<ValidatorFunction>;

export const _64BitMacAddressRegex =
  /^[0-9A-Fa-f]{2}([:-]?)(?:[0-9A-Fa-f]{2}\1){6}[0-9A-Fa-f]{2}$/g;

// TODO: Need to split into diff functions
export const composeValidators =
  (...validators: Validators) =>
  (value: string) =>
    validators.reduce<undefined | string>(
      (error, validator) => error || validator(value),
      undefined,
    );

export const fieldRequired = (value?: string) => {
  const message = 'Obligatoriskt fält';

  if (value === undefined || value === null) {
    return message;
  }

  if (typeof value === 'string' && value.trim() === '') {
    return message;
  }

  return undefined;
};

export const minMax = (value: string, min: number, max: number) => {
  if (!value) return undefined;

  if (min && value.length < min) {
    return `Fältet måste innehålla minst ${min} antal tecken`;
  }

  if (max && value.length > max) {
    return `Fältet får innehålla max ${max} antal tecken`;
  }

  return undefined;
};

export const fieldIsPersonalNumber = (value: string) => {
  if (!value) return undefined;

  if (Personnummer.valid(value)) {
    return undefined;
  }

  return 'Ogiltigt personnummer';
};

export const fieldIsOrgNumber = (value: string) => {
  if (!value) return undefined;

  const onlyDigitsVal = value.replace(/\D/g, '');

  if (isValidLuhn(onlyDigitsVal)) {
    return undefined;
  }

  return 'Ogiltigt organisationsnummer';
};

export const fieldIs64BitMacAddress = (value: string) => {
  if (!value) return undefined;

  // 64-bit MAC-address === 96:3e:c0:26:00:4b:12:00 || 963ec026004b1200 || 96-3e-c0-26-00-4b-12-00
  if (value.match(_64BitMacAddressRegex)) {
    return undefined;
  }

  return 'Ogiltig MAC-address';
};

/**
 * Check if last digit of number is vald Luhn control digit
 * @param pnr
 * @returns {boolean}
 */
export const isValidLuhn = (pnr: string) => {
  let number;
  let checksum = 0;
  for (let i = pnr.length - 1; i >= 0; i--) {
    number = parseInt(pnr.charAt(i));
    if (i % 2 === 1) {
      checksum += number;
    } else {
      checksum += number * 2 > 9 ? number * 2 - 9 : number * 2;
    }
  }

  return checksum % 10 === 0;
};

export const isValidDate = (dateString: string) => {
  if (!dateString) return undefined;

  if (isValid(new Date(dateString))) return undefined;

  return 'Ogiltigt datum';
};

Yup.setLocale({
  mixed: {
    required: 'Obligatoriskt fält',
  },
  string: {
    max: 'Max antal tecken är ${max}',
    email: 'Ange en giltig epost',
  },
});

/****** SVEA ******/
const validSveaChars =
  `!"#$%&'()*+,-./0123456789:;<=>?@ABCDEFGHIJKLMNOPQRSTUVWXYZ[]^ \`abcdefghijklmnopqrstuvwxyz{|}~ÀÁÄÅÆÈÉÊËÏÖØÙÚÛÜßàáäåæèéêëöøùúûü`.split(
    '',
  );

const replacementConfig = {
  ç: 'c',
  Ç: 'C',
  ó: 'o',
  á: 'a',
  Ó: 'O',
  Á: 'A',
  ã: 'a',
  Ã: 'A',
  í: 'i',
  Í: 'I',
  ñ: 'n',
  Ñ: 'N',
  ô: 'o',
  Ô: 'O',
  ê: 'e',
  Ê: 'E',
  ò: 'o',
  Ò: 'O',
  â: 'a',
  Â: 'A',
  ć: 'c',
  Ć: 'C',
  þ: 't',
  Þ: 'T',
  š: 's',
  Š: 'S',
};

const replacementRegex = new RegExp(
  Object.keys(replacementConfig).join('|'),
  'gi',
);
export function removeInvalidSveaChars(fieldValue: string) {
  return fieldValue.replace(replacementRegex, (c) => {
    return replacementConfig[c as keyof typeof replacementConfig];
  });
}

function getIvalidSveaChars(fieldValue: string) {
  return uniq(
    Array.from(fieldValue).filter((char) => !validSveaChars.includes(char)),
  );
}

export const isValidSvea = (fieldValue: string) => {
  const invalidChars = getIvalidSveaChars(fieldValue);
  if (invalidChars.length === 0) {
    return undefined;
  }

  return `Ogiltiga tecken: ${invalidChars.map((char) => char)}`;
};

// check examples of yup and final form for validation
export const makeValidation = (schema: any) => async (values: any) => {
  if (typeof schema === 'function') {
    schema = schema();
  }
  try {
    await schema.validate(values, { abortEarly: false });
  } catch (err: any) {
    return err.inner.reduce((formError: any, innerError: any) => {
      return setIn(formError, innerError.path, innerError.message);
    }, {});
  }
};

// #region isValidOption validation
export const getInvalidOptionMessage = (value?: string | number) =>
  `Ogiltigt värde${value ? ': ' + value : ''}`;

// Is the options an array of strings
export const isStringArray = (options: string[]): options is string[] =>
  Array.isArray(options) && typeof options[0] === 'string';

export type OptionsArray = SelectFieldOptions | string[];

// Is the options an array of SelectFieldOptions
export const isSelectFieldOptionsObjectArray = (
  options: OptionsArray,
): options is SelectFieldOptions =>
  typeof options[0] === 'object' && 'value' in options[0];

export const isValidOption =
  <T extends OptionsArray | undefined>(options: T, isFetching?: boolean) =>
  (value: string | number | undefined) => {
    if (!value || !options || isFetching) return undefined;
    const isOptionsAnArray = Array.isArray(options);

    // If the options are an empty array but value is not empty, return error message
    if (isOptionsAnArray && options.length === 0 && value) {
      return getInvalidOptionMessage(value);
    }

    if (isSelectFieldOptionsObjectArray(options)) {
      return options.some((option) => {
        const optionValueIsString = typeof option.value === 'string';
        if (optionValueIsString) {
          return option.value === value.toString();
        } else {
          return option.value === value;
        }
      })
        ? undefined
        : getInvalidOptionMessage(value);
    } else if (isStringArray(options)) {
      return options.includes(value.toString())
        ? undefined
        : getInvalidOptionMessage(value);
    }

    return undefined;
  };

// #endregion

export default Yup;
