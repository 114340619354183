import { addDays, format } from 'date-fns';
import omit from 'lodash/omit';

import { normalizeToNumbersOnly } from '@/components/form';
import { CustomerCategoryTypes } from '@/constants';
import { getFeatureFlagsFromLocalStorage } from '@/helpers/getFeatureFlagsFromLocalStorage';
import { getCamelCaseServiceName, getObjectKeys } from '@/helpers/tsHelpers';
import { Personnummer } from '@/helpers/validators/personnummer';
import { CreateHomegateFormValues } from '@/modules/services/components/Create/Homegate/CreateHomegateForm';
import {
  DATE_FIELDS,
  FIELDS_TO_REMOVE_BEFORE_CREATE_SERVICE,
  INITIAL_VALUES,
  SPREADSHEET_FIELDS_MAP,
} from '@/modules/services/components/Create/constants';
import {
  CamelCaseServiceName,
  CreateCoProtectionDTO,
  CreateFiretextDTO,
  CreateKeyTagDTO,
  CreateProtectionDTO,
  CreateSinneExcelValues,
  CreateStickerDTO,
  ServiceName,
} from '@/types/services';
import { FEATURE_FLAGS } from '@/utils/featureFlags';
import { hasOwnProperty } from '@/utils/typeHelpers';
import { isValidLuhn } from '@/utils/validation';

import { getFormattedDate } from './dates';
import { getElementFromArray } from './getElementFromArray';

const getFieldNames = (serviceName: CamelCaseServiceName) => {
  const featureFlags = getFeatureFlagsFromLocalStorage();

  const protectionCampaignV2Enabled = featureFlags.find(
    (flag) => flag.name === FEATURE_FLAGS.protectionCampaignV2.name,
  )?.enabled;

  const fieldNames = SPREADSHEET_FIELDS_MAP[serviceName];

  if (serviceName === 'protection' && protectionCampaignV2Enabled) {
    const fieldNameToReplace = 'campaign';
    const protectionCampaignV2fieldName = 'protectionsCampaign';
    const fieldNameToReplaceIndex = fieldNames.findIndex(
      (field) => field === fieldNameToReplace,
    );

    return fieldNames.toSpliced(
      fieldNameToReplaceIndex,
      1,
      protectionCampaignV2fieldName,
    );
  }

  return fieldNames;
};

export function parseExcelData(
  value: string,
  productName: CamelCaseServiceName,
) {
  const cellValues = value.split('\t');

  const fieldNames = getFieldNames(productName);

  return fieldNames.reduce(
    (acc: Record<string, string | number>, current: string, index: number) => {
      // Get only non-empty field values
      if (cellValues[index]) {
        let fieldValue = cellValues?.[index] || '';

        if (DATE_FIELDS.includes(current)) {
          fieldValue = format(
            new Date(getElementFromArray(cellValues[index])),
            'yyyy/MM/dd',
          );
        }

        return {
          ...acc,
          [current]: fieldValue,
        };
      }
      return acc;
    },
    INITIAL_VALUES,
  );
}

export const parseToNumbers = (value: string) =>
  isNaN(parseFloat(value)) ? '' : parseFloat(value);

export const extractNumbersFromString = (
  string: string,
  errorMessage?: string,
) => {
  if (typeof string !== 'string') {
    throw new Error(
      errorMessage ? errorMessage : 'Parameter must be of type string',
    );
  }

  const numbers = string.replace(/\D/g, '');
  return numbers;
};

export const formatSSN = (v: string) => {
  if (Personnummer.valid(v)) {
    const ssn = new Personnummer(v).format(true);
    return ssn;
  }
  return v;
};

export const formatOrgNr = (v: string) => {
  const onlyDigitsVal = v.replace(/\D/g, '');
  if (isValidLuhn(onlyDigitsVal)) {
    return onlyDigitsVal;
  }
  return v;
};

export const parsePin = (values: { category: number; pin: string }) => {
  const isOrgNr = values.category === CustomerCategoryTypes.COMPANY;
  const pin = values.pin;

  values.pin = isOrgNr ? formatOrgNr(pin) : formatSSN(pin);

  return values;
};

export const formatPinInput = (v: string, isOrgNr: boolean) => {
  if (isOrgNr) {
    return formatOrgNr(v);
  } else {
    return formatSSN(v);
  }
};

export const trimWhiteSpaceInObjectValues = <T extends Record<string, any>>(
  obj: T,
): T => {
  return Object.keys(obj).reduce((acc, key) => {
    if (typeof obj[key] === 'string') {
      acc[key as keyof T] = obj[key].trim();
    } else {
      acc[key as keyof T] = obj[key];
    }
    return acc;
  }, {} as T);
};

type TempValue = {
  bankName?: string;
  bankAccount?: string;
  autogiroSource?: number;
  autogiroAgreement?: number;
  campaign?: string;
} & Omit<
  CreateSinneExcelValues &
    CreateHomegateFormValues &
    CreateProtectionDTO &
    CreateFiretextDTO &
    CreateCoProtectionDTO &
    CreateKeyTagDTO &
    CreateStickerDTO,
  'status'
>;

export function prepareFieldValues(
  rawDataString: string,
  serviceName: ServiceName,
) {
  const camelCaseServiceName = getCamelCaseServiceName(serviceName);

  const rawData = parseExcelData(
    rawDataString,
    getCamelCaseServiceName(camelCaseServiceName),
  );

  if (camelCaseServiceName === 'sticker') {
    return rawData;
  }

  const tempValue: TempValue = getObjectKeys(rawData).reduce((acc, current) => {
    const fieldValue = rawData[current];

    if (current === 'reseller' && !!fieldValue) {
      return {
        ...acc,
        reseller: `/resellers/${fieldValue}`,
      };
    }

    if (current === 'partner' && !!fieldValue) {
      return {
        ...acc,
        partnerCompany: `/partner_companies/${fieldValue}`,
      };
    }

    if (
      current === 'companyName' &&
      typeof fieldValue === 'string' &&
      fieldValue.length > 2
    ) {
      return {
        ...acc,
        [current]: fieldValue,
        category: 2,
      };
    }

    if (
      camelCaseServiceName === 'firetext' &&
      current === 'contactType2' &&
      hasOwnProperty(acc, 'contact2')
    ) {
      return {
        ...acc,
        contact2: `${acc.contact2} (${fieldValue})`,
      };
    }

    if (
      camelCaseServiceName === 'firetext' &&
      current === 'contactType3' &&
      hasOwnProperty(acc, 'contact3')
    ) {
      return {
        ...acc,
        contact3: `${acc.contact3} (${fieldValue})`,
      };
    }

    if (
      camelCaseServiceName === 'firetext' &&
      current === 'contactType4' &&
      hasOwnProperty(acc, 'contact4')
    ) {
      return {
        ...acc,
        contact4: `${acc.contact4} (${fieldValue})`,
      };
    }

    if (
      camelCaseServiceName === 'firetext' &&
      current === 'contactType5' &&
      hasOwnProperty(acc, 'contact5')
    ) {
      return {
        ...acc,
        contact5: `${acc.contact5} (${fieldValue})`,
      };
    }
    if (
      ['protection', 'coProtection', 'sinne'].includes(camelCaseServiceName) &&
      current === 'accountManager' &&
      typeof rawData.accountManager === 'string' &&
      rawData.accountManager?.trim()
    ) {
      return {
        ...acc,
        accountManager: `/users/${rawData.accountManager}`,
      };
    }

    if (current === 'pin' && fieldValue) {
      return {
        ...acc,
        [current]: normalizeToNumbersOnly(fieldValue.toString()),
      };
    }

    if (
      typeof fieldValue === 'string' &&
      current === 'category' &&
      camelCaseServiceName === 'homegate'
    ) {
      return { ...acc, [current]: parseInt(fieldValue, 10) };
    }
    if (
      typeof fieldValue === 'string' &&
      current === 'kamId' &&
      camelCaseServiceName === 'homegate'
    ) {
      return { ...acc, [current]: parseInt(fieldValue, 10) };
    }
    if (
      typeof fieldValue === 'string' &&
      current === 'tb' &&
      (camelCaseServiceName === 'homegate' || camelCaseServiceName === 'sinne')
    ) {
      return { ...acc, [current]: parseInt(fieldValue, 10) };
    }

    if (current === 'partnerManager') {
      return { ...acc, [current]: `/users/${fieldValue}` };
    }

    if (
      current === 'campaign' &&
      !!fieldValue &&
      (camelCaseServiceName === 'homegate' || camelCaseServiceName === 'sinne')
    ) {
      return {
        ...acc,
        [current]: `/${camelCaseServiceName}_campaigns/` + fieldValue,
      };
    }

    if (
      (camelCaseServiceName === 'homegate' ||
        camelCaseServiceName === 'sinne') &&
      current === 'partnerCompany' &&
      !!fieldValue
    ) {
      return {
        ...acc,
        [current]: '/partner_companies/' + fieldValue,
      };
    }

    // Calculate churn date based on regDate and autoChurnPeriod
    if (current === 'autoChurnPeriod') {
      if (!rawData.regDate || !fieldValue) {
        return {
          ...acc,
        };
      }

      const regDate = new Date(rawData.regDate as string);
      const churnDate = addDays(regDate, parseInt(fieldValue as string, 10));

      return {
        ...acc,
        ['churnDate']: getFormattedDate(churnDate, 'yyyy/MM/dd'),
      };
    }

    return {
      ...acc,
      [current]: fieldValue,
    };
  }, {});

  if (
    camelCaseServiceName !== 'sinne' &&
    !!tempValue.bankName &&
    !!tempValue.bankAccount &&
    tempValue.bankName.length > 2 &&
    tempValue.bankAccount.length > 2
  ) {
    tempValue.autogiroSource = 2;
    tempValue.autogiroAgreement = 1;
  }

  return omit(tempValue, FIELDS_TO_REMOVE_BEFORE_CREATE_SERVICE);
}
