import { zodResolver } from '@hookform/resolvers/zod';
import { Button, InputAdornment, LinearProgress } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { useAuth } from '@/hooks/useAuth';
import { useSnackbar } from '@/hooks/useSnackbar';
import { PasswordIcon, UserIcon } from '@/icons';
import { Textfield } from '@/modules/components';

import { useSignIn } from '../hooks';
import { Box } from './Box';

const validationSchema = z.object({
  username: z.string().min(1, 'Fältet får inte vara tomt').trim(),
  password: z.string().min(1, 'Fältet får inte vara tomt').trim(),
});

type Values = z.infer<typeof validationSchema>;

export const SignInForm = () => {
  const snackbar = useSnackbar();
  const signInMutation = useSignIn();
  const navigate = useNavigate();
  const { onLoginSuccess } = useAuth();

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
    setError,
  } = useForm<Values>({
    defaultValues: {
      username: '',
      password: '',
    },
    resolver: zodResolver(validationSchema),
  });

  const onSubmit: SubmitHandler<Values> = (values) =>
    signInMutation.mutate(values, {
      onSuccess: async (token) => {
        await onLoginSuccess(token);
        navigate('/dashboard');
      },
      onError: () => {
        snackbar({ type: 'error', message: 'Ogiltiga uppgifter' });
        setError(
          'username',
          { message: 'Ogiltiga uppgifter' },
          { shouldFocus: true },
        );
        setError('password', { message: 'Ogiltiga uppgifter' });
      },
    });

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Textfield
        label="Användarnamn"
        error={errors.username}
        fullWidth
        inputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <UserIcon />
            </InputAdornment>
          ),
          ...register('username'),
          'data-testid': 'signin-username',
        }}
      />
      <Textfield
        label="Lösenord"
        error={errors.password}
        fullWidth
        inputProps={{
          type: 'password',
          startAdornment: (
            <InputAdornment position="start">
              <PasswordIcon />
            </InputAdornment>
          ),
          ...register('password'),
          'data-testid': 'signin-password',
        }}
      />

      {signInMutation.isPending && <LinearProgress />}
      <Button
        sx={{ marginTop: 1, marginBottom: 1 }}
        color="primary"
        disabled={!isValid || signInMutation.isPending}
        size="large"
        type="submit"
        variant="contained"
        fullWidth
      >
        Logga in
      </Button>
      <Link to="/forgot-password">Glömt lösenord?</Link>
    </Box>
  );
};
