import { Grid, LinearProgress, Typography } from '@mui/material';

type Props = {
  pin: string;
};

export const Loading = ({ pin }: Props) => {
  return (
    <Grid item xs={12}>
      <Typography mb={2} variant="h2">
        Kontrollerar spärrstatus på {pin}
      </Typography>
      <LinearProgress />
    </Grid>
  );
};
