import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  List,
  ListItem,
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import findKey from 'lodash/findKey';
import { Form } from 'react-final-form';

import { updateUser } from '@/api/users';
import { Checkboxes } from '@/components/form';
import { queryKeys } from '@/constants/queryKeys';
import { RoleTypes, displayAvailableRoles } from '@/constants/roleTypes';
import { getObjectKeys } from '@/helpers/tsHelpers';
import { useSnackbar } from '@/hooks';
import useUserRoles from '@/hooks/useUserRoles';
import { UserRole } from '@/types/auth';
import { UserItem } from '@/types/users';

import { checkIfSingleRoleShouldBeDisabled } from '../utils/userPermissions';

type Props = {
  user: UserItem;
  isReadOnly: boolean;
};

export const UserPermissions = ({ user, isReadOnly }: Props) => {
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();
  const userRoles = user?.roles;
  const authUserRoles = useUserRoles();

  const initialValues = userRoles.reduce(
    (acc: Record<string, boolean>, cVal: UserRole) => {
      acc[cVal] = true;

      return acc;
    },
    {},
  );

  const permissionMutation = useMutation({
    mutationFn: updateUser,
    onSuccess: (response) => {
      snackbar({ type: 'success', message: 'Rättigheter uppdaterade!' });

      queryClient.setQueryData([queryKeys.getUserById, user.id], response);
    },
    onError: () => {
      snackbar({
        type: 'error',
        message: 'Misslyckades uppdatera rättigheter.',
      });
    },
  });

  const onSubmit = (values: Record<UserRole, boolean>) => {
    const roles: UserRole[] = getObjectKeys(values).filter(
      (role) => values[role] && role,
    );

    permissionMutation.mutate({ userId: user.id, fields: { roles } });
  };

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit }) => (
        <Grid item md={6} xs={12}>
          <Card component="form" onSubmit={handleSubmit}>
            <CardHeader title="Rättigheter" />
            <Divider />
            <CardContent>
              <List disablePadding>
                {displayAvailableRoles().map((role) => (
                  <ListItem key={role} disablePadding>
                    <Checkboxes
                      formControlProps={{
                        margin: 'none',
                      }}
                      disabled={
                        isReadOnly ||
                        checkIfSingleRoleShouldBeDisabled(authUserRoles!, role)
                      }
                      options={{
                        label: role,
                        value: 'true',
                      }}
                      name={findKey(RoleTypes, (key) => key === role) ?? ''}
                    />
                  </ListItem>
                ))}
              </List>
            </CardContent>
            <CardActions>
              {!isReadOnly && (
                <Button color="primary" type="submit" variant="outlined">
                  Spara ändringar
                </Button>
              )}
            </CardActions>
          </Card>
        </Grid>
      )}
    </Form>
  );
};
