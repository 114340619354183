import Grid from '@mui/material/Grid';

import { PageWrapper } from '@/components';
import { TemplateParamsList } from '@/components/TemplateParamsList';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { CustomTemplatesCard } from '@/modules/fileTemplate/components/CustomTemplates/CustomTemplatesCard';
import { PredefinedTemplatesCard } from '@/modules/fileTemplate/components/PredefinedTemplates/PredefinedTemplatesCard';
import { FEATURE_FLAGS } from '@/utils/featureFlags';

const TemplateFileUpload = () => {
  const customLettersFeatureEnabled = useFeatureFlag({
    name: FEATURE_FLAGS.customLetters.name,
  });

  return (
    <PageWrapper
      pageTitle="Filmall"
      breadCrumbs={[{ label: 'Verktyg' }, { label: 'Filuppladdning' }]}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <PredefinedTemplatesCard />
        </Grid>
        {customLettersFeatureEnabled && (
          <Grid item xs={12} md={6} data-testid="custom-templates-card">
            <CustomTemplatesCard />
          </Grid>
        )}
        <Grid item xs={12} md={4}>
          <TemplateParamsList />
        </Grid>
      </Grid>
    </PageWrapper>
  );
};

export default TemplateFileUpload;
