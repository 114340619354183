import { formatISO, startOfDay } from 'date-fns';
import zodToJsonSchema from 'zod-to-json-schema';

import { createApiPropertiesFromJsonSchema } from '@/helpers/createApiPropertiesFromJsonSchema';
import {
  ApiTaskTeamNames,
  TaskTeamTypes,
} from '@/modules/tasks/constants/tasksModalsConstants';
import { TaskItem, TasksResponse, getTasksParamSchema } from '@/types/tasks';
import httpClient from '@/utils/httpClient';

export async function getTaskById(id: number) {
  const { data } = await httpClient.get(`/tasks/${id}`);

  return data;
}

export type GetTasksParams = {
  type: string;
  status: string | string[];
};

export async function getCustomerTasks(
  customerId: number,
  team?: keyof typeof ApiTaskTeamNames,
) {
  const properties = createApiPropertiesFromJsonSchema(
    zodToJsonSchema(getTasksParamSchema),
  );

  const { data } = await httpClient.get<TasksResponse>('/tasks', {
    params: {
      pagination: false,
      customer: customerId,
      ...(team && { team: TaskTeamTypes[team] }),
      properties,
    },
  });

  return data;
}

export async function getAdministratorTasks(customerId: number) {
  return getCustomerTasks(customerId, 'ADMINISTRATOR');
}

export async function getCustomerSupportTasks(customerId: number) {
  return getCustomerTasks(customerId, 'CUSTOMER_SUPPORT');
}

export type CreateTaskPayload = {
  status: number;
  type: string;
  customerId?: number;
  deadline: Date | null;
  text: string;
  team: number;
  claimedBy?: string;
};
export async function createTask({
  status,
  type,
  customerId,
  deadline,
  text,
  team,
  claimedBy,
}: CreateTaskPayload) {
  const { data } = await httpClient.post<TaskItem>(`/tasks`, {
    status: status.toString(),
    type: type,
    customer: customerId ? `/customers/${customerId}` : null,
    deadline,
    text,
    team,
    claimedBy,
  });

  return data;
}

export type UpdateTaskPayload = {
  id: number;
  status: string;
  type: string | number;
  deadline: string;
  text: string;
  claimedBy: string;
};
export async function updateTask({
  id,
  status,
  type,
  deadline,
  text,
  claimedBy,
}: UpdateTaskPayload) {
  const { data } = await httpClient.put<TaskItem>(`/tasks/${id}`, {
    status: status.toString(),
    type: type.toString(),
    deadline: deadline ?? null,
    text,
    ...(claimedBy ? { claimedBy } : {}),
  });

  return data;
}

export async function deleteTask(taskId: number) {
  return await httpClient.delete(`/tasks/${taskId}`);
}

export type GetTasksWithPassedDeadlineParams = {
  type: string;
  status: string | string[];
  team?: number;
};

export async function getTasksWithPassedDeadline({
  type,
  status = [],
  team = TaskTeamTypes.CUSTOMER_SUPPORT,
}: GetTasksWithPassedDeadlineParams) {
  const before = formatISO(startOfDay(new Date()));

  const { data } = await httpClient.get<TasksResponse>('/tasks', {
    params: {
      type,
      status,
      team: [team],
      'deadline[before]': before,
    },
  });

  return data;
}
