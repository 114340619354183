import { useCallback } from 'react';

import { Box, Grid } from '@mui/material';
import { ValidationError } from 'yup';

import { EmailInput, PhoneInput, PinInput, TextInput } from '@/components/form';
import { getElementFromArray } from '@/helpers/getElementFromArray';
import { ProtectionFormValues } from '@/modules/services';

type FilteredEmailKeys = 'email1' | 'email2' | 'email3' | 'email4' | 'email5';
type FilteredPhoneKeys = 'phone1' | 'phone2' | 'phone3' | 'phone4' | 'phone5';
type FilteredPinKeys = 'pin1' | 'pin2' | 'pin3' | 'pin4' | 'pin5';

export const ProtectionListSection = () => {
  const emailIsUnique = useCallback(
    (value: string, allValues: ProtectionFormValues) => {
      const emailsDictionary = Object.keys(allValues)
        // Get all field names that starts with 'email'
        .filter(
          (key): key is FilteredEmailKeys =>
            key.startsWith('email') && key !== 'email',
        )
        // Get their values
        .map((field) => allValues[field])
        // Group by email and calculate count of occurances
        .reduce((emailsMap, email) => {
          if (!email) return emailsMap;
          if (!emailsMap[email]) emailsMap[email] = 0;
          emailsMap[email]++;
          return emailsMap;
        }, {} as Record<string, number>);

      const duplicateEmails = Object.keys(emailsDictionary).filter(
        (email) => getElementFromArray(emailsDictionary[email]) > 1,
      );

      if (duplicateEmails.includes(value)) {
        const error = new ValidationError(
          'E-post används redan i ett annat fält',
        );
        throw error;
      }
    },
    [],
  );

  const phoneIsUnique = useCallback(
    (value: string, allValues: ProtectionFormValues) => {
      const phoneDictionary = Object.keys(allValues)
        .filter(
          (key): key is FilteredPhoneKeys =>
            key.startsWith('phone') && key !== 'phone',
        )
        .map((field) => allValues[field])
        .reduce((acc, phoneNumber) => {
          if (!phoneNumber) return acc;
          if (!acc[phoneNumber]) acc[phoneNumber] = 0;
          acc[phoneNumber]++;
          return acc;
        }, {} as Record<string, number>);

      const duplicatePhoneNumbers = Object.keys(phoneDictionary).filter(
        (phoneNumber) => getElementFromArray(phoneDictionary[phoneNumber]) > 1,
      );
      if (duplicatePhoneNumbers.includes(value)) {
        const error = new ValidationError(
          'Numret används redan i ett annat fält',
        );
        throw error;
      }
    },
    [],
  );

  const pinIsUnique = useCallback(
    (value: string, allValues: ProtectionFormValues) => {
      const pinDictionary = Object.keys(allValues)
        .filter(
          (key): key is FilteredPinKeys =>
            key.startsWith('pin') && key !== 'pin',
        )
        .map((field) => allValues[field])
        .reduce((acc, pin) => {
          if (!pin) return acc;
          if (!acc[pin]) acc[pin] = 0;
          acc[pin]++;
          return acc;
        }, {} as Record<string, number>);

      const duplicatePins = Object.keys(pinDictionary).filter(
        (pin) => getElementFromArray(pinDictionary[pin]) > 1,
      );
      if (duplicatePins.includes(value)) {
        const error = new ValidationError(
          'Personnummret används redan i ett annat fält',
        );
        throw error;
      }
    },
    [],
  );

  return (
    <Box marginTop={-2}>
      {[...Array(5).keys()].map((_, index) => (
        <Grid container spacing={2} sx={{ marginTop: 2 }} key={index}>
          <Grid item xs={12} sm>
            <TextInput
              margin="none"
              label={`Namn ${index + 1}`}
              name={`name${index + 1}`}
            />
          </Grid>
          <Grid item xs={12} sm>
            <PinInput
              required={false}
              margin="none"
              label={`Personnummer ${index + 1}`}
              name={`pin${index + 1}`}
              customValidator={pinIsUnique}
            />
          </Grid>

          <Grid item xs={12} sm>
            <PhoneInput
              margin="none"
              required={false}
              label={`Larm till mobil ${index + 1}`}
              name={`phone${index + 1}`}
              customValidator={phoneIsUnique}
            />
          </Grid>

          <Grid item xs={12} sm>
            <EmailInput
              margin="none"
              required={false}
              label={`Larm till e-post ${index + 1}`}
              name={`email${index + 1}`}
              customValidator={emailIsUnique}
            />
          </Grid>
        </Grid>
      ))}
    </Box>
  );
};
