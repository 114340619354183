import { AllFeesResponse, Fee } from '@/types/fees';
import httpClient from '@/utils/httpClient';

export type GetFeesParams = {
  customerId: number;
  billedAtExists?: boolean;
};

export async function getFees({ customerId, billedAtExists }: GetFeesParams) {
  const data = httpClient.get<AllFeesResponse>('/fees', {
    params: {
      'order[invoiceAt]': 'desc',
      'customer.id': customerId,
      'exists[billedAt]': billedAtExists ? 'true' : 'false',
    },
  });

  return data;
}

export async function deleteFee(feeId: number) {
  return httpClient.delete(`fees/${feeId}`);
}

export type AddFeePayload = {
  title: string;
  description: string;
  price: string;
  count: number;
  invoiceAt: string;
  billedAt?: string;
  customer: string;
};

export async function addFee({
  title,
  description,
  price,
  invoiceAt,
  customer,
  count,
}: AddFeePayload) {
  const { data } = await httpClient.post<Fee>('/fees', {
    title,
    description,
    price,
    invoiceAt,
    customer,
    count,
  });

  return data;
}
