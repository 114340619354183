import React from 'react';

import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import copy from 'copy-to-clipboard';

import { useSnackbar } from '@/hooks';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(0.5),
  cursor: 'pointer',
  borderRadius: theme.spacing(0.5),
  backgroundColor: 'initial',
  '&:hover': {
    backgroundColor: theme.palette.divider,
    '& > *': {
      color: 'black',
    },
  },
  '&:not(:hover)': {
    '& p': {
      display: 'none',
    },
  },
}));

const StyledIcon = styled(FileCopyOutlinedIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

type Props = {
  value: string;
  onlyIcon?: boolean;
};

export const CopyButton = ({ value, onlyIcon = false }: Props) => {
  const snackbar = useSnackbar();

  const onCopyToClipboard = () => {
    copy(value, { format: 'text/plain' });
    snackbar({ type: 'success', message: 'Kopierad till urklipp!' });
  };

  return (
    <Container>
      {!onlyIcon && <Typography>COPY&nbsp;&nbsp;</Typography>}
      <StyledIcon fontSize="small" onClick={onCopyToClipboard} />
    </Container>
  );
};
