import { useState } from 'react';

import { Card, DialogContent, Divider, Grid } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import { TabPanel } from '@/components/TabPanel';
import { getObjectKeys } from '@/helpers/tsHelpers';
import { usePrivateSettings } from '@/hooks';
import usePublicSettings from '@/hooks/usePublicSettings';
import { SelectFieldOptions } from '@/types/common';
import {
  CoProtectionParamSchema,
  ProtectionParamSchema,
} from '@/types/services';

import { BarringServiceCustomer, CustomerProps } from '../../types';
import {
  FifthTab,
  FirstCoTab,
  FirstTab,
  FourthTab,
  SecondCoTab,
  SecondTab,
  SeventhTab,
  SixthTab,
  ThirdCoTab,
  ThirdTab,
} from './components';

const ProtectionChecklistTabTypes = {
  MY_PAGES_EVENTS: 0,
  BLOCK_CARD_SUBSCRIPTION: 1,
  BLOCK_PHONE: 2,
  ID_HIJACKING: 3,
  ID_HIJACKING_LOSS_OF_DOCUMENTS: 4,
  LOSS_OF_DOCUMENTS: 5,
  CANCELLATION_FRAUD_PREVENTION: 6,
};

const CoProtectionChecklistTabTypes = {
  MY_PAGES_EVENTS: 0,
  COMPANY_CREATION: 1,
  CREDIT_RATING_FAQ: 2,
};

type Props = {
  customer: BarringServiceCustomer;
  service: ProtectionParamSchema | CoProtectionParamSchema;
};

export const ChecklistTable = ({ customer, service }: Props) => {
  const [protectionActiveTab, setProtectionActiveTab] = useState(
    ProtectionChecklistTabTypes.MY_PAGES_EVENTS,
  );

  const [coProtectionActiveTab, setCoProtectionActiveTab] = useState(
    CoProtectionChecklistTabTypes.MY_PAGES_EVENTS,
  );

  const { phoneNumbers, serviceHours, emailAddresses } = usePrivateSettings();
  const { publicSettings } = usePublicSettings();

  const customerEmailAddresses = getObjectKeys(service)
    .filter((key) => key.match(/email/))
    .reduce((arr, key, i) => {
      if (service[key]) {
        arr.push({
          text: service[key] as string,
          value: service[key] as string,
        });
      }
      return arr;
    }, [] as SelectFieldOptions);

  const componentProps: CustomerProps = {
    customer,
    companyName: publicSettings?.companyNames?.standard || '',
    phoneNumbers,
    serviceHours,
    emailAddresses,
    customerEmailAddresses,
  };

  const handleProtectionTabChange = (_: any, tab: number) => {
    setProtectionActiveTab(tab);
  };

  const handleCoProtectionTabChange = (_: any, tab: number) => {
    setCoProtectionActiveTab(tab);
  };

  return (
    <Grid item xs={12}>
      <Card>
        <Divider />
        <DialogContent>
          {service['@type'] === 'CoProtection' ? (
            <>
              <Tabs
                aria-label="security service checklist tabs"
                indicatorColor="primary"
                onChange={handleCoProtectionTabChange}
                textColor="primary"
                value={coProtectionActiveTab}
              >
                <Tab label="Mina Sidor - Händelser" />
                <Tab label="Bolagskapning" />
                <Tab label="Kreditbetyg (FAQ)" />
              </Tabs>
              <TabPanel
                index={CoProtectionChecklistTabTypes.MY_PAGES_EVENTS}
                value={coProtectionActiveTab}
              >
                <FirstCoTab />
              </TabPanel>
              <TabPanel
                index={CoProtectionChecklistTabTypes.COMPANY_CREATION}
                value={coProtectionActiveTab}
              >
                <SecondCoTab {...componentProps} />
              </TabPanel>
              <TabPanel
                index={CoProtectionChecklistTabTypes.CREDIT_RATING_FAQ}
                value={coProtectionActiveTab}
              >
                <ThirdCoTab />
              </TabPanel>
            </>
          ) : (
            <>
              <Tabs
                aria-label="security service checklist tabs"
                indicatorColor="primary"
                onChange={handleProtectionTabChange}
                textColor="primary"
                variant="scrollable"
                scrollButtons
                value={protectionActiveTab}
                sx={{
                  '& .MuiTabs-scrollButtons.Mui-disabled': {
                    opacity: 0.2,
                  },
                }}
              >
                <Tab label="Mina Sidor - Händelser" />
                <Tab label="Spärra - Kort/Abonnemang" />
                <Tab label="Spärra - Telefon" />
                <Tab label="ID-Kapning" />
                <Tab label="ID-Kapning & Förlust av handlingar" />
                <Tab label="Förlust av handlingar" />
                <Tab label="Hävning - Bedrägerispärr" />
              </Tabs>

              <TabPanel
                index={ProtectionChecklistTabTypes.MY_PAGES_EVENTS}
                value={protectionActiveTab}
              >
                <FirstTab />
              </TabPanel>
              <TabPanel
                index={ProtectionChecklistTabTypes.BLOCK_CARD_SUBSCRIPTION}
                value={protectionActiveTab}
              >
                <SecondTab {...componentProps} />
              </TabPanel>
              <TabPanel
                index={ProtectionChecklistTabTypes.BLOCK_PHONE}
                value={protectionActiveTab}
              >
                <ThirdTab {...componentProps} />
              </TabPanel>
              <TabPanel
                index={ProtectionChecklistTabTypes.ID_HIJACKING}
                value={protectionActiveTab}
              >
                <FourthTab {...componentProps} />
              </TabPanel>
              <TabPanel
                index={
                  ProtectionChecklistTabTypes.ID_HIJACKING_LOSS_OF_DOCUMENTS
                }
                value={protectionActiveTab}
              >
                <FifthTab {...componentProps} />
              </TabPanel>
              <TabPanel
                index={ProtectionChecklistTabTypes.LOSS_OF_DOCUMENTS}
                value={protectionActiveTab}
              >
                <SixthTab {...componentProps} />
              </TabPanel>
              <TabPanel
                index={
                  ProtectionChecklistTabTypes.CANCELLATION_FRAUD_PREVENTION
                }
                value={protectionActiveTab}
              >
                <SeventhTab />
              </TabPanel>
            </>
          )}
        </DialogContent>
      </Card>
    </Grid>
  );
};
