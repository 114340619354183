import React, { useEffect, useState } from 'react';

import { Grid, Typography, useTheme } from '@mui/material';
import { isBefore, isSameDay, parseISO } from 'date-fns';
import { ErrorBoundary } from 'react-error-boundary';

import { DataTable } from '@/components/DataTable';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { TASK_STATUSES } from '@/constants';
import { queryKeys } from '@/constants/queryKeys';
import { getFormattedDate } from '@/helpers/dates';
import { usePrivateSettings } from '@/hooks';
import { EditTaskModal } from '@/modules/tasks';
import {
  ApiTaskTeamNames,
  TaskTeamTypes,
} from '@/modules/tasks/constants/tasksModalsConstants';
import { TaskItem } from '@/types/tasks';

import { useTasks } from '../../hooks/useTasks';
import { FilterValues } from '../../types/filters';
import { TaskTeamType } from '../../types/team';
import { ClaimedBy } from '../ClaimedBy';

type Props = {
  filter: FilterValues;
  isWinback?: boolean;
  team: TaskTeamType;
};

export const TasksList = ({ filter, isWinback = false, team }: Props) => {
  const { servicesTypes } = usePrivateSettings();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [taskToEditId, setTaskToEditId] = useState<string | null>(null);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(100);
  const [sortConfig, setSortConfig] = useState<{
    key: string | null;
    direction: 'asc' | 'desc' | null;
  }>({
    key: 'deadline',
    direction: 'asc',
  });

  const { tasks, total, loading, error } = useTasks({
    filter,
    isWinback,
    page,
    perPage,
    team,
    sortKey: sortConfig.key,
    sortDirection: sortConfig.direction,
  });

  const taskToEdit = tasks?.find((task) => task['@id'] === taskToEditId);

  const handlePageChange = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    setPerPage(rowsPerPage);
  };

  useEffect(() => {
    if (total % perPage !== 0) setPage(0);
  }, [total, perPage]);

  const handleRowClick = (task: TaskItem) => {
    setTaskToEditId(task['@id']);
    setOpen(true);
  };

  const handleSort = (key: string) => {
    if (key === 'customer.fullName') {
      key = 'customer.firstName';
    }

    if (key === 'claimedBy') {
      key = 'claimedBy.name';
    }

    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
    setPage(0); // Reset to first page on sort
  };

  const showWarning = (row: TaskItem) => {
    const today = new Date();
    const deadline = parseISO(row.deadline!);
    const isSameOrBefore =
      isBefore(deadline, today) || isSameDay(deadline, today);
    const closed = row.status === '1';
    return closed ? false : isSameOrBefore;
  };

  const processedTasks = tasks?.map((task) => ({
    ...task,
    rowProps: {
      style: {
        backgroundColor: showWarning(task)
          ? theme.palette.mode === 'dark'
            ? '#5d1f1f' // Dark muted red for dark mode
            : '#ffcccb' // Soft red for light mode
          : 'inherit',
        color: showWarning(task)
          ? theme.palette.mode === 'dark'
            ? '#ff8a80' // Soft text color for dark mode
            : '#b71c1c' // Strong text color for light mode
          : 'inherit',
      },
    },
  }));

  const filteredTasks = processedTasks?.filter((task) => {
    return (
      task.team === ApiTaskTeamNames.CUSTOMER_SUPPORT ||
      task.team === ApiTaskTeamNames.ADMINISTRATOR
    );
  });

  const columns = [
    {
      label: 'Deadline',
      key: 'deadline',
      renderer: (row: TaskItem) =>
        row.deadline ? (
          <Grid container spacing={2} alignItems="center">
            <Grid item>{getFormattedDate(row.deadline, 'yyyy-MM-dd')}</Grid>
          </Grid>
        ) : null,
    },
    {
      label: 'Skapad',
      key: 'createdAt',
      renderer: (row: TaskItem) =>
        row.createdAt ? getFormattedDate(row.createdAt, 'yyyy-MM-dd') : null,
    },
    {
      label: 'Uppdaterad',
      key: 'updatedAt',
      renderer: (row: TaskItem) =>
        row.updatedAt ? getFormattedDate(row.updatedAt, 'yyyy-MM-dd') : null,
    },
    {
      label: 'Kundnummer',
      key: 'customer.id',
      renderer: (row: TaskItem) => row.customer?.id,
    },
    {
      label: 'Namn',
      key: 'customer.fullName',
      renderer: (row: TaskItem) =>
        row.customer?.companyCategory
          ? row.customer?.companyName
          : row.customer?.fullName,
    },
    {
      label: 'Status',
      key: 'status',
      renderer: (row: TaskItem) =>
        TASK_STATUSES[row.status as keyof typeof TASK_STATUSES],
    },
    {
      label: 'Kategori',
      key: 'type',
      renderer: (row: TaskItem) => {
        const key = Number(row.type);
        let teamType;
        if (row.team === ApiTaskTeamNames.CUSTOMER_SUPPORT) {
          teamType = TaskTeamTypes.CUSTOMER_SUPPORT;
        } else if (row.team === ApiTaskTeamNames.ADMINISTRATOR) {
          teamType = TaskTeamTypes.ADMINISTRATOR;
        } else {
          return '';
        }
        return servicesTypes.task[teamType][key];
      },
    },
    {
      label: 'Hanteras av',
      key: 'claimedBy',
      renderer: (row: TaskItem) => (
        <ErrorBoundary
          fallback={
            <Typography fontWeight={500} fontSize={12}>
              Fel: Felaktig serverdata
            </Typography>
          }
        >
          <ClaimedBy
            claimedBy={row.claimedBy!}
            taskIri={row['@id'] || ''}
            queryKey={[
              queryKeys.getTasks,
              filter,
              isWinback,
              page,
              perPage,
              team,
              sortConfig.key,
              sortConfig.direction,
            ]}
          />
        </ErrorBoundary>
      ),
    },
  ];

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <Typography variant="h1">Misslyckades hämta tasks.</Typography>;
  }

  return (
    <>
      <DataTable
        columns={columns}
        data={filteredTasks || []}
        total={total}
        page={page}
        rowsPerPage={perPage}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        onSort={handleSort}
        sortConfig={sortConfig}
        onRowClick={handleRowClick}
      />
      {taskToEdit && (
        <EditTaskModal
          open={open}
          task={taskToEdit}
          fromTasksList
          customer={taskToEdit.customer}
          handleVisibility={() => setOpen(!open)}
          queryKey={[
            queryKeys.getTasks,
            filter,
            isWinback,
            page,
            perPage,
            team,
          ]}
        />
      )}
    </>
  );
};
