const customPalette = {
  error: {
    main: '#CF1F1F',
  },
  warning: {
    main: '#FFB800',
    deadline: '#EBCCD1',
  },
  button: {
    send: {
      default: '#1F53CC',
      hover: '#2A4868',
    },
    saved: {
      default: '#36AF7B',
      hover: '#319A6C',
    },
    lost: {
      default: '#E0175B',
      hover: '#B9164B',
    },
  },
  notes: {
    3: {
      header: '#eab12a',
      content: '#fbefd4',
      type: 'regret',
    },
    6: {
      header: '#e0175b',
      content: '#f9d0de',
      type: 'cancel',
    },
    11: {
      header: '#36af7b',
      content: '#d6efe4',
      type: 'winBack',
    },
    12: {
      header: '#1f53cc',
      content: '#d2dcf5',
      type: 'agreement',
    },
    13: {
      header: '#36af7b',
      content: '#d6efe4',
      type: 'turn',
    },

    14: {
      header: '#6081c0',
      content: '#dfe6f3',
      type: 'barringService',
    },
    19: {
      header: '#000000',
      content: '#ccc',
      type: 'termination',
    },
    21: { header: '#eab12a', content: '#fbefd4', type: 'toAdmin' },
    24: {
      header: '#f6efe8',
      content: '#fdfcfb',
      type: 'billing',
    },
  },
  success: {
    main: '#319A6C',
  },
} as const;

export default customPalette;
