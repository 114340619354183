import React from 'react';

import { ZodSchema } from 'zod';

type FieldErrors = {
  [x: string]: string[] | undefined;
  [x: number]: string[] | undefined;
};
export const useGetZodErrors = (data: any, schema: ZodSchema) => {
  const [errors, setErrors] = React.useState<FieldErrors>();

  React.useEffect(() => {
    const parseErrors = async () => {
      const result = await schema.safeParseAsync(data);
      if (result.success) {
        setErrors(undefined);
      } else {
        const errorMessages = result.error.flatten().fieldErrors;
        setErrors(errorMessages);
      }
    };
    if (data) {
      parseErrors();
    }
  }, [data]);

  return errors;
};
