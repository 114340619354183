import { useMutation } from '@tanstack/react-query';

import { createReseller } from '@/api';
import { GenericHttpError } from '@/types/errors';
import { CreateResellerParams, ResellerItem } from '@/types/resellers';

export const useCreateReseller = () => {
  return useMutation<ResellerItem, GenericHttpError, CreateResellerParams>({
    mutationFn: (values) => {
      const { businessDevelopmentManager, ...rest } = values;

      const payload = {
        ...rest,
        ...(businessDevelopmentManager !== '' && {
          businessDevelopmentManager: businessDevelopmentManager,
        }),
      };

      return createReseller(payload);
    },
  });
};
