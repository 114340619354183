import { ArrowForwardOutlined, LaunchOutlined } from '@mui/icons-material';
import { Box, Link as NativeLink, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';

import { getFormattedDate } from '@/helpers/dates';
import { RmaCase } from '@/types/rma';

export const rmaTableColumns = [
  {
    label: 'Ärendenummer',
    renderer: (row: RmaCase) => row.id,
  },
  {
    label: 'Kundnummer',
    renderer: (row: RmaCase) => row.endCustomerId,
  },
  {
    label: 'Mac Address',
    renderer: (row: RmaCase) => row.macAddress,
  },
  {
    label: 'Status',
    renderer: (row: RmaCase) => row.status,
  },
  {
    label: 'Skapad',
    renderer: (row: RmaCase) => getFormattedDate(row.createdAt, 'yyyy-MM-dd'),
  },
  {
    label: 'Uppdaterad',
    renderer: (row: RmaCase) => getFormattedDate(row.updatedAt, 'yyyy-MM-dd'),
  },
  {
    label: '',
    renderer: (row: RmaCase) => (
      <Box display="flex" justifyContent="space-around">
        <NativeLink
          href={`/customer/${row.endCustomerId}?tab=Ärenden`}
          target="_blank"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Tooltip title="Öppna kundkort i ny flik">
            <LaunchOutlined
              fontSize="small"
              color="primary"
              style={{ cursor: 'pointer' }}
            />
          </Tooltip>
        </NativeLink>
        <Link to={`/customer/${row.endCustomerId}?tab=Ärenden`}>
          <Tooltip title="Öppna kundkort i denna flik">
            <ArrowForwardOutlined fontSize="small" color="primary" />
          </Tooltip>
        </Link>
      </Box>
    ),
  },
];
