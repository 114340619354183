import isPropValid from '@emotion/is-prop-valid';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

type Props = {
  titleText: string;
  isOpened: boolean;
  isCollapsible: boolean;
  onToggleCollapse: () => void;
};

const Container = styled(Box, {
  shouldForwardProp: (prop: string) => isPropValid(prop),
})<{ isCollapsible: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  padding: ${({ theme }) => theme.spacing(2)};
  cursor: ${(props) => (props.isCollapsible ? 'pointer' : 'auto')};
`;

export const TitleSection = ({
  titleText,
  isCollapsible,
  isOpened,
  onToggleCollapse,
}: Props) => {
  return (
    <Container isCollapsible={isCollapsible} onClick={onToggleCollapse}>
      <Typography variant="h5">{titleText}</Typography>

      {isCollapsible && (
        <IconButton aria-label="expand row" size="small">
          {isOpened ? (
            <KeyboardArrowUpIcon width="20px" height="20px" />
          ) : (
            <KeyboardArrowDownIcon width="20px" height="20px" />
          )}
        </IconButton>
      )}
    </Container>
  );
};
