import React from 'react';

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { Skeleton } from '@mui/material';
import { useForm } from 'react-final-form';

import { Checkboxes, DatePicker, Dropdown, TextInput } from '@/components/form';
import { Condition } from '@/components/form/Condition';
import { CustomerCategoryTypes } from '@/constants';
import { getServiceStatusKey } from '@/helpers';
import { getFormattedDate } from '@/helpers/dates';
import { usePrivateSettings } from '@/hooks';
import { useChurnFiretextReasonOptions } from '@/hooks/useChurnFireTextReasonOptions';
import { useServiceSavedLostMutation } from '@/modules/services/hooks/useServiceSavedLostMutation';
import { HomegateParamSchema } from '@/types/services';
import { fieldRequired } from '@/utils/validation';

import { SavedLostSection } from '../../SavedLostSection';
import {
  regretReasonOptions,
  regretSourceOptions,
} from '../Firetext/constants';

type Props = {
  loadingLatestNote: boolean;
  serviceDetails: HomegateParamSchema;
};

export const ServiceInfoFields = ({
  loadingLatestNote,
  serviceDetails,
}: Props) => {
  const form = useForm();
  const { generateStatusOptions, servicesStatuses } = usePrivateSettings();

  const [regretStatusKey] = React.useState(() =>
    getServiceStatusKey(servicesStatuses, 'homegate', 'Ånger'),
  );
  const [churnStatusKey] = React.useState(() =>
    getServiceStatusKey(servicesStatuses, 'homegate', 'Churn'),
  );

  const statusOptions = generateStatusOptions('homegate');
  const firetextChurnReasonOptions = useChurnFiretextReasonOptions();

  React.useEffect(() => {
    if (form.getFieldState('status')?.value !== churnStatusKey) {
      form.batch(() => {
        form.change('churnReason', null);
        form.change('churnDate', null);
      });
    }

    if (form.getFieldState('status')?.value !== regretStatusKey) {
      form.batch(() => {
        form.change('regretSource', null);
        form.change('regretReason', null);
      });
    }
  }, [churnStatusKey, form, regretStatusKey]);

  const savedOrLostMutation = useServiceSavedLostMutation({
    serviceName: 'homegate',
    serviceId: serviceDetails.id,
  });

  return (
    <Card>
      <CardHeader
        title={`${
          serviceDetails.customer.category === CustomerCategoryTypes.COMPANY
            ? serviceDetails.customer.companyName
            : serviceDetails.customer.fullName
        } - ${serviceDetails.customer.id}`}
      />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h5">
              {`Orderdatum: ${getFormattedDate(serviceDetails.regDate)}`}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography data-testid="sentDate" variant="h5">{`Skickad: ${
              serviceDetails.sentDate
                ? getFormattedDate(serviceDetails.sentDate)
                : ''
            }`}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Dropdown options={statusOptions} label="Status" name="status" />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextInput name="batchId" label="Batchnummer" />
          </Grid>

          <Condition when="status" is={churnStatusKey}>
            <Grid item xs={12} sm={6}>
              <DatePicker
                name="churnDate"
                label="Churn-datum"
                showClearButton
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Dropdown
                label="Anledning"
                name="churnReason"
                options={firetextChurnReasonOptions}
              />
            </Grid>
          </Condition>

          <Condition when="status" is={regretStatusKey}>
            <Grid item xs={12} sm={6}>
              <Dropdown
                label="Anledning"
                name="regretReason"
                options={regretReasonOptions}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Dropdown
                label="Källa"
                name="regretSource"
                options={regretSourceOptions}
              />
            </Grid>
          </Condition>

          <Grid item xs={12}>
            <Checkboxes
              name="unitReturned"
              options={{
                label: 'Enhet returnerad',
                value: Boolean(serviceDetails.unitReturned),
              }}
              inputProps={{
                // @ts-expect-error data-testid is not a valid prop
                'data-testid': 'unitReturned',
              }}
            />
            <Checkboxes
              name="antiregret"
              options={{
                label: 'Vänd',
                value: Boolean(serviceDetails.antiregret),
              }}
              inputProps={{
                // @ts-expect-error data-testid is not a valid prop
                'data-testid': 'antiregret',
              }}
            />
            <Checkboxes
              name="winback"
              options={{
                label: 'Anti-churn',
                value: Boolean(serviceDetails.winback),
              }}
              inputProps={{
                // @ts-expect-error data-testid is not a valid prop
                'data-testid': 'winback',
              }}
            />
          </Grid>

          <Condition when="unitReturned" is={true}>
            <Grid item xs={12}>
              {loadingLatestNote ? (
                <Skeleton height={54} />
              ) : (
                <TextInput
                  fieldProps={{ validate: fieldRequired }}
                  name="returnLog"
                  label="Anteckningar om returen"
                />
              )}
            </Grid>
          </Condition>
        </Grid>

        <Box pt={2}>
          <SavedLostSection
            onClickSaved={() => {
              savedOrLostMutation.mutate({
                action: 'saved',
              });
            }}
            onClickLost={() => {
              savedOrLostMutation.mutate({
                action: 'lost',
              });
            }}
          />
        </Box>
      </CardContent>
    </Card>
  );
};
