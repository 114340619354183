import React from 'react';

import { FileCopyOutlined } from '@mui/icons-material';
import {
  Alert,
  Button,
  Grid,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import copy from 'copy-to-clipboard';

import { deactivateFraudProtection } from '@/api/fraudProtection';

import { VerifiedCustomer } from '../../../types';
import { DeactivateFraudProtection } from './DeactivateFraudProtection';

type Props = {
  pin: string;
  customerId: string;
  data: any;
  hasPhoneVerification: boolean;
};

export const FraudProtectionFound = ({
  pin,
  customerId,
  data,
  hasPhoneVerification,
}: Props) => {
  const [verifiedCustomer, setVerifiedCustomer] =
    React.useState<VerifiedCustomer | null>(null);

  const mutation = useMutation({
    mutationFn: (payload: {
      pin: string;
      customerId: string;
      grandIdSession: string;
    }) => {
      return deactivateFraudProtection(payload);
    },
  });

  const handleDeactivate = () => {
    if (!verifiedCustomer) return;

    const payload = {
      pin: pin,
      customerId: customerId,
      grandIdSession: verifiedCustomer.sessionId,
    };

    mutation.mutate(payload);
  };

  const handleCopyToClipboard = () => {
    const value = pin;

    copy(value, { format: 'text/plain' });
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h2" mb={2}>
          Aktiv bedrägerispärr funnen på: {pin}{' '}
          <Tooltip title="Kopiera personnummer">
            <IconButton onClick={handleCopyToClipboard} size="large">
              <FileCopyOutlined />
            </IconButton>
          </Tooltip>
        </Typography>

        {data.addedByUs ? (
          <Typography variant="body1" fontSize="1rem">
            En aktiv bedrägerispärr finns på personnummer {pin}.
          </Typography>
        ) : (
          <Alert severity="warning">
            <Typography variant="body1" fontSize="1rem">
              {data.message}
            </Typography>
          </Alert>
        )}
      </Grid>

      {data.addedByUs && hasPhoneVerification && (
        <DeactivateFraudProtection
          pin={pin}
          setVerifiedCustomer={setVerifiedCustomer}
          verifiedCustomer={verifiedCustomer}
        />
      )}

      {mutation.isPending && (
        <Grid item xs={12}>
          <Typography mb={2} variant="h2">
            Avaktiverar...
          </Typography>
          <LinearProgress />
        </Grid>
      )}

      {mutation.isSuccess && (
        <Grid item xs={12}>
          <Alert severity="success">
            <Typography variant="h2" mb={2}>
              Bedrägerispärr avaktiverad på personnummer: {pin}!
            </Typography>
            <Typography variant="body1" fontSize="1rem">
              En bedrägerispärr på personnummer {pin} har nu blivit{' '}
              <b>avaktiverad</b> hos samtliga rikstäckande
              kreditupplysningsföretag i Sverige, dvs. Bisnode, Creditsafe,
              Decidas, Syna och UC men det kan ta upp till 24 timmar innan
              övriga kreditupplysningsföretag har hunnit registrera{' '}
              <b>avaktiveringen</b>.
            </Typography>
          </Alert>
        </Grid>
      )}

      {mutation.isError && (
        <Grid item xs={12}>
          <Alert severity="error">
            Något gick fel vid aktiveringen av bedrägerispärr. Kontrollera
            internetanslutningen. Om felet kvarstår, kontakta IT.
          </Alert>
        </Grid>
      )}

      {mutation.isIdle && hasPhoneVerification && (
        <Grid
          container
          item
          justifyContent="flex-start"
          sx={(theme) => ({
            '& > *': {
              marginLeft: theme.spacing(1),
            },
            '& > *:first-child': {
              marginLeft: '0',
            },
            marginTop: theme.spacing(2),
          })}
        >
          <Button
            disabled={
              !(verifiedCustomer?.userAttributes?.personalNumber === pin)
            }
            variant="outlined"
            onClick={handleDeactivate}
          >
            Avaktivera
          </Button>
        </Grid>
      )}
    </>
  );
};
