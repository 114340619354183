import { useQuery } from '@tanstack/react-query';

import { getCampaign } from '@/api';
import { queryKeys } from '@/constants/queryKeys';
import { ProtectionCampaign } from '@/types/campaigns';

type Args = {
  campaignIri?: string | null;
};
export const useGetProtectionCampaign = ({ campaignIri }: Args) =>
  useQuery({
    queryKey: queryKeys.campaigns.detailById(campaignIri!),
    queryFn: () => getCampaign<ProtectionCampaign>(campaignIri!),
    enabled: !!campaignIri,
  });
