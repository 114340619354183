export const TaskTypes = {
  OTHER: '0',
  BILLING_QUESTION: '1',
  UNIT_PROBLEM: '2',
  CONTRACT_QUESTION: '3',
  ORDER: '4',
  UNIT_RETURN: '5',
  SHREDDING: '6',
  AWAITING_UNIT: '7',

  DISPUTED: '9',
  AWAITING_ANSWER: '10',
  WINBACK_CALL_CUSTOMER: '11',
  WINBACK_SEND_LETTER: '12',
  WINBACK_AWAITING_ANSWER: '13',
  ALARM_CENTRAL: '14',
};

export function taskTypeToString(taskType: string) {
  switch (taskType) {
    case TaskTypes.OTHER:
      return 'Övrigt';

    case TaskTypes.BILLING_QUESTION:
      return 'Fakturafråga';

    case TaskTypes.UNIT_PROBLEM:
      return 'Enhetsproblem';

    case TaskTypes.CONTRACT_QUESTION:
      return 'Avtalsfråga';

    case TaskTypes.ORDER:
      return 'Beställning';

    case TaskTypes.UNIT_RETURN:
      return 'Enhetsretur';

    case TaskTypes.SHREDDING:
      return 'Makulering';

    case TaskTypes.AWAITING_UNIT:
      return 'Väntar Enhet';

    case TaskTypes.DISPUTED:
      return 'Bestridan';

    case TaskTypes.AWAITING_ANSWER:
      return 'Väntar svar';

    case TaskTypes.WINBACK_CALL_CUSTOMER:
      return 'Winback - Ring kund';

    case TaskTypes.WINBACK_SEND_LETTER:
      return 'Winback - Skicka brev';

    case TaskTypes.WINBACK_AWAITING_ANSWER:
      return 'Winback - Väntar svar';

    case TaskTypes.ALARM_CENTRAL:
      return 'Larmcentral';

    default:
      return taskType;
  }
}

const WINBACK_TYPES = ['11', '12', '13'];

// Supposed to be used with Dropdown fields
export const taskTypeOptions = Object.values(TaskTypes)
  .map((value) => ({
    value,
    text: taskTypeToString(value),
  }))
  // .filter((task) => !WINBACK_TYPES.includes(task.value))
  .sort((a, b) => {
    if (a.text < b.text) {
      return -1;
    }
    if (a.text > b.text) {
      return 1;
    }
    return 0;
  });

export const winbackCategoriesOptions = Object.values(TaskTypes)
  .map((value) => ({
    value,
    text: taskTypeToString(value),
  }))
  .filter((task) => WINBACK_TYPES.includes(task.value))
  .sort((a, b) => {
    if (a.text < b.text) {
      return -1;
    }
    if (a.text > b.text) {
      return 1;
    }
    return 0;
  });
