import { NoClipSinneDeviceData } from '@/types/noclip';
import httpClient from '@/utils/httpClient';

export type UpdateSinneDevicePayload = {
  simcardId?: number | null;
  whiteLabelId?: string | null;
  sinneEntityId?: number | null;
  deviceManufactureDate?: string | null; // ISO 8601 date format
  deviceStatus?: number | null;
  customerPrice?: number | null;
};

export type UpdateSinneDeviceInNoClipParams = UpdateSinneDevicePayload & {
  deviceId: string;
};

export const updateSinneDeviceInNoClip = async ({
  deviceId,
  ...payload
}: UpdateSinneDeviceInNoClipParams) =>
  httpClient.put(`/noclip/sinne/update/${deviceId}`, payload);

export type GetSinneDeviceFromNoClipParams = {
  imei?: string;
  macAddress?: string;
} & (
  | {
      imei: string;
    }
  | {
      macAddress: string;
    }
);
export const getSinneDeviceFromNoClip = async ({
  imei,
  macAddress,
}: GetSinneDeviceFromNoClipParams) => {
  let queryString = '';
  if (imei) {
    queryString = `imei=${imei}`;
  }
  if (macAddress) {
    queryString = `macAddress=${macAddress}`;
  }
  const { data } = await httpClient.get<NoClipSinneDeviceData>(
    `/noclip/sinne?${queryString}`,
  );
  return data;
};
