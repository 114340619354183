import React from 'react';

import { UseMutationResult } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { GenerateMockedWelcomeLetterParams } from '@/api';
import { SimpleTableColumn } from '@/components/SimpleTable';
import { GenericHttpError } from '@/types/errors';

import { getHomegateCampaignTableColumns } from './getHomegateCampaignTableColumns';
import { getProtectionCampaignTableColumns } from './getProtectionCampaignTableColumns';
import { getSinneCampaignTableColumns } from './getSinneCampaignTableColumns';

export type CampaignTableColumnProps = {
  campaignType: string;
  handleEditCampaign: (campaign: string) => void;
  deleting: boolean;
  deleteCampaignMutation: UseMutationResult<any, Error, string, unknown>;
  handleDeleteOpen: (event: React.MouseEvent<HTMLButtonElement>) => void;
  anchorEl: (EventTarget & HTMLButtonElement) | null;
  handleDeleteClose: () => void;
  handleDelete: () => void;
  generateMockedWelcomeLetterMutation: UseMutationResult<
    AxiosResponse<any, any>,
    GenericHttpError,
    GenerateMockedWelcomeLetterParams,
    unknown
  >;
};
export const getCampaignTableColumns = (
  props: CampaignTableColumnProps,
): SimpleTableColumn[] => {
  const { campaignType, ...columnProps } = props;
  switch (campaignType) {
    case 'protections_campaigns':
      return getProtectionCampaignTableColumns(columnProps);
    case 'sinne_campaigns':
      return getSinneCampaignTableColumns(columnProps);
    case 'homegate_campaigns':
      return getHomegateCampaignTableColumns(columnProps);
    default:
      return [];
  }
};
