import React from 'react';

import {
  Alert,
  AlertTitle,
  Button,
  CardActions,
  CardContent,
  Divider,
  LinearProgress,
} from '@mui/material';
import { useActor } from '@xstate/react';
import { useParams } from 'react-router-dom';

import { getServiceStatusKey } from '@/helpers/Services';
import { getTypeKey } from '@/helpers/Types';
import { useQueryParams } from '@/hooks';
import { usePrivateSettings } from '@/hooks/usePrivateSettings';
import { SinneConfigContext } from '@/modules/sinne/components/sinneConfigFlow/context/SinneConfigContextProvider';

export const Initialize = () => {
  const { serviceId } = useParams<{
    serviceId: string;
  }>();
  const isReplacementFlow =
    useQueryParams().get('isReplacementFlow') === 'true';

  const machine = React.useContext(SinneConfigContext);
  const [currentState] = useActor(machine);

  const { servicesStatuses, servicesTypes, servicesNames } =
    usePrivateSettings();

  const noteTypeForReplacementFlow = getTypeKey(
    servicesTypes,
    'note',
    'Övrigt',
  );

  const unitActiveStatusKey = getServiceStatusKey(
    servicesStatuses,
    'sinne',
    'Aktiv',
  );

  const configuringStatusKey = getServiceStatusKey(
    servicesStatuses,
    'sinne',
    'Konfigurerar enhet',
  );

  const isLoading = currentState.matches('INITIALIZE.initializing');
  const isError = currentState.matches('INITIALIZE.error');

  React.useEffect(() => {
    if (serviceId && unitActiveStatusKey && configuringStatusKey) {
      machine.send({
        type: 'INITIALIZE_CONTEXT',
        serviceId,
        unitActiveStatusKey,
        configuringStatusKey,
        isReplacementFlow,
        noteTypeForReplacementFlow: noteTypeForReplacementFlow!,
        whiteLabelServiceName: servicesNames.sinne,
      });
    }
  }, [serviceId, configuringStatusKey, unitActiveStatusKey, machine]);

  return (
    <>
      <CardContent>
        {isLoading && <LinearProgress />}
        {isError && (
          <Alert severity="error">
            <AlertTitle>Kan inte påbörja flödet</AlertTitle>
            Applikationsinställningarna saknar information om statusar antingen
            för "aktiv" eller "konfigurerar enhet".
          </Alert>
        )}
      </CardContent>
      <Divider />
      <CardActions>
        <Button
          disabled={isError || isLoading}
          onClick={() => machine.send('PROCEED')}
        >
          Nästa
        </Button>
      </CardActions>
    </>
  );
};
