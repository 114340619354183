import React from 'react';

import CloseRounded from '@mui/icons-material/CloseRounded';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useQuery } from '@tanstack/react-query';
import { useMachine } from '@xstate/react';

import { getResellers } from '@/api/reseller';
import { getObjectKeys } from '@/helpers/tsHelpers';
import { usePrivateSettings } from '@/hooks';
import { exportStateMachine } from '@/modules/Export/exportStateMachine';
import { getSnakeServiceName } from '@/modules/common/utils/services';
import { prepareResellerOptions } from '@/modules/services';

import { queryKeys } from '../../../constants/queryKeys';
import { UseDownloadExportFile } from '../../../hooks/useDownloadExportFile';
import { CustomerExportExplanationCard } from './CustomerExportExplanationCard';
import { ExportAllExplanationCard } from './ExportAllExplanationCard';
import { ExportTab } from './ExportTabs';

export type FormValues = {
  start: string;
  end: string;
  billing?: number;
  reseller?: string;
  status?: string;
  fetchAll?: boolean;
};

type Props = {
  exportType: ExportTab;
  grandIdSession: string;
  mutation: UseDownloadExportFile;
  hasBillableOption: boolean;
};
export const ExportForm = ({
  exportType,
  grandIdSession,
  mutation,
  hasBillableOption,
}: Props) => {
  const [state, send] = useMachine(exportStateMachine);
  const { formValues, ...context } = state.context;

  const theme = useTheme();

  const { servicesNames } = usePrivateSettings();
  const { data: resellers } = useQuery({
    queryKey: [queryKeys.getResellers],

    queryFn: () => getResellers(),
  });
  const { generateStatusOptions } = usePrivateSettings();

  const isCustomerExport = exportType === 'customer';

  const hasInvalidFormValues = React.useMemo(() => {
    return getObjectKeys(formValues).some(
      (key) => formValues[key] === undefined || formValues[key] === null,
    );
  }, [formValues]);

  React.useEffect(() => {
    if (exportType) {
      const type =
        exportType === 'customer'
          ? 'customer'
          : getSnakeServiceName(exportType);

      const whiteLableExportName =
        exportType === 'customer' ? 'Kunder' : servicesNames[exportType];

      send({ type: 'SET_EXPORT_TYPE', exportType: type });
      send({
        type: 'SET_WHITE_LABEL_EXPORT_NAME',
        whiteLabelExportName: whiteLableExportName,
      });
    }
  }, [exportType]);

  const handleSubmit = () => {
    let { reseller, status, fetchAll, ...rest } = { ...formValues };

    if (typeof reseller === 'string') {
      reseller = reseller.replace(/\D/g, '');
    }
    const params = {
      ...rest,
      ...(!fetchAll ? null : { fetchAll: true }),
      ...(!reseller ? null : { reseller }),
      ...(!status ? null : { status }),
      grandIdSession,
    };

    const type = context.exportType;
    const whiteLableExportName = context.whiteLabelExportName;

    mutation.mutate({
      type,
      params,
      whiteLableExportName,
    });
  };

  return (
    <Box maxWidth={theme.breakpoints.values.lg}>
      {mutation.isPending && <LinearProgress />}

      <Card>
        <CardHeader title="Export" />
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            {!isCustomerExport && (
              <>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h5">Period</Typography>
                  <DesktopDatePicker
                    label="Start"
                    inputFormat="yyyy-MM-dd"
                    value={formValues.start}
                    onChange={(date: Date | null) =>
                      send({ type: 'SET_START_DATE', start: date })
                    }
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                  <DesktopDatePicker
                    label="Till"
                    inputFormat="yyyy-MM-dd"
                    value={formValues.end}
                    onChange={(date: Date | null) =>
                      send({ type: 'SET_END_DATE', end: date })
                    }
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                  <ButtonBox>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        send({
                          type: 'SET_THIS_MONTH',
                        });
                      }}
                    >
                      Denna månad
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        send({
                          type: 'SET_LAST_MONTH',
                        });
                      }}
                    >
                      Förra månaden
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        send({
                          type: 'SET_FULL_PERIOD',
                        });
                      }}
                    >
                      Full period
                    </Button>
                  </ButtonBox>
                </Grid>
              </>
            )}

            <Grid item xs={12} sm={6}>
              {!isCustomerExport && resellers && (
                <>
                  <Typography variant="h5">Alternativ</Typography>
                  <FormControl fullWidth>
                    <InputLabel id="status">Status</InputLabel>
                    <Select
                      name="status"
                      label="Status"
                      displayEmpty
                      value={
                        (generateStatusOptions(exportType).find(
                          (option) => option.value === formValues.status,
                        )?.value as string) || ''
                      }
                      {...(formValues.status && {
                        endAdornment: (
                          <IconButton
                            size="small"
                            color="primary"
                            onClick={() => {
                              send({
                                type: 'SET_STATUS',
                                status: '',
                              });
                            }}
                          >
                            <CloseRounded />
                          </IconButton>
                        ),
                      })}
                      onChange={(e) => {
                        const value = e.target.value;
                        send({
                          type: 'SET_STATUS',
                          status: value,
                        });
                      }}
                    >
                      {generateStatusOptions(exportType).map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.text}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel id="status">Återförsäljare</InputLabel>
                    <Select
                      name="reseller"
                      label="Återförsäljare"
                      displayEmpty
                      value={
                        (prepareResellerOptions(resellers.data).find(
                          (option) => option.value === formValues.reseller,
                        )?.value as string) || ''
                      }
                      {...(formValues.reseller && {
                        endAdornment: (
                          <IconButton
                            size="small"
                            color="primary"
                            onClick={() => {
                              send({
                                type: 'SET_RESELLER',
                                reseller: '',
                              });
                            }}
                          >
                            <CloseRounded />
                          </IconButton>
                        ),
                      })}
                      onChange={(e) => {
                        const value = e.target.value;
                        send({
                          type: 'SET_RESELLER',
                          reseller: value,
                        });
                      }}
                    >
                      {prepareResellerOptions(resellers.data).map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.text}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              )}
              {hasBillableOption && (
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formValues.billing}
                        onClick={() => {
                          send({ type: 'TOGGLE_BILLING' });
                        }}
                      />
                    }
                    label="Faktureras"
                  />
                </FormControl>
              )}
              <FormControl sx={{ mt: isCustomerExport ? 0 : 2 }}>
                <Tooltip
                  placement="right"
                  title={
                    formValues.fetchAll
                      ? 'Kriterierna uppfylls. Export hämtas snabbt från cache'
                      : 'Kryssa i för att exportera från cache'
                  }
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formValues.fetchAll}
                        disabled={formValues.fetchAll}
                        onClick={() => {
                          send({ type: 'SET_FETCH_ALL' });
                        }}
                      />
                    }
                    label="Exportera från cache"
                  />
                </Tooltip>
              </FormControl>
              {isCustomerExport ? (
                <CustomerExportExplanationCard />
              ) : (
                <ExportAllExplanationCard
                  hasBillableOption={hasBillableOption}
                />
              )}
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            disabled={mutation.isPending || hasInvalidFormValues}
            variant="contained"
            color="primary"
            type="submit"
            onClick={handleSubmit}
          >
            Ladda ner
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};

const ButtonBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(2),
}));
