import { LinearProgress } from '@mui/material';
import { useQueries } from '@tanstack/react-query';

import { getUserById } from '@/api';
import { SimpleTable } from '@/components/SimpleTable';
import { extractIdFromIRI } from '@/utils/common';

import { BasicAlert } from '../../../../components/BasicAlert/BasicAlert';
import { ManagedCustomer } from '../../types';
import { ManagerMeanArr } from '../ManagerMeanArr';
import { ManagerMedianArr } from '../ManagerMedianArr';
import { ManagerTotalArr } from '../ManagerTotalArr';

type TeamMemberUser = {
  name: string;
  managedCustomers: ManagedCustomer[];
};

const fieldsToFetch = {
  name: true,
  managedCustomers: { id: true },
};

type Column = {
  label: string;
  renderer: (row: TeamMemberUser) => React.ReactNode;
};

type Props = {
  members: string[];
};
export const TeamTable = ({ members }: Props) => {
  const membersData = useQueries({
    queries: members.map((member) => ({
      queryKey: ['teamMember', member],
      queryFn: async () => {
        const data = await getUserById<TeamMemberUser>(
          extractIdFromIRI(member),
          fieldsToFetch,
        );

        return data;
      },
    })),
  });

  const isLoading = membersData.some((customer) => customer.isLoading);

  const isError = membersData.some((customer) => customer.isError);
  const isSuccess = membersData.every((customer) => customer.isSuccess);
  const data = isSuccess ? membersData.map((customer) => customer.data) : [];

  const columns: Column[] = [
    {
      label: 'Namn',
      renderer: (row) => row.name,
    },
    {
      label: 'Totalt Antal kunder',
      renderer: (row) => row?.managedCustomers?.length,
    },
    {
      label: 'Totalt Förvaltad ARR',
      renderer: ({ managedCustomers }) => (
        <ManagerTotalArr managedCustomers={managedCustomers} />
      ),
    },
    {
      label: 'ARR / Medelvärde',
      renderer: ({ managedCustomers }) => (
        <ManagerMeanArr managedCustomers={managedCustomers} />
      ),
    },
    {
      label: 'ARR / Median',
      renderer: ({ managedCustomers }) => (
        <ManagerMedianArr managedCustomers={managedCustomers} />
      ),
    },
  ];

  if (isLoading) return <LinearProgress />;
  if (isError) return <BasicAlert />;

  return <SimpleTable rows={data} columns={columns} />;
};
