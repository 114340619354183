import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  LinearProgress,
} from '@mui/material';

import { BasicAlert } from '@/components/BasicAlert';
import { SimpleTable } from '@/components/SimpleTable';
import { renderServiceIcons } from '@/helpers/render';
import { EnhancedAMSCustomer } from '@/types/customers';

type Props = {
  title?: string;
  items?: EnhancedAMSCustomer[];
  loading: boolean;
  error: boolean;
  handleToggle: (id: string) => () => void;
  handleToggleAll: (ids: string[]) => () => void;
  checked: string[];
};

export const TransferList = ({
  title,
  items,
  loading,
  error,
  handleToggle,
  handleToggleAll,
  checked,
}: Props) => {
  const columns = [
    {
      label: '',
      renderer: (row: EnhancedAMSCustomer) => (
        <Checkbox
          color="primary"
          checked={checked.indexOf(row['@id']) !== -1}
          onClick={handleToggle(row['@id'])}
        />
      ),
    },
    { label: 'Kundnummer', renderer: (row: EnhancedAMSCustomer) => row.id },
    {
      label: 'Företag',
      renderer: (row: EnhancedAMSCustomer) =>
        row.companyCategory ? row.companyName : '',
    },
    { label: 'Namn', renderer: (row: EnhancedAMSCustomer) => row.fullName },
    {
      label: 'Tjänster',
      renderer: (row: EnhancedAMSCustomer) => renderServiceIcons(row),
    },
    {
      label: 'ARR',
      renderer: (row: EnhancedAMSCustomer) => row.totalARR,
    },
  ];
  return loading ? (
    <LinearProgress />
  ) : error ? (
    <BasicAlert />
  ) : items ? (
    <Card>
      <CardHeader
        title={title}
        subheader={`${checked.length} valda`}
        action={
          <Checkbox
            color="primary"
            onClick={handleToggleAll(items.map((item) => item['@id']))}
            checked={checked.length === items.length}
            indeterminate={
              checked.length !== items.length && checked.length !== 0
            }
            disabled={items.length === 0}
          />
        }
      />
      <Divider />
      <CardContent>
        <SimpleTable columns={columns} rows={items} />
      </CardContent>
    </Card>
  ) : null;
};
