import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useQueryClient } from '@tanstack/react-query';
import { format, parseISO } from 'date-fns';
import { Form } from 'react-final-form';
import { Link } from 'react-router-dom';

import { UpdateTaskPayload } from '@/api';
import { Dropdown } from '@/components/form';
import { CustomerCategoryTypes } from '@/constants';
import { mapDateFieldsValues } from '@/helpers/dates';
import { usePrivateSettings, useSnackbar } from '@/hooks';
import { useGetNextSveaInvoice } from '@/hooks/useGetNextSveaInvoice';
import { useGetUsers } from '@/hooks/useGetUsers';
import { CustomerDTO } from '@/types/customers';
import { TaskItem } from '@/types/tasks';
import { MakeRequired } from '@/types/utility';
import { getCustomerName } from '@/utils/customer';

import { TaskTeamTypes } from '../../constants/tasksModalsConstants';
import { useDeleteTask } from '../../hooks/useDeleteTask';
import { useUpdateTask } from '../../hooks/useUpdateTask';
import { TaskTeamType } from '../../types/team';
import { EditTaskModalFields } from './EditTaskModalFields';

export type EditTaskModalProps = {
  customer: Partial<CustomerDTO>;
  task: TaskItem;
  open: boolean;
  fromTasksList: boolean;
  queryKey: any[];
  handleVisibility: () => void;
};

export const EditTaskModal = ({
  customer,
  task,
  open,
  handleVisibility,
  fromTasksList,
  queryKey,
}: EditTaskModalProps) => {
  const { invoiceProvider } = usePrivateSettings();
  const hasSveaInvoiceProvider = invoiceProvider?.name.toLowerCase() == 'svea';

  const snackbar = useSnackbar();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const queryClient = useQueryClient();

  const updateTaskMutation = useUpdateTask();
  const deleteTaskMutation = useDeleteTask({ queryKey });

  const onSubmit = (values: UpdateTaskPayload) => {
    values = mapDateFieldsValues(values);

    updateTaskMutation.mutate(values, {
      onSuccess: () => {
        handleVisibility();
        snackbar({ type: 'success', message: 'Uppgiften uppdaterad.' });
        fromTasksList
          ? queryClient.refetchQueries({ queryKey })
          : queryClient.invalidateQueries({ queryKey });
      },
      onError: () => {
        snackbar({
          type: 'error',
          message: 'Något gick fel, uppgiften kunde inte uppdateras.',
        });
      },
    });
  };

  const handleDeleteOpen = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    // @ts-ignore
    setAnchorEl(event.currentTarget);
  };

  const handleDeleteClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteConfirm = () => {
    deleteTaskMutation.mutate(task.id, {
      onSuccess: () => {
        handleVisibility();
        handleDeleteClose();

        snackbar({ type: 'success', message: 'Uppgift borttagen.' });
        queryClient.invalidateQueries({ queryKey });
      },
      onError: () => {
        snackbar({
          type: 'error',
          message: 'Något gick fel, uppgiften kunde inte tas bort.',
        });
      },
    });
  };

  const getAndMatchTeam = (): TaskTeamType | undefined => {
    let teamCheck;
    if (task.team === 'Customer Support') {
      teamCheck = TaskTeamTypes.CUSTOMER_SUPPORT;
    } else if (task.team === 'Customer Success') {
      teamCheck = TaskTeamTypes.ADMINISTRATOR;
    }
    return teamCheck;
  };

  const taskTeam: TaskTeamType | undefined = getAndMatchTeam();

  const { data, isLoading, isError, refetch } = useGetNextSveaInvoice(
    customer?.id,
  );

  const handleRefetch = () => {
    refetch();
  };

  return (
    <Dialog onClose={handleVisibility} open={open} maxWidth="xs">
      {deleteTaskMutation.isPending && <LinearProgress />}

      <DialogTitle>
        <DialogHeader>
          {customer?.id ? (
            <Link to={`/customer/${customer.id}`}>
              {customer.id} -{' '}
              {getCustomerName(
                customer as MakeRequired<
                  CustomerDTO,
                  'category' | 'fullName' | 'companyName'
                >,
              )}
            </Link>
          ) : (
            <>Ingen Kund</>
          )}

          <IconButton
            aria-label="delete"
            size="small"
            color="primary"
            disabled={deleteTaskMutation.isPending}
            onClick={handleDeleteOpen}
          >
            <StyledDeleteIcon fontSize="small" />
          </IconButton>
        </DialogHeader>
      </DialogTitle>
      <Divider />

      <Form
        onSubmit={onSubmit}
        initialValues={{
          ...task,
          claimedBy: task.claimedBy ? task.claimedBy['@id'] : null,
        }}
        render={({ handleSubmit, submitting, pristine, form, values }) => (
          <form onSubmit={handleSubmit} noValidate autoComplete="off">
            <DialogContent style={{ paddingTop: '0' }}>
              <Box>
                <List dense>
                  {hasSveaInvoiceProvider && customer?.id && (
                    <Alert
                      icon={false}
                      sx={{
                        background: '#7fffd4',
                      }}
                    >
                      {isLoading ? (
                        <LinearProgress />
                      ) : isError ? (
                        <Typography>
                          OBS! Kunde inte hämta Svea information.
                        </Typography>
                      ) : (
                        <Typography>
                          OBS! Kundens nästa faktura från Svea bank kommer att
                          vara på{' '}
                          {customer.category === CustomerCategoryTypes.COMPANY
                            ? `${data?.amountExclVat}`
                            : `${data?.amountInclVat}`}{' '}
                          kr. Kunden har {data?.amountOfContracts} aktiva
                          kontrakt.{' '}
                          <Typography
                            sx={{ color: 'blue', cursor: 'pointer' }}
                            onClick={handleRefetch}
                          >
                            Tryck här för att uppdatera
                          </Typography>
                        </Typography>
                      )}
                      <Link
                        to={`https://fenixweb.svea.com/#/CustomerContract/Invoicee/${customer?.id}/Subscriptions`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Öppna kund i Svea
                      </Link>
                    </Alert>
                  )}
                  <ListItem disableGutters>
                    <ListItemText>
                      <Typography>
                        {`Start: ${format(
                          parseISO(task.startDate),
                          'yyyy-MM-dd',
                        )}`}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  {task.updatedBy && (
                    <ListItem disableGutters>
                      <ListItemText>
                        <Typography>
                          {`Uppdaterad: `}{' '}
                          <strong>
                            {format(
                              parseISO(task.updatedAt),
                              'yyyy-MM-dd HH:mm:ss',
                            )}
                          </strong>{' '}
                          {` av `}
                          <strong>{task.updatedBy.name}</strong>
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  )}
                  {task.createdBy && (
                    <ListItem disableGutters>
                      <ListItemText>
                        <Typography>
                          {`Skapad: `}{' '}
                          <strong>
                            {format(
                              parseISO(task.createdAt),
                              'yyyy-MM-dd HH:mm:ss',
                            )}
                          </strong>{' '}
                          {` av `}
                          <strong>{task.createdBy.name}</strong>
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  )}

                  <ListItem disableGutters>
                    <Box display="flex" width="100%">
                      <Dropdown
                        label="Hanteras av:"
                        name="claimedBy"
                        options={useGetUsers({
                          taskTeam: taskTeam,
                        }).map((user) => ({
                          value: user['@id'],
                          text: user.name,
                        }))}
                      />
                    </Box>
                  </ListItem>
                </List>
              </Box>
              <Divider style={{ marginBottom: '0.5rem' }} />

              <EditTaskModalFields task={task} form={form} values={values} />
            </DialogContent>
            <Divider />
            <StyledDialogActions>
              <Button onClick={handleVisibility}>Avbryt</Button>
              <Button
                disabled={submitting || pristine}
                color="primary"
                type="submit"
                variant="contained"
              >
                Uppdatera
              </Button>
            </StyledDialogActions>
          </form>
        )}
      />

      <Menu
        id="delete-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleDeleteClose}
      >
        <MenuItem onClick={handleDeleteConfirm}>Ja</MenuItem>
        <MenuItem onClick={handleDeleteClose}>Nej</MenuItem>
      </Menu>
    </Dialog>
  );
};

const DialogHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledDeleteIcon = styled(DeleteIcon)`
  color: ${({ theme }) => theme.palette.text.secondary};
`;

const StyledDialogActions = styled(DialogActions)`
  justify-content: space-between;
`;
