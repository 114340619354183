import { useQuery } from '@tanstack/react-query';

import { fetchManagedCustomers } from '@/api/customers';
import { getPrivateSettings } from '@/api/settings';
import { getNoteTypeByName } from '@/helpers';
import { CustomerSearchTypes } from '@/types/customers';

import { OnboardingCustomer } from '../types';

export type QueryOptions = {
  q: string;
  sortField: 'id' | 'firstName' | 'lastName'; // This is what the API can sort by
  sortOrder: 'asc' | 'desc';
  type: CustomerSearchTypes;
};
type Props = {
  accountManagerId: number;
  page: number;
  perPage: number;
  queryOptions: QueryOptions;
  queryProps: Record<string, boolean>;
};

export const useFetchUserWithCustomers = ({
  queryOptions,
  page,
  perPage,
  accountManagerId,
  queryProps,
}: Props) => {
  const { data: settings, isSuccess } = useQuery({
    queryKey: ['getPrivateSettings'],
    queryFn: async () => await getPrivateSettings(),
    staleTime: 20000,
  });

  const activeProducts = settings?.products;
  const accountManagerNoteType = isSuccess
    ? getNoteTypeByName(settings, 'Account manager')
    : null;

  return useQuery({
    queryKey: [
      'fetchUserWithCustomers',
      queryOptions,
      activeProducts,
      accountManagerNoteType,
      queryProps,
    ],

    queryFn: async () => {
      const data = await fetchManagedCustomers({
        ...queryOptions,
        page,
        perPage,
        accountManagerId,
        churnDateExists: false,
        activeProducts: activeProducts!,
        ...queryProps,
      });

      // filter out the only wanted note type and order by desc
      const customers = data['hydra:member'].map(
        (customer: OnboardingCustomer) => {
          const notes = customer.notes.filter(
            (note) => note.type === accountManagerNoteType,
          );
          customer.notes = notes;
          return customer;
        },
      );

      return customers;
    },

    initialData: [],

    enabled:
      Boolean(activeProducts) && isSuccess && Boolean(accountManagerNoteType),
  });
};
