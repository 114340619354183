import { CustomerCategoryTypes } from '@/constants';
import { BasicCustomerData } from '@/types/customers';

type Args = {
  remainingRegretDays: number;
  regretTimeExpired: boolean;
  customer: BasicCustomerData;
};
export function getRegDateMessage({
  remainingRegretDays,
  regretTimeExpired,
  customer,
}: Args) {
  const isPrivateCustomer =
    customer?.category === CustomerCategoryTypes.PRIVATE;

  if (!isPrivateCustomer) {
    return {
      status: 'isCompany',
      message: 'Företagskund',
    };
  }

  if (isPrivateCustomer && regretTimeExpired) {
    return {
      status: 'regretOver',
      message: 'Ingen ångerrätt kvar!',
    };
  }

  if (isPrivateCustomer && remainingRegretDays) {
    return {
      status: 'regretNotOver',
      message: `Ångerätten löper ut om ${remainingRegretDays} dagar.`,
    };
  }
}
