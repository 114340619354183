import React from 'react';

import { Dialog, DialogTitle, Divider } from '@mui/material';

import * as KeyTagApi from '@/api/keyTag';
import { getElementFromArray } from '@/helpers/getElementFromArray';
import { BasicCustomerData } from '@/types/customers';
import { KeyTagItem } from '@/types/services';
import { getCatchErrorMessage } from '@/utils/common';

import { CustomerIdCheck } from '../CustomerIdCheck';
import { KeyTagConfigStep1 } from './KeyTagConfigStep1';
import { KeyTagConfigStep2 } from './KeyTagConfigStep2';
import { KeyTagConfigStep3 } from './KeyTagConfigStep3';

type Props = {
  open: boolean;
  onClose: () => void;
};

export type PartialKeyTagItem = Pick<KeyTagItem, 'id' | 'code' | 'customer'>;

export const KeyTagConfigModal = ({ open, onClose }: Props) => {
  const [keyTagsToConfigure, setKeyTagsToConfigure] = React.useState<
    PartialKeyTagItem[]
  >([]);
  const [configuredKeyTags, setConfiguredKeyTags] = React.useState<
    PartialKeyTagItem[]
  >([]);
  const [activeKeyTag, setActiveKeyTag] =
    React.useState<null | PartialKeyTagItem>(null);
  const [foundCustomer, setFoundCustomer] =
    React.useState<null | BasicCustomerData>(null);
  const [requestInProgress, setRequestInProgress] = React.useState(false);
  const [responseError, setResponseError] = React.useState<string>('');
  const [activeStep, setActiveStep] = React.useState(1);

  async function fetchCustomerKeyTags(customerId: number) {
    try {
      const data = await KeyTagApi.checkCustomerKeyTagConfig(customerId);

      setRequestInProgress(false);
      setResponseError('');
      setKeyTagsToConfigure(data);
      setActiveStep(2);
    } catch (error) {
      const message = 'Något gick fel';
      setRequestInProgress(false);
      setResponseError(message);
    }
  }

  async function handleStep2Submit(code: string) {
    setRequestInProgress(true);

    if (!activeKeyTag?.id) {
      setRequestInProgress(false);
      setResponseError('Något gick fel');
      return;
    }

    try {
      await KeyTagApi.updateKeyTagCode({
        id: activeKeyTag.id,
        code,
      });

      setConfiguredKeyTags([
        ...configuredKeyTags,
        {
          ...activeKeyTag,
          code,
        },
      ]);
      setRequestInProgress(false);
      setResponseError('');

      const updatedKeyTagsToConfigure = keyTagsToConfigure.splice(1);

      if (updatedKeyTagsToConfigure.length === 0) {
        setActiveStep(3);
      }

      setKeyTagsToConfigure(updatedKeyTagsToConfigure);
    } catch (error: unknown) {
      const errorMessage = getCatchErrorMessage(error);

      const message = errorMessage || 'Något gick fel';
      setRequestInProgress(false);
      setResponseError(message);
    }
  }

  function handleResetState() {
    setKeyTagsToConfigure([]);
    setFoundCustomer(null);
    setActiveStep(1);
  }

  React.useEffect(() => {
    if (foundCustomer?.id) {
      fetchCustomerKeyTags(foundCustomer.id);
    }
  }, [foundCustomer]);

  React.useEffect(() => {
    if (keyTagsToConfigure.length > 0) {
      setActiveKeyTag(getElementFromArray(keyTagsToConfigure[0]));
    }
  }, [keyTagsToConfigure]);

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth="sm">
      <DialogTitle>Utskick</DialogTitle>
      <Divider />

      {activeStep === 1 && (
        <KeyTagConfigStep1
          requestInProgress={requestInProgress}
          customerIdCheckComponent={
            <CustomerIdCheck onCustomerFound={setFoundCustomer} isFirstStep />
          }
        />
      )}

      {foundCustomer && activeStep === 2 && (
        <KeyTagConfigStep2
          onGoBack={handleResetState}
          onSubmit={handleStep2Submit}
          customer={foundCustomer}
          noKeyTags={keyTagsToConfigure.length === 0}
          keyTag={activeKeyTag}
          requestInProgress={requestInProgress}
          error={responseError}
        />
      )}

      {foundCustomer && activeStep === 3 && (
        <KeyTagConfigStep3
          customer={foundCustomer}
          configuredKeyTags={configuredKeyTags}
          requestInProgress={requestInProgress}
          customerIdCheckComponent={
            <CustomerIdCheck
              onCustomerFound={setFoundCustomer}
              isFirstStep={false}
            />
          }
        />
      )}
    </Dialog>
  );
};
