import { Campaign } from '@/types/campaigns';

export function prepareCampaignOptions(campaigns: Campaign[]) {
  if (campaigns) {
    return campaigns
      .filter((campaign) => campaign.active)
      .map((campaign) => campaign['@id']);
  }
  return [];
}
