import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Controller, useForm } from 'react-hook-form';

import {
  GetBulkLegacyWelcomeLetterParams,
  GetBulkLegacyWelcomeLetterParamsSchema,
} from '@/api/files';
import { getFormattedDate } from '@/helpers/dates';
import { getObjectKeys } from '@/helpers/tsHelpers';
import { usePrivateSettings, useSnackbar } from '@/hooks';
import { useGetBulkWelcomeLetterFromLegacy } from '@/modules/sendings/components/LegacyWelcomeLetter/hooks/useGetBulkWelcomeLetterFromLegacy';
import { generatePdfDownloadLink } from '@/utils/pdf';

type FormValues = GetBulkLegacyWelcomeLetterParams;

export const LegacyWelcomeLetter = () => {
  const snackbar = useSnackbar();
  const { servicesNames } = usePrivateSettings();

  const validationSchema = GetBulkLegacyWelcomeLetterParamsSchema;
  const supportedProducts =
    GetBulkLegacyWelcomeLetterParamsSchema.shape.product.Values;

  const mutationHandler = useGetBulkWelcomeLetterFromLegacy();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      product: supportedProducts.protection,
      regDate: getFormattedDate(new Date()) || '',
    },
    resolver: zodResolver(validationSchema),
  });

  const onSubmit = (values: FormValues) => {
    mutationHandler.mutate(values, {
      onSuccess: (response) => {
        if (response.status === 204) {
          snackbar({ type: 'info', message: 'Ingen data för vald period.' });
        } else {
          snackbar({ type: 'success', message: 'Välkomstbrev skapade.' });
          const wlServiceName = hasWhiteLabelServiceName(values.product)
            ? servicesNames[values.product]
            : values.product;

          generatePdfDownloadLink(
            response.data,
            `Välkomstbrev-${wlServiceName}-${values.regDate}`,
          );
        }
      },
      onError: () => {
        snackbar({ type: 'error', message: 'Något gick fel' });
      },
    });
  };

  const hasWhiteLabelServiceName = (
    product: string,
  ): product is keyof typeof servicesNames => {
    return servicesNames.hasOwnProperty(product);
  };

  return (
    <Card component="form" onSubmit={handleSubmit(onSubmit)}>
      <CardHeader title="Legacy Välkomstbrev" />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Controller
              name="product"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="product-label" shrink>
                    Tjänst
                  </InputLabel>
                  <Select
                    label="Tjänst"
                    labelId="product-label"
                    {...field}
                    onChange={(e) =>
                      field.onChange(
                        e.target
                          .value as GetBulkLegacyWelcomeLetterParams['product'],
                      )
                    }
                    placeholder="Välj tjänst"
                  >
                    {getObjectKeys(supportedProducts).map((product, idx) => {
                      return (
                        <MenuItem
                          value={product}
                          selected={idx === 0}
                          key={product}
                        >
                          {hasWhiteLabelServiceName(product)
                            ? servicesNames[product]
                            : product}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="regDate"
              control={control}
              render={({ field }) => (
                <DatePicker
                  label="Registreringsdatum (åååå-mm-dd)"
                  inputFormat="yyyy-MM-dd"
                  value={field.value}
                  onChange={(date) => {
                    const formattedDate = getFormattedDate(date);
                    if (formattedDate) {
                      field.onChange(formattedDate);
                    }
                  }}
                  InputProps={{
                    fullWidth: true,
                    error: Boolean(errors.regDate),
                    // @ts-ignore
                    'data-testid': 'legacy-welcome-letter-regDate',
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              )}
            />
          </Grid>
        </Grid>
        {mutationHandler.isPending && <LinearProgress />}
      </CardContent>
      <Divider />
      <CardActions>
        <Button
          variant="outlined"
          type="submit"
          disabled={mutationHandler.isPending}
        >
          Skapa brev
        </Button>
      </CardActions>
    </Card>
  );
};
