import React from 'react';

import { HighlightOff } from '@mui/icons-material';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import {
  Button,
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { formatISO } from 'date-fns';
import kebabCase from 'lodash/kebabCase';
import { useNavigate } from 'react-router-dom';

import { Can } from '@/components/Can';
import { DialogActions } from '@/components/DialogActions';
import { ServiceIcon } from '@/components/icons';
import { queryKeys } from '@/constants/queryKeys';
import { getTypeKey } from '@/helpers';
import getServicePricePlanPrice from '@/helpers/getServicePricePlanPrice';
import { usePrivateSettings, useSnackbar } from '@/hooks';
import { useAuth } from '@/hooks/useAuth';
import { Service } from '@/modules/common/hooks/useActiveServices';
import {
  ServicesWithPricePlans,
  getCamelServiceName,
} from '@/modules/common/utils/services/getServicesNames';
import { PascalCasedServiceNames } from '@/types/services';
import httpClient from '@/utils/httpClient';

type Props = {
  data: Service[];
  customerId: number;
  isActive?: boolean;
  dense?: boolean;
};

export const CustomerServicesTable = ({
  data,
  isActive,
  dense,
  customerId,
}: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const snackbar = useSnackbar();

  const { getAuthenticatedUserRoles } = useAuth();
  const userRoles = getAuthenticatedUserRoles();
  const { servicesStatuses, servicesNames, pricePlans } = usePrivateSettings()!;

  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [chosenService, setChosenService] = React.useState<{
    url: string;
    status: number;
    type: string;
    id: number;
  } | null>(null);

  const today = formatISO(new Date());

  const churnMutation = useMutation({
    mutationFn: async ({ url, status }: { url: string; status: number }) => {
      return await httpClient.put(url, {
        status,
        churnDate: today,
        billing: 0,
      });
    },
    onSuccess: () => {
      setConfirmOpen(false);
      snackbar({
        type: 'success',
        message: 'Churn lyckades.',
      });
      queryClient.invalidateQueries({
        queryKey: [queryKeys.getCustomerServices, customerId],
      });
    },
  });

  function onRowClick(to: string) {
    navigate(to);
  }

  const onChurnClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    service: Service,
  ) => {
    e.stopPropagation();
    setConfirmOpen(true);

    const churnKey = getTypeKey(
      servicesStatuses,
      getCamelServiceName(service['@type'] as PascalCasedServiceNames),
      'Churn',
    );

    setChosenService({
      url: service['@id'],
      status: parseInt(churnKey!, 10),
      type: service['@type'],
      id: service.id,
    });
  };

  const getServiceSpecificText = (
    type: Exclude<PascalCasedServiceNames, 'Homegate'>,
    data: Service,
  ) => {
    const strings = {
      Firetext: {
        title: 'Placering: ',
        data: data.placement,
      },
      CoProtection: {
        title: 'Pin: ',
        data: data.pin,
      },
      KeyTag: {
        title: 'Code: ',
        data: data.code,
      },
      Protection: {
        title: '',
        data: '',
      },
      Sticker: {
        title: 'Code: ',
        data: data.code,
      },
      ...(isActive && {
        Sinne: {
          title: 'IMEI: ',
          data: data.imei,
        },
      }),
    };
    return strings[type];
  };

  const isChurnable = (type: PascalCasedServiceNames) => {
    const types = ['KeyTag', 'Sticker'];

    return types.includes(type);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table size={dense ? 'small' : 'medium'}>
          <TableBody>
            {data.map((row, i) => (
              <StyledTableRow
                hover
                key={i}
                onClick={() =>
                  onRowClick(
                    `/products/${kebabCase(row['@type']).toLowerCase()}/${
                      row.id
                    }/edit`,
                  )
                }
              >
                <TableCell>
                  <StyledServiceIcon
                    fontSize="small"
                    serviceName={getCamelServiceName(
                      row['@type'] as PascalCasedServiceNames,
                    )}
                    isActive={!!isActive}
                  />
                  <InlineBlock>
                    {
                      servicesNames[
                        getCamelServiceName(
                          row['@type'] as PascalCasedServiceNames,
                        )
                      ]
                    }
                  </InlineBlock>
                </TableCell>
                <TableCell>{row.id}</TableCell>
                <TableCell>
                  {row['@type'] === 'Sinne' || row['@type'] === 'Homegate' ? (
                    <>
                      <b>{row.price?.priceWithVatInCurrency} kr</b> (inkl) /{' '}
                      <b>{row.price?.priceInCurrency} kr</b> (exkl)
                    </>
                  ) : (
                    <>
                      <b>{getServicePricePlanPrice(row, pricePlans)}</b> (inkl)
                      /{' '}
                      <b>
                        {
                          pricePlans[
                            getCamelServiceName(
                              row['@type'],
                            ) as ServicesWithPricePlans
                          ][row.pricePlanId]
                        }{' '}
                        kr
                      </b>{' '}
                      (exkl)
                    </>
                  )}
                </TableCell>
                <TableCell>
                  Status:{' '}
                  <b>
                    {
                      // @ts-ignore
                      servicesStatuses[getCamelServiceName(row['@type'])][
                        row.status
                      ]
                    }
                  </b>
                </TableCell>
                <TableCell>
                  {
                    getServiceSpecificText(
                      row['@type'] as Exclude<
                        PascalCasedServiceNames,
                        'Homegate' | 'Sinne'
                      >,
                      row,
                    )?.title
                  }
                  <InlineBlock>
                    {
                      getServiceSpecificText(
                        row['@type'] as Exclude<
                          PascalCasedServiceNames,
                          'Homegate' | 'Sinne'
                        >,
                        row,
                      )?.data
                    }
                  </InlineBlock>
                </TableCell>
                <TableCell>
                  {isActive &&
                    isChurnable(row['@type'] as PascalCasedServiceNames) && (
                      <Can
                        userRoles={userRoles!}
                        action="services.churn"
                        yes={() => (
                          <IconButton
                            size="small"
                            onClick={(e) => onChurnClick(e, row)}
                          >
                            <Tooltip title="Churn">
                              <HighlightOff color="error" />
                            </Tooltip>
                          </IconButton>
                        )}
                      />
                    )}
                </TableCell>

                <TableCell>
                  <Link
                    target="_blank"
                    href={`/products/${kebabCase(row['@type']).toLowerCase()}/${
                      row.id
                    }/edit`}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Tooltip title="Öppna i ny flik">
                      <LaunchOutlinedIcon />
                    </Tooltip>
                  </Link>
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={confirmOpen} onClose={() => setConfirmOpen(!confirmOpen)}>
        <DialogTitle>
          {`Vill du sätta status churn på tjänsten ${
            servicesNames[
              getCamelServiceName(
                chosenService?.type! as PascalCasedServiceNames,
              )
            ]
          } - ${chosenService?.id}?`}
        </DialogTitle>
        <Divider />
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => churnMutation.mutate(chosenService!)}
          >
            Ja
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              setConfirmOpen(false);
              setChosenService(null);
            }}
          >
            Nej
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const StyledTableRow = styled(TableRow)({
  cursor: 'pointer',
});

const StyledServiceIcon = styled(ServiceIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
  verticalAlign: 'bottom',
}));

const InlineBlock = styled('b')({
  display: 'inline-block',
});
