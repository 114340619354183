import { z } from 'zod';

const invalidValueMessage = 'Ogiltigt värde';

const validateBooleanSelect = z.union([z.literal('true'), z.literal('false')]);

const daysSchema = z.string().refine(
  (v) => {
    const n = Number(v);
    return !isNaN(n) && v?.length > 0 && n >= 0;
  },
  { message: invalidValueMessage },
);
const kickbackTypeSchema = z.string().refine(
  (v) => {
    const n = Number(v);
    return !isNaN(n) && v !== null && v !== undefined && n >= 0 && n <= 999999;
  },
  { message: invalidValueMessage },
);

const tickTypeSchema = z.string().refine(
  (v) => {
    const n = Number(v);

    return !isNaN(n) && v !== null && v !== undefined && n >= 0 && n <= 100;
  },
  { message: invalidValueMessage },
);

export const partnerFormSchema = z.object({
  name: z.string().nonempty('Fältet får inte vara tomt'),
  tick: validateBooleanSelect,
  kickback: validateBooleanSelect,
  daysWithTick: daysSchema,
  tickCoProtection: tickTypeSchema,
  tickHomegate: tickTypeSchema,
  tickProtection: tickTypeSchema,
  tickSinne: tickTypeSchema,
  daysWithKickback: daysSchema,
  kickbackCoProtection: kickbackTypeSchema,
  kickbackHomegate: kickbackTypeSchema,
  kickbackKeyTag: kickbackTypeSchema,
  kickbackProtection: kickbackTypeSchema,
  kickbackSinne: kickbackTypeSchema,
  kickbackSticker: kickbackTypeSchema,
});
