import { useMutation } from '@tanstack/react-query';

import { SveaBulkFeePayload, createBulkFeesInSvea } from '@/api/customers';

export const useCreateSveaBulkFees = () => {
  return useMutation({
    mutationFn: async (payload: SveaBulkFeePayload) =>
      createBulkFeesInSvea(payload),
  });
};
