import { SvgIcon, SvgIconProps } from '@mui/material';

export const CoProtectionIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 96 96" width="96px" height="96px">
      <g id="surface196134712">
        <path d="M 25 12 C 18.960938 12 14 16.960938 14 23 L 14 29 C 14 32.140625 15.347656 34.980469 17.484375 36.992188 C 15.347656 39 14 41.84375 14 45 L 14 51 C 14 54.152344 15.339844 56.992188 17.472656 59 C 15.339844 61.007812 14 63.847656 14 67 L 14 73 C 14 79.066406 18.9375 84 25 84 L 56.480469 84 C 55.335938 82.25 54.339844 80.257812 53.574219 78 L 25 78 C 22.242188 78 20 75.757812 20 73 L 20 67 C 20 64.242188 22.242188 62 25 62 L 52 62 L 52 56 L 25 56 C 22.242188 56 20 53.757812 20 51 L 20 45 C 20 42.242188 22.242188 40 25 40 L 71 40 C 72.6875 40 74.171875 40.84375 75.078125 42.125 C 76.5625 42.390625 77.914062 43.164062 78.804688 44.359375 C 79.742188 45.609375 80.855469 46.570312 82.003906 47.328125 L 82.003906 45 C 82.003906 41.839844 80.660156 38.996094 78.519531 36.988281 C 80.652344 34.976562 82 32.136719 82 29 L 82 23 C 82 16.960938 77.039062 12 71 12 Z M 25 18 L 71 18 C 73.796875 18 76 20.203125 76 23 L 76 29 C 76 31.796875 73.796875 33.996094 71.003906 34 C 71.003906 34 71 34 71 34 L 25 34 C 22.203125 34 20 31.796875 20 29 L 20 23 C 20 20.203125 22.203125 18 25 18 Z M 74 46 C 73.386719 46 72.777344 46.253906 72.398438 46.757812 C 67.097656 53.824219 58.085938 53.957031 58 53.957031 C 56.894531 53.957031 56 54.851562 56 55.957031 L 56 68 C 56 87.339844 73.339844 91.898438 73.515625 91.941406 C 73.675781 91.980469 73.835938 92 74 92 C 74.164062 92 74.324219 91.980469 74.484375 91.941406 C 74.660156 91.898438 92 87.339844 92 68 L 92 55.957031 C 92 54.855469 91.109375 53.964844 90.007812 53.957031 C 89.917969 53.957031 80.902344 53.824219 75.601562 46.757812 C 75.222656 46.253906 74.613281 46 74 46 Z M 74 46 " />
      </g>
    </SvgIcon>
  );
};
