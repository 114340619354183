import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { isAfter, parseISO } from 'date-fns';

import { SimpleCard } from '@/components/SimpleCard';
import { CustomerToDeleteData } from '@/types/customers';
import { getCustomerName } from '@/utils/customer';

type Props = {
  customer: CustomerToDeleteData;
  onDeleteCustomer: () => void;
};

export const CustomerCheck = ({ customer, onDeleteCustomer }: Props) => {
  const noActiveServices = customer.activeServiceTypes.length === 0;
  const noOpenedTasks =
    customer.tasks.filter((task) => task.status === '0').length === 0;
  const noActiveInvoicing = true; // TODO: Implement this after contact with Musa
  const noFutureFees = customer.fees.filter((fee) => {
    return isAfter(parseISO(fee.invoiceAt), new Date());
  });
  const canDeleteCustomer =
    noActiveServices && noOpenedTasks && noActiveInvoicing && noFutureFees;

  const checks = [
    {
      title: 'Inga aktiva tjänster',
      predicate: noActiveServices,
    },
    {
      title: 'Inga öppna ärenden',
      predicate: noOpenedTasks,
    },
    {
      title: 'Inga aktiv fakturering',
      predicate: noActiveInvoicing,
    },
    {
      title: 'Inga kommande avgifter',
      predicate: noFutureFees,
    },
  ];
  return (
    <SimpleCard
      title="Sammanfattning"
      cardContent={
        <>
          <Typography variant="h5" sx={{ ml: 2 }}>
            Kund: {getCustomerName(customer)} med kundnummer {customer.id}{' '}
            kommer härmed att arkiveras!
          </Typography>

          <List dense>
            {checks.map((check) => (
              <ListItem key={check.title}>
                <ListItemIcon>
                  {check.predicate ? (
                    <CheckCircleOutlineIcon color="success" />
                  ) : (
                    <ErrorOutlineIcon color="error" />
                  )}
                </ListItemIcon>
                <ListItemText>
                  <Typography variant="h6">{check.title}</Typography>
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </>
      }
      cardActions={
        canDeleteCustomer ? (
          <Button color="primary" variant="outlined" onClick={onDeleteCustomer}>
            Arkivera
          </Button>
        ) : null
      }
    />
  );
};
