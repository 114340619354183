import React from 'react';

import GetAppIcon from '@mui/icons-material/GetApp';
import { Button, Divider, Typography } from '@mui/material';

import * as AgreementsApi from '@/api/agreements';
import { DialogActions } from '@/components/DialogActions';
import { LabelContentAndAction } from '@/components/LabelContentAndAction';
import { Spacing } from '@/components/Spacing';
import { useSnackbar } from '@/hooks';
import { Customer } from '@/types/customers';
import { FiretextItem } from '@/types/services';

import { DialogContent } from '../helpers';
import { CustomerInfo } from './CustomerInfo';

type Props = {
  service: FiretextItem;
  customer: Customer;
  goBack: () => void;
  goToNextStep: () => void;
};

export const Step4 = ({ goToNextStep, goBack, service, customer }: Props) => {
  const snackbar = useSnackbar();

  async function handleAgreementDownload(agreement: string) {
    const agreementId = agreement.split('/')[2];
    try {
      await AgreementsApi.downloadFile({
        // @ts-ignore
        id: agreementId,
        filename: 'Avtal',
      });
    } catch {
      snackbar({
        type: 'error',
        message: 'Det gick inte att ladda ner avtalet',
      });
    }
  }

  const renderAgreement = (agreement: any) => {
    return (
      <React.Fragment key={agreement.id}>
        <Spacing spacing={1} />
        <LabelContentAndAction
          content={<Typography>{agreement.name}</Typography>}
          label={
            agreement.fileType === 'document' ? 'Skriftlig fil' : 'Ljudfil'
          }
          action={
            <GetAppIcon
              sx={(theme) => ({
                color: theme.palette.text.secondary,
                cursor: 'pointer',
                '&:hover': {
                  color: 'black',
                },
              })}
              onClick={() => handleAgreementDownload(agreement)}
            />
          }
        />

        <Spacing spacing={1} />
        <Divider />
      </React.Fragment>
    );
  };

  const renderContent = () => {
    if (!service.agreements?.length) {
      return <Typography>Inga avtal</Typography>;
    }

    return <>{service.agreements.map(renderAgreement)}</>;
  };

  return (
    <>
      <DialogContent>
        <CustomerInfo customer={customer} />
        {renderContent()}
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={goBack}>
          Tillbaka
        </Button>
        <Button color="primary" variant="contained" onClick={goToNextStep}>
          Gå vidare
        </Button>
      </DialogActions>
    </>
  );
};
