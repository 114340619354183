import React from 'react';

import { Grid, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { deleteCustomer } from '@/api/customers';
import { Breadcrumbs } from '@/components/Breadcrumbs';
import { ConfirmationDialog } from '@/components/ConfirmationDialog';
import { PageHeader } from '@/components/PageHeader';
import { useSnackbar } from '@/hooks';
import { CustomerToDeleteData } from '@/types/customers';
import { getCustomerName } from '@/utils/customer';

import { CustomerCheck } from './CustomerCheck';
import { CustomerSearch } from './CustomerSearch';

const crumbsConfig = [{ label: 'Verktyg' }, { label: 'Kundborttagning' }];

export const CustomersDelete = () => {
  const queryClient = useQueryClient();
  const snackbar = useSnackbar();

  const [customerToDelete, setCustomerToDelete] =
    React.useState<CustomerToDeleteData | null>(null);
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] =
    React.useState(false);

  const deleteCustomerMutation = useMutation({
    mutationFn: (customerId: number) => deleteCustomer(customerId),
    onSuccess: () => {
      setCustomerToDelete(null);
      setDeleteConfirmationVisible(false);
      queryClient.refetchQueries({
        queryKey: ['customersToDelete'],
      });
      snackbar({
        type: 'success',
        message: 'Kunden har arkiverats',
      });
    },
    onError: () => {
      setDeleteConfirmationVisible(false);
      snackbar({
        type: 'error',
        message: 'Det gick inte att arkivera kunden',
      });
    },
  });

  function showDeleteConfirmationDialog() {
    setDeleteConfirmationVisible(true);
  }

  function handleDeleteCustomer() {
    if (!customerToDelete) return;
    deleteCustomerMutation.mutate(customerToDelete.id);
  }

  return (
    <>
      <Breadcrumbs crumbs={crumbsConfig} />
      <PageHeader title="Kundborttagning" margin />

      <Grid container spacing={3}>
        <Grid item xs={6}>
          <CustomerSearch
            onSelectCustomer={(customer) => setCustomerToDelete(customer)}
          />
        </Grid>

        <Grid item xs={6}>
          {customerToDelete && (
            <CustomerCheck
              customer={customerToDelete}
              onDeleteCustomer={showDeleteConfirmationDialog}
            />
          )}
        </Grid>
      </Grid>

      {customerToDelete && (
        <ConfirmationDialog
          isOpened={deleteConfirmationVisible}
          onClose={() => setDeleteConfirmationVisible(false)}
          onConfirm={handleDeleteCustomer}
          dialogText={
            <Typography component="span">
              Är du säker på att du vill ta bort{' '}
              <b>{getCustomerName(customerToDelete)}</b>?
            </Typography>
          }
          confirmationButtonText="Ja"
        />
      )}
    </>
  );
};
