import { Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';
import { Form } from 'react-final-form';

import { DatePicker } from '@/components/form';
import { AutoSave } from '@/components/form/AutoSave';
import { getFormattedDate } from '@/helpers/dates';

import { SinneEconomyFormValues } from './SinneEconomyOverview';

type Props = {
  save: (values: SinneEconomyFormValues) => void;
  filter: SinneEconomyFormValues;
};

export const Datepicker = ({ save, filter }: Props) => {
  const submitHandler = ({ end }: SinneEconomyFormValues) => {
    const formattedDate = getFormattedDate(end);
    const payload = formattedDate ? { end: formattedDate } : {};
    save(payload);
  };
  return (
    <Grid marginBottom={2}>
      <Card>
        <CardHeader title="Visa värde av köpta enheter vid tidpunkt" />
        <Divider />
        <CardContent>
          <Form onSubmit={submitHandler} initialValues={filter}>
            {() => (
              <>
                <AutoSave
                  debounce={500}
                  save={submitHandler}
                  debounced={['end']}
                />

                <DatePicker
                  label="Visa värde vid tidpunkt"
                  name="end"
                  showClearButton
                />
              </>
            )}
          </Form>
        </CardContent>
      </Card>
    </Grid>
  );
};
