import { useEffect, useState } from 'react';

import {
  Box,
  Card,
  FormControl,
  Grid,
  LinearProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useQuery } from '@tanstack/react-query';
import {
  CategoryScale,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
} from 'chart.js';
import { ReactChart } from 'chartjs-react';
import {
  differenceInMonths,
  endOfMonth,
  isAfter,
  isBefore,
  startOfMonth,
  subMonths,
} from 'date-fns';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';

import * as ServicesApi from '@/api/services';
import { getFormattedDate } from '@/helpers/dates';
import { getCamelCaseServiceName } from '@/helpers/tsHelpers';
import { ServiceName } from '@/types/services';

import {
  pickFieldsForProductStats,
  prepareBasicStatsChartData,
  prepareBasicStatsTableData,
  prepareDetailedInfoTablesData,
} from '../../utils/stats';
import { BasicStatsTable, DetailedInfoTable } from './components';

const SELECT_OPTIONS = [
  { id: 1, value: 'thisMonth', label: 'Den här månaden' },
  { id: 2, value: 'lastMonth', label: 'Förra månaden' },
  { id: 3, value: 'custom', label: 'Anpassad' },
];

ReactChart.register(
  LinearScale,
  LineController,
  CategoryScale,
  PointElement,
  LineElement,
  Tooltip,
);

type Props = {
  serviceName: ServiceName;
};

type Resolution = 'daily' | 'weekly' | 'monthly' | 'quarter' | 'yearly';

type FetchParams = {
  start: string;
  end: string;
  resolution: Resolution;
};

export const Stats = ({ serviceName }: Props) => {
  const [period, setPeriod] = useState('thisMonth');
  const [startDate, setStartDate] = useState(startOfMonth(new Date()));
  const [endDate, setEndDate] = useState(endOfMonth(new Date()));
  const [fetchParams, setFetchParams] = useState<FetchParams | null>(null);

  const {
    isInitialLoading: isLoading,
    data,
    isError,
  } = useQuery({
    queryKey: ['stats', fetchParams, serviceName],

    queryFn: async () =>
      ServicesApi.getServiceStats({
        serviceName: getCamelCaseServiceName(serviceName),
        ...fetchParams!,
      }),

    enabled: !!fetchParams,
  });

  const chartData = data?.basic?.period
    ? prepareBasicStatsChartData(data.basic.period)
    : null;

  const basicStatsTableData = data?.basic?.summary
    ? prepareBasicStatsTableData(pickFieldsForProductStats(data.basic.summary))
    : null;

  const resellerTableData = data?.reseller
    ? prepareDetailedInfoTablesData({
        rawData: data.reseller,
        serviceName,
        firstHeaderName: 'Återförsäljare',
      })
    : null;

  const salesRepTableData = data?.salesRep
    ? prepareDetailedInfoTablesData({
        rawData: data.salesRep,
        serviceName,
        firstHeaderName: 'Säljare',
      })
    : null;

  const pricePlanTableData = data?.pricePlanId
    ? prepareDetailedInfoTablesData({
        rawData: data.pricePlanId,
        serviceName,
        firstHeaderName: 'Prisplan',
      })
    : null;

  useEffect(() => {
    if (period === 'thisMonth') {
      setStartDate(startOfMonth(new Date()));
      setEndDate(endOfMonth(new Date()));
    }

    if (period === 'lastMonth') {
      setStartDate(startOfMonth(subMonths(new Date(), 1)));
      setEndDate(endOfMonth(subMonths(new Date(), 1)));
    }
  }, [period]);

  useEffect(() => {
    if (startDate && endDate) {
      const monthsDiff = differenceInMonths(
        new Date(startDate),
        new Date(endDate),
      );

      let resolution: Resolution;

      if (monthsDiff <= 3) {
        resolution = 'daily';
      } else if (monthsDiff > 3 && monthsDiff <= 12) {
        resolution = 'weekly';
      } else if (monthsDiff > 12 && monthsDiff <= 36) {
        resolution = 'monthly';
      } else if (monthsDiff > 36 && monthsDiff <= 60) {
        resolution = 'quarter';
      } else {
        resolution = 'yearly';
      }

      const startDateAsString = getFormattedDate(startDate);
      const endDateAsString = getFormattedDate(endDate);

      if (startDateAsString && endDateAsString) {
        setFetchParams({
          start: startDateAsString,
          end: endDateAsString,
          resolution,
        });
      }
    }
  }, [startDate, endDate]);

  function handlePeriodChange(event: SelectChangeEvent<string>) {
    setPeriod(event.target.value);
  }

  function handleStartDateChange(date: Date | null) {
    if (date === null) {
      return;
    }
    const start = new Date(date);
    const end = new Date(endDate);
    if (isBefore(start, end) || isEqual(start, end)) {
      setStartDate(date);
      setPeriod('custom');
    }
  }

  function handleEndDateChange(date: Date | null) {
    if (date === null) {
      return;
    }
    const start = new Date(startDate);
    const end = new Date(date);
    if (isAfter(end, start) || isEqual(end, start)) {
      setEndDate(date);
      setPeriod('custom');
    }
  }

  if (isError) {
    return (
      <Typography align="center" variant="h3" color="error">
        Något gick fel
      </Typography>
    );
  }

  return (
    <>
      <Card
        sx={(theme) => ({
          width: '100%',
          padding: theme.spacing(2),
          marginTop: theme.spacing(3),
        })}
      >
        <Grid
          container
          justifyContent="space-between"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Grid item>
            <Typography variant="h6" component="h1">
              Grundstatistik
            </Typography>
          </Grid>

          <Grid item>
            <Grid container alignItems="center" spacing={3}>
              <Grid item>
                <DatePicker
                  label="Från"
                  onChange={(date) => handleStartDateChange(date)}
                  value={startDate}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Grid>

              <Grid item>
                <DatePicker
                  label="Till"
                  onChange={(date) => handleEndDateChange(date)}
                  value={endDate}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Grid>

              <Grid item>
                <FormControl sx={{ minWidth: 160 }} variant="outlined">
                  <Select value={period} onChange={handlePeriodChange}>
                    {SELECT_OPTIONS.map((item) => (
                      <MenuItem key={item.id} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {isLoading && <LinearProgress />}

        <Box
          component="section"
          sx={(theme) => ({
            marginTop: theme.spacing(4),
          })}
        >
          {chartData && (
            <ReactChart
              type="line"
              data={chartData}
              options={{
                maintainAspectRatio: false,
              }}
              height={230}
            />
          )}
        </Box>

        <Box
          component="section"
          sx={(theme) => ({ marginTop: theme.spacing(4) })}
        >
          {basicStatsTableData && (
            <BasicStatsTable data={basicStatsTableData} />
          )}
        </Box>
      </Card>

      <Card
        sx={(theme) => ({
          width: '100%',
          padding: theme.spacing(2),
          marginTop: theme.spacing(3),
        })}
      >
        <Box
          component="section"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6" component="h1">
            Visar detaljerad info
          </Typography>
        </Box>

        {isLoading && <LinearProgress />}

        <Box
          component="section"
          sx={(theme) => ({ marginTop: theme.spacing(4) })}
        >
          {resellerTableData && <DetailedInfoTable data={resellerTableData} />}
        </Box>

        <Box
          component="section"
          sx={(theme) => ({ marginTop: theme.spacing(4) })}
        >
          {salesRepTableData && <DetailedInfoTable data={salesRepTableData} />}
        </Box>

        <Box
          component="section"
          sx={(theme) => ({ marginTop: theme.spacing(4) })}
        >
          {pricePlanTableData && (
            <DetailedInfoTable data={pricePlanTableData} />
          )}
        </Box>
      </Card>
    </>
  );
};

Stats.propTyps = {
  serviceName: PropTypes.string.isRequired,
};
