import { useQuery } from '@tanstack/react-query';

import { getSinneDeviceSimcardInfo } from '@/api/sinne';

type Args = {
  sinneId: number;
};
export const useGetSimcardInfo = (sinneId: Args['sinneId']) => {
  return useQuery({
    queryKey: ['getSimcardInfo', sinneId],
    queryFn: () => {
      return getSinneDeviceSimcardInfo(sinneId);
    },
  });
};
