import React from 'react';

import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Container,
  Divider,
  Grid,
  LinearProgress,
  List,
  Paper,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import upperFirst from 'lodash/upperFirst';
import { Helmet } from 'react-helmet';

import { fetchUsers } from '@/api';
import { BasicAlert } from '@/components/BasicAlert';
import { Breadcrumbs } from '@/components/Breadcrumbs';
import { ConfirmationDialog } from '@/components/ConfirmationDialog';
import { PageHeader } from '@/components/PageHeader';
import { useSnackbar } from '@/hooks';
import { UserRole } from '@/types/auth';
import { ApiCollectionResponse } from '@/types/common';
import httpClient from '@/utils/httpClient';

import { managerRoles, teamMemberRoles } from '../../const';
import { useFetchTeams } from '../../hooks/useFetchTeams';
import { Team } from '../../types';
import { Manager } from '../Manager';
import { AddMembersCard } from './AddMembersCard';
import { ChangeManagerCard } from './ChangeManagerCard';
import { CreateTeamCard } from './CreateTeamCard';
import { TeamMemberListItem } from './TeamMemberListItem';

type TeamUser = {
  '@id': string;
  name: string;
  roles: UserRole[];
};

export const TeamWrapper = () => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] =
    React.useState(false);
  const [teamToDelete, setTeamToDelete] = React.useState('');
  const snackbar = useSnackbar();
  const {
    data: teams,
    isFetching: isFetchingTeams,
    isError: errorTeams,
    isSuccess,
  } = useFetchTeams();

  const {
    data: userOptions,
    isLoading: loadingUsers,
    isError: errorUsers,
  } = useQuery({
    queryKey: ['getUsers'],

    queryFn: async () => {
      const data = await fetchUsers<ApiCollectionResponse<TeamUser>>({
        properties: {
          name: true,
          roles: true,
        },
      });

      const managers = data['hydra:member']
        .filter((user) =>
          user.roles.some((role) => managerRoles.includes(role)),
        )
        .map((user) => ({
          value: user['@id'],
          text: user.name,
        }));

      const members = data['hydra:member']
        .filter((user) => {
          return user.roles.some((role) => teamMemberRoles.includes(role));
        })
        .map((user) => ({
          value: user['@id'],
          text: user.name,
        }));

      return { members, managers };
    },
  });

  const teamOptions = React.useMemo(() => {
    if (teams) {
      return teams.map((team) => ({
        text: upperFirst(team.name),
        value: team['@id'],
      }));
    }
  }, [teams]);

  const deleteTeamMutation = useMutation({
    mutationFn: async (iri: string) => await httpClient.delete(iri),
    onSuccess: (_: any, iri) => {
      queryClient.setQueryData<Team[]>(['amsTeams'], (oldData) => {
        const index = oldData?.findIndex((t) => t['@id'] === iri);
        if (index === undefined || oldData === undefined) return oldData;
        const updatedData = [...oldData];
        updatedData.splice(index, 1);
        return updatedData;
      });
      snackbar({ type: 'success', message: 'Teamet togs bort.' });
    },
    onError: () => {
      queryClient.refetchQueries({
        queryKey: ['amsTeams'],
      });
      snackbar({
        type: 'error',
        message: 'Misslyckades ta bort team.',
      });
    },
    onSettled: () => {
      setTeamToDelete('');
    },
  });

  return (
    <>
      <Helmet title="Teams" />
      <Breadcrumbs
        crumbs={[{ label: 'Account Manager System' }, { label: 'Teams' }]}
      />
      <PageHeader margin title="Teams" />
      <Container maxWidth="lg" disableGutters style={{ margin: 0 }}>
        <Grid container spacing={2}>
          <Grid
            container
            item
            xs={12}
            lg={8}
            spacing={2}
            style={{ height: '100%' }}
          >
            {isFetchingTeams ? (
              <LinearProgress />
            ) : errorTeams ? (
              <BasicAlert />
            ) : (
              <>
                {teams!.map((team) => (
                  <Grid item xs={12} key={team['@id']}>
                    <Paper>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMore />}
                          style={{ alignItems: 'center' }}
                        >
                          <Typography
                            variant="h5"
                            style={{ flexBasis: '33.33%', flexShrink: 0 }}
                          >
                            Team {upperFirst(team.name)}
                          </Typography>
                          <Manager managerIri={team.manager} />
                        </AccordionSummary>
                        <Divider />
                        <AccordionDetails
                          style={{ alignItems: 'end', flexDirection: 'column' }}
                        >
                          <List
                            style={{ width: '100%', paddingBottom: '1rem' }}
                          >
                            {team.members.map((member) => (
                              <TeamMemberListItem
                                key={member}
                                id={member}
                                members={team.members}
                                team={team['@id']}
                              />
                            ))}
                          </List>

                          <Button
                            onClick={() => {
                              setTeamToDelete(team['@id']);
                              setConfirmDeleteModalOpen(true);
                            }}
                            variant="contained"
                            style={{
                              backgroundColor: theme.palette.error.light,
                              color: theme.palette.getContrastText(
                                theme.palette.error.light,
                              ),
                            }}
                          >
                            Ta bort team
                          </Button>
                        </AccordionDetails>
                      </Accordion>
                    </Paper>
                  </Grid>
                ))}
                {teamToDelete && (
                  <ConfirmationDialog
                    dialogTitle="Ta bort team"
                    dialogText={`Är du säker på att du vill ta bort team ${teams!
                      .find((team) => team['@id'] === teamToDelete)
                      ?.name?.toUpperCase()}?`}
                    isOpened={confirmDeleteModalOpen}
                    onClose={() => setConfirmDeleteModalOpen(false)}
                    onConfirm={() => {
                      setConfirmDeleteModalOpen(false);
                      deleteTeamMutation.mutate(teamToDelete);
                    }}
                  />
                )}
              </>
            )}
          </Grid>
          <Grid
            container
            spacing={2}
            item
            xs={12}
            lg={4}
            style={{ height: '100%' }}
          >
            {loadingUsers || isFetchingTeams ? (
              <Grid item xs={12}>
                <LinearProgress />
              </Grid>
            ) : errorUsers || !isSuccess ? (
              <BasicAlert />
            ) : (
              <>
                <Grid item xs={12}>
                  <AddMembersCard
                    teamOptions={teamOptions}
                    memberOptions={userOptions?.members}
                    teams={teams}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CreateTeamCard
                    teamNames={teams.map((team) => team.name.toUpperCase())}
                    managerOptions={userOptions?.managers}
                    memberOptions={userOptions?.members}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ChangeManagerCard
                    teamOptions={teamOptions}
                    managerOptions={userOptions?.managers}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
