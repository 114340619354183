import { useContext } from 'react';

import { Check, ErrorOutline } from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Button,
  CardActions,
  CardContent,
  CircularProgress,
  Divider,
  Typography,
} from '@mui/material';
import { useSelector } from '@xstate/react';

import { LabelContentAndAction } from '@/components/LabelContentAndAction';
import { SinneConfigContext } from '@/modules/sinne/components/sinneConfigFlow/context/SinneConfigContextProvider';
import { SIM_CARD_ACTIVE } from '@/modules/sinne/components/sinneConfigFlow/context/sinneConfigStateMachine';

export const SimCardSyncronization = () => {
  const machine = useContext(SinneConfigContext);

  const state = useSelector(machine, (state) => state);
  const { simCard, scannedImei } = state.context;

  const imeiFromApi = simCard.data?.imei;
  const simSubscriptionStatus = simCard.data?.simSubscriptionStatus;
  const activeButNoImei =
    simSubscriptionStatus === SIM_CARD_ACTIVE && !imeiFromApi;

  const simcardCheckOk = state.matches('SIM_CARD_SYNCRONIZATION.ACTIVE');
  const isLoading = state.matches('SIM_CARD_SYNCRONIZATION.loading');
  const isServerError = state.matches(
    'SIM_CARD_SYNCRONIZATION.error.serverError',
  );
  const isNoDetectedImeiError = state.matches(
    'SIM_CARD_SYNCRONIZATION.error.noDetectedImei',
  );
  const isSimInactiveError = state.matches(
    'SIM_CARD_SYNCRONIZATION.error.simInactive',
  );
  const isError = isServerError || isNoDetectedImeiError || isSimInactiveError;

  const pollRetries = state.context.simCard.retries || 1;
  const maxPollRetries = state.context.maxRetries;

  return (
    <>
      <CardContent>
        {!isNoDetectedImeiError && activeButNoImei && (
          <Alert severity="warning">
            <AlertTitle>
              Simkortet är aktivt men har ännu inte registrerat enhetens IMEI.
            </AlertTitle>
            {`Kontrollerar igen. Försök ${pollRetries} av ${maxPollRetries}`}
          </Alert>
        )}
        {isNoDetectedImeiError && (
          <Alert severity="error">
            <AlertTitle>
              Telia har inte kunnat identifiera enhetens IMEI.
            </AlertTitle>
          </Alert>
        )}

        {isSimInactiveError && (
          <Alert severity="error">
            <AlertTitle>Simkortet är inte aktivt hos Telia.</AlertTitle>
          </Alert>
        )}
        {isServerError && (
          <Alert severity="error">
            <AlertTitle>
              Något fick fel vid anrop till Telia. (Serverfel)
            </AlertTitle>
          </Alert>
        )}
        {simcardCheckOk && (
          <Alert severity="success" data-testid="alert-success">
            <AlertTitle>Simkortet är aktivt hos Telia.</AlertTitle>
          </Alert>
        )}
        <LabelContentAndAction
          label="Angiven IMEI:"
          content={<Typography>{scannedImei}</Typography>}
          action={<Check color="success" />}
        />
        <LabelContentAndAction
          label="Identifierad IMEI enligt API:"
          content={<Typography>{imeiFromApi}</Typography>}
          action={
            imeiFromApi ? (
              <Check color="success" />
            ) : (
              <ErrorOutline color="error" />
            )
          }
        />
        <LabelContentAndAction
          label="Identifierad IMEI enligt operatören:"
          content={
            simcardCheckOk ? (
              <Typography>{imeiFromApi}</Typography>
            ) : (
              <Typography color={(t) => t.palette.error.main}>
                {imeiFromApi}
              </Typography>
            )
          }
          action={
            isLoading ? (
              <CircularProgress size={20} />
            ) : isError ? (
              <ErrorOutline color="error" />
            ) : (
              simcardCheckOk && <Check color="success" />
            )
          }
        />
      </CardContent>
      <Divider />
      <CardActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          disabled={!simcardCheckOk}
          onClick={() => machine.send('PROCEED')}
        >
          Nästa
        </Button>
        {isError && (
          <Button
            variant="outlined"
            onClick={() =>
              machine.send({
                type: 'RETRY_SIM_CARD_SYNCRONIZATION',
              })
            }
          >
            Försök igen
          </Button>
        )}
      </CardActions>
    </>
  );
};
