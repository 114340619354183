import React from 'react';

import { Card, CardContent, CardHeader, Divider } from '@mui/material';

import { PredefinedTemplatesUploadForm } from './PredefinedTemplatesUploadForm';

export const PredefinedTemplatesCard = () => {
  return (
    <Card>
      <CardHeader title="Ladda upp en mall" />
      <Divider />
      <CardContent>
        <PredefinedTemplatesUploadForm />
      </CardContent>
    </Card>
  );
};
