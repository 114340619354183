import React from 'react';

import { useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import { Drawer } from './Drawer';

const rightSidebarWidth = 400;
const smallRightSidebarWidth = 56;

type Props = {
  pageContent: React.ReactNode;
  drawerContent: React.ReactNode;
};

export const PageWithDrawer = ({ pageContent, drawerContent }: Props) => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const [open, setOpen] = React.useState(smallScreen);

  React.useEffect(() => {
    setOpen(smallScreen);
  }, [smallScreen]);

  return (
    <PageWithDrawerWrapper open={open}>
      {pageContent}
      <Drawer open={open} setOpen={() => setOpen(!open)}>
        {drawerContent}
      </Drawer>
    </PageWithDrawerWrapper>
  );
};

const PageWithDrawerWrapper = styled('div')<{ open: boolean }>(
  ({ theme, open }) => ({
    paddingRight: open ? rightSidebarWidth : smallRightSidebarWidth,
    transition: theme.transitions.create(['padding'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  }),
);
