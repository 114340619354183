import React from 'react';

import { useMutation, useQuery } from '@tanstack/react-query';
import omit from 'lodash/omit';
import pick from 'lodash/pick';

import {
  createCustomer,
  findCustomerByPIN,
  updateCustomer,
} from '@/api/customers';
import { addService } from '@/api/services';
import { queryKeys } from '@/constants/queryKeys';
import { getTypeKey } from '@/helpers';
import { getElementFromArray } from '@/helpers/getElementFromArray';
import { MutableCustomerFields } from '@/types/customers';
import { GenericHttpError } from '@/types/errors';
import { CamelCaseServiceName, Template } from '@/types/services';

import { usePrivateSettings } from './usePrivateSettings';
import { useSnackbar } from './useSnackbar';

const CUSTOMER_FIELDS = [
  'accountManager',
  'address',
  'address2',
  'autogiroAgreement',
  'autogiroSource',
  'bankAccount',
  'bankName',
  'category',
  'city',
  'companyName',
  'email',
  'firstName',
  'id',
  'invoiceEmail',
  'lastName',
  'mobile',
  'partnerCompany',
  'partnerDate',
  'phone',
  'pin',
  'zip',
] as const;

const getCustomerInformationFields = (values: any) =>
  pick(values, CUSTOMER_FIELDS);

type Values = {
  // contractMonths: number;
  // freeMonths: number;
  // kamId: number;
  // noticePeriod: number;
  abTest: string;
  accountManager?: string | null;
  address: string;
  address2: string;
  addressList: string;
  adminNote: string;
  campaign: string;
  category: 2 | 3;
  city: string;
  companyName: string;
  customer?: string;
  email: string;
  firstName: string;
  lastName: string;
  leadSource: string;
  mobile: string;
  partnerCompany?: string;
  partnerDate?: string;
  phone: string;
  pin: string;
  regDate: string;
  reseller: string;
  salesRep: string;
  tb: number;
  templates: Template[];
  userId?: string | number;
  zip: string;
};

type CustomerFields = (typeof CUSTOMER_FIELDS)[number];

type SalesDataFields = Omit<
  MutableCustomerFields,
  CustomerFields | 'contractMonths' | 'freeMonths' | 'noticePeriod'
> & {
  status?: number;
  customer: string;
} & Partial<Values>;

type CustomerMutationPayload = {
  values: MutableCustomerFields;
  mutationType: 'create' | 'update';
} & (
  | {
      mutationType: 'create';
      userId?: string | number;
    }
  | {
      mutationType: 'update';
      userId: string | number;
    }
);

const customerMutationFn = ({
  userId,
  values,
  mutationType,
}: CustomerMutationPayload) => {
  const payload = getCustomerInformationFields(values);
  payload.category = parseInt(payload.category, 10);

  if (mutationType === 'update') {
    return updateCustomer({
      ...payload,
      id: userId,
    });
  }

  // TODO: Improve typing after migrating customers API to TypeScript
  // @ts-ignore
  return createCustomer({
    ...payload,
  });
};

export const useAddServiceToCustomer = (serviceName: CamelCaseServiceName) => {
  const snackbar = useSnackbar();
  const [values, setValues] = React.useState<Values | null>(null);
  const [pin, setPin] = React.useState<string | null>(null);
  const [mutatedCustomer, setMutatedCustomer] = React.useState<{
    id: string;
    iriId: string;
  } | null>(null);
  const [errors, setErrors] = React.useState<any[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const { servicesStatuses } = usePrivateSettings();

  const customerMutation = useMutation({
    mutationFn: customerMutationFn,
    onSuccess: (response, data) => {
      if (data.mutationType === 'create') {
        setMutatedCustomer({
          id: response.id,
          iriId: response['@id'],
        });
      } else if (data.mutationType === 'update') {
        setMutatedCustomer({
          id: response.data.id,
          iriId: response.data['@id'],
        });
      }
      if (response?.data?.sveaError) {
        snackbar({
          type: 'warning',
          message: `Ändringar sparade.
                OBS! ${response.data.sveaError}`,
          autoHide: false,
        });
      }
    },
    onError: (error: GenericHttpError) =>
      setErrors(errors.concat([error.data])),
  });

  const createServiceMutation = useMutation({
    mutationFn: (params: { serviceName: CamelCaseServiceName; payload: any }) =>
      addService(params),
    onError: (error: GenericHttpError) =>
      setErrors(errors.concat([error.data])),
    onSuccess: () => setIsLoading(false),
  });

  const createServiceFromTemplates = (
    tmpls: Template[],
    salesDataFields: SalesDataFields,
  ) => {
    setIsLoading(true);

    salesDataFields.status = parseInt(
      getTypeKey(servicesStatuses, serviceName, 'Nyregistrerad')!,
      10,
    );

    for (const [i, template] of tmpls.entries()) {
      delete template['@id'];
      delete template.id;

      const { templates, ...salesDataPayload } = salesDataFields;
      const payload = { ...template, ...salesDataPayload };

      if (i !== 0) {
        payload.tb = 0;
      }
      createServiceMutation.mutate({ payload, serviceName });
    }
  };

  // Check if customer exists
  const { data: customer } = useQuery({
    queryKey: [queryKeys.getCustomerByPin, pin],
    queryFn: () => findCustomerByPIN(pin!),
    enabled: !!pin,
  });

  React.useEffect(() => {
    // If there is an existing customer, update it
    if (customer?.length === 1 && !!values) {
      const clonedValues = { ...values };

      // Some values should not or can't be overwritten on update

      if (getElementFromArray(customer[0]).accountManager)
        delete clonedValues.accountManager;
      if (getElementFromArray(customer[0]).partnerDate) {
        delete clonedValues.partnerDate;
        delete clonedValues.partnerCompany;
      }

      customerMutation.mutate({
        userId: getElementFromArray(customer[0]).id,
        values: clonedValues,
        mutationType: 'update',
      });
    }

    // If new customer, create it
    if (customer?.length === 0 && !!values) {
      customerMutation.mutate({
        values,
        mutationType: 'create',
      });
    }
  }, [customer, values]);

  React.useEffect(() => {
    if (mutatedCustomer && !!values) {
      const salesDataFields = omit(values, [
        ...CUSTOMER_FIELDS,
        'contractMonths',
        'freeMonths',
        'noticePeriod',
      ]) as SalesDataFields;

      salesDataFields.customer = mutatedCustomer.iriId;

      createServiceFromTemplates(values.templates, salesDataFields);
    }
  }, [mutatedCustomer, values]);

  const handleSubmit = (formValues: Values) => {
    setIsLoading(true);
    setPin(formValues.pin);
    setValues(formValues);
  };

  const { isSuccess: createServiceSuccess } = createServiceMutation;
  const { isSuccess: createUserSuccess } = customerMutation;

  const allSuccess = createUserSuccess && createServiceSuccess;

  return {
    handleSubmit,
    isLoading,
    allSuccess,
    mutatedCustomer,
    createServiceFromTemplates,
    createServiceSuccess,
    errors,
  };
};
