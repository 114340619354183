import { useGetPrices } from '@/hooks/useGetPrices';
import { formatCurrency } from '@/modules/common/utils/currency';
import { ServiceName } from '@/types/services';

type Args = {
  active: boolean;
  serviceName: ServiceName;
};

export const useGetPriceOptions = (args: Args) => {
  const { data, isLoading, isSuccess, isError } = useGetPrices(args);
  const priceOptions =
    data?.map((price) => ({
      text: `${formatCurrency(
        price.priceWithVatInCurrency,
      )} (inkl) / ${formatCurrency(price.priceInCurrency)} (exkl)`,
      value: `/prices/${price.id}`,
    })) || [];

  return { priceOptions, isLoading, isSuccess, isError };
};
