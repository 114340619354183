import { useMutation } from '@tanstack/react-query';

import { UpdateTaskPayload, updateTask } from '@/api/tasks';

export const useUpdateTask = () => {
  return useMutation({
    mutationFn: (task: UpdateTaskPayload) => {
      return updateTask(task);
    },
  });
};
