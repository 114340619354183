import { PageWrapper } from '@/components/PageWrapper';
import { Billing } from '@/modules/economy/components/Billing/Billing';

export function BillingPage() {
  return (
    <PageWrapper
      pageTitle="Fakturering"
      breadCrumbs={[{ label: 'Ekonomi' }, { label: 'Fakturering' }]}
    >
      <Billing />
    </PageWrapper>
  );
}
