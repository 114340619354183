import { AuthRule } from '@/constants/AuthorizationRules';
import useUserRoles from '@/hooks/useUserRoles';
import { UserRole } from '@/types/auth';
import { checkRolesAccess } from '@/utils/authorization';

type Props = {
  userRoles?: UserRole[];
  action: AuthRule;
  yes: () => any | null;
  no?: () => any | null;
};

export const Can = ({
  userRoles,
  action,
  yes = () => null,
  no = () => null,
}: Props) => {
  const roles = userRoles ? userRoles : useUserRoles();
  return checkRolesAccess(roles, action) ? yes() : no();
};
