import { PageWrapper } from '@/components';
import { Tabs } from '@/components/Tabs/Tabs';
import { SveaBulkFeeFormCard } from '@/modules/economy/components/SveaBank/SveaBulkFeeFormCard';

const tabMap = [
  {
    title: 'Avgifter till Fenix',
    view: () => <SveaBulkFeeFormCard />,
  },
];

export const SveaBank = () => {
  return (
    <PageWrapper
      pageTitle="Svea Bank"
      breadCrumbs={[{ label: 'Ekonomi' }, { label: 'Svea Bank' }]}
    >
      <Tabs tabs={tabMap} />
    </PageWrapper>
  );
};
