import React from 'react';

import { Box, Button, Grid, Typography } from '@mui/material';

import { SimpleCard } from '@/components/SimpleCard';

import { WatchListForm } from './WatchListForm';
import { WatchListInfo } from './WatchListInfo';

type Props = {
  pin: string;
  onboardedAt: string | null;
  onSubmit: (v: any) => void;
};

export type WatchListProps = { open: boolean; type: 'add' | 'remove' | '' };

export const WatchListCard = ({ pin, onboardedAt, onSubmit }: Props) => {
  const [watchListFormProps, setWatchListFormProps] =
    React.useState<WatchListProps>({
      open: false,
      type: '',
    });

  const addOnboardedAtDate = async () => {
    onSubmit({ onboardedAt: new Date().toISOString() });
  };

  const resetOnboardedAtDate = async () => {
    onSubmit({ onboardedAt: null });
  };

  return (
    <SimpleCard
      marginBottom="0"
      titleComponent={
        <Box display="flex" alignItems="center">
          <Typography variant="h5">Bevakningslista</Typography>

          <Grid container spacing={2} justifyContent="flex-end">
            {watchListFormProps.open ? (
              <Grid item>
                <WatchListForm
                  watchListProps={watchListFormProps}
                  protectedPin={pin}
                  onClose={() =>
                    setWatchListFormProps({ open: false, type: '' })
                  }
                />
              </Grid>
            ) : (
              <>
                <Grid item>
                  {onboardedAt ? (
                    <Button
                      onClick={resetOnboardedAtDate}
                      variant="outlined"
                      size="small"
                    >
                      Återställ onboarding
                    </Button>
                  ) : (
                    <Button
                      onClick={addOnboardedAtDate}
                      variant="outlined"
                      size="small"
                    >
                      Onboarda
                    </Button>
                  )}
                </Grid>
                <Grid item>
                  <Button
                    onClick={() =>
                      setWatchListFormProps({ open: true, type: 'add' })
                    }
                    variant="outlined"
                    size="small"
                  >
                    Lägg till bevakning
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={() =>
                      setWatchListFormProps({ open: true, type: 'remove' })
                    }
                    variant="outlined"
                    size="small"
                  >
                    Ta bort bevakning
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      }
      cardContent={<WatchListInfo />}
    />
  );
};
