import React from 'react';
import { Collapse } from '@mui/material';

type Props = {
  isCollapsible: boolean;
  isOpened: boolean;
  children: React.ReactNode;
};

export const CollapsibleContainer = ({
  isCollapsible,
  isOpened,
  children,
}: Props) => {
  return (
    <>
      {isCollapsible ? (
        <Collapse in={isOpened} timeout="auto" unmountOnExit>
          {children}
        </Collapse>
      ) : (
        children
      )}
    </>
  );
};
