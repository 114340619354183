import React from 'react';

import { Button, Grid } from '@mui/material';
import { Form } from 'react-final-form';

import { InfoMessage } from '@/components/InfoMessage';
import { Spacing } from '@/components/Spacing';
import { TextInput } from '@/components/form';
import { MarketingCheckType } from '@/modules/addressWash/hooks/useAddressWash';
import { fieldRequired } from '@/utils/validation';

type Props = {
  type: MarketingCheckType;
  info: React.ReactNode;
  onSubmit: (payload: { pins: string; type: MarketingCheckType }) => void;
};

export const FormAndInfo = ({ type, info, onSubmit }: Props) => {
  return (
    <Form onSubmit={({ pins }) => onSubmit({ pins, type })}>
      {({ handleSubmit }) => (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextInput
                multiline
                label="Person/Org"
                name="pins"
                rows={12}
                fieldProps={{ validate: fieldRequired }}
                style={{ margin: 0 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {info}
            </Grid>
          </Grid>
          <Spacing />
          <InfoMessage>Ange ett person/org-nr per rad</InfoMessage>
          <Spacing />
          <Button
            color="primary"
            variant="outlined"
            type="submit"
            onClick={handleSubmit}
          >
            Kontrollera
          </Button>
        </>
      )}
    </Form>
  );
};
