import { JSXElementConstructor, ReactElement, ReactFragment } from 'react';

import { Typography } from '@mui/material';

export const AutogiroAgreementTypes = {
  DOES_NOT_EXIST: 0,
  NOT_FINISHED: 1,
  APPROVED: 2,
  DENIED: 3,
  REGRET: 4,
  SHREDDED: 5,
};

const getAutogitoAgreementTypeColors = (type: number) => {
  const defaultColors = {
    color: '#000',
    backgroundColor: '#FFFFFF',
  };
  const colorMap = [
    defaultColors,
    defaultColors,
    {
      color: '#36AF7B',
      backgroundColor: '#36AF7B33',
    },
    {
      color: '#FFFFFF',
      backgroundColor: '#00000033',
    },
    { color: '#EAB12A', backgroundColor: '#EAB12A33' },
    {
      color: '#E0175B',
      backgroundColor: '#E0175B33',
    },
  ];
  return colorMap[type];
};

const getColorCodedOptionLabel = (type: number, title: React.ReactNode) => (
  <Typography
    style={{
      ...getAutogitoAgreementTypeColors(type),
      padding: '5px 10px',
      borderRadius: '4px',
    }}
  >
    {title}
  </Typography>
);

export function autogiroAgreementTypeOptionLabel(
  autogiroAgreementType: number,
) {
  switch (autogiroAgreementType) {
    case AutogiroAgreementTypes.DOES_NOT_EXIST:
      return getColorCodedOptionLabel(
        AutogiroAgreementTypes.DOES_NOT_EXIST,
        'Finns ej',
      );

    case AutogiroAgreementTypes.NOT_FINISHED:
      return getColorCodedOptionLabel(
        AutogiroAgreementTypes.NOT_FINISHED,
        'Ej klar',
      );

    case AutogiroAgreementTypes.APPROVED:
      return getColorCodedOptionLabel(
        AutogiroAgreementTypes.APPROVED,
        'Godkänd',
      );

    case AutogiroAgreementTypes.DENIED:
      return getColorCodedOptionLabel(AutogiroAgreementTypes.DENIED, ' Nekad');

    case AutogiroAgreementTypes.REGRET:
      return getColorCodedOptionLabel(AutogiroAgreementTypes.REGRET, 'Ånger');

    case AutogiroAgreementTypes.SHREDDED:
      return getColorCodedOptionLabel(
        AutogiroAgreementTypes.SHREDDED,
        'Makulerad',
      );

    default:
      return autogiroAgreementType;
  }
}

// Supposed to be used with Dropdown fields
export const autogiroAgreementTypeOptions = Object.values(
  AutogiroAgreementTypes,
)
  .map((value) => ({
    value,
    text: autogiroAgreementTypeOptionLabel(value),
  }))
  .sort((a, b) => {
    if (a.text < b.text) {
      return -1;
    }
    if (a.text > b.text) {
      return 1;
    }
    return 0;
  });
