import { NumberedListItem } from '@/components/NumberedListItem';

import { ListTitle } from '../../../commonStyles';

export function MessageCustomer() {
  return (
    <>
      <ListTitle variant="h6">Meddela kund:</ListTitle>

      <NumberedListItem
        number={1}
        text={`Be om ett telefonnummer samt kontrollera e-post så vi kan nå kund på utifall det behövs under processen samt. Om e-post ej finns, be om en.`}
      />
      <NumberedListItem
        number={2}
        text={`Förklara för kund att kunden behöver göra en polisanmälan 114 14 (Vi kan inte göra detta)`}
      />
      <NumberedListItem
        number={3}
        text={`Om kund gjort en polisanmälan. Be om Diarienumret samt fråga vilken av mobilerna som skall spärras om kund registrerat flera.`}
      />
      <NumberedListItem
        number={4}
        text={`Fråga kund om vi även skall spärra själva abonnemanget? (Sim-kortet). Telefonen och Abonnemanget är två olika saker.`}
      />
      <NumberedListItem
        number={5}
        text={`Meddela kund att vi återkommer när vi genomfört spärren.`}
      />
      <NumberedListItem
        number={6}
        text={`Meddela kund att om mobiltelefonen hittas och kund önskar häva spärren, behöver kund kontakta sin mobiloperatör och det kan ta upp till 48h att häva spärren.`}
      />
    </>
  );
}
