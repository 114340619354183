import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import { Link, Tooltip } from '@mui/material';

export const LinkToCustomer = ({ customerId }: { customerId: number }) => (
  <Link
    sx={{ display: 'flex' }}
    target="_blank"
    href={`/customer/${customerId}`}
  >
    <Tooltip title="Öppna kundkort i ny flik">
      <LaunchOutlinedIcon color="primary" />
    </Tooltip>
  </Link>
);
