import { z } from 'zod';
import zodToJsonSchema from 'zod-to-json-schema';

import { createApiPropertiesFromJsonSchema } from '@/helpers/createApiPropertiesFromJsonSchema';
import {
  ProtectionCampaignsResponse,
  ProtectionsResponse,
} from '@/types/services';
import httpClient from '@/utils/httpClient';

export async function getProtectionCampaigns({
  useNewEndpoint = false,
}: {
  useNewEndpoint: boolean;
}) {
  const url = useNewEndpoint
    ? '/protections_campaigns'
    : '/protection_campaigns';
  const { data } = await httpClient.get<ProtectionCampaignsResponse>(url, {
    params: {
      pagination: false,
      properties: {
        id: true,
        name: true,
        active: true,
        customerType: true,
        pricePlanId: useNewEndpoint ? undefined : true,
        type: useNewEndpoint ? undefined : true,
      },
    },
  });

  return data['hydra:member'];
}
type FetchProtectionsToConfigureProps = {
  page: number;
  perPage: number;
  search: string;
  serviceName: string;
};
export async function fetchProtectionsToConfigure({
  page,
  perPage,
  search,
  serviceName,
}: FetchProtectionsToConfigureProps) {
  const properties = {
    agreements: {
      id: true,
      fileType: true,
      name: true,
    },
    customer: {
      id: true,
      pin: true,
      fullName: true,
      companyName: true,
      category: true,
      company: true,
      city: true,
      address: true,
      address2: true,
      zip: true,
      email: true,
      mobile: true,
    },
    type: true,
    id: true,
    campaign: true,
    msisdn: true,
    regDate: true,
    manufactureDate: true,
    regretTimeExpired: true,
    remainingRegretDays: true,
    adminNote: true,
    msisdn2: true,
    icc: true,
    icc2: true,
    operatorId: true,
    reseller: {
      id: true,
      name: true,
    },
    salesRep: true,
    pricePlanId: true,
  };

  const { data } = await httpClient.get<ProtectionsResponse>(
    serviceName + 's',
    {
      params: {
        page,
        perPage,
        search,
        pagination: true,
        status: [1],
        billing: 0,
        order: {
          regDate: 'asc',
        },
        properties,
      },
    },
  );
  return data;
}

export async function checkProtectionsStatusForSticker({
  customerId,
}: {
  customerId: string | number;
}) {
  const schema = z.object({
    type: z.number(),
    campaign: z.string(),
    regDate: z.string(),
  });

  const properties = createApiPropertiesFromJsonSchema(zodToJsonSchema(schema));

  const collection = z.array(schema);

  const { data } = await httpClient.get<ProtectionsResponse>('/protections', {
    params: {
      'customer.id': customerId,
      status: 1,
      properties,
    },
  });

  return collection.parse(data['hydra:member']);
}

export type GetProtectionsParams = {
  customerPin?: string;
  page?: number;
  perPage?: number;
  churns?: boolean;
  search?: string;
  searchPin?: string;
  sortOrder?: 'asc' | 'desc';
};
export async function getProtections({
  customerPin,
  page = 1,
  perPage = 100,
  churns = false,
  search,
  searchPin,
  sortOrder,
}: GetProtectionsParams) {
  let params;

  if (churns) {
    params = {
      'order[churnDate]': sortOrder,
      status: 1,
      'exists[churnDate]': true,
      'customer.pin': customerPin,
      pagination: true,
      page,
      perPage,
      search,
      searchPin,
      properties: {
        churnDate: true,

        id: true,
        customer: {
          id: true,
          fullName: true,
          companyName: true,
          pin: true,
        },
      },
    };
  } else {
    params = {
      'customer.pin': customerPin,
      pagination: true,
      page,
      perPage,
      search,
      searchPin,
      status: 1,
      properties: {
        id: true,
        churnDate: true,
        status: true,
        customer: {
          id: true,
          fullName: true,
          company: true,
          companyName: true,
        },
      },
    };
  }

  const { data } = await httpClient.get<ProtectionsResponse>('/protections', {
    params,
  });

  return data;
}
