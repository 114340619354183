import React from 'react';

import { Box, Divider, Grid } from '@mui/material';
import { useSelector } from '@xstate/react';
import { upperFirst } from 'lodash';

import { LabelAndContentRow } from '@/components';
import { BasicAlert } from '@/components/BasicAlert';
import { SimpleCard } from '@/components/SimpleCard';
import { StepRow } from '@/modules/sinne/components/StepRow';
import { DiscardStateContext } from '@/modules/sinne/components/sinneDiscardFlow/context/DiscardStateProvider';
import { RetryButton } from '@/modules/sinne/components/sinneReturnFlow/components/RetryButton';

export const DeviceDataDisplay = () => {
  const discardFlowService = React.useContext(DiscardStateContext);
  const state = useSelector(discardFlowService, (state) => state);

  const {
    customer,
    device,
    isDeviceDataFetchComplete,
    macAddress,
    noClipData,
    simcard,
  } = state.context;

  return (
    <Box>
      {isDeviceDataFetchComplete && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} md>
              <SimpleCard
                title="Enhetsinformation"
                cardContent={<InfoRow data={device} />}
              />
            </Grid>
            <Grid item xs={12} md>
              <SimpleCard
                title="Simkortsinformation"
                cardContent={<InfoRow data={simcard} />}
              />
            </Grid>
            <Grid item xs={12} md>
              <SimpleCard
                title="Information i NoClip"
                cardContent={<InfoRow data={noClipData ?? {}} />}
              />
            </Grid>
            <Grid item xs={12} md>
              <SimpleCard
                title="Senaste kundinformation"
                cardContent={<InfoRow data={customer} />}
              />
            </Grid>
          </Grid>
        </>
      )}
      {state.matches('FETCH_DEVICE_SIM_AND_CUSTOMER_DATA.DEVICE.loading') && (
        <p>Hämtar information om enheten...</p>
      )}
      {state.matches(
        'FETCH_DEVICE_SIM_AND_CUSTOMER_DATA.DEVICE.error.notFound',
      ) && (
        <Row
          message="Hittade ingen enhet"
          onClick={() =>
            discardFlowService.send({ type: 'RETRY_DEVICE_CHECK', macAddress })
          }
        />
      )}
      {state.matches(
        'FETCH_DEVICE_SIM_AND_CUSTOMER_DATA.DEVICE.error.serverError',
      ) && (
        <Row onClick={() => discardFlowService.send('RETRY_DEVICE_CHECK')}>
          <BasicAlert message="Fel hos server när enhetsdata skulle hämtas" />
        </Row>
      )}

      {state.matches('FETCH_DEVICE_SIM_AND_CUSTOMER_DATA.SIM_CARD.loading') && (
        <p>Hämtar information om simkortet...</p>
      )}
      {state.matches(
        'FETCH_DEVICE_SIM_AND_CUSTOMER_DATA.SIM_CARD.error.notFound',
      ) && (
        <Row
          message="Hittade inget simkort"
          onClick={() => discardFlowService.send('RETRY_SIM_CARD_CHECK')}
        />
      )}
      {state.matches(
        'FETCH_DEVICE_SIM_AND_CUSTOMER_DATA.SIM_CARD.error.serverError',
      ) && (
        <Row onClick={() => discardFlowService.send('RETRY_SIM_CARD_CHECK')}>
          <BasicAlert message="Fel hos server när simkort skulle hämtas" />
        </Row>
      )}

      {state.matches('FETCH_DEVICE_SIM_AND_CUSTOMER_DATA.CUSTOMER.loading') && (
        <p>Hämtar information om senaste kund...</p>
      )}
      {state.matches(
        'FETCH_DEVICE_SIM_AND_CUSTOMER_DATA.CUSTOMER.error.notFound',
      ) && (
        <Row
          message="Hittade ingen kund"
          onClick={() => discardFlowService.send('RETRY_SIM_CARD_CHECK')}
        />
      )}
      {state.matches(
        'FETCH_DEVICE_SIM_AND_CUSTOMER_DATA.CUSTOMER.error.serverError',
      ) && (
        <Row
          onClick={() =>
            discardFlowService.send({ type: 'RETRY_CUSTOMER_CHECK' })
          }
        >
          <BasicAlert message="Fel hos server när kunddata skulle hämtas" />
        </Row>
      )}
    </Box>
  );
};

type Obj = Record<string, string | number | boolean | null> | undefined;
type Props = {
  data: Record<string, string | number | boolean | null | Obj>;
};
const InfoRow = ({ data }: Props) => {
  return (
    <Box>
      {Object.entries(data).map(([key, val]) => {
        return (
          <LabelAndContentRow
            compact
            key={key}
            label={upperFirst(key)}
            content={
              typeof val === 'object' && val !== null ? (
                <InfoRow data={val} />
              ) : (
                val?.toString()
              )
            }
          />
        );
      })}
    </Box>
  );
};

const Row = ({
  message,
  onClick,
  children,
}: {
  message?: string;
  onClick: () => void;
  children?: React.ReactNode;
}) => (
  <StepRow>
    {message && <p>{message}</p>}
    {children}
    <RetryButton onClick={onClick} />
  </StepRow>
);
