import { ListItem } from '@mui/material';
import { NavLink } from 'react-router-dom';

import * as Icons from '@/icons';
import { MenuButton } from '@/layouts/Main/components/Sidebar/components/SidebarNav/components/MenuButton';

import { renderIcon } from '../utils/renderIcon';

export type SubMenuItem = {
  title: string;
  href: string;
  icon?: keyof typeof Icons;
  callback?: () => void;
  'data-testid'?: string;
};

export const SubMenuListItem = (props: SubMenuItem) => {
  const { title, href, icon, callback } = props;

  return (
    <ListItem sx={{ padding: 0 }} key={href} data-testid={props['data-testid']}>
      <MenuButton
        startIcon={icon ? renderIcon(icon) : null}
        component={NavLink}
        to={href}
        {...(callback ? { onClick: callback } : {})}
      >
        <span style={{ flexGrow: 1 }}>{title}</span>
      </MenuButton>
    </ListItem>
  );
};
