import React, { useReducer } from 'react';

import { Close } from '@mui/icons-material';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useQuery } from '@tanstack/react-query';

import { checkFraudProtection } from '@/api/fraudProtection';
import { usePrivateSettings } from '@/hooks';
import usePublicSettings from '@/hooks/usePublicSettings';

import { CallerInfo, ProtectionOrCoProtectionService } from '../../types';
import {
  FraudProtectionFound,
  FraudProtectionNotFound,
  Loading,
  Start,
} from './components';

const Root = styled(DialogContent)`
  overflow: visible;
`;

const StyledDialogTitle = styled(DialogTitle)`
  margin: 0;
  padding: ${({ theme }) => theme.spacing(2)};
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  right: ${({ theme }) => theme.spacing(1)};
  top: ${({ theme }) => theme.spacing(1)};
  color: ${({ theme }) => theme.palette.grey[500]};
`;

const BottomActions = styled(DialogActions)`
  justify-content: start;
`;

type State = {
  isStart: boolean;
  allowNextStep: boolean;
  chosenPin: string;
  chosenAction: string;
  runCheck: boolean;
};

type Action =
  | { type: 'NEXT' }
  | { type: 'BACK' }
  | { type: 'SET_CHOSEN_PIN'; pin: string }
  | { type: 'SET_CHOSEN_ACTION'; action: string };

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'NEXT':
      return { ...state, isStart: false, runCheck: true };

    case 'BACK':
      return { ...state, runCheck: false, isStart: true };

    case 'SET_CHOSEN_PIN':
      return { ...state, chosenPin: action.pin };

    case 'SET_CHOSEN_ACTION':
      return { ...state, chosenAction: action.action };

    default:
      throw new Error(`Unhandled action type: ${(action as any).type}`);
  }
};

type Props = {
  open: boolean;
  handleCloseModal: () => void;
  service: ProtectionOrCoProtectionService;
  callerInfo: CallerInfo;
};

export const FraudProtectionModal = ({
  open,
  handleCloseModal,
  service,
  callerInfo,
}: Props) => {
  const { publicSettings } = usePublicSettings();
  const { privateSettings } = usePrivateSettings();

  const hasPhoneVerification = Boolean(privateSettings?.hasPhoneVerification);

  const initialState = {
    isStart: true,
    allowNextStep: false,
    chosenPin: callerInfo.data.pin!,
    chosenAction: '',
    runCheck: false,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    data,
    isFetching,
    isPending: isLoading,
    isError,
  } = useQuery({
    queryKey: ['checkFraudProtection', state.chosenPin],

    queryFn: async () => {
      const { data } = await checkFraudProtection(state.chosenPin);

      return data;
    },

    enabled: state.runCheck,
    initialData: undefined,
  });

  return (
    <Dialog open={open} fullWidth={true} maxWidth="md">
      <StyledDialogTitle>
        <Typography
          component="span"
          sx={{
            fontWeight: 500,
            fontSize: '20px',
            letterSpacing: '-0.06px',
            lineHeight: '24px',
          }}
        >
          Bedrägerispärr
        </Typography>
        <CloseButton onClick={handleCloseModal} size="large">
          <Close />
        </CloseButton>
      </StyledDialogTitle>

      <Root dividers>
        <Grid container spacing={4}>
          {!hasPhoneVerification && (
            <Grid item>
              <Alert severity="warning">
                <Typography variant="h5">
                  {publicSettings?.companyNames?.standard || 'Företaget'} har
                  inte telefonverifiering med bank-ID aktiverat. Enbart kontroll
                  av bedrägerispärr är möjlig.
                </Typography>
              </Alert>
            </Grid>
          )}
          {state.isStart && (
            <Start
              chosenAction={state.chosenAction}
              setChosenAction={(action) =>
                dispatch({ type: 'SET_CHOSEN_ACTION', action: action })
              }
              chosenPin={state.chosenPin}
              hasPhoneVerification={hasPhoneVerification}
            />
          )}
          {!state.isStart &&
            (isLoading || isFetching ? (
              <Loading pin={state.chosenPin} />
            ) : isError ? (
              <Grid item xs={12}>
                <Alert severity="error">
                  Något gick fel. Kontrollera internetanslutningen. Om felet
                  kvarstår, kontakta IT.
                </Alert>
              </Grid>
            ) : data?.hasFraudProtection ? (
              <FraudProtectionFound
                data={data}
                pin={state.chosenPin}
                customerId={service.customer.id.toString()}
                hasPhoneVerification={hasPhoneVerification}
              />
            ) : (
              <FraudProtectionNotFound
                pin={state.chosenPin}
                customerId={service.customer.id.toString()}
                chosenAction={parseInt(state.chosenAction, 10)}
                hasPhoneVerification={hasPhoneVerification}
              />
            ))}
        </Grid>
      </Root>
      <BottomActions>
        <Button
          disabled={state.isStart || isError}
          onClick={() => dispatch({ type: 'BACK' })}
          sx={(theme) => ({
            marginRight: theme.spacing(1),
          })}
        >
          Tillbaka
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={() => dispatch({ type: 'NEXT' })}
          disabled={state.runCheck}
          sx={(theme) => ({
            marginRight: theme.spacing(1),
          })}
          hidden={!state.isStart}
        >
          Nästa
        </Button>

        <Button onClick={handleCloseModal} style={{ marginLeft: 'auto' }}>
          Avsluta
        </Button>
      </BottomActions>
      <DialogContent dividers>
        <Typography mt={2} variant="h4">
          Information om bedrägerispärr
        </Typography>
        <Typography mt={2} variant="h5">
          Allmänt om bedrägerispärr
        </Typography>
        <List>
          <ListItem>Spärrar köp på avbetalning, lån eller liknande</ListItem>
          <ListItem>Spärren är rikstäckande</ListItem>
          <ListItem>
            Det tar upp till 24 timmar innan spärren är aktiverad överallt
          </ListItem>
        </List>
        <Typography variant="h5">Tillfällig spärr</Typography>
        <List>
          <ListItem>Gäller i 14 dagar.</ListItem>
        </List>
        <Typography variant="h5">Hårdspärr</Typography>
        <List>
          <ListItem>
            Gäller i 5 år, aktiveras efter kunds polisanmälan mottagits via post
            eller mejl.
          </ListItem>
        </List>
        <Typography variant="h5">Upphäva en bedrägerispärr</Typography>
        <List>
          <ListItem>
            Kund måste ringa in samt verifiera sig med BankID.
          </ListItem>
        </List>
      </DialogContent>
    </Dialog>
  );
};
