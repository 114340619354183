import httpClient from '@/utils/httpClient';

export async function getCustomLetterTemplates() {
  const { data } = await httpClient.get('/templates/custom', {
    params: {
      id: true,
      name: true,
      type: true,
    },
  });

  return data['hydra:member'];
}

export async function deleteCustomLetterTemplate(id: number) {
  return await httpClient.delete(`/templates/${id}`);
}

export type GenerateBulkCustomLetterPayload = {
  templateName: string;
  customerIds?: string[];
  customerPins?: string[];
  templateType?: 'custom';
};

export async function generateBulkCustomLetterPdf({
  templateType = 'custom',
  ...payload
}: GenerateBulkCustomLetterPayload) {
  const { data } = await httpClient.post(
    '/documentable/template/generate/bulk',
    {
      templateType,
      ...payload,
    },
    {
      responseType: 'blob',
    },
  );

  return data;
}
