import { useQuery } from '@tanstack/react-query';

import { getKeyTags } from '@/api/keyTag';

import { queryKeys } from '../../../constants/queryKeys';

export const useGetKeyTags = (code: string) => {
  return useQuery({
    queryKey: ['findProduct', queryKeys.getKeyTags, code],

    queryFn: async () => {
      const data = await getKeyTags({ code });
      return data['hydra:member'].map((item) => {
        return { type: item['@type'], status: item.status, code: item.code };
      });
    },

    enabled: !!code,
  });
};
