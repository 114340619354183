import { Clear } from '@mui/icons-material';
import {
  CardHeader,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
} from '@mui/material';

import { usePrivateSettings } from '@/hooks';
import { SERVICES_WITH_CAMPAIGNS } from '@/hooks/useGetCampaignServiceOptions';
import { CampaignTypes } from '@/types/campaigns';

import { renderCampaignForm } from './helpers/renderCampaignForm';

export type CampaignModalProps = {
  handleVisibility: () => void;
  campaignToEdit: string | null;
  selectedCampaignType: CampaignTypes;
};

export const CampaignModal = (props: CampaignModalProps) => {
  const { servicesNames } = usePrivateSettings();
  const service = props.selectedCampaignType.split(
    '_',
  )[0] as (typeof SERVICES_WITH_CAMPAIGNS)[number];

  const wlServiceName = servicesNames[service] || service;

  const headerText = `${
    props.campaignToEdit ? 'Redigera' : 'Skapa'
  } kampanj för tjänsten ${wlServiceName}`;

  return (
    <Dialog
      open={true}
      maxWidth="lg"
      fullWidth
      onClose={props.handleVisibility}
    >
      <CardHeader
        title={headerText}
        action={
          <IconButton onClick={props.handleVisibility} size="large">
            <Clear />
          </IconButton>
        }
      />
      <Divider />
      <DialogContent>{renderCampaignForm(props)}</DialogContent>
    </Dialog>
  );
};
