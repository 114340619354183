import React from 'react';

import GetAppIcon from '@mui/icons-material/GetApp';
import {
  Button,
  CardActions,
  CardContent,
  Divider,
  Skeleton,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSelector } from '@xstate/react';
import camelCase from 'lodash/camelCase';

import { downloadFile } from '@/api/agreements';
import { LabelAndContentRow } from '@/components';
import { usePrivateSettings, useSnackbar } from '@/hooks';
import { SinneConfigContext } from '@/modules/sinne/components/sinneConfigFlow/context/SinneConfigContextProvider';
import { CustomerAgreement } from '@/types/agreements';

export const Agreements = () => {
  const machine = React.useContext(SinneConfigContext);
  const state = useSelector(machine, (state) => state);
  const { agreements } = state.context;

  const isLoading = state.matches('AGREEMENTS.loading');
  const isSuccess = state.matches('AGREEMENTS.success');

  const snackbar = useSnackbar();

  const { servicesNames } = usePrivateSettings();

  async function handleDownloadAgreement(agreement: CustomerAgreement) {
    try {
      const { id, name } = agreement;
      await downloadFile({ id, filename: name || '' });
    } catch (e) {
      snackbar({
        type: 'error',
        message: 'Det gick inte att ladda ner avtalet',
      });
    }
  }

  return (
    <>
      <CardContent>
        {isLoading ? (
          <Skeleton />
        ) : isSuccess && Boolean(agreements?.length) ? (
          agreements?.map((agreement, i, arr) => (
            <LabelAndContentRow
              key={agreement.id}
              divider={i !== arr.length - 1}
              label={
                agreement.fileType === 'document' ? 'Skriftlig fil' : 'Ljudfil'
              }
              secondaryText={
                // @ts-ignore
                servicesNames[camelCase(agreement.type)] ?? agreement.type
              }
              content={
                <FlexContainerDiv>
                  <Typography>{agreement.name}</Typography>
                  <DownloadIcon
                    color="primary"
                    onClick={() => handleDownloadAgreement(agreement)}
                  />
                </FlexContainerDiv>
              }
            />
          ))
        ) : (
          <Typography variant="h4" style={{ padding: '16px 0 0 16px' }}>
            Inga avtal
          </Typography>
        )}
      </CardContent>

      <Divider />
      <CardActions>
        <Button disabled={isLoading} onClick={() => machine.send('PROCEED')}>
          Nästa
        </Button>
      </CardActions>
    </>
  );
};

export const FlexContainerDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  '& > *': {
    marginLeft: theme.spacing(1),
  },
}));

export const DownloadIcon = styled(GetAppIcon)(({ theme }) => ({
  color: theme.palette.text.primary,
  cursor: 'pointer',
  '&:hover': {
    color: 'black',
  },
}));
