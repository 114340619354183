import { useContext } from 'react';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { CircularProgress, Typography } from '@mui/material';
import { useSelector } from '@xstate/react';

import { SinneReturnStateContext } from '../context';
import { StepRow } from './StepRow';

export const UpdateSinneService = () => {
  const sinneReturnFlowService = useContext(SinneReturnStateContext);
  const state = useSelector(sinneReturnFlowService, (state) => state);

  const { isSinneServiceUpdated } = state.context;

  if (state.matches('UPDATE_SINNE_SERVICE.loading')) {
    return (
      <StepRow>
        <CircularProgress size={14} />
        <Typography component="span" variant="h6">
          Uppdaterar tjänsten...
        </Typography>
      </StepRow>
    );
  }

  if (isSinneServiceUpdated) {
    return (
      <StepRow>
        <CheckCircleOutlineIcon color="success" sx={{ fontSize: 20 }} />
        <Typography component="span" variant="h6">
          Tjänsten har markerats med "enhet returnerad"
        </Typography>
      </StepRow>
    );
  }

  if (state.matches('UPDATE_SINNE_SERVICE.error')) {
    return (
      <StepRow>
        <ErrorOutlineIcon color="error" sx={{ fontSize: 20 }} />
        <Typography component="span" variant="h6" padding={0} margin={0}>
          Något gick fel när tjänsten skulle uppdateras
        </Typography>
      </StepRow>
    );
  }
  return null;
};
