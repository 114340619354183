import { Alert } from '@/components/Alert';

export const NoteTypeMissingAlert = () => {
  return (
    <Alert severity="error">
      Applikationsinställningarna innehåller inte nödvändig data för att
      genomföra flödet. Kontakta IT (note type missing in private settings)
    </Alert>
  );
};
