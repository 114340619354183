export const BILLING_MONTH_OPTIONS = [
  {
    value: 1,
    text: 'Januari',
  },
  {
    value: 2,
    text: 'Februari',
  },
  {
    value: 3,
    text: 'Mars',
  },
  {
    value: 4,
    text: 'April',
  },
  {
    value: 5,
    text: 'Maj',
  },
  {
    value: 6,
    text: 'Juni',
  },
  {
    value: 7,
    text: 'Juli',
  },
  {
    value: 8,
    text: 'Augusti',
  },
  {
    value: 9,
    text: 'September',
  },
  {
    value: 10,
    text: 'Oktober',
  },
  {
    value: 11,
    text: 'November',
  },
  {
    value: 12,
    text: 'December',
  },
];
