import axios, { AxiosError, AxiosHeaders, AxiosRequestConfig } from 'axios';
import qs from 'qs';

import { getEnvConfig } from '@/helpers/config';

function onRequest(config: AxiosRequestConfig) {
  if (
    config.url?.indexOf('/login_check') !== -1 ||
    config.url?.indexOf('/settings/public') !== -1
  ) {
    return config;
  }

  const jwtToken = localStorage.getItem('jwt-token');

  if (config.headers === undefined) {
    config.headers = {};
  }

  if (jwtToken) {
    (config.headers as AxiosHeaders).set('Authorization', `Bearer ${jwtToken}`);
  }

  // config.paramsSerializer = (params) => qs.stringify(params);
  config.paramsSerializer = {
    encode: (param) => qs.parse(param),
    serialize: (params) => qs.stringify(params),
  };

  return config;
}

export async function onResponseError(error: AxiosError) {
  const status = error.response?.status;
  const data = error.response?.data as Blob | Record<string, unknown>;

  if (
    (window.location.href.indexOf('sign-in') === -1 ||
      error.request.responseURL.includes('private')) &&
    status === 401
  ) {
    localStorage.removeItem('jwt-token');
    localStorage.removeItem('persist:root');
    window.location.reload();
  }

  // INFO: When we download files we set responseType: 'blob' in the api call,
  // therefore we need to parse any returned json back to json
  if (data && data instanceof Blob) {
    const responseObj = JSON.parse(await data.text()) as Record<
      string,
      unknown
    >;
    const responseMessage =
      responseObj?.message ??
      responseObj?.['hydra:description'] ??
      responseObj?.detail ??
      '';
    const errorToReturn = {
      ...error.response,
      data: responseObj,
      statusText: responseMessage,
    };
    return Promise.reject(errorToReturn);
  } else {
    const errorMessage =
      data?.['hydra:description'] ?? data?.message ?? data?.detail ?? '';
    const errorToReturn = {
      ...error.response,
      statusText: errorMessage,
    };

    return Promise.reject(errorToReturn);
  }
}

function httpClient() {
  const envConfig = getEnvConfig();

  const defaultOptions = {
    baseURL: envConfig.apiUrl,
  };

  const instance = axios.create(defaultOptions);

  // @ts-expect-error Type 'undefined' is not assignable to type 'AxiosRequestHeaders'.
  instance.interceptors.request.use(onRequest);
  instance.interceptors.response.use(undefined, onResponseError);

  return instance;
}

export default httpClient();
