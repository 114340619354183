import { CamelCaseServiceName } from '@/types/services';
import { ServiceStatuses } from '@/types/settings';
import { hasOwnProperty } from '@/utils/typeHelpers';

import { getObjectKeys } from './tsHelpers';

export function getServiceStatusKey(
  serviceStatusesObj: ServiceStatuses,
  serviceName: CamelCaseServiceName,
  status: string,
) {
  const serviceStatuses = serviceStatusesObj[serviceName];

  if (!serviceStatuses) {
    console.error(`No service statuses for ${serviceName}`);
    return undefined;
  }

  const serviceStatusKeys = getObjectKeys(serviceStatuses);

  const serviceStatusKey = serviceStatusKeys.find((key) => {
    if (hasOwnProperty(serviceStatuses, key)) {
      return serviceStatuses[key] === status;
    }

    return false;
  });

  return serviceStatusKey as string | undefined;
}
