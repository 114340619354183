import { SvgIcon, SvgIconProps } from '@mui/material';

export const SinneIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <g>
        <path d="M11,16.5h2c0,0.55-0.45,1-1,1S11,17.05,11,16.5z M15,15v-2.34c0-1.54-0.81-2.82-2.25-3.16V9.25c0-0.41-0.34-0.75-0.75-0.75 s-0.75,0.34-0.75,0.75V9.5C9.82,9.84,9,11.12,9,12.66V15H8v1h8v-1H15z M12,5.5L6,10v9h12v-9L12,5.5 M12,3l8,6v12H4V9L12,3z" />
      </g>
      ;
    </SvgIcon>
  );
};
