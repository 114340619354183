import { Grid } from '@mui/material';

import { PageWrapper } from '@/components/PageWrapper';
import { Tabs } from '@/components/Tabs/Tabs';

import { MonthlyFees } from './MonthlyFees';
import { OneOffFees } from './OneOffFees';

const tabMap = [
  {
    title: 'Månadsavgifter',
    view: () => <MonthlyFees />,
  },
  {
    title: 'Engångsavgifter',
    view: () => <OneOffFees />,
  },
];

export const Fees = () => {
  return (
    <PageWrapper
      pageTitle="Kassaflöde"
      breadCrumbs={[{ label: 'Ekonomi' }, { label: 'Kassaflöde' }]}
    >
      <Tabs tabs={tabMap} />
    </PageWrapper>
  );
};
