import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  CreateOrUpdateCampaignParams,
  createCampaign,
  updateCampaign,
} from '@/api/campaigns';
import { queryKeys } from '@/constants/queryKeys';
import { Campaign } from '@/types/campaigns';
import { GenericHttpError } from '@/types/errors';

type Args = {
  isUpdate?: string | null;
};

export const useCampaignMutation = ({ isUpdate }: Args) => {
  const queryClient = useQueryClient();
  const mutationFn = isUpdate ? updateCampaign : createCampaign;

  return useMutation<Campaign, GenericHttpError, CreateOrUpdateCampaignParams>({
    mutationFn,
    onSuccess: () => {
      return queryClient.refetchQueries({
        queryKey: queryKeys.campaigns.listAll(),
      });
    },
  });
};
