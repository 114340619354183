export const REFETCH_INTERVAL = 5000;
export const RETRY_DURATION = 1000 * 300;
export const MS_TO_READ_INSTRUCTION = 3000;

export const SINNE_CONFIG_STEP_TITLES = [
  'Steg 1 - Ta bort plastflikarna',
  'Steg 2 - Registrera enhet',
  'Steg 3 - Förbered posten',
  'Steg 4 - Filer',
  'Steg 5 - Testa synkronisering av enheten',
  'Steg 6 - Verifiera koppling med simkort',
];
