import React from 'react';

import {
  Box,
  Breakpoint,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  LinearProgress,
} from '@mui/material';

type Props = {
  dialogTitle?: React.ReactNode | string;
  dialogText: React.ReactNode | string;
  isOpened: boolean;
  onClose: () => void;
  onConfirm: () => void;
  confirmationButtonText?: string;
  cancellationButtonText?: string;
  inProgress?: boolean;
  width?: Breakpoint;
};

export const ConfirmationDialog = ({
  dialogTitle,
  dialogText,
  isOpened,
  onClose,
  onConfirm,
  confirmationButtonText = 'Bekräfta',
  cancellationButtonText = 'Avbryt',
  inProgress = false,
  width = 'xs',
}: Props) => {
  return (
    <Dialog
      open={isOpened}
      maxWidth={width}
      fullWidth
      onClose={() => !inProgress && onClose()}
    >
      {dialogTitle && (
        <>
          <DialogTitle>{dialogTitle}</DialogTitle>
          <Divider />
        </>
      )}
      <DialogContent>
        <DialogContentText style={{ marginTop: '8px' }}>
          {dialogText}
        </DialogContentText>
      </DialogContent>
      {inProgress ? <LinearProgress /> : <Divider />}
      <DialogActions style={{}}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Button variant="outlined" disabled={inProgress} onClick={onClose}>
            {cancellationButtonText}
          </Button>
          <Button
            variant="contained"
            disabled={inProgress}
            onClick={onConfirm}
            color="primary"
          >
            {confirmationButtonText}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
