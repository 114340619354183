import ArticleIcon from '@mui/icons-material/Article';
import { Button } from '@mui/material';

import {
  GetLegacyWelcomeLetterParams,
  GetLegacyWelcomeLetterParamsSchema,
} from '@/api/files';
import { usePrivateSettings, useSnackbar } from '@/hooks';
import { DocumentDownloadCard } from '@/modules/services/components/Edit/Documents/DocumentDownloadCard';
import { useGetWelcomeLetterFromLegacy } from '@/modules/services/components/Edit/Documents/hooks/useGetWelcomeLetterFromLegacy';
import { generatePdfDownloadLink } from '@/utils/pdf';

type Props = Partial<GetLegacyWelcomeLetterParams>;

export const LegacyWelcomeLetterButton = (props: Props) => {
  const snackbar = useSnackbar();
  const { servicesNames } = usePrivateSettings();
  const fileDownLoadMutation = useGetWelcomeLetterFromLegacy();

  const allPropsValid = GetLegacyWelcomeLetterParamsSchema.safeParse(props);

  if (!allPropsValid.success) {
    return null;
  }

  const { customer, product, productId } = allPropsValid.data;
  const whiteLabelServiceName = servicesNames[product];

  const downloadHandler = () =>
    fileDownLoadMutation.mutate(
      {
        customer,
        product,
        productId,
      },
      {
        onSuccess: (data) => {
          generatePdfDownloadLink(
            data,
            `Välkomstbrev-${whiteLabelServiceName}-${customer}`,
          );
        },
        onError: () => {
          snackbar({
            message: 'Filen kunde inte skapas',
            type: 'error',
          });
        },
      },
    );

  return (
    <>
      <Button
        disabled={fileDownLoadMutation.isPending}
        onClick={() => downloadHandler()}
      >
        <DocumentDownloadCard>
          <ArticleIcon style={{ margin: '10px' }} />
          Välkomstbrev
        </DocumentDownloadCard>
      </Button>
    </>
  );
};
