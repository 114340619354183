import { CustomerAgreement } from '@/types/agreements';

export function getInfoAboutAgreements(
  agreements: Partial<CustomerAgreement>[],
) {
  const documentAgreement = !!agreements?.find(
    (agreement) => agreement.fileType === 'document',
  );
  const audioAgreement = !!agreements?.find(
    (agreement) => agreement.fileType === 'audio',
  );

  return {
    audio: audioAgreement,
    document: documentAgreement,
  };
}
