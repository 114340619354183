import React from 'react';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { Can } from '@/components/Can';
import { usePrivateSettings } from '@/hooks';
import useUserRoles from '@/hooks/useUserRoles';
import { CollapsableMenuList } from '@/layouts/Main/components/Sidebar/components/SidebarNav/components/CollapsableMenuList';
import { MenuListItem } from '@/layouts/Main/components/Sidebar/components/SidebarNav/components/MenuListItem';
import { MenuListItemButton } from '@/layouts/Main/components/Sidebar/components/SidebarNav/components/MenuListItemButton';

import { MenuItem } from '../types';
import { SubMenuListItem } from './SubMenuListItem';

export const EconomyMenu = () => {
  const [open, setOpen] = React.useState(false);

  const userRoles = useUserRoles();
  const { pathname } = useLocation();
  const { invoiceProvider } = usePrivateSettings();

  const hasSveaBank = invoiceProvider.name.toLowerCase() === 'svea';

  const menuItems: Required<Pick<MenuItem, 'title' | 'href' | 'authRule'>>[] = [
    {
      title: 'Faktuering',
      href: `/economy/billing`,
      authRule: 'economy.view',
    },
    {
      title: 'Kassaflöde',
      href: `/economy/cash-flow`,
      authRule: 'economy.view',
    },
    {
      title: 'Hårdvara',
      href: `/economy/hardware`,
      authRule: 'economy.view',
    },
  ];

  const sveaMenuItem: (typeof menuItems)[number] = {
    title: 'Svea Bank',
    href: `/economy/svea-bank`,
    authRule: 'economy.view',
  };

  if (hasSveaBank) menuItems.push(sveaMenuItem);

  return (
    <>
      <MenuListItem>
        <MenuListItemButton onClick={() => setOpen(!open)}>
          Ekonomi
          {open ? <ExpandLess /> : <ExpandMore />}
        </MenuListItemButton>
      </MenuListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <CollapsableMenuList>
          {menuItems.map(({ href, title, authRule }) => (
            <Can
              key={href}
              userRoles={userRoles}
              action={authRule}
              yes={() => (
                <SubMenuListItem
                  key={encodeURI(href)}
                  href={href}
                  title={title}
                />
              )}
            />
          ))}
        </CollapsableMenuList>
      </Collapse>
    </>
  );
};
