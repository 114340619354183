import { getCamelCaseServiceName, getObjectKeys } from '@/helpers/tsHelpers';
import { usePrivateSettings } from '@/hooks';
import useUser from '@/hooks/useUser';
import { userRolesEnum } from '@/types/auth';
import {
  CAMEL_CASE_SERVICE_NAMES,
  CamelCaseServiceName,
} from '@/types/services';

import { useGetResellersEnabledServices } from './useGetResellersEnabledServices';

type Options = {
  id: number;
  value: CamelCaseServiceName;
  label: string;
};

type Returns = {
  status: 'loading' | 'success' | 'error';
  options: Options[];
};

export const useGetServiceOptions = (resellerId: number | undefined) => {
  const { id, roles } = useUser();
  const { servicesNames } = usePrivateSettings();
  const { data: services, status } = useGetResellersEnabledServices(
    resellerId ?? id,
  );
  const returns = {
    status,
    options: [],
  };

  if (roles.includes(userRolesEnum.Values.ROLE_RESELLER)) {
    const options = services
      ? getObjectKeys(services).map((name, idx) => {
          return {
            id: idx,
            value: getCamelCaseServiceName(name),
            label: services[name],
          };
        })
      : [];
    return { ...returns, options };
  } else {
    const options = CAMEL_CASE_SERVICE_NAMES.filter(
      (service) => service !== 'firetext' && service !== 'sticker',
    ).map((name, idx) => ({
      id: idx,
      value: getCamelCaseServiceName(name),
      label: servicesNames[name],
    }));
    return { status: 'success', options } as Returns;
  }
};
