import { forwardRef, useCallback } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Alert, AlertTitle, IconButton } from '@mui/material';
import { SnackbarContent, SnackbarProvider, closeSnackbar } from 'notistack';

import { SnackbarProps, SnackbarType, statusNamesMap } from '@/types/snackbar';

const Snackbar = forwardRef<HTMLDivElement, SnackbarProps>((props, ref) => {
  const handleDismiss = useCallback(() => {
    closeSnackbar(props.id);
  }, [props.id, closeSnackbar]);

  return (
    <SnackbarContent ref={ref}>
      <Alert
        elevation={3}
        data-testid={`${props.variant}Snackbar`}
        onClose={handleDismiss}
        severity={props.variant as SnackbarType}
        sx={{ width: '100%' }}
      >
        <AlertTitle sx={{ textTransform: 'capitalize' }}>
          {props.title || statusNamesMap[props.variant as SnackbarType]}
        </AlertTitle>
        {props.message}
      </Alert>
    </SnackbarContent>
  );
});

export const SnackbarManager = () => {
  return (
    <SnackbarProvider
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      Components={{
        default: Snackbar,
        error: Snackbar,
        info: Snackbar,
        success: Snackbar,
        warning: Snackbar,
      }}
      action={(snackbarId) => (
        <IconButton onClick={() => closeSnackbar(snackbarId)}>
          <CloseIcon />
        </IconButton>
      )}
    />
  );
};
