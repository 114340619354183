import { Typography } from '@mui/material';

import {
  ManagerMeanArr,
  ManagerMedianArr,
  ManagerTotalArr,
} from './components';
import { getNumberOfCustomersWithActiveService } from './helpers';
import { AMSRow, ManagedCustomer } from './types';

// Roles that can managa a team
export const managerRoles = ['ROLE_SALES_MANAGER'];

// Roles that can be member of a team
export const teamMemberRoles = [
  'ROLE_HEAD_OF_B2B',
  'ROLE_ACCOUNT_MANAGER',
  'ROLE_SALES_MANAGER',
];

export const ACCOUNT_MANAGER_COLUMNS = [
  {
    label: 'Namn',
    renderer: ({ name }: { name: string }) => name,
  },
  {
    label: 'Kunder (Aktiva)',
    renderer: (row: AMSRow) => (
      <Typography variant="body2">
        {`${
          row.managedCustomers?.length || 0
        } (${getNumberOfCustomersWithActiveService(row.managedCustomers)})`}
      </Typography>
    ),
  },
  {
    label: 'Totalt Förvaltad ARR',
    renderer: ({
      managedCustomers,
    }: {
      managedCustomers: ManagedCustomer[];
    }) => <ManagerTotalArr managedCustomers={managedCustomers} />,
  },
  {
    label: 'ARR / Medelvärde',
    renderer: ({
      managedCustomers,
    }: {
      managedCustomers: ManagedCustomer[];
    }) => <ManagerMeanArr managedCustomers={managedCustomers} />,
  },
  {
    label: 'ARR / Median',
    renderer: ({
      managedCustomers,
    }: {
      managedCustomers: ManagedCustomer[];
    }) => <ManagerMedianArr managedCustomers={managedCustomers} />,
  },
];
