import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  LinearProgress,
  useTheme,
} from '@mui/material';
import { format, startOfMonth } from 'date-fns';
import { Form } from 'react-final-form';

import { usePrivateSettings } from '@/hooks';
import { CamelCaseServiceName } from '@/types/services';
import { Products } from '@/types/settings';

import { UseDownloadExportFile } from '../../../hooks/useDownloadExportFile';
import { BillableAndOnboardingFields } from './BillableAndOnboardingFields';

type FormValues = {
  start: number;
};
type Props = {
  exportType: Extract<keyof Products, 'keyTag' | 'sticker'>;
  grandIdSession: string;
  mutation: UseDownloadExportFile;
};

type BillableSuffix = '.billable';
type Billablexports = 'keytag' | 'sticker';
type Billable = `${Billablexports}${BillableSuffix}`;

const billableExports: Extract<CamelCaseServiceName, 'keyTag' | 'sticker'>[] = [
  'keyTag',
  'sticker',
];

export const BillableExportForm = ({
  exportType,
  grandIdSession,
  mutation,
}: Props) => {
  const theme = useTheme();

  const { servicesNames } = usePrivateSettings();

  const handleSubmit = (values: FormValues) => {
    const today = new Date().setMonth(values.start);
    const month = format(startOfMonth(today), 'yyyy-MM-dd');

    const params = {
      ...values,
      start: month,
      grandIdSession,
    };

    const type = billableExports.reduce((acc, service) => {
      if (service === exportType) {
        acc = `${exportType.toLocaleLowerCase()}.billable` as Billable;
      }
      return acc;
    }, '' as Billable);

    if (!type) {
      return;
    }
    mutation.mutate({
      type,
      params,
      whiteLableExportName: servicesNames[exportType],
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      {({ handleSubmit, invalid }) => (
        <Box maxWidth={theme.breakpoints.values.lg}>
          {mutation.isPending && <LinearProgress />}
          <Card component="form" onSubmit={handleSubmit}>
            <CardHeader title="Att fakturera" />
            <Divider />
            <CardContent
              sx={{
                padding: 0,
                '&.MuiCardContent-root:last-child': { padding: 0 },
              }}
            >
              <BillableAndOnboardingFields
                invalid={invalid}
                isLoading={mutation.isPending}
              />
            </CardContent>
          </Card>
        </Box>
      )}
    </Form>
  );
};
