import { Card, CardHeader, Divider, Typography } from '@mui/material';
import invariant from 'tiny-invariant';

import { LabelAndContentRow } from '@/components';
import { getFormattedDate } from '@/helpers/dates';
import { usePrivateSettings } from '@/hooks';

export const BillogramInfo = () => {
  const { invoiceProvider } = usePrivateSettings();

  const billogramSettings = invoiceProvider.settings;

  invariant(
    'invoiceDay' in billogramSettings,
    'invoiceDay is required for billogram settings',
  );

  const renderInvoiceDay = () => {
    const invoiceDay = billogramSettings.invoiceDay || new Date().toISOString();
    return invoiceDay ? getFormattedDate(invoiceDay, 'yyyy-MM-dd') : null;
  };

  if (!billogramSettings) {
    return null;
  }

  return (
    <Card>
      <CardHeader title="Info" />
      <Divider />
      <LabelAndContentRow
        content={<Typography>{renderInvoiceDay()}</Typography>}
        label="Fakturadatum"
      />
      <Divider />
      <LabelAndContentRow
        content={<Typography>{billogramSettings.dueDays}</Typography>}
        label="Förfallodagar"
      />
      <Divider />
      <LabelAndContentRow
        content={
          <Typography>{billogramSettings.firstReminderDelayDays}</Typography>
        }
        label="Dagar första påminnelse"
      />
      <Divider />
      <LabelAndContentRow
        content={
          <Typography>{billogramSettings.secondReminderDelayDays}</Typography>
        }
        label="Dagar andra påminnelse"
      />
      <Divider />
      <LabelAndContentRow
        content={
          <Typography>{billogramSettings.collectionDelayDays}</Typography>
        }
        label="Dagar inkasso"
      />
    </Card>
  );
};
