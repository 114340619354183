import { useMutation, useQueryClient } from '@tanstack/react-query';

import { deleteTask } from '@/api/tasks';
import { TaskItem } from '@/types/tasks';

export const useDeleteTask = ({ queryKey }: { queryKey: any[] }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: number) => deleteTask(id),
    onSuccess: (_, taskId) => {
      queryClient.setQueryData(queryKey, (oldData: TaskItem[] | undefined) => {
        return oldData?.filter((task) => task.id !== taskId);
      });
    },
  });
};
