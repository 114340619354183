import Checkbox from '@mui/material/Checkbox';
import { Autocomplete } from 'mui-rff';
import { FieldProps } from 'react-final-form';

import { SelectFieldOptions } from '@/types/common';

type Props = {
  multiple: boolean;
  name: string;
  label: string;
  options: SelectFieldOptions;
  fieldProps?: Partial<FieldProps<any, any>>;
};

export const MembersAutoComplete = ({
  multiple = false,
  name,
  label,
  options,
  fieldProps,
}: Props) => {
  return (
    <Autocomplete
      name={name}
      label={label}
      multiple={multiple}
      options={options}
      getOptionValue={(option) => option.value}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.text
      }
      textFieldProps={{ variant: 'outlined' }}
      disableCloseOnSelect
      limitTags={2}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox checked={selected} />
          {option.text}
        </li>
      )}
      fieldProps={fieldProps}
    />
  );
};
