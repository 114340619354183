import { Card, CardContent, Divider, List } from '@mui/material';

import { NumberedListItem } from '@/components/NumberedListItem';

import { ChecklistTitle, ListTitle } from '../../commonStyles';

// Seventh tab for Protection Checklist
export const SeventhTab = () => {
  return (
    <Card>
      <ChecklistTitle variant="h6">
        Kund vill häva bedrägeri/hårdspärr på sitt personnummer
      </ChecklistTitle>
      <Divider />
      <CardContent>
        <List>
          <ListTitle variant="h6">Meddela kund:</ListTitle>
          <NumberedListItem
            number={1}
            text={`Om kund önskar häva sin hårdspärr behöver de själva kontakta Dun & Bradstreet som sedan skickar ut en fullmakt till användarens folkbokföringsadress som de behöver skriva under och skicka tillbaka till Dun & Bradstreet.`}
          />
          <ListTitle variant="h6">
            Vad händer/ hur påverkas jag av att bedrägerispärren är aktiv?
          </ListTitle>
          <NumberedListItem
            number={1}
            text={`Du kan fortsätta ditt liv som vanligt, bankkort, konton, bank id mm fungerar som vanligt. Det enda är att det inte går att göra någon kreditupplysning på dig så du kan ex inte ta lån eller teckna nya abonnemang mm under tiden.`}
          />
          <ListTitle variant="h6">Att göra:</ListTitle>

          <NumberedListItem
            number={1}
            text={`Notera i [Kundnoteringar] vad som är gjort.`}
          />
        </List>
      </CardContent>
    </Card>
  );
};
