export async function checkForAdBlocker() {
  let Blocked;

  async function check() {
    try {
      return fetch('https://www3.doubleclick.net', {
        method: 'HEAD',
        mode: 'no-cors',
        cache: 'no-store',
      })
        .then(({ redirected }) => {
          Blocked = redirected;
          return Blocked;
        })
        .catch(() => {
          Blocked = true;
          return Blocked;
        });
    } catch (error) {
      Blocked = true;
      return Blocked;
    }
  }

  return Blocked !== undefined ? Blocked : await check();
}
