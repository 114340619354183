import React from 'react';

import { LaunchOutlined } from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Box,
  Card,
  CardHeader,
  Divider,
  Link,
  Skeleton,
  Tooltip,
  Typography,
} from '@mui/material';
import { useActor } from '@xstate/react';

import { PageWrapper } from '@/components';
import { usePrivateSettings } from '@/hooks';
import { Agreements } from '@/modules/sinne/components/sinneConfigFlow/components/Agreements';
import { DeviceInfoForm } from '@/modules/sinne/components/sinneConfigFlow/components/DeviceInfoForm';
import { DeviceSyncronization } from '@/modules/sinne/components/sinneConfigFlow/components/DeviceSyncronization';
import { Finalize } from '@/modules/sinne/components/sinneConfigFlow/components/Finalize';
import { Initialize } from '@/modules/sinne/components/sinneConfigFlow/components/Initialize';
import { InstructionStep } from '@/modules/sinne/components/sinneConfigFlow/components/InstructionStep';
import { MailPreparation } from '@/modules/sinne/components/sinneConfigFlow/components/MailPreparation';
import { SimCardSyncronization } from '@/modules/sinne/components/sinneConfigFlow/components/SimCardSyncronization';
import { UpdateService } from '@/modules/sinne/components/sinneConfigFlow/components/UpdateService';
import {
  SinneConfigContext,
  SinneConfigContextProvider,
} from '@/modules/sinne/components/sinneConfigFlow/context/SinneConfigContextProvider';

type TopLevelState =
  | 'INITIALIZE'
  | 'INSTRUCTION'
  | 'FORM_INPUT'
  | 'DEVICE_SYNCRONIZATION'
  | 'SIM_CARD_SYNCRONIZATION'
  | 'MAIL_PREPARATION'
  | 'AGREEMENTS'
  | 'UPDATE_SERVICE'
  | 'DEVICE_BROKEN'
  | 'CREATE_NOTE'
  | 'FINALIZE';

export const SINNE_CONFIG_STEP_TITLES: Record<TopLevelState, string> = {
  INITIALIZE: 'Förbereder konfiguration',
  INSTRUCTION: 'Steg 1 - Ta bort plastflikarna',
  FORM_INPUT: 'Steg 2 - Registrera enhet',
  DEVICE_SYNCRONIZATION: 'Steg 3 - Testa synkronisering av enheten',
  SIM_CARD_SYNCRONIZATION: 'Steg 4 - Verifiera koppling med simkort',
  MAIL_PREPARATION: 'Steg 5 - Förbered posten',
  AGREEMENTS: 'Steg 6 - Filer',
  UPDATE_SERVICE: 'Steg 7 - Uppdaterar tjänsten',
  DEVICE_BROKEN: 'Steg 7 - Uppdaterar tjänsten',
  CREATE_NOTE: 'Steg 7 - Uppdaterar tjänsten',
  FINALIZE: 'Steg 7 - Klart',
};

const getTopLevelState = (state: any): TopLevelState => {
  // For compound states, the value is an object
  if (typeof state.value === 'object' && state.value !== null) {
    // Get the first key which is the top-level state
    return Object.keys(state.value)[0] as TopLevelState;
  }
  // For atomic states, the value is a string
  return state.value;
};

const Container = () => {
  const machine = React.useContext(SinneConfigContext);
  const [currentState] = useActor(machine);
  const { servicesNames } = usePrivateSettings();

  const { isReplacementFlow, sinneServiceData, contextError } =
    currentState.context;

  const {
    id: customerId,
    fullName,
    companyCategory,
    companyName,
  } = sinneServiceData?.customer || {};

  const confirmWindowClose = (e: Event) => {
    e.preventDefault();
  };

  React.useEffect(() => {
    window.addEventListener('beforeunload', confirmWindowClose);

    return () => {
      window.removeEventListener('beforeunload', confirmWindowClose);
    };
  });

  const renderComponent = () => {
    if (currentState.matches('INITIALIZE')) {
      return <Initialize />;
    }
    if (currentState.matches('INSTRUCTION')) {
      return <InstructionStep />;
    }
    if (currentState.matches('FORM_INPUT')) {
      return <DeviceInfoForm />;
    }
    if (currentState.matches('DEVICE_SYNCRONIZATION')) {
      return <DeviceSyncronization />;
    }
    if (currentState.matches('SIM_CARD_SYNCRONIZATION')) {
      return <SimCardSyncronization />;
    }
    if (currentState.matches('MAIL_PREPARATION')) {
      return <MailPreparation />;
    }
    if (currentState.matches('AGREEMENTS')) {
      return <Agreements />;
    }
    if (currentState.matches('UPDATE_SERVICE')) {
      return <UpdateService />;
    }
    if (
      currentState.matches('FINALIZE') ||
      currentState.matches('DEVICE_BROKEN') ||
      currentState.matches('CREATE_NOTE')
    ) {
      return <Finalize />;
    }
    if (currentState.matches('CONTEXT_ERROR')) {
      return (
        <Alert severity="error">
          <AlertTitle>Fel i context</AlertTitle>
          Fel: {contextError}
        </Alert>
      );
    }

    return (
      <Alert severity="error">
        <AlertTitle>Ett fel har uppstått</AlertTitle>
        Ingen komponent hittades för det aktuella steget. Kontakta IT.
      </Alert>
    );
  };

  return (
    <PageWrapper
      breadCrumbs={[{ label: servicesNames.sinne }, { label: 'Konfigurera' }]}
      pageTitle="Konfigurera enhet"
      children={
        <Card
          sx={{
            maxHeight: '1000px',
            maxWidth: (theme) => theme.breakpoints.values.md,
          }}
          key={currentState.value.toString()}
        >
          <CardHeader
            title={
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h5">
                  {
                    SINNE_CONFIG_STEP_TITLES[
                      getTopLevelState(
                        currentState,
                      ) as keyof typeof SINNE_CONFIG_STEP_TITLES
                    ]
                  }
                </Typography>
                {isReplacementFlow && (
                  <Typography
                    color="error"
                    variant="h5"
                    sx={{ margin: '0 auto' }}
                  >
                    Detta flöde ersätter en annan enhet
                  </Typography>
                )}
              </Box>
            }
            subheader={
              <>
                {!sinneServiceData && <Skeleton />}
                {sinneServiceData && (
                  <Link
                    href={`/customer/${customerId}`}
                    target="_blank"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    {companyCategory ? companyName : fullName}
                    <Tooltip title="Öppna i ny flik">
                      <LaunchOutlined
                        color="primary"
                        sx={{
                          cursor: 'pointer',
                          fontSize: '14px',
                          marginLeft: 1,
                        }}
                      />
                    </Tooltip>
                  </Link>
                )}
              </>
            }
          />
          <Divider />
          {renderComponent()}
        </Card>
      }
    />
  );
};

export const ConfigureSinne = () => (
  <SinneConfigContextProvider>
    <Container />
  </SinneConfigContextProvider>
);
