import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useMutation } from '@tanstack/react-query';
import { Form } from 'react-final-form';

import { CreateTaskPayload, createTask } from '@/api/tasks';
import { TextInput } from '@/components/form';
import { TaskTypes } from '@/constants';
import { useSnackbar } from '@/hooks';
import { useModal } from '@/hooks/useModal';
import { TaskTeamTypes } from '@/modules/tasks/constants';
import { fieldRequired } from '@/utils/validation';

type Props = {
  modal: {
    extra: {
      customerId: number;
    };
  };
};

export const AlarmCustomerTaskModal = ({
  modal: {
    extra: { customerId },
  },
}: Props) => {
  const { closeModal } = useModal();
  const snackbar = useSnackbar();

  const taskMutation = useMutation({
    mutationFn: createTask,
    onSuccess: () => {
      closeModal();

      snackbar({
        type: 'success',
        message: 'Ärende skapat.',
      });
    },
    onError: () => {
      snackbar({
        type: 'error',
        message: 'Kunde inte skapa ärende.',
      });
    },
  });

  function handleSubmit({ text }: { text: string }) {
    const payload: CreateTaskPayload = {
      text: text.trim(),
      status: 0,
      type: TaskTypes.ALARM_CENTRAL,
      customerId,
      deadline: null,
      team: TaskTeamTypes.CUSTOMER_SUPPORT,
    };

    taskMutation.mutate(payload);
  }

  return (
    <Form
      onSubmit={handleSubmit}
      render={({ handleSubmit, pristine }) => (
        <StyledDialog
          onClose={() => {
            closeModal();
          }}
          open={true}
        >
          <form onSubmit={handleSubmit} noValidate autoComplete="off">
            <DialogTitle>Skapa kundärende</DialogTitle>
            <DialogContent>
              <TextInput
                multiline
                label="Skriv ditt meddelande här..."
                name="text"
                rows={8}
                fieldProps={{ validate: fieldRequired }}
              />
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={pristine || taskMutation.isPending}
              >
                Skapa
              </Button>
            </DialogActions>
          </form>
        </StyledDialog>
      )}
    />
  );
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: theme.breakpoints.values.sm,
  },
}));
