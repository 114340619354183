import { z } from 'zod';

import { CustomerCategoryTypes } from '@/constants';
import { ProtectionCampaign } from '@/types/campaigns';

import { protectionValidationSchema } from '../ProtectionValidationSchema';

type ProtectionCampaignDefaultValues = z.infer<
  typeof protectionValidationSchema
>;

export const protectionCampaignDefaultValues: ProtectionCampaignDefaultValues =
  {
    name: '',
    active: true,
    bulkPrintEnabled: true,
    price: '',
    contractMonths: 0,
    customerType: CustomerCategoryTypes.PRIVATE,
    endUsers: 1,
    freeMonths: 0,
    noticePeriod: 0,
    withdrawalPeriodDays: 0,
    package: '',
    internalDescription: '',
    specialHeader: '',
    specialRow1: '',
    specialRow2: '',
    specialRow3: '',
  };

export function getDefaultFormValuesForProtectionCampaign(
  data: ProtectionCampaign | undefined,
  editingExistingCampaign: boolean,
): ProtectionCampaignDefaultValues | null {
  if (editingExistingCampaign && !data) return null;

  if (editingExistingCampaign && data) {
    return {
      name: data.name,
      active: data.active,
      bulkPrintEnabled: data.bulkPrintEnabled ?? true,
      price: data.price?.['@id'] ?? '',
      contractMonths: data.contractMonths,
      customerType: data.customerType,
      endUsers: data.endUsers,
      freeMonths: data.freeMonths,
      noticePeriod: data.noticePeriod,
      withdrawalPeriodDays: data.withdrawalPeriodDays,
      package: data.package ?? '',
      internalDescription: data.internalDescription ?? '',
      specialHeader: data.specialHeader ?? '',
      specialRow1: data.specialRow1 ?? '',
      specialRow2: data.specialRow2 ?? '',
      specialRow3: data.specialRow3 ?? '',
    };
  } else {
    return protectionCampaignDefaultValues;
  }
}
