import { z } from 'zod';

import { getElementFromArray } from '@/helpers/getElementFromArray';
import { getObjectKeys } from '@/helpers/tsHelpers';
import { ApiCollectionResponse } from '@/types/common';
import { FileImportValidation, TemplateTypes } from '@/types/files';
import { OrderFileEndpointMap, OrderFileModalType } from '@/types/orderFile';
import { CamelCaseServiceName } from '@/types/services';
import httpClient from '@/utils/httpClient';
import { generatePdfDownloadLink } from '@/utils/pdf';

function normalizeCustomerId(customerId: number | string) {
  return typeof customerId === 'number' ? customerId.toString() : customerId;
}

export async function getAutogiroPdf(customerId: number | string) {
  const { data } = await httpClient.post(
    '/pdf/autogiro',
    {
      customerId: normalizeCustomerId(customerId),
    },
    {
      responseType: 'blob',
    },
  );

  return data;
}

export async function getC5H5Pdf(customerId: number | string) {
  const { data } = await httpClient.post(
    '/pdf/letterhead',
    {
      customerId: normalizeCustomerId(customerId),
    },
    {
      responseType: 'blob',
    },
  );

  return data;
}

export async function getSimpleLetterPdf(
  customerId: string | number,
  message: string,
) {
  const { data } = await httpClient.post(
    '/pdf/simple-letter',
    {
      customerId: normalizeCustomerId(customerId),
      message,
    },
    {
      responseType: 'blob',
    },
  );

  return data;
}

export type GetTerminationPdfParams = {
  customerId: number | string;
  productId: string;
  type: 'protection' | 'firetext' | 'homegate';
};

export async function getTerminationPdf({
  customerId,
  productId,
  type,
}: GetTerminationPdfParams) {
  const { data } = await httpClient.post(
    '/pdf/termination',
    {
      customerId,
      productId,
      type,
    },
    {
      responseType: 'blob',
    },
  );

  return data;
}

export async function getWelcomeLetterPdf(
  customerId: number | string,
  product: string,
  productId: number,
) {
  const { data } = await httpClient.post(
    'pdf/welcome-letter',
    {
      customerId: customerId,
      product,
      productId,
    },
    {
      responseType: 'blob',
    },
  );

  return data;
}

type BulkWelcomeLetterDownloadPayload = {
  campaignIds: Array<number>;
  product: string;
  regDate: string;
};

export const bulkWelcomeLetterDownload = async (
  payload: BulkWelcomeLetterDownloadPayload,
) => {
  return await httpClient.post(
    'pdf/bulk/welcome-letter',
    {
      ...payload,
    },
    {
      responseType: 'blob',
    },
  );
};

// TODO: Add better typing
export async function protectedUpload(payload: any) {
  const formData = new FormData();

  Object.keys(payload).forEach((key) => {
    if (key === 'file') {
      formData.append(key, payload[key][0]);
    } else {
      formData.append(key, payload[key]);
    }
  });

  const { data } = await httpClient.post('protected_upload/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return data;
}

export type GetProtectedUploadsParams = {
  page?: number;
  perPage?: number;
  protectedPin?: string | string[];
  filteredPins?: string | string[];
};

export async function getProtectedUploads(params: GetProtectedUploadsParams) {
  const data = await httpClient.get('protected_uploads', {
    params,
  });
  return data;
}

export type ProtectedDownloadParams = {
  id: string;
  type: string;
  pin: string;
};

export async function protectedDownload({
  id,
  type,
  pin,
}: ProtectedDownloadParams) {
  await httpClient({
    url: `protected_upload/${id}/download`,
    method: 'GET',
    responseType: 'blob',
  }).then((response) => {
    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: response.headers['content-type'] }),
    );
    const filename = `${type}-${pin}`;

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${filename}`);
    document.body.appendChild(link);
    link.click();
    return url;
  });
}

// TODO: Add better typing
export async function campaignWelcomeLetterUpload(values: any) {
  const formData = new FormData();

  Object.keys(values).forEach((key) => {
    if (key === 'file') {
      formData.append(key, values[key][0]);
    } else {
      formData.append(key, values[key]);
    }
  });

  const { data } = await httpClient.post(
    'documentable/welcome-letter',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );

  return data;
}

type TemplateUploadPayload = {
  file: Array<File>;
  templateType: TemplateTypes;
  serviceType: CamelCaseServiceName;
};

export async function singleTemplateUpload(
  url: string,
  payload: TemplateUploadPayload,
) {
  const formData = new FormData();

  getObjectKeys(payload).forEach((key) => {
    if (key === 'file') {
      formData.append(key, getElementFromArray(payload?.[key]?.[0]));
    } else {
      formData.append(key, payload[key]);
    }
  });

  const { data } = await httpClient.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return data;
}

export async function getTemplateDataParams() {
  const { data } = await httpClient.get('/documentable/template.data');
  return data;
}

// for sinne when you send product type you have access to more info with more variables.
export const TemplateProductTypesSchema = z.enum(['sinne', 'firetext']);
export type TemplateProductTypes = z.infer<typeof TemplateProductTypesSchema>;

export type TemplateDownloadParams = {
  customerId: number;
  templateType: TemplateTypes;
  serviceType: CamelCaseServiceName;
  productId?: number;
  productType?: TemplateProductTypes;
  protectedPin?: string;
  protectedName?: string;
  protectedPhone?: string;
  protectedEmail?: string;
  protectedAddress?: string;
  protectedZip?: string;
  protectedCity?: string;
};

export async function documentableTemplateDownload(
  params: TemplateDownloadParams,
) {
  const { data } = await httpClient.post(
    'documentable/template/generate',
    params,
    { responseType: 'blob' },
  );

  generatePdfDownloadLink(
    data,
    `${params.templateType}-kundnr=${params.customerId}`,
  );
}

export async function getFileImportValidationProcess(uuid: string) {
  const { data } = await httpClient.get<
    ApiCollectionResponse<FileImportValidation>
  >(`/file_import_validations`, {
    params: {
      uuid,
    },
  });
  return data;
}

/* Legacy Welcome Letters */

export const GetLegacyWelcomeLetterParamsSchema = z.object({
  customer: z.union([z.string(), z.number()]),
  product: z.enum(['protection', 'firetext']),
  productId: z.number(),
});

export type GetLegacyWelcomeLetterParams = z.infer<
  typeof GetLegacyWelcomeLetterParamsSchema
>;

export async function getWelcomeLetterFromLegacy(
  params: GetLegacyWelcomeLetterParams,
) {
  return httpClient.get('/pdf/legacy/welcome-letter', {
    params,
    responseType: 'blob',
  });
}

export const GetBulkLegacyWelcomeLetterParamsSchema = z.object({
  product: z.enum(['protection', 'protection-upsale', 'firetext']),
  regDate: z.string(),
});

export type GetBulkLegacyWelcomeLetterParams = z.infer<
  typeof GetBulkLegacyWelcomeLetterParamsSchema
>;

export async function getBulkWelcomeLetterFromLegacy(
  params: GetBulkLegacyWelcomeLetterParams,
) {
  return httpClient.get('/pdf/legacy/welcome-letter/bulk', {
    params,
    responseType: 'blob',
  });
}

export type GetCustomLegacyWelcomeLetterParams = {
  type: string; // Customer Success Team receives this from unknown source. Different partner letters, senior-deals etc.
  customers: string[] | number[];
};

export async function getCustomWelcomeLetterFromLegacy(
  params: GetCustomLegacyWelcomeLetterParams,
) {
  const customersAsSpaceSeparatedString = params.customers.join(' ');
  const payload = {
    ...params,
    customers: customersAsSpaceSeparatedString,
  };
  return httpClient.get('/pdf/legacy/welcome-letter/custom', {
    params: payload,
    responseType: 'blob',
  });
}

export type CustomTemplateUploadPayload = {
  file: Array<File>;
  templateType: 'custom';
  templateName: string;
  c5h2Address: boolean;
};

export async function customTemplateUpload(
  payload: CustomTemplateUploadPayload,
) {
  const formData = new FormData();

  getObjectKeys(payload).forEach((key) => {
    if (key === 'file') {
      formData.append(key, getElementFromArray(payload?.[key]?.[0]));
    } else {
      formData.append(key, payload[key].toString());
    }
  });

  return httpClient.post('documentable/template', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export type OrderFileUploadPayload = {
  file: Array<File>;
  serviceType: OrderFileModalType;
};

export async function orderFileUpload(payload: OrderFileUploadPayload) {
  const formData = new FormData();
  formData.append('file', getElementFromArray(payload?.['file']?.[0]));

  return httpClient.post(OrderFileEndpointMap[payload.serviceType], formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}
