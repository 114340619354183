import { StatsRawData } from '@/types/stats';

import { enhanceChartDataWithColors, parseBasicStatsChartData } from '../stats';

export function prepareBasicStatsChartData(rawData: StatsRawData) {
  const parsedData = parseBasicStatsChartData(rawData);
  parsedData.datasets = enhanceChartDataWithColors(parsedData.datasets);

  return parsedData;
}
