import { useMutation, useQueryClient } from '@tanstack/react-query';

import { insertArvatoInvoices } from '@/api/costs';
import { queryKeys } from '@/constants/queryKeys';
import { useSnackbar } from '@/hooks';

type RequestParams = {
  customerIds?: number[];
};

export function useInsertArvatoInvoices() {
  const queryClient = useQueryClient();
  const snackbar = useSnackbar();

  return useMutation({
    mutationFn: ({ customerIds }: RequestParams) =>
      insertArvatoInvoices(customerIds),
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: queryKeys.economy.arvatoInvoiceMigrationProcess(),
      });

      snackbar({
        type: 'success',
        message:
          'Processen för överföring av fakturor till Arvato Invoice Provider startades',
      });
    },
    onError() {
      snackbar({
        type: 'error',
        message:
          'Processen för att överföra fakturor till Arvato fakturaleverantör misslyckades',
      });
    },
  });
}
