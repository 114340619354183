import React from 'react';

import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from '@mui/material';

import { Spacing } from '@/components/Spacing';

import { MarketingCheckType } from '../hooks/useAddressWash';
import { FormAndInfo } from './FormAndInfo';

type Props = {
  children: React.ReactNode;
  onSubmit: (payload: { pins: string; type: MarketingCheckType }) => void;
};

export function CustomerExists({ children, onSubmit }: Props) {
  return (
    <Card>
      <CardHeader title="Finns kund tvätt" />
      <Divider />
      <CardContent>
        <Typography>Tvätta bort leads om personnummer redan finns</Typography>
        <Spacing />
        <FormAndInfo
          onSubmit={onSubmit}
          type="exists"
          info={
            <>
              <Typography fontWeight="bold">Tvättar bort allmänt</Typography>
              <Typography>Kundkort: Om kund redan finns</Typography>
            </>
          }
        />
        <Spacing spacing={4} />
        {children}
      </CardContent>
    </Card>
  );
}

CustomerExists.propTypes = {};
