import { useEffect, useMemo, useState } from 'react';

import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useDebounce, usePrevious } from 'react-use';

import { usePrivateSettings } from '@/hooks';
import useDebouncedValue from '@/hooks/useDebouncedValue';
import { useGetUsers } from '@/hooks/useGetUsers';
import { useGetUsersByIds } from '@/hooks/useGetUsersByIDs';
import { taskStatusOptions } from '@/modules/tasks/constants';
import { UserItem } from '@/types/users';

import { FilterValues } from '../../types/filters';
import { TaskTeamType } from '../../types/team';

type Props = {
  initialValues: FilterValues;
  taskTeam: TaskTeamType;
  onChange: (value: FilterValues) => void;
};

type ClaimedByOptionType = Partial<UserItem> & Pick<UserItem, 'id' | 'name'>;
export const TasksFilter = ({ onChange, taskTeam, initialValues }: Props) => {
  const { getTypesOptionsForDropdownInput } = usePrivateSettings();
  const [claimedByInputValue, setClaimedByInputValue] = useState('');
  const claimedBySearchParamValue = useDebouncedValue(claimedByInputValue);

  const claimedByOptions = useGetUsers(
    claimedBySearchParamValue?.length <= 0
      ? { taskTeam }
      : {
          pagination: false,
          search: claimedBySearchParamValue,
          taskTeam,
        },
  );

  const taskTypeOptions = useMemo(
    () =>
      getTypesOptionsForDropdownInput('task', {
        taskTeam,
      }),
    [],
  );

  const { control, watch, setValue } = useForm({
    defaultValues: {
      search: initialValues.search || '',
      deadline: Boolean(initialValues.deadline),
      status: initialValues.status || [],
      type: initialValues.type || [],
      claimedBy: [] as ClaimedByOptionType[],
    },
  });

  const getClaimedByInitialValueQueries = useGetUsersByIds(
    initialValues.claimedBy || [],
  );
  const allClaimedByInitialValueQueriesFinished =
    getClaimedByInitialValueQueries.every((query) => query.isSuccess);

  useEffect(() => {
    if (allClaimedByInitialValueQueriesFinished) {
      const claimedByInitialValues = getClaimedByInitialValueQueries.map(
        ({ data }) => data,
      ) as ClaimedByOptionType[];
      setValue('claimedBy', claimedByInitialValues);
    }
  }, [allClaimedByInitialValueQueriesFinished]);

  const valuesWatch = watch();
  const prevValuesWatch = usePrevious(valuesWatch);

  useDebounce(
    () => {
      if (JSON.stringify(prevValuesWatch) !== JSON.stringify(valuesWatch)) {
        const newFilterValues: FilterValues = {
          ...valuesWatch,
          ...(valuesWatch.claimedBy && {
            claimedBy: valuesWatch.claimedBy.map((val) => val.id),
          }),
        };
        onChange(newFilterValues);
      }
    },
    500,
    [valuesWatch],
  );

  const getOptionText = (
    value: number | string,
    options: { value: number | string; text: string }[],
  ) => {
    return options.find((o) => o.value === value)?.text || '';
  };

  // TODO: Create shared wrappers for rhf + mui components

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Controller
            name="type"
            control={control}
            render={({ field }) => (
              <>
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  limitTags={4}
                  id="task-types-select"
                  value={field.value}
                  onChange={(_, values) => {
                    field.onChange(values);
                  }}
                  onBlur={field.onBlur}
                  options={taskTypeOptions.map((o) => o.value)}
                  getOptionLabel={(option) =>
                    getOptionText(option, taskTypeOptions)
                  }
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox checked={selected} />
                      {getOptionText(option, taskTypeOptions)}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Kategori"
                      placeholder="Kategori"
                    />
                  )}
                />
              </>
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            name="status"
            control={control}
            render={({ field }) => (
              <Autocomplete
                multiple
                disableCloseOnSelect
                limitTags={4}
                id="task-status-select"
                value={field.value}
                onChange={(_, values) => {
                  field.onChange(values);
                }}
                onBlur={field.onBlur}
                options={taskStatusOptions.map((o) => o.value)}
                getOptionLabel={(option) =>
                  getOptionText(option, taskStatusOptions)
                }
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox checked={selected} />
                    {getOptionText(option, taskStatusOptions)}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Status"
                    placeholder="Status"
                  />
                )}
              />
            )}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            name="claimedBy"
            control={control}
            render={({ field }) => (
              <>
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  limitTags={4}
                  id="task-claimed-by-select"
                  value={field.value}
                  onInputChange={(_, newInputValue) => {
                    setClaimedByInputValue(newInputValue);
                  }}
                  onChange={(_, values: ClaimedByOptionType[]) => {
                    field.onChange(values);
                  }}
                  options={claimedByOptions as ClaimedByOptionType[]}
                  onBlur={field.onBlur}
                  filterOptions={(x) => x}
                  getOptionLabel={(option: ClaimedByOptionType) => option.name}
                  isOptionEqualToValue={(option: ClaimedByOptionType, val) =>
                    option.id === val.id
                  }
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox checked={selected} />
                      {option.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Hanteras av"
                      placeholder="Hanteras av"
                    />
                  )}
                />
              </>
            )}
          />
        </Grid>
      </Grid>

      <Grid container alignItems="end">
        <Grid item xs={4} lg={6}>
          <FormGroup>
            <FormControlLabel
              control={
                <Controller
                  name="deadline"
                  control={control}
                  render={({ field }) => (
                    <Checkbox {...field} checked={field.value} />
                  )}
                />
              }
              label="Har deadline"
            />
          </FormGroup>
        </Grid>

        <Grid item xs={8} lg={6}>
          <Grid container justifyContent="right">
            <Grid item xs={8}>
              <Controller
                name="search"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Sök efter personnummer eller kundnummer"
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};
