import React from 'react';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import Collapse from '@mui/material/Collapse';

import { Can } from '@/components/Can';
import { CollapsableMenuList } from '@/layouts/Main/components/Sidebar/components/SidebarNav/components/CollapsableMenuList';
import { MenuListItem } from '@/layouts/Main/components/Sidebar/components/SidebarNav/components/MenuListItem';
import { MenuListItemButton } from '@/layouts/Main/components/Sidebar/components/SidebarNav/components/MenuListItemButton';
import { UserRole } from '@/types/auth';

import { SubMenuListItem } from './SubMenuListItem';

type Props = {
  userRoles: UserRole[];
};

export const ToolsMenu = ({ userRoles }: Props) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <MenuListItem>
        <MenuListItemButton onClick={() => setOpen(!open)}>
          Verktyg
          {open ? <ExpandLess /> : <ExpandMore />}
        </MenuListItemButton>
      </MenuListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <CollapsableMenuList>
          <Can
            userRoles={userRoles}
            action="tools.campaignBuilder"
            yes={() => (
              <SubMenuListItem title="Kampanjbyggaren" href="/campaigns" />
            )}
          />
          <Can
            userRoles={userRoles}
            action="services.export"
            yes={() => <SubMenuListItem title="Export" href="/exports" />}
          />
          <Can
            userRoles={userRoles}
            action="tools.fileUpload"
            yes={() => (
              <SubMenuListItem
                title="Filmallar"
                href="/tools/template-upload"
              />
            )}
          />
          <Can
            userRoles={userRoles}
            action="tools.sendOuts"
            yes={() => <SubMenuListItem title="Utskick" href="/sendings" />}
          />
          <Can
            userRoles={userRoles}
            action="tools.addressWash"
            yes={() => (
              <SubMenuListItem title="Adresstvätt" href="/addresswash" />
            )}
          />
          <Can
            userRoles={userRoles}
            action="tools.agreementManagement"
            yes={() => (
              <SubMenuListItem
                title="Avtalshantering"
                href="/agreement-management"
              />
            )}
          />
          <Can
            userRoles={userRoles}
            action="customers.delete"
            yes={() => (
              <SubMenuListItem
                title="Kundborttagning"
                href="/delete-customers"
              />
            )}
          />
        </CollapsableMenuList>
      </Collapse>
    </>
  );
};
