import { common, grey } from '@mui/material/colors';
import { Palette, createTheme } from '@mui/material/styles';

import { CompanyColors } from '@/types/publicSettings';

import customPalette from './customPalette';
import typography from './typography';

declare module '@mui/material/styles' {
  interface Theme {
    palette: Palette & {
      error: {
        ultraLight: string;
      };
      warning: {
        deadline: string;
      };
      button: {
        lost: {
          default: string;
          hover: string;
        };
        saved: {
          default: string;
          hover: string;
        };
        send: {
          default: string;
          hover: string;
        };
      };
      notes: {
        [key in number]: {
          header: string;
          content: string;
          type: string;
        };
      };
      success: {
        main: string;
      };
    };
  }
}

export function extendDefaultTheme(
  companyColors: CompanyColors,
  paletteType: 'light' | 'dark',
  darkMode: boolean,
) {
  return {
    palette: {
      mode: paletteType,
      background: {
        default: darkMode ? '#191919' : grey[100],
        paper: darkMode ? '#272727' : common.white,
      },
      primary: {
        main: darkMode ? '#E7F2F7' : companyColors?.primary,
        light: darkMode ? '#191919' : companyColors?.lighten,
      },
      text: {
        primary: darkMode ? common.white : common.black,
      },
      ...customPalette,
    },
    typography,
  };
}

export function prepareTheme(
  companyColors: CompanyColors,
  paletteType: 'light' | 'dark',
  darkMode: boolean,
) {
  return createTheme({
    ...extendDefaultTheme(companyColors, paletteType, darkMode),
    zIndex: {
      appBar: 1200,
      drawer: 1100,
    },
    components: {
      MuiAutocomplete: {
        defaultProps: {
          openText: 'Öppna',
          closeText: 'Stäng',
          clearText: 'Rensa',
          noOptionsText: 'Inget alternativ',
        },
      },
      MuiFormControl: {
        defaultProps: {
          margin: 'normal',
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: ({ theme }) => ({
            background: theme.palette.background.paper,
          }),
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: ({ theme }) => ({
            '& .MuiSwitch-switchBase': {
              '&.Mui-checked': {
                color: theme.palette.success.main,
                '& + .MuiSwitch-track': {
                  backgroundColor: theme.palette.success.light,
                },
              },
            },
          }),
        },
      },
    },
  });
}
