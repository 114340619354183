import { CheckboxesProps, Checkboxes as MUICheckboxes } from 'mui-rff';

type Props = Omit<CheckboxesProps, 'data'> & {
  options:
    | { value: boolean; label: string }
    | Array<{
        value: string | boolean;
        label: string;
      }>
    | { value: string | boolean; label: string };
};

export const Checkboxes = ({ options, ...props }: Props) => {
  return <MUICheckboxes data={options} color="primary" {...props} />;
};
