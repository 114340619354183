import { Check, ErrorOutline } from '@mui/icons-material';
import {
  Alert,
  Card,
  CardHeader,
  CircularProgress,
  Divider,
  List,
  ListItem,
} from '@mui/material';

import { FlatSinneDeviceInfo } from '@/types/services';

import { ListItemText } from './ListItemText';
import { useGetSimcardInfo } from './hooks/useGetSimcardInfo';

type Props = {
  serviceId: number;
  deviceInfo: FlatSinneDeviceInfo | undefined;
  isFetchingDeviceInfo: boolean;
  deviceInfoError: boolean;
};
export const SimcardCheck = ({
  serviceId,
  deviceInfo,
  isFetchingDeviceInfo,
  deviceInfoError,
}: Props) => {
  const circleSize = 20;

  const {
    data: simcard,
    isFetching: isFetchingSimcard,
    isSuccess: simcardSuccess,
    isError: simcardError,
  } = useGetSimcardInfo(serviceId);

  const serviceDeviceImei = deviceInfo?.imei;
  const imeiFromTelia = simcard?.data?.imei;
  const imeisMatch = serviceDeviceImei === imeiFromTelia;

  return (
    <Card>
      <CardHeader title="SIM-kort" />
      <Divider />
      {!imeisMatch && (
        <Alert severity="warning">
          Kontrollera att IMEI-numret på enheten stämmer överens med det som
          finns angivet på tjänsten.
        </Alert>
      )}
      {deviceInfoError && (
        <Alert severity="error">
          Ett fel uppstod när vi hämtade information om enheten.
        </Alert>
      )}
      <List dense disablePadding>
        <ListItem
          secondaryAction={
            isFetchingDeviceInfo ? (
              <CircularProgress size={circleSize} />
            ) : deviceInfoError || !imeisMatch ? (
              <ErrorOutline color="error" />
            ) : (
              <Check color="success" />
            )
          }
        >
          <ListItemText
            primary="Enhetens IMEI:"
            secondary={serviceDeviceImei}
            secondaryColor={imeisMatch ? 'success' : 'error'}
          />
        </ListItem>
        <ListItem
          secondaryAction={
            isFetchingSimcard ? (
              <CircularProgress size={circleSize} />
            ) : simcardError || !imeisMatch ? (
              <ErrorOutline color="error" />
            ) : (
              simcardSuccess && <Check color="success" />
            )
          }
        >
          <ListItemText
            primary="Identifierad IMEI hos operatören:"
            secondary={simcardSuccess && imeiFromTelia}
            secondaryColor={imeisMatch ? 'success' : 'error'}
          />
        </ListItem>
      </List>
    </Card>
  );
};
