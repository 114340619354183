import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { getAccountManagers } from '@/api/users';
import { PageWrapper } from '@/components';
import {
  AssignCustomers,
  TransferCustomers,
} from '@/modules/accountManagerSystem/components';

export const CustomerManagement = () => {
  const {
    data: accountManagers,
    isLoading: loadingAccountManagers,
    isError: errorAccountManager,
  } = useQuery({
    queryKey: ['accountManagers'],
    queryFn: getAccountManagers,
  });

  return (
    <PageWrapper
      pageTitle="Kundhantering"
      breadCrumbs={[
        { label: 'Account Manager System' },
        { label: 'Kundhantering' },
      ]}
    >
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5">
            Tilldela kunder en account manager
          </Typography>{' '}
        </AccordionSummary>
        <Divider />
        <AccordionDetails style={{ display: 'block' }}>
          <AssignCustomers
            accountManagers={accountManagers}
            loadingAccountManagers={loadingAccountManagers}
            errorAccountManager={errorAccountManager}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5">
            Flytta kunder mellan account managers
          </Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails style={{ display: 'block' }}>
          <TransferCustomers
            accountManagers={accountManagers}
            loadingAccountManagers={loadingAccountManagers}
            errorAccountManager={errorAccountManager}
          />
        </AccordionDetails>
      </Accordion>
    </PageWrapper>
  );
};

export default CustomerManagement;
