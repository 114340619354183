import { Field, FieldProps } from 'react-final-form';

import { formatPinInput } from '@/helpers/parsing';
import { Personnummer } from '@/helpers/validators/personnummer';
import { ProtectionFormValues } from '@/modules/services';
import Yup, { isValidLuhn } from '@/utils/validation';

import { PinTextFieldAdapter } from './PinTextFieldAdapter';

type Props<AllValues> = Partial<FieldProps<any, any>> & {
  isOrgNr?: boolean;
  customValidator?: (value: string, allValues: AllValues) => void;
  fullWidth?: boolean;
};

export const PinInput = <AllValues extends ProtectionFormValues>({
  name = 'pin',
  label = 'Personnummer',
  required = true,
  isOrgNr = false,
  customValidator = undefined,
  fullWidth = true,
  ...rest
}: Props<AllValues>) => {
  const validate = async (value: string, allValues: AllValues) => {
    const schema = Yup.string()
      .when('$required', (required, schema) =>
        required ? schema.required() : schema,
      )
      .when('$isOrgNr', (isOrgNr, schema) => {
        if (value) {
          if (isOrgNr) {
            return schema.test({
              name: 'isOrgNr',
              test: (value: string) => {
                if (value.length !== 10) return false;
                return isValidLuhn(value);
              },
              message: 'Ogiltigt organisationsnummer',
            });
          } else {
            return schema.test({
              name: 'isPin',
              test: (value: string) => {
                return Personnummer.valid(value);
              },
              message: 'Ogiltigt personnummer.',
            });
          }
        }
        return undefined;
      })
      .nullable();

    try {
      await schema.validate(value, { context: { required, isOrgNr } });

      if (customValidator && value) {
        customValidator(value, allValues);
      }

      return undefined;
    } catch (e: any) {
      return e?.errors?.map((error: string) => error);
    }
  };

  return (
    <Field
      name={name}
      label={label}
      validate={(values, allValues) => {
        return validate(values, allValues as AllValues);
      }}
      format={(v) => (v ? formatPinInput(v, isOrgNr) : '')}
      parse={(v) => v && formatPinInput(v, isOrgNr)}
      formatOnBlur
      inputProps={{ 'data-testid': name }}
      fullWidth={fullWidth}
      {...rest}
    >
      {(props) => {
        return (
          <PinTextFieldAdapter
            isOrgNr={isOrgNr}
            required={required}
            onBlur={(event) => props.input.onBlur(event)}
            {...props}
          />
        );
      }}
    </Field>
  );
};
