import { Typography } from '@mui/material';
import { Skeleton } from '@mui/material';

import { BasicAlert } from '@/components/BasicAlert';
import { formatCurrency } from '@/modules/common/utils/currency';

import { useManagedCustomersArr } from '../hooks';
import { ManagedCustomer } from '../types';

type Props = {
  managedCustomers: ManagedCustomer[];
};

export const ManagerMeanArr = ({ managedCustomers }: Props) => {
  // TODO: ARR is a string when fetched on on a customer level. It should be a number.
  // when this is fixed it separate call to get ARR can be removed.
  // fieldsToFetch then can include totalARR:  managedCustomers: { id: true, activeServiceTypes: true, totalARR: true },
  // In the best of worlds this should be calculated on the backend.
  const { data, isLoading, isError } = useManagedCustomersArr(managedCustomers);

  const arr = data.map((obj) => obj?.message?.ARR);
  const administeredArr = arr.reduce((pVal, cVal) => pVal + cVal, 0);

  if (isLoading) return <Skeleton />;
  if (isError) return <BasicAlert />;
  return (
    <Typography variant="body2">
      {formatCurrency(administeredArr && administeredArr / data.length)}
    </Typography>
  );
};
