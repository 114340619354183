import { getObjectKeys } from '@/helpers/tsHelpers';
import { CamelCaseServiceName } from '@/types/services';

import { usePrivateSettings } from './usePrivateSettings';

export const useGetActiveServices = () => {
  const { services } = usePrivateSettings();

  const activeServices = getObjectKeys(services).filter(
    (service) => services[service],
  ) as CamelCaseServiceName[];

  return activeServices;
};
