import { ChangeEvent } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import { Input, Paper } from '@mui/material';
import '@mui/material/styles';
import { styled } from '@mui/material/styles';

type Props = {
  onChange: (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => void;
  placeholder?: string;
};

const SearchInput = (props: Props) => {
  const { placeholder, onChange, ...rest } = props;

  return (
    <Paper
      sx={(theme) => ({
        borderRadius: '4px',
        alignItems: 'center',
        padding: theme.spacing(1),
        display: 'flex',
        flexBasis: 420,
      })}
      {...rest}
    >
      <StyledSearchIcon />
      <Input
        {...rest}
        sx={{
          flexGrow: 1,
          fontSize: '14px',
          lineHeight: '16px',
          letterSpacing: '-0.05px',
        }}
        placeholder={placeholder}
        disableUnderline
        onChange={(
          event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
        ) => onChange(event)}
      />
    </Paper>
  );
};

const StyledSearchIcon = styled(SearchIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

export default SearchInput;
