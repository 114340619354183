import { useQuery } from '@tanstack/react-query';

import { GetKeyTagsParams, getKeyTags } from '@/api';
import { queryKeys } from '@/constants/queryKeys';

export const useGetKeyTags = (filter: GetKeyTagsParams) => {
  return useQuery({
    queryKey: queryKeys.keyTags.filteredList(filter),
    queryFn: () => {
      return getKeyTags(filter);
    },
  });
};
