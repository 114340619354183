import { ListItem } from '@mui/material';

import { NumberedListItem } from '@/components/NumberedListItem';

import { CustomerProps } from '../../../../../types';
import { ListTitle } from '../../../commonStyles';
import { EmailTemplate } from '../../EmailTemplate';

export function Todo({
  customer,
  phoneNumbers,
  companyName,
  customerEmailAddresses,
}: CustomerProps) {
  const emailTemplate = `
  Hej!

  På uppdrag av ${customer.fullName} med personnummer ${
    customer.pin
  } har vi på ${companyName}
  fått i uppdrag att spärra kundens mobiltelefon.

  Mobiltelefonnummer: _____________
  IMEI nummer: ______________________
  Diarienummer polisanmälan: ___________

  För att säkerställa att detta blir genomfört ber vi er därför bekräfta att
  spärren är genomförd till e-post ${
    customer.email ? customer.email : '[ Kunds e-post ]'
  }.

  Behöver ni komma i kontakt med oss ringer ni oss på ${phoneNumbers.customer}.

  Mvh,
  Spärrlinjen hos ${companyName}`;

  return (
    <>
      <ListTitle variant="h6">Att göra:</ListTitle>
      <NumberedListItem
        number={1}
        text="Ring till kunds mobiloperatör och spärra mobil samt eventuellt abonnemang om kund önskat detta. Om utgivaren ej har fungerande spärrnummer t.ex. p.g.a. andra öppettider, så maila företaget och skriv detta:"
      />
      <ListItem>
        <EmailTemplate
          customerId={customer.id}
          emailAddresses={customerEmailAddresses}
          template={emailTemplate}
        />
      </ListItem>

      <NumberedListItem
        number={2}
        text={`Ring tillbaka till kunden och meddela om de åtgärder som genomförts.`}
      />
    </>
  );
}
