import React from 'react';

import {
  Box,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

type Props = {
  cardContent: React.ReactNode;
  title?: string;
  cardActions?: React.ReactNode;
  titleComponent?: React.ReactNode;
  marginBottom?: string;
  contentPadding?: number;
};

export const SimpleCard = ({
  title,
  titleComponent,
  cardContent,
  cardActions,
  marginBottom = '1.5rem',
  contentPadding = 2,
}: Props) => {
  return (
    <Card style={{ marginBottom }}>
      {titleComponent ? (
        <TitleComponentWrapper>{titleComponent}</TitleComponentWrapper>
      ) : (
        <Title variant="h5">{title}</Title>
      )}

      <Divider />
      <Box>
        <CardContent sx={{ padding: contentPadding }}>
          {cardContent}
        </CardContent>
        {cardActions && <StyledCardActions>{cardActions}</StyledCardActions>}
      </Box>
    </Card>
  );
};

const TitleComponentWrapper = styled('header')(({ theme }) => ({
  padding: theme.spacing(2),
}));

const Title = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const StyledCardActions = styled(CardActions)(({ theme }) => ({
  paddingRight: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(2),
}));
