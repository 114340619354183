import { Card, CardContent, CardHeader, Divider } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FormApi } from 'final-form';
import { Form } from 'react-final-form';

import { Dropdown } from '@/components/form';
import { useSnackbar } from '@/hooks';
import httpClient from '@/utils/httpClient';
import { fieldRequired } from '@/utils/validation';

import { Team } from '../../types';
import { MembersAutoComplete } from './MembersAutoComplete';
import { SaveButton } from './SaveButton';

type FormValues = {
  team: string;
  members: string[];
};

type Props = {
  teamOptions?: { text: string; value: string }[];
  memberOptions?: { text: string; value: string }[];
  teams?: Team[];
};

export const AddMembersCard = ({
  teamOptions,
  memberOptions,
  teams,
}: Props) => {
  const snackBar = useSnackbar();
  const queryClient = useQueryClient();

  const addMembersMutation = useMutation({
    mutationFn: async (values: FormValues) => {
      const currentMembers = teams!.find(
        (team) => team['@id'] === values.team,
      )!.members;
      const newMembers = currentMembers!.concat(values.members);

      return await httpClient.put(values.team, {
        members: newMembers,
      });
    },
    onSuccess: (response) => {
      queryClient.setQueryData<Team[]>(['amsTeams'], (oldData) => {
        const index = oldData!.findIndex(
          (t) => t['@id'] === response.data['@id'],
        );
        const newData = [...oldData!];
        newData[index] = response.data;

        return newData;
      });
      snackBar({ type: 'success', message: 'Uppdatering lyckades.' });
    },
    onError: () => {
      queryClient.refetchQueries({
        queryKey: ['amsTeams'],
      });
      snackBar({ type: 'error', message: 'Uppdatering misslyckades.' });
    },
  });

  const submitHandler = (values: FormValues, form: FormApi<FormValues>) => {
    addMembersMutation.mutate(values);
    form.restart();
  };

  if (!teamOptions || !memberOptions || !teams) return null;

  return (
    <Card>
      <CardHeader title="Lägg till medlemmar i ett team" />
      <Divider />
      <CardContent>
        <Form<FormValues>
          onSubmit={(values: FormValues, form) => submitHandler(values, form)}
          initialValues={{ members: [] }}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Dropdown
                name="team"
                label="Välj team"
                options={teamOptions}
                fieldProps={{
                  validate: fieldRequired,
                }}
              />

              <MembersAutoComplete
                multiple
                name="members"
                label="Välj medlemmar att lägga till"
                options={memberOptions}
                fieldProps={{
                  validate: fieldRequired,
                }}
              />
              <SaveButton />
            </form>
          )}
        />
      </CardContent>
    </Card>
  );
};
