import { Service } from '@/types/services';

export type OrderFileModalType = Service.PROTECTION | Service.SINNE;

export const OrderFileEndpointMap: { [key in OrderFileModalType]: string } = {
  [Service.PROTECTION]: '/protections/orderfiles',
  [Service.SINNE]: '/sinnes/orderfiles',
};

export const OrderFileServices: OrderFileModalType[] = [
  Service.PROTECTION,
  Service.SINNE,
];
