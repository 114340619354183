import React from 'react';

import { Check, Clear, Edit } from '@mui/icons-material';
import {
  Button,
  Card,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useQuery } from '@tanstack/react-query';

import { getPrices } from '@/api/prices';
import { Pagination } from '@/components/Pagination';
import { SimpleTable } from '@/components/SimpleTable';
import { PureDropdown } from '@/components/form';
import { ServiceIcon } from '@/components/icons';
import {
  CustomerCategoryTypes,
  PRICE_TRANSLATIONS,
  PRICE_TYPES,
  customerCategoryTypeOptions,
} from '@/constants';
import { queryKeys } from '@/constants/queryKeys';
import { config } from '@/helpers/config';
import { getCamelCaseServiceName } from '@/helpers/tsHelpers';
import { usePrivateSettings } from '@/hooks';
import { PriceItemWithType } from '@/types/prices';

import { PriceModal } from './PriceModal';

type Props = {
  type: keyof typeof PRICE_TYPES;
  title: string;
};

export const PriceList = ({ type, title }: Props) => {
  const [page, setPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(100);

  const [sortOrder, setSortOrder] = React.useState<'asc' | 'desc'>('asc');

  const [service, setService] = React.useState<'sinne' | 'homegate'>('sinne');

  const [modalOpen, setModalOpen] = React.useState(false);

  const [priceToEdit, setPriceToEdit] = React.useState<string>();

  const queryKey = [
    queryKeys.getPrices,
    page,
    perPage,
    type,
    { order: { price: sortOrder } },
    service,
  ];

  const [customerType, setCustomerType] = React.useState(
    CustomerCategoryTypes.COMPANY,
  );

  const { services, servicesNames } = usePrivateSettings();

  const orderOptions = [
    { id: 'asc', value: 'asc', text: 'Pris - lågt till högt' },
    { id: 'desc', value: 'desc', text: 'Pris - högt till lågt' },
  ];

  const serviceOptions = Object.keys(services).reduce(
    (options, key) => {
      if (key === 'sinne' && services[key]) {
        options.push({
          id: key,
          value: key,
          text: servicesNames[key],
        });
      }

      if (
        key === 'homegate' &&
        services[key] &&
        config.homegateCampaignEnabled
      ) {
        options.push({
          id: key,
          value: key,
          text: servicesNames[key],
        });
      }

      return options;
    },
    [] as Array<{ id: string; value: string; text: string }>,
  );

  const {
    data: prices,
    isLoading,
    isSuccess,
  } = useQuery({
    queryKey: queryKey,

    queryFn: () =>
      getPrices({
        page: page + 1,
        perPage,
        type,
        order: { price: sortOrder },
        ...(service ? { service } : null),
      }),
  });

  const handlePageChange = (_: any, newPage: React.SetStateAction<number>) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: { target: { value: string } }) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    setPerPage(rowsPerPage);
  };

  const handleEdit = (priceId: string) => {
    setPriceToEdit(priceId);
    setModalOpen(true);
  };

  const handleVisibility = () => {
    setModalOpen(!modalOpen);
    setPriceToEdit(undefined);
  };

  const columns: Array<{
    label: string;
    renderer: (price: PriceItemWithType) => React.ReactNode;
    align?: 'right' | 'left';
  }> = [
    {
      label: 'Pris',
      renderer: (price) =>
        `${
          customerType === CustomerCategoryTypes.PRIVATE
            ? `${price.priceWithVatInCurrency} - Inkl Moms`
            : `${price.priceInCurrency} - Exkl Moms`
        }`,
    },
    {
      label: 'Tjänst',
      align: 'right',
      renderer: (price) => (
        <ServiceIcon
          serviceName={getCamelCaseServiceName(price.service!)}
          isActive
        />
      ),
    },
    {
      label: 'Aktiv',
      align: 'right',
      renderer: (price) => (price.active ? <Check /> : <Clear color="error" />),
    },
    {
      label: 'Redigera',
      align: 'right',
      renderer: (price) => (
        <IconButton size="small" onClick={() => handleEdit(price['@id'])}>
          <Edit />
        </IconButton>
      ),
    },
  ];

  return (
    <>
      <Card>
        <CardHeader
          title={title}
          action={
            <Button
              variant="contained"
              color="primary"
              onClick={() => setModalOpen(true)}
            >
              Skapa {PRICE_TRANSLATIONS[type as keyof typeof PRICE_TYPES]}
            </Button>
          }
        />
        <Divider />
        <Grid
          container
          spacing={2}
          style={{ padding: '0.5rem' }}
          justifyContent="space-between"
        >
          <OptionsContainer container item spacing={2} xs={12} md={4}>
            <Grid item xs>
              <PureDropdown
                value={customerType}
                onChange={(e: any) => setCustomerType(e.target.value)}
                label="Pristyp"
                labelId="Pristyp"
                options={customerCategoryTypeOptions}
              />
            </Grid>

            <Grid item xs>
              <PureDropdown
                value={service}
                onChange={(e: any) => setService(e.target.value)}
                label="Tjänst"
                labelId="Tjänst"
                options={serviceOptions}
              />
            </Grid>
          </OptionsContainer>
          <Grid item xs={12} md={2}>
            <PureDropdown
              value={sortOrder}
              onChange={(e: any) => setSortOrder(e.target.value)}
              label="Sortering"
              labelId="Sortering"
              options={orderOptions}
            />
          </Grid>
        </Grid>
        <Divider />
        {isLoading ? (
          <LinearProgress />
        ) : (
          isSuccess && (
            <>
              <Pagination
                count={prices.length}
                rowsPerPage={perPage}
                page={page}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
              />
              <SimpleTable rows={prices} columns={columns} />
            </>
          )
        )}
      </Card>
      <PriceModal
        open={modalOpen}
        handleVisibility={handleVisibility}
        queryKey={queryKey}
        type={type}
        priceToEdit={priceToEdit}
      />
    </>
  );
};

const OptionsContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '100%',
    margin: 0,
    '&.MuiGrid-item': {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
}));
