import React from 'react';

import { ArrowForwardOutlined, LaunchOutlined } from '@mui/icons-material';
import {
  LinearProgress,
  Link as NativeLink,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

import { Pagination } from '@/components/Pagination';
import { SimpleTable } from '@/components/SimpleTable';
import { getFormattedDate } from '@/helpers/dates';
import { renderServiceIcons } from '@/helpers/render';
import useUser from '@/hooks/useUser';
import { Toolbar } from '@/modules/accountManagerSystem/components/Toolbar';

import { handleTableRowClick } from '../helpers';
import { QueryOptions, useFetchUserWithCustomers } from '../hooks';
import { OnboardingCustomer } from '../types';

type Props = {
  queryProps: Record<string, boolean>;
};
export const Table = ({ queryProps }: Props) => {
  const navigate = useNavigate();
  const { id: accountManagerId } = useUser();
  const [page, setPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(100);
  const [orderBy, setOrderBy] = React.useState('');
  const [queryOptions, setQueryOptions] = React.useState<QueryOptions>({
    q: '',
    type: 'standard',
    sortField: 'id',
    sortOrder: 'asc',
  });

  const [managedCustomers, setManagedCustomers] = React.useState<
    OnboardingCustomer[]
  >([]);

  const { isFetching, isError, isSuccess, data } = useFetchUserWithCustomers({
    queryOptions,
    page,
    perPage,
    accountManagerId,
    queryProps,
  });

  React.useEffect(() => {
    setManagedCustomers(data);
  }, [data, isFetching]);

  React.useEffect(() => {
    setManagedCustomers(orderTable(orderBy, managedCustomers));
  }, [orderBy]);

  return (
    <>
      <Toolbar
        options={ORDER_DROPDOWN_OPTIONS}
        orderHandler={(order) => setOrderBy(order)}
        order={orderBy}
        searchHandler={setQueryOptions}
      />

      <Paper>
        {isFetching ? (
          <LinearProgress />
        ) : isError ? (
          <Typography variant="h2">
            Kunde inte hämta kunder. Kontrollera din anslutning och försök igen.
          </Typography>
        ) : isSuccess && managedCustomers?.length > 0 ? (
          <>
            <SimpleTable
              columns={ONBOARDING_COLUMNS}
              rows={managedCustomers}
              onRowClick={(row) => handleTableRowClick(row, navigate)}
            />
            <Pagination
              count={managedCustomers?.length}
              page={page}
              rowsPerPage={perPage}
              onPageChange={(_, page) => setPage(page)}
              onRowsPerPageChange={(event) =>
                setPerPage(parseInt(event.target.value, 10))
              }
            />
          </>
        ) : (
          <Typography
            variant="h2"
            sx={{
              padding: 2,
            }}
          >
            Inga kunder som motsvarar kriterierna
          </Typography>
        )}
      </Paper>
    </>
  );
};

type Column = {
  label: string;
  renderer: (row: OnboardingCustomer) => React.ReactNode;
};
const ONBOARDING_COLUMNS: Column[] = [
  { label: 'Företag', renderer: (row) => row.companyName },
  { label: 'Kontakt', renderer: (row) => row.fullName },
  { label: 'Telefon', renderer: (row) => row.phone },
  { label: 'Mobil', renderer: (row) => row.mobile },
  { label: 'E-post', renderer: (row) => row.email },
  { label: 'Tjänster', renderer: (row) => renderServiceIcons(row) },
  {
    label: 'Senaste',
    renderer: (row) => {
      return row.notes[0] ? (
        <>
          <Typography style={{ fontSize: 'inherit' }}>
            {getFormattedDate(
              new Date(row.notes[0].createdAt),
              'yyyy-MM-dd HH:mm:ss',
            )}
          </Typography>
          <Tooltip title={row.notes[0].text}>
            <Typography noWrap style={{ width: '200px', fontSize: 'inherit' }}>
              {row.notes[0].text}
            </Typography>
          </Tooltip>
        </>
      ) : (
        'Saknar notering'
      );
    },
  },
  {
    label: '',
    renderer: (row) => (
      <>
        <NativeLink
          href={`/customer/${row.id}?tab=SERVICES`}
          target="_blank"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Tooltip title="Öppna i ny flik">
            <LaunchOutlined color="primary" style={{ cursor: 'pointer' }} />
          </Tooltip>
        </NativeLink>
        <Link to={`/customer/${row.id}?tab=SERVICES`}>
          <Tooltip title="Öppna i denna flik">
            <ArrowForwardOutlined color="primary" />
          </Tooltip>
        </Link>
      </>
    ),
  },
];

const ORDER_DROPDOWN_OPTIONS = [
  {
    id: 1,
    value: '{"fieldName": "id", "order": "asc"}',
    text: 'Kundnummer (stigande)',
  },
  {
    id: 2,
    value: '{"fieldName": "id", "order": "desc"}',
    text: 'Kundnummer (fallande)',
  },
  {
    id: 3,
    value: '{"fieldName": "note", "order": "asc"}',
    text: 'Notering (fallande)',
  },
  {
    id: 4,
    value: '{"fieldName": "note", "order": "desc"}',
    text: 'Notering (stigande)',
  },
];

// This is for sorting on client side, API does not support sorting on notes
export const orderTable = (
  orderBy: string,
  managedCustomers: OnboardingCustomer[],
) => {
  switch (orderBy) {
    case ORDER_DROPDOWN_OPTIONS[0]!.value:
      return [...managedCustomers].sort((a, b) => (a.id < b.id ? -1 : 1));
    case ORDER_DROPDOWN_OPTIONS[1]!.value:
      return [...managedCustomers].sort((a, b) => (a.id > b.id ? -1 : 1));
    case ORDER_DROPDOWN_OPTIONS[2]!.value:
      return [...managedCustomers].sort((a, b) => {
        const aCreatedAt = a.notes[0]?.createdAt;
        const bCreatedAt = b.notes[0]?.createdAt;
        if (aCreatedAt && bCreatedAt) {
          return new Date(aCreatedAt).getTime() < new Date(bCreatedAt).getTime()
            ? 1
            : -1;
        }
        return 0;
      });
    case ORDER_DROPDOWN_OPTIONS[3]!.value:
      return [...managedCustomers].sort((a, b) => {
        const aCreatedAt = a.notes[0]?.createdAt;
        const bCreatedAt = b.notes[0]?.createdAt;
        if (aCreatedAt && bCreatedAt) {
          return new Date(aCreatedAt).getTime() > new Date(bCreatedAt).getTime()
            ? 1
            : -1;
        }
        return 0;
      });
    default:
      return [...managedCustomers].sort((a, b) => {
        const aCreatedAt = a.notes[0]?.createdAt;
        const bCreatedAt = b.notes[0]?.createdAt;
        if (aCreatedAt && bCreatedAt) {
          return new Date(aCreatedAt).getTime() > new Date(bCreatedAt).getTime()
            ? 1
            : -1;
        }
        return 0;
      });
  }
};
