import { useState } from 'react';

import { jwtDecode } from 'jwt-decode';

import { UserRole } from '@/types/auth';

export default function useUserRoles() {
  const [userRoles] = useState(() => {
    const jwtToken = localStorage.getItem('jwt-token');

    if (jwtToken) {
      const { roles } = jwtDecode(jwtToken) as { roles: string[] };
      return roles;
    } else {
      return [];
    }
  });

  return userRoles as UserRole[];
}
