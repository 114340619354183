import { CustomerWithRequiredFields } from '@/types/customers';
import { getCustomerName } from '@/utils/customer';

export function getServiceInfoCardTitle(
  customer: Required<
    CustomerWithRequiredFields<'id' | 'category' | 'fullName' | 'companyName'>
  >,
) {
  return `${customer.id} - ${getCustomerName(customer)}`;
}
