import React from 'react';

import {
  Alert,
  AlertTitle,
  Button,
  CardActions,
  CardContent,
  Divider,
  LinearProgress,
  Typography,
} from '@mui/material';
import { useSelector } from '@xstate/react';

import { SinneConfigContext } from '@/modules/sinne/components/sinneConfigFlow/context/SinneConfigContextProvider';

export const UpdateService = () => {
  const machine = React.useContext(SinneConfigContext);
  const state = useSelector(machine, (state) => state);

  const isLoading = state.matches('UPDATE_SERVICE.loading');
  const isSuccess = state.matches('UPDATE_SERVICE.success');
  const isError = state.matches('UPDATE_SERVICE.error');
  const errorMessage = state.context.apiError?.message;
  return (
    <>
      <CardContent>
        {isSuccess && (
          <Alert severity="success">Tjänsten uppdaterades korrekt</Alert>
        )}
        {isLoading && (
          <>
            <LinearProgress />
            <Typography variant="h5">Uppdaterar tjänsten</Typography>
          </>
        )}
        {isError && (
          <>
            <Alert severity="error" sx={{ mb: 1 }}>
              <AlertTitle>Det gick inte att uppdatera tjänsten</AlertTitle>
              {errorMessage}
            </Alert>
            <Button
              variant="outlined"
              onClick={() => machine.send('RETRY_UPDATE_DEVICE_INFO')}
            >
              Försök igen
            </Button>
          </>
        )}
      </CardContent>
      <Divider />
      <CardActions>
        <Button disabled={!isSuccess} onClick={() => machine.send('PROCEED')}>
          Nästa
        </Button>
      </CardActions>
    </>
  );
};
