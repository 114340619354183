import { useContext } from 'react';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { CircularProgress, Typography } from '@mui/material';
import { useSelector } from '@xstate/react';

import { MAX_RETRIES, MINUTES_TO_WAIT } from '../constants/constants';
import { SIM_CARD_ACTIVE, SinneReturnStateContext } from '../context';
import { RetryButton } from './RetryButton';
import { StepRow } from './StepRow';

export const SimCardCheck = () => {
  const service = useContext(SinneReturnStateContext);
  const state = useSelector(service, (state) => state);
  const {
    simCard: { retries, status },
    imei,
  } = state.context;

  return (
    <>
      {state.matches('STATUS_CHECKS.SIM_CARD.loading') && (
        <StepRow>
          <CircularProgress size={14} />
          {!status && (
            <Typography component="span" variant="h6">
              Kontrollerar status på sim-kort...
            </Typography>
          )}
          {!!status && status.toUpperCase() !== SIM_CARD_ACTIVE && (
            <>
              <Typography
                variant="h6"
                color={(theme) => theme.palette.warning.light}
              >
                Sim-kortet är inte aktivt, kontrollerar igen
              </Typography>
              <Typography variant="h6">
                Antal försök: {retries} av {MAX_RETRIES} ({MINUTES_TO_WAIT} min)
              </Typography>
            </>
          )}
        </StepRow>
      )}
      {status?.toUpperCase() === SIM_CARD_ACTIVE && (
        <StepRow>
          <CheckCircleOutlineIcon color="success" sx={{ fontSize: 20 }} />
          <Typography component="span" variant="h6" padding={0} margin={0}>
            Sim-kortet är aktivt
          </Typography>
        </StepRow>
      )}
      {state.matches('STATUS_CHECKS.SIM_CARD.error.simInactive') && (
        <StepRow>
          <ErrorOutlineIcon color="error" sx={{ fontSize: 20 }} />
          <Typography component="span" variant="h6" padding={0} margin={0}>
            Sim-kortet är inte aktivt
          </Typography>
          <RetryButton
            onClick={() => service.send({ type: 'RETRY_SIM_CARD_CHECK', imei })}
          />
        </StepRow>
      )}

      {state.matches('STATUS_CHECKS.SIM_CARD.error.serverError') && (
        <StepRow>
          <ErrorOutlineIcon color="error" sx={{ fontSize: 20 }} />
          <Typography component="span" variant="h6" padding={0} margin={0}>
            Kontrollera sim-kort: Server error
          </Typography>

          <RetryButton
            onClick={() => service.send({ type: 'RETRY_SIM_CARD_CHECK', imei })}
          />
        </StepRow>
      )}
    </>
  );
};
