import React, { useState } from 'react';

import { Box, Button, Card, CardActions, CardContent } from '@mui/material';
import { Helmet } from 'react-helmet';

import { PageHeader } from '@/components/PageHeader';
import { usePrivateSettings } from '@/hooks';
import { USER_TYPES } from '@/modules/common/constants/entitiyNamesMap';
import {
  AdminTasksBreadcrumbs,
  CreateTaskModal,
  TasksFilter,
  TasksList,
} from '@/modules/tasks';
import {
  TaskTeamTypes,
  defaultStatuses,
} from '@/modules/tasks/constants/tasksModalsConstants';
import { FilterValues } from '@/modules/tasks/types/filters';

const AdminTasks = () => {
  const { servicesTypes } = usePrivateSettings();

  const team = TaskTeamTypes.ADMINISTRATOR;
  const adminTasksExcludingOnBoarding =
    Object.keys(servicesTypes.task[team]).filter((type) => type !== '16') || [];

  const [createTaskModalOpen, setCreateTaskModalOpen] = useState(false);

  const [filter, setFilter] = useState<FilterValues>({
    type: adminTasksExcludingOnBoarding,
    status: defaultStatuses.map((s) => s.value),
    deadline: true,
    search: '',
  });

  const handleFilterChange = (values: FilterValues) => {
    setFilter(values);
  };

  const handleShowCreateTaskModal = () => {
    setCreateTaskModalOpen(true);
  };

  return (
    <>
      <Helmet title={`Ärenden | ${USER_TYPES.admin}`} />

      <AdminTasksBreadcrumbs />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <PageHeader title="Ärenden" margin />
        <CardActions sx={{ mb: 2 }}>
          <Button
            color="primary"
            variant="contained"
            onClick={handleShowCreateTaskModal}
          >
            Lägg till
          </Button>
        </CardActions>
      </Box>

      <CreateTaskModal
        open={createTaskModalOpen}
        handleVisibility={() => setCreateTaskModalOpen(!createTaskModalOpen)}
        isBarringService={false}
        isTaskTeamView={true}
        defaults={{
          team: team,
          status: 0,
        }}
      />

      <Card sx={{ mb: 1 }}>
        <CardContent>
          <TasksFilter
            initialValues={filter}
            onChange={handleFilterChange}
            taskTeam={team}
          />
        </CardContent>
      </Card>

      <TasksList filter={filter} team={team} />
    </>
  );
};

export default AdminTasks;
