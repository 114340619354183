import { Grid, LinearProgress, Typography } from '@mui/material';

import { Can } from '@/components/Can';
import { SimpleCard } from '@/components/SimpleCard';
import useUserRoles from '@/hooks/useUserRoles';
import { SalesInformationEditModeSection } from '@/modules/services/components/sections';
import { CoProtectionParamSchema } from '@/types/services';

export type Props = {
  serviceName: 'coProtection';
  serviceDetails: CoProtectionParamSchema;
  updateInProgress: boolean;
};

export const SalesInformationForm = ({
  serviceDetails,
  serviceName,
  updateInProgress,
}: Props) => {
  const userRoles = useUserRoles();

  return (
    <SimpleCard
      marginBottom="0"
      titleComponent={
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h5">Sälj</Typography>
          </Grid>

          <Grid item>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                {!serviceDetails.customer.mobile && (
                  <Typography color="error">Ingen mobiluppsättning</Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      cardContent={
        <Can
          userRoles={userRoles}
          action="services.editSalesInfo"
          yes={() => (
            <>
              <section>
                <SalesInformationEditModeSection
                  serviceDetails={serviceDetails}
                  serviceName={serviceName}
                />
              </section>

              {updateInProgress && <LinearProgress />}
            </>
          )}
          no={() => (
            <section>
              <SalesInformationEditModeSection
                serviceDetails={serviceDetails}
                serviceName={serviceName}
                disabled
              />
            </section>
          )}
        />
      }
    />
  );
};
