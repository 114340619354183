import React, { useEffect, useState } from 'react';

import { Alert, AlertTitle, Grid, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { PhoneVerification } from '@/components/BankId';
import {
  CallInitiator,
  useGetBankIdCallInitiatorOptions,
} from '@/hooks/useGetBankIdCallInitiatorOptions';
import { GrandIdInitResponse } from '@/types/grandId';
import httpClient from '@/utils/httpClient';

import { VerifiedCustomer } from '../../../types';

type Props = {
  verifiedCustomer: VerifiedCustomer | null;
  setVerifiedCustomer: React.Dispatch<
    React.SetStateAction<VerifiedCustomer | null>
  >;
  pin: string;
};

export const DeactivateFraudProtection = ({
  verifiedCustomer,
  setVerifiedCustomer,
  pin,
}: Props) => {
  const { CALL_INITIATOR_ENUM } = useGetBankIdCallInitiatorOptions();
  const [callerPin, setCallerPin] = useState('');
  const [callInitiator, setCallInitiator] = useState<CallInitiator>(
    CALL_INITIATOR_ENUM.RP,
  );
  const [initiateBankId, setInitiateBankId] = useState(false);

  const { isLoading, isSuccess, error } = useQuery({
    queryKey: ['initGrandId', callerPin, callInitiator],
    queryFn: async () => {
      const { data } = await httpClient.get<GrandIdInitResponse>(
        `/grandid/initiate?pin=${callerPin}&callInitiator=${callInitiator}`,
      );

      setVerifiedCustomer(data);
      return data;
    },
    enabled: initiateBankId,
  });

  useEffect(() => {
    if (error) {
      console.log('BANK_ID ERROR', error);
    }
  }, [error]);

  return (
    <Grid container item spacing={2}>
      {verifiedCustomer?.userAttributes?.personalNumber !== pin && (
        <>
          <Grid container item xs={12}>
            <Grid container item xs={12}>
              <Typography variant="h3" fontSize="1rem">
                Signering med BankID krävs för att avaktivera en aktiv
                bedrägerispärr.
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={6}>
            {isLoading && <Alert severity="info">Be kunden öppna BankID</Alert>}
            {isSuccess && (
              <Alert severity="success">Kund med {callerPin} verifierad</Alert>
            )}
            {error && (
              <Alert severity="error">
                <AlertTitle>Fel vid verifiering</AlertTitle>
              </Alert>
            )}
            {!initiateBankId && (
              <PhoneVerification
                callerPin={callerPin}
                setCallerPin={setCallerPin}
                callInitiator={callInitiator}
                setCallInitiator={setCallInitiator}
                setInitiateBankId={setInitiateBankId}
              />
            )}
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        {verifiedCustomer?.userAttributes?.personalNumber === pin && (
          <Alert severity="success">
            <Typography variant="body1" fontSize="1rem">
              Verifiering av personnummer{' '}
              {verifiedCustomer?.userAttributes?.personalNumber} lyckades!
            </Typography>
          </Alert>
        )}

        {verifiedCustomer &&
          verifiedCustomer?.userAttributes?.personalNumber !== pin && (
            <Alert severity="error">Verifiering misslyckades!</Alert>
          )}
      </Grid>
    </Grid>
  );
};
