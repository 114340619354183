import { InfoOutlined } from '@mui/icons-material';
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Paper,
  Skeleton,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { getTemplateDataParams } from '@/api/files';
import { BasicAlert } from '@/components/BasicAlert';

import { getObjectKeys } from '../../helpers/tsHelpers';

export const TemplateParamsList = () => {
  const {
    data: templateParams,
    isLoading: loadingTemplateParams,
    isError: errorTemplateParams,
  } = useQuery({
    queryKey: ['getTemplateParams'],
    queryFn: getTemplateDataParams,
  });

  return (
    <Box>
      {loadingTemplateParams ? (
        <Skeleton width={100} />
      ) : errorTemplateParams ? (
        <BasicAlert />
      ) : (
        <List
          component={Paper}
          disablePadding
          subheader={
            <ListSubheader
              component={Box}
              display="flex"
              padding={2}
              alignItems="center"
            >
              <InfoOutlined color="primary" />
              <Typography variant="h5" style={{ marginLeft: '1rem' }}>
                Dessa parametrar kan användas
              </Typography>
            </ListSubheader>
          }
        >
          <Divider />

          {getObjectKeys(templateParams).map((param) => (
            <ListItem divider key={param as string}>
              <ListItemText
                primary={param as string}
                secondary={templateParams[param]}
              />
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
};
