import { USER_TYPES } from '@/modules/common/constants/entitiyNamesMap';

export const taskStatusOptions = [
  {
    value: 0,
    text: 'Öppen',
  },
  {
    value: 1,
    text: 'Stängd',
  },
  {
    value: 2,
    text: 'Väntar kund',
  },
  {
    value: 3,
    text: 'Vilande',
  },
  {
    value: 4,
    text: 'Bearbetas',
  },
  {
    value: 5,
    text: 'Winback',
  },
];

export const defaultStatuses = [
  {
    value: 0,
    text: 'Öppen',
  },
  {
    value: 2,
    text: 'Väntar kund',
  },
  {
    value: 3,
    text: 'Vilande',
  },
  {
    value: 4,
    text: 'Bearbetas',
  },
];

export const ApiTaskTeamNames = {
  CUSTOMER_SUPPORT: 'Customer Support',
  ADMINISTRATOR: 'Customer Success',
} as const;

export const TaskTeamTypes = {
  CUSTOMER_SUPPORT: 1,
  ADMINISTRATOR: 2,
} as const;

export type TaskTeamStringType =
  (typeof ApiTaskTeamNames)[keyof typeof ApiTaskTeamNames];

export const teamTypeOptions = [
  { value: TaskTeamTypes.CUSTOMER_SUPPORT.toString(), label: 'Kundservice' },
  { value: TaskTeamTypes.ADMINISTRATOR.toString(), label: USER_TYPES.admin },
];

export const TaskTeams = {
  [TaskTeamTypes.CUSTOMER_SUPPORT]: ApiTaskTeamNames.CUSTOMER_SUPPORT,
  [TaskTeamTypes.ADMINISTRATOR]: ApiTaskTeamNames.ADMINISTRATOR,
};
