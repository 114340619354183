import React from 'react';

import {
  Button,
  DialogContent,
  LinearProgress,
  TextField,
  Typography,
} from '@mui/material';
import { add, format } from 'date-fns';

import { DialogActions } from '@/components/DialogActions';
import { KEY_TAG_AND_STICKER_CODE_VALIDATION_REGEX } from '@/modules/services/constants';
import { BasicCustomerData } from '@/types/customers';

import { useCreateSticker } from '../../api-hooks/useCreateSticker';
import { useGetStickerPricePlanIds } from '../../hooks/useGetStickerPricePlanIds';
import { StickerItem } from '../../types/dto';
import { getStickerConfigStep2ErrorMessage } from '../../utils/getStickerConfigStep2ErrorMessage';

type Props = {
  onGoBack: () => void;
  onFinish: (createdStickers: StickerItem[]) => void;
  customer: BasicCustomerData;
};

export const Step2Partner = ({ onFinish, customer, onGoBack }: Props) => {
  const createSticker = useCreateSticker();
  const { getPricePlanId } = useGetStickerPricePlanIds();

  const [errorMessage, setErrorMessage] = React.useState('');
  const [stickersCreated, setStickersCreated] = React.useState<StickerItem[]>(
    [],
  );

  //#region Effects
  React.useEffect(() => {
    if (stickersCreated.length > 0) {
      onFinish(stickersCreated);
    }
  }, [onFinish, stickersCreated]);
  //#endregion

  //#region Async functions
  async function createStickerHandler(code: string) {
    const regDate = format(new Date(), 'yyyy-MM-dd');
    const billingMonth = parseInt(
      format(add(new Date(), { months: 1 }), 'M'),
      10,
    );

    const payload = {
      code,
      regDate,
      billingMonth,
      status: 1,
      billing: 0,
      kamId: 0,
      tb: 0,
      adminNote: '',
      pricePlanId: getPricePlanId(1),
      customer: `/customers/${customer.id}`,
    };

    createSticker.mutate(payload, {
      onSuccess: (data) => {
        setStickersCreated((prevState) => [...prevState, data]);
      },
      onError: (error: any) => {
        const message = getStickerConfigStep2ErrorMessage(error);
        setErrorMessage(message);
      },
    });
  }
  //#endregion

  //#region Functions

  function handleSubmit(e: React.SyntheticEvent) {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      code: { value: string };
    };
    const {
      code: { value },
    } = target;

    // TODO: Add translations
    if (value) {
      if (KEY_TAG_AND_STICKER_CODE_VALIDATION_REGEX.test(value)) {
        setErrorMessage('');
        createStickerHandler(value);
      } else {
        setErrorMessage('Fel kodformat');
      }
    }
  }
  //#endregion

  if (createSticker.isPending) {
    return <LinearProgress />;
  }

  // TODO: Add translations
  return (
    <form onSubmit={handleSubmit} noValidate autoComplete="off">
      <DialogContent>
        <Typography gutterBottom variant="h5">
          Steg 2: Skanna välkomstbrev
        </Typography>

        <section>
          <Typography gutterBottom>
            Kund:{' '}
            {customer.companyCategory
              ? customer.companyName
              : customer.fullName}
          </Typography>
        </section>

        <TextField
          autoFocus
          label="Serienummer"
          name="code"
          margin="normal"
          size="small"
          variant="outlined"
          fullWidth
        />

        {errorMessage && (
          <Typography gutterBottom color="error">
            {errorMessage}
          </Typography>
        )}

        {errorMessage && (
          <Typography gutterBottom color="error">
            {errorMessage}
          </Typography>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          type="button"
          color="primary"
          size="small"
          variant="contained"
          onClick={onGoBack}
        >
          Gå tillbaka
        </Button>

        <Button type="submit" color="primary" size="small" variant="contained">
          Nästa
        </Button>
      </DialogActions>
    </form>
  );
};
