import { DetailedStatsDistributionToDisplay } from '@/types/stats';

export type StatusesToDisplay = Extract<
  DetailedStatsDistributionToDisplay,
  'Brutto' | 'Netto' | 'Ånger' | 'Makulerad'
>;

export const SERVICE_STATUS_CHART_COLOR_MAP: Record<StatusesToDisplay, string> =
  {
    Brutto: '#6081c0',
    Netto: '#36af7b',
    Ånger: '#eab12a',
    Makulerad: '#e0175b',
  };
