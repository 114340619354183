import { useMutation } from '@tanstack/react-query';

import {
  GenerateBulkCustomLetterPayload,
  generateBulkCustomLetterPdf,
} from '@/api/customLetterTemplates';
import { generatePdfDownloadLink } from '@/utils/pdf';

export const useGenerateBulkCustomLettersMutation = () =>
  useMutation({
    mutationFn: async (payload: GenerateBulkCustomLetterPayload) => {
      const data = await generateBulkCustomLetterPdf(payload);
      generatePdfDownloadLink(data, 'Välkomstbrev');
    },
  });
