import { LinearProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { getUserById } from '@/api';
import { BasicAlert } from '@/components/BasicAlert';
import { SimpleTable } from '@/components/SimpleTable';
import useUser from '@/hooks/useUser';

import { ManagedCustomer } from '../../types';
import { ManagerMedianArr } from '../ManagerMedianArr';

const fieldsToFetch = {
  name: true,
  coProtectionOnboardingPercentage: true,
  firetextOnboardingPercentage: true,
  managedCustomersARR: true,
  managedCustomers: {
    id: true,
  },
};

type AccountManagerSummaryUser = {
  '@id': string;
  '@type': 'User';
  name: string;
  managedCustomers: ManagedCustomer[];
  coProtectionOnboardingPercentage: string;
  firetextOnboardingPercentage: string;
  managedCustomersARR: {
    firetext: string;
    protection: string;
    'co-protection': string;
    sticker: string;
    'key-tag': string;
    homegate: string;
    sinne: string;
    totalARR: string;
    meanARR: string;
  };
};

type Column = {
  label: string;
  renderer: (row: {
    name: string;
    managedCustomers: ManagedCustomer[];
    totalARR: string;
    meanARR: string;
  }) => React.ReactNode;
};

export const AccountManagerArrSummary = () => {
  const { id: userId } = useUser();

  const { data: user, status } = useQuery({
    queryKey: ['getUser', userId, fieldsToFetch],
    queryFn: async () => {
      const data = await getUserById<AccountManagerSummaryUser>(
        userId,
        fieldsToFetch,
      );

      return data;
    },
    enabled: !!userId,
  });

  const rows = [
    {
      name: user?.name,
      totalARR: user?.managedCustomersARR?.totalARR,
      meanARR: user?.managedCustomersARR?.meanARR,
      totalCustomers: user?.managedCustomers?.length,
      managedCustomers: user?.managedCustomers,
    },
  ];

  const columns: Column[] = [
    {
      label: 'Account Manager',
      renderer: ({ name }) => name,
    },
    {
      label: 'Totalt Antal kunder',
      renderer: ({ managedCustomers }) => managedCustomers?.length,
    },
    {
      label: 'Totalt Förvaltad ARR',
      renderer: ({ totalARR }) => totalARR,
    },
    {
      label: 'ARR / Medelvärde',
      renderer: ({ meanARR }) => meanARR,
    },
    {
      label: 'ARR / Median',
      renderer: ({ managedCustomers }) => (
        <ManagerMedianArr managedCustomers={managedCustomers} />
      ),
    },
  ];

  const currentView = {
    pending: <LinearProgress />,
    success: <SimpleTable rows={rows} columns={columns} />,
    error: <BasicAlert message="Något gick fel" />,
  };

  return currentView[status];
};
