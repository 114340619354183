import React from 'react';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { StatsDetailedTableData } from '@/modules/services/utils/stats';

type Props = {
  data: StatsDetailedTableData;
};
//TODO set colspan index to constant
export const DetailedInfoTable = ({ data }: Props) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {data.headers.map((item, index) => (
              <TableCell key={index} colSpan={index <= 1 ? 1 : 2}>
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {data.data.length > 0 ? (
            <>
              {data.data.map((item, index) => (
                <TableRow hover key={index}>
                  {item.map((cellData, index) => (
                    <TableCell key={index}>{cellData}</TableCell>
                  ))}
                </TableRow>
              ))}
            </>
          ) : (
            <TableRow>
              <TableCell>
                <Typography>Ingen Data</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
