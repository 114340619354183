import { Box, Tabs as MuiTabs, Tab } from '@mui/material';

import { useAuth } from '@/hooks/useAuth';
import { useTabChangeHandler } from '@/hooks/useTabChangeHandler';
import { Tabmap } from '@/types/tabs';
import { checkRolesAccess } from '@/utils/authorization';

import { TabPanel } from '../TabPanel';

type TabsProps<ViewProps> = {
  tabs: Tabmap[];
  viewProps?: ViewProps;
  defaultTabIndex?: number;
};

export const Tabs = <ViewProps extends Record<string, any>>({
  tabs,
  viewProps,
  defaultTabIndex = 0,
}: TabsProps<ViewProps>) => {
  const { activeTab, tabChangeHandler } = useTabChangeHandler({
    tabs,
    defaultTabIndex,
  });

  const { getAuthenticatedUserRoles } = useAuth();
  const userRoles = getAuthenticatedUserRoles();

  return (
    <>
      <MuiTabs
        value={activeTab}
        onChange={(_, newTab) => {
          tabChangeHandler(newTab);
        }}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
      >
        {tabs.map((tab) => {
          if (tab.authRule) {
            if (checkRolesAccess(userRoles!, tab.authRule)) {
              return (
                <Tab
                  key={tab.title}
                  label={tab.title}
                  value={tab.title}
                  data-testid={tab['data-testid']}
                />
              );
            }
            return null;
          }
          return (
            <Tab
              key={tab.title}
              label={tab.title}
              value={tab.title}
              data-testid={tab['data-testid']}
            />
          );
        })}
      </MuiTabs>
      <Box
        sx={{ marginTop: 2, maxWidth: (theme) => theme.breakpoints.values.lg }}
      >
        {tabs.map((tab) => (
          <TabPanel key={tab.title} value={activeTab} index={tab.title}>
            {tab.view(viewProps)}
          </TabPanel>
        ))}
      </Box>
    </>
  );
};
