import {
  Box,
  Divider,
  Drawer as MuiDrawer,
  Stack,
  styled,
} from '@mui/material';

import { Profile, SidebarNav } from './components';

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: () => true,
})(({ theme }) => ({
  '& .MuiDrawer-paper': {
    boxSizing: 'border-box',
    width: 240,
    marginTop: 56,
    height: 'calc(100% - 56px)',
    [theme.breakpoints.up('sm')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)',
    },
  },
}));

type Props = {
  onClose: () => void;
  open: boolean;
  variant: 'permanent' | 'persistent' | 'temporary';
};

export const Sidebar = ({ open, variant, onClose }: Props) => {
  return (
    <Drawer anchor="left" onClose={onClose} open={open} variant={variant}>
      <Stack sx={{ overflowX: 'scroll', height: '100%' }}>
        <Profile />
        <Divider />
        <SidebarNav />
        <Box pl={2} pb={2} mt="auto">
          Version: {import.meta.env.VITE_APP_VERSION}
        </Box>
      </Stack>
    </Drawer>
  );
};
