import {
  AgreementUploadPayload,
  CustomerAgreementsResponse,
} from '@/types/agreements';
import httpClient from '@/utils/httpClient';

export type DownloadFilePayload = {
  id: number;
  filename: string;
};

export async function downloadFile({ id, filename }: DownloadFilePayload) {
  return httpClient({
    url: `agreements/${id}/download`,
    method: 'GET',
    responseType: 'blob',
  }).then((response) => {
    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: response.headers['content-type'] }),
    );

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${filename}`);
    document.body.appendChild(link);
    link.click();
    return url;
  });
}

export async function getAgreementsByCustomer(customer: number) {
  const { data } = await httpClient.get<CustomerAgreementsResponse>(
    '/agreements',
    {
      params: {
        customer,
      },
    },
  );

  return data['hydra:member'];
}

export async function uploadMultipleAgreements(
  payload: AgreementUploadPayload,
) {
  const formData = new FormData();
  formData.append('type', payload.type);
  Array.from(payload.files).forEach((file) => {
    formData.append('files[]', file);
  });

  const { data } = await httpClient.post(
    '/agreements/upload.multiple',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );

  return data;
}
