import { useQuery } from '@tanstack/react-query';

import { getFileImportValidationProcess } from '@/api/files';
import { generateKeys } from '@/constants/queryKeys';

export const fileImportValidationProcessQueryKey = generateKeys(
  'fileImportValidationProcess',
);

export const useGetFileImportValidationProcess = (uuid: string) => {
  return useQuery({
    queryKey: fileImportValidationProcessQueryKey.detailById(uuid),
    queryFn: async () => {
      const response = await getFileImportValidationProcess(uuid);

      return response['hydra:member'][0];
    },
    enabled: Boolean(uuid),
  });
};
