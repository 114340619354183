import {
  Divider,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Paper,
} from '@mui/material';

type Props = {
  files: File[] | null;
  title?: string;
};

export const ChosenFilesList = ({ files, title = 'Valda filer' }: Props) => {
  if (!files) {
    return null;
  }

  return (
    <Paper square elevation={2}>
      <List
        dense
        subheader={<ListSubheader component="div">{title}</ListSubheader>}
      >
        <Divider />
        {Array.from(files).map((file) => {
          return (
            <ListItem key={file.name}>
              <ListItemText primary={file.name} secondary={file.type} />
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );
};
