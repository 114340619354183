import React from 'react';

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  LinearProgress,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';

import { Checkboxes, Dropdown, TextInput } from '@/components/form';
import { customerCategoryTypeOptions } from '@/constants';
import {
  parseToNumbers,
  trimWhiteSpaceInObjectValues,
} from '@/helpers/parsing';
import { useGetCampaignData } from '@/hooks';
import { useGetCampaignServiceOptions } from '@/hooks/useGetCampaignServiceOptions';
import { CampaignModalProps } from '@/modules/campaigns/components/CampaignModal';
import { TemplateArrayFields } from '@/modules/campaigns/components/CampaignModal/TemplateArrayFields';
import { ServerErrorAlert } from '@/modules/campaigns/components/CampaignModal/components/ServerErrorAlert';
import { useCampaignMutation } from '@/modules/campaigns/components/CampaignModal/hooks/useCampaignMutation';
import { useFormSubmissionHandler } from '@/modules/campaigns/components/CampaignModal/hooks/useFormSubmissionHandler';
import { HydraViolationError } from '@/types/errors';
import { Template } from '@/types/services';
import { makeValidation } from '@/utils/validation';

import { sinneValidationSchema } from './SinneValidationSchema';

export const SinneCampaignForm = ({
  campaignToEdit,
  selectedCampaignType,
  handleVisibility,
}: CampaignModalProps) => {
  const [serverErrors, setServerErrors] = React.useState<
    HydraViolationError[] | null
  >(null);

  const campaignOptions = useGetCampaignServiceOptions();

  const { mutate, error, isError, isSuccess, isPending } = useCampaignMutation({
    isUpdate: campaignToEdit,
  });

  const {
    templateData,
    isLoadingCampaign,
    campaignData: rawCampaignData,
  } = useGetCampaignData(campaignToEdit);

  useFormSubmissionHandler({
    campaignToEdit,
    error,
    isError,
    isSuccess,
    handleCloseModal: handleVisibility,
    setErrors: setServerErrors,
  });

  if (isLoadingCampaign) {
    return <LinearProgress />;
  }

  return (
    <Form
      onSubmit={(v) => {
        const { service, ...rest } = trimWhiteSpaceInObjectValues(v);
        const { templates, ...campaign } = rest;

        mutate({
          service: campaignToEdit ?? service,
          payload: {
            templates: templates.map((template: Template) => {
              if ('@id' in template) {
                return {
                  ...template,
                  id: template['@id'],
                };
              }

              return template;
            }),
            ...(campaignToEdit || service === 'sinne_campaigns'
              ? campaign
              : { campaign: { ...campaign } }),
            // TODO: Refactor to use separate mutations for creation and edit. Than we can remove `any`
          } as any,
        });
      }}
      mutators={{ ...arrayMutators }}
      validate={makeValidation(sinneValidationSchema)}
      keepDirtyOnReinitialize
      subscription={{
        submitting: true,
        pristine: true,
        values: true,
        errors: true,
      }}
      initialValues={{
        ...(campaignToEdit
          ? { ...rawCampaignData, templates: templateData }
          : {
              active: false,
              templates: templateData,
              service: selectedCampaignType,
            }),
      }}
    >
      {({
        values,
        handleSubmit,
        pristine,
        form: {
          mutators: { push, pop },
        },
      }) => {
        return (
          <form onSubmit={handleSubmit} autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {isPending && <LinearProgress />}
              </Grid>
              <ServerErrorAlert
                serverErrors={serverErrors}
                isUpdate={!!campaignToEdit}
              />

              <Grid sx={{ pr: 2 }} item xs={12} md>
                <Card elevation={0}>
                  <CardHeader
                    title="Kampanjdata"
                    action={
                      <Button
                        disabled={
                          pristine ||
                          isPending ||
                          (values.templates && values.templates.length === 0)
                        }
                        variant="contained"
                        type="submit"
                        color="primary"
                      >
                        Spara
                      </Button>
                    }
                  />
                  <CardContent>
                    <>
                      <Dropdown
                        name="service"
                        label="Produkt"
                        options={campaignOptions}
                      />
                      <TextInput name="name" label="Namn" />
                      <TextInput name="description" label="Beskrivning" />
                      <Dropdown
                        name="customerType"
                        label="Kundkategori"
                        type="number"
                        options={customerCategoryTypeOptions}
                      />
                      <TextInput
                        name="contractMonths"
                        label="Bindningstid (månader)"
                        fieldProps={{
                          parse: parseToNumbers,
                          defaultValue: 0,
                        }}
                      />
                      <TextInput
                        name="freeMonths"
                        label="Gratismånader"
                        fieldProps={{
                          parse: parseToNumbers,
                          defaultValue: 0,
                        }}
                      />
                      <TextInput
                        name="noticePeriod"
                        label="Uppsägningstid (månader)"
                        fieldProps={{
                          parse: parseToNumbers,
                          defaultValue: 0,
                        }}
                      />
                      <TextInput
                        name="withdrawalPeriodDays"
                        label="Ångerrätt (dagar)"
                        type="number"
                        fieldProps={{
                          parse: parseToNumbers,
                          defaultValue: 0,
                        }}
                      />
                      <TextInput name="package" label="Paket" />
                      {values.service === 'homegate_campaigns' && (
                        <>
                          <TextInput
                            name="specialHeader"
                            label="Specialtitel"
                          />
                          <TextInput name="specialRow1" label="Specialrad 1" />
                          <TextInput name="specialRow2" label="Specialrad 2" />
                          <TextInput name="specialRow3" label="Specialrad 3" />
                        </>
                      )}
                      <Checkboxes
                        name="active"
                        options={{
                          label: 'Aktiv',
                          value: true,
                        }}
                      />
                    </>
                  </CardContent>
                </Card>
              </Grid>

              <Divider
                orientation="vertical"
                flexItem
                style={{ marginRight: '-1px' }}
              />

              <Grid item xs={12} md>
                <Card elevation={0}>
                  <CardHeader
                    title="Enheter"
                    subheader={
                      !values.customerType && 'Välj kundkategori först'
                    }
                    action={
                      <>
                        <Button
                          type="button"
                          variant="contained"
                          color="primary"
                          disabled={!values.customerType}
                          onClick={() => {
                            const newTemplate = {
                              price: null,
                              startFee: null,
                              installationFee: null,

                              ...(values.service === 'homegate_campaigns' && {
                                hardwareSurcharge: null,
                              }),
                            };

                            push!('templates', newTemplate);
                          }}
                          style={{ marginRight: '0.5rem' }}
                        >
                          Lägg till enhet
                        </Button>
                        <Button
                          type="button"
                          variant="outlined"
                          disabled={values.templates.length === 0}
                          onClick={() => pop!('templates')}
                        >
                          Ta bort enhet
                        </Button>
                      </>
                    }
                  />
                  <CardContent>
                    <UnitContainer container item>
                      <TemplateArrayFields
                        values={values}
                        templateData={templateData}
                      />
                    </UnitContainer>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Form>
  );
};

const UnitContainer = styled(Grid)({
  '& > div:not(:first-of-type)': {
    marginTop: '1.5rem',
  },
  '& > div': {
    paddingBottom: '1.5rem',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
});
