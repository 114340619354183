import React from 'react';

import { Button, Stack, Tooltip } from '@mui/material';
import { Form } from 'react-final-form';

import { CustomTemplate } from '@/types/files';

import { TemplateListEditButton } from './TemplateListEditButton';
import { useUploadCustomTemplate } from './hooks/useUploadCustomTemplate';

type Props = {
  template: CustomTemplate;
};
export const EditTemplateForm = ({ template }: Props) => {
  const editMutation = useUploadCustomTemplate();

  return (
    <Form
      onSubmit={(values: { file: File[] }, form) => {
        const { reset } = form;
        const payload = {
          templateType: 'custom',
          templateName: template.name,
          c5h2Address: template.c5h2Address,
          ...values,
        } as const;

        editMutation.mutateAsync(payload).then(() => reset());
      }}
    >
      {({ handleSubmit, values, form: { reset } }) => (
        <form onSubmit={handleSubmit}>
          {!values.file ? (
            <TemplateListEditButton
              name="file"
              buttonId={`replace-custom-template-${template.name}`}
            />
          ) : (
            <Stack gap={2} direction="row">
              <Button color="error" onClick={() => reset()}>
                Avbryt
              </Button>
              <Tooltip title="Ersätt befintliga brevmallen med den valda filen">
                <Button type="submit" variant="contained" color="primary">
                  Spara
                </Button>
              </Tooltip>
            </Stack>
          )}
        </form>
      )}
    </Form>
  );
};
