import { TemplateTypes } from '@/types/files';

export const templateTypeOptions: Array<{
  value: TemplateTypes;
  text: string;
}> = [
  { value: 'hard_lock', text: 'Hårdspärr' },
  { value: 'power_of_attourney', text: 'Fullmakt' },
  { value: 'termination_form', text: 'Uppsägningsblankett' },
  { value: 'final_invoice_threat', text: 'Hot om slutfaktura' },
  { value: 'uncontactable', text: 'Okontaktbar' },
];

export const protectionSecurityTemplateTypeOptions: Array<{
  value: TemplateTypes;
  text: string;
}> = [{ value: 'power_of_attourney', text: 'Fullmakt' }];
