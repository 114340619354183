import { Box, LinearProgress, Paper, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { getSinneKeyFigures } from '@/api/sinne';
import { BasicAlert } from '@/components/BasicAlert';

export const KeyFigures = () => {
  const { isFetching, data, isError } = useQuery({
    queryKey: ['keyfigures'],

    queryFn: async () => {
      return await getSinneKeyFigures();
    },
  });

  return (
    <Box>
      {isFetching ? (
        <LinearProgress />
      ) : isError ? (
        <BasicAlert />
      ) : (
        <Paper style={{ padding: '2rem' }}>
          <Typography>
            % Onboarded: {data.onboardedPercentage} ({data.onboarded} av{' '}
            {data.total})
          </Typography>
        </Paper>
      )}
    </Box>
  );
};
