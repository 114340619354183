import React from 'react';

import { TextFieldProps } from 'mui-rff';
import { ValidationError } from 'yup';

import { identity } from '@/helpers';
import Yup from '@/utils/validation';

import { TextInput } from '../TextInput';

type Props = TextFieldProps & {
  name?: string;
  label?: string;
  required?: boolean;
  customValidator?: any;
};

export const PhoneInput = ({
  name = 'phone',
  label = 'Telefon',
  required = true,
  customValidator = undefined,
  ...rest
}: Props) => {
  const validate = async (value: string, allValues: any) => {
    const schema = Yup.string()
      .matches(/^[0-9]+$/, {
        excludeEmptyString: true,
        message: 'Enbart siffror accepteras',
      })
      .when('$required', (required, schema) =>
        required ? schema.required() : schema,
      )
      .nullable();

    try {
      await schema.validate(value, { context: { required } });
      if (customValidator && value) {
        const result = customValidator(value, allValues);
        if (result && result.then) {
          await result;
        }
      }
      return undefined;
    } catch (e: unknown) {
      if (e instanceof ValidationError) {
        return e.errors.map((error: string) => error);
      } else {
        return '';
      }
    }
  };

  return (
    <TextInput
      name={name}
      label={label}
      required={required}
      fieldProps={{ validate, parse: identity }}
      inputProps={{
        'data-testid': name,
      }}
      {...rest}
    />
  );
};
