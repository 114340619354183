import { Typography } from '@mui/material';

import { usePrivateSettings } from '@/hooks/usePrivateSettings';
import { CustomerIdCheck } from '@/modules/services';
import { BasicCustomerData } from '@/types/customers';

import { StickerConfigModalType } from '../../types/cofigModal';

type Props = {
  type: StickerConfigModalType;
  onCustomerFound: (customer: BasicCustomerData) => void;
};

export const Step1 = ({ type, onCustomerFound }: Props) => {
  const { servicesNames } = usePrivateSettings();

  // TODO: Add translations
  const getSubtitleText = () => {
    switch (type) {
      case 'newCustomer':
        return `Till nykund ${servicesNames.protection}`;
      case 'upsale':
        return `Skickas i samband med välkomstbrev till uppsålda ${servicesNames.protection} kunder.`;
      case 'coProtection':
        return '';
      case 'partner':
        return 'Ej knuten till en annan tjänst. Vanligtvis inkomna via partnersamarbeten.';
      default:
        return `Till nykund ${servicesNames.protection}`;
    }
  };

  // TODO: Add translations
  return (
    <>
      <Typography gutterBottom variant="h3">
        Steg 1: Skanna välkomstbrev
      </Typography>

      <Typography variant="h5" gutterBottom>
        {getSubtitleText()}
      </Typography>

      <CustomerIdCheck onCustomerFound={onCustomerFound} isFirstStep />
    </>
  );
};
