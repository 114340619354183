import { Card, CardContent, Divider, List } from '@mui/material';

import { NumberedListItem } from '@/components/NumberedListItem';

import { ChecklistTitle, ListTitle } from '../../commonStyles';

type Props = {
  phoneNumbers: {
    customer: string;
  };
};

// Second tab for CoProtection Checklist
export const SecondCoTab = ({ phoneNumbers }: Props) => {
  return (
    <Card>
      <ChecklistTitle variant="h6">
        Kund misstänker att den blivit bolagskapad
      </ChecklistTitle>
      <Divider />
      <CardContent>
        <List>
          <ListTitle variant="h6">Meddela kund:</ListTitle>
          <NumberedListItem
            number={1}
            text={`Be kund kontakta kundtjänst på ${phoneNumbers.customer}`}
          />
          <ListTitle variant="h6">Att göra:</ListTitle>
          <NumberedListItem
            number={1}
            text={'Anteckna i Kundnoteringar om samtalet med kund.'}
          />
        </List>
      </CardContent>
    </Card>
  );
};
