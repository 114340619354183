import { useQuery } from '@tanstack/react-query';

import { getRmaCaseByCustomerId } from '@/api/rma';
import { queryKeys } from '@/constants/queryKeys';

export const useGetRmaCase = (customerId: string | number) => {
  return useQuery({
    queryFn: () => getRmaCaseByCustomerId(customerId),
    queryKey: queryKeys.rma.detailById(customerId),
  });
};
