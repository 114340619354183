import { useState } from 'react';

import { useAtom } from 'jotai';
import { RESET, atomWithStorage } from 'jotai/utils';
import pick from 'lodash/pick';

import { config } from '@/helpers/config';
import { useSendingsState } from '@/hooks/useSendingsState';

import { fetchAuthenticatedUserDetails, getResellerById } from '../api';
import { AuthenticatedUser, userRolesEnum } from '../types/auth';
import { parseJwt } from '../utils/authorization';

const authenticatedUserAtom = atomWithStorage<AuthenticatedUser | null>(
  'authenticatedUser',
  null,
);

export function useAuth() {
  const { resetSendingsState } = useSendingsState();
  const [user, setUser] = useAtom(authenticatedUserAtom);
  const [loginError, setLoginError] = useState<string | null>(null);

  const loginErrorMessage = 'Ogiltiga uppgifter';

  async function onLoginSuccess(token: string) {
    try {
      const { id, roles } = parseJwt(token);

      const isReseller = roles.includes(userRolesEnum.Values.ROLE_RESELLER);

      let response;
      if (isReseller) {
        response = await getResellerById(id);
      } else {
        response = await fetchAuthenticatedUserDetails(id);
      }

      const authenticatedUser = pick(response, [
        'id',
        'email',
        'level',
        'name',
        'roles',
        'username',
        'pin',
      ]);

      setLoginError(null);
      //@ts-expect-error Type undefined is not assignable to type number
      setUser(authenticatedUser);
    } catch (error) {
      setLoginError(loginErrorMessage);
    }
  }

  async function logOut() {
    resetSendingsState();

    console.log('LOGGING OUT');

    localStorage.removeItem('jwt-token');
    localStorage.removeItem('privateSettings');
    localStorage.removeItem('publicSettings');
    localStorage.removeItem('persist:root');

    setUser(RESET);

    window.location.replace(config.siteUri);
  }

  function isLoggedIn() {
    const token = localStorage.getItem('jwt-token');
    return !!token;
  }

  function getAuthenticatedUserRoles() {
    if (user && 'roles' in user) {
      return user.roles;
    } else {
      return [];
    }
  }

  return {
    onLoginSuccess,
    logOut,
    isLoggedIn,
    loginError,
    authenticatedUser: user,
    getAuthenticatedUserRoles,
  };
}
