import { NumberedListItem } from '@/components/NumberedListItem';

import { ListTitle } from '../../../commonStyles';

export function MessageCustomer() {
  return (
    <>
      <ListTitle variant="h6">Meddela kund:</ListTitle>

      <NumberedListItem
        number={1}
        text={`Be om ett telefonnummer samt kontrollera e-post så vi kan nå kund på utifall det behövs under processen samt. Om e-post ej finns, be om en.`}
      />
      <NumberedListItem
        number={2}
        text={`Meddela att kund behöver göra en Polisanmälan. Detta får inte vi göra.`}
      />
      <NumberedListItem
        number={3}
        text={
          <>
            {
              'Meddela att kund behöver posta sin Polisanmälan till Dun & Bradstreet inom 14 dagar tillsammans med ett personligt brev där en viss text måste finnas med ”Jag vill inte att ni lämnar ut kreditupplysningar om mig” samt kundens underskrift. Kund kan även mejla in kopian på polisanmälan till kredit.se@bisnode.com.\n\nVi skickar detta dokument till kund. Gör inte kunden detta hävs spärren per automatik efter 14 dagar. Inkommer kunden med Polisanmälan ligger spärren upp till 5 år. Kunden kan när som helst häva spärren. Då ringer kunden till spärrlinjen igen så hjälper vi till genom att skicka hem en blankett till folkbokföringsadressen.[Separat rutin]\n\n'
            }
            <span style={{ color: 'red' }}>
              {
                'Be om nedan uppgifter och logga uppgifterna i kundnoteringen för att de ska kunna återkoppla till användaren.\n\na) Personnumret på personen vi spärrat\n\nb) Namn på personen\n\nc) Postadress till personen\n\nd) Mejladress och telefonnummer'
              }
            </span>
          </>
        }
      />
      <NumberedListItem
        number={4}
        text={`Meddela att vi kommer skicka en folder gällande hur processen kommer att gå till tillsammans med en fullmakt och förlängd bedrägerispärr. Förklara att kund även kommer att bli kontaktad utav en handläggare som kommer att kartlägga ärendet.`}
      />
      <NumberedListItem
        number={5}
        text={
          <>
            {`Har kund förlorat `}
            <b>Körkort:</b>
            {`
Meddela kund att vi kommer maila en länk till kunden där kund via 3 enkla steg spärrar sitt körkort och därmed får hem en blankett om förnyelse av körkort.

Har kund förlorat `}
            <b>ID-Kort:</b>
            {`
Meddela kund att kund behöver ringa Skatteverkat direkt och spärra sitt ID-kort.
Om kund har ett `}
            <b>grönt ID-kort</b>
            {` (utfärdades före september 2017), ring `}
            <b>010-331 1010</b>
            {`
Om kund har ett `}
            <b>gult eller blått ID-kort</b>
            {` (utfärdades efter september 2017), ring `}
            <b>020-32 32 62</b>
            <br />
            <br />
            <b>Har kund forlotart pass:</b>
            {`
Meddela kund att när kunden gör sin polisanmälan på passet så blir det per automatik förlustanmält. Spärren lämnas vidare till Schengen Information System, SIS, och Interpol. Gränskontrollmyndigheter runt om i världen kontrollerar resehandlingar mot dessa register vid inresa i länder och därför kan ett upphittat pass eller nationellt id-kort aldrig användas efter att det har spärrats.`}
            <br />
            <br />
            <b>Har kund förlorat nationellt ID-Kort (utfärdat hos Polisen):</b>
            {`Be kund ringa 11414 och spärra hos polisen (kund måste ringa själv då polisen ställer kontrollfrågor ) alt använd deras e-tjänst https://etjanster.polisen.se/eanmalan/forlust`}
          </>
        }
      />
      <NumberedListItem
        number={6}
        text={`Berätta även att om kund får tillbaka passet, körkort eller id-kort är det viktigt att kund vänder sig till polisen så att polisen kan makulera detta. Har kund fått ett nytt pass eller nationellt id-kort är kund skyldig att lämna det gamla för makulering. Den gamla resehandlingen ska under inga omständigheter användas.`}
      />
      <NumberedListItem
        number={7}
        text={`Meddelat att vi kommer göra en Bedrägerispärr hos kreditupplysningsföretaget Dun & Bradstreet (tidigare Bisnode), denna blir aktiv direkt och inom 60 min blir spärren även aktiv hos kreditupplysningsföretagen , Decidas, Syna och Creditsafe) Spärren ligger upp till 14 dagar.`}
      />
      <NumberedListItem
        number={8}
        text={`Be även kund kontakta det bolag där kund blivit utsatt för bedrägeriet (Ex Om någon har ansökt om lån hos X så måste bolaget få veta att det är ett bedrägeri direkt för att kunna stoppa en eventuell utbetalning)`}
      />
      <NumberedListItem
        number={9}
        text={`Meddela att kund bör kolla sina bankkonton, krediter och kort så att inga oförutsedda händelser har skett. I så fall spärra sina kort.`}
      />
      <NumberedListItem
        number={10}
        text={`Meddela att kund bör ringa tel. 0771-567 567 och kolla sin folkbokföringsadress. Någon obehörig kan ha ändrat den utan din vetskap.`}
      />
      <NumberedListItem
        number={11}
        text={`Meddela att kund bör ringa tel. 0771-97 98 99 och kolla att ingen eftersänt kunds post på felaktiga grunder.`}
      />
    </>
  );
}
