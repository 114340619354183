import { useQuery } from '@tanstack/react-query';

import { getInvoiceListSummary } from '@/api/invoice';
import { queryKeys } from '@/constants/queryKeys';
import { BillingStats } from '@/types/billing';

export function useGetInvoiceListSummary() {
  const { data, isLoading, isError } = useQuery({
    queryKey: [queryKeys.getInvoiceListSummary],
    queryFn: async () => await getInvoiceListSummary<BillingStats>(),
  });

  return {
    data,
    isLoading,
    isError,
  };
}
