import React from 'react';

import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from '@mui/material';

import { FileUploadForm } from '@/modules/services';
import { OrderFileModalType } from '@/types/orderFile';

type Props = {
  isOpen: boolean;
  type: OrderFileModalType;
  onClose: () => void;
};

export const OrderFileUploadModal = ({ isOpen, type, onClose }: Props) => {
  return (
    <Dialog onClose={onClose} open={isOpen} fullWidth maxWidth="sm">
      <DialogTitle>Orderfil uppladdning</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>
          Automatisk orderregistrering fungerar endast för nya kunder eller för
          kunder utan en aktiv tjänst. Skicka uppsälj eller specialkampanjer
          till Success-teamet enligt vanliga rutiner. Du kommer att få ett
          e-postmeddelande när dina ordrar har registrerats.
        </DialogContentText>
      </DialogContent>
      <Divider />
      <FileUploadForm serviceType={type} onClose={onClose} />
    </Dialog>
  );
};
