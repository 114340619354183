export const columns: Array<{
  label: string;
  renderer: (row: {
    article: string;
    name: string;
    quantity: number;
    price: number;
    sum: number;
  }) => React.ReactNode;
}> = [
  {
    label: 'Artikelnr',
    renderer: (row) => row.article,
  },
  {
    label: 'Typ',
    renderer: (row) => row.name,
  },
  {
    label: 'Antal',
    renderer: (row) => row.quantity,
  },
  {
    label: 'Pris',
    renderer: (row) => row.price?.toFixed(2),
  },
  {
    label: 'Summa',
    renderer: (row) => row.sum?.toFixed(2),
  },
];
