import { useQuery } from '@tanstack/react-query';
import { useAtom } from 'jotai/index';
import invariant from 'tiny-invariant';

import { getPrivateSettings } from '@/api';
import { queryKeys } from '@/constants/queryKeys';
import { getObjectKeys } from '@/helpers/tsHelpers';
import { useAuth } from '@/hooks/useAuth';
import { getCamelServiceName } from '@/modules/common/utils/services';
import { TaskTeamTypes } from '@/modules/tasks/constants/tasksModalsConstants';
import { privateSettingsAtom } from '@/state/privateSettingsAtom';
import { CamelCaseServiceName, ServiceName } from '@/types/services';
import {
  CoProtectionStatusEnum,
  FiretextStatusEnum,
  HomegateStatusEnum,
  KeyTagStatusEnum,
  PricePlans,
  PrivateSettingsServiceTypes,
  ProtectionStatusEnum,
  ServiceStatuses,
  SinneStatusEnum,
  StickerStatusEnum,
} from '@/types/settings';

export function usePrivateSettings() {
  const [privateSettings, setPrivateSettings] = useAtom(privateSettingsAtom);

  const auth = useAuth();

  const { data, isSuccess, isLoading, isError, error } = useQuery({
    queryKey: [queryKeys.getPrivateSettings],
    queryFn: getPrivateSettings,
    enabled: auth.isLoggedIn(),
    staleTime: Infinity,
  });

  if (data) {
    setPrivateSettings(data);
  }

  const operators = privateSettings?.operators;
  const operatorsOptions = operators
    ? Object.entries(operators).map(([key, value]) => ({
        value: key,
        text: value,
      }))
    : [];

  const getServicePricePlans = (
    serviceName: CamelCaseServiceName,
  ): Record<number, number> => {
    try {
      invariant(!!privateSettings?.pricePlans, "Can't get price plans");
      return privateSettings?.pricePlans?.[serviceName as keyof PricePlans];
    } catch {
      return {};
    }
  };

  const coProtectionMonitoringPlans =
    privateSettings?.coProtectionMonitoringPlans || {};

  const coProtectionMonitoringOptions = getObjectKeys(
    coProtectionMonitoringPlans,
  ).map((key) => {
    const plan = coProtectionMonitoringPlans[key];
    return {
      value: plan?.planId || 1,
      text: `${plan?.maximumNumberOfMonitoredCompanies}`,
    };
  });

  const mapEntriesToDropdownOptions = (privateSettingsTypes: {
    [key in number]: string;
  }) => {
    return Object.entries(privateSettingsTypes)
      .map(([key, value]) => ({
        value: key,
        text: value,
      }))
      .sort((a, b) => a.text.localeCompare(b.text));
  };

  const getTypesOptionsForDropdownInput = (
    service: keyof PrivateSettingsServiceTypes,
    options?: {
      taskTeam?: (typeof TaskTeamTypes)[keyof typeof TaskTeamTypes];
    },
  ) => {
    if (!privateSettings || !privateSettings.types) {
      return [];
    }

    if (service === 'task') {
      if (!options) {
        console.error(
          'For "task" service you need to provide options with taskTeam value',
        );
      }

      if (
        !options ||
        options?.taskTeam === undefined ||
        isNaN(options.taskTeam)
      ) {
        return [];
      }

      return mapEntriesToDropdownOptions(
        privateSettings.types[service][options.taskTeam],
      );
    } else {
      return mapEntriesToDropdownOptions(privateSettings.types[service]);
    }
  };

  const generateStatusOptions = (
    service: keyof ServiceStatuses,
    addAllOption?: boolean,
    allOptionConfig = { value: 0, text: 'Alla' },
  ) => {
    const returnValue: Array<{
      text: string;
      value: string | number;
    }> = [];

    if (!privateSettings?.statuses) {
      return returnValue;
    }

    const servicesStatusesKeys = getObjectKeys(
      privateSettings.statuses[service],
    );

    servicesStatusesKeys.forEach((statusKey) => {
      returnValue.push({
        value: statusKey,
        text: privateSettings.statuses[service][statusKey],
      });
    });

    if (addAllOption) {
      returnValue.unshift({
        value: allOptionConfig.value,
        text: allOptionConfig.text,
      });
    }
    return returnValue;
  };

  const getServiceStatusString = (
    service: ServiceName,
    status:
      | FiretextStatusEnum
      | KeyTagStatusEnum
      | ProtectionStatusEnum
      | CoProtectionStatusEnum
      | HomegateStatusEnum
      | SinneStatusEnum
      | StickerStatusEnum,
  ) => {
    const serviceInCamelCase = getCamelServiceName(service);

    switch (serviceInCamelCase) {
      case 'firetext':
        return privateSettings?.statuses[serviceInCamelCase][
          status as FiretextStatusEnum
        ];
      case 'keyTag':
        return privateSettings?.statuses[serviceInCamelCase][
          status as KeyTagStatusEnum
        ];
      case 'protection':
        return privateSettings?.statuses[serviceInCamelCase][
          status as ProtectionStatusEnum
        ];
      case 'coProtection':
        return privateSettings?.statuses[serviceInCamelCase][
          status as CoProtectionStatusEnum
        ];
      case 'homegate':
        return privateSettings?.statuses[serviceInCamelCase][
          status as HomegateStatusEnum
        ];
      case 'sinne':
        return privateSettings?.statuses[serviceInCamelCase][
          status as SinneStatusEnum
        ];
      case 'sticker':
        return privateSettings?.statuses[serviceInCamelCase][
          status as StickerStatusEnum
        ];
      default:
        return '';
    }
  };

  return {
    privateSettings,
    // Assume that private settings will be present when actual values will be used in components
    generateStatusOptions,
    getServicePricePlans,
    getServiceStatusString,
    getTypesOptionsForDropdownInput,
    invoiceProvider: privateSettings?.invoiceProvider!,
    coProtectionMonitoringOptions,
    operatorsOptions,
    services: privateSettings?.products!,
    servicesNames: privateSettings?.names!,
    servicesPricePlans: privateSettings?.pricePlans!,
    servicesStatuses: privateSettings?.statuses!,
    servicesTypes: privateSettings?.types!,
    sinne: privateSettings?.sinne!,
    sinneEvent: privateSettings?.statuses!.sinneEvent?.events,
    stickerDoubleFamily: privateSettings?.stickerDoubleFamily!,
    webAddresses: privateSettings?.webAddresses!,
    pricePlans: privateSettings?.pricePlans!,
    companyCurrency: privateSettings?.companyCurrency!,
    churnReasons: privateSettings?.churnReasons!,
    phoneNumbers: privateSettings?.phoneNumbers!,
    serviceHours: privateSettings?.customerServiceHours!,
    emailAddresses: privateSettings?.emailAddresses!,
    isSuccess,
    isLoading,
    isError,
    error,
  };
}
