import React from 'react';

import { Card, CardHeader, LinearProgress } from '@mui/material';

import { BasicAlert } from '@/components/BasicAlert';

type Props = {
  status: 'pending' | 'success' | 'error';
  successView: React.ReactNode;
  loadingView?: React.ReactNode;
  errorView?: React.ReactNode;
  cardTitle?: string;
};

const DefaultErrorView = <BasicAlert message="Något gick fel" />;
const DefaultLoadingView = <LinearProgress />;

export const CardWrapper = ({
  status,
  successView,
  loadingView = DefaultLoadingView,
  errorView = DefaultErrorView,
  cardTitle,
}: Props) => {
  const currentView = {
    pending: <>{loadingView}</>,
    success: <>{successView}</>,
    error: <>{errorView}</>,
  };

  const title = status !== 'success' && cardTitle && (
    <CardHeader title={cardTitle} />
  );

  return (
    <Card>
      {title}
      {currentView[status]}
    </Card>
  );
};
