import { useContext } from 'react';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { CircularProgress, Typography } from '@mui/material';
import { useSelector } from '@xstate/react';

import { FlatSinneDeviceInfo } from '@/types/services';

import { MAX_RETRIES, MINUTES_TO_WAIT } from '../constants/constants';
import { SinneReturnStateContext } from '../context';
import { RetryButton } from './RetryButton';
import { StepRow } from './StepRow';

export const DeviceCheck = () => {
  const service = useContext(SinneReturnStateContext);

  const state = useSelector(service, (state) => state);
  const {
    device: { retries, connected, sinneEntityId },
    macAddress,
  } = state.context;

  return (
    <>
      {state.matches('STATUS_CHECKS.DEVICE.loading') && (
        <StepRow>
          <CircularProgress size={14} />
          {retries === 0 && (
            <Typography component="span" variant="h6">
              Kontrollerar status på enhet...
            </Typography>
          )}
          {!connected && retries > 0 && (
            <>
              <Typography
                variant="h6"
                color={(theme) => theme.palette.warning.light}
              >
                Enheten har inte kopplat upp, kontrollerar igen
              </Typography>
              <Typography variant="h6">
                Antal försök: {retries} av {MAX_RETRIES} ({MINUTES_TO_WAIT} min)
              </Typography>
            </>
          )}
        </StepRow>
      )}
      {connected && (
        <StepRow>
          <CheckCircleOutlineIcon color="success" sx={{ fontSize: 20 }} />
          <Typography component="span" variant="h6" padding={0} margin={0}>
            Enheten är ansluten
          </Typography>
        </StepRow>
      )}
      {connected && !sinneEntityId && (
        <StepRow>
          <ErrorOutlineIcon color="error" sx={{ fontSize: 20 }} />
          <Typography component="span" variant="h6" padding={0} margin={0}>
            Enheten är ansluten men har ingen tillhörande tjänst
          </Typography>
        </StepRow>
      )}
      {state.matches('STATUS_CHECKS.DEVICE.error.deviceDisconnected') && (
        <StepRow>
          <ErrorOutlineIcon color="error" sx={{ fontSize: 20 }} />
          <Typography component="span" variant="h6" padding={0} margin={0}>
            Enheten är inte ansluten
          </Typography>
          <RetryButton
            onClick={() =>
              service.send({ type: 'RETRY_DEVICE_CHECK', macAddress })
            }
          />
        </StepRow>
      )}
      {state.matches('STATUS_CHECKS.DEVICE.error.serverError') && (
        <StepRow>
          <ErrorOutlineIcon color="error" sx={{ fontSize: 20 }} />
          <Typography component="span" variant="h6" padding={0} margin={0}>
            Kontrollera enhet: Server error
          </Typography>
          <RetryButton
            onClick={() =>
              service.send({ type: 'RETRY_DEVICE_CHECK', macAddress })
            }
          />
        </StepRow>
      )}
    </>
  );
};
