import React from 'react';

import { Box, Button, CardActions, CardContent, Divider } from '@mui/material';
import { styled } from '@mui/system';
import { useSelector } from '@xstate/react';

import { SinneConfigContext } from '@/modules/sinne/components/sinneConfigFlow/context/SinneConfigContextProvider';

const InstructionImg = styled('img')`
  object-fit: cover;
  width: 100%;
  height: auto;
`;

export const InstructionStep = () => {
  const machine = React.useContext(SinneConfigContext);
  const state = useSelector(machine, (state) => state);
  const timerDone = useSelector(
    machine,
    (state) => state.context.instructionTimerDone,
  );
  const fetchServiceInfoError = state.matches('INSTRUCTION.error');

  return (
    <>
      <CardContent>
        <Box display="flex" height="400px" sx={{ overflowY: 'auto' }}>
          <InstructionImg src={'/images/sinne/battery-step-1.png'} />

          <InstructionImg src={'/images/sinne/battery-step-2.png'} />
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
        <Button
          disabled={!timerDone}
          onClick={() => machine.send('INSTRUCTION_VIEWED')}
        >
          Nästa
        </Button>
      </CardActions>
    </>
  );
};
