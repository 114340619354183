import { captureException } from '@sentry/react';
import { format, isValid, parseISO } from 'date-fns';

import { getObjectKeys } from '@/helpers/tsHelpers';

const defaultFormat = 'yyyy-MM-dd';
const dateFields = [
  'churnDate',
  'deadline',
  'deviceManufactureDate',
  'invoiceAt',
  'partnerDate',
  'regDate',
];

export const getFormattedDate = (
  date: unknown,
  outputFormat: string = 'yyyy-MM-dd',
): string | null => {
  if (!date) {
    return null;
  }

  let parsedDate: Date;

  if (typeof date === 'string') {
    parsedDate = parseISO(date);
  } else if (date instanceof Date) {
    parsedDate = date;
  } else {
    return null;
  }

  if (!isValid(parsedDate)) {
    return null;
  }

  try {
    return format(parsedDate, outputFormat);
  } catch (e) {
    console.error(`Error formatting date: ${e}`);
    captureException(`Error formatting date: ${e}`);
    return null;
  }
};

export const mapDateFieldsValues = <T extends object>(
  payload: T,
  dateFormat?: string,
) => {
  const keys = getObjectKeys(payload);

  return keys.reduce((acc, key) => {
    const value = payload[key];

    if (dateFields.includes(key.toString())) {
      if (typeof value === 'string') {
        const date = new Date(value);

        return {
          ...acc,
          [key]: format(date, dateFormat || defaultFormat),
        };
      }

      if (isValid(value)) {
        // We can assume type of value because it's already checked by isValid
        return {
          ...acc,
          [key]: format(value as number | Date, dateFormat || defaultFormat),
        };
      }

      return {
        ...acc,
        [key]: value,
      };
    }

    return {
      ...acc,
      [key]: value,
    };
  }, {} as T);
};
