import { Alert, Card, CardHeader, Divider } from '@mui/material';

import { SimpleTable, SimpleTableColumn } from '@/components/SimpleTable';
import { usePrivateSettings } from '@/hooks';
import { formatCurrency } from '@/modules/common/utils/currency';
import {
  RevenueStatisticEntry,
  RevenueStatistics,
} from '@/types/partnerCompany';

type Props = {
  stats?: RevenueStatistics;
};

export const RevenueCardTable = ({ stats }: Props) => {
  const { servicesNames } = usePrivateSettings();

  const revenueColumns: SimpleTableColumn<RevenueStatisticEntry>[] = [
    {
      label: 'Tjänst',
      renderer: (row) => servicesNames[row.service],
      align: 'left',
    },
    {
      label: 'Antal',
      renderer: (row) => row.count,
      align: 'center',
    },
    {
      label: 'MRR',
      renderer: (row) => formatCurrency(row.price),
      align: 'center',
    },
    {
      label: 'Split till partner %',
      renderer: (row) => row.splitPercentage,
      align: 'center',
    },
    {
      label: 'Fakturerbart',
      renderer: (row) => formatCurrency(row.billable),
      align: 'right',
    },
  ];

  const emptyColumns = Array.from({ length: 3 }, () => ({
    label: '',
    renderer: () => '',
  }));
  const totalColumns: SimpleTableColumn<RevenueStatistics['total']>[] = [
    {
      label: 'Totalt',
      renderer: () => '',
    },
    ...emptyColumns,
    {
      label: formatCurrency(stats?.total || 0),
      renderer: () => '',
      align: 'right',
    },
  ];
  return (
    <Card>
      <CardHeader title="Revenue split" />
      <Divider />
      {stats?.data?.length === 0 ? (
        <Alert severity="info">Ingen data för revenue split</Alert>
      ) : (
        <>
          <SimpleTable
            columns={revenueColumns}
            rows={stats?.data || []}
            cardProps={{
              elevation: 0,
              sx: { borderRadius: 0 },
            }}
          />
          <SimpleTable
            columns={totalColumns}
            rows={[]}
            cardProps={{
              elevation: 0,
            }}
          />
        </>
      )}
    </Card>
  );
};
