import {
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { SimpleModal } from '../SimpleModal';

const FIELD_NAMES_MAP = {
  firstName: 'Förnamn',
  lastName: 'Efternamn',
  companyName: 'Företagsnamn',
} as const;

type Props = {
  opened: boolean;
  errors: Array<{
    fieldName: keyof typeof FIELD_NAMES_MAP;
    wrongChars: string[];
  }>;
  onClose: () => void;
};

export const SveaFieldsErrorsModal = ({ opened, errors, onClose }: Props) => {
  return (
    <SimpleModal
      open={opened}
      onClose={onClose}
      title="Felaktig inmatning"
      content={
        <>
          <List>
            {errors.map((fieldError) => {
              return (
                <ListItem key={fieldError.fieldName} disableGutters>
                  <ListItemText
                    primary={
                      <>
                        <strong>{FIELD_NAMES_MAP[fieldError.fieldName]}</strong>{' '}
                        {fieldError.wrongChars.join(', ')}
                      </>
                    }
                  />
                </ListItem>
              );
            })}
          </List>

          <Divider />

          <ITInformationText>
            Det gick inte spara informationen på grund av felaktiga tecken
          </ITInformationText>
        </>
      }
    />
  );
};

const ITInformationText = styled(Typography)`
  padding-top: ${({ theme }) => theme.spacing(2)};
`;
