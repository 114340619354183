import React from 'react';

import {
  AlertTitle,
  Box,
  Button,
  Card,
  CardHeader,
  useTheme,
} from '@mui/material';
import { Link } from 'react-router-dom';

import { Alert } from '@/components/Alert';
import { Breadcrumbs } from '@/components/Breadcrumbs';
import { PageHeader } from '@/components/PageHeader';
import { useAuth } from '@/hooks/useAuth';
import { GrandIdVerifyResponse } from '@/types/grandId';
import httpClient from '@/utils/httpClient';

import { useDownloadExportFile } from '../../hooks/useDownloadExportFile';
import { ExportTabs } from './components/ExportTabs';

type Action =
  | { type: 'SET_VERIFIED_USER'; payload: GrandIdVerifyResponse | null }
  | { type: 'SET_ALERT_OPEN'; payload: boolean }
  | { type: 'ON_NO_DATA' }
  | { type: 'ON_SESSION_CONSUMED' }
  | { type: 'ON_SUCCESS' }
  | { type: 'ON_ERROR' };

type State = {
  verifiedUser: GrandIdVerifyResponse | null;
  noData: boolean;
  sessionConsumed: boolean;
  error: boolean;
  alertOpen: boolean;
};

const initialState = {
  verifiedUser: null,
  noData: false,
  sessionConsumed: false,
  error: false,
  alertOpen: false,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_VERIFIED_USER':
      return { ...state, verifiedUser: action.payload };
    case 'SET_ALERT_OPEN':
      return { ...state, alertOpen: action.payload };
    case 'ON_NO_DATA':
      return { ...state, noData: true, alertOpen: true, verifiedUser: null };
    case 'ON_SESSION_CONSUMED':
      return {
        ...state,
        sessionConsumed: true,
        alertOpen: true,
        verifiedUser: null,
      };
    case 'ON_SUCCESS':
      return { ...state, noData: false, alertOpen: true, verifiedUser: null };
    case 'ON_ERROR':
      return {
        ...state,
        sessionConsumed: true,
        alertOpen: true,
        verifiedUser: null,
        error: true,
      };
    default:
      throw new Error(`Unhandled action type`);
  }
};

export const ExportWrapper = () => {
  const theme = useTheme();
  const [
    { noData, sessionConsumed, error, alertOpen, verifiedUser },
    dispatch,
  ] = React.useReducer(reducer, initialState);
  const [grandIdInitiateResponse, setGrandIdInitiateResponse] = React.useState<{
    redirectUrl: string;
    sessionId: string;
  }>();
  const [grandIdData, setGrandIdData] = React.useState<GrandIdVerifyResponse>();
  const [resetCounter, setResetCounter] = React.useState(0);

  const { authenticatedUser: user } = useAuth();

  const userHasEmail = Boolean(user?.email);
  const pin = user?.pin;
  const name = user?.name || 'Namn saknas';

  const exportAllowed = !verifiedUser && pin && userHasEmail;
  const queryParams = new URLSearchParams(location.search);
  const sessionId = queryParams.get('grandidsession');

  function resetVerification() {
    setResetCounter((prev) => prev + 1);
  }

  React.useEffect(() => {
    if (sessionId) return;

    const initiateGrandId = async () => {
      return httpClient.get('/grandid/initiate');
    };

    initiateGrandId().then((res) => {
      if (!res.data.sessionId) return;

      localStorage.setItem('grandIdSession', res.data.sessionId);
      setGrandIdInitiateResponse(res.data);
    });
  }, [resetCounter]);

  React.useEffect(() => {
    if (!sessionId) return;
    if (localStorage.getItem('grandIdSession') !== sessionId) return;

    const getVerifiedUser = async () => {
      return httpClient.get<GrandIdVerifyResponse>(
        `/grandid/verify?grandIdSession=${sessionId}`,
      );
    };

    getVerifiedUser().then((res) => setGrandIdData(res.data));
  }, [sessionId]);

  React.useEffect(() => {
    if (!grandIdData?.userAttributes) return;

    dispatch({ type: 'SET_VERIFIED_USER', payload: grandIdData });
    dispatch({ type: 'SET_ALERT_OPEN', payload: false });
    localStorage.removeItem('grandIdSession');
  }, [grandIdData]);

  const resetBankIdVerification = () => {
    const url = new URL(window.location.href);
    url.search = '';
    window.history.pushState({}, '', url);
    resetVerification();
  };

  const onNoData = () => dispatch({ type: 'ON_NO_DATA' });
  const onSessionConsumed = () => dispatch({ type: 'ON_SESSION_CONSUMED' });
  const onSuccess = () => dispatch({ type: 'ON_SUCCESS' });
  const onError = () => dispatch({ type: 'ON_ERROR' });

  const mutation = useDownloadExportFile(
    onSessionConsumed,
    onSuccess,
    onError,
    onNoData,
  );

  return (
    <>
      <Box
        maxWidth={theme.breakpoints.values.lg}
        height={`calc(100vh - 94px)`}
        minHeight="800px"
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Breadcrumbs crumbs={[{ label: 'Verktyg' }, { label: 'Export' }]} />
        <PageHeader title="Export" margin />
        {alertOpen && (
          <Alert
            sx={{ mb: 2 }}
            severity={error ? 'error' : 'info'}
            onClose={() => {
              dispatch({ type: 'SET_ALERT_OPEN', payload: false });
            }}
          >
            <AlertTitle>
              {noData && `Ingen data för valda filter. `}
              {error && `Något gick fel.`}
              {sessionConsumed && `Verifieringen har gått ut. `}
              Du måste verifiera dig med bank-ID igen för att göra en ny export.
            </AlertTitle>
          </Alert>
        )}
        {!pin && (
          <Alert severity="info">
            <AlertTitle>
              Din användare saknar personnummer. För att kunna göra en export
              måste du läggat till det i dina{' '}
              <Link to="/settings/me">inställningar</Link>.
            </AlertTitle>
            <Link to="/settings/me">Gå till dina inställningar</Link> och
            uppdatera informationen. Logga sedan ut och in igen.
          </Alert>
        )}
        {!userHasEmail && (
          <Alert severity="info" sx={{ mt: 2 }}>
            <AlertTitle>
              Din användare saknar e-postadress. För att kunna göra en export
              måste du lägga till det i dina{' '}
              <Link to="/settings/me">inställningar</Link>.
            </AlertTitle>
            <Link to="/settings/me">Gå till dina inställningar</Link> och
            uppdatera informationen. Logga sedan ut och in igen.
          </Alert>
        )}
        {exportAllowed && (
          <Box
            component={Card}
            sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}
          >
            <CardHeader
              title={`${name}, du måste verifiera dig med bankID innan du kan fortsätta med exporten.`}
              action={
                <>
                  <Button
                    variant="outlined"
                    value={pin}
                    disabled={!pin}
                    onClick={() => navigator.clipboard.writeText(pin)}
                    style={{ marginRight: '1rem' }}
                  >
                    Kopiera ditt personnummer
                  </Button>
                  <Button variant="outlined" onClick={resetBankIdVerification}>
                    Återställ BankID-verifiering
                  </Button>
                </>
              }
            />
            {grandIdInitiateResponse && (
              <Button
                component={Link}
                to={grandIdInitiateResponse.redirectUrl}
                variant="contained"
                color="primary"
                sx={{
                  textTransform: 'none',
                  width: 'auto',
                  padding: '0.5rem 1rem',
                }}
              >
                Till BankId
              </Button>
            )}
          </Box>
        )}

        {verifiedUser && verifiedUser?.username !== pin && (
          <Alert severity="error">Bank-ID verifiering misslyckades</Alert>
        )}

        {verifiedUser && verifiedUser?.username === pin && (
          <ExportTabs sessionId={verifiedUser.sessionId} mutation={mutation} />
        )}
      </Box>
    </>
  );
};
