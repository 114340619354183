import { Paper, styled } from '@mui/material';

export const DocumentDownloadCard = styled(Paper)(({ theme }) => ({
  variant: 'outlined',
  padding: theme.spacing(4),
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
}));
