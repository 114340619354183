import React from 'react';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  LinearProgress,
  List,
  ListItem,
  Typography,
} from '@mui/material';

import { BasicAlert } from '@/components/BasicAlert';
import { useBulkRegistrationState } from '@/hooks/useBulkRegistrationState';
import { useGetFileImportValidationProcess } from '@/hooks/useGetFileImportValidationProcess';
import { useIndexDBFile } from '@/hooks/useIndexDbFile';
import { KeyTagBulkRegistrationFileValidationForm } from '@/modules/services/components/KeyTag/components/KeyTagBulkRegistrationFileValidationForm';
import { KeyTagBulkRegistrationForm } from '@/modules/services/components/KeyTag/components/KeyTagBulkRegistrationForm';
import { KeyTagBulkServiceRegistrationPending } from '@/modules/services/components/KeyTag/components/KeyTagBulkServiceRegistrationPending';
import { KeyTagBulkServiceRegistrationResults } from '@/modules/services/components/KeyTag/components/KeyTagBulkServiceRegistrationResults';
import { clearStoredFile } from '@/utils/indexDb';

import { getKeyTagBulkRegistrationCardHeader } from '../helpers/getKeyTagBulkRegistrationCardHeader';

export const KeyTagBulkRegistration = () => {
  const {
    bulkRegistrationState,
    setBulkRegistrationState,
    resetBulkRegistrationProcess,
  } = useBulkRegistrationState();

  const {
    serviceRegistrationStatus,
    fileValidationStatus,
    bulkRegistrationStep,
    fileValidationUuid,
  } = bulkRegistrationState;

  const file = useIndexDBFile(fileValidationUuid);

  const { data } = useGetFileImportValidationProcess(fileValidationUuid);
  const { title, subtitle } =
    getKeyTagBulkRegistrationCardHeader(bulkRegistrationStep);

  const handleReset = async () => {
    resetBulkRegistrationProcess();
    await clearStoredFile(fileValidationUuid);
  };

  const showFileValidationForm = bulkRegistrationStep === 'fileValidationForm';
  const showFileValidationLoading =
    bulkRegistrationStep === 'fileValidationPending';
  const showFileValidationError =
    bulkRegistrationStep === 'fileValidationError';
  const showBulkRegistrationForm =
    file &&
    fileValidationStatus === 'complete' &&
    bulkRegistrationStep === 'serviceRegistrationForm';
  const showBulkRegistrationProcess =
    bulkRegistrationStep === 'serviceRegistrationPending';
  const showBulkRegistrationResults =
    bulkRegistrationStep === 'serviceRegistrationComplete';
  const showBulkRegistrationError =
    bulkRegistrationStep === 'serviceRegistrationError';

  React.useEffect(() => {
    if (
      data?.uuid === fileValidationUuid &&
      data?.status === 'Finished' &&
      !showBulkRegistrationResults
    ) {
      setBulkRegistrationState({
        ...bulkRegistrationState,
        fileValidationStatus: 'complete',
        bulkRegistrationStep: 'serviceRegistrationForm',
      });
    }
  }, [data, fileValidationUuid, showBulkRegistrationResults]);
  return (
    <>
      <Card>
        <CardHeader
          title={title}
          subheader={subtitle}
          action={
            <Button
              onClick={() => handleReset()}
              disabled={serviceRegistrationStatus === 'idle'}
            >
              {showBulkRegistrationResults ? 'Klar' : 'Avbryt'}
            </Button>
          }
        />
        <Divider />
        <CardContent>
          {showFileValidationForm && (
            <KeyTagBulkRegistrationFileValidationForm />
          )}
          {showFileValidationLoading && (
            <Box>
              <LinearProgress />
              <Typography variant="h5">Filen analyseras</Typography>
            </Box>
          )}

          {showBulkRegistrationForm && (
            <KeyTagBulkRegistrationForm data={data} file={file} />
          )}

          {showBulkRegistrationProcess && (
            <KeyTagBulkServiceRegistrationPending />
          )}
          {showBulkRegistrationResults && (
            <KeyTagBulkServiceRegistrationResults />
          )}

          {showFileValidationError && <BasicAlert />}
          {showBulkRegistrationError && (
            <>
              <BasicAlert />
              {Boolean(data?.errors?.length) && (
                <List component={Card} dense>
                  <CardContent>
                    {data?.errors?.map((error) => (
                      <ListItem key={error}>{error}</ListItem>
                    ))}
                  </CardContent>
                </List>
              )}
            </>
          )}
        </CardContent>
      </Card>
    </>
  );
};
