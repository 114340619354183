import React from 'react';

import { Grid } from '@mui/material';

import { ExistingCustomer } from '@/types/customers';

import { resizeAllCards } from '../../utils/resizeCards';
import { CustomerCard } from '../CustomerCard';
import { Tasks } from '../Tasks';
import { ActiveServicesSummaryList } from './ActiveServicesSummaryList';
import { Agreements } from './Agreements';
import { AutogiroCard } from './AutogiroCard';
import { PartnerCooperationCard } from './PartnerCooperationCard';

type Props = {
  customer: ExistingCustomer;
  handleCreateTaskModalVisibility: () => void;
};

export const CustomerOverview = ({
  customer,
  handleCreateTaskModalVisibility,
}: Props) => {
  React.useEffect(() => {
    resizeAllCards();
  });

  return (
    <Grid id="wrapper" container spacing={2}>
      <Grid container item spacing={2}>
        <Grid item xs={12} md={6} className="overview-card-left">
          <CustomerCard />
        </Grid>
        <Grid item xs={12} md={6} className="overview-card-right">
          <ActiveServicesSummaryList customer={customer} />
        </Grid>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs={12} md={6} className="overview-card-left">
          <AutogiroCard customer={customer} />
        </Grid>
        <Grid item xs={12} md={6} className="overview-card-right">
          <Tasks
            customer={customer}
            handleCreateTaskModalVisibility={handleCreateTaskModalVisibility}
          />
        </Grid>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs={12} md={6} className="overview-card-left">
          <PartnerCooperationCard
            partnerCompany={customer.partnerCompany as any}
            customerId={customer.id!}
            partnerDate={customer.partnerDate!}
          />
        </Grid>
        <Grid item xs={12} md={6} className="overview-card-right">
          <Agreements customer={customer} />
        </Grid>
      </Grid>
    </Grid>
  );
};
