import { useState } from 'react';

import { jwtDecode } from 'jwt-decode';

import { AuthenticatedUser, JwtToken } from '@/types/auth';

import { useAuth } from './useAuth';

/**
 * DEPRECATED: Use `useAuth` hoos instead
 */
export default function useUser() {
  const [user] = useState(() => {
    const jwtToken = localStorage.getItem('jwt-token');

    if (jwtToken) {
      return jwtDecode(jwtToken) as JwtToken;
    } else {
      return [];
    }
  });

  const { authenticatedUser } = useAuth();

  const userToReturn = { ...user, ...authenticatedUser };

  return userToReturn as unknown as AuthenticatedUser;
}
