import { NavigateFunction } from 'react-router-dom';

import { Products } from '@/types/settings';

import { ManagedCustomer } from '../types';

export const QUERY_KEYS = {
  FETCH_CUSTOMERS: 'FETCH_CUSTOMERS',
  AMS_CUSTOMERS_FROM: 'AMS_CUSTOMERS_FROM',
  AMS_CUSTOMERS_TO: 'AMS_CUSTOMERS_TO',
};

export const getNumberOfCustomersWithActiveService = (
  managedCustomers: ManagedCustomer[],
) => {
  if (!managedCustomers) {
    return 0;
  }

  return managedCustomers.reduce((acc, cVal) => {
    if (cVal.activeServiceTypes.length > 0) {
      acc++;
    }
    return acc;
  }, 0);
};

export const getChurnDateParams = (
  churnDateExists: boolean,
  products: Products,
) => {
  const obj: { [key: string]: { churnDate: boolean } } = {};
  for (const key in products) {
    obj[key] = { churnDate: churnDateExists };
  }
  return obj;
};

export const getActiveServiceParams = (
  hasActiveService: boolean,
  products: Products,
) => {
  const obj: { [key: string]: { active: boolean } } = {};
  for (const key in products) {
    obj[key] = { active: hasActiveService };
  }
  return obj;
};

export const handleTableRowClick = (
  customerId: number,
  navigate: NavigateFunction,
) => {
  navigate(`/customer/${customerId}?tab=SERVICES`);
};
