import { InputBaseComponentProps } from '@mui/material';
import { DatePickerProps, DatePicker as RffDatePicker } from 'mui-rff';

type Props = DatePickerProps & {
  showClearButton?: boolean;
  inputProps?: InputBaseComponentProps;
};

export const DatePicker = ({
  fieldProps,
  showClearButton = false,
  inputProps,
  name,
  ...props
}: Props) => {
  return (
    <RffDatePicker
      margin="none"
      name={name}
      {...props}
      fieldProps={{
        ...fieldProps,
      }}
      //@ts-ignore
      format="yyyy-MM-dd"
      inputProps={{
        placeholder: 'åååå-mm-dd',
        'data-testid': name,
        ...inputProps,
      }}
      componentsProps={{
        actionBar: {
          actions: showClearButton ? ['clear'] : [],
        },
      }}
    />
  );
};
