import { useContext } from 'react';

import { Check, ErrorOutline } from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Button,
  CardActions,
  CardContent,
  CircularProgress,
  Divider,
  Typography,
} from '@mui/material';
import { useSelector } from '@xstate/react';

import { LabelContentAndAction } from '@/components/LabelContentAndAction';
import { SinneConfigContext } from '@/modules/sinne/components/sinneConfigFlow/context/SinneConfigContextProvider';
import { getDurationSinceLastDeviceSync } from '@/modules/sinne/components/sinneConfigFlow/helpers/getDurationSinceLastDeviceSync';

export const DeviceSyncronization = () => {
  const machine = useContext(SinneConfigContext);

  const state = useSelector(machine, (state) => state);
  const { device, scannedImei } = state.context;

  const imeiFromApi = device.data?.imei;
  const syncedOn = device.data?.syncedOn;

  const pollRetries = state.context.device.retries || 1;
  const maxPollRetries = state.context.maxRetries;

  const connected = state.matches('DEVICE_SYNCRONIZATION.CONNECTED');
  const isLoading = state.matches('DEVICE_SYNCRONIZATION.loading');
  const isError = state.matches('DEVICE_SYNCRONIZATION.error');
  const errorMessage = state.context.apiError?.message;

  return (
    <>
      <CardContent>
        {isLoading && (
          <Alert severity="info">
            <AlertTitle>
              Väntar på att enheten ska synkronisera med molnet, det kan ta upp
              till 5 minuter
            </AlertTitle>
            Väck enheten genom att ta bort batteriernas plastflikar om detta
            inte redan har gjorts. Försök {pollRetries} av {maxPollRetries}
          </Alert>
        )}
        {connected && (
          <Alert severity="success" data-testid="alert-success">
            Enheten synkroniserades för{' '}
            <strong>
              {syncedOn ? getDurationSinceLastDeviceSync(syncedOn) : ''}{' '}
            </strong>
            sedan.
          </Alert>
        )}
        {isError && (
          <Alert severity="error" sx={{ marginBottom: 2 }}>
            <AlertTitle>Det gick inte att synkronisera enheten</AlertTitle>
            {errorMessage}
          </Alert>
        )}

        <LabelContentAndAction
          label="Angiven IMEI:"
          content={<Typography>{scannedImei}</Typography>}
          action={<Check color="success" />}
        />
        <LabelContentAndAction
          label="Identifierad IMEI enligt api:"
          content={
            <>
              {!imeiFromApi && isLoading ? (
                <CircularProgress size={25} />
              ) : scannedImei === imeiFromApi ? (
                <Typography>{imeiFromApi}</Typography>
              ) : (
                <Typography color={(t) => t.palette.error.main}>
                  {imeiFromApi}
                </Typography>
              )}
            </>
          }
          action={
            !imeiFromApi && isLoading ? (
              <CircularProgress size={25} />
            ) : imeiFromApi === scannedImei ? (
              <Check color="success" />
            ) : (
              <ErrorOutline color="error" />
            )
          }
        />

        {(isLoading || !connected) && !isError && (
          <LabelContentAndAction
            label="Status:"
            content={connected ? 'Ansluten' : 'Väntar på synkronisering'}
            action={connected ? <Check /> : <CircularProgress size={24} />}
          />
        )}
      </CardContent>
      <Divider />
      <CardActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button disabled={!connected} onClick={() => machine.send('PROCEED')}>
          Nästa
        </Button>
        {isError && (
          <Button
            variant="outlined"
            onClick={() =>
              machine.send({
                type: 'RETRY_DEVICE_SYNCRONIZATION',
              })
            }
          >
            Försök igen
          </Button>
        )}
      </CardActions>
    </>
  );
};
