import { useSetAtom } from 'jotai/index';

import { Modal, modalManagementAtom } from '@/state/modalManagementAtom';

export const useModal = () => {
  const setModalAtom = useSetAtom(modalManagementAtom);

  const openModal = (modal: Modal) => {
    setModalAtom(modal);
  };

  const closeModal = () => {
    setModalAtom(null);
  };

  return { openModal, closeModal };
};
