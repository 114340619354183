import React, { useMemo } from 'react';

import { ArrowForwardOutlined } from '@mui/icons-material';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import {
  Box,
  Card,
  CardContent,
  LinearProgress,
  Link,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useQuery } from '@tanstack/react-query';
import { endOfDay, format, startOfDay, sub } from 'date-fns';
import camelCase from 'lodash/camelCase';
import kebabCase from 'lodash/kebabCase';
import snakeCase from 'lodash/snakeCase';
import { Link as RouterLink } from 'react-router-dom';

import { Alert } from '@/components/Alert';
import { Pagination } from '@/components/Pagination';
import { SimpleTable, SimpleTableColumn } from '@/components/SimpleTable';
import { getFormattedDate } from '@/helpers/dates';
import { renderServiceIcons } from '@/helpers/render';
import { getObjectKeys } from '@/helpers/tsHelpers';
import { usePrivateSettings } from '@/hooks';
import { useAuth } from '@/hooks/useAuth';
import { Toolbar } from '@/modules/accountManagerSystem/components/Toolbar';
import { Customer } from '@/types/customers';
import { PricePlansTypes, ServiceTypes } from '@/types/settings';
import httpClient from '@/utils/httpClient';

import { LatestNote } from './LatestNote';

function isSimpleTableColumn(column: any): column is SimpleTableColumn {
  return typeof column !== 'boolean' && 'renderer' in column;
}

const SORT_OPTIONS = [
  {
    id: 0,
    value: 'asc',
    text: 'Churn-datum (stigande)',
  },
  {
    id: 1,
    value: 'desc',
    text: 'Churn-datum (fallande)',
  },
];
export const AntiChurnTable = () => {
  const today = new Date();
  const theme: Theme = useTheme();
  const tabLocationParameter = new URLSearchParams(location.search).get('tab');

  const breakpoints = theme?.breakpoints.values;
  const { authenticatedUser: user } = useAuth();
  const [page, setPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(100);
  const [totalServices, setTotalServices] = React.useState(0);
  const [searchParams, setSearchParams] = React.useState({
    query: '',
    type: 'standard',
  });
  const [order, setOrder] = React.useState('asc');

  const { servicesPricePlans, servicesTypes, servicesNames } =
    usePrivateSettings();

  const invertedServiceNames = getObjectKeys(servicesNames).reduce(
    (acc: Record<string, string>, cVal) => {
      acc[servicesNames[cVal]] = cVal;
      return acc;
    },
    {},
  );

  const activeTab =
    invertedServiceNames[
      tabLocationParameter as keyof typeof invertedServiceNames
    ];

  const {
    data: customers,
    isLoading: loadingCustomers,
    isError: errorCustomers,
  } = useQuery({
    queryKey: ['AmsAntiChurn', activeTab, user, searchParams],
    queryFn: async () => {
      const { query, type } = searchParams;
      const params = {
        doNotDisturb: false,
        ...(type === 'standard' ? { search: query } : { q: query }),
        type,
        accountManager: user?.id,
        [`exists[${camelCase(activeTab)}s.churnDate]`]: true,
        ...(activeTab === 'coProtection' && { 'coProtections.status': 1 }),
        properties: {
          id: true,
        },
      };

      let endpoint = '/customers';

      if (type !== 'standard') {
        endpoint += '/advanced';
      }

      const { data } = await httpClient.get(endpoint, {
        params,
      });

      const customerIds = data['hydra:member'].map(
        (customer: Customer) => customer.id,
      );
      return data['hydra:totalItems'] === 0 ? false : customerIds;
    },
  });

  const endDate = useMemo(() => {
    const threeMonthsFromNow = sub(today, { months: -3 });
    const threeMonthsFromNowEndOfDay = endOfDay(threeMonthsFromNow);
    return format(threeMonthsFromNowEndOfDay, 'yyyy-MM-dd HH:mm:ss');
  }, [today]);

  const startDate = useMemo(() => {
    return format(startOfDay(today), 'yyyy-MM-dd HH:mm:ss');
  }, [today]);

  const {
    data: services,
    isInitialLoading: loadingServices,
    isError: errorServices,
  } = useQuery({
    queryKey: ['antiChurnServices', customers, activeTab, order, page, perPage],
    queryFn: async () => {
      const { data } = await httpClient.get(`/${snakeCase(activeTab)}s`, {
        params: {
          'customer.id': customers,
          'exists[churnDate]': true,
          'order[churnDate]': order,
          'churnDate[after]': startDate,
          'churnDate[before]': endDate,
          ...(activeTab === 'coProtection' && { 'exists[lostAt]': false }),
          ...(activeTab === 'coProtection' && { status: 1 }),
          page: page + 1,
          perPage,
          properties: {
            status: true,
            churnDate: true,
            id: true,
            price: true,
            pricePlanId: true,
            campaign: true,
            type: true,
            customer: {
              companyCategory: true,
              id: true,
              notes: true,
              accountManager: {
                id: true,
              },
              companyName: true,
              fullName: true,
              phone: true,
              mobile: true,
              email: true,
              serviceTypes: true,
            },
          },
        },
      });
      setTotalServices(data['hydra:totalItems']);
      return data['hydra:member'];
    },
    enabled: !!customers,
  });

  const handlePageChange = (_: any, page: React.SetStateAction<number>) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event: { target: { value: string } }) => {
    const rowsPerPage = parseInt(event.target.value, 10);

    if (totalServices <= rowsPerPage) {
      setPage(0);
    }

    setPerPage(rowsPerPage);
  };

  const columns = [
    {
      label: 'Kundnr',
      renderer: (row: { customer: { id: any } }) => row.customer.id,
    },
    {
      label: 'Företag',
      renderer: (row: {
        customer: { companyCategory: any; companyName: any };
      }) => (row.customer.companyCategory ? row.customer.companyName : ''),
    },
    {
      label: 'Kontakt',
      renderer: (row: { customer: { fullName: any } }) => row.customer.fullName,
    },
    {
      label: 'Telefon',
      renderer: (row: { customer: { phone: any } }) => row.customer.phone,
    },
    {
      label: 'Mobil',
      renderer: (row: { customer: { mobile: any } }) => row.customer.mobile,
    },
    {
      label: 'Tjänster',
      renderer: (row: { customer: any }) => (
        <Box display="flex" justifyContent="space-evenly">
          {renderServiceIcons(row.customer)}
        </Box>
      ),
    },
    {
      label: 'Pris',
      renderer: (row: {
        pricePlanId: string | number;
        price: { priceInCurrency: any };
      }) =>
        `${
          typeof row.pricePlanId === 'number'
            ? servicesPricePlans[activeTab as keyof typeof servicesPricePlans][
                row.pricePlanId as keyof PricePlansTypes
              ]
            : row.price.priceInCurrency
        }kr exkl. moms`,
    },
    {
      label: 'Churn-datum',
      renderer: (row: { churnDate: string }) =>
        getFormattedDate(row.churnDate, 'yyyy-MM-dd'),
    },
    (activeTab === 'protection' || activeTab === 'coProtection') && {
      label: 'Paket',
      renderer: (row: { type: string | number }) =>
        servicesTypes[camelCase(activeTab) as keyof typeof servicesTypes][
          row.type as keyof ServiceTypes
        ],
    },
    {
      label: 'Senaste logg',
      renderer: (row: { customer: Customer }) => (
        <LatestNote customer={row.customer['@id']} />
      ),
    },
    {
      label: '',
      renderer: (row: { [x: string]: string | undefined; id: any }) => (
        <>
          <Link
            style={{ marginRight: '1rem' }}
            target="_blank"
            href={`/products/${kebabCase(row['@type']).toLowerCase()}/${
              row.id
            }/edit`}
          >
            <Tooltip title="Öppna i ny flik">
              <LaunchOutlinedIcon color="primary" />
            </Tooltip>
          </Link>
          <RouterLink
            to={`/products/${kebabCase(row['@type']).toLowerCase()}/${
              row.id
            }/edit`}
          >
            <Tooltip title="Öppna i samma flik">
              <ArrowForwardOutlined color="primary" />
            </Tooltip>
          </RouterLink>
        </>
      ),
    },
  ];

  const filteredColumns = columns.filter(isSimpleTableColumn);

  return (
    <Box maxWidth={breakpoints.xl}>
      <Box marginBottom={2}>
        <Toolbar
          options={SORT_OPTIONS}
          searchHandler={(
            v: React.SetStateAction<{ query: string; type: string }>,
          ) => setSearchParams(v)}
          orderHandler={(order) => setOrder(order)}
          order={order}
        />
      </Box>
      {loadingCustomers || loadingServices ? (
        <LinearProgress />
      ) : errorCustomers || errorServices ? (
        <Alert severity="error">Kunde inte hämta data.</Alert>
      ) : totalServices > 0 ? (
        <>
          <Pagination
            count={totalServices}
            page={page}
            rowsPerPage={perPage}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
          {/**
           * ts can't handle
           * label: string | React.ReactNode;
          //@ts-ignore */}
          <SimpleTable rows={services} columns={filteredColumns} />
          <Pagination
            count={totalServices}
            page={page}
            rowsPerPage={perPage}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </>
      ) : (
        <Card>
          <CardContent>
            <Typography variant="h1">Ingen data</Typography>
          </CardContent>
        </Card>
      )}
    </Box>
  );
};
