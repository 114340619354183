import mapValues from 'lodash/mapValues';

import { getFormattedDate } from '@/helpers/dates';
import { ServiceName } from '@/types/services';

type Values = Record<string, unknown>;

export function prepareServiceUpdatePayload(
  values: Values,
  service: ServiceName,
) {
  const KEYS_TO_ZERO_OR_ONE = [
    'billing',
    'antiregret',
    'winBack',
    'upgradePolicy',
    'unitReturned',
  ];

  const KEYS_TO_INT = [
    'kamId',
    'tb',
    'operatorId',
    'operatorId2',
    'status',
    'churnFiretextActive',
    'churnFiretextReason',
    'pricePlanId',
    'regretSource',
    'regretReason',
    'billingMonth',
    'type',
    'churnReason',
  ];

  return mapValues(values, (value, key) => {
    if (service !== 'homegate' && service !== 'sinne') {
      if (KEYS_TO_ZERO_OR_ONE.includes(key)) {
        return value ? 1 : 0;
      }
    }

    if (KEYS_TO_INT.includes(key) && typeof value === 'string') {
      return parseInt(value, 10);
    }

    //TODO: Move to a commonly used function
    if (key === 'churnDate' || key === 'regDate') {
      if (values[key]) {
        return getFormattedDate(value, 'yyyy-MM-dd');
      }
    }

    return value;
  });
}
