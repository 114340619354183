import React from 'react';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { CircularProgress, Typography } from '@mui/material';
import { useSelector } from '@xstate/react';

import { RetryButton, StepRow } from '@/modules/sinne/components';
import { DiscardStateContext } from '@/modules/sinne/components/sinneDiscardFlow/context/';

export const DeactivationDispatch = () => {
  const discardFlowService = React.useContext(DiscardStateContext);

  const state = useSelector(discardFlowService, (state) => state);

  return (
    <>
      {
        // SIM CARD DEACTIVATION
      }
      {state.matches('DEACTIVATION_DISPATCH.DEACTIVATE_SIM_CARD.loading') && (
        <StepRow>
          <CircularProgress size={14} />
          <Typography component="span" variant="h6">
            Avslutar (Terminate) sim-kortet
          </Typography>
        </StepRow>
      )}
      {state.context.isSimCardDeactivated && (
        <StepRow>
          <CheckCircleOutlineIcon color="success" sx={{ fontSize: 20 }} />
          <Typography component="span" variant="h6" padding={0} margin={0}>
            Sim-kortet har avslutats (Terminated).
          </Typography>
        </StepRow>
      )}
      {state.matches('DEACTIVATION_DISPATCH.DEACTIVATE_SIM_CARD.error') && (
        <StepRow>
          <ErrorOutlineIcon color="error" sx={{ fontSize: 20 }} />
          <Typography component="span" variant="h6">
            Kunde inte avsluta sim-kortet.
          </Typography>
          <RetryButton
            onClick={() =>
              discardFlowService.send({
                type: 'RETRY_DEACTIVATE_SIM_CARD',
              })
            }
          />
        </StepRow>
      )}
      {
        // CUSTOMER SERVICE EMAIL
      }
      {state.matches(
        'DEACTIVATION_DISPATCH.SEND_CUSTOMER_SERVICE_EMAIL.loading',
      ) && (
        <StepRow>
          <CircularProgress size={14} />
          <Typography component="span" variant="h6">
            Skickar mail till kundservice med information om att enheten
            kasserats.
          </Typography>
        </StepRow>
      )}
      {state.context.isCustomerServiceEmailSent && (
        <StepRow>
          <CheckCircleOutlineIcon color="success" sx={{ fontSize: 20 }} />
          <Typography component="span" variant="h6" padding={0} margin={0}>
            Mail har skickats till kundservice med information om att enheten
            kasserats
          </Typography>
        </StepRow>
      )}
      {state.matches(
        'DEACTIVATION_DISPATCH.SEND_CUSTOMER_SERVICE_EMAIL.error',
      ) && (
        <StepRow>
          <ErrorOutlineIcon color="error" sx={{ fontSize: 20 }} />
          <Typography component="span" variant="h6">
            Kunde inte skicka mail till kundservice.
          </Typography>
          <RetryButton
            onClick={() =>
              discardFlowService.send({
                type: 'RETRY_SEND_CUSTOMER_SERVICE_EMAIL',
              })
            }
          />
        </StepRow>
      )}
      {
        // ELDES EMAIL
      }
      {state.matches('DEACTIVATION_DISPATCH.SEND_ELDES_EMAIL.loading') && (
        <StepRow>
          <CircularProgress size={14} />
          <Typography component="span" variant="h6">
            Skickar mail till Eldes med information om att enheten kasserats.
          </Typography>
        </StepRow>
      )}
      {state.context.isEldesEmailSent && (
        <StepRow>
          <CheckCircleOutlineIcon color="success" sx={{ fontSize: 20 }} />
          <Typography component="span" variant="h6" padding={0} margin={0}>
            Mail har skickats till Eldes med information om att enheten
            kasserats och ska avaktiveras.
          </Typography>
        </StepRow>
      )}
      {state.matches('DEACTIVATION_DISPATCH.SEND_ELDES_EMAIL.error') && (
        <StepRow>
          <ErrorOutlineIcon color="error" sx={{ fontSize: 20 }} />
          <Typography component="span" variant="h6">
            Kunde inte skicka mail till Eldes.
          </Typography>
          <RetryButton
            onClick={() =>
              discardFlowService.send({
                type: 'RETRY_DEACTIVATE_SIM_CARD',
              })
            }
          />
        </StepRow>
      )}
    </>
  );
};
