import { Grid, LinearProgress, Typography } from '@mui/material';
import { format, formatISO, parseISO } from 'date-fns';

import { SimpleCard } from '@/components/SimpleCard';
import { Checkboxes, DatePicker, Dropdown } from '@/components/form';
import { BILLING_MONTH_OPTIONS } from '@/constants';
import { generateStatusOptions } from '@/helpers/productEdit';
import { usePrivateSettings } from '@/hooks';
import useUserRoles from '@/hooks/useUserRoles';
import {
  getServiceInfoCardTitle,
  isServiceStatusChangeEnabled,
} from '@/modules/services/utils';
import { StickersParamSchema } from '@/types/services';
import { fieldRequired } from '@/utils/validation';

type Props = {
  serviceDetails: StickersParamSchema;
  updateInProgress: boolean;
};

export const ServiceInfoForm = ({
  serviceDetails,
  updateInProgress,
}: Props) => {
  const userRoles = useUserRoles();
  const { servicesStatuses } = usePrivateSettings();

  const statusOptions = generateStatusOptions(servicesStatuses.sticker);

  const canChangeStatus = isServiceStatusChangeEnabled(
    serviceDetails,
    userRoles,
  );

  return (
    <SimpleCard
      title={getServiceInfoCardTitle(serviceDetails.customer)}
      cardContent={
        <>
          <Grid container>
            <Grid item xs>
              <Typography variant="h6">
                Orderdatum:{' '}
                {serviceDetails.regDate
                  ? format(parseISO(serviceDetails.regDate), 'yyyy-MM-dd')
                  : 'Saknas'}
              </Typography>
            </Grid>

            <Grid item xs>
              <Typography gutterBottom variant="h6">
                Seriekod: {serviceDetails.code}
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs>
              <Dropdown
                label="Status"
                name="status"
                options={statusOptions}
                disabled={!canChangeStatus}
                required
                fieldProps={{
                  validate: fieldRequired,
                  parse: (value) => parseInt(value, 10),
                }}
              />
            </Grid>

            <Grid item xs>
              <DatePicker
                label="Churn-datum"
                name="churnDate"
                showClearButton
              />

              <Dropdown
                label="Fakturamånad"
                name="billingMonth"
                options={BILLING_MONTH_OPTIONS}
                required
                fieldProps={{
                  validate: fieldRequired,
                }}
              />
            </Grid>
          </Grid>

          <Typography variant="h6">Val</Typography>

          <Grid container spacing={2}>
            <Grid item>
              <Checkboxes
                options={{
                  label: 'Faktureras',
                  value: !!serviceDetails.billing,
                }}
                fieldProps={{
                  parse: (value) => Boolean(value),
                }}
                name="billing"
                inputProps={{
                  // @ts-expect-error data-testid does not exist in type
                  'data-testid': 'billing',
                }}
              />
            </Grid>
          </Grid>

          {updateInProgress && <LinearProgress />}
        </>
      }
    />
  );
};
