import { getObjectKeys } from '@/helpers/tsHelpers';
import {
  BasicStatsDistributionToDisplay,
  StatsDistribution,
} from '@/types/stats';

import { SERVICE_STATUS_COLORS_MAP } from '../../constants';

type Headers = {
  id: number;
  label: BasicStatsDistributionToDisplay;
  color: string;
};

export type StatsBasicTableData = {
  headers: Headers[];
  data: string[];
};

type RawData = Pick<StatsDistribution, BasicStatsDistributionToDisplay>;

export function prepareBasicStatsTableData(rawData: RawData) {
  return getObjectKeys(rawData).reduce(
    (prev, current, index) => {
      prev.headers.push({
        id: index + 1,
        label: current,
        color: SERVICE_STATUS_COLORS_MAP[current],
      });

      const brutto = rawData['Brutto'];

      const percent = brutto
        ? `(${((rawData[current] / brutto) * 100).toFixed()}%)`
        : '';

      if (current === 'Brutto') {
        prev.data.push(`${rawData[current]}`);
      } else {
        prev.data.push(`${rawData[current]} ${percent}`);
      }

      return prev;
    },
    {
      headers: [],
      data: [],
    } as StatsBasicTableData,
  );
}
