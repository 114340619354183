import {
  Alert,
  AlertTitle,
  Card,
  CardHeader,
  Divider,
  LinearProgress,
  Tooltip,
  Typography,
} from '@mui/material';

import { SinneDeviceEconomyResponse } from '@/api/sinne';
import { LabelAndContentRow } from '@/components';
import { formatCurrency } from '@/modules/common/utils/currency';

type Props = {
  servicesNames: { sinne: string };
  isError: boolean;
  data: SinneDeviceEconomyResponse;
  isLoading: boolean;
};

export const DeviceEconomy = ({
  servicesNames,
  isError,
  data,
  isLoading,
}: Props) => {
  return (
    <Card style={{ marginBottom: '1rem' }}>
      <CardHeader title={servicesNames.sinne}></CardHeader>
      <Divider />

      {isLoading ? (
        <LinearProgress />
      ) : isError ? (
        <Alert severity="info">
          <AlertTitle>Inga enheter hittades</AlertTitle>
        </Alert>
      ) : (
        <>
          <LabelAndContentRow
            content={<Typography>{data.allDevices} st</Typography>}
            label="Antal"
          />
          <Divider />
          <LabelAndContentRow
            content={<Typography>{data.inactiveDevices} st</Typography>}
            label="Avskrivna enheter"
          />
          <Divider />
          <LabelAndContentRow
            content={
              <Tooltip title="Totala summan av bolagets köpta enheter">
                <Typography>{formatCurrency(data.totalPrice)}</Typography>
              </Tooltip>
            }
            label="Totalt inköpsvärde"
          />
          <Divider />
          <LabelAndContentRow
            content={
              <Tooltip title="Totala inköpsvärde minus Restvärde">
                <Typography>
                  {formatCurrency(data.inactiveDevicesValue)}
                </Typography>
              </Tooltip>
            }
            label="Ackumulerad avskrivning"
          />
          <Divider />
          <LabelAndContentRow
            content={
              <Tooltip title="Totala summan av restvärde för alla enheter">
                <Typography>
                  {formatCurrency(data.activeDevicesCurrentValue)}
                </Typography>
              </Tooltip>
            }
            label="Restvärde"
          />
          <Divider />
        </>
      )}
    </Card>
  );
};
