import { SvgIcon, SvgIconProps } from '@mui/material';

export const HelpIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 96 96" width="96px" height="96px">
      <g id="surface192620464">
        <path d="M 48 8 C 25.945312 8 8 25.945312 8 48 C 8 70.054688 25.945312 88 48 88 C 70.054688 88 88 70.054688 88 48 C 88 25.945312 70.054688 8 48 8 Z M 48 14 C 66.8125 14 82 29.1875 82 48 C 82 66.8125 66.8125 82 48 82 C 29.1875 82 14 66.8125 14 48 C 14 29.1875 29.1875 14 48 14 Z M 48 26 C 41.410156 26 36 31.410156 36 38 L 36 39 C 35.984375 40.082031 36.554688 41.089844 37.488281 41.632812 C 38.421875 42.179688 39.578125 42.179688 40.511719 41.632812 C 41.445312 41.089844 42.015625 40.082031 42 39 L 42 38 C 42 34.652344 44.652344 32 48 32 C 51.347656 32 54 34.652344 54 38 C 54 42.683594 52.78125 43.269531 50.648438 44.945312 C 49.582031 45.785156 48.183594 46.75 46.988281 48.378906 C 45.792969 50.007812 45 52.277344 45 55 C 44.984375 56.082031 45.554688 57.089844 46.488281 57.632812 C 47.421875 58.179688 48.578125 58.179688 49.511719 57.632812 C 50.445312 57.089844 51.015625 56.082031 51 55 C 51 53.292969 51.332031 52.601562 51.824219 51.929688 C 52.316406 51.257812 53.167969 50.59375 54.351562 49.664062 C 56.71875 47.804688 60 44.316406 60 38 C 60 31.410156 54.589844 26 48 26 Z M 48 64 C 45.789062 64 44 65.789062 44 68 C 44 70.210938 45.789062 72 48 72 C 50.210938 72 52 70.210938 52 68 C 52 65.789062 50.210938 64 48 64 Z M 48 64 " />
      </g>
    </SvgIcon>
  );
};
