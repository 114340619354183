import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ChecklistTitle = styled(Typography)`
  padding: ${({ theme }) => theme.spacing(2)};
`;

export const ListTitle = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;
