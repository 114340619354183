import React from 'react';

import { Check, Clear, Delete, Download, Edit } from '@mui/icons-material';
import {
  CircularProgress,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';

import { SimpleTableColumn } from '@/components/SimpleTable';
import { getFormattedDate } from '@/helpers/dates';
import { formatCurrency } from '@/modules/common/utils/currency';
import { ProtectionCampaign } from '@/types/campaigns';

import { CampaignTableColumnProps } from './getCampaignTableColumns';

export const getProtectionCampaignTableColumns = ({
  handleEditCampaign,
  deleting,
  deleteCampaignMutation,
  handleDeleteOpen,
  anchorEl,
  handleDeleteClose,
  handleDelete,
  generateMockedWelcomeLetterMutation,
}: Omit<CampaignTableColumnProps, 'campaignType'>) => {
  const columns: SimpleTableColumn<ProtectionCampaign>[] = [
    { label: 'Id', renderer: (campaign) => campaign.id },
    { label: 'Namn', renderer: (campaign) => campaign.name },
    {
      label: 'Intern Beskrivning',
      renderer: (campaign) => campaign?.internalDescription,
    },
    {
      label: 'Månadspris',
      align: 'right',
      renderer: (campaign) =>
        campaign.price
          ? formatCurrency(
              campaign.price?.priceWithVatInCurrency,
              campaign.price?.currency,
            )
          : '-',
    },
    {
      label: 'Paket',
      align: 'right',
      renderer: (campaign) => campaign.package,
    },
    {
      label: 'Skapad',
      align: 'right',
      renderer: (campaign) => getFormattedDate(campaign.createdAt),
    },
    {
      label: 'Aktiv',
      renderer: (campaign) =>
        campaign.active ? <Check /> : <Clear color="error" />,
    },
    {
      label: 'Visa',
      renderer: (campaign) => (
        <IconButton
          onClick={() => handleEditCampaign(campaign['@id'])}
          size="large"
        >
          <Edit />
        </IconButton>
      ),
    },
    {
      label: 'Exempelbrev',
      renderer: (row) => (
        <Tooltip
          title={
            row.welcomeLetterUri
              ? 'Klicka för att ladda ner ett exempelbrev'
              : 'Kampanjen har ingen mall kopplad till sig. Ladda först upp en mall till kampanjen för att kunna generera ett exempelbrev'
          }
        >
          <span>
            <IconButton
              onClick={() => {
                row.welcomeLetterUri;
                generateMockedWelcomeLetterMutation.mutate({
                  campaignId: row.id,
                  product: 'protection',
                });
              }}
              size="large"
              disabled={
                row.welcomeLetterUri === null ||
                generateMockedWelcomeLetterMutation.isPending
              }
            >
              <Download />
            </IconButton>
          </span>
        </Tooltip>
      ),
    },
    {
      label: 'Ta bort',
      renderer: (campaign) => (
        <>
          {deleting ? (
            <CircularProgress />
          ) : (
            <>
              <IconButton
                aria-label="delete"
                size="small"
                value={campaign['@id']}
                disabled={deleteCampaignMutation.isPending}
                onClick={handleDeleteOpen}
              >
                <Delete fontSize="small" />
              </IconButton>
              <Menu
                id="delete-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleDeleteClose}
              >
                <MenuItem onClick={handleDeleteClose} disabled>
                  Ta bort?
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleDelete}>Ja</MenuItem>
                <MenuItem onClick={handleDeleteClose}>Nej</MenuItem>
              </Menu>
            </>
          )}
        </>
      ),
    },
  ];
  return columns;
};
