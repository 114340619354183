import { useQuery } from '@tanstack/react-query';

import { getKeyTagRegisterResults } from '@/api/keyTag';
import { generateKeys } from '@/constants/queryKeys';

const keyTagBulkRegisteringResultQueryKey = generateKeys(
  'keyTagBulkRegisteringResult',
);

export const useGetKeyTagBulkRegisteringResult = (
  uuid: string,
  refetch: boolean,
) => {
  return useQuery({
    queryKey: keyTagBulkRegisteringResultQueryKey.detailById(uuid),
    queryFn: () => {
      const response = getKeyTagRegisterResults(uuid);

      return response;
    },
    refetchInterval: refetch ? 1000 : 0,
    enabled: !!uuid,
  });
};
