import { DeleteOutline } from '@mui/icons-material';
import {
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Skeleton,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useSnackbar } from '@/hooks';
import httpClient from '@/utils/httpClient';

import { BasicAlert } from '../../../../components/BasicAlert/BasicAlert';
import { Team } from '../../types';

type Props = {
  id: string;
  team: string;
  members: string[];
};

export const TeamMemberListItem = ({
  id: iri,
  team: teamIri,
  members,
}: Props) => {
  const queryClient = useQueryClient();
  const snackbar = useSnackbar();

  const {
    data: member,
    isFetching,
    isError,
  } = useQuery({
    queryKey: ['teamMember', iri],

    queryFn: async () => {
      const { data } = await httpClient.get(iri, {
        params: {
          properties: {
            name: true,
          },
        },
      });
      return data;
    },
  });

  const removeMemberMutation = useMutation({
    mutationFn: async (id: string): Promise<Team> => {
      const newMembers = members.filter((member) => id !== member);
      const { data } = await httpClient.put(teamIri, {
        members: newMembers,
      });
      return data;
    },
    onSuccess: (updatedTeam: Team) => {
      queryClient.setQueryData<Team[]>(['amsTeams'], (oldTeamData) => {
        if (!oldTeamData) return [];
        const teamIndex = oldTeamData.findIndex(
          (team) => team['@id'] === teamIri,
        );
        const updatedTeams = [...oldTeamData];
        updatedTeams[teamIndex] = updatedTeam;
        return updatedTeams;
      });
      snackbar({ type: 'success', message: 'Uppdatering lyckades.' });
    },
    onError: () => {
      queryClient.refetchQueries({
        queryKey: ['amsTeams'],
      });
      snackbar({ type: 'error', message: 'Uppdatering misslyckades.' });
    },
  });

  return (
    <>
      {isFetching ? (
        <Skeleton width="100%" />
      ) : isError ? (
        <BasicAlert />
      ) : (
        <ListItem divider>
          <ListItemText primary={member.name} />
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              onClick={() => removeMemberMutation.mutate(iri)}
              size="large"
            >
              <DeleteOutline />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      )}
    </>
  );
};
