import React, { useCallback, useState } from 'react';

import { HighlightOff } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  Tooltip,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { formatISO } from 'date-fns';
import camelCase from 'lodash/camelCase';

import { Can } from '@/components/Can';
import { DialogActions } from '@/components/DialogActions';
import { getTypeKey } from '@/helpers';
import { usePrivateSettings, useSnackbar } from '@/hooks';
import { useAuth } from '@/hooks/useAuth';
import { Service } from '@/modules/common/hooks/useActiveServices';
import { CamelCaseServiceName } from '@/types/services';
import { ServiceStatuses } from '@/types/settings';
import httpClient from '@/utils/httpClient';

type Props = {
  service: Service;
  onChurnConfirm: () => void;
};

export const ChurnButton = ({ service, onChurnConfirm }: Props) => {
  const snackbar = useSnackbar();

  const { getAuthenticatedUserRoles } = useAuth();
  const userRoles = getAuthenticatedUserRoles();
  const { servicesStatuses, servicesNames } = usePrivateSettings();
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const handleChurnClick = useCallback(
    (e: any) => {
      e.stopPropagation();
      setIsConfirmOpen(true);
    },
    [setIsConfirmOpen],
  );

  const handleChurnCancel = useCallback(
    (e: any) => {
      e.stopPropagation();
      setIsConfirmOpen(false);
    },
    [setIsConfirmOpen],
  );

  const churnMutation = useMutation({
    mutationFn: async () => {
      const churnKey = getTypeKey(
        servicesStatuses,
        camelCase(service['@type']) as keyof ServiceStatuses,
        'Churn',
      );
      const status = parseInt(churnKey!, 10);
      await httpClient.put(service['@id'], {
        status,
        churnDate: formatISO(new Date()),
        billing: 0,
      });
      setIsConfirmOpen(false);
      snackbar({
        type: 'success',
        message: 'Churn lyckades.',
      });
      if (onChurnConfirm) onChurnConfirm();
    },
  });
  const handleConfirmClick = useCallback((e: any) => {
    e.stopPropagation();
    churnMutation.mutate();
  }, []);
  if (!['KeyTag', 'Sticker'].includes(service['@type'])) return null;
  return (
    <>
      <Can
        userRoles={userRoles}
        action="services.churn"
        yes={() => (
          <IconButton
            size="small"
            onClick={handleChurnClick}
            sx={{
              position: 'relative',
              top: -5,
              right: -5,
            }}
          >
            <Tooltip title="Churn">
              <HighlightOff color="error" />
            </Tooltip>
          </IconButton>
        )}
      />
      <Dialog open={isConfirmOpen} onClose={handleChurnCancel}>
        <DialogTitle>
          {`Vill du sätta status churn på tjänsten ${
            servicesNames[camelCase(service['@type']) as CamelCaseServiceName]
          } - ${service.id}?`}
        </DialogTitle>
        <Divider />
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={handleConfirmClick}
          >
            Ja
          </Button>
          <Button variant="outlined" onClick={handleChurnCancel}>
            Nej
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
