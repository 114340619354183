import { z } from 'zod';

import { StickerItem } from '@/modules/sticker';
import { AllBooleans } from '@/utils/typeHelpers';

import { CustomerAgreement } from './agreements';
import { AutogiroAgreementTypes, AutogiroSourceTypes } from './autogiro';
import { CommonResponseFields, Pagination } from './common';
import { Cost } from './costs';
import { Fee } from './fees';
import { NoteItem } from './notes';
import {
  CoProtectionItem,
  FiretextItem,
  HomegateItem,
  KeyTagItem,
  ProtectionItem,
  ServiceName,
  SinneItem,
} from './services';
import { UserItem } from './users';

export enum CustomerType {
  COMPANY = 2,
  PRIVATE = 3,
}

export const CustomerTypeEnum = z.union([
  z.literal(CustomerType.COMPANY),
  z.literal(CustomerType.PRIVATE),
]);

export const createCustomerSchema = z.object({
  accountManager: z.string().nullable().optional(),
  category: z.union([
    z.literal(CustomerType.COMPANY),
    z.literal(CustomerType.PRIVATE),
  ]),
  companyName: z.string().optional(),
  address: z.string(),
  address2: z.string().optional(),
  autogiroAgreement: z.number().optional(),
  autogiroSource: z.number().optional(),
  bankAccount: z.string().optional(),
  bankName: z.string().optional(),
  city: z.string(),
  debtCollection: z.boolean().optional(),
  doNotDisturb: z.boolean().optional(),
  email: z.string().optional(),
  firstName: z.string(),
  invoiceEmail: z.string().optional(),
  lastName: z.string(),
  mobile: z.string().optional(),
  partnerCompany: z.string().optional(),
  partnerDate: z.string().optional(),
  phone: z.string().optional(),
  pin: z.string(),
  zip: z.string(),
});

export type CreateCustomerPayload = z.infer<typeof createCustomerSchema>;

export const updateCustomerSchema = createCustomerSchema.partial();

export type UpdateCustomerPayload = z.infer<typeof updateCustomerSchema>;

// todo add pauload to name
export type UpdateCustomer = {
  id: string | number;
  accountManager?: string | null | undefined;
} & MutableCustomerFields;

export type CustomerDTO = MutableCustomerFields &
  ImmutableCustomerFields &
  CommonResponseFields;

export type PartnerCompany = {
  '@id': string;
  type: string;
  id: number;
  name: string;
};

export interface Customer extends CustomerDTO {
  birthday: string;
  accountManager: string | null | undefined;
}

// TODO: Figure out best way to have Customer type adjustable to different use cases
export interface ExistingCustomer extends Customer {
  id: number;
}

export type EnhancedAMSCustomer = Customer & {
  totalARR: string;
  accountManager: Pick<UserItem, '@id' | '@type' | 'id' | 'name'>;
  serviceTypes: {
    [key in ServiceName]: boolean;
  };
};

export type MutableCustomerFields = {
  category: 2 | 3;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  address?: string;
  address2?: string;
  zip?: string;
  city?: string;
  email?: string;
  phone?: string;
  mobile?: string;
  pin?: string;
  invoiceEmail?: string;
  invoicePeriod?: number;
  bankName?: string;
  bankAccount?: string;
  autogiroAgreement?: AutogiroAgreementTypes;
  autogiroSource?: AutogiroSourceTypes;
  latitude?: string;
  longitude?: string;
  companyName?: string;
  doNotDisturb?: boolean;
  lastLogin?: string;
  passwordType?: number;
  saveStatus?: number;
  savedAt?: string;
  partnerDate?: string;
  debtCollection?: boolean;
  churn?: boolean;
  partnerCompany?: string;
  accountManager?: string | null | Record<string, any>;
};

export type CustomerWithRequiredFields<T extends keyof CustomerDTO> = Required<
  Pick<CustomerDTO, T>
>;

export type ImmutableCustomerFields = {
  readonly '@id': string;
  readonly ['@type']: string;
  readonly id?: number;
  readonly company?: boolean;
  readonly activeServiceTypes?: ServiceName[];
  readonly activeProtections?: Array<{
    id: number;
    regDate: string;
  }>;
  readonly companyCategory?: boolean;
  readonly notes?: string[] | Partial<NoteItem>[];
  readonly firetexts?: string[] | Partial<FiretextItem>[];
  readonly protections?: string[] | Partial<ProtectionItem>[];
  readonly coProtections?: string[] | Partial<CoProtectionItem>[];
  readonly costs?: string[] | Partial<Cost>[];
  readonly fees?: string[] | Partial<Fee>[];
  readonly keyTags?: string[] | Partial<KeyTagItem>[];
  readonly stickers?: string[] | Partial<StickerItem>[];
  readonly agreements?: string[] | Partial<CustomerAgreement>[];
  readonly savedBy?: string | Partial<UserItem>;
  readonly homegates?: string[] | Partial<HomegateItem>[];
  readonly sinnes?: string[] | Partial<SinneItem>[];
  readonly plainPassword?: string;
  readonly savedData?: string;
  readonly tasks?: string[];
  readonly invoicePartnerResponse?: InvoicePartnerResponse;
};

export type AllCustomerFields = MutableCustomerFields & ImmutableCustomerFields;

type CustomerQueryProperties = Omit<
  {
    notes?: boolean | Partial<AllBooleans<NoteItem>>;
    firetexts?: boolean | Partial<AllBooleans<FiretextItem>>;
    protections?: boolean | Partial<AllBooleans<ProtectionItem>>;
    coProtections?: boolean | Partial<AllBooleans<CoProtectionItem>>;
    costs?: boolean | Partial<AllBooleans<Cost>>;
    fees?: boolean | Partial<AllBooleans<Fee>>;
    keyTags?: boolean | Partial<AllBooleans<KeyTagItem>>;
    stickers?: boolean | Partial<AllBooleans<StickerItem>>;
    agreements?: boolean | Partial<AllBooleans<CustomerAgreement>>;
    homegates?: boolean | Partial<AllBooleans<HomegateItem>>;
    sinnes?: boolean | Partial<AllBooleans<SinneItem>>;
  },
  'doNotDisturb' | 'accountManager'
>;

export type CustomerParams = Pagination & {
  doNotDisturb?: boolean;
  debtCollection?: boolean;
  saveStatus?: number | number[];
  order?: {
    id: boolean;
  };
  search?: string;
  q?: string;
  type?: string;
  accountManager?: string;
  savedAt?: {
    before?: string;
    strictly_before?: string;
    after?: string;
    strictly_after?: string;
  };
  firetexts?: { churnDate: CommonChurnDateParams };
  protections?: { churnDate: CommonChurnDateParams };
  coProtections?: { churnDate: CommonChurnDateParams };
  keyTags?: { churnDate: CommonChurnDateParams };
  stickers?: { churnDate: CommonChurnDateParams };
  homegates?: { churnDate: CommonChurnDateParams };
  sinnes?: { churnDate: CommonChurnDateParams };
  exists?: {
    onboardedAt?: boolean;
    firetext?: {
      churnDate: boolean;
    };
    keyTag?: {
      churnDate: boolean;
    };
    protection?: {
      churnDate: boolean;
    };
    coProtection?: {
      churnDate: boolean;
    };
    sticker?: {
      churnDate: boolean;
    };
    homegate?: {
      churnDate: boolean;
    };
    sinne?: {
      churnDate: boolean;
    };
  };
  properties?: CustomerQueryProperties;
};

type CommonChurnDateParams = {
  before?: string;
  strictly_before?: string;
  after?: string;
  strictly_after?: string;
};

export const customerSearchTypeSchema = z.enum([
  'standard',
  'pin',
  'email',
  'phone',
  'code',
]);

export type CustomerSearchTypes = z.infer<typeof customerSearchTypeSchema>;

export type CustomerSortFields = 'fullName' | 'lastName' | 'firstName' | 'id';

export type CustomerSortOrders = 'asc' | 'desc';

export const basicCustomerDataSchema = z.object({
  category: CustomerTypeEnum,
  companyCategory: z.boolean().optional(),
  companyName: z.string(),
  firstName: z.string().optional(),
  fullName: z.string(),
  id: z.number(),
  lastName: z.string().optional(),
  mobile: z.string(),
  phone: z.string(),
  pin: z.string(),
  accountManager: z
    .object({
      name: z.string().optional(),
    })
    .nullable()
    .optional()
    .or(z.string().nullable().optional()), // The return from PUT is only an IRI string
});

type BasicCustomerFields = keyof z.infer<typeof basicCustomerDataSchema>;
export type BasicCustomerData = Pick<CustomerDTO, BasicCustomerFields>;

export const customerToDeleteDataSchema = z.object({
  activeServiceTypes: z.array(z.string()),
  category: CustomerTypeEnum,
  companyCategory: z.boolean().optional(),
  companyName: z.string(),
  firstName: z.string().optional(),
  fullName: z.string(),
  id: z.number(),
  lastName: z.string().optional(),
  mobile: z.string(),
  phone: z.string(),
  pin: z.string(),
  tasks: z.array(
    z.object({
      id: z.number(),
      type: z.string(),
      status: z.string(),
    }),
  ),
  fees: z.array(
    z.object({
      id: z.number(),
      billedAt: z.string(),
      invoiceAt: z.string(),
    }),
  ),
});
export type CustomerToDeleteData = z.infer<typeof customerToDeleteDataSchema>;

type InvoicePartnerResponse = {
  CustomerId: string;
  ErrorCode: number;
  ErrorText: string;
  Provider: string;
  RawResult: string;
  RequestType: string;
  Title: string;
};
