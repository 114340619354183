import { PageWrapper } from '@/components';
import { Tabs } from '@/components/Tabs/Tabs';
import {
  SalesManagerOnboardingStatistics,
  SalesManagerTeamStatistics,
} from '@/modules/accountManagerSystem/components/SalesManager';
import { Tabmap } from '@/types/tabs';

const SalesManagerStatistics = () => {
  const tabMap: Tabmap[] = [
    {
      title: 'Team',
      view: () => {
        return <SalesManagerTeamStatistics />;
      },
    },
    {
      title: 'Onboarding',
      view: () => <SalesManagerOnboardingStatistics />,
    },
  ];

  return (
    <PageWrapper
      pageTitle="Statistik"
      breadCrumbs={[
        { label: 'Account Manager System' },
        { label: 'Sales Manager' },
        { label: 'Statistik' },
      ]}
    >
      <Tabs tabs={tabMap} />
    </PageWrapper>
  );
};

export default SalesManagerStatistics;
