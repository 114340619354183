import { Helmet } from 'react-helmet';

import { PageHeader } from '@/components/PageHeader';
import { Tabs } from '@/components/Tabs/Tabs';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import {
  Costs,
  CustomLetters,
  LegacyWelcomeLetter,
  Notes,
  SendingsBreadcrumbs,
  Sms,
  WelcomeLetter,
} from '@/modules/sendings/components';
import { Tabmap } from '@/types/tabs';
import { FEATURE_FLAGS } from '@/utils/featureFlags';

const SendingsContainer = () => {
  const customLettersFeatureEnabled = useFeatureFlag({
    name: FEATURE_FLAGS.customLetters.name,
  });

  let tabMap: Tabmap[] = [
    { title: 'Kostnader', view: () => <Costs /> },
    { title: 'Noteringar', view: () => <Notes /> },
    { title: 'SMS', view: () => <Sms /> },
    { title: 'Välkomstbrev', view: () => <WelcomeLetter /> },
    { title: 'Legacy Välkomstbrev', view: () => <LegacyWelcomeLetter /> },
  ];

  if (customLettersFeatureEnabled) {
    tabMap = [
      ...tabMap,
      {
        title: 'Valfria brev',
        view: () => <CustomLetters data-testid="custom-letters-tab" />,
        'data-testid': 'custom-letters-tab',
      },
    ];
  }

  return (
    <>
      <Helmet title="Utskick" />
      <SendingsBreadcrumbs />
      <PageHeader title="Utskick" margin />
      <Tabs tabs={tabMap} />
    </>
  );
};

export default SendingsContainer;
