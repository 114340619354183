import React from 'react';

import { Button, CardActions, CardContent } from '@mui/material';
import { useForm } from 'react-final-form';

import { SimpleCard } from '@/components/SimpleCard';
import { Dropdown, TextInput } from '@/components/form';
import { protectionSecurityTemplateTypeOptions } from '@/constants/FileTypes';
import { ProtectionItem } from '@/types/services';

type ProtectedFileCreationProps = {
  form: any;
  handleSubmit: () => void;
  isLoading: boolean;
  isSuccess: boolean;
  serviceDetails: Record<string, any>;
};

type ProtectedPinType = 'pin1' | 'pin2' | 'pin3' | 'pin4' | 'pin5';
type ProtectedNameType = 'name1' | 'name2' | 'name3' | 'name4' | 'name5';
type ProtectedPhoneType = Pick<
  ProtectionItem,
  'phone1' | 'phone2' | 'phone3' | 'phone4' | 'phone5'
>;
type ProtectedEmailType = Pick<
  ProtectionItem,
  'email1' | 'email2' | 'email3' | 'email4' | 'email5'
>;

const ProtectedFileCreation = ({
  form,
  isLoading,
  handleSubmit,
  serviceDetails,
}: ProtectedFileCreationProps) => {
  const protectedPins = [...Array(5).keys()].reduce((acc, cVal) => {
    const pinIndex = `pin${cVal + 1}` as ProtectedPinType;
    const nameIndex = `name${cVal + 1}` as ProtectedNameType;
    if (serviceDetails[pinIndex] && serviceDetails[nameIndex] === null) {
      acc.push({
        value: serviceDetails[pinIndex]!,
        text: `${serviceDetails[pinIndex]!}`,
      });
    } else if (serviceDetails[pinIndex]) {
      acc.push({
        value: serviceDetails[pinIndex]!,
        text: `${serviceDetails[nameIndex]!} - ${serviceDetails[pinIndex]!}`,
      });
    }

    return acc;
  }, [] as { value: string; text: string }[]);

  const customerData = protectedPins.map((pin, index) => {
    return {
      pin: pin.value,
      name: serviceDetails[`name${index + 1}` as ProtectedNameType],
      phone: serviceDetails[`phone${index + 1}` as keyof ProtectedPhoneType],
      email: serviceDetails[`email${index + 1}` as keyof ProtectedEmailType],
    };
  });

  const { getFieldState } = useForm();
  const selectedPin = getFieldState('protectedPin')?.value;

  const customerPinData = customerData.find((pin) => pin.pin === selectedPin);
  React.useEffect(() => {
    if (selectedPin) {
      form.change('protectedName', customerPinData?.name);
      form.change('protectedPhone', customerPinData?.phone);
      form.change('protectedEmail', customerPinData?.email);
    }
  }, [selectedPin]);

  const formFields = [
    <Dropdown
      options={protectionSecurityTemplateTypeOptions}
      name="templateType"
      label="Typ av fil"
    />,
    <Dropdown
      name="protectedPin"
      label="Personnummer"
      options={protectedPins}
    />,
    <TextInput name="protectedName" label="Namn" />,
    <TextInput name="protectedPhone" label="Telefon" />,
    <TextInput name="protectedEmail" label="Email" />,
    <TextInput name="protectedAddress" label="Adress" />,
    <TextInput name="protectedZip" label="Postnummer" />,
    <TextInput name="protectedCity" label="Stad" />,
  ];
  return (
    <SimpleCard
      title="Bedrägeriassistans"
      cardContent={
        <>
          <form onSubmit={handleSubmit}>
            {formFields.map((field) => (
              <React.Fragment key={field.props.name}>{field}</React.Fragment>
            ))}
            <TextInput name="action" sx={{ display: 'none' }} />
            <CardActions
              sx={{
                p: 0,
                marginTop: 2,
                display: 'flex',
                justifyContent: 'space-between',
                gap: 2,
              }}
            >
              <Button
                type="submit"
                onClick={() => form.change('action', 'download')}
                variant="contained"
                disabled={isLoading}
              >
                Ladda ner
              </Button>

              <span>
                <Button
                  type="submit"
                  onClick={() => form.change('action', 'attach')}
                  variant="contained"
                  disabled={isLoading}
                >
                  Koppla filen till person
                </Button>
              </span>
            </CardActions>
          </form>
        </>
      }
    />
  );
};

export default ProtectedFileCreation;
