import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  LinearProgress,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { Form } from 'react-final-form';
import * as Yup from 'yup';

import { sendBulkSms } from '@/api/sms';
import { InfoMessage } from '@/components/InfoMessage';
import { Spacing } from '@/components/Spacing';
import {
  Checkboxes,
  Dropdown,
  RadioButtons,
  TextArea,
} from '@/components/form';
import { MAX_RECIPIENT_LENGTH, MAX_SMS_LENGTH } from '@/constants/sms';
import { useSnackbar } from '@/hooks';
import { useSendingsState } from '@/hooks/useSendingsState';
import { makeValidation } from '@/utils/validation';

type RecipientTooltipLabelsType = {
  id: string;
  phone: string;
  pin: string;
} & Record<string, string>;

type Values = {
  listType: string;
  isTwoWay: string;
  recipients: string;
  message: string;
  agreement: boolean;
};

//#region Config
const listTypeOptions = [
  { value: 'id', label: 'Kundnummer' },
  { value: 'phone', label: 'Mobilnummer' },
  { value: 'pin', label: 'Person-/Organisationsnummer' },
];

const typeOptions = [
  { value: 'oneWay', text: '1 way SMS' },
  { value: 'twoWay', text: '2 way SMS' },
];

const recipientTooltipLabels: RecipientTooltipLabelsType = {
  id: 'id',
  phone: 'mobilnummer',
  pin: 'person-/organisationsnummer',
};

const validationSchema = Yup.object().shape({
  isTwoWay: Yup.string().required(),
  recipients: Yup.string()
    .required()
    .test(
      'max-recipients',
      `Max antal mottagare är ${MAX_RECIPIENT_LENGTH}`,
      (r) => {
        if (r === undefined) return false;
        return (
          r
            ?.split('\n')
            .map((item) => item.trim())
            .filter((item) => item.length > 0).length <= MAX_RECIPIENT_LENGTH
        );
      },
    ),
  message: Yup.string().required().max(MAX_SMS_LENGTH),
  agreement: Yup.bool().required(),
  listType: Yup.string().required(),
});
//#endregion

export const Sms = () => {
  const { sendingsState, setSendingsState, resetSendingsState } =
    useSendingsState();

  const snackbar = useSnackbar();
  const sendSMSMutation = useMutation({ mutationFn: sendBulkSms });
  const validate = makeValidation(validationSchema);

  async function submitHandler(values: Values) {
    const { message, listType } = values;

    const recipients = values.recipients
      .split('\n')
      .map((item) => item.trim())
      .filter((item) => item.length > 0);

    const isTwoWay = values.isTwoWay === 'twoWay';

    resetSendingsState();
    return sendSMSMutation.mutateAsync(
      {
        recipients,
        message,
        isTwoWay,
        listType,
      },
      {
        onError: () => {
          snackbar({
            type: 'error',
            message: 'Misslyckades att skicka',
          });
        },
        onSuccess: (response) => {
          setSendingsState({
            ...sendingsState,
            active: true,
            type: 'SMS',
            batchUUID: response.id,
            numberOfRecipients: recipients.length,
          });
        },
      },
    );
  }

  return (
    <Card>
      <CardHeader title="SMS-Utskick" />
      <Divider />
      <Form
        initialValues={{
          listType: 'id',
        }}
        validate={validate}
        onSubmit={(values, form) => {
          submitHandler(values);
          form.restart();
        }}
      >
        {({ handleSubmit, values }) => {
          const messageLength = values.message?.length ?? 0;

          const recipientsLength = (values.recipients?.split('\n') ?? [])
            .map((item: string) => item.trim())
            .filter((item: string) => item.length > 0).length;

          return (
            <form onSubmit={handleSubmit}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Dropdown
                      label="Way settings"
                      name="isTwoWay"
                      required
                      options={typeOptions}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <RadioButtons
                      label="Typ av mottagare"
                      name="listType"
                      data={listTypeOptions}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <TextArea
                        sx={{ marginTop: 0 }}
                        label="Mottagare"
                        placeholder="Klistra in mottagare"
                        name="recipients"
                        rows={12}
                        required
                      />
                      <FormHelperText sx={{ textAlign: 'right' }}>
                        {`${recipientsLength} / ${MAX_RECIPIENT_LENGTH}`}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <TextArea
                        sx={{ marginTop: 0 }}
                        label="Meddelande"
                        placeholder="Skriv ditt meddelande här..."
                        name="message"
                        rows={12}
                        required
                      />

                      <FormHelperText sx={{ textAlign: 'right' }}>
                        {`${messageLength} / ${MAX_SMS_LENGTH}`}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <Spacing />
                <InfoMessage>
                  {`Ange ett ${
                    recipientTooltipLabels[values.listType ?? 'id']
                  } per rad`}
                </InfoMessage>
              </CardContent>
              <Divider />
              <CardContent>
                <Checkboxes
                  name="agreement"
                  required
                  options={{
                    label: 'Bekräfta utskick *',
                    value: 'true',
                  }}
                  inputProps={{
                    // @ts-expect-error data-testid is not available in the type definition
                    'data-testid': 'confirm',
                  }}
                />
              </CardContent>
              <Divider />
              <Spacing />
              <CardActions>
                <Button
                  color="primary"
                  variant="outlined"
                  type="submit"
                  disabled={sendSMSMutation.isPending}
                  onClick={handleSubmit}
                >
                  Skicka meddelande
                </Button>
              </CardActions>
              {sendSMSMutation.isPending && <LinearProgress />}
            </form>
          );
        }}
      </Form>
    </Card>
  );
};
