import { SvgIcon, SvgIconProps } from '@mui/material';

export const GiftIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 172 172" width="48px" height="48px">
      <g>
        <path d="M0,172v-172h172v172z" fill="none"></path>
        <g>
          <path d="M68.08333,10.75c-12.79944,0 -23.29167,10.49222 -23.29167,23.29167c0,4.71222 1.77041,8.86332 4.20622,12.54167h-18.53955c-4.88621,0 -8.95833,4.07212 -8.95833,8.95833v25.08333c0,4.1931 3.22803,7.27434 7.16667,8.20947v45.54053c0,12.79944 10.49222,23.29167 23.29167,23.29167h68.08333c12.79944,0 23.29167,-10.49222 23.29167,-23.29167v-45.54053c3.93864,-0.93514 7.16667,-4.01638 7.16667,-8.20947v-25.08333c0,-4.88621 -4.07212,-8.95833 -8.95833,-8.95833h-18.53955c2.43581,-3.67834 4.20622,-7.82945 4.20622,-12.54167c0,-12.79944 -10.49222,-23.29167 -23.29167,-23.29167c-7.34008,0 -13.63777,3.66389 -17.91667,9.02132c-4.2789,-5.35743 -10.57659,-9.02132 -17.91667,-9.02132zM68.08333,21.5c6.98772,0 12.54167,5.55394 12.54167,12.54167v12.54167h-12.54167c-6.98772,0 -12.54167,-5.55394 -12.54167,-12.54167c0,-6.98772 5.55394,-12.54167 12.54167,-12.54167zM103.91667,21.5c6.98772,0 12.54167,5.55394 12.54167,12.54167c0,6.98772 -5.55394,12.54167 -12.54167,12.54167h-12.54167v-12.54167c0,-6.98772 5.55394,-12.54167 12.54167,-12.54167zM32.25,57.33333h35.83333h12.54167v21.5h-48.375zM91.375,57.33333h12.54167h35.83333v21.5h-48.375zM39.41667,89.58333h41.20833v57.33333h-28.66667c-6.98772,0 -12.54167,-5.55394 -12.54167,-12.54167zM91.375,89.58333h41.20833v44.79167c0,6.98772 -5.55394,12.54167 -12.54167,12.54167h-28.66667z"></path>
        </g>
      </g>
    </SvgIcon>
  );
};
