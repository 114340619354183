import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  LinearProgress,
  MenuItem,
} from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { BasicAlert } from '@/components/BasicAlert';
import { queryKeys } from '@/constants/queryKeys';
import { useSnackbar } from '@/hooks';
import { queryClient } from '@/index';
import { Textfield } from '@/modules/components';

import { useCreateReseller } from '../../hooks/useCreateReseller';
import { useGetBdmOptions } from '../../hooks/useGetBdmOptions';
import { validationSchema } from './validationSchema';

type FormValues = z.infer<typeof validationSchema>;

export const CreateReseller = () => {
  const snackbar = useSnackbar();
  const navigation = useNavigate();
  const createReseller = useCreateReseller();
  const { options, status } = useGetBdmOptions();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: zodResolver(validationSchema),
  });

  const onSubmit: SubmitHandler<FormValues> = (values) =>
    createReseller.mutate(values, {
      onSuccess: async () => {
        queryClient.invalidateQueries({
          queryKey: queryKeys.reseller.all,
        });
        snackbar({ type: 'success', message: 'Återförsäljaren skapades' });
        navigation('/reseller-portal/accounts');
      },
      onError: (error) => {
        snackbar({
          type: 'error',
          message: `Kunde inte skapa återförsäljare: ${error?.data?.['hydra:description']}`,
        });
      },
    });

  if (status === 'pending') return <LinearProgress />;
  if (status === 'error') return <BasicAlert />;

  return (
    <Card component="form" onSubmit={handleSubmit(onSubmit)}>
      <CardHeader title="Skapa återförsäljarkonto" />
      <Divider />
      <CardContent>
        <Textfield
          label="Namn"
          error={errors.name}
          fullWidth
          inputProps={{
            ...register('name'),
            'data-testid': 'create-reseller-name',
          }}
        />
        <Textfield
          label="Användarnamn"
          error={errors.username}
          fullWidth
          inputProps={{
            ...register('username'),
            'data-testid': 'create-reseller-username',
          }}
        />
        <Textfield
          label="Email"
          error={errors.email}
          fullWidth
          inputProps={{
            ...register('email'),
            'data-testid': 'create-reseller-email',
          }}
        />
        <Textfield
          label="Business Development Manager"
          error={errors.businessDevelopmentManager}
          select
          fullWidth
          inputProps={{
            ...register('businessDevelopmentManager'),
            defaultValue: '',
            'data-testid': 'create-reseller-businessDevelopmentManager',
          }}
        >
          {options?.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Textfield>
      </CardContent>
      <Divider />
      <CardActions sx={{ p: 2, justifyContent: 'space-between' }}>
        <Button type="submit" variant="contained">
          Spara
        </Button>
      </CardActions>
    </Card>
  );
};
