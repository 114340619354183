import { SvgIcon, SvgIconProps } from '@mui/material';

export const SmsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 96 96" width="96px" height="96px">
      <g id="surface198512316">
        <path d="M 31 10 C 26.410156 10 22.375 12.398438 20.0625 16 L 67 16 C 75.273438 16 82 22.726562 82 31 L 82 63.9375 C 85.601562 61.625 88 57.589844 88 53 L 88 31 C 88 19.421875 78.578125 10 67 10 Z M 21 20 C 13.855469 20 8 25.855469 8 33 L 8 60.925781 C 8 68.070312 13.855469 73.925781 21 73.925781 L 22 73.925781 L 22 82.996094 C 22 86.898438 26.785156 89.335938 29.941406 87.039062 L 47.976562 73.925781 L 65 73.925781 C 72.144531 73.925781 78 68.070312 78 60.925781 L 78 33 C 78 25.855469 72.144531 20 65 20 Z M 21 26 L 65 26 C 68.898438 26 72 29.101562 72 33 L 72 60.925781 C 72 64.824219 68.898438 67.925781 65 67.925781 L 47 67.925781 C 46.367188 67.925781 45.746094 68.125 45.234375 68.496094 L 28 81.03125 L 28 70.925781 C 28 69.269531 26.65625 67.925781 25 67.925781 L 21 67.925781 C 17.101562 67.925781 14 64.824219 14 60.925781 L 14 33 C 14 29.101562 17.101562 26 21 26 Z M 31 43.925781 C 29.34375 43.925781 28 45.269531 28 46.925781 C 28 48.582031 29.34375 49.925781 31 49.925781 C 32.65625 49.925781 34 48.582031 34 46.925781 C 34 45.269531 32.65625 43.925781 31 43.925781 Z M 43 43.925781 C 41.34375 43.925781 40 45.269531 40 46.925781 C 40 48.582031 41.34375 49.925781 43 49.925781 C 44.65625 49.925781 46 48.582031 46 46.925781 C 46 45.269531 44.65625 43.925781 43 43.925781 Z M 55 43.925781 C 53.34375 43.925781 52 45.269531 52 46.925781 C 52 48.582031 53.34375 49.925781 55 49.925781 C 56.65625 49.925781 58 48.582031 58 46.925781 C 58 45.269531 56.65625 43.925781 55 43.925781 Z M 55 43.925781 " />
      </g>
    </SvgIcon>
  );
};
