import { PageWrapper } from '@/components';
import { CreateReseller } from '@/modules/ResellerPortal/components/Forms/CreateReseller';

const CreateResellerAccounts = () => {
  return (
    <PageWrapper
      pageTitle="Återförsäljarkonton"
      breadCrumbs={[
        { label: 'Återförsäljare', url: '/reseller-portal/accounts' },
        { label: 'Konton' },
        { label: 'Skapa' },
      ]}
    >
      <CreateReseller />
    </PageWrapper>
  );
};

export default CreateResellerAccounts;
