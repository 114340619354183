import React from 'react';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link, Breadcrumbs as MuiBreadcrumbs, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const Container = styled('div')`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export type Crumb = {
  label: string;
  url?: string;
};

type Props = {
  crumbs: Crumb[];
};

export const Breadcrumbs = ({ crumbs }: Props) => {
  const navigate = useNavigate();

  const renderCrumb = (crumb: Crumb) => {
    if (!crumb.label) {
      return null;
    }

    if (!crumb.url) {
      return (
        <Typography color="textPrimary" key={crumb.label}>
          {crumb.label}
        </Typography>
      );
    }

    const handleClick =
      (url: string) => (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        navigate(url);
      };

    return (
      <Link
        color="inherit"
        href={crumb.url}
        onClick={handleClick(crumb.url)}
        key={crumb.label}
      >
        {crumb.label}
      </Link>
    );
  };

  const enhancedCrumbs = [{ label: 'Start', url: '/' }, ...crumbs];

  return (
    <Container>
      <MuiBreadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
        {enhancedCrumbs.map(renderCrumb)}
      </MuiBreadcrumbs>
    </Container>
  );
};
