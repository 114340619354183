import React from 'react';

import { Button, Typography } from '@mui/material';

import {
  ChosenFilesList,
  FileUploadInputButton,
} from '@/components/FileUpload';
import { SimpleCard } from '@/components/SimpleCard';
import { Dropdown, TextInput } from '@/components/form';
import { PROTECTED_FILE_TYPES } from '@/constants/ProtectedFiles';

type ProtectedPinType = 'pin1' | 'pin2' | 'pin3' | 'pin4' | 'pin5';
type ProtectedNameType = 'name1' | 'name2' | 'name3' | 'name4' | 'name5';

type ProtectedFileUploadProps = {
  handleSubmit: () => void;
  serviceDetails: Record<string, any>;
  values: Record<string, any>;
  uploadMutation: { isPending: boolean };
};

const Uploads = ({
  serviceDetails,
  values,
  handleSubmit,
  uploadMutation,
}: ProtectedFileUploadProps) => {
  const protectedPins = [...Array(5).keys()].reduce(
    (acc, cVal) => {
      const pinIndex = `pin${cVal + 1}` as ProtectedPinType;
      const nameIndex = `name${cVal + 1}` as ProtectedNameType;
      if (serviceDetails[pinIndex] && serviceDetails[nameIndex] === null) {
        acc.push({
          value: serviceDetails[pinIndex]!,
          text: `${serviceDetails[pinIndex]!}`,
        });
      } else if (serviceDetails[pinIndex]) {
        acc.push({
          value: serviceDetails[pinIndex]!,
          text: `${serviceDetails[nameIndex]!} - ${serviceDetails[pinIndex]!}`,
        });
      }

      return acc;
    },
    [] as { value: string; text: string }[],
  );

  return (
    <SimpleCard
      titleComponent={
        <>
          <Typography variant="h5">Filuppladdning</Typography>
          <Typography variant="body2">
            Ange <strong>personnummer</strong> på den du vill ladda upp en fil
            till.
          </Typography>
        </>
      }
      cardContent={
        <>
          <form onSubmit={handleSubmit}>
            <FileUploadInputButton
              name="file"
              fullWidth
              multiple={false}
              buttonText="Välj fil"
              buttonId="protected-file-upload"
            />
            <ChosenFilesList files={values?.file} title="Vald fil" />
            <Dropdown
              options={PROTECTED_FILE_TYPES}
              name="type"
              label="Filtyp"
              required
            />

            <Dropdown
              options={protectedPins}
              name="protectedPin"
              label="Personnummer"
              required
            />

            <TextInput name="entityType" label="Tjänst" disabled />
            <TextInput name="entityId" label="Tjänste-ID" disabled />
            <Button
              style={{ marginTop: '8px', marginBottom: '16px' }}
              type="submit"
              color="primary"
              variant="contained"
              disabled={uploadMutation.isPending}
            >
              Ladda upp
            </Button>
          </form>
        </>
      }
    ></SimpleCard>
  );
};

export default Uploads;
