import { useMutation, useQueryClient } from '@tanstack/react-query';

import { TASK_STATUSES } from '@/constants';
import { getObjectKeys } from '@/helpers/tsHelpers';
import { TaskItem } from '@/types/tasks';
import httpClient from '@/utils/httpClient';

type Params = {
  taskIri: string;
  queryKey: any[];
};

export const useClaimTask = ({ taskIri, queryKey }: Params) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (userIri: string | null) =>
      httpClient.put<TaskItem>(taskIri, {
        claimedBy: userIri,
        status: getObjectKeys(TASK_STATUSES).find((key) =>
          !!userIri
            ? TASK_STATUSES[key] === 'Bearbetas'
            : TASK_STATUSES[key] === 'Öppen',
        ),
      }),
    onSuccess: ({ data: updatedTask }) => {
      queryClient.setQueryData<TaskItem[]>(queryKey, (taskList) => {
        if (!taskList) {
          return taskList;
        }

        return taskList.map((task) =>
          task['@id'] === taskIri
            ? {
                ...task,
                ...updatedTask,
              }
            : task,
        );
      });

      queryClient.invalidateQueries({
        queryKey,
        refetchType: 'none',
      });
    },
  });
};
