import React from 'react';

import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  useTheme,
} from '@mui/material';

type Column<T> = {
  label: React.ReactNode;
  key: keyof T | string;
  renderer: (row: T) => React.ReactNode;
};

type DataTableProps<T> = {
  columns: Column<T>[];
  data: T[];
  total: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: unknown, newPage: number) => void;
  onRowsPerPageChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  onSort: (key: string) => void;
  sortConfig: { key: string | null; direction: 'asc' | 'desc' | null };
  onRowClick?: (row: T) => void;
};

export const DataTable = <T extends { rowProps?: any }>({
  columns,
  data,
  total,
  page,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange,
  onSort,
  sortConfig,
  onRowClick,
}: DataTableProps<T>) => {
  const theme = useTheme();

  return (
    <Card>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.key as string}>
                  <TableSortLabel
                    active={sortConfig.key === column.key}
                    direction={sortConfig.direction || undefined}
                    onClick={() => onSort(column.key as string)}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, rowIndex) => (
              <TableRow
                key={rowIndex}
                onClick={() => onRowClick && onRowClick(row)}
                hover={!!onRowClick}
                style={{
                  ...row.rowProps?.style,
                  backgroundColor:
                    row.rowProps?.style?.backgroundColor || 'inherit',
                  color: theme.palette.text.primary, // Ensure text color adjusts to theme
                }}
              >
                {columns.map((column) => (
                  <TableCell key={column.key as string}>
                    {column.renderer(row)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={total}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </Card>
  );
};
