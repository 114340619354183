import React from 'react';

import CallMade from '@mui/icons-material/CallMade';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';

import { useModal } from '@/hooks/useModal';
import { ContactAdminIcon, MailIcon, SmsIcon } from '@/icons';
import { USER_TYPES } from '@/modules/common/constants/entitiyNamesMap';
import { ModalTypes } from '@/state/modalManagementAtom';

type Props = {
  customerId: number;
  customerPin?: string;
};

export function ContactButton({ customerId, customerPin }: Props) {
  const { openModal } = useModal();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        startIcon={<CallMade />}
        onClick={handleClick}
      >
        Kontakta
      </Button>
      <StyledMenu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            openModal({
              modalType: ModalTypes.MAIL_CUSTOMER,
              extra: { customerId },
            });

            handleClose();
          }}
        >
          <StyledMailIcon color="primary" />
          Mail
        </MenuItem>
        <MenuItem
          onClick={() => {
            openModal({
              modalType: ModalTypes.SMS_CUSTOMER,
              extra: { customerId },
            });

            handleClose();
          }}
        >
          <StyledSmsIcon color="primary" />
          Sms
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (customerId && customerPin) {
              openModal({
                modalType: ModalTypes.CREATE_ADMIN_TASK,
                extra: { customerId, pin: customerPin },
              });
            }

            handleClose();
          }}
        >
          <StyledContactAdminIcon color="primary" /> Kontakta{' '}
          {USER_TYPES.admin.toLowerCase()}
        </MenuItem>
      </StyledMenu>
    </>
  );
}

const StyledMenu = styled(Menu)`
  margin-top: ${({ theme }) => theme.spacing(6)};
`;

const StyledSmsIcon = styled(SmsIcon)`
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

const StyledMailIcon = styled(MailIcon)`
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

const StyledContactAdminIcon = styled(ContactAdminIcon)`
  margin-right: ${({ theme }) => theme.spacing(1)};
`;
