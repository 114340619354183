import React from 'react';

import { Grid } from '@mui/material';

import { PageHeader } from '@/components/PageHeader';
import { FoundItemCard } from '@/modules/securityService/components/FoundItemCard';
import { SecurityServiceBreadcrumbs } from '@/modules/securityService/components/SecurityServiceBreadcrumbs';
import { SecurityServiceCard } from '@/modules/securityService/components/SecurityServiceCard';

const SecurityService = () => {
  return (
    <>
      <SecurityServiceBreadcrumbs />
      <PageHeader title="Säkerhetsservice" margin />
      <Grid container spacing={3}>
        <SecurityServiceCard />
        <FoundItemCard />
      </Grid>
    </>
  );
};

export default SecurityService;
