import React from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { queryKeys } from '@/constants/queryKeys';
import { useSnackbar } from '@/hooks/useSnackbar';
import { GenericHttpError, HydraViolationError } from '@/types/errors';

export type CampaignErrors = HydraViolationError[] | null;

type Args = {
  campaignToEdit: string | null | undefined;
  error: GenericHttpError | null;
  handleCloseModal: () => void;
  isError: boolean;
  isSuccess: boolean;
  setErrors: (errors: CampaignErrors) => void;
  successMessage?: string;
  useSnackbarHook?: typeof useSnackbar; // Makes mocking the hook easier
};
export const useFormSubmissionHandler = ({
  error,
  isError,
  isSuccess,
  handleCloseModal,
  setErrors,
  campaignToEdit,
  successMessage,
  useSnackbarHook = useSnackbar,
}: Args) => {
  const queryClient = useQueryClient();
  const snackbar = useSnackbarHook();

  const campaignQueryKey = queryKeys.campaigns.all;

  const resetFetchedCampaignCache = React.useCallback(() => {
    return queryClient.invalidateQueries({ queryKey: campaignQueryKey });
  }, [queryClient, campaignQueryKey]);

  // Handle form submission errors
  React.useEffect(() => {
    if (isError && error) {
      setErrors(error.data.violations);
    }
  }, [isError, error, setErrors]);

  // Handle form submission success
  React.useEffect(() => {
    if (isSuccess) {
      snackbar({
        type: 'success',
        message:
          successMessage ||
          `Kampanj ${campaignToEdit ? 'uppdaterad' : 'skapad'}!`,
      });
      if (campaignToEdit) {
        resetFetchedCampaignCache().then(() => {
          handleCloseModal();
        });
      }
      handleCloseModal();
    }
  }, [
    isSuccess,
    successMessage,
    handleCloseModal,
    snackbar,
    campaignToEdit,
    resetFetchedCampaignCache,
  ]);
};
