import { useEffect, useState } from 'react';

import { checkForAdBlocker } from '@/helpers/checkAdBlock';
import { checkRolesAccess } from '@/utils/authorization';

import { useAuth } from './useAuth';

export const useCheckAdBlocker = () => {
  const [isUsingAdblock, setIsUsingAdblock] = useState(false);

  const { authenticatedUser } = useAuth();

  useEffect(() => {
    const handleAdBlockCheck = async () => {
      const usingBlocker =
        process.env.NODE_ENV === 'development'
          ? false
          : await checkForAdBlocker();

      setIsUsingAdblock(usingBlocker);
    };

    if (
      authenticatedUser &&
      checkRolesAccess(authenticatedUser.roles, 'partners.view')
    ) {
      handleAdBlockCheck();
    }
  }, [authenticatedUser]);

  return {
    isUsingAdblock,
  };
};
