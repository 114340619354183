import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { Alert, IconButton, LinearProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import copy from 'copy-to-clipboard';

import { SimpleTable } from '@/components/SimpleTable';
import { useSnackbar } from '@/hooks';
import { GenericHttpError } from '@/types/errors';

import {
  MarketingCheckResponse,
  MarketingCheckType,
} from '../hooks/useAddressWash';

const ClipboardLabel = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
});

const AnswerSpan = styled('span')<{ canContact: boolean }>(
  ({ theme, canContact }) => ({
    color: canContact ? theme.palette.success.dark : theme.palette.error.dark,
  }),
);

const ScrollableDiv = styled('div')({
  overflow: 'auto',

  '& > div': {
    minWidth: '700px',
    padding: 1,
  },
});

type Props = {
  type: MarketingCheckType;
  data?: MarketingCheckResponse[];
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  error?: GenericHttpError;
};

export function AnswerTable({
  type,
  data,
  isLoading,
  isError,
  isSuccess,
  error,
}: Props) {
  const snackbar = useSnackbar();

  const onCopyToClipboard = () => {
    if (!data) {
      return;
    }

    const toPaste = data
      .map((row) => `${row.pin}\t${getAnswerString(row)}`)
      .join('\n');

    copy(toPaste, { format: 'text/plain' });

    snackbar({ type: 'success', message: 'Kopierad till urklipp!' });
  };

  const renderClipBoardLabel = () => {
    return (
      <ClipboardLabel>
        <p>Kopiera till urklipp</p>
        <IconButton
          sx={(theme) => ({
            marginLeft: theme.spacing(1),
          })}
          onClick={onCopyToClipboard}
          size="small"
        >
          <FileCopyOutlinedIcon fontSize="inherit" />
        </IconButton>
      </ClipboardLabel>
    );
  };

  const getAnswerString = (row: MarketingCheckResponse) => {
    // Must have this special case, because exists should show different messages.
    if (type === 'exists') {
      return row.canContact ? 'Kund finns ej' : 'Kund finns redan';
    }
    return row.canContact ? 'Godkänd att kontakta' : 'Kontakta ej';
  };

  const renderAnswer = (row: MarketingCheckResponse) => {
    const str = getAnswerString(row);
    return <AnswerSpan canContact={row.canContact}>{str}</AnswerSpan>;
  };

  const columns = [
    {
      label: 'Person-/Org.nummer',
      renderer: (row: MarketingCheckResponse) => row.pin,
    },
    {
      label: 'Svar',
      renderer: renderAnswer,
    },
    {
      label: renderClipBoardLabel(),
      renderer: () => null,
      align: 'right' as const,
    },
  ];

  if (isLoading) {
    return <LinearProgress />;
  }

  if (isError) {
    return (
      <Alert severity="error">
        {error?.status === 503
          ? 'Denna tjänst har inaktiverats tills vidare'
          : 'Något gick fel'}
      </Alert>
    );
  }

  if (isSuccess && data && data.length > 0) {
    return (
      <ScrollableDiv>
        <div>
          <SimpleTable columns={columns} rows={data} />
        </div>
      </ScrollableDiv>
    );
  }

  return null;
}
