import { FormApi } from 'final-form';
import pick from 'lodash/pick';

export const getChangedFormFields = <Fields>(
  fields: Fields,
  form: FormApi,
  extraFields?: string[],
) => {
  if (!fields || !form) {
    console.error('getChangedFormFields: Missing fields or form');
  }

  const fieldsToPick = Object.keys(form.getState().dirtyFields);

  if (extraFields) {
    extraFields.forEach((field: string) => {
      if (!fieldsToPick.includes(field)) {
        fieldsToPick.push(field);
      }
    });
  }

  const changedFields = pick(fields, fieldsToPick);

  return changedFields;
};

export const identity = (value: unknown) => value;
