import { Card } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { camelCase } from 'lodash';
import { Form as FinalForm } from 'react-final-form';

import { campaignWelcomeLetterUpload } from '@/api/files';
import { queryKeys } from '@/constants/queryKeys';
import { useSnackbar } from '@/hooks';
import { CampaignTypes } from '@/types/campaigns';

import { Form } from './Form';

export type FormValues = {
  entityType: CampaignTypes;
  entityIri: string;
  c5h2Address: boolean;
  file: File[] | null;
};

export const WelcomeLetter = () => {
  const snackBar = useSnackbar();
  const queryClient = useQueryClient();

  const uploadMutation = useMutation({
    mutationFn: (payload) => campaignWelcomeLetterUpload(payload),
    onSuccess: () => {
      snackBar({ type: 'success', message: 'Uppladdning lyckades!' });
      queryClient.invalidateQueries({
        queryKey: [queryKeys.getCampaigns],
      });
    },
    onError: () => {
      snackBar({ type: 'error', message: 'Uppladdning misslyckades!' });
    },
  });

  return (
    <Card>
      <FinalForm
        onSubmit={(values: FormValues, { reset }) => {
          const { entityType, entityIri, ...rest } = values;

          // INFO: Needed check for V2 of protection campaigns. v2 is protectionsCampaigns, but for creating the welcome letter we need to use protectionCampaign
          const convertedType =
            entityType === 'protections_campaigns'
              ? 'protectionCampaign'
              : camelCase(entityType.slice(0, -1));

          const entityId = Number(entityIri.split('/').pop());
          const payload = {
            ...rest,
            entityType: convertedType,
            entityId,
          };

          uploadMutation
            .mutateAsync(payload as any)
            .then(() => reset({ entityType }));
        }}
        render={({ handleSubmit, values, form }) => (
          <>
            <Form
              handleSubmit={handleSubmit}
              values={values}
              submitFn={form.submit}
              loading={uploadMutation.isPending}
            />
          </>
        )}
      />
    </Card>
  );
};
