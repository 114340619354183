import React from 'react';

import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  LinearProgress,
  SxProps,
  TextField,
  Typography,
} from '@mui/material';
import { captureException } from '@sentry/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { getSinneDeviceActiveFaults, newGetSinneDeviceInfo } from '@/api';
import { SimpleTable, SimpleTableColumn } from '@/components/SimpleTable';
import { queryKeys } from '@/constants/queryKeys';
import { config } from '@/helpers/config';
import { getFormattedDate } from '@/helpers/dates';
import { useSnackbar } from '@/hooks';
import usePublicSettings from '@/hooks/usePublicSettings';
import useUser from '@/hooks/useUser';
import { useUpdateSinne } from '@/modules/services/components/Configuration/components/ConfigureSinneModal/useUpdateSinne';
import { DeviceInformation } from '@/modules/services/components/CreateRmaCaseModal/DeviceInformation';
import { SimcardCheck } from '@/modules/services/components/CreateRmaCaseModal/SimcardCheck';
import { GenericHttpError } from '@/types/errors';
import { CreateRmaCasePayload } from '@/types/rma';
import { FlatSinneDeviceInfo, SinneItem } from '@/types/services';
import { sinneStatusKey } from '@/types/settings';
import { ActiveFault } from '@/types/sinne';
import { getCustomerName } from '@/utils/customer';

import { useCreateRmaCase } from './hooks/useCreateRmaCase';

type Props = {
  isOpened: boolean;
  handleClose: () => void;
  sinne: SinneItem;
};

const columns: SimpleTableColumn<ActiveFault>[] = [
  {
    label: 'Felkod',
    renderer: (row) => row.type,
  },
  {
    label: 'Tidpunkt',
    renderer: (row) => getFormattedDate(row.startedOn),
  },
];

export const CreateRmaCaseModal = ({ handleClose, isOpened, sinne }: Props) => {
  const [note, setNote] = React.useState<string>('');
  const trimmedNote = note.trim();
  const { whitelabelId } = config;
  const { publicSettings } = usePublicSettings();
  const whiteLabelName = publicSettings?.companyNames?.standard!;
  const { name: issueCreatorName, id: issueCreatorId } = useUser();
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();

  const endCustomerId = sinne.customer?.id;
  const endCustomerName =
    sinne.customer?.fullName || sinne.customer?.companyName;

  const { id: serviceId, deviceId } = sinne;

  const createRmaCaseMutation = useCreateRmaCase();
  const updateSinneMutation = useUpdateSinne();

  const updateSinne = () => {
    updateSinneMutation.mutate(
      {
        serviceId,
        status: sinneStatusKey.enum['Enhet rapporterad: Sönder'],
      },
      {
        onSuccess() {
          queryClient.refetchQueries({
            queryKey: ['serviceDetails', 'sinne', serviceId.toString()],
          });
          handleClose();
        },
      },
    );
  };

  const {
    data: deviceInfo,
    isFetching: isFetchingDeviceInfo,
    isError: deviceInfoError,
  } = useQuery({
    queryKey: ['deviceInfo', deviceId],
    queryFn: () => newGetSinneDeviceInfo({ macAddress: deviceId! }),
    enabled: !!deviceId,
  });

  const {
    data: activeFaults,
    isRefetching: isFetchingActiveFaults,
    isError: activeFaultsError,
    isSuccess: activeFaultsSuccess,
    refetch: runHealthCheck,
    isInitialLoading: activeFaultsInitialLoading,
  } = useQuery({
    queryKey: queryKeys.devices.healthCheck(deviceId!),
    queryFn: () => getSinneDeviceActiveFaults(deviceId!),
    enabled: Boolean(deviceId),
  });

  const isLoadingActiveFaults =
    isFetchingActiveFaults || activeFaultsInitialLoading;

  const handleCreateRmaCase = () => {
    if (!deviceInfo) return;
    if (!trimmedNote) return;

    const createRmaCasePayload: CreateRmaCasePayload = {
      deviceOwnerId: whitelabelId,
      deviceOwnerName: whiteLabelName,
      endCustomerId,
      endCustomerName,
      initiator: 'CRM',
      issueCreatorId,
      issueCreatorName,
      log: activeFaults || [],
      macAddress: deviceInfo.mac,
      note: trimmedNote,
    };

    createRmaCaseMutation.mutate(createRmaCasePayload, {
      onSuccess() {
        snackbar({
          message: 'RMA ärendet skapades',
          type: 'success',
        });
        updateSinne();
        queryClient.invalidateQueries({
          queryKey: queryKeys.rma.detailById(endCustomerId),
        });
      },
      onError(error) {
        console.error(error);
        captureException(error);
        snackbar({
          message: 'Något gick fel när RMA ärendet skulle skapas',
          type: 'error',
        });
      },
    });
  };

  return (
    <Dialog onClose={handleClose} open={isOpened} maxWidth="md" fullWidth>
      <CardHeader title="Skapa ett RMA ärende" />
      <Divider />
      <DialogContent>
        <Box display="flex" mb={2}>
          <SquareChip label={`Service-ID: ${serviceId}`} sx={{ mx: 1 }} />
          <SquareChip label={`Kund: ${getCustomerName(sinne.customer)}`} />
        </Box>
        <Card>
          <CardHeader
            title="Felmeddelanden"
            action={
              <Button
                variant="contained"
                color="success"
                size="small"
                onClick={() => runHealthCheck()}
              >
                Hämta felloggar
              </Button>
            }
          />
          <Divider />

          <CardContent>
            {isLoadingActiveFaults && <LinearProgress />}
            {activeFaultsError && <Typography>Något gick fel...</Typography>}
            {!activeFaults && !activeFaultsInitialLoading && (
              <Typography>
                Klicka på knappen för att hämta felmeddelanden från enheten.
              </Typography>
            )}
            {activeFaultsSuccess && activeFaults?.length > 0 && (
              <SimpleTable rows={activeFaults} columns={columns} />
            )}
            {activeFaultsSuccess && activeFaults?.length === 0 && (
              <Alert severity="info">Inga felmeddelanden hittades.</Alert>
            )}
            {activeFaults && (
              <TextField
                required
                fullWidth
                multiline
                rows={3}
                label="Notering"
                value={note}
                onChange={(e) => setNote(e.target.value)}
                helperText="Exempel: Kund rapporterade att larmet inte upptäckte kolmonoxid"
              />
            )}
          </CardContent>
        </Card>
      </DialogContent>
      <Divider />
      <DialogActions
        sx={{
          my: 1,
          px: '24px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button onClick={handleClose} size="small">
          Avbryt
        </Button>
        <Button
          color="primary"
          variant="contained"
          size="small"
          disabled={
            !activeFaults || !trimmedNote || createRmaCaseMutation.isPending
          }
          endIcon={
            createRmaCaseMutation.isPending && <CircularProgress size={20} />
          }
          onClick={handleCreateRmaCase}
        >
          Skapa RMA ärende
        </Button>
      </DialogActions>
      <Divider />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <DeviceInformation
              deviceInfo={deviceInfo}
              deviceInfoError={deviceInfoError}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SimcardCheck
              serviceId={serviceId}
              deviceInfo={deviceInfo}
              isFetchingDeviceInfo={isFetchingDeviceInfo}
              deviceInfoError={deviceInfoError}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const SquareChip = ({ label, sx }: { label: string; sx?: SxProps }) => (
  <Chip variant="outlined" sx={{ borderRadius: '4px', ...sx }} label={label} />
);
