import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  LinearProgress,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Form } from 'react-final-form';
import { Link } from 'react-router-dom';
import invariant from 'tiny-invariant';

import { sendFoundItemEmail } from '@/api/sendEmail';
import { NumberedListItem } from '@/components/NumberedListItem';
import { SimpleCard } from '@/components/SimpleCard';
import { TextInput } from '@/components/form';
import { useSnackbar } from '@/hooks';
import { USER_TYPES } from '@/modules/common/constants/entitiyNamesMap';
import { fieldRequired } from '@/utils/validation';

import { queryKeys } from '../../../constants/queryKeys';

const StyledCircleIcon = styled(CheckCircleIcon)(({ theme }) => ({
  color: theme.palette.success.main,
}));

type SubmitValues = {
  name: string;
  phoneNumber: string;
  address: string;
  postalCode: string;
  city: string;
  object: string;
  code: string;
};

type ParamType = {
  code: string;
  type: ProductType;
};
type ProductType = 'sticker' | 'key-tag';

export const FoundObjectForm = ({ code, type }: ParamType) => {
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();

  const foundObjectMutation = useMutation({
    mutationFn: sendFoundItemEmail,
    onSuccess: () => {
      snackbar({
        type: 'success',
        message: `Meddelande skickat till ${USER_TYPES.admin}.`,
      });
      queryClient.refetchQueries({
        queryKey: [queryKeys.getNotes],
      });
    },
    onError: () => {
      snackbar({
        type: 'error',
        message: 'Meddelandet kunde inte skickas.',
      });
    },
  });

  const onSubmit = (values: SubmitValues) => {
    invariant(type, 'Product type is required');
    foundObjectMutation.mutate({ ...values, productType: type });
  };

  const { isPending, isSuccess } = foundObjectMutation;

  return (
    <Form onSubmit={onSubmit} initialValues={{ code }}>
      {({ handleSubmit, pristine }) => (
        <SimpleCard
          title="Upphittat föremål med hittelön"
          cardContent={
            <form onSubmit={handleSubmit}>
              <NumberedListItem
                number={1}
                text={
                  'Berätta att vi har en kund som har en tjänst hos oss där det ingår hittelön till upphittaren vid förlorade värdehandlingar.'
                }
              />

              <NumberedListItem
                number={2}
                text={
                  'Fyll nedstående info och meddela att vi kommer skicka en frankerad påse till personen som hen använder för att skicka in föremålet.'
                }
              />

              <NumberedListItem
                number={3}
                text={
                  'Meddela att när vi fått paketet betalas en hittelön ut, inom 10 arbetsdagar. Även att han/hon skall skicka med sitt bankkontonummer med föremålet.'
                }
                isLast
              >
                <Grid container spacing={2}>
                  <Grid item lg={4} md={6} xs={12}>
                    <TextInput
                      label="För-/Efternamn Upphittare"
                      name="name"
                      fieldProps={{
                        validate: fieldRequired,
                      }}
                    />
                    <TextInput
                      label="Adress"
                      name="address"
                      fieldProps={{
                        validate: fieldRequired,
                      }}
                    />
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextInput
                          label="Postnummer"
                          name="postalCode"
                          fieldProps={{
                            validate: fieldRequired,
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextInput label="Stad" name="city" />
                      </Grid>
                    </Grid>
                    <TextInput
                      label="Telefon"
                      name="phoneNumber"
                      fieldProps={{
                        validate: fieldRequired,
                      }}
                    />
                  </Grid>
                  <Grid item lg={4} md={6} xs={12}>
                    <TextInput
                      label="Upphittat föremål"
                      name="object"
                      fieldProps={{
                        validate: fieldRequired,
                      }}
                    />
                    <TextInput
                      label="Seriekod"
                      name="code"
                      disabled
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <StyledCircleIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </NumberedListItem>

              {isPending && <LinearProgress />}

              <Box pt={1} pb={4}>
                <Button
                  color="primary"
                  type="submit"
                  disabled={pristine || isPending || isSuccess}
                  variant="contained"
                >
                  Slutför och skicka
                </Button>
              </Box>

              {isSuccess && (
                <>
                  <Box>
                    <Link to="/security-service">
                      <Button color="primary" variant="contained">
                        Avsluta
                      </Button>
                    </Link>
                  </Box>
                </>
              )}
            </form>
          }
        />
      )}
    </Form>
  );
};
