import { Typography } from '@mui/material';

import { DirectDebitFields } from '@/components/DirectDebitFields';

export function AutoGiroColumn() {
  return (
    <>
      <Typography variant="h5">Autogiro</Typography>
      <DirectDebitFields />
    </>
  );
}
