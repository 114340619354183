import React from 'react';

import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Field, FieldProps } from 'react-final-form';

import { getCampaigns } from '@/api';
import { BasicAlert } from '@/components/BasicAlert';
import { queryKeys } from '@/constants/queryKeys';
import { ServiceWithCampaigns } from '@/types/services';
import { isValidOption } from '@/utils/validation';

import { prepareCampaignOptions } from './utils/prepareCampaignOptions';

type Props = {
  customerType?: number;
  defaultValue?: any;
  fieldProps?: Partial<FieldProps<any, any>>;
  label?: string;
  name?: string;
  service: ServiceWithCampaigns;
  required?: boolean;
};

export const CampaignAutoComplete = ({
  service,
  customerType,
  name = 'campaign',
  label = 'Kampanj',
  fieldProps,
  required = true,
}: Props) => {
  const { data, isFetching, isError } = useQuery({
    queryKey: [queryKeys.getCampaigns, customerType, service],
    queryFn: async () => {
      const data = await getCampaigns(
        service === 'protection' // INFO: Needed check for V2 of protection campaigns
          ? `${service}s_campaigns`
          : `${service}_campaigns`,
        {
          active: true,
          pagination: false,
          customerType,
          properties: {
            name: true,
            id: true,
            active: true,
          },
        },
      );
      return { options: prepareCampaignOptions(data), data };
    },
    initialData: { options: [], data: [] },
    enabled: !!service,
  });

  if (isError) return <BasicAlert />;
  const getOptionLabel = (option: string) => {
    if (data.data.length) {
      const campaign = data.data.find((campaign) => campaign['@id'] === option);
      return campaign?.name ? `${campaign?.id} - ${campaign?.name}` : '';
    }
    return '';
  };

  return (
    <Field
      name={name}
      {...fieldProps}
      validate={isValidOption(data.options, isFetching)}
    >
      {({ input, meta }) => (
        <Autocomplete
          onChange={(_, value) => input.onChange(value)}
          value={input.value || null}
          disabled={isFetching}
          options={data.options}
          loading={isFetching}
          getOptionLabel={getOptionLabel}
          renderInput={({ inputProps, InputProps, ...rest }) => (
            <TextField
              error={meta.invalid}
              helperText={meta.error}
              label={isFetching ? 'Laddar' : label}
              required={required}
              inputProps={{
                'data-testid': name,
                ...inputProps,
              }}
              FormHelperTextProps={{
                // @ts-expect-error - data-testid is not a valid prop
                'data-testid': 'campaignHelperText',
              }}
              {...rest}
              InputProps={{
                ...InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isFetching ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      )}
    </Field>
  );
};
