import { Box, DialogContent, LinearProgress, Typography } from '@mui/material';

import { CustomerDTO } from '@/types/customers';

import { PartialKeyTagItem } from './KeyTagConfigModal';

type Props = {
  customer: Partial<CustomerDTO>;
  configuredKeyTags: PartialKeyTagItem[];
  requestInProgress: boolean;
  customerIdCheckComponent: React.ReactNode;
};

export const KeyTagConfigStep3 = ({
  customer,
  configuredKeyTags,
  requestInProgress,
  customerIdCheckComponent,
}: Props) => {
  return (
    <>
      <DialogContent>
        <Typography gutterBottom variant="h5">
          Steg 3: Inlagd!
        </Typography>

        <Box marginTop={2}>
          <Typography gutterBottom>
            Nyckelbrickans serienummer:{' '}
            {configuredKeyTags.map((keyTag, index) => (
              <span key={keyTag.id}>
                {keyTag.code}
                {index < configuredKeyTags.length - 1 && ','}
              </span>
            ))}
          </Typography>
          <Typography>
            {customer?.company ? customer?.companyName : customer?.fullName}
          </Typography>
        </Box>

        {customerIdCheckComponent}
      </DialogContent>

      {requestInProgress && <LinearProgress />}
    </>
  );
};
