import {
  Alert,
  Autocomplete,
  CircularProgress,
  FormControl,
  TextField,
} from '@mui/material';
import { Field } from 'react-final-form';

import { useGetBDMs } from '@/modules/ResellerPortal/hooks/useGetBDMs';
import { isValidOption } from '@/utils/validation';

import { preparePartnerManagerOptions } from './utils/preparePartnerManagerOptions';

type Props = {
  disabled?: boolean;
};

// For Sinne service, the partnerManager field is an iri to a user that has role business development manager
// https://monitum.atlassian.net/browse/MDE-12

export const PartnerManagerAutocomplete = ({ disabled = false }: Props) => {
  const { data: bdms, isFetching, isError } = useGetBDMs();

  const options = preparePartnerManagerOptions(bdms);

  const getOptionLabel = (option: string) => {
    if (bdms?.length) {
      const bdm = bdms.find((bdm) => bdm['@id'] === option);
      return bdm?.name ? `${bdm?.id} - ${bdm?.name}` : '';
    }
    return 'Ingen';
  };

  if (isError)
    return (
      <FormControl fullWidth>
        <Alert severity="error">Något gick fel</Alert>
      </FormControl>
    );

  return (
    <Field name="partnerManager" validate={isValidOption(options, isFetching)}>
      {({ input, meta }) => (
        <Autocomplete
          onChange={(_, value) => input.onChange(value)}
          value={input.value.toString() || null}
          options={options}
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={(option, value) =>
            option.toString() === value.toString()
          }
          disabled={disabled || isFetching}
          loading={isFetching}
          renderInput={({ inputProps, InputProps, ...rest }) => (
            <TextField
              label="Partner Manager"
              error={meta.invalid}
              helperText={meta.error}
              FormHelperTextProps={{
                // @ts-expect-error - data-testid is not a valid prop
                'data-testid': 'partnerManagerHelperText',
              }}
              inputProps={{
                'data-testid': 'partnerManager',
                ...inputProps,
              }}
              InputProps={{
                ...InputProps,
                endAdornment: (
                  <>
                    {isFetching ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {InputProps.endAdornment}
                  </>
                ),
              }}
              {...rest}
            />
          )}
        />
      )}
    </Field>
  );
};
