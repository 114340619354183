import { useParams } from 'react-router-dom';

import { PageWrapper } from '@/components';
import { getCamelCaseServiceName } from '@/helpers/tsHelpers';
import { usePrivateSettings } from '@/hooks';
import { KeyTagBulkRegistration } from '@/modules/services/components/KeyTag/components';
import { Crumb } from '@/types/crumbs';
import {
  CamelCaseServiceName,
  ServiceName,
  ServiceNamesEnum,
} from '@/types/services';

type Params = { serviceName: ServiceName };

const getBulkRegistrationComponent = (serviceName: CamelCaseServiceName) => {
  const BULK_REGISTRATION_COMPONENTS: {
    [key in CamelCaseServiceName]?: React.ReactNode;
  } = {
    keyTag: <KeyTagBulkRegistration />,
  };

  return BULK_REGISTRATION_COMPONENTS[serviceName] || null;
};

export const BulkRegistrationPage = () => {
  const { serviceName } = useParams<Params>();
  const { servicesNames } = usePrivateSettings();

  const result = ServiceNamesEnum.safeParse(serviceName);

  if (!result.success) {
    window.location.href = '/not-found';
  }

  const camelCaseServiceName = getCamelCaseServiceName(serviceName!);
  const whiteLabelServiceName = servicesNames[camelCaseServiceName];

  const breadCrumbs: Crumb[] = [
    { label: 'Tjänster' },
    { label: whiteLabelServiceName || '', url: `/products/${serviceName}` },
    { label: 'Bulkregistrering' },
  ];

  return (
    <PageWrapper
      pageTitle={`Bulkregistrering - ${whiteLabelServiceName}`}
      breadCrumbs={breadCrumbs}
      maxWidth="md"
    >
      {getBulkRegistrationComponent(camelCaseServiceName)}
    </PageWrapper>
  );
};
