import { useMemo, useState } from 'react';

import { LinearProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import camelCase from 'lodash/camelCase';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';

import * as ServicesApi from '@/api/services';
import { Breadcrumbs } from '@/components/Breadcrumbs';
import { PageWithDrawer } from '@/components/PageWithDrawer';
import { Tabs } from '@/components/Tabs/Tabs';
import { queryKeys } from '@/constants/queryKeys';
import { usePrivateSettings, useSnackbar } from '@/hooks';
import { CommunicationDrawer } from '@/modules/common/components';
import { AgreementsModal } from '@/modules/services';
import { ServiceEditNavigation } from '@/modules/services/components';
import { useFetchServiceDetails } from '@/modules/services/hooks/useFetchServiceDetails';
import {
  CamelCaseServiceName,
  ServiceParams,
  ServicesWithAgreements,
} from '@/types/services';
import { KebabCaseServiceNames } from '@/types/settings';
import { getCatchErrorMessage } from '@/utils/common';
import { getCustomerName } from '@/utils/customer';

import { prepareServiceUpdatePayload } from '../../utils/prepareServiceUpdatePayload';
import { ServiceTransferModal } from './ServiceTransferModal';
import { SERVICE_EDIT_TABS } from './constants';

type UrlParams = {
  serviceName: KebabCaseServiceNames;
  serviceId: string;
};

export const Edit = () => {
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();
  const [updateErrorMessage, setUpdateErrorMessage] = useState<null | string>(
    null,
  );
  const [agreementsModalOpen, setAgreementsModalOpen] = useState(false);

  const { serviceId, serviceName } = useParams<UrlParams>();
  invariant(serviceId, 'Service ID must be present in url params');
  invariant(serviceName, 'Service name must be present in url params');

  const camelCasedServiceName = camelCase(serviceName) as CamelCaseServiceName;

  const [serviceTransferModalVisible, setServiceTransferModalVisible] =
    useState(false);

  const { servicesNames } = usePrivateSettings();

  const mappedServiceName = servicesNames?.[camelCasedServiceName];

  //#region Queries

  const {
    data: serviceDetails,
    isLoading: serviceDetailsLoading,
    isError: serviceDetailsError,
  } = useFetchServiceDetails({
    serviceName,
    serviceId,
  });

  const customerName = useMemo(() => {
    const customer = serviceDetails?.customer;

    if (!customer) {
      return '';
    }

    return getCustomerName(customer);
  }, [serviceDetails?.customer]);
  const customerId = serviceDetails?.customer?.id;

  const updateServiceMutation = useMutation({
    mutationFn: ({
      serviceName,
      serviceId,
      values,
    }: {
      serviceName: string;
      serviceId: string;
      values: Record<string, any>;
    }) =>
      ServicesApi.updateService({
        serviceName: camelCase(serviceName) as CamelCaseServiceName,
        serviceId: parseInt(serviceId, 10),
        values,
      }),
  });

  //#endregion

  function handleServiceUpdate(values: Record<string, any>) {
    const updatedServicePayload = prepareServiceUpdatePayload(
      values,
      serviceName!,
    );

    if (!serviceDetails) return;

    updateServiceMutation.mutate(
      {
        serviceName: serviceName!,
        serviceId: serviceDetails.id.toString(),
        values: updatedServicePayload,
      },
      {
        onSuccess: (response, data) => {
          setUpdateErrorMessage(null);
          if (serviceName === 'homegate' || serviceName === 'sinne') {
            queryClient.setQueryData(
              ['serviceDetails', serviceName, serviceId],
              response,
            );
          } else if (serviceName === 'protection') {
            queryClient.invalidateQueries({
              queryKey: [
                queryKeys.getAllCampaigns,
                data?.values?.protectionsCampaign,
              ],
            });
          } else {
            queryClient.invalidateQueries({
              queryKey: ['serviceDetails', serviceName, serviceId],
            });
          }

          snackbar({
            type: 'success',
            message: 'Tjänsten uppdaterad',
          });
        },
        onError: (error) => {
          setUpdateErrorMessage(getCatchErrorMessage(error));

          snackbar({
            type: 'error',
            message: 'Tjänstuppdatering misslyckades',
          });
        },
      },
    );
  }

  const renderAgreementsModal = ({
    open,
    serviceDetails,
    onClose,
  }: {
    open: boolean;
    serviceDetails: ServiceParams;
    onClose: (arg: false) => void;
  }) => {
    const { agreements } = serviceDetails as ServicesWithAgreements;

    return (
      <AgreementsModal
        onClose={() => onClose(false)}
        open={open}
        agreements={agreements}
      />
    );
  };

  if (serviceDetailsLoading) {
    return <LinearProgress />;
  }

  if (serviceDetailsError || !customerId) {
    return (
      <Typography variant="h3" component="h1" color="error" align="center">
        Det gick inte att läsa in servicedata
      </Typography>
    );
  }

  return (
    <>
      <Helmet
        title={`${customerId} - ${customerName}  | Redigera ${mappedServiceName}`}
      />
      <PageWithDrawer
        pageContent={
          <Box sx={{ maxWidth: 'lg' }}>
            <Breadcrumbs
              crumbs={[
                {
                  label: customerName ?? '',
                  url: `/customer/${customerId}`,
                },
                {
                  label: 'Tjänster',
                  url: `/customer/${customerId}?tab=services`,
                },
                { label: mappedServiceName },
              ]}
            />

            <Typography
              variant="h3"
              component="h1"
              data-testid="service-details-title"
            >
              Redigera {mappedServiceName}
            </Typography>

            <ServiceEditNavigation
              serviceId={serviceId}
              serviceName={serviceName}
              customer={serviceDetails.customer}
              onOpenServiceTransfer={() => setServiceTransferModalVisible(true)}
              onOpenAgreementsModal={() => setAgreementsModalOpen(true)}
            />

            {updateServiceMutation.isPending && <LinearProgress />}

            <Tabs
              tabs={
                SERVICE_EDIT_TABS[serviceName as keyof typeof SERVICE_EDIT_TABS]
              }
              viewProps={{
                serviceName,
                serviceDetails,
                isLoading: updateServiceMutation.isPending,
                onSubmit: handleServiceUpdate,
              }}
            />

            {updateServiceMutation.isError && (
              <Typography variant="h4" color="error" align="center">
                {updateErrorMessage}
              </Typography>
            )}
          </Box>
        }
        drawerContent={
          <CommunicationDrawer
            customerId={customerId}
            isInSecurityService={false}
          />
        }
      />
      {serviceDetails && serviceName && serviceTransferModalVisible && (
        <ServiceTransferModal
          opened={serviceTransferModalVisible}
          serviceName={camelCasedServiceName}
          service={serviceDetails}
          onClose={() => setServiceTransferModalVisible(false)}
          onTransferSuccess={() => {
            queryClient.invalidateQueries({
              queryKey: ['serviceDetails', serviceName, serviceId],
            });
          }}
        />
      )}
      {/* TODO:  Data filter for agreements not working on Homegate (only getting @id and @type)  */}
      {agreementsModalOpen &&
        renderAgreementsModal({
          open: agreementsModalOpen,
          serviceDetails,
          onClose: setAgreementsModalOpen,
        })}
    </>
  );
};
