import { CreateRmaCasePayload, GetRmaCaseResponse } from '@/types/rma';
import httpClient from '@/utils/httpClient';

export async function createRmaCase(params: CreateRmaCasePayload) {
  return await httpClient.post(
    `/noclip/sinne/${params.macAddress}/initiate-rma-case`,
    params,
  );
}

export async function getRmaCaseByCustomerId(customerId: string | number) {
  const response = await httpClient.get<GetRmaCaseResponse>(
    `/noclip/rma/${customerId}`,
  );

  return response.data;
}

export async function listRmaCases(search?: string) {
  const response = await httpClient.get(`/noclip/rma`, {
    params: { ...(search ? { search } : {}) },
  });

  return response.data;
}
