import React from 'react';

import { Clear } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Form } from 'react-final-form';

import { PinInput } from '@/components/form';
import { AutoSave } from '@/components/form/AutoSave/AutoSave';
import { SearchIcon } from '@/icons';

type Props = {
  onChange: (pin: string) => void;
  value: string;
};

export const DebouncedPinSearchField = ({
  onChange,
  value,
  ...rest
}: Props) => {
  const searchHandler = ({ pin }: { pin: string }) => {
    onChange(pin);
  };

  return (
    <Form
      onSubmit={searchHandler}
      initialValues={{ pin: '' }}
      render={({ form }) => (
        <>
          <AutoSave
            debounce={500}
            save={searchHandler}
            debounced={['protectedPin']}
          />
          <PinInput
            label="Sök personnummer"
            required={false}
            value={value}
            InputProps={{
              startAdornment: <SearchIcon />,
              endAdornment: (
                <IconButton
                  onClick={() => form.reset()}
                  color="primary"
                  size="large"
                >
                  <Clear />
                </IconButton>
              ),
            }}
            {...rest}
          />
        </>
      )}
    />
  );
};
