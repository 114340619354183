import {
  CoProtectionEdit,
  CoProtectionEditProps,
  FiretextEdit,
  FiretextEditProps,
  HomegateEdit,
  HomegateEditProps,
  KeyTagEdit,
  KeyTagEditProps,
  ProtectionEdit,
  ProtectionEditProps,
  SinneDeviceDetails,
  SinneEdit,
  SinneEditProps,
  StickerEdit,
  StickerEditProps,
} from '@/modules/services';
import { ServiceParams } from '@/types/services';

import { Documents } from './Documents/Documents';

type Props = {
  serviceDetails: ServiceParams;
  isLoading: boolean;
  onSubmit: (values: any) => void;
  serviceCustomerDetails: Record<string, any>;
};

export const SERVICE_EDIT_TABS = {
  firetext: [
    {
      title: 'Översikt',
      view: ({ isLoading, serviceDetails, onSubmit }: FiretextEditProps) => (
        <FiretextEdit
          serviceDetails={serviceDetails}
          isLoading={isLoading}
          onSubmit={onSubmit}
        />
      ),
    },
    {
      title: 'Dokument',
      view: ({ serviceDetails, serviceCustomerDetails }: Props) => (
        <Documents
          serviceDetails={serviceDetails}
          customer={serviceCustomerDetails}
        />
      ),
    },
  ],
  'key-tag': [
    {
      title: 'Översikt',
      view: ({ isLoading, serviceDetails, onSubmit }: KeyTagEditProps) => (
        <KeyTagEdit
          serviceDetails={serviceDetails}
          isLoading={isLoading}
          onSubmit={onSubmit}
        />
      ),
    },
  ],
  protection: [
    {
      title: 'Översikt',
      view: ({ isLoading, serviceDetails, onSubmit }: ProtectionEditProps) => (
        <ProtectionEdit
          serviceDetails={serviceDetails}
          isLoading={isLoading}
          onSubmit={onSubmit}
        />
      ),
    },
    {
      title: 'Dokument',
      view: ({ serviceDetails, serviceCustomerDetails }: Props) => (
        <Documents
          serviceDetails={serviceDetails}
          customer={serviceCustomerDetails}
        />
      ),
    },
  ],
  'co-protection': [
    {
      title: 'Översikt',
      view: ({
        isLoading,
        serviceDetails,
        onSubmit,
      }: CoProtectionEditProps) => (
        <CoProtectionEdit
          serviceDetails={serviceDetails}
          isLoading={isLoading}
          onSubmit={onSubmit}
        />
      ),
    },
    {
      title: 'Dokument',
      view: ({ serviceDetails, serviceCustomerDetails }: Props) => (
        <Documents
          serviceDetails={serviceDetails}
          customer={serviceCustomerDetails}
        />
      ),
    },
  ],
  sticker: [
    {
      title: 'Översikt',
      view: ({ isLoading, serviceDetails, onSubmit }: StickerEditProps) => (
        <StickerEdit
          serviceDetails={serviceDetails}
          isLoading={isLoading}
          onSubmit={onSubmit}
        />
      ),
    },
  ],
  homegate: [
    {
      title: 'Översikt',
      view: ({ isLoading, serviceDetails, onSubmit }: HomegateEditProps) => (
        <HomegateEdit
          serviceDetails={serviceDetails}
          isLoading={isLoading}
          onSubmit={onSubmit}
        />
      ),
    },
    {
      title: 'Dokument',
      view: ({ serviceDetails, serviceCustomerDetails }: Props) => (
        <Documents
          serviceDetails={serviceDetails}
          customer={serviceCustomerDetails}
        />
      ),
    },
  ],
  sinne: [
    {
      title: 'Översikt',
      view: ({ serviceDetails, isLoading, onSubmit }: SinneEditProps) => (
        <SinneEdit
          serviceDetails={serviceDetails}
          isLoading={isLoading}
          onSubmit={onSubmit}
        />
      ),
    },
    {
      title: 'Dokument',
      view: ({ serviceDetails, serviceCustomerDetails }: Props) => (
        <Documents
          serviceDetails={serviceDetails}
          customer={serviceCustomerDetails}
        />
      ),
    },

    {
      title: 'Enhetsinformation',
      view: () => <SinneDeviceDetails />,
    },
  ],
};
