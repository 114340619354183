import { ButtonProps, Button as MuiButton, styled } from '@mui/material';

const Button = styled(MuiButton)(({ theme }) => ({
  // color: theme.palette.text.secondary,
  fontWeight: theme.typography.fontWeightMedium,
  justifyContent: 'space-between',
  letterSpacing: 0,
  padding: theme.spacing(1),
  textTransform: 'none',
  width: '100%',
}));

export const MenuListItemButton = ({ children, ...rest }: ButtonProps) => {
  return (
    <Button className="listItemBtn" {...rest}>
      {children}
    </Button>
  );
};
