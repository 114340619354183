import React, { useCallback, useState } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import debounce from 'lodash/debounce';

/**
 *
 * @param setDebouncedQueryString. Set the query string you need in the request
 * @returns Search field component that debounce the setDebouncedQueryString function passed in
 *
 */

type Props = {
  setDebouncedQueryString: (q: string) => void;
  placeholder?: string;
  helperText?: string;
};
export const SearchField = ({
  setDebouncedQueryString,
  placeholder = 'Sök kund',
  helperText = '',
}: Props) => {
  const [queryString, setQueryString] = useState('');

  const debouncedSearchHandler = useCallback(
    debounce(
      (q) => {
        setDebouncedQueryString(q);
      },
      500,
      { maxWait: 750 },
    ),
    [queryString],
  );

  // set search term to both input value and query function
  const onSearchHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setQueryString(value.trim());
    debouncedSearchHandler(value.trim());
  };

  const clearSearchHandler = () => {
    setQueryString('');
    debouncedSearchHandler('');
  };

  return (
    <TextField
      margin="dense"
      onChange={onSearchHandler}
      sx={{
        width: '100%',
      }}
      value={queryString}
      autoComplete="off"
      variant="outlined"
      placeholder={placeholder}
      helperText={helperText}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="clear search"
              size="small"
              onClick={clearSearchHandler}
            >
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
