import isObject from 'lodash/isObject';
import mapValues from 'lodash/mapValues';

import { hasOwnProperty } from '@/utils/typeHelpers';

type Values = Record<string, unknown>;

export function mapInitialObjectValuesToStrings(values: Values) {
  return mapValues(values, (value, key) => {
    if (key === 'reseller' && isObject(value) && hasOwnProperty(value, 'id')) {
      return `/resellers/${value.id}`;
    }

    if (
      key === 'protectionsCampaign' &&
      isObject(value) &&
      hasOwnProperty(value, '@id')
    ) {
      return value['@id'];
    }

    return value;
  });
}
