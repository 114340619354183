import React from 'react';

import { Edit } from '@mui/icons-material';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  LinearProgress,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { z } from 'zod';
import zodToJsonSchema from 'zod-to-json-schema';

import { GetPartnerParams } from '@/api/partners';
import { BasicAlert } from '@/components/BasicAlert';
import { Pagination } from '@/components/Pagination';
import { SimpleTable, SimpleTableColumn } from '@/components/SimpleTable';
import { PER_PAGE } from '@/constants/pagination';
import { createApiPropertiesFromJsonSchema } from '@/helpers/createApiPropertiesFromJsonSchema';
import { useGetPartnerCompanies } from '@/modules/PartnerPortal/hooks/useGetPartnerCompanies';

const fieldsToFetch = z.object({
  id: z.number(),
  name: z.string(),
});

type PartnerListData = z.infer<typeof fieldsToFetch>;

const columns: SimpleTableColumn[] = [
  {
    label: 'ID',
    renderer: (row: PartnerListData) => row.id,
  },
  {
    label: 'Namn',
    renderer: (row: PartnerListData) => row.name,
  },
  {
    label: 'Redigera',
    renderer: (row: PartnerListData) => (
      <IconButton component={Link} to={`/partner/accounts/${row.id}/edit`}>
        <Edit />
      </IconButton>
    ),
    align: 'right',
  },
];

export const PartnerList = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(PER_PAGE);

  const properties = createApiPropertiesFromJsonSchema(
    zodToJsonSchema(fieldsToFetch),
  ) as GetPartnerParams['properties'];

  const { data, status } = useGetPartnerCompanies({
    properties,
    pagination: true,
    page,
    perPage: rowsPerPage,
  });

  const rows = data?.['hydra:member'] ?? [];
  const totalItems = data?.['hydra:totalItems'] ?? 0;

  return (
    <Card>
      <CardHeader
        title="Alla partners"
        action={
          <Link to="/partner/accounts/create">
            <Button variant="contained">Skapa</Button>
          </Link>
        }
      />
      <Divider />
      <CardContent>
        {status === 'pending' && <LinearProgress />}
        {status === 'error' && <BasicAlert />}
        {status === 'success' && (
          <>
            <SimpleTable columns={columns} rows={rows} />
            <Pagination
              page={page}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={(event) =>
                setRowsPerPage(parseInt(event.target.value))
              }
              count={totalItems}
              onPageChange={(_, page) => setPage(page)}
            />
          </>
        )}
      </CardContent>
    </Card>
  );
};
