import { Card } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { ExistingCustomer } from '@/types/customers';
import { FEATURE_FLAGS } from '@/utils/featureFlags';

import { GenerateCustomLetterTemplate } from './GenerateCustomLetterTemplate';
import { GenerateLetter } from './GenerateLetter';

type Props = {
  customer: ExistingCustomer;
};

export const GenerateLetterTab = ({ customer }: Props) => {
  const customLettersFeatureEnabled = useFeatureFlag({
    name: FEATURE_FLAGS.customLetters.name,
  });

  return (
    <>
      <StyledCard>
        <GenerateLetter customer={customer} />
      </StyledCard>
      {customLettersFeatureEnabled && (
        <StyledCard data-testid="generate-custom-letter-template-card">
          <GenerateCustomLetterTemplate customer={customer} />
        </StyledCard>
      )}
    </>
  );
};

const StyledCard = styled(Card)(({ theme }) => ({
  margin: theme.spacing(4, 0, 0, 0),
}));
