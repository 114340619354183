import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material';
import upperFirst from 'lodash/upperFirst';

import { BasicAlert } from '@/components/BasicAlert';
import { useFetchTeams } from '@/modules/accountManagerSystem/hooks/useFetchTeams';

import { Manager } from '../Manager';
import { TeamTable } from './TeamTable';

export const TeamList = () => {
  const {
    data: teams,
    isLoading: loadingTeams,
    isError: errorTeams,
  } = useFetchTeams();

  return loadingTeams ? (
    <LinearProgress />
  ) : errorTeams ? (
    <BasicAlert />
  ) : (
    <Grid container spacing={2}>
      {teams?.map((team) => (
        <Grid item xs={12} key={team['@id']}>
          <Card>
            <CardHeader
              title={
                <Typography
                  variant="h5"
                  style={{ flexBasis: '33.33%', flexShrink: 0 }}
                >
                  Team {upperFirst(team.name)}
                </Typography>
              }
              subheader={<Manager managerIri={team.manager} />}
            />
            <Divider />
            <CardContent>
              <TeamTable members={team.members} />
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};
