import { QueryKey, UseQueryOptions, useQuery } from '@tanstack/react-query';

import { getMissingSinneDevices } from '@/api/sinne';
import { queryKeys } from '@/constants/queryKeys';
import { PaginatedPageDetails } from '@/types/common';
import { GenericHttpError } from '@/types/errors';
import { MissingDevicesResponse } from '@/types/sinne';

export const useGetMissingDevices = (
  props: PaginatedPageDetails,
  queryOptions?: UseQueryOptions<
    MissingDevicesResponse,
    GenericHttpError,
    MissingDevicesResponse,
    QueryKey
  >,
) => {
  return useQuery<
    MissingDevicesResponse,
    GenericHttpError,
    MissingDevicesResponse,
    QueryKey
  >({
    queryKey: queryKeys.devices.missingDevices(props),
    queryFn: async () => getMissingSinneDevices({ ...props, pagination: true }),
    ...queryOptions,
  });
};
