import { z } from 'zod';

import { userRolesEnum } from './auth';
import { ApiCollectionResponse } from './common';

export const userSchema = z.object({
  '@id': z.string(),
  '@type': z.string(),
  id: z.number(),
  name: z.string(),
  email: z.string().email(),
  username: z.string(),
  level: z.number().optional(),
  startPage: z.string().optional(),
  roles: z.array(userRolesEnum),
  tasks: z.array(z.string()).optional(),
  savedCustomers: z.array(z.string()).optional(),
  notifications: z.array(z.string()).optional(),
  coProtectionOnboardingPercentage: z.string().optional(),
  firetextOnboardingPercentage: z.string().optional(),
  avatarUrl: z.string(),
  taggable: z.boolean(),
  pin: z.string().nullable(),
  deleted: z.boolean(),
});

export type UserItem = z.infer<typeof userSchema>;

export type UpdateUserItem = Partial<UserItem> & {
  plainPassword?: string;
  taggable?: boolean;
};

export type AccountManager = UserItem & {
  managedCustomers: Array<{
    '@id': string;
    '@type': string;
    id: number;
  }>;
};

export type UsersResponse = ApiCollectionResponse<Partial<UserItem>>;

export type AccountManagersResponse = ApiCollectionResponse<AccountManager>;
