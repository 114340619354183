import { z } from 'zod';

export const editCustomerFormSchema = z.object({
  address: z
    .string()
    .refine((val) => val.length! > 0, { message: 'Adress får inte vara tomt' }),
  address2: z.string().optional(),
  city: z
    .string()
    .refine((val) => val.length! > 0, { message: 'Stad får inte vara tomt' }),
  email: z.union([z.string().email('Ogiltig e-postadress'), z.literal('')]),
  firstName: z.string().refine((val) => val.length! > 0, {
    message: 'Förnamn får inte vara tomt',
  }),
  invoiceEmail: z.union([
    z.string().email('Ogiltig e-postadress'),
    z.literal(''),
  ]),
  lastName: z.string().refine((val) => val.length! > 0, {
    message: 'Efternamn får inte vara tomt',
  }),
  mobile: z.string(),
  phone: z.preprocess(
    Number,
    z.number({
      invalid_type_error: 'Telefonnummer ska enbart innehålla siffror',
    }),
  ),
  pin: z.string(),
  zip: z.string().refine((val) => !/\s/.test(val), {
    message: 'Postnummer får inte innehålla mellanslag',
  }),
});

export type BasicCustomerData = z.infer<typeof editCustomerFormSchema>;
