import React from 'react';

import { Box, Button, FormControl } from '@mui/material';

import { ChosenFilesList } from '@/components/FileUpload';
import { useBulkRegistrationState } from '@/hooks/useBulkRegistrationState';
import { useSnackbar } from '@/hooks/useSnackbar';
import { UploadIcon } from '@/icons/Actions/UploadIcon';
import { allowedExtensions } from '@/modules/services/components/KeyTag/constants';
import { useValidateKeyTagBulkFile } from '@/modules/services/components/KeyTag/hooks/useValidateKeyTagBulkFile';
import { storeFileInIndexedDB } from '@/utils/indexDb';

export const KeyTagBulkRegistrationFileValidationForm = () => {
  const { bulkRegistrationState, setBulkRegistrationState } =
    useBulkRegistrationState();
  const [file, setFile] = React.useState<File | null>(null);

  const fileUploadRef = React.useRef<HTMLButtonElement>(null);

  const snackbar = useSnackbar();
  const uploadMutation = useValidateKeyTagBulkFile();

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!file) {
      return;
    }

    uploadMutation.mutate(file, {
      onSuccess: async (data) => {
        snackbar({ message: 'Filen laddades upp', type: 'success' });
        await storeFileInIndexedDB(file, data.uuid);

        setBulkRegistrationState({
          ...bulkRegistrationState,
          fileValidationStatus: 'pending',
          fileValidationUuid: data.uuid,
          bulkRegistrationStep: 'fileValidationPending',
        });
      },
      onError: (error: any) => {
        setBulkRegistrationState({
          ...bulkRegistrationState,
          fileValidationStatus: 'failed',
          bulkRegistrationStep: 'fileValidationError',
        });

        snackbar({
          message: error?.message || 'Filupladdning misslyckades',
          type: 'error',
        });
      },
      onSettled: () => {
        setFile(null);
      },
    });
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;

    if (!file) return;
    setFile(file);
  };

  return (
    <form onSubmit={(e) => onSubmit(e)}>
      <Box display="flex" justifyContent="space-between">
        <FormControl>
          <input
            required
            type="file"
            accept={allowedExtensions.join(',')}
            onChange={onChange}
            id="contained-button-file"
            style={{ display: 'none' }}
          />
          <label htmlFor="contained-button-file">
            <Button
              ref={fileUploadRef}
              size="large"
              startIcon={<UploadIcon />}
              variant="outlined"
              color="primary"
              component="span"
              fullWidth
            >
              Välj fil
            </Button>
          </label>
        </FormControl>
        <FormControl>
          <Button
            sx={{
              height: fileUploadRef.current?.getBoundingClientRect().height,
            }}
            type="submit"
            color="primary"
            variant="contained"
            disabled={uploadMutation.isPending || !file}
          >
            Ladda upp
          </Button>
        </FormControl>
      </Box>
      <ChosenFilesList files={file ? [file] : null} title="Vald fil" />
    </form>
  );
};
