import React, { Suspense } from 'react';

import { LinearProgress } from '@mui/material';
import * as Sentry from '@sentry/react';
import { Navigate, Routes as ReactRouterRoutes, Route } from 'react-router-dom';

import { RouteWithLayout } from '@/components/RouteWithLayout';
import { AuthLayout, MainLayout } from '@/layouts';
import { SinneAdmin } from '@/modules/admin';
import SveaAdminPage from '@/modules/admin/components/SveaAdmin';
import {
  BillingPage,
  Fees,
  Hardware,
  SveaBank,
} from '@/modules/economy/components';
import { ConfigureSinne } from '@/modules/sinne/components/sinneConfigFlow/components/ConfigureSinne';
import AccountPage from '@/pages/Account';
import AMSAntiChurn from '@/pages/AccountManagerSystem/AntiChurn';
import AMSCustomerManagement from '@/pages/AccountManagerSystem/CustomerManagement';
import AMSOnboarding from '@/pages/AccountManagerSystem/Onboarding';
import AMSStatisticsOverview from '@/pages/AccountManagerSystem/StatisticsOverview';
import AMSTeam from '@/pages/AccountManagerSystem/Team';
import AMSUpsale from '@/pages/AccountManagerSystem/Upsale';
import AMSWinback from '@/pages/AccountManagerSystem/Winback';
import AddressWashPage from '@/pages/AddressWash';
import AdminTasks from '@/pages/AdminTasks';
import AgreementManagement from '@/pages/AgreementManagement';
import AlarmDetails from '@/pages/AlarmDetails';
import AlarmsPage from '@/pages/Alarms';
import BankIdCallerVerification from '@/pages/BankIdCallerVerification';
import BankIdVerification from '@/pages/BankIdVerification';
import BankIdVerificationCallback from '@/pages/BankIdVerificationCallback';
import BarringService from '@/pages/BarringService';
import Campaign from '@/pages/Campaign';
import Customer from '@/pages/Customer';
import CustomerSupportStatistics from '@/pages/CustomerSupportStatistics';
import CustomersPage from '@/pages/Customers';
import Dashboard from '@/pages/Dashboard';
import DeleteCustomer from '@/pages/DeleteCustomer';
import Export from '@/pages/Export';
import ForgotPasswordPage from '@/pages/ForgotPassword';
import FoundObject from '@/pages/FoundObject';
import AMSHOB2BStatistics from '@/pages/HOB2BStatistics';
import LostDeviceFlowPage from '@/pages/MissingSinneDevices/LostDeviceFlowPage';
import MissingSinneDevicesPage from '@/pages/MissingSinneDevices/MissingSinneDevicesPage';
import NotFound from '@/pages/NotFound';
import OnboardingTasks from '@/pages/OnboardingTasks';
import CreatePartnerAccounts from '@/pages/PartnerPortal/CreatePartnerAccount';
import EditPartnerAccount from '@/pages/PartnerPortal/EditPartnerAccount';
import PartnerAccountStats from '@/pages/PartnerPortal/PartnerAccountStats';
import PartnerAccounts from '@/pages/PartnerPortal/PartnerAccounts';
import CreateResellerAccounts from '@/pages/ResellerPortal/CreateResellerAccount';
import EditResellerAccount from '@/pages/ResellerPortal/EditResellerAccount';
import ResellerAccounts from '@/pages/ResellerPortal/ResellerAccounts';
import ResellerPortalStats from '@/pages/ResellerPortal/ResellerPortalStats';
import { ResellerSettings } from '@/pages/ResellerPortal/ResellerSettings';
import ResetPasswordPage from '@/pages/ResetPassword';
import AMSSalesManagerStats from '@/pages/SalesManagerStatistics';
import SecurityService from '@/pages/SecurityService';
import SendingsContainer from '@/pages/SendingsContainer';
import ServiceCreatePage from '@/pages/ServiceCreate';
import ServiceEditPage from '@/pages/ServiceEdit';
import ServicePage from '@/pages/Services';
import { BulkRegistrationPage } from '@/pages/Services/BulkRegistrationPage';
import SigninPage from '@/pages/SignIn';
import SupportChurn from '@/pages/SupportChurn';
import SupportNotes from '@/pages/SupportNotes';
import SupportTasks from '@/pages/SupportTasks';
import TemplateFileUpload from '@/pages/TemplateFileUpload';
import UserPage from '@/pages/User';
import Users from '@/pages/Users';
import WinbackTasks from '@/pages/WinbackTasks';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(ReactRouterRoutes);

export const Routes = () => {
  return (
    <Suspense fallback={<LinearProgress />}>
      <SentryRoutes>
        <Route path="/" element={<Navigate to="/dashboard" />} />

        <Route
          path="/sign-in"
          element={
            <RouteWithLayout layout={AuthLayout}>
              <SigninPage />
            </RouteWithLayout>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <RouteWithLayout layout={AuthLayout}>
              <ForgotPasswordPage />
            </RouteWithLayout>
          }
        />

        <Route
          path="/reset-password"
          element={
            <RouteWithLayout layout={AuthLayout}>
              <ResetPasswordPage />
            </RouteWithLayout>
          }
        />

        <Route
          path="/dashboard"
          element={
            <RouteWithLayout isProtected layout={MainLayout}>
              <Dashboard />
            </RouteWithLayout>
          }
        />

        <Route
          path="/users"
          element={
            <RouteWithLayout
              isProtected
              layout={MainLayout}
              authorizationAction="users.view"
            >
              <Users />
            </RouteWithLayout>
          }
        />

        <Route
          path="/user/:id"
          element={
            <RouteWithLayout
              layout={MainLayout}
              isProtected
              authorizationAction="users.view"
            >
              <UserPage />
            </RouteWithLayout>
          }
        />

        <Route
          path="/settings/me"
          element={
            <RouteWithLayout layout={MainLayout} isProtected>
              <UserPage />
            </RouteWithLayout>
          }
        />

        <Route
          path="/agreement-management"
          element={
            <RouteWithLayout
              isProtected
              authorizationAction="tools.agreementManagement"
              layout={MainLayout}
            >
              <AgreementManagement />
            </RouteWithLayout>
          }
        />

        <Route
          path="/sendings"
          element={
            <RouteWithLayout
              isProtected
              authorizationAction="tools.sendOuts"
              layout={MainLayout}
            >
              <SendingsContainer />
            </RouteWithLayout>
          }
        />

        <Route
          path="/addresswash"
          element={
            <RouteWithLayout
              isProtected
              authorizationAction="tools.addressWash"
              layout={MainLayout}
            >
              <AddressWashPage />
            </RouteWithLayout>
          }
        />

        <Route
          path="/admin/svea/"
          element={
            <RouteWithLayout
              isProtected
              authorizationAction="admin.menu.view"
              layout={MainLayout}
            >
              <SveaAdminPage />
            </RouteWithLayout>
          }
        />
        <Route
          path="/admin/tasks/"
          element={
            <RouteWithLayout
              isProtected
              authorizationAction="admin.menu.view"
              layout={MainLayout}
            >
              <AdminTasks />
            </RouteWithLayout>
          }
        />
        <Route
          path="/admin/:serviceName/tools"
          element={
            <RouteWithLayout
              isProtected
              authorizationAction="admin.menu.view"
              layout={MainLayout}
            >
              <SinneAdmin />
            </RouteWithLayout>
          }
        />

        <Route
          path="/tools/template-upload"
          element={
            <RouteWithLayout
              isProtected
              authorizationAction="tools.fileUpload"
              layout={MainLayout}
            >
              <TemplateFileUpload />
            </RouteWithLayout>
          }
        />

        <Route
          path="/alarms"
          element={
            <RouteWithLayout
              isProtected
              authorizationAction="alarms.view"
              layout={MainLayout}
            >
              <AlarmsPage />
            </RouteWithLayout>
          }
        />

        <Route
          path="/alarms/:id"
          element={
            <RouteWithLayout
              isProtected
              authorizationAction="alarms.view"
              layout={MainLayout}
            >
              <AlarmDetails />
            </RouteWithLayout>
          }
        />

        <Route
          path="/security-service"
          element={
            <RouteWithLayout
              isProtected
              authorizationAction="securityService.view"
              layout={MainLayout}
            >
              <SecurityService />
            </RouteWithLayout>
          }
        />

        <Route
          path="/security-service/found-object/:type/:code"
          element={
            <RouteWithLayout
              isProtected
              authorizationAction="securityService.foundObject"
              layout={MainLayout}
            >
              <FoundObject />
            </RouteWithLayout>
          }
        />

        <Route
          path="/security-service/barring-service/:productType/:productId"
          element={
            <RouteWithLayout
              isProtected
              authorizationAction="securityService.barringService"
              layout={MainLayout}
            >
              <BarringService />
            </RouteWithLayout>
          }
        />

        <Route
          path="/products/:serviceName"
          element={
            <RouteWithLayout
              isProtected
              authorizationAction="services.view"
              layout={MainLayout}
            >
              <ServicePage />
            </RouteWithLayout>
          }
        />
        <Route
          path="/products/:serviceName/add"
          element={
            <RouteWithLayout
              isProtected
              authorizationAction="services.view"
              layout={MainLayout}
            >
              <ServiceCreatePage />
            </RouteWithLayout>
          }
        />
        <Route
          path="/products/:serviceName/add-bulk"
          element={
            <RouteWithLayout
              isProtected
              authorizationAction="services.view"
              layout={MainLayout}
            >
              <BulkRegistrationPage />
            </RouteWithLayout>
          }
        />

        <Route
          path="/products/:serviceName/:serviceId/edit"
          element={
            <RouteWithLayout
              isProtected
              authorizationAction="services.view"
              layout={MainLayout}
            >
              <ServiceEditPage />
            </RouteWithLayout>
          }
        />

        <Route
          path="/products/sinne/:serviceId/configure"
          element={
            <RouteWithLayout
              isProtected
              authorizationAction="services.view"
              layout={MainLayout}
            >
              <ConfigureSinne />
            </RouteWithLayout>
          }
        />

        <Route
          path="/products/sinne/:serviceId/configure?isReplacementFlow=true"
          element={
            <RouteWithLayout
              isProtected
              authorizationAction="services.view"
              layout={MainLayout}
            >
              <ConfigureSinne />
            </RouteWithLayout>
          }
        />

        <Route
          path="/support/sinne/missing-devices"
          element={
            <RouteWithLayout
              isProtected
              authorizationAction="services.sinne.missingDevices.view"
              layout={MainLayout}
            >
              <MissingSinneDevicesPage />
            </RouteWithLayout>
          }
        />

        <Route
          path="/support/sinne/missing-devices/:deviceId"
          element={
            <RouteWithLayout
              isProtected
              authorizationAction="services.sinne.lostDeviceFlow"
              layout={MainLayout}
            >
              <LostDeviceFlowPage />
            </RouteWithLayout>
          }
        />

        <Route
          path="/account"
          element={
            <RouteWithLayout isProtected layout={MainLayout}>
              <AccountPage />
            </RouteWithLayout>
          }
        />

        <Route
          path="/customers"
          element={
            <RouteWithLayout
              isProtected
              authorizationAction="customers.view"
              layout={MainLayout}
            >
              <CustomersPage debtCollection={true} />
            </RouteWithLayout>
          }
        />

        <Route
          path="/customers/debt-collection"
          element={
            <RouteWithLayout
              isProtected
              authorizationAction="customers.debtCollection"
              layout={MainLayout}
            >
              <CustomersPage debtCollection={false} />
            </RouteWithLayout>
          }
        />

        <Route
          path="/customer/:id"
          element={
            <RouteWithLayout
              isProtected
              authorizationAction="customers.view"
              layout={MainLayout}
            >
              <Customer />
            </RouteWithLayout>
          }
        />

        <Route
          path="/support/notes"
          element={
            <RouteWithLayout
              isProtected
              authorizationAction="support.notes"
              layout={MainLayout}
            >
              <SupportNotes />
            </RouteWithLayout>
          }
        />

        <Route
          path="/support/tasks"
          element={
            <RouteWithLayout
              isProtected
              authorizationAction="support.tasks"
              layout={MainLayout}
            >
              <SupportTasks />
            </RouteWithLayout>
          }
        />

        <Route
          path="/support/winback-tasks"
          element={
            <RouteWithLayout
              isProtected
              authorizationAction="support.tasks"
              layout={MainLayout}
            >
              <WinbackTasks />
            </RouteWithLayout>
          }
        />

        <Route
          path="/support/onboarding-tasks"
          element={
            <RouteWithLayout
              isProtected
              authorizationAction="support.tasks"
              layout={MainLayout}
            >
              <OnboardingTasks />
            </RouteWithLayout>
          }
        />

        <Route
          path="/delete-customers"
          element={
            <RouteWithLayout
              isProtected
              authorizationAction="customers.delete"
              layout={MainLayout}
            >
              <DeleteCustomer />
            </RouteWithLayout>
          }
        />

        <Route
          path="/support/statistics"
          element={
            <RouteWithLayout
              isProtected
              authorizationAction="support.stats"
              layout={MainLayout}
            >
              <CustomerSupportStatistics />
            </RouteWithLayout>
          }
        />

        <Route
          path="/support/churn"
          element={
            <RouteWithLayout
              isProtected
              authorizationAction="support.churn"
              layout={MainLayout}
            >
              <SupportChurn />
            </RouteWithLayout>
          }
        />

        <Route
          path="/support/verify-customer"
          element={
            <RouteWithLayout
              isProtected
              authorizationAction="support.verifyCustomer"
              layout={MainLayout}
            >
              <BankIdCallerVerification />
            </RouteWithLayout>
          }
        />

        <Route
          path="/account-manager-system/customer-management"
          element={
            <RouteWithLayout isProtected layout={MainLayout}>
              <AMSCustomerManagement />
            </RouteWithLayout>
          }
        />

        <Route
          path="/account-manager-system/hob2b/statistics"
          element={
            <RouteWithLayout
              isProtected
              layout={MainLayout}
              authorizationAction="accountManagerSystem.stats.HOB2B"
            >
              <AMSHOB2BStatistics />
            </RouteWithLayout>
          }
        />

        <Route
          path="/account-manager-system/onboarding"
          element={
            <RouteWithLayout isProtected layout={MainLayout}>
              <AMSOnboarding />
            </RouteWithLayout>
          }
        />

        <Route
          path="/account-manager-system/upsale"
          element={
            <RouteWithLayout isProtected layout={MainLayout}>
              <AMSUpsale />
            </RouteWithLayout>
          }
        />

        <Route
          path="/account-manager-system/anti-churn"
          element={
            <RouteWithLayout isProtected layout={MainLayout}>
              <AMSAntiChurn />
            </RouteWithLayout>
          }
        />

        <Route
          path="/account-manager-system/sales-manager/statistics"
          element={
            <RouteWithLayout isProtected layout={MainLayout}>
              <AMSSalesManagerStats />
            </RouteWithLayout>
          }
        />

        <Route
          path="/account-manager-system/win-back"
          element={
            <RouteWithLayout isProtected layout={MainLayout}>
              <AMSWinback />
            </RouteWithLayout>
          }
        />

        <Route
          path="/account-manager-system/statistics/overview"
          element={
            <RouteWithLayout
              isProtected
              authorizationAction="accountManagerSystem.overview.view"
              layout={MainLayout}
            >
              <AMSStatisticsOverview />
            </RouteWithLayout>
          }
        />

        <Route
          path="/account-manager-system/team"
          element={
            <RouteWithLayout
              isProtected
              authorizationAction="accountManagerSystem.teams.view"
              layout={MainLayout}
            >
              <AMSTeam />
            </RouteWithLayout>
          }
        />

        <Route
          path="/support/verify-customer/bank-id/callback"
          element={
            <RouteWithLayout
              isProtected
              authorizationAction="support.verifyCustomer"
              layout={({ children }: { children: React.ReactNode }) => (
                <main>{children}</main>
              )}
            >
              <BankIdVerificationCallback />
            </RouteWithLayout>
          }
        />

        <Route
          path="/support/verify-user/bank-id"
          element={
            <RouteWithLayout
              isProtected
              authorizationAction="support.verifyCustomer"
              layout={({ children }: { children: React.ReactNode }) => (
                <main>{children}</main>
              )}
            >
              <BankIdVerification />
            </RouteWithLayout>
          }
        />

        {/*  ECONOMY  */}

        <Route
          path="/economy/billing"
          element={
            <RouteWithLayout
              isProtected
              authorizationAction="economy.view"
              layout={MainLayout}
            >
              <BillingPage />
            </RouteWithLayout>
          }
        />
        <Route
          path="/economy/cash-flow"
          element={
            <RouteWithLayout
              isProtected
              authorizationAction="economy.view"
              layout={MainLayout}
            >
              <Fees />
            </RouteWithLayout>
          }
        />
        <Route
          path="/economy/hardware"
          element={
            <RouteWithLayout
              isProtected
              authorizationAction="economy.view"
              layout={MainLayout}
            >
              <Hardware />
            </RouteWithLayout>
          }
        />
        <Route
          path="/economy/svea-bank"
          element={
            <RouteWithLayout
              isProtected
              authorizationAction="economy.view"
              layout={MainLayout}
            >
              <SveaBank />
            </RouteWithLayout>
          }
        />

        <Route
          path="/campaigns"
          element={
            <RouteWithLayout
              isProtected
              authorizationAction="tools.campaignBuilder"
              layout={MainLayout}
            >
              <Campaign />
            </RouteWithLayout>
          }
        />

        <Route
          path="/exports"
          element={
            <RouteWithLayout
              isProtected
              authorizationAction="services.export"
              layout={MainLayout}
            >
              <Export />
            </RouteWithLayout>
          }
        />

        <Route
          path="/reseller-portal/accounts"
          element={
            <RouteWithLayout
              isProtected
              authorizationAction="resellerPortal.manageAccounts"
              layout={MainLayout}
            >
              <ResellerAccounts />
            </RouteWithLayout>
          }
        />
        <Route
          path="/reseller-portal/accounts/:id"
          element={
            <RouteWithLayout
              isProtected
              authorizationAction="resellerPortal.manageAccounts"
              layout={MainLayout}
            >
              <EditResellerAccount />
            </RouteWithLayout>
          }
        />
        <Route
          path="/reseller-portal/accounts/create"
          element={
            <RouteWithLayout
              isProtected
              authorizationAction="resellerPortal.manageAccounts"
              layout={MainLayout}
            >
              <CreateResellerAccounts />
            </RouteWithLayout>
          }
        />
        <Route
          path="/reseller-portal/bdm/statistics"
          element={
            <RouteWithLayout
              isProtected
              authorizationAction="resellerPortal.bdmStatistics"
              layout={MainLayout}
            >
              <ResellerPortalStats />
            </RouteWithLayout>
          }
        />
        <Route
          path="/reseller-portal/bdm/statistics/reseller/:id"
          element={
            <RouteWithLayout
              isProtected
              authorizationAction="resellerPortal.bdmStatistics"
              layout={MainLayout}
            >
              <ResellerPortalStats />
            </RouteWithLayout>
          }
        />
        <Route
          path="/reseller-portal/reseller/statistics"
          element={
            <RouteWithLayout
              isProtected
              authorizationAction="resellerPortal.resellerStatistics"
              layout={MainLayout}
            >
              <ResellerPortalStats />
              {/* <ResellerStatisticsPage /> */}
            </RouteWithLayout>
          }
        />
        <Route
          path="/reseller-portal/me"
          element={
            <RouteWithLayout
              isProtected
              authorizationAction="resellerPortal.resellerSettings"
              layout={MainLayout}
            >
              <ResellerSettings />
            </RouteWithLayout>
          }
        />
        <Route
          path="/partner/accounts"
          element={
            <RouteWithLayout
              isProtected
              authorizationAction="partners.view"
              layout={MainLayout}
            >
              <PartnerAccounts />
            </RouteWithLayout>
          }
        />
        <Route
          path="/partner/statistics"
          element={
            <RouteWithLayout
              isProtected
              authorizationAction="partners.view"
              layout={MainLayout}
            >
              <PartnerAccountStats />
            </RouteWithLayout>
          }
        />
        <Route
          path="/partner/accounts/:partnerId/edit"
          element={
            <RouteWithLayout
              isProtected
              authorizationAction="partners.view"
              layout={MainLayout}
            >
              <EditPartnerAccount />
            </RouteWithLayout>
          }
        />
        <Route
          path="/partner/accounts/create"
          element={
            <RouteWithLayout
              isProtected
              authorizationAction="partners.view"
              layout={MainLayout}
            >
              <CreatePartnerAccounts />
            </RouteWithLayout>
          }
        />
        <Route
          path="*"
          element={
            <RouteWithLayout layout={MainLayout}>
              <NotFound />
            </RouteWithLayout>
          }
        />
      </SentryRoutes>
    </Suspense>
  );
};
