import { CircularProgress, CircularProgressProps } from '@mui/material';

type Props = CircularProgressProps;

export function LoadingSpinner(props: Props) {
  return (
    <CircularProgress
      sx={{
        display: 'block',
        margin: '32px auto 0 auto',
      }}
      size={60}
      {...props}
    />
  );
}
