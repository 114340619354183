import { ListItemText as MuiListItemText, Typography } from '@mui/material';

type StyledListTextProps = {
  primary: React.ReactNode;
  secondary: React.ReactNode;
  secondaryColor?: 'success' | 'error' | 'primary';
};
export const ListItemText = ({
  primary,
  secondary,
  secondaryColor,
}: StyledListTextProps) => (
  <MuiListItemText
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    }}
    primary={
      <Typography variant="h6" mr={1}>
        {primary}
      </Typography>
    }
    secondary={
      <Typography variant="h6" color={secondaryColor}>
        {secondary}
      </Typography>
    }
  />
);
