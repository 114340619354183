import React from 'react';

import { FormApi } from 'final-form';

import { UpdateTaskPayload } from '@/api';
import { DatePicker, Dropdown, TextArea, TextInput } from '@/components/form';
import { getObjectKeys } from '@/helpers/tsHelpers';
import { usePrivateSettings } from '@/hooks';
import { fieldRequired } from '@/utils/validation';

import {
  TaskTeams,
  taskStatusOptions,
} from '../../constants/tasksModalsConstants';
import { EditTaskModalProps } from './EditTaskModal';

type EditTaskModalFieldProps = {
  form: FormApi<any>;
  values: UpdateTaskPayload;
};

type Props = Omit<
  EditTaskModalProps,
  'open' | 'handleVisibility' | 'fromTasksList' | 'queryKey' | 'customer'
> &
  EditTaskModalFieldProps;

export const EditTaskModalFields = ({ task }: Props) => {
  const { getTypesOptionsForDropdownInput } = usePrivateSettings();

  return (
    <>
      <Dropdown
        label="Status"
        name="status"
        options={taskStatusOptions}
        fieldProps={{
          validate: fieldRequired,
        }}
      />

      <Dropdown
        label="Kategori"
        name="type"
        options={getTypesOptionsForDropdownInput('task', {
          taskTeam: getObjectKeys(TaskTeams).find(
            (key) => TaskTeams[key] === task.team,
          ),
        })}
        fieldProps={{
          validate: fieldRequired,
        }}
      />

      <DatePicker label="Deadline" name="deadline" showClearButton />

      <TextArea
        label="Text"
        name="text"
        rows={8}
        fieldProps={{
          validate: fieldRequired,
        }}
      />
    </>
  );
};
