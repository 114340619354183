import LinearProgress from '@mui/material/LinearProgress';

import { SimpleCard } from '@/components/SimpleCard';
import { AlarmLocationSection } from '@/modules/services/components/sections';

type Props = {
  isLoading: boolean;
};

export const AlarmLocationForm = ({ isLoading }: Props) => {
  return (
    <SimpleCard
      title="Placering av brandlarmet"
      marginBottom="0"
      cardContent={
        <>
          <section>
            <AlarmLocationSection />
          </section>

          {isLoading && <LinearProgress />}
        </>
      }
    />
  );
};
