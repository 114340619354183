import { useQuery } from '@tanstack/react-query';

import { fetchDeletedUsers } from '@/api/users';
import { queryKeys } from '@/constants/queryKeys';
import { RoleTypes } from '@/constants/roleTypes';
import useUserRoles from '@/hooks/useUserRoles';

type Args = {
  page: number;
  perPage: number;
  rowsPerPage?: number;
  enabled?: boolean;
};

export const useGetDeletedUsers = ({
  page,
  perPage,
  rowsPerPage,
  enabled = true,
}: Args) => {
  const userRoles = useUserRoles();
  return useQuery({
    queryKey: [queryKeys.getDeletedUsers, page, rowsPerPage, perPage],

    queryFn: async () => {
      const data = await fetchDeletedUsers({
        page: page + 1,
        perPage: rowsPerPage,
      });

      if (!Array.isArray(data)) return { users: [], totalUsers: 0 };

      if (!userRoles.includes(RoleTypes.ROLE_GODMODE)) {
        return {
          users: data.filter(
            (user) => !user?.roles?.includes(RoleTypes.ROLE_GODMODE),
          ),
          totalUsers: data.length,
        };
      }

      return {
        users: data,
        totalUsers: data.length,
      };
    },
    enabled,
    initialData: { users: [], totalUsers: 0 },
  });
};
