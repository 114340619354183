import { atomWithStorage } from 'jotai/utils';

export type SendingsState = {
  active: boolean;
  batchUUID: string;
  bulkBatchIds: string[] | string;
  complete: boolean;
  completedBatches: number;
  failed: boolean;
  failedItems: string[];
  numberOfBatches: number;
  numberOfFails: number;
  numberOfRecipients: number;
  type: string;
};
export const initialState: SendingsState = {
  active: false,
  batchUUID: '',
  bulkBatchIds: [],
  complete: false,
  completedBatches: 0,
  failed: false,
  failedItems: [],
  numberOfBatches: 0,
  numberOfFails: 0,
  numberOfRecipients: 0,
  type: '',
};

export const sendingsAtom = atomWithStorage<SendingsState>(
  'sendings',
  initialState,
  undefined,
  {
    getOnInit: true,
  },
);
