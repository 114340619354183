import cloneDeep from 'lodash/cloneDeep';
import mapKeys from 'lodash/mapKeys';
import invariant from 'tiny-invariant';

import { getChurnReasonKey, getServiceStatusKey } from '@/helpers';
import { config } from '@/helpers/config';
import { getCamelCaseServiceName } from '@/helpers/tsHelpers';
import { getCamelServiceName } from '@/modules/common/utils/services';
import { ServiceName } from '@/types/services';
import { ChurnReasons } from '@/types/settings';

type PrepareServiceCreationPayloadArgs = {
  serviceData: any;
  serviceName: ServiceName;
  customerId: number;
  servicesStatuses: any;
  churnReasons?: ChurnReasons;
};

export default function prepareServiceCreationPayload({
  serviceData,
  serviceName,
  customerId,
  servicesStatuses,
  churnReasons,
}: PrepareServiceCreationPayloadArgs) {
  let service = cloneDeep(serviceData);
  const camelCaseServiceName = getCamelCaseServiceName(serviceName);

  if (serviceName === 'co-protection') {
    service = mapKeys(service, (value, key) => {
      if (key === 'protectedPin') {
        return 'pin';
      }

      if (key === 'protectedPhone') {
        return 'phone';
      }

      if (key === 'protectedEmail') {
        return 'email';
      }

      if (key === 'protectedName') {
        return 'name';
      }

      return key;
    });
  }

  let status: number;
  if (serviceName === 'firetext') {
    const statusKey = getServiceStatusKey(
      servicesStatuses,
      camelCaseServiceName,
      'Nyregistrerad',
    );
    invariant(statusKey !== undefined, 'Could not find status for firetext');
    status = parseInt(statusKey, 10);
  } else {
    const statusKey = getServiceStatusKey(
      servicesStatuses,
      camelCaseServiceName,
      'Aktiv',
    );
    invariant(statusKey !== undefined, 'Could not find status for service');
    status = parseInt(statusKey, 10);
  }

  return {
    ...service,
    status,
    customer: `${config.whitelabelId}/customers/${customerId}`,
    campaign: service.campaign ?? '',
    addressList: service.addressList ?? '',
    placement: service.placement ?? '',
    salesRep: service.salesRep ?? '',
    kamId: parseInt(service.kamId, 10),
    pricePlanId: parseInt(service.pricePlanId, 10),
    type: parseInt(service.type, 10),
    leadSource: service.leadSource ?? '',
    tb: service.tb ? parseInt(service.tb, 10) : null,
    ...(serviceName === 'co-protection' && {
      pin: service.pin ?? '',
    }),
    ...(serviceName === 'protection' && {
      pin1: service.pin1 ?? '',
    }),
    ...(serviceName === 'sticker' && {
      billing: service.billing ? 1 : 0,
    }),
    churnReason:
      service.churnDate && churnReasons
        ? getChurnReasonKey(
            churnReasons,
            getCamelServiceName(serviceName),
            'Auto-churn',
          )
        : undefined,
  };
}
