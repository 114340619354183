import { useState } from 'react';

import { Box } from '@mui/material';
import camelCase from 'lodash/camelCase';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Breadcrumbs } from '@/components/Breadcrumbs';
import { PageHeader } from '@/components/PageHeader';
import { Tabs } from '@/components/Tabs/Tabs';
import { usePrivateSettings } from '@/hooks/usePrivateSettings';
import {
  KeyTagConfigModal,
  OrderFileUploadModal,
  SERVICE_TABS,
} from '@/modules/services';
import { ServiceActionButtons } from '@/modules/services/components/ServiceActionButtons';
import { StickerConfigModal, StickerConfigModalType } from '@/modules/sticker';
import { OrderFileModalType } from '@/types/orderFile';
import { CamelCaseServiceName } from '@/types/services';

type ParamType = { serviceName: keyof typeof SERVICE_TABS };

export const Main = () => {
  const { t } = useTranslation();
  const { serviceName } = useParams<ParamType>();
  const { servicesNames } = usePrivateSettings();

  const camelCasedServiceName = camelCase(serviceName) as CamelCaseServiceName;

  const mappedServiceName = servicesNames[camelCasedServiceName];
  const [showKeyTagConfigModal, setShowKeyTagConfigModal] = useState(false);

  const [stickerConfigModal, setStickerConfigModal] = useState<{
    opened: boolean;
    type: StickerConfigModalType | null;
  }>({
    opened: false,
    type: null,
  });

  const [orderFileModal, setOrderFileModal] = useState<{
    opened: boolean;
    type?: OrderFileModalType;
  }>({
    opened: false,
    type: undefined,
  });

  function handleOpenKeyTagConfigModal() {
    setShowKeyTagConfigModal(true);
  }

  function handleOpenStickerConfigModal(type: StickerConfigModalType) {
    setStickerConfigModal({
      opened: true,
      type,
    });
  }

  function handleOpenOrderFileUploadModal(type: OrderFileModalType) {
    setOrderFileModal({
      opened: true,
      type,
    });
  }

  const getTabs = () => {
    if (serviceName) {
      return SERVICE_TABS[serviceName];
    }
    return [];
  };

  return (
    <Box sx={{ maxWidth: 'lg' }}>
      <Breadcrumbs
        crumbs={[{ label: t('common.services') }, { label: mappedServiceName }]}
      />

      <PageHeader title={mappedServiceName} />

      <ServiceActionButtons
        serviceName={serviceName!}
        onOpenKeyTagConfigModal={handleOpenKeyTagConfigModal}
        onOpenStickerConfigModal={handleOpenStickerConfigModal}
        onOpenOrderFileUploadModal={handleOpenOrderFileUploadModal}
      />

      {getTabs()?.length > 0 && (
        <Tabs
          key={serviceName}
          tabs={getTabs()}
          viewProps={{ serviceName }}
          defaultTabIndex={1}
        />
      )}

      {showKeyTagConfigModal && (
        <KeyTagConfigModal
          open
          onClose={() => setShowKeyTagConfigModal(false)}
        />
      )}

      {stickerConfigModal.opened && stickerConfigModal.type && (
        <StickerConfigModal
          open
          type={stickerConfigModal.type}
          onClose={() => {
            setStickerConfigModal((prevState) => ({
              ...prevState,
              opened: false,
            }));
          }}
        />
      )}

      <OrderFileUploadModal
        isOpen={orderFileModal.opened && !!orderFileModal.type}
        type={orderFileModal.type as OrderFileModalType}
        onClose={() => setOrderFileModal({ opened: false, type: undefined })}
      />
    </Box>
  );
};
