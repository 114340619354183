import { Box } from '@mui/material';

import { PageHeader } from '@/components/PageHeader';
import { Tabs } from '@/components/Tabs/Tabs';
import { PRICE_TYPES } from '@/constants';
import {
  CampaignList,
  PriceList,
  WelcomeLetter,
} from '@/modules/campaigns/components';
import { Tabmap } from '@/types/tabs';

const tabMap: Tabmap[] = [
  { title: 'Kampanjer', view: () => <CampaignList /> },
  {
    title: 'Priser',
    view: () => <PriceList title="Priser" type={PRICE_TYPES.PRICE} />,
    authRule: 'prices.create',
  },
  {
    title: 'Avgifter',
    view: () => <PriceList title="Avgifter" type={PRICE_TYPES.FEE} />,
    authRule: 'prices.create',
  },
  {
    title: 'Välkomstbrev',
    view: () => <WelcomeLetter />,
    authRule: 'prices.create',
  },
];

const CampaignPage = () => {
  return (
    <Box sx={{ maxWidth: 'lg' }}>
      <PageHeader title="Kampanjbyggaren" margin />
      <Tabs tabs={tabMap} />
    </Box>
  );
};

export default CampaignPage;
