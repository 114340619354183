import React from 'react';

import { Grid, Skeleton, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import round from 'lodash/round';

import { getResellers } from '@/api/reseller';
import {
  DatePicker,
  Dropdown,
  KamIdAutocomplete,
  TextInput,
} from '@/components/form';
import { CustomerCategoryTypes } from '@/constants';
import { usePrivateSettings } from '@/hooks';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { USER_TYPES } from '@/modules/common/constants/entitiyNamesMap';
import { formatCurrency } from '@/modules/common/utils/currency';
import {
  preparePricePlanOptions,
  prepareResellerOptions,
} from '@/modules/services';
import { ProtectionCampaignSection } from '@/modules/services/components/Edit/Protection/ProtectionCampaignSection';
import {
  CamelCaseServiceName,
  HomegateParamSchema,
  ServiceParams,
  SinneItem,
} from '@/types/services';
import { FEATURE_FLAGS } from '@/utils/featureFlags';
import { hasOwnProperty } from '@/utils/typeHelpers';
import { fieldRequired } from '@/utils/validation';

type Props = {
  serviceName: CamelCaseServiceName;
  serviceDetails: Exclude<ServiceParams, HomegateParamSchema | SinneItem>;
  disabled?: boolean;
};

const getAccountManagerText = (
  accountManager: Props['serviceDetails']['customer']['accountManager'],
) => {
  if (
    typeof accountManager === 'object' &&
    accountManager !== null &&
    hasOwnProperty(accountManager, 'name')
  ) {
    return accountManager.name;
  }
  return 'Saknas';
};

export const SalesInformationEditModeSection = ({
  serviceName,
  serviceDetails,
  disabled = false,
}: Props) => {
  const protectionCampaignV2Enabled = useFeatureFlag({
    name: FEATURE_FLAGS.protectionCampaignV2.name,
  });

  const { getServicePricePlans } = usePrivateSettings();

  const pricePlans = getServicePricePlans(serviceName);

  const isCompany =
    serviceDetails?.customer?.category === CustomerCategoryTypes.COMPANY;
  const {
    data: resellerOptions,
    isPending,
    isSuccess,
  } = useQuery({
    queryKey: ['resellersList'],
    queryFn: async () => {
      const resellers = await getResellers();

      return prepareResellerOptions(resellers.data);
    },
  });

  const feeWithoutTax = pricePlans[serviceDetails.pricePlanId];
  const feeWithTax = round(feeWithoutTax! * 1.25, 2);

  return (
    <>
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <Typography variant="h6" gutterBottom>
            Månadsavgift
          </Typography>
        </Grid>

        <Grid item>
          <Typography gutterBottom>
            {formatCurrency(feeWithTax)} (inkl) /{' '}
            {formatCurrency(feeWithoutTax!)} (exkl)
          </Typography>
        </Grid>

        <Grid item style={{ marginLeft: 'auto' }}>
          <Typography variant="h6" gutterBottom>
            Account Manager
          </Typography>
        </Grid>

        <Grid item>
          <Typography gutterBottom>
            {getAccountManagerText(serviceDetails.customer.accountManager)}
          </Typography>
        </Grid>
      </Grid>

      {serviceName !== 'sticker' ? (
        <Grid container spacing={3}>
          <Grid item xs>
            <Dropdown
              disabled={disabled}
              label="Prisplan"
              name="pricePlanId"
              options={preparePricePlanOptions({
                pricePlans,
                isCompany,
              })}
              required
              fieldProps={{
                validate: fieldRequired,
              }}
            />

            {isPending ? (
              <Skeleton height={54} />
            ) : (
              isSuccess && (
                <Dropdown
                  disabled={disabled}
                  label="Återförsäljare"
                  name="reseller"
                  options={resellerOptions}
                  required
                  fieldProps={{
                    validate: fieldRequired,
                  }}
                />
              )
            )}

            <TextInput
              disabled={disabled}
              label={`Info till ${USER_TYPES.admin}`}
              name="adminNote"
            />

            <TextInput
              disabled={disabled}
              label="Leadkälla"
              name="leadSource"
            />

            <KamIdAutocomplete disabled={disabled} />

            {serviceName !== 'keyTag' && (
              <TextInput disabled={disabled} label="AB Test" name="abTest" />
            )}
          </Grid>

          <Grid item xs>
            <DatePicker
              disabled={disabled}
              label="Datum"
              name="regDate"
              required
              fieldProps={{
                validate: fieldRequired,
              }}
            />

            <TextInput disabled={disabled} label="Säljare" name="salesRep" />

            {serviceName === 'protection' ? (
              <>
                {protectionCampaignV2Enabled && (
                  <ProtectionCampaignSection
                    customerType={serviceDetails.customer.category}
                  />
                )}
                <TextInput
                  disabled={protectionCampaignV2Enabled}
                  label={
                    protectionCampaignV2Enabled ? 'Legacykampanj' : 'Kampanj'
                  }
                  name="campaign"
                />
              </>
            ) : (
              <TextInput
                disabled={disabled}
                label="Kampanj"
                name="campaign"
                {...(serviceName === 'keyTag'
                  ? { required: true, fieldProps: { validate: fieldRequired } }
                  : {})}
              />
            )}

            <TextInput
              disabled={disabled}
              label="Adresslista"
              name="addressList"
            />

            <TextInput disabled={disabled} label="Täckningsbidrag" name="tb" />
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs>
            <Dropdown
              label="Prisplan"
              name="pricePlanId"
              options={preparePricePlanOptions({
                pricePlans,
                isCompany,
              })}
              required
              fieldProps={{
                validate: fieldRequired,
              }}
            />

            <TextInput
              disabled={disabled}
              label={`Info till ${USER_TYPES.admin}`}
              name="adminNote"
            />
          </Grid>

          <Grid item xs>
            <KamIdAutocomplete disabled={disabled} />

            <TextInput disabled={disabled} label="Täckningsbidrag" name="tb" />
          </Grid>
        </Grid>
      )}
    </>
  );
};
