import React, { useContext } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { useSelector } from '@xstate/react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { CreateNotePayload } from '@/api/notes';
import { getTypeKey } from '@/helpers';
import { usePrivateSettings } from '@/hooks';
import { Textfield } from '@/modules/components';
import { LinkToCustomer } from '@/modules/sinne/components/LinkToCustomer';
import { DiscardStateContext } from '@/modules/sinne/components/sinneDiscardFlow/context';

import { RetryButton } from '../../RetryButton';
import { StepRow } from '../../StepRow';

const formSchema = z.object({
  type: z.string(),
  text: z.string().min(1, 'Obligatoriskt fält'),
  customerId: z.number(),
});

type Form = Pick<CreateNotePayload, 'customerId' | 'text' | 'type'>;

type Args = {
  macAddress: string;
  canDeviceBeReused: boolean;
  discardDeviceFee: number;
};

export const NoteForm = () => {
  const { servicesTypes } = usePrivateSettings();
  const discardFlowService = useContext(DiscardStateContext);
  const state = useSelector(discardFlowService, (state) => state);
  const { macAddress, customer, discardDeviceFee } = state.context;

  const noteType = z
    .string()
    .parse(getTypeKey(servicesTypes, 'note', 'Enhet Returnerad'));

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<Form>({
    shouldUnregister: true,
    resolver: zodResolver(formSchema),
  });

  React.useEffect(() => {
    if (customer.id && noteType) {
      setValue('customerId', customer.id);
      setValue('type', noteType);
      setValue(
        'text',
        `Enhet med mac adress ${macAddress} har kasserats. Kunden debiteras ${discardDeviceFee} kr. (exkl. moms.)`,
      );
    }
  }, [customer.id, noteType, macAddress, discardDeviceFee]);

  const onSubmit = (payload: Form) =>
    discardFlowService.send({ type: 'CREATE_NOTE', payload });

  if (state.matches('CREATE_NOTE.loading')) {
    return (
      <StepRow>
        <CircularProgress size={20} />
        <Typography component="span" variant="h6">
          Skapar notering...
        </Typography>
      </StepRow>
    );
  }

  if (state.context.isNoteCreated) {
    return (
      <StepRow>
        <CheckCircleOutlineIcon color="success" sx={{ fontSize: 20 }} />
        <Typography component="span" variant="h6">
          Notering skapad på kund med id: {customer.id}
        </Typography>
        <LinkToCustomer customerId={z.number().parse(customer.id)} />
      </StepRow>
    );
  }

  if (state.matches('CREATE_NOTE.error')) {
    return (
      <StepRow>
        <ErrorOutlineIcon color="error" sx={{ fontSize: 20 }} />
        <Typography component="span" variant="h6" padding={0} margin={0}>
          Något gick fel när notering skulle skapas
        </Typography>

        <RetryButton
          onClick={() =>
            discardFlowService.send({
              type: 'CREATE_NOTE',
              payload: {
                text: state.context.noteText,
                customerId: z.number().parse(customer.id),
                type: noteType,
              },
            })
          }
          text="Försök igen"
        />
      </StepRow>
    );
  }
  if (state.matches('CREATE_NOTE')) {
    return (
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid container item xs={12}>
            <Typography display="inline-block" variant="h5" sx={{ mr: 2 }}>
              Skapa notering på kund
            </Typography>
            <LinkToCustomer customerId={z.number().parse(customer.id)} />
          </Grid>

          <Grid item xs={12}>
            <Textfield
              label="Text på noteringen"
              error={errors.text}
              autoComplete="off"
              multiline
              rows={4}
              fullWidth
              autoFocus
              sx={{ marginBottom: 2 }}
              inputProps={{
                ...register('text'),
                'data-testid': 'note-text',
              }}
              disabled={state.matches('CREATE_NOTE.loading')}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              disabled={state.matches('CREATE_NOTE.loading')}
              type="submit"
              variant="outlined"
            >
              Skapa notering
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  }
  return null;
};
