import React from 'react';

import { ListItem } from '@mui/material';

type Props = {
  children: React.ReactNode;
};
export const MenuListItem = ({ children }: Props) => {
  return (
    <ListItem disableGutters dense sx={{ padding: 0 }}>
      {children}
    </ListItem>
  );
};
