import { LockRegister, LockRegistersResponse } from '@/types/lockRegister';
import httpClient from '@/utils/httpClient';

type GetAllLockRegistersParams = {
  page: number;
  perPage: number;
  search: string;
};
export async function getAllLockRegisters({
  page,
  perPage,
  search,
}: GetAllLockRegistersParams): Promise<{
  total: number;
  data: LockRegister[];
}> {
  const { data } = await httpClient.get<LockRegistersResponse>(
    '/lock_registers',
    {
      params: {
        pagination: true,
        page,
        perPage,
        companyName: search,
      },
    },
  );

  return {
    total: data['hydra:totalItems'],
    data: data['hydra:member'],
  };
}
