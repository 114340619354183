import { Box, Button } from '@mui/material';
import pick from 'lodash/pick';
import { Form } from 'react-final-form';
import { z } from 'zod';

import { FiretextParamSchema } from '@/types/services';
import { firetextPricePlanSchema, firetextStatusKey } from '@/types/settings';

import { mapInitialObjectValuesToStrings } from '../../../utils/mapInitialObjectValuesToStrings';
import { AlarmLocationForm } from './AlarmLocationForm';
import { ContactListForm } from './ContactListForm';
import { SalesInformationForm } from './SalesInformationForm';
import { ServiceInfoForm } from './ServiceInfoForm';

const FIELDS_TO_PICK = [
  'abTest',
  'addressList',
  'adminNote',
  'antiregret',
  'batch',
  'campaign',
  'churnDate',
  'churnFiretextActive',
  'churnFiretextReason',
  'contact1',
  'contact2',
  'contact3',
  'contact4',
  'contact5',
  'icc',
  'icc2',
  'kamId',
  'leadSource',
  'manufactureDate',
  'msg',
  'msisdn',
  'msisdn2',
  'operatorId',
  'operatorId2',
  'phone1',
  'phone2',
  'phone3',
  'phone4',
  'phone5',
  'placement',
  'pricePlanId',
  'regDate',
  'regretReason',
  'regretSource',
  'reseller',
  'salesRep',
  'status',
  'tb',
  'unitReturned',
  'upgradePolicy',
  'winBack',
];

const firetextFormValuesSchema = z.object({
  abTest: z.string(),
  addressList: z.string(),
  adminNote: z.string().nullable(),
  antiregret: z.number().int(),
  campaign: z.string(),
  churnDate: z.string().nullable(),
  churnFiretextActive: z.number().int(),
  churnFiretextReason: z.number().int(),
  contact1: z.string(),
  contact2: z.string(),
  contact3: z.string(),
  contact4: z.string(),
  contact5: z.string(),
  icc: z.string(),
  icc2: z.string(),
  kamId: z.number().nullable(),
  leadSource: z.string(),
  manufactureDate: z.string().nullable(),
  msg: z.string().nullable(),
  msisdn: z.string(),
  msisdn2: z.string(),
  operatorId: z.number().int(),
  operatorId2: z.number().int(),
  phone1: z.string(),
  phone2: z.string(),
  phone3: z.string(),
  phone4: z.string(),
  phone5: z.string(),
  placement: z.string(),
  pricePlanId: firetextPricePlanSchema.keySchema,
  regDate: z.string(),
  regretReason: z.number().int(),
  regretSource: z.number().int(),
  reseller: z.string(),
  salesRep: z.string(),
  status: firetextStatusKey,
  tb: z.number().nullable(),
  unitReturned: z.number().int(),
  upgradePolicy: z.number().int(),
  winBack: z.number().int(),
});

type FormValues = z.infer<typeof firetextFormValuesSchema>;

export type FiretextEditProps = {
  serviceDetails: FiretextParamSchema;
  isLoading: boolean;
  onSubmit: (v: FormValues) => void;
};

export const FiretextEdit = ({
  serviceDetails,
  isLoading,
  onSubmit,
}: FiretextEditProps) => {
  const serviceName = 'firetext';

  const initialValues = mapInitialObjectValuesToStrings(
    pick(serviceDetails, FIELDS_TO_PICK),
  );

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit} noValidate>
          <Box display="flex" flexDirection="column" gap={2}>
            <ServiceInfoForm
              serviceDetails={serviceDetails}
              isLoading={isLoading}
              churnDateValue={values.churnDate}
              statusValue={values.status}
            />

            <SalesInformationForm
              serviceName={serviceName}
              serviceDetails={serviceDetails}
              isLoading={isLoading}
            />

            <ContactListForm isLoading={isLoading} />

            <AlarmLocationForm isLoading={isLoading} />
          </Box>

          <Button
            type="submit"
            color="primary"
            variant="contained"
            sx={{ mt: 2 }}
            disabled={isLoading}
          >
            Uppdatera
          </Button>
        </form>
      )}
    />
  );
};
