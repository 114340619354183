import { CheckOutlined, ErrorOutline } from '@mui/icons-material';
import { CircularProgress, Typography, styled } from '@mui/material';

type Props = {
  loading: boolean;
  success: boolean;
  error: boolean;
  idle: boolean;
  successMessage?: string;
  errorMessage?: string;
};

const Wrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'idle',
})<{ idle: boolean }>(({ idle }) => ({
  display: idle ? 'none' : 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
  bottom: '5%',
}));

const Circle = styled('div', {
  shouldForwardProp: (prop) => prop !== 'success' && prop !== 'error',
})<{ success: boolean; error: boolean }>(({ theme, success }) => ({
  borderRadius: '50%',
  width: '4rem',
  height: '4rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: success ? theme.palette.success.main : theme.palette.error.main,
  border: `2px solid ${
    success ? theme.palette.success.main : theme.palette.error.main
  }`,
}));

export const MutationLoadingSpinner = ({
  loading,
  success,
  error,
  idle,
  successMessage,
  errorMessage,
}: Props) => {
  return (
    <Wrapper idle={idle}>
      <Circle error={error} success={success}>
        {success ? (
          <CheckOutlined fontSize="large" />
        ) : error ? (
          <ErrorOutline />
        ) : (
          <></>
        )}
      </Circle>
      {loading && (
        <CircularProgress
          color="primary"
          size={'4rem'}
          sx={{ position: 'absolute', top: 0, zIndex: 1 }}
        />
      )}
      {success && successMessage && (
        <Typography mt={2} variant="h3">
          {successMessage}
        </Typography>
      )}
      {error && errorMessage && (
        <Typography mt={2} variant="h3" color="error">
          {errorMessage}
        </Typography>
      )}
    </Wrapper>
  );
};
