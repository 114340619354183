import { useQuery } from '@tanstack/react-query';

import { getProtections } from '@/api/protection';
import { queryKeys } from '@/constants/queryKeys';

export const useGetProtections = (pin: string) => {
  return useQuery({
    queryKey: ['findProduct', queryKeys.getProtections, pin],

    queryFn: async () => {
      const data = await getProtections({ searchPin: pin });

      return data['hydra:member'].map((item) => {
        return {
          ['@type']: item['@type'],
          customer: item.customer,
          id: item.id,
        };
      });
    },

    enabled: !!pin,
  });
};
