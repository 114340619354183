import { SupportRetetionStatistics } from '@/types/stats';
import httpClient from '@/utils/httpClient';

export type SupportRetentionStatisticsArgs = {
  start: string;
  end: string;
};
export const getSupportRetetionStatistics = async ({
  start,
  end,
}: SupportRetentionStatisticsArgs) => {
  const { data } = await httpClient.get<SupportRetetionStatistics>(
    `retention/statistics?start=${start}&end=${end}`,
  );

  return data;
};
