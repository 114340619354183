import { captureException } from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { updateUser } from '@/api/users';
import { GenericHttpError } from '@/types/errors';
import { UpdateUserItem, UserItem } from '@/types/users';

type MutationProps = {
  userId: number;
  fields: UpdateUserItem;
};

export const useUpdateUser = () => {
  const queryClient = useQueryClient();

  return useMutation<UserItem, GenericHttpError, MutationProps>({
    mutationFn: ({ fields, userId }: MutationProps) =>
      updateUser({ userId, fields }),
    onSuccess: (data, { userId }) => {
      queryClient.setQueryData(['getUserById', userId], data);
    },
    onError: (e) => {
      if (e) {
        const error =
          e.data['hydra:description'] ||
          e.data.message ||
          e.data['hydra:title'];

        console.error(error);
        captureException(error);
      }
    },
  });
};
