import { useQuery } from '@tanstack/react-query';

import { getUserById } from '@/api';
import useUser from '@/hooks/useUser';

import { SalesManagerUser } from '../../types';

const fieldsToFetch = {
  name: true,
  managedTeams: {
    manager: {
      name: true,
      coProtectionOnboardingPercentage: true,
      firetextOnboardingPercentage: true,
    },
    name: true,
    members: {
      id: true,
      name: true,
      managedCustomers: { id: true, activeServiceTypes: true },
      coProtectionOnboardingPercentage: true,
      firetextOnboardingPercentage: true,
    },
  },
};

export const useGetCurrentSalesManager = () => {
  const user = useUser();

  const { data, status } = useQuery({
    queryKey: ['user', user.id, fieldsToFetch],

    queryFn: async () => {
      return getUserById<SalesManagerUser>(user.id, fieldsToFetch);
    },

    enabled: !!user.id,
  });

  return { data, status };
};
