import React from 'react';

import { Alert, Grid, Skeleton, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { FieldArray } from 'react-final-form-arrays';

import { getPrices } from '@/api/prices';
import { Dropdown } from '@/components/form';
import { queryKeys } from '@/constants/queryKeys';
import {
  prepareFeesIntegerOptions,
  prepareFeesOptions,
  preparePricesIntegerOptions,
  preparePricesOptions,
} from '@/modules/services';
import { CampaignTypes, TemplateResponse } from '@/types/campaigns';
import { Template } from '@/types/services';

export type CampaignFormValues = {
  service: CampaignTypes;
  name: string;
  customerType: number;
  contractMonths: number;
  freeMonths: number;
  noticePeriod: number;
  withdrawalPeriodDays: number;
  templates: Template[];
};

type Props = {
  templateData: TemplateResponse[];
  values: CampaignFormValues;
};

export const TemplateArrayFields = ({ templateData, values }: Props) => {
  const service = values.service?.replace('_campaigns', '');

  const {
    data: prices,
    isInitialLoading: isLoading,
    isError,
  } = useQuery({
    queryKey: [queryKeys.getPrices, service],
    queryFn: () => getPrices({ service }),
    enabled: !!service,
  });

  const [setIriOptions] = React.useState(templateData.length > 0);

  const sinnePriceOptions = preparePricesOptions(prices, values.customerType);

  const sinneFeesOptions = prepareFeesOptions(prices, values.customerType);

  const homeGatePriceOptions = setIriOptions
    ? preparePricesOptions(prices, values.customerType)
    : preparePricesIntegerOptions(prices, values.customerType);

  const homeGateFeesOptions = setIriOptions
    ? prepareFeesOptions(prices, values.customerType)
    : prepareFeesIntegerOptions(prices, values.customerType);

  return (
    <FieldArray name={'templates'}>
      {({ fields }) =>
        fields.map((name, index) => (
          <Grid item xs={12} key={name}>
            <Typography variant="h3">{`Enhet ${++index}`}</Typography>
            {isLoading ? (
              <Skeleton />
            ) : isError ? (
              <Alert>Något gick fel</Alert>
            ) : (
              <>
                <Dropdown
                  name={`${name}.price`}
                  label="Pris"
                  options={
                    service === 'sinne'
                      ? sinnePriceOptions
                      : homeGatePriceOptions
                  }
                />
                <Dropdown
                  name={`${name}.startFee`}
                  label="Startavgift"
                  options={
                    service === 'sinne' ? sinneFeesOptions : homeGateFeesOptions
                  }
                />
                <Dropdown
                  name={`${name}.installationFee`}
                  label="Installationsavgift"
                  options={
                    service === 'sinne' ? sinneFeesOptions : homeGateFeesOptions
                  }
                />
                {values.service === 'homegate_campaigns' && (
                  <Dropdown
                    name={`${name}.hardwareSurcharge`}
                    label="Hårdvarutillägg"
                    options={
                      service === 'sinne'
                        ? sinneFeesOptions
                        : homeGateFeesOptions
                    }
                  />
                )}
              </>
            )}
          </Grid>
        ))
      }
    </FieldArray>
  );
};
