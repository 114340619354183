import {
  Button,
  CardActions,
  CardHeader,
  Divider,
  LinearProgress,
  Typography,
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FormApi } from 'final-form';
import isBoolean from 'lodash/isBoolean';
import { Form } from 'react-final-form';

import { updateCustomer } from '@/api/customers';
import { CustomerInfoFields } from '@/components/CustomerInfoFields';
import { CustomerCategoryTypes } from '@/constants';
import { queryKeys } from '@/constants/queryKeys';
import { getChangedFormFields } from '@/helpers';
import { useSnackbar } from '@/hooks';
import useUser from '@/hooks/useUser';
import { DialogContent } from '@/modules/services/components/Configuration/components/ConfigureFiretextModal/helpers';
import { Customer } from '@/types/customers';
import { checkRolesAccess } from '@/utils/authorization';
import Yup, { makeValidation } from '@/utils/validation';

type Props = {
  onClose: () => void;
  initialValues: Customer;
};

const pickFields = [
  '@id',
  '@type',
  'address',
  'address2',
  'category',
  'city',
  'companyName',
  'debtCollection',
  'doNotDisturb',
  'email',
  'firstName',
  'fullName',
  'id',
  'invoiceEmail',
  'lastName',
  'mobile',
  'phone',
  'pin',
  'zip',
];

export const EditCustomerForm = ({ onClose, initialValues }: Props) => {
  const { roles } = useUser();

  const disablePinField = !checkRolesAccess(roles, 'customers.editPin');
  const queryClient = useQueryClient();
  const snackbar = useSnackbar();

  const updateCustomerMutation = useMutation({
    mutationFn: (values: Partial<Customer>) =>
      updateCustomer({ ...values, id: initialValues.id! }),
    onSuccess: (response) => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.getCustomerById],
      });
      if (response.data.sveaError) {
        snackbar({
          type: 'warning',
          message: `Ändringar sparade.
                OBS! ${response.data.sveaError}`,
          autoHide: false,
        });
      } else {
        snackbar({
          type: 'success',
          message: `Ändringar sparade.`,
        });
      }
      onClose();
    },
    onError: () => {
      snackbar({
        type: 'error',
        message: 'Kunde inte uppdatera kund',
      });
    },
  });

  const { isPending, isError } = updateCustomerMutation;
  // TODO: Add better types for errors
  const error = updateCustomerMutation.error as any;

  const onSubmit = (values: Partial<Customer>, form: FormApi) => {
    const fieldsToPost = getChangedFormFields(values, form, [
      'fullName',
      'firstName',
      'lastName',
    ]);
    if (isBoolean(fieldsToPost.debtCollection)) {
      fieldsToPost.debtCollection = !fieldsToPost.debtCollection;
    }
    if (values.category === CustomerCategoryTypes.PRIVATE) {
      fieldsToPost.companyName = '';
    }

    updateCustomerMutation.mutate(fieldsToPost);
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    companyName: Yup.string().when('category', {
      is: CustomerCategoryTypes.COMPANY,
      then: Yup.string().required(),
      otherwise: Yup.string().notRequired(),
    }),
    address: Yup.string().required(),
    city: Yup.string().required(),
    invoiceEmail: Yup.string().email(),
  });

  const validate = makeValidation(validationSchema);

  return (
    <>
      <Form
        onSubmit={(values, form) => onSubmit(values, form as any)}
        initialValues={{
          ...initialValues,
          debtCollection: !initialValues.debtCollection,
        }}
        validate={validate}
        render={({ handleSubmit, pristine, form }) => (
          <DialogContent>
            <CardHeader title="Kundinformation" />
            <Divider />
            <CustomerInfoFields
              disablePinField={disablePinField}
              disablePasteField
              disablePartnerField
              form={form}
            />
            <Divider />
            <CardActions style={{ justifyContent: 'space-between' }}>
              <Button
                onClick={onClose}
                variant="outlined"
                color="primary"
                disabled={isPending}
              >
                Avbryt
              </Button>
              <Button
                color="primary"
                disabled={pristine || isPending}
                onClick={handleSubmit}
                variant="contained"
              >
                Spara ändringar
              </Button>
            </CardActions>
            {isError && error && (
              <>
                <Typography color="error">
                  {error.status} - {error.statusText}
                </Typography>
                <Typography color="error">
                  {error.data['hydra:description']}
                </Typography>
              </>
            )}
            {isPending && <LinearProgress />}
          </DialogContent>
        )}
      />
    </>
  );
};
