import { CustomerCategoryTypes } from '@/constants/CustomerCategoryTypes';
import Yup from '@/utils/validation';

import { numberValidation } from '../../constants/numberValidation';
import { validationMessages } from '../../constants/validationMessages';

export const sinneValidationSchema = Yup.object().shape({
  service: Yup.string().required(),
  name: Yup.string().required(),
  customerType: Yup.number().required(),
  contractMonths: numberValidation,
  freeMonths: numberValidation,
  noticePeriod: numberValidation,
  withdrawalPeriodDays: Yup.number()
    .when('customerType', {
      is: CustomerCategoryTypes.PRIVATE,
      then: Yup.number().min(14, validationMessages.withdrawalPeriodDays),
      otherwise: Yup.number()
        .required(validationMessages.required)
        .typeError(validationMessages.typeNumber),
    })
    .typeError(validationMessages.typeNumber),
  templates: Yup.array()
    .of(
      Yup.object().shape({
        hardwareSurcharge: Yup.string(),
        installationFee: Yup.string().required(),
        price: Yup.string().required(),
        startFee: Yup.string().required(),
      }),
    )
    .required(),
});
