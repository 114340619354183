/**
 * Inspiration from here
 * https://github.com/mauricedb/cypress-react-router
 */
import { FC, useEffect } from 'react';

import { NavigateFunction, useNavigate } from 'react-router-dom';

declare global {
  interface Window {
    cyNavigate: NavigateFunction;
    // @ts-ignore
    Cypress: Cypress.Cypress;
  }
}

export const CypressHistorySupport: FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (window.Cypress) {
      window.cyNavigate = navigate;
    }
  }, [navigate]);

  return null;
};
