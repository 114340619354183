import { getObjectKeys } from '@/helpers/tsHelpers';
import { ChurnReasonServiceNames, ChurnReasons } from '@/types/settings';

export type Fees = {
  2: 'Orderregistrering - Brandlarm';
  3: 'Frakt (500g)';
  4: 'Enhetspaket';
  5: 'Specialbrev';
  6: 'SMS';
  7: 'Återanv. Enhetspaket';
  15: 'Orderregistrering - ID-Skydd';
  16: 'Stöldskyddsdekal (Tryck)';
  24: 'Registrering Autogiro';
  25: 'Registrering Värdehandlingar';
  26: 'Registrering Ångerblankett';
  27: 'Fixad Gammal Firetext';
  28: 'Förlängningsbrev';
  29: 'Registrering Uppsägningsblankett';
  34: 'Mobilt BankID - Inloggningar';
  35: 'Registrering Konkurrentblankett';
  36: 'Utskick Nytt 9V Batteri';
  42: 'Admin - Specialuppdrag';
  45: 'Orderregistrering - Företagsskydd';
  47: 'Orderregistrering - Nyckelbricka';
  49: 'Nyckelbricka (Tryck)';
  50: 'BankID Kundverifiering';
  52: 'Registrering ID-Skydd';
  53: 'Registrering Bolagsbevakning';
  54: 'Registrering Mobilt Brandlarm';
  55: 'Registrering Stöldskyddsdekal';
  56: 'Registrering Nyckelbricka';
  57: 'Registrera Homegate';
  70: 'Brevutskick 100g';
  90: 'Kundsamtal';
  91: 'Bedrägeriassistans (15 min)';
  92: 'Juridisk Asistans (15 min)';
  220: 'Sinne Huvudenhet';
  221: 'Sinne Satellite-enhet';
  240: 'Registrering Sinne';
  241: 'Registrering Satellite';
  250: 'Återanvändning Sinne Huvudenhet';
  251: 'Återanvändning Satellite-enhet';
};

export function isFlatObject(item: any): item is { [key: string]: string } {
  if (typeof item !== 'object' || item === null) {
    return false;
  }

  // Check if all values are strings
  return Object.values(item).every((value) => typeof value === 'string');
}

export function hasNestedValues(
  item: any,
): item is { [key: string]: { [key: string]: string } } {
  if (typeof item !== 'object' || item === null) {
    return false;
  }

  // Check if any value is an object with nested values
  return Object.values(item).some(
    (value) => typeof value === 'object' && value !== null,
  );
}

// TODO: Fix types in this function!
export function getTypeKey<T>(
  serviceTypesObj: T,
  type: keyof T,
  value: string,
  options?: {
    nestedKey?: string;
  },
): string | undefined {
  const item = serviceTypesObj[type] as
    | {
        [key: string]: string;
      }
    | {
        [key: string]: {
          [key: string]: string;
        };
      };

  if (isFlatObject(item)) {
    return Object.keys(item).find((key) => {
      return item[key]?.toLowerCase() === value.toLowerCase();
    });
  } else if (hasNestedValues(item)) {
    if (options?.nestedKey !== undefined) {
      const nestedObj = item[options.nestedKey] || {};

      return Object.keys(nestedObj).find((key) => {
        return nestedObj[key]?.toLowerCase() === value.toLowerCase();
      });
    } else {
      console.error(
        'For service typed with nested objects you need to provide options with nestedKey value',
      );
      return '';
    }
  } else {
    console.error(
      'Item is neither a flat object nor objects with nested values',
    );
    return '';
  }
}

export const getChurnReasonKey = (
  churnReasons: ChurnReasons,
  serviceName: ChurnReasonServiceNames,
  value: string,
) => {
  const item = churnReasons[serviceName];

  const foundKey = getObjectKeys(item).find((key) => {
    return item[key]?.toLowerCase() === value.toLowerCase();
  });

  if (foundKey && typeof foundKey === 'string') {
    return parseInt(foundKey, 10);
  }

  return undefined;
};
