import { BulkRegistrationState } from '@/state/bulkRegistrationAtom';

export const getKeyTagBulkRegistrationCardHeader = (
  status: BulkRegistrationState['bulkRegistrationStep'],
) => {
  switch (status) {
    case 'fileValidationForm':
      return {
        title: 'Steg 1 - Registreringsfil',
        subtitle:
          'Ladda upp fil här för skapandet av flera tjänster samtidigt. Formatet ska vara i formatet .xlsx',
      };
    case 'fileValidationPending':
      return {
        title: 'Filen analyseras',
        subtitle: 'Du kan lämna sidan och återkomma senare',
      };
    case 'serviceRegistrationForm':
      return {
        title: 'Steg 2 - Analys av fil',
        subtitle: 'Den uppladdade filen har nu analyserats',
      };
    case 'serviceRegistrationComplete':
      return {
        title: 'Steg 3 - Resultat',
        subtitle: 'Nedan ser du hur många tjänster som har skapats',
      };
    case 'serviceRegistrationError':
      return {
        title: 'Fel vid registrering av tjänster',
        subtitle: 'Var vänlig och kontakta IT, felet har loggats till Sentry.',
      };
    default:
      return {
        title: 'Bulkregistrering av tjänster',
      };
  }
};
