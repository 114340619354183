import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { getHandleableAlarms, getOldHandleableAlarms } from '@/api/alarms';
import { BasicAlert } from '@/components/BasicAlert';
import { BaseModal } from '@/modules/common/components';
import { SinneAlarmsHistory } from '@/modules/services/components/AlarmsHistoryModal/SinneAlarmsHistory';

import { FiretextAlarmsHistory } from './FiretextAlarmsHistory';

type Props = {
  onClose: () => void;
  serviceId: number;
  type: 'sinne' | 'firetext';
};

export function AlarmsHistoryModal({
  onClose,
  serviceId,
  type = 'firetext',
}: Props) {
  const { data: oldFiretextData, isInitialLoading: isLoadingOldFiretextData } =
    useQuery({
      queryKey: ['oldFiretextHistory', serviceId],
      queryFn: () =>
        getOldHandleableAlarms({ 'firetext.id': serviceId, status: [] }),
      enabled: type === 'firetext',
    });

  const { data, isLoading, isError, isSuccess } = useQuery({
    queryKey: [`${type}History`, serviceId],
    queryFn: () =>
      getHandleableAlarms({ [`${type}.id`]: serviceId, status: [2, 3] }),
  });

  return (
    <BaseModal
      title="Enhetslarm"
      open
      onClose={onClose}
      buttons={
        <Button onClick={onClose} color="primary" variant="outlined">
          stäng
        </Button>
      }
      children={
        <>
          {isLoading && isLoadingOldFiretextData && (
            <Grid container justifyContent="center">
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          )}
          {isError && <BasicAlert />}
          {isSuccess && !data.count && !oldFiretextData?.count && (
            <Typography>Inga larm</Typography>
          )}
          {isSuccess && (
            <>
              {type === 'firetext' && data && oldFiretextData && (
                <FiretextAlarmsHistory oldData={oldFiretextData} data={data} />
              )}
              {type === 'sinne' && data && <SinneAlarmsHistory data={data} />}
            </>
          )}
        </>
      }
    />
  );
}
