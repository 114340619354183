import isPropValid from '@emotion/is-prop-valid';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { StatsBasicTableData } from '@/modules/services/utils/stats';

type Props = {
  data: StatsBasicTableData;
};

const InnerCell = styled('div')`
  display: flex;
  align-items: center;
`;

const ColorChip = styled('div', {
  shouldForwardProp: (prop: string) => isPropValid(prop),
})`
  width: 12px;
  height: 12px;
  borderradius: 4px;
  margin-left: ${({ theme }) => theme.spacing(1)};
  background-color: ${({ color }) => color};
`;

export const BasicStatsTable = ({ data }: Props) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {data.headers.map((item) => (
              <TableCell key={item.id}>
                <InnerCell>
                  {item.label}
                  <ColorChip color={item.color} />
                </InnerCell>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow>
            {data.data.map((item, index) => (
              <TableCell key={index}>{item}</TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

BasicStatsTable.propTypes = {
  data: PropTypes.object.isRequired,
};
