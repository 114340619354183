import { getCamelCaseServiceName } from '@/helpers/tsHelpers';
import { SupportRetetionStatistics } from '@/types/stats';

import { ManagerMap } from '../types';

export const getHandlerMap = (data: Partial<SupportRetetionStatistics>) =>
  Object.entries(data).reduce((acc, [serviceName, serviceData]) => {
    serviceData.handlers.map((handler) => {
      if (!acc[handler.name]) {
        acc[handler.name] = { name: handler.name };
      }
      acc[handler.name]![getCamelCaseServiceName(serviceName)] = {
        successRate: handler.successRate,
        savedCount: handler.savedCount,
        lostCount: handler.lostCount,
      };
    });
    return acc;
  }, {} as ManagerMap);
