import React from 'react';

import {
  Autocomplete,
  CircularProgress,
  TextField,
  TextFieldProps,
} from '@mui/material';

import { BasicAlert } from '@/components/BasicAlert';
import { PartnerResponse } from '@/types/partnerCompany';

import { useGetPartnerCompanies } from '../hooks';

type Props = {
  textFieldProps?: Partial<TextFieldProps>;
  label?: string;
  required?: boolean;
  value: string | null;
  onChange: (value: Partial<PartnerResponse> | null) => void;
};

export const PartnerAutoComplete = ({
  label = 'Partner',
  textFieldProps,
  required = true,
  value,
  onChange,
}: Props) => {
  const { data, isFetching, isError } = useGetPartnerCompanies({
    pagination: false,
    properties: { id: true, name: true, tick: true, kickback: true },
  });

  const partners = data?.['hydra:member'] || [];
  const partnerOptions =
    partners?.map((partner) => partner.id.toString()) || [];

  if (isError) return <BasicAlert />;

  const getOptionLabel = (option: string) => {
    if (partners?.length) {
      const campaign = partners.find(
        (partner) => partner.id === Number(option),
      );
      return campaign?.name ? `${campaign?.id} - ${campaign?.name}` : '';
    }
    return '';
  };
  return (
    <Autocomplete
      onChange={(_, value) =>
        onChange(partners?.find((p) => p.id === Number(value)) || null)
      }
      value={value}
      disabled={isFetching}
      options={partnerOptions}
      loading={isFetching}
      getOptionLabel={getOptionLabel}
      renderInput={(params) => (
        <TextField
          {...params}
          {...textFieldProps}
          label={isFetching ? 'Laddar' : label}
          required={required}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isFetching ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};
