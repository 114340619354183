import { LinearProgress } from '@mui/material';

import { SimpleCard } from '@/components/SimpleCard';
import { AntiTheftDecalInformationSection } from '@/modules/services/components/sections';

type Props = {
  updateInProgress: boolean;
  editMode: boolean;
};
// TODO: Rename and merge AntiTheftDecalInformationSection into this component
export const AntiTheftDecalInformation = ({
  updateInProgress,
  editMode,
}: Props) => {
  return (
    <SimpleCard
      title="Skyddad"
      marginBottom="0"
      cardContent={
        <>
          <section>
            <AntiTheftDecalInformationSection editMode={editMode} />
          </section>

          {updateInProgress && <LinearProgress />}
        </>
      }
    />
  );
};
