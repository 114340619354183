import React from 'react';

import DoneIcon from '@mui/icons-material/Done';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { green } from '@mui/material/colors';
import { useQueryClient } from '@tanstack/react-query';

import * as alarmsApi from '@/api/alarms';
import { updateFiretext } from '@/api/firetext';
import { DialogActions } from '@/components/DialogActions';
import { queryKeys } from '@/constants/queryKeys';
import { usePrivateSettings } from '@/hooks';
import { useInterval } from '@/hooks/useInterval';
import { FiretextTestStatus } from '@/types/alarms';
import { Customer } from '@/types/customers';
import { FiretextItem } from '@/types/services';
import { FiretextStatuses } from '@/types/settings';

import { DialogContent } from '../helpers';
import { CustomerInfo } from './CustomerInfo';

type ApiPollingProps = {
  serviceId: number;
  onResponse: (data: FiretextTestStatus[]) => void;
  onError: (error: string) => void;
};

const ApiPolling = ({ serviceId, onResponse, onError }: ApiPollingProps) => {
  useInterval(() => {
    checkTestStatus();
  }, 2000);

  async function checkTestStatus() {
    try {
      const response = await alarmsApi.getFiretextTestStatus(serviceId);

      onResponse(response);
    } catch (error) {
      onError('Testet misslyckades!');
    }
  }

  return null;
};

type Props = {
  service: FiretextItem;
  customer: Customer;
  onClose: () => void;
  queryKey: Array<any>;
};

export const Step5 = ({ onClose, service, customer, queryKey }: Props) => {
  const queryClient = useQueryClient();

  const { servicesStatuses } = usePrivateSettings();

  const firetextStatuses = servicesStatuses.firetext;
  const alarmStatuses = servicesStatuses.alarm;

  const [testReceived, setTestReceived] = React.useState(false);
  const [testError, setTestError] = React.useState<string | null>(null);

  const [statusUpdateLoading, setStatusUpdateLoading] = React.useState(false);
  const [statusUpdateError, setStatusUpdateError] = React.useState<
    string | null
  >(null);
  const [statusUpdateSuccess, setStatusUpdateSuccess] = React.useState(false);

  React.useEffect(
    () => () => {
      queryClient.refetchQueries({
        queryKey: [queryKeys.getServiceConfigurationList],
      });
    },
    [],
  );
  function handlePollResponse(data: FiretextTestStatus[]) {
    if (data?.length > 0) {
      setTestReceived(true);

      handleUpdateFiretextAndAlarmStatus(data[0]!);
    }
  }

  function handlePollError(message: string) {
    setTestError(message);
  }

  function getAlarmStatus() {
    if (statusUpdateSuccess) {
      return alarmStatuses[10];
    }

    if (testReceived) {
      return alarmStatuses[0];
    }

    return 'null';
  }

  const refetchFiretext = () => {
    queryClient.refetchQueries({ queryKey });
  };

  const setAlarmStatusToTest = async (id: number) =>
    alarmsApi.updateAlarmStatus({
      alarmId: id,
      // @ts-ignore
      status: '10',
    });

  const setFiretextStatusToSent = async () => {
    const today = new Date();
    const isoString = today.toISOString();

    await updateFiretext({
      firetextId: service.id,
      // @ts-ignore
      status: 2,
      sentDate: isoString,
    });
  };

  async function handleUpdateFiretextAndAlarmStatus(alarm: FiretextTestStatus) {
    setStatusUpdateLoading(true);
    try {
      await setAlarmStatusToTest(alarm.id);
      await setFiretextStatusToSent();
      refetchFiretext();

      setStatusUpdateLoading(false);
      setStatusUpdateSuccess(true);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('ERROR', error);
      setStatusUpdateLoading(false);
      setStatusUpdateError('Uppdateringen misslyckades');
    }
  }

  return (
    <>
      <DialogContent>
        <CustomerInfo customer={customer} />

        <Box
          component="section"
          sx={(theme) => ({
            paddingBottom: theme.spacing(1),
          })}
        >
          <Typography
            sx={(theme) => ({
              marginBottom: theme.spacing(2),
            })}
          >
            Aktuell enhetsstatus:{' '}
            <strong>
              {firetextStatuses[service.status as keyof FiretextStatuses]}
            </strong>
          </Typography>

          <Typography
            sx={(theme) => ({
              marginBottom: theme.spacing(2),
            })}
          >
            Aktuell larmstatus: <strong>{getAlarmStatus()}</strong>
          </Typography>

          <Divider />
        </Box>

        {!testReceived && (
          <Typography>
            Testa enheten genom att trycka på test knappen!
          </Typography>
        )}

        <Grid
          container
          sx={(theme) => ({
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
          })}
          spacing={1}
          alignItems="center"
        >
          <Grid item>
            <Typography fontWeight={600}>
              {!testReceived
                ? 'Väntar på att ett test ska tas emot:'
                : 'Test genomförd!'}
            </Typography>
          </Grid>

          <Grid item>
            {!testReceived && !testError && (
              <>
                <ApiPolling
                  serviceId={service.id}
                  onResponse={handlePollResponse}
                  onError={handlePollError}
                />
                <CircularProgress size={20} />
              </>
            )}

            {testReceived && (
              <DoneIcon style={{ color: green[500] }} fontSize="small" />
            )}

            {testError && <ErrorIcon color="error" fontSize="small" />}
          </Grid>
        </Grid>

        {testError && <Typography color="error">{testError}</Typography>}

        {testReceived && (
          <Grid
            container
            sx={(theme) => ({
              paddingTop: theme.spacing(1),
              paddingBottom: theme.spacing(1),
            })}
            spacing={1}
            alignItems="center"
          >
            <Grid item>
              <Typography fontWeight={600}>
                Larmstatus uppdaterad till "Test - Innan utskick"
              </Typography>
            </Grid>

            <Grid item>
              {statusUpdateLoading && <CircularProgress size={20} />}
              {statusUpdateError && (
                <ErrorIcon color="error" fontSize="small" />
              )}
              {statusUpdateSuccess && (
                <DoneIcon style={{ color: green[500] }} fontSize="small" />
              )}
            </Grid>
          </Grid>
        )}

        {statusUpdateError && (
          <Typography color="error" gutterBottom>
            {statusUpdateError}
          </Typography>
        )}

        {statusUpdateSuccess && (
          <>
            <Grid
              container
              sx={(theme) => ({
                paddingTop: theme.spacing(1),
                paddingBottom: theme.spacing(1),
              })}
              spacing={1}
              alignItems="center"
            >
              <Grid item>
                <Typography fontWeight={600}>
                  Enhetsstatus uppdaterad till "Skickad"
                </Typography>
              </Grid>

              <Grid item>
                <DoneIcon style={{ color: green[500] }} fontSize="small" />
              </Grid>
            </Grid>
            <Grid
              container
              sx={(theme) => ({
                paddingTop: theme.spacing(1),
                paddingBottom: theme.spacing(1),
              })}
              spacing={1}
              alignItems="center"
            >
              <Grid item>
                <Typography fontWeight={600}>Då ska allt vara klart</Typography>
              </Grid>

              <Grid item>
                <DoneIcon style={{ color: green[500] }} fontSize="small" />
              </Grid>
            </Grid>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={onClose}
          disabled={!statusUpdateSuccess}
        >
          Stäng
        </Button>
      </DialogActions>
    </>
  );
};
