export const CustomerCategoryTypes = {
  COMPANY: 2,
  PRIVATE: 3,
};

export function customerCategoryTypeToString(
  customerCategoryType: number,
): string {
  switch (customerCategoryType) {
    case CustomerCategoryTypes.COMPANY:
      return 'Företag';

    case CustomerCategoryTypes.PRIVATE:
      return 'Privat';

    default:
      return 'Unknown';
  }
}

// Supposed to be used with Dropdown fields
export const customerCategoryTypeOptions = Object.values(CustomerCategoryTypes)
  .map((value) => ({
    id: value,
    value,
    text: customerCategoryTypeToString(value),
  }))
  .sort((a, b) => {
    if (a.text < b.text) {
      return -1;
    }
    if (a.text > b.text) {
      return 1;
    }
    return 0;
  });
