import { useMutation } from '@tanstack/react-query';

import { exportServiceStats, getServiceStats } from '@/api/services';
import { getFormattedDate } from '@/helpers/dates';
import { usePrivateSettings, useSnackbar } from '@/hooks';
import { CamelCaseServiceName } from '@/types/services';

export type Resolution = 'daily' | 'weekly' | 'monthly' | 'quarter' | 'yearly';

export type ExportStatsParams = {
  start: Date;
  end: Date;
  resolution: Resolution;
  bdm: number;
  serviceName: CamelCaseServiceName;
  format?: 'xlsx';
};

export const useExportBdmStats = () => {
  const { servicesNames } = usePrivateSettings();
  const snackbar = useSnackbar();
  return useMutation({
    mutationFn: ({
      format = 'xlsx',
      serviceName,
      ...args
    }: ExportStatsParams) => {
      const startAsString = getFormattedDate(args.start);
      const endAsString = getFormattedDate(args.end);

      const filename = `${
        servicesNames[serviceName]
      }_Statistik_${startAsString}_${endAsString}.${format || 'xlsx'}`;
      const formattedArgs = {
        ...args,
        format,
        serviceName,
        start: startAsString!,
        end: endAsString!,
        filename,
      };

      return exportServiceStats(formattedArgs);
    },
    onError: (error) => {
      console.error(error);
      snackbar({ message: 'Kunde inte skapa export', type: 'error' });
    },
  });
};
