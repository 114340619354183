import { useQuery } from '@tanstack/react-query';

import { GetPartnerParams, fetchPartnersListV2 } from '@/api/partners';
import { generateKeys } from '@/constants/queryKeys';

export const partnerCompaniesQueryKey = generateKeys('getPartners');

export const useGetPartnerCompanies = (params: GetPartnerParams) => {
  return useQuery({
    queryKey: partnerCompaniesQueryKey.filteredList({ ...params }),
    queryFn: async () => fetchPartnersListV2(params),
  });
};
