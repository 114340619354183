import { useQuery } from '@tanstack/react-query';

import httpClient from '@/utils/httpClient';

export const useLatestNote = (customer: string, type = '') => {
  return useQuery({
    queryKey: [customer, type],

    queryFn: async () => {
      const { data } = await httpClient.get('/notes', {
        params: {
          customer,
          type,
          'order[createdAt]': 'desc',
          properties: {
            text: true,
            createdAt: true,
          },
        },
      });

      return data['hydra:totalItems'] === 0 ? false : data['hydra:member'][0];
    },
  });
};
