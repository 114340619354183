import React from 'react';

import {
  Alert,
  AlertTitle,
  Button,
  CardActions,
  CardContent,
  Divider,
  LinearProgress,
} from '@mui/material';
import { useSelector } from '@xstate/react';

import { LabelContentAndAction } from '@/components/LabelContentAndAction';
import { getObjectKeys } from '@/helpers/tsHelpers';
import { CopyButton } from '@/modules/common/components';
import { SinneConfigContext } from '@/modules/sinne/components/sinneConfigFlow/context/SinneConfigContextProvider';
import { transformSinneConfigData } from '@/modules/sinne/components/sinneConfigFlow/helpers/transformSinneServiceData';

type SinneConfigTranslations = {
  reseller: 'Återförsäljare';
  salesRep: 'Säljare';
  price: 'Månadsavgift';
  campaign: 'Kampanj';
  customerId: 'Snabbsök';
  fullName: 'Namn';
  companyName: 'Företag';
  address: 'Adress';
  zip: 'Postnummer';
  city: 'Stad';
  email: 'E-Post';
  mobile: 'SMS';
};

export const MailPreparation = () => {
  const machine = React.useContext(SinneConfigContext);
  const state = useSelector(machine, (state) => state);
  const context = useSelector(machine, (state) => state.context);

  const { reseller, campaign, sinneServiceData } = context;
  const isLoading =
    state.matches('MAIL_PREPARATION.campaign.loading') ||
    state.matches('MAIL_PREPARATION.reseller.loading');

  const isError =
    state.matches('MAIL_PREPARATION.campaign.error') ||
    state.matches('MAIL_PREPARATION.reseller.error');

  const missingCampaignError = state.matches(
    'MAIL_PREPARATION.campaign.error.missingCampaign',
  );

  const translations: SinneConfigTranslations = {
    reseller: 'Återförsäljare',
    salesRep: 'Säljare',
    price: 'Månadsavgift',
    campaign: 'Kampanj',
    customerId: 'Snabbsök',
    fullName: 'Namn',
    companyName: 'Företag',
    address: 'Adress',
    zip: 'Postnummer',
    city: 'Stad',
    email: 'E-Post',
    mobile: 'SMS',
  };

  if (isLoading) {
    return <LinearProgress />;
  }

  if (isError) {
    return (
      <Alert severity="error">
        <AlertTitle>Något gick fel</AlertTitle>
        {missingCampaignError && 'Kampanj saknas på tjänsten'}
      </Alert>
    );
  }

  if (sinneServiceData && campaign) {
    const dataWitResellerName = {
      ...sinneServiceData,
      reseller,
      campaign,
    };

    const transformedSinneServiceData =
      transformSinneConfigData(dataWitResellerName);

    let keys = getObjectKeys(translations);

    return (
      <>
        <CardContent>
          {keys.map((key) => (
            <React.Fragment key={key}>
              <LabelContentAndAction
                label={translations[key]}
                content={transformedSinneServiceData[key] ?? '-'}
                action={
                  <CopyButton
                    value={transformedSinneServiceData[key] as string}
                  />
                }
              />
              {key === 'campaign' && <Divider sx={{ my: 2 }} />}
            </React.Fragment>
          ))}
        </CardContent>
        <Divider />
        <CardActions>
          <Button disabled={isLoading} onClick={() => machine.send('PROCEED')}>
            Nästa
          </Button>
        </CardActions>
      </>
    );
  }
  return null;
};
