import { Alert, Grid } from '@mui/material';

type Props = {
  pin: string;
  customerId: string;
};

export const ActivateLongFraudProtection = ({ pin, customerId }: Props) => {
  // const classes = useCommonStyles();
  // const [file, setFile] = React.useState();

  // const handleActivate = () => {
  //   const payload = {
  //     pin: pin,
  //     ceaseInterval: 1,
  //     customerId: customerId,
  //   };

  //   mutation.mutate(payload);
  // };

  // const mutation = useMutation((payload) => {
  //   return activateFraudProtection(payload);
  // });

  // const onFileChange = (e) => {
  //   console.log('FILE:', e.target.files[0]);

  //   setFile(e.target.files[0]);
  // };

  // const onSubmit = (e) => {
  //   e.preventDefault();
  //   console.log('SUBMIT', e);
  // };

  return (
    <>
      <Grid item xs={12}>
        <Alert severity="error">Hårdspärr är ännu inte implementerad!</Alert>
      </Grid>

      {/* {mutation.isLoading && (
        <Grid item xs={12}>
          <Typography className={classes.title} variant="h2">
            Aktiverar...
          </Typography>
          <LinearProgress />
        </Grid>
      )}

      {!mutation.isSuccess && !mutation.isLoading && (
        <>
          <Grid item xs={12}>
            <Typography variant="h2" className={classes.title}>
              Aktiv bedrägerispärr <b>EJ</b> funnen på: {pin}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" className={classes.span}>
              En aktiv bedrägerispärr har <b>EJ</b> hittats på personnummer{' '}
              {pin}. Välj ett av alternativen nedan för att fortsätta. För att
              gå vidare med att aktivera en hårdspärr, krävs det att en
              polisrapport laddas upp.
            </Typography>
          </Grid>
        </>
      )}

      {mutation.isSuccess && (
        <Grid item xs={12}>
          <Alert severity="success">
            <Typography variant="h2" className={classes.title}>
              Bedrägerispärr aktiverad på personnummer: {pin}!
            </Typography>
            <Typography variant="body1" className={classes.span}>
              En tillfällig bedrägerispärr som gäller i högst 14 dagar har nu
              blivit <b>aktiverad</b> på personnummer {pin} hos samtliga
              rikstäckande kreditupplysningsföretag i Sverige, dvs. Bisnode,
              Creditsafe, Decidas, Syna och UC men det kan ta upp till 24 timmar
              innan övriga kreditupplysningsföretag har hunnit registrera{' '}
              <b>aktiveringen</b>.
            </Typography>
          </Alert>
        </Grid>
      )}

      {mutation.isError && (
        <Grid item xs={12}>
          <Alert severity="error">
            Något gick fel vid aktiveringen av bedrägerispärr. Kontrollera
            internetanslutningen. Om felet kvarstår, kontakta IT.
          </Alert>
        </Grid>
      )}

      <Grid className={clsx(!file && classes.hidden)} item xs={12}>
        <Typography variant="h4">Vald fil:</Typography>
        <Typography variant="body1">{file?.name}</Typography>
      </Grid>

      <Grid
        container
        item
        spacing={2}
        justifyContent="flex-start"
        className={classes.buttonContainer}
      >
        {/* VART SKA FILEN LADDAS UPP */}
      {/* <form onSubmit={onSubmit}>
          <input
            accept="application/pdf"
            type="file"
            required
            onChange={onFileChange}
            id="police-report"
            style={{ display: 'none' }}
          />
          <label htmlFor="police-report">
            <Button component="span" variant="outlined">
              Välj fil
            </Button>
          </label>
          <Button variant="outlined" type="submit">
            Ladda upp
          </Button>
        </form> */}
      {/* Aktivera när uppladdning är slutförd */}
      {/* {mutation.isIdle && (
          <Button variant="outlined" onClick={handleActivate}>
            Aktivera
          </Button>
        )}
      </Grid>  */}
    </>
  );
};
