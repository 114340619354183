import { z } from 'zod';

import { RmaCaseList } from '@/modules/rma';
import { ServiceList, Stats } from '@/modules/services';
import { KeyFigures } from '@/modules/services';
import { Configuration } from '@/modules/services';
import { FileListCard } from '@/modules/services';
import { KeyTagBulkRegistration } from '@/modules/services/components/KeyTag/components';
import { KeyTagServiceList } from '@/modules/services/components/KeyTag/components/KeyTagServiceList';
import { ProtectionItem, ServiceName } from '@/types/services';
import { DetailedStatsDistributionToDisplay } from '@/types/stats';
import { Tabmap } from '@/types/tabs';

export const KEY_TAG_AND_STICKER_CODE_VALIDATION_REGEX = /^[a-zA-Z]+[0-9]+$/;

export const MainTabsMap = {
  STATS: 0,
  CUSTOMERS: 1,
  CONFIG: 2,
  KEYFIGURES: 3,
};

export const SERVICE_STATUS_COLORS_MAP: Record<
  DetailedStatsDistributionToDisplay,
  string
> = {
  Brutto: '#6081c0',
  Netto: '#36af7b',
  Ånger: '#eab12a',
  Makulerad: '#e0175b',
  Goodwill: '#f6efe8',
  Churn: '#000000',
};

export const SERVICES_WITH_CONFIG = [
  'firetext',
  'protection',
  'co-protection',
  'homegate',
  'sinne',
] as const;

export const servicesWithConfigEnum = z.enum(SERVICES_WITH_CONFIG);
export type ServiceWithConfig = z.infer<typeof servicesWithConfigEnum>;

type ServiceTabs = {
  [key in ServiceName]: Tabmap[];
};

export const SERVICE_TABS: ServiceTabs = {
  firetext: [
    {
      title: 'Statistik',
      view: ({ serviceName }: { serviceName: ServiceName }) => (
        <Stats serviceName={serviceName} />
      ),
      authRule: 'services.stats',
    },
    {
      title: 'Lista',
      view: () => <ServiceList />,
      authRule: 'services.customers',
    },
    {
      title: 'Konfigurera',
      view: ({ serviceName }: { serviceName: ServiceName }) => (
        <Configuration serviceName={serviceName} />
      ),
      authRule: 'services.configuration',
    },
  ],
  'key-tag': [
    {
      title: 'Statistik',
      view: ({ serviceName }: { serviceName: ServiceName }) => (
        <Stats serviceName={serviceName} />
      ),
      authRule: 'services.stats',
    },
    {
      title: 'Lista',
      view: () => <KeyTagServiceList />,
      authRule: 'services.customers',
    },
    {
      title: 'Saknar seriekod',
      view: () => <KeyTagServiceList serviceHasKeyCode={false} />,
      authRule: 'services.customers',
    },
  ],
  protection: [
    {
      title: 'Statistik',
      view: ({ serviceName }: { serviceName: ServiceName }) => (
        <Stats serviceName={serviceName} />
      ),
      authRule: 'services.stats',
    },
    {
      title: 'Lista',
      view: () => <ServiceList />,
      authRule: 'services.customers',
    },
    {
      title: 'Konfigurera',
      view: ({ serviceName }: { serviceName: ServiceName }) => (
        <Configuration serviceName={serviceName} />
      ),
      authRule: 'services.configuration',
    },
    {
      title: 'Filer',
      view: ({ serviceDetails }: { serviceDetails: ProtectionItem }) => (
        <FileListCard serviceDetails={serviceDetails} />
      ),
      authRule: 'protectedFiles.view',
    },
  ],
  'co-protection': [
    {
      title: 'Statistik',
      view: ({ serviceName }: { serviceName: ServiceName }) => (
        <Stats serviceName={serviceName} />
      ),
      authRule: 'services.stats',
    },
    {
      title: 'Lista',
      view: () => <ServiceList />,
      authRule: 'services.customers',
    },
    {
      title: 'Konfigurera',
      view: ({ serviceName }: { serviceName: ServiceName }) => (
        <Configuration serviceName={serviceName} />
      ),
      authRule: 'services.configuration',
    },
  ],
  sticker: [
    {
      title: 'Statistik',
      view: ({ serviceName }: { serviceName: ServiceName }) => (
        <Stats serviceName={serviceName} />
      ),
      authRule: 'services.stats',
    },
    {
      title: 'Lista',
      view: () => <ServiceList />,
      authRule: 'services.customers',
    },
  ],
  homegate: [
    {
      title: 'Statistik',
      view: ({ serviceName }: { serviceName: ServiceName }) => (
        <Stats serviceName={serviceName} />
      ),
      authRule: 'services.stats',
    },
    {
      title: 'Lista',
      view: () => <ServiceList />,
      authRule: 'services.customers',
    },
    {
      title: 'Konfigurera',
      view: ({ serviceName }: { serviceName: ServiceName }) => (
        <Configuration serviceName={serviceName} />
      ),
      authRule: 'services.configuration',
    },
  ],
  sinne: [
    {
      title: 'Statistik',
      view: ({ serviceName }: { serviceName: ServiceName }) => (
        <Stats serviceName={serviceName} />
      ),
      authRule: 'services.stats',
    },
    {
      title: 'Lista',
      view: () => <ServiceList />,
      authRule: 'services.customers',
    },
    {
      title: 'Nyckeltal',
      view: () => <KeyFigures />,
      authRule: 'services.customers',
    },
    {
      title: 'Konfigurera',
      view: ({ serviceName }: { serviceName: ServiceName }) => (
        <Configuration serviceName={serviceName} />
      ),
      authRule: 'services.configuration',
    },
    {
      title: 'RMA',
      view: () => <RmaCaseList />,
      authRule: 'rma.view.list',
    },
  ],
};
