import { ApiCollectionResponse } from '@/types/common';
import {
  ArvatoInvoiceMigrationProcess,
  Cost,
  CostsResponse,
  SendBulkCostResponse,
} from '@/types/costs';
import httpClient from '@/utils/httpClient';

export type SendBulkCostsParams = {
  customerIds: string[];
  quantity: number;
  type: number;
  comment: string;
};
export async function sendBulkCosts({
  customerIds,
  quantity,
  type,
  comment,
}: SendBulkCostsParams) {
  const { data } = await httpClient.post<SendBulkCostResponse>(
    '/rpc/bulk.costs',
    {
      customerIds,
      quantity,
      type,
      comment,
    },
  );

  return data;
}

export type CreateCostPayload = {
  type: number;
  comment?: string;
  customer?: string;
};

export async function createCost(payload: CreateCostPayload) {
  return await httpClient.post<Cost>('/costs', payload);
}

export async function deleteCost({ costId }: { costId: number }) {
  const { data } = await httpClient.delete(`/costs/${costId}`);

  return data;
}

export type GetCostsParams = {
  customer: string;
  page: number;
  perPage: number;
  order: { createdAt: 'asc' | 'desc' };
};

export async function getCosts(params: GetCostsParams) {
  const { data } = await httpClient.get<CostsResponse>('/costs', {
    params: {
      ...params,
    },
  });

  return data;
}

type getInvoiceCostRoyaltyType = { start: string; end: string };

export const getInvoiceCosts = async ({
  start,
  end,
}: getInvoiceCostRoyaltyType) => {
  const { data } = await httpClient.get('/economy/costs', {
    params: {
      start,
      end,
    },
  });

  return data;
};

export const getInvoiceRoyalties = async () => {
  const { data } = await httpClient.get('/economy/royalties', {});

  return data;
};

export const getArvatoInvoiceMigrationProcess = async () => {
  const { data } = await httpClient.get<
    ApiCollectionResponse<ArvatoInvoiceMigrationProcess>
  >('/arvato_invoice_migration_processes');

  return data;
};

export const insertArvatoInvoices = async (customerIds?: number[]) => {
  const { data } = await httpClient.post('/arvato/insert-invoices', {
    ...(customerIds ? { customerIds } : {}),
  });

  return data;
};
