import { useQueries } from '@tanstack/react-query';

import httpClient from '@/utils/httpClient';

import { ManagedCustomer } from '../types';

export const useManagedCustomersArr = (managedCustomers: ManagedCustomer[]) => {
  const customers = useQueries({
    queries: managedCustomers.map((customer) => ({
      queryKey: ['customerArr', customer.id],
      queryFn: async () => {
        const { data } = await httpClient.get(`/customers/${customer.id}/ARR`);
        return data;
      },
      staleTime: 60 * 1000,
    })),
  });

  const isLoading = customers.some((customer) => customer.isLoading);

  const isError = customers.some((customer) => customer.isError);
  const isSuccess = customers.every((customer) => customer.isSuccess);
  const data = isSuccess ? customers.map((customer) => customer.data) : [];

  return { data, isLoading, isError, isSuccess };
};
