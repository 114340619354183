import { Grid } from '@mui/material';

import { Dropdown } from '@/components/form';
import { usePrivateSettings } from '@/hooks/usePrivateSettings';
import { fieldRequired } from '@/utils/validation';

export const MonitoringPlanSection = () => {
  const { coProtectionMonitoringOptions } = usePrivateSettings();

  return (
    <Grid container>
      <Grid item xs={4}>
        <Dropdown
          required
          margin="none"
          label="Max manuellt bevakade företag"
          name="monitoringPlan"
          options={coProtectionMonitoringOptions}
          fieldProps={{
            validate: fieldRequired,
          }}
        />
      </Grid>
    </Grid>
  );
};
