import { Alert, AlertTitle, Typography } from '@mui/material';
import { captureException } from '@sentry/react';

import { getObjectKeys } from '@/helpers/tsHelpers';

const ErrBody = ({ data }: any) => (
  <>
    {data?.message && <Typography variant="body1">{data?.message}</Typography>}\
    {getObjectKeys(data).map((key) => (
      <Typography variant="body1" key={key as string}>
        {`${key as string}: ${data[key]}`}
      </Typography>
    ))}
  </>
);

export const TemporaryFraudProtectionError = ({ error }: { error: any }) => {
  console.error('ERROR', error); // intentional log
  captureException(error);
  const getErrData = (status: number, data: any) => {
    switch (status) {
      case 400:
        return data?.errors;
      case 422:
        return data?.errors;
      case 500:
        return data?.data;
      default:
        return data;
    }
  };

  const errTitle = 'Något gick fel',
    statusText = error?.statusText,
    errData = getErrData(error.status, error?.data);

  return (
    <Alert severity="error">
      <AlertTitle>{errTitle}</AlertTitle>
      <AlertTitle>{statusText}</AlertTitle>
      <ErrBody data={errData} />
    </Alert>
  );
};
