import React from 'react';

import { ListItem, ListItemText, Typography } from '@mui/material';

import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { Campaign } from '@/types/campaigns';
import { PascalCasedServiceNames } from '@/types/services';
import { FEATURE_FLAGS } from '@/utils/featureFlags';

type PassedCampaign = Pick<
  Campaign,
  'name' | 'contractMonths' | 'freeMonths' | 'noticePeriod'
>;

type Props = {
  campaign: PassedCampaign | string;
  serviceType: PascalCasedServiceNames;
};

const getSecondaryTextElement = (label: string, text: string) => (
  <Typography
    variant="body1"
    component="span"
    color="textPrimary"
    style={{ display: 'block' }}
  >
    {`${label}: ${text}`}
  </Typography>
);

export const CampaignInfoListItem = ({ campaign, serviceType }: Props) => {
  const protectionCampaignV2Enabled = useFeatureFlag({
    name: FEATURE_FLAGS.protectionCampaignV2.name,
  });

  if (!campaign) return null;

  if (typeof campaign === 'string' && serviceType === 'Protection') {
    return (
      <ListItem>
        <ListItemText
          primary={
            <b>
              {protectionCampaignV2Enabled ? 'Legacy kampanj' : 'Kampanj'}:{' '}
              {campaign}
            </b>
          }
        />
      </ListItem>
    );
  }

  if (typeof campaign === 'string') {
    return (
      <ListItem>
        <ListItemText primary={<b>Kampanj: {campaign}</b>} />
      </ListItem>
    );
  }

  return (
    <ListItem>
      <ListItemText
        primary={
          <>
            <b>Kampanj: </b>
          </>
        }
        secondary={
          <>
            {getSecondaryTextElement('Namn', campaign.name)}
            {getSecondaryTextElement(
              'Bindningstid',
              `${campaign.contractMonths} månader`,
            )}
            {getSecondaryTextElement(
              'Uppsägningstid',
              `${campaign.noticePeriod} månader`,
            )}
            {getSecondaryTextElement(
              'Gratismånader',
              `${campaign.freeMonths} månader`,
            )}
          </>
        }
      />
    </ListItem>
  );
};
