import React, { useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import * as costsApi from '@/api/costs';
import { Can } from '@/components/Can';
import { ConfirmationDialog } from '@/components/ConfirmationDialog';
import { Pagination } from '@/components/Pagination';
import { SimpleTable } from '@/components/SimpleTable';
import { PureDropdown } from '@/components/form';
import { queryKeys } from '@/constants/queryKeys';
import { getFormattedDate } from '@/helpers/dates';
import { usePrivateSettings, useSnackbar } from '@/hooks';
import { useAuth } from '@/hooks/useAuth';
import { Cost } from '@/types/costs';
import { CostTypes } from '@/types/settings';

export const Costs = () => {
  const snackbar = useSnackbar();

  const { id: customerId } = useParams();
  const queryClient = useQueryClient();

  const { privateSettings } = usePrivateSettings();

  const costTypes = privateSettings?.types.cost ?? ({} as CostTypes);
  const { getAuthenticatedUserRoles } = useAuth();
  const userRoles = getAuthenticatedUserRoles();

  const [page, setPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(100);
  const [total, setTotal] = React.useState(0);
  const [sortOrder, setSortOrder] = React.useState<'asc' | 'desc'>('desc');
  const queryKey = [
    queryKeys.getCosts,
    customerId,
    page,
    perPage,
    { order: { createdAt: sortOrder } },
  ];

  const [deleteConfirmationVisible, setDeleteConfirmationVisible] =
    useState(false);
  const [costToDelete, setCostToDelete] = useState<Cost | null>(null);

  const orderOptions = [
    { id: 1, value: 'desc', text: 'Fallande' },
    {
      id: 2,
      value: 'asc',
      text: 'Stigande',
    },
  ];

  const {
    data: costs,
    isLoading,
    isError,
  } = useQuery({
    queryKey: queryKey,

    queryFn: async () => {
      const data = await costsApi.getCosts({
        customer: `/customers/${customerId}`,
        page: page + 1,
        perPage,
        order: { createdAt: sortOrder },
      });
      setTotal(data['hydra:totalItems']);
      return data['hydra:member'];
    },
  });

  const deleteMutation = useMutation({
    mutationFn: costsApi.deleteCost,
    onSuccess: (_, variables) => {
      snackbar({
        type: 'success',
        message: 'Kostnad borttagen',
      });
      queryClient.setQueryData(queryKey, (oldData: Cost[]) => {
        return (oldData as Cost[]).filter(
          (cost) => cost.id !== variables.costId,
        );
      });
    },
    onError: () => {
      snackbar({
        type: 'error',
        message: 'Det gick inte att ta bort kostnaden',
      });
    },
    onSettled: () => {
      setCostToDelete(null);
      setDeleteConfirmationVisible(false);
    },
  });

  function initializeCostDelete(cost: Cost) {
    setDeleteConfirmationVisible(true);
    setCostToDelete(cost);
  }

  const deleteHandler = () => {
    deleteMutation.mutate({ costId: costToDelete!.id });
  };

  const columns = [
    {
      label: 'Datum',
      renderer: (row: Cost) =>
        getFormattedDate(row.createdAt as string, 'yyyy-MM-dd'),
    },
    {
      label: 'Typ',
      renderer: (row: Cost) => costTypes[row.type as keyof CostTypes],
    },
    {
      label: 'Pris (exkl moms)',
      renderer: (row: Cost) => row.price,
    },
    {
      label: 'Beskrivning',
      renderer: (row: Cost) => row.comment,
    },
    {
      label: '',
      renderer: (row: Cost) => (
        <Can
          userRoles={userRoles}
          action="customers.costDelete"
          yes={() => (
            <IconButton
              aria-label="delete"
              disabled={deleteMutation.isPending}
              onClick={() => initializeCostDelete(row)}
              size="large"
            >
              <StyledDeleteIcon fontSize="small" />
            </IconButton>
          )}
        />
      ),
    },
  ];

  return (
    <>
      <Card>
        <Grid
          container
          style={{ alignItems: 'center', padding: '0.5rem 1rem' }}
        >
          <Grid item xs>
            <CardHeader title="Kostnader" />
          </Grid>
          <Grid item xs={12} md={3}>
            <PureDropdown
              size="small"
              labelId="type-select-label"
              label="Sortera efter datum"
              options={orderOptions}
              value={sortOrder}
              onChange={(e) => {
                const event = e as SelectChangeEvent<HTMLInputElement>;
                setSortOrder(event.target.value as 'asc' | 'desc');
              }}
            />
          </Grid>
        </Grid>

        <Divider />
        <CardContent>
          <Pagination
            count={total}
            page={page}
            rowsPerPage={perPage}
            onPageChange={(_, page) => setPage(page)}
            onRowsPerPageChange={(event) =>
              setPerPage(parseInt(event.target.value, 10))
            }
          />
          {isLoading ? (
            <LinearProgress />
          ) : isError ? (
            <Alert severity="error">Något gick fel.</Alert>
          ) : (
            <>{costs && <SimpleTable columns={columns} rows={costs} />}</>
          )}
          <Pagination
            count={total}
            page={page}
            rowsPerPage={perPage}
            onPageChange={(_, page) => setPage(page)}
            onRowsPerPageChange={(event) =>
              setPerPage(parseInt(event.target.value, 10))
            }
          />
        </CardContent>
      </Card>

      <ConfirmationDialog
        isOpened={deleteConfirmationVisible}
        onClose={() => setDeleteConfirmationVisible(false)}
        onConfirm={deleteHandler}
        dialogTitle="Ta bort kostnad"
        dialogText={
          <Typography component="span">
            Vill du ta bort kostnaden{' '}
            {costToDelete && (
              <strong>{costTypes[costToDelete.type as keyof CostTypes]}</strong>
            )}{' '}
            ?
          </Typography>
        }
        cancellationButtonText="Avbryt"
        confirmationButtonText="Bekräfta"
        inProgress={deleteMutation.isPending}
      />
    </>
  );
};

export const StyledDeleteIcon = styled(DeleteIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));
