import React from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  LinearProgress,
  MenuItem,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { BasicAlert } from '@/components/BasicAlert';
import { DialogActions } from '@/components/DialogActions';
import { queryKeys } from '@/constants/queryKeys';
import { useSnackbar } from '@/hooks';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { Textfield } from '@/modules/components';
import { ResellerItem } from '@/types/resellers';
import { FEATURE_FLAGS } from '@/utils/featureFlags';

import { useDeleteReseller } from '../../hooks/useDeleteReseller';
import { useGetBdmOptions } from '../../hooks/useGetBdmOptions';
import { useResetPassword } from '../../hooks/useResetPassword';
import { useUpdateReseller } from '../../hooks/useUpdateReseller';
import { validationSchema } from './validationSchema';

type FormValues = z.infer<typeof validationSchema>;

type Props = {
  data: ResellerItem;
};

export const EditReseller = ({ data }: Props) => {
  const [confirmResendPassword, setConfirmResendPassword] =
    React.useState(false);

  const deleteResellerEnabled = useFeatureFlag({
    name: FEATURE_FLAGS.deleteReseller.name,
  });
  const queryClient = useQueryClient();
  const snackbar = useSnackbar();
  const updateResellerMutation = useUpdateReseller();
  const resetPasswordMutation = useResetPassword();
  const { options: bdmOptions, status } = useGetBdmOptions();

  const initialBdm = data.businessDevelopmentManagerInfo.id
    ? `/users/${data.businessDevelopmentManagerInfo.id}`
    : '';

  const {
    handleSubmit,
    register,
    formState: { errors, defaultValues },
  } = useForm<FormValues>({
    defaultValues: {
      username: data.username,
      email: data.email,
      name: data.name,
      businessDevelopmentManager: initialBdm,
    },
    resolver: zodResolver(validationSchema),
  });

  const onSubmit: SubmitHandler<FormValues> = (values) => {
    updateResellerMutation.mutate(
      { id: data.id, ...values },
      {
        onSuccess: async (data) => {
          queryClient.invalidateQueries({
            queryKey: queryKeys.reseller.detail(data.id),
          });
          snackbar({
            type: 'success',
            message: 'Återförsäljaren uppdaterades',
          });
        },
        onError: () => {
          snackbar({
            type: 'error',
            message: 'Kunde inte uppdatera återförsäljaren',
          });
        },
      },
    );
  };

  const onResetPassword = (id: number) => {
    resetPasswordMutation.mutate(id, {
      onSuccess: () => {
        setConfirmResendPassword(false);
      },
      onError: () => {
        snackbar({
          type: 'error',
          message: 'Kunde inte återställa lösenordet',
        });
      },
    });
  };

  if (status === 'pending' || !bdmOptions) return <LinearProgress />;

  if (status === 'error') return <BasicAlert />;

  return (
    <Card component="form" onSubmit={handleSubmit(onSubmit)}>
      <CardHeader
        title="Redigera återförsäljarkonto"
        action={
          <Button size="small" onClick={() => setConfirmResendPassword(true)}>
            Återställ lösenord
          </Button>
        }
      />
      <Dialog
        open={confirmResendPassword}
        onClose={() => setConfirmResendPassword(false)}
      >
        <DialogTitle>Vill du återställa lösenordet?</DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText>
            Ett mail med inloggninsuppgifter kommer skickas till
            återförsäljaren.
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            disabled={resetPasswordMutation.isPending}
            onClick={() => setConfirmResendPassword(false)}
            variant="contained"
            color="error"
          >
            Nej
          </Button>
          <Button
            disabled={resetPasswordMutation.isPending}
            onClick={() => onResetPassword(data.id)}
            variant="contained"
            color="success"
          >
            Ja
          </Button>
        </DialogActions>
      </Dialog>
      <Divider />
      <CardContent>
        <Textfield
          label="Namn"
          error={errors.name}
          fullWidth
          inputProps={{
            ...register('name'),
            'data-testid': 'edit-reseller-name',
          }}
        />
        <Textfield
          label="Användarnamn"
          error={errors.username}
          fullWidth
          inputProps={{
            ...register('username'),
            'data-testid': 'edit-reseller-username',
          }}
        />
        <Textfield
          label="Email"
          error={errors.email}
          fullWidth
          inputProps={{
            ...register('email'),
            'data-testid': 'edit-reseller-email',
          }}
        />
        <Textfield
          select
          label="Manager"
          fullWidth
          error={errors.businessDevelopmentManager}
          inputProps={{
            ...register('businessDevelopmentManager'),
            defaultValue: defaultValues?.businessDevelopmentManager,
            'data-testid': 'edit-reseller-businessDevelopmentManager',
          }}
        >
          {bdmOptions?.map((bdm) => (
            <MenuItem key={bdm.value} value={bdm.value}>
              {bdm.label}
            </MenuItem>
          ))}
        </Textfield>
      </CardContent>
      <Divider />
      <CardActions sx={{ p: 2, justifyContent: 'space-between' }}>
        {deleteResellerEnabled && <Delete id={data.id} />}
        <Button type="submit" variant="contained" color="success">
          Spara
        </Button>
      </CardActions>
    </Card>
  );
};

const Delete = ({ id }: { id: string | number }) => {
  const [confirm, setConfirm] = React.useState(false);
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const deleteResellerMutation = useDeleteReseller();

  const onDelete = (id: string | number) => {
    deleteResellerMutation.mutate(id, {
      onSuccess: async () => {
        navigate('/reseller-portal/accounts');
        snackbar({ type: 'success', message: 'Återförsäljaren raderades' });
      },
      onError: (error) => {
        snackbar({
          type: 'error',
          message: error.data.message || 'Kunde inte radera återförsäljaren',
        });
      },
      onSettled: () => {
        setConfirm(false);
      },
    });
  };

  return (
    <>
      <Button
        color="error"
        variant="contained"
        onClick={() => setConfirm(true)}
      >
        Radera
      </Button>
      <Dialog open={confirm} onClose={() => setConfirm(false)}>
        <DialogTitle>Vill du radera återförsäljaren?</DialogTitle>
        <Divider />
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            onClick={() => onDelete(id)}
          >
            Ja
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => setConfirm(false)}
          >
            Nej
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
