import { Typography } from '@mui/material';
import { Skeleton } from '@mui/material';

import { BasicAlert } from '@/components/BasicAlert';
import { formatCurrency } from '@/modules/common/utils/currency';

import { useManagedCustomersArr } from '../hooks';
import { ManagedCustomer } from '../types';

type Props = {
  managedCustomers: ManagedCustomer[];
};

export const ManagerMedianArr = ({ managedCustomers }: Props) => {
  const { data, isLoading, isError } = useManagedCustomersArr(managedCustomers);

  const getCustomersMedianArr = () => {
    if (data.length === 0) {
      return 0;
    }
    const sortedArr = data
      .map((obj) => obj?.message?.ARR)
      .sort((a, b) => a - b);
    const length = sortedArr.length;

    if (length % 2 === 0) {
      const lowestIndex = length / 2 - 1;
      const highestIndex = length / 2;
      const medianVal = (sortedArr[lowestIndex] + sortedArr[highestIndex]) / 2;
      return medianVal;
    } else {
      const medianValIndex = Math.floor(length / 2);
      return sortedArr[medianValIndex];
    }
  };

  if (isLoading) return <Skeleton />;
  if (isError) return <BasicAlert />;

  return (
    <Typography variant="body2">
      {formatCurrency(getCustomersMedianArr())}
    </Typography>
  );
};
