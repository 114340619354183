import { Card, CardContent, Divider, List, Typography } from '@mui/material';

import { CustomerProps } from '../../../../../types';
import { ChecklistTitle } from '../../../commonStyles';
import { MessageCustomer } from './MessageCustomer';
import { Todo } from './Todo';

// Fifth tab for Protection Checklist
export const FifthTab = (props: CustomerProps) => {
  return (
    <Card>
      <ChecklistTitle variant="h6">
        Kund har förlorat Id-handling och blivit Id-kapad
      </ChecklistTitle>
      <Divider />
      <CardContent>
        <Typography variant="subtitle2">
          <List>
            <MessageCustomer />
            <Todo {...props} />
          </List>
        </Typography>
      </CardContent>
    </Card>
  );
};
