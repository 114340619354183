import React from 'react';

import { Check } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  InputAdornment,
  Typography,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { FormApi } from 'final-form';
import { Form } from 'react-final-form';

import { getCustomerById } from '@/api/customers';
import { TextInput } from '@/components/form';
import { useSnackbar } from '@/hooks';
import usePublicSettings from '@/hooks/usePublicSettings';
import { GenericHttpError } from '@/types/errors';
import httpClient from '@/utils/httpClient';
import Yup from '@/utils/validation';

export const AddToSvea = () => {
  const [errors, setErrors] = React.useState<
    Record<string, string> | undefined
  >();
  const snackBar = useSnackbar();
  const { publicSettings } = usePublicSettings();

  const checkIfCustomerExists = async (id: string) => {
    try {
      await getCustomerById(id, { id: true });
      return true;
    } catch (error) {
      return false;
    }
  };
  const validate = async (value: string) => {
    const schema = Yup.string()
      .trim()
      .matches(/^\d+$/, {
        message: 'Kundnummret består enbart av siffror',
        excludeEmptyString: true,
      })
      .test(
        'customerExists',
        () =>
          !!value &&
          `Kundnumret hittades inte på ${publicSettings?.companyNames?.standard}`,
        async (value) => !!value && checkIfCustomerExists(value),
      );

    try {
      await schema.validate(value);
      return undefined;
    } catch (e: any) {
      return e.errors.map((error: any) => error);
    }
  };

  const mutation = useMutation({
    mutationFn: ({ id }: Record<string, any>) =>
      httpClient.get(`/customers/${id}/add.to.svea`),
    onSuccess: ({ data }) => {
      const responseTitle = data.invoicePartnerResponse?.Title.toLowerCase();

      if (responseTitle.includes('error')) {
        snackBar({
          type: 'error',
          message: 'Något gick fel. Se felmeddelandet nedaför.',
        });
        setErrors(data.invoicePartnerResponse);
      } else {
        snackBar({ type: 'success', message: 'Kunden tillagd hos Svea' });
      }
    },
    onError: (error: GenericHttpError) => {
      setErrors(error.data);
      snackBar({ type: 'error', message: 'Något gick fel' });
    },
  });

  const submitHandler = (value: Record<string, any>, form: FormApi) => {
    mutation.mutate(value);
    form.restart();
  };

  return (
    <Box>
      <Card>
        <CardHeader title="Lägg till befintlig kund till Svea" />
        <Divider />
        <CardContent style={{ paddingTop: 0 }}>
          <Form
            onSubmit={(v, form) => submitHandler(v, form)}
            render={({ handleSubmit, validating, valid }) => (
              <form onSubmit={handleSubmit}>
                <TextInput
                  name="id"
                  label="Kundnummer"
                  style={{ marginBottom: '16px' }}
                  InputProps={{
                    endAdornment: (
                      <>
                        {
                          <InputAdornment position="end">
                            {validating ? (
                              <CircularProgress size={30} />
                            ) : (
                              valid && <Check />
                            )}
                          </InputAdornment>
                        }
                      </>
                    ),
                  }}
                  fieldProps={{
                    validate,
                  }}
                />
                <Button type="submit" variant="outlined" disabled={validating}>
                  Spara
                </Button>
              </form>
            )}
          />
          <Box marginTop="1rem">
            {errors &&
              Object.keys(errors).map((error) => (
                <Typography color="error" key={error}>
                  <>
                    <b>{error}: </b>
                    {typeof errors[error] === 'boolean'
                      ? errors[error]
                      : errors[error]
                        ? errors[error]
                        : 'null'}
                  </>
                </Typography>
              ))}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};
