import {
  Campaign,
  CampaignCollectionResponse,
  CampaignPayload,
  CampaignTypes,
  ProtectionCampaignPayload,
} from '@/types/campaigns';
import { CommonResponseFields } from '@/types/common';
import { ServiceName } from '@/types/services';
import httpClient from '@/utils/httpClient';

export async function getHomegateCampaigns(customerType: number) {
  const { data } = await httpClient.get<CampaignCollectionResponse>(
    '/homegate_campaigns',
    {
      params: {
        customerType,
        active: true,
        properties: {
          name: true,
          id: true,
          active: true,
        },
      },
    },
  );

  return data['hydra:member'];
}

export async function getSinneCampaigns(customerType: number) {
  const { data } = await httpClient.get('/sinne_campaigns', {
    params: {
      pagination: false,
      customerType,
    },
  });

  return data['hydra:member'];
}

export type GetAllCampaignsParams = {
  active?: boolean;
  customerType?: boolean | number;
  freeMonths?: boolean;
  noticePeriod?: boolean;
  package?: boolean;
  units?: boolean;
  withdrawalPeriodDays?: boolean;
  properties?: Record<string, boolean>;
  page?: number;
  perPage?: number;
  pagination?: boolean;
  'order[active]'?: 'asc' | 'desc';
  'order[createdAt]'?: 'asc' | 'desc';
};

// TODO: naming is wrong here, this fetches one campaign
export async function getAllCampaigns(
  campaign: string,
  parameters?: GetAllCampaignsParams,
) {
  const { data } = await httpClient.get<Campaign & CommonResponseFields>(
    campaign,
    {
      params: {
        ...parameters,
      },
    },
  );

  return data;
}

export async function getCampaign<T = Campaign>(iri: string) {
  const { data } = await httpClient.get<T>(iri);
  return data;
}

export type CreateOrUpdateCampaignParams = {
  service: CampaignTypes | string;
  payload: CampaignPayload | ProtectionCampaignPayload;
};

export async function createCampaign({
  service: campaignType,
  payload,
}: CreateOrUpdateCampaignParams) {
  const { data } = await httpClient.post<Campaign>(campaignType, payload);
  return data;
}

export async function updateCampaign({
  service,
  payload,
}: CreateOrUpdateCampaignParams) {
  const { data } = await httpClient.put<Campaign>(service, payload);
  return data;
}

export async function getCampaigns(
  service: CampaignTypes,
  parameters: GetAllCampaignsParams,
) {
  const { data } = await httpClient.get<CampaignCollectionResponse>(service, {
    params: {
      ...parameters,
    },
  });

  return data['hydra:member'];
}

export async function deleteCampaign(iri: string) {
  const { data } = await httpClient.delete(iri);

  return data;
}

export type GenerateMockedWelcomeLetterParams = {
  product: Extract<ServiceName, 'protection'>; // INFO: currently only works for this
  campaignId: number;
};

export async function generateMockedWelcomeLetter(
  params: GenerateMockedWelcomeLetterParams,
) {
  return httpClient.post('/pdf/welcome-letter/mock', params, {
    responseType: 'blob',
  });
}
