import { Grid } from '@mui/material';

import { DeviceInformationCard } from './DeviceInformationCard';
import { EventDataContent } from './EventDataContent';

export const SinneDeviceDetails = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <DeviceInformationCard />
      </Grid>

      <Grid item xs={12} md={6}>
        <EventDataContent />
      </Grid>
    </Grid>
  );
};
