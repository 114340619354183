import { jwtDecode } from 'jwt-decode';
import get from 'lodash/get';
import { z } from 'zod';

import { AuthRule, authorizationRules } from '@/constants/AuthorizationRules';
import { availableRoles } from '@/constants/roleTypes';
import { getElementFromArray } from '@/helpers/getElementFromArray';
import { UserRole } from '@/types/auth';

const jwtSchema = z.object({
  username: z.string(),
  id: z.number(),
  iat: z.number(),
  exp: z.number(),
  roles: z.array(z.enum(availableRoles)),
});

export function checkRolesAccess(userRoles: UserRole[], action: AuthRule) {
  let hasPermission = false;
  userRoles?.forEach((role) => {
    if (get(authorizationRules[role], action, false)) {
      hasPermission = true;
    }
  });

  return hasPermission;
}

export function parseJwt(token: string) {
  const base64Payload = getElementFromArray(token.split('.')[1]);
  const payload = window.atob(base64Payload);
  const parsedPayload = JSON.parse(payload);

  return jwtSchema.parse(parsedPayload);
}

export function checkRolesAccessFromJwt(action: string) {
  const jwtToken = localStorage.getItem('jwt-token');

  let hasPermission = false;

  if (jwtToken) {
    const userRoles = jwtDecode<any>(jwtToken)?.roles as UserRole[];

    userRoles?.forEach((role) => {
      if (get(authorizationRules[role], action, false)) {
        hasPermission = true;
      }
    });
  }

  return hasPermission;
}
