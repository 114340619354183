import React from 'react';

import { startOfTomorrow } from 'date-fns';

import {
  DatePicker,
  Dropdown,
  RadioButtons,
  TextArea,
  TextInput,
} from '@/components/form';
import { usePrivateSettings } from '@/hooks';
import { useGetUsers } from '@/hooks/useGetUsers';
import { composeValidators, fieldRequired } from '@/utils/validation';

import {
  TaskTeamTypes,
  taskStatusOptions,
  teamTypeOptions,
} from '../../constants/tasksModalsConstants';
import { usePrependTaskTypeToText } from '../../hooks/usePrependTaskTypeToText';
import { TaskTeamType } from '../../types/team';
import { TaskTypeTemplateAlert } from './TaskTypeTemplateAlert';
import { CreateTaskModalFieldProps, CreateTaskModalProps } from './types';

type Props = Omit<CreateTaskModalProps, 'open' | 'handleVisibility'> &
  CreateTaskModalFieldProps;

export const CreateTaskModalFields = ({
  isTaskTeamView,
  defaults,
  form,
  isBarringService,
  values,
}: Props) => {
  const prependType = usePrependTaskTypeToText();

  const getAndMatchTeam = (): TaskTeamType | undefined => {
    switch (defaults?.team) {
      case 1:
        return TaskTeamTypes.CUSTOMER_SUPPORT;
      case 2:
        return TaskTeamTypes.ADMINISTRATOR;
      default:
        return undefined;
    }
  };

  const taskTeam: TaskTeamType | undefined = getAndMatchTeam();

  const { getTypesOptionsForDropdownInput } = usePrivateSettings();

  const dateTomorrow = React.useMemo(() => startOfTomorrow(), []);
  const dateToday = React.useMemo(() => new Date(), []);

  React.useEffect(() => {
    if (
      Number(values.team) === TaskTeamTypes.ADMINISTRATOR &&
      values.type !== '100'
    ) {
      if (values.type) {
        form.change('text', prependType(values.type, values.text || ''));
      }
    }
  }, [values.type, form]);

  const users = useGetUsers({ taskTeam: taskTeam });
  const typeOptions = getTypesOptionsForDropdownInput('task', {
    // TS error due to the inconsistency in final form typing
    // @ts-expect-error "Type number is not assignable to type 1 | 2 | undefined"
    taskTeam: Number(values.team),
  });

  return (
    <>
      {/* PIN is not shown in task team view */}
      {!isTaskTeamView && (
        <TextInput
          label="PIN"
          name="pin"
          disabled
          fieldProps={{
            validate: composeValidators(fieldRequired),
          }}
        />
      )}

      {/* Hanteras av field is only shown in Task team view */}
      {isTaskTeamView && (
        <Dropdown
          label="Hanteras av:"
          name="claimedBy"
          options={users.map((user) => ({
            value: user['@id'],
            text: user.name,
          }))}
        />
      )}

      {/* Radio Buttons is not shown in task team view or barring service view */}
      {!(isTaskTeamView || isBarringService) && (
        <RadioButtons
          required
          name="team"
          data={teamTypeOptions}
          onChangeCapture={() => {
            form.restart();
          }}
        />
      )}

      {/* Status is not shown in task team view */}
      {!isTaskTeamView && (
        <Dropdown
          label="Status"
          name="status"
          options={taskStatusOptions}
          required
          fieldProps={{
            validate: fieldRequired,
          }}
        />
      )}

      <Dropdown
        label="Kategori"
        name="type"
        required={!isTaskTeamView}
        disabled={isBarringService || !values.team || isTaskTeamView}
        options={typeOptions}
        fieldProps={{
          validate: fieldRequired,
        }}
      />

      <DatePicker
        label="Deadline"
        name="deadline"
        showClearButton
        fieldProps={{
          initialValue:
            values.team === TaskTeamTypes.CUSTOMER_SUPPORT.toString()
              ? dateTomorrow
              : dateToday,
        }}
      />
      {Number(values.team) === TaskTeamTypes.ADMINISTRATOR && (
        <TaskTypeTemplateAlert type={values.type} />
      )}
      <TextArea
        label="Text"
        name="text"
        required
        rows={8}
        fieldProps={{
          validate: fieldRequired,
        }}
      />
    </>
  );
};
