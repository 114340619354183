import { Box, LinearProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';

import { getCustomerById } from '@/api/customers';
import { getServiceDetailsByCode } from '@/api/services';
import { Alert } from '@/components/Alert';
import { Breadcrumbs } from '@/components/Breadcrumbs';
import { PageHeader } from '@/components/PageHeader';
import { PageWithDrawer } from '@/components/PageWithDrawer';
import { queryKeys } from '@/constants/queryKeys';
import { CommunicationDrawer } from '@/modules/common/components';
import { getSnakeServiceName } from '@/modules/common/utils/services';
import { FoundObjectForm } from '@/modules/foundObject/components';
import { KebabCaseServiceNames } from '@/types/settings';

type ParamType = {
  code: string;
  type: ProductType;
};

type ProductType = Extract<KebabCaseServiceNames, 'key-tag' | 'sticker'>;

export const FoundObject = () => {
  const { code, type } = useParams<ParamType>();

  invariant(typeof code === 'string', 'Type value should be a string');
  invariant(typeof type === 'string', 'Type value should be a string');

  const {
    data: customerId,
    isError,
    isLoading: loadingProductInfo,
  } = useQuery({
    queryKey: ['foundObjectServiceDetails', code, type],

    queryFn: async () => {
      const serviceName = getSnakeServiceName(type);
      const response = await getServiceDetailsByCode({ serviceName, code });
      const product = response['hydra:member'][0];

      if (product) {
        return product.customerId;
      } else {
        return null;
      }
    },
  });

  const fieldsToFetch = {
    id: true,
    category: true,
    firstName: true,
    lastName: true,
    fullName: true,
    address: true,
    address2: true,
    zip: true,
    city: true,
    email: true,
    phone: true,
    mobile: true,
    pin: true,
  };

  const {
    data: customer,
    isInitialLoading: loadingCustomer,
    isError: errorCustomer,
  } = useQuery({
    queryKey: [queryKeys.getCustomerById, customerId],
    queryFn: async () => await getCustomerById(customerId, fieldsToFetch),
    enabled: !!customerId,
  });

  return (
    <PageWithDrawer
      pageContent={
        <>
          <Breadcrumbs
            crumbs={[
              { label: 'Spärrlinje', url: '/security-service' },
              { label: 'Upphittat föremål' },
              { label: code! },
            ]}
          />
          <PageHeader title="Säkerhetsservice" />
          {loadingProductInfo ? (
            <LinearProgress />
          ) : !customerId || isError ? (
            <Alert severity="error">
              Ingen tjänst hittades för koden <b>{code}</b>
            </Alert>
          ) : (
            <>
              <Box>
                <FoundObjectForm code={code!} type={type!} />
              </Box>
            </>
          )}
        </>
      }
      drawerContent={
        loadingCustomer ? (
          <LinearProgress />
        ) : errorCustomer ? (
          <Alert>Kunde inte hämta data</Alert>
        ) : (
          customer && (
            <CommunicationDrawer customerId={customerId} isInSecurityService />
          )
        )
      }
    />
  );
};

export default FoundObject;
