import React from 'react';

import { z } from 'zod';

import { getTypeKey } from '@/helpers';
import { usePrivateSettings } from '@/hooks';

export const useNoteTypeGuard = (noteName: string) => {
  const [loading, setLoading] = React.useState(true);
  const [noteTypeIsInPrivateSettings, setNoteTypeIsInPrivateSettings] =
    React.useState(false);
  const { servicesTypes } = usePrivateSettings();

  React.useMemo(() => {
    const noteType = z
      .string()
      .safeParse(getTypeKey(servicesTypes, 'note', noteName));

    if (noteType.success) {
      setNoteTypeIsInPrivateSettings(true);
      setLoading(false);
    }

    setLoading(false);
  }, [servicesTypes]);

  return { noteTypeIsInPrivateSettings, loading };
};
