import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import {
  DownloadExportFileParams,
  ExportDownloadError,
  downloadExportFile,
} from '@/api/exports';

export type UseDownloadExportFile = UseMutationResult<
  AxiosResponse<Blob, any>,
  ExportDownloadError,
  DownloadExportFileParams,
  unknown
>;

export const useDownloadExportFile = (
  onSessionConsumed: () => void,
  onSuccess: () => void,
  onError: () => void,
  onNoData: () => void,
) => {
  const mutation = useMutation({
    mutationFn: downloadExportFile,
    onSuccess: (response) => {
      response.status === 200 && onSuccess();
      response.status === 204 && onNoData();
    },
    onError: (error: ExportDownloadError) => {
      error.message === '403' && onSessionConsumed();
      error.message === '500' && onError();
    },
  });

  return mutation;
};
