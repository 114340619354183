import { useQuery } from '@tanstack/react-query';

import { listRmaCases } from '@/api';
import { queryKeys } from '@/constants/queryKeys';

export const useGetRmaCases = (search?: string) => {
  return useQuery({
    queryFn: () => listRmaCases(search),
    queryKey: queryKeys.rma.filteredList(search?.trim()),
  });
};
