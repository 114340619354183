import React from 'react';

import { Box, Card, CardHeader, Divider, Tab, Tabs } from '@mui/material';

import { TabPanel } from '@/components/TabPanel';

import { CustomTemplateFileUploadForm } from './CustomTemplateFileUploadForm';
import { UploadedTemplatesList } from './UploadedTemplatesList';

export const CustomTemplatesCard = () => {
  const [activeTab, setActiveTab] = React.useState(1);

  return (
    <Card>
      <CardHeader title="Skapa en valfri brevmall" />
      <Divider />

      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={activeTab}
            onChange={(_, tab) => setActiveTab(tab)}
            aria-label="basic tabs example"
          >
            <Tab value={1} label="Skapa" />
            <Tab value={2} label="Lista" />
          </Tabs>
        </Box>
        <Box p={2}>
          <TabPanel value={activeTab} index={1}>
            <CustomTemplateFileUploadForm />
          </TabPanel>
          <TabPanel value={activeTab} index={2}>
            <UploadedTemplatesList />
          </TabPanel>
        </Box>
      </Box>
    </Card>
  );
};
