import { JsonSchema7Type } from 'zod-to-json-schema';

import { getObjectKeys } from './tsHelpers';

export const createApiPropertiesFromJsonSchema = (
  inputObj: JsonSchema7Type,
) => {
  // @ts-ignore
  return getObjectKeys(inputObj.properties).reduce((acc, key: string) => {
    if (key) {
      // @ts-ignore
      if (inputObj.properties[key].type === 'object') {
        // @ts-ignore
        acc[key] = createApiPropertiesFromJsonSchema(inputObj.properties[key]);
      } else {
        acc[key] = true;
      }
    }
    return acc;
  }, {} as any); // TODO: create a recursive type for this
};
