import React, { useMemo } from 'react';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import Collapse from '@mui/material/Collapse';

import { getTasksWithPassedDeadline } from '@/api/tasks';
import { Can } from '@/components/Can';
import { queryKeys } from '@/constants/queryKeys';
import { usePrivateSettings } from '@/hooks';
import { CollapsableMenuList } from '@/layouts/Main/components/Sidebar/components/SidebarNav/components/CollapsableMenuList';
import { MenuListItem } from '@/layouts/Main/components/Sidebar/components/SidebarNav/components/MenuListItem';
import { MenuListItemButton } from '@/layouts/Main/components/Sidebar/components/SidebarNav/components/MenuListItemButton';
import { TaskTeamTypes } from '@/modules/tasks/constants/tasksModalsConstants';
import { UserRole } from '@/types/auth';

import { MenuItemWithCounter } from './MenuItemWithCounter';
import { SubMenuListItem } from './SubMenuListItem';

type Props = {
  userRoles: UserRole[];
};

export const SupportMenu = ({ userRoles }: Props) => {
  const [open, setOpen] = React.useState(false);
  const { privateSettings } = usePrivateSettings();
  const isBankIdEnabled = privateSettings?.bankIdEnabled;
  const { servicesTypes } = usePrivateSettings();
  const supportTasksExcludingOnBoarding = useMemo(() => {
    if (!servicesTypes) {
      return [];
    }

    return Object.keys(
      servicesTypes.task[TaskTeamTypes.CUSTOMER_SUPPORT],
    ).filter((type) => type !== '16');
  }, [servicesTypes]);

  return (
    <>
      <MenuListItem>
        <MenuListItemButton onClick={() => setOpen(!open)}>
          Kundservice
          {open ? <ExpandLess /> : <ExpandMore />}
        </MenuListItemButton>
      </MenuListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <CollapsableMenuList>
          <Can
            userRoles={userRoles}
            action="support.stats"
            yes={() => (
              <SubMenuListItem title="Statistik" href="/support/statistics" />
            )}
          />
          <Can
            userRoles={userRoles}
            action="support.tasks"
            yes={() => (
              <MenuItemWithCounter
                title="Ärenden"
                href="/support/tasks"
                queryFn={getTasksWithPassedDeadline}
                params={{
                  status: [0, 2, 3, 4],
                  type: supportTasksExcludingOnBoarding,
                }}
                refetchQueryKey={[queryKeys.getTasks]}
              />
            )}
          />
          <Can
            userRoles={userRoles}
            action="support.tasks"
            yes={() => (
              <MenuItemWithCounter
                title="Winback"
                href="/support/winback-tasks"
                queryFn={getTasksWithPassedDeadline}
                params={{ status: [5] }}
                refetchQueryKey={[queryKeys.getTasks]}
              />
            )}
          />

          <Can
            userRoles={userRoles}
            action="support.tasks"
            yes={() => (
              <MenuItemWithCounter
                title="Onboarding"
                href="/support/onboarding-tasks"
                queryFn={getTasksWithPassedDeadline}
                params={{ status: [0, 2, 3, 4], type: ['16'] }}
                refetchQueryKey={[queryKeys.getTasks]}
              />
            )}
          />

          <Can
            userRoles={userRoles}
            action="support.churn"
            yes={() => <SubMenuListItem title="Churn" href="/support/churn" />}
          />
          <Can
            userRoles={userRoles}
            action="support.notes"
            yes={() => (
              <SubMenuListItem title="Noteringar" href="/support/notes" />
            )}
          />

          <Can
            userRoles={userRoles}
            action="services.sinne.missingDevices.view"
            yes={() => (
              <SubMenuListItem
                title="Ej returnerade enheter"
                href="/support/sinne/missing-devices"
              />
            )}
          />

          {isBankIdEnabled && (
            <Can
              userRoles={userRoles}
              action="support.verifyCustomer"
              yes={() => (
                <SubMenuListItem
                  title="Verifiera kund"
                  href="/support/verify-customer"
                />
              )}
            />
          )}
        </CollapsableMenuList>
      </Collapse>
    </>
  );
};
