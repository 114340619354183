import React from 'react';

import { Box, LinearProgress, Typography } from '@mui/material';
import { captureException } from '@sentry/react';
import { useLocation } from 'react-router-dom';

import { config } from '@/helpers/config';
import { GrandIdUserAttributes, GrandIdVerifyResponse } from '@/types/grandId';
import httpClient from '@/utils/httpClient';

export const BankIdVerificationCallback = () => {
  const location = useLocation();

  const [loading, setLoading] = React.useState(false);
  const [customer, setCustomer] = React.useState<GrandIdUserAttributes | null>(
    null,
  );

  const queryParams = new URLSearchParams(location.search);
  const sessionId = queryParams.get('grandidsession');

  React.useEffect(() => {
    if (sessionId) {
      fetchData();
    }

    return () => {
      setCustomer(null);
      setLoading(false);
    };
  }, [sessionId]);

  async function fetchData() {
    try {
      setLoading(true);

      const { data } = await httpClient.get<GrandIdVerifyResponse>(
        `/grandid/verify?grandIdSession=${sessionId}`,
      );

      setCustomer(data.userAttributes);

      window.parent.postMessage(data, config.siteUri);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
      captureException(error);
    }
  }

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <Box>
      {customer && (
        <Typography variant="h5">
          Personnummer {customer.personalNumber} verifierat
        </Typography>
      )}
    </Box>
  );
};

export default BankIdVerificationCallback;
