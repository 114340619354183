import React from 'react';

import { FormControlProps, MenuItem } from '@mui/material';
import { Select, SelectProps } from 'mui-rff';

type Props = {
  options: Array<{
    value: string | number | undefined;
    text: React.ReactNode;
    disabled?: boolean;
  }>;
  noOptionText?: string;
  defaultOption?: boolean;
  defaultOptionText?: string;
  formControlProps?: FormControlProps;
} & SelectProps;

export const Dropdown = ({
  options,
  noOptionText = 'Inga alternativ',
  defaultOption = false,
  defaultOptionText = 'Alla',
  name,
  formControlProps,
  ...props
}: Props) => {
  return (
    <Select
      margin="none"
      name={name}
      inputProps={{
        'data-testid': name,
      }}
      fullWidth
      formControlProps={formControlProps}
      formHelperTextProps={{
        // @ts-expect-error - data-testid is not a valid prop
        'data-testid': `${name}HelperText`,
      }}
      {...props}
    >
      {defaultOption && <MenuItem value="">{defaultOptionText}</MenuItem>}
      {options && options.length > 0 ? (
        options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            disabled={option.disabled}
          >
            {option.text}
          </MenuItem>
        ))
      ) : (
        <MenuItem value="">{noOptionText}</MenuItem>
      )}
    </Select>
  );
};
