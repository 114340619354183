import { Button, Card, CardActions, CardContent, Divider } from '@mui/material';
import { FormApi } from 'final-form';
import { Form } from 'react-final-form';

import { TextArea } from '@/components/form';
import { ExistingCustomer } from '@/types/customers';
import {
  downloadAutogiroPdf,
  downloadC5H5Pdf,
  downloadSimpleLetterPdf,
} from '@/utils/pdf';
import Yup, { makeValidation } from '@/utils/validation';

import { hasWhiteLabelAutogiroPermission } from '../../utils/hasWhiteLabelAutogiroPermission';

type Props = {
  customer: ExistingCustomer;
};

export const GenerateLetter = ({ customer }: Props) => {
  function handleAutogiroPDFGenerate() {
    console.log('HERE!!!!!');
    downloadAutogiroPdf(customer.id);
  }

  function handleC5H5PdfGenerate() {
    downloadC5H5Pdf(customer.id);
  }

  function onSubmit(event: { message: string }, form: FormApi) {
    downloadSimpleLetterPdf(customer.id, event.message);
    form.reset();
    form.resetFieldState('message');
  }

  let schema = Yup.object().shape({
    message: Yup.string()
      .trim()
      .required(() => '0 / 2000. Minst 1 tecken')
      .max(2000, (obj) => `${obj.value.length} / 2000 tecken`),
  });

  const validate = makeValidation(schema);
  return (
    <Form
      onSubmit={(values, form) => onSubmit(values, form)}
      validate={validate}
      render={({ handleSubmit, values }) => (
        <form onSubmit={(event) => handleSubmit(event)}>
          <Card>
            <CardContent>
              <TextArea
                label="Meddelande"
                name="message"
                placeholder="Skriv ett meddelande för att kunna generera ett nytt brev..."
                helperText={`${values.message?.length ?? 0} / 2000 tecken`}
              />
            </CardContent>
            <Divider />
            <CardActions>
              <Button
                type="submit"
                color="primary"
                variant="outlined"
                disabled={!values.message || values.message?.length > 2000}
              >
                Generera brev
              </Button>
              <Button
                type="button"
                color="primary"
                onClick={handleC5H5PdfGenerate}
                variant="outlined"
              >
                C5H2 adress
              </Button>
              <Button
                type="button"
                color="primary"
                onClick={handleAutogiroPDFGenerate}
                disabled={!hasWhiteLabelAutogiroPermission()}
                variant="outlined"
              >
                Autogiroblankett
              </Button>
            </CardActions>
          </Card>
        </form>
      )}
    />
  );
};
