import { useMutation } from '@tanstack/react-query';

import {
  GetLegacyWelcomeLetterParams,
  getWelcomeLetterFromLegacy,
} from '@/api/files';

export const useGetWelcomeLetterFromLegacy = () => {
  return useMutation({
    mutationFn: (params: GetLegacyWelcomeLetterParams) =>
      getWelcomeLetterFromLegacy(params),
  });
};
