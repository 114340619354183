import { z } from 'zod';

import { CustomerType, CustomerTypeEnum } from '@/types/customers';

import { validationMessages } from '../../constants';

// TODO: Add translations to all the fields
export const protectionValidationSchema = z
  .object({
    active: z.boolean(),
    bulkPrintEnabled: z.boolean(),
    contractMonths: z.coerce
      .number()
      .int()
      .nonnegative('Antalet måste vara större än eller lika med 0'),
    customerType: CustomerTypeEnum,
    endUsers: z
      .number()
      .int()
      .min(1)
      .max(5, 'Antal användare får vara mellan 1 och 5'),
    freeMonths: z.coerce
      .number()
      .int()
      .nonnegative('Antalet måste vara större än eller lika med 0'),
    internalDescription: z.string().optional(),
    name: z.string().min(1, validationMessages.required).trim(),
    noticePeriod: z.coerce
      .number()
      .int()
      .nonnegative('Antalet måste vara större än eller lika med 0'),
    price: z.string().min(1, validationMessages.required), // INFO: iri-reference
    specialHeader: z.string(),
    specialRow1: z.string().optional(),
    specialRow2: z.string().optional(),
    specialRow3: z.string().optional(),
    package: z.string(), // INFO: constants.protectionProductTypes, some people refer to this as package
    withdrawalPeriodDays: z.coerce
      .number()
      .int()
      .nonnegative('Antalet måste vara större än eller lika med 0'),
  })
  .refine(
    (fields) => {
      if (fields.customerType === CustomerType.PRIVATE) {
        return fields.withdrawalPeriodDays >= 14;
      }
      return true;
    },
    {
      message: validationMessages.withdrawalPeriodDays,
      path: ['withdrawalPeriodDays'],
    },
  );
