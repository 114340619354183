import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  LinearProgress,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { getUserById } from '@/api';
import { BasicAlert } from '@/components/BasicAlert';
import { SimpleTable } from '@/components/SimpleTable';
import useUser from '@/hooks/useUser';

import { ManagedCustomer } from '../../types';
import { AccountManagerArrSummary } from './AccountManagerArrSummary';

const fieldsToFetch = {
  name: true,
  coProtectionOnboardingPercentage: true,
  firetextOnboardingPercentage: true,
  managedCustomersARR: true,
  managedCustomers: {
    id: true,
  },
};

type AccountManagerOnboardingUser = {
  '@id': string;
  '@type': 'User';
  name: string;
  managedCustomers: ManagedCustomer[];
  coProtectionOnboardingPercentage: string;
  firetextOnboardingPercentage: string;
  managedCustomersARR: {
    firetext: string;
    protection: string;
    'co-protection': string;
    sticker: string;
    'key-tag': string;
    homegate: string;
    sinne: string;
    totalARR: string;
    meanARR: string;
  };
};

type Column = {
  label: string;
  renderer: ({
    name,
    coProtectionOnboardingPercentage,
  }: {
    name: string;
    coProtectionOnboardingPercentage: string;
  }) => any;
};

export const AccountManagerOnboardingStatistics = () => {
  const { id: userId } = useUser();

  const { data: user, status } = useQuery({
    queryKey: ['getUser', userId, fieldsToFetch],
    queryFn: async () => {
      const data = await getUserById<AccountManagerOnboardingUser>(
        userId,
        fieldsToFetch,
      );
      return data;
    },

    enabled: !!userId,
  });

  const onBoardingrows = [
    {
      name: user?.name,
      coProtectionOnboardingPercentage: user?.coProtectionOnboardingPercentage,
      firetextOnboardingPercentage: user?.firetextOnboardingPercentage,
    },
  ];
  const columns: Column[] = [
    { label: 'Namn', renderer: ({ name }) => name },

    {
      label: 'Bolagsbevakning',
      renderer: ({ coProtectionOnboardingPercentage }) =>
        coProtectionOnboardingPercentage,
    },
  ];

  const currentView = {
    pending: <LinearProgress />,
    success: (
      <>
        <Card style={{ margin: '1rem 0' }}>
          <AccountManagerArrSummary />
        </Card>
        <Card>
          <CardHeader title="Onboarding" />
          <Divider />
          <CardContent>
            <SimpleTable rows={onBoardingrows} columns={columns} />
          </CardContent>
        </Card>
      </>
    ),
    error: <BasicAlert message="Något gick fel" />,
  };

  return currentView[status];
};
