import { CardContent, Divider } from '@mui/material';

import { Spacing } from '@/components/Spacing';
import { InvoiceList } from '@/types/billing';

import { CustomerBillingBottomInfo } from './CustomerBillingBottomInfo';
import { CustomerTopTable } from './CustomerTopTable';

type Props = {
  invoiceList: InvoiceList;
  companyCustomer: boolean;
};

export function CustomerBilling({ invoiceList, companyCustomer }: Props) {
  return (
    <>
      <Divider />
      <CardContent>
        <Spacing />
        <CustomerTopTable
          invoiceList={invoiceList}
          companyCustomer={companyCustomer}
        />
        <Spacing spacing={3} />
        <CustomerBillingBottomInfo invoiceList={invoiceList} />
      </CardContent>
    </>
  );
}
