import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@mui/material';
import pick from 'lodash/pick';

import { TResellerPortalStats } from '@/types/stats';

import { prepareDetailedInfoTablesData } from '../utils/prepareDetailedInfoTablesData';
import { ChartCard } from './ChartCard';
import { TotalCard } from './TotalCard';

export const BdmView = ({ data }: { data: TResellerPortalStats }) => {
  const resellersTableData = data?.reseller
    ? prepareDetailedInfoTablesData({
        rawData: data.reseller,
        firstHeaderName: 'Namn',
        linkProps: {
          linkKey: 'ÅterförsäljarId',
          href: '/reseller-portal/bdm/statistics/reseller',
        },
      })
    : null;
  const totalTableData = pick(data?.basic?.summary, [
    'Brutto',
    'Ånger',
    'Makulerad',
    'Netto',
  ]);

  return (
    //TODO set colspan index to constant
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ChartCard periodStats={data?.basic.period} />
      </Grid>
      <Grid item xs={12}>
        <TotalCard summaryStats={totalTableData} />
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardHeader title="Återförsäljare" />
          <Divider />
          <StyledCardContent>
            <TableContainer component={Paper}>
              {resellersTableData && (
                <Table>
                  <TableHead>
                    <TableRow>
                      {resellersTableData.headers.map((item, index) => (
                        <TableCell key={index} colSpan={index <= 1 ? 1 : 2}>
                          {item}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {resellersTableData.data.length > 0 ? (
                      <>
                        {resellersTableData.data.map((item, index) => (
                          <TableRow hover key={index}>
                            {item.map((cellData, index) => (
                              <TableCell key={index}>{cellData}</TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </>
                    ) : (
                      <TableRow>
                        <TableCell>
                          <Typography>Ingen Data</Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </StyledCardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
  '& > *': {
    marginBottom: theme.spacing(2),
  },
}));
