import { usePrivateSettings } from '@/hooks/usePrivateSettings';
import { StickerPricePlans } from '@/types/settings';

export const useGetStickerPricePlanIds = () => {
  const { pricePlans } = usePrivateSettings();

  // Can't use getObjectKeys
  // here because it returns a keyof StickerPricePlans[], but it's actually a string[]
  // and then TS complains about the key as arg to parseInt function
  const pricePlanIds = Object.keys(pricePlans.sticker).map(
    (key) => parseInt(key, 10) as keyof StickerPricePlans,
  );

  const getPricePlanId = (idToGet: keyof StickerPricePlans) =>
    pricePlanIds.find((key) => key === idToGet);

  return { pricePlanIds, getPricePlanId };
};
