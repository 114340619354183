import httpClient from '@/utils/httpClient';

export async function sendPasswordRecoveryLink(username: string) {
  const data = await httpClient.post<null>('forgot_password', {
    username,
  });

  return data;
}

export async function resetPassword(payload: {
  token: string;
  new_password: string;
  confirm_password: string;
}) {
  const data = await httpClient.post<{ message: string }>(
    '/forgot_password/reset',
    {
      token: payload.token,
      new_password: payload.new_password,
      confirm_password: payload.confirm_password,
    },
  );

  return data;
}
