import { useQuery } from '@tanstack/react-query';

import { getServiceStats } from '@/api/services';
import { queryKeys } from '@/constants/queryKeys';
import { getFormattedDate } from '@/helpers/dates';
import { CamelCaseServiceName } from '@/types/services';
import { TResellerPortalStats } from '@/types/stats';

export type Resolution = 'daily' | 'weekly' | 'monthly' | 'quarter' | 'yearly';

export type GetStatsParams = {
  bdm?: number; // If this is supplied we will get all resellers attached to this bdm
  end: Date;
  reseller?: number; // If this is supplied we will get the stats for the supplied reseller
  resolution: Resolution;
  serviceName: CamelCaseServiceName | '';
  start: Date;
};

export const useGetStats = (args: GetStatsParams) => {
  const enabled = !!(args.bdm || args.reseller) && args.serviceName !== '';

  const formattedArgs = {
    ...args,
    start: getFormattedDate(args.start)!,
    end: getFormattedDate(args.end)!,
    serviceName: args.serviceName as CamelCaseServiceName,
  };
  return useQuery({
    queryKey: queryKeys.statistics.list(args),
    queryFn: async () => {
      const response = await getServiceStats(formattedArgs);
      return response as TResellerPortalStats;
    },
    enabled,
  });
};
