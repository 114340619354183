import { useEffect, useRef, useState } from 'react';

import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material';

import { SelectFieldOptions } from '@/types/common';

type Props = {
  options: SelectFieldOptions;
  label: string;
  onChange: (payload: string[]) => void;
};

export const TaskCategorySelect = ({ options, label, onChange }: Props) => {
  const isInitialValue = useRef(true);

  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

  useEffect(() => {
    if (isInitialValue.current) {
      isInitialValue.current = false;
    } else {
      onChange(selectedCategories);
    }
  }, [selectedCategories, onChange]);

  const renderValue = (selected: string[]) => (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
      {selected.map((item) => (
        <Chip
          key={item}
          label={options.find((option) => option.value === item)?.text}
        />
      ))}
    </Box>
  );

  const handleChange = (
    event: SelectChangeEvent<typeof selectedCategories>,
  ) => {
    const {
      target: { value },
    } = event;
    setSelectedCategories(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <FormControl sx={{ minWidth: 400, marginTop: 2 }}>
      <InputLabel id="task-category-select-label">{label}</InputLabel>
      <Select
        labelId="task-category-select-label"
        id="task-category-select"
        multiple
        value={selectedCategories}
        onChange={handleChange}
        input={<OutlinedInput id="select-multiple-chip" label={label} />}
        renderValue={(selected) => renderValue(selected)}
      >
        {options.map((option) => (
          <MenuItem key={option.text} value={option.value}>
            <Checkbox
              checked={selectedCategories.includes(option.value.toString())}
            />
            <ListItemText primary={option.text} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
