import React, { useState } from 'react';

import { Button, CardActions, CardHeader, Divider } from '@mui/material';
import kebabCase from 'lodash/kebabCase';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { Can } from '@/components/Can';
import { usePrivateSettings } from '@/hooks';
import { useAuth } from '@/hooks/useAuth';
import { MultipleProtectionsWarningModal } from '@/modules/common/components';
import { ExistingCustomer } from '@/types/customers';

type Props = {
  customer: ExistingCustomer;
};

export function AddService({ customer }: Props) {
  const [showMultipleProtectionsModal, setShowMultipleProtectionsModal] =
    useState(false);

  const { privateSettings } = usePrivateSettings();
  const productNames = privateSettings?.names;
  const isProductEnabled = privateSettings?.products;

  const { getAuthenticatedUserRoles } = useAuth();
  const userRoles = getAuthenticatedUserRoles();

  const navigate = useNavigate();

  function goToProductCreation(serviceName: string) {
    const pin = customer.pin;
    const customerId = customer.id;

    navigate({
      pathname: `/products/${serviceName}/add`,
      search: `?pin=${pin}&customerId=${customerId}`,
    });
  }

  function getRoutePath(serviceName: string) {
    const pin = customer.pin;
    const customerId = customer.id;

    return `/products/${serviceName}/add?pin=${pin}&customerId=${customerId}`;
  }

  function addNewProtectionAfterCheck() {
    if (customer?.activeProtections?.length! > 0) {
      setShowMultipleProtectionsModal(true);
    } else {
      goToProductCreation('protection');
    }
  }

  const products = [
    'firetext',
    'protection',
    'coProtection',
    'sticker',
    'keyTag',
    'homegate',
    'sinne',
  ] as const;

  return (
    <Can
      userRoles={userRoles}
      action="services.registration"
      yes={() => (
        <>
          <CardHeader title="Lägg till tjänster" />
          <Divider />

          <CardActions sx={{ flexWrap: 'wrap' }}>
            {products.map((product) => {
              const props =
                product === 'protection'
                  ? {
                      onClick: addNewProtectionAfterCheck,
                    }
                  : {
                      component: RouterLink,
                      to: getRoutePath(kebabCase(product)),
                    };

              return (
                !!isProductEnabled![product] && (
                  <Button
                    {...props}
                    color={'primary'}
                    variant="outlined"
                    key={`btn-add-${product}`}
                    style={{ margin: 4 }}
                  >
                    {productNames![product]}
                  </Button>
                )
              );
            })}
          </CardActions>

          {showMultipleProtectionsModal && (
            <MultipleProtectionsWarningModal
              isOpened
              customer={customer}
              onClose={() => setShowMultipleProtectionsModal(false)}
              onConfirm={() => goToProductCreation('protection')}
            />
          )}
        </>
      )}
    />
  );
}
