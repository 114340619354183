import { Grid, Skeleton } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { FormApi } from 'final-form';
import isArray from 'lodash/isArray';

import { fetchPartnersList } from '@/api/partners';
import { BasicAlert } from '@/components/BasicAlert';
import {
  Checkboxes,
  Dropdown,
  EmailInput,
  PhoneInput,
  TextInput,
  ZipInput,
  normalizeToNumbersOnly,
  normalizeToPersonNumberShort,
} from '@/components/form';
import { Condition } from '@/components/form/Condition';
import {
  CustomerCategoryTypes,
  customerCategoryTypeOptions,
} from '@/constants';
import { queryKeys } from '@/constants/queryKeys';
import { getEnvConfig } from '@/helpers/config';
import { usePrivateSettings } from '@/hooks';
import { checkRolesAccessFromJwt } from '@/utils/authorization';
import {
  fieldIsOrgNumber,
  fieldIsPersonalNumber,
  removeInvalidSveaChars,
} from '@/utils/validation';

import { PasteCustomerField } from '../PasteCustomerField';

type Props = {
  disablePinField?: boolean;
  disablePasteField?: boolean;
  disablePartnerField?: boolean;
  form: FormApi<any>;
};

export const CustomerInfoFields = ({
  disablePinField,
  disablePasteField,
  disablePartnerField,
  form,
}: Props) => {
  const {
    data: partners,
    isLoading,
    isError,
  } = useQuery({
    queryKey: [queryKeys.getPartners],
    queryFn: fetchPartnersList,
    initialData: [],
    enabled: !disablePartnerField,
  });

  const { whitelabelId } = getEnvConfig();

  const { invoiceProvider } = usePrivateSettings();

  const hasInvoiceProviderSvea = invoiceProvider?.name.toLowerCase() === 'svea';

  const showDebtCollectionCheckbox =
    (hasInvoiceProviderSvea || whitelabelId === '0000') &&
    checkRolesAccessFromJwt('customers.debtCollection');

  const partnerSelectOptions = isArray(partners)
    ? [
        { value: '', text: 'Ingen' },
        ...partners.map((partner) => ({
          value: `/partner_companies/${partner.id}`,
          text: `${partner.id} - ${partner.name}`,
        })),
      ]
    : [];

  return (
    <>
      {!disablePasteField && <PasteCustomerField form={form} />}
      <Dropdown
        label="Kategori"
        name="category"
        required
        options={customerCategoryTypeOptions}
      />

      <Condition when="category" is={CustomerCategoryTypes.COMPANY}>
        <TextInput
          label="Företagsnamn"
          name="companyName"
          required
          fieldProps={{
            parse: (v) => removeInvalidSveaChars(v),
            format: (v) => (v ? removeInvalidSveaChars(v) : ''),
          }}
        />
      </Condition>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Condition when="category" is={CustomerCategoryTypes.COMPANY}>
            <TextInput
              label="Kontakt Förnamn"
              name="firstName"
              required
              fieldProps={{
                parse: (v) => removeInvalidSveaChars(v),
                format: (v) => (v ? removeInvalidSveaChars(v) : ''),
              }}
            />
          </Condition>
          <Condition when="category" is={CustomerCategoryTypes.PRIVATE}>
            <TextInput
              label="Förnamn"
              name="firstName"
              required
              fieldProps={{
                parse: (v) => removeInvalidSveaChars(v),
                format: (v) => (v ? removeInvalidSveaChars(v) : ''),
              }}
            />
          </Condition>
        </Grid>
        <Grid item xs={6}>
          <Condition when="category" is={CustomerCategoryTypes.COMPANY}>
            <TextInput
              label="Kontakt Efternamn"
              name="lastName"
              required
              fieldProps={{
                parse: (v) => removeInvalidSveaChars(v),
                format: (v) => (v ? removeInvalidSveaChars(v) : ''),
              }}
            />
          </Condition>
          <Condition when="category" is={CustomerCategoryTypes.PRIVATE}>
            <TextInput
              label="Efternamn"
              name="lastName"
              required
              fieldProps={{
                parse: (v) => removeInvalidSveaChars(v),
                format: (v) => (v ? removeInvalidSveaChars(v) : ''),
              }}
            />
          </Condition>
        </Grid>
      </Grid>

      <TextInput label="Adress 1" name="address" required />

      <TextInput label="Adress 2" name="address2" />

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ZipInput />
        </Grid>
        <Grid item xs={6}>
          <TextInput label="Stad" name="city" required />
        </Grid>
      </Grid>

      <Condition when="category" is={CustomerCategoryTypes.COMPANY}>
        <TextInput
          disabled={disablePinField}
          label={'Organisationsnummer'}
          name="pin"
          required
          fieldProps={{
            parse: normalizeToNumbersOnly,
            format: (value) => (value ? normalizeToNumbersOnly(value) : ''),
            validate: fieldIsOrgNumber,
          }}
        />
      </Condition>
      <Condition when="category" is={CustomerCategoryTypes.PRIVATE}>
        <TextInput
          disabled={disablePinField}
          label={'Personnummer'}
          name="pin"
          required
          fieldProps={{
            parse: normalizeToPersonNumberShort,
            format: (value) =>
              value ? normalizeToPersonNumberShort(value) : '',
            validate: fieldIsPersonalNumber,
          }}
        />
      </Condition>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <PhoneInput label="Telefon" name="phone" required={false} />
        </Grid>
        <Grid item xs={6}>
          <PhoneInput label="Mobil" name="mobile" required={false} />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <EmailInput
            label="Email (kontaktperson)"
            name="email"
            required={false}
          />
        </Grid>
        <Grid item xs={6}>
          <EmailInput
            label="Email (fakturor)"
            name="invoiceEmail"
            required={false}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Checkboxes
            name="doNotDisturb"
            options={{
              label: 'Stör ej kund',
              value: 'true',
            }}
          />
        </Grid>

        <Grid item xs={6}>
          {showDebtCollectionCheckbox && (
            <Checkboxes
              name="debtCollection"
              options={{
                label: 'Ej inkasso',
                value: 'true',
              }}
            />
          )}
        </Grid>
      </Grid>

      {!disablePartnerField &&
        (isLoading ? (
          <Skeleton />
        ) : isError ? (
          <BasicAlert />
        ) : (
          <Dropdown
            label="Partner Nummer"
            name="partnerCompany"
            options={partnerSelectOptions}
          />
        ))}
    </>
  );
};
