import React from 'react';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import Collapse from '@mui/material/Collapse';

import { Can } from '@/components/Can';
import { CollapsableMenuList } from '@/layouts/Main/components/Sidebar/components/SidebarNav/components/CollapsableMenuList';
import { MenuListItem } from '@/layouts/Main/components/Sidebar/components/SidebarNav/components/MenuListItem';
import { MenuListItemButton } from '@/layouts/Main/components/Sidebar/components/SidebarNav/components/MenuListItemButton';
import { UserRole } from '@/types/auth';

import { SubMenuListItem } from './SubMenuListItem';

type Props = {
  userRoles: UserRole[];
};

export const AccountManagerMenu = ({ userRoles }: Props) => {
  const [open, setOpen] = React.useState(false);
  const collapseRef = React.useRef<HTMLDivElement>(null);

  return (
    <>
      <MenuListItem>
        <MenuListItemButton onClick={() => setOpen(!open)}>
          Account Manager
          {open ? <ExpandLess /> : <ExpandMore />}
        </MenuListItemButton>
      </MenuListItem>
      <Collapse
        ref={collapseRef}
        in={open}
        timeout="auto"
        unmountOnExit
        exit={false}
      >
        <CollapsableMenuList>
          <Can
            userRoles={userRoles}
            action="accountManagerSystem.stats.HOB2B"
            yes={() => (
              <SubMenuListItem
                title="Statistik - HOB2B"
                href="/account-manager-system/hob2b/statistics"
              />
            )}
          />
          <Can
            userRoles={userRoles}
            action="accountManagerSystem.stats.salesManager"
            yes={() => (
              <SubMenuListItem
                title="Statistik - Säljare"
                href="/account-manager-system/sales-manager/statistics"
              />
            )}
          />
          <Can
            userRoles={userRoles}
            action="accountManagerSystem.onboarding.view"
            yes={() => (
              <SubMenuListItem
                title="Onboarding"
                href="/account-manager-system/onboarding"
              />
            )}
          />
          <Can
            userRoles={userRoles}
            action="accountManagerSystem.upsale.view"
            yes={() => (
              <SubMenuListItem
                title="Upsale"
                href="/account-manager-system/upsale"
              />
            )}
          />
          <Can
            userRoles={userRoles}
            action="accountManagerSystem.antiChurn.view"
            yes={() => (
              <SubMenuListItem
                title="Anti-churn"
                href="/account-manager-system/anti-churn"
              />
            )}
          />
          <Can
            userRoles={userRoles}
            action="accountManagerSystem.winback.view"
            yes={() => (
              <SubMenuListItem
                title="Win-back"
                href="/account-manager-system/win-back"
              />
            )}
          />
          <Can
            userRoles={userRoles}
            action="accountManagerSystem.overview.view"
            yes={() => (
              <SubMenuListItem
                title="Översikt"
                href="/account-manager-system/statistics/overview"
              />
            )}
          />
          {/* <Can
            userRoles={userRoles}
            action="accountManagerSystem.tools.view"
            yes={() =>
              <SubMenuListItem title='Verktyg' href='/account-manager-system/tools' />
            }
          /> */}
          <Can
            userRoles={userRoles}
            action="accountManagerSystem.customer.transfer"
            yes={() => (
              <SubMenuListItem
                title="Kundhantering"
                href="/account-manager-system/customer-management"
              />
            )}
          />
          <Can
            userRoles={userRoles}
            action="accountManagerSystem.teams.view"
            yes={() => (
              <SubMenuListItem
                title="Teamhantering"
                href="/account-manager-system/team"
              />
            )}
          />
        </CollapsableMenuList>
      </Collapse>
    </>
  );
};
