import React from 'react';

import { Block, Check, Clear, Delete, Edit } from '@mui/icons-material';
import {
  CircularProgress,
  Divider,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';

import { SimpleTableColumn } from '@/components/SimpleTable';
import { getFormattedDate } from '@/helpers/dates';
import { Campaign } from '@/types/campaigns';

import { CampaignTableColumnProps } from './getCampaignTableColumns';

export const getSinneCampaignTableColumns = ({
  handleEditCampaign,
  deleting,
  deleteCampaignMutation,
  handleDeleteOpen,
  anchorEl,
  handleDeleteClose,
  handleDelete,
}: Omit<CampaignTableColumnProps, 'campaignType'>) => {
  const columns: SimpleTableColumn<Campaign>[] = [
    { label: 'Id', renderer: (campaign) => campaign.id },
    { label: 'Namn', renderer: (campaign) => campaign.name },
    { label: 'Beskrivning', renderer: (campaign) => campaign.description },
    {
      label: 'Enheter',
      align: 'right',
      renderer: (campaign) => campaign.units,
    },
    {
      label: 'Skapad',
      align: 'right',
      renderer: (campaign) => getFormattedDate(campaign.createdAt),
    },
    {
      label: 'Aktiv',
      align: 'right',
      renderer: (campaign) =>
        campaign.active ? <Check /> : <Clear color="error" />,
    },
    {
      label: 'Redigera',
      align: 'right',
      renderer: (campaign) => (
        <IconButton
          disabled={!campaign.isEditable}
          onClick={() => handleEditCampaign(campaign['@id'])}
          size="large"
        >
          {campaign.isEditable ? <Edit /> : <Block color="error" />}
        </IconButton>
      ),
    },
    {
      label: 'Ta bort',
      align: 'right',
      renderer: (campaign) => (
        <>
          {deleting ? (
            <CircularProgress />
          ) : (
            <>
              <IconButton
                aria-label="delete"
                size="small"
                value={campaign['@id']}
                disabled={
                  !campaign.isEditable || deleteCampaignMutation.isPending
                }
                onClick={handleDeleteOpen}
              >
                <Delete fontSize="small" />
              </IconButton>
              <Menu
                id="delete-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleDeleteClose}
              >
                <MenuItem onClick={handleDeleteClose} disabled>
                  Ta bort?
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleDelete}>Ja</MenuItem>
                <MenuItem onClick={handleDeleteClose}>Nej</MenuItem>
              </Menu>
            </>
          )}
        </>
      ),
    },
  ];
  return columns;
};
