import React from 'react';

import CreateIcon from '@mui/icons-material/Create';
import {
  Avatar,
  Box,
  Chip,
  LinearProgress,
  Tooltip,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { Pagination } from '@/components/Pagination';
import { SimpleTable } from '@/components/SimpleTable';
import { RoleTypes } from '@/constants/roleTypes';
import { getInitials } from '@/helpers';
import { UserItem } from '@/types/users';

import { useGetActiveUsers, useGetDeletedUsers } from '../hooks';
import { ReactivationDialog } from './ReactivationDialog';

type Props = {
  type: 'active' | 'deleted';
};
export const UsersTable = ({ type }: Props) => {
  const [selectedUser, setSelectedUser] = React.useState<
    Partial<UserItem> | undefined
  >(undefined);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [page, setPage] = React.useState(0);
  const handlePageChange = (page: number) => {
    setPage(page);
  };
  const navigate = useNavigate();

  const handleRowsPerPageChange = (rows: string) => {
    setRowsPerPage(parseInt(rows, 10));
  };
  const {
    data: activeUsers,
    status: activeUsersQueryStatus,
    isFetching: isFetchingActiveUsers,
  } = useGetActiveUsers({
    page,
    perPage: rowsPerPage,
    enabled: type === 'active',
  });
  const {
    data: deletedUsers,
    status: deletedUsersQueryStatus,
    isFetching: isFetchingDeletedUsers,
  } = useGetDeletedUsers({
    page,
    perPage: rowsPerPage,
    enabled: type !== 'active',
  });
  const isError =
    activeUsersQueryStatus === 'error' || deletedUsersQueryStatus === 'error';
  const isFetching = isFetchingActiveUsers || isFetchingDeletedUsers;
  const isSuccess =
    activeUsersQueryStatus === 'success' ||
    deletedUsersQueryStatus === 'success';
  const data = type === 'active' ? activeUsers : deletedUsers;

  if (isError) {
    return <Typography variant="h1">Misslyckades hämta användare.</Typography>;
  }
  if (isFetching) {
    return <LinearProgress />;
  }
  const columns = [
    { label: 'Id', renderer: (row: UserItem) => row.id },
    {
      label: 'Namn',
      renderer: (row: UserItem) => (
        <Box display="flex" alignItems="center">
          <Avatar src={row.avatarUrl} sx={{ mr: 2 }}>
            {getInitials(row.name)}
          </Avatar>
          <p>{row.name}</p>
        </Box>
      ),
    },
    { label: 'Mejl', renderer: (row: UserItem) => row.email },
    { label: 'Användarnamn', renderer: (row: UserItem) => row.username },
    {
      label: 'Roll',
      renderer: (row: UserItem) => {
        if (!row.roles || row.roles.length === 0) {
          return null;
        }
        const roleChips = row.roles
          .map((role) => RoleTypes[role])
          .filter((label) => label != null)
          .map((label) => (
            <Chip key={label} label={label} size="small" sx={{ m: '3px' }} />
          ));

        return roleChips;
      },
    },
    {
      label: '',
      renderer: () =>
        type === 'deleted' && (
          <Tooltip title="Återaktivera" children={<CreateIcon />} />
        ),
    },
  ];
  const handleRowClick = (row: { id: number }) => {
    type === 'active' && navigate(`/user/${row.id}`);

    if (data && type === 'deleted') {
      setSelectedUser(data.users.find((user) => user.id === row.id));
    }
  };
  return (
    <>
      {isSuccess && (
        <>
          <SimpleTable
            columns={columns}
            rows={data?.users}
            onRowClick={handleRowClick}
          />
          <Pagination
            count={data.totalUsers}
            onPageChange={(e, page) => handlePageChange(page)}
            onRowsPerPageChange={(e) => handleRowsPerPageChange(e.target.value)}
            page={page}
            rowsPerPage={rowsPerPage}
          />{' '}
          {selectedUser && (
            <ReactivationDialog
              open={Boolean(selectedUser)}
              handleClose={() => setSelectedUser(undefined)}
              user={selectedUser}
            />
          )}
        </>
      )}
    </>
  );
};
