import { useAtomValue } from 'jotai';

import { AlarmCustomerTaskModal } from '@/modules/alarms/components';
import {
  AddCostModal,
  MailCustomerModal,
  SmsCustomerModal,
} from '@/modules/common/components';
import { CreateCustomerModal } from '@/modules/customer/components';
import { CreateAdminTaskModal } from '@/modules/tasks/components';
import { ModalTypes, modalManagementAtom } from '@/state/modalManagementAtom';

function mapModalTypeToComponent(
  modalType: (typeof ModalTypes)[keyof typeof ModalTypes],
) {
  switch (modalType) {
    case ModalTypes.MAIL_CUSTOMER:
      return MailCustomerModal;

    case ModalTypes.SMS_CUSTOMER:
      return SmsCustomerModal;

    case ModalTypes.CREATE_ADMIN_TASK:
      return CreateAdminTaskModal;

    case ModalTypes.ADD_COST:
      return AddCostModal;

    case ModalTypes.CREATE_CUSTOMER:
      return CreateCustomerModal;

    case ModalTypes.CUSTOMER_TASK:
      return AlarmCustomerTaskModal;

    default:
      return null;
  }
}

export function ModalManager() {
  const modalAtom = useAtomValue(modalManagementAtom);

  if (!modalAtom) {
    return null;
  }

  const Component = mapModalTypeToComponent(modalAtom.modalType);

  if (!Component) {
    return null;
  }
  return <Component modal={modalAtom as any} />;
}
