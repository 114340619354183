import { Grid } from '@mui/material';

import { TextInput } from '@/components/form';

export const AlarmLocationSection = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <TextInput label="Placering av larmet" name="placement" />
      </Grid>
    </Grid>
  );
};
