import React from 'react';

import { Button, Grid, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useFetchBasicCustomerData } from '@/modules/services/hooks/useFetchBasicCustomerData';
import { BasicCustomerData } from '@/types/customers';
import { GenericHttpError } from '@/types/errors';

type Props = {
  onCustomerFound: (customer: BasicCustomerData) => void;
  isFirstStep: boolean;
};

export const CustomerIdCheck = ({ onCustomerFound, isFirstStep }: Props) => {
  const { mutate, isPending, isError } = useFetchBasicCustomerData();
  const [errorMessage, setErrorMessage] = React.useState('');

  function handleSubmit(e: React.SyntheticEvent) {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      customerId: { value: string };
    };
    const {
      customerId: { value },
    } = target;
    if (value) {
      mutate(value, {
        onSuccess: (data) => onCustomerFound(data),
        onError: (error: unknown) => {
          const { status } = error as GenericHttpError;
          if (status === 404) {
            setErrorMessage('Hittar ej kund');
          } else {
            setErrorMessage('Något gick fel');
          }
        },
      });
    }
  }

  return (
    <Form onSubmit={handleSubmit} noValidate autoComplete="off">
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs>
          <TextField
            autoFocus
            name="customerId"
            label="Kundnummer"
            margin="none"
            size="small"
            variant="outlined"
            fullWidth
          />
        </Grid>

        <Grid item>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            size="small"
            disabled={isPending}
          >
            {isFirstStep ? 'Nästa' : 'Skanna nästa kund'}
          </Button>
        </Grid>
      </Grid>

      {isError && (
        <Typography gutterBottom color="error">
          {errorMessage}
        </Typography>
      )}
    </Form>
  );
};

const Form = styled('form')`
  margin-top: ${({ theme }) => theme.spacing(2)};
`;
