import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import {
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  LinearProgress,
  Typography,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { deleteFee, getFees } from '@/api/fees';
import { Alert } from '@/components/Alert';
import { ConfirmationDialog } from '@/components/ConfirmationDialog';
import { SimpleTable } from '@/components/SimpleTable';
import { queryKeys } from '@/constants/queryKeys';
import { getFormattedDate } from '@/helpers/dates';
import { useSnackbar } from '@/hooks';
import { Fee } from '@/types/fees';

type Props = {
  companyCustomer: boolean;
};

export const ComingSpecialFees = ({ companyCustomer }: Props) => {
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();
  const { id: customerId } = useParams();

  const [confirmationModalOpened, setConfirmationModalOpened] =
    React.useState(false);
  const [feeToDelete, setFeeToDelete] = React.useState<Fee | null>(null);

  const {
    data: comingSpecialFees,
    isLoading,
    isError,
  } = useQuery({
    queryKey: [queryKeys.getFees, customerId],

    queryFn: async () => {
      const { data } = await getFees({ customerId: parseInt(customerId!, 10) });
      return data['hydra:member'];
    },
  });

  const deleteMutation = useMutation({
    mutationFn: deleteFee,
    onSuccess: async () => {
      snackbar({ type: 'success', message: 'Kostnad borttagen.' });
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.getFees],
      });
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.getInvoiceList],
      });
    },
    onError: () => {
      snackbar({
        type: 'error',
        message: 'Kunde inte ta bort kostnad.',
      });
    },
  });

  function handleOpenConfirmationModal(fee: Fee) {
    setFeeToDelete(fee);
    setConfirmationModalOpened(true);
  }

  function handleCloseConfirmationModal() {
    setFeeToDelete(null);
    setConfirmationModalOpened(false);
  }

  function handleDeleteFee() {
    if (feeToDelete) {
      deleteMutation.mutate(feeToDelete.id);
      handleCloseConfirmationModal();
    }
  }

  const dateRenderer = (fee: Fee) =>
    fee.invoiceAt ? getFormattedDate(fee.invoiceAt, 'yyyy-MM-dd') : '';

  const nameRenderer = (fee: Fee) => fee.title;

  const priceRenderer = (fee: Fee) =>
    companyCustomer ? fee.price : fee.priceWithSalesTax;

  const descriptionRenderer = (fee: Fee) => fee.description;

  const columns = [
    { label: 'Datum', renderer: dateRenderer },
    { label: 'Namn', renderer: nameRenderer },
    {
      label: companyCustomer ? 'Pris (exkl moms)' : 'Pris (inkl moms)',
      renderer: priceRenderer,
    },
    { label: 'Beskrivning', renderer: descriptionRenderer },
    {
      label: 'Ta bort',
      renderer: (fee: Fee) => (
        <IconButton
          size="small"
          onClick={() => handleOpenConfirmationModal(fee)}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      ),
    },
  ];

  return (
    <>
      <CardHeader title="Kommande engångsavgifter" />
      <Divider />
      <CardContent>
        {isLoading ? (
          <LinearProgress />
        ) : isError ? (
          <Alert severity="error">Något gick fel</Alert>
        ) : (
          <SimpleTable columns={columns} rows={comingSpecialFees || []} />
        )}
      </CardContent>

      <ConfirmationDialog
        dialogTitle="Ta bort avgift"
        dialogText={
          <>
            Vill du ta bort{' '}
            <Typography fontWeight="bold">{feeToDelete?.title}</Typography>?
          </>
        }
        isOpened={confirmationModalOpened}
        onClose={handleCloseConfirmationModal}
        onConfirm={handleDeleteFee}
      />
    </>
  );
};
