import { useEffect, useMemo } from 'react';

import {
  Alert,
  AlertTitle,
  LinearProgress,
  CssBaseline as MuiCssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material';
import { LocalizationProvider, svSE } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import * as Sentry from '@sentry/react';
import { inspect } from '@xstate/inspect';
import { CypressHistorySupport } from 'cypress/CypressHistorySupport';
import sv from 'date-fns/locale/sv';
import { BrowserRouter } from 'react-router-dom';

import { SendingsManager } from '@/components/SendingsManager';
import { WLid, fontConfig } from '@/helpers/fontConfig';
import {
  useCheckAdBlocker,
  useDarkMode,
  usePrivateSettings,
  useSnackbar,
  useWhiteLabelFavicon,
} from '@/hooks';
import { useFeatureFlags } from '@/hooks/useFeatureFlags';
import usePublicSettings from '@/hooks/usePublicSettings';
import { useSendingsState } from '@/hooks/useSendingsState';
import { ModalManager } from '@/modules/common/components/ModalManager';
import CssBaseline from '@/theme/CssBaseline';

import { Routes } from './Routes';
import { ScrollToTop } from './components/ScrollToTop';
import { SnackbarManager } from './components/SnackbarManager';
import { WL_NUMBERS } from './constants/whiteLabels';
import { getEnvConfig } from './helpers/config';
import { useAuth } from './hooks/useAuth';
import { prepareTheme } from './theme';

const App = () => {
  const snackbar = useSnackbar();
  const {
    isLoading: isLoadingPrivateSettings,
    isError: isErrorPrivateSettings,
  } = usePrivateSettings();

  const { darkModeState } = useDarkMode();

  const config = getEnvConfig();

  const {
    publicSettings,
    isLoading: loadingPublicSettings,
    isError: errorPublicSettings,
  } = usePublicSettings();

  const {
    sendingsState: { active: showSendingsManager },
  } = useSendingsState();

  const { isLoading: isLoadingFeatureFlags } = useFeatureFlags();
  const { authenticatedUser } = useAuth();

  const { isUsingAdblock } = useCheckAdBlocker();

  fontConfig(config.whitelabelId as WLid);

  if (
    import.meta.env.MODE === 'development' &&
    import.meta.env.VITE_XSTATE_INSPECTOR_ENABLED === 'true'
  ) {
    // XSTATE INSPECTOR

    inspect({
      // options
      // url: 'https://stately.ai/viz?inspect', // (default)
      iframe: false, // open in new window
    });
  }

  useDarkMode();
  useWhiteLabelFavicon();

  useEffect(() => {
    if (isUsingAdblock) {
      snackbar({
        type: 'warning',
        autoHide: false,
        message: `Det verkar som du använder en annonsblockerare. Var vänlig och
              stäng av den för att säkerställa att Monitum fungerar korrekt.`,
      });
    }
  }, [isUsingAdblock, snackbar]);

  useEffect(() => {
    if (authenticatedUser) {
      Sentry.setUser({
        username: authenticatedUser.username,
        roles: authenticatedUser.roles,
      });
    }
  }, [authenticatedUser]);

  const theme = useMemo(() => {
    if (publicSettings?.colors) {
      return prepareTheme(
        publicSettings.colors,
        darkModeState.paletteType,
        darkModeState.darkMode,
      );
    }
  }, [publicSettings, darkModeState]);

  if (errorPublicSettings) {
    return (
      <Alert severity="error">
        <AlertTitle>
          Servern kan vara nere, kontakta vårt supportteam
        </AlertTitle>
      </Alert>
    );
  }

  if (!theme) {
    return <LinearProgress />;
  }

  if (
    (authenticatedUser && isLoadingPrivateSettings) ||
    loadingPublicSettings
  ) {
    return <LinearProgress />;
  }

  if (isLoadingFeatureFlags) {
    return <LinearProgress />;
  }

  if (isErrorPrivateSettings) {
    return (
      <Alert severity="error">
        <AlertTitle>Inställningarna kunde inte hämtas</AlertTitle>
      </Alert>
    );
  }

  return (
    <BrowserRouter>
      <CypressHistorySupport />
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={sv}
        localeText={
          svSE.components.MuiLocalizationProvider.defaultProps.localeText
        }
      >
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <MuiCssBaseline enableColorScheme />
            <CssBaseline />

            <ScrollToTop />

            <Routes />

            <ModalManager />
            <SnackbarManager />
            {showSendingsManager && <SendingsManager />}
          </ThemeProvider>
        </StyledEngineProvider>
      </LocalizationProvider>
    </BrowserRouter>
  );
};

const AppWithSentryProfiles = Sentry.withProfiler(App);

export default AppWithSentryProfiles;
