import { getElementFromArray } from '@/helpers/getElementFromArray';
import { getObjectKeys } from '@/helpers/tsHelpers';
import { NoteTypes } from '@/types/notes';

// Supposed to be used with Dropdown fields
export const getNoteTypeOptions = (noteTypes: NoteTypes) => {
  return getObjectKeys(noteTypes)
    .map((key) => ({
      value: key,
      text: getElementFromArray(noteTypes[key]),
    }))
    .sort((a, b) => {
      if (a.text < b.text) {
        return -1;
      }
      if (a.text > b.text) {
        return 1;
      }
      return 0;
    });
};
