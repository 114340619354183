import React from 'react';

import { useMutation } from '@tanstack/react-query';

import { marketingCheck } from '@/api/marketing';
import { GenericHttpError } from '@/types/errors';

export type MarketingCheckType = 'all' | 'exists' | 'firetext';

export type MarketingCheckResponse = {
  canContact: boolean;
  error: string;
  id: number;
  pin: string;
};

export const useAddressWash = () => {
  const [data, setData] = React.useState<{
    [key in MarketingCheckType]?: MarketingCheckResponse[];
  }>({});
  const [error, setError] = React.useState<GenericHttpError | null>(null);

  const marketingCheckMutation = useMutation({
    mutationFn: marketingCheck,
    onSuccess: (response, variables) =>
      setData((prevState) => ({ ...prevState, [variables.type]: response })),
    onError: (error: unknown) => {
      setError(error as GenericHttpError);
    },
  });

  return { sendMarketingCheck: marketingCheckMutation, data, error };
};
