import { enqueueSnackbar } from 'notistack';

import { OpenSnackbarParams } from '@/types/snackbar';

export const useSnackbar = () => {
  return (params: OpenSnackbarParams) => {
    enqueueSnackbar(params.message, {
      variant: params.type,
      autoHideDuration: !params.autoHide ? 5000 : null,
      title: params.title,
    });
  };
};
