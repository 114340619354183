import { useMutation } from '@tanstack/react-query';

import {
  KeyTagBulkRegistrationParams,
  keyTagBulkRegistration,
} from '@/api/keyTag';

export const useBulkRegisterKeyTags = () => {
  return useMutation({
    mutationFn: (payload: KeyTagBulkRegistrationParams) => {
      return keyTagBulkRegistration(payload);
    },
  });
};
