import {
  BulkBatchesResponse,
  FailedBulkBatchResponse,
} from '@/types/bulkBatch';
import httpClient from '@/utils/httpClient';

export type GetBulkBatchesParams = {
  page?: number;
  perPage?: number;
  pagination?: boolean;
  batchUUID?: string;
};

export async function getBulkBatches(params: GetBulkBatchesParams) {
  const { data } = await httpClient.get<BulkBatchesResponse>('/bulk_batches', {
    params: {
      page: params.page ?? 1,
      perPage: params.page ?? 30,
      pagination: params.pagination ?? false,
      batchUUID: params.batchUUID ?? params.batchUUID,
    },
  });

  return data;
}

// TODO: Improve type when checked in the App
export async function getFailedBulkBatchItem(iri: string) {
  const { data } = await httpClient.get<unknown>(iri);

  return data;
}

export type GetFailedBulkBatchItemsParams = {
  page?: number;
  perPage?: number;
  bulkBatch: string;
};

export async function getFailedBulkBatchItems({
  page = 1,
  perPage = 30,
  bulkBatch,
}: GetFailedBulkBatchItemsParams) {
  const { data } = await httpClient.get<FailedBulkBatchResponse>(
    '/failed_bulk_batch_items',
    {
      params: {
        page,
        perPage,
        'bulkBatch.batchUUID': bulkBatch,
      },
    },
  );

  return data;
}
