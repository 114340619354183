import { useMutation } from '@tanstack/react-query';

import { createRmaCase } from '@/api/rma';
import { CreateRmaCasePayload } from '@/types/rma';

export const useCreateRmaCase = () => {
  return useMutation({
    mutationFn: (params: CreateRmaCasePayload) => {
      return createRmaCase(params);
    },
  });
};
