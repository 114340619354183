import { Personnummer } from '@/helpers/validators/personnummer';
import Yup from '@/utils/validation';

export const VALIDATION_SCHEMAS = {
  email: Yup.object().shape({
    email: Yup.string().email(),
  }),
  pin: Yup.object().shape({
    pin: Yup.string()
      .length(12, 'Ange 12 siffror')
      .test(
        'pin-validation',
        'Ange ett giltigt personnummer med 12 siffror',
        function (pin) {
          if (pin === undefined) {
            return true;
          }
          return Personnummer.valid(pin);
        },
      ),
  }),
  password: Yup.object({
    oldPassword: Yup.string().required(),
    newPassword: Yup.string().required(),
    confirmNewPassword: Yup.string().test(
      'passwords-match',
      'Lösenorden måste överensstämma',
      function (value) {
        return this.parent.newPassword === value;
      },
    ),
  }),
  username: Yup.object().shape({
    username: Yup.string()
      .test(
        'username-no-whitespace',
        'Inga mellanslag',
        (username) => username === undefined || username.indexOf(' ') === -1,
      )
      .required(),
  }),
};
