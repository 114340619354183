import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  LinearProgress,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { Form } from 'react-final-form';

import { changePassword } from '@/api/auth';
import { BasicAlert } from '@/components/BasicAlert';
import { TextInput } from '@/components/form';
import { useSnackbar } from '@/hooks';
import { useUpdateUser } from '@/modules/user/hooks';
import Yup, { makeValidation } from '@/utils/validation';

type ChangePasswordPayload = {
  oldPassword: string;
  newPassword: string;
};

type FormValues = ChangePasswordPayload & { confirmNewPassword: string };
type Props = {
  handleClose: () => void;
  open: boolean;
  isCurrentUser: boolean;
  userId: number;
};

export const ChangePasswordModal = ({
  isCurrentUser,
  handleClose,
  open,
  userId,
}: Props) => {
  const snackbar = useSnackbar();
  const validationSchema = Yup.object({
    ...(isCurrentUser && { oldPassword: Yup.string().required() }),
    newPassword: Yup.string().required(),
    confirmNewPassword: Yup.string().test(
      'passwords-match',
      'Lösenorden måste överensstämma',
      function (value) {
        return this.parent.newPassword === value;
      },
    ),
  });

  const validate = makeValidation(validationSchema);

  const updateUserMutation = useUpdateUser();

  type ChangePasswordError = { error: string };

  const changePasswordMutation = useMutation<
    {},
    ChangePasswordError,
    ChangePasswordPayload
  >({ mutationFn: changePassword });

  const handleSubmit = (values: FormValues) => {
    const { confirmNewPassword, ...payload } = values;
    let mutationPromise;
    if (isCurrentUser) {
      mutationPromise = changePasswordMutation.mutateAsync(payload);
    } else {
      mutationPromise = updateUserMutation.mutateAsync({
        fields: { plainPassword: payload.newPassword },
        userId,
      });
    }

    mutationPromise
      .then(() => {
        handleClose();
        snackbar({
          type: 'success',
          message: 'Lösenord uppdaterat',
        });
      })
      .catch((e) => {
        if (e?.data?.error) {
          snackbar({
            type: 'error',
            message: e?.data?.error,
          });
        } else {
          snackbar({
            type: 'error',
            message: e?.response?.data?.error ?? 'Något gick fel',
          });
        }
      });
  };

  const isLoading =
    changePasswordMutation.isPending || updateUserMutation.isPending;
  const isError = changePasswordMutation.isError || updateUserMutation.isError;

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      onClose={handleClose}
      aria-labelledby="dialog-title"
      open={open}
    >
      <DialogTitle id="dialog-title">Ändra lösenord</DialogTitle>
      <Divider />

      <Form
        onSubmit={handleSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <DialogContent>
              <Typography>
                <strong>Rekommendationer kring lösenord:</strong>
              </Typography>

              <List dense>
                <ListItem>
                  <Typography>
                    Ange minst åtta tecken - ju fler tecken, desto bättre.
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    Ha en blandning av både stora och små bokstäver.
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    Ha en blandning av bokstäver och siffror.
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    Inkludera minst en speciell karaktär, t.ex. ()+!@#?[]
                  </Typography>
                </ListItem>
              </List>

              <div>
                {isCurrentUser && (
                  <TextInput
                    type="password"
                    label="Nuvarande lösenord"
                    name="oldPassword"
                    fullWidth
                    size="small"
                    required
                  />
                )}
                <TextInput
                  type="password"
                  label="Nya lösenordet"
                  name="newPassword"
                  fullWidth
                  size="small"
                  required
                />
                <TextInput
                  type="password"
                  label="Bekräfta nya lösenordet"
                  name="confirmNewPassword"
                  fullWidth
                  size="small"
                  required
                />
                {isError && <BasicAlert />}
              </div>
            </DialogContent>

            {isLoading && <LinearProgress />}

            <Divider />

            <DialogActions>
              <Button
                disabled={isLoading}
                onClick={handleClose}
                color="primary"
                variant="outlined"
              >
                Avbryt
              </Button>
              <Button
                disabled={isLoading}
                type="submit"
                color="primary"
                variant="contained"
              >
                Spara Ändring
              </Button>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};
