import httpClient from '@/utils/httpClient';

export type SendAdminTaskPayload = {
  customerId: string;
  message: string;
  choreType: number;
};

export async function sendAdminTask(payload: SendAdminTaskPayload) {
  return await httpClient.post<unknown>(`/rpc/send-email/admin-chore`, payload);
}

export type SendMailToCustomerPayload = {
  customerId: number;
  message: string;
  receiver?: string;
};

export async function sendMailToCustomer(payload: SendMailToCustomerPayload) {
  return await httpClient.post<unknown>(
    `/rpc/send-email/direct-customer`,
    payload,
  );
}

export type SendEmailToCustomerServicePayload = {
  customerId: number;
  message: string;
  title: string;
};

export async function sendEmailToCustomerService(
  payload: SendEmailToCustomerServicePayload,
) {
  return await httpClient.post<unknown>(
    `/rpc/send-email/customer-service`,
    payload,
  );
}
