import React from 'react';

import { Clear } from '@mui/icons-material';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { PRICE_TRANSLATIONS, PRICE_TYPES } from '@/constants';

import { PriceForm } from './PriceForm';

type Props = {
  open: boolean;
  handleVisibility: () => void;
  priceToEdit?: string;
  queryKey: any[];
  type: keyof typeof PRICE_TYPES;
};

export const PriceModal = ({
  open,
  handleVisibility,
  priceToEdit,
  queryKey,
  type,
}: Props) => {
  return (
    <Dialog maxWidth="sm" fullWidth onClose={handleVisibility} open={open}>
      <StyledDialogTitle variant="h3">
        {priceToEdit ? 'Redigera' : 'Skapa'} {PRICE_TRANSLATIONS[type]}
        <StyledCloseIconButton onClick={handleVisibility} size="large">
          <Clear />
        </StyledCloseIconButton>
      </StyledDialogTitle>
      <Divider />
      <DialogContent>
        <PriceForm
          onClose={handleVisibility}
          queryKey={queryKey}
          priceToEdit={priceToEdit}
          type={type}
        />
      </DialogContent>
    </Dialog>
  );
};

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  margin: theme.spacing(2),
}));

const StyledCloseIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(2.5),
  top: theme.spacing(2.5),
}));
