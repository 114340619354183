import React from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import omit from 'lodash/omit';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { useSnackbar } from '@/hooks/useSnackbar';
import { GenericHttpError } from '@/types/errors';
import { PartnerResponse } from '@/types/partnerCompany';

import {
  fullFormDefaultValues,
  kickbackDefaultValues,
  nonNullableFieldsAsEmptyStringFields,
  partnerFormSchema,
  tickDefaultValues,
} from '../../const';
import {
  getFormattedTickFieldValues,
  getKickbackNullValues,
  getTickNullValues,
  tickFieldNames,
} from '../../helpers';
import {
  partnerCompaniesQueryKey,
  useCreatePartner,
  useUpdatePartner,
} from '../../hooks';

export type FormValues = z.infer<typeof partnerFormSchema>;

const payloadSchema = partnerFormSchema.extend({
  kickback: z.boolean(),
  tick: z.boolean(),
});

export type Payload = z.infer<typeof payloadSchema>;

type Props = {
  children: React.ReactNode;
  isEdit: boolean;
  partner?: PartnerResponse;
};

export const PartnerForm = ({ children, isEdit, partner }: Props) => {
  const queryClient = useQueryClient();
  const createMutation = useCreatePartner();
  const updateMutation = useUpdatePartner();
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  const partnerId = partner?.id;

  const formMethods = useForm<FormValues>({
    resolver: zodResolver(partnerFormSchema),
    defaultValues: fullFormDefaultValues,
    disabled: Boolean(queryClient.isMutating()),
  });

  const { reset } = formMethods;

  React.useEffect(() => {
    if (partner) {
      const values = omit(partner, ['@id', '@type', ...tickFieldNames]);

      const kickbackEnabled = values.kickback;
      const tickEnabled = values.tick;

      const formattedTickFields = getFormattedTickFieldValues(partner);

      const defaultValues = {
        ...values,
        ...formattedTickFields,
        kickback: kickbackEnabled ? 'true' : 'false',
        tick: tickEnabled ? 'true' : 'false',
        daysWithKickback: values.daysWithKickback.toString(),
        daysWithTick: values.daysWithTick.toString(),
        ...(kickbackEnabled ? {} : kickbackDefaultValues),
        ...(tickEnabled ? {} : tickDefaultValues),
      } as FormValues;
      reset(defaultValues);
    }
  }, [partner, reset]);

  const onError = (error: GenericHttpError) => {
    snackbar({
      title: 'Något gick fel',
      type: 'error',
      message: error.statusText,
    });
  };

  const onSuccess = () => {
    const message = isEdit ? 'Partner uppdaterad' : 'Partner skapad';
    snackbar({ message, type: 'success' });

    isEdit && partnerId
      ? queryClient.invalidateQueries({
          queryKey: partnerCompaniesQueryKey.detailById(partnerId),
        })
      : navigate('/partner/accounts');
  };

  const onSubmit = (values: FormValues) => {
    const payload = {
      ...values,
      kickback: values.kickback === 'true',
      tick: values.tick === 'true',
      daysWithKickback: Number(values.daysWithKickback),
      daysWithTick: Number(values.daysWithTick),
      // Set values to null if kickback or tick is false
      ...(values.kickback === 'false' ? getKickbackNullValues() : {}),
      ...(values.tick === 'false' ? getTickNullValues() : {}),
      ...nonNullableFieldsAsEmptyStringFields,
    };

    if (isEdit && partnerId) {
      updateMutation.mutate(
        { id: partnerId, ...payload },
        {
          onSuccess() {
            onSuccess();
          },
          onError(error: unknown) {
            onError(error as GenericHttpError);
          },
        },
      );
    } else {
      createMutation.mutate(payload, {
        onSuccess() {
          onSuccess();
        },
        onError(error: unknown) {
          onError(error as GenericHttpError);
        },
      });
    }
  };
  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>{children}</form>
    </FormProvider>
  );
};
