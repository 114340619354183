import {
  FiretextHistoryResponse,
  FiretextItem,
  FiretextsResponse,
} from '@/types/services';
import httpClient from '@/utils/httpClient';

export async function getFiretextById(id: number) {
  const { data } = await httpClient.get<FiretextItem>(`/firetexts/${id}`);

  return data;
}

export type UpdateFiretextStatusPayload = {
  firetextId: number;
  status: number;
};
export async function updateFiretextStatus({
  firetextId,
  status,
}: UpdateFiretextStatusPayload) {
  const { data } = await httpClient.put<FiretextItem>(
    `/firetexts/${firetextId}`,
    {
      status,
    },
  );

  return data;
}

export type UpdateFiretextPayload = {
  firetextId: number;
  payload: Partial<FiretextItem>;
};

export async function updateFiretext({
  firetextId,
  ...payload
}: UpdateFiretextPayload) {
  return await httpClient.put<FiretextItem>(`/firetexts/${firetextId}`, {
    ...payload,
  });
}

type SearchFiretextsToConfigureProps = {
  page: number;
  perPage: number;
  search: string;
};
export async function searchFiretextsToConfigure({
  page,
  perPage,
  search,
}: SearchFiretextsToConfigureProps) {
  const q = search;
  const properties = {
    agreements: {
      id: true,
      fileType: true,
      name: true,
    },
    customer: {
      id: true,
      pin: true,
      fullName: true,
      companyName: true,
      category: true,
      company: true,
      city: true,
      address: true,
      address2: true,
      zip: true,
      email: true,
      mobile: true,
    },
    status: true,
    id: true,
    campaign: true,
    msisdn: true,
    regDate: true,
    manufactureDate: true,
    regretTimeExpired: true,
    remainingRegretDays: true,
    adminNote: true,
    msisdn2: true,
    icc: true,
    icc2: true,
    operatorId: true,
    reseller: {
      id: true,
      name: true,
    },
    salesRep: true,
    pricePlanId: true,
  };

  const { data } = await httpClient.get<
    FiretextsResponse<keyof typeof properties>
  >('/firetexts/config-search', {
    params: {
      pagination: true,
      q,
      page,
      perPage,
      status: [3, 4],
      order: {
        regDate: 'asc',
      },
      properties,
    },
  });

  return data;
}

export async function fetchFiretextsToConfigure({
  page,
  perPage,
}: {
  page: number;
  perPage: number;
}) {
  const properties = {
    agreements: {
      id: true,
      fileType: true,
      name: true,
    },
    customer: {
      id: true,
      pin: true,
      fullName: true,
      companyName: true,
      category: true,
      company: true,
      city: true,
      address: true,
      address2: true,
      zip: true,
      email: true,
      mobile: true,
    },
    status: true,
    id: true,
    campaign: true,
    msisdn: true,
    regDate: true,
    manufactureDate: true,
    regretTimeExpired: true,
    remainingRegretDays: true,
    adminNote: true,
    msisdn2: true,
    icc: true,
    icc2: true,
    operatorId: true,
    reseller: {
      id: true,
      name: true,
    },
    salesRep: true,
    pricePlanId: true,
  };

  const { data } = await httpClient.get<
    FiretextsResponse<keyof typeof properties>
  >('firetexts', {
    params: {
      pagination: true,
      page,
      perPage,
      status: [3, 4],
      order: {
        regDate: 'asc',
      },
      properties,
    },
  });
  return data;
}

export async function firetextConfigSmsSend(firetextId: number) {
  const { data } = await httpClient.post<Array<{ result: string }>>(
    `/firetexts/${firetextId}/configure`,
  );

  return data;
}

export async function fetchFiretextHistory(firetextId: number) {
  const { data } = await httpClient.get<FiretextHistoryResponse>('/alarms', {
    params: {
      pagination: false,
      firetext: firetextId,
      order: {
        createdAt: 'desc',
      },
      properties: {
        id: true,
        createdAt: true,
        dateTime: true,
        note: true,
        status: true,
        type: true,
        totalProcessTimeInSeconds: true,
      },
    },
  });

  return data['hydra:member'];
}

export type GetFiretextsPayload = {
  customerPin?: string;
  page?: number;
  perPage?: number;
  churns: unknown;
  sortOrder: 'asc' | 'desc';
};

export async function getFiretexts({
  customerPin,
  page = 1,
  perPage = 100,
  churns,
  sortOrder,
}: GetFiretextsPayload) {
  const properties = {
    id: true,
    churnDate: true,
    status: true,
    customer: {
      id: true,
      fullName: true,
      companyName: true,
      pin: true,
    },
  };

  const { data } = await httpClient.get<
    FiretextsResponse<keyof typeof properties>
  >('/firetexts', {
    params: {
      'order[churnDate]': churns ? sortOrder : undefined,
      'exists[churnDate]': !!churns,
      status: churns ? [1, 2] : null,
      'customer.pin': customerPin,
      pagination: true,
      page,
      perPage,
      properties,
    },
  });

  return data;
}
