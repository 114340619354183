import { Button, Divider, Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { fetchPartnersList } from '@/api/partners';
import { BasicAlert } from '@/components/BasicAlert';
import { Dropdown } from '@/components/form';
import { fieldRequired } from '@/utils/validation';

import { monthOptions } from '../utils/monthOptions';

type Props = {
  invalid: boolean;
  isLoading: boolean;
  isOnboarding?: boolean;
};

export const BillableAndOnboardingFields = ({
  invalid,
  isLoading,
  isOnboarding = false,
}: Props) => {
  const {
    data: partners,
    isLoading: loadingPartners,
    isError: errorPartners,
  } = useQuery({
    queryKey: ['fetchPartners', isOnboarding],
    queryFn: fetchPartnersList,
    enabled: isOnboarding,
  });

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sm
        sx={{
          padding: 2,
        }}
      >
        <Dropdown
          fieldProps={{ validate: fieldRequired }}
          name="start"
          label="Månad"
          options={monthOptions}
        />

        {isOnboarding &&
          !!partners &&
          (errorPartners ? (
            <BasicAlert />
          ) : (
            <Dropdown
              name="partnerId"
              label="Partner"
              placeholder={loadingPartners ? 'Laddar...' : 'Partner'}
              options={partners.map((partner) => ({
                value: partner['@id'],
                text: partner.name,
              }))}
            />
          ))}
      </Grid>
      <Divider orientation="vertical" flexItem />

      <Grid
        item
        xs={12}
        sx={{
          borderTop: '1px solid rgba(0, 0, 0, 0.12)',
          padding: 2,
        }}
      >
        <Button
          disabled={invalid || isLoading}
          variant="contained"
          color="primary"
          type="submit"
        >
          Ladda ner
        </Button>
      </Grid>
    </Grid>
  );
};
