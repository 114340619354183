import React, { useState } from 'react';

import { Card, CardContent } from '@mui/material';
import { format } from 'date-fns';
import { Helmet } from 'react-helmet';

import { PageHeader } from '@/components/PageHeader';
import { Filter } from '@/modules/supportNotes/components/Filter';
import { NotesList } from '@/modules/supportNotes/components/NotesList';
import { SupportNotesBreadcrumbs } from '@/modules/supportNotes/components/SupportNotesBreadcrumbs';

export type FilterValues = {
  dateAfter: string;
  dateBefore: string;
  types: Array<{ value: string; text: string }>;
  customer: string;
};

const SupportNotes = () => {
  const today = format(new Date(), 'yyyy-MM-dd');
  const [filter, setFilter] = useState<FilterValues>({
    dateAfter: today,
    dateBefore: today,
    types: [],
    customer: '',
  });

  const handleFilterChange = (values: FilterValues) => {
    return Promise.resolve(
      setFilter({
        dateAfter: values?.dateAfter ?? filter.dateAfter,
        dateBefore: values?.dateBefore ?? filter.dateBefore,
        types: values?.types ?? filter.types,
        customer: values?.customer ?? filter.customer,
      }),
    );
  };
  return (
    <>
      <Helmet title="Noteringar | Kundservice" />

      <SupportNotesBreadcrumbs />
      <PageHeader title="Noteringar" margin />

      <Card>
        <CardContent>
          <Filter initialValues={filter} onChange={handleFilterChange} />
          <NotesList filter={filter} />
        </CardContent>
      </Card>
    </>
  );
};

export default SupportNotes;
