import { searchFiretextsToConfigure } from '@/api/firetext';
import { getHomegatesToConfigure } from '@/api/homegate';
import { fetchProtectionsToConfigure } from '@/api/protection';
import { getSinnesToConfigure } from '@/api/sinne';

const apiService = {
  protection: fetchProtectionsToConfigure,
  coProtection: fetchProtectionsToConfigure,
  homegate: getHomegatesToConfigure,
  firetext: searchFiretextsToConfigure,
  sinne: getSinnesToConfigure,
} as const;

type ServiceNameToConfigure = keyof typeof apiService;

export const getServiceToConfigure = (serviceName: ServiceNameToConfigure) => {
  return apiService[serviceName];
};
