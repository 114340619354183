import { useEffect, useState } from 'react';

import { getFileFromIndexedDB } from '@/utils/indexDb';

export const useIndexDBFile = (fileKey?: string) => {
  const [file, setFile] = useState<File>();

  useEffect(() => {
    const retrieveFile = async () => {
      try {
        const retrievedFile = await getFileFromIndexedDB(fileKey!);
        if (retrievedFile) {
          setFile(retrievedFile);
        }
      } catch (error) {
        // TODO: Handle error
        console.error('Error retrieving file from IndexedDB:', error);
      }
    };

    if (fileKey) {
      retrieveFile();
    }
  }, [fileKey]);

  return file;
};
