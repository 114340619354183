import { getObjectKeys } from './tsHelpers';

export function generateStatusOptions<T extends Object>(statuses: T) {
  let returnValue: {
    value: keyof T;
    text: T[keyof T];
  }[] = [];

  getObjectKeys(statuses).forEach((statusKey) => {
    returnValue.push({
      value: statusKey,
      text: statuses[statusKey],
    });
  });
  return returnValue;
}
