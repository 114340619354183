import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

type Props = {
  children: React.ReactNode;
  type: 'warning';
};

export const LabelBadge = ({ children, type }: Props) => {
  return (
    <LabelBadgeWrapper type={type}>
      <LabelBadgeText type={type}>{children}</LabelBadgeText>
    </LabelBadgeWrapper>
  );
};

const LabelBadgeWrapper = styled('div')<{ type: string }>(
  ({ theme, type }) => ({
    borderRadius: theme.shape.borderRadius,
    width: 80,
    height: 21,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor:
      type === 'warning' ? theme.palette.error.ultraLight : 'inherit',
  }),
);

const LabelBadgeText = styled(Typography)<{ type: string }>(
  ({ theme, type }) => ({
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.body2.fontSize,
    color: type === 'warning' ? theme.palette.error.main : 'inherit',
  }),
);
