import React from 'react';

import { DialogContent as MuiDialogContent } from '@mui/material';

type Props = {
  children: React.ReactNode;
  noPadding?: boolean;
};

export function DialogContent({ children, noPadding }: Props) {
  return (
    <MuiDialogContent
      sx={(theme) => ({
        padding: noPadding ? 0 : theme.spacing(3),
      })}
    >
      {children}
    </MuiDialogContent>
  );
}
