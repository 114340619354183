import React from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material';

import * as ServicesApi from '@/api/services';
import { CustomerSearchInput } from '@/components/CustomerSearchInput';
import { usePrivateSettings } from '@/hooks';
import { CamelCaseServiceName } from '@/modules/common/utils/services';
import { BasicCustomerData } from '@/types/customers';
import { ServiceParams } from '@/types/services';
import { getCustomerName, getCustomerNameSchema } from '@/utils/customer';

type Props = {
  opened: boolean;
  service: ServiceParams;
  serviceName: CamelCaseServiceName;
  onClose: () => void;
  onTransferSuccess: () => void;
};
export const ServiceTransferModal = ({
  opened,
  service,
  serviceName,
  onClose,
  onTransferSuccess,
}: Props) => {
  const { invoiceProvider, servicesNames } = usePrivateSettings();
  const oldCustomer = service.customer;
  const [selectedCustomer, setSelectedCustomer] =
    React.useState<BasicCustomerData | null>(null);
  const [transferInProgress, setTransferInProgress] = React.useState(false);
  const [transferError, setTransferError] = React.useState('');
  const [transferSuccess, setTransferSuccess] = React.useState(false);

  function handleSelectCustomer(customer: BasicCustomerData) {
    setSelectedCustomer(customer);
  }

  async function handleServiceTransfer(selectedCustomerId: number) {
    setTransferInProgress(true);

    const payload = {
      serviceName,
      serviceId: service.id,
      newCustomer: `/customers/${selectedCustomerId}`,
    };

    try {
      await ServicesApi.transferService(payload);
      setTransferInProgress(false);
      setTransferError('');
      setTransferSuccess(true);
    } catch (error) {
      setTransferInProgress(false);
      setTransferError('Något gick fel');
    }
  }

  function handleClose() {
    setSelectedCustomer(null);
    setTransferInProgress(false);
    setTransferError('');
    setTransferSuccess(false);

    if (transferSuccess) {
      onTransferSuccess();
      onClose();
    } else {
      onClose();
    }
  }

  return (
    <Dialog open={opened} onClose={handleClose} fullWidth>
      <DialogTitle>Överlåtelse av tjänst</DialogTitle>
      <Divider />
      <Box component={DialogContent} minHeight={400}>
        {transferSuccess === false && (
          <>
            <section>
              <CustomerSearchInput onSelectCustomer={handleSelectCustomer} />
            </section>

            {selectedCustomer && (
              <section>
                <Typography variant="h5" gutterBottom>
                  Vald kund
                </Typography>

                <Grid container spacing={4} alignItems="center">
                  <>
                    <Grid item>
                      <Typography>
                        {selectedCustomer.id} -{' '}
                        {getCustomerName(
                          getCustomerNameSchema
                            .passthrough()
                            .parse(selectedCustomer),
                        )}
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        disabled={transferInProgress}
                        onClick={() =>
                          handleServiceTransfer(selectedCustomer.id!)
                        }
                      >
                        Överlåt
                      </Button>
                    </Grid>
                  </>
                </Grid>
              </section>
            )}
            {transferInProgress && <LinearProgress />}
          </>
        )}

        <section>
          {transferSuccess && (
            <>
              <Typography gutterBottom>
                Tjänsten{' '}
                <strong>
                  {servicesNames[serviceName]} - {service.id}
                </strong>{' '}
                har flyttats från{' '}
                <strong>{getCustomerName(oldCustomer)}</strong> -{' '}
                <strong>{oldCustomer.id}</strong> till{' '}
                <strong>
                  {getCustomerName(
                    getCustomerNameSchema.passthrough().parse(selectedCustomer),
                  )}
                </strong>{' '}
                - <strong>{selectedCustomer?.id!}</strong>
              </Typography>

              {invoiceProvider?.name === 'svea' && (
                <Typography>
                  Kom ihåg att även flytta abonnemanget i Svea Fenix
                </Typography>
              )}
            </>
          )}
          {transferError && (
            <Typography variant="h6" color="error">
              {transferError}
            </Typography>
          )}
        </section>
      </Box>
      <Divider />
      <DialogActions>
        <Button
          color="primary"
          variant="outlined"
          onClick={handleClose}
          disabled={transferInProgress}
        >
          Stäng
        </Button>
      </DialogActions>
    </Dialog>
  );
};
