import React, { useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Form } from 'react-final-form';

import { createCustomer } from '@/api/customers';
import { CustomerInfoFields } from '@/components/CustomerInfoFields';
import { SveaFieldsErrorsModal } from '@/components/SveaFieldsErrorsModal';
import { CustomerCategoryTypes } from '@/constants';
import { queryKeys } from '@/constants/queryKeys';
import { getFieldsWithIllegalSveaCharacters } from '@/helpers';
import { usePrivateSettings, useSnackbar } from '@/hooks';
import { useModal } from '@/hooks/useModal';
import { CreateCustomerPayload } from '@/types/customers';
import Yup, { makeValidation } from '@/utils/validation';

import { AutoGiroColumn } from './AutoGiroColumn';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paperWidthSm': {
    width: theme.breakpoints.up('sm')
      ? `${theme.breakpoints.values.lg}px`
      : '100%',
    maxWidth: `${theme.breakpoints.values.lg}px`,
  },
}));

const StyledDialogActions = styled(DialogActions)({
  justifyContent: 'space-between',
});

type RequestError = {
  status: number;
  statusText: string;
  data: {
    ['hydra:description']: string;
  };
};

export const CreateCustomerModal = () => {
  const snackbar = useSnackbar();
  const { closeModal } = useModal();
  const queryClient = useQueryClient();
  const { invoiceProvider } = usePrivateSettings();

  const dialogElement = React.useRef(null);

  const [sveaFieldErrors, setSveaFieldErrors] = useState<
    Array<{ fieldName: string; wrongChars: string[] }>
  >([]);
  const [sveaErrorsModalOpened, setSveaErrorsModalOpened] = useState(false);

  const createCustomerMutation = useMutation({
    mutationFn: (values: CreateCustomerPayload) => createCustomer(values),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.getAllCustomers],
      });

      if (data.sveaError) {
        snackbar({
          type: 'warning',
          message: `Ändringar sparade.
                OBS! ${data.sveaError}`,
          autoHide: false,
        });
      }
      closeModal();
    },
    onError: () => {
      snackbar({
        type: 'error',
        message: 'Kunde inte skapa kund',
      });
    },
  });

  function onSubmit(values: CreateCustomerPayload) {
    if (invoiceProvider?.name === 'svea') {
      const fieldErrors = getFieldsWithIllegalSveaCharacters(
        values,
        // @ts-ignore
        invoiceProvider?.settings?.replacementConfig,
      );

      if (fieldErrors.length > 0) {
        setSveaFieldErrors(fieldErrors);
        setSveaErrorsModalOpened(true);
      } else {
        createCustomerMutation.mutate(values);
      }
    } else {
      createCustomerMutation.mutate(values);
    }
  }

  const { isPending, isError, error } = createCustomerMutation;

  const validationSchema = Yup.object({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    category: Yup.string().required(),
    companyName: Yup.string().when('category', {
      is: CustomerCategoryTypes.COMPANY.toString(),
      then: Yup.string().required(),
      otherwise: Yup.string().notRequired(),
    }),
    address: Yup.string().required(),
    city: Yup.string().required(),
  });

  const validate = makeValidation(validationSchema);

  return (
    <Form
      validate={validate}
      onSubmit={onSubmit}
      destroyOnUnregister
      render={({ handleSubmit, pristine, form }) => (
        <StyledDialog onClose={() => closeModal()} open={true}>
          <form onSubmit={handleSubmit}>
            <DialogTitle>Registrera kund</DialogTitle>
            <Divider />

            <DialogContent ref={dialogElement}>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <Typography variant="h5">Kundinformation</Typography>

                  <CustomerInfoFields form={form} />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <AutoGiroColumn />
                </Grid>
              </Grid>
              {isError && (
                <>
                  <Typography color="error">
                    {(error as unknown as RequestError).status} -{' '}
                    {(error as unknown as RequestError).statusText}
                  </Typography>
                  <Typography color="error">
                    {
                      (error as unknown as RequestError).data[
                        'hydra:description'
                      ]
                    }
                  </Typography>
                </>
              )}
            </DialogContent>
            <Divider />
            {isPending && <LinearProgress />}
            <StyledDialogActions>
              <Button
                disabled={isPending}
                color="primary"
                variant="outlined"
                onClick={() => closeModal()}
              >
                Avbryt
              </Button>
              <Button
                disabled={isPending || pristine}
                color="primary"
                variant="contained"
                onClick={handleSubmit}
              >
                Spara
              </Button>
            </StyledDialogActions>
            <SveaFieldsErrorsModal
              opened={sveaErrorsModalOpened}
              onClose={() => setSveaErrorsModalOpened(false)}
              //@ts-ignore
              errors={sveaFieldErrors}
            />
          </form>
        </StyledDialog>
      )}
    />
  );
};
