import { FormApi } from 'final-form';

const handleCampaignReset = (formApi: FormApi) => {
  let oldCategory = formApi.getState().values?.category;
  return formApi.subscribe(
    (state) => {
      if (!!oldCategory && oldCategory !== state.values.category) {
        formApi.change('campaign', null);
        formApi.resetFieldState('campaign');
      }
      oldCategory = state.values.category;
    },
    { values: true },
  );
};

export default handleCampaignReset;
