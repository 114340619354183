import React from 'react';

import { Grid, TextField, Typography } from '@mui/material';

import { prepareFieldValues } from '@/helpers/parsing';
import { ServiceName } from '@/types/services';

type Props<ExcelValues> = {
  serviceName: ServiceName;
  setPastedData: (values: ExcelValues & Record<string, string>) => void;
};

export const ExcelRowInput = <T extends object>({
  serviceName,
  setPastedData,
}: Props<T>) => {
  const [pasteFieldValue, setPasteFieldValue] = React.useState('');
  const [pasteFieldError, setPasteFieldError] = React.useState(false);

  function handlePasteExcelData(event: React.ClipboardEvent<HTMLDivElement>) {
    setPasteFieldError(false);

    try {
      const fieldsValues = prepareFieldValues(
        event.clipboardData.getData('Text'),
        serviceName,
      );
      setPastedData(fieldsValues as T & Record<string, string>);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setPasteFieldError(true);
    }

    setPasteFieldValue('');
  }
  return (
    <Grid container item xs={12} sm={6}>
      <TextField
        data-testid="excelRowInput"
        autoFocus
        fullWidth
        placeholder="Klistra in en rad från en Excel fil"
        onPaste={(event) => handlePasteExcelData(event)}
        value={pasteFieldValue}
      />
      {pasteFieldError && <Typography color="error">Ogiltig data</Typography>}
    </Grid>
  );
};
