import { useMutation } from '@tanstack/react-query';

import { updateResellerProfile } from '@/api/reseller';
import { GenericHttpError } from '@/types/errors';
import { ResellerItem, UpdateResellerProfileParams } from '@/types/resellers';

export const useUpdateResellerProfile = () => {
  return useMutation<
    ResellerItem,
    GenericHttpError,
    UpdateResellerProfileParams
  >({ mutationFn: (values) => updateResellerProfile(values) });
};
