import React from 'react';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Link,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import kebabCase from 'lodash/kebabCase';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { BasicAlert } from '@/components/BasicAlert';
import { Pagination } from '@/components/Pagination';
import { SimpleTable } from '@/components/SimpleTable';
import { PureDropdown } from '@/components/form';
import { SearchIcon } from '@/components/icons';
import { getFormattedDate } from '@/helpers/dates';
import { getKebabCaseServiceName, getObjectKeys } from '@/helpers/tsHelpers';
import { usePrivateSettings } from '@/hooks';
import { CamelCaseServiceName } from '@/types/services';
import { ServiceNamesPrivateSetting } from '@/types/settings';

import { getServiceApiFunction } from '../utils/getServiceApiFunction';

type ApiCallParams = {
  page: number;
  perPage: number;
  customerPin: string;
  sortOrder: 'asc' | 'desc';
  churns: boolean;
  status?: number[];
  search?: string;
};

export const View = () => {
  const { servicesNames } = usePrivateSettings();
  const tabLocationParameter = new URLSearchParams(location.search).get('tab');

  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(100);
  const [search, setSearch] = React.useState('');
  const [sortOrder, setSortOrder] = React.useState<'asc' | 'desc'>('asc');

  const invertedServiceNames =
    servicesNames &&
    getObjectKeys(servicesNames).reduce(
      (acc: Record<string, CamelCaseServiceName>, cVal) => {
        const serviceName =
          servicesNames[cVal as keyof ServiceNamesPrivateSetting];
        if (serviceName) {
          acc[serviceName] = cVal;
        }
        return acc;
      },
      {},
    );

  const orderOptions = [
    { id: 1, value: 'desc', text: 'Fallande' },
    {
      id: 2,
      value: 'asc',
      text: 'Stigande',
    },
  ];

  const activeTab =
    invertedServiceNames[
      tabLocationParameter as keyof typeof invertedServiceNames
    ];

  const { data, isLoading, isError, isSuccess } = useQuery({
    queryKey: ['churnList', activeTab, page, perPage, search, sortOrder],

    queryFn: async () => {
      const props: ApiCallParams = {
        page: page + 1,
        perPage,
        customerPin: search,
        sortOrder: sortOrder,
        churns: true,
      };

      return getServiceApiFunction(activeTab!, props);
    },

    enabled: !!activeTab,
  });

  const handlePageChange = (_: any, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    setPerPage(rowsPerPage);
  };

  const onRowClick = (row: Record<string, any>) => {
    const { id } = row;

    navigate(`/products/${kebabCase(activeTab)}/${id}/edit`);
  };

  const columns = [
    {
      label: 'Kundnummer',
      renderer: (row: { customer: { id: number } }) => row.customer.id,
    },
    {
      label: 'Namn',
      renderer: (row: {
        customer: { companyName: string | undefined; fullName: string };
      }) =>
        row.customer.companyName
          ? row.customer.companyName
          : row.customer.fullName,
    },
    {
      label: 'Personnr',
      renderer: (row: { customer: { pin: string } }) => row.customer.pin,
    },
    { label: 'Tjänst ID', renderer: (row: { id: any }) => row.id },
    {
      label: 'Churndatum',
      renderer: (row: { churnDate: string }) =>
        getFormattedDate(row.churnDate, 'yyyy-MM-dd'),
    },
    {
      label: '',
      renderer: (row: { id: number }) => {
        return (
          <Box display="flex" justifyContent="center" alignItems="center">
            <Link
              target="_blank"
              href={`/products/${getKebabCaseServiceName(activeTab!)}/${
                row.id
              }/edit`}
              onClick={(e) => e.stopPropagation()}
            >
              <Tooltip title="Öppna i ny flik">
                <LaunchOutlinedIcon color="primary" />
              </Tooltip>
            </Link>
            <Link
              component={RouterLink}
              to={`/products/${getKebabCaseServiceName(activeTab!)}/${
                row.id
              }/edit`}
            >
              <IconButton size="large">
                <ArrowForwardIcon color="primary" />
              </IconButton>
            </Link>
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <div>
        <div>
          <Card>
            <CardContent>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item>
                  <TextField
                    onChange={(event) => {
                      setSearch(event.target.value);
                    }}
                    value={search}
                    variant="outlined"
                    placeholder="Sök personnummer"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <PureDropdown
                    labelId="churn-date-sort-order-label"
                    id="churn-date-sort-order"
                    value={sortOrder}
                    options={orderOptions}
                    onChange={(e) => {
                      const target = e.target as typeof e.target & {
                        value: 'asc' | 'desc';
                      };
                      setSortOrder(target.value);
                    }}
                    label="Sortera efter"
                  ></PureDropdown>
                </Grid>
              </Grid>
            </CardContent>
            {isLoading ? (
              <LinearProgress />
            ) : isError ? (
              <BasicAlert />
            ) : isSuccess && data['hydra:totalItems'] === 0 ? (
              <Typography variant="h5">Ingen data</Typography>
            ) : (
              isSuccess && (
                <>
                  <SimpleTable
                    columns={columns}
                    rows={data['hydra:member']}
                    onRowClick={onRowClick}
                  />
                  <Pagination
                    count={data['hydra:totalItems']}
                    page={page}
                    rowsPerPage={perPage}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                  />
                </>
              )
            )}
          </Card>
        </div>
      </div>
    </>
  );
};
