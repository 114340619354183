import { useMutation } from '@tanstack/react-query';

import {
  GetBulkLegacyWelcomeLetterParams,
  getBulkWelcomeLetterFromLegacy,
} from '@/api/files';

export const useGetBulkWelcomeLetterFromLegacy = () => {
  return useMutation({
    mutationFn: (params: GetBulkLegacyWelcomeLetterParams) =>
      getBulkWelcomeLetterFromLegacy(params),
  });
};
