import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { LabelAndContentRow } from '@/components';
import { Spacing } from '@/components/Spacing';
import { InvoiceList } from '@/types/billing';

type Props = {
  invoiceList: InvoiceList;
};

export function CustomerBillingBottomInfo({ invoiceList }: Props) {
  if (!invoiceList) return null;

  return (
    <div>
      <LabelAndContentRow
        content={<Typography>{invoiceList?.itemCount} st</Typography>}
        label="Avgifter"
        compact
      />
      <Spacing />
      <LabelAndContentRow
        content={<Typography>{invoiceList?.netto}</Typography>}
        label="Totalt pris - Exkl Moms"
        compact
      />
      <LabelAndContentRow
        content={<Typography>{invoiceList?.moms}</Typography>}
        label="Moms"
        compact
      />
      <LabelAndContentRow
        content={<Typography>{invoiceList?.brutto}</Typography>}
        label="Totalt pris - Inkl Moms"
        compact
      />
    </div>
  );
}

CustomerBillingBottomInfo.propTypes = {
  invoiceList: PropTypes.object,
};
