import React from 'react';

import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  ListItem,
  ListItemText,
} from '@mui/material';

import { PartialContactPerson } from '@/types/services';

export type ContactPeopleCardProps = {
  contactPersonList: PartialContactPerson[];
};
export const ContactPeopleCard = ({
  contactPersonList,
}: ContactPeopleCardProps) => {
  return (
    <Card sx={{ '& .MuiCardContent-root:last-child': { padding: 0 } }}>
      <CardHeader title="Kontaktlista" />
      <Divider />

      <CardContent sx={{ p: 0 }}>
        {contactPersonList.length > 0 ? (
          contactPersonList.map((row, idx) => (
            <React.Fragment key={row.name || idx}>
              <ListItem sx={{ flexWrap: 'wrap' }}>
                <ListItemText primary={<b>{row.name}</b>} />
                <ListItemText
                  sx={{ wordBreak: 'break-all' }}
                  primary={row.phone}
                />
                {row.relation && <ListItemText primary={row.relation} />}
              </ListItem>
              <Divider />
            </React.Fragment>
          ))
        ) : (
          <ListItem>
            <ListItemText primary="Inga kontaktpersoner" />
          </ListItem>
        )}
      </CardContent>
    </Card>
  );
};
