import { PublicSettings } from '@/types/publicSettings';
import { PrivateSettings } from '@/types/settings';
import httpClient from '@/utils/httpClient';

export async function getPrivateSettings() {
  const { data } = await httpClient.get<PrivateSettings>('/settings/private');

  return data;
}

export async function getPublicSettings() {
  const { data } = await httpClient.get<PublicSettings>('/settings/public');

  return data;
}
