import { PageWrapper } from '@/components';
import { CustomersTable } from '@/modules/customers';

const Customers = ({ debtCollection }: { debtCollection: boolean }) => {
  return (
    <PageWrapper pageTitle="Kunder" breadCrumbs={[{ label: 'Kunder' }]}>
      <CustomersTable debtCollection={debtCollection} />
    </PageWrapper>
  );
};

export default Customers;
