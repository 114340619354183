import { useMutation, useQueryClient } from '@tanstack/react-query';

import { markServiceAsSavedOrLost } from '@/api/services';
import { queryKeys } from '@/constants/queryKeys';
import { usePrivateSettings } from '@/hooks/usePrivateSettings';
import { useSnackbar } from '@/hooks/useSnackbar';
import { getKebabServiceName } from '@/modules/common/utils/services';
import { CamelCaseServiceName } from '@/types/services';

type Params = {
  serviceName: CamelCaseServiceName;
  serviceId: string | number;
};

export function useServiceSavedLostMutation({
  serviceName,
  serviceId,
}: Params) {
  const { servicesNames } = usePrivateSettings();
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ action }: { action: 'saved' | 'lost' }) =>
      markServiceAsSavedOrLost({
        serviceName,
        id: serviceId,
        type: action,
      }),
    onSuccess(_, { action }) {
      const suffix = action === 'saved' ? 'räddad' : 'förlorad';
      const message = `${servicesNames[serviceName]} (${serviceId}) ${suffix}.`;

      snackbar({
        type: 'success',
        message,
      });

      queryClient.invalidateQueries({
        queryKey: queryKeys.fetchServiceDetails({
          serviceName: getKebabServiceName(serviceName),
          serviceId: serviceId.toString(),
        }),
      });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === queryKeys.getNotes,
      });
    },
    onError() {
      snackbar({
        type: 'error',
        message: 'Något gick fel.',
      });
    },
  });
}
