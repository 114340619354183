import React from 'react';

import { Grid, LinearProgress, Typography } from '@mui/material';
import { format, parseISO } from 'date-fns';

import { SimpleCard } from '@/components/SimpleCard';
import { Checkboxes, DatePicker, Dropdown } from '@/components/form';
import { BILLING_MONTH_OPTIONS } from '@/constants';
import { generateStatusOptions } from '@/helpers/productEdit';
import { usePrivateSettings } from '@/hooks';
import useUserRoles from '@/hooks/useUserRoles';
import { useServiceSavedLostMutation } from '@/modules/services/hooks/useServiceSavedLostMutation';
import {
  getServiceInfoCardTitle,
  isServiceStatusChangeEnabled,
} from '@/modules/services/utils';
import { KeyTagParamSchema } from '@/types/services';
import { fieldRequired } from '@/utils/validation';

import { SavedLostSection } from '../../SavedLostSection';

type Props = {
  serviceDetails: KeyTagParamSchema;
  isLoading: boolean;
};
export const ServiceInfoForm = ({ serviceDetails, isLoading }: Props) => {
  const { servicesStatuses } = usePrivateSettings();
  const userRoles = useUserRoles();

  const statusOptions = generateStatusOptions(servicesStatuses.keyTag);

  const canChangeStatus = isServiceStatusChangeEnabled(
    serviceDetails,
    userRoles,
  );

  const savedOrLostMutation = useServiceSavedLostMutation({
    serviceName: 'keyTag',
    serviceId: serviceDetails.id,
  });

  return (
    <SimpleCard
      title={getServiceInfoCardTitle(serviceDetails.customer)}
      marginBottom="0"
      cardContent={
        <>
          <section>
            <Grid container spacing={2}>
              <Grid container item>
                <Grid item xs>
                  <Typography gutterBottom variant="h6">
                    Orderdatum:{' '}
                    {format(parseISO(serviceDetails.regDate), 'yyyy-MM-dd')}
                  </Typography>
                </Grid>

                <Grid item xs>
                  <Typography gutterBottom variant="h6">
                    Seriekod: {serviceDetails.code}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container item spacing={2}>
                <Grid item xs>
                  <Dropdown
                    label="Status"
                    name="status"
                    options={statusOptions}
                    disabled={!canChangeStatus}
                    required
                    fieldProps={{
                      validate: fieldRequired,
                    }}
                  />
                </Grid>

                <Grid item xs>
                  <DatePicker
                    label="Churn-datum"
                    name="churnDate"
                    showClearButton
                  />

                  <Dropdown
                    label="Fakturamånad"
                    name="billingMonth"
                    options={BILLING_MONTH_OPTIONS}
                    required
                    fieldProps={{
                      validate: fieldRequired,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </section>

          <section>
            <Typography variant="h6">Val</Typography>

            <Grid container spacing={2}>
              <Grid item>
                <Checkboxes
                  options={{
                    label: 'Faktureras',
                    value: !!serviceDetails.billing,
                  }}
                  name="billing"
                  inputProps={{
                    // @ts-expect-error property data-testid is missing in type
                    'data-testid': 'billing',
                  }}
                />
              </Grid>
            </Grid>
          </section>

          <SavedLostSection
            onClickSaved={() => {
              savedOrLostMutation.mutate({
                action: 'saved',
              });
            }}
            onClickLost={() => {
              savedOrLostMutation.mutate({
                action: 'lost',
              });
            }}
          />

          {isLoading && <LinearProgress />}
        </>
      }
    />
  );
};
