import { SelectFieldOptions } from '@/types/common';
import { ResellerItem } from '@/types/resellers';

type PrepareResellerOptionsArgs = {
  resellers: ResellerItem[];
  addAllOption?: boolean;
  allOptionConfig?: { value: number; text: string };
};

export function prepareResellerOptions(
  resellers: PrepareResellerOptionsArgs['resellers'],
  addAllOption: PrepareResellerOptionsArgs['addAllOption'] = false,
  allOptionConfig: PrepareResellerOptionsArgs['allOptionConfig'] = {
    value: 0,
    text: 'Alla',
  },
) {
  if (resellers) {
    const options: SelectFieldOptions = resellers.map((reseller) => {
      return {
        value: `/resellers/${reseller.id}`,
        text: `${reseller.id} - ${reseller.name}`,
      };
    });
    if (addAllOption) {
      options.unshift({
        value: allOptionConfig.value,
        text: allOptionConfig.text,
      });
    }
    return options;
  }

  return [];
}
