import React from 'react';

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { Form } from 'react-final-form';

import { uploadMultipleAgreements } from '@/api/agreements';
import { ChosenFilesList } from '@/components/FileUpload/ChosenFilesList';
import { Spacing } from '@/components/Spacing';
import { Dropdown } from '@/components/form';
import { getAgreementTypeOptions } from '@/constants';
import { usePrivateSettings } from '@/hooks';
import { FileUploadButton } from '@/modules/common/components/FileUploadButton';
import { AgreementUploadPayload } from '@/types/agreements';
import { fieldRequired } from '@/utils/validation';

import { FilesUploadSummary } from './FilesUploadSummary';

export const AgreementsForm = () => {
  const agreementMutation = useMutation({
    mutationFn: uploadMultipleAgreements,
  });
  const { servicesNames } = usePrivateSettings();
  const agreementTypeOptions = getAgreementTypeOptions(servicesNames!);

  const handleSubmit = (values: AgreementUploadPayload) => {
    agreementMutation.mutate(values);
  };

  const { data, isSuccess } = agreementMutation;
  return (
    <Card>
      <CardHeader title="Ladda upp avtal" />
      <Divider />
      <CardContent>
        <Form
          onSubmit={handleSubmit}
          render={({ handleSubmit, pristine, errors, values }) => (
            <Grid
              component="form"
              onSubmit={handleSubmit}
              container
              spacing={2}
            >
              <Grid item xs={12} md={6}>
                <Dropdown
                  label="Avtalstyp"
                  name="type"
                  options={agreementTypeOptions}
                  fieldProps={{ validate: fieldRequired }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FileUploadButton />
              </Grid>
              <Spacing spacing={2} />
              <Grid item xs={12} md={6}>
                {values?.files && errors?.files ? (
                  <Typography variant="h4" color="error">
                    {errors?.files}
                  </Typography>
                ) : (
                  <ChosenFilesList files={values?.files} />
                )}
              </Grid>
              <Spacing />
              <Grid item xs={12}>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={pristine || !values?.files || !!errors?.files}
                >
                  Ladda upp
                </Button>
              </Grid>
            </Grid>
          )}
        />
        {isSuccess && (
          <Grid item xs={12}>
            <FilesUploadSummary
              validFiles={data.data.success}
              invalidFiles={data.data.fail}
            />
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};
