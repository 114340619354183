import { TextInput } from '@/components/form';
import { CampaignAutoComplete } from '@/components/form/CampaignAutocomplete/CampaignAutoComplete';
import { ServiceWithCampaigns } from '@/types/services';

type Props = {
  service: ServiceWithCampaigns;
  customerType?: number;
};

export const CampaignSection = ({ service, customerType }: Props) => {
  return (
    <>
      <CampaignAutoComplete service={service} customerType={customerType} />
      <TextInput disabled label="Fria månader" name="freeMonths" />
      <TextInput disabled label="Uppsägningstid" name="noticePeriod" />
      <TextInput disabled label="Bindningstid" name="contractMonths" />
      <TextInput disabled label="Ångerrätt" name="withdrawalPeriodDays" />
    </>
  );
};
