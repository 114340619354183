import React, { useCallback, useEffect, useRef, useState } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import debounce from 'lodash/debounce';

import { PureDropdown } from '@/components/form';
import { SearchIcon } from '@/icons';

const TYPE_DROPDOWN_OPTIONS = [
  {
    id: 0,
    value: 'standard',
    text: 'Standard',
  },
  {
    id: 1,
    value: 'pin',
    text: 'Personnummer',
  },
  {
    id: 2,
    value: 'email',
    text: 'E-Post',
  },
  {
    id: 3,
    value: 'phone',
    text: 'Telefon',
  },
  {
    id: 4,
    value: 'code',
    text: 'Seriekod',
  },
];

type Props = {
  options?: Array<{ id: number; value: string; text: string }>;
  searchHandler: (filterValues: any) => void; // TODO: check type
  orderHandler?: (order: string) => void;
  order?: string;
  paperComponent?: boolean;
  disabled?: boolean;
};

export const Toolbar = ({
  options,
  searchHandler,
  orderHandler,
  order,
  paperComponent = true,
  disabled = false,
}: Props) => {
  const [query, setQuery] = useState('');
  const [type, setType] = useState('standard');

  const firstRender = useRef(true);

  const debouncedFiltersChange = useCallback(
    debounce((filterValues) => {
      searchHandler(filterValues);
    }, 750),
    [],
  );
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else if (query === '') {
      // refetch immediately if search is cleared
      searchHandler({
        query,
        type,
      });
    } else {
      debouncedFiltersChange({
        query,
        type,
      });
    }
  }, [query, type]);
  return (
    <Grid
      container
      component={paperComponent ? Paper : Grid}
      justifyContent="space-between"
      sx={{ marginBottom: 2, padding: paperComponent ? 2 : 0 }}
    >
      <Grid container item xs={12} sm={5} spacing={1}>
        <Grid item xs sm={4}>
          <PureDropdown
            labelId="type-select-label"
            label="Söktyp"
            value={type}
            onChange={(event: any) => setType(event.target.value)}
            options={TYPE_DROPDOWN_OPTIONS}
          />
        </Grid>
        <Grid item xs sm={8}>
          <TextField
            fullWidth
            variant="outlined"
            label="Sök kund"
            value={query}
            disabled={disabled}
            onChange={(e) => setQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              ...(query && {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="clear search"
                      size="small"
                      onClick={() => {
                        setQuery('');
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }),
            }}
          />
        </Grid>
      </Grid>
      {options && orderHandler && (
        <Grid item xs={12} sm={2}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="sort-select">Sortera efter</InputLabel>
            <Select
              label="Sortera efter"
              labelId="sort-select"
              value={order}
              onChange={(event: SelectChangeEvent<string>) =>
                orderHandler(event.target.value)
              }
            >
              {options.map((option, i) => (
                <MenuItem key={option.id} value={option.value}>
                  {option.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
    </Grid>
  );
};
