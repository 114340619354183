import React from 'react';

import Yup from '@/utils/validation';

import { TextInput } from '../TextInput';

type Props = {
  name?: string;
  label?: string;
  required?: boolean;
};

export const ZipInput = ({
  name = 'zip',
  label = 'Postnummer',
  required = true,
  ...rest
}: Props) => {
  const validate = async (value: string) => {
    const schema = Yup.string()
      .matches(/^\d{5}$/, {
        message: 'Postnummer består av fem siffror',
        excludeEmptyString: true,
      })
      .when('$required', (required, schema) => {
        return required ? schema.required() : schema;
      });

    try {
      await schema.validate(value, { context: { required } });
      return undefined;
    } catch (e: any) {
      return e.errors.map((error: string) => error);
    }
  };

  return (
    <TextInput
      name={name}
      label={label}
      required={required}
      fieldProps={{
        validate,
        parse: (v) => (v ? v.replace(' ', '').trim() : ''),
        format: (v) => (v ? v.replace(' ', '').trim() : ''),
      }}
      {...rest}
    />
  );
};
