import { z } from 'zod';

import { TaskTeamTypes } from '@/modules/tasks/constants/tasksModalsConstants';

import {
  HandleableAlarmReasons,
  HandleableAlarmRemedies,
  HandleableAlarmStatuses,
} from './alarms';
import { CamelCaseServiceName } from './services';

export type PrivateSettings = {
  invoiceProvider: InvoiceProvider;
  phoneNumbers: PhoneNumbers;
  customerServiceHours: CustomerServiceHours;
  emailAddresses: EmailAddresses;
  operators: Operators;
  stickerDoubleFamily: boolean;
  webAddresses: WebAddresses;
  products: Products;
  statuses: ServiceStatuses;
  types: PrivateSettingsServiceTypes;
  pricePlans: PricePlans;
  coProtectionMonitoringPlans: CoProtectionMonitoringPlans;
  externalServices: ExternalServices;
  names: ServiceNamesPrivateSetting;
  campaigns: Campaigns;
  bankIdEnabled: boolean;
  sinne: Sinne;
  companyCurrency: CompanyCurrency;
  churnReasons: ChurnReasons;
  hasPhoneVerification: boolean;
};

export type CompanyCurrency = {
  iso: string;
  symbol: string;
};

export type InvoiceProvider = {
  name: string;
  settings:
    | {
        client: string;
        replacementConfig: Record<string, string>;
      }
    | {
        invoiceDay: string;
        dueDays: number;
        firstReminderDelayDays: number;
        secondReminderDelayDays: number;
        collectionDelayDays: number;
      }
    | object;
};

export type PhoneNumbers = {
  customer: string;
  protection: string;
};

export type CustomerServiceHours = {
  hours: string;
};

export type EmailAddresses = {
  admin: string;
  monitum: string;
  headOfB2C: string;
  headOfB2B: string;
  customerService: string;
  protectionService: string;
  noReply: string;
};

export type Operators = {
  0: string;
  1: string;
  2: string;
  3: string;
  4: string;
  5: string;
  6: string;
  7: string;
  8: string;
  9: string;
  10: string;
  99: string;
};

export type WebAddresses = {
  mypage: string;
  monitum: string;
};

export type Products = {
  firetext: number;
  keyTag: number;
  protection: number;
  coProtection: number;
  sticker: number;
  homegate: number;
  sinne: number;
};

export type ServiceStatuses = {
  alarm: AlarmStatuses;
  firetext: FiretextStatuses;
  keyTag: KeyTagStatuses;
  protection: ProtectionStatuses;
  coProtection: CoProtectionStatuses;
  sticker: StickerStatuses;
  homegate: HomegateStatuses;
  sinne: SinneStatuses;
  sinneEvent: { events: SinneEventStatuses };
  handleableAlarm: HandleableAlarmStateMaps;
};

export enum AlarmStatusEnum {
  'Aktuell',
  'Bearbetas',
  'Konfigurerar',
  'Testade',
  'Levande ljus',
  'Brand - Larmat SOS',
  'Brand - Självsläckt',
  'Matlagning',
  'Övrigt',
  'Ej kontaktbar',
  'Test - Innan utskick',
  'Dubbellarm - Ej uppringd',
  'Lågt batteri',
}
export type AlarmStatuses = {
  0: 'Aktuell';
  1: 'Bearbetas';
  2: 'Konfigurerar';
  3: 'Testade';
  4: 'Levande ljus';
  5: 'Brand - Larmat SOS';
  6: 'Brand - Självsläckt';
  7: 'Matlagning';
  8: 'Övrigt';
  9: 'Ej kontaktbar';
  10: 'Test - Innan utskick';
  11: 'Dubbellarm - Ej uppringd';
  12: 'Lågt batteri';
};
export type AlarmStatus = keyof typeof AlarmStatusEnum;
export const alarmStatusKey = z.nativeEnum(AlarmStatusEnum);

export enum FiretextStatusEnum {
  Aktiv = 1,
  Nyregistrerad = 3,
  Konfigurerar = 4,
  Ånger = 5,
  Makulerad = 6,
  Churn = 7,
  Goodwill = 8,
}
export type FiretextStatuses = {
  1: 'Aktiv';
  3: 'Nyregistrerad';
  4: 'Konfigurerar';
  5: 'Ånger';
  6: 'Makulerad';
  7: 'Churn';
  8: 'Goodwill';
};
export type FiretextStatus = keyof typeof FiretextStatusEnum;
export const firetextStatusKey = z.nativeEnum(FiretextStatusEnum);

export enum KeyTagStatusEnum {
  Aktiv = 1,
  Ånger,
  Makulerad,
  Churn,
  Goodwill,
}

export type KeyTagStatuses = {
  1: 'Aktiv';
  2: 'Ånger';
  3: 'Makulerad';
  4: 'Churn';
  5: 'Goodwill';
};
export type KeyTagStatus = keyof typeof KeyTagStatusEnum;
export const keyTagStatusKey = z.nativeEnum(KeyTagStatusEnum);

export enum ProtectionStatusEnum {
  Aktiv = 1,
  Ånger,
  Makulerad,
  Churn,
  Goodwill,
  Uppsåld,
}

export type ProtectionStatuses = {
  1: 'Aktiv';
  2: 'Ånger';
  3: 'Makulerad';
  4: 'Churn';
  5: 'Goodwill';
  6: 'Uppsåld';
};
export type ProtectionStatus = keyof typeof ProtectionStatusEnum;
export const protectionStatusKey = z.nativeEnum(ProtectionStatusEnum);

export enum CoProtectionStatusEnum {
  Aktiv = 1,
  Ånger,
  Makulerad,
  Churn,
  Goodwill,
  Uppsåld,
}

export type CoProtectionStatuses = {
  1: 'Aktiv';
  2: 'Ånger';
  3: 'Makulerad';
  4: 'Churn';
  5: 'Goodwill';
  6: 'Uppsåld';
};
export type CoProtectionStatus = keyof typeof CoProtectionStatusEnum;
export const coProtectionStatusKey = z.nativeEnum(CoProtectionStatusEnum);

export enum StickerStatusEnum {
  'Ej satt' = 0,
  Aktiv = 1,
  Churn = 3,
}
export type StickerStatuses = {
  0: 'Ej satt';
  1: 'Aktiv';
  3: 'Churn';
};
export type StickerStatus = keyof typeof StickerStatusEnum;
export const stickerStatusKey = z.nativeEnum(StickerStatusEnum);

export enum HomegateStatusEnum {
  Aktiv = 1,
  Ånger,
  Makulerad,
  Churn,
  Goodwill,
  Nyregistrerad,
}
export type HomegateStatuses = {
  1: 'Aktiv';
  2: 'Ånger';
  3: 'Makulerad';
  4: 'Churn';
  5: 'Goodwill';
  6: 'Nyregistrerad';
};
export type HomegateStatus = keyof typeof HomegateStatusEnum;
export const homegateStatusKey = z.nativeEnum(HomegateStatusEnum);

export enum SinneStatusEnum {
  Aktiv = 1,
  Ånger = 2,
  Makulerad = 3,
  Churn = 4,
  Goodwill = 5,
  Nyregistrerad = 6,
  'Produkten är en kampanj-mall' = 7,
  'Konfigurerar enhet' = 8,
  'Enhet rapporterad: Sönder' = 10,
}
export type SinneStatuses = {
  1: 'Aktiv';
  2: 'Ånger';
  3: 'Makulerad';
  4: 'Churn';
  5: 'Goodwill';
  6: 'Nyregistrerad';
  7: 'Produkten är en kampanj-mall';
  8: 'Konfigurerar enhet';
  10: 'Enhet rapporterad: Sönder';
};
export type SinneStatus = keyof typeof SinneStatusEnum;
export const sinneStatusKey = z.nativeEnum(SinneStatusEnum);

export enum SinneEventStatusEnum {
  'Rökutveckling' = 1,
  'Rökutveckling återställd',
  'Kolmonoxidutveckling',
  'Kolmonoxidutveckling återställd',
  'Testknapp tryckt',
  'Lågt batteri',
  'Batteri återställt',
  'Enhet närmar sig end of life',
  'Fel med kolmonoxidsensor',
  'Kolmonoxidsensor återställd',
  'Fel med röksensor',
  'Röksensor återställd',
  'Enheten kunde inte läsa från röksensor',
  'Röksensor lyckades läsa igen',
  'Fel med temperatursensor',
  'Temperatursensor återställd',
  'Fel med batterisensor',
  'Batterisensor återställd',
  'Kunde inte läsa från minneskort',
  'Kunde läsa från minneskort',
  'Enhet frånkopplad',
  'Enhet kopplad',
  'Ogiltig data mottagen',
  'Enhet omstartad',
  'Dålig signal',
  'Dålig signal återställd',
  'Enhet uppdaterad',
  'Brandlarm ändrat via app',
  'Brandlarm ändrat via enhet',
  'Uppdatering misslyckades',
  'Kundkommand förfallen',
  'Kundkommand misslyckades',
  'Kommand påbörjat',
  'Kundkommand lyckades',
}
export const sinneEventStatusSchema = z.object({
  '1': z.literal('Rökutveckling'),
  '2': z.literal('Rökutveckling återställd'),
  '3': z.literal('Kolmonoxidutveckling'),
  '4': z.literal('Kolmonoxidutveckling återställd'),
  '5': z.literal('Testknapp tryckt'),
  '6': z.literal('Lågt batteri'),
  '7': z.literal('Batteri återställt'),
  '8': z.literal('Enhet närmar sig end of life'),
  '9': z.literal('Fel med kolmonoxidsensor'),
  '10': z.literal('Kolmonoxidsensor återställd'),
  '11': z.literal('Fel med röksensor'),
  '12': z.literal('Röksensor återställd'),
  '13': z.literal('Enheten kunde inte läsa från röksensor'),
  '14': z.literal('Röksensor lyckades läsa igen'),
  '15': z.literal('Fel med temperatursensor'),
  '16': z.literal('Temperatursensor återställd'),
  '17': z.literal('Fel med batterisensor'),
  '18': z.literal('Batterisensor återställd'),
  '19': z.literal('Kunde inte läsa från minneskort'),
  '20': z.literal('Kunde läsa från minneskort'),
  '22': z.literal('Enhet frånkopplad'),
  '23': z.literal('Enhet kopplad'),
  '24': z.literal('Ogiltig data mottagen'),
  '25': z.literal('Enhet omstartad'),
  '26': z.literal('Dålig signal'),
  '27': z.literal('Dålig signal återställd'),
  '28': z.literal('Enhet uppdaterad'),
  '29': z.literal('Brandlarm ändrat via app'),
  '30': z.literal('Brandlarm ändrat via enhet'),
  '31': z.literal('Uppdatering misslyckades'),
  '32': z.literal('Kundkommand förfallen'),
  '33': z.literal('Kundkommand misslyckades'),
  '34': z.literal('Kommand påbörjat'),
  '35': z.literal('Kundkommand lyckades'),
});

export const sinneEventStatus = sinneEventStatusSchema.keyof();

export type SinneEventStatuses = z.infer<typeof sinneEventStatusSchema>;
export type SinneEventStatus = keyof typeof SinneEventStatusEnum;

export type HandleableAlarmStateMaps = {
  statuses: HandleableAlarmStatuses;
  reasons: HandleableAlarmReasons;
  remedies: HandleableAlarmRemedies;
};

export type PrivateSettingsServiceTypes = {
  protection: ProtectionTypes;
  coProtection: CoProtectionTypes;
  note: NoteTypes;
  cost: CostTypes;
  chore: ChoreTypes;
  task: TaskTypes;
};

export type ProtectionTypes = {
  0: string;
  1: string;
  2: string;
  3: string;
};

export type CoProtectionTypes = {
  0: string;
  1: string;
};

export type NoteTypes = {
  [key: string]: string;
};

export type CostTypes = {
  2: string;
  3: string;
  4: string;
  5: string;
  6: string;
  7: string;
  15: string;
  16: string;
  24: string;
  25: string;
  26: string;
  27: string;
  28: string;
  29: string;
  34: string;
  35: string;
  36: string;
  42: string;
  45: string;
  47: string;
  49: string;
  50: string;
  52: string;
  53: string;
  54: string;
  55: string;
  56: string;
  57: string;
  70: string;
  90: string;
  91: string;
  92: string;
  220: string;
  221: string;
  240: string;
  241: string;
  250: string;
  251: string;
  [key: string]: string;
};

export type ChoreTypes = {
  0: string;
  1: string;
  2: string;
  3: string;
  4: string;
  6: string;
  7: string;
  8: string;
  99: string;
};

type FilteredKeys<T> = T extends string
  ? T extends 'keyTag' | 'sticker'
    ? never
    : T
  : never;
export type ChurnReasonServiceNames = FilteredKeys<CamelCaseServiceName>;
export type ChurnReasons = {
  [key in ChurnReasonServiceNames]: Record<string, string>;
};

export type TaskTypes = Record<
  (typeof TaskTeamTypes)[keyof typeof TaskTeamTypes],
  {
    [key in number]: string;
  }
>;

export type PricePlans = {
  firetext: FiretextPricePlans;
  keyTag: KeyTagPricePlans;
  protection: ProtectionPricePlans;
  coProtection: CoProtectionPricePlans;
  sticker: StickerPricePlans;
};

export type PricePlansTypes =
  | FiretextPricePlans
  | KeyTagPricePlans
  | ProtectionPricePlans
  | CoProtectionPricePlans
  | StickerPricePlans;

export type ServiceTypes =
  | ProtectionTypes
  | CoProtectionTypes
  | NoteTypes
  | CostTypes
  | ChoreTypes
  | TaskTypes;

export const firetextPricePlanSchema = z.record(z.number(), z.number());
export type FiretextPricePlans = z.infer<typeof firetextPricePlanSchema>;
// export type FiretextPricePlans = {
//   0: number;
//   1: number;
//   2: number;
//   3: number;
//   4: number;
//   5: number;
//   6: number;
//   7: number;
// };

export const keyTagPricePlanSchema = z.record(z.number(), z.number());
export type KeyTagPricePlans = z.infer<typeof keyTagPricePlanSchema>;
// export type KeyTagPricePlans = {
//   0: number;
//   1: number;
//   2: number;
// };

export const protectionPricePlanSchema = z.record(z.number(), z.number());
export type ProtectionPricePlans = z.infer<typeof protectionPricePlanSchema>;
// export type ProtectionPricePlans = {
//   0: number;
//   1: number;
//   2: number;
//   3: number;
//   4: number;
//   5: number;
//   6: number;
//   7: number;
//   8: number;
//   9: number;
//   10: number;
//   11: number;
//   12: number;
//   13: number;
//   14: number;
// };

export const coProtectionPricePlanSchema = z.record(z.number(), z.number());
export type CoProtectionPricePlans = z.infer<
  typeof coProtectionPricePlanSchema
>;

// export type CoProtectionPricePlans = {
//   0: number;
//   1: number;
//   2: number;
//   3: number;
//   4: number;
//   5: number;
//   6: number;
//   7: number;
// };

export const stickerPricePlanSchema = z.record(z.number(), z.number());
export type StickerPricePlans = z.infer<typeof stickerPricePlanSchema>;
// export type StickerPricePlans = {
//   0: number;
//   1: number;
//   10: number;
// };

export type ExternalServices = {
  creditsafe: boolean;
  bisnode: boolean;
};

export type ServiceNamesPrivateSetting = Record<CamelCaseServiceName, string>;

export type KebabCaseServiceNames =
  | 'firetext'
  | 'key-tag'
  | 'protection'
  | 'co-protection'
  | 'sticker'
  | 'homegate'
  | 'sinne';

export type Campaigns = {
  firetext: FiretextCampaigns;
  protection: ProtectionCampaigns;
};

export type FiretextCampaigns = {
  [key: string]: {
    pricePlan: number;
    monthlyFee: number;
    contractMonths: number;
    startFee: number;
    units: number;
    specialHeader: string;
    specialRow1: string;
    specialRow2: string;
    specialRow3: string;
  };
};

export type ProtectionCampaigns = {
  [key: string]: {
    pricePlan: number;
    type: number;
    startFee: number;
    monthlyFee: number;
    contractMonths: number;
    specialHeader: string;
    specialRow1: string;
    specialRow2: string;
    specialRow3: string;
  };
};

export type CoProtectionMonitoringPlans = {
  [key: number]: {
    planId: number;
    maximumNumberOfMonitoredCompanies: number;
  };
};

type Sinne = {
  customerPrice: number;
};
