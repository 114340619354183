import React from 'react';

import { Checkbox, TextField } from '@mui/material';
import { Autocomplete } from '@mui/material';

import { usePrivateSettings } from '@/hooks';

type ValueItem = {
  value: string;
  text: string;
};

type Props = {
  value: ValueItem[];
  onChange: (value: ValueItem[]) => void;
};

export const NotesFilter = ({ value, onChange }: Props) => {
  const { getTypesOptionsForDropdownInput } = usePrivateSettings();

  return (
    <Autocomplete
      multiple
      onChange={(_, value) => {
        onChange(value);
      }}
      options={getTypesOptionsForDropdownInput('note')}
      value={value}
      getOptionLabel={(option) => option.text}
      isOptionEqualToValue={(option, selected) =>
        option.value === selected.value
      }
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox checked={selected} />
          {option.text}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Filtrera"
          placeholder="Filtrera"
        />
      )}
    />
  );
};
