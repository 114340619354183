import { useState } from 'react';

import { Box, Button } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { createUser } from '@/api/users';
import { Can } from '@/components/Can';
import { Tabs } from '@/components/Tabs/Tabs';
import { useSnackbar } from '@/hooks';
import { useAuth } from '@/hooks/useAuth';
import { AddUserIcon } from '@/icons';
import { Tabmap } from '@/types/tabs';
import { UserItem } from '@/types/users';

import { CreateUserModal } from './CreateUserModal';
import { UsersTable } from './UsersTable';

export const UsersContainer = () => {
  const { getAuthenticatedUserRoles } = useAuth();
  const snackbar = useSnackbar();
  const userRoles = getAuthenticatedUserRoles();

  const [showAddUserModal, setShowAddUserModal] = useState(false);

  const queryClient = useQueryClient();

  const TabMap: Tabmap[] = [
    { title: 'Aktiva', view: () => <UsersTable type="active" /> },
    {
      title: 'Arkiverade',
      view: () => <UsersTable type="deleted" />,
      authRule: 'users.reactivate',
    },
  ];

  const addUserMutation = useMutation({
    mutationFn: (user: Partial<UserItem>) => createUser(user),
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ['users'],
      });
      setShowAddUserModal(false);
      snackbar({
        type: 'success',
        message: 'Användaren har lagts till',
      });
    },
    onError: () => {
      snackbar({
        type: 'error',
        message: 'Det gick inte att lägga till användare',
      });
    },
  });
  const handleAddUserSubmit = (payload: Partial<UserItem>) => {
    addUserMutation.mutate(payload);
  };

  return (
    <Box maxWidth="lg">
      {userRoles && (
        <Box my={2}>
          <Can
            userRoles={userRoles}
            action="users.create"
            yes={() => (
              <Button
                color="primary"
                onClick={() => setShowAddUserModal(true)}
                startIcon={<AddUserIcon />}
                variant="contained"
              >
                Ny Användare
              </Button>
            )}
          />
        </Box>
      )}

      <Tabs tabs={TabMap} />

      <CreateUserModal
        opened={showAddUserModal}
        onClose={() => setShowAddUserModal(false)}
        onSubmit={handleAddUserSubmit}
      />
    </Box>
  );
};
