import { useMutation } from '@tanstack/react-query';

import { sendNewPassword } from '@/api';
import { GenericHttpError } from '@/types/errors';

export const useResetPassword = () => {
  return useMutation<{ message: string }, GenericHttpError, number>({
    mutationFn: (id) => {
      return sendNewPassword(id);
    },
  });
};
