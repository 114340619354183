import { useQuery } from '@tanstack/react-query';

import { UserItem } from '@/types/users';
import httpClient from '@/utils/httpClient';

export const useGetAccountManagersOptions = () =>
  useQuery({
    queryKey: ['getAccountManagers'],
    queryFn: async () => {
      const { data } = await httpClient.get<{
        ['hydra:member']: UserItem[];
      }>('/users', {
        params: {
          pagination: false,
          properties: {
            roles: true,
            name: true,
            username: true,
            level: true,
          },
        },
      });

      const accountManagers = data['hydra:member'].filter((user: UserItem) =>
        user.roles.includes('ROLE_ACCOUNT_MANAGER'),
      );
      const options = accountManagers.map((manager: UserItem) => ({
        value: manager['@id'],
        text: manager.name,
      }));

      options.unshift({ value: '', text: 'Ingen' });

      return { options, data: accountManagers };
    },
    initialData: { options: [], data: [] },
  });
