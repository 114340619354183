import React from 'react';

import { Grid } from '@mui/material';
import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { add, getMonth } from 'date-fns';
import PropTypes from 'prop-types';
import { useForm } from 'react-final-form';
import invariant from 'tiny-invariant';
import { z } from 'zod';

import { fetchUsers } from '@/api/users';
import {
  Checkboxes,
  DatePicker,
  Dropdown,
  KamIdAutocomplete,
  TextInput,
} from '@/components/form';
import { BILLING_MONTH_OPTIONS, CustomerCategoryTypes } from '@/constants';
import { usePrivateSettings } from '@/hooks';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { USER_TYPES } from '@/modules/common/constants/entitiyNamesMap';
import {
  KebabCaseServiceName,
  getCamelServiceName,
} from '@/modules/common/utils/services';
import {
  preparePricePlanOptions,
  prepareResellerOptions,
  prepareTypeOptions,
} from '@/modules/services';
import { ProtectionCampaignSection } from '@/modules/services/components/Edit/Protection/ProtectionCampaignSection';
import { ResellerItem } from '@/types/resellers';
import { CamelCaseServiceName } from '@/types/services';
import { PrivateSettingsServiceTypes } from '@/types/settings';
import { UserItem, userSchema } from '@/types/users';
import { FEATURE_FLAGS } from '@/utils/featureFlags';
import {
  composeValidators,
  fieldRequired,
  isValidOption,
} from '@/utils/validation';

const BillingFields = () => {
  return (
    <>
      <Dropdown
        fieldProps={{ validate: fieldRequired }}
        required
        label="Fakturamånad"
        name="billingMonth"
        options={BILLING_MONTH_OPTIONS}
      />

      <Checkboxes
        fieldProps={{
          defaultValue: 1,
          parse: (value) => (value ? 1 : 0),
          format: (value) => (value ? 1 : 0),
        }}
        inputProps={{
          // @ts-expect-error data-testid is not a valid prop
          'data-testid': 'billing',
        }}
        options={{
          label: 'Faktureras',
          value: '1',
        }}
        name="billing"
      />
    </>
  );
};

type Props = {
  serviceName: Exclude<KebabCaseServiceName, 'homegate' | 'sinne'>;
  resellers: ResellerItem[];
  customerInfo: any;
  customerCategory: number;
};

type ServiceWithType = Extract<
  CamelCaseServiceName,
  'protection' | 'coProtection'
>;
const getProtectionTypes = (
  serviceName: ServiceWithType,
  serviceTypes: PrivateSettingsServiceTypes,
) => serviceTypes[serviceName];

export const SalesInformationSection = ({
  serviceName,
  resellers,
  customerInfo,
  customerCategory,
}: Props) => {
  const protectionCampaignV2Enabled = useFeatureFlag({
    name: FEATURE_FLAGS.protectionCampaignV2.name,
  });

  const camelServiceName = getCamelServiceName(serviceName) as Exclude<
    CamelCaseServiceName,
    'homegate' | 'sinne'
  >;

  const { servicesPricePlans, servicesTypes } = usePrivateSettings();
  const form = useForm();
  const { change } = form;
  const pricePlans = servicesPricePlans[camelServiceName];

  React.useEffect(() => {
    if (serviceName === 'sticker' || serviceName === 'key-tag') {
      const nextMonth = getMonth(add(new Date(), { months: 2 }));
      change('billingMonth', nextMonth);
    }
  }, [serviceName, change]);
  const isCompany =
    customerInfo?.isCompany ||
    customerCategory === CustomerCategoryTypes.COMPANY;

  const { data: managers } = useQuery({
    queryKey: ['getAccountManagersUsers'],

    queryFn: async () => {
      const response = await fetchUsers({
        computer: false,
        properties: {
          roles: true,
          managedCustomers: { id: true, activeServiceTypes: true },
        },
      });

      const managers = response['hydra:member'].filter((user) => {
        invariant(user.roles, 'User roles should be defined');
        return user.roles.some((role) => role === 'ROLE_ACCOUNT_MANAGER');
      });

      const parsedManagers = z
        .array(userSchema.pick({ '@id': true, name: true }))
        .safeParse(managers);

      if (!parsedManagers.success) {
        console.error(parsedManagers.error);
        Sentry.captureException(parsedManagers.error);
        return managers as z.infer<typeof userSchema>[];
      } else {
        return parsedManagers.data;
      }
    },

    initialData: [],
  });

  const prepareManagerNames = (managers: Pick<UserItem, '@id' | 'name'>[]) => {
    if (!managers) {
      return [];
    }
    return managers.map((manager) => ({
      value: manager['@id'],
      text: manager.name,
    }));
  };

  if (serviceName === 'sticker') {
    return (
      <Grid container spacing={3}>
        <Grid item xs>
          <DatePicker
            label="Orderdatum"
            name="regDate"
            required
            fieldProps={{
              validate: fieldRequired,
            }}
          />

          <TextInput
            label="Seriekod"
            name="code"
            required
            fieldProps={{
              validate: fieldRequired,
            }}
          />

          <Dropdown
            label="Prisplan"
            name="pricePlanId"
            options={preparePricePlanOptions({
              pricePlans,
              isCompany,
            })}
            required
            fieldProps={{
              validate: fieldRequired,
            }}
          />
          <BillingFields />
        </Grid>

        <Grid item xs>
          <KamIdAutocomplete />
          <TextInput label="TB" name="tb" />
          <TextInput label={`Info till ${USER_TYPES.admin}`} name="adminNote" />
          <DatePicker label="Churndatum" name="churnDate" />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs>
        <DatePicker
          label="Orderdatum"
          name="regDate"
          required
          fieldProps={{
            validate: fieldRequired,
          }}
        />

        <Dropdown
          label="Återförsäljare"
          name="reseller"
          options={prepareResellerOptions(resellers)}
          required
          fieldProps={{
            validate: composeValidators(
              fieldRequired,
              isValidOption(prepareResellerOptions(resellers)),
            ),
          }}
        />

        <Dropdown
          label="Prisplan"
          name="pricePlanId"
          options={preparePricePlanOptions({
            pricePlans,
            isCompany,
          })}
          required
          fieldProps={{
            validate: composeValidators(
              fieldRequired,
              isValidOption(
                preparePricePlanOptions({
                  pricePlans,
                  isCompany,
                }),
              ),
            ),
          }}
        />

        {(camelServiceName === 'protection' ||
          camelServiceName === 'coProtection') && (
          <Dropdown
            label="Paket"
            name="type"
            options={prepareTypeOptions(
              getProtectionTypes(camelServiceName, servicesTypes),
            )}
            required
            fieldProps={{
              validate: composeValidators(
                fieldRequired,
                isValidOption(
                  prepareTypeOptions(
                    getProtectionTypes(camelServiceName, servicesTypes),
                  ),
                ),
              ),
            }}
          />
        )}

        <TextInput label="Adresslista" name="addressList" />
        <TextInput label="ab Test" name="abTest" />
        <KamIdAutocomplete />
        <TextInput label={`Info till ${USER_TYPES.admin}`} name="adminNote" />
      </Grid>

      <Grid item xs>
        <TextInput label="Säljare" name="salesRep" />
        {(serviceName === 'protection' || serviceName === 'co-protection') && (
          <Dropdown
            label="Account Manager"
            name="accountManager"
            options={prepareManagerNames(managers)}
            fieldProps={{
              validate: isValidOption(prepareManagerNames(managers)),
            }}
          />
        )}
        {serviceName === 'protection' ? (
          <>
            {protectionCampaignV2Enabled && (
              <ProtectionCampaignSection customerType={customerCategory} />
            )}
            <TextInput
              disabled={protectionCampaignV2Enabled}
              label={protectionCampaignV2Enabled ? 'Legacykampanj' : 'Kampanj'}
              name="campaign"
            />
          </>
        ) : (
          <TextInput
            label="Kampanj"
            name="campaign"
            {...(serviceName === 'key-tag'
              ? { required: true, fieldProps: { validate: fieldRequired } }
              : {})}
          />
        )}
        <TextInput label="Leadkälla" name="leadSource" />
        <TextInput label="TB" name="tb" />
        <DatePicker label="Churn-datum" name="churnDate" />
        {serviceName === 'key-tag' && <BillingFields />}
      </Grid>
    </Grid>
  );
};

SalesInformationSection.propTypes = {
  serviceName: PropTypes.string.isRequired,
  customerInfo: PropTypes.object,
  resellers: PropTypes.array,
};
