import React from 'react';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button, IconButton, LinearProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import camelCase from 'lodash/camelCase';
import snakeCase from 'lodash/snakeCase';

import { Alert } from '@/components/Alert';
import { SimpleCard } from '@/components/SimpleCard';
import { SimpleTable, SimpleTableColumn } from '@/components/SimpleTable';
import { queryKeys } from '@/constants/queryKeys';
import { RegisteredCardsAndMobilesModal } from '@/modules/services/components';
import { PascalCasedServiceNames } from '@/types/services';
import httpClient from '@/utils/httpClient';

type Props = {
  productType: PascalCasedServiceNames;
  productIri: string;
  callerPin?: string;
  callerName?: string | null;
  editDisabled?: boolean;
  marginBottom?: string;
};

type ExtraOptions = {
  '@id': string;
  '@type': string;
  params: {
    protection?: string;
    coProtection?: string;
  };
};

type GenericApiResponse = {
  '@id': string;
  '@type': string;
  fullName: string;
  pin: string;
  type: string;
  brand: string;
  ks: string;
};

type ModalProps = {
  extra: Partial<ExtraOptions>;
  productIri: string;
  registerType: string;
  initialValues?: Partial<GenericApiResponse>;
  create?: boolean;
};

export const CardTable = ({
  productType,
  productIri,
  editDisabled,
  callerPin,
  callerName,
  marginBottom,
}: Props) => {
  const [modalProps, setModalProps] = React.useState<ModalProps | null>(null);
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);

  let extra: Partial<ExtraOptions>;

  if (productType === 'Protection') {
    extra = {
      '@type': 'RegisteredCard',
      params: { protection: productIri },
    };
  } else {
    extra = {
      '@type': 'CoRegisteredCard',
      params: {
        coProtection: productIri,
      },
    };
  }

  const {
    data: cards,
    isLoading,
    isError,
  } = useQuery({
    queryKey: [queryKeys.getCards, productIri],
    queryFn: async () =>
      await httpClient
        .get<{
          ['hydra:member']: GenericApiResponse[];
        }>(snakeCase(extra['@type']) + 's', {
          params: {
            pagination: false,
            [camelCase(productType)]: productIri,
          },
        })
        .then((res) => res.data['hydra:member']),
  });

  const columns: SimpleTableColumn[] = [
    { label: 'Namn', renderer: (row: GenericApiResponse) => row.fullName },
    { label: 'Personnummer', renderer: (row: GenericApiResponse) => row.pin },
    { label: 'Typ av kort', renderer: (row: GenericApiResponse) => row.type },
    { label: 'Bank', renderer: (row: GenericApiResponse) => row.brand },
    { label: '4-sista', renderer: (row: GenericApiResponse) => row.ks },
    ...(editDisabled
      ? []
      : [
          {
            label: 'Ändra',
            renderer: (row: GenericApiResponse) => (
              <IconButton
                aria-label="Redigera kort"
                onClick={() => {
                  const { brand, fullName, ks, pin, type } = row;
                  setModalProps({
                    productIri,
                    registerType: 'Kort',
                    extra: {
                      ...extra,
                      '@id': row['@id'],
                    },
                    initialValues: {
                      brand,
                      fullName,
                      ks,
                      pin,
                      type,
                    },
                  });
                  setModalOpen(true);
                }}
                size="large"
              >
                <ArrowForwardIcon />
              </IconButton>
            ),
            align: 'center',
          } as SimpleTableColumn, // Small hack optional column typing
        ]),
  ];

  return (
    <>
      <SimpleCard
        title={`Kort (${cards?.length || 0})`}
        marginBottom={marginBottom}
        cardContent={
          isLoading ? (
            <LinearProgress />
          ) : isError ? (
            <Alert>Något gick fel</Alert>
          ) : (
            <>{cards && <SimpleTable columns={columns} rows={cards} />}</>
          )
        }
        cardActions={
          <>
            {editDisabled ? null : (
              <Button
                color="primary"
                variant="outlined"
                onClick={() => {
                  setModalProps({
                    extra,
                    create: true,
                    productIri,
                    registerType: 'Kort',
                    ...(callerPin &&
                      callerName && {
                        initialValues: {
                          pin: callerPin,
                          fullName: callerName,
                        },
                      }),
                  });
                  setModalOpen(true);
                }}
              >
                Lägg till kort
              </Button>
            )}

            {modalOpen && (
              <RegisteredCardsAndMobilesModal
                {...modalProps}
                open
                onClose={() => setModalOpen(!modalOpen)}
              />
            )}
          </>
        }
      />
    </>
  );
};
