import React from 'react';

import { TextField, TextFieldProps } from 'mui-rff';

export const TextArea = ({ ...props }: TextFieldProps) => {
  return (
    <TextField
      fullWidth
      multiline
      rows={3}
      {...props}
      inputProps={{
        'data-testid': props.name,
      }}
    />
  );
};
