import last from 'lodash/last';

import { hasOwnProperty } from './typeHelpers';

export function extractIdFromIRI(iri: string) {
  try {
    if (typeof iri !== 'string') {
      throw new Error('Invalid IRI');
    }

    const lastElement = last(iri.split('/'));

    if (lastElement === undefined) {
      throw new Error('Invalid IRI');
    }

    const parsedIRI = parseInt(lastElement, 10);

    if (isNaN(parsedIRI)) {
      throw new Error('Invalid IRI');
    }

    return parsedIRI;
  } catch (error) {
    console.error(error);
    return -1;
  }
}

export function getCatchErrorMessage(error: unknown): string {
  const unknownError = 'Unknown error';

  if (error === null) {
    return unknownError;
  }

  if (typeof error === 'string') {
    return error;
  }

  if (
    typeof error === 'object' &&
    hasOwnProperty(error, 'data') &&
    typeof error.data === 'object'
  ) {
    return error.data && hasOwnProperty(error.data, 'hydra:description')
      ? typeof error.data['hydra:description'] === 'string'
        ? error.data['hydra:description']
        : unknownError
      : unknownError;
  }

  return unknownError;
}

export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));
