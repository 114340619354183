import React from 'react';

import * as Sentry from '@sentry/react';
import { Helmet } from 'react-helmet';
import { Navigate } from 'react-router-dom';

import { Can } from '@/components/Can';
import { ErrorFallback } from '@/components/ErrorFallback';
import { AuthRule } from '@/constants/AuthorizationRules';
import { useAuth } from '@/hooks/useAuth';
import usePublicSettings from '@/hooks/usePublicSettings';

type Props = {
  children: React.ReactNode;
  layout: any;
  isProtected?: boolean;
  authorizationAction?: AuthRule;
};

type ErrorBoundaryProps = {
  name?: string;
  email?: string;
  children: React.ReactNode;
};

const SentryErrorBoundary = (props: ErrorBoundaryProps) => (
  <Sentry.ErrorBoundary
    fallback={ErrorFallback}
    showDialog
    dialogOptions={{
      user: {
        name: props.name,
        email: props.email,
      },
    }}
  >
    {props.children}
  </Sentry.ErrorBoundary>
);

export const RouteWithLayout = ({
  children,
  layout: Layout,
  isProtected,
  authorizationAction,
}: Props) => {
  const { authenticatedUser, isLoggedIn } = useAuth();
  const { publicSettings } = usePublicSettings();
  const companyName = publicSettings?.companyNames?.standard;

  if (isProtected && !isLoggedIn()) {
    return <Navigate to="/sign-in" />;
  }

  if (authorizationAction) {
    return (
      <Can
        userRoles={authenticatedUser?.roles || []}
        action={authorizationAction}
        yes={() => (
          <Layout>
            <Helmet
              defaultTitle={companyName}
              titleTemplate={`%s | ${companyName}`}
            />
            <SentryErrorBoundary
              name={authenticatedUser?.name}
              email={authenticatedUser?.email}
            >
              {children}
            </SentryErrorBoundary>
          </Layout>
        )}
        no={() => <Navigate to={'/dashboard'} />}
      />
    );
  }

  return (
    <Layout>
      <Helmet
        defaultTitle={companyName}
        titleTemplate={`%s | ${companyName}`}
      />
      <SentryErrorBoundary
        name={authenticatedUser?.name}
        email={authenticatedUser?.email}
      >
        {children}
      </SentryErrorBoundary>
    </Layout>
  );
};
