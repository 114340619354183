import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  LinearProgress,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { Form } from 'react-final-form';

import { Dropdown } from '@/components/form';
import { useCustomTemplateTypeOptions } from '@/hooks/customTemplateTypeOptions';
import { ExistingCustomer } from '@/types/customers';
import httpClient from '@/utils/httpClient';
import { generatePdfDownloadLink } from '@/utils/pdf';

type GenerateCustomLetterPayload = {
  templateType: 'custom';
  templateName: string;
  customerId: number;
};

type Props = {
  customer: ExistingCustomer;
};

export const GenerateCustomLetterTemplate = ({ customer }: Props) => {
  const customTemplateOptions = useCustomTemplateTypeOptions();

  const downloadFileHandler = useMutation({
    mutationFn: (v: GenerateCustomLetterPayload) =>
      httpClient.post('documentable/template/generate', v, {
        responseType: 'blob',
      }),
  });

  const onSubmit = async (values: { templateName: string }) => {
    const payload = {
      ...values,
      customerId: customer.id,
      templateType: 'custom',
    } as const;

    downloadFileHandler.mutate(payload, {
      onSuccess: (response) => {
        generatePdfDownloadLink(response.data, 'Test');
      },
    });
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardHeader title="Generera valfri brevmall" />
            {downloadFileHandler.isPending && <LinearProgress />}
            <Divider />
            <CardContent sx={{ pt: 0 }}>
              <Dropdown
                options={customTemplateOptions}
                name="templateName"
                label="Val av brevmall"
              />
            </CardContent>
            <Divider />
            <CardActions>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={downloadFileHandler.isPending}
              >
                Generera
              </Button>
            </CardActions>
          </Card>
        </form>
      )}
    />
  );
};
