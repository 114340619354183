import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';

import { extractNumbersFromString } from '@/helpers/parsing';
import useUser from '@/hooks/useUser';
import { TaskRelatedUser } from '@/types/tasks';
import { checkRolesAccessFromJwt } from '@/utils/authorization';

import { useClaimTask } from '../../hooks/useClaimTask';

type Props = {
  claimedBy: TaskRelatedUser;
  taskIri: string;
  queryKey: any[];
};

export const ClaimedBy = ({ claimedBy, taskIri, queryKey }: Props) => {
  const currentUser = useUser();
  // TODO: This seems to render a lot on customer support tasks page

  const claimMutation = useClaimTask({
    taskIri,
    queryKey,
  });

  const ClaimButton = () => (
    <Tooltip title="Ta detta ärende">
      <IconButton
        size="small"
        color="inherit"
        aria-label="Ta detta ärende"
        onClick={(e) => {
          e.stopPropagation();

          claimMutation.mutate(`/users/${currentUser.id}`);
        }}
      >
        <PlaylistAddIcon />
      </IconButton>
    </Tooltip>
  );

  const ReleaseButton = () => (
    <Tooltip title="Släpp detta ärende">
      <IconButton
        size="small"
        color="inherit"
        aria-label="Släpp detta ärende"
        onClick={(e) => {
          e.stopPropagation();
          claimMutation.mutate(null);
        }}
      >
        <DeleteSweepIcon />
      </IconButton>
    </Tooltip>
  );

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      {claimedBy ? (
        parseInt(
          extractNumbersFromString(
            claimedBy['@id'],
            `claimedBy['@id'] is either missing or is not a string`,
          ),
        ) === currentUser.id ? (
          <>
            <Typography>{claimedBy.name} (Du)</Typography>

            {checkRolesAccessFromJwt('task.release') && <ReleaseButton />}
          </>
        ) : (
          <>
            <Typography>{claimedBy.name}</Typography>
            {checkRolesAccessFromJwt('task.claim') && <ClaimButton />}
          </>
        )
      ) : (
        <>
          <Typography>&#8212;</Typography>
          {checkRolesAccessFromJwt('task.claim') && <ClaimButton />}
        </>
      )}
    </Box>
  );
};
