import { useQuery } from '@tanstack/react-query';

import {
  GetPartnerCompanyByIdParams,
  getPartnerCompanyById,
} from '@/api/partners';

import { partnerCompaniesQueryKey } from './useGetPartnerCompanies';

export const useGetPartnerCompanyById = ({
  id,
  properties,
}: GetPartnerCompanyByIdParams) => {
  return useQuery({
    queryKey: partnerCompaniesQueryKey.detailById(id),
    queryFn: async () => {
      const data = await getPartnerCompanyById({
        id: `/partner_companies/${id}`,
        properties,
      });

      return data;
    },
  });
};
