import React from 'react';

import { List, ListItem, ListItemText, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';

import { InvalidFileTypes } from '@/types/files';

const StyledSuccessTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.success.main,
}));
const StyledErrorTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
}));

type ErrorTranslation = {
  [key: string]: string;
};

const ERROR_TRANSLATIONS: ErrorTranslation = {
  pin: 'personnummer',
  regDate: 'registreringsdatum',
};

type FilesUploadSummaryProps = {
  validFiles: any[];
  invalidFiles: InvalidFileTypes[];
};

export const FilesUploadSummary = ({
  validFiles,
  invalidFiles,
}: FilesUploadSummaryProps) => {
  const groupedInvalidFiles = groupBy(invalidFiles, (entry) => entry.reason);

  return (
    <>
      {!isEmpty(validFiles) && (
        <section>
          <StyledSuccessTitle variant="h4">
            <Typography variant="h4">Filerna har laddats upp</Typography>
            <List dense={true}>
              {validFiles.map((file: { fileName: string }, index: number) => (
                <ListItem key={index}>
                  <ListItemText primary={`${index + 1}. ${file.fileName}`} />
                </ListItem>
              ))}
            </List>
          </StyledSuccessTitle>
        </section>
      )}

      {!isEmpty(groupedInvalidFiles) && (
        <section>
          {Object.keys(groupedInvalidFiles).map((key) => (
            <React.Fragment key={key}>
              <StyledErrorTitle variant="h4">
                <Typography variant="h4">
                  Fel! Orsak: {ERROR_TRANSLATIONS[key] ?? key}
                </Typography>

                <List dense={true}>
                  {groupedInvalidFiles?.[key]?.map((file, index) => (
                    <ListItem key={index}>
                      <ListItemText
                        primary={`${index + 1}. ${file.fileName}`}
                        secondary={
                          file.errors && (
                            <Typography>
                              {file.errors.map((error: Error) => `${error},`)}
                            </Typography>
                          )
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </StyledErrorTitle>
            </React.Fragment>
          ))}
        </section>
      )}
    </>
  );
};
