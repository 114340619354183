import { useMemo } from 'react';

import { useAtom } from 'jotai';

import {
  bulkRegistrationAtom,
  initialState,
} from '@/state/bulkRegistrationAtom';

export const useBulkRegistrationState = () => {
  const [state, setBulkRegistrationState] = useAtom(bulkRegistrationAtom);

  const bulkRegistrationState = useMemo(() => state, [state]);
  const resetBulkRegistrationProcess = () => {
    setBulkRegistrationState(initialState);
  };

  return {
    bulkRegistrationState,
    setBulkRegistrationState,
    resetBulkRegistrationProcess,
  };
};
