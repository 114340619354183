import { Card, CardContent, CardHeader, Divider } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FormApi } from 'final-form';
import { Form } from 'react-final-form';

import { Dropdown } from '@/components/form';
import { useSnackbar } from '@/hooks';
import httpClient from '@/utils/httpClient';
import { fieldRequired } from '@/utils/validation';

import { Team } from '../../types';
import { SaveButton } from './SaveButton';

type FormValues = {
  team: string;
  manager: string;
};

type Props = {
  teamOptions?: { text: string; value: string }[];
  managerOptions?: { text: string; value: string }[];
};

export const ChangeManagerCard = ({ teamOptions, managerOptions }: Props) => {
  const snackBar = useSnackbar();
  const queryClient = useQueryClient();

  const changeManagerMutation = useMutation({
    mutationFn: async ({ team, manager }: FormValues) =>
      await httpClient.put(team, { manager }),
    onSuccess: (response) => {
      queryClient.setQueryData<Team[]>(['amsTeams'], (oldData) => {
        const index = oldData!.findIndex(
          (team) => team['@id'] === response.data['@id'],
        );
        const newData = [...oldData!];
        newData[index] = response.data;
        return newData;
      });
      snackBar({ type: 'success', message: 'Manager uppdaterad' });
    },
    onError: () =>
      snackBar({
        type: 'error',
        message: 'Misslyckades med att byta manager',
      }),
  });

  const submitHandler = (values: FormValues, form: FormApi<FormValues>) => {
    changeManagerMutation.mutate(values);
    form.restart();
  };

  if (!teamOptions || !managerOptions) return null;

  return (
    <Card>
      <CardHeader title="Ändra team manager" />
      <Divider />
      <CardContent>
        <Form<FormValues>
          onSubmit={(values, form) => submitHandler(values, form)}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Dropdown
                name="team"
                label="Välj team"
                options={teamOptions}
                fieldProps={{
                  validate: fieldRequired,
                }}
              />
              <Dropdown
                name="manager"
                label="Välj manager"
                options={managerOptions}
                fieldProps={{
                  validate: fieldRequired,
                }}
              />
              <SaveButton />
            </form>
          )}
        />
      </CardContent>
    </Card>
  );
};
