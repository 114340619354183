import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

import { getObjectKeys } from '@/helpers/tsHelpers';
import {
  ResellerPortalStatsDistribution,
  TResellerPortalStats,
} from '@/types/stats';

type Props = {
  summaryStats:
    | TResellerPortalStats['basic']['summary']
    | TResellerPortalStats['reseller_summary']
    | Pick<
        ResellerPortalStatsDistribution,
        'Brutto' | 'Netto' | 'Makulerad' | 'Ånger'
      >;
};

export const TotalCard = ({ summaryStats }: Props) => {
  return (
    <Grid item xs={12}>
      <Card>
        <CardHeader title="Totalt" />
        <Divider />
        <CardContent sx={{ pt: 1 }}>
          <Table size="small">
            <>
              <TableHead>
                <TableRow>
                  {getObjectKeys(summaryStats).map((key, idx) => (
                    <TableCell key={key}>{key}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {getObjectKeys(summaryStats).map((key, idx) => (
                    <TableCell key={key + idx}>{summaryStats[key]}</TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </>
          </Table>
        </CardContent>
      </Card>
    </Grid>
  );
};
