import { useQuery } from '@tanstack/react-query';

import { ApiCollectionResponse } from '@/types/common';
import httpClient from '@/utils/httpClient';

import { Team } from '../types';

export const useFetchTeams = () => {
  return useQuery({
    queryKey: ['amsTeams'],
    queryFn: async () => {
      const { data } = await httpClient.get<ApiCollectionResponse<Team>>(
        '/teams',
        {
          params: {
            pagination: false,
          },
        },
      );
      return data['hydra:member'];
    },
  });
};
