import React, { useState } from 'react';

import { LinearProgress, Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { deleteNote, getNotes } from '@/api/notes';
import { Note } from '@/components/Note';
import { Pagination } from '@/components/Pagination';
import {
  handlePageChange,
  handleRowsPerPageChange,
} from '@/modules/common/utils/pagination';
import { NoteItem } from '@/types/notes';

import { queryKeys } from '../../../constants/queryKeys';

type Props = {
  filter: {
    customer: string;
    dateAfter: string;
    dateBefore: string;
    types: Array<{ value: string; text: string }>;
  };
};

type OldData = {
  data: { id: number }[];
  totalItems: number;
};

export const NotesList = ({ filter }: Props) => {
  const queryClient = useQueryClient();
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(100);

  const {
    data: notes,
    isFetching,
    isSuccess: success,
    error,
  } = useQuery({
    queryKey: [queryKeys.getNotes, filter, page, perPage],

    queryFn: async () => {
      const types = filter.types.map(({ value }) => parseInt(value));
      const { data } = await getNotes({
        types,
        dateAfter: filter.dateAfter,
        dateBefore: filter.dateBefore,
        page: page + 1,
        perPage,
        pagination: true,
        customerId: filter.customer,
      });
      return {
        data: data['hydra:member'],
        totalItems: data['hydra:totalItems'],
      };
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (id: string) => deleteNote(id),
    onSuccess: (response, data) => {
      const noteIri = data.split('/');
      const noteId = noteIri[2] && parseInt(noteIri[2]);
      queryClient.setQueryData<OldData | undefined>(
        [queryKeys.getNotes, filter, page, perPage],
        (oldData) => {
          if (oldData) {
            const newData = oldData.data.filter(
              (note: { id: number }) => note.id !== noteId,
            );

            const newTotal = oldData.totalItems - 1;
            return { data: newData, totalItems: newTotal };
          }
          return oldData;
        },
      );
    },
  });

  async function handleNoteDelete(payload: NoteItem) {
    deleteMutation.mutate(payload['@id']);
  }

  if (error) {
    return <Typography variant="h1">Misslyckades hämta notes.</Typography>;
  }

  if (success && notes.data.length === 0) {
    return (
      <Typography
        variant="h2"
        color="textSecondary"
        align="center"
        marginTop={3}
      >
        Inga anteckningar för valda filter
      </Typography>
    );
  }

  return (
    <>
      {isFetching && <LinearProgress />}
      {success && (
        <>
          {notes.data.map((note, i) => (
            <Note
              key={note.id}
              note={note}
              removalInProgress={deleteMutation.isPending}
              onDelete={handleNoteDelete}
            />
          ))}
          <Pagination
            count={notes.totalItems}
            page={page}
            rowsPerPage={perPage}
            onPageChange={(_: any, page: number) =>
              handlePageChange(page, setPage)
            }
            onRowsPerPageChange={(event: { target: { value: string } }) =>
              handleRowsPerPageChange(event, setPerPage)
            }
          />
        </>
      )}
    </>
  );
};
