import { assign, createMachine } from 'xstate';
import { z } from 'zod';

import { updateSinneDeviceInNoClip } from '@/api/noClip';
import { CreateNotePayload, createNote } from '@/api/notes';
import { updateService } from '@/api/services';
import {
  getMostRecentSinneCustomer,
  getSinneDeviceSimcardInfoByImei,
  newGetSinneDeviceInfo,
} from '@/api/sinne';
import { queryClient } from '@/index';
import { basicCustomerDataSchema } from '@/types/customers';
import { sinneDeviceStatusMap } from '@/types/services';

import { MAX_RETRIES, RETRY_DELAY } from '../constants/constants';
import { isServerError } from '../utils';

export type SinneReturnFlowMachineContext = {
  cardTitle: string;
  customerId: number | undefined;
  customerName: string | undefined;
  discardDeviceFee: number;
  imei: number;
  invoiceProvider: string; // TODO: this can be typed keyof typeof INVOICE_PROVIDERS | 'none' | undefined
  isCustomerFeeCreated: boolean;
  isCustomerServiceEmailSent: boolean;
  isEldesEmailSent: boolean;
  isNoClipUpdated: boolean;
  isNoteCreated: boolean;
  isSimCardDeactivated: boolean;
  isSveaFeeCreated: boolean;
  isSinneServiceUpdated: boolean;
  macAddress: string;
  noteText: string;
  simCard: {
    status: string;
    retries: number;
  };
  device: {
    connected: boolean;
    retries: number;
    sinneEntityId: number | null;
  };
};

type CREATE_NOTE = {
  type: 'CREATE_NOTE';
  payload: CreateNotePayload;
};

type SET_SIM_CARD_RETRIES = {
  type: 'SET_SIM_CARD_RETRIES';
  retries: number;
};

type SET_SIM_CARD_STATUS = {
  type: 'SET_SIM_CARD_STATUS';
  status: string;
};

type RETRY_SIM_CARD_CHECK = {
  type: 'RETRY_SIM_CARD_CHECK';
  imei: number;
};

type SET_DEVICE_INFORMATION = {
  type: 'SET_DEVICE_INFORMATION';
  connected: boolean;
  sinneEntityId: number | null;
};

type SET_DEVICE_RETRIES = {
  type: 'SET_DEVICE_RETRIES';
  retries: number;
};

type RETRY_DEVICE_CHECK = {
  type: 'RETRY_DEVICE_CHECK';
  macAddress: string;
};

type RETRY_CUSTOMER_CHECK = {
  type: 'RETRY_CUSTOMER_CHECK';
};

type RETRY_ADD_FEE = {
  type: 'RETRY_ADD_FEE';
  value: number;
};

type RETRY_ADD_SVEA_FEE = {
  type: 'RETRY_ADD_SVEA_FEE';
  value: number;
};
type RETRY_SEND_CUSTOMER_SERVICE_EMAIL = {
  type: 'RETRY_SEND_CUSTOMER_SERVICE_EMAIL';
};
type RETRY_SEND_ELDES_EMAIL = {
  type: 'RETRY_SEND_ELDES_EMAIL';
};
type RETRY_DEACTIVATE_SIM_CARD = {
  type: 'RETRY_DEACTIVATE_SIM_CARD';
};

type SUBMIT_DEVICE_DATA_FORM = {
  type: 'SUBMIT_DEVICE_DATA_FORM';
  macAddress: string;
  imei: number;
};

export type SinneReturnFlowMachineEvent =
  | CREATE_NOTE
  | RETRY_ADD_FEE
  | RETRY_ADD_SVEA_FEE
  | RETRY_CUSTOMER_CHECK
  | RETRY_DEACTIVATE_SIM_CARD
  | RETRY_DEVICE_CHECK
  | RETRY_SEND_CUSTOMER_SERVICE_EMAIL
  | RETRY_SEND_ELDES_EMAIL
  | RETRY_SIM_CARD_CHECK
  | SET_DEVICE_RETRIES
  | SET_DEVICE_INFORMATION
  | SET_SIM_CARD_RETRIES
  | SET_SIM_CARD_STATUS
  | SUBMIT_DEVICE_DATA_FORM
  | { type: 'SET_FEE_VALUE'; value: number }
  | { type: 'COMPLETE_FLOW' }
  | { type: 'SET_CARD_TITLE'; cardTitle: string };

export const SIM_CARD_ACTIVE = 'ACTIVE';

const initialContext: SinneReturnFlowMachineContext = {
  cardTitle: 'Flöde för att återanvända en enhet',
  customerId: undefined,
  customerName: undefined,
  device: {
    connected: false,
    retries: 0,
    sinneEntityId: null,
  },
  discardDeviceFee: 0,
  imei: 0,
  invoiceProvider: '',
  isCustomerFeeCreated: false,
  isCustomerServiceEmailSent: false,
  isEldesEmailSent: false,
  isNoClipUpdated: false,
  isNoteCreated: false, // TODO: redundant
  isSimCardDeactivated: false,
  isSveaFeeCreated: false,
  isSinneServiceUpdated: false,
  macAddress: '',
  noteText: '',
  simCard: {
    status: '',
    retries: 0,
  },
};
export const sinneReturnFlowMachine = createMachine(
  {
    id: 'returnFlow',
    tsTypes: {} as import('./sinneReturnFlowMachine.typegen').Typegen0,
    schema: {
      context: {} as SinneReturnFlowMachineContext,
      events: {} as SinneReturnFlowMachineEvent,
      services: {} as {
        createFee: { data: any };
        createNote: { data: any };
        createSveaFee: { data: any };
        terminateSimCard: { data: any };
        fetchDeviceInfo: { data: any };
        fetchSimCardInfo: { data: any };
        getMostRecentCustomer: { data: any };
        sendEmailToCustomerService: { data: any };
        sendEmailToEldes: { data: any };
        setInvoiceProvider: { data: any };
        updateSinneService: { data: any };
        updateNoclip: { data: any };
      },
    },
    context: {
      ...initialContext,
    },
    on: {
      SET_CARD_TITLE: {
        actions: assign((_, event) => ({ cardTitle: event.cardTitle })),
      },

      COMPLETE_FLOW: {
        target: 'DEVICE_DATA_FORM',
        actions: 'resetContext',
      },
    },
    initial: 'DEVICE_DATA_FORM',
    states: {
      DEVICE_DATA_FORM: {
        initial: 'editing',
        on: {
          SUBMIT_DEVICE_DATA_FORM: {
            actions: assign((_, event) => ({
              macAddress: event.macAddress,
              imei: event.imei,
            })),
            target: 'STATUS_CHECKS',
          },
        },
        states: {
          editing: {},
        },
      },
      STATUS_CHECKS: {
        type: 'parallel',
        onDone: {
          target: 'UPDATE_NOCLIP',
        },
        states: {
          DEVICE: {
            on: {
              RETRY_DEVICE_CHECK: {
                target: '.loading',
              },
              SET_DEVICE_INFORMATION: {
                actions: assign((ctx, event) => ({
                  device: {
                    ...ctx.device,
                    connected: event.connected,
                    sinneEntityId: event.sinneEntityId,
                  },
                })),
              },
              SET_DEVICE_RETRIES: {
                actions: assign((ctx, event) => ({
                  device: {
                    ...ctx.device,
                    retries: event.retries,
                  },
                })),
              },
            },
            initial: 'loading',
            states: {
              loading: {
                invoke: {
                  src: 'fetchDeviceInfo',
                  onDone: {
                    target: '#returnFlow.STATUS_CHECKS.DEVICE.CONNECTED',

                    cond: (_, event) => event.data.connected,
                  },
                  onError: [
                    {
                      target:
                        '#returnFlow.STATUS_CHECKS.DEVICE.error.serverError',
                      cond: (_, event) =>
                        event.data.status && event.data.status !== 200,
                    },
                    {
                      target:
                        '#returnFlow.STATUS_CHECKS.DEVICE.error.deviceDisconnected',
                      cond: (_, event) => !event.data.connected,
                    },
                  ],
                },
              },
              error: {
                states: {
                  serverError: {},
                  deviceDisconnected: {},
                },
              },
              CONNECTED: {
                type: 'final',
              },
            },
          },
          SIM_CARD: {
            initial: 'loading',
            on: {
              RETRY_SIM_CARD_CHECK: {
                target: '.loading',
              },
              SET_SIM_CARD_STATUS: {
                actions: assign((ctx, event) => ({
                  simCard: {
                    ...ctx.simCard,
                    status: event.status,
                  },
                })),
              },
              SET_SIM_CARD_RETRIES: {
                actions: assign((ctx, event) => ({
                  simCard: {
                    ...ctx.simCard,
                    retries: event.retries,
                  },
                })),
              },
            },
            states: {
              loading: {
                invoke: {
                  src: 'fetchSimCardInfo',
                  onDone: {
                    target: '#returnFlow.STATUS_CHECKS.SIM_CARD.ACTIVE',
                    actions: assign((context, event) => ({
                      simCard: {
                        ...context.simCard,
                        status: event.data.simSubscriptionStatus.toUpperCase(),
                      },
                    })),
                    cond: (_, event) =>
                      event.data.simSubscriptionStatus.toUpperCase() ===
                      SIM_CARD_ACTIVE,
                  },
                  onError: [
                    {
                      target:
                        '#returnFlow.STATUS_CHECKS.SIM_CARD.error.serverError',
                      cond: (_, event) =>
                        event.data.status && event.data.status !== 200,
                    },
                    {
                      target:
                        '#returnFlow.STATUS_CHECKS.SIM_CARD.error.simInactive',
                      cond: (_, event) =>
                        event.data.simSubscriptionStatus.toUpperCase() !==
                        SIM_CARD_ACTIVE,
                    },
                  ],
                },
              },
              error: {
                states: {
                  serverError: {},
                  simInactive: {},
                },
              },
              ACTIVE: {
                type: 'final',
              },
            },
          },
          CUSTOMER: {
            initial: 'loading',
            on: {
              RETRY_CUSTOMER_CHECK: {
                target: '.loading',
              },
            },
            states: {
              loading: {
                invoke: {
                  src: 'getMostRecentCustomer',
                  onDone: {
                    target: 'success',
                    actions: assign((_, event) => ({
                      customerId: event.data.id,
                      customerName: event.data.companyCategory
                        ? event.data.companyName
                        : event.data.fullName,
                    })),
                  },
                  onError: 'error',
                },
              },
              error: {},
              success: {
                type: 'final',
              },
            },
          },
        },
      },
      UPDATE_NOCLIP: {
        initial: 'loading',
        states: {
          loading: {
            invoke: {
              src: 'updateNoclip',
              onDone: {
                target: '#returnFlow.UPDATE_SINNE_SERVICE',
                actions: assign({ isNoClipUpdated: true }),
              },
              onError: '#returnFlow.UPDATE_NOCLIP.error',
            },
          },
          error: {
            always: { actions: assign({ isNoClipUpdated: false }) },
          },
        },
      },
      UPDATE_SINNE_SERVICE: {
        initial: 'loading',
        states: {
          loading: {
            invoke: {
              src: 'updateSinneService',
              onDone: 'success',
              onError: 'error',
            },
          },
          success: {
            entry: assign({ isSinneServiceUpdated: true }),
            always: '#returnFlow.CREATE_NOTE',
          },
          error: {
            entry: assign({ isSinneServiceUpdated: false }),
          }, // Todo: could add retry here
        },
      },
      CREATE_NOTE: {
        initial: 'editing',
        states: {
          editing: {
            on: {
              CREATE_NOTE: {
                actions: assign({ noteText: (_, event) => event.payload.text }),
                target: '#returnFlow.CREATE_NOTE.loading',
              },
            },
          },
          loading: {
            invoke: {
              src: 'createNote',
              onDone: '#returnFlow.CREATE_NOTE.success',
              onError: '#returnFlow.CREATE_NOTE.error',
            },
          },
          success: {
            entry: assign({ isNoteCreated: true }),
          },
          error: {},
        },
      },
    },
    predictableActionArguments: true,
    preserveActionOrder: true,
  },
  {
    actions: {
      resetContext: assign({ ...initialContext }),
    },
    services: {
      updateNoclip: async (context) => {
        const payload = {
          deviceId: context.macAddress,
          deviceStatus: sinneDeviceStatusMap.IN_STORAGE,
          sinneEntityId: null,
        };

        return await updateSinneDeviceInNoClip(payload);
      },
      getMostRecentCustomer: (context) => {
        return queryClient.fetchQuery({
          queryKey: ['getMostRecentCustomer', context.macAddress],
          queryFn: async () => {
            const data = await getMostRecentSinneCustomer(context.macAddress);
            const customer = basicCustomerDataSchema.passthrough().parse(data);

            return customer;
          },
        });
      },
      createNote: async (_, event) => {
        const { payload } = event;
        return queryClient.fetchQuery({
          queryKey: ['createNote', payload],
          queryFn: async () => {
            return await createNote(payload);
          },
        });
      },
      fetchSimCardInfo: (_, event) => (send) => {
        const { imei } = event;

        return queryClient.fetchQuery({
          queryKey: ['getSimCardInfo', imei],
          queryFn: async () => {
            const response = await getSinneDeviceSimcardInfoByImei(imei);

            send({
              type: 'SET_SIM_CARD_STATUS',
              status: response.simSubscriptionStatus,
            });

            if (
              response.simSubscriptionStatus.toUpperCase() === SIM_CARD_ACTIVE
            ) {
              return response;
            }

            throw new Error('Sim card is not active');
          },
          retry: (failureCount, error) => {
            send({
              type: 'SET_SIM_CARD_RETRIES',
              retries: failureCount,
            });
            if (isServerError(error)) {
              return false;
            }

            return failureCount < MAX_RETRIES;
          },
          retryDelay: RETRY_DELAY,
        });
      },
      fetchDeviceInfo: (_, event) => (send) => {
        const { macAddress } = event;

        return queryClient.fetchQuery({
          queryKey: ['sinneDeviceInfo', macAddress],
          queryFn: async () => {
            const deviceInfo = await newGetSinneDeviceInfo({ macAddress });
            if (deviceInfo.connected && deviceInfo.sinneEntityId) {
              send({
                type: 'SET_DEVICE_INFORMATION',
                connected: deviceInfo.connected,
                sinneEntityId: deviceInfo.sinneEntityId,
              });
              return deviceInfo;
            }
            send({
              type: 'SET_DEVICE_INFORMATION',
              connected: deviceInfo.connected,
              sinneEntityId: deviceInfo.sinneEntityId,
            });

            throw new Error('Device is not connected');
          },
          retry: (failureCount, error) => {
            send({ type: 'SET_DEVICE_RETRIES', retries: failureCount });
            if (isServerError(error)) {
              return false;
            }
            return failureCount < MAX_RETRIES;
          },
          retryDelay: RETRY_DELAY,
        });
      },
      updateSinneService: (context) => {
        const serviceId = z.number().parse(context.device.sinneEntityId);
        return queryClient.fetchQuery({
          queryKey: ['updateSinneService', context.device.sinneEntityId],
          queryFn: async () => {
            return await updateService({
              serviceName: 'sinne',
              serviceId,
              values: { unitReturned: true },
            });
          },
        });
      },
    },
  },
);
