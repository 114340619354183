import React from 'react';

import {
  Button,
  Grid,
  LinearProgress,
  TextField,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Form } from 'react-final-form';

import { getProtectionCampaigns } from '@/api/protection';
import { getResellers } from '@/api/reseller';
import { SimpleCard } from '@/components/SimpleCard';
import { prepareFieldValues } from '@/helpers/parsing';
import { getObjectKeys } from '@/helpers/tsHelpers';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import {
  AlarmLocationSection,
  AntiTheftDecalInformationSection,
  ContactListSection,
  CustomerBankInformationSection,
  CustomerInformationSection,
  MonitoringPlanSection,
  ProtectionListSection,
  SalesInformationSection,
} from '@/modules/services/components/sections';
import { ServiceName } from '@/types/services';
import { FEATURE_FLAGS } from '@/utils/featureFlags';

type CreateServiceFormProps = {
  customerCheckInProgress?: boolean;
  customerInfo?: any; // BasicCustomerInfo???
  errorMessage?: string;
  hasError?: boolean;
  onSubmit: (values: any) => void;
  preselectedCustomerMode?: boolean;
  requestInProgress?: boolean;
  serviceName: Exclude<ServiceName, 'sinne' | 'homegate'>;
};

export const CreateServiceForm = ({
  customerCheckInProgress,
  customerInfo,
  errorMessage,
  hasError,
  onSubmit,
  preselectedCustomerMode,
  requestInProgress,
  serviceName,
}: CreateServiceFormProps) => {
  const protectionCampaignV2Enabled = useFeatureFlag({
    name: FEATURE_FLAGS.protectionCampaignV2.name,
  });

  const [pasteFieldValue, setPasteFieldValue] = React.useState('');
  const [pasteFieldError, setPasteFieldError] = React.useState(false);

  const { data: protectionCampaigns, isLoading: loadingProtectionCampaign } =
    useQuery({
      queryKey: ['protectionCampaigns'],
      queryFn: () => getProtectionCampaigns({ useNewEndpoint: false }),
      enabled: !protectionCampaignV2Enabled,
    });

  const { data: resellers, isLoading: loadingResellers } = useQuery({
    queryKey: ['resellersList'],
    queryFn: () => getResellers(),
    initialData: { data: [], totalCount: 0 },
  });

  //#endregion

  function handlePasteExcelData(
    event: React.ClipboardEvent<HTMLDivElement>,
    change: (name: string, value?: unknown) => void,
  ) {
    setPasteFieldError(false);

    try {
      const fieldsValues = prepareFieldValues(
        event.clipboardData.getData('Text'),
        serviceName,
      );

      if (serviceName === 'protection' && protectionCampaignV2Enabled) {
        fieldsValues.protectionsCampaign = `/protections_campaigns/${fieldsValues.protectionsCampaign}`;
      }

      if (
        serviceName === 'protection' &&
        !protectionCampaignV2Enabled &&
        protectionCampaigns
      ) {
        const campaignSettings = protectionCampaigns.find(
          (campaign) => campaign.name === fieldsValues.campaign,
        );
        if (
          campaignSettings &&
          campaignSettings.pricePlanId !== null &&
          campaignSettings.pricePlanId !== undefined
        ) {
          fieldsValues.pricePlanId = campaignSettings.pricePlanId;
        }
        if (
          campaignSettings &&
          campaignSettings.type !== null &&
          campaignSettings.type !== undefined
        ) {
          fieldsValues.type = campaignSettings.type;
        }
      }

      getObjectKeys(fieldsValues).forEach((key) => {
        change(key, fieldsValues[key]);
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setPasteFieldError(true);
    }

    setPasteFieldValue('');
  }

  if (loadingResellers) {
    return <LinearProgress />;
  }

  if (protectionCampaignV2Enabled && loadingProtectionCampaign) {
    return <LinearProgress />;
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        ...(serviceName === 'co-protection' && { monitoringPlan: 1 }),
      }}
      render={({
        handleSubmit,
        form: { change, getRegisteredFields },
        values,
      }) => (
        <form id={serviceName} onSubmit={handleSubmit}>
          {!preselectedCustomerMode && (
            <section>
              <SimpleCard
                title="Data från Excel"
                cardContent={
                  <>
                    <TextField
                      autoFocus
                      placeholder="Klistra in en rad från en Excel fil"
                      variant="outlined"
                      onPaste={(event) => handlePasteExcelData(event, change)}
                      value={pasteFieldValue}
                      data-testid="excelRowInput"
                    />
                    {pasteFieldError && (
                      <Typography color="error">Ogiltig data</Typography>
                    )}
                  </>
                }
              />
            </section>
          )}

          <section>
            <SimpleCard
              title="Kundregistrering"
              cardContent={
                <>
                  {preselectedCustomerMode ? (
                    <Grid container direction="column" spacing={3}>
                      <Grid item>
                        <Typography variant="h6">Kundinformation</Typography>
                      </Grid>

                      <Grid item>
                        <TextField
                          margin="none"
                          value={customerInfo.pin}
                          label="PIN"
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container spacing={3}>
                      <Grid item xs>
                        <Typography variant="h6">Kundinformation</Typography>

                        <CustomerInformationSection />
                      </Grid>

                      <Grid item xs>
                        <Typography variant="h6">Autogiro</Typography>

                        <CustomerBankInformationSection />
                      </Grid>
                    </Grid>
                  )}
                </>
              }
            />
          </section>

          <section>
            <SimpleCard
              title="Produktregistrering"
              cardContent={
                <>
                  {serviceName === 'firetext' && (
                    <>
                      <section>
                        <Typography variant="h6">Kontaktlista</Typography>

                        <ContactListSection />
                      </section>

                      <section>
                        <Typography variant="h6">
                          Placering av brandlarmet
                        </Typography>

                        <AlarmLocationSection />
                      </section>
                    </>
                  )}

                  {serviceName === 'protection' && (
                    <>
                      <section>
                        <Typography variant="h6">Skyddslista</Typography>

                        <ProtectionListSection />
                      </section>
                    </>
                  )}

                  {serviceName === 'co-protection' && (
                    <>
                      <section>
                        <Typography variant="h6">Övervakningsplan</Typography>

                        <MonitoringPlanSection />
                      </section>

                      <section>
                        <Typography variant="h6">Skyddade</Typography>

                        <AntiTheftDecalInformationSection editMode={false} />
                      </section>
                    </>
                  )}

                  <section>
                    <Typography variant="h6">Sälj</Typography>

                    <SalesInformationSection
                      customerInfo={customerInfo}
                      serviceName={serviceName}
                      resellers={resellers.data}
                      customerCategory={values?.category}
                    />
                  </section>
                </>
              }
            />
          </section>

          {(requestInProgress || customerCheckInProgress) && <LinearProgress />}

          {hasError && (
            <Typography color="error" gutterBottom>
              {errorMessage}
            </Typography>
          )}

          <div>
            <Button
              disabled={requestInProgress || customerCheckInProgress}
              type="submit"
              color="primary"
              variant="contained"
            >
              {requestInProgress || customerCheckInProgress
                ? 'Registrering'
                : 'Registrera'}
            </Button>
          </div>
        </form>
      )}
    />
  );
};
