import { getPaginatedPageParams } from '@/modules/common/utils/pagination';
import { ApiCollectionResponse, Pagination } from '@/types/common';
import {
  CreatePartnerPayload,
  PartnerResponse,
  PartnerStatisticsResponse,
  UpdatePartnerPayload,
} from '@/types/partnerCompany';
import httpClient from '@/utils/httpClient';
import { AllBooleans } from '@/utils/typeHelpers';

export async function fetchPartnersList() {
  const { data } = await httpClient.get<ApiCollectionResponse<PartnerResponse>>(
    '/partner_companies',
    {
      params: {
        pagination: false,
        properties: {
          id: true,
          name: true,
        },
      },
    },
  );

  return data['hydra:member'];
}

type QueryableProperties = AllBooleans<PartnerResponse>;

export type GetPartnerParams = Pagination & {
  properties?: Partial<Omit<QueryableProperties, '@type' | '@id'>>;
};

export async function fetchPartnersListV2({
  properties,
  ...paginationParams
}: GetPartnerParams) {
  const params = {
    properties,
    ...getPaginatedPageParams(paginationParams),
  };
  const { data } = await httpClient.get<ApiCollectionResponse<PartnerResponse>>(
    '/partner_companies',
    {
      params,
    },
  );

  return data;
}

export type GetPartnerCompanyByIdParams = {
  id: string;
  properties?: Partial<Omit<QueryableProperties, '@type' | '@id'>>;
};
export async function getPartnerCompanyById({
  id,
  properties,
}: GetPartnerCompanyByIdParams) {
  const { data } = await httpClient.get<PartnerResponse>(id, {
    params: {
      properties,
    },
  });

  return data;
}

export async function createPartner(payload: CreatePartnerPayload) {
  const { data } = await httpClient.post<PartnerResponse>(
    '/partner_companies',
    payload,
  );

  return data;
}

export async function updatePartner(payload: UpdatePartnerPayload) {
  const { id, ...rest } = payload;
  const { data } = await httpClient.put<PartnerResponse>(
    `/partner_companies/${id}`,
    rest,
  );

  return data;
}

export async function deletePartner(id: string | number) {
  return await httpClient.delete(`/partner_companies/${id}`);
}

export type PartnerStatisticsParams = {
  partnerId: number;
  start: string;
  end: string;
};

export async function getPartnerStatistics({
  partnerId,
  ...params
}: PartnerStatisticsParams) {
  const { data } = await httpClient.get<PartnerStatisticsResponse>(
    `/partner/${partnerId}/statistics`,
    {
      params,
    },
  );

  return data;
}
