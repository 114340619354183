import { atomWithStorage } from 'jotai/utils';

export type BulkRegistrationState = {
  fileValidationUuid: string;
  fileValidationStatus: 'pending' | 'complete' | 'failed' | 'idle';
  serviceRegistrationUuid: string;
  serviceRegistrationStatus?: 'pending' | 'complete' | 'failed' | 'idle';
  bulkRegistrationStep:
    | 'fileValidationForm'
    | 'fileValidationPending'
    | 'fileValidationComplete'
    | 'fileValidationError'
    | 'serviceRegistrationForm'
    | 'serviceRegistrationPending'
    | 'serviceRegistrationComplete'
    | 'serviceRegistrationError';
};

export const initialState: BulkRegistrationState = {
  fileValidationUuid: '',
  fileValidationStatus: 'idle',
  serviceRegistrationUuid: '',
  serviceRegistrationStatus: 'idle',
  bulkRegistrationStep: 'fileValidationForm',
};
export const bulkRegistrationAtom = atomWithStorage<BulkRegistrationState>(
  'bulkRegistration',
  initialState,
  undefined,
  {
    getOnInit: true,
  },
);
