import { Box, Button } from '@mui/material';
import pick from 'lodash/pick';
import { Form } from 'react-final-form';
import { z } from 'zod';

import { KeyTagParamSchema } from '@/types/services';
import { keyTagStatusKey } from '@/types/settings';

import { mapInitialObjectValuesToStrings } from '../../../utils/mapInitialObjectValuesToStrings';
import { SalesInformationForm } from './SalesInformationForm';
import { ServiceInfoForm } from './ServiceInfoForm';

const FIELDS_TO_PICK = [
  'status',
  'churnDate',
  'billing',
  'billingMonth',
  'reseller',
  'pricePlanId',
  'adminNote',
  'salesRep',
  'campaign',
  'regDate',
  'addressList',
  'leadSource',
  'kamId',
  'tb',
];

const keytagFormValuesSchema = z.object({
  addressList: z.string().nullable(),
  adminNote: z.string().nullable(),
  billing: z.number().int().nullable().or(z.boolean()),
  billingMonth: z.number().int().nullable(),
  campaign: z.string().nullable(),
  churnDate: z.string().nullable(),
  kamId: z.number().int().nullable(),
  leadSource: z.string().nullable(),
  pricePlanId: z.number().int(),
  regDate: z.string().or(z.date()),
  reseller: z.string().nullable(),
  salesRep: z.string().nullable(),
  status: keyTagStatusKey,
  tb: z.number().int().nullable(),
});

type KeyTagFormValues = z.infer<typeof keytagFormValuesSchema>;

export type KeyTagEditProps = {
  serviceDetails: KeyTagParamSchema;
  isLoading: boolean;
  onSubmit: (values: KeyTagFormValues) => void;
};

export const KeyTagEdit = ({
  serviceDetails,
  isLoading,
  onSubmit,
}: KeyTagEditProps) => {
  const serviceName = 'keyTag';
  const initialValues = mapInitialObjectValuesToStrings(
    pick(serviceDetails, FIELDS_TO_PICK),
  );

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <Box display="flex" flexDirection="column" gap={2}>
            <ServiceInfoForm
              serviceDetails={serviceDetails}
              isLoading={isLoading}
            />

            <SalesInformationForm
              serviceName={serviceName}
              serviceDetails={serviceDetails}
              isLoading={isLoading}
            />
          </Box>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={isLoading}
            sx={{ mt: 2 }}
          >
            Uppdatera
          </Button>
        </form>
      )}
    />
  );
};
