import React from 'react';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  LinearProgress,
  Typography,
} from '@mui/material';
import { useSelector } from '@xstate/react';

import { NoteTypeMissingAlert } from '@/modules/sinne';
import { StepRow } from '@/modules/sinne/components/StepRow';
import { DeactivationDispatch } from '@/modules/sinne/components/sinneDiscardFlow/components/DeactivationDispatch';
import { DeviceDataDisplay } from '@/modules/sinne/components/sinneDiscardFlow/components/DeviceDataDisplay';
import { DeviceDataForm } from '@/modules/sinne/components/sinneDiscardFlow/components/DeviceDataForm';
import { FeeForm } from '@/modules/sinne/components/sinneDiscardFlow/components/FeeForm';
import { NoteForm } from '@/modules/sinne/components/sinneDiscardFlow/components/NoteForm';
import {
  DiscardStateContext,
  DiscardStateProvider,
} from '@/modules/sinne/components/sinneDiscardFlow/context/DiscardStateProvider';
import { useNoteTypeGuard } from '@/modules/sinne/hooks/useNoteTypeGuard';

const Container = () => {
  const { noteTypeIsInPrivateSettings, loading } =
    useNoteTypeGuard('Enhet Returnerad');
  const discardFlowService = React.useContext(DiscardStateContext);
  const state = useSelector(discardFlowService, (state) => state);
  const { cardTitle } = state.context;

  const flowSuccessText = 'Enheten har kasserats';
  const isNoteCreated = state.matches('CREATE_NOTE.success');

  if (loading) {
    return <LinearProgress />;
  }

  if (!noteTypeIsInPrivateSettings) {
    return <NoteTypeMissingAlert />;
  }

  return (
    <Box component={Card} maxWidth="lg">
      <CardHeader title={cardTitle} />
      <Divider />
      <CardContent>
        <DeviceDataForm />
        <FeeForm />
        <DeactivationDispatch />
        <NoteForm />

        {isNoteCreated && (
          <StepRow>
            <CheckCircleOutlineIcon color="success" sx={{ fontSize: 20 }} />
            <Typography component="span" variant="h6">
              {flowSuccessText}
            </Typography>
          </StepRow>
        )}
      </CardContent>
      {isNoteCreated && (
        <>
          <Divider />
          <CardActions sx={{ p: 2 }}>
            <Button
              variant="contained"
              onClick={() => discardFlowService.send({ type: 'COMPLETE_FLOW' })}
            >
              Klar
            </Button>
          </CardActions>
        </>
      )}
      <Divider sx={{ mb: 2 }} />
      <CardContent>
        <DeviceDataDisplay />
      </CardContent>
    </Box>
  );
};

export const DiscardFlow = () => {
  return (
    <DiscardStateProvider>
      <Container />
    </DiscardStateProvider>
  );
};
