import {
  Divider,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material';

const BoldListItemText = ({ children }: { children: React.ReactNode }) => (
  <ListItemText primaryTypographyProps={{ variant: 'subtitle2' }}>
    {children}
  </ListItemText>
);

export const CustomerExportExplanationCard = () => {
  return (
    <List
      dense
      component={Paper}
      subheader={
        <Typography variant="h6" sx={{ py: 1, px: 2 }}>
          Vid kundexport gäller följande:
        </Typography>
      }
    >
      <Divider />
      <ListItem>
        <BoldListItemText>
          Den cachade filen skapas 06.00 varje morgon
        </BoldListItemText>
      </ListItem>
      <ListItem>
        <BoldListItemText>
          Exporten är alltid från en cachad fil
        </BoldListItemText>
      </ListItem>
    </List>
  );
};
