import findIndex from 'lodash/findIndex';

import { getElementFromArray } from '@/helpers/getElementFromArray';
import { getObjectKeys } from '@/helpers/tsHelpers';
import { StatsRawData } from '@/types/stats';

import { StatusesToDisplay } from '../constants/SERVICE_STATUS_CHART_COLOR_MAP';
import { pickFieldsForProductStats } from './pickFieldsForProductStats';

type DataSets = {
  label: StatusesToDisplay;
  backgroundColor?: string;
  data: number[];
  fill: boolean;
};

type Labels = string[];

export type StatsChartData = {
  datasets: DataSets[];
  labels: Labels;
};

export function parseBasicStatsChartData(data: StatsRawData) {
  return getObjectKeys(data).reduce((prev, current) => {
    const label = current;

    const dataToDisplay = pickFieldsForProductStats(
      getElementFromArray(data[current]),
    );

    if (!prev.labels) {
      prev.labels = [label];
    } else {
      prev.labels.push(label);
    }

    if (!prev.datasets) {
      prev.datasets = [];

      getObjectKeys(dataToDisplay).forEach((key) => {
        prev.datasets.push({
          label: key,
          data: [dataToDisplay[key]!],
          fill: false,
        });
      });
    } else {
      getObjectKeys(dataToDisplay).forEach((key) => {
        const entryIndex = findIndex(prev.datasets, (set) => set.label === key);

        getElementFromArray(
          prev.datasets?.[entryIndex]?.data.push(dataToDisplay[key]!),
        );
      });
    }

    return prev;
  }, {} as StatsChartData);
}
