import React, { createContext } from 'react';

import { useInterpret } from '@xstate/react';
import { InterpreterFrom } from 'xstate';

import { sinneReturnFlowMachine } from './sinneReturnFlowMachine';

export const SinneReturnStateContext = createContext(
  {} as InterpreterFrom<typeof sinneReturnFlowMachine>,
);

export const SinneReturnStateProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const sinneReturnFlowService = useInterpret(sinneReturnFlowMachine, {
    devTools: true,
  }).start();

  return (
    <SinneReturnStateContext.Provider value={sinneReturnFlowService}>
      {children}
    </SinneReturnStateContext.Provider>
  );
};
