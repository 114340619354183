import { Card } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { addFee } from '@/api/fees';
import { queryKeys } from '@/constants/queryKeys';
import { mapDateFieldsValues } from '@/helpers/dates';
import { useSnackbar } from '@/hooks';

import { AddFee, AddFeeFormValues } from './AddFee';
import { ComingSpecialFees } from './ComingSpecialFees';
import { EarlierSpecialFees } from './EarlierSpecialFees';
import { NextBilling } from './NextBilling';

type Props = {
  companyCustomer: boolean;
};

export const Billing = ({ companyCustomer }: Props) => {
  const snackbar = useSnackbar();

  const { id: customerId } = useParams();
  const queryClient = useQueryClient();

  const addFeeMutation = useMutation({
    mutationFn: addFee,
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: [queryKeys.getInvoiceList],
      });
      queryClient.refetchQueries({
        queryKey: [queryKeys.getFees],
      });
      snackbar({
        type: 'success',
        message: 'Avgift skapad.',
      });
    },
    onError: () => {
      snackbar({
        type: 'error',
        message: 'Misslyckades skapa avgift.',
      });
    },
  });

  function handleAddFee(values: AddFeeFormValues) {
    values = mapDateFieldsValues(values);

    const customer = `/customers/${customerId}`;
    addFeeMutation.mutate({ ...values, customer, count: 1 });
  }

  return (
    <>
      <NextBilling companyCustomer={companyCustomer} />
      <StyledCard>
        <AddFee onSubmit={handleAddFee} />
      </StyledCard>
      <StyledCard>
        <ComingSpecialFees companyCustomer={companyCustomer} />
      </StyledCard>
      <StyledCard>
        <EarlierSpecialFees companyCustomer={companyCustomer} />
      </StyledCard>
    </>
  );
};

const StyledCard = styled(Card)(({ theme }) => ({
  margin: theme.spacing(4, 0, 0, 0),
}));
