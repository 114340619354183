import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import findKey from 'lodash/findKey';

import { getHandleableAlarm, updateHandleableAlarm } from '@/api/alarms';
import { getServiceDetails } from '@/api/services';
import { getEvent } from '@/api/sinne';
import { getElementFromArray } from '@/helpers/getElementFromArray';
import { usePrivateSettings } from '@/hooks';
import { AlarmEvent } from '@/types/alarms';
import {
  CamelCaseServiceName,
  FiretextContactPerson,
  PartialContactPerson,
} from '@/types/services';
import { extractIdFromIRI } from '@/utils/common';

export type TransformedAlarmEvent = Pick<AlarmEvent, 'occuredAt'> & {
  type: string;
};

type Props = {
  id: string;
  onAlarmMutationSuccess: () => void;
  onAlarmMutationError: () => void;
};

export const useAlarmDetails = ({
  id,
  onAlarmMutationError,
  onAlarmMutationSuccess,
}: Props) => {
  const { servicesStatuses } = usePrivateSettings();
  const queryClient = useQueryClient();

  const [alarmEventIris, setAlarmEventIris] = useState<string[]>([]);
  const [deviceType, setDeviceType] = useState<CamelCaseServiceName>();
  const [deviceId, setDeviceId] = useState<number>();
  const [contactPersonList, setContactPersonList] = useState<
    PartialContactPerson[]
  >([]);
  const [alarmEvents, setAlarmEvents] = useState<TransformedAlarmEvent[]>([]);

  // TODO: Check if this need to be useMemo
  const alarmEventTypes = useMemo(
    () => servicesStatuses?.sinneEvent?.events,
    [servicesStatuses],
  );

  const alarmMutation = useMutation({
    mutationFn: updateHandleableAlarm,
    onSuccess: ({ data }) => {
      queryClient.setQueryData(['handleableAlarm', id], data);
      onAlarmMutationSuccess();
    },
    onError: () => {
      onAlarmMutationError();
    },
  });

  const {
    data: alarm,
    isLoading: loadingAlarm,
    isError: errorAlarm,
  } = useQuery({
    queryKey: ['handleableAlarm', id],

    queryFn: async () => {
      const data = await getHandleableAlarm(id!);

      // TODO: Move to be handled in effect
      setAlarmEventIris(data.sinneEvents);

      return data;
    },

    refetchInterval: 5000,
  });

  const { data: deviceData, isError: errorDeviceData } = useQuery({
    queryKey: ['device', deviceId, deviceType],

    queryFn: () =>
      getServiceDetails({
        serviceName: deviceType!,
        serviceId: deviceId!,
      }),

    enabled: Boolean(deviceId && deviceType),
  });

  useQueries({
    queries: alarmEventIris.map((event) => ({
      queryKey: ['alarmEvent', event],
      queryFn: () => getEvent(event),
      onSuccess(response: AlarmEvent) {
        const newEvent: TransformedAlarmEvent = {
          type: alarmEventTypes[response.event],
          occuredAt: response.occuredAt,
        };

        setAlarmEvents((events) => [...events, newEvent]);
      },
      enabled: alarmEventIris.length > 0,
    })),
  });

  useEffect(() => {
    if (alarm?.firetext) {
      setDeviceType('firetext');
      setDeviceId(extractIdFromIRI(alarm.firetext));
    }
    if (alarm?.sinne) {
      setDeviceType('sinne');
      setDeviceId(extractIdFromIRI(alarm.sinne));
    }
  }, [alarm?.sinne, alarm?.firetext]);

  useEffect(() => {
    // TODO: Extract to a separate function
    if (deviceData) {
      if (deviceData && 'churnFiretextActive' in deviceData) {
        const contactPersons = (
          ['contact1', 'contact2', 'contact3', 'contact4', 'contact5'] as const
        ).reduce((acc: string[], key) => {
          if (deviceData[key]) {
            acc.push(deviceData[key]);
          }
          return acc;
        }, []);

        const phoneNumbers = (
          ['phone1', 'phone2', 'phone3', 'phone4', 'phone5'] as const
        ).reduce((acc: string[], key) => {
          if (deviceData[key]) {
            acc.push(deviceData[key]);
          }
          return acc;
        }, []);

        const personsWithPhone: FiretextContactPerson[] = contactPersons.map(
          (value, index) => ({
            name: value,
            phone: phoneNumbers[index]
              ? getElementFromArray(phoneNumbers[index])
              : 'saknas',
            id: value === '' ? 'saknas' : value,
          }),
        );
        setContactPersonList(personsWithPhone);
      } else if ('sinneEvents' in deviceData) {
        setContactPersonList(deviceData.contactPeople);
      }
    }
  }, [deviceData]);

  useEffect(() => {
    let processingAlarmStatusKey: string | number | undefined = findKey(
      servicesStatuses.handleableAlarm.statuses,
      (status) => status === 'Bearbetas',
    );

    let activeAlarmStatusKey: string | number | undefined = findKey(
      servicesStatuses.handleableAlarm.statuses,
      (status) => status === 'Aktiv',
    );

    if (processingAlarmStatusKey && activeAlarmStatusKey) {
      processingAlarmStatusKey = parseInt(processingAlarmStatusKey, 10);
      activeAlarmStatusKey = parseInt(activeAlarmStatusKey, 10);

      if (id && alarm?.status && alarm?.status === activeAlarmStatusKey) {
        const payload = {
          status: processingAlarmStatusKey,
          alarmId: parseInt(id, 10),
        };
        alarmMutation.mutate(payload);
      }
    } else {
      console.error("Status 'Bearbetas' eller 'Aktiv' finns inte.");
    }
  }, [alarm?.status, servicesStatuses]);

  const getSortedEvents = useCallback(() => {
    if (alarmEventIris.length === alarmEvents.length) {
      return alarmEvents.sort(
        (a, b) =>
          new Date(b.occuredAt).getTime() - new Date(a.occuredAt).getTime(),
      );
    }
    return [];
  }, [alarmEventIris, alarmEvents]);

  const isLoading = loadingAlarm || !deviceData;
  const hasError = errorAlarm || errorDeviceData;
  const sortedEvents = getSortedEvents();

  return [
    { isLoading, hasError, alarm, deviceData, contactPersonList, sortedEvents },
    { alarmMutation },
  ];
};
