import * as React from 'react';

import { SelectProps } from 'mui-rff';

import { usePrivateSettings } from '@/hooks';

import { Dropdown } from '../Dropdown';

type Props = SelectProps & {
  name?: string;
  label?: string;
};

export const ServicesWithPricesEntitiesDropdown = ({
  name = 'service',
  label = 'Tjänst',
  ...rest
}: Props) => {
  const { servicesNames } = usePrivateSettings();

  const options = [
    { value: 'homegate', text: servicesNames['homegate'] },
    { value: 'protection', text: servicesNames['protection'] },
    { value: 'sinne', text: servicesNames['sinne'] },
  ];
  return <Dropdown {...rest} name={name} label={label} options={options} />;
};
