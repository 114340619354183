import { useQuery } from '@tanstack/react-query';
import invariant from 'tiny-invariant';

import { getCustomerById } from '@/api/customers';
import { getSinneDeviceDetails } from '@/api/services';
import { queryKeys } from '@/constants/queryKeys';
import { getObjectKeys } from '@/helpers/tsHelpers';
import { usePrivateSettings } from '@/hooks';
import { ServiceDetailsResponse, SinneDeviceInfo } from '@/types/services';

const commonProps = {
  id: true,
  status: true,
  regDate: true,
  pricePlanId: true,
  price: {
    priceInCurrency: true,
    priceWithVatInCurrency: true,
  },
  campaign: {
    id: true,
    name: true,
    contractMonths: true,
    noticePeriod: true,
    freeMonths: true,
  },
};

type Params = {
  customerId: number;
};

export type Service = {
  '@id': string;
  '@type': ServiceDetailsResponse['@type'];
  id: number;
  campaign:
    | string
    | {
        name: string;
        contractMonths: number;
        noticePeriod: number;
        freeMonths: number;
      };
  placement: string;
  protectionsCampaign?: {
    name: string;
    contractMonths: number;
    noticePeriod: number;
    freeMonths: number;
  };
  pricePlanId: number;
  regDate: string;
  status: number;
  code?: string;
  imei?: string;
  price?: {
    priceInCurrency: number;
    priceWithVatInCurrency: number;
  };
  pin?: string;
};

export const useActiveServices = ({ customerId }: Params) => {
  const { privateSettings } = usePrivateSettings();
  const productNames = privateSettings?.products;

  const {
    data: services,
    refetch,
    status,
  } = useQuery({
    queryKey: [queryKeys.getCustomerServices, customerId],

    queryFn: async () => {
      const services = await getCustomerById(customerId, {
        coProtections: {
          ...commonProps,
          pin: true,
        },
        firetexts: {
          ...commonProps,
          placement: true,
        },
        homegates: commonProps,
        keyTags: {
          ...commonProps,
          code: true,
        },
        protections: {
          ...commonProps,
          protectionsCampaign: true, // v2 protection campaign
        },
        stickers: {
          ...commonProps,
          code: true,
        },
        sinnes: commonProps,
      });

      const firetextActiveStatuses = [1, 2, 3, 4];
      const sinneActiveStatuses = [1, 6, 8, 9];

      let enhancedServices = { ...services };

      // In order to get IMEI for sinne services, we need to make a separate request
      if (enhancedServices.sinnes && enhancedServices.sinnes.length > 0) {
        let sinneServices = await Promise.all(
          enhancedServices.sinnes.map(async (service) => {
            invariant(
              typeof service !== 'string',
              'service should not be a string',
            );

            // Skip inactive services
            if (!sinneActiveStatuses.includes(service.status!)) {
              return service;
            }

            try {
              const deviceInfo = (await getSinneDeviceDetails({
                serviceId: service.id!,
              })) as any as SinneDeviceInfo; // TODO: Fix ServiceDetailsResponse to include device field on sinne device info type

              return {
                ...service,
                imei: deviceInfo.device.imei,
              };
            } catch {
              return {
                ...service,
              };
            }
          }),
        );
        enhancedServices = { ...services, sinnes: sinneServices };
      }

      const allServices = getObjectKeys(productNames).map((type) => {
        return enhancedServices[`${type}s`] ? enhancedServices[`${type}s`] : [];
      }) as Array<Array<Service>>;

      const activeStatuses = [1];
      const sorted: { active: Service[]; inactive: Service[] } = {
        active: [],
        inactive: [],
      };

      allServices.map((services) => {
        return services.map((service) => {
          if (
            service['@type'] === 'Firetext' &&
            firetextActiveStatuses.includes(service.status)
          ) {
            return sorted.active.push(service);
          } else if (
            service['@type'] === 'Sinne' &&
            sinneActiveStatuses.includes(service.status)
          ) {
            return sorted.active.push(service);
          } else if (
            service['@type'] !== 'Firetext' &&
            activeStatuses.includes(service.status)
          ) {
            return sorted.active.push(service);
          }
          return sorted.inactive.push(service);
        });
      });
      return sorted;
    },

    enabled: !!customerId,
  });

  return { services, status, refetch };
};
