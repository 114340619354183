import { Grid } from '@mui/material';

import { SimpleCard } from '@/components/SimpleCard';
import { PhoneInput, TextInput } from '@/components/form';

export const ContactListSection = () => {
  return (
    <section>
      <SimpleCard
        title="Kontaktlista"
        cardContent={
          <>
            <section>
              <>
                {[...Array(5).keys()].map((_, index) => (
                  <Grid container spacing={3} key={index}>
                    <Grid item xs>
                      <TextInput
                        label="Namn"
                        name={`name${index + 1}`}
                        fieldProps={{ defaultValue: '' }}
                      />
                      <TextInput
                        name={`@id${index + 1}`}
                        sx={{ display: 'none' }}
                      />
                    </Grid>

                    <Grid item xs>
                      <PhoneInput
                        required={false}
                        label="Telefonnummer"
                        name={`phone${index + 1}`}
                      />
                    </Grid>
                    <Grid item xs>
                      <TextInput
                        required={false}
                        label="Relation"
                        name={`relation${index + 1}`}
                      />
                    </Grid>
                  </Grid>
                ))}
              </>
            </section>
          </>
        }
      />
    </section>
  );
};
