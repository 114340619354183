import React from 'react';

import {
  Alert,
  AlertTitle,
  Card,
  CardContent,
  CardHeader,
  Divider,
  LinearProgress,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { endOfMonth, format, startOfMonth, sub } from 'date-fns';
import { Form } from 'react-final-form';

import { getInvoiceCosts } from '@/api/costs';
import { LabelAndContentRow } from '@/components';
import { BasicAlert } from '@/components/BasicAlert';
import { SimpleTable } from '@/components/SimpleTable';
import { DatePicker } from '@/components/form';
import { AutoSave } from '@/components/form/AutoSave';
import { getFormattedDate } from '@/helpers/dates';
import { usePrivateSettings } from '@/hooks';
import { StyledButton } from '@/modules/common/components/DatePickerButtons/DatePickerButton';
import { formatValueTotal } from '@/modules/common/utils/currency';

import { columns } from './FeesTableColumnRows';

type Values = Record<string, any>;

export const OneOffFees = () => {
  const [filter, setFilter] = React.useState<{
    start: string;
    end: string;
  }>({
    start: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
    end: format(endOfMonth(new Date()), 'yyyy-MM-dd'),
  });

  const { privateSettings } = usePrivateSettings();
  const companyCurrency = privateSettings?.companyCurrency.symbol;

  const handleSave = async (values: Values) => {
    setFilter((prevFilter) => ({ ...prevFilter, ...values }));
  };

  const { data, isError, isLoading } = useQuery({
    queryKey: ['invoiceCosts', filter],

    queryFn: async () => {
      const data = await getInvoiceCosts(filter);
      return data;
    },
  });

  const combinedData = React.useMemo(() => {
    if (data && data.costs && data.discounts) {
      const flattenedDiscounts = data.discounts.flat();
      const combined = [...data.costs];

      if (flattenedDiscounts.length > 0) {
        combined.push({});
        combined.push(...flattenedDiscounts);
      }

      return combined.sort((a, b) => {
        if (a.name && b.name) {
          return a.name.localeCompare(b.name);
        }
        return 0;
      });
    }
    return [];
  }, [data]);

  const today = new Date();
  let start = startOfMonth(new Date());
  let end = endOfMonth(new Date());

  const parseDate = (value: string) => {
    if (value) {
      return getFormattedDate(value);
    }
  };

  return (
    <Card>
      <CardHeader title="Engångsavgifter" />
      <Divider />
      <CardContent>
        <Form onSubmit={handleSave} initialValues={filter}>
          {() => (
            <>
              <AutoSave
                debounce={500}
                save={handleSave}
                debounced={['start', 'end']}
              />
              <DatePicker
                label="Från"
                name="start"
                showClearButton
                fieldProps={{ parse: parseDate }}
              />
              <DatePicker
                label="Till"
                name="end"
                showClearButton
                fieldProps={{ parse: parseDate }}
              />

              <StyledButton
                variant="outlined"
                onClick={() => {
                  handleSave({
                    start: format(startOfMonth(start), 'yyyy-MM-dd'),
                    end: format(endOfMonth(end), 'yyyy-MM-dd'),
                  });
                }}
              >
                {' '}
                Denna månaden
              </StyledButton>
              <StyledButton
                onClick={() => {
                  handleSave({
                    start: format(
                      startOfMonth(sub(today, { months: 1 })),
                      'yyyy-MM-dd',
                    ),
                    end: format(
                      endOfMonth(sub(today, { months: 1 })),
                      'yyyy-MM-dd',
                    ),
                  });
                }}
                variant="outlined"
              >
                Förra månaden
              </StyledButton>
              <StyledButton
                onClick={() => {
                  handleSave({
                    start: format(
                      startOfMonth(new Date('2000-01-01')),
                      'yyyy-MM-dd',
                    ),
                    end: format(endOfMonth(today), 'yyyy-MM-dd'),
                  });
                }}
                variant="outlined"
              >
                Alla
              </StyledButton>
            </>
          )}
        </Form>
        {isLoading ? (
          <LinearProgress />
        ) : isError ? (
          <BasicAlert />
        ) : (
          <>
            <SimpleTable rows={combinedData} columns={columns} />
            <div>
              <LabelAndContentRow
                content={
                  <Typography sx={{ fontWeight: 500 }}>
                    {formatValueTotal(data?.totalSum)} {companyCurrency}
                  </Typography>
                }
                label="Total"
              />
            </div>
          </>
        )}
      </CardContent>
    </Card>
  );
};
