import React from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Button, Grid, Typography } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';
import { useQueryClient } from '@tanstack/react-query';
import { FormApi } from 'final-form';
import { Form } from 'react-final-form';
import { Link } from 'react-router-dom';

import { LoadingSpinner } from '@/components/LoadingSpinner';
import { SimpleCard } from '@/components/SimpleCard';
import { SimpleTable } from '@/components/SimpleTable';
import { TextInput } from '@/components/form';
import { getCamelCaseServiceName } from '@/helpers/tsHelpers';
import { Personnummer } from '@/helpers/validators/personnummer';
import { usePrivateSettings, useSnackbar } from '@/hooks';
import { CustomerDTO } from '@/types/customers';
import { ServiceName } from '@/types/services';
import {
  composeValidators,
  fieldIsPersonalNumber,
  fieldRequired,
} from '@/utils/validation';

import { useGetCoProtections } from '../hooks/useGetCoProtections';
import { useGetProtections } from '../hooks/useGetProtections';

type ProtectionItemResponse = {
  '@id': string;
  '@type': ServiceName;
  churnDate: string;
  customer: CustomerDTO;
  id: number;
  status: number;
};

export const SecurityServiceCard = () => {
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();
  const { servicesNames } = usePrivateSettings();
  const [pin, setPin] = React.useState('');

  const {
    data: protections,
    isInitialLoading: loadingprotections,
    isError: protectionsError,
    isSuccess: protectionsSuccess,
  } = useGetProtections(pin);
  const {
    data: coProtections,
    isInitialLoading: loadingCoProtections,
    isError: coProtectionsError,
    isSuccess: coProtectionsSuccess,
  } = useGetCoProtections(pin);

  const loading = loadingprotections || loadingCoProtections;
  const error = protectionsError || coProtectionsError;
  const success = protectionsSuccess && coProtectionsSuccess;

  const foundProtection = protections && protections[0];
  const foundCoProtection = coProtections && coProtections[0];

  const foundItem = foundProtection ? foundProtection : foundCoProtection;

  React.useEffect(() => {
    if (success && !foundItem) {
      snackbar({
        type: 'error',
        message: 'Hittade inga produkter som matchar personnumret.',
      });
    }
  }, [foundItem, success]);

  const columns = [
    {
      label: 'Tjänst',
      renderer: (row: ProtectionItemResponse) =>
        servicesNames[getCamelCaseServiceName(row['@type'])],
    },
    {
      label: 'Genom',
      renderer: ({ customer }: ProtectionItemResponse) =>
        customer?.companyName ? customer.companyName : customer.fullName,
    },
    {
      label: '',

      renderer: (row: ProtectionItemResponse) => (
        <Button
          component={Link}
          color="primary"
          to={`/security-service/barring-service/${row['@type']}/${row.id}`}
          state={{ callerPin: pin }}
        >
          Gå vidare
        </Button>
      ),
    },
  ];

  const status = success && Boolean(foundItem);

  const onSubmit = ({ pin }: { pin: string }) => {
    const longFormatPin = Personnummer.parse(pin).format(true);

    setPin(longFormatPin);
  };

  const handleReset = (
    form: FormApi<{ pin: string }, Partial<{ pin: string }>>,
  ) => {
    setPin('');
    queryClient.resetQueries({
      queryKey: ['findProduct'],
    });
    form.resetFieldState('pin');
  };

  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit, form }) => (
        <Grid item md={6} xs={12}>
          <form onSubmit={handleSubmit}>
            <SimpleCard
              title="Spärrservice"
              cardContent={
                <>
                  <StyledInputDescription variant="body1">
                    Ange <strong>personnummer</strong> på den som ringer in.
                  </StyledInputDescription>

                  <TextInput
                    label="YYYYMMDDXXXX"
                    name="pin"
                    fieldProps={{
                      validate: composeValidators(
                        fieldRequired,
                        fieldIsPersonalNumber,
                      ),
                    }}
                    InputProps={{
                      endAdornment:
                        loading || !success ? null : (
                          <InputAdornment position="end">
                            <StyledServiceStatusCircleIcon isSuccess={status} />
                          </InputAdornment>
                        ),
                    }}
                    onKeyDown={() => (success || error) && handleReset(form)}
                  />
                  {loading && <LoadingSpinner />}
                  {success && foundItem && (
                    <StyledBox>
                      <SimpleTable columns={columns} rows={[foundItem]} />
                    </StyledBox>
                  )}
                </>
              }
              cardActions={
                <Button color="primary" type="submit" variant="outlined">
                  Sök
                </Button>
              }
            />
          </form>
        </Grid>
      )}
    </Form>
  );
};

const StyledServiceStatusCircleIcon = styled(CheckCircleIcon, {
  shouldForwardProp: (prop) => prop !== 'isSuccess',
})<{
  isSuccess: boolean;
}>`
  color: ${({ theme, isSuccess }) =>
    isSuccess === true ? theme.palette.success.main : theme.palette.error.main};
`;

const StyledBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const StyledInputDescription = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));
