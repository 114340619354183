import React from 'react';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import { Box, IconButton, Link, Tooltip } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Link as RouterLink } from 'react-router-dom';

import { Pagination } from '@/components/Pagination';
import { CustomerCategoryTypes } from '@/constants';
import { getFormattedDate } from '@/helpers/dates';
import { renderServiceIcons } from '@/helpers/render';
import useUser from '@/hooks/useUser';
import { ListWithLoading } from '@/modules/accountManagerSystem/components';
import { Toolbar } from '@/modules/accountManagerSystem/components/Toolbar';
import { ApiCollectionResponse } from '@/types/common';
import httpClient from '@/utils/httpClient';

type StatisticsCustomer = {
  '@id': string;
  '@type': 'Customer';
  id: number;
  category: number;
  companyName: string;
  accountManager: {
    '@id': string;
    '@type': 'User';
    name: string;
  };
  createdAt: string;
  fullName: string;
  companyCategory: boolean;
  serviceTypes: {
    firetext: boolean;
    protection: boolean;
    'co-protection': boolean;
    sticker: boolean;
    'key-tag': boolean;
    homegate: boolean;
    sinne: boolean;
  };
  totalARR: string;
};

type Column = {
  label: string;
  renderer: (row: StatisticsCustomer) => React.ReactNode;
};

export const columns: Column[] = [
  {
    label: 'Kundnummer',
    renderer: (row) => {
      return row.id;
    },
  },
  {
    label: 'Företagsnamn',
    renderer: (row) => (row.companyCategory ? row.companyName : ''),
  },
  {
    label: 'Inträdesdatum',
    renderer: (row) =>
      row.createdAt ? getFormattedDate(row.createdAt, 'yyyy/MM/dd') : '',
  },
  {
    label: 'Tjänster',
    renderer: (row) => renderServiceIcons(row),
  },
  {
    label: 'ARR',
    renderer: (row) => row.totalARR,
  },
  {
    label: '',
    renderer: (row) => (
      <div>
        <Link component={RouterLink} to={`/customer/${row.id}`}>
          <IconButton color="primary" size="large">
            <ArrowForwardIcon />
          </IconButton>
        </Link>
        <Link
          target="_blank"
          href={`/customer/${row.id}`}
          onClick={(e) => e.stopPropagation()}
        >
          <Tooltip title="Öppna i ny flik">
            <IconButton color="primary" size="large">
              <LaunchOutlinedIcon />
            </IconButton>
          </Tooltip>
        </Link>
      </div>
    ),
  },
];

export const CustomerOverviewList = () => {
  const { id } = useUser();

  const [page, setPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(100);
  const [searchParams, setSearchParams] = React.useState({
    query: '',
    type: 'standard',
  });
  const {
    data,
    isLoading: loadingCustomers,
    isError,
  } = useQuery({
    queryKey: ['amsStatistics', id, searchParams, page, perPage],
    queryFn: async () => {
      const { query, type } = searchParams;
      const standardSearch = type === 'standard';
      const endpoint = standardSearch ? '/customers' : '/customers/advanced';
      const searchType = standardSearch ? 'search' : 'q';
      const { data } = await httpClient.get<
        ApiCollectionResponse<StatisticsCustomer>
      >(endpoint, {
        params: {
          accountManager: id.toString(),
          [searchType]: query,
          type,
          page: page + 1,
          perPage,
          properties: {
            id: true,
            accountManager: {
              name: true,
            },
            totalARR: true,
            companyName: true,
            companyCategory: true,
            createdAt: true,
            serviceTypes: true,
            category: CustomerCategoryTypes.COMPANY,
            fullName: true,
          },
        },
      });

      return {
        customers: data['hydra:member'],
        total: data['hydra:totalItems'],
      };
    },
    initialData: { customers: [], total: 0 },
  });

  return (
    <>
      <ListWithLoading<StatisticsCustomer>
        columns={columns}
        loading={loadingCustomers}
        error={isError}
        title="Mina kunder"
        rows={data.customers}
      >
        <Box>
          <Toolbar
            searchHandler={(v: any) => setSearchParams(v)}
            paperComponent={false}
          />
        </Box>
      </ListWithLoading>
      <Pagination
        count={data.total}
        page={page}
        rowsPerPage={perPage}
        onPageChange={(_: any, page: number) => setPage(page)}
        onRowsPerPageChange={(e) => setPerPage(parseInt(e.target.value, 10))}
      />
    </>
  );
};
