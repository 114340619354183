import {
  SERVICE_STATUS_CHART_COLOR_MAP,
  StatusesToDisplay,
} from '../constants/SERVICE_STATUS_CHART_COLOR_MAP';

type ChartData = {
  label: StatusesToDisplay;
  data: number[];
  fill: boolean;
};

export function enhanceChartDataWithColors(data: ChartData[]) {
  return data.map((item) => {
    const color = SERVICE_STATUS_CHART_COLOR_MAP[item.label];

    return {
      ...item,
      backgroundColor: color,
      borderColor: color,
    };
  });
}
