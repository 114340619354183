import { UserItem } from '@/types/users';

export function prepareKamIdOptions(
  users?: Pick<
    UserItem,
    '@id' | 'id' | 'name' | 'username' | 'email' | 'roles' | 'deleted'
  >[],
) {
  if (users) {
    return users.map((user) => user?.id?.toString());
  }
  return [];
}
