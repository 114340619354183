import React from 'react';

import {
  Alert,
  Box,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Typography,
} from '@mui/material';

import { SimpleTable } from '@/components/SimpleTable';
import { useBulkRegistrationState } from '@/hooks/useBulkRegistrationState';
import { useGetKeyTagBulkRegisteringResult } from '@/modules/services/components/KeyTag/hooks/useGetKeyTagBulkRegisteringResult';

export const KeyTagBulkServiceRegistrationResults = () => {
  const { bulkRegistrationState } = useBulkRegistrationState();

  const [refetch, setRefetch] = React.useState(true);

  const { serviceRegistrationUuid, serviceRegistrationStatus } =
    bulkRegistrationState;

  const { data, error } = useGetKeyTagBulkRegisteringResult(
    serviceRegistrationUuid,
    refetch,
  );

  React.useEffect(() => {
    if (error) {
      setRefetch(false);
    }
  }, [error]);

  const firstDataRow = data?.[0];

  React.useEffect(() => {
    if (firstDataRow && data[0]?.uuid === serviceRegistrationUuid) {
      setRefetch(false);
    }
  }, [data, serviceRegistrationUuid, firstDataRow]);

  const summaryColumns = [
    {
      label: 'Antal rader',
      renderer: (row: typeof firstDataRow) => row?.rowCount,
    },
    {
      label: 'Antal registrerade',
      renderer: (row: typeof firstDataRow) => row?.insertedRows,
    },
    {
      label: 'Antal ickeregistrerade',
      renderer: (row: typeof firstDataRow) => row?.notInsertedRows,
    },
    {
      label: 'Antal fel',
      renderer: (row: typeof firstDataRow) => row?.errors.length,
    },
  ];

  if (serviceRegistrationStatus === 'complete' && firstDataRow) {
    return (
      <Box>
        <Typography variant="h5" gutterBottom>
          Registreringen är nu klar
        </Typography>

        <SimpleTable columns={summaryColumns} rows={[firstDataRow]} />
        {/* File processing errors */}
        {Boolean(data[0]?.errors.length) && (
          <List
            dense
            subheader={
              <ListSubheader disableGutters>
                Felmeddelanden från registreringen:
              </ListSubheader>
            }
            sx={{
              '& .MuiListItemText-primary': {
                color: (theme) => theme.palette.error.main,
              },
            }}
          >
            {data[0]?.errors.map((error) => (
              <ListItem key={error.pin} dense>
                <ListItemText primary={error.pin} secondary={error.error} />
              </ListItem>
            ))}
          </List>
        )}
      </Box>
    );
  }

  if (serviceRegistrationStatus === 'failed') {
    return (
      <Alert severity="error">
        Något gick fel vid registrering av tjänsterna.
      </Alert>
    );
  }

  return null;
};
