import { z } from 'zod';
import zodToJsonSchema from 'zod-to-json-schema';

import { createApiPropertiesFromJsonSchema } from '@/helpers/createApiPropertiesFromJsonSchema';
import { StickerItem, StickersResponse } from '@/modules/sticker';
import httpClient from '@/utils/httpClient';

export async function checkStickersForCustomer({
  customerId,
}: {
  customerId: string | number;
}) {
  const schema = z.object({
    billingMonth: z.number(),
  });

  const collection = z.array(schema);

  const properties = createApiPropertiesFromJsonSchema(zodToJsonSchema(schema));

  const { data } = await httpClient.get<StickersResponse<'billingMonth'>>(
    '/stickers',
    {
      params: {
        'customer.id': customerId,
        status: 1,
        'pricePlanId[gt]': 0,
        properties,
      },
    },
  );

  return collection.parse(data['hydra:member']);
}

export async function createStickerWithCode(payload: unknown) {
  const { data } = await httpClient.post<StickerItem>('/stickers', payload);

  return data;
}

export type GetStickerParams = {
  customerPin?: string;
  page?: number;
  perPage?: number;
  churns?: boolean | null;
  code?: string;
  sortOrder?: 'asc' | 'desc';
};
export async function getStickers({
  customerPin,
  page = 1,
  perPage = 100,
  churns = null,
  code,
  sortOrder,
}: GetStickerParams) {
  const properties = {
    id: true,
    churnDate: true,
    status: true,
    code: true,
    ['@type']: true,
    customer: {
      id: true,
      fullName: true,
      companyName: true,
      pin: true,
    },
  };
  const { data } = await httpClient.get<
    StickersResponse<keyof typeof properties>
  >('/stickers', {
    params: {
      'order[churnDate]': churns !== null ? sortOrder : undefined,
      'exists[churnDate]': churns !== null ? !!churns : undefined,
      status: churns ? 1 : null,
      'customer.pin': customerPin,
      pagination: true,
      page,
      perPage,
      code,
      properties,
    },
  });

  return data;
}
