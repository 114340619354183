import { useQuery } from '@tanstack/react-query';

import { PartnerStatisticsParams, getPartnerStatistics } from '@/api/partners';
import { generateKeys } from '@/constants/queryKeys';
import { getObjectKeys } from '@/helpers/tsHelpers';
import { PartnerStatisticsResponse } from '@/types/partnerCompany';

export const partnerStatisticsQueryKey = generateKeys('getPartnerStatistics');

type NullabelPartnerStatisticsParams = {
  [K in keyof PartnerStatisticsParams]: PartnerStatisticsParams[K] | null;
};

export const useGetPartnerStatistics = (
  params: NullabelPartnerStatisticsParams,
) => {
  return useQuery({
    queryKey: partnerStatisticsQueryKey.filteredList(params),
    queryFn: async () =>
      getPartnerStatistics(params as PartnerStatisticsParams),
    enabled: getObjectKeys(params).every((key) => Boolean(params[key])),
    initialData: {} as PartnerStatisticsResponse,
  });
};
