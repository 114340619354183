import { Card, CardHeader } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { getInvoiceList } from '@/api/invoice';
import { Alert } from '@/components/Alert';
import { CustomerBilling } from '@/components/Billing';
import { queryKeys } from '@/constants/queryKeys';
import { getElementFromArray } from '@/helpers/getElementFromArray';
import { NextBillingDto } from '@/types/billing';

type Props = {
  companyCustomer: boolean;
};

export function NextBilling({ companyCustomer }: Props) {
  const { id: customerId } = useParams();

  const { data, isLoading, isError } = useQuery({
    queryKey: ['customer', customerId, queryKeys.getInvoiceList],

    queryFn: async () => {
      const data = await getInvoiceList<NextBillingDto>({
        customerId: parseInt(customerId!, 10),
      });

      const list = Object.keys(data.list).map((entry) => {
        return {
          customerNumber: entry,
          fees: [...getElementFromArray(data.list[entry])],
        };
      });

      const returned = {
        list,
        ...data.stats,
      };

      return returned;
    },
  });

  return (
    <Card>
      <CardHeader title="Nästa fakturering" />
      {isLoading ? (
        <LinearProgress />
      ) : isError ? (
        <Alert severity="error">Något gick fel.</Alert>
      ) : (
        <>
          {data && (
            <CustomerBilling
              companyCustomer={companyCustomer}
              invoiceList={data}
            />
          )}
        </>
      )}
    </Card>
  );
}

NextBilling.propTypes = {};
