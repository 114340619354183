import React from 'react';

import { Box, Grid, LinearProgress, Paper, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { getSinnesToConfigure } from '@/api/sinne';
import { BasicAlert } from '@/components/BasicAlert';
import { Pagination } from '@/components/Pagination';
import { SimpleTable } from '@/components/SimpleTable';
import { queryKeys } from '@/constants/queryKeys';
import { usePrivateSettings } from '@/hooks';
import { SearchField } from '@/modules/common/components';
import { useSinneTableConfig } from '@/modules/services/components/Configuration/components/useSinneTableConfig';

export const Configuration = () => {
  const { servicesStatuses } = usePrivateSettings();

  const [debouncedQueryString, setDebouncedQueryString] = React.useState('');
  const [total, setTotal] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(100);

  const tableColumns = useSinneTableConfig({
    servicesStatuses,
    serviceName: 'sinne',
    isReplacementFlow: true,
  });

  const { isError, data, isFetching, refetch } = useQuery({
    queryKey: [
      queryKeys.getSinnesToConfigure,
      debouncedQueryString,
      page,
      perPage,
    ],

    queryFn: async () => {
      const data = await getSinnesToConfigure({
        page: page + 1,
        perPage,
        search: debouncedQueryString,
        status: [10],
      });
      const total = data['hydra:totalItems'];
      const products = data['hydra:member'];
      setTotal(total);
      return products;
    },
  });

  const handlePageChange = (_: any, page: number) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event: { target: { value: string } }) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    setPerPage(rowsPerPage);
  };

  return (
    <Box>
      <Grid
        component={Paper}
        style={{ padding: '1rem', marginBottom: '0.5rem' }}
        container
      >
        <Grid item xs={12} sm={6}>
          <SearchField setDebouncedQueryString={setDebouncedQueryString} />
        </Grid>
      </Grid>

      {isFetching ? (
        <LinearProgress />
      ) : isError ? (
        <BasicAlert />
      ) : total === 0 ? (
        <Paper style={{ padding: '2rem' }}>
          <Typography>Inget resultat</Typography>
        </Paper>
      ) : (
        tableColumns && (
          <>
            <SimpleTable columns={tableColumns} rows={data} />
            <Paper square>
              <Pagination
                count={total}
                rowsPerPage={perPage}
                page={page}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
              />
            </Paper>
          </>
        )
      )}
    </Box>
  );
};
