import { SvgIcon, SvgIconProps } from '@mui/material';

export const SearchIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 96 96" width="96px" height="96px">
      <g id="surface196201418">
        <path d="M 41 12 C 25.019531 12 12 25.019531 12 41 C 12 56.980469 25.019531 70 41 70 C 47.914062 70 54.265625 67.558594 59.257812 63.5 L 78.878906 83.121094 C 79.632812 83.90625 80.75 84.21875 81.800781 83.945312 C 82.851562 83.671875 83.671875 82.851562 83.945312 81.800781 C 84.21875 80.75 83.90625 79.632812 83.121094 78.878906 L 63.5 59.257812 C 67.558594 54.265625 70 47.914062 70 41 C 70 25.019531 56.980469 12 41 12 Z M 41 18 C 53.738281 18 64 28.261719 64 41 C 64 47.207031 61.550781 52.8125 57.582031 56.941406 C 57.335938 57.121094 57.121094 57.335938 56.941406 57.582031 C 52.8125 61.550781 47.207031 64 41 64 C 28.261719 64 18 53.738281 18 41 C 18 28.261719 28.261719 18 41 18 Z M 41 18 " />
      </g>
    </SvgIcon>
  );
};
