import { StatsRawData } from '@/types/stats';

export function getStatsSortedByNetto(data: StatsRawData) {
  const sortedEntries = Object.entries(data).sort(([, dataA], [, dataB]) => {
    const nettoA = dataA['Netto'] || 0;
    const nettoB = dataB['Netto'] || 0;
    return nettoB - nettoA;
  });

  const sortedObject: StatsRawData = {};
  sortedEntries.forEach(([key, value]) => {
    sortedObject[key] = value;
  });

  return sortedObject;
}
