import React from 'react';

import {
  Button,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { getCampaigns } from '@/api/campaigns';
import { ConfirmationDialog } from '@/components/ConfirmationDialog';
import {
  ChosenFilesList,
  FileUploadInputButton,
} from '@/components/FileUpload';
import { TemplateParamsList } from '@/components/TemplateParamsList';
import { Checkboxes, Dropdown } from '@/components/form';
import { CampaignAutoComplete } from '@/components/form/CampaignAutocomplete/CampaignAutoComplete';
import { queryKeys } from '@/constants/queryKeys';
import { useGetCampaignServiceOptions } from '@/hooks/useGetCampaignServiceOptions';
import { servicesWithCampaigns } from '@/types/services';

import { FormValues } from './WelcomeLetter';

type Props = {
  handleSubmit: () => void;
  submitFn: () => void;
  values: FormValues;
  loading: boolean;
};

export const Form = ({ handleSubmit, submitFn, values, loading }: Props) => {
  const matches = useMediaQuery('(max-width:959px)');
  const campaignServiceOptions = useGetCampaignServiceOptions();

  const [showConfirmDialog, setShowConfirmDialog] = React.useState(false);

  const { data: campaignsWithWelcomeLetter, isLoading: loadingCampaigns } =
    useQuery({
      queryKey: [queryKeys.getCampaigns, values.entityType, 'campaignBuilder'],

      queryFn: async () => {
        const data = await getCampaigns(values.entityType, {
          pagination: false,
          properties: { id: true, name: true, welcomeLetterUri: true },
        });

        return data.reduce((acc, arr) => {
          if (arr.welcomeLetterUri) {
            acc.push(arr['@id']);
          }
          return acc;
        }, [] as string[]);
      },

      enabled: !!values?.entityType,
    });

  const service = servicesWithCampaigns.safeParse(
    values?.entityType?.split('_')?.[0],
  );

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (campaignsWithWelcomeLetter?.includes(values.entityIri)) {
            setShowConfirmDialog(true);
          } else {
            handleSubmit();
          }
        }}
      >
        <CardHeader
          title="Välkomstbrev"
          subheader="Ladda upp välkomstbrev till en kampanj. Filen ska vara i formatet .docx"
          action={
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading || loadingCampaigns}
            >
              Spara
            </Button>
          }
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={2}
            direction={matches ? 'column-reverse' : 'row'}
          >
            <Grid item xs={12} md={4}>
              <TemplateParamsList />
            </Grid>
            {!matches && (
              <Grid item>
                <Divider orientation="vertical" />
              </Grid>
            )}
            <Grid
              container
              item
              xs={12}
              md
              spacing={2}
              alignContent="flex-start"
            >
              <Grid item xs={12} sm={4}>
                <Dropdown
                  label="Tjänst"
                  name="entityType"
                  fieldProps={{
                    initialValue: campaignServiceOptions?.[0]?.value,
                  }}
                  options={campaignServiceOptions}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                {service.success && (
                  <CampaignAutoComplete
                    service={service.data}
                    name="entityIri"
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                <FileUploadInputButton
                  multiple={false}
                  name="file"
                  buttonText="Välj fil"
                  fullWidth
                  mimeTypes={[
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                  ]}
                  buttonId="welcome-letter-upload"
                />
              </Grid>
              <Grid item xs={12}>
                <ChosenFilesList files={values.file} title="Vald fil" />
              </Grid>
              <Grid style={{ marginLeft: '16px' }}>
                <Typography>Brevhuvud</Typography>
                <Checkboxes
                  name="c5h2Address"
                  options={{
                    label:
                      'Översta delen av brevet (före texten), med plats för namn och annan data',
                    value: true,
                  }}
                  formControlProps={{ margin: 'none' }}
                />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </form>

      <ConfirmationDialog
        isOpened={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        onConfirm={() => {
          submitFn();
          setShowConfirmDialog(false);
        }}
        dialogTitle="Välkomstbrev finns redan på vald kampanj"
        dialogText="Vill du skriva över befintligt välkomstbrev?"
        confirmationButtonText="Skriv över"
      />
    </>
  );
};
