import { Button, Typography } from '@mui/material';

import { SimpleModal } from '@/components/SimpleModal';

import { useDeleteCustomer } from '../hooks/useDeleteCustomer';

type Props = {
  isOpened: boolean;
  onClose: () => void;
  customerId: number;
};

export const CustomerDeleteModal = ({
  isOpened,
  onClose,
  customerId,
}: Props) => {
  const deleteCustomerMutation = useDeleteCustomer();

  return (
    <SimpleModal
      actionButtons={
        <Button
          variant="contained"
          color="error"
          onClick={() => deleteCustomerMutation.mutate(customerId)}
        >
          Ta bort
        </Button>
      }
      content={
        <Typography variant="h5" color="error">
          Är du säker på att du vill ta bort Kunden? Detta kan inte ångras!
        </Typography>
      }
      closeButtonText="Avbryt"
      onClose={onClose}
      open={isOpened}
      title="Bekräfta att du vill ta bort kunden"
    />
  );
};
