import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';

import { getCamelCaseServiceName, getObjectKeys } from '@/helpers/tsHelpers';
import { usePrivateSettings } from '@/hooks';
import { CamelCaseServiceName } from '@/types/services';
import { SupportRetetionStatistics } from '@/types/stats';

type Props = {
  data: SupportRetetionStatistics;
};

export const ServiceTable = ({ data }: Props) => {
  const dataServiceNames = getObjectKeys(data);

  return (
    <Card>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Tjänst</TableCell>
            <TableCell>Räddad</TableCell>
            <TableCell>Hitrate</TableCell>
            <TableCell>Förlorad</TableCell>
            <TableCell>Totalt</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataServiceNames.map((serviceName) => (
            <HandlerDetails
              serviceName={serviceName}
              data={data}
              key={serviceName}
            />
          ))}
        </TableBody>
      </Table>
    </Card>
  );
};

const HandlerDetails = ({
  serviceName,
  data,
}: {
  serviceName: CamelCaseServiceName;
  data: SupportRetetionStatistics;
}) => {
  const theme = useTheme();
  const { servicesNames } = usePrivateSettings();

  return (
    <>
      {data[serviceName].handlers.length > 0 && (
        <TableRow sx={{ backgroundColor: theme.palette.action.hover }}>
          <TableCell sx={{ fontSize: theme.typography.h6 }}>
            {servicesNames[getCamelCaseServiceName(serviceName)]}
          </TableCell>
          <TableCell>
            {data[getCamelCaseServiceName(serviceName)].totalSaved}
          </TableCell>
          <TableCell>
            {data[getCamelCaseServiceName(serviceName)].totalSuccessRate}
          </TableCell>
          <TableCell>
            {data[getCamelCaseServiceName(serviceName)].totalLost}
          </TableCell>
          <TableCell>
            {data[getCamelCaseServiceName(serviceName)].totalLost +
              data[getCamelCaseServiceName(serviceName)].totalSaved}
          </TableCell>
        </TableRow>
      )}

      {data[serviceName].handlers.map((person) => (
        <TableRow key={person.name} hover>
          <TableCell>
            <Typography sx={{ ml: 2 }}>- {person.name}</Typography>
          </TableCell>
          <TableCell>{person.savedCount}</TableCell>
          <TableCell>{person.successRate}</TableCell>
          <TableCell>{person.lostCount}</TableCell>
          <TableCell>{person.lostCount + person.savedCount}</TableCell>
        </TableRow>
      ))}
    </>
  );
};
