import React from 'react';

import { Close, Done, Edit } from '@mui/icons-material';
import {
  Card,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Tooltip,
} from '@mui/material';
import { Box } from '@mui/system';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Form } from 'react-final-form';

import { updateService } from '@/api/services';
import { LabelAndContentRow } from '@/components';
import { TextInput } from '@/components/form';
import { customerInformationTranslations } from '@/constants/Translations';
import { getCamelCaseServiceName, getObjectKeys } from '@/helpers/tsHelpers';
import { useSnackbar } from '@/hooks';
import {
  CoProtectionParamSchema,
  ProtectionParamSchema,
} from '@/types/services';
import Yup, { makeValidation } from '@/utils/validation';

import { CallerInfo } from '../types';

type Service = ProtectionParamSchema | CoProtectionParamSchema;

type Props = {
  service: Service;
  callerInfo: CallerInfo;
};

export const AssistantTable = ({ service, callerInfo }: Props) => {
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();

  const serviceName = getCamelCaseServiceName(service['@type']);

  const [showEdit, setShowEdit] = React.useState(false);

  const updatedProtectedPerson = useMutation({
    mutationFn: (values) =>
      updateService<Service>({
        serviceId: service.id,
        serviceName,
        values,
      }),
    onSuccess: (_, data) => {
      const newData = data as unknown as Service;

      queryClient.setQueryData(
        [`get${service['@type']}`, service.id],
        (oldData: Service) => {
          return {
            ...(oldData as Service),
            ...newData,
          };
        },
      );
      setShowEdit(false);
      snackbar({ type: 'success', message: 'Uppdatering lyckades' });
    },
    onError: () =>
      snackbar({ type: 'error', message: 'Uppdatering misslyckades' }),
    onSettled: () =>
      queryClient.refetchQueries({
        queryKey: [`get${service['@type']}`, service.id],
      }),
  });

  const Actions = ({ resetForm }: { resetForm: () => void }) => {
    if (showEdit) {
      return (
        <>
          <Tooltip title="Avbryt">
            <span>
              <IconButton
                onClick={() => {
                  setShowEdit(false);
                  resetForm();
                }}
                disabled={updatedProtectedPerson.isPending}
                sx={{ p: 1, my: -0.5 }}
              >
                <Close />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Spara">
            <span>
              <IconButton
                type="submit"
                disabled={updatedProtectedPerson.isPending}
                sx={{ p: 1, my: -0.5 }}
              >
                {updatedProtectedPerson.isPending ? (
                  <CircularProgress size={24} />
                ) : (
                  <Done />
                )}
              </IconButton>
            </span>
          </Tooltip>
        </>
      );
    }

    return (
      <Tooltip title="Redigera">
        <IconButton
          onClick={() => setShowEdit(!showEdit)}
          sx={{ p: 1, my: -0.5 }}
        >
          <Edit />
        </IconButton>
      </Tooltip>
    );
  };

  const validationSchema = Yup.object().shape({
    [`name${callerInfo.index}`]: Yup.string(),
    [`phone${callerInfo.index}`]: Yup.number().positive(
      "Telefonnummer innehåller enbart siffror. Includera landskod utan '+' tecken.",
    ),
    [`email${callerInfo.index}`]: Yup.string().email(),
  });

  const validation = makeValidation(validationSchema);

  return (
    <Grid item md={6} xs={12}>
      <Form
        onSubmit={(values) => {
          updatedProtectedPerson.mutate(values);
        }}
        validate={validation}
        keepDirtyOnReinitialize
        render={({ handleSubmit, form }) => (
          <Card component="form" onSubmit={handleSubmit}>
            <CardHeader
              title="Person vi assisterar"
              action={<Actions resetForm={form.reset} />}
            />
            <Divider />
            <Box>
              {getObjectKeys(callerInfo.data).map((value) => (
                <React.Fragment key={value}>
                  <LabelAndContentRow
                    label={customerInformationTranslations[value]}
                    content={
                      showEdit ? (
                        <TextInput
                          name={value + callerInfo.index}
                          fieldProps={{
                            initialValue: callerInfo.data[value],
                            defaultValue: callerInfo.data[value],
                          }}
                          size="small"
                          disabled={callerInfo.disabledFields.includes(value)}
                          sx={{ width: '60%', m: 0 }}
                        />
                      ) : (
                        callerInfo.data[value]
                      )
                    }
                  />
                  <Divider />
                </React.Fragment>
              ))}
            </Box>
          </Card>
        )}
      />
    </Grid>
  );
};
