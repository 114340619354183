import { DialogContent, LinearProgress, Typography } from '@mui/material';

type Props = {
  requestInProgress: boolean;
  customerIdCheckComponent: React.ReactNode;
};
export const KeyTagConfigStep1 = ({
  requestInProgress,
  customerIdCheckComponent,
}: Props) => {
  return (
    <>
      <DialogContent>
        <Typography gutterBottom variant="h5">
          Steg 1: Skanna välkomstbrev
        </Typography>

        {customerIdCheckComponent}
      </DialogContent>

      {requestInProgress && <LinearProgress />}
    </>
  );
};
