import { Helmet } from 'react-helmet';

import { Breadcrumbs } from '@/components/Breadcrumbs';
import { PageHeader } from '@/components/PageHeader';
import { TasksList } from '@/modules/tasks';
import {
  TaskTeamTypes,
  defaultStatuses,
} from '@/modules/tasks/constants/tasksModalsConstants';

const crumbs = [{ label: 'Kundservice' }, { label: 'Onboarding' }];

export const OnboardingTasks = () => {
  const team = TaskTeamTypes.CUSTOMER_SUPPORT;

  const filter = {
    type: ['16'],
    status: defaultStatuses.map((s) => s.value),
  };

  return (
    <>
      <Helmet title="Onboarding | Kundservice" />
      <Breadcrumbs crumbs={crumbs} />

      <PageHeader title="Onboarding" margin />

      <TasksList filter={filter} team={team} />
    </>
  );
};

export default OnboardingTasks;
