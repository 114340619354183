import { getStatsSortedByNetto } from '@/helpers/SortStatsByNetto';
import { getElementFromArray } from '@/helpers/getElementFromArray';
import { getObjectKeys } from '@/helpers/tsHelpers';
import { ServiceName } from '@/types/services';
import { StatsRawData } from '@/types/stats';

type Args = {
  rawData: StatsRawData;
  firstHeaderName: 'Återförsäljare' | 'Säljare' | 'Prisplan';
  serviceName: ServiceName;
};

export type StatsDetailedTableData = {
  headers: string[];
  data: Array<Array<string | number>>;
};

export function prepareDetailedInfoTablesData({
  rawData,
  firstHeaderName,
  serviceName,
}: Args) {
  const sortedData = getStatsSortedByNetto(rawData);

  if (serviceName === 'sticker') {
    const result = getObjectKeys(sortedData).reduce(
      (prev, current) => {
        const brutto = getElementFromArray(sortedData?.[current]?.['Brutto']);
        const loss = getElementFromArray(sortedData?.[current]?.['Bortfall']);
        const churn = getElementFromArray(sortedData?.[current]?.['Churn']);
        const netto = getElementFromArray(sortedData?.[current]?.['Netto']);

        const lossPercent = (loss / brutto) * 100;
        const churnPercent = (churn / brutto) * 100;
        const nettoPercent = (netto / brutto) * 100;

        prev.data.push([
          current,
          brutto,
          `${loss} (${lossPercent.toFixed()}%)`,
          `${churn} (${churnPercent.toFixed()}%)`,
          `${netto} (${nettoPercent.toFixed()}%)`,
        ]);

        return prev;
      },
      {
        headers: [
          firstHeaderName,
          'Brutto',
          'Bortfall (% av brutto)',
          'Churn (% av brutto)',
          'Netto (% av brutto)',
        ],
        data: [],
      } as StatsDetailedTableData,
    );
    return result;
  } else {
    const result = Object.keys(sortedData).reduce(
      (prev, current) => {
        const brutto = getElementFromArray(sortedData?.[current]?.['Brutto']);
        const loss = getElementFromArray(sortedData?.[current]?.['Bortfall']);
        const anger = getElementFromArray(sortedData?.[current]?.['Ånger']);
        const cancelled = getElementFromArray(
          sortedData?.[current]?.['Makulerad'],
        );
        const goodWill = getElementFromArray(
          sortedData?.[current]?.['Goodwill'],
        );
        const churn = getElementFromArray(sortedData?.[current]?.['Churn']);
        const netto = getElementFromArray(sortedData?.[current]?.['Netto']);

        const lossPercent = (loss / brutto) * 100;
        const angerPercent = (anger / brutto) * 100;
        const nettoPercent = (netto / brutto) * 100;
        const cancelledPercent = (cancelled / brutto) * 100;
        const goodWillPercent = (goodWill / brutto) * 100;
        const churnPercent = (churn / brutto) * 100;

        prev.data.push([
          current,
          brutto,
          loss,
          `(${lossPercent.toFixed()}%)`,
          anger,
          `(${angerPercent.toFixed()}%)`,
          cancelled,
          `(${cancelledPercent.toFixed()}%)`,
          goodWill,
          `(${goodWillPercent.toFixed()}%)`,
          churn,
          `(${churnPercent.toFixed()}%)`,
          netto,
          `(${nettoPercent.toFixed()}%)`,
        ]);

        return prev;
      },
      {
        headers: [
          firstHeaderName,
          'Brutto',
          'Bortfall (% av brutto)',
          'Ånger (% av brutto)',
          'Makulerad (% av brutto)',
          'Goodwill (% av brutto)',
          'Churn (% av brutto)',
          'Netto (% av brutto)',
        ],
        data: [],
      } as StatsDetailedTableData,
    );
    return result;
  }
}
