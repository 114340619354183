import React from 'react';

import { TextField, TextFieldProps } from 'mui-rff';

export const TextInput = ({
  name,
  ...props
}: TextFieldProps & { name: string }) => {
  //Set null to empty string
  // https://github.com/final-form/react-final-form/issues/130#issuecomment-425482365
  const identity = (value: string) => value;

  return (
    <TextField
      name={name}
      fieldProps={{ parse: identity }}
      {...props}
      inputProps={{
        'data-testid': name,
      }}
      FormHelperTextProps={{
        // @ts-expect-error - data-testid is not a valid prop
        'data-testid': `${name}HelperText`,
      }}
    />
  );
};
