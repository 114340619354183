import { Button, Grid } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { Form } from 'react-final-form';
import invariant from 'tiny-invariant';

import { TextInput, normalizeToNumbersOnly } from '@/components/form';
import { getElementFromArray } from '@/helpers/getElementFromArray';
import { useSnackbar } from '@/hooks';
import httpClient from '@/utils/httpClient';
import {
  composeValidators,
  fieldIsOrgNumber,
  fieldRequired,
} from '@/utils/validation';

import { WatchListProps } from './WatchListCard';

type BisnodeErrorResponse = Array<{
  CorrelationId: string;
  ErrorCode: 5 | 19 | 20 | 21;
  ErrorText: string;
  Message: string;
}>;

const BISNODE_ERROR_MESSAGES = {
  5: 'Existerar redan',
  19: 'Företagets maximala övervakade antal har uppnåtts',
  20: 'Företaget får inte övervaka',
  21: 'Användaren har ingen aktiv prenumeration',
  404: 'Företaget hittades inte',
};

type Props = {
  watchListProps: WatchListProps;
  protectedPin: string;
  onClose: () => void;
};

export const WatchListForm = ({
  watchListProps,
  protectedPin,
  onClose,
}: Props) => {
  const snackbar = useSnackbar();

  const watchListMutation = useMutation({
    //@ts-expect-error payload and variables type mismatch
    mutationFn: (payload: { monitoredPin: string; protectedPin: string }) =>
      httpClient.post(`rpc/${watchListProps.type}.monitor`, payload),
    onSuccess: (_, { monitoredPin }: { monitoredPin: string }) => {
      snackbar({
        type: 'success',
        message: `Bevakning av ${monitoredPin} ${
          watchListProps.type === 'add' ? 'tillagd' : 'borttagen'
        }.`,
      });
    },
    //@ts-expect-error Error type does not have data and status
    onError: ({ data, status }, { monitoredPin }) => {
      let bisnodeMessage: null | string = null;

      if (data) {
        // TODO: Add Zod parsing when this will be checked on actual errors on prod
        const errors = data as BisnodeErrorResponse;

        const error = getElementFromArray(errors[0]);

        if (status === 404) {
          bisnodeMessage = BISNODE_ERROR_MESSAGES[404];
        } else if (status === 400 || status === 409) {
          bisnodeMessage = BISNODE_ERROR_MESSAGES[error['ErrorCode']];
        } else {
          bisnodeMessage = 'Okänt Bisnode-fel';
        }
      }

      const message = bisnodeMessage
        ? bisnodeMessage
        : `${data?.message}: ${JSON.stringify(data?.errors)}`;

      snackbar({
        type: 'error',
        message: `Bevakning av ${monitoredPin} kunde inte ${
          watchListProps.type === 'add' ? 'läggas till' : 'tas bort'
        }. ${message}
     `,
        autoHide: false,
      });
    },
    onSettled: () => onClose(),
  });

  return (
    <Form
      onSubmit={({ monitoredPin }) => {
        watchListMutation.mutate({
          monitoredPin,
          protectedPin,
        });
      }}
      render={({ handleSubmit }) => (
        <Grid
          component="form"
          container
          item
          spacing={2}
          alignItems="center"
          onSubmit={handleSubmit}
        >
          <Grid item>
            <TextInput
              name="monitoredPin"
              label="Org nummer"
              size="small"
              variant="outlined"
              style={{ margin: 0 }}
              fieldProps={{
                format: (v) => (v ? normalizeToNumbersOnly(v) : ''),
                parse: normalizeToNumbersOnly,
                validate: composeValidators(fieldRequired, fieldIsOrgNumber),
              }}
            />
          </Grid>
          <Grid item>
            <Button
              disabled={watchListMutation.isPending}
              variant="contained"
              size="small"
              color="primary"
              type="submit"
            >
              Spara
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={onClose} variant="outlined" size="small">
              Avbryt
            </Button>
          </Grid>
        </Grid>
      )}
    />
  );
};
