import React from 'react';

import { EditOutlined } from '@mui/icons-material';
import {
  Alert,
  CardContent,
  CardHeader,
  IconButton,
  List,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import pick from 'lodash/pick';
import { useParams } from 'react-router-dom';

import { getCustomerById } from '@/api';
import { LabelAndContentRow } from '@/components';
import { customerInformationTranslations } from '@/constants/Translations';
import { queryKeys } from '@/constants/queryKeys';
import { getObjectKeys } from '@/helpers/tsHelpers';
import { Customer } from '@/types/customers';

import { editCustomerFormSchema } from '../../const/schemas';
import { useGetZodErrors } from '../../hooks/useGetZodErrors';
import { CardWrapper } from '../CardWrapper';
import { EditCustomerModal } from '../EditCustomerModal';

/* Params to fetch from the api */
export const fieldsToFetch = {
  address: true,
  address2: true,
  category: true,
  city: true,
  companyName: true,
  debtCollection: true,
  doNotDisturb: true,
  email: true,
  firstName: true,
  fullName: true,
  id: true,
  invoiceEmail: true,
  lastName: true,
  mobile: true,
  phone: true,
  pin: true,
  zip: true,
};

type CustomerCardInfo = {
  address: string;
  city: string;
  email: string;
  fullName: string;
  mobile: string;
  pin: string;
  zip: string;
};

type Props = {
  customerId?: string;
};

const ValidationErrors = ({ customer }: { customer?: Customer }) => {
  const parsingErrors = useGetZodErrors(customer, editCustomerFormSchema);

  if (!parsingErrors || !customer) return null;
  return (
    <Alert severity="warning">
      {getObjectKeys(parsingErrors).map((field) => {
        return (
          <div key={field}>
            {customerInformationTranslations[
              field as keyof typeof customerInformationTranslations
            ] || field}
            : {parsingErrors[field]}
          </div>
        );
      })}
    </Alert>
  );
};

export const CustomerCard = ({ customerId }: Props) => {
  const { id } = useParams<{ id: string }>();
  const customerIdToUse = customerId || id;

  const { data: customer, status } = useQuery({
    queryKey: [queryKeys.getCustomerById, customerIdToUse, fieldsToFetch],

    queryFn: async () => {
      return getCustomerById(customerIdToUse!, fieldsToFetch);
    },
  });

  const [showEditCustomerModal, setShowEditCustomerModal] =
    React.useState(false);

  const customerInfo: Partial<CustomerCardInfo> = pick(customer, [
    'fullName',
    'pin',
    'address',
    'city',
    'zip',
    'mobile',
    'email',
  ]);

  const cardTitle = 'Kundinformation';

  const successView = (
    <>
      <CardHeader
        style={{
          height: '56px',
          borderBottom: 'solid 1px rgba(0, 0, 0, 0.12)',
        }}
        title={cardTitle}
        subheader={customer?.doNotDisturb && 'Stör ej'}
        subheaderTypographyProps={{ color: 'error' }}
        action={
          <IconButton
            color="primary"
            onClick={() => setShowEditCustomerModal(true)}
            size="large"
            style={{ padding: '5px' }}
          >
            <EditOutlined />
          </IconButton>
        }
      />
      <ValidationErrors customer={customer} />
      <CardContent style={{ padding: '1px 0 0 0' }}>
        <List style={{ padding: 0 }}>
          {getObjectKeys(customerInfo).map((key, i, arr) => {
            return (
              <LabelAndContentRow
                key={i}
                divider={i !== arr.length - 1}
                label={
                  customerInformationTranslations[
                    key as keyof typeof customerInformationTranslations
                  ]
                }
                content={<Typography>{customerInfo[key]}</Typography>}
              />
            );
          })}
        </List>
      </CardContent>
      {customer && (
        <EditCustomerModal
          onClose={() => setShowEditCustomerModal(false)}
          initialValues={customer}
          open={showEditCustomerModal}
        />
      )}
    </>
  );

  return (
    <CardWrapper
      cardTitle={cardTitle}
      status={status}
      successView={successView}
    />
  );
};
