import { atom } from 'jotai';

export const ModalTypes = {
  MAIL_CUSTOMER: 'MAIL_CUSTOMER',
  SMS_CUSTOMER: 'SMS_CUSTOMER',
  CREATE_ADMIN_TASK: 'CREATE_ADMIN_TASK',
  ADD_FEE: 'ADD_FEE',
  ADD_COST: 'ADD_COST',
  CREATE_CUSTOMER: 'CREATE_CUSTOMER',
  CUSTOMER_TASK: 'CUSTOMER_TASK',
  SECURITY_CHECKLIST: 'SECURITY_CHECKLIST',
  UPDATE_REGISTERED_MOBILE: 'UPDATE_REGISTERED_MOBILE',
  UPDATE_REGISTERED_CARD: 'UPDATE_REGISTERED_CARD',
  PRODUCT_AGREEMENTS: 'PRODUCT_AGREEMENTS',
  PRODUCT_KEYTAG_CONFIG: 'PRODUCT_KEYTAG_CONFIG',
  PRODUCT_STICKER_CONFIG: 'PRODUCT_STICKER_CONFIG',
  CONFIGURE_FIRETEXT: 'CONFIGURE_FIRETEXT',
} as const;

export type Modal = {
  modalType: (typeof ModalTypes)[keyof typeof ModalTypes];
  extra?:
    | string
    | {
        modalTitle?: string;
        messageText?: string;
        disable2WayOption?: boolean;
        isProtection?: boolean;
        isCoProtection?: boolean;
        usePhoneNumbers?: boolean;
        serviceId?: string | number;
        phoneNumbers?: string[];
        customerId?: string | number;
        pin?: string;
      };
};

export const modalManagementAtom = atom<Modal | null>(null);
