import { DialogContent, Typography } from '@mui/material';

import { CustomerIdCheck } from '@/modules/services';
import { BasicCustomerData } from '@/types/customers';

import { StickerItem } from '../../types/dto';
import { StickersList } from './StickersList';

type Props = {
  customer: BasicCustomerData;
  stickersCreated: StickerItem[];
  onNextCustomerFound: (customer: BasicCustomerData) => void;
};

// TODO: Add translations
export const Step3 = ({
  customer,
  stickersCreated,
  onNextCustomerFound,
}: Props) => {
  return (
    <>
      <DialogContent>
        <Typography gutterBottom variant="h3">
          Steg 3: Inlagd!
        </Typography>
        <StickersList stickers={stickersCreated} customer={customer} />
        <CustomerIdCheck onCustomerFound={onNextCustomerFound} isFirstStep />
      </DialogContent>
    </>
  );
};
