import findKey from 'lodash/findKey';

import { RoleTypes } from '@/constants/roleTypes';
import { UserRole } from '@/types/auth';
import { checkRolesAccess } from '@/utils/authorization';

type RoleLabelName = (typeof RoleTypes)[keyof typeof RoleTypes];

export function shouldPermissionsBeReadOnly(
  authUserRoles: UserRole[],
  editedUserRoles: UserRole[],
) {
  if (editedUserRoles.includes('ROLE_GODMODE')) {
    return !authUserRoles.includes('ROLE_GODMODE');
  }

  return !checkRolesAccess(authUserRoles, 'users.updatePermissions');
}

export function checkIfSingleRoleShouldBeDisabled(
  authUserRoles: UserRole[],
  roleLabelName: RoleLabelName,
) {
  const roleName = findKey(
    RoleTypes,
    (key) => key === roleLabelName,
  ) as keyof typeof RoleTypes;

  if (roleName === 'ROLE_GODMODE') {
    return !authUserRoles.includes('ROLE_GODMODE');
  }

  return false;
}
