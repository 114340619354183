export const TYPE_DROPDOWN_OPTIONS = [
  {
    id: 0,
    value: 'standard',
    text: 'Standard',
  },
  {
    id: 1,
    value: 'pin',
    text: 'Personnummer',
  },
  {
    id: 2,
    value: 'email',
    text: 'E-Post',
  },
  {
    id: 3,
    value: 'phone',
    text: 'Telefon',
  },
  {
    id: 4,
    value: 'code',
    text: 'Seriekod',
  },
];

export const SORT_DROPDOWN_OPTIONS = [
  {
    id: 1,
    value: { sortField: 'id', sortOrder: 'asc' },
    text: 'Kundnummer (stigande)',
  },
  {
    id: 2,
    value: { sortField: 'id', sortOrder: 'desc' },
    text: 'Kundnummer (fallande)',
  },
  {
    id: 3,
    value: { sortField: 'fullName', sortOrder: 'asc' },
    text: 'Namn (stigande)',
  },
  {
    id: 4,
    value: { sortField: 'fullName', sortOrder: 'desc' },
    text: 'Namn (fallande)',
  },
];
