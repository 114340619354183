import GetAppIcon from '@mui/icons-material/GetApp';
import {
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Skeleton,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import startCase from 'lodash/startCase';

import { BasicAlert } from '@/components/BasicAlert';
import httpClient from '@/utils/httpClient';

type Props = {
  iri: string;
  handleAgreementDownload: (agreement: any) => void;
  downloading: boolean;
};

export const AgreementListItem = ({
  iri,
  handleAgreementDownload,
  downloading,
}: Props) => {
  // TODO: Check what data is actually fetched in Edit.tsx and if agreements are accessible from there.
  // Data fetching may not be needed here.
  // data filter for agreements not working on Homegate
  const { data, isLoading, isError } = useQuery({
    queryKey: [iri],

    queryFn: async () => {
      const { data } = await httpClient.get(iri);
      return data;
    },
  });

  return (
    <ListItem divider>
      {isLoading ? (
        <Skeleton style={{ width: '100%', height: '2rem' }} />
      ) : isError ? (
        <BasicAlert />
      ) : (
        <>
          <ListItemText
            primary={data.fileType === 'document' ? 'Skriftlig fil' : 'Ljudfil'}
            secondary={startCase(data.type)}
          />
          <ListItemText primary={data.name} secondary="Filnamn" />
          <ListItemSecondaryAction>
            <IconButton
              disabled={downloading}
              onClick={() => handleAgreementDownload(data)}
              color="primary"
              size="large"
            >
              <GetAppIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </>
      )}
    </ListItem>
  );
};
