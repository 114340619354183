import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

type Props = {
  setChosenAction: (value: string) => void;
  chosenAction: string;
  chosenPin: string;
  hasPhoneVerification: boolean;
};

export const Start = ({
  setChosenAction,
  chosenAction,
  chosenPin,
  hasPhoneVerification,
}: Props) => {
  const actionOptions = [
    { id: 1, value: 1, text: 'Kontrollera status' },
    {
      id: 2,
      ceaseInterval: 0,
      value: 2,
      text: 'Tillfällig bedrägerispärr (giltig i 14 dagar)',
      disabled: hasPhoneVerification !== true,
    },
  ];

  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="h4">Skyddat personnummer</Typography>
          <TextField
            label="Personnummer"
            disabled
            value={chosenPin}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="h4">Välj åtgärd</Typography>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id={'action'}>Åtgärd</InputLabel>
            <Select
              labelId={'action'}
              label={'Åtgärd'}
              value={chosenAction}
              onChange={(e) => setChosenAction(e.target.value)}
            >
              {actionOptions.map(
                (option) =>
                  option && (
                    <MenuItem
                      key={option.id}
                      value={option.value}
                      disabled={option.disabled}
                    >
                      {option.text}
                    </MenuItem>
                  ),
              )}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  );
};
