import React from 'react';

import { Box } from '@mui/material';

type TabPanelProps = {
  children: React.ReactNode;
  value: number | string;
  index: number | string;
};

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <Box role="tabpanel" hidden={value !== index} {...other}>
      {value === index && children}
    </Box>
  );
};
