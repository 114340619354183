import { SvgIcon, SvgIconProps } from '@mui/material';

export const AddUserIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 96 96" width="96px" height="96px">
      <g id="surface196116449">
        <path d="M 34 4 C 22.988281 4 14 12.988281 14 24 C 14 35.011719 22.988281 44 34 44 C 45.011719 44 54 35.011719 54 24 C 54 12.988281 45.011719 4 34 4 Z M 34 10 C 41.765625 10 48 16.234375 48 24 C 48 31.765625 41.765625 38 34 38 C 26.234375 38 20 31.765625 20 24 C 20 16.234375 26.234375 10 34 10 Z M 70 48 C 57.851562 48 48 57.851562 48 70 C 48 82.148438 57.851562 92 70 92 C 82.148438 92 92 82.148438 92 70 C 92 57.851562 82.148438 48 70 48 Z M 12.445312 52 C 8.339844 52 5 55.570312 5 59.957031 L 5 63 C 5 69.5625 8.390625 75.265625 14.550781 79.054688 C 19.734375 82.246094 26.640625 84 34 84 C 38.378906 84 42.863281 83.351562 46.957031 82.019531 C 46.035156 80.257812 45.328125 78.371094 44.828125 76.402344 C 41.433594 77.46875 37.675781 78 34 78 C 22.921875 78 11 73.304688 11 63 L 11 59.957031 C 11 58.894531 11.664062 58 12.445312 58 L 46.949219 58 C 48.097656 55.792969 49.558594 53.777344 51.265625 52 Z M 70 54 C 71.105469 54 72 54.894531 72 56 L 72 68 L 84 68 C 85.105469 68 86 68.894531 86 70 C 86 71.105469 85.105469 72 84 72 L 72 72 L 72 84 C 72 85.105469 71.105469 86 70 86 C 68.894531 86 68 85.105469 68 84 L 68 72 L 56 72 C 54.894531 72 54 71.105469 54 70 C 54 68.894531 54.894531 68 56 68 L 68 68 L 68 56 C 68 54.894531 68.894531 54 70 54 Z M 70 54 " />
      </g>
    </SvgIcon>
  );
};
