import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import { showErrorOnBlur } from 'mui-rff';
import { Form } from 'react-final-form';

import { Checkboxes } from '@/components/form/Checkboxes';
import { PinInput } from '@/components/form/PinInput';
import { TextInput } from '@/components/form/TextInput';
import { RoleTypes, rolesOptions } from '@/constants/roleTypes';
import { useAuth } from '@/hooks/useAuth';
import { UserItem } from '@/types/users';
import Yup, { makeValidation } from '@/utils/validation';

const schema = Yup.object().shape({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  username: Yup.string().required(),
  email: Yup.string().email().required(),
  password: Yup.string().required(),
  passwordConfirmation: Yup.string().test(
    'passwords-match',
    'Lösenorden måste överensstämma',
    function (value) {
      return this.parent.password === value;
    },
  ),
});

const validate = makeValidation(schema);

type FormValues = {
  firstName: string;
  lastName: string;
  username: string;
  pin: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  roles: (keyof typeof RoleTypes)[];
};

type Props = {
  opened: boolean;
  onClose: () => void;
  onSubmit: (values: Partial<UserItem>) => void;
};

export const CreateUserModal = ({ onClose, onSubmit, opened }: Props) => {
  const { getAuthenticatedUserRoles } = useAuth();
  const authUserRoles = getAuthenticatedUserRoles();

  function onAddNewUser(values: FormValues) {
    const payload = {
      name: `${values.firstName} ${values.lastName}`,
      pin: values.pin,
      username: values.username,
      email: values.email,
      plainPassword: values.password,
      roles: values.roles,
      level: 0,
      startPage: '',
    };

    onSubmit(payload);
  }

  return (
    <Dialog
      fullWidth
      onClose={onClose}
      aria-labelledby="add-user-dialog"
      maxWidth="md"
      open={opened}
    >
      <Form
        onSubmit={onAddNewUser}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <DialogTitle id="add-user-dialog">
              Skapa en ny Användare
            </DialogTitle>

            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs>
                  <TextInput
                    name="firstName"
                    required
                    label="Förnamn"
                    // inputProps={{ 'data-testid': 'first-name' }}
                  />
                </Grid>

                <Grid item xs>
                  <TextInput
                    name="lastName"
                    required
                    label="Efternamn"
                    // inputProps={{ 'data-testid': 'last-name' }}
                  />
                </Grid>

                <Grid item xs style={{ whiteSpace: 'pre-wrap' }}>
                  <PinInput required={false} fullWidth />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs>
                  <TextInput
                    name="username"
                    required
                    label="Användarnamn"
                    // inputProps={{ 'data-testid': 'username' }}
                  />
                </Grid>

                <Grid item xs>
                  <TextInput
                    name="email"
                    type="email"
                    required
                    label="E-post"
                    // inputProps={{ 'data-testid': 'email' }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs>
                  <TextInput
                    name="password"
                    type="password"
                    required
                    label="Lösenord"
                    // inputProps={{ 'data-testid': 'password' }}
                  />
                </Grid>

                <Grid item xs>
                  <TextInput
                    name="passwordConfirmation"
                    type="password"
                    required
                    showError={showErrorOnBlur}
                    label="Lösenordsbekräftelse"
                    // inputProps={{ 'data-testid': 'password-confirm' }}
                  />
                </Grid>
              </Grid>

              <Grid container>
                <Checkboxes
                  name="roles"
                  label="Rättigheter"
                  options={rolesOptions
                    .filter((role) => {
                      if (role === 'ROLE_GODMODE') {
                        return (authUserRoles || []).includes('ROLE_GODMODE');
                      }

                      return true;
                    })
                    .map((roleKey) => ({
                      label: RoleTypes[roleKey],
                      value: roleKey,
                    }))}
                />
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button onClick={onClose} variant="outlined" type="button">
                Avbryt
              </Button>
              <Button color="primary" variant="outlined" type="submit">
                Skapa Användare
              </Button>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};
