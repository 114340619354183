import React from 'react';

import { RemoveCircleOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Skeleton,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { BasicAlert } from '@/components/BasicAlert';
import { Pagination } from '@/components/Pagination';
import { SimpleTable } from '@/components/SimpleTable';
import { PureDropdown } from '@/components/form';
import {
  renderCustomerServiceRowActions,
  renderServiceIcons,
} from '@/helpers/render';
import { useSnackbar } from '@/hooks';
import { Toolbar } from '@/modules/accountManagerSystem/components/Toolbar';
import { QUERY_KEYS } from '@/modules/accountManagerSystem/helpers';
import { EnhancedAMSCustomer } from '@/types/customers';
import { AccountManager } from '@/types/users';
import httpClient from '@/utils/httpClient';

// TODO: Move this to toolbar component
type SearchParam = {
  query: string;
  type: 'standard' | 'pin' | 'email' | 'phone' | 'code';
};

type Props = {
  accountManagers: AccountManager[] | undefined;
  loadingAccountManagers: boolean;
  errorAccountManager: boolean;
};

export const AssignCustomers = ({
  accountManagers,
  loadingAccountManagers,
  errorAccountManager,
}: Props) => {
  const navigate = useNavigate();

  const snackbar = useSnackbar();

  const queryClient = useQueryClient();
  const [accountManager, setAccountManager] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(100);
  const [customersToAssign, setCustomersToAssign] = React.useState<string[]>(
    [],
  );
  const [searchParams, setSearchParams] = React.useState<SearchParam>({
    query: '',
    type: 'standard',
  });

  // TODO: Improve after API is updated
  const {
    data,
    isLoading: loadingCustomers,
    isError: errorCustomers,
  } = useQuery({
    queryKey: [QUERY_KEYS.FETCH_CUSTOMERS, page, perPage, searchParams],

    queryFn: async () => {
      const standardSearch = searchParams.type === 'standard';
      const endpoint = standardSearch ? '/customers' : '/customers/advanced';
      const searchParam = standardSearch ? 'search' : 'q';
      const { type, query } = searchParams;

      const params = {
        [searchParam]: query,
        type,
        page: page + 1,
        perPage,
        properties: {
          accountManager: {
            id: true,
            name: true,
          },
          companyCategory: true,
          companyName: true,
          fullName: true,
          id: true,
          serviceTypes: true,
          totalARR: true,
        },
      };

      const { data } = await httpClient.get(endpoint, {
        params,
      });

      return {
        customers: data['hydra:member'],
        total: data['hydra:totalItems'],
      };
    },
  });

  // TODO: Improve after API is updated
  const assignMutation = useMutation({
    mutationFn: ({
      managedCustomers,
      accountManager,
    }: {
      managedCustomers: string[];
      accountManager: string;
    }) =>
      httpClient.put(accountManager, {
        managedCustomers,
      }),
    onSuccess: () => {
      setCustomersToAssign([]);

      snackbar({
        type: 'success',
        message: 'Ändringar sparade.',
      });

      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.FETCH_CUSTOMERS, page, perPage, searchParams],
        exact: true,
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.AMS_CUSTOMERS_FROM],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.AMS_CUSTOMERS_TO],
      });
      queryClient.invalidateQueries({
        queryKey: ['accountManagers'],
      });
    },
    onError: () => {
      snackbar({
        type: 'error',
        message: 'Något gick fel.',
      });
    },
  });

  const addHandler = ({
    customersToAssign,
    accountManager,
    accountManagers,
  }: {
    customersToAssign: string[];
    accountManager: string;
    accountManagers: AccountManager[];
  }) => {
    const foundManager = accountManagers.find(
      (manager) => manager['@id'] === accountManager,
    );

    if (foundManager) {
      const currentManagedCustomers = foundManager.managedCustomers.map(
        (customer) => customer['@id'],
      );
      const managedCustomers =
        currentManagedCustomers.concat(customersToAssign);
      assignMutation.mutate({ managedCustomers, accountManager });
    } else {
      console.error('No account manager found');
    }
  };

  const removeHandler = ({
    customerToRemove,
    accountManager,
    accountManagers,
  }: {
    customerToRemove: string;
    accountManager: string;
    accountManagers: AccountManager[];
  }) => {
    const foundManager = accountManagers.find(
      (manager) => manager['@id'] === accountManager,
    );

    if (foundManager) {
      const managedCustomers = foundManager.managedCustomers
        .filter((customer) => customer['@id'] !== customerToRemove)
        .map((customer) => customer['@id']);

      assignMutation.mutate({ managedCustomers, accountManager });
    } else {
      console.error('No account manager found');
    }
  };

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    setPerPage(rowsPerPage);
  };

  const handleToggle = (value: string) => () => {
    const currentIndex = customersToAssign.indexOf(value);
    const newChecked = [...customersToAssign];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCustomersToAssign(newChecked);
  };

  const columns = [
    {
      label: 'Välj',
      renderer: (row: EnhancedAMSCustomer) => (
        <Checkbox
          color="primary"
          checked={customersToAssign.includes(row['@id'])}
          onClick={handleToggle(row['@id'])}
        />
      ),
    },
    { label: 'Kundnummer', renderer: (row: EnhancedAMSCustomer) => row.id },
    {
      label: 'Företag',
      renderer: (row: EnhancedAMSCustomer) =>
        row.companyCategory ? row.companyName : '',
    },
    { label: 'Namn', renderer: (row: EnhancedAMSCustomer) => row.fullName },
    {
      label: 'Tjänster',
      renderer: (row: EnhancedAMSCustomer) => (
        <Box display="flex" justifyContent="space-around">
          {renderServiceIcons(row)}
        </Box>
      ),
    },
    {
      label: 'Account Manager',
      renderer: (row: EnhancedAMSCustomer) => (
        <>
          {row.accountManager ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="body2">{row.accountManager.name}</Typography>
              <Tooltip title="Ta bort account manager">
                <IconButton
                  onClick={() =>
                    removeHandler({
                      customerToRemove: row['@id'],
                      accountManager: row.accountManager['@id'],
                      accountManagers: accountManagers || [],
                    })
                  }
                  size="large"
                >
                  <RemoveCircleOutlined />
                </IconButton>
              </Tooltip>
            </Box>
          ) : (
            'Saknas'
          )}
        </>
      ),
    },
    { label: 'Arr', renderer: (row: EnhancedAMSCustomer) => row.totalARR },
    {
      label: '',
      renderer: (row: EnhancedAMSCustomer) =>
        renderCustomerServiceRowActions(row, navigate),
    },
  ];

  const SaveButton = styled(Button)({
    marginTop: '1rem',
    marginBottom: '0.5rem',
  });

  return (
    <Box>
      {loadingAccountManagers ? (
        <Skeleton />
      ) : errorAccountManager ? (
        <BasicAlert />
      ) : (
        <>
          <Box padding={2} marginBottom={2} component={Paper}>
            <Grid spacing={2} container>
              <Grid item xs={12} sm={6}>
                <PureDropdown
                  label="Välj account manager"
                  value={accountManager}
                  labelId="from"
                  onChange={(e: any) => setAccountManager(e.target.value)}
                  options={accountManagers!.map((manager) => ({
                    id: manager['@id'],
                    value: manager['@id'],
                    text: manager.name,
                  }))}
                />
              </Grid>
              <Grid container item xs={12} sm={6}>
                <SaveButton
                  color="primary"
                  disabled={
                    !accountManager ||
                    customersToAssign.length === 0 ||
                    assignMutation.isPending
                  }
                  variant="contained"
                  onClick={() =>
                    addHandler({
                      customersToAssign,
                      accountManagers: accountManagers || [],
                      accountManager,
                    })
                  }
                >
                  Tilldela
                </SaveButton>
              </Grid>
            </Grid>
          </Box>
          <Box marginBottom={2}>
            {/* @ts-ignore */}
            <Toolbar searchHandler={(v: SearchParam) => setSearchParams(v)} />
          </Box>

          {loadingCustomers ? (
            <LinearProgress />
          ) : errorCustomers ? (
            <BasicAlert />
          ) : data && data.total > 0 ? (
            <>
              <SimpleTable rows={data?.customers || []} columns={columns} />
              <Pagination
                count={data?.total || 0}
                page={page}
                rowsPerPage={perPage}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
              />
            </>
          ) : (
            <Typography variant="h1">Ingen data</Typography>
          )}
        </>
      )}
    </Box>
  );
};
