import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { SimpleTable } from '@/components/SimpleTable';
import { InvoiceItem, InvoiceList } from '@/types/billing';

const RowTextContent = styled('div')`
  margin-top: ${({ theme }) => theme.spacing(0.5)};
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
`;

type Props = {
  invoiceList: InvoiceList;
  companyCustomer: boolean;
};

export function CustomerTopTable({ invoiceList, companyCustomer }: Props) {
  const rows = invoiceList?.list || [];

  const renderSeveralRowsUtil = (rows: string[] | number[]) => {
    return rows.map((row, index) => (
      <RowTextContent key={`${row}-${index}`}>{row}</RowTextContent>
    ));
  };

  const feesRenderer = (row: InvoiceItem) => {
    if (!row.fees) {
      return null;
    }
    const feeNames = row.fees.map((fee) => fee.title || ' ');
    return renderSeveralRowsUtil(feeNames);
  };

  const priceRenderer = (row: InvoiceItem) => {
    if (!row.fees) {
      return null;
    }
    const feePrices = row.fees.map((fee) => {
      return companyCustomer ? fee.price : fee.priceWithSalesTax;
    });
    return renderSeveralRowsUtil(feePrices);
  };

  const periodRenderer = (row: InvoiceItem) => {
    if (!row.fees) {
      return null;
    }

    const feeDescriptions = row.fees.map((fee) => fee.description || ' ');
    return renderSeveralRowsUtil(feeDescriptions);
  };

  const columns = [
    { label: 'Avgifter', renderer: feesRenderer },
    {
      label: companyCustomer ? 'Pris (exkl moms)' : 'Pris (inkl moms)',
      renderer: priceRenderer,
    },
    { label: 'Beskrivning', renderer: periodRenderer, alignTop: true },
  ];

  return <SimpleTable columns={columns} rows={rows} />;
}

CustomerTopTable.propTypes = {
  invoiceList: PropTypes.object,
};
