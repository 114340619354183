import { getObjectKeys } from '@/helpers/tsHelpers';

import { usePrivateSettings } from './usePrivateSettings';

export const useChurnFiretextReasonOptions = () => {
  const { churnReasons } = usePrivateSettings();

  return getObjectKeys(churnReasons.firetext).map((key) => ({
    value: parseInt(key, 10),
    text: churnReasons.firetext[key],
  }));
};
