import { Card, CardContent, Divider } from '@mui/material';

import { NumberedListItem } from '@/components/NumberedListItem';

import { ChecklistTitle, ListTitle } from '../../commonStyles';

// First tab for CoProtection Checklist
export const FirstCoTab = () => {
  return (
    <Card>
      <ChecklistTitle variant="h6">
        Mina Sidor - Kund undrar över meddelande på Händelser
      </ChecklistTitle>
      <Divider />
      <CardContent>
        <ListTitle variant="h6">
          Antal Folkbokförda på din adress har ökat/minskat och är nu X
          personer. Tidigare var det Y personer.
        </ListTitle>
        <NumberedListItem
          number={1}
          text={
            'Tjänsten du har bevakar antal folkbokförda personer på din adress. När det sker en ändring på antalet folkbokförda personer så får du ett larm om detta. Vi rekommenderar dig att gå in på skatteverkets hemsida och kontrollera med BankID vilka som är folkbokförda på din adress just nu om du inte känner till ändringen. \n\nOm du misstänker att någon otillåten har folkbokfört sig på din adress efter att du kontrollerat hos skatteverket, rekommenderar vi att du anmäler händelsen direkt till Polisen samt Skatteverket'
          }
        />
        <ListTitle variant="h6">
          Ditt skuldsaldo har ökat och är nu XXX kr.
        </ListTitle>
        <NumberedListItem
          number={1}
          text={
            'Larm skickas när skulderna ökar eller när nya skulder fastställs av Kronofogden.\n\nLarm skickas även löpande då skulden ökar i takt med att räntan på skulderna ökar.\n\nBe kund logga in med BankID på Mina sidor. Där ser kund om det finns några skulder som växer. Och vilka företag som kund har betalningsanmärkningar hos.\nKund kan sedan kontakta dessa företag direkt eller kronofogden för mer information.'
          }
        />
        <ListTitle variant="h6">Det har tillkommit en varningssignal</ListTitle>
        <NumberedListItem
          number={1}
          text={
            'Be kund kontakta Dun & Bradstreets kundservice (tidigare Bisnode). Vi kan inte i detalj se vad som skapat just denna varning för kunds företag.'
          }
        />

        <ListTitle variant="h6">Ett nytt bokslut har inkommit.</ListTitle>
        <NumberedListItem
          number={1}
          text={'Bolagsverket har registrerat att ett nytt bokslut inkommit.'}
        />
        <ListTitle variant="h6">Att göra:</ListTitle>
        <NumberedListItem
          number={1}
          text={'Skriv in information kring konversationen på Kundnoteringar'}
        />
      </CardContent>
    </Card>
  );
};
