import React from 'react';

import {
  Box,
  Button,
  LinearProgress,
  TextField,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';

import { KEY_TAG_AND_STICKER_CODE_VALIDATION_REGEX } from '@/modules/services/constants';
import { BasicCustomerData } from '@/types/customers';

import { useCreateSticker } from '../../api-hooks/useCreateSticker';
import { useFetchStickers } from '../../api-hooks/useFetchStickers';
import { useGetStickerPricePlanIds } from '../../hooks/useGetStickerPricePlanIds';
import { StickerItem } from '../../types/dto';
import { getStickerConfigStep2ErrorMessage } from '../../utils/getStickerConfigStep2ErrorMessage';

type Props = {
  onGoBack: () => void;
  onFinish: (createdStickers: StickerItem[]) => void;
  customer: BasicCustomerData;
};

type ReturnedStickerItem = Required<Pick<StickerItem, 'billingMonth'>>;

export const Step2Upsale = ({ onGoBack, onFinish, customer }: Props) => {
  const fetchStickers = useFetchStickers();
  const createSticker = useCreateSticker();
  const { getPricePlanId } = useGetStickerPricePlanIds();

  const [errorMessage, setErrorMessage] = React.useState('');

  const [existingSticker, setExistingSticker] =
    React.useState<ReturnedStickerItem | null>(null);
  const [noBillingMonth, setNoBillingMonth] = React.useState(false);
  const [stickersCreated, setStickersCreated] = React.useState<StickerItem[]>(
    [],
  );

  //#region Effects
  React.useEffect(() => {
    if (stickersCreated.length > 0) {
      onFinish(stickersCreated);
    }
  }, [onFinish, stickersCreated]);

  React.useEffect(() => {
    if (customer?.id) {
      checkCustomerStickers(customer.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);
  //#endregion

  //#region Async functions
  async function checkCustomerStickers(customerId: string | number) {
    fetchStickers.mutate(
      { customerId },
      {
        onSuccess: (data) => {
          const existingSticker = data[0] ?? null;

          setExistingSticker(existingSticker);
          setNoBillingMonth(!existingSticker?.billingMonth);
        },
        onError: () => {
          // TODO: Add translations
          setErrorMessage('Något gick fel');
        },
      },
    );
  }

  async function createStickerHandler(
    code: string,
    parentService: ReturnedStickerItem,
  ) {
    const { billingMonth } = parentService;
    const regDate = format(new Date(), 'yyyy-MM-dd');

    // TODO: Add translations
    const payload = {
      code,
      billingMonth,
      status: 1,
      regDate,
      billing: 1,
      kamId: 0,
      tb: 0,
      adminNote: 'Utskickad genom uppsälj välkomstbrev',
      pricePlanId: getPricePlanId(0),
      customer: `/customers/${customer.id}`,
    };

    createSticker.mutate(payload, {
      onSuccess: (data) => {
        setStickersCreated((prevState) => [...prevState, data]);
      },
      onError: (error: any) => {
        const message = getStickerConfigStep2ErrorMessage(error);
        setErrorMessage(message);
      },
    });
  }
  //#endregion

  //#region Functions

  function handleSubmit(e: React.SyntheticEvent) {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      code: { value: string };
    };
    const {
      code: { value },
    } = target;

    if (value && existingSticker) {
      if (KEY_TAG_AND_STICKER_CODE_VALIDATION_REGEX.test(value)) {
        setErrorMessage('');
        createStickerHandler(value, existingSticker);
      } else {
        // TODO: Add translations
        setErrorMessage('Fel kodformat');
      }
    }
  }
  //#endregion

  const isLoading = createSticker.isPending || fetchStickers.isPending;

  if (isLoading) {
    return <LinearProgress />;
  }

  // TODO: Add translations
  return (
    <form onSubmit={handleSubmit} noValidate autoComplete="off">
      <Typography gutterBottom variant="h5">
        Steg 2: Skanna välkomstbrev
      </Typography>

      <section>
        <Typography gutterBottom>
          Kund:{' '}
          {customer.companyCategory ? customer.companyName : customer.fullName}
        </Typography>
      </section>

      {!existingSticker || noBillingMonth ? (
        <Typography>
          Kund betalar inte för aktiv dekal sen tidigare. Undersök fel.
        </Typography>
      ) : (
        <>
          <TextField
            autoFocus
            label="Serienummer"
            name="code"
            margin="normal"
            size="small"
            variant="outlined"
            fullWidth
          />

          {errorMessage && (
            <Typography gutterBottom color="error">
              {errorMessage}
            </Typography>
          )}
        </>
      )}
      <Box display="flex" justifyContent="space-between" marginTop={2}>
        <Button
          type="button"
          color="primary"
          size="small"
          variant="contained"
          onClick={onGoBack}
        >
          Gå tillbaka
        </Button>

        <Button
          type="submit"
          color="primary"
          size="small"
          variant="contained"
          disabled={!existingSticker || isLoading}
        >
          Nästa
        </Button>
      </Box>
    </form>
  );
};
