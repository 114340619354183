import { useRef, useState } from 'react';

import { Alert, Button, LinearProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  InfiniteData,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import {
  CreateNotePayload,
  createNote,
  deleteNote,
  getNotes,
} from '@/api/notes';
import { Note } from '@/components/Note';
import { NoteType } from '@/constants/notes';
import { queryKeys } from '@/constants/queryKeys';
import useUserRoles from '@/hooks/useUserRoles';
import { NotesFilter } from '@/modules/common/components';
import { NoteItem } from '@/types/notes';

import { CustomerNotesForm } from './CustomerNotesForm';

type Props = {
  customerId: number;
  isInSecurityService: boolean;
};

export function CommunicationDrawer({
  customerId,
  isInSecurityService,
}: Props) {
  const notesContainer = useRef<HTMLDivElement>(null);

  const [notesFilter, setNotesFilter] = useState<
    Array<{ value: string; text: string }>
  >([]);
  const [nextPage, setNextPage] = useState<number | undefined>();

  const queryClient = useQueryClient();
  const userRoles = useUserRoles();

  const queryDepencyArr = [
    queryKeys.getNotes,
    notesFilter,
    customerId,
    isInSecurityService,
  ];

  // TODO: Move queries and mutations to hooks
  const getNotesHandler = async ({ pageParam }: { pageParam?: number }) => {
    let types = notesFilter.map(({ value }) => parseInt(value, 10));

    if (isInSecurityService) {
      types = [NoteType.SecurityService];
    }

    const { data } = await getNotes({
      types,
      customerId,
      page: pageParam,
    });

    const matchLastDigit = /(?<digit>[\d]*)$/;

    const nextPage =
      data['hydra:view']?.['hydra:next']?.match(matchLastDigit)?.groups?.digit;

    if (nextPage) setNextPage(parseInt(nextPage, 10));

    return data['hydra:member'];
  };

  const {
    data: notes,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
    error,
  } = useInfiniteQuery({
    queryKey: queryDepencyArr,
    queryFn: ({ pageParam }) => getNotesHandler({ pageParam }),
    getNextPageParam: () => nextPage,
    initialPageParam: 1,
    enabled: !!customerId,
  });

  const createMutation = useMutation({
    mutationFn: (payload: CreateNotePayload) => {
      return createNote(payload);
    },
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: [queryKeys.getNotes, notesFilter],
      });
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (id: string) => deleteNote(id),
    onSuccess: async (_, noteId) => {
      const newData =
        notes?.pages.map((page) =>
          page.filter((note) => note['@id'] !== noteId),
        ) ?? [];

      queryClient.setQueryData(
        queryDepencyArr,
        (oldData: InfiniteData<NoteItem[]> | undefined) => {
          return {
            pages: newData,
            pageParams: oldData?.pageParams || [],
          };
        },
      );
    },
  });

  async function handleNoteDelete(note: NoteItem) {
    deleteMutation.mutate(note['@id']);
  }

  if (!customerId) {
    return <LinearProgress />;
  }
  if (status === 'error') {
    return <Alert severity="error">Misslyckades hämta noteringar</Alert>;
  }

  return (
    <>
      <CustomerNotesForm
        customerId={customerId}
        isInSecurityService={isInSecurityService}
        createNote={createMutation}
      />

      {!isInSecurityService && (
        <NotesFilter value={notesFilter} onChange={setNotesFilter} />
      )}

      <NotesContainer ref={notesContainer}>
        {(isFetching ||
          createMutation.isPending ||
          deleteMutation.isPending) && <LinearProgress />}
        {status === 'success' &&
          notes.pages.map((page) => {
            return page.map((note) => (
              <Note
                note={note}
                key={note['@id']}
                onDelete={handleNoteDelete}
                removalInProgress={false}
              />
            ));
          })}
        <div>
          <LoadMoreButton
            fullWidth
            variant="outlined"
            onClick={() => fetchNextPage()}
            disabled={!hasNextPage || isFetchingNextPage}
          >
            {isFetchingNextPage
              ? 'Laddar...'
              : hasNextPage
                ? 'Hämta fler'
                : 'Slut'}
          </LoadMoreButton>
        </div>
        {error && <Alert severity="error">Kunde inte hämta noteringar</Alert>}
        {createMutation.isError && (
          <Alert severity="error">
            Något gick fel vid skapandet av notering.
          </Alert>
        )}
        {deleteMutation.isError && (
          <Alert severity="error">
            Något gick fel vid borttaggning av notering.
          </Alert>
        )}
      </NotesContainer>
    </>
  );
}

const LoadMoreButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(2, 0, 1, 0),
}));

const NotesContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));
