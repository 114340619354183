import { Button, FormControl } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Field } from 'react-final-form';

import { UploadIcon } from '@/icons';

const FileUploadButtonInput = styled('input')({
  display: 'none',
});

type ButtonProps = {
  component: string;
};

const FileUploadButtonStyle = styled(Button)<ButtonProps>`
  color: ${({ theme }) => theme.palette.primary.main};
  height: 53.633px;
  width: 100%;
`;

type Props = {
  name?: string;
  mimeTypes?: string[];
  multiple?: boolean;
  fullWidth?: boolean;
  buttonText?: string;
};

export const FileUploadButton = ({
  name = 'files',
  multiple = true,
  fullWidth = false,
  buttonText = 'Välj filer',
  mimeTypes,
}: Props) => {
  const allowedExtensions = mimeTypes ?? [
    'application/pdf',
    'audio/m4a',
    'audio/mp3',
    'audio/mpeg',
    'audio/ogg',
    'audio/wav',
    'image/gif',
    'image/jpeg',
    'image/jpg',
    'image/png',
  ];

  const isSupportedFileType = (files: File[]) => {
    return files.length > 0 &&
      Array.from(files).every((file, i) =>
        allowedExtensions.includes(file?.type),
      )
      ? undefined
      : `Filformatet stöds inte`;
  };

  return (
    <Field
      name={name}
      validate={(v) =>
        v !== undefined && v.length > 0 && isSupportedFileType(v)
      }
    >
      {({ input }) => (
        <FormControl fullWidth={fullWidth}>
          <FileUploadButtonInput
            required
            multiple={multiple}
            type="file"
            accept={allowedExtensions.join(',')}
            name={input.name}
            onChange={(e) => input.onChange(Array.from(e.target.files!))}
            id="contained-button-file"
          />
          <label htmlFor="contained-button-file">
            <FileUploadButtonStyle
              size="large"
              startIcon={<UploadIcon />}
              variant="outlined"
              component="span"
            >
              {buttonText}
            </FileUploadButtonStyle>
          </label>
        </FormControl>
      )}
    </Field>
  );
};
