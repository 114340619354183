import React from 'react';

import { Box, Breakpoint, useTheme } from '@mui/material';
import { Helmet } from 'react-helmet';

import { Breadcrumbs } from '@/components/Breadcrumbs';
import { PageHeader } from '@/components/PageHeader';
import { Crumb } from '@/types/crumbs';

type PageWrapperProps = {
  pageTitle: string;
  pageHeader?: string;
  breadCrumbs: Crumb[];
  children: React.ReactNode;
  maxWidth?: Breakpoint;
  margin?: boolean;
};

export const PageWrapper = ({
  pageTitle,
  pageHeader = pageTitle,
  breadCrumbs,
  children,
  maxWidth = 'xl',
  margin = true,
}: PageWrapperProps) => {
  const theme = useTheme();
  const breakpoints = theme?.breakpoints.values[maxWidth];
  return (
    <Box maxWidth={breakpoints}>
      <Helmet title={pageTitle} />
      <Breadcrumbs crumbs={breadCrumbs} />
      <PageHeader margin={margin} title={pageHeader} />
      {children}
    </Box>
  );
};
