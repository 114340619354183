import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  LinearProgress,
} from '@mui/material';

import { BasicAlert } from '@/components/BasicAlert';
import { Pagination } from '@/components/Pagination';
import { SimpleTable } from '@/components/SimpleTable';
import { ACCOUNT_MANAGER_COLUMNS } from '@/modules/accountManagerSystem/const';

import { useFetchAccountManagers } from '../../hooks/useFetchAccountManagers';

export const AllAccountManagers = () => {
  const { data, status, page, perPage, setPage, setPerPage } =
    useFetchAccountManagers();

  const currentView = {
    loading: <LinearProgress />,
    error: <BasicAlert />,
    success: (
      <Card>
        <CardHeader title="Alla Account Managers" />
        <Divider />
        <CardContent>
          <SimpleTable rows={data} columns={ACCOUNT_MANAGER_COLUMNS} />
          <Pagination
            count={data.length}
            page={page}
            rowsPerPage={perPage}
            onPageChange={(_, page) => setPage(page)}
            onRowsPerPageChange={(event) =>
              setPerPage(parseInt(event.target.value, 10))
            }
          />
        </CardContent>
      </Card>
    ),
  };

  return currentView[status];
};
