import {
  Button,
  Grid,
  LinearProgress,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useMutation } from '@tanstack/react-query';

import {
  ActiveFraudProtectionPayload,
  activateFraudProtection,
} from '@/api/fraudProtection';
import { Alert } from '@/components/Alert';
import usePublicSettings from '@/hooks/usePublicSettings';

import { TemporaryFraudProtectionError } from './TemporaryFraudProtectionError';

type Props = {
  pin: string;
  customerId: string;
  hasPhoneVerification: boolean;
};

export const ActivateTemporaryFraudProtection = ({
  pin,
  customerId,
  hasPhoneVerification,
}: Props) => {
  const { publicSettings } = usePublicSettings();

  // TODO: Check type of both error when possible and improve from any
  const mutation = useMutation<any, any, ActiveFraudProtectionPayload>({
    mutationFn: (payload) => {
      return activateFraudProtection(payload);
    },
  });

  const handleActivate = () => {
    const payload = {
      pin,
      customerId,
      ceaseInterval: 0,
    };

    mutation.mutate(payload);
  };

  return (
    <>
      {mutation.isPending && (
        <Grid item xs={12}>
          <Typography mb={2} variant="h2">
            Aktiverar...
          </Typography>
          <LinearProgress />
        </Grid>
      )}

      {!mutation.isSuccess && !mutation.isPending && (
        <Grid item xs={12}>
          <Typography variant="h2" mb={2}>
            Aktiv bedrägerispärr <b>EJ</b> funnen på: {pin}
          </Typography>
          <Typography variant="body1" fontSize="1rem">
            En aktiv bedrägerispärr har <b>EJ</b> hittats på personnummer {pin}.
            {hasPhoneVerification &&
              ' Klicka på aktivera för att aktivera en tillfällig spärr (14-dagar).'}
          </Typography>
        </Grid>
      )}

      {mutation.isSuccess && (
        <>
          <Grid item xs={12}>
            <Alert severity="success">
              <Typography variant="h2" mb={2}>
                Bedrägerispärr aktiverad på personnummer: {pin}!
              </Typography>

              <Typography variant="body1" fontSize="1rem">
                Vänligen läs upp följande text till kunden:
                <br />
                <br />
                Ditt personnummer har spärrats hos Bisnode från idag och två
                veckor framåt. Under den tid som du har en bedrägerispärr kan
                Bisnodes kunder inte hämta någon information om dig och det
                innebär att det blir svårt att exempelvis teckna nya telefon-,
                tv- och elabonnemang samt att ta lån. Med denna spärr hos
                Bisnode spärras du även hos fyra andra kreditupplysningsbolag.
                Syftet med detta är att det ska förenkla för dig som blivit
                utsatt för bedrägeri att spärra dig på en stor del av den
                svenska kreditupplysningsmarknaden med en enda kontakt med oss.
                Denna 14-dagars spärr kommer även aktiveras hos nedanstående
                bolag (utöver Bisnode).
                <br />{' '}
              </Typography>
              <BulletList>
                <ListItem>UC (Upplysningscentralen)</ListItem>
                <ListItem>Creditsafe</ListItem>
                <ListItem>Syna</ListItem>
                <ListItem>Valitive</ListItem>
              </BulletList>
              <Typography variant="body1" fontSize="1rem">
                Härnäst så kommer vi att skicka hem en en broschyr med viktig
                information till dig som har genomfört en bedrägerispärr samt en
                fullmakt. Önskas spärren förlängas i ytterligare fem år måste en
                kopia på polisanmälan (rörande bedrägeri eller försök till
                bedrägeri) och fullmakten skickas till{' '}
                {publicSettings?.companyNames?.standard}.
              </Typography>
            </Alert>
          </Grid>
        </>
      )}

      {mutation.isError && (
        <Grid item xs={12}>
          <TemporaryFraudProtectionError error={mutation.error} />
        </Grid>
      )}

      {hasPhoneVerification && (
        <Grid
          container
          item
          spacing={2}
          justifyContent="flex-start"
          sx={(theme) => ({
            '& > *': {
              marginLeft: theme.spacing(1),
            },
            '& > *:first-child': {
              marginLeft: '0',
            },
            marginTop: theme.spacing(2),
          })}
        >
          {mutation.isIdle && (
            <Tooltip title="Aktivera 14-dagars bedrägerispärr">
              <Button variant="outlined" onClick={handleActivate}>
                Aktivera
              </Button>
            </Tooltip>
          )}
        </Grid>
      )}
    </>
  );
};

const ListItem = styled('li')`
  font-size: 1rem;
`;

const BulletList = styled('ul')`
  margin: 10px 0px;

  & > li {
    margin-left: 18px;
  }
`;
