import React from 'react';

import { Box, Divider } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { getSinneEvents } from '@/api/sinne';
import { LabelAndContentRow } from '@/components';
import { SimpleCard } from '@/components/SimpleCard';
import { getFormattedDate } from '@/helpers/dates';
import { usePrivateSettings } from '@/hooks';

export const EventDataContent = () => {
  const { serviceId } = useParams();
  const { servicesStatuses } = usePrivateSettings();

  const { data } = useQuery({
    queryKey: ['sinne_events_collection', serviceId],

    queryFn: async () => {
      const data = await getSinneEvents({ sinneId: serviceId! });
      const events = data['hydra:member'];

      return events;
    },

    enabled: !!serviceId,
  });

  const alarmEventTypes = servicesStatuses?.sinneEvent?.events;
  const alarmEvents = data?.map((alarm) => {
    const data = {
      type: alarmEventTypes[alarm.event],
      occuredAt: alarm.occuredAt,
    };
    return data;
  });

  const eventsByDescendingOrder = alarmEvents?.sort((a, b) => {
    return new Date(b.occuredAt).getTime() - new Date(a.occuredAt).getTime();
  });

  return (
    <SimpleCard
      title="Händelsehistorik"
      contentPadding={0}
      cardContent={
        <>
          {eventsByDescendingOrder?.map((row) => (
            <Box key={row.type + row.occuredAt}>
              <Box py={2}>
                <LabelAndContentRow
                  compact
                  label="Händelse"
                  content={row.type}
                />
                <LabelAndContentRow
                  compact
                  label="Datum"
                  content={getFormattedDate(
                    row.occuredAt,
                    'yyyy-MM-dd HH:mm:ss',
                  )}
                />
              </Box>
              <Divider />
            </Box>
          ))}
        </>
      }
    />
  );
};
