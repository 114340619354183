import React from 'react';

import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Skeleton,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useForm } from 'react-final-form';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';

import { getAllCampaigns as getCampaign } from '@/api/campaigns';
import { getResellers } from '@/api/reseller';
import { getAccountManagers } from '@/api/users';
import { Alert } from '@/components/Alert';
import {
  DatePicker,
  Dropdown,
  PartnerManagerAutocomplete,
  TextInput,
} from '@/components/form';
import { queryKeys } from '@/constants/queryKeys';
import { USER_TYPES } from '@/modules/common/constants/entitiyNamesMap';
import { formatCurrency } from '@/modules/common/utils/currency';
import { prepareResellerOptions } from '@/modules/services';
import { useGetPriceOptions } from '@/modules/services/hooks/useGetPriceOptions';
import { ServiceName, SinneItem } from '@/types/services';
import { fieldRequired, isValidOption } from '@/utils/validation';

import { CampaignSection } from '../../sections';

type Props = {
  serviceDetails: SinneItem;
  campaign: string;
  isCreateMode: boolean;
};

export const SalesInformationFields = ({
  serviceDetails,
  campaign,
  isCreateMode,
}: Props) => {
  const form = useForm();

  const { serviceName } = useParams<{ serviceName: ServiceName }>();
  invariant(serviceName === 'sinne', 'Service name must be sinne');
  const {
    priceOptions,
    isLoading: isLoadingPriceOptions,
    isSuccess: priceOptionsSuccess,
    isError: priceOptionsError,
  } = useGetPriceOptions({ serviceName, active: true });

  const {
    data: resellers,
    isLoading: isLoadingResellers,
    isSuccess: resellerSuccess,
    isError: resellerError,
  } = useQuery({
    queryKey: [queryKeys.getResellers],
    queryFn: () => getResellers(),
    initialData: { data: [], totalCount: 0 },
  });

  const {
    data: accountManagerOptions,
    isLoading: loadingAccountManagerOptions,
    isSuccess: accountManagerSuccess,
    isError: errorAccountManagerOptions,
  } = useQuery({
    queryKey: [queryKeys.getAccountManagers],

    queryFn: async () => {
      const response = await getAccountManagers();

      if (!!response.length) {
        return response.map((user) => ({
          value: user['@id'],
          text: user.name,
        }));
      }
      return [];
    },
  });

  const {
    data: campaignData,
    isInitialLoading: isLoadingCampaign,
    isSuccess: campaignDataSuccess,
    isError: campaignDataError,
  } = useQuery({
    queryKey: [queryKeys.getAllCampaigns, campaign],

    queryFn: () =>
      getCampaign(campaign, {
        freeMonths: true,
        noticePeriod: true,
        withdrawalPeriodDays: true,
        package: true,
        units: true,
        active: true,
        customerType: true,
      }),

    enabled: !!campaign,
  });

  const isError =
    resellerError ||
    campaignDataError ||
    errorAccountManagerOptions ||
    priceOptionsError;

  const isSuccess =
    resellerSuccess &&
    campaignDataSuccess &&
    accountManagerSuccess &&
    priceOptionsSuccess;

  const isLoading =
    isLoadingCampaign ||
    isLoadingResellers ||
    loadingAccountManagerOptions ||
    isLoadingPriceOptions;

  React.useEffect(() => {
    if (campaignData) {
      form.change('freeMonths', campaignData?.['freeMonths']);
      form.change('noticePeriod', campaignData?.['noticePeriod']);
      form.change('contractMonths', campaignData?.['contractMonths']);
      form.change(
        'withdrawalPeriodDays',
        campaignData?.['withdrawalPeriodDays'],
      );
    }
  });

  if (isError) {
    return (
      <Alert severity="error">
        <Typography variant="h3" color="error">
          Något gick fel. Om problemet kvarstår, kontakta IT.
        </Typography>
      </Alert>
    );
  }

  return (
    <Card>
      <CardHeader title="Sälj" />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">
              Månadsavgift:{' '}
              {formatCurrency(
                serviceDetails.price.priceWithVatInCurrency,
                serviceDetails.price.currency,
              )}{' '}
              (inkl) /{' '}
              {formatCurrency(
                serviceDetails.price.priceInCurrency,
                serviceDetails.price.currency,
              )}{' '}
              (exkl)
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker
              label="Datum"
              name="regDate"
              required
              fieldProps={{
                validate: fieldRequired,
              }}
            />
            <TextInput
              label="Säljare"
              name="salesRep"
              required
              fieldProps={{ validate: fieldRequired }}
            />
            {isLoading ? (
              <Skeleton height={54} />
            ) : (
              isSuccess && (
                <Dropdown
                  label="Återförsäljare"
                  name="reseller"
                  options={prepareResellerOptions(resellers.data)}
                  required
                />
              )
            )}
            <TextInput label="Adresslista" name="addressList" />

            <TextInput label="Täckningsbidrag" name="tb" />
            <TextInput label="Leadkälla" name="leadSource" />
            {isCreateMode && <TextInput label="Placering" name="placement" />}
            {isLoading ? (
              <Skeleton height={54} />
            ) : (
              isSuccess && (
                <Dropdown
                  label="Account Manager"
                  name="accountManager"
                  options={accountManagerOptions!}
                  fieldProps={{
                    validate: isValidOption(accountManagerOptions, isLoading),
                  }}
                />
              )
            )}
            <PartnerManagerAutocomplete />
            <TextInput label="AB Test" name="abTest" />
          </Grid>
          <Grid item xs={12} sm={6}>
            {isLoading ? (
              <Skeleton height={54} />
            ) : (
              <Dropdown
                label="Pris"
                name="price"
                fieldProps={{ initialValue: serviceDetails.price['@id'] }}
                options={priceOptions}
              />
            )}
            <CampaignSection
              service={serviceName}
              customerType={serviceDetails.customer.category}
            />

            {isLoading ? (
              <>
                <Skeleton height={54} />
                <Skeleton height={54} />
              </>
            ) : (
              isSuccess && (
                <>
                  <TextInput
                    label="Startavgift"
                    name="startFee"
                    disabled
                    value={
                      campaignData?.customerType === 2
                        ? serviceDetails.startFee.priceInCurrency
                        : serviceDetails.startFee.priceWithVatInCurrency
                    }
                  />{' '}
                  <TextInput
                    label="Installationsavgift"
                    name="installationFee"
                    disabled
                    value={
                      campaignData?.customerType === 2
                        ? serviceDetails.installationFee.priceInCurrency
                        : serviceDetails.installationFee.priceWithVatInCurrency
                    }
                  />
                </>
              )
            )}
            {isLoading ? <Skeleton height={54} /> : isSuccess && <></>}
            <TextInput
              label={`Info till ${USER_TYPES.admin}`}
              name="adminNote"
            />
          </Grid>
        </Grid>{' '}
      </CardContent>
    </Card>
  );
};
