import { NumberedListItem } from '@/components/NumberedListItem';

import { CustomerProps } from '../../../../../types';
import { ListTitle } from '../../../commonStyles';
import { EmailTemplate } from '../../EmailTemplate';

export function Todo({
  customer,
  companyName,
  emailAddresses,
  phoneNumbers,
  customerEmailAddresses,
}: CustomerProps) {
  return (
    <>
      <ListTitle variant="h6">Att göra:</ListTitle>

      <NumberedListItem
        number={1}
        text={`Ring UC och gör en förlustanmälan av ID-Handling. Nr: 090 - 010 120 30. `}
      />
      <NumberedListItem
        number={2}
        text={`Ring Bisnode och gör en förlustanmälan av ID-Handling. Nr: 08 - 519 01 360`}
      />
      <NumberedListItem
        number={3}
        text={
          <>
            {`Har kund förlorat `}
            <b>Körkort:</b>
            {`
Maila nedan text till kund:

`}
            <EmailTemplate
              customerId={customer.id}
              emailAddresses={customerEmailAddresses}
              template={`Hej,

Följ länken nedan för att göra en förlustanmälan på ditt körkort och beställa ett nytt:
https://korkorttjanster.transportstyrelsen.se/ForlustAnmalan

Mvh,
Spärrlinjen hos ${companyName}
`}
            />
            {`
Har kund förlorat `}
            <b>ID-Kort:</b>
            {`
Vi behöver inte göra något utan kund förlustanmäler själv till Skatteverket.
`}
            {`
Har kund förlorat `}
            <b>Pass:</b>
            {`
Vi behöver inte göra något utan kund sköter detta genom sin Polisanmälan som vi förklarat för kund.
`}
          </>
        }
      />
      <NumberedListItem
        number={4}
        text={
          <>
            {`Ring kund och meddela vilka åtgärder vi genomfört och att de kan kontakta kundtjänst vid övriga frågor på `}
            <b>{phoneNumbers.customer}</b>
            {` eller `}
            <b>{emailAddresses?.customerService}</b>
          </>
        }
      />
    </>
  );
}
