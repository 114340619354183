import React from 'react';

import { Button } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { format, isValid, parseISO } from 'date-fns';
import { Form } from 'react-final-form';
import { Link } from 'react-router-dom';

import * as servicesApi from '@/api/services';
import { DialogActions } from '@/components/DialogActions';
import { Dropdown, TextInput } from '@/components/form';
import { queryKeys } from '@/constants/queryKeys';
import { getFormattedDate } from '@/helpers/dates';
import { usePrivateSettings, useSnackbar } from '@/hooks';
import { Customer } from '@/types/customers';
import { FiretextItem } from '@/types/services';
import Yup, { makeValidation } from '@/utils/validation';

import { DialogContent } from '../helpers';
import { CustomerInfo } from './CustomerInfo';

const validationSchema = Yup.object({
  msisdn: Yup.number().required(),
  icc: Yup.string().required(),
  manufactureDate: Yup.number().required(),
  operatorId: Yup.string().required(),
});

const validate = makeValidation(validationSchema);

type Props = {
  service: FiretextItem;
  customer: Customer;
  goToNextStep: () => void;
};

export const Step1 = ({ service, customer, goToNextStep }: Props) => {
  const snackbar = useSnackbar();

  const queryClient = useQueryClient();
  const { operatorsOptions } = usePrivateSettings();

  const firetextMutation = useMutation({
    mutationFn: (values: any) => {
      const { id, msisdn, icc, manufactureDate, operatorId } = values;

      return servicesApi.updateService({
        serviceName: 'firetext',
        serviceId: id,
        values: {
          msisdn,
          icc,
          manufactureDate,
          operatorId: parseInt(operatorId, 10),
        },
      });
    },
    onSuccess: (_, response) => {
      queryClient.setQueryData(
        [queryKeys.getFiretextById, response.id],
        response,
      );
      goToNextStep();
    },
    onError: () => {
      snackbar({
        type: 'error',
        message: 'Misslyckades med uppdateringen.',
      });
    },
  });

  return (
    <Form
      initialValues={{
        ...service,
        manufactureDate: isValid(parseISO(service.manufactureDate!))
          ? getFormattedDate(service.manufactureDate, 'yyyyMMdd')
          : format(new Date(), 'yyyyMMdd'),
      }}
      onSubmit={(values) => {
        firetextMutation.mutate(values);
      }}
      validate={validate}
      render={({ handleSubmit }) => (
        <form
          onSubmit={handleSubmit}
          noValidate
          style={{
            flex: '1 1 auto',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <DialogContent>
            <Link to={`/customer/${service.customerId}`}>
              <CustomerInfo customer={customer} />
            </Link>

            <TextInput
              type="number"
              label="Telefon"
              name="msisdn"
              fieldProps={{
                InputLabelProps: {
                  shrink: true,
                },
              }}
              required
            />

            <TextInput label="ICC" name="icc" required />

            <TextInput
              type="number"
              label="Tillverkningsdatum"
              name="manufactureDate"
              fieldProps={{
                InputLabelProps: {
                  shrink: true,
                },
              }}
              required
            />

            <Dropdown
              label="Operatör"
              name="operatorId"
              options={operatorsOptions}
              required
            />
          </DialogContent>

          <DialogActions>
            <Button color="primary" variant="contained" onClick={handleSubmit}>
              Gå vidare
            </Button>
          </DialogActions>
        </form>
      )}
    />
  );
};
