import { ArrowForward, LaunchOutlined } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

export const renderCustomerServiceRowActions = (
  row: { id?: number },
  navigate: any,
) => {
  return (
    <>
      <Tooltip title="Öppna i ny flik">
        <LaunchOutlined
          color="primary"
          onClick={(e) => {
            e.stopPropagation();
            window.open(`/customer/${row.id}?tab=SERVICES`);
          }}
          style={{ cursor: 'pointer' }}
        />
      </Tooltip>
      <Tooltip title="Öppna i denna flik">
        <ArrowForward
          color="primary"
          onClick={() => navigate(`/customer/${row.id}?tab=SERVICES`)}
          style={{ cursor: 'pointer' }}
        />
      </Tooltip>
    </>
  );
};
