import { useEffect, useState } from 'react';

import CallMadeIcon from '@mui/icons-material/CallMade';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import { Box, Button, Grid, LinearProgress, Typography } from '@mui/material';
import { captureException } from '@sentry/react';
import { format } from 'date-fns';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';

import * as CustomersApi from '@/api/customers';
import { FindCustomerByPinParamSchema } from '@/api/customers';
import * as ServicesApi from '@/api/services';
import { Breadcrumbs } from '@/components/Breadcrumbs';
import { normalizeToPersonNumberShort } from '@/components/form';
import { CustomerCategoryTypes } from '@/constants';
import { mapDateFieldsValues } from '@/helpers/dates';
import { getCamelCaseServiceName } from '@/helpers/tsHelpers';
import { usePrivateSettings, useSnackbar } from '@/hooks';
import { MultipleProtectionsWarningModal } from '@/modules/common/components';
import getCreateServiceBreadcrumbs from '@/modules/services/components/Create/utils/getCreateServiceBreadcrumbs';
import prepareServiceCreationPayload from '@/modules/services/components/Create/utils/prepareServiceCreationPayload';
import { BasicCustomerData, CreateCustomerPayload } from '@/types/customers';
import { CreateDTOUnion, ServiceName, ServiceParams } from '@/types/services';
import { getCatchErrorMessage } from '@/utils/common';

import { CreateServiceForm } from './CreateServiceForm';
import { CreateHomegateForm } from './Homegate/CreateHomegateForm';
import { CreateSinneForm } from './Sinne/CreateSinneForm';

const CUSTOMER_FIELDS = [
  'category',
  'firstName',
  'lastName',
  'companyName',
  'pin',
  'email',
  'address',
  'address2',
  'zip',
  'city',
  'phone',
  'mobile',
  'bankName',
  'bankAccount',
  'autogiroSource',
  'autogiroAgreement',
  'partnerCompany',
  'partnerDate',
  'accountManager',
] as const;

const REQUEST_STATUSES = {
  IDLE: 'idle',
  IN_PROGRESS: 'inProgress',
  ERROR: 'error',
  SUCCESS: 'success',
};

export const Create = () => {
  const { serviceName } = useParams<{ serviceName: ServiceName }>();
  invariant(serviceName, 'Service name is required');

  const location = useLocation();
  const snackbar = useSnackbar();

  const { servicesNames, invoiceProvider, servicesStatuses, churnReasons } =
    usePrivateSettings();

  invariant(serviceName, 'Service name is required');

  const mappedServiceName =
    servicesNames?.[getCamelCaseServiceName(serviceName)];

  /**
   * TODO: As a possible quality of life update, move all this state to useReducer
   */

  // Customer and service state
  const [customerInfo, setCustomerInfo] = useState<BasicCustomerData>();

  const [tempServiceData, setTempServiceData] = useState<CreateDTOUnion | null>(
    null,
  );
  const [createdService, setCreatedService] = useState<ServiceParams | null>(
    null,
  );
  const [createdCustomer, setCreatedCustomer] =
    useState<CustomersApi.UpdatedCustomerResponse | null>(null);
  const [customerWithActiveProtection, setCustomerWithExistingProtection] =
    useState<FindCustomerByPinParamSchema>();

  // UI State
  const [preselectedCustomerMode, setPreselectedCustomerMode] = useState(false);
  const [showMultipleProtectionsModal, setShowMultipleProtectionsModal] =
    useState(false);

  // API calls state
  const [customerCheckInProgress, setCustomerCheckInProgress] = useState(false);
  const [requestStatus, setRequestStatus] = useState(REQUEST_STATUSES.IDLE);
  const [requestErrorMessage, setRequestErrorMessage] = useState<
    string | undefined
  >();

  // Derived state
  const customer = preselectedCustomerMode ? customerInfo : createdCustomer;

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const customerId = queryParams.get('customerId');

    if (customerId && serviceName === 'sticker') {
      getPreselectedCustomerInfo(customerId);
    }

    if (customerId) {
      setPreselectedCustomerMode(true);
      getPreselectedCustomerInfo(customerId);
    }
  }, [location, serviceName]);

  //#region Async Methods
  async function getPreselectedCustomerInfo(customerId: string) {
    try {
      const customer = await CustomersApi.fetchBasicCustomerData(customerId);

      // @ts-expect-error TODO: remove this after working on better customer typing
      setCustomerInfo((prevState) => {
        const prev = prevState || {};
        return {
          ...prev,
          isCompany: customer.category === CustomerCategoryTypes.COMPANY,
          id: customer.id,
          pin: customer.pin,
        };
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      captureException(error);
    }
  }

  async function addNewServiceToCustomer({
    serviceData,
    customerId,
  }: {
    serviceData: CreateDTOUnion;
    customerId: number;
  }) {
    invariant(serviceName, 'Service name is required');

    const payload = prepareServiceCreationPayload({
      serviceName: serviceName,
      serviceData,
      customerId,
      servicesStatuses,
      churnReasons,
    });

    return await ServicesApi.addService({
      serviceName: getCamelCaseServiceName(serviceName),
      payload,
    });
  }

  // Remove checks with isCompany
  async function handleCreateService({
    serviceFormValues,
    customerFormValues,
    customerInfo,
  }: {
    serviceFormValues: CreateDTOUnion;
    customerFormValues: CreateCustomerPayload;
    customerInfo: BasicCustomerData | undefined;
  }) {
    setRequestStatus(REQUEST_STATUSES.IN_PROGRESS);
    if (customerFormValues.category === CustomerCategoryTypes.PRIVATE) {
      customerFormValues.pin = normalizeToPersonNumberShort(
        customerFormValues.pin,
      );
    }

    let createdService;
    try {
      if (preselectedCustomerMode && customerInfo) {
        // For preselected customer just add new service based on his or her customerId
        invariant(customerInfo.id, 'Customer id is required');
        createdService = await addNewServiceToCustomer({
          serviceData: serviceFormValues,
          customerId: customerInfo.id,
        });
        setCreatedService(createdService);
        setRequestStatus(REQUEST_STATUSES.SUCCESS);
      } else {
        setCustomerCheckInProgress(true);

        const foundCustomer = await CustomersApi.findCustomerByPIN(
          customerFormValues.pin,
        );

        const existingCustomer =
          foundCustomer.length > 0 ? foundCustomer[0] : null;
        setCustomerCheckInProgress(false);

        if (existingCustomer) {
          const updateCustomerData = {
            id: existingCustomer.id,
            ...customerFormValues,
          };

          if (existingCustomer.partnerDate) {
            delete updateCustomerData.partnerDate;
            delete updateCustomerData.partnerCompany;
          }

          if (existingCustomer.accountManager) {
            updateCustomerData.accountManager = `/users/${existingCustomer.accountManager.id}`;
          }

          // If customer already exist, we should update his or her data with updated ones from the form
          const { data: updatedCustomer } =
            await CustomersApi.updateCustomer(updateCustomerData);

          setCreatedCustomer(updatedCustomer);
          // Customer should have only 1 protection, if there is existing one, display warning modal
          if (
            serviceName === 'protection' &&
            existingCustomer?.activeServiceTypes.includes('protection')
          ) {
            setCustomerWithExistingProtection(existingCustomer);
            setTempServiceData(serviceFormValues);
            setShowMultipleProtectionsModal(true);
          } else {
            // If everything is OK, create new service on a given customer
            createdService = await addNewServiceToCustomer({
              serviceData: serviceFormValues,
              customerId: updatedCustomer.id!,
            });

            setCreatedService(createdService);
            setRequestStatus(REQUEST_STATUSES.SUCCESS);
          }
        } else {
          // If no customer with given PIN is found, create new one ad add new service to this customer
          if (customerFormValues.partnerCompany && serviceFormValues.regDate) {
            customerFormValues.partnerDate = format(
              new Date(serviceFormValues.regDate),
              'yyyy-MM-dd',
            );
          }
          if (!customerFormValues.accountManager) {
            customerFormValues.accountManager = null;
          }
          const newCustomer =
            await CustomersApi.createCustomer(customerFormValues);

          setCreatedCustomer(newCustomer);

          createdService = await addNewServiceToCustomer({
            serviceData: serviceFormValues,
            customerId: newCustomer.id,
          });

          setCreatedService(createdService);
          setRequestStatus(REQUEST_STATUSES.SUCCESS);
          setRequestErrorMessage(undefined);
        }
      }
    } catch (error) {
      console.error(error);
      captureException(error);
      const errorMessage = getCatchErrorMessage(error);

      setCustomerCheckInProgress(false);
      setRequestErrorMessage(errorMessage);
      setRequestStatus(REQUEST_STATUSES.ERROR);

      snackbar({
        type: 'error',
        message: 'Det gick inte att lägga till tjänsten',
      });
    }
  }

  //#endregion

  type Values = CreateDTOUnion & Required<CreateCustomerPayload>;

  function handleSubmit(values: Values) {
    values = mapDateFieldsValues(values);

    const customerFormValues = pick(values, CUSTOMER_FIELDS);

    //@ts-expect-error TODO: remove this when we have proper validation and/or create better logic for create service
    // next to impossible to get the typing right here since service data can is a bit different for each service
    const serviceFormValues = omit(values, CUSTOMER_FIELDS) as CreateDTOUnion;

    handleCreateService({
      serviceFormValues,
      customerFormValues,
      customerInfo,
    });
  }

  function resetServiceCreateState() {
    setRequestStatus(REQUEST_STATUSES.IDLE);
    setCreatedService(null);
    setCreatedCustomer(null);
  }

  if (serviceName === 'homegate') {
    return <CreateHomegateForm />;
  }

  if (serviceName === 'sinne') {
    return <CreateSinneForm />;
  }

  return (
    <>
      <Box>
        <Breadcrumbs
          crumbs={getCreateServiceBreadcrumbs(
            serviceName,
            mappedServiceName,
            customer?.id,
          )}
        />

        <Typography variant="h3" component="h1">
          {customer?.id ? 'Befintlig kund' : 'Ny Kund'} - {mappedServiceName}
        </Typography>

        {requestStatus === 'success' ? (
          <>
            <Grid
              container
              direction="row"
              spacing={3}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs={4}>
                {invoiceProvider?.name ? (
                  <Typography>
                    Tjänsten {mappedServiceName} har skapats för kundnr{' '}
                    {customer?.id} i <strong>Monitum</strong> och även
                    registrerats hos <strong>{invoiceProvider?.name}</strong>.
                  </Typography>
                ) : (
                  <Typography>
                    Tjänsten {mappedServiceName} har skapats för kundnr
                    {customer?.id} i <strong>Monitum</strong>.
                  </Typography>
                )}
              </Grid>

              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  component={RouterLink}
                  startIcon={<CallMadeIcon />}
                  to={`/products/${serviceName}/${createdService?.id}/edit`}
                >
                  Tjänst
                </Button>

                <Button
                  color="primary"
                  variant="contained"
                  onClick={resetServiceCreateState}
                  startIcon={<PersonAddOutlinedIcon />}
                >
                  Registrera ny produkt
                </Button>
              </Grid>
            </Grid>
          </>
        ) : preselectedCustomerMode && !customerInfo ? (
          <LinearProgress />
        ) : (
          <CreateServiceForm
            serviceName={serviceName}
            preselectedCustomerMode={preselectedCustomerMode}
            customerInfo={customerInfo}
            customerCheckInProgress={customerCheckInProgress}
            onSubmit={handleSubmit}
            requestInProgress={requestStatus === REQUEST_STATUSES.IN_PROGRESS}
            hasError={requestErrorMessage !== undefined}
            errorMessage={requestErrorMessage}
          />
        )}
      </Box>

      {showMultipleProtectionsModal && customerWithActiveProtection && (
        <MultipleProtectionsWarningModal
          isOpened
          // @ts-expect-error Related to line 301
          customer={customerWithActiveProtection}
          onClose={() => {
            setShowMultipleProtectionsModal(false);
            resetServiceCreateState();
          }}
          onConfirm={async () => {
            setShowMultipleProtectionsModal(false);

            const createdService = await addNewServiceToCustomer({
              serviceData: tempServiceData!,
              customerId: customer!.id as number,
            });

            setCreatedService(createdService);
            setRequestStatus(REQUEST_STATUSES.SUCCESS);
            setRequestErrorMessage(undefined);
          }}
        />
      )}
    </>
  );
};
