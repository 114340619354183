import { Box, Divider, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { format, parseISO } from 'date-fns';

import { usePrivateSettings } from '@/hooks';
import { HandleableAlarmsReturnType } from '@/types/alarms';

type Props = {
  data: HandleableAlarmsReturnType;
};

export const SinneAlarmsHistory = ({ data }: Props) => {
  const { servicesStatuses } = usePrivateSettings();
  const alarmStatuses = servicesStatuses.handleableAlarm.statuses;
  const alarmReasons = servicesStatuses.handleableAlarm.reasons;
  const alarmRemedies = servicesStatuses.handleableAlarm.remedies;

  return (
    <Grid container direction="column">
      {data.items.map((alarm, index) => {
        return (
          <Box key={alarm['@id']}>
            <Grid item>
              <Grid container direction="column">
                <Grid item>
                  <Typography>
                    <Label>Datum:</Label>{' '}
                    {format(parseISO(alarm.occuredAt), 'yyyy-MM-dd HH:mm:ss')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    <Label>Status:</Label> {alarmStatuses[alarm.status]}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    <Label>Anledning:</Label> {alarmReasons[alarm.reason]}
                  </Typography>
                </Grid>
                {alarm.reasonComment && (
                  <Grid item>
                    <Typography>
                      <Label>Anledning kommentar:</Label> {alarm.reasonComment}
                    </Typography>
                  </Grid>
                )}
                <Grid item>
                  <Typography>
                    <Label>Åtgärd:</Label> {alarmRemedies[alarm.remedy]}
                  </Typography>
                </Grid>
                {alarm.remedyComment && (
                  <Grid item>
                    <Typography>
                      <Label>Åtgärd kommentar:</Label> {alarm.remedyComment}
                    </Typography>
                  </Grid>
                )}
              </Grid>
              {index + 1 !== data.items.length && (
                <Divider sx={{ marginY: 2, marginLeft: 0 }} flexItem />
              )}
            </Grid>
          </Box>
        );
      })}
    </Grid>
  );
};

const Label = styled('span')`
  font-weight: 500;
`;
