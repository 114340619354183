import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

import { AccountProfile } from '@/modules/account';

const Content = styled('div')`
  padding: ${({ theme }) => theme.spacing(4)};
`;

export const Account = () => {
  return (
    <Content>
      <Grid container spacing={4}>
        <Grid item lg={4} md={6} xl={4} xs={12}>
          <AccountProfile />
        </Grid>
        <Grid item lg={8} md={6} xl={8} xs={12} />
      </Grid>
    </Content>
  );
};

export default Account;
