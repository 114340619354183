import { AxiosResponse } from 'axios';

export function generateAndClickDownloadExcelLink(
  response: AxiosResponse<Blob, any>,
): string | null {
  try {
    const contentDisposition = response.headers['content-disposition'];

    let fileName = 'faktureringsunderlag.xlsx'; // Default name

    if (contentDisposition) {
      const match = contentDisposition.match(/filename="(.+)"/);
      if (match && match.length > 1) {
        fileName = match[1] as string;
      }
    }

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();

    window.URL.revokeObjectURL(url);

    return fileName;
  } catch (error) {
    console.error('Download link generation failed:', error);
    return null;
  }
}
