import * as Sentry from '@sentry/react';
import { z } from 'zod';
import zodToJsonSchema from 'zod-to-json-schema';

import { createApiPropertiesFromJsonSchema } from '@/helpers/createApiPropertiesFromJsonSchema';
import { getPaginatedPageParams } from '@/modules/common/utils/pagination';
import { AlarmEvent } from '@/types/alarms';
import { ApiCollectionResponse, Paginated } from '@/types/common';
import { CustomerDTO } from '@/types/customers';
import {
  MissingDevicesResponse,
  SinneDeviceHealthCheckResponse,
} from '@/types/sinne';
import httpClient from '@/utils/httpClient';

import {
  FlatSinneDeviceInfo,
  SinneDeviceInfo,
  SinneDeviceSimInfo,
  SinneDeviceSimInfoV2,
  SinneEvent,
  SinneItem,
  SinnesResponse,
} from '../types/services';

export type GetSinneEventParams = {
  sinneId: number | string;
  deviceId?: string;
};

export const getSinneEvents = async ({
  deviceId,
  sinneId,
}: GetSinneEventParams) => {
  const { data } = await httpClient.get<ApiCollectionResponse<SinneEvent>>(
    `/sinne_events`,
    {
      params: { deviceId, ['sinne.id']: sinneId },
    },
  );
  return data;
};

export type GetSinnesParams = {
  customerPin?: string;
  page?: number;
  perPage?: number;
  sortOrder?: 'asc' | 'desc';
  churns?: boolean;
  status?: number[];
  search?: string;
  placement?: string;
};

type GetSinnesToConfigureProps = {
  page: number;
  perPage: number;
  search: string;
  status: number[];
};

export const getSinnesToConfigure = async ({
  page,
  perPage,
  search,
  status,
}: GetSinnesToConfigureProps) => {
  const properties = {
    agreements: {
      id: true,
      fileType: true,
      name: true,
    },
    customer: {
      id: true,
      category: true,
      pin: true,
      companyName: true,
      fullName: true,
    },
    reseller: true,
    status: true,
    regDate: true,
    id: true,
    adminNote: true,
    salesRep: true,
    antiregret: true,
    regretTimeExpired: true,
  };

  const { data } = await httpClient.get('sinnes', {
    params: {
      page,
      perPage,
      'customer.id': search,
      pagination: true,
      unitReturned: false,
      status,
      order: {
        regDate: 'asc',
      },
      properties,
    },
  });
  return data;
};

export async function getSinnes({
  customerPin,
  page = 1,
  perPage = 100,
  sortOrder,
  churns,
  status,
  placement,
}: GetSinnesParams) {
  const properties = {
    id: true,
    churnDate: true,
    status: true,
    placement: true,
    customer: {
      id: true,
      fullName: true,
      companyName: true,
      pin: true,
    },
  };

  const { data } = await httpClient.get<
    SinnesResponse<keyof typeof properties>
  >('/sinnes', {
    params: {
      'order[churnDate]': sortOrder,
      'exists[churnDate]': churns,
      status: status,
      'customer.pin': customerPin,
      pagination: true,
      page,
      perPage,
      properties,
      placement,
    },
  });

  return data;
}

export async function getEvent(iri: string) {
  const { data } = await httpClient.get<AlarmEvent>(iri);
  return data;
}

export type GetSinneDeviceEconomyParams = {
  start?: string | null;
  end?: string | null;
  status?: number;
};

export type SinneDeviceEconomyResponse = {
  allDevices: number;
  inactiveDevices: number;
  totalPrice: number;
  inactiveDevicesValue: number;
  activeDevicesCurrentValue: number;
  currency: string;
};

export const getSinneDeviceEconomy = async ({
  start,
  end,
}: GetSinneDeviceEconomyParams) => {
  const { data } = await httpClient.get('/noclip/sinne/economy', {
    params: {
      start,
      end,
    },
  });
  return data;
};

export async function updateBrokenSinneNoClip(deviceId: string | number) {
  const { data } = await httpClient.get(
    `/noclip/sinne/deviceBroken/${deviceId}`,
  );
  return data;
}

export type GetSinneParams = {
  id: number;
  fieldsToFetch: z.ZodObject<any>;
};

export async function getSinne<ReturnType>({
  id,
  fieldsToFetch,
}: GetSinneParams) {
  const properties = createApiPropertiesFromJsonSchema(
    zodToJsonSchema(fieldsToFetch),
  );

  const { data } = await httpClient.get<SinneItem>(`/sinnes/${id}`, {
    params: {
      properties,
    },
  });

  return fieldsToFetch.parse(data) as ReturnType;
}

export async function getSinneDeviceInfo(id: number) {
  const { data } = await httpClient.get<SinneDeviceInfo>(
    `/sinnes/${id}/device_info`,
  );

  return data;
}
// TODO: When this new endpoint is used instead of the old one, remove the old one and the type SinneDeviceInfo should look like FlatSinneDeviceInfo
export async function newGetSinneDeviceInfo({
  macAddress,
}: {
  macAddress: string;
}) {
  const { data } = await httpClient.get<FlatSinneDeviceInfo>(
    '/sinnes/device-info',
    {
      params: {
        macAddress,
      },
    },
  );

  return data;
}

export async function getSinneDeviceSimcardInfo(id: number) {
  const { data } = await httpClient.get<SinneDeviceSimInfo>(
    `/sinnes/${id}/sim/get`,
  );
  return data;
}

type SimCardArgs = {
  imei: number;
  sinneEntityId?: string;
};
export async function deactivateSimCard({ imei, sinneEntityId }: SimCardArgs) {
  const queryParams = sinneEntityId
    ? `?sinneEntityId=${sinneEntityId}`
    : `?imei=${imei}`;
  const { data } = await httpClient.put(`/sinnes/sim-deactivate${queryParams}`);
  return data;
}

export async function terminateSimCard({ imei, sinneEntityId }: SimCardArgs) {
  const queryParams = sinneEntityId
    ? `?sinneEntityId=${sinneEntityId}`
    : `?imei=${imei}`;
  const { data } = await httpClient.put(`/sinnes/sim-terminate${queryParams}`);
  return data;
}

export async function getSinneKeyFigures() {
  const { data } = await httpClient.get('sinnes/key_figures');
  return data;
}

export async function getSinneDeviceSimcardInfoByImei(imei: number) {
  const { data } = await httpClient.get<SinneDeviceSimInfoV2>('/sinnes/sim', {
    params: { imei },
  });
  return data;
}

export async function getMostRecentSinneCustomer(macAddress: string) {
  const { data } = await httpClient.get<CustomerDTO>('/sinnes/customer', {
    params: { macAddress },
  });
  return data;
}

type DeactivateDeviceResponse = {
  message: string;
};
type DeactivateDeviceInEldesPayload = {
  macAddress: string;
  message: string;
};
export async function deactivateDeviceInEldes(
  payload: DeactivateDeviceInEldesPayload,
) {
  const { data } = await httpClient.post<DeactivateDeviceResponse>(
    '/sinnes/deactivate-device',
    payload,
  );
  return data;
}

export async function getSinneDeviceActiveFaults(deviceId: string) {
  const { data } = await httpClient.get<SinneDeviceHealthCheckResponse>(
    `/noclip/sinne/${deviceId}/active-faults`,
  );

  return data;
}

export async function getMissingSinneDevices(paginationPageDetails: Paginated) {
  const { data } = await httpClient.get<MissingDevicesResponse>(
    `/sinnes/missing-devices`,
    {
      params: getPaginatedPageParams(paginationPageDetails),
    },
  );

  return data;
}

export async function handleLostDevice(data: {
  fee: number; // Withouth VAT
  imei: number;
  macAddress: string;
  note: string;
  rollback_after?: number; // for testing
}) {
  const payload = {
    ...data,
    imei: data.imei.toString(),
  };
  try {
    const { data: response } = await httpClient.post(
      '/handle/lost-device',
      payload,
    );
    return response;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
}
