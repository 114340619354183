import { useMutation } from '@tanstack/react-query';

import {
  GenerateMockedWelcomeLetterParams,
  generateMockedWelcomeLetter,
} from '@/api';
import { useSnackbar } from '@/hooks';
import { GenericHttpError } from '@/types/errors';
import { generatePdfDownloadLink } from '@/utils/pdf';

export const useGenerateMockedWelcomeLetter = () => {
  const snackbar = useSnackbar();
  return useMutation({
    mutationFn: async (payload: GenerateMockedWelcomeLetterParams) => {
      const { data } = await generateMockedWelcomeLetter(payload);
      generatePdfDownloadLink(data, 'Välkomstbrev');
      return data;
    },
    onError: (error: GenericHttpError) => {
      snackbar({
        type: 'error',
        autoHide: false,
        message: `${error.status}: ${error.statusText}`,
      });
    },
  });
};
