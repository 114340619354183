import { useCallback, useEffect, useRef, useState } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
} from '@mui/material';
import debounce from 'lodash/debounce';
import { z } from 'zod';

import { PureDropdown } from '@/components/form';
import { useModal } from '@/hooks/useModal';
import { AddCustomerIcon, SearchIcon } from '@/icons';
import { ModalTypes } from '@/state/modalManagementAtom';
import { CustomerSearchTypes } from '@/types/customers';

import {
  SORT_DROPDOWN_OPTIONS,
  TYPE_DROPDOWN_OPTIONS,
} from '../utils/constants';
import { Filter } from './CustomersTable';

type Props = {
  onFiltersChange: (args: Filter) => void;
};

const OrderSchema = z.object({
  sortField: z.enum(['id', 'fullName']),
  sortOrder: z.enum(['asc', 'desc']),
});

type OrderTypes = z.infer<typeof OrderSchema>;

export const CustomersToolbar = ({ onFiltersChange }: Props) => {
  const { openModal } = useModal();

  const firstRender = useRef(true);

  const [search, setSearch] = useState('');
  const [type, setType] = useState<CustomerSearchTypes>('standard');
  const [order, setOrder] = useState<OrderTypes | string>('');

  const debouncedFiltersChange = useCallback(
    debounce((filterValues: Filter) => {
      onFiltersChange(filterValues);
    }, 750),
    [],
  );

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      const { sortField, sortOrder } = order as OrderTypes;

      debouncedFiltersChange({
        search,
        type,
        sortField,
        sortOrder,
      });
    }
  }, [search, order, type]);

  return (
    <Box>
      <Grid container justifyContent="flex-end" sx={{ marginBottom: 2 }}>
        <Grid item>
          <Button
            color="primary"
            onClick={() => openModal({ modalType: ModalTypes.CREATE_CUSTOMER })}
            startIcon={<AddCustomerIcon />}
            variant="contained"
          >
            Registrera kund
          </Button>
        </Grid>
      </Grid>

      <Box component={Paper} sx={{ padding: 2 }} marginBottom={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <PureDropdown
              labelId="type-select-label"
              inputProps={{
                'data-testid': 'customer-search-type',
              }}
              label="Söktyp"
              value={type}
              onChange={(event) => {
                const target = event.target as HTMLInputElement;

                setType(target.value as CustomerSearchTypes);
              }}
              options={TYPE_DROPDOWN_OPTIONS}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              label="Sök kund"
              variant="outlined"
              inputProps={{
                'data-testid': 'customer-search-query',
              }}
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="clear search"
                      size="small"
                      onClick={() => {
                        setSearch('');
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs />

          <Grid item xs={12} md={3}>
            <PureDropdown
              labelId="order-select-label"
              inputProps={{
                'data-testid': 'customer-search-order',
              }}
              label="Sortera efter"
              value={order}
              onChange={(event) => {
                const target = event.target as HTMLInputElement;

                OrderSchema.parse(target.value) && setOrder(target.value);
              }}
              options={SORT_DROPDOWN_OPTIONS}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
