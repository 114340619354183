import React from 'react';

import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import SettingsIconOutlined from '@mui/icons-material/SettingsOutlined';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Button, Grid, Menu, MenuItem, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { Can } from '@/components/Can';
import { useAuth } from '@/hooks/useAuth';
import { usePrivateSettings } from '@/hooks/usePrivateSettings';
import { StickerConfigModalType } from '@/modules/sticker';
import { OrderFileModalType, OrderFileServices } from '@/types/orderFile';
import { KebabCaseServiceNames } from '@/types/settings';

type Props = {
  onOpenKeyTagConfigModal: () => void;
  onOpenStickerConfigModal: (action: StickerConfigModalType) => void;
  onOpenOrderFileUploadModal: (type: OrderFileModalType) => void;
  serviceName: KebabCaseServiceNames;
};

export const ServiceActionButtons = ({
  onOpenKeyTagConfigModal,
  onOpenStickerConfigModal,
  onOpenOrderFileUploadModal,
  serviceName,
}: Props) => {
  const navigate = useNavigate();

  const { getAuthenticatedUserRoles } = useAuth();
  const userRoles = getAuthenticatedUserRoles();

  const { servicesNames } = usePrivateSettings();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLButtonElement>(
    null,
  );

  const openStickerConfigModal = (type: StickerConfigModalType) => {
    closeStickerConfigMenu();

    onOpenStickerConfigModal(type);
  };

  const openStickerConfigMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const closeStickerConfigMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Grid container justifyContent="flex-end" alignItems="center">
      <ButtonContainer container item justifyContent="space-between">
        {serviceName !== 'sticker' && (
          <Grid item>
            <Can
              userRoles={userRoles!}
              action="services.registration"
              yes={() => (
                <Button
                  color="primary"
                  onClick={() => {
                    navigate('add');
                  }}
                  startIcon={<PersonAddOutlinedIcon />}
                  variant="contained"
                >
                  Registrera produkt
                </Button>
              )}
            />
          </Grid>
        )}

        {OrderFileServices.includes(serviceName) && (
          <Grid item>
            <Can
              userRoles={userRoles!}
              action="services.order_files.upload"
              yes={() => (
                <Button
                  color="primary"
                  onClick={() =>
                    onOpenOrderFileUploadModal(
                      serviceName as OrderFileModalType,
                    )
                  }
                  startIcon={<UploadFileIcon />}
                  variant="contained"
                >
                  Orderfil Uppladdning
                </Button>
              )}
            />
          </Grid>
        )}

        {serviceName === 'key-tag' && (
          <>
            <Grid item>
              <Can
                userRoles={userRoles!}
                action="services.keyTag.bulkRegistration"
                yes={() => (
                  <Button
                    color="primary"
                    onClick={() => {
                      navigate('add-bulk');
                    }}
                    startIcon={<PersonAddOutlinedIcon />}
                    variant="contained"
                  >
                    Bulkregistrering
                  </Button>
                )}
              />
            </Grid>
            <Grid item>
              <Can
                userRoles={userRoles!}
                action="services.configuration"
                yes={() => (
                  <Button
                    color="primary"
                    onClick={onOpenKeyTagConfigModal}
                    startIcon={<SettingsIconOutlined />}
                    variant="contained"
                  >
                    Utskick
                  </Button>
                )}
              />
            </Grid>
          </>
        )}
        {serviceName === 'sticker' && (
          <Grid item>
            <Can
              userRoles={userRoles!}
              action="services.configuration"
              yes={() => (
                <>
                  <Button
                    aria-controls="Sticker config menu"
                    aria-haspopup="true"
                    color="primary"
                    startIcon={<SettingsIconOutlined />}
                    variant="contained"
                    onClick={openStickerConfigMenu}
                  >
                    Utskick
                  </Button>
                  <Menu
                    id="sticker-config-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={closeStickerConfigMenu}
                  >
                    <MenuItem
                      onClick={() => {
                        openStickerConfigModal('newCustomer');
                      }}
                    >
                      {`Nykund ${servicesNames.protection}`}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        openStickerConfigModal('upsale');
                      }}
                    >
                      {`Uppsälj ${servicesNames.protection}`}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        openStickerConfigModal('coProtection');
                      }}
                    >
                      {`Nykund ${servicesNames.coProtection}`}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        openStickerConfigModal('partner');
                      }}
                    >
                      Partnersamarbete
                    </MenuItem>
                  </Menu>
                </>
              )}
            />
          </Grid>
        )}
      </ButtonContainer>
    </Grid>
  );
};

const ButtonContainer = styled(Grid)(({ theme }) => ({
  width: 'auto',
  '& > div': {
    marginLeft: theme.spacing(1),
  },
}));
