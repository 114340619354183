import { useQuery } from '@tanstack/react-query';

import { getArvatoInvoiceMigrationProcess } from '@/api/costs';
import { queryKeys } from '@/constants/queryKeys';

export function useArvatoInvoice() {
  const { data, isLoading, isError } = useQuery({
    queryKey: queryKeys.economy.arvatoInvoiceMigrationProcess(),
    queryFn: async () => {
      const data = await getArvatoInvoiceMigrationProcess();

      return data['hydra:member'];
    },
  });

  return {
    data,
    isLoading,
    isError,
  };
}
