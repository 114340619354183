import { openDB } from 'idb';

const DATABASE_NAME = 'MonitumCrm';
const DATABASE_VERSION = 1;
const STORE_NAME = 'files';

async function openDatabase() {
  return openDB(DATABASE_NAME, DATABASE_VERSION, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME);
      }
    },
  });
}

export async function closeDbConnection() {
  const db = await openDatabase();
  db.close();
}

export async function storeFileInIndexedDB(file: File, fileKey: string) {
  const db = await openDatabase();

  try {
    const arrayBuffer = await file.arrayBuffer(); // Convert the file to ArrayBuffer
    const fileData = {
      buffer: arrayBuffer,
      type: file.type,
      name: file.name,
    };

    db.put(STORE_NAME, fileData, fileKey);
  } catch (error) {
    console.error('Error storing file in IndexedDB:', error);
    // TODO: Handle error
    throw error; // Rethrow or handle the error as needed
  }
}

export async function getFileFromIndexedDB(fileKey: string) {
  const db = await openDatabase();
  const fileData = await db.get(STORE_NAME, fileKey);

  if (fileData) {
    const file = new File([fileData.buffer], fileData.name, {
      type: fileData.type,
    });
    return file;
  }

  return null;
}

export async function clearStoredFile(fileKey: string) {
  const db = await openDatabase();
  await db.delete(STORE_NAME, fileKey);
}
