import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Form } from 'react-final-form';

import { sendMailToCustomer } from '@/api/email';
import { TextInput } from '@/components/form';
import { queryKeys } from '@/constants/queryKeys';
import { usePrivateSettings, useSnackbar } from '@/hooks';
import { useModal } from '@/hooks/useModal';
import usePublicSettings from '@/hooks/usePublicSettings';
import { fieldRequired } from '@/utils/validation';

type Props = {
  modal: {
    extra: {
      customerId: number;
    };
  };
};

export const MailCustomerModal = ({
  modal: {
    extra: { customerId },
  },
}: Props) => {
  const { closeModal } = useModal();
  const snackbar = useSnackbar();

  const { publicSettings } = usePublicSettings();
  const companyName = publicSettings?.companyNames?.standard;

  const { privateSettings } = usePrivateSettings();
  const phoneNumbers = privateSettings?.phoneNumbers;
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: sendMailToCustomer,
    onSuccess: () => {
      snackbar({
        type: 'success',
        message: 'E-postmeddelandet skickades',
      });
      queryClient.refetchQueries({
        queryKey: [queryKeys.getNotes],
      });
    },
    onError: () => {
      snackbar({
        type: 'error',
        message: 'Något gick fel',
      });
    },

    onSettled: () => closeModal(),
  });

  async function onSubmit(values: { message: string }) {
    mutation.mutate({
      customerId,
      message: values.message,
    });
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        message: `Hej



Med vänliga hälsningar,
Kundservice / ${phoneNumbers?.customer}
----
${companyName}
`,
      }}
      render={({ handleSubmit, pristine }) => (
        <StyledDialog onClose={() => closeModal()} open>
          <form onSubmit={handleSubmit}>
            <DialogTitle>Mail till kund</DialogTitle>

            <DialogContent>
              <TextInput
                multiline
                label="Meddelande"
                name="message"
                rows={8}
                fieldProps={{
                  validate: fieldRequired,
                }}
              />
            </DialogContent>
            <DialogActions>
              <StyledButton
                disabled={pristine || mutation.isPending}
                color="primary"
                variant="outlined"
                onClick={handleSubmit}
              >
                Skicka
              </StyledButton>
            </DialogActions>
          </form>
        </StyledDialog>
      )}
    />
  );
};

const StyledDialog = styled(Dialog)(() => ({
  '& .MuiDialog-paper': {
    width: 500,
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(2, 0, 0, 0),
}));
