import { useQuery } from '@tanstack/react-query';

import { fetchUsers } from '@/api/users';
import { availableRoles } from '@/constants/roleTypes';
import useUserRoles from '@/hooks/useUserRoles';

type Props = {
  page: number;
  perPage: number;
  rowsPerPage?: number;
  enabled?: boolean;
};

export const useGetActiveUsers = ({
  page,
  perPage,
  rowsPerPage,
  enabled = true,
}: Props) => {
  const userRoles = useUserRoles();

  return useQuery({
    queryKey: ['users', page, rowsPerPage, perPage],
    queryFn: async () => {
      const data = await fetchUsers({
        page: page + 1,
        perPage: rowsPerPage,
      });

      const users = data['hydra:member'];
      const totalUsers = data['hydra:totalItems'];

      if (!userRoles.includes(availableRoles[9])) {
        return {
          users: users.filter(
            (user) => !user?.roles?.includes(availableRoles[9]),
          ),
          totalUsers,
        };
      }

      return {
        users,
        totalUsers,
      };
    },
    enabled,
    initialData: { users: [], totalUsers: 0 },
  });
};
