import { Service } from '@/modules/common/hooks/useActiveServices';
import {
  ServicesWithPricePlans,
  getCamelServiceName,
} from '@/modules/common/utils/services/getServicesNames';

const getServicePricePlanPrice = (
  row: Service,
  pricePlans: Record<ServicesWithPricePlans, Record<string, number>>,
) => {
  const price =
    pricePlans[getCamelServiceName(row['@type']) as ServicesWithPricePlans][
      row.pricePlanId
    ];

  if (!price) {
    return '0 kr';
  }
  return `${(price * 1.25).toFixed(2)} kr`;
};

export default getServicePricePlanPrice;
