import React from 'react';

import { Breadcrumbs } from '@/components/Breadcrumbs';

const crumbs = [{ label: 'Verktyg' }, { label: 'Utskick' }];

export const SendingsBreadcrumbs = () => {
  return <Breadcrumbs crumbs={crumbs} />;
};

SendingsBreadcrumbs.propTypes = {};
