import CancelPresentationOutlinedIcon from '@mui/icons-material/CancelPresentationOutlined';
import CreditCardOffOutlinedIcon from '@mui/icons-material/CreditCardOffOutlined';
import PhoneDisabledOutlinedIcon from '@mui/icons-material/PhoneDisabledOutlined';
import WelcomeLetterIcon from '@mui/icons-material/RateReviewOutlined';
import { Button, Grid } from '@mui/material';
import { useMutation } from '@tanstack/react-query';

import {
  TemplateProductTypesSchema,
  documentableTemplateDownload,
} from '@/api/files';
import { SimpleCard } from '@/components/SimpleCard';
import { errorHasMessage, getCamelCaseServiceName } from '@/helpers/tsHelpers';
import { useSnackbar } from '@/hooks';
import { DocumentDownloadCard } from '@/modules/services/components/Edit/Documents/DocumentDownloadCard';
import { LegacyWelcomeLetterButton } from '@/modules/services/components/Edit/Documents/LegacyWelcomeLetterButton';
import { GenericHttpError } from '@/types/errors';
import { TemplateTypes } from '@/types/files';
import { ServiceDetailsResponse } from '@/types/services';
import { useDownloadWelcomeLetter } from '@/utils/pdf';

const ERROR_MESSAGE_MAP: Record<string, string> = {
  'Template was not found': 'Kunde inte hitta mallen',
};

function getServerErrorMessage(message: string): string {
  return ERROR_MESSAGE_MAP[message] || message;
}

type DownloadsProps = { serviceDetails: ServiceDetailsResponse };
const DocumentDownload = ({ serviceDetails }: DownloadsProps) => {
  const serviceHasLegacyWelcomeLetter =
    serviceDetails?.['@type'] === 'Firetext' ||
    serviceDetails?.['@type'] === 'Protection';
  const snackbar = useSnackbar();
  const displayError = async (e: GenericHttpError<Blob>) => {
    type Error = { message: string; error: boolean };
    const errorObj = JSON.parse(await e.data.text()) as Error;

    if ('message' in errorObj) {
      snackbar({
        type: 'error',
        message: errorObj.message,
      });
    }
  };

  const displaySuccess = () => {
    snackbar({
      type: 'success',
      message: 'Filnedladdning lyckades!',
    });
  };
  const documentableTemplateMutation = useMutation({
    mutationFn: documentableTemplateDownload,
    onError: displayError,
    onSuccess: displaySuccess,
  });

  const fileDownLoadHandler = (
    templateType: TemplateTypes,
    product: string,
  ) => {
    // Only Sinne and Firetext have a productType that we have to send to the backend
    if (product === 'sinne' || product === 'firetext') {
      const productType = TemplateProductTypesSchema.safeParse(
        serviceDetails?.['@type'].toLocaleLowerCase(),
      );
      if (productType.success) {
        return documentableTemplateMutation.mutate(
          {
            customerId: serviceDetails?.customer?.id!,
            productId: serviceDetails?.id,
            serviceType: getCamelCaseServiceName(serviceDetails?.['@type']),
            productType: productType.data,
            templateType,
          },
          {
            onError: (error) => {
              if (errorHasMessage(error)) {
                snackbar({
                  type: 'error',
                  message: getServerErrorMessage(error.data.message),
                });
              }
            },
          },
        );
      } else {
        snackbar({ message: 'Kunde inte ladda ner filen', type: 'error' });
      }
    }
    return documentableTemplateMutation.mutate(
      {
        customerId: serviceDetails?.customer?.id!,
        productId: serviceDetails?.id,
        serviceType: getCamelCaseServiceName(serviceDetails?.['@type']),
        templateType,
      },
      {
        onError: (error: unknown) => {
          if (errorHasMessage(error)) {
            snackbar({
              type: 'error',
              message: getServerErrorMessage(error.data.message),
            });
          }
        },
      },
    );
  };
  const product = serviceDetails['@type'].toLowerCase();
  const welcomeLetterMutation = useMutation({
    mutationFn: useDownloadWelcomeLetter(),
    onError: displayError,
    onSuccess: displaySuccess,
  });

  return (
    <SimpleCard
      title="Ladda ner"
      cardContent={
        <>
          <Grid style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Button disabled={documentableTemplateMutation.isPending}>
              <DocumentDownloadCard
                onClick={() => fileDownLoadHandler('uncontactable', product)}
              >
                <PhoneDisabledOutlinedIcon style={{ margin: '10px' }} />
                Okontaktbar
              </DocumentDownloadCard>
            </Button>
            <Button disabled={documentableTemplateMutation.isPending}>
              <DocumentDownloadCard
                onClick={() => fileDownLoadHandler('termination_form', product)}
              >
                {' '}
                <CancelPresentationOutlinedIcon style={{ margin: '10px' }} />
                Uppsägningsblankett
              </DocumentDownloadCard>
            </Button>
            <Button disabled={documentableTemplateMutation.isPending}>
              <DocumentDownloadCard
                onClick={() =>
                  fileDownLoadHandler('final_invoice_threat', product)
                }
              >
                <CreditCardOffOutlinedIcon style={{ margin: '10px' }} />
                Hot om slutfaktura
              </DocumentDownloadCard>{' '}
            </Button>
            {serviceHasLegacyWelcomeLetter && (
              <LegacyWelcomeLetterButton
                customer={serviceDetails?.customer?.id}
                product={
                  getCamelCaseServiceName(serviceDetails['@type']) as
                    | 'firetext'
                    | 'protection'
                }
                productId={serviceDetails?.id}
              />
            )}
            {serviceDetails['@type'] === 'Sinne' && (
              <Button disabled={welcomeLetterMutation.isPending}>
                <DocumentDownloadCard
                  onClick={() =>
                    welcomeLetterMutation.mutate({
                      customerId: serviceDetails?.customer?.id,
                      product: 'sinne',
                      productId: serviceDetails?.id,
                    })
                  }
                >
                  <WelcomeLetterIcon style={{ margin: '10px' }} />
                  Välkomstbrev
                </DocumentDownloadCard>{' '}
              </Button>
            )}
            {serviceDetails['@type'] === 'Homegate' && (
              <Button disabled={welcomeLetterMutation.isPending}>
                <DocumentDownloadCard
                  onClick={() =>
                    welcomeLetterMutation.mutate({
                      customerId: serviceDetails?.customer?.id!,
                      product: 'homegate',
                      productId: serviceDetails?.id,
                    })
                  }
                >
                  <WelcomeLetterIcon style={{ margin: '10px' }} />
                  Välkomstbrev
                </DocumentDownloadCard>{' '}
              </Button>
            )}
          </Grid>
        </>
      }
    />
  );
};

export default DocumentDownload;
