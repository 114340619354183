import React from 'react';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse } from '@mui/material';

import { CollapsableMenuList } from '@/layouts/Main/components/Sidebar/components/SidebarNav/components/CollapsableMenuList';
import { MenuListItem } from '@/layouts/Main/components/Sidebar/components/SidebarNav/components/MenuListItem';
import { MenuListItemButton } from '@/layouts/Main/components/Sidebar/components/SidebarNav/components/MenuListItemButton';

import { SubMenuListItem } from './SubMenuListItem';

export const CustomersMenu = () => {
  const [open, setOpen] = React.useState(false);
  return (
    <div style={{ flexGrow: 1 }}>
      <MenuListItem>
        <MenuListItemButton
          onClick={(e) => {
            setOpen(!open);
          }}
        >
          Kunder
          {open ? <ExpandLess /> : <ExpandMore />}
        </MenuListItemButton>
      </MenuListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <CollapsableMenuList>
          <SubMenuListItem title="Kunder" href="/customers" />
          <SubMenuListItem
            title="Ej Inkasso"
            href="/customers/debt-collection"
          />
        </CollapsableMenuList>
      </Collapse>
    </div>
  );
};
