import React from 'react';

import { Box, Grid, LinearProgress, Paper } from '@mui/material';

import { BasicAlert } from '@/components/BasicAlert';
import { SimpleTable } from '@/components/SimpleTable';
import { SearchField } from '@/modules/common/components/SearchField';
import { rmaTableColumns } from '@/modules/rma/constants/rmaTableColumns';

import { useGetRmaCases } from '../hooks/useGetRmaCases';

export const RmaCaseList = () => {
  const [debouncedQueryString, setDebouncedQueryString] = React.useState('');

  return (
    <Box>
      <Grid
        component={Paper}
        style={{ padding: '1rem', marginBottom: '0.5rem' }}
        container
      >
        <Grid item xs={12} sm={6}>
          <SearchField
            placeholder="Sök på Mac-address eller kundnummer"
            setDebouncedQueryString={setDebouncedQueryString}
          />
        </Grid>
      </Grid>
      <RmaCaseListTable debouncedQueryString={debouncedQueryString} />
    </Box>
  );
};

type Props = {
  debouncedQueryString: string;
};
const RmaCaseListTable = ({ debouncedQueryString }: Props) => {
  const {
    data: rmaCases,
    isFetching,
    isLoading,
    isError,
  } = useGetRmaCases(debouncedQueryString);

  if (isFetching || isLoading) return <LinearProgress />;
  if (!rmaCases) return null;
  if (isError) return <BasicAlert />;
  if (rmaCases.data.length === 0) return <div>Inga ärenden</div>;
  return <SimpleTable columns={rmaTableColumns} rows={rmaCases.data} />;
};
