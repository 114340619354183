import { Fragment, useState } from 'react';

import {
  Button,
  Card,
  CardHeader,
  Grid,
  Switch,
  Typography,
} from '@mui/material';

import { LabelAndContentRow } from '@/components';
import { Can } from '@/components/Can';
import { useSnackbar } from '@/hooks';
import { useAuth } from '@/hooks/useAuth';
import { UserItem } from '@/types/users';

import { useUpdateUser } from '../hooks';
import { ChangeInputForm } from './ChangeInputForm';
import { ChangePasswordModal } from './ChangePasswordModal';

type Props = {
  user: UserItem;
  isCurrentUser: boolean;
};

export const UserInfo = ({ user, isCurrentUser }: Props) => {
  const { getAuthenticatedUserRoles } = useAuth();
  const userRoles = getAuthenticatedUserRoles();
  const isGodmode = userRoles?.includes('ROLE_GODMODE');
  const snackbar = useSnackbar();

  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [showPinChangeInput, setShowPinChangeInput] = useState(false);
  const [showUsernameChangeInput, setShowUsernameChangeInput] = useState(false);
  const [showEmailChangeInput, setShowEmailChangeInput] = useState(false);

  const updateUserMutation = useUpdateUser();

  const canChangePassword = isCurrentUser || isGodmode;

  return (
    <Grid item md={6} xs={12}>
      <Card>
        <CardHeader title="Användarinformation" />

        <Fragment>
          <LabelAndContentRow
            divider
            content={<Typography variant="subtitle2">{user?.name}</Typography>}
            label="Namn"
          />

          <LabelAndContentRow
            divider
            content={
              <Grid
                container
                spacing={2}
                alignItems="center"
                justifyContent="flex-end"
              >
                {showEmailChangeInput ? (
                  <Grid item>
                    <ChangeInputForm
                      onSubmit={({ email }) =>
                        updateUserMutation
                          .mutateAsync({
                            userId: user.id,
                            fields: { email },
                          })
                          .then(() => {
                            setShowEmailChangeInput(false);
                            snackbar({
                              type: 'success',
                              message: 'Email uppdaterad',
                            });
                          })
                      }
                      onDelete={() =>
                        updateUserMutation
                          .mutateAsync({
                            userId: user.id,
                            fields: { email: '' },
                          })
                          .then(() => {
                            setShowEmailChangeInput(false);
                            snackbar({
                              type: 'success',
                              message: 'Email borttagen',
                            });
                          })
                      }
                      onCancel={() => setShowEmailChangeInput(false)}
                      isLoading={updateUserMutation.isPending}
                      placeholder={user.email}
                      label="E-post"
                      name="email"
                    />
                  </Grid>
                ) : (
                  <>
                    <Grid item>
                      <Typography color="textSecondary">
                        {user.email ? user.email : 'Saknar epost'}
                      </Typography>
                    </Grid>
                    {isGodmode && (
                      <Grid item>
                        <Button
                          color="primary"
                          variant="outlined"
                          size="small"
                          onClick={() => setShowEmailChangeInput(true)}
                        >
                          Ändra
                        </Button>
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            }
            label="Epost"
          />

          <LabelAndContentRow
            divider
            content={
              <Grid
                container
                spacing={2}
                alignItems="center"
                justifyContent="flex-end"
              >
                {showUsernameChangeInput ? (
                  <Grid item>
                    <ChangeInputForm
                      onSubmit={({ username }) =>
                        updateUserMutation
                          .mutateAsync({
                            userId: user.id,
                            fields: { username },
                          })
                          .then(() => {
                            setShowUsernameChangeInput(false);
                            snackbar({
                              type: 'success',
                              message: 'Användarnamn uppdaterat',
                            });
                          })
                      }
                      onCancel={() => setShowUsernameChangeInput(false)}
                      isLoading={updateUserMutation.isPending}
                      placeholder={user.username}
                      label="Användarnamn"
                      name="username"
                    />
                  </Grid>
                ) : (
                  <>
                    <Grid item>
                      <Typography color="textSecondary">
                        {user.username}
                      </Typography>
                    </Grid>
                    {isGodmode && (
                      <Grid item>
                        <Button
                          color="primary"
                          variant="outlined"
                          size="small"
                          onClick={() => setShowUsernameChangeInput(true)}
                        >
                          Ändra
                        </Button>
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            }
            label="Användarnamn"
          />

          <Can
            action="users.changePin"
            yes={() => (
              <LabelAndContentRow
                divider
                content={
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    {showPinChangeInput ? (
                      <Grid item>
                        <ChangeInputForm
                          onSubmit={({ pin }) =>
                            updateUserMutation
                              .mutateAsync({
                                userId: user.id,
                                fields: { pin },
                              })
                              .then(() => {
                                setShowPinChangeInput(false);
                                snackbar({
                                  type: 'success',
                                  message: 'Personnummer uppdaterat',
                                });
                              })
                          }
                          onDelete={() =>
                            updateUserMutation
                              .mutateAsync({
                                userId: user.id,
                                fields: { pin: null },
                              })
                              .then(() => {
                                snackbar({
                                  type: 'success',
                                  message: 'Personnummer borttaget',
                                });
                              })
                          }
                          onCancel={() => setShowPinChangeInput(false)}
                          isLoading={updateUserMutation.isPending}
                          placeholder={user.pin ?? ''}
                          label="Personnummer"
                          name="pin"
                        />
                      </Grid>
                    ) : (
                      <>
                        <Grid item>
                          <Typography color="textSecondary">
                            {user.pin ? '*********' : 'Saknar personnummer'}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Button
                            color="primary"
                            variant="outlined"
                            size="small"
                            onClick={() => setShowPinChangeInput(true)}
                          >
                            Ändra
                          </Button>
                        </Grid>
                      </>
                    )}
                  </Grid>
                }
                label="Personnummer"
              />
            )}
            no={() => null}
          />
          {canChangePassword && (
            <>
              <LabelAndContentRow
                divider
                content={
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <Grid item>
                      <Typography color="textSecondary">*********</Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        color="primary"
                        variant="outlined"
                        size="small"
                        onClick={() => setShowChangePasswordModal(true)}
                      >
                        Ändra
                      </Button>
                    </Grid>
                  </Grid>
                }
                label="Lösenord"
              />

              <ChangePasswordModal
                open={showChangePasswordModal}
                isCurrentUser={isCurrentUser}
                handleClose={() => setShowChangePasswordModal(false)}
                userId={user.id}
              />
            </>
          )}
          {isGodmode && (
            <>
              <LabelAndContentRow
                content={
                  <Switch
                    checked={user.taggable}
                    onChange={() =>
                      updateUserMutation.mutate({
                        userId: user.id,
                        fields: {
                          taggable: !user.taggable,
                        },
                      })
                    }
                  />
                }
                label="Tagbar"
              />
            </>
          )}
        </Fragment>
      </Card>
    </Grid>
  );
};
