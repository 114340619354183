import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  styled,
} from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { Can } from '@/components/Can';
import { CustomerSearchInput } from '@/components/CustomerSearchInput';
import { hasAccessToAllCustomers } from '@/constants/roleTypes';
import { config } from '@/helpers/config';
import useUserRoles from '@/hooks/useUserRoles';
import { Customer } from '@/types/customers';

const Logo = styled('img')({
  height: 38,
  flexGrow: 1,
  display: 'block',
});

const SearchBar = styled('div')(({ theme }) => ({
  width: '500px',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    marginLeft: theme.spacing(1),
  },
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

type Props = {
  onSidebarOpen: () => void;
};

export const Topbar = ({ onSidebarOpen }: Props) => {
  const navigate = useNavigate();

  const userRoles = useUserRoles();
  function handleGoToCustomer({ id }: Customer) {
    navigate(`/customer/${id}`);
  }

  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        borderBottom: (theme) =>
          `1px solid ${theme.palette.background.default}`,
      }}
      color="inherit"
    >
      <Toolbar>
        <RouterLink to="/dashboard">
          <Logo
            alt="Logo"
            src={`/images/${config.whitelabelId}/logo_color.png`}
          />
        </RouterLink>
        <Box flexGrow={3} />
        {hasAccessToAllCustomers(userRoles) && (
          <SearchBar>
            <Can
              userRoles={userRoles}
              action="customers.view"
              yes={() => (
                <CustomerSearchInput onSelectCustomer={handleGoToCustomer} />
              )}
            />
          </SearchBar>
        )}
        <Hidden lgUp>
          <IconButton
            edge="end"
            color="inherit"
            onClick={onSidebarOpen}
            size="large"
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};
