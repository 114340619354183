import { atomWithStorage } from 'jotai/utils';

type DarkMode = {
  paletteType: 'light' | 'dark';
  darkMode: boolean;
};
export const darkModeAtom = atomWithStorage<DarkMode>(
  'darkMode',
  {
    paletteType: 'light',
    darkMode: false,
  },
  undefined,
  {
    getOnInit: true,
  },
);
