import React from 'react';

import { Box, Button, Grid, LinearProgress } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { Alert } from '@/components/Alert';
import { PureDropdown } from '@/components/form';
import { renderServiceIcons } from '@/helpers/render';
import { Toolbar } from '@/modules/accountManagerSystem/components/Toolbar';
import { QUERY_KEYS } from '@/modules/accountManagerSystem/helpers';
import { EnhancedAMSCustomer } from '@/types/customers';
import { AccountManager } from '@/types/users';
import httpClient from '@/utils/httpClient';

import { ListWithLoading } from '../ListWithLoading';
import { TransferList } from './TransferList';

const TO_ACCOUNT_MANAGER_CUSTOMER_LIST_COLUMNS = [
  { label: 'Kundnummer', renderer: (row: EnhancedAMSCustomer) => row.id },
  {
    label: 'Företag',
    renderer: (row: EnhancedAMSCustomer) =>
      row.companyCategory ? row.companyName : '',
  },
  { label: 'Namn', renderer: (row: EnhancedAMSCustomer) => row.fullName },
  {
    label: 'Tjänster',
    renderer: (row: EnhancedAMSCustomer) => renderServiceIcons(row),
  },
  {
    label: 'ARR',
    renderer: (row: EnhancedAMSCustomer) => row.totalARR,
  },
];

type Props = {
  accountManagers: AccountManager[] | undefined;
  loadingAccountManagers: boolean;
  errorAccountManager: boolean;
};

export const TransferCustomers = ({
  accountManagers,
  loadingAccountManagers,
  errorAccountManager,
}: Props) => {
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = React.useState({
    query: '',
    type: 'standard',
  });
  const [fromAccountManager, setFromAccountManager] = React.useState('');
  const [toAccountManager, setToAccountManager] = React.useState('');

  const [checkedCustomers, setCheckedCustomers] = React.useState<string[]>([]);

  //#region Fetching
  const fetchManagedCustomers = async (
    manager: string,
    query: string = '',
    type: string = 'standard',
  ) => {
    const standardSearch = type === 'standard';
    const endpoint = standardSearch ? '/customers' : '/customers/advanced';
    const searchType = standardSearch ? 'search' : 'q';

    const { data } = await httpClient.get(endpoint, {
      params: {
        [searchType]: query,
        type,
        accountManager: manager,
        pagination: false,
        properties: {
          companyCategory: true,
          companyName: true,
          id: true,
          fullName: true,
          serviceTypes: true,
          accountManager: {
            id: true,
          },
          totalARR: true,
        },
      },
    });

    return data['hydra:member'] as EnhancedAMSCustomer[];
  };

  const {
    data: customersFrom,
    isInitialLoading: loadingCustomersFrom,
    isError: errorCustomersFrom,
  } = useQuery({
    queryKey: [QUERY_KEYS.AMS_CUSTOMERS_FROM, fromAccountManager, searchParams],

    queryFn: async () => {
      const { type, query } = searchParams;
      return fetchManagedCustomers(fromAccountManager, query, type);
    },

    enabled: !!fromAccountManager,
  });

  const {
    data: customersTo,
    isInitialLoading: loadingCustomersTo,
    isError: errorCustomersTo,
  } = useQuery({
    queryKey: [QUERY_KEYS.AMS_CUSTOMERS_TO, toAccountManager],

    queryFn: async () => {
      return fetchManagedCustomers(toAccountManager);
    },

    enabled: !!toAccountManager,
  });

  const moveMutation = useMutation({
    mutationFn: (managedCustomers: string[]) =>
      httpClient.put(toAccountManager, {
        managedCustomers,
      }),
    onSuccess: () => {
      setCheckedCustomers([]);

      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.AMS_CUSTOMERS_FROM, fromAccountManager],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.AMS_CUSTOMERS_TO, toAccountManager],
      });

      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.FETCH_CUSTOMERS],
      });
      queryClient.invalidateQueries({
        queryKey: ['accountManagers'],
      });
    },
  });
  //#endregion

  const handleToggle = (value: string) => () => {
    const currentIndex = checkedCustomers.indexOf(value);
    const newChecked = [...checkedCustomers];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedCustomers(newChecked);
  };

  const handleToggleAll = (items: string[]) => () => {
    if (checkedCustomers.length === items.length) {
      setCheckedCustomers([]);
    } else {
      setCheckedCustomers(items);
    }
  };

  const handleMoveCustomers = React.useCallback(() => {
    const foundManager = accountManagers?.find(
      (manager) => manager['@id'] === toAccountManager,
    );

    if (foundManager) {
      const existingCustomers = foundManager.managedCustomers.map(
        (customer) => customer['@id'],
      );

      const allCustomers = checkedCustomers.concat(existingCustomers);

      moveMutation.mutate(allCustomers);
    }
  }, [accountManagers, checkedCustomers, toAccountManager]);
  return (
    <>
      <Box>
        {loadingAccountManagers ? (
          <LinearProgress />
        ) : errorAccountManager ? (
          <Alert severity="error">Något gick fel</Alert>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <PureDropdown
                label="Flytta kunder från"
                value={fromAccountManager}
                labelId="from"
                onChange={(e: any) => {
                  if (e.target.value !== toAccountManager) {
                    setFromAccountManager(e.target.value);
                  }
                }}
                options={
                  accountManagers
                    ?.map((manager) => ({
                      id: manager['@id'],
                      value: manager['@id'],
                      text: manager.name,
                    }))
                    .filter((option) => option.value !== toAccountManager) || []
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <PureDropdown
                label="Flytta kunder till"
                labelId="to"
                value={toAccountManager}
                onChange={(e: any) => {
                  setToAccountManager(e.target.value);
                }}
                options={
                  accountManagers
                    ?.map((manager) => ({
                      id: manager['@id'],
                      value: manager['@id'],
                      text: manager.name,
                    }))
                    .filter((option) => option.value !== fromAccountManager) ||
                  []
                }
              />
            </Grid>
          </Grid>
        )}
      </Box>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {/* @ts-ignore */}
            <Toolbar
              searchHandler={(v: { type: string; query: string }) =>
                setSearchParams(v)
              }
              paperComponent={false}
              disabled={!customersFrom}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <TransferList
              title={
                accountManagers &&
                `Välj kunder att flytta från ${
                  accountManagers.find(
                    (manager) => manager['@id'] === fromAccountManager,
                  )?.name
                }`
              }
              items={customersFrom}
              loading={loadingCustomersFrom}
              error={errorCustomersFrom}
              handleToggle={handleToggle}
              handleToggleAll={handleToggleAll}
              checked={checkedCustomers}
            />
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={2}
            direction="column"
            alignItems="center"
          >
            <Button
              style={{ marginBottom: '1rem' }}
              variant="outlined"
              size="small"
              onClick={handleMoveCustomers}
              disabled={checkedCustomers.length === 0 || !toAccountManager}
              aria-label="move selected right"
            >
              &gt;
            </Button>
          </Grid>
          <Grid item xs={12} sm={5}>
            <ListWithLoading
              title={
                accountManagers &&
                `${
                  accountManagers.find(
                    (manager) => manager['@id'] === toAccountManager,
                  )?.name
                }'s befintliga kunder`
              }
              loading={loadingCustomersTo}
              error={errorCustomersTo}
              rows={customersTo}
              columns={TO_ACCOUNT_MANAGER_CUSTOMER_LIST_COLUMNS}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
