import { useState } from 'react';

import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { getAllLockRegisters } from '@/api/lockRegister';
import { SearchInput } from '@/components';
import { Pagination } from '@/components/Pagination';
import { SimpleTable } from '@/components/SimpleTable';
import { LockRegister } from '@/types/lockRegister';

const columns: Array<{
  label: string;
  renderer: (row: LockRegister) => string;
}> = [
  { label: 'Namn', renderer: (row) => row.companyName },
  { label: 'Spärrnummer', renderer: (row) => row.phone1 },
  { label: 'Alt. kontakt', renderer: (row) => row.phone2 },
  { label: 'Mer info', renderer: (row) => row.comment },
];

export const BlockedTable = () => {
  const [search, setSearch] = useState('');
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(100);
  const [total, setTotal] = useState<number>(0);

  const { data: lockRegisters, isLoading: requestInProgress } = useQuery({
    queryKey: ['lockRegisters'],

    queryFn: async () => {
      const { data, total } = await getAllLockRegisters({
        page: page + 1,
        perPage: rowsPerPage,
        search,
      });

      setTotal(total);

      return data;
    },
  });

  const handlePageChange = (event: any, page: number) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(rowsPerPage);
  };

  const onSearchChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setSearch(event.target.value);
  };

  return (
    <Grid item xs={12}>
      <Card>
        <CardContent
          sx={{
            padding: 0,
          }}
        >
          <Box
            sx={(theme) => ({
              padding: theme.spacing(2),
              height: '56px',
            })}
          >
            <Typography variant="h5">Spärrnummer-lista</Typography>
          </Box>
          <Divider />
          <Box
            sx={(theme) => ({
              width: '50%',
              height: '78px',
              padding: theme.spacing(2),
            })}
          >
            <SearchInput onChange={onSearchChange} placeholder="Sök" />
          </Box>
          {requestInProgress && <LinearProgress />}
          {lockRegisters && (
            <SimpleTable columns={columns} rows={lockRegisters} />
          )}
          <Pagination
            count={total}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPageOptions={undefined}
          />
        </CardContent>
      </Card>
    </Grid>
  );
};
