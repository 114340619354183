import { WL_NUMBERS } from '../constants/whiteLabels';

export type AppConfig = {
  apiBase: string;
  apiUrl: string;
  siteUri: string;
  whitelabelId: string;
  folderPath: string;
  env: 'production' | 'staging' | 'development';
  homegateCampaignEnabled?: boolean;
  testMessage?: string;
};

export let config: AppConfig = {
  apiBase: 'https://api.monitum.io/',
  apiUrl: 'https://api.monitum.io/0000',
  whitelabelId: '0000',
  folderPath: 'src/projects/0000',
  env: 'production',
  siteUri: 'localhost:5173',
};

export function getEnvConfig() {
  const baseConfig: AppConfig = {
    apiBase: 'https://api.monitum.io/',
    apiUrl: 'https://api.monitum.io/0000',
    whitelabelId: '0000',
    folderPath: 'src/projects/0000',
    env: 'production',
    siteUri: 'localhost:5173',
  };

  switch (window.location.hostname) {
    case 'localhost':
      baseConfig.env = 'development';
      baseConfig.siteUri = 'http://localhost:5173';
      baseConfig.apiBase =
        import.meta.env.VITE_USE_EXTERNAL_API_FOR_LOCALHOST === 'true'
          ? 'https://api.pleasework.se/'
          : 'http://localhost:8000/';
      baseConfig.whitelabelId = '0000';
      baseConfig.homegateCampaignEnabled = true;

      break;

    case 'staging':
      baseConfig.env = 'development';
      baseConfig.siteUri = 'https://backoffice.pleasework.se';
      baseConfig.apiBase = 'https://api.pleasework.se/';
      baseConfig.whitelabelId = '0000';
      break;

    case 'backoffice.pleasework.se':
      baseConfig.env = 'staging';
      baseConfig.siteUri = 'https://backoffice.pleasework.se';
      baseConfig.apiBase = 'https://api.pleasework.se/';
      baseConfig.homegateCampaignEnabled = true;

      break;

    // monitum
    case 'monitum2.monitum.se':
      baseConfig.siteUri = 'https://monitum2.monitum.se';
      break;

    // tryggsam
    case 'monitum2.tryggsam.se':
      baseConfig.siteUri = 'https://monitum2.tryggsam.se';
      baseConfig.whitelabelId = '1337';
      break;

    // forebygg
    case 'monitum2-1608.monitum.se':
      baseConfig.siteUri = 'https://monitum2-1608.monitum.se';
      baseConfig.whitelabelId = '1608';
      baseConfig.homegateCampaignEnabled = true;
      break;

    // prevento
    case 'monitum2-1702.monitum.se':
      baseConfig.siteUri = 'https://monitum2-1702.monitum.se';
      baseConfig.whitelabelId = '1702';
      break;

    // fonia
    case 'monitum2-1703.monitum.se':
      baseConfig.siteUri = 'https://monitum2-1703.monitum.se';
      baseConfig.whitelabelId = '1703';
      break;

    // securio
    case 'monitum2-1711.monitum.se':
      baseConfig.siteUri = 'https://monitum2-1711.monitum.se';
      baseConfig.whitelabelId = '1711';
      break;

    // vipo
    case 'monitum2-1801.monitum.se':
      baseConfig.siteUri = 'https://monitum2-1801.monitum.se';
      baseConfig.whitelabelId = '1801';
      break;

    // sakra_dig
    case 'monitum2-1802.monitum.se':
      baseConfig.siteUri = 'https://monitum2-1802.monitum.se';
      baseConfig.whitelabelId = '1802';
      break;

    // keep_safe
    case 'monitum2-1804.monitum.se':
      baseConfig.siteUri = 'https://monitum2-1804.monitum.se';
      baseConfig.whitelabelId = '1804';
      break;

    // securitum
    case 'monitum2-1806.monitum.se':
      baseConfig.siteUri = 'https://monitum2-1806.monitum.se';
      baseConfig.whitelabelId = '1806';
      break;

    // guardify
    case 'monitum2-1807.monitum.se':
      baseConfig.siteUri = 'https://monitum2-1807.monitum.se';
      baseConfig.whitelabelId = '1807';
      break;

    // global23
    case 'monitum2-1808.monitum.se':
      baseConfig.siteUri = 'https://monitum2-1808.monitum.se';
      baseConfig.whitelabelId = '1808';
      break;

    // safe_id
    case 'monitum2-1809.monitum.se':
      baseConfig.siteUri = 'https://monitum2-1809.monitum.se';
      baseConfig.whitelabelId = '1809';
      break;

    // bevakia
    case 'monitum2-1810.monitum.se':
      baseConfig.siteUri = 'https://monitum2-1810.monitum.se';
      baseConfig.whitelabelId = '1810';
      break;

    // tryggare_id
    case 'monitum2-1901.monitum.se':
      baseConfig.siteUri = 'https://monitum2-1901.monitum.se';
      baseConfig.whitelabelId = '1901';
      break;

    // riktiga
    case 'monitum2.riktiga.se':
      baseConfig.siteUri = 'https://monitum2.riktiga.se';
      baseConfig.whitelabelId = '1909';
      break;

    // smart_senior
    case 'monitum2-2005.monitum.se':
      baseConfig.siteUri = 'https://monitum2-2005.monitum.se';
      baseConfig.whitelabelId = '2005';
      break;

    // alltid
    case 'monitum2-2008.monitum.se':
      baseConfig.siteUri = 'https://monitum2-2008.monitum.se';
      baseConfig.whitelabelId = '2008';
      break;

    // verbal
    case 'monitum2-2011.monitum.se':
      baseConfig.siteUri = 'https://monitum2-2011.monitum.se';
      baseConfig.whitelabelId = '2011';
      break;

    // sensor_alarm
    case 'monitum2-2012.monitum.se':
      baseConfig.siteUri = 'https://monitum2-2012.monitum.se';
      baseConfig.whitelabelId = '2012';
      break;

    // barksy
    case 'monitum2-2206.monitum.se':
      baseConfig.siteUri = 'https://monitum2-2206.monitum.se';
      baseConfig.whitelabelId = '2206';
      break;

    // Thorn
    case 'crm-idskydd.thorn.se':
      baseConfig.siteUri = 'https://crm-idskydd.thorn.se';
      baseConfig.whitelabelId = WL_NUMBERS.THORN;
      break;

    case 'monitum2-2401.monitum.se':
      baseConfig.siteUri = 'https://monitum2-2401.monitum.se';
      baseConfig.whitelabelId = WL_NUMBERS.THORN;
      break;

    // Axel
    case 'monitum.axel.se':
      baseConfig.siteUri = 'https://monitum.axel.se';
      baseConfig.whitelabelId = WL_NUMBERS.AXEL;
      break;

    case 'monitum2-2406.monitum.se':
      baseConfig.siteUri = 'https://monitum2-2406.monitum.se';
      baseConfig.whitelabelId = WL_NUMBERS.AXEL;
      break;

    // Protexia
    case 'monitum.protexia.se':
      baseConfig.siteUri = 'monitum.protexia.se';
      baseConfig.whitelabelId = WL_NUMBERS.PROTEXIA;
      break;

    case 'monitum2-2408.monitum.se':
      baseConfig.siteUri = 'https://monitum2-2408.monitum.se';
      baseConfig.whitelabelId = WL_NUMBERS.PROTEXIA;
      break;

    // THV
    case 'monitum2.thv.fi':
      baseConfig.siteUri = 'https://monitum2.thv.fi';
      baseConfig.whitelabelId = '2409';
      break;

    case 'https://monitum2-2409.monitum.se':
      baseConfig.siteUri = 'https://monitum2-2409.monitum.se';
      baseConfig.whitelabelId = '2409';
      break;

    // My Beat
    case 'monitum.mybeat.com':
      baseConfig.siteUri = 'https://monitum.mybeat.com';
      baseConfig.whitelabelId = WL_NUMBERS.MY_BEAT;
      break;

    case 'monitum.mybeat.se':
      baseConfig.siteUri = 'https://monitum.mybeat.se';
      baseConfig.whitelabelId = WL_NUMBERS.MY_BEAT;
      break;

    case 'monitum2-2405.monitum.se':
      baseConfig.siteUri = 'https://monitum2-2405.monitum.se';
      baseConfig.whitelabelId = WL_NUMBERS.MY_BEAT;
      break;

    // production demo
    case 'monitum2-9999.monitum.se':
      baseConfig.siteUri = 'https://monitum2-9999.monitum.se';
      baseConfig.whitelabelId = '9999';
      break;
    // Prod demo .IO
    case 'demo.monitum.io':
      baseConfig.siteUri = 'https://demo.monitum.io';
      baseConfig.whitelabelId = '9999';
      break;
    // Prod demo .SE
    case 'demo.monitum.se':
      baseConfig.siteUri = 'https://demo.monitum.se';
      baseConfig.whitelabelId = '9999';
      break;

    // Test white label 1
    case 'pleasework2-1711.pleasework.se':
      baseConfig.testMessage = 'Staging WL: 1711';
      break;

    // Test white label 2
    case 'pleasework2-1611.pleasework.se':
      baseConfig.testMessage = 'Staging WL: 1611';
      break;

    default:
      if (window.location.hostname.includes('isdemo')) {
        // This is for review apps which have isdemo in their url.
        baseConfig.env = 'staging';
        baseConfig.siteUri = `https://${window.location.hostname}`;
        baseConfig.apiBase = 'https://api.pleasework.se/';
      } else {
        baseConfig.siteUri = 'https://monitum2.monitum.se';
      }
      break;
  }

  baseConfig.apiUrl = baseConfig.apiBase + baseConfig.whitelabelId;
  baseConfig.folderPath = 'src/projects/' + baseConfig.whitelabelId;

  return baseConfig;
}

export function environmentInit() {
  // Temp solution for compatibility until all parts of app are migrated to new http client
  config = getEnvConfig();
}
