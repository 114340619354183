import React from 'react';

import {
  Alert,
  AlertTitle,
  Button,
  CardActions,
  CardContent,
  Divider,
  LinearProgress,
  Typography,
} from '@mui/material';
import { useSelector } from '@xstate/react';
import { useNavigate } from 'react-router-dom';

import { SinneConfigContext } from '@/modules/sinne/components/sinneConfigFlow/context/SinneConfigContextProvider';

export const Finalize = () => {
  const machine = React.useContext(SinneConfigContext);
  const state = useSelector(machine, (state) => state);

  const navigate = useNavigate();

  const isReplacementFlow = state.context.isReplacementFlow;
  const configFlowUrl = '/products/sinne?tab=Konfigurera';
  const replacemenFlowUrl = '/admin/Sinne/tools?tab=Ersätt+enhet';
  const urlToNavigateTo = isReplacementFlow ? replacemenFlowUrl : configFlowUrl;

  const isLoading = state.matches('FINALIZE.loading');
  const isSuccess = state.matches('FINALIZE.success');
  const isError = state.matches('FINALIZE.error');

  const isLoadingSetDeviceToBroken = state.matches('DEVICE_BROKEN.loading');
  const isErrorSetDeviceToBroken = state.matches('DEVICE_BROKEN.error');

  const isLoadingCreatingNote = state.matches('CREATE_NOTE.loading');
  const isErrorCreatingNote = state.matches('CREATE_NOTE.error');

  const errorMessage = state.context.apiError?.message;

  return (
    <>
      <CardContent>
        {isSuccess && (
          <Alert severity="success">
            Tjänstens status har satts till <b>aktiv</b>
          </Alert>
        )}
        {isLoading && (
          <>
            <LinearProgress />
            <Typography variant="h5">
              Uppdaterar tjänsten till <b>aktiv</b>
            </Typography>
          </>
        )}
        {isError && (
          <>
            <Alert severity="error" sx={{ mb: 1 }}>
              <AlertTitle>Det gick inte att uppdatera tjänsten</AlertTitle>
              {errorMessage}
            </Alert>
            <Button
              variant="outlined"
              onClick={() => machine.send('RETRY_UPDATE_SERVICE_TO_ACTIVE')}
            >
              Försök igen
            </Button>
          </>
        )}

        {isLoadingSetDeviceToBroken && (
          <>
            <LinearProgress />
            <Typography variant="h5">
              Uppdaterar status på den trasiga enheten.
            </Typography>
          </>
        )}
        {isErrorSetDeviceToBroken && (
          <Alert severity="error">
            Misslyckades med att uppdatera den gamla enhetens status.
          </Alert>
        )}

        {isLoadingCreatingNote && (
          <>
            <LinearProgress />
            <Typography variant="h5">
              Skapar notering om att enheten har blivit ersatt.
            </Typography>
          </>
        )}
        {isErrorCreatingNote && (
          <Alert severity="error">Misslyckades med att skapa notering.</Alert>
        )}
      </CardContent>
      <Divider />
      <CardActions>
        <Button disabled={!isSuccess} onClick={() => navigate(urlToNavigateTo)}>
          Klar
        </Button>
      </CardActions>
    </>
  );
};
