import { formatDuration, intervalToDuration } from 'date-fns';
import sv from 'date-fns/locale/sv';
import upperFirst from 'lodash/upperFirst';

export const getDurationSinceLastDeviceSync = (syncedAt: string | null) => {
  if (syncedAt) {
    return upperFirst(
      formatDuration(
        intervalToDuration({
          start: new Date(syncedAt),
          end: new Date(),
        }),
        { locale: sv },
      ),
    );
  }
  return '-';
};
