import { useQuery } from '@tanstack/react-query';

import { getResellerById } from '@/api';
import { queryKeys } from '@/constants/queryKeys';

type Options = {
  enabled?: boolean;
  initialData?: any;
};
export const useGetResellerById = (id: string | number, options?: Options) => {
  const idAsNumber = typeof id === 'string' ? parseInt(id, 10) : id;
  return useQuery({
    queryKey: queryKeys.reseller.detail(idAsNumber),
    queryFn: () => getResellerById(idAsNumber),
    ...options,
  });
};
