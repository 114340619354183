import React from 'react';

import {
  Button,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';

import { Personnummer } from '@/helpers/validators/personnummer';
import {
  CallInitiator,
  useGetBankIdCallInitiatorOptions,
} from '@/hooks/useGetBankIdCallInitiatorOptions';

type Props = {
  callerPin: string;
  setCallerPin: (value: string) => void;
  callInitiator: CallInitiator;
  setCallInitiator: (value: CallInitiator) => void;
  setInitiateBankId: (value: boolean) => void;
};

export const PhoneVerification = ({
  callerPin,
  callInitiator,
  setCallInitiator,
  setCallerPin,
  setInitiateBankId,
}: Props) => {
  const { CALL_INITIATOR_RADIO_OPTIONS } = useGetBankIdCallInitiatorOptions();

  const invalidPin = callerPin.length === 12 && !Personnummer.valid(callerPin);
  return (
    <div>
      <div>
        <FormLabel id="callInitiator">
          <Typography variant="h5">Vem tog kontakt?</Typography>
          <span>Fyll i vem som ringde till vem</span>
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby="callInitiator"
          name="row-radio-buttons-group"
        >
          {CALL_INITIATOR_RADIO_OPTIONS.map((option) => (
            <FormControlLabel
              value={option.value}
              checked={option.value === callInitiator}
              control={<Radio />}
              label={option.label}
              onChange={(event) => {
                const target = event.target as HTMLInputElement;

                setCallInitiator(target.value as CallInitiator);
              }}
            />
          ))}
        </RadioGroup>
      </div>
      <TextField
        fullWidth
        label="Personnummer"
        sx={{ marginBottom: 2 }}
        onChange={(v) => {
          setCallerPin(v.target.value);
        }}
        error={invalidPin}
        helperText={invalidPin ? 'Felaktigt personnummer' : ''}
      />
      <Button
        variant="contained"
        disabled={!Personnummer.valid(callerPin)}
        onClick={() => setInitiateBankId(true)}
      >
        Verifiera kund
      </Button>
    </div>
  );
};
