import React from 'react';

import { useNavigate } from 'react-router-dom';

import { getElementFromArray } from '@/helpers/getElementFromArray';
import { Tabmap } from '@/types/tabs';

type Props = {
  tabs: Tabmap[];
  defaultTabIndex?: number;
};

export const useTabChangeHandler = ({ tabs, defaultTabIndex = 0 }: Props) => {
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const defaultTab = getElementFromArray(tabs?.[defaultTabIndex]?.title);
  const [activeTab, setActiveTab] = React.useState<string>(defaultTab);

  const tabChangeHandler = (tab?: string) => {
    tab ||= defaultTab;
    queryParams.set('tab', tab!);
    setActiveTab(tab!);

    // Replace location if the link to page did not include query param for which tab to go to
    // if this is not done, the back button does not work correctly
    navigate(
      { search: queryParams.toString() },
      { replace: !Boolean(location.search) },
    );
  };

  React.useEffect(() => {
    const tabName = queryParams.get('tab');
    if (tabName && tabs.some((tab) => tab.title === tabName)) {
      setActiveTab(tabName);
    } else {
      tabChangeHandler();
    }
  }, [queryParams, tabs]);

  return { activeTab, tabChangeHandler };
};
