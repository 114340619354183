import { useMutation } from '@tanstack/react-query';

import { deleteReseller } from '@/api/reseller';
import { CustomErrorData, GenericHttpError } from '@/types/errors';

export const useDeleteReseller = () => {
  return useMutation<{}, GenericHttpError<CustomErrorData>, string | number>({
    mutationFn: deleteReseller,
  });
};
