import { MobileTable } from '@/components/MobileTable';

type Props = {
  productType: 'Protection';
  productIri: string;
};

export const MobilesSection = (props: Props) => {
  return <MobileTable marginBottom="0" {...props} />;
};
