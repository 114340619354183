import { Brightness4Outlined } from '@mui/icons-material';
import { Divider, List, ListItem } from '@mui/material';

import { Can } from '@/components/Can';
import { useDarkMode } from '@/hooks';
import { useAuth } from '@/hooks/useAuth';
import { EconomyMenu } from '@/layouts/Main/components/Sidebar/components/SidebarNav/components/EconomyMenu';
import { MenuButton } from '@/layouts/Main/components/Sidebar/components/SidebarNav/components/MenuButton';
import { checkRolesAccessFromJwt } from '@/utils/authorization';

import {
  AccountManagerMenu,
  ServiceMenu,
  SupportMenu,
  ToolsMenu,
} from './components';
import { AdminMenu } from './components/AdminMenu';
import { CustomersMenu } from './components/CustomersMenu';
import { PartnerMenu } from './components/PartnerMenu';
import { ResellerMenu } from './components/ResellerMenu';
import { SecurityServiceMenu } from './components/SecurityServiceMenu';
import { SubMenuListItem } from './components/SubMenuListItem';

export const SidebarNav = () => {
  const { getAuthenticatedUserRoles, logOut } = useAuth();
  const userRoles = getAuthenticatedUserRoles();
  const { toggleDarkMode } = useDarkMode();

  if (!userRoles) return null;

  const handleDarkMode = () => {
    toggleDarkMode();
  };

  const userCanViewCustomerMenu = [
    'ROLE_GODMODE',
    'ROLE_CUSTOMER_SUPPORT_MANAGER',
    'ROLE_CUSTOMER_SUPPORT',
    'ROLE_ADMIN_MANAGER',
    'ROLE_ADMINISTRATOR',
    'ROLE_HEAD_OF_B2B',
    'ROLE_OWNER',
  ];

  return (
    <List sx={{ mb: 2, p: 2, pt: 0 }} dense disablePadding>
      <>
        <Can
          userRoles={userRoles}
          action="admin.menu.view"
          yes={() => <AdminMenu />}
        />
        <Can
          userRoles={userRoles}
          action="users.view"
          yes={() => <SubMenuListItem title="Användare" href="/users" />}
        />
        {userRoles.some((role) => userCanViewCustomerMenu.includes(role)) ? (
          <Can
            userRoles={userRoles}
            action="customers.debtCollection"
            yes={() => <CustomersMenu />}
            no={() =>
              checkRolesAccessFromJwt('customers.view') ? (
                <SubMenuListItem title="Kunder" href="/customers" />
              ) : null
            }
          />
        ) : null}
        <Can
          userRoles={userRoles}
          action="services.view"
          yes={() => <ServiceMenu />}
        />
        <Can
          userRoles={userRoles}
          action="accountManagerSystem.view"
          yes={() => <AccountManagerMenu userRoles={userRoles} />}
        />
        <Can
          userRoles={userRoles}
          action="support.view"
          yes={() => <SupportMenu userRoles={userRoles} />}
        />
        <Can
          userRoles={userRoles}
          action="securityService.view"
          yes={() => <SecurityServiceMenu />}
        />{' '}
        <Can
          userRoles={userRoles}
          action="resellerPortal.view"
          yes={() => <ResellerMenu />}
        />
        <Can
          userRoles={userRoles}
          action="economy.view"
          yes={() => <EconomyMenu />}
        />
        <Can
          userRoles={userRoles}
          action="partners.view"
          yes={() => <PartnerMenu />}
        />
        <Can
          userRoles={userRoles}
          action="alarms.view"
          yes={() => <SubMenuListItem title="Larmcentral" href="/alarms" />}
        />
        <Can
          userRoles={userRoles}
          action="tools.view"
          yes={() => <ToolsMenu userRoles={userRoles} />}
        />
        <Divider />
        <Can
          userRoles={userRoles}
          action="resellerPortal.resellerSettings"
          yes={() => (
            <SubMenuListItem
              title="Mina inställningar"
              href="/reseller-portal/me"
            />
          )}
          no={() => (
            <SubMenuListItem title="Mina inställningar" href="/settings/me" />
          )}
        />
        <ListItem disableGutters>
          <MenuButton
            endIcon={<Brightness4Outlined />}
            onClick={handleDarkMode}
          >
            Utseende
          </MenuButton>
        </ListItem>
        <SubMenuListItem title="Logga ut" href="/#" callback={logOut} />
      </>
    </List>
  );
};
