import React from 'react';

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Skeleton,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useQuery } from '@tanstack/react-query';
import { type Dictionary } from 'lodash';
import groupBy from 'lodash/groupBy';

import { getAdministratorTasks, getCustomerSupportTasks } from '@/api/tasks';
import { SimpleTable, SimpleTableColumn } from '@/components/SimpleTable';
import { TabPanel } from '@/components/TabPanel';
import { TASK_STATUSES } from '@/constants/TaskStatuses';
import { queryKeys } from '@/constants/queryKeys';
import { getFormattedDate } from '@/helpers/dates';
import { getObjectKeys } from '@/helpers/tsHelpers';
import { usePrivateSettings } from '@/hooks';
import { USER_TYPES } from '@/modules/common/constants/entitiyNamesMap';
import { RmaCaseCard } from '@/modules/rma/components/RmaCaseCard';
import { EditTaskModal } from '@/modules/tasks';
import {
  ApiTaskTeamNames,
  TaskTeamStringType,
  TaskTeamTypes,
} from '@/modules/tasks/constants/tasksModalsConstants';
import { ExistingCustomer } from '@/types/customers';
import { TaskItem } from '@/types/tasks';

type Props = {
  customer: ExistingCustomer;
  handleCreateTaskModalVisibility: () => void;
};

type GroupedTasksListProps = {
  columns: SimpleTableColumn[];
  groupedTasks: Dictionary<TaskItem[]>;
  editHandler: (task: TaskItem) => void;
};
const GroupedTasksList = ({
  columns,
  groupedTasks,
  editHandler,
}: GroupedTasksListProps) => {
  if (Object.keys(groupedTasks).length === 0)
    return <Typography variant="h4">Inga ärenden</Typography>;

  return (
    <Box marginBottom={2}>
      {getObjectKeys(groupedTasks).map((idx) => {
        // TODO: Don't like the type casting here. The lodash groupBy function returns a string as key
        // I want to do something like z.enum(keyof typeof TASK_STATUSES)
        const key = idx as keyof typeof TASK_STATUSES;
        const tasks = groupedTasks[key];

        return (
          <SimpleTable
            key={key}
            columns={columns}
            rows={
              tasks?.map((task) => ({
                ...task,
              })) || []
            }
            isCollapsible
            tableTitle={`${TASK_STATUSES[key]} (${tasks?.length})`}
            onRowClick={(task) => editHandler(task)}
          />
        );
      })}
    </Box>
  );
};

export const Tasks = ({ customer, handleCreateTaskModalVisibility }: Props) => {
  const [activeTab, setActiveTab] = React.useState(1);
  const rmaTab = 3;
  const [taskToEditId, setTaskToEditId] = React.useState<string>();
  const [taskToEditType, setTaskToEditType] =
    React.useState<TaskTeamStringType>();

  const { servicesTypes } = usePrivateSettings();

  const {
    data: customerSupportTasks,
    isFetching: isLoadingCustomerTasks,
    isError: isErrorCustomerTasks,
    isSuccess: isSuccessCustomerTasks,
  } = useQuery({
    queryKey: [
      queryKeys.getCustomerTasks,
      ApiTaskTeamNames.CUSTOMER_SUPPORT,
      customer.id,
    ],

    queryFn: async () => {
      const data = await getCustomerSupportTasks(customer.id).then(
        (data) => data['hydra:member'],
      );
      return data;
    },
  });

  const {
    data: administratorTasks,
    isFetching: isLoadingAdministratorTasks,
    isError: isErrorAdministratorTasks,
    isSuccess: isSuccessAdministratorTasks,
  } = useQuery({
    queryKey: [
      queryKeys.getCustomerTasks,
      ApiTaskTeamNames.ADMINISTRATOR,
      customer.id,
    ],

    queryFn: async () => {
      const data = await getAdministratorTasks(customer.id).then(
        (data) => data['hydra:member'],
      );

      return data;
    },
  });

  const isLoading = isLoadingCustomerTasks || isLoadingAdministratorTasks;
  const isError = isErrorCustomerTasks || isErrorAdministratorTasks;
  const isSuccess = isSuccessCustomerTasks || isSuccessAdministratorTasks;

  const columns = [
    {
      label: 'Deadline',
      renderer: (row: TaskItem) =>
        row.deadline ? getFormattedDate(row.deadline, 'yyyy-MM-dd') : '-',
    },
    {
      label: 'Uppdaterad',
      renderer: (row: TaskItem) =>
        row.updatedAt ? getFormattedDate(row.updatedAt, 'yyyy-MM-dd') : '-',
    },
    {
      label: 'Kategori',

      renderer: (row: TaskItem) => {
        const key = Number(row.type);
        return servicesTypes.task[
          activeTab === 1
            ? TaskTeamTypes.CUSTOMER_SUPPORT
            : TaskTeamTypes.ADMINISTRATOR
        ][key];
      },
    },
  ];

  function handleRowClick(task: TaskItem) {
    setTaskToEditId(task['@id']);
    setTaskToEditType(task.team);
  }

  const taskToEdit =
    administratorTasks && customerSupportTasks
      ? [...administratorTasks, ...customerSupportTasks].find(
          (task) => task['@id'] === taskToEditId,
        )
      : null;

  const queryKey = [queryKeys.getCustomerTasks, taskToEditType, customer.id];

  return (
    <Card style={{ overflow: 'unset' }}>
      <StickyHeader title="Ärenden" />
      <Divider />
      {isError && (
        <Typography color="error" variant="h3">
          Kunde inte hämta ärenden
        </Typography>
      )}
      {isLoading ? (
        <Skeleton variant="rectangular" height={200} />
      ) : (
        isSuccess &&
        customerSupportTasks &&
        administratorTasks && (
          <>
            <CardContent sx={{ pt: 0 }}>
              <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs
                    value={activeTab}
                    onChange={(_, tab) => setActiveTab(tab)}
                    aria-label="basic tabs example"
                  >
                    <Tab value={1} label={USER_TYPES.customerService} />
                    <Tab value={2} label={USER_TYPES.admin} />
                    <Tab value={rmaTab} label="RMA" />
                  </Tabs>
                </Box>
                <TabPanel value={activeTab} index={1}>
                  <GroupedTasksList
                    columns={columns}
                    editHandler={handleRowClick}
                    groupedTasks={groupBy(customerSupportTasks, 'status')}
                  />
                </TabPanel>
                <TabPanel value={activeTab} index={2}>
                  <GroupedTasksList
                    columns={columns}
                    editHandler={handleRowClick}
                    groupedTasks={groupBy(administratorTasks, 'status')}
                  />
                </TabPanel>
                <TabPanel value={activeTab} index={rmaTab}>
                  <Box marginBottom={2}>
                    <RmaCaseCard customer={customer} />
                  </Box>
                </TabPanel>
              </Box>
            </CardContent>
            <Divider />
            {activeTab !== rmaTab && (
              <CardActions>
                <Button
                  color="primary"
                  onClick={handleCreateTaskModalVisibility}
                  variant="outlined"
                >
                  Lägg till
                </Button>
              </CardActions>
            )}
            {taskToEdit && taskToEditType && (
              <EditTaskModal
                customer={customer}
                task={taskToEdit}
                open={true}
                handleVisibility={() => {
                  setTaskToEditId(undefined);
                  setTaskToEditType(undefined);
                }}
                fromTasksList={false}
                queryKey={queryKey}
              />
            )}
          </>
        )
      )}
    </Card>
  );
};

const StickyHeader = styled(CardHeader)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  backgroundColor: theme.palette.background.paper,
  zIndex: 1,
}));
