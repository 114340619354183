import { PageWrapper } from '@/components/PageWrapper';
import { Tabs } from '@/components/Tabs/Tabs';
import { SinneEconomyOverview } from '@/modules/economy/components/SinneEconomy';

const tabMap = [
  {
    title: 'Muuun',
    view: () => <SinneEconomyOverview />,
  },
];

export const Hardware = () => {
  return (
    <PageWrapper
      pageTitle="Hårdvara"
      breadCrumbs={[{ label: 'Ekonomi' }, { label: 'Hårdvara' }]}
    >
      <Tabs tabs={tabMap} />
    </PageWrapper>
  );
};
