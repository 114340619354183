import { SvgIcon, SvgIconProps } from '@mui/material';

export const MailIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 96 96" width="96px" height="96px">
      <g id="surface198507384">
        <path d="M 21 16 C 13.855469 16 8 21.855469 8 29 L 8 67 C 8 74.144531 13.855469 80 21 80 L 75 80 C 82.144531 80 88 74.144531 88 67 L 88 29 C 88 21.855469 82.144531 16 75 16 Z M 21 22 L 75 22 C 78.898438 22 82 25.101562 82 29 L 82 31.210938 L 48 49.589844 L 14 31.210938 L 14 29 C 14 25.101562 17.101562 22 21 22 Z M 14 38.03125 L 46.574219 55.640625 C 47.464844 56.121094 48.535156 56.121094 49.425781 55.640625 L 82 38.03125 L 82 67 C 82 70.898438 78.898438 74 75 74 L 21 74 C 17.101562 74 14 70.898438 14 67 Z M 14 38.03125 " />
      </g>
    </SvgIcon>
  );
};
