import { NumberedListItem } from '@/components/NumberedListItem';

import { CustomerProps } from '../../../../../types';
import { ListTitle } from '../../../commonStyles';
import { EmailTemplate } from '../../EmailTemplate';

export function Todo({
  customer,
  customerEmailAddresses,
  emailAddresses,
  companyName,
}: CustomerProps) {
  return (
    <>
      <ListTitle variant="h6">Att göra</ListTitle>

      <NumberedListItem
        number={1}
        text={
          <>
            {`Om du behöver lägga en bedrägerispärr, kan du göra det direkt via vårt system. Om knappen inte fungerar, ring oss på 08-519 013 60. \n`}
            <b>Om de ej svarar, testa följande:</b>
            {`
UC: 08-670 90 00
Dun & Bradstreet (tidigare Bisnode): 08-558 059 00
Creditsafe: 031-725 50 00
Decidas: 031-730 67 00
Syna: 040-25 85 00
`}
            <b>Spärras det hos en av ovan så spärras det hos alla.</b>
          </>
        }
      />

      <NumberedListItem
        number={2}
        text={`Maila nedan text till ${emailAddresses?.admin}`}
      />
      <EmailTemplate
        customerId={customer.id}
        emailAddresses={customerEmailAddresses}
        template={`
Skicka folder gällande bestridan av fakturor samt brev som skall skickas till Bisnode tillsammans med Polisanmälan för längre spärr.
Avser kund med personnummer ${customer.pin}

Mvh,
Spärrlinjen hos ${companyName}
`}
      />
    </>
  );
}
