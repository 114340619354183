import { useMutation } from '@tanstack/react-query';

import { updateService } from '@/api/services';

type Payload = {
  serviceId: number;
  deviceId?: string;
  placement?: string | undefined;
  status?: number;
};

export const useUpdateSinne = () => {
  return useMutation({
    mutationFn: ({ serviceId, deviceId, placement, status }: Payload) => {
      return updateService({
        serviceName: 'sinne',
        serviceId,
        values: {
          deviceId,
          placement,
          status,
        },
      });
    },
  });
};
