export const customerInformationTranslations = {
  address: 'Adress',
  address2: 'Adress 2',
  autogiroAgreement: 'Status',
  autogiroSource: 'Källa',
  bankAccount: 'Kontonummer',
  bankName: 'Bank',
  city: 'Stad',
  companyName: 'Företagsnamn',
  email: 'E-post',
  firstName: 'Förnamn',
  fullName: 'Namn',
  invoiceEmail: 'Faktura e-post',
  lastName: 'Efternamn',
  mobile: 'Mobil',
  name: 'Namn',
  phone: 'Telefon',
  pin: 'Personnummer',
  zip: 'Postnummer',
};
