import React from 'react';

import { usePrivateSettings } from '@/hooks/usePrivateSettings';
import { BaseModal } from '@/modules/common/components';
import { BasicCustomerData } from '@/types/customers';

import { StickerConfigModalType } from '../../types/cofigModal';
import { StickerItem } from '../../types/dto';
import { Step1 } from './Step1';
import { Step2CoProtection } from './Step2CoProtection';
import { Step2Partner } from './Step2Partner';
import { Step2NewCustomer } from './Step2Protection';
import { Step2Upsale } from './Step2Upsale';
import { Step3 } from './Step3';

type Props = {
  type: StickerConfigModalType;
  open: boolean;
  onClose: () => void;
};

export const StickerConfigModal = ({ type, open, onClose }: Props) => {
  const { servicesNames } = usePrivateSettings();

  //#region State
  const [activeStep, setActiveStep] = React.useState<1 | 2 | 3>(1);
  const [foundCustomer, setFoundCustomer] =
    React.useState<BasicCustomerData | null>(null);
  const [stickersCreated, setStickersCreated] = React.useState<
    StickerItem[] | null
  >(null);
  //#endregion

  //#region Submit functions
  function handleResetState() {
    setFoundCustomer(null);
    setActiveStep(1);
  }

  function handleCustomerFound(customer: BasicCustomerData) {
    setFoundCustomer(customer);
    setActiveStep(2);
  }

  function handleFinishStep2(stickers: StickerItem[]) {
    setStickersCreated(stickers);
    setActiveStep(3);
  }

  //#endregion

  // TODO: Add translations
  const getModalTitle = () => {
    switch (type) {
      case 'newCustomer':
        return `Utskick: ${servicesNames.sticker}`;
      case 'upsale':
        return `Utskick: ${servicesNames.sticker} Uppsälj`;
      case 'coProtection':
        return `Utskick: ${servicesNames.coProtection} ${servicesNames.sticker}`;
      case 'partner':
        return `Utskick: ${servicesNames.sticker}`;
      default:
        return `Utskick: ${servicesNames.sticker}`;
    }
  };

  const getStep = ({
    step,
    type,
    customer,
  }: {
    step: 1 | 2 | 3;
    type: StickerConfigModalType;
    customer: BasicCustomerData | null;
  }) => {
    if (!customer) return null;

    const steps = {
      1: <Step1 type={type} onCustomerFound={handleCustomerFound} />,
      2: {
        newCustomer: (
          <Step2NewCustomer
            onGoBack={handleResetState}
            onFinish={handleFinishStep2}
            customer={foundCustomer!}
          />
        ),
        upsale: (
          <Step2Upsale
            onGoBack={handleResetState}
            onFinish={handleFinishStep2}
            customer={foundCustomer!}
          />
        ),
        coProtection: (
          <Step2CoProtection
            onGoBack={handleResetState}
            onFinish={handleFinishStep2}
            customer={foundCustomer!}
          />
        ),
        partner: (
          <Step2Partner
            onGoBack={handleResetState}
            onFinish={handleFinishStep2}
            customer={foundCustomer!}
          />
        ),
      },
      3: (
        <Step3
          customer={foundCustomer!}
          stickersCreated={stickersCreated || []}
          onNextCustomerFound={handleCustomerFound}
        />
      ),
    };

    return step !== 2 ? steps[step] : steps[step][type];
  };

  return (
    <BaseModal title={getModalTitle()} onClose={onClose} open={open}>
      {activeStep === 1 && (
        <Step1 type={type} onCustomerFound={handleCustomerFound} />
      )}
      {getStep({ step: activeStep, type, customer: foundCustomer })}
    </BaseModal>
  );
};
