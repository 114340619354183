import React from 'react';

import { Field } from 'react-final-form';

type Props = {
  when: string;
  is: number | string | boolean | null | undefined;
  children: React.ReactNode;
};

export const Condition = ({ when, is, children }: Props) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
);
