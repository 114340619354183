import React from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  LinearProgress,
  MenuItem,
  Stack,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

import { customerCategoryTypeOptions } from '@/constants';
import { useGetPackageOptions } from '@/modules/services/hooks/useGetPackageOptions';
import { useGetPriceOptions } from '@/modules/services/hooks/useGetPriceOptions';
import { HydraViolationError } from '@/types/errors';

import { useCampaignMutation } from '../../hooks/useCampaignMutation';
import { useFormSubmissionHandler } from '../../hooks/useFormSubmissionHandler';
import { ServerErrorAlert } from '../ServerErrorAlert';
import { protectionValidationSchema } from './ProtectionValidationSchema';

type FormValues = z.infer<typeof protectionValidationSchema>;

type Props = {
  defaultFormValues: FormValues;
  campaignToEdit: string | null | undefined; // IRI reference to campaign being edited
  handleVisibility: () => void;
};

export const ProtectionCampaignForm = ({
  defaultFormValues,
  campaignToEdit,
  handleVisibility,
}: Props) => {
  const { priceOptions, isLoading: isLoadingPrices } = useGetPriceOptions({
    active: true,
    serviceName: 'protection',
  });

  const packageOptions = useGetPackageOptions();

  const { mutate, error, isError, isSuccess, isPending } = useCampaignMutation({
    isUpdate: campaignToEdit,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormValues>({
    resolver: zodResolver(protectionValidationSchema),
    defaultValues: defaultFormValues,
    disabled: isPending,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const [serverErrors, setServerErrors] = React.useState<
    HydraViolationError[] | null
  >(null);

  useFormSubmissionHandler({
    campaignToEdit,
    error,
    isError,
    isSuccess,
    handleCloseModal: handleVisibility,
    setErrors: setServerErrors,
  });

  const onSubmit = (values: FormValues) => {
    mutate({
      service: campaignToEdit ?? 'protections_campaigns',
      payload: values,
    });
  };

  if (isLoadingPrices) {
    return <LinearProgress />;
  }

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        {isPending && (
          <Grid item xs={12}>
            <LinearProgress />
          </Grid>
        )}

        <ServerErrorAlert
          serverErrors={serverErrors}
          isUpdate={!!campaignToEdit}
        />

        <Grid item xs={12} md>
          <Stack>
            <HeaderBox>
              <Typography variant="h6">Kampanjstruktur</Typography>
            </HeaderBox>

            <Controller
              control={control}
              name="name"
              render={({ field }) => (
                <TextField
                  label="Namn"
                  error={Boolean(errors.name)}
                  helperText={errors.name?.message}
                  data-testid="protection-campaign-name"
                  {...field}
                />
              )}
            />

            <Controller
              control={control}
              name="customerType"
              render={({ field }) => (
                <TextField
                  select
                  label="Kundkategori"
                  error={Boolean(errors.customerType)}
                  helperText={errors.customerType?.message}
                  data-testid="protection-campaign-customerType"
                  {...field}
                >
                  {customerCategoryTypeOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.text}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />

            <Controller
              control={control}
              name="price"
              render={({ field }) => (
                <TextField
                  select
                  label="Månadspris"
                  error={Boolean(errors.price)}
                  helperText={errors.price?.message}
                  data-testid="protection-campaign-subscriptionFee"
                  {...field}
                >
                  {priceOptions.map((option) => (
                    <MenuItem key={option.text} value={option.value}>
                      {option.text}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            <Controller
              control={control}
              name="endUsers"
              render={({ field }) => (
                <TextField
                  select
                  label="Antal användare"
                  error={Boolean(errors.endUsers)}
                  helperText={errors.endUsers?.message}
                  data-testid="protection-campaign-customerType"
                  {...field}
                >
                  {Array.from({ length: 5 }).map((_, idx) => {
                    const value = ++idx;
                    return (
                      <MenuItem key={idx} value={value}>
                        {value}
                      </MenuItem>
                    );
                  })}
                </TextField>
              )}
            />

            <Controller
              control={control}
              name="contractMonths"
              render={({ field }) => (
                <TextField
                  label="Bindningstid (månader)"
                  error={Boolean(errors.contractMonths)}
                  helperText={errors.contractMonths?.message}
                  {...field}
                  data-testid="protection-campaign-contractMonths"
                />
              )}
            />

            <Controller
              control={control}
              name="freeMonths"
              render={({ field }) => (
                <TextField
                  label="Gratismånader"
                  error={Boolean(errors.freeMonths)}
                  helperText={errors.freeMonths?.message}
                  {...field}
                  data-testid="protection-campaign-freeMonths"
                />
              )}
            />

            <Controller
              control={control}
              name="noticePeriod"
              render={({ field }) => (
                <TextField
                  label="Uppsägningstid (månader)"
                  error={Boolean(errors.noticePeriod)}
                  helperText={errors.noticePeriod?.message}
                  {...field}
                  data-testid="protection-campaign-noticePeriod"
                />
              )}
            />

            <Controller
              control={control}
              name="withdrawalPeriodDays"
              render={({ field }) => (
                <TextField
                  label="Ångerrätt (dagar)"
                  error={Boolean(errors.withdrawalPeriodDays)}
                  helperText={errors.withdrawalPeriodDays?.message}
                  {...field}
                  data-testid="protection-campaign-withdrawalPeriodDays"
                />
              )}
            />

            <Controller
              control={control}
              name="package"
              render={({ field }) => (
                <TextField
                  select
                  label="Paket"
                  error={Boolean(errors.package)}
                  helperText={errors.package?.message}
                  data-testid="protection-campaign-package"
                  {...field}
                >
                  {packageOptions.map((option) => (
                    <MenuItem key={option.text} value={option.value}>
                      {option.text}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Stack>
        </Grid>

        <Divider orientation="vertical" flexItem sx={{ ml: 2 }} />

        <Grid item xs={12} md>
          <Stack>
            <HeaderBox>
              <Typography variant="h6" gutterBottom={false}>
                Intern Beskrivning
              </Typography>
              <Typography variant="subtitle2">
                Skriv en kommentar till kundservice vad denna kampanj används
                till.
              </Typography>
            </HeaderBox>

            <Controller
              control={control}
              name="internalDescription"
              render={({ field }) => (
                <TextField
                  label="Beskrivning"
                  error={Boolean(errors.internalDescription)}
                  helperText={errors.internalDescription?.message}
                  {...field}
                  data-testid="protection-campaign-internalDescription"
                />
              )}
            />

            <HeaderBox
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'end',
              }}
            >
              <Typography variant="h6" gutterBottom={false}>
                Kampanjbeskrivning till kund
              </Typography>
              <Typography variant="subtitle2">
                Dessa meningar kan skrivas ut på välkomstbrevet.
              </Typography>
            </HeaderBox>

            <Controller
              control={control}
              name="specialHeader"
              render={({ field }) => (
                <TextField
                  label="Specialtitel"
                  error={Boolean(errors.specialHeader)}
                  helperText={errors.specialHeader?.message}
                  {...field}
                  data-testid="protection-campaign-specialHeader"
                />
              )}
            />

            <Controller
              control={control}
              name="specialRow1"
              render={({ field }) => (
                <TextField
                  label="Specialrad 1"
                  error={Boolean(errors.specialRow1)}
                  helperText={errors.specialRow1?.message}
                  {...field}
                  data-testid="protection-campaign-specialRow1"
                />
              )}
            />
            <Controller
              control={control}
              name="specialRow2"
              render={({ field }) => (
                <TextField
                  label="Specialrad 2"
                  error={Boolean(errors.specialRow2)}
                  helperText={errors.specialRow2?.message}
                  {...field}
                  data-testid="protection-campaign-specialRow2"
                />
              )}
            />
            <Controller
              control={control}
              name="specialRow3"
              render={({ field }) => (
                <TextField
                  label="Specialrad 3"
                  error={Boolean(errors.specialRow3)}
                  helperText={errors.specialRow3?.message}
                  {...field}
                  data-testid="protection-campaign-specialRow3"
                />
              )}
            />

            <Typography variant="h6" gutterBottom={false} mt={2}>
              Skall kampanjen kunna användas till nya kunder?
            </Typography>
            <FormControlLabel
              label={watch('active') ? 'Ja' : 'Nej'}
              control={
                <Controller
                  name="active"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      checked={field.value}
                      data-testid="protection-campaign-active"
                    />
                  )}
                />
              }
            />

            <Typography variant="h6" gutterBottom={false} mt={2}>
              Ska kampanjen vara valbar för bulk utskrift?
            </Typography>
            <FormControlLabel
              label={watch('bulkPrintEnabled') ? 'Ja' : 'Nej'}
              control={
                <Controller
                  name="bulkPrintEnabled"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      checked={field.value}
                      data-testid="protection-campaign-bulkPrintEnabled"
                    />
                  )}
                />
              }
            />
          </Stack>
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="end">
        <Button type="submit" variant="contained">
          Spara
        </Button>
      </Box>
    </Box>
  );
};

const HeaderBox = styled(Box)(() => ({
  minHeight: '53px',
}));
