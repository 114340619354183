import React, { useState } from 'react';

import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Helmet } from 'react-helmet';

import { PageWrapper } from '@/components';
import { PhoneVerification } from '@/components/BankId';
import {
  CallInitiator,
  useGetBankIdCallInitiatorOptions,
} from '@/hooks/useGetBankIdCallInitiatorOptions';
import httpClient from '@/utils/httpClient';

export const BankIdCallerVerification = () => {
  const { CALL_INITIATOR_ENUM } = useGetBankIdCallInitiatorOptions();

  const [callerPin, setCallerPin] = useState('');
  const [callInitiator, setCallInitiator] = useState<CallInitiator>(
    CALL_INITIATOR_ENUM.RP,
  );
  const [initiateBankId, setInitiateBankId] = useState(false);

  const { isLoading, isSuccess, error } = useQuery({
    queryKey: ['callerPin', callerPin, callInitiator],
    queryFn: async () => {
      const { data } = await httpClient.get(
        `/grandid/initiate?pin=${callerPin}&callInitiator=${callInitiator}`,
      );
      return data;
    },
    enabled: initiateBankId,
  });

  const handleBankIdReset = () => {
    setCallerPin('');
    setInitiateBankId(false);
  };

  return (
    <PageWrapper
      breadCrumbs={[{ label: 'Kundservice' }, { label: 'Verifiera kund' }]}
      maxWidth="sm"
      pageTitle="Verifiera kund"
      children={
        <Card>
          <Helmet title="Verifiera kund | Kundservice" />

          <CardHeader
            title="Verifiera kund med hjälp av BankID"
            action={
              isSuccess && <Button onClick={handleBankIdReset}>Reset</Button>
            }
          />
          <Divider />
          <CardContent>
            <Box>
              {isLoading && (
                <Alert severity="info">Be kunden öppna BankID</Alert>
              )}
              {isSuccess && (
                <Alert severity="success">
                  Kund med personnummer {callerPin} verifierad
                </Alert>
              )}
              {error && (
                <Alert severity="error">
                  <AlertTitle>Fel vid verifiering</AlertTitle>
                  <pre>{JSON.stringify(error)}</pre>
                </Alert>
              )}
            </Box>

            {!initiateBankId && (
              <PhoneVerification
                callerPin={callerPin}
                setCallerPin={setCallerPin}
                callInitiator={callInitiator}
                setCallInitiator={setCallInitiator}
                setInitiateBankId={setInitiateBankId}
              />
            )}
          </CardContent>
        </Card>
      }
    />
  );
};

export default BankIdCallerVerification;
