import httpClient from '@/utils/httpClient';

export async function checkFraudProtection(pin: string) {
  const response = await httpClient.post<{ hasFraudProtection: boolean }>(
    '/rpc/fraud/fetch',
    {
      pin,
    },
  );

  return response;
}

export type ActiveFraudProtectionPayload = {
  pin: string;
  ceaseInterval: number;
  customerId: string;
};

export async function activateFraudProtection(
  payload: ActiveFraudProtectionPayload,
) {
  const response = await httpClient.post<null>('/rpc/fraud/create', payload);

  return response;
}

export type DeactivateFraudProtectionPayload = {
  pin: string;
  customerId: string;
  grandIdSession: string;
};

export async function deactivateFraudProtection(
  payload: DeactivateFraudProtectionPayload,
) {
  const response = await httpClient.post<null>('/rpc/fraud/cancel', payload);
  return response;
}
