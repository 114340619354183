import { useContext } from 'react';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  LinearProgress,
  Typography,
} from '@mui/material';
import { useSelector } from '@xstate/react';

import { NoteTypeMissingAlert, StepRow } from '@/modules/sinne/components';
import { useNoteTypeGuard } from '@/modules/sinne/hooks/useNoteTypeGuard';

import { SinneReturnStateContext, SinneReturnStateProvider } from '../context';
import { DeviceDataForm } from './DeviceDataForm';
import { NoteForm } from './NoteForm';
import { StatusChecks } from './StatusChecks';
import { UpdateNoclip } from './UpdateNoclip';
import { UpdateSinneService } from './UpdateSinneService';

const Flow = () => {
  const sinneReturnFlowService = useContext(SinneReturnStateContext);
  const state = useSelector(sinneReturnFlowService, (state) => state);

  const { noteTypeIsInPrivateSettings, loading } =
    useNoteTypeGuard('Enhet Returnerad');

  const { cardTitle } = state.context;

  const flowSuccessText = 'Enheten har återanvänts';
  const isNoteCreated = state.matches('CREATE_NOTE.success');

  if (loading) {
    return <LinearProgress />;
  }

  if (!noteTypeIsInPrivateSettings) {
    return <NoteTypeMissingAlert />;
  }

  return (
    <Box component={Card} maxWidth="lg">
      <CardHeader title={cardTitle} />
      <Divider />
      <CardContent>
        <DeviceDataForm />
        <StatusChecks />
        <UpdateNoclip />
        <UpdateSinneService />
        <NoteForm />
        {isNoteCreated && (
          <StepRow>
            <CheckCircleOutlineIcon color="success" sx={{ fontSize: 20 }} />
            <Typography component="span" variant="h6">
              {flowSuccessText}
            </Typography>
          </StepRow>
        )}
      </CardContent>
      <Divider />

      {isNoteCreated && (
        <CardActions sx={{ p: 2 }}>
          <Button
            variant="contained"
            onClick={() =>
              sinneReturnFlowService.send({ type: 'COMPLETE_FLOW' })
            }
          >
            Klar
          </Button>
        </CardActions>
      )}
    </Box>
  );
};

export const ReturnFlow = () => {
  return (
    <SinneReturnStateProvider>
      <Flow />
    </SinneReturnStateProvider>
  );
};
