import React from 'react';

import { Box, Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { getSinneDeviceEconomy } from '@/api/sinne';
import { Can } from '@/components/Can';
import { usePrivateSettings } from '@/hooks/usePrivateSettings';
import useUserRoles from '@/hooks/useUserRoles';

import { Datepicker } from './Datepicker';
import { DeviceEconomy } from './DeviceEconomy';
import { ExportEconomy } from './ExportEconomy';

export type SinneEconomyFormValues = {
  end?: string | undefined;
};

export const SinneEconomyOverview = () => {
  const userRoles = useUserRoles();
  const { servicesNames } = usePrivateSettings();

  const [filter, setFilter] = React.useState<SinneEconomyFormValues>({});

  const { data, isError, isLoading } = useQuery({
    queryKey: ['deviceEconomy', filter],

    queryFn: async () => {
      const data = await getSinneDeviceEconomy(filter);
      return data;
    },
  });

  return (
    <Box>
      <Datepicker filter={filter} save={setFilter} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <DeviceEconomy
            servicesNames={servicesNames}
            data={data}
            isError={isError}
            isLoading={isLoading}
          />{' '}
        </Grid>
        <Grid item xs={12} md={6}>
          <Can
            userRoles={userRoles}
            action="economy.exporting"
            yes={() => <ExportEconomy filter={filter} />}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
