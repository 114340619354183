import { Customer, CustomerType } from '@/types/customers';

// Only set the value if the value exists, for example is not an empty string.
function setValueIfExists<T>(customer: T, field: keyof T, value: T[keyof T]) {
  if (value) {
    customer[field] = value;
  }
}

/**
 * Parses a customer row imported from Excel.
 */
export function parseCustomerData(data: string | null) {
  if (!data) {
    return null;
  }

  const customer: Partial<Customer> = {};

  try {
    const fields = data.split('\t').map((field) => field.trim());
    setValueIfExists(customer, 'pin', fields[0]);
    setValueIfExists(customer, 'companyName', fields[1]);
    // Auto-set category (private/customer based on existence of companyName)
    if (customer.companyName) {
      customer.category = CustomerType.COMPANY;
    } else {
      customer.category = CustomerType.PRIVATE;
    }
    setValueIfExists(customer, 'firstName', fields[2]);
    setValueIfExists(customer, 'lastName', fields[3]);
    setValueIfExists(customer, 'address', fields[4]);
    setValueIfExists(customer, 'address2', fields[5]);
    setValueIfExists(customer, 'zip', fields[6]);
    setValueIfExists(customer, 'city', fields[7]);
    setValueIfExists(customer, 'phone', fields[8]);
    setValueIfExists(customer, 'mobile', fields[9]);
    setValueIfExists(customer, 'email', fields[10]);
  } catch (exception) {
    return null;
  }

  return customer;
}
