import { Alert, Box, Grid, LinearProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { getUserById } from '@/api/users';
import { Breadcrumbs } from '@/components/Breadcrumbs';
import { queryKeys } from '@/constants/queryKeys';
import { useAuth } from '@/hooks/useAuth';
import { UserInfo, UserPermissions, UserToolbar } from '@/modules/user';
import { shouldPermissionsBeReadOnly } from '@/modules/user/utils/userPermissions';

const User = () => {
  const { id } = useParams();
  const { getAuthenticatedUserRoles, authenticatedUser } = useAuth();
  const userRoles = getAuthenticatedUserRoles();

  const userId = id ?? authenticatedUser?.id.toString();

  const {
    data: user,
    isInitialLoading: isLoading,
    isError,
    isSuccess,
  } = useQuery({
    queryKey: [queryKeys.getUserById, parseInt(userId!, 10)],
    queryFn: async () => await getUserById(parseInt(userId!, 10), {}),
    enabled: Boolean(userId),
  });

  const isCurrentUser = authenticatedUser?.id === user?.id;

  const crumbs = isCurrentUser
    ? [{ label: 'Mina Inställningar' }]
    : [{ label: 'Användare', url: '/users' }, { label: user?.name ?? '' }];

  return (
    <Grid item xs>
      <Breadcrumbs crumbs={crumbs} />
      {isError ? (
        <Alert severity="error">Kunde inte hämta användarinformation</Alert>
      ) : isLoading ? (
        <LinearProgress />
      ) : (
        isSuccess && (
          <>
            <UserToolbar user={user!} isCurrentUser={isCurrentUser} />
            <Box sx={{ marginTop: 2 }}>
              <Grid container spacing={3}>
                <UserInfo user={user!} isCurrentUser={isCurrentUser} />
                {!shouldPermissionsBeReadOnly(
                  userRoles!,
                  user?.roles ?? [],
                ) && (
                  <UserPermissions
                    user={user}
                    isReadOnly={shouldPermissionsBeReadOnly(
                      userRoles!,
                      user?.roles ?? [],
                    )}
                  />
                )}
              </Grid>
            </Box>
          </>
        )
      )}
    </Grid>
  );
};

export default User;
