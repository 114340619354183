import React from 'react';

import { ArrowForwardOutlined } from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Card,
  CardContent,
  CardHeader,
  Divider,
  LinearProgress,
  Tooltip,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';

import { BasicAlert } from '@/components/BasicAlert';
import { Pagination } from '@/components/Pagination';
import { SimpleTable, SimpleTableColumn } from '@/components/SimpleTable';
import { PER_PAGE } from '@/constants/pagination';
import { usePrivateSettings } from '@/hooks';
import { SinneStatus } from '@/types/settings';
import { MissingDevice } from '@/types/sinne';

import { useGetMissingDevices } from '../../hooks/useGetMissingDevices';
import { mapServiceStatusToString } from './helpers';

type MissingDeviceWithStatusString = Omit<MissingDevice, 'status'> & {
  status: SinneStatus;
};

const columns: SimpleTableColumn<MissingDeviceWithStatusString>[] = [
  {
    label: 'Enhetens MAC-adress',
    renderer: (row) => row.deviceId,
  },
  {
    label: 'Kundnummer',
    renderer: (row) => row.customerId,
  },
  {
    label: 'Tjänstens status',
    renderer: (row) => row.status,
  },
  {
    label: '',
    renderer: (row) => (
      <Tooltip title="Detaljer">
        <Link
          to={`/support/sinne/missing-devices/${row.deviceId}?imei=${row.imei}&serviceId=${row.sinneEntityId}`}
        >
          <ArrowForwardOutlined color="primary" />
        </Link>
      </Tooltip>
    ),
    align: 'right',
  },
];
// Devices that are bound to be returned to the office but for some reason are not
export const MissingDevicesList = () => {
  const { servicesStatuses } = usePrivateSettings();

  const [page, setPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(PER_PAGE);

  const { data: missingDevices, status } = useGetMissingDevices({
    page,
    perPage,
  });

  const rowData = missingDevices?.data.length
    ? missingDevices.data
        .sort((a, b) => {
          if (new Date(a.updatedAt) > new Date(b.updatedAt)) {
            return 1;
          }
          return -1;
        })
        .map((device) => ({
          ...device,
          status: mapServiceStatusToString(
            device.status,
            servicesStatuses.sinne,
          ),
        }))
    : [];

  if (status === 'pending') return <LinearProgress />;
  if (status === 'error') return <BasicAlert />;

  return (
    <Card>
      <CardHeader
        subheader="I denna lista ser ni alla enheter som är skickade till kund men som av
        olika skäl ska skickas tillbaka. Det kan t ex vara en felanmäld enhet
        eller en churned tjänst. När enheten kommit tillbaka så försvinner
        enheten från listan."
      />
      <Divider />
      <CardHeader
        subheader="Listan är sorterad enligt senast uppdaterad"
        sx={{ pb: 0 }}
      />
      <CardContent>
        {missingDevices.meta?.total === 0 ? (
          <Alert severity="info">
            <AlertTitle>
              <Typography variant="h5">Inga saknade enheter</Typography>
            </AlertTitle>
          </Alert>
        ) : (
          <SimpleTable columns={columns} rows={rowData} />
        )}
      </CardContent>
      <Pagination
        page={page}
        onPageChange={(_, page) => setPage(page)}
        rowsPerPage={perPage}
        onRowsPerPageChange={(event) =>
          setPerPage(parseInt(event.target.value, 10))
        }
        count={missingDevices.meta.total}
      />
    </Card>
  );
};
