import { Pagination } from '@/types/common';

type SetPageFunction = React.Dispatch<React.SetStateAction<number>>;

export const handlePageChange = (page: number, setPage: SetPageFunction) => {
  setPage(page);
};

export const handleRowsPerPageChange = (
  event: {
    target: { value: string };
  },
  setPerPage: (rowsPerPage: number) => void,
) => {
  const rowsPerPage = parseInt(event.target.value, 10);
  setPerPage(rowsPerPage);
};

export const getPaginatedPageParams = (params?: Pagination) => {
  if (
    params &&
    Object.prototype.hasOwnProperty.call(params, 'pagination') &&
    params.pagination
  ) {
    const { page, perPage } = params;
    return {
      pagination: true,
      page: page + 1,
      perPage,
    };
  } else if (
    params &&
    Object.prototype.hasOwnProperty.call(params, 'pagination') &&
    !params.pagination
  ) {
    return {
      pagination: false,
    };
  }

  return {};
};
