import React from 'react';

import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import { PartnerFormFields } from './PartnerFormFields';
import { SubmitButton } from './SubmitButton';

export const EditPartnerForm = () => {
  const queryClient = useQueryClient();

  return (
    <Box maxWidth="md">
      <Card>
        <CardHeader title="Redigera partner" />

        <Divider />
        <CardContent>
          <PartnerFormFields />
        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: 'end' }}>
          <SubmitButton disabled={Boolean(queryClient.isMutating())} />
        </CardActions>
      </Card>
    </Box>
  );
};
