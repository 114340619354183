import {
  Card,
  CardContent,
  Divider,
  Link,
  List,
  ListItem,
  Typography,
} from '@mui/material';

import { NumberedListItem } from '@/components/NumberedListItem';

import { CustomerProps } from '../../../../types';
import { ChecklistTitle, ListTitle } from '../../commonStyles';
import { EmailTemplate } from '../EmailTemplate';

// Second tab for Protection Checklist
export const SecondTab = ({
  customer,
  customerEmailAddresses,
  companyName,
  phoneNumbers,
}: CustomerProps) => {
  //indented this way to skip whitespace before every new line
  const emailTemplate = `Hej!

På uppdrag av ${customer.fullName} med personnummer ${customer.pin} har vi på ${companyName}
fått i uppdrag att spärra kundens ___________________ hos er.

För att säkerhetsställa att detta blir genomfört ber vi er därför bekräfta att spärren är genomförd
till e-post ${customer.email}.

Behöver ni komma i kontakt med oss ringer ni oss på ${phoneNumbers.customer}.

Med vänlig hälsning,
Spärrlinjen hos ${companyName}`;

  return (
    <Card>
      <ChecklistTitle variant="h6">
        Kund vill spärra kort och/eller mobilabonnemang
      </ChecklistTitle>
      <Divider />
      <CardContent>
        <List>
          <Typography variant="h6">Meddela kund:</Typography>

          <NumberedListItem
            number={1}
            text={
              'Be om ett telefonnummer samt kontrollera e-post så vi kan nå kund på utifall det behövs under processen samt. Om e-post ej finns, be om en'
            }
          />

          <NumberedListItem
            number={2}
            text={
              'Fråga vilka handlingar som har förlorats som kund önskar spärra och skriv ned dessa.'
            }
          />

          <NumberedListItem
            number={3}
            text={
              'Meddela kund att vi återkopplar när vi utfört spärrningarna.'
            }
          />

          <NumberedListItem
            number={4}
            text={
              'Meddela även kund att om vi spärrar bankkort så kommer banken skicka nya kort automatiskt till kunds folkbokföringsadress och det går inte att häva denna spärr om kund hittar sitt bankkort igen.'
            }
          />

          <ListTitle variant="h6">
            Obs: Om kund har Telia som operatör
          </ListTitle>
          <ListTitle variant="body1">
            Telia har i April 2021 infört en ny policy där de för att spärra
            telefon eller abonnemang kräver att kund själv legitimerar sig
            fysiskt i butik eller med BankID på deras hemsida Fråga om kund kan
            göra detta, alltså besöka butik eller om kund har BankID på annan
            telefon eller dator. Annars hjälp kund beställa personlig kod via{' '}
            <Link target="_blank" href="https://casey-ext.han.telia.se/2116">
              https://casey-ext.han.telia.se/2116
            </Link>
            . Meddela att koden skickas till kunds folkbokföringsadress och att
            kund kan ringa Telia's support när denna inkommit själv för
            spärrning eller kontakta oss igen så försöker vi hjälpa till.
            Meddela även kund att av säkerhetsskäl kan vi inte rekommendera
            Telia som operatör då just spärrning av mobil och telefon bör kunna
            göras enklare då de inte innebär ökad risk utan endast tvärtom.
          </ListTitle>
          <ListTitle variant="h6">Att göra:</ListTitle>
          <NumberedListItem
            number={1}
            text={
              'Ring till bolagen som utfärdat de handlingar kund vill spärra. Om utgivaren ej har fungerande spärrnummer t.ex. p.g.a. andra öppettider, så maila företaget och skriv detta:'
            }
          />
          <ListItem>
            <EmailTemplate
              customerId={customer.id}
              emailAddresses={customerEmailAddresses}
              template={emailTemplate}
            />
          </ListItem>
          <NumberedListItem
            number={2}
            text={
              'Ring tillbaks till kund och meddela om de åtgärdet som genomförts.'
            }
            isLast
          />
        </List>
      </CardContent>
    </Card>
  );
};
