import { useMutation } from '@tanstack/react-query';

import { DownloadExportFileParams, downloadExportFile } from '@/api/exports';
import { useSnackbar } from '@/hooks';

export const useDownloadExportFile = () => {
  const snackbar = useSnackbar();
  const mutation = useMutation({
    mutationFn: (values: DownloadExportFileParams) =>
      downloadExportFile(values),
    onSuccess: (response) => {
      if (response?.status === 204) {
        snackbar({
          type: 'info',
          message: 'Ingen data för vald period.',
        });
      }
    },
    onError: () => {
      snackbar({
        type: 'error',
        message: 'Misslyckades ladda ner enheterunderlag',
      });
    },
  });

  return mutation;
};
