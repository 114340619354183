import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  LinearProgress,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useQuery } from '@tanstack/react-query';
import { Form } from 'react-final-form';

import { fetchPartnersList } from '@/api/partners';
import { BasicAlert } from '@/components/BasicAlert';
import { Dropdown } from '@/components/form';
import { WeekPicker } from '@/components/form/WeekPicker';
import { usePrivateSettings } from '@/hooks';
import { getSnakeServiceName } from '@/modules/common/utils/services';
import { CamelCaseServiceName, SnakeCaseServiceName } from '@/types/services';
import { fieldRequired } from '@/utils/validation';

import { UseDownloadExportFile } from '../../../hooks/useDownloadExportFile';

type Props = {
  exportType: 'keyTag';
  grandIdSession: string;
  mutation: UseDownloadExportFile;
};

type FormValues = {
  start: string;
  partnerId: number;
};

type OnboardingSuffix = '.onboarding';
type OboardingExports = 'keytag';
type Onboarding = `${OboardingExports}${OnboardingSuffix}`;

const onboardingExports: Extract<CamelCaseServiceName, 'keyTag'>[] = ['keyTag'];

export const OnboardingExportForm = ({
  exportType,
  grandIdSession,
  mutation,
}: Props) => {
  const theme = useTheme();

  const { servicesNames } = usePrivateSettings();

  const {
    data: partners,
    isLoading,
    isError: errorPartners,
  } = useQuery({
    queryKey: ['fetchPartners', 'onboarding'],
    queryFn: fetchPartnersList,
  });

  const handleSubmit = (values: FormValues) => {
    const params = {
      ...values,
      format: 'excel',
      grandIdSession,
    };
    const type = onboardingExports.reduce((acc, service) => {
      if (service === exportType) {
        acc = `${exportType.toLocaleLowerCase()}.onboarding` as Onboarding;
      }
      return acc;
    }, '' as Onboarding);

    if (!type) {
      return;
    }

    mutation.mutate({
      type,
      params,
      whiteLableExportName: servicesNames[exportType],
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      {({ handleSubmit, invalid }) => (
        <Box maxWidth={theme.breakpoints.values.lg}>
          {mutation.isPending && <LinearProgress />}
          <Card component="form" onSubmit={handleSubmit}>
            <CardHeader title="Onboarding" />
            <Divider />
            <CardContent
              sx={{
                padding: 0,
                '&.MuiCardContent-root:last-child': { padding: 0 },
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm
                  sx={{
                    padding: 2,
                  }}
                >
                  <WeekPicker name="start" label="Vecka" required />

                  {!!partners &&
                    (errorPartners ? (
                      <BasicAlert />
                    ) : (
                      <Dropdown
                        name="partnerId"
                        label="Partner"
                        fieldProps={{
                          validate: fieldRequired,
                        }}
                        placeholder={isLoading ? 'Laddar...' : 'Partner'}
                        options={partners.map((partner) => ({
                          value: partner.id,
                          text: partner.name,
                        }))}
                      />
                    ))}
                </Grid>
                <Divider orientation="vertical" flexItem />

                <Grid
                  item
                  xs={12}
                  sx={{
                    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                    padding: 2,
                  }}
                >
                  <Button
                    disabled={invalid || isLoading}
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    Ladda ner
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      )}
    </Form>
  );
};
