import { z } from 'zod';

import { ApiCollectionResponse } from './common';

const basicPriceItem = z.object({
  '@id': z.string(),
  '@type': z.string(),
  currency: z.string(),
  priceInCurrency: z.number(),
  priceWithVatInCurrency: z.number(),
  vat: z.number(),
});

export const priceItem = basicPriceItem.extend({
  active: z.boolean().optional(),
  id: z.number().optional(),
  service: z.string().optional(),
});

// Price type ("FEE", "PRICE") is not included in response when getting
// the price on a service. It is only included when getting all prices.
// ex. when fetching sinne,
// installationFee: Object { "@id": "/prices/3", "@type": "Price", vat: 6, … }
// "@id": "/prices/3"
// "@type": "Price"
// currency: "SEK"
// priceInCurrency: 472.27
// priceWithVatInCurrency: 500.6
// vat: 6
export const priceItemWithType = priceItem.extend({
  type: z.union([z.literal('PRICE'), z.literal('FEE')]),
});

export type BasicPriceItem = z.infer<typeof basicPriceItem>;
export type PriceItem = z.infer<typeof priceItem>;
export type PriceItemWithType = z.infer<typeof priceItemWithType>;

export type PricesResponse<T> = ApiCollectionResponse<T>;
