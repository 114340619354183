import { LinearProgress } from '@mui/material';
import findKey from 'lodash/findKey';
import { useNavigate, useParams } from 'react-router-dom';

import { BasicAlert } from '@/components/BasicAlert';
import { PageWrapper } from '@/components/PageWrapper';
import { getElementFromArray } from '@/helpers/getElementFromArray';
import { usePrivateSettings, useSnackbar } from '@/hooks';
import { AlarmDetailsView, useAlarmDetails } from '@/modules/alarms';
import { UpdateAlarmStatusPayload } from '@/types/alarms';

export const BREADCRUMBS_CONFIG = [
  { label: 'Larmcentral', url: '/alarms' },
  { label: 'Aktuellt alarm' },
];

type AlarmQueryParams = {
  id: string;
};

const AlarmDetails = () => {
  const { id } = useParams<AlarmQueryParams>();

  const navigate = useNavigate();
  const snackbar = useSnackbar();

  const { servicesStatuses } = usePrivateSettings();

  const alarmDetailsHookResponse = useAlarmDetails({
    id: id!,
    onAlarmMutationSuccess() {
      snackbar({ type: 'success', message: 'Uppdatering lyckades' });
    },
    onAlarmMutationError() {
      snackbar({ type: 'error', message: 'Uppdatering misslyckades' });
    },
  });

  const {
    isLoading,
    hasError,
    alarm,
    deviceData,
    contactPersonList,
    sortedEvents,
  } = getElementFromArray(alarmDetailsHookResponse[0]);
  const { alarmMutation } = getElementFromArray(alarmDetailsHookResponse[1]);

  const submitHandler = (formValues: UpdateAlarmStatusPayload) => {
    const handeleldAlarmStatusKey: string | number | undefined = findKey(
      servicesStatuses.handleableAlarm.statuses,
      (status) => status === 'Bearbetad',
    );

    if (handeleldAlarmStatusKey) {
      const transformed: UpdateAlarmStatusPayload = {
        ...formValues,
        remedyComment: formValues?.remedyComment?.trim(),
        reasonComment: formValues?.reasonComment?.trim(),
        status: parseInt(handeleldAlarmStatusKey, 10),
      };

      alarmMutation?.mutateAsync(transformed).then(() => navigate('/alarms'));
    } else {
      console.error("Status 'Bearbetad' finns inte.");
    }
  };

  const alarmDataReady =
    alarm && deviceData && contactPersonList && sortedEvents;

  if (isLoading) {
    return (
      <PageWrapper pageTitle="Aktuellt larm" breadCrumbs={BREADCRUMBS_CONFIG}>
        <LinearProgress />
      </PageWrapper>
    );
  }

  return (
    <PageWrapper pageTitle="Aktuellt larm" breadCrumbs={BREADCRUMBS_CONFIG}>
      {hasError ? (
        <BasicAlert />
      ) : (
        <>
          {alarmDataReady && (
            <AlarmDetailsView
              alarmId={id!}
              alarm={alarm}
              deviceData={deviceData}
              contactPersonList={contactPersonList}
              sortedEvents={sortedEvents}
              onUpdateAlarmStatus={submitHandler}
            />
          )}
        </>
      )}
    </PageWrapper>
  );
};

export default AlarmDetails;
