import { CardTable } from '@/components/CardTable';

export const CardsSection = ({ serviceId }: { serviceId: number }) => {
  return (
    <CardTable
      marginBottom="0"
      productType={'CoProtection'}
      productIri={`/co_protections/${serviceId}}`}
    />
  );
};
