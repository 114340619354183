import { useGetCustomLetters } from '@/hooks/useGetCustomLetters';
import { CustomTemplate } from '@/types/files';

export const useCustomTemplateTypeOptions = () => {
  const { data } = useGetCustomLetters();

  return (
    data?.map((template: CustomTemplate) => ({
      value: template.name,
      text: template.name,
    })) || []
  );
};
