import { LinearProgress } from '@mui/material';
import { isString } from 'lodash';

import { ProtectionCampaignForm } from './ProtectionCampaignForm';
import { useGetProtectionCampaign } from './hooks/useGetProtectionCampaign';
import { getDefaultFormValuesForProtectionCampaign } from './utils/getDefaultFormValues';

type Props = {
  campaignToEdit?: string | null;
  handleVisibility: () => void;
};
export const ProtectionCampaignFormWrapper = ({
  campaignToEdit,
  handleVisibility,
}: Props) => {
  const { data, isLoading } = useGetProtectionCampaign({
    campaignIri: campaignToEdit,
  });

  const editingExistingCampaign = isString(campaignToEdit);

  const defaultFormValues = getDefaultFormValuesForProtectionCampaign(
    data,
    editingExistingCampaign,
  );

  if (editingExistingCampaign && isLoading) {
    return <LinearProgress />;
  }

  if (defaultFormValues) {
    return (
      <ProtectionCampaignForm
        defaultFormValues={defaultFormValues}
        campaignToEdit={campaignToEdit}
        handleVisibility={handleVisibility}
      />
    );
  }

  return null;
};
