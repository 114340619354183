import React, { useState } from 'react';

import { Box, Button, LinearProgress } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';

import { getProtectedUploads, protectedDownload } from '@/api/files';
import { BasicAlert } from '@/components/BasicAlert';
import { DebouncedPinSearchField } from '@/components/DebouncedPinSearchField/DebouncedPinSearchField';
import { Pagination } from '@/components/Pagination';
import { SimpleCard } from '@/components/SimpleCard';
import { SimpleTable, SimpleTableColumn } from '@/components/SimpleTable';
import { PROTECTED_FILE_TYPES } from '@/constants/ProtectedFiles';
import { getCamelCaseServiceName } from '@/helpers/tsHelpers';
import { usePrivateSettings } from '@/hooks';
import useUserRoles from '@/hooks/useUserRoles';
import {
  handlePageChange,
  handleRowsPerPageChange,
} from '@/modules/common/utils/pagination';
import { ProtectionItem } from '@/types/services';
import { checkRolesAccess } from '@/utils/authorization';

type Props = {
  serviceDetails: ProtectionItem;
};

export const FileListCard = ({ serviceDetails }: Props) => {
  const userRoles = useUserRoles();
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(100);
  const { servicesNames } = usePrivateSettings();
  const [filteredPins, setFilteredPins] = useState('');

  const protectedPin = serviceDetails
    ? [...Array(5).keys()].reduce((acc: string[], cVal) => {
        const pinIndex = `pin${cVal + 1}` as `pin${1 | 2 | 3 | 4 | 5}`;
        acc.push(serviceDetails[pinIndex] ?? '');
        return acc;
      }, [])
    : [];

  const downLoadMutation = useMutation({ mutationFn: protectedDownload });
  const { data, isFetching, isError } = useQuery({
    queryKey: ['protectedFilesList', protectedPin, filteredPins, page, perPage],

    queryFn: async () => {
      const { data } = await getProtectedUploads({
        page: page + 1,
        perPage,
        protectedPin: filteredPins.length ? filteredPins : protectedPin,
      });
      return { files: data['hydra:member'], total: data['hydra:totalItems'] };
    },

    initialData: { files: [], total: 0 },
  });

  const columns: SimpleTableColumn[] = [
    {
      label: 'Tjänst',
      renderer: (row) =>
        servicesNames[getCamelCaseServiceName(row.pinSourceEntity)],
    },
    {
      label: 'Tjänste-ID',
      renderer: (row) => row.entityId,
    },
    {
      label: 'Personnummer',
      renderer: (row) => row.protectedPin,
    },
    {
      label: 'Filtyp',
      renderer: (row) =>
        PROTECTED_FILE_TYPES.find((element) => element.value === row.type)
          ?.text,
    },
    {
      label: 'Filnamn',
      renderer: (row) => row.name,
    },
  ];

  checkRolesAccess(userRoles, 'protectedFiles.download') &&
    columns.push({
      label: 'Visa fil',
      renderer: (row) => (
        <Button
          variant="outlined"
          disabled={downLoadMutation.isPending}
          onClick={() => {
            const fileType = PROTECTED_FILE_TYPES.find(
              (element) => element.value === row.type,
            );
            downLoadMutation.mutate({
              id: row.id,
              type: fileType?.text ?? 'Unknown file type',
              pin: row.protectedPin,
            });
          }}
        >
          Visa
        </Button>
      ),
    });

  return (
    <SimpleCard
      title="Uppladdade Filer"
      cardContent={
        <>
          {!serviceDetails && (
            <Box alignItems="center" marginBottom={1}>
              <DebouncedPinSearchField
                onChange={(v: string) => setFilteredPins(v)}
                value={filteredPins}
              />
            </Box>
          )}
          {isFetching ? (
            <LinearProgress />
          ) : isError ? (
            <BasicAlert />
          ) : (
            <>
              <SimpleTable rows={data.files} columns={columns} />
              <Pagination
                count={data.total}
                page={page}
                rowsPerPage={perPage}
                onPageChange={(_: any, page: number) =>
                  handlePageChange(page, setPage)
                }
                onRowsPerPageChange={(event: { target: { value: string } }) =>
                  handleRowsPerPageChange(event, setPerPage)
                }
              />
            </>
          )}
        </>
      }
    />
  );
};
