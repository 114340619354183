import camelCase from 'lodash/camelCase';
import kebabCase from 'lodash/kebabCase';
import snakeCase from 'lodash/snakeCase';

type CamelCaseToKebabCase<T extends string> = T extends `${infer F}${infer R}`
  ? F extends Uppercase<F>
    ? `${F extends '-' ? '' : '-'}${Lowercase<F>}${CamelCaseToKebabCase<R>}`
    : `${F}${CamelCaseToKebabCase<R>}`
  : T;

type CamelCaseToSnakeCase<T extends string> = T extends `${infer F}${infer R}`
  ? F extends Uppercase<F>
    ? `${F extends '_' ? '' : '_'}${Lowercase<F>}${CamelCaseToSnakeCase<R>}`
    : `${F}${CamelCaseToSnakeCase<R>}`
  : T;

type CamelCaseToPascalCase<T extends string> = T extends `${infer F}${infer R}`
  ? `${Uppercase<F>}${R}`
  : T;

export type CamelCaseServiceName =
  | 'firetext'
  | 'keyTag'
  | 'sticker'
  | 'protection'
  | 'coProtection'
  | 'homegate'
  | 'sinne';

export type ServicesWithPricePlans = Exclude<
  CamelCaseServiceName,
  'homegate' | 'sinne'
>;

export type KebabCaseServiceName = CamelCaseToKebabCase<CamelCaseServiceName>;
export type PascalCaseServiceName = CamelCaseToPascalCase<CamelCaseServiceName>;
export type SnakeCaseServiceName = CamelCaseToSnakeCase<CamelCaseServiceName>;
export type PluralServiceName<T extends string> = `${T}s`;

export type AllServiceNames =
  | CamelCaseServiceName
  | KebabCaseServiceName
  | PascalCaseServiceName
  | SnakeCaseServiceName;

export const getCamelServiceName = <T = CamelCaseServiceName>(
  serviceName: AllServiceNames,
): T => {
  return camelCase(serviceName) as T;
};

export const getSnakeServiceName = (
  serviceName: AllServiceNames,
): SnakeCaseServiceName => {
  return snakeCase(serviceName) as SnakeCaseServiceName;
};

export const getKebabServiceName = (
  serviceName: AllServiceNames,
): KebabCaseServiceName => {
  return kebabCase(serviceName) as KebabCaseServiceName;
};

export const getPluralServiceName = <T extends AllServiceNames>(
  serviceName: AllServiceNames,
): PluralServiceName<T> => {
  return `${serviceName}s` as PluralServiceName<T>;
};
