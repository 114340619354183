import GetAppIcon from '@mui/icons-material/GetApp';
import {
  Card,
  CardContent,
  CardHeader,
  Skeleton,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useQuery } from '@tanstack/react-query';
import camelCase from 'lodash/camelCase';

import { downloadFile, getAgreementsByCustomer } from '@/api/agreements';
import { LabelAndContentRow } from '@/components';
import { queryKeys } from '@/constants/queryKeys';
import { usePrivateSettings, useSnackbar } from '@/hooks';
import { CustomerAgreement } from '@/types/agreements';
import { Customer } from '@/types/customers';

type Props = {
  customer: Customer;
};

export const Agreements = ({ customer }: Props) => {
  const snackbar = useSnackbar();

  const { servicesNames } = usePrivateSettings();

  const {
    data: agreements,
    isLoading,
    isSuccess,
  } = useQuery({
    queryKey: [queryKeys.getAgreementsByCustomer, customer.id],

    queryFn: () => getAgreementsByCustomer(customer.id!),
  });

  async function handleDownloadAgreement(agreement: CustomerAgreement) {
    try {
      const { id, name } = agreement;
      await downloadFile({ id, filename: name || '' });
    } catch (e) {
      snackbar({
        type: 'error',
        message: 'Det gick inte att ladda ner avtalet',
      });
    }
  }

  // async function handleViewAgreement(agreement) {
  //   try {
  //     await viewAgreement(agreement.id);
  //   } catch (e) {
  //     console.log(e);
  //     dispatch(
  //       openSnackbar({
  //         type: 'error',
  //         message: 'Det gick inte att ladda ner avtalet',
  //       })
  //     );
  //   }
  // }

  return (
    <Card>
      <CardHeader
        style={{
          height: '56px',
          borderBottom: 'solid 1px rgba(0, 0, 0, 0.12)',
        }}
        title="Avtal"
      />
      <StyledCardContent>
        {isLoading ? (
          <Skeleton />
        ) : isSuccess && agreements?.length > 0 ? (
          agreements.map((agreement, i, arr) => (
            <LabelAndContentRow
              key={agreement.id}
              divider={i !== arr.length - 1}
              label={
                agreement.fileType === 'document' ? 'Skriftlig fil' : 'Ljudfil'
              }
              secondaryText={
                // @ts-ignore
                servicesNames[camelCase(agreement.type)] ?? agreement.type
              }
              content={
                <FlexContainerDiv>
                  <Typography>{agreement.name}</Typography>
                  {/*
                    Temporarily disabled
                  <OpenInNew
                    onClick={() => handleViewAgreement(agreement)}
                    className={classes.downloadIcon}
                  /> */}
                  <DownloadIcon
                    color="primary"
                    onClick={() => handleDownloadAgreement(agreement)}
                  />
                </FlexContainerDiv>
              }
            />
          ))
        ) : (
          <Typography variant="h4" style={{ padding: '16px 0 0 16px' }}>
            Inga avtal
          </Typography>
        )}
      </StyledCardContent>
    </Card>
  );
};

export const StyledCardContent = styled(CardContent)({
  padding: '1px 0 0 0',
});

export const FlexContainerDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  '& > *': {
    marginLeft: theme.spacing(1),
  },
}));

export const DownloadIcon = styled(GetAppIcon)(({ theme }) => ({
  color: theme.palette.text.primary,
  cursor: 'pointer',
  '&:hover': {
    color: 'black',
  },
}));
