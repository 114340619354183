import { z } from 'zod';

import { ApiTaskTeamNames } from '@/modules/tasks/constants/tasksModalsConstants';

import { ApiCollectionResponse } from './common';
import { basicCustomerDataSchema } from './customers';

const taskUser = z.object({
  '@id': z.string(),
  '@type': z.literal('User'),
  name: z.string(),
  username: z.string(),
  email: z.string(),
});

export type TaskRelatedUser = z.infer<typeof taskUser>;

export const getTasksParamSchema = z.object({
  '@id': z.string(),
  '@type': z.literal('Task'),
  id: z.number(),
  startDate: z.string(),
  deadline: z.string(),
  text: z.string(),
  type: z.string(),
  status: z.string(),
  customer: basicCustomerDataSchema,
  createdAt: z.string(),
  createdBy: taskUser,
  claimedAt: z.string(),
  claimedBy: taskUser,
  updatedAt: z.string(),
  updatedBy: taskUser,
  team: z.enum([
    ApiTaskTeamNames.CUSTOMER_SUPPORT,
    ApiTaskTeamNames.ADMINISTRATOR,
  ]),
});

export type TaskItem = z.infer<typeof getTasksParamSchema>;

export type TasksResponse = ApiCollectionResponse<TaskItem>;
