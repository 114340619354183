import React from 'react';

import { TextInput, normalizeToNumbersOnly } from '@/components/form';
import { fieldRequired } from '@/utils/validation';

export const CardFormFields = () => {
  return (
    <>
      <TextInput
        label="För-/Efternamn"
        name="fullName"
        required
        fieldProps={{
          validate: fieldRequired,
        }}
      />
      <TextInput
        label="Personnummer"
        name="pin"
        required
        fieldProps={{
          validate: fieldRequired,
          parse: normalizeToNumbersOnly,
          format: (value) => (value ? normalizeToNumbersOnly(value) : ''),
        }}
      />
      <TextInput
        label="Typ"
        name="type"
        required
        fieldProps={{
          validate: fieldRequired,
        }}
      />
      <TextInput
        label="Kortuställare"
        name="brand"
        required
        fieldProps={{
          validate: fieldRequired,
        }}
      />
      <TextInput
        label="4a Sista"
        name="ks"
        required
        fieldProps={{
          validate: fieldRequired,
        }}
      />
    </>
  );
};
