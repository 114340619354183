import React from 'react';

import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  LinearProgress,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Form } from 'react-final-form';

import { createPrice, getPriceById, updatePrice } from '@/api/prices';
import {
  Checkboxes,
  Dropdown,
  ServicesWithPricesEntitiesDropdown,
  TextInput,
} from '@/components/form';
import { PRICE_TYPES } from '@/constants';
import { queryKeys } from '@/constants/queryKeys';
import { getFeeWithVat } from '@/helpers';
import { parseToNumbers } from '@/helpers/parsing';
import { useSnackbar } from '@/hooks';
import { PriceItemWithType } from '@/types/prices';
import { fieldRequired } from '@/utils/validation';

type Props = {
  onClose: () => void;
  priceToEdit?: string;
  queryKey: any[];
  type: keyof typeof PRICE_TYPES;
};

export const PriceForm = ({ onClose, queryKey, priceToEdit, type }: Props) => {
  const queryClient = useQueryClient();
  const snackbar = useSnackbar();

  const { data, isLoading, isError } = useQuery({
    queryKey: [queryKeys.getPricesById, priceToEdit],
    queryFn: () => getPriceById(priceToEdit!),
    enabled: !!priceToEdit,
  });

  React.useEffect(() => {
    if (isError) {
      snackbar({
        type: 'error',
        message: 'Kunde inte hämta data',
      });
      onClose();
    }
  }, [isError, onClose, snackbar]);

  const mutationFunction = priceToEdit ? updatePrice : createPrice;

  const priceMutation = useMutation({
    //@ts-expect-error mutationFunction types mismatch
    mutationFn: mutationFunction,
    onSuccess: (response) => {
      priceToEdit
        ? queryClient.setQueryData(
            queryKey,
            (oldData: Omit<PriceItemWithType, 'type'>[] | undefined) => {
              const updatedData = oldData ? [...oldData] : [];
              const priceToUpdate = updatedData.findIndex(
                (oldPrice) => oldPrice['@id'] === response['@id'],
              );

              updatedData.splice(priceToUpdate, 1, response);

              return updatedData;
            },
          )
        : queryClient.setQueryData(
            queryKey,
            (oldData: Omit<PriceItemWithType, 'type'>[] | undefined) => {
              const updatedData = oldData ? [...oldData] : [];
              updatedData.push(response);
              return [...updatedData];
            },
          );
      snackbar({
        type: 'success',
        message: `Pris ${priceToEdit ? 'uppdaterad' : 'skapad'}!`,
      });
      onClose();
    },
    onError: (e) => {
      // eslint-disable-next-line no-console
      console.log('ERROR', e);
      snackbar({
        type: 'error',
        message: `Kunde inte ${priceToEdit ? 'uppdatera' : 'skapa'} pris`,
      });
    },
  });

  return (
    <Form
      onSubmit={(values: any) => {
        if (priceToEdit) {
          priceMutation.mutate({ id: values['@id'], active: values.active });
        } else {
          values.type = type;
          priceMutation.mutate(values);
        }
      }}
      initialValues={data ?? { active: false }}
      render={({ handleSubmit, values, pristine }) => (
        <form onSubmit={handleSubmit}>
          <Card elevation={0}>
            <CardContent>
              {isLoading && <LinearProgress />}
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ServicesWithPricesEntitiesDropdown
                    disabled={!!priceToEdit}
                    label={isLoading ? 'Hämtar data...' : 'Tjänst'}
                    fieldProps={{
                      validate: fieldRequired,
                    }}
                    name="service"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    disabled={!!priceToEdit}
                    type="number"
                    label={
                      !!priceToEdit && isLoading
                        ? 'Hämtar data...'
                        : 'Avgift (exkl moms)'
                    }
                    name={priceToEdit ? 'priceInCurrency' : 'price'}
                    fieldProps={{
                      parse: parseToNumbers,
                      validate: fieldRequired,
                    }}
                  />
                  <TextInput
                    disabled={!!priceToEdit}
                    label={
                      !!priceToEdit && isLoading ? 'Hämtar data...' : 'Valuta'
                    }
                    name="currency"
                    fieldProps={{
                      validate: fieldRequired,
                      defaultValue: 'SEK',
                    }}
                    style={{ display: 'none' }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Dropdown
                    disabled={!!priceToEdit}
                    label={
                      !!priceToEdit && isLoading
                        ? 'Hämtar data...'
                        : 'Skatteprocent'
                    }
                    name="vat"
                    options={[
                      { text: '6 %', value: 6 },
                      { text: '12 %', value: 12 },
                      { text: '25 %', value: 25 },
                    ]}
                    fieldProps={{
                      validate: fieldRequired,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    type="number"
                    fieldProps={{
                      format: () =>
                        getFeeWithVat(
                          priceToEdit ? values.priceInCurrency : values.price,
                          values.vat,
                        ),
                    }}
                    label={
                      !!priceToEdit && isLoading
                        ? 'Hämtar data...'
                        : 'Avgift (inkl moms)'
                    }
                    name="priceWithVatInCurrency"
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <Checkboxes
                    options={{
                      label:
                        !!priceToEdit && isLoading ? 'Hämtar data...' : 'Aktiv',
                      value: '1',
                    }}
                    name="active"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions style={{ justifyContent: 'space-between' }}>
              <Button onClick={onClose} color="primary" variant="outlined">
                Avbryt
              </Button>
              <Button
                disabled={pristine}
                color="primary"
                type="submit"
                variant="contained"
              >
                Spara
              </Button>
            </CardActions>
          </Card>
        </form>
      )}
    />
  );
};
