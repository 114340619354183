import React from 'react';

import { Badge, CircularProgress, ListItemIcon } from '@mui/material';
import { useIsFetching, useQuery } from '@tanstack/react-query';
import { NavLink } from 'react-router-dom';

import { MenuButton } from '@/layouts/Main/components/Sidebar/components/SidebarNav/components/MenuButton';
import { MenuListItem } from '@/layouts/Main/components/Sidebar/components/SidebarNav/components/MenuListItem';

type Props = {
  title: string;
  href: string;
  queryFn: (params: any) => Promise<any>;
  params: object;
  refetchQueryKey: string[];
};

export const MenuItemWithCounter = ({
  title,
  href,
  queryFn: queryFunction,
  params,
  refetchQueryKey,
}: Props) => {
  const isFetching = useIsFetching({
    queryKey: refetchQueryKey,
  });

  const { data, isLoading, refetch } = useQuery({
    queryKey: [params],
    queryFn: async () => await queryFunction(params),
  });

  React.useEffect(() => {
    refetch();
  }, [isFetching]);

  return (
    <MenuListItem>
      <MenuButton component={NavLink} to={href}>
        <span style={{ flexGrow: 1 }}>{title}</span>
        <ListItemIcon>
          {isLoading ? (
            <CircularProgress size="1rem" />
          ) : (
            <Badge
              showZero
              badgeContent={data['hydra:totalItems']}
              color="error"
            />
          )}
        </ListItemIcon>
      </MenuButton>
    </MenuListItem>
  );
};
