import React from 'react';

import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  isAfter,
  isSameDay,
  parseISO,
  setHours,
  setMinutes,
  startOfTomorrow,
} from 'date-fns';
import { Form } from 'react-final-form';

import { createTask } from '@/api/tasks';
import { queryKeys } from '@/constants/queryKeys';
import { getTypeKey } from '@/helpers';
import { mapDateFieldsValues } from '@/helpers/dates';
import { usePrivateSettings, useSnackbar } from '@/hooks';
import { USER_TYPES } from '@/modules/common/constants/entitiyNamesMap';

import { TaskTeamTypes } from '../../constants/tasksModalsConstants';
import { CreateTaskModalFields } from './CreateTaskModalFields';
import { CreateTaskModalProps, FormValues } from './types';

export const CreateTaskModal = ({
  open,
  customer,
  handleVisibility,
  isBarringService,
  defaults,
  isTaskTeamView,
}: CreateTaskModalProps) => {
  const snackbar = useSnackbar();

  const queryClient = useQueryClient();
  const { servicesTypes } = usePrivateSettings();

  const dateTomorrow = startOfTomorrow();

  const createTaskMutation = useMutation({
    mutationFn: (values: FormValues) => {
      const team = parseInt(values.team);

      return createTask({
        ...values,
        customerId: customer?.id,
        team,
      });
    },
  });

  const initialValues = React.useMemo(
    () => ({
      pin: customer?.pin,
      type: '100',
      ...(isBarringService && {
        type: getTypeKey(servicesTypes, 'task', 'Spärrlinje', {
          nestedKey: TaskTeamTypes.CUSTOMER_SUPPORT.toString(),
        }),
        team: TaskTeamTypes.CUSTOMER_SUPPORT.toString(),
      }),
      ...(defaults && { team: defaults.team.toString() }),
      ...(defaults && { status: defaults.status.toString() }),
    }),
    [customer?.pin, isBarringService, defaults],
  );

  const handleSubmit = (values: FormValues) => {
    const now = new Date();
    const fourPM = setMinutes(setHours(now, 16), 0); // set the time to 4pm

    if (
      values.deadline &&
      isAfter(now, fourPM) &&
      isSameDay(now, parseISO(values.deadline.toISOString()))
    ) {
      values.deadline = dateTomorrow;
    }

    values = mapDateFieldsValues(values);

    createTaskMutation.mutate(values, {
      onSuccess: () => {
        handleVisibility();
        queryClient.invalidateQueries({
          queryKey: [queryKeys.getTasks],
        });
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey[0] === queryKeys.getNotes,
        });
        snackbar({
          type: 'success',
          message: 'Uppgift skapad.',
          autoHide: false,
        });
      },
      onError: () => {
        snackbar({
          type: 'error',
          message: 'Något gick fel, uppgiften kunde inte skapas.',
          autoHide: false,
        });
      },
    });
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      subscription={{ submitting: true, pristine: true, values: true }}
      render={({ handleSubmit, submitting, pristine, values, form }) => (
        <Dialog onClose={handleVisibility} open={open} maxWidth="xs">
          <DialogTitle>
            {`Nytt ärende till ${
              Number(values.team) === TaskTeamTypes.CUSTOMER_SUPPORT
                ? USER_TYPES.customerService
                : USER_TYPES.admin
            }`}
          </DialogTitle>
          {isBarringService && (
            <>
              <Divider />
              <DialogContent>
                <Alert severity="info">
                  <DialogContentText>
                    Ta inga ärenden gällande tjänsten, uppsägning eller
                    avgiftsfrågor. Be inringaren ringa in till kundservice för
                    dessa ärenden. Ta endast ärende till kundservice om
                    uppgifter i kundprofilen saknas eller behövs ändras på under
                    kontakt/mobiler/kort.
                  </DialogContentText>
                </Alert>
              </DialogContent>
            </>
          )}
          <Divider />
          <form onSubmit={handleSubmit} noValidate autoComplete="off">
            <DialogContent>
              <CreateTaskModalFields
                isTaskTeamView={isTaskTeamView}
                form={form}
                isBarringService={isBarringService}
                values={values}
                defaults={defaults}
              />
            </DialogContent>
            <Divider />
            <StyledDialogActions>
              <Button onClick={handleVisibility}>Avbryt</Button>
              <Button
                disabled={submitting || pristine}
                color="primary"
                type="submit"
                variant="contained"
              >
                Skapa ärende
              </Button>
            </StyledDialogActions>
          </form>
        </Dialog>
      )}
    />
  );
};

const StyledDialogActions = styled(DialogActions)`
  justify-content: space-between;
`;
