import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { usePrivateSettings } from '@/hooks';
import { Textfield } from '@/modules/components';
import { FloatInput } from '@/modules/components/FloatInput/FloatInput';

import { getKickbackFields, getTickFields } from '../../helpers';
import { FormValues } from './PartnerForm';

export const PartnerFormFields = () => {
  const { servicesNames, services } = usePrivateSettings();

  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext<FormValues>();

  const kickbackEnabled = watch('kickback') === 'true';
  const tickEnabled = watch('tick') === 'true';

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Textfield
                inputProps={{ ...field }}
                fullWidth
                label="Namn"
                error={errors.name}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Controller
            name="kickback"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <FormControl fullWidth>
                <FormLabel>Kickback</FormLabel>
                <Select
                  {...field}
                  onChange={(event: SelectChangeEvent<'true' | 'false'>) => {
                    const value = event.target.value as 'true' | 'false';
                    field.onChange(value);
                  }}
                  startAdornment={
                    <InputAdornment position="start" sx={{ cursor: 'pointer' }}>
                      <Tooltip title="Denna struktur innebär att partnern får ersättning vid netto försäljning av tjänster på flaggade kunder.">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </InputAdornment>
                  }
                >
                  <MenuItem value="true">Ja</MenuItem>
                  <MenuItem value="false">Nej</MenuItem>
                </Select>
              </FormControl>
            )}
          />
          {/* Kickback inputs */}
          {kickbackEnabled && (
            <FormControl fullWidth>
              <FormLabel component="legend">
                Inställningar för kickback
              </FormLabel>
              <FormGroup>
                <Controller
                  name="daysWithKickback"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Textfield
                      inputProps={{
                        ...field,
                        startAdornment: (
                          <InputAdornment position="start">
                            <Tooltip
                              title="Denna siffra representerar
                          hur länge partnern har rätt att få
                          ersättning på ny försäljning på
                          deras kunder från att kunden blev
                          flaggad av denna partnern?
                          Varning: När denna tid har gått
                          kommer kunden automatiskt
                          avflaggas från partnern, så var
                          försiktiga om ni minskar denna
                          siffra"
                            >
                              <HelpOutlineIcon />
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                      label="Dagar med kickback"
                      error={errors.daysWithKickback}
                    />
                  )}
                />
                {getKickbackFields(servicesNames, services).map((service) => {
                  if (service.enabled) {
                    return (
                      <FloatInput
                        control={control}
                        name={service.name}
                        label={service.label}
                        key={service.name}
                      />
                    );
                  }
                })}
              </FormGroup>
            </FormControl>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="tick"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <FormControl fullWidth>
                <FormLabel>Tick</FormLabel>
                <Select
                  {...field}
                  onChange={(event: SelectChangeEvent<'true' | 'false'>) => {
                    const value = event.target.value as 'true' | 'false';
                    field.onChange(value);
                  }}
                  startAdornment={
                    <InputAdornment position="start" sx={{ cursor: 'pointer' }}>
                      <Tooltip title="Denna struktur innebär att partnern får ersättning baserat på alla deras flaggade kunders månadsavgifter.">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </InputAdornment>
                  }
                >
                  <MenuItem value="true">Ja</MenuItem>
                  <MenuItem value="false">Nej</MenuItem>
                </Select>
              </FormControl>
            )}
          />
          {/* Tick (Revenue share) inputs */}
          {tickEnabled && (
            <FormControl fullWidth>
              <FormLabel component="legend">Inställningar för tick</FormLabel>
              <FormGroup>
                <Controller
                  name="daysWithTick"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Textfield
                      inputProps={{
                        ...field,
                        startAdornment: (
                          <InputAdornment position="start">
                            <Tooltip
                              title="Denna siffra representerar
                            hur många dagar partnern har rätt
                            att få ersättning baserat på
                            kundens omsättning från att
                            kunden blev kopplad till partnern.
                            Varning: När denna tid har gått
                            kommer kunden automatiskt
                            avflaggas från partnern, så var
                            försiktiga om ni minskar denna
                            siffra"
                            >
                              <HelpOutlineIcon />
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                      label="Dagar med tick"
                      error={errors.daysWithKickback}
                    />
                  )}
                />
                <FormGroup>
                  {getTickFields(servicesNames, services).map((service) => {
                    if (service.enabled) {
                      return (
                        <FloatInput
                          key={service.name}
                          control={control}
                          name={service.name}
                          label={service.label}
                        />
                      );
                    }
                  })}
                </FormGroup>
              </FormGroup>
            </FormControl>
          )}
        </Grid>
      </Grid>
    </>
  );
};
