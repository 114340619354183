import { PageWrapper } from '@/components';
import { ResellerList } from '@/modules/ResellerPortal/components';

const ResellerAccounts = () => {
  return (
    <PageWrapper
      pageTitle="Återförsäljarkonton"
      breadCrumbs={[{ label: 'Återförsäljare' }, { label: 'Konton' }]}
    >
      <ResellerList />
    </PageWrapper>
  );
};

export default ResellerAccounts;
