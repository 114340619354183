import React from 'react';

import { LinearProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';
import { useLocation, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';

import { getCustomerById } from '@/api/customers';
import { PageHeader } from '@/components/PageHeader';
import { normalizeToPersonNumberShort } from '@/components/form';
import { CustomerCategoryTypes } from '@/constants';
import { getChurnReasonKey } from '@/helpers/Types';
import { mapDateFieldsValues } from '@/helpers/dates';
import { getCamelCaseServiceName } from '@/helpers/tsHelpers';
import { useAddServiceToCustomer, usePrivateSettings } from '@/hooks';
import { getCamelServiceName } from '@/modules/common/utils/services/getServicesNames';
import handleCampaignReset from '@/modules/services/components/Create/utils/handleCampaignReset';
import { HomegateTemplate } from '@/types/campaigns';
import { Customer } from '@/types/customers';

import { queryKeys } from '../../../../../constants/queryKeys';
import { SuccessPage } from '../SuccessPage';
import { FormFields } from './FormFields';

type Template = HomegateTemplate & {
  '@id': string;
  '@type': string;
};

export type CreateHomegateFormValues = {
  templates: Template[];
  category: 2 | 3;
  companyName: string;
  pin: string;
  firstName: string;
  lastName: string;
  address: string;
  address2: string;
  email: string;
  zip: string;
  city: string;
  phone: string;
  mobile: string;
  partnerCompany: string;
  bankName: string;
  bankAccount: string;
  regDate: string;
  salesRep: string;
  reseller: string;
  addressList: string;
  tb: number;
  leadSource: string;
  kamId: number;
  abTest: string;
  adminNote: string;
  autogiroSource: number;
  autogiroAgreement: number;
  partnerDate: string;
  campaign: string;
  freeMonths: number;
  noticePeriod: number;
  contractMonths: number;
  churnDate?: string;
};

type CreateHomegateDTO = CreateHomegateFormValues & {
  customer: string;
  churnReason?: number;
};

type ExistinCustomerInfo = {
  id: string;
  pin: string;
};

export const CreateHomegateForm = () => {
  const location = useLocation();
  const { serviceName } = useParams<{ serviceName: 'homegate' }>();
  invariant(serviceName === 'homegate', 'Invalid service name');

  const [isExistingCustomer, setIsExistingCustomer] = React.useState(false);
  const [existingCustomerInfo, setExistingCustomerInfo] =
    React.useState<ExistinCustomerInfo>();
  const { servicesNames, churnReasons } = usePrivateSettings();

  const getHeaderText = () =>
    existingCustomerInfo?.id ? 'Befintlig kund' : 'Ny Kund';
  const mappedServiceName = servicesNames[getCamelCaseServiceName('homegate')];
  const {
    handleSubmit,
    isLoading,
    allSuccess,
    mutatedCustomer,
    createServiceFromTemplates,
    createServiceSuccess,
  } = useAddServiceToCustomer(serviceName);

  const { data: customerData, isInitialLoading: loadingCustomer } = useQuery({
    queryKey: [queryKeys.getCustomerById, existingCustomerInfo?.id],
    queryFn: () => getCustomerById(existingCustomerInfo!.id),
    enabled: !!existingCustomerInfo?.id,
  });

  const onSubmit = (
    values: CreateHomegateFormValues,
    customerData: Customer | undefined,
  ) => {
    const mappedValues: CreateHomegateDTO = {
      ...mapDateFieldsValues(values),
      customer: '',
      churnReason:
        values.churnDate && churnReasons
          ? getChurnReasonKey(
              churnReasons,
              getCamelServiceName(serviceName),
              'Auto-churn',
            )
          : undefined,
    };

    if (mappedValues.category === CustomerCategoryTypes.PRIVATE) {
      mappedValues.pin = normalizeToPersonNumberShort(mappedValues.pin);
    }

    if (isExistingCustomer && customerData) {
      const { templates, ...salesDataFields } = mappedValues;
      salesDataFields.customer = customerData['@id'];
      createServiceFromTemplates(templates, salesDataFields);
    } else {
      handleSubmit(mappedValues);
    }
  };

  React.useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const customerId = queryParams.get('customerId');
    const pin = queryParams.get('pin');

    if (customerId && pin) {
      setIsExistingCustomer(true);
      setExistingCustomerInfo({
        id: customerId,
        pin,
      });
    }
  }, [location]);

  const customerId = isExistingCustomer
    ? existingCustomerInfo?.id
    : mutatedCustomer?.id;

  if (
    customerId &&
    (allSuccess || (isExistingCustomer && createServiceSuccess))
  ) {
    return (
      <SuccessPage
        customerId={customerId}
        mappedServiceName={mappedServiceName}
      />
    );
  }
  return (
    <>
      <PageHeader margin title={`${getHeaderText()} - ${mappedServiceName}`} />
      <Form
        onSubmit={(values: CreateHomegateFormValues) =>
          onSubmit(values, customerData)
        }
        mutators={{
          ...arrayMutators,
        }}
        /* @ts-ignore */
        decorators={[handleCampaignReset]}
        subscription={{ submitting: true, pristine: true, values: true }}
        render={({
          handleSubmit,
          values,
          form,
          form: {
            mutators: { push },
          },
        }) => (
          <>
            {isLoading || loadingCustomer ? (
              <LinearProgress />
            ) : (
              <FormFields
                isExistingCustomer={isExistingCustomer}
                form={form}
                handleSubmit={handleSubmit}
                values={values}
                push={push!}
                customerData={customerData}
              />
            )}
          </>
        )}
      />
    </>
  );
};
