import { usePrivateSettings } from '@/hooks';

export const useGetPackageOptions = () => {
  const { getTypesOptionsForDropdownInput } = usePrivateSettings();

  return getTypesOptionsForDropdownInput('protection').map((option) => ({
    text: option.text,
    value: option.text,
  }));
};
