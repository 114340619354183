export const regretSourceOptions = [
  {
    value: 0,
    text: 'Ej ånger',
  },
  {
    value: 1,
    text: 'Samtal',
  },
  {
    value: 2,
    text: 'Brev',
  },
  {
    value: 3,
    text: 'E-post',
  },
  {
    value: 4,
    text: 'Återförsäljare',
  },
];

export const regretReasonOptions = [
  {
    value: 0,
    text: 'Ej ånger',
  },
  {
    value: 1,
    text: 'Okänt',
  },
  {
    value: 2,
    text: 'Övrigt',
  },
  {
    value: 3,
    text: 'Ångerblankett',
  },
  {
    value: 4,
    text: 'Innan välkomstbrev',
  },
  {
    value: 5,
    text: 'Svarsvägrare',
  },
  {
    value: 6,
    text: 'Förstår inte tjänsten',
  },
  {
    value: 7,
    text: 'Ej avtalsmedveten',
  },
  {
    value: 8,
    text: 'Info. Sälj',
  },
  {
    value: 9,
    text: 'Sambo',
  },
  {
    value: 10,
    text: 'Ekonomiskt',
  },
  {
    value: 11,
    text: 'Har annan aktör',
  },
  {
    value: 12,
    text: 'Har via sin försäkring',
  },
];

export const churnReasonOptions = [
  {
    value: 0,
    text: 'Ej Churn',
  },
  {
    value: 1,
    text: 'Okänd',
  },
  {
    value: 2,
    text: 'Övrigt',
  },
  {
    value: 3,
    text: 'Har ej råd',
  },
  {
    value: 4,
    text: 'Utebliven betalning',
  },
  {
    value: 5,
    text: 'Missade ångra',
  },
  {
    value: 6,
    text: 'Ej avtalsmedveten',
  },
  {
    value: 7,
    text: 'Avliden',
  },
  {
    value: 8,
    text: 'Vill ej ha',
  },
  {
    value: 9,
    text: 'Sambo',
  },
  {
    value: 10,
    text: 'Skaffat via annan aktör',
  },
  {
    value: 11,
    text: 'Förstår ej tjänst',
  },
  {
    value: 12,
    text: 'Förlängningsbrev',
  },
  {
    value: 13,
    text: 'Har via sin försäkring',
  },
];
