import camelCase from 'lodash/camelCase';
import kebabCase from 'lodash/kebabCase';

import { KebabCaseServiceName } from '@/modules/common/utils/services';
import { CamelCaseServiceName } from '@/types/services';

export function getObjectKeys<T>(obj: T): (keyof T)[] {
  if (!obj || typeof obj !== 'object') return [];
  return Object.keys(obj) as (keyof T)[];
}

export function getCamelCaseServiceName(
  serviceName: string,
): CamelCaseServiceName {
  return camelCase(serviceName) as CamelCaseServiceName;
}

export const getKebabCaseServiceName = (serviceName: string) => {
  return kebabCase(serviceName) as KebabCaseServiceName;
};

export function errorHasMessage(
  error: unknown,
): error is { data: { message: string } } {
  return (
    typeof error === 'object' &&
    error !== null &&
    'data' in error &&
    typeof error.data === 'object' &&
    error.data !== null &&
    'message' in error.data &&
    typeof error.data.message === 'string'
  );
}
