import { Typography } from '@mui/material';
import { Skeleton } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import upperFirst from 'lodash/upperFirst';

import httpClient from '@/utils/httpClient';

import { BasicAlert } from '../../../components/BasicAlert/BasicAlert';

type Props = {
  managerIri: string;
};

export const Manager = ({ managerIri }: Props) => {
  const {
    data: manager,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['teamManager', managerIri],
    queryFn: async () =>
      await httpClient.get(managerIri, {
        params: {
          properties: {
            name: true,
          },
        },
      }),
  });

  return isLoading ? (
    <Skeleton width="100%" />
  ) : isError ? (
    <BasicAlert />
  ) : (
    <Typography variant="subtitle2" color="textSecondary">
      Manager: {upperFirst(manager?.data.name)}
    </Typography>
  );
};
