export const kickbackDefaultValues = {
  kickbackCoProtection: '0.0',
  kickbackHomegate: '0.0',
  kickbackKeyTag: '0.0',
  kickbackProtection: '0.0',
  kickbackSinne: '0.0',
  kickbackSticker: '0.0',
};

export const tickDefaultValues = {
  tickCoProtection: '0',
  tickHomegate: '0',
  tickProtection: '0',
  tickSinne: '0',
};

// These fields are not included in the form, but are required in the database
export const nonNullableFieldsAsEmptyStringFields = {
  account: '',
  organization: '',
  address: '',
  address2: '',
  zip: '',
  city: '',
  referral: '',
};

export const fullFormDefaultValues = {
  name: '',
  tick: 'false',
  kickback: 'false',
  daysWithKickback: '0',
  daysWithTick: '0',
  ...kickbackDefaultValues,
  ...tickDefaultValues,
} as const;
