import React, { useState } from 'react';

import { TextField } from '@mui/material';
import { FormApi } from 'final-form';

import { hasOwnProperty } from '@/utils/typeHelpers';

import { parseCustomerData } from './parseCustomerData';

type Props = {
  form: FormApi;
};

export function PasteCustomerField({ form }: Props) {
  const [value, setValue] = useState('');

  const onPasteChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    form: FormApi,
  ) => {
    const { value } = event.target;

    setValue(value);

    if (!value) {
      // If the field is empty then do nothing.
      return;
    }

    const customer = parseCustomerData(value);

    if (customer) {
      for (const field in customer) {
        if (hasOwnProperty(customer, field)) {
          form.change(field, customer[field]);
        }
      }
      setValue('');
    }
  };

  return (
    <TextField
      name="paste"
      fullWidth
      placeholder="Snabbregistrering (klistra in här)"
      onChange={(e) => onPasteChange(e, form)}
      variant="outlined"
      value={value}
    />
  );
}
