import { Card, CardContent, Divider, Link } from '@mui/material';

import { CustomerProps } from '../../../../../types';
import { ChecklistTitle, ListTitle } from '../../../commonStyles';
import { MessageCustomer } from './MessageCustomer';
import { Todo } from './Todo';

// Third tab for Protection Checklist
export const ThirdTab = (props: CustomerProps) => {
  return (
    <Card>
      <ChecklistTitle variant="h6">
        Kund vill spärra mobiltelefon (fysisk telefon, ej abonnemang)
      </ChecklistTitle>
      <Divider />
      <CardContent>
        <ListTitle>
          <MessageCustomer />
          <ListTitle variant="h6">
            Obs: Om kund har Telia som operatör
          </ListTitle>
          <ListTitle variant="body1">
            Telia har i April 2021 infört en ny policy där de för att spärra
            telefon eller abonnemang kräver att kund själv legitimerar sig
            fysiskt i butik eller med BankID på deras hemsida Fråga om kund kan
            göra detta, alltså besöka butik eller om kund har BankID på annan
            telefon eller dator. Annars hjälp kund beställa personlig kod via{' '}
            <Link target="_blank" href="https://casey-ext.han.telia.se/2116">
              https://casey-ext.han.telia.se/2116
            </Link>
            . Meddela att koden skickas till kunds folkbokföringsadress och att
            kund kan ringa Telia's support när denna inkommit själv för
            spärrning eller kontakta oss igen så försöker vi hjälpa till.
            Meddela även kund att av säkerhetsskäl kan vi inte rekommendera
            Telia som operatör då just spärrning av mobil och telefon bör kunna
            göras enklare då de inte innebär ökad risk utan endast tvärtom.
          </ListTitle>
          <Todo {...props} />
        </ListTitle>
      </CardContent>
    </Card>
  );
};
