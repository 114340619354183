import { getCamelCaseServiceName } from '@/helpers/tsHelpers';
import { usePrivateSettings } from '@/hooks';
import { Service } from '@/modules/common/hooks/useActiveServices';

export const useGetPriceFromPricePlan = (service: Service): number | null => {
  const { servicesPricePlans } = usePrivateSettings();

  const camelCasedServiceName = getCamelCaseServiceName(service['@type']);

  if (
    camelCasedServiceName !== 'sinne' &&
    camelCasedServiceName !== 'homegate' &&
    'pricePlanId' in service
  ) {
    return servicesPricePlans[camelCasedServiceName][
      service.pricePlanId
    ] as number;
  }

  return null;
};
