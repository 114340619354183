import { Box, Button } from '@mui/material';
import { endOfMonth, format, startOfMonth, sub } from 'date-fns';
import { Form } from 'react-final-form';

import { SupportRetentionStatisticsArgs } from '@/api/stats';
import { DatePicker } from '@/components/form';
import { AutoSave } from '@/components/form/AutoSave/AutoSave';
import { getFormattedDate } from '@/helpers/dates';

type Filter = SupportRetentionStatisticsArgs;
type Props = {
  onChange: (values: Filter) => void;
  initialValues: Filter;
};

export const Filter = ({ onChange, initialValues }: Props) => {
  const save = async (values: Filter) => {
    const { start, end } = values;
    const startDateAsString = getFormattedDate(start) || initialValues.start;
    const endDateAsString = getFormattedDate(end) || initialValues.end;
    if (startDateAsString || endDateAsString) {
      onChange({ start: startDateAsString, end: endDateAsString });
    }
  };

  const today = new Date();

  return (
    <Form onSubmit={save} initialValues={initialValues}>
      {() => (
        <>
          <AutoSave
            debounce={500}
            save={save}
            debounced={['after', 'before']}
          />
          <DatePicker name="start" label="Från" required />
          <DatePicker name="end" label="Till" required />
          <Box pt={2}>
            <Button
              color="primary"
              onClick={() => {
                save({
                  start: format(startOfMonth(today), 'yyyy-MM-dd'),
                  end: format(endOfMonth(today), 'yyyy-MM-dd'),
                });
              }}
              variant="outlined"
            >
              Denna månaden
            </Button>
            <Button
              sx={{ ml: 2 }}
              color="primary"
              onClick={() => {
                save({
                  start: format(
                    startOfMonth(sub(today, { months: 1 })),
                    'yyyy-MM-dd',
                  ),
                  end: format(
                    endOfMonth(sub(today, { months: 1 })),
                    'yyyy-MM-dd',
                  ),
                });
              }}
              variant="outlined"
            >
              Förra månaden
            </Button>
            <Button
              sx={{ ml: 2 }}
              color="primary"
              onClick={() => {
                save({
                  start: format(
                    startOfMonth(new Date('2000-01-01')),
                    'yyyy-MM-dd',
                  ),
                  end: format(endOfMonth(today), 'yyyy-MM-dd'),
                });
              }}
              variant="outlined"
            >
              Alla
            </Button>
          </Box>
        </>
      )}
    </Form>
  );
};
