import { useQuery } from '@tanstack/react-query';

import { FetchUsersParams, fetchUsers } from '@/api';
import { queryKeys } from '@/constants/queryKeys';
import { UserItem } from '@/types/users';

export const useGetUsers = (params: FetchUsersParams) => {
  const { data } = useQuery({
    initialData: [],
    queryKey: queryKeys.user.list(params),
    queryFn: async () => {
      const response = await fetchUsers(params);
      return response['hydra:member'];
    },
  });
  return data;
};
