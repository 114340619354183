import React from 'react';

import { styled } from '@mui/material/styles';

type Props = {
  children: React.ReactNode;
};
export const StepRow = ({ children }: Props) => {
  return <RowContainer>{children}</RowContainer>;
};
const RowContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1.5)};
  padding-top: ${({ theme }) => theme.spacing(2)};
  padding-bottom: ${({ theme }) => theme.spacing(2)};
`;
