import { SvgIcon, SvgIconProps } from '@mui/material';

export const CustomerSuccessIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 172 172" width="48px" height="48px">
      <g>
        <path d="M0,172v-172h172v172z" fill="none"></path>
        <g>
          <path d="M62.70833,14.33333c-8.20828,0 -14.81935,6.35973 -15.76107,14.33333h-9.32226c-6.86192,0 -12.54167,5.67975 -12.54167,12.54167v21.5c0,12.79944 10.49222,23.29167 23.29167,23.29167h0.74886c4.82598,14.77173 17.52401,25.40946 33.29281,27.57487v11.8418h-12.54167c-12.79944,0 -23.29167,10.49222 -23.29167,23.29167v3.58333c0.0003,2.96841 2.40659,5.3747 5.375,5.375h71.66667c2.96841,-0.0003 5.3747,-2.40659 5.375,-5.375v-3.58333c0,-12.79944 -10.49222,-23.29167 -23.29167,-23.29167h-12.54167v-11.8418c15.7688,-2.16541 28.46683,-12.80314 33.29281,-27.57487h0.74886c12.79944,0 23.29167,-10.49222 23.29167,-23.29167v-21.5c0,-6.86192 -5.67974,-12.54167 -12.54167,-12.54167h-9.32226c-0.94172,-7.9736 -7.55279,-14.33333 -15.76107,-14.33333zM62.70833,25.08333h50.16667c3.03329,0 5.375,2.34171 5.375,5.375v2.7015c-0.09479,0.57703 -0.09479,1.16565 0,1.74268v38.55583c0,16.77659 -13.41691,30.22094 -30.15039,30.39535c-0.20513,-0.01177 -0.41076,-0.01177 -0.61589,0c-16.73347,-0.1744 -30.15039,-13.61875 -30.15039,-30.39535v-38.53483c0.09479,-0.57703 0.09479,-1.16565 0,-1.74268v-2.72249c0,-3.03329 2.34171,-5.375 5.375,-5.375zM37.625,39.41667h8.95833v34.04167c0,0.53049 0.28091,0.97198 0.30094,1.49772c-6.23402,-0.75739 -11.05094,-5.79055 -11.05094,-12.24772v-21.5c0,-1.05724 0.73442,-1.79167 1.79167,-1.79167zM129,39.41667h8.95833c1.05724,0 1.79167,0.73442 1.79167,1.79167v21.5c0,6.45717 -4.81691,11.49033 -11.05094,12.24772c0.02005,-0.52574 0.30094,-0.96723 0.30094,-1.49772zM69.875,136.16667h35.83333c6.34982,0 11.29447,4.66927 12.18473,10.75h-60.2028c0.89026,-6.08073 5.83492,-10.75 12.18473,-10.75z"></path>
        </g>
      </g>
    </SvgIcon>
  );
};
