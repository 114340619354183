import {
  Button,
  Card,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from '@mui/material';

import { LabelAndContentRow } from '@/components';
import { formatCurrency } from '@/modules/common/utils/currency';
import { useInsertArvatoInvoices } from '@/modules/economy/hooks/useInsertArvatoInvoices';
import { BillingStats } from '@/types/billing';

import { ExportButton } from './ExportButton';

type Props = {
  invoiceListSummary: BillingStats;
  arvatoIntegrationEnabled: boolean;
};

export function UpcomingBilling({
  invoiceListSummary,
  arvatoIntegrationEnabled,
}: Props) {
  const insertArvatoInvoicesMutation = useInsertArvatoInvoices();

  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader title="Kommande fakturering" action={<ExportButton />} />
      <Divider />
      <LabelAndContentRow
        content={
          <Typography>{invoiceListSummary?.customerCount} st</Typography>
        }
        label="Kunder"
      />
      <Divider />
      <LabelAndContentRow
        content={<Typography>{invoiceListSummary?.itemCount} st</Typography>}
        label="Avgifter"
      />
      <Divider />
      <LabelAndContentRow
        content={
          <Typography>{formatCurrency(invoiceListSummary?.netto)}</Typography>
        }
        label="Omsättning"
      />
      <Divider />
      <LabelAndContentRow
        content={
          <Typography>{formatCurrency(invoiceListSummary?.moms)}</Typography>
        }
        label="Totalt pris - Moms"
      />
      <Divider />
      <LabelAndContentRow
        content={
          <Typography>{formatCurrency(invoiceListSummary?.brutto)}</Typography>
        }
        label="Totalt pris - Brutto"
      />
      {arvatoIntegrationEnabled && (
        <>
          <Divider />
          <Grid container justifyContent="right" py={2} pr={2}>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                disabled={insertArvatoInvoicesMutation.isPending}
                onClick={() => insertArvatoInvoicesMutation.mutate({})}
              >
                {insertArvatoInvoicesMutation.isPending
                  ? 'Bearbetas...'
                  : 'Kör fakturering'}
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </Card>
  );
}
