import { Personnummer } from '@/helpers/validators/personnummer';

export function normalizeToNumbersOnly(value: string) {
  return value.replace('-', '').replace(' ', '');
}

// YYMMDDXXXX format
export function normalizeToPersonNumberShort(value: string) {
  const val = normalizeToNumbersOnly(value);

  // If it is a valid pin then put it into the format we want.
  if (Personnummer.valid(val)) {
    if (val?.length === 12) {
      return val.substring(2);
    }
  }

  return val;
}
