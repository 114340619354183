import React from 'react';

import { Button } from '@mui/material';
import { Form } from 'react-final-form';

import { CustomTemplateUploadPayload } from '@/api/files';
import {
  ChosenFilesList,
  FileUploadInputButton,
} from '@/components/FileUpload';
import { Checkboxes, TextInput } from '@/components/form';
import { fieldRequired } from '@/utils/validation';

import { useUploadCustomTemplate } from './hooks/useUploadCustomTemplate';

type FormValues = {
  c5h2Address: boolean;
  templateName: string;
  file: File[];
};

export const CustomTemplateFileUploadForm = () => {
  const uploadMutation = useUploadCustomTemplate();
  return (
    <Form
      onSubmit={(values: FormValues, { restart }) => {
        const payload: CustomTemplateUploadPayload = {
          templateType: 'custom',
          ...values,
        };
        uploadMutation.mutateAsync(payload).then(() => restart());
      }}
    >
      {({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <Checkboxes
            name="c5h2Address"
            options={{
              label:
                'Översta delen av brevet (före texten), med plats för namn och annan data',
              value: true,
            }}
            formControlProps={{ margin: 'none' }}
          />
          <TextInput
            name="templateName"
            label="Namn"
            fieldProps={{
              validate: fieldRequired,
            }}
          />
          <FileUploadInputButton
            multiple={false}
            name="file"
            buttonText="Välj fil"
            fullWidth
            mimeTypes={[
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            ]}
            buttonId="custom-template-file-upload-button"
          />
          <ChosenFilesList files={values?.file} title="Vald fil" />

          <Button
            sx={{ mt: 1 }}
            type="submit"
            variant="contained"
            disabled={uploadMutation.isPending}
          >
            Ladda upp
          </Button>
        </form>
      )}
    </Form>
  );
};
