import { Box, LinearProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';

import { PageWrapper } from '@/components';
import { BasicAlert } from '@/components/BasicAlert';
import { EditReseller } from '@/modules/ResellerPortal/components/Forms/EditReseller';
import { useGetResellerById } from '@/modules/ResellerPortal/hooks/useGetResellerById';

type Params = {
  id: string;
};

const EditResellerAccount = () => {
  const { id } = useParams<Params>();
  invariant(id, 'id is required');

  const { data, status } = useGetResellerById(id, { enabled: !!id });

  const breadCrumbs = [
    { label: 'Återförsäljare', url: '/reseller-portal/accounts' },
    { label: data?.name || '' },
  ];

  if (status === 'pending') return <LinearProgress />;
  if (status === 'error') return <BasicAlert />;
  if (status === 'success') {
    return (
      <PageWrapper
        pageTitle="Redigera återförsäljarkonto"
        breadCrumbs={breadCrumbs}
        maxWidth="md"
      >
        <EditReseller data={data} />
      </PageWrapper>
    );
  }
  return null;
};

export default EditResellerAccount;
