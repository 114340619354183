import { Alert, Card, CardHeader, Divider } from '@mui/material';

import { DatePeriodToolbar, Dates } from '@/components/DatePeriodToolbar/';
import { SimpleTable, SimpleTableColumn } from '@/components/SimpleTable';
import { usePrivateSettings } from '@/hooks';
import { formatCurrency } from '@/modules/common/utils/currency';
import {
  KickbackStatisticEntry,
  KickbackStatistics,
} from '@/types/partnerCompany';

import { PartnerStatisticsPeriodParams } from './PartnerStatistics';

type Props = {
  stats?: KickbackStatistics;
  periodParams: PartnerStatisticsPeriodParams;
  setFetchParams: React.Dispatch<
    React.SetStateAction<PartnerStatisticsPeriodParams>
  >;
};

export const KickbackCardTable = ({
  stats,
  periodParams,
  setFetchParams,
}: Props) => {
  const { servicesNames } = usePrivateSettings();

  const kickbackColumns: SimpleTableColumn<KickbackStatisticEntry>[] = [
    {
      label: 'Tjänst',
      renderer: (row) => servicesNames[row.service],
      align: 'left',
    },
    {
      label: 'Antal',
      renderer: (row) => row.count,
      align: 'center',
    },
    {
      label: 'Ersättning per st',
      renderer: (row) => formatCurrency(row.kickBack),
      align: 'center',
    },
    {
      label: 'Fakturerbart',
      renderer: (row) => formatCurrency(row.billable),
      align: 'right',
    },
  ];

  const emptyColumns = Array.from({ length: 2 }, () => ({
    label: '',
    renderer: () => '',
  }));

  const totalColumns: SimpleTableColumn<KickbackStatistics['total']>[] = [
    {
      label: 'Totalt',
      renderer: () => '',
    },
    ...emptyColumns,
    {
      label: formatCurrency(stats?.total || 0),
      renderer: () => '',
      align: 'right',
    },
  ];

  const handleDateChange = (dates: Dates) => {
    setFetchParams((prev) => ({ ...prev, ...dates }));
  };

  return (
    <Card>
      <CardHeader title="Kickback baserat på försäljning under given period" />
      <Divider />
      <DatePeriodToolbar
        setDates={handleDateChange}
        dates={periodParams}
        cardProps={{
          square: true,
        }}
      />
      {stats?.data?.length === 0 ? (
        <Alert severity="info">Ingen data för kickback</Alert>
      ) : (
        <>
          <SimpleTable
            columns={kickbackColumns}
            rows={stats?.data || []}
            cardProps={{
              elevation: 0,
              sx: { borderRadius: 0 },
            }}
          />
          <SimpleTable
            columns={totalColumns}
            rows={[]}
            cardProps={{
              elevation: 0,
              sx: { borderRadius: 0 },
            }}
          />
        </>
      )}
    </Card>
  );
};
