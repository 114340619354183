import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  isAfter,
  isSameDay,
  parseISO,
  setHours,
  setMinutes,
  startOfTomorrow,
} from 'date-fns';
import { FormApi } from 'final-form';
import cloneDeep from 'lodash/cloneDeep';
import { Form, FormSpy } from 'react-final-form';

import { createTask } from '@/api/tasks';
import { DatePicker, Dropdown, TextArea, TextInput } from '@/components/form';
import { queryKeys } from '@/constants/queryKeys';
import { mapDateFieldsValues } from '@/helpers/dates';
import { usePrivateSettings, useSnackbar } from '@/hooks';
import { useModal } from '@/hooks/useModal';
import { USER_TYPES } from '@/modules/common/constants/entitiyNamesMap';
import {
  TaskTeamTypes,
  taskStatusOptions,
} from '@/modules/tasks/constants/tasksModalsConstants';
import { composeValidators, fieldRequired } from '@/utils/validation';

import { usePrependTaskTypeToText } from '../../hooks/usePrependTaskTypeToText';

type FormValues = {
  pin: string;
  status: number;
  type: string;
  deadline: Date;
  text: string;
};

type Props = {
  modal: {
    extra: {
      customerId: number;
      pin: string;
    };
  };
};

export const CreateAdminTaskModal = ({ modal: { extra } }: Props) => {
  const { closeModal } = useModal();
  const snackbar = useSnackbar();
  const prependType = usePrependTaskTypeToText();
  const { getTypesOptionsForDropdownInput } = usePrivateSettings();
  const queryClient = useQueryClient();

  const dateTomorrow = startOfTomorrow();
  const today = new Date();

  const createTaskMutation = useMutation({
    mutationFn: (values: FormValues & { deadline: string }) => {
      if (extra.customerId) {
        const team = TaskTeamTypes.ADMINISTRATOR;
        return createTask({
          ...values,
          customerId: extra.customerId,
          team,
        });
      } else {
        return Promise.reject('Missing customerId');
      }
    },
  });

  const initialValues: Partial<FormValues> = {
    pin: extra.pin,
    status: 0, // Open
    deadline: today,
  };

  const handleCloseModal = () => {
    closeModal();
  };

  const handleSubmit = (
    values: FormValues,
    form: FormApi<FormValues, Partial<FormValues>>,
  ) => {
    form.pauseValidation(); // pause validation so required fields don't show errors when submitting

    const now = new Date();
    const fourPM = setMinutes(setHours(now, 16), 0); // set the time to 4pm

    if (
      isAfter(now, fourPM) &&
      isSameDay(now, parseISO(values.deadline.toISOString()))
    ) {
      values.deadline = dateTomorrow;
    }

    const mappedValues = mapDateFieldsValues(
      cloneDeep(values),
    ) as FormValues & {
      deadline: string;
    };

    createTaskMutation.mutate(mappedValues, {
      onSuccess: () => {
        handleCloseModal();
        snackbar({ type: 'success', message: 'Uppgift skapad.' });
        queryClient.invalidateQueries({
          queryKey: [queryKeys.getAdminTasks],
        });
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey[0] === queryKeys.getNotes,
        });
      },
      onError: () => {
        snackbar({
          type: 'error',
          message: 'Något gick fel, uppgiften kunde inte skapas.',
        });
      },
    });
  };

  return (
    <Dialog onClose={handleCloseModal} open maxWidth="xs">
      <DialogTitle>Nytt ärende till {USER_TYPES.admin}</DialogTitle>
      <Divider />
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        render={({ handleSubmit, submitting, pristine, values, form }) => (
          <form onSubmit={handleSubmit} noValidate autoComplete="off">
            <DialogContent>
              <TextInput
                label="PIN"
                name="pin"
                disabled
                fieldProps={{
                  validate: composeValidators(fieldRequired),
                }}
              />

              <Dropdown
                label="Status"
                name="status"
                options={taskStatusOptions}
                disabled
                required
                fieldProps={{
                  validate: fieldRequired,
                }}
              />

              <Dropdown
                label="Kategori"
                name="type"
                required
                options={getTypesOptionsForDropdownInput('task', {
                  taskTeam: TaskTeamTypes.ADMINISTRATOR,
                })}
                fieldProps={{
                  validate: fieldRequired,
                }}
              />

              <DatePicker label="Deadline" name="deadline" showClearButton />

              <FormSpy
                subscription={{ values: true }}
                onChange={(props) => {
                  if (props.values.type) {
                    form.change(
                      'text',
                      prependType(props.values.type, props.values.text || ''),
                    );
                  }
                }}
              />
              <TextArea
                label="Text"
                name="text"
                required
                rows={8}
                disabled={!values.type}
                helperText={!values.type ? 'Välj kategori först' : undefined}
                fieldProps={{
                  validate: fieldRequired,
                }}
              />
            </DialogContent>
            <Divider />

            <StyledDialogActions>
              <Button onClick={handleCloseModal}>Avbryt</Button>
              <Button
                disabled={submitting || pristine}
                color="primary"
                type="submit"
                variant="contained"
              >
                Skapa ärende
              </Button>
            </StyledDialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};

const StyledDialogActions = styled(DialogActions)`
  justify-content: space-between;
`;
