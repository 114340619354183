import { Avatar, Typography, styled } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { RoleTypes } from '@/constants/roleTypes';
import { getInitials } from '@/helpers';
import useUser from '@/hooks/useUser';

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  minHeight: 'fit-content',
  padding: theme.spacing(2),
}));

export const Profile = () => {
  const authenticatedUser = useUser();

  const user = {
    name: authenticatedUser.name,
    avatar: '',
    bio: '',
  };

  const getRoleLabels = () => {
    if (!authenticatedUser?.roles) {
      return [];
    }

    return authenticatedUser.roles.map((role) => RoleTypes[role]);
  };

  return (
    <Wrapper>
      <Avatar
        alt="Person"
        sx={{ width: 60, height: 60, backgroundColor: 'primary.main' }}
        component={RouterLink}
        src={user.avatar}
        to="/settings/me"
      >
        {getInitials(user.name)}
      </Avatar>
      <Typography mt={1} variant="h4">
        {user.name}
      </Typography>

      <Typography variant="body2" color="text.secondary">
        {getRoleLabels().join(', ')}
      </Typography>
    </Wrapper>
  );
};
