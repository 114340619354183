import httpClient from '@/utils/httpClient';

export async function getInvoiceList<Data = string[]>({
  customerId,
  page = 1,
  perPage = 100,
}: {
  customerId?: number;
  page?: number;
  perPage?: number;
}) {
  const {
    data: { data },
  } = await httpClient.get<{ data: Data }>('/invoice-list', {
    params: { pagination: true, customerId, page, perPage },
  });
  return data;
}

export async function getInvoiceListSummary<Data = string[]>(
  customerId?: string,
) {
  const {
    data: { data },
  } = await httpClient.get<{ data: Data }>('/invoice-list-summary', {
    params: { ...(customerId ? { customerId } : {}) },
  });

  return data;
}

export function getInvoiceListCsv() {
  return httpClient.get<string>('/invoice-list-csv');
}

export function getInvoiceListExcel() {
  return httpClient.get<Blob>('/invoice-list-excel', {
    responseType: 'blob',
  });
}
