export const SPREADSHEET_FIELDS_MAP = {
  firetext: [
    'campaign',
    'regDate',
    'pin',
    'firstName',
    'lastName',
    'address',
    'address2',
    'zip',
    'city',
    'phone',
    'mobile',
    'email',
    'contact1',
    'phone1',
    'contact2',
    'contactType2',
    'phone2',
    'contact3',
    'contactType3',
    'phone3',
    'contact4',
    'contactType4',
    'phone4',
    'contact5',
    'contactType5',
    'phone5',
    'placement',
    'placement2',
    'placement3',
    'reseller',
    'salesRep',
    'addressList',
    'leadSource',
    'bankName',
    'bankAccount',
    'abTest',
    'companyName',
    'partner',
    'kamId',
    'tb',
    'adminNote',
    'churnDate',
  ],
  protection: [
    'regDate',
    'campaign',
    'pin',
    'firstName',
    'lastName',
    'address',
    'address2',
    'zip',
    'city',
    'mobile',
    'phone',
    'email',
    'pin1',
    'phone1',
    'email1',
    'pin2',
    'phone2',
    'email2',
    'pin3',
    'phone3',
    'email3',
    'pin4',
    'phone4',
    'email4',
    'pin5',
    'phone5',
    'email5',
    'reseller',
    'salesRep',
    'addressList',
    'leadSource',
    'bankName',
    'bankAccount',
    'abTest',
    'undefinedField1',
    'undefinedField2',
    'companyName',
    'partner',
    'kamId',
    'tb',
    'adminNote',
    'accountManager',
    'churnDate',
    'commission',
    'autoChurnPeriod',
  ],
  coProtection: [
    'regDate',
    'campaign',
    'pin',
    'companyName',
    'firstName',
    'lastName',
    'address',
    'address2',
    'zip',
    'city',
    'mobile',
    'phone',
    'email',
    'protectedPin',
    'protectedPhone',
    'protectedEmail',
    'reseller',
    'salesRep',
    'addressList',
    'leadSource',
    'bankName',
    'bankAccount',
    'abTest',
    'partner',
    'kamId',
    'tb',
    'adminNote',
    'accountManager',
    'project',
    'commission',
    'autoChurnPeriod',
  ],
  keyTag: [
    'regDate',
    'campaign',
    'pin',
    'companyName',
    'firstName',
    'lastName',
    'address',
    'address2',
    'zip',
    'city',
    'phone',
    'mobile',
    'email',
    'reseller',
    'salesRep',
    'addressList',
    'leadSource',
    'partner',
    'kamId',
    'tb',
    'adminNote',
    'bankName',
    'bankAccount',
    'debtCollection',
    'autoChurnPeriod',
  ],
  sticker: [
    'regDate',
    'pin',
    'companyName',
    'firstName',
    'lastName',
    'address',
    'address2',
    'zip',
    'city',
    'phone',
    'mobile',
    'email',
    'partner',
  ],
  homegate: [
    'category',
    'companyName',
    'pin',
    'firstName',
    'lastName',
    'address',
    'address2',
    'email',
    'zip',
    'city',
    'phone',
    'mobile',
    'partnerCompany',
    'bankName',
    'bankAccount',
    'autogiroSource',
    'autogiroAgreement',
    'regDate',
    'salesRep',
    'reseller',
    'addressList',
    'tb',
    'leadSource',
    'kamId',
    'abTest',
    'campaign',
    'adminNote',
  ],
  sinne: [
    'regDate',
    'campaign',
    'pin',
    'companyName',
    'firstName',
    'lastName',
    'address',
    'address2',
    'zip',
    'city',
    'mobile',
    'phone',
    'email',
    'reseller',
    'salesRep',
    'addressList',
    'leadSource',
    'abTest',
    'partnerCompany',
    'partnerManager', // === kamID
    'tb',
    'adminNote',
    'accountManager',
    'project', // not yet implemented
    'provision', // not yet implemented
    'name1',
    'phone1',
    'relation1',
    'name2',
    'phone2',
    'relation2',
    'name3',
    'phone3',
    'relation3',
    'name4',
    'phone4',
    'relation4',
    'name5',
    'phone5',
    'relation5',
  ],
};

export const FIELDS_TO_REMOVE_BEFORE_CREATE_SERVICE = [
  'contactType2',
  'contactType3',
  'contactType4',
  'contactType5',
  'placement2',
  'placement3',
] as const;

export const DATE_FIELDS = ['regDate'];

export const INITIAL_VALUES = {
  category: 3,
};
