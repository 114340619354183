export const RETRY_DELAY = 10000;
export const MINUTES_TO_WAIT = 5;
export const MAX_RETRIES = (MINUTES_TO_WAIT * 60 * 1000) / RETRY_DELAY;

export const SINNE_CONFIG_STEP_TITLES = [
  'Steg 1 - Ta bort plastflikarna',
  'Steg 2 - Registrera enhet',
  'Steg 3 - Förbered posten',
  'Steg 4 - Filer',
  'Steg 5 - Testa synkronisering av enheten',
  'Steg 6 - Verifiera koppling med simkort',
];
