import { Grid } from '@mui/material';

import { PhoneInput, TextInput } from '@/components/form';

export const ContactListSection = () => {
  return (
    <>
      {[...Array(5).keys()].map((_, index) => (
        <Grid container spacing={3} key={index}>
          <Grid item xs>
            <TextInput
              label="Namn"
              name={`contact${index + 1}`}
              fieldProps={{
                defaultValue: '',
                parse: (value) => value,
              }}
            />
            <TextInput name={`@id${index + 1}`} sx={{ display: 'none' }} />
          </Grid>

          <Grid item xs>
            <PhoneInput
              required={false}
              label="Telefonnummer"
              name={`phone${index + 1}`}
              fieldProps={{
                parse: (value) => value,
              }}
            />
          </Grid>
        </Grid>
      ))}
    </>
  );
};
