import React from 'react';

import { Button, DialogActions, DialogContent, Divider } from '@mui/material';
import { Form } from 'react-final-form';

import {
  ChosenFilesList,
  FileUploadInputButton,
} from '@/components/FileUpload';
import { OrderFileModalType } from '@/types/orderFile';

import { useUploadOrderFile } from './hooks/useUploadOrderFile';

type Props = {
  serviceType: OrderFileModalType;
  onClose: () => void;
};

export const FileUploadForm = ({ serviceType, onClose }: Props) => {
  const uploadMutation = useUploadOrderFile();
  return (
    <Form
      onSubmit={(values: { file: File[] }) => {
        const payload = {
          file: values.file,
          serviceType,
        };

        uploadMutation.mutateAsync(payload).then(() => onClose());
      }}
    >
      {({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <FileUploadInputButton
              multiple={false}
              name="file"
              buttonText="Välj fil"
              fullWidth
              mimeTypes={[
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              ]}
              buttonId="order-file-upload-button"
            />
            <ChosenFilesList files={values?.file} title="Vald fil" />
          </DialogContent>

          <Divider />

          <DialogActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              sx={{ mt: 1 }}
              type="submit"
              variant="contained"
              disabled={uploadMutation.isPending}
            >
              Ladda upp
            </Button>
          </DialogActions>
        </form>
      )}
    </Form>
  );
};
