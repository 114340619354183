import React from 'react';

import { Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import isArray from 'lodash/isArray';
import { useForm } from 'react-final-form';

import { fetchPartnersList } from '@/api/partners';
import {
  DatePicker,
  Dropdown,
  EmailInput,
  PhoneInput,
  TextInput,
  ZipInput,
  normalizeToNumbersOnly,
  normalizeToPersonNumberShort,
} from '@/components/form';
import {
  CustomerCategoryTypes,
  customerCategoryTypeOptions,
} from '@/constants';
import { queryKeys } from '@/constants/queryKeys';
import {
  composeValidators,
  fieldIsOrgNumber,
  fieldIsPersonalNumber,
  fieldRequired,
  isValidOption,
  removeInvalidSveaChars,
} from '@/utils/validation';

type Props = {
  valuesFromExcel?: Record<string, string | number | null>;
};

export const CustomerInformationSection = ({ valuesFromExcel }: Props) => {
  const { data: partners, isPending } = useQuery({
    queryKey: [queryKeys.getPartners],
    queryFn: async () => {
      const data = await fetchPartnersList();

      const options = isArray(data)
        ? [
            { value: '', text: 'Ingen' },
            ...data.map((partner) => ({
              value: `/partner_companies/${partner.id}`,
              text: `${partner.id} - ${partner.name}`,
            })),
          ]
        : [];

      return { options, data };
    },
    initialData: { options: [], data: [] },
  });
  const { getFieldState, change } = useForm();
  const selectedCategory = getFieldState('category');
  const regDate = getFieldState('regDate')?.value;
  const partnerCompany = getFieldState('partnerCompany');

  const selectedCategoryIsCompany =
    parseInt(selectedCategory?.value, 10) === CustomerCategoryTypes.COMPANY;

  React.useEffect(() => {
    if (valuesFromExcel && partners) {
      if (
        partners?.data?.find(
          (partner) => partner['@id'] === valuesFromExcel.partnerCompany,
        )
      ) {
        change('partnerDate', valuesFromExcel.regDate);
      }
    }
  }, [valuesFromExcel, partners]);

  React.useEffect(() => {
    if (partnerCompany?.valid && !!partnerCompany.value && regDate) {
      change('partnerDate', regDate);
    }
    if (!partnerCompany?.valid) {
      change('partnerDate', undefined);
    }
  }, [regDate, partnerCompany]);

  return (
    <>
      <Dropdown
        label="Kundtyp"
        name="category"
        options={customerCategoryTypeOptions}
        required
        fieldProps={{
          validate: fieldRequired,
        }}
      />

      {selectedCategoryIsCompany ? (
        <>
          <TextInput
            label="Företagsnamn"
            name="companyName"
            required
            fieldProps={{
              validate: fieldRequired,
              parse: (v) => removeInvalidSveaChars(v),
              format: (v) => (v ? removeInvalidSveaChars(v) : ''),
            }}
          />
          <TextInput
            label="Organisationsnummer"
            name="pin"
            required
            fieldProps={{
              parse: normalizeToNumbersOnly,
              format: (value) => (value ? normalizeToNumbersOnly(value) : ''),
              validate: composeValidators(fieldRequired, fieldIsOrgNumber),
            }}
          />
        </>
      ) : (
        <TextInput
          label="Personnummer"
          name="pin"
          required
          fieldProps={{
            parse: normalizeToPersonNumberShort,
            format: (value) =>
              value ? normalizeToPersonNumberShort(value) : '',
            validate: composeValidators(fieldRequired, fieldIsPersonalNumber),
          }}
        />
      )}

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextInput
            label={selectedCategoryIsCompany ? 'Kontakt Förnamn' : 'Förnamn'}
            name="firstName"
            required
            fieldProps={{
              validate: fieldRequired,
              parse: (v) => removeInvalidSveaChars(v),
              format: (v) => (v ? removeInvalidSveaChars(v) : ''),
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            label={
              selectedCategoryIsCompany ? 'Kontakt Efternamn' : 'Efternamn'
            }
            name="lastName"
            required
            fieldProps={{
              validate: fieldRequired,
              parse: (v) => removeInvalidSveaChars(v),
              format: (v) => (v ? removeInvalidSveaChars(v) : ''),
            }}
          />
        </Grid>
      </Grid>

      <TextInput
        label="Adress 1"
        name="address"
        required
        fieldProps={{
          validate: fieldRequired,
        }}
      />

      <TextInput label="Adress 2" name="address2" />

      <EmailInput name="email" required={false} />

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ZipInput />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            label="Stad"
            name="city"
            required
            fieldProps={{
              validate: fieldRequired,
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <PhoneInput label="Telefon" name="phone" required={false} />
        </Grid>
        <Grid item xs={6}>
          <PhoneInput label="Mobil" name="mobile" required={false} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Dropdown
            sx={{ margin: 0 }}
            label="Partnerföretag"
            name="partnerCompany"
            options={partners?.options}
            fieldProps={{
              validate: isValidOption(partners?.options, isPending),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePicker disabled label="Partnerdatum" name="partnerDate" />
        </Grid>
      </Grid>
    </>
  );
};
