import React from 'react';

import { Button, Divider, LinearProgress, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { getResellerById } from '@/api/reseller';
import { DialogActions } from '@/components/DialogActions';
import { LabelContentAndAction } from '@/components/LabelContentAndAction';
import { Spacing } from '@/components/Spacing';
import { queryKeys } from '@/constants/queryKeys';
import { config } from '@/helpers/config';
import { usePrivateSettings } from '@/hooks';
import { CopyButton } from '@/modules/common/components';
import { Customer } from '@/types/customers';
import { FiretextItem } from '@/types/services';

import { DialogContent } from '../helpers';

type Props = {
  service: FiretextItem;
  customer: Customer;
  goBack: () => void;
  goToNextStep: () => void;
};

export const Step3 = ({ goToNextStep, goBack, service, customer }: Props) => {
  const { servicesNames, servicesPricePlans } = usePrivateSettings();
  const price = servicesPricePlans.firetext[service.pricePlanId];

  const {
    data: reseller,
    isLoading,
    isSuccess,
  } = useQuery({
    queryKey: [queryKeys.getResellers, service.reseller],

    queryFn: async () => {
      //@ts-ignore
      return await getResellerById(service.reseller);
    },
  });

  return (
    <>
      <DialogContent noPadding>
        <Spacing spacing={1} />

        <LabelContentAndAction
          content={
            isLoading ? (
              <LinearProgress />
            ) : (
              isSuccess && <Typography>{reseller?.name}</Typography>
            )
          }
          label="Återförsäljare"
        />

        <LabelContentAndAction
          content={<Typography>{service?.salesRep}</Typography>}
          label="Säljare"
        />
        <LabelContentAndAction
          content={<Typography>{price} kr</Typography>}
          label="Månadsavgift"
        />
        <LabelContentAndAction
          content={<Typography>{service?.campaign}</Typography>}
          label="Kampanj"
        />

        <Spacing spacing={1} />
        <Divider />
        <Spacing spacing={1} />

        <LabelContentAndAction
          content={
            <Typography>{`${config.whitelabelId}-${customer.id}`}</Typography>
          }
          label="Snabbsök"
          action={
            <CopyButton value={`${config.whitelabelId}-${customer.id}`} />
          }
        />
        <LabelContentAndAction
          content={<Typography>{customer?.fullName}</Typography>}
          label="Namn"
          action={<CopyButton value={customer?.fullName || ''} />}
        />
        <LabelContentAndAction
          content={<Typography>{customer?.address}</Typography>}
          label="Adress"
          action={<CopyButton value={customer?.address || ''} />}
        />
        <LabelContentAndAction
          content={<Typography>{customer?.zip}</Typography>}
          label="Postkod"
          action={<CopyButton value={customer?.zip || ''} />}
        />
        <LabelContentAndAction
          content={<Typography>{customer?.city}</Typography>}
          label="Stad"
          action={<CopyButton value={customer?.city || ''} />}
        />
        <LabelContentAndAction
          content={<Typography>{customer?.email}</Typography>}
          label="E-post"
          action={<CopyButton value={customer?.email || ''} />}
        />
        <LabelContentAndAction
          content={<Typography>{customer?.mobile}</Typography>}
          label="SMS"
          action={<CopyButton value={customer?.mobile || ''} />}
        />
        <LabelContentAndAction
          content={<Typography>{servicesNames.firetext}</Typography>}
          label="Innehåll"
          action={<CopyButton value={servicesNames.firetext} />}
        />
      </DialogContent>

      <DialogActions>
        <Button color="primary" variant="contained" onClick={goBack}>
          Tillbaka
        </Button>
        <Button color="primary" variant="contained" onClick={goToNextStep}>
          Gå vidare
        </Button>
      </DialogActions>
    </>
  );
};
