import React from 'react';

import {
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { useSendingsState } from '@/hooks/useSendingsState';

export const SummaryTable = () => {
  const { sendingsState } = useSendingsState();

  const { complete, numberOfFails, numberOfRecipients, type, active } =
    sendingsState;

  if (complete) {
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Typ</TableCell>
              <TableCell>Antal lyckade</TableCell>
              <TableCell>Antal misslyckade</TableCell>
              <TableCell>Totalt</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{type}</TableCell>
              <TableCell>{numberOfRecipients - numberOfFails}</TableCell>
              <TableCell>{numberOfFails}</TableCell>
              <TableCell>{numberOfRecipients}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  if (active && !complete) {
    return <LinearProgress />;
  }

  return <Typography variant="h5">Inga aktiva utskick</Typography>;
};
