import React from 'react';

import {
  AlertTitle,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
} from '@mui/material';
import { setIn } from 'final-form';
import { Form } from 'react-final-form';

import { Alert } from '@/components/Alert';
import { InfoMessage } from '@/components/InfoMessage';
import { Spacing } from '@/components/Spacing';
import { Dropdown, RadioButtons, TextInput } from '@/components/form';
import { useCustomTemplateTypeOptions } from '@/hooks/customTemplateTypeOptions';
import { GenericHttpError } from '@/types/errors';
import { fieldRequired } from '@/utils/validation';

import { useGenerateBulkCustomLettersMutation } from './hooks/useGenerateBulkCustomLettersMutation';

const recipientIdentifiersToArray = (value: string) =>
  !value ? [] : value.split('\n').filter((id) => id);

const recipientIdentifiers = {
  customerIds: 'customerIds',
  customerPins: 'customerPins',
} as const;
const recipientLabels = {
  [recipientIdentifiers.customerIds]: 'kundnummer',
  [recipientIdentifiers.customerPins]: 'person-/organisationsnummer',
} as const;

type FormValues = {
  templateName: string;
  recipients: string;
  recipientIdentifier: keyof typeof recipientLabels;
};

export const CustomLetters = () => {
  const templateOptions = useCustomTemplateTypeOptions();

  const bulkMutation = useGenerateBulkCustomLettersMutation();
  const error = bulkMutation.error as unknown as GenericHttpError | undefined;

  return (
    <Box>
      <Form
        initialValues={{
          recipientIdentifier: recipientIdentifiers.customerIds,
        }}
        onSubmit={(values: FormValues, form) => {
          const { recipientIdentifier, recipients, templateName } = values;
          const payload = {
            [recipientIdentifier]: recipientIdentifiersToArray(recipients),
            templateName,
          };

          bulkMutation.mutateAsync(payload).then(() => form.restart());
        }}
        validate={(values) => {
          let errors = {};
          if (recipientIdentifiersToArray(values.recipients).length >= 100) {
            errors = setIn(
              {},
              'recipients',
              `Max 100 ${recipientLabels[values.recipientIdentifier]}`,
            );
          }
          return errors;
        }}
        render={({ handleSubmit, pristine, values }) => (
          <Card component="form" onSubmit={handleSubmit}>
            <CardHeader title="Skapa noteringar" />
            <Divider />
            <CardContent>
              {!!error && (
                <Alert severity="error">
                  <AlertTitle>Något gick fel</AlertTitle>
                  {`${error.status}: ${error?.statusText}`}
                </Alert>
              )}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Dropdown
                    label="Val av brevmall"
                    name="templateName"
                    options={templateOptions}
                    fieldProps={{
                      validate: fieldRequired,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <RadioButtons
                    label="Typ av mottagare"
                    name="recipientIdentifier"
                    required={true}
                    data={[
                      {
                        label:
                          recipientLabels[recipientIdentifiers.customerIds],
                        value: recipientIdentifiers.customerIds,
                      },
                      {
                        label:
                          recipientLabels[recipientIdentifiers.customerPins],
                        value: recipientIdentifiers.customerPins,
                      },
                    ]}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextInput
                    multiline
                    label="Mottagare"
                    placeholder="Klistra in mottagare"
                    name="recipients"
                    rows={12}
                    fieldProps={{
                      validate: fieldRequired,
                    }}
                  />
                </Grid>
              </Grid>
              <Spacing />
              <InfoMessage>
                Ange ett {recipientLabels[values.recipientIdentifier]} per rad
              </InfoMessage>
            </CardContent>
            <Divider />
            <Spacing />
            <CardActions>
              <Button
                disabled={pristine || bulkMutation.isPending}
                color="primary"
                variant="outlined"
                type="submit"
                onClick={handleSubmit}
              >
                Skapa
              </Button>
            </CardActions>
          </Card>
        )}
      />
    </Box>
  );
};
