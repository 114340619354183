import {
  CardContent,
  CardHeader,
  Divider,
  LinearProgress,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { Alert } from '@/components/Alert';
import { useActiveServices } from '@/modules/common/hooks/useActiveServices';

import { CustomerServicesTable } from './CustomerServicesTable';

type Props = {
  customerId: number;
};

export const CustomerServicesList = ({ customerId }: Props) => {
  const { services, status } = useActiveServices({ customerId });

  if (!services || status === 'pending') {
    return (
      <Card>
        <LinearProgress />
      </Card>
    );
  }

  if (status === 'error') {
    return (
      <Card>
        <Alert severity="error">Något gick fel</Alert>
      </Card>
    );
  }

  return (
    <Card>
      <StyledCardHeader title="Aktiva" />
      <Divider />
      <CardContent>
        <CustomerServicesTable
          customerId={customerId}
          data={services.active}
          isActive
        />
      </CardContent>
      <StyledCardHeader title="Inaktiva" />
      <Divider />
      <CardContent>
        <CustomerServicesTable
          customerId={customerId}
          data={services.inactive}
        />
      </CardContent>
    </Card>
  );
};

export const Card = styled('div')(({ theme }) => ({
  overflow: 'auto',
  maxHeight: '50vh',
}));

export const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  position: 'sticky',
  top: '0',
  zIndex: 1,
}));
