import { endOfDay, formatISO, startOfDay } from 'date-fns';

import { config } from '@/helpers/config';
import { NoteItem, NotesResponse } from '@/types/notes';
import httpClient from '@/utils/httpClient';

export type GetNoteParams = {
  noteId: number | string;
  sinne?: string;
  homegate?: string;
};

export async function getNote({ noteId, sinne, homegate }: GetNoteParams) {
  let id: string;
  if (typeof noteId === 'number') {
    id = `/notes/${noteId}`;
  } else {
    id = noteId;
  }

  return httpClient.get<NoteItem>(id, {
    params: {
      ...(sinne ? { sinne } : null),
      ...(homegate ? { homegate } : null),
    },
  });
}

export type GetNotesParams = {
  types: Array<number> | number;
  customerId: number | string;
  page?: number;
  perPage?: number;
  pagination?: boolean;
  dateAfter?: string;
  dateBefore?: string;
  sinne?: string;
};

export async function getNotes({
  types,
  customerId,
  page = 1,
  perPage = 100,
  pagination = true,
  dateAfter,
  dateBefore,
  sinne,
}: GetNotesParams) {
  const after = dateAfter ? formatISO(startOfDay(new Date(dateAfter))) : null;
  const before = dateBefore ? formatISO(endOfDay(new Date(dateBefore))) : null;

  const properties = {
    id: true,
    createdAt: true,
    text: true,
    type: true,
    createdBy: {
      name: true,
      id: true,
    },
    sinne: true,
    customer: {
      id: true,
      fullName: true,
      companyName: true,
    },
    taggedUsers: {
      id: true,
      name: true,
    },
  };

  return await httpClient.get<NotesResponse>('/notes', {
    params: {
      ...(dateAfter ? { 'createdAt[after]': after } : null),
      ...(dateBefore ? { 'createdAt[before]': before } : null),
      page,
      perPage,
      sinne,
      pagination: pagination,
      ...(customerId ? { customer: customerId } : null),
      order: {
        createdAt: 'desc',
      },
      type: types,
      properties,
    },
  });
}

export type CreateNotePayload = {
  customerId: number;
  type: string | number;
  text: string;
  status?: string | number;
  taggedUsers?: string[];
  homegate?: string;
  sinne?: string;
};
export async function createNote({
  customerId,
  type,
  status,
  text,
  taggedUsers,
  homegate,
  sinne,
}: CreateNotePayload) {
  return await httpClient.post<NoteItem>('/notes', {
    type: typeof type === 'string' ? parseInt(type, 10) : type,
    ...(status !== undefined && { status }),
    text,
    createdAt: new Date().toISOString(),
    customer: `/${config.whitelabelId}/customers/${customerId}`,
    taggedUsers,
    homegate,
    sinne,
  });
}

export async function deleteNote(id: string) {
  return await httpClient.delete(id);
}

export type CreateBulkNotesPayload = {
  customerIds: string[];
  type: string;
  message: string;
};
export async function createBulkNotes({
  customerIds,
  type,
  message,
}: CreateBulkNotesPayload) {
  return await httpClient.post<string[]>('/rpc/bulk.notes', {
    customerIds,
    type: parseInt(type, 10),
    message,
  });
}
