import { ListItem, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledListItem = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== 'compact',
})<{ compact: boolean }>`
  padding: ${({ theme }) => theme.spacing(0, 2, 0, 2)};
  min-height: ${({ compact, theme }) =>
    compact ? theme.spacing(3) : '53.5px'};
`;

const StyledListItemText = styled(ListItemText)`
  & .MuiListItemText-primary {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const StyledSpan = styled('span')`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

type Props = {
  label: string;
  content: React.ReactNode;
  divider?: boolean;
  compact?: boolean;
  secondaryText?: React.ReactNode;
};

export function LabelAndContentRow({
  label,
  content,
  compact,
  divider,
  secondaryText,
}: Props) {
  return (
    <>
      <StyledListItem divider={divider} compact={!!compact}>
        <StyledListItemText secondary={secondaryText ?? null}>
          <b>{label}</b>
          <StyledSpan>{content}</StyledSpan>
        </StyledListItemText>
      </StyledListItem>
    </>
  );
}
