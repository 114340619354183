import { Card, CardContent, Divider, List } from '@mui/material';

import { NumberedListItem } from '@/components/NumberedListItem';

import { ChecklistTitle, ListTitle } from '../../commonStyles';

// First tab for Protection Checklist
export const FirstTab = () => {
  return (
    <Card>
      <ChecklistTitle variant="h6">
        Mina Sidor - Kund undrar över meddelande på Händelser
      </ChecklistTitle>
      <Divider />
      <CardContent>
        <List>
          <ListTitle variant="h6">
            Antal Folkbokförda på din adress har ökat/minskat och är nu X
            personer. Tidigare var det Y personer.
          </ListTitle>
          <NumberedListItem
            number={1}
            text={
              'Tjänsten du har bevakar antal folkbokförda personer på din adress. När det sker en ändring på antalet folkbokförda personer så får du ett larm om detta. Vi rekommenderar dig att gå in på skatteverkets hemsida och kontrollera med BankID vilka som är folkbokförda på din adress just nu om du inte känner till ändringen. \n\nOm du misstänker att någon otillåten har folkbokfört sig på din adress efter att du kontrollerat hos skatteverket, rekommenderar vi att du anmäler händelsen direkt till Polisen samt Skatteverket'
            }
          />
          <ListTitle variant="h6">
            Ditt skuldsaldo har ökat och är nu XXX kr.
          </ListTitle>
          <NumberedListItem
            number={1}
            text={
              'Larm skickas även löpande då skulden ökar i takt med att räntan på skulderna ökar. Be kund logga in med BankID på Mina sidor. Där ser kund om det finns några skulder som växer. Och vilka företag som kund har betalningsanmärkningar hos. Kund kan sedan kontakta dessa företag direkt eller kronofogden för mer information.'
            }
          />
          <ListTitle variant="h6">
            Telefonnummer har ändrats. Borttagna: 07X-XXXXXXX.
          </ListTitle>
          <NumberedListItem
            number={1}
            text={
              'En Mobiloperatör har avregisterat ett mobilnummer som stod på kunds mobilnummer. Vanligtvis ett gammal kontaktkort, ett tillfälligt nummer man haft när man köpt ny telefon eller ett Mobilt Bredband som avslutats. Det kan även vara om du tex ändrar från kontantkort till abonnemang, eller andra ändringar på ditt befintliga abonnemang'
            }
          />
          <NumberedListItem
            number={2}
            text={
              'Hänvisa att kund kan kontakta sin operatör om de har fler frågor. (Hjälp kund att ta fram telefonnummer till operatör)'
            }
          />
          <ListTitle variant="h6">
            En ny inkomsttaxering har inkommit.
          </ListTitle>
          <NumberedListItem
            number={1}
            text={'Skatteverket har registerat en ny inkomstdeklaration'}
          />
          <ListTitle variant="h6">Okänd kreditupplysning</ListTitle>
          <NumberedListItem
            number={1}
            text={
              'Kund ringer in och har fått sms om kreditupplysning som de inte känner igen. Be kunden att kontakta det bolag som har gjort kreditupplysningen för att kontrollera varför. (Hjälp kund att ta fram nummer till rätt bolag)'
            }
          />
          <ListTitle variant="h6">Lagfart</ListTitle>
          <NumberedListItem
            number={1}
            text={
              'Kund har fått sms om att lagfart ändrats mm. Kan bero på om kund har förvärvat en fastighet eller tomträtt via köp, gåva, arv, bodelning eller dödsbo. Be kund ringa till lantmäteriet för att kontrollera att allt är i sin ordning. (Hjälp kund att ta fram telefonnummer.'
            }
          />
          <ListTitle variant="h6">Att göra:</ListTitle>
          <NumberedListItem
            number={1}
            text={'Skriv in information kring konversationen på Kundnoteringar'}
            isLast
          />
        </List>
      </CardContent>
    </Card>
  );
};
