export const FEATURE_FLAGS = {
  deleteReseller: {
    name: 'delete-reseller',
  },
  protectionCampaignV2: {
    name: 'protectionCampaignV2',
  },
  customLetters: {
    name: 'custom-letters',
  },
} as const;
