import { z } from 'zod';

export const getCustomerNameSchema = z.object({
  category: z.literal(2).or(z.literal(3)),
  companyName: z.string().optional(),
  fullName: z.string().optional(),
});

export type GetCustomerNameArgs = z.infer<typeof getCustomerNameSchema>;

export function getCustomerName(
  customerNameFields: GetCustomerNameArgs,
): string {
  if (!customerNameFields) {
    return '';
  }

  const name =
    customerNameFields.category === 2
      ? customerNameFields.companyName
      : customerNameFields.fullName;

  if (name) {
    return name;
  }

  return '';
}

const CustomerServicePrioritizedNoteTypes = [
  '0', // Ånger
  '3', // Samtal
  '11', // Winback
  '19', // Uppsägningsblanket
];
type ListElement = {
  value: string | number;
  text: string;
};

export function getPrioritizedNoteTypes(
  list: ListElement[],
  isCustomerServiceUser: boolean,
) {
  function getPriorityValue(type: string) {
    const prioritizedTypes = isCustomerServiceUser
      ? CustomerServicePrioritizedNoteTypes
      : [];

    return prioritizedTypes.indexOf(type) > -1 ? 1 : 0;
  }

  function compare(a: ListElement, b: ListElement) {
    const priorityA = getPriorityValue(a.value.toString());
    const priorityB = getPriorityValue(b.value.toString());

    if (priorityA > priorityB) {
      return 1;
    }

    if (priorityA < priorityB) {
      return -1;
    }

    return 0;
  }

  return list.sort(compare);
}
