import React from 'react';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useQuery } from '@tanstack/react-query';

import { getCustomSMSMessages } from '@/api/sms';
import { Checkboxes, DatePicker, Dropdown } from '@/components/form';
import { getFormattedDate } from '@/helpers/dates';
import { generateStatusOptions } from '@/helpers/productEdit';
import { usePrivateSettings } from '@/hooks';
import { useModal } from '@/hooks/useModal';
import usePublicSettings from '@/hooks/usePublicSettings';
import useUserRoles from '@/hooks/useUserRoles';
import { prepareTypeOptions } from '@/modules/services';
import { useServiceSavedLostMutation } from '@/modules/services/hooks/useServiceSavedLostMutation';
import {
  getRegDateMessage,
  getServiceInfoCardTitle,
  isServiceStatusChangeEnabled,
} from '@/modules/services/utils';
import { ModalTypes } from '@/state/modalManagementAtom';
import { ProtectionParamSchema } from '@/types/services';
import { ProtectionStatusEnum } from '@/types/settings';
import { getCustomerName } from '@/utils/customer';
import { fieldRequired } from '@/utils/validation';

import { SavedLostSection } from '../../SavedLostSection';
import { RegretTimeMessage } from '../RegretTimeMessage';
import { regretReasonOptions, regretSourceOptions } from './constants';

const ButtonWrapper = styled('div')({
  '& button': {
    marginRight: '0.5rem',
    marginTop: '0.5rem',
  },
  '& :last-child': {
    marginRight: 0,
  },
});

type Props = {
  serviceDetails: ProtectionParamSchema;
  statusValue: ProtectionStatusEnum;
  churnDateValue: null | string;
  isLoading: boolean;
};

export const ServiceInfoForm = ({
  serviceDetails,
  statusValue,
  churnDateValue,
  isLoading,
}: Props) => {
  const { openModal } = useModal();

  const { data: customMessages } = useQuery({
    queryKey: ['custom-sms-messages'],
    queryFn: getCustomSMSMessages,
  });

  const userRoles = useUserRoles();
  const {
    servicesNames,
    webAddresses,
    servicesStatuses,
    servicesTypes,
    churnReasons,
  } = usePrivateSettings();
  const { publicSettings } = usePublicSettings();

  const statusOptions = generateStatusOptions(servicesStatuses.protection);
  const protectionTypes = servicesTypes.protection;

  const regretStatusSelected = statusValue === 2;

  const regDateMessage = React.useMemo(
    () => getRegDateMessage(serviceDetails),
    [serviceDetails],
  );

  const canChangeStatus = isServiceStatusChangeEnabled(
    serviceDetails,
    userRoles,
  );

  function sendActivationSMS() {
    const { customer } = serviceDetails;
    const customerName = getCustomerName(customer);

    const message = customMessages?.protectionActivation
      .replace('[[-customerName-]]', customerName)
      .replace('[[-protectionProductName-]]', servicesNames.protection)
      .replace('[[-webAddressMyPage-]]', webAddresses.mypage)
      .replace(
        '[[-companyNameStandard-]]',
        publicSettings?.companyNames?.standard ?? '',
      );

    // TODO: Move modal to not use redux for show/hide

    openModal({
      modalType: ModalTypes.SMS_CUSTOMER,
      extra: {
        modalTitle: 'Skicka aktiverings SMS',
        messageText: `${message}`,
        disable2WayOption: true,
        isProtection: true,
        usePhoneNumbers: false,
        customerId: customer.id,
      },
    });
  }

  const churnReasonOptions = Object.keys(churnReasons.protection).map(
    (key) => ({
      value: parseInt(key, 10),
      text: churnReasons.protection[key],
    }),
  );

  const savedOrLostMutation = useServiceSavedLostMutation({
    serviceName: 'protection',
    serviceId: serviceDetails.id,
  });

  return (
    <Card>
      <CardHeader
        title={getServiceInfoCardTitle(serviceDetails.customer)}
        sx={{ flexWrap: 'wrap', '& .MuiCardHeader-action': { marginRight: 0 } }}
        action={
          <ButtonWrapper>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={sendActivationSMS}
            >
              Aktiverings SMS
            </Button>
          </ButtonWrapper>
        }
      />
      <Divider />
      <CardContent>
        <Box component="section">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">
                Orderdatum:{' '}
                {getFormattedDate(serviceDetails.regDate, 'yyyy-MM-dd')}{' '}
                {regDateMessage && (
                  <RegretTimeMessage
                    display="inline"
                    variant="body1"
                    regretStatus={regDateMessage.status}
                  >
                    ({regDateMessage.message})
                  </RegretTimeMessage>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Dropdown
                label="Status"
                name="status"
                options={statusOptions}
                disabled={!canChangeStatus}
                required
                fieldProps={{
                  validate: fieldRequired,
                  parse: (value) => parseInt(value, 10),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Dropdown
                label="Paket"
                name="type"
                options={prepareTypeOptions(protectionTypes)}
                required
                fieldProps={{
                  validate: fieldRequired,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <DatePicker
                label="Churn-datum"
                name="churnDate"
                showClearButton
              />
            </Grid>
            {churnDateValue && (
              <Grid item xs={12} sm={6}>
                <Dropdown
                  label="Anledning"
                  name="churnReason"
                  options={churnReasonOptions}
                />
              </Grid>
            )}
          </Grid>

          {regretStatusSelected && (
            <Grid container item spacing={2}>
              <Grid item xs={12} sm={6}>
                <Dropdown
                  label="Källa"
                  name="regretSource"
                  options={regretSourceOptions}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Dropdown
                  label="Anledning"
                  name="regretReason"
                  options={regretReasonOptions}
                />
              </Grid>
            </Grid>
          )}
        </Box>

        <Box component="section">
          <Typography marginTop={2} variant="h6">
            Val
          </Typography>
          <Grid container spacing={2}>
            <Grid item>
              <Checkboxes
                options={{
                  label: 'Faktureras',
                  value: !!serviceDetails.billing,
                }}
                name="billing"
                inputProps={{
                  // @ts-expect-error data-testid is not a valid prop
                  'data-testid': 'billing',
                }}
              />
            </Grid>
            <Grid item>
              <Checkboxes
                options={{
                  label: 'Vänd',
                  value: !!serviceDetails.antiregret,
                }}
                name="antiregret"
                inputProps={{
                  // @ts-expect-error data-testid is not a valid prop
                  'data-testid': 'antiregret',
                }}
              />
            </Grid>
            <Grid item>
              <Checkboxes
                options={{
                  label: 'Anti-churn',
                  value: !!serviceDetails.winBack,
                }}
                name="winBack"
                inputProps={{
                  // @ts-expect-error data-testid is not a valid prop
                  'data-testid': 'winBack',
                }}
              />
            </Grid>
          </Grid>
        </Box>

        <SavedLostSection
          onClickSaved={() => {
            savedOrLostMutation.mutate({
              action: 'saved',
            });
          }}
          onClickLost={() => {
            savedOrLostMutation.mutate({
              action: 'lost',
            });
          }}
        />

        {isLoading && <LinearProgress />}
      </CardContent>
    </Card>
  );
};
