import React from 'react';

import {
  FilledInputProps,
  InputProps,
  OutlinedInputProps,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { FieldError } from 'react-hook-form';
import { z } from 'zod';

export type TextfieldProps = {
  label: string;
  error: FieldError | undefined;
  fullWidth?: boolean;
  children?: React.ReactNode;
  inputProps:
    | FilledInputProps
    | OutlinedInputProps
    | (InputProps & { 'data-testid': string });
} & Omit<TextFieldProps, 'error'>;

export const Textfield = React.forwardRef<HTMLInputElement, TextfieldProps>(
  ({ label, error, fullWidth = false, inputProps, children, ...rest }, ref) => {
    const errorSchema = z.literal(inputProps.name);

    React.useEffect(() => {
      if (error) {
        // Make sure the passed error object is for the same input
        try {
          errorSchema.parse(error?.ref?.name);
        } catch {
          console.error('Error object is not for the same input', error);
        }
      }
    }, [error, errorSchema]);

    return (
      <TextField
        label={label}
        error={Boolean(error)}
        helperText={error?.message}
        InputProps={inputProps}
        fullWidth={fullWidth}
        {...rest}
        ref={ref}
      >
        {children}
      </TextField>
    );
  },
);
