import React from 'react';

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  LinearProgress,
  TextField,
  Typography,
} from '@mui/material';

import { BasicCustomerData } from '@/types/customers';

import { KEY_TAG_AND_STICKER_CODE_VALIDATION_REGEX } from '../../constants';
import { PartialKeyTagItem } from './KeyTagConfigModal';

type Props = {
  onSubmit: (v: any) => void;
  onGoBack: () => void;
  noKeyTags: boolean;
  keyTag: PartialKeyTagItem | null;
  customer: BasicCustomerData;
  requestInProgress: boolean;
  error: string | null;
};

export const KeyTagConfigStep2 = ({
  onSubmit,
  onGoBack,
  noKeyTags,
  keyTag,
  customer,
  requestInProgress,
  error,
}: Props) => {
  const idInput = React.useRef<HTMLBaseElement>(null);

  const [codeValue, setCodeValue] = React.useState('');
  const [validationError, setValidationError] = React.useState<string>('');
  const [submitted, setSubmitted] = React.useState(false);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setCodeValue(event.target.value);
  }

  function handleSubmit(e: React.SyntheticEvent) {
    e.preventDefault();

    if (codeValue) {
      if (KEY_TAG_AND_STICKER_CODE_VALIDATION_REGEX.test(codeValue)) {
        onSubmit(codeValue);
        setValidationError('');
        setSubmitted(true);
      } else {
        setValidationError('Fel kodformat');
      }
    }
  }

  React.useEffect(() => {
    setCodeValue('');

    if (idInput.current) {
      idInput.current.focus();
    }
  }, [keyTag]);

  return (
    <form onSubmit={handleSubmit} noValidate autoComplete="off">
      <DialogContent>
        <Typography gutterBottom variant="h5">
          Steg 2: Skanna nyckelbricka
        </Typography>

        <Box marginTop={2}>
          <Typography gutterBottom>
            Kund:{' '}
            {customer.companyCategory
              ? customer.companyName
              : customer.fullName}
          </Typography>
          {keyTag && <Typography>ID Nyckelbricka: {keyTag.id}</Typography>}
        </Box>

        {noKeyTags ? (
          <Typography>
            Ingen nyckelbricka utan kod hittad på kund med kundnummer{' '}
            {customer.id}
          </Typography>
        ) : (
          <>
            <TextField
              inputRef={idInput}
              label="Serienummer"
              margin="normal"
              size="small"
              variant="outlined"
              fullWidth
              value={codeValue}
              onChange={handleChange}
            />

            {error && (
              <Typography gutterBottom color="error">
                {error}
              </Typography>
            )}

            {validationError && (
              <Typography gutterBottom color="error">
                {validationError}
              </Typography>
            )}
          </>
        )}
      </DialogContent>

      {requestInProgress && <LinearProgress />}

      <Divider />

      <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          type="button"
          color="primary"
          size="small"
          variant="contained"
          onClick={onGoBack}
          disabled={submitted}
        >
          Gå tillbaka
        </Button>

        <Button
          type="submit"
          color="primary"
          size="small"
          variant="contained"
          disabled={!codeValue || requestInProgress}
        >
          Nästa
        </Button>
      </DialogActions>
    </form>
  );
};
