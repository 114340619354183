import { Typography } from '@mui/material';
import { styled } from '@mui/system';

export const RegretTimeMessage = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'regretStatus',
})<{ regretStatus: string }>`
  color: ${({ regretStatus }) =>
    regretStatus === 'regretOver'
      ? '#36af7b'
      : regretStatus === 'regretNotOver'
      ? '#eab12a'
      : 'inherit'};
`;
