import { ServiceNamesPrivateSetting } from '@/types/settings';

export const AgreementTypes = {
  FIRETEXT: 'firetext',
  HOMEGATE: 'homegate',
  PROTECTION: 'protection',
  CO_PROTECTION: 'co-protection',
  DIRECT_DEBIT: 'autogiro',
  SINNE: 'sinne',
};

export function agreementTypeToString(
  agreementType: string,
  productNames: ServiceNamesPrivateSetting,
) {
  switch (agreementType) {
    case AgreementTypes.FIRETEXT:
      return productNames.firetext;

    case AgreementTypes.HOMEGATE:
      return productNames.homegate;

    case AgreementTypes.PROTECTION:
      return productNames.protection;

    case AgreementTypes.CO_PROTECTION:
      return productNames.coProtection;
    case AgreementTypes.SINNE:
      return productNames.sinne;

    case AgreementTypes.DIRECT_DEBIT:
      return 'Autogiro';

    default:
      return agreementType;
  }
}

export const getAgreementTypeOptions = (
  productNames: ServiceNamesPrivateSetting,
) => {
  return Object.values(AgreementTypes)
    .map((value) => ({
      value,
      text: agreementTypeToString(value, productNames),
    }))
    .sort((a, b) => {
      if (a.text < b.text) {
        return -1;
      }
      if (a.text > b.text) {
        return 1;
      }
      return 0;
    });
};
