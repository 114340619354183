import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
} from '@mui/material';
import { upperFirst } from 'lodash';

import { BasicAlert } from '@/components/BasicAlert';
import { SimpleTable } from '@/components/SimpleTable';

import { ACCOUNT_MANAGER_COLUMNS } from '../../const';
import { SalesManagerUser } from '../../types';
import { useGetCurrentSalesManager } from './useGetCurrentSalesManager';

export const SalesManagerTeamStatistics = () => {
  const { data, status } = useGetCurrentSalesManager();

  const currentView = {
    pending: <LinearProgress />,
    error: <BasicAlert />,
    success: <SuccessView data={data} />,
  };

  return currentView[status];
};

const SuccessView = ({ data }: { data: SalesManagerUser | undefined }) => {
  const hasTeams = data?.managedTeams?.length ?? 0;
  if (hasTeams) {
    return (
      <Grid container spacing={2}>
        {data!.managedTeams.map((team) => (
          <Grid item xs={12} key={team.name}>
            <Card>
              <CardHeader title={`Team: ${upperFirst(team.name)}`} />
              <CardContent>
                <SimpleTable
                  rows={team.members}
                  columns={ACCOUNT_MANAGER_COLUMNS}
                />
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  }
  return <Alert severity="info">Du har inga team</Alert>;
};
