import { Edit } from '@mui/icons-material';
import { FormControl, IconButton } from '@mui/material';
import { Field } from 'react-final-form';

type Props = {
  name?: string;
  multiple?: boolean;
  fullWidth?: boolean;
  buttonText?: string;
  buttonId: string;
};

export const TemplateListEditButton = ({
  name = 'files',
  multiple = true,
  fullWidth = false,
  buttonId,
}: Props) => {
  const allowedExtensions = [
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ];

  const isSupportedFileType = (files: File[]) => {
    return files.length > 0 &&
      Array.from(files).every((file) => allowedExtensions.includes(file?.type))
      ? undefined
      : `Filformatet stöds inte`;
  };

  return (
    <Field
      name={name}
      validate={(v) =>
        v !== undefined && v.length > 0 && isSupportedFileType(v)
      }
    >
      {({ input }) => (
        <FormControl fullWidth={fullWidth}>
          <input
            required
            multiple={multiple}
            type="file"
            accept={allowedExtensions.join(',')}
            name={input.name}
            style={{ display: 'none' }}
            onChange={(e) => input.onChange(Array.from(e.target.files!))}
            id={buttonId}
          />
          <label htmlFor={buttonId}>
            <IconButton color="primary" component="span">
              <Edit />
            </IconButton>
          </label>
        </FormControl>
      )}
    </Field>
  );
};
