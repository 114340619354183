import { Typography } from '@mui/material';

const sendingsTypes = [27, 30, 31, 33, 34, 35, 36];
const churnTypes = [21, 22];
const regretTypes = [23, 24];
const canceledTypes = [25];
const freeMonthsTypes = [20];
const turnCustomerTypes = [18];
const changeRequestTypes = [17, 19, 28, 29];
const otherTypes = [32, 37, 100];

const typeTemplates = {
  sendingsTemplateText:
    '<strong>Skicka returpåse:</strong> Tjänst, anledning och om enheten är defekt. \n\n<strong>Skicka batteri:</strong> Tjänst.\n\n<strong>Skicka välkomstbrev på nytt:</strong> Tjänst, studsat eller nytt?\n\n<strong>Skicka konkurrent blankett:</strong> Rätt konkurrent.\n\n<strong>Skicka autogiroblankett:</strong> Ingen beskrivning nödvändig',
  churnTemplateText:
    '<strong>1. Vilken tjänst\n\n2. Korrekt datum\n\n3. Anledning\n\n4. Källa</strong>\n\n<strong>5. Churn + slutfaktura:</strong> Tjänst.\n\n<strong>6. Churndatum + slutfaktura:</strong> Tjänst, håll tjänst aktiv.',
  regretTemplateText:
    '<strong>1. Vilken tjänst\n\n2. Ånger inom 14dagar (måste ha anledning)\n\n3. ånger efter 14 dagar (behöver ingen anledning)\n\n4. Källa</strong>',
  canceledTemplateText: '<strong>1.Vilken tjänst\n\n2.Anledning</strong>',
  turnCustomerTemplateText:
    '<strong>MBL  → SmartSmoke:</strong>\nKonvertering Mbl till SmartSmoke, kampanjkod.\n\n<strong>Winback kund:</strong> Tjänst, nytt pris.\n\n<strong>Prisförändring:</strong> Tjänst och nya priset (Ex (Tjänst) + (ny prisplan))',
  freeMonthsTemplateText:
    '<strong>Vänd 3 fria:</strong> Tjänst, anledning.\n\n<strong>Lägg 1 fri månad:</strong> Tjänst, anledning.',
  changeRequestTemplateText:
    '<strong>Avsluta fakturering:</strong> Tjänst, tjänst ID, anledning.\n\n<strong>Ändra till mejlfaktura:</strong> Se till att det finns en mejl i kundkortet, det behövs ej skrivas något mer.\n\n<strong>Inaktivera dekaler:</strong> Rabatt? ny?\n\n<strong>Ändra faktureringsintervall:</strong> År? månad? kvartal? halvår?\n\n<strong>Prisreducering:</strong> Ta bort prisreducering pga uppsägning.\n\n<strong>Ändring av kampanj:</strong> ex  Ändra kampanjkod på “tjänsten” till 79_12_0 (kampanjkoden)',
  otherTemplateText: '<strong>Beskriv ärendet noggrant</strong>',
  generalTemplateText:
    '<strong>Använd gärna kategorin "Ändring order". Beskriv ärendet noggrant</strong>',
};

const typeTemplateMap = new Map([
  [sendingsTypes, typeTemplates.sendingsTemplateText],
  [churnTypes, typeTemplates.churnTemplateText],
  [regretTypes, typeTemplates.regretTemplateText],
  [canceledTypes, typeTemplates.canceledTemplateText],
  [freeMonthsTypes, typeTemplates.freeMonthsTemplateText],
  [turnCustomerTypes, typeTemplates.turnCustomerTemplateText],
  [changeRequestTypes, typeTemplates.changeRequestTemplateText],
  [otherTypes, typeTemplates.otherTemplateText],
]);

export const getTemplateComponentForType = (type: number | string) => {
  for (const [types, template] of typeTemplateMap.entries()) {
    if (types.includes(Number(type))) {
      return (
        <Typography
          sx={{ whiteSpace: 'break-spaces', lineHeight: '1rem' }}
          dangerouslySetInnerHTML={{ __html: template }}
        />
      );
    }
  }
  return (
    <Typography
      sx={{ whiteSpace: 'break-spaces', lineHeight: '1rem' }}
      dangerouslySetInnerHTML={{
        __html: typeTemplates.generalTemplateText,
      }}
    />
  );
};
