import React, { SetStateAction } from 'react';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import {
  Grid,
  IconButton,
  LinearProgress,
  Link,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import zodToJsonSchema from 'zod-to-json-schema';

import { ServiceListSchema, serviceListSchema } from '@/api';
import { BasicAlert } from '@/components/BasicAlert';
import { Pagination } from '@/components/Pagination';
import { SimpleTable } from '@/components/SimpleTable';
import { PER_PAGE } from '@/constants/pagination';
import { hasAccessToAllCustomers } from '@/constants/roleTypes';
import { createApiPropertiesFromJsonSchema } from '@/helpers/createApiPropertiesFromJsonSchema';
import { getFormattedDate } from '@/helpers/dates';
import useUserRoles from '@/hooks/useUserRoles';
import { SearchField } from '@/modules/common/components';
import { useGetKeyTags } from '@/modules/services/components/KeyTag/hooks/useGetKeyTags';
import { KeyTagStatusEnum, keyTagStatusKey } from '@/types/settings';

type Props = {
  serviceHasKeyCode?: boolean;
};

const getTableColumns = (serviceHasKeyCode?: boolean) => {
  return [
    {
      label: 'Kundnummer',
      renderer: (row: ServiceListSchema) => row?.customer?.id,
    },
    {
      label: 'Namn',
      renderer: (row: ServiceListSchema) => row?.customer?.fullName,
    },
    {
      label: 'Personnr',
      renderer: (row: ServiceListSchema) => row?.customer?.pin,
    },

    {
      label: 'Startdatum',
      renderer: (row: ServiceListSchema) =>
        getFormattedDate(row?.regDate, 'yyyy-MM-dd'),
    },

    typeof serviceHasKeyCode !== 'boolean'
      ? {
          label: 'Seriekod',
          renderer: (row: ServiceListSchema) => (row?.code ? row?.code : '-'),
        }
      : {
          label: 'Adresslist',
          renderer: (row: ServiceListSchema) => row.addressList,
        },
    { label: 'Tjänst ID', renderer: (row: ServiceListSchema) => row?.id },
    {
      label: 'Status',
      renderer: (row: ServiceListSchema) => {
        const canceldStatusKey = keyTagStatusKey.enum.Makulerad;
        const statusInText = keyTagStatusKey.enum[row.status];

        return (
          <span
            style={{
              color:
                canceldStatusKey && row.status === canceldStatusKey
                  ? '#e0175b'
                  : 'inherit',
            }}
          >
            {statusInText}
          </span>
        );
      },
    },
    {
      label: 'Ändra',
      renderer: (row: ServiceListSchema) => (
        <div>
          <Link component={RouterLink} to={`/products/key-tag/${row.id}/edit`}>
            <IconButton color="primary" size="large">
              <ArrowForwardIcon />
            </IconButton>
          </Link>
          <Link
            target="_blank"
            href={`/products/key-tag/${row.id}/edit`}
            onClick={(e) => e.stopPropagation()}
          >
            <Tooltip title="Öppna i ny flik">
              <IconButton color="primary" size="large">
                <LaunchOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Link>
        </div>
      ),
    },
  ];
};

export const KeyTagServiceList = ({ serviceHasKeyCode }: Props) => {
  const [addressListSearch, setAddressListSearch] = React.useState('');
  const [search, setSearch] = React.useState('');
  const [regDate, setRegDate] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(PER_PAGE);
  const TABLE_COLUMNS = getTableColumns(serviceHasKeyCode);

  const navigate = useNavigate();
  const userRoles = useUserRoles();

  const properties = createApiPropertiesFromJsonSchema(
    zodToJsonSchema(serviceListSchema),
  ) as unknown as Record<string, boolean>;

  const serviceHasKeyCodeFilter = (() => {
    let filter = {};
    if (typeof serviceHasKeyCode === 'boolean') {
      const filter: Record<string, any> = {};
      filter['exists[code]'] = serviceHasKeyCode;

      // Only show active services when serviceHasKeyCode is false
      if (!serviceHasKeyCode) {
        filter['status'] = KeyTagStatusEnum.Aktiv;
      }
      return filter;
    }
    return filter;
  })();

  const regDateFilter = regDate
    ? {
        'regDate[before]': regDate,
        'regDate[after]': regDate,
      }
    : {};

  const addressListSearchFilter = addressListSearch
    ? { addressList: addressListSearch }
    : {};

  const filter = {
    page: page + 1,
    perPage,
    params: {
      search,
      ...addressListSearchFilter,
      ...regDateFilter,
      ...serviceHasKeyCodeFilter,
    },
    properties,
  };

  const { data, isLoading, isError } = useGetKeyTags(filter);

  const handlePageChange = (_: any, newPage: SetStateAction<number>) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: { target: { value: string } }) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    setPerPage(rowsPerPage);
  };

  const handleRowClick = (row: ServiceListSchema) => {
    navigate(`/products/key-tag/${row.id}/edit`);
  };

  if (isError) return <BasicAlert />;

  return (
    <>
      {hasAccessToAllCustomers(userRoles) && (
        <Paper sx={{ mb: 1, p: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <SearchField setDebouncedQueryString={setSearch} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SearchField
                placeholder="Sök adresslista"
                setDebouncedQueryString={setAddressListSearch}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                margin="dense"
                label="Registreringsdatum"
                value={regDate}
                onChange={(e) => setRegDate(e.target.value)}
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </Paper>
      )}
      {isLoading && <LinearProgress />}
      {data && data?.['hydra:totalItems'] === 0 && (
        <Paper style={{ padding: '2rem' }}>
          <Typography>Inget resultat</Typography>
        </Paper>
      )}
      {data && data['hydra:totalItems'] > 0 && (
        <>
          <SimpleTable
            columns={TABLE_COLUMNS}
            rows={data?.['hydra:member']}
            onRowClick={handleRowClick}
          />
          <Paper square>
            <Pagination
              count={data?.['hydra:totalItems'] || 0}
              rowsPerPage={perPage}
              page={page}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </Paper>
        </>
      )}
    </>
  );
};
