import { PrivateSettings } from '@/types/settings';

export const getNoteTypeByName = (
  settings: PrivateSettings,
  noteName: string,
) => {
  if (settings) {
    const stringKey = Object.keys(settings.types.note).find(
      (key) => settings.types.note[parseInt(key, 10)] === noteName,
    );

    if (!stringKey) {
      return null;
    }

    return parseInt(stringKey, 10);
  }
};
