import React from 'react';

import { Button } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { TextField } from 'mui-rff';
import { Form } from 'react-final-form';

import { Dropdown } from '@/components/form';
import { queryKeys } from '@/constants/queryKeys';
import { usePrivateSettings, useSnackbar } from '@/hooks';
import { BaseModal } from '@/modules/common/components';
import httpClient from '@/utils/httpClient';
import { fieldRequired } from '@/utils/validation';

type Props = {
  service: {
    status: string;
    '@id': string;
  };
  onClose: () => void;
};

export const ConfigureHomegateModal = ({
  service: { status, '@id': serviceIri },
  onClose,
}: Props) => {
  const snackbar = useSnackbar();
  const { generateStatusOptions } = usePrivateSettings();
  const queryClient = useQueryClient();

  const configureHomegate = useMutation({
    mutationFn: async (values) => await httpClient.put(serviceIri, values),
    onSuccess: () => {
      snackbar({ type: 'success', message: 'Konfigurering lyckades' }),
        queryClient.invalidateQueries({
          queryKey: [queryKeys.getServiceConfigurationList],
        });
    },
    onError: () => {
      snackbar({
        type: 'error',
        message: 'Konfigurering misslyckades',
      });
    },
    onSettled: () => {
      onClose();
    },
  });

  return (
    <Form
      onSubmit={() => configureHomegate.mutate()}
      initialValues={{ status }}
    >
      {({ handleSubmit }) => (
        <BaseModal
          onClose={onClose}
          open={true}
          title="Konfigurera Homegate"
          buttons={
            <>
              <Button variant="outlined" onClick={onClose}>
                Avbryt
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                Spara
              </Button>
            </>
          }
        >
          <form onSubmit={handleSubmit}>
            <Dropdown
              name="status"
              label="Status"
              options={generateStatusOptions('homegate')}
              fieldProps={{
                parse: (v) => parseInt(v, 10),
                validate: fieldRequired,
              }}
            />

            <TextField
              variant="outlined"
              name="batchId"
              label="Batchnummer"
              fieldProps={{
                validate: fieldRequired,
              }}
            />
          </form>
        </BaseModal>
      )}
    </Form>
  );
};
