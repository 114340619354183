import React from 'react';

import { Box, Grid, LinearProgress, Paper, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import camelCase from 'lodash/camelCase';
import snakeCase from 'lodash/snakeCase';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';

import { BasicAlert } from '@/components/BasicAlert';
import { Pagination } from '@/components/Pagination';
import { SimpleTable } from '@/components/SimpleTable';
import { queryKeys } from '@/constants/queryKeys';
import { getServiceToConfigure } from '@/helpers/Api';
import { getCamelCaseServiceName } from '@/helpers/tsHelpers';
import { usePrivateSettings } from '@/hooks';
import { SearchField } from '@/modules/common/components';
import {
  SERVICES_WITH_CONFIG,
  ServiceWithConfig,
} from '@/modules/services/constants';
import {
  CamelCasedConfigurabelServiceNamesEnum,
  ConfigurabelServiceNamesEnum,
} from '@/types/services';

import { ConfigureFiretextModal, ConfigureHomegateModal } from './components';
import { useSinneTableConfig } from './components/useSinneTableConfig';
import { getTableConfig } from './helpers';

type Params = {
  serviceName: ServiceWithConfig;
};
export const Configuration = ({}) => {
  const { serviceName } = useParams<Params>();
  invariant(serviceName, 'serviceName is required');

  const navigate = useNavigate();

  const { servicesStatuses, servicesTypes } = usePrivateSettings();

  const [debouncedQueryString, setDebouncedQueryString] = React.useState('');
  const [total, setTotal] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(100);
  const [showFiretextModal, setShowFiretextModal] = React.useState(false);
  const [showHomegateModal, setShowHomegateModal] = React.useState(false);
  const [serviceToConfigure, setServiceToConfigure] = React.useState<
    Record<string, any> | number | null
  >(null);

  const sinneTableConfig = useSinneTableConfig({
    serviceName,
    servicesStatuses,
  });

  const serviceTypes =
    (serviceName === 'protection' || serviceName === 'co-protection') &&
    servicesTypes[(camelCase(serviceName) as 'protection') || 'coProtection'];

  const { isError, data, isFetching } = useQuery({
    queryKey: [
      queryKeys.getServiceConfigurationList,
      debouncedQueryString,
      page,
      perPage,
      serviceName,
    ],

    queryFn: async () => {
      const serviceNameForApiService = getCamelCaseServiceName(
        ConfigurabelServiceNamesEnum.parse(serviceName),
      );
      const snakeCaseServiceName = snakeCase(serviceName);

      const apiService = getServiceToConfigure(
        CamelCasedConfigurabelServiceNamesEnum.parse(serviceNameForApiService),
      );
      const data = await apiService({
        page: page + 1,
        perPage,
        search: debouncedQueryString,
        serviceName: snakeCaseServiceName,
        status: [6, 8],
      });

      const total = data['hydra:totalItems'];
      const products = data['hydra:member'];

      setTotal(total);
      return products;
    },

    initialData: [],
  });

  const handlePageChange = (_: any, page: React.SetStateAction<number>) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event: { target: { value: string } }) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    setPerPage(rowsPerPage);
  };

  const handleRowClick = (row: any) => {
    switch (serviceName) {
      case 'homegate':
        setServiceToConfigure(row);
        setShowHomegateModal(true);
        return;
      case 'firetext':
        setServiceToConfigure(row.id);
        setShowFiretextModal(true);
        return;
      case 'sinne':
        return;
      default:
        navigate(`/products/${serviceName}/${row.id}/edit`);
    }
  };

  const tableColumns = React.useMemo(() => {
    if (SERVICES_WITH_CONFIG.includes(serviceName)) {
      if (serviceName === 'sinne') {
        return sinneTableConfig;
      }
      return getTableConfig({
        serviceName,
        serviceTypes,
        servicesStatuses,
      });
    }
  }, [serviceName]);

  return (
    <Box>
      <Grid
        component={Paper}
        style={{ padding: '1rem', marginBottom: '0.5rem' }}
        container
      >
        <Grid item xs={12} sm={6}>
          <SearchField setDebouncedQueryString={setDebouncedQueryString} />
        </Grid>
      </Grid>

      {isFetching ? (
        <LinearProgress />
      ) : isError ? (
        <BasicAlert />
      ) : total === 0 ? (
        <Paper style={{ padding: '2rem' }}>
          <Typography>Inget resultat</Typography>
        </Paper>
      ) : (
        !!tableColumns && (
          <>
            <SimpleTable
              columns={tableColumns}
              rows={data}
              onRowClick={handleRowClick}
            />

            <Paper square>
              <Pagination
                count={total}
                rowsPerPage={perPage}
                page={page}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
              />
            </Paper>
          </>
        )
      )}

      {showFiretextModal && serviceToConfigure && (
        <ConfigureFiretextModal
          // @ts-ignore
          serviceId={serviceToConfigure}
          handleCloseModal={() => setShowFiretextModal(false)}
        />
      )}

      {showHomegateModal && serviceToConfigure && (
        <ConfigureHomegateModal
          // @ts-ignore
          service={serviceToConfigure}
          onClose={() => setShowHomegateModal(false)}
        />
      )}
    </Box>
  );
};

Configuration.propTypes = {
  serviceName: PropTypes.string.isRequired,
};
