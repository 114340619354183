import httpClient from '@/utils/httpClient';

export type SendDirectCustomerEmail = {
  customerId: string;
  receiver: string;
  message: string;
};

export async function sendDirectCustomerEmail({
  customerId,
  receiver,
  message,
}: SendDirectCustomerEmail) {
  const data = await httpClient.post('/rpc/send-email/direct-customer', {
    customerId,
    receiver,
    message,
  });

  return data;
}

export type SendFoundItemEmailPayload = {
  name: string;
  phoneNumber: string;
  address: string;
  postalCode: string;
  city: string;
  object: string;
  productType: 'sticker' | 'key-tag';
  code: string;
};

export async function sendFoundItemEmail(payload: SendFoundItemEmailPayload) {
  const { data } = await httpClient.post(
    'rpc/send-email/object-found',
    payload,
  );

  return data;
}
