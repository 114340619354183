import * as Sentry from '@sentry/react';
import isNil from 'lodash/isNil';
import { z } from 'zod';
import zodToJsonSchema from 'zod-to-json-schema';

import { createApiPropertiesFromJsonSchema } from '@/helpers/createApiPropertiesFromJsonSchema';
import {
  getActiveServiceParams,
  getChurnDateParams,
} from '@/modules/accountManagerSystem/helpers';
import { getAPIServiceName } from '@/modules/services';
import { ApiCollectionResponse } from '@/types/common';
import {
  CreateCustomerPayload,
  Customer,
  CustomerDTO,
  CustomerParams,
  CustomerSearchTypes,
  CustomerSortFields,
  CustomerSortOrders,
  CustomerToDeleteData,
  UpdateCustomerPayload,
  basicCustomerDataSchema,
  customerToDeleteDataSchema,
} from '@/types/customers';
import { CamelCaseServiceName, ServiceNamesEnum } from '@/types/services';
import {
  Products,
  coProtectionStatusKey,
  firetextStatusKey,
  homegateStatusKey,
  keyTagStatusKey,
  protectionStatusKey,
  sinneStatusKey,
  stickerStatusKey,
} from '@/types/settings';
import httpClient from '@/utils/httpClient';

export async function fetchBasicCustomerData(customerId: number | string) {
  const { data } = await httpClient.get(`/customers/${customerId}`, {
    params: {
      properties: createApiPropertiesFromJsonSchema(
        zodToJsonSchema(basicCustomerDataSchema),
      ),
    },
  });

  return basicCustomerDataSchema.parse(data);
}

export async function getCustomerById<CustomerType extends Customer = Customer>(
  id: number | string,
  fieldsToFetch?: {
    [key: string]: boolean | object | { [key: string]: boolean | object };
  }, // TODO improve type to only allow CustomerProps
) {
  const { data } = await httpClient.get<CustomerType>(`/customers/${id}`, {
    params: {
      properties: fieldsToFetch ?? {
        id: true,
        category: true,
        firstName: true,
        lastName: true,
        fullName: true,
        address: true,
        address2: true,
        zip: true,
        city: true,
        email: true,
        phone: true,
        mobile: true,
        pin: true,
        invoiceEmail: true,
        validInvoiceEmail: true,
        invoicePeriod: true,
        bankName: true,
        bankAccount: true,
        autogiroAgreement: true,
        autogiroSource: true,
        latitude: true,
        longitude: true,
        companyName: true,
        company: true,
        doNotDisturb: true,
        lastLogin: true,
        password: true,
        passwordType: true,
        saveStatus: true,
        savedAt: true,
        partnerDate: true,
        notes: {
          id: true,
          createdBy: {
            id: true,
            name: true,
          },
          customer: true,
          createdAt: true,
          text: true,
          type: true,
          taggedUsers: {
            id: true,
            name: true,
          },
        },
        costs: {
          id: true,
          type: true,
          price: true,
          createdAt: true,
          comment: true,
        },
        coProtections: true,
        firetexts: true,
        homegates: {
          price: true,
          campaign: true,
          status: true,
          regDate: true,
          id: true,
        },
        keyTags: true,
        protections: {
          price: true,
          campaign: true,
          status: true,
          regDate: true,
          pricePlanId: true,
          id: true,
        },
        stickers: true,
        fees: true,
        feesForBilling: true,
        partnerCompany: {
          id: true,
          name: true,
        },
        agreements: true,
        savedBy: {
          userName: true,
          name: true,
          email: true,
          id: true,
        },
        activeServiceTypes: true,
        activeProtections: true,
      },
    },
  });

  return data;
}

type FetchCustomersArgs = {
  q: string;
  sortField: CustomerSortFields;
  sortOrder: CustomerSortOrders;
  type: CustomerSearchTypes;
  page?: number;
  perPage?: number;
  debtCollection?: boolean;
};
export async function fetchCustomers({
  q,
  type,
  sortField,
  sortOrder,
  page,
  perPage,
  debtCollection,
}: FetchCustomersArgs) {
  let order;

  if (sortField === 'fullName') {
    order = {
      firstName: sortOrder,
      lastName: sortOrder,
    };
  } else {
    order = {
      [sortField]: sortOrder,
    };
  }

  const properties = {
    id: true,
    company: true,
    companyName: true,
    fullName: true,
    category: true,
    activeServiceTypes: true,
    tasks: {
      id: true,
    },
    pin: true,
    debtCollection: true,
  };

  const params = {
    ...(!debtCollection && { debtCollection }),
    order,
    page,
    perPage,
    properties,
    pagination: true,
  };

  if (type === 'standard') {
    const { data } = await httpClient.get(`/customers`, {
      params: {
        ...params,
        search: q,
      },
    });

    return data as ApiCollectionResponse<Customer>;
  } else {
    const { data } = await httpClient.get(`/customers/advanced`, {
      params: {
        ...params,
        q,
        type,
      },
    });

    return data as ApiCollectionResponse<Customer>;
  }
}

export async function deleteCustomer(customerId: number) {
  const { data } = await httpClient.delete(`/customers/${customerId}`);

  return data;
}

export async function findCustomersToDelete({
  page = 1,
  perPage = 30,
  search = '',
}) {
  const properties = createApiPropertiesFromJsonSchema(
    zodToJsonSchema(customerToDeleteDataSchema),
  );
  const { data } = await httpClient.get<
    ApiCollectionResponse<CustomerToDeleteData>
  >(`/customers`, {
    params: {
      pagination: true,
      page,
      perPage,
      search,
      properties,
    },
  });

  return data['hydra:member'];
}

type FetchManagedCustomersArgs = {
  accountManagerId?: number;
  page: number;
  perPage: number;
  type: CustomerSearchTypes;
  q: string;
  churnDateExists: boolean;
  onboarded?: boolean;
  hasActiveService?: boolean;
  activeProducts: Products;
};

export async function fetchManagedCustomers({
  accountManagerId,
  page,
  perPage,
  q,
  type,
  churnDateExists,
  hasActiveService,
  onboarded,
  activeProducts,
}: FetchManagedCustomersArgs) {
  const properties = {
    doNotDisturb: true,
    onboardedAt: true,
    id: true,
    fullName: true,
    company: true,
    companyName: true,
    serviceTypes: true,
    email: true,
    phone: true,
    mobile: true,
    pin: true,
    notes: {
      id: true,
      type: true,
      text: true,
      createdAt: true,
    },
  };
  const params: CustomerParams = {
    page: page + 1,
    perPage,
    pagination: true,
    search: q,
    q,
    type,
    accountManager: `/users/${accountManagerId}`,
    doNotDisturb: false,
    ...(!isNil(hasActiveService) &&
      getActiveServiceParams(hasActiveService, activeProducts)),
    exists: {
      onboardedAt: onboarded,
      ...(!isNil(churnDateExists) &&
        getChurnDateParams(churnDateExists, activeProducts)),
    },
    properties: {
      ...properties,
    },
  };

  let endpoint = '/customers';

  if (type === 'standard') {
    delete params.q;
    delete params.type;
  } else {
    // delete params.search;
    endpoint += '/advanced';
  }

  const { data } = await httpClient.get(endpoint, {
    params,
  });

  return data;
}

type FetchCustomersForServiceArgs = {
  serviceName: CamelCaseServiceName;
  page: number;
  perPage: number;
  search: string;
};
export const serviceListSchema = z.object({
  addressList: z.string().optional(),
  code: z.string().optional(),
  customer: z.object({
    id: z.string(),
    pin: z.string(),
    fullName: z.string(),
  }),
  icc: z.string().optional(),
  id: z.number().int(),
  regDate: z.string(),
  status: coProtectionStatusKey
    .or(firetextStatusKey)
    .or(homegateStatusKey)
    .or(keyTagStatusKey)
    .or(protectionStatusKey)
    .or(sinneStatusKey)
    .or(stickerStatusKey),
});

export type ServiceListSchema = z.infer<typeof serviceListSchema>;
export async function fetchCustomersForService({
  serviceName,
  page,
  perPage,
  search,
}: FetchCustomersForServiceArgs) {
  const endpoint = getAPIServiceName(serviceName);

  const properties = createApiPropertiesFromJsonSchema(
    zodToJsonSchema(serviceListSchema),
  );

  const { data } = await httpClient.get<
    ApiCollectionResponse<ServiceListSchema>
  >(`/${endpoint}`, {
    params: {
      pagination: true,
      page,
      perPage,
      ...(search ? { search } : {}),
      properties,
    },
  });

  return data;
}

const findCustomerByPinParamSchema = basicCustomerDataSchema.extend({
  activeServiceTypes: z.array(z.string()),
  activeProtections: z.array(z.string()),
  address: z.string(),
  address2: z.string(),
  zip: z.string(),
  city: z.string(),
  email: z.string(),
  accountManager: z.object({ id: z.string() }),
  partnerDate: z.string(),
});

export type FindCustomerByPinParamSchema = z.infer<
  typeof findCustomerByPinParamSchema
>;

export async function findCustomerByPIN(pin: string) {
  const { data } = await httpClient.get<
    ApiCollectionResponse<z.infer<typeof findCustomerByPinParamSchema>>
  >('/customers', {
    params: {
      pin,
      properties: createApiPropertiesFromJsonSchema(
        zodToJsonSchema(findCustomerByPinParamSchema),
      ),
    },
  });

  return data['hydra:member'];
}

export type UpdatedCustomerResponse = CustomerDTO & { sveaError?: string };

export async function updateCustomer({
  id,
  ...rest
}: { id: string | number } & UpdateCustomerPayload) {
  const response = await httpClient.put(`/customers/${id}`, rest);

  if (response.data.invoicePartnerResponse) {
    const responseTitle =
      response.data.invoicePartnerResponse?.Title.toLowerCase();

    if (responseTitle.includes('error')) {
      response.data.sveaError = await handleSveaError(
        response.data.invoicePartnerResponse,
      );
    }
  }

  return response as { data: UpdatedCustomerResponse };
}

export async function createCustomer({
  firstName,
  lastName,
  city,
  zip,
  address,
  address2,
  pin,
  email,
  accountManager,
  phone,
  mobile,
  category,
  bankName,
  bankAccount,
  autogiroAgreement,
  autogiroSource,
  companyName,
  doNotDisturb,
  invoiceEmail,
  partnerCompany,
  partnerDate,
}: CreateCustomerPayload) {
  const { data } = await httpClient.post('/customers', {
    firstName,
    lastName,
    city,
    zip,
    address,
    address2,
    pin,
    email,
    phone,
    mobile,
    category,
    bankName,
    bankAccount,
    autogiroAgreement: autogiroAgreement,
    autogiroSource,
    companyName,
    doNotDisturb: doNotDisturb || false,
    invoiceEmail: invoiceEmail || '',
    partnerCompany: partnerCompany || null,
    partnerDate: partnerDate || null,
    accountManager,
  });

  if (data.invoicePartnerResponse) {
    const responseTitle = data.invoicePartnerResponse?.Title.toLowerCase();

    if (responseTitle.includes('error')) {
      data.data = {
        sveaError: await handleSveaError(data.invoicePartnerResponse),
      };
    }
  }

  return data;
}

export const sveaFeePayloadSchema = z.object({
  amount_excl_vat: z.number(),
  customerId: z.number(),
  description: z.string(),
  start_date: z.string(), //'2023-01-01';
});
export type SveaFeePayload = z.infer<typeof sveaFeePayloadSchema>;
type SveaFeeResponse = {
  message: string;
};

export async function createFeeInSvea(args: SveaFeePayload) {
  const { customerId, ...payload } = args;
  const { data } = await httpClient.put<SveaFeeResponse>(
    `/customers/${customerId}/add-fee`, // this endpoint is correct, svea is not part of the url path
    payload,
  );

  return data;
}

export const sveaBulkFeePayloadSchema = sveaFeePayloadSchema
  .omit({ customerId: true })
  .extend({ customers: z.array(z.string()) });
export type SveaBulkFeePayload = z.infer<typeof sveaBulkFeePayloadSchema>;

export async function createBulkFeesInSvea(payload: SveaBulkFeePayload) {
  const { data } = await httpClient.put<SveaFeeResponse>(
    '/customers/send.bulk.fees.to.svea',
    payload,
  );

  return data;
}

type SveaNextInvoiceResponse = {
  amountOfContracts: number;
  amountExclVat: number;
  amountInclVat: number;
};

export async function getNextSveaInvoice(customerId: number | string) {
  const { data } = await httpClient.get<SveaNextInvoiceResponse>(
    `/customers/${customerId}/svea/next-invoice`,
  );
  return data;
}

type SveaInvoicePartnerResponse = {
  ErrorCode: number;
  ErrorText: string;
  Provider: string;
  CustomerId: string;
  Reference: string | undefined;
  CurlError: null | string;
  RawResult: string;
  RequestType: string;
};
// Private
async function handleSveaError(
  invoicePartnerResponse: SveaInvoicePartnerResponse,
) {
  const {
    ErrorCode,
    ErrorText,
    Provider,
    CustomerId,
    Reference,
    CurlError,
    RawResult,
  } = invoicePartnerResponse;

  if (ErrorCode === 400 || ErrorCode === 500) {
    const message = `Felmeddelande: Fel inträffade hos ${Provider} gällande ${CustomerId} pga ${ErrorText}. Kontrollera att kundinformationen hos ${Provider} är korrekt.`;
    Sentry.captureMessage(message);
    return message;
  }

  if (CurlError) {
    const message = `Felmeddelande: Fel inträffade hos ${Provider} gällande ${CustomerId}. Kontrollera att kundinformationen hos ${Provider} är korrekt.`;
    Sentry.captureMessage(message);
    return message;
  }

  if (RawResult) {
    const message = `Felmeddelande: Fel inträffade hos ${Provider} gällande ${CustomerId}. Meddelande: ${RawResult}, referens: ${Reference}. Kontrollera att kundinformationen hos ${Provider} är korrekt.`;
    Sentry.captureMessage(message);
    return message;
  }

  const message = `Felmeddelande: Fel inträffade hos ${Provider} gällande ${CustomerId}. Kontrollera att kundinformationen hos ${Provider} är korrekt.`;
  Sentry.captureMessage(message);
  return message;
}
