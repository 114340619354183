import React from 'react';

import {
  Alert,
  AlertTitle,
  Card,
  CardContent,
  CardHeader,
  Divider,
  LinearProgress,
  Stack,
} from '@mui/material';
import { endOfMonth, startOfMonth } from 'date-fns';

import { getFormattedDate } from '@/helpers/dates';
import { PartnerResponse } from '@/types/partnerCompany';

import { showKickbackStats, showRevenueStats } from '../../helpers';
import { useGetPartnerStatistics } from '../../hooks/useGetPartnerStatistics';
import { PartnerAutoComplete } from '../PartnerAutocomplete';
import { KickbackCardTable } from './KickbackCardTable';
import { RevenueCardTable } from './RevenueCardTable';

export type PartnerStatisticsPeriodParams = {
  start: string | null;
  end: string | null;
};

export const PartnerStatistics = () => {
  const [selectedPartner, setSelectedPartner] =
    React.useState<Partial<PartnerResponse> | null>();

  const [fetchParams, setFetchParams] =
    React.useState<PartnerStatisticsPeriodParams>({
      start: getFormattedDate(startOfMonth(new Date())),
      end: getFormattedDate(endOfMonth(new Date())),
    });

  const {
    data: stats,
    isFetching,
    isError,
    error,
  } = useGetPartnerStatistics({
    partnerId: Number(selectedPartner?.id),
    start: fetchParams.start,
    end: fetchParams.end,
  });

  //@ts-expect-error Type Error is missing the following properties from type { statusText: string; status: number; }: statusText, status
  const typedError = error as { statusText: string; status: number };

  const isKickbackEnabled = selectedPartner?.kickback;
  const isTickEnabled = selectedPartner?.tick;
  const isPartnerSelected = !!selectedPartner?.id;

  const isPartnerWithoutStats =
    !isFetching && !isKickbackEnabled && !isTickEnabled && isPartnerSelected;

  return (
    <Card>
      <CardHeader title="Statistik" />
      <Divider />
      <CardContent>
        <Stack spacing={2}>
          <PartnerAutoComplete
            value={selectedPartner?.id?.toString() || null}
            onChange={(partner) => setSelectedPartner(partner)}
          />
          {isFetching && <LinearProgress />}
          {isError && (
            <Alert severity="error">
              <AlertTitle>Kunde inte hämta statistik</AlertTitle>
              <p>{typedError?.status}</p>
              <p>{typedError?.statusText || 'Något gick fel'}</p>
            </Alert>
          )}
          {showKickbackStats(selectedPartner) && (
            <KickbackCardTable
              stats={stats?.kickbackStatistics}
              periodParams={fetchParams}
              setFetchParams={setFetchParams}
            />
          )}
          {showRevenueStats(selectedPartner) && (
            <RevenueCardTable stats={stats?.revenueStatistics} />
          )}

          {!isPartnerSelected && (
            <Alert severity="info">
              <AlertTitle>Välj en partner för att se statistik</AlertTitle>
            </Alert>
          )}

          {isPartnerWithoutStats && (
            <Alert severity="info">
              <AlertTitle>
                Ingen statistik tillgänglig för vald partner
              </AlertTitle>
            </Alert>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};
