import React from 'react';

import DoneIcon from '@mui/icons-material/Done';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material';
import { green } from '@mui/material/colors';
import { styled } from '@mui/material/styles';

import * as firetextApi from '@/api/firetext';
import { DialogActions } from '@/components/DialogActions';
import { Customer } from '@/types/customers';
import { FiretextItem } from '@/types/services';

import { DialogContent } from '../helpers';
import { CustomerInfo } from './CustomerInfo';

type Props = {
  service: FiretextItem;
  customer: Customer;
  goBack: () => void;
  goToNextStep: () => void;
};

export const Step2 = ({ goToNextStep, goBack, service, customer }: Props) => {
  const [smsSendSuccess, setSmsSendSuccess] = React.useState(false);
  const [smsSendInProgress, setSmsSendInProgress] = React.useState(false);
  const [smsSendError, setSmsSendError] = React.useState<string | null>(null);
  const [deviceAlreadyConfigured, setDeviceAlreadyConfigured] =
    React.useState(false);

  async function sendSms() {
    setSmsSendInProgress(true);

    try {
      await firetextApi.firetextConfigSmsSend(service.id);
      setSmsSendInProgress(false);
      setSmsSendSuccess(true);
    } catch (error) {
      setSmsSendInProgress(false);
      setSmsSendError('Något gick fel');
    }
  }

  function handleDeviceAlreadyConfiguredChange(event: any) {
    setDeviceAlreadyConfigured(event.target.checked);
  }

  return (
    <>
      <DialogContent>
        <CustomerInfo customer={customer} />

        <Typography>
          Skicka ett konfigurations-SMS och vänta sedan på svar
        </Typography>

        <StyledGrid container spacing={3} alignItems="center">
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              disabled={deviceAlreadyConfigured}
              onClick={() => sendSms()}
            >
              Skicka Meddelande
            </Button>
          </Grid>

          <Grid item>
            {smsSendInProgress && <CircularProgress size={20} />}
            {smsSendSuccess && <DoneIcon style={{ color: green[500] }} />}
            {smsSendError && <ErrorIcon color="error" />}
          </Grid>
        </StyledGrid>

        {smsSendError && <Typography color="error">{smsSendError}</Typography>}

        <FormControlLabel
          disabled={smsSendSuccess}
          control={
            <Checkbox
              checked={deviceAlreadyConfigured}
              onChange={handleDeviceAlreadyConfiguredChange}
              name="deviceAlreadyConfigured"
              color="primary"
            />
          }
          label="Enheten redan konfigurerad"
        />
      </DialogContent>

      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={goBack}
          disabled={smsSendSuccess && deviceAlreadyConfigured}
        >
          Tillbaka
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={goToNextStep}
          disabled={!smsSendSuccess && !deviceAlreadyConfigured}
        >
          Gå vidare
        </Button>
      </DialogActions>
    </>
  );
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2),
  paddingBottom: theme.spacing(1),
}));
