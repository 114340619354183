import { PageWrapper } from '@/components';
import { LostDeviceFlow } from '@/modules/sinne';

const LostDeviceFlowPage = () => {
  return (
    <PageWrapper
      maxWidth="md"
      pageTitle="Faktureringsflöde för ej returnerade enheter"
      breadCrumbs={[
        { label: 'Kundservice' },
        {
          label: 'Ej returnerade enheter',
          url: '/support/sinne/missing-devices',
        },
        { label: 'Faktureringsflöde' },
      ]}
      children={<LostDeviceFlow />}
    />
  );
};

export default LostDeviceFlowPage;
