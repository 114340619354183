import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Form } from 'react-final-form';

import { TextInput } from '@/components/form';
import { makeValidation } from '@/utils/validation';

import { VALIDATION_SCHEMAS } from '../constants';

type Props = {
  onSubmit: (values: any) => void;
  onCancel: () => void;
  isLoading: boolean;
  name: keyof typeof VALIDATION_SCHEMAS;
  label: string;
  placeholder: string;
  onDelete?: () => void;
};

const DeleteButton = styled(Button)`
  border-color: ${({ theme }) => theme.palette.error.main};
  color: ${({ theme }) => theme.palette.error.main};
`;

const ButtonWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 0;
  & button:not(:first-of-type) {
    margin-left: 1rem;
  }
`;

export const ChangeInputForm = ({
  onSubmit,
  onCancel,
  isLoading,
  name,
  label,
  placeholder,
  onDelete,
}: Props) => {
  const validate = makeValidation(VALIDATION_SCHEMAS[name]);

  return (
    <>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={{
          [name]: placeholder,
        }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <TextInput
              disabled={isLoading}
              type="text"
              label={label}
              name={name}
              sx={{ marginBottom: 0 }}
            />
            <ButtonWrapper>
              <Button
                disabled={isLoading}
                onClick={onCancel}
                variant="outlined"
                color="primary"
              >
                Avbryt
              </Button>
              <Button
                fullWidth
                type="submit"
                color="primary"
                variant="contained"
              >
                Spara
              </Button>
              {onDelete && (
                <DeleteButton fullWidth variant="outlined" onClick={onDelete}>
                  Ta bort
                </DeleteButton>
              )}
            </ButtonWrapper>
          </form>
        )}
      />
    </>
  );
};
