import {
  AlertTitle,
  Button,
  CardActions,
  CardHeader,
  Dialog,
  DialogContent,
  Divider,
  LinearProgress,
  Typography,
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FormApi } from 'final-form';
import { Form } from 'react-final-form';

import { updateCustomer } from '@/api/customers';
import { DirectDebitFields } from '@/components/DirectDebitFields';
import { queryKeys } from '@/constants/queryKeys';
import { getChangedFormFields } from '@/helpers';
import { useSnackbar } from '@/hooks';
import { Customer, UpdateCustomer } from '@/types/customers';

type AutogiroInfo = Pick<
  Customer,
  'bankName' | 'bankAccount' | 'autogiroSource' | 'autogiroAgreement'
>;

type Props = {
  autogiroInfo: AutogiroInfo;
  customerId: string | number;
  open: boolean;
  handleClose: () => void;
};

export const EditAutogiroModal = ({
  autogiroInfo,
  customerId,
  open,
  handleClose,
}: Props) => {
  const snackbar = useSnackbar();

  const queryClient = useQueryClient();

  const updateCustomerMutation = useMutation({
    mutationFn: (values: UpdateCustomer) => updateCustomer(values),
    onSuccess: (response) => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.getCustomerById, response?.data?.id],
      });

      // TODO: Check if sveaError should be on the response object
      if ((response as any).sveaError) {
        snackbar({
          type: 'warning',
          message: (
            <>
              <AlertTitle>Ändringar sparade.</AlertTitle>
              OBS! {(response as any).sveaError}
            </>
          ),
          autoHide: false,
        });
      } else {
        snackbar({
          type: 'success',
          message: 'Ändringar sparade',
        });
      }
      handleClose();
    },
    onError: (e) => {
      snackbar({
        type: 'error',
        message: 'Kunde inte uppdatera kund',
      });
    },
  });

  const { isPending, isError, error } = updateCustomerMutation;

  const onSubmit = (values: AutogiroInfo, form: FormApi) => {
    const fieldsToPost = getChangedFormFields(values, form) as UpdateCustomer;
    fieldsToPost.id = customerId;
    updateCustomerMutation.mutate(fieldsToPost);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <Form
        initialValues={autogiroInfo}
        onSubmit={onSubmit}
        render={({ handleSubmit, pristine }) => (
          <DialogContent>
            <CardHeader title="Autogiro" />
            <Divider />
            <DirectDebitFields />
            <Divider />

            <CardActions style={{ justifyContent: 'space-between' }}>
              <Button
                onClick={handleClose}
                variant="outlined"
                color="primary"
                disabled={isPending}
              >
                Avbryt
              </Button>
              <Button
                color="primary"
                disabled={pristine || isPending}
                onClick={handleSubmit}
                variant="contained"
              >
                Spara ändringar
              </Button>
            </CardActions>
            {isPending && <LinearProgress />}
            {isError && (
              <Typography color="error" style={{ paddingLeft: 16 }}>
                Något gick fel.
                {(error as any)?.request?.status}{' '}
                {(error as any)?.request?.statusText}
              </Typography>
            )}
          </DialogContent>
        )}
      />
    </Dialog>
  );
};
