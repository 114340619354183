import { useQuery } from '@tanstack/react-query';

import { getCustomLetterTemplates } from '@/api/customLetterTemplates';
import { queryKeys } from '@/constants/queryKeys';

export const useGetCustomLetters = () => {
  return useQuery({
    queryKey: queryKeys.getCustomLetters.all,
    queryFn: getCustomLetterTemplates,
  });
};
