import { SvgIcon, SvgIconProps } from '@mui/material';

export const ContactAdminIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 96 96" width="96px" height="96px">
      <g id="surface192619361">
        <path d="M 49.640625 6.039062 C 30.171875 5.121094 14 20.714844 14 40 L 14 63 C 14 68.746094 18.53125 73.378906 24.167969 73.832031 C 24.625 81.675781 31.046875 88 39 88 L 41.027344 88 C 41.941406 89.207031 43.375 90 45 90 L 51 90 C 53.757812 90 56 87.757812 56 85 C 56 82.242188 53.757812 80 51 80 L 45 80 C 43.375 80 41.941406 80.792969 41.027344 82 L 39 82 C 34.347656 82 30.710938 78.503906 30.199219 74 L 35 74 C 36.65625 74 38 72.65625 38 71 L 38 49 C 38 47.34375 36.65625 46 35 46 L 20 46 L 20 40 C 20 24.050781 33.21875 11.273438 49.355469 12.03125 C 64.382812 12.738281 76 25.71875 76 40.875 L 76 46 L 61 46 C 59.34375 46 58 47.34375 58 49 L 58 71 C 58 72.65625 59.34375 74 61 74 L 71 74 C 77.039062 74 82 69.039062 82 63 L 82 40.875 C 82 22.644531 67.960938 6.902344 49.640625 6.039062 Z M 20 52 L 32 52 L 32 68 L 25 68 C 22.203125 68 20 65.796875 20 63 Z M 64 52 L 76 52 L 76 63 C 76 65.796875 73.796875 68 71 68 L 64 68 Z M 64 52 " />
      </g>
    </SvgIcon>
  );
};
