import { ReactNode } from 'react';

import { CustomContentProps } from 'notistack';

export type OpenSnackbarParams = {
  type: SnackbarType;
  title?: ReactNode;
  message: ReactNode;
  autoHide?: boolean;
  options?: {
    bigFont?: boolean;
  };
};

export type SnackbarType = 'success' | 'error' | 'warning' | 'info';

export const statusNamesMap: {
  [key in SnackbarType]: string;
} = {
  success: 'framgång',
  error: 'fel',
  warning: 'varning',
  info: 'info',
} as const;

export type SnackbarProps = {
  title?: string;
} & CustomContentProps;

declare module 'notistack' {
  interface VariantOverrides {
    success: {
      title?: ReactNode;
    };
    error: {
      title?: ReactNode;
    };
    warning: {
      title?: ReactNode;
    };
    info: {
      title?: ReactNode;
    };
    default: {
      title?: ReactNode;
    };
  }
}
