import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Grid, InputAdornment, LinearProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FieldArray } from 'react-final-form-arrays';

import { SimpleCard } from '@/components/SimpleCard';
import { PhoneInput, TextInput } from '@/components/form';
import { useAuth } from '@/hooks/useAuth';
import { SmsIcon } from '@/icons';
import { ContactPerson } from '@/types/services';

const StyledSmsIcon = styled(SmsIcon, {
  shouldForwardProp: (prop) => prop !== 'activeAlarmNotification',
})<{ activeAlarmNotification: boolean }>(
  ({ theme, activeAlarmNotification }) => ({
    color: activeAlarmNotification
      ? '#3B873E'
      : theme.palette.mode === 'light'
        ? theme.palette.action.disabled
        : theme.palette.common.white,
  }),
);

type Props = {
  isLoading: boolean;
  contactPeople: ContactPerson[];
  push: (array: 'contactPeople', args: {}) => void;
  remove: (array: 'contactPeople', index: number) => void;
};

export const ContactListSection = ({
  push,
  remove,
  isLoading,
  contactPeople,
}: Props) => {
  const totalContacts = contactPeople?.length || 0;

  const { getAuthenticatedUserRoles } = useAuth();
  const userRoles = getAuthenticatedUserRoles();
  const isGodmode = userRoles?.includes('ROLE_GODMODE');

  const handleRemove = (index: number) => {
    remove('contactPeople', index);
  };

  return (
    <SimpleCard
      marginBottom="0"
      title="Kontaktlista"
      cardContent={
        <div data-testid="contactPeopleList">
          {isLoading && <LinearProgress />}
          <FieldArray name="contactPeople">
            {({ fields }) =>
              fields.map((name, index) => (
                <div key={name} data-testid={name}>
                  <Grid container spacing={3} key={index}>
                    <Grid item xs>
                      <TextInput
                        label="Namn"
                        name={`${name}.name`}
                        fieldProps={{ defaultValue: '' }}
                      />
                      <TextInput
                        name={`${name}.@id`}
                        sx={{ display: 'none' }}
                      />
                    </Grid>
                    <Grid item xs>
                      <PhoneInput
                        required={false}
                        label="Telefonnummer"
                        name={`${name}.phone`}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <StyledSmsIcon
                                activeAlarmNotification={
                                  fields.value[index].alarmNotification
                                }
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>{' '}
                    <Grid item xs>
                      <TextInput
                        required={false}
                        label="Relation"
                        name={`${name}.relation`}
                      />
                    </Grid>
                    {isGodmode && (
                      <Grid item xs>
                        <Button
                          type="button"
                          variant="outlined"
                          size="small"
                          startIcon={<CloseIcon />}
                          style={{ marginTop: '16px' }}
                          onClick={() => handleRemove(index)}
                        >
                          Ta bort kontakt
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </div>
              ))
            }
          </FieldArray>
          <div className="buttons">
            <Button
              type="button"
              variant="contained"
              color="primary"
              size="small"
              startIcon={<AddIcon />}
              onClick={() => push('contactPeople', {})}
              disabled={totalContacts > 9}
            >
              Lägg till kontakt
            </Button>
          </div>
        </div>
      }
    />
  );
};
