import React from 'react';

import { EditOutlined } from '@mui/icons-material';
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  List,
  Typography,
} from '@mui/material';
import pick from 'lodash/pick';

import { LabelAndContentRow } from '@/components';
import {
  autogiroAgreementTypeToString,
  autogiroSourceTypeToString,
} from '@/constants';
import { customerInformationTranslations } from '@/constants/Translations';
import { getObjectKeys } from '@/helpers/tsHelpers';
import { ExistingCustomer } from '@/types/customers';

import { EditAutogiroModal } from './EditAutogiroModal';

type Props = {
  customer: ExistingCustomer;
};

export const AutogiroCard = ({ customer }: Props) => {
  const [open, setOpen] = React.useState(false);

  const autogiroInfo = pick(customer, [
    'bankName',
    'bankAccount',
    'autogiroSource',
    'autogiroAgreement',
  ]);

  return (
    <Card>
      <CardHeader
        style={{
          height: '56px',
          borderBottom: 'solid 1px rgba(0, 0, 0, 0.12)',
        }}
        title="Autogiro"
        action={
          <IconButton
            color="primary"
            onClick={() => setOpen(true)}
            size="large"
            style={{ padding: '5px' }}
          >
            <EditOutlined />
          </IconButton>
        }
      />
      <CardContent style={{ padding: '1px 0 0 0' }}>
        <List style={{ padding: 0 }}>
          {getObjectKeys(autogiroInfo).map((key, i, arr) => (
            <LabelAndContentRow
              key={i}
              divider={i !== arr.length - 1}
              label={customerInformationTranslations[key]}
              content={
                <Typography component="span">
                  {key === 'autogiroAgreement'
                    ? autogiroAgreementTypeToString(autogiroInfo[key] as number)
                    : key === 'autogiroSource'
                    ? autogiroSourceTypeToString(autogiroInfo[key] as number)
                    : autogiroInfo[key]}
                </Typography>
              }
            />
          ))}
        </List>
      </CardContent>
      <EditAutogiroModal
        autogiroInfo={autogiroInfo}
        customerId={customer.id}
        open={open}
        handleClose={() => setOpen(false)}
      />
    </Card>
  );
};
