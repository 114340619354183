import React from 'react';

import {
  AspectRatio,
  Close,
  ErrorOutline,
  Minimize,
} from '@mui/icons-material';
import {
  AppBar,
  Badge,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  LinearProgress,
  Paper,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import invariant from 'tiny-invariant';

import { getBulkBatches } from '@/api/bulkBatch';
import { Can } from '@/components/Can';
import { TabPanel } from '@/components/TabPanel';
import { useSnackbar } from '@/hooks';
import { useAuth } from '@/hooks/useAuth';
import { useSendingsState } from '@/hooks/useSendingsState';

import { FailedItemsTable } from './FailedItemsTable';
import { SummaryTable } from './SummaryTable';

const Wrapper = styled('div')(() => ({
  position: 'fixed',
  left: 0,
  bottom: 0,
  width: '240px',
  maxHeight: '150px',
  zIndex: '1110',
  overflowY: 'auto',
}));

const SummaryCard = styled(Paper)(({ open }: { open: boolean }) => ({
  bottom: '0',
  left: '0',
  overflow: 'hidden',
  position: 'fixed',
  transitionDuration: '300ms',
  transitionProperty: 'all',
  width: open ? '0' : '239px',
  height: open ? '0' : 'auto',
}));

const SummaryCardHeader = styled(CardHeader)(() => ({
  padding: '0.5rem 1rem 0.5rem 1rem',
  '& .MuiCardHeader-action': {
    margin: 0,
  },
}));

const SummaryCardContent = styled(CardContent)(() => ({
  '&.MuiCardContent-root:last-child': {
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
  },
  marginTop: '0.5rem',
  display: 'flex',
  justifyContent: 'space-around',
}));

const OpenCard = styled(Paper)(() => ({
  transitionProperty: 'all',
  transitionDuration: '300ms',
  position: 'fixed',
  bottom: 0,
  left: 0,
  width: 0,
  height: 0,
}));

export const SendingsManager = () => {
  const { resetSendingsState, setSendingsState, sendingsState } =
    useSendingsState();
  const {
    active,
    batchUUID,
    complete,
    numberOfFails,
    failedItems,
    numberOfRecipients,
    type,
  } = sendingsState;

  const snackbar = useSnackbar();
  const { getAuthenticatedUserRoles } = useAuth();

  const userRoles = getAuthenticatedUserRoles();
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [tab, setTab] = React.useState('1');
  const [intervalId, setIntervalId] = React.useState<NodeJS.Timeout>();
  const openCardRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (active && batchUUID && numberOfRecipients) {
      setLoading(true);
      const intervalId = setInterval(fetchBulkStatus, 2000);
      setIntervalId(intervalId);
    }
  }, [active, batchUUID, numberOfRecipients]);

  React.useEffect(() => {
    if (complete) {
      clearInterval(intervalId);
      setLoading(false);
    }
  }, [intervalId, complete]);

  const handleReset = () => {
    resetSendingsState();
    clearInterval(intervalId);
  };

  async function fetchBulkStatus() {
    try {
      const response = await getBulkBatches({
        batchUUID,
      });

      const numberOfBatches = response['hydra:totalItems'];
      const batches = response['hydra:member'];
      if (numberOfBatches && batches) {
        const allComplete = batches.every((batch) => batch.complete);
        const hasFailedItems = batches.some((batch) => batch.hasFailedItems);
        const failedBulkBatchItems = batches.reduce(
          (acc, batch) => acc.concat(batch.failedBulkBatchItems),
          [] as string[],
        );
        const batchIds = batches.reduce(
          (acc, cVal) => acc.concat(cVal['@id']),
          [] as string[],
        );

        if (allComplete) {
          setLoading(false);

          setSendingsState({
            ...sendingsState,
            numberOfFails: failedBulkBatchItems.length,
            complete: allComplete,
            type,
            failedItems: failedBulkBatchItems,
            failed: hasFailedItems,
            bulkBatchIds: batchIds,
            numberOfBatches: batches.length,
          });

          if (
            hasFailedItems &&
            failedBulkBatchItems.length !== numberOfRecipients
          ) {
            snackbar({
              type: 'warning',
              message: `Bulkutskick av ${type} är klart! ${failedBulkBatchItems.length}st misslyckades`,
            });
          } else if (failedBulkBatchItems.length === numberOfRecipients) {
            snackbar({
              type: 'error',
              message: `Bulkutskick av ${type} misslyckades! ${failedBulkBatchItems.length}st (alla) misslyckades`,
            });
          } else {
            snackbar({
              type: 'success',
              message: `Bulkutskick av ${type} är klart!`,
            });
          }
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Bulk error', error);
      snackbar({
        type: 'error',
        message: `Något gick fel. Ytterligare information finns i konsolen.`,
      });
    }
  }

  const showCard = () => {
    setOpen(true);
    const card = openCardRef.current!;
    const rootElement = document.getElementById('root')!;
    const { clientWidth: rootWidth, clientHeight: rootHeight } = rootElement;
    card.style.height = '600px';
    card.style.width = '800px';
    card.style.left = (rootWidth - 800) / 2 + 'px';
    card.style.bottom = (rootHeight - 500) / 2 + 'px';
  };

  const hideCard = () => {
    setOpen(false);
    const card = openCardRef.current!;
    card.style.height = '0';
    card.style.width = '0';
    card.style.left = '0';
    card.style.bottom = '0';
  };

  const handleTabChange = (_: any, newValue: string) => {
    setTab(newValue);
  };

  if (!userRoles) {
    return null;
  }

  return (
    <Can
      userRoles={userRoles}
      action="tools.sendOuts"
      yes={() => (
        <Wrapper>
          <SummaryCard elevation={13} open={open}>
            <SummaryCardHeader
              title="Utskickshanteraren"
              subheader={loading && <LinearProgress />}
              action={
                <>
                  <Tooltip title="Rensa och stäng" placement="top">
                    <IconButton
                      onClick={handleReset}
                      color="primary"
                      size="small"
                    >
                      <Close />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Förstora" placement="top">
                    <IconButton
                      onClick={showCard}
                      color="primary"
                      size="small"
                      data-testid="expandSendingsManagerBtn"
                    >
                      <AspectRatio />
                    </IconButton>
                  </Tooltip>
                </>
              }
            />
            {complete && (
              <>
                <Divider />
                <SummaryCardContent>
                  {failedItems ? (
                    <>
                      <Typography>Utskick {type} är klart!</Typography>
                      <Badge badgeContent={numberOfFails} color="error">
                        <ErrorOutline />
                      </Badge>
                    </>
                  ) : (
                    <>
                      <Typography>Utskick {type} är klart!</Typography>
                    </>
                  )}
                </SummaryCardContent>
              </>
            )}
          </SummaryCard>
          <OpenCard elevation={24} ref={openCardRef}>
            <CardHeader
              title="Utskick"
              subheader={loading ? 'Jobbar med utskick' : 'Inga aktiva utskick'}
              action={
                <div>
                  <Tooltip title="Minimera" placement="top">
                    <IconButton onClick={hideCard} size="large">
                      <Minimize />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Rensa och stäng" placement="top">
                    <IconButton
                      onClick={handleReset}
                      color="primary"
                      size="small"
                    >
                      <Close />
                    </IconButton>
                  </Tooltip>
                </div>
              }
            />
            <Divider />
            <AppBar position="static" color="transparent">
              <Tabs
                indicatorColor="primary"
                variant="fullWidth"
                value={tab}
                onChange={handleTabChange}
              >
                <Tab value="1" label="Sammanfattning" />
                <Tab
                  value="2"
                  label={`Misslyckade (${failedItems.length ?? 0})`}
                />
              </Tabs>
            </AppBar>
            <CardContent>
              <TabPanel value={tab} index="1">
                <SummaryTable />
              </TabPanel>
              <TabPanel value={tab} index="2">
                <FailedItemsTable />
              </TabPanel>
            </CardContent>
          </OpenCard>
        </Wrapper>
      )}
    />
  );
};
