import React from 'react';

import {
  Box,
  Button,
  Grid,
  LinearProgress,
  TextField,
  Typography,
} from '@mui/material';
import { add, format, parseISO } from 'date-fns';

import { getTypeKey } from '@/helpers';
import { usePrivateSettings } from '@/hooks/usePrivateSettings';
import { useSnackbar } from '@/hooks/useSnackbar';
import { KEY_TAG_AND_STICKER_CODE_VALIDATION_REGEX } from '@/modules/services/constants';
import { BasicCustomerData } from '@/types/customers';
import { ProtectionItem } from '@/types/services';

import { useCreateSticker } from '../../api-hooks/useCreateSticker';
import { useFetchProtections } from '../../api-hooks/useFetchProtections';
import { useGetStickerPricePlanIds } from '../../hooks/useGetStickerPricePlanIds';
import { StickerItem } from '../../types/dto';
import { getStickerConfigStep2ErrorMessage } from '../../utils/getStickerConfigStep2ErrorMessage';
import { StickersList } from './StickersList';

type Props = {
  onGoBack: () => void;
  onFinish: (createdStickers: StickerItem[]) => void;
  customer: BasicCustomerData;
};

type ReturnedProtectionItem = Required<
  Pick<ProtectionItem, 'type' | 'regDate' | 'campaign'>
>;

export const Step2NewCustomer = ({ onGoBack, onFinish, customer }: Props) => {
  const snackbar = useSnackbar();

  const fetchProtections = useFetchProtections();
  const createSticker = useCreateSticker();
  const { getPricePlanId } = useGetStickerPricePlanIds();

  const { servicesNames, stickerDoubleFamily, servicesTypes } =
    usePrivateSettings();

  const [errorMessage, setErrorMessage] = React.useState('');
  const [customerProtection, setCustomerProtection] =
    React.useState<ReturnedProtectionItem | null>(null);
  const [isFamilyPackage, setIsFamilyPackage] = React.useState(false);
  const [stickersCreated, setStickersCreated] = React.useState<StickerItem[]>(
    [],
  );

  const isSecondStickerStep = stickersCreated.length === 1 && isFamilyPackage;

  //#region Effects
  React.useEffect(() => {
    // If the user has isFamilyPackage and created two stickers, we can finish the process, otherwise only one sticker has been created
    if (
      (stickersCreated.length === 1 && !isFamilyPackage) ||
      (stickersCreated.length === 2 && isFamilyPackage)
    ) {
      onFinish(stickersCreated);
    }
  }, [isFamilyPackage, onFinish, stickersCreated]);

  React.useEffect(() => {
    if (customer?.id) {
      checkCustomerProtections(customer.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  React.useEffect(() => {
    if (customerProtection) {
      const familyType = parseInt(
        getTypeKey(servicesTypes, 'protection', 'Familj')!,
        10,
      );

      const isFamilyPackage =
        customerProtection.type === familyType &&
        customerProtection.campaign.indexOf('_upsale') < 0 &&
        stickerDoubleFamily;

      setIsFamilyPackage(isFamilyPackage);
    }
  }, [customerProtection, servicesTypes, stickerDoubleFamily]);
  //#endregion

  //#region Async functions
  async function checkCustomerProtections(customerId: string | number) {
    fetchProtections.mutate(
      { customerId },
      {
        onSuccess: (data) => {
          setCustomerProtection(data[0] ?? null);
        },
        onError: () => {
          // TODO: Add translations
          snackbar({ type: 'error', message: 'Något gick fel' });
        },
      },
    );
  }

  async function createStickerHandler(code: string) {
    // customerProtection is checked in the jsx
    const { regDate } = customerProtection!;
    const billingMonth = parseInt(
      format(add(parseISO(regDate), { months: 1 }), 'M'),
      10,
    );

    const payload = {
      code,
      billingMonth,
      status: 1,
      regDate,
      billing: 0,
      kamId: 0,
      tb: 0,
      pricePlanId:
        stickersCreated.length === 1 ? getPricePlanId(0) : getPricePlanId(1),
      customer: `/customers/${customer.id}`,
    };

    createSticker.mutate(payload, {
      onSuccess: (data) => {
        setStickersCreated((prevState) => [...prevState, data]);
      },
      onError: (error: any) => {
        const message = getStickerConfigStep2ErrorMessage(error);
        setErrorMessage(message);
      },
    });
  }
  //#endregion

  //#region Functions

  function handleSubmit(e: React.SyntheticEvent) {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      code: { value: string };
    };

    const value = target?.code?.value;

    if (value) {
      if (KEY_TAG_AND_STICKER_CODE_VALIDATION_REGEX.test(value)) {
        setErrorMessage('');
        createStickerHandler(value);
      } else {
        // TODO: Add translations
        setErrorMessage('Fel kodformat');
      }
    }
  }
  //#endregion

  const isLoading = createSticker.isPending || fetchProtections.isPending;

  if (isLoading) {
    return <LinearProgress />;
  }

  // TODO: Add translations
  return (
    <form onSubmit={handleSubmit} noValidate autoComplete="off">
      <Typography gutterBottom variant="h3">
        Steg 2:{' '}
        {isSecondStickerStep
          ? 'Skanna nästa stöldskyddsdekal!'
          : 'Skanna stöldskyddsdekal!'}
      </Typography>
      <StickersList stickers={stickersCreated} customer={customer} />

      <Grid container>
        {!customerProtection ? (
          <Typography color="error">
            Inget aktivt {servicesNames.protection}
          </Typography>
        ) : (
          <>
            <TextField
              autoFocus
              label={'Serienummer'}
              name="code"
              size="small"
              variant="outlined"
              error={!!errorMessage}
              helperText={errorMessage}
            />
          </>
        )}
      </Grid>

      <Box display="flex" justifyContent="space-between" marginTop={2}>
        <Button
          type="button"
          color="primary"
          size="small"
          variant="contained"
          onClick={onGoBack}
        >
          Gå tillbaka
        </Button>
        <Button
          type="submit"
          color="primary"
          size="small"
          variant="contained"
          disabled={isLoading}
        >
          Nästa
        </Button>
      </Box>
    </form>
  );
};
