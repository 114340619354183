import React from 'react';

import { useQuery } from '@tanstack/react-query';

import { fetchUsers } from '@/api';
import { ApiCollectionResponse } from '@/types/common';

import { AccountManagerUser } from '../types';

export const useFetchAccountManagers = () => {
  const [page, setPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(100);
  const { data, status } = useQuery({
    queryKey: ['getUsers', page, perPage],

    queryFn: async () => {
      const response = await fetchUsers<
        ApiCollectionResponse<AccountManagerUser>
      >({
        page: page + 1,
        perPage,
        computer: false,
        properties: {
          roles: true,
          managedCustomers: { id: true, activeServiceTypes: true },
        },
        onboardingPercentages: true,
      });
      const managers = response['hydra:member'].filter((user) =>
        user.roles.some((role) => role === 'ROLE_ACCOUNT_MANAGER'),
      );

      return managers;
    },

    initialData: [],
  });

  return { data, status, page, perPage, setPage, setPerPage };
};
