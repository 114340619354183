import React from 'react';

import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import {
  differenceInMonths,
  endOfMonth,
  isAfter,
  isBefore,
  isEqual,
  startOfMonth,
  subMonths,
} from 'date-fns';

import { BasicAlert } from '@/components/BasicAlert';
import { CamelCaseServiceName } from '@/types/services';

import {
  ExportStatsParams,
  Resolution,
  useExportBdmStats,
} from '../hooks/useExportBdmStats';
import { useGetServiceOptions } from '../hooks/useGetServiceOptions';
import { GetStatsParams } from '../hooks/useGetStats';

type SetFetchParams = React.Dispatch<
  React.SetStateAction<ExportStatsParams | GetStatsParams>
>;

type Props = {
  setFetchParams: SetFetchParams;
  fetchParams: GetStatsParams;
};

const PERIOD_OPTIONS = [
  { id: 1, value: 'thisMonth', label: 'Den här månaden' },
  { id: 2, value: 'lastMonth', label: 'Förra månaden' },
  { id: 3, value: 'custom', label: 'Anpassad' },
];

export const ToolBar = ({ setFetchParams, fetchParams }: Props) => {
  const initialRender = React.useRef(true);
  const [period, setPeriod] = React.useState('thisMonth');

  React.useEffect(() => {
    if (!initialRender.current) {
      if (period === 'thisMonth') {
        setFetchParams((prevState: GetStatsParams) => ({
          ...prevState,
          start: startOfMonth(new Date()),
          end: endOfMonth(new Date()),
        }));
      }
      if (period === 'lastMonth') {
        setFetchParams((prevState) => ({
          ...prevState,
          start: startOfMonth(subMonths(new Date(), 1)),
          end: endOfMonth(subMonths(new Date(), 1)),
        }));
      }
    }
    initialRender.current = false;
  }, [period, initialRender]);

  const exportMutation = useExportBdmStats();

  const calculateResolution = (start: Date, end: Date) => {
    const monthsDiff = differenceInMonths(end, start);

    let resolution: Resolution;

    if (monthsDiff <= 3) {
      resolution = 'daily';
    } else if (monthsDiff > 3 && monthsDiff <= 12) {
      resolution = 'weekly';
    } else if (monthsDiff > 12 && monthsDiff <= 36) {
      resolution = 'monthly';
    } else if (monthsDiff > 36 && monthsDiff <= 60) {
      resolution = 'quarter';
    } else {
      resolution = 'yearly';
    }

    return resolution;
  };

  function handlePeriodChange(event: SelectChangeEvent<string>) {
    setPeriod(event.target.value);
  }

  function handleStartDateChange(date: Date | null) {
    if (date === null) {
      return;
    }
    const start = new Date(date);
    const end = new Date(fetchParams.end);
    if (isBefore(start, end) || isEqual(start, end)) {
      const resolution = calculateResolution(start, end);
      if (resolution) {
        setFetchParams((prevState) => ({
          ...prevState,
          start: date,
          resolution,
        }));
        setPeriod('custom');
      }
    }
  }

  function handleEndDateChange(date: Date | null) {
    if (date === null) {
      return;
    }
    const start = new Date(fetchParams.start);
    const end = new Date(date);
    if (isAfter(end, start) || isEqual(end, start)) {
      const resolution = calculateResolution(start, end);
      if (resolution) {
        setFetchParams((prevState) => ({
          ...prevState,
          end: date,
          resolution,
        }));
        setPeriod('custom');
      }
    }
  }

  return (
    <>
      <Box display="flex" justifyContent="flex-end" p={2}>
        <Button
          variant="contained"
          onClick={() =>
            exportMutation.mutate(fetchParams as ExportStatsParams)
          }
        >
          Exportera
        </Button>
      </Box>
      <Box component={Card} sx={{ mb: 2 }}>
        <Grid container spacing={2} component={CardContent}>
          <Grid item xs={3}>
            <DatePicker
              label="Från"
              onChange={(date) => handleStartDateChange(date!)}
              value={fetchParams.start}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </Grid>
          <Grid item xs={3}>
            <DatePicker
              label="Till"
              onChange={(date) => handleEndDateChange(date)}
              value={fetchParams.end}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel>Period</InputLabel>
              <Select
                value={period}
                label="Period"
                onChange={handlePeriodChange}
              >
                {PERIOD_OPTIONS.map((item) => (
                  <MenuItem key={item.id} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <ServiceOptionsDropdown
              fetchParams={fetchParams}
              setFetchParams={setFetchParams}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const ServiceOptionsDropdown = ({ fetchParams, setFetchParams }: Props) => {
  const { status, options } = useGetServiceOptions(fetchParams.reseller);
  const isCurrentOptionValid = options.some(
    (option) => fetchParams.serviceName === option.value,
  );

  React.useEffect(() => {
    if (status === 'success' && options.length > 0 && !isCurrentOptionValid) {
      setFetchParams((prevState) => ({
        ...prevState,
        serviceName: options[0]!.value,
      }));
    }
  }, [status, options, isCurrentOptionValid]);

  const renderContent = () => {
    switch (status) {
      case 'loading':
        return <Skeleton variant="text" sx={{ minWidth: '100%' }} />;
      case 'error':
        return <BasicAlert />;
      case 'success':
        return (
          <>
            <InputLabel>Tjänst</InputLabel>
            <Select
              value={fetchParams.serviceName}
              label="Tjänst"
              onChange={(e: SelectChangeEvent) => {
                setFetchParams((prevState) => ({
                  ...prevState,
                  serviceName: e.target.value as CamelCaseServiceName, // MUI SelectChangeEvent generic typing does not work - https://github.com/mui/material-ui/issues/33399
                }));
              }}
            >
              {isCurrentOptionValid && !!options.length ? (
                options.map((item) => (
                  <MenuItem key={item.id} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">Inga tjänster</MenuItem>
              )}
            </Select>
          </>
        );
      default:
        return null;
    }
  };

  return <FormControl fullWidth>{renderContent()}</FormControl>;
};
