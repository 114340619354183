import React from 'react';

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { Form } from 'react-final-form';

import { createBulkNotes } from '@/api/notes';
import { InfoMessage } from '@/components/InfoMessage';
import { Spacing } from '@/components/Spacing';
import { Checkboxes, Dropdown, TextInput } from '@/components/form';
import { usePrivateSettings, useSnackbar } from '@/hooks';
import { fieldRequired } from '@/utils/validation';

type Values = {
  agreement: boolean;
  customerIds: string;
  type: string;
  message: string;
};

export const Notes = () => {
  const snackbar = useSnackbar();
  const { getTypesOptionsForDropdownInput } = usePrivateSettings();

  const noteMutation = useMutation({
    mutationFn: createBulkNotes,
    onSuccess: () => {
      snackbar({
        type: 'success',
        message: 'Noteringar skickade',
      });
    },
    onError: () => {
      snackbar({
        type: 'error',
        message: 'Misslyckades skicka noteringar',
      });
    },
  });

  const onSubmit = async (values: Values) => {
    const { type, message } = values;
    const customerIds = values.customerIds
      .split('\n')
      .reduce((acc: string[], curr) => {
        if (curr) {
          acc.push(curr.trim());
        }
        return acc;
      }, []);

    return noteMutation.mutateAsync({ customerIds, type, message });
  };
  return (
    <Form
      onSubmit={async (values: Values, form) => {
        await onSubmit(values);
        form.restart();
      }}
      render={({ handleSubmit, pristine }) => (
        <Card component="form" onSubmit={handleSubmit}>
          <CardHeader title="Skapa noteringar" />
          <Divider />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Dropdown
                  label="Typ"
                  name="type"
                  options={getTypesOptionsForDropdownInput('note')}
                  fieldProps={{
                    validate: fieldRequired,
                  }}
                />
              </Grid>
              <Grid item xs={6}></Grid>

              <Grid item xs={6}>
                <TextInput
                  multiline
                  label="Mottagare"
                  placeholder="Klistra in mottagare"
                  name="customerIds"
                  rows={12}
                  fieldProps={{
                    validate: fieldRequired,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  multiline
                  label="Meddelande"
                  placeholder="Skriv ditt meddelande här..."
                  name="message"
                  rows={12}
                  fieldProps={{
                    validate: fieldRequired,
                  }}
                />
              </Grid>
            </Grid>
            <Spacing />
            <InfoMessage>Ange ett kundnummer per rad</InfoMessage>
          </CardContent>
          <Divider />
          <CardContent>
            <Checkboxes
              name="agreement"
              data={{
                label: 'Bekräfta noteringar',
              }}
              fieldProps={{
                validate: fieldRequired,
              }}
              inputProps={{
                // @ts-expect-error data-testid is not available in the type definition
                'data-testid': 'confirm',
              }}
              options={{ label: 'Bekräfta noteringar', value: true }}
            />
          </CardContent>
          <Divider />
          <Spacing />
          <CardActions>
            <Button
              disabled={pristine || noteMutation.isPending}
              color="primary"
              variant="outlined"
              type="submit"
              onClick={handleSubmit}
            >
              Skicka meddelande
            </Button>
          </CardActions>
        </Card>
      )}
    />
  );
};
