import { Button } from '@mui/material';
import pick from 'lodash/pick';
import { Form } from 'react-final-form';
import { z } from 'zod';

import { StickersParamSchema } from '@/types/services';
import { stickerStatusKey } from '@/types/settings';

import { mapInitialObjectValuesToStrings } from '../../../utils/mapInitialObjectValuesToStrings';
import { SalesInformationForm } from './SalesInformationForm';
import { ServiceInfoForm } from './ServiceInfoForm';

const FIELDS_TO_PICK = [
  'status',
  'churnDate',
  'billing',
  'billingMonth',
  'pricePlanId',
  'adminNote',
  'kamId',
  'tb',
];

const formValuesSchema = z.object({
  status: stickerStatusKey,
  churnDate: z.string().nullable(),
  billing: z.number().min(0).max(1), // TODO: this is returned as 1 or 0 from BE, but should be boolean
  billingMonth: z.number(),
  pricePlanId: z.number(),
  adminNote: z.string().nullable(),
  kamId: z.number(),
  tb: z.number(),
});

type FormValues = z.infer<typeof formValuesSchema>;

export type StickerEditProps = {
  serviceDetails: StickersParamSchema;
  isLoading: boolean;
  onSubmit: (v: FormValues) => void;
};

export const StickerEdit = ({
  serviceDetails,
  isLoading,
  onSubmit,
}: StickerEditProps) => {
  const serviceName = 'sticker';

  const initialValues = mapInitialObjectValuesToStrings(
    pick(serviceDetails, FIELDS_TO_PICK),
  );

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <ServiceInfoForm
            serviceDetails={serviceDetails}
            updateInProgress={isLoading}
          />

          <SalesInformationForm
            serviceDetails={serviceDetails}
            serviceName={serviceName}
            updateInProgress={isLoading}
          />

          <section>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={isLoading}
            >
              Uppdatera
            </Button>
          </section>
        </form>
      )}
    />
  );
};
