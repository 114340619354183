import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';

import { getCamelCaseServiceName } from '@/helpers/tsHelpers';
import { usePrivateSettings } from '@/hooks';
import { CamelCaseServiceName } from '@/types/services';
import {
  SupportRetetionStatistics,
  SupportRetetionStatisticsHandler,
} from '@/types/stats';

import { getHandlerMap } from '../utils/getHandlerMap';
import { getTotalLost } from '../utils/getTotalLost';
import { getTotalSaved } from '../utils/getTotalSaved';
import { getTotalSuccessRate } from '../utils/getTotalSuccessRate';

type Props = {
  data: SupportRetetionStatistics;
};

export type HandlerData = {
  [K in CamelCaseServiceName]: {
    lostCount: number;
    savedCount: number;
    successRate: string;
  };
} & { name: string };

export const ManagerTable: React.FC<Props> = ({ data }) => {
  const handlerMap = getHandlerMap(data);
  const handlerList = Object.values(handlerMap) as HandlerData[];

  return (
    <Card>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Namn</TableCell>
            <TableCell>Räddad</TableCell>
            <TableCell>Hitrate</TableCell>
            <TableCell>Förlorad</TableCell>
            <TableCell>Totalt</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {handlerList.map((handlerData, index) => {
            return <HandlerDetails key={index} handlerData={handlerData} />;
          })}
        </TableBody>
      </Table>
    </Card>
  );
};

type HandlerDetailsProps = {
  handlerData: HandlerData;
};

const HandlerDetails: React.FC<HandlerDetailsProps> = ({ handlerData }) => {
  const theme = useTheme();
  const { servicesNames } = usePrivateSettings();

  const totalSaved = getTotalSaved(handlerData);
  const totalLost = getTotalLost(handlerData);

  const totalSuccessRate = getTotalSuccessRate({ totalSaved, totalLost });

  return (
    <>
      <TableRow sx={{ backgroundColor: theme.palette.action.hover }}>
        <TableCell sx={{ fontSize: theme.typography.h6 }}>
          {handlerData.name}
        </TableCell>
        <TableCell>{totalSaved}</TableCell>
        <TableCell>{totalSuccessRate}</TableCell>
        <TableCell>{totalLost}</TableCell>
        <TableCell>{totalLost + totalSaved}</TableCell>
      </TableRow>
      {Object.entries(handlerData).map(([serviceName, serviceData]) => {
        if (
          serviceName !== 'name' &&
          serviceName !== 'totalSaved' &&
          serviceName !== 'totalLost' &&
          serviceName !== 'totalSuccessRate'
        ) {
          const data =
            serviceData as unknown as SupportRetetionStatisticsHandler;
          return (
            <TableRow key={serviceName}>
              <TableCell>
                <Typography sx={{ ml: 2 }}>
                  - {servicesNames[getCamelCaseServiceName(serviceName)]}
                </Typography>
              </TableCell>
              <TableCell>{data.savedCount}</TableCell>
              <TableCell>{data.successRate}</TableCell>
              <TableCell>{data.lostCount}</TableCell>
              <TableCell>{data.lostCount + data.savedCount}</TableCell>
            </TableRow>
          );
        }
        return null;
      })}
    </>
  );
};
