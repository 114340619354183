import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { Skeleton } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';

import { getAllCampaigns, getHomegateCampaigns } from '@/api/campaigns';
import { getPrices } from '@/api/prices';
import { getResellers } from '@/api/reseller';
import { Alert } from '@/components/Alert';
import { DatePicker, Dropdown, TextInput } from '@/components/form';
import { CustomerCategoryTypes } from '@/constants';
import { USER_TYPES } from '@/modules/common/constants/entitiyNamesMap';
import {
  prepareCampaignOptions,
  prepareFeesOptions,
  prepareResellerOptions,
} from '@/modules/services';
import { useGetPriceOptions } from '@/modules/services/hooks/useGetPriceOptions';
import { BasicCustomerData, basicCustomerDataSchema } from '@/types/customers';
import { BasicPriceItem } from '@/types/prices';
import { ServiceName } from '@/types/services';
import { fieldRequired } from '@/utils/validation';

import { queryKeys } from '../../../../../constants/queryKeys';

type Props = {
  customer: BasicCustomerData;
  price: BasicPriceItem;
  campaign: string;
};

export const SalesInformationFields = ({
  customer: rawCustomer,
  price,
  campaign,
}: Props) => {
  const customer = basicCustomerDataSchema.parse(rawCustomer);

  const { serviceName } = useParams<{ serviceName: ServiceName }>();

  invariant(serviceName, 'Service name is required in url');
  const {
    priceOptions,
    isLoading: isLoadingPriceOptions,
    isSuccess: priceOptionsSuccess,
    isError: priceOptionsError,
  } = useGetPriceOptions({ serviceName, active: true });

  const {
    data: campaignOptions,
    isLoading: isLoadingCampaigns,
    isSuccess: campaignsSuccess,
    isError: campaignsError,
  } = useQuery({
    queryKey: [queryKeys.getHomegateCampaigns, customer.category],

    queryFn: async () => {
      invariant(customer.category, 'Customer category is required');
      const data = await getHomegateCampaigns(customer.category);
      return prepareCampaignOptions(data);
    },

    initialData: [],
  });
  const {
    data: resellers,
    isLoading: isLoadingResellers,
    isSuccess: resellerSuccess,
    isError: resellerError,
  } = useQuery({
    queryKey: [queryKeys.getResellers],
    queryFn: () => getResellers(),
    initialData: { data: [], totalCount: 0 },
  });
  const {
    data: prices,
    isLoading: isLoadingPrices,
    isSuccess: pricesSuccess,
    isError: priceError,
  } = useQuery({
    queryKey: [queryKeys.getPrices],
    queryFn: getPrices,
    initialData: [],
  });

  const {
    data: campaignData,
    isPending: isLoadingCampaign,
    isSuccess: campaignDataSuccess,
    isError: campaignDataError,
  } = useQuery({
    queryKey: [queryKeys.getAllCampaigns, campaign],

    queryFn: async () => {
      const data = await getAllCampaigns(campaign, {
        freeMonths: true,
        noticePeriod: true,
        package: true,
        units: true,
        active: true,
        customerType: true,
      });

      return data;
    },

    enabled: !!campaign,
  });

  const isError =
    campaignsError ||
    resellerError ||
    priceError ||
    campaignDataError ||
    priceOptionsError;

  const isSuccess =
    resellerSuccess &&
    pricesSuccess &&
    campaignDataSuccess &&
    campaignsSuccess &&
    priceOptionsSuccess;

  const isLoading =
    isLoadingCampaigns ||
    isLoadingCampaign ||
    isLoadingPrices ||
    isLoadingResellers ||
    isLoadingPriceOptions;

  if (isError) {
    return (
      <Alert severity="error">
        <Typography variant="h3" color="error">
          Något gick fel. Kontrollera din internetanslutning och försök igen
        </Typography>
      </Alert>
    );
  }

  return (
    <Card style={{ marginTop: '2rem' }}>
      <CardHeader title="Sälj" />
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5">
              Månadsavgift:{' '}
              {customer.category === CustomerCategoryTypes.COMPANY
                ? `${price.priceInCurrency} ${price.currency} exkl moms`
                : `${price.priceWithVatInCurrency} ${price.currency} inkl moms`}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker label="Datum" name="regDate" />
            <TextInput
              label="Säljare"
              name="salesRep"
              fieldProps={{ validate: fieldRequired }}
            />
            {isLoading ? (
              <Skeleton height={54} />
            ) : (
              isSuccess && (
                <Dropdown
                  label="Återförsäljare"
                  name="reseller"
                  options={prepareResellerOptions(resellers.data)}
                  required
                />
              )
            )}
            <TextInput label="Adresslista" name="addressList" />

            <TextInput label="Täckningsbidrag" name="tb" />

            <TextInput label="Leadkälla" name="leadSource" />
            <TextInput label="Key Account Manager" name="kamId" />

            <TextInput label="AB Test" name="abTest" />
          </Grid>

          <Grid item xs={12} sm={6}>
            {isLoading ? (
              <>
                <Skeleton height={54} />
                <Skeleton height={54} />
                <Skeleton height={54} />
                <Skeleton height={54} />
                <Skeleton height={54} />
                <Skeleton height={54} />
                <Skeleton height={54} />
                <Skeleton height={54} />
              </>
            ) : (
              isSuccess && (
                <>
                  <Dropdown
                    label="Pris"
                    name="price"
                    fieldProps={{ initialValue: price['@id'] }}
                    options={priceOptions}
                  />
                  <Dropdown
                    label="Kampanj"
                    name="campaign"
                    fieldProps={{ initialValue: campaign }}
                    options={campaignOptions}
                  />
                  <TextField
                    disabled
                    value={campaignData['freeMonths']}
                    label="Fria månader"
                    fullWidth
                  />
                  <TextField
                    disabled
                    value={campaignData['noticePeriod']}
                    label="Uppsägningstid (månader)"
                    fullWidth
                  />
                  <TextField
                    value={campaignData['contractMonths']}
                    disabled
                    label="Bindningstid (månader)"
                    fullWidth
                  />
                  <Dropdown
                    label="Startavgift"
                    name="startFee"
                    options={prepareFeesOptions(prices, customer.category)}
                  />
                  <Dropdown
                    label="Installationsavgift"
                    name="installationFee"
                    options={prepareFeesOptions(prices, customer.category)}
                  />
                  <Dropdown
                    label="Hårdvarutillägg"
                    name="hardwareSurcharge"
                    options={prepareFeesOptions(prices, customer.category)}
                  />
                </>
              )
            )}

            <TextInput
              label={`Info till ${USER_TYPES.admin}`}
              name="adminNote"
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
