import { useQuery } from '@tanstack/react-query';

import { getPrices } from '@/api/prices';
import { queryKeys } from '@/constants/queryKeys';
import { ServiceName } from '@/types/services';

type Args = {
  active: boolean;
  serviceName: ServiceName;
};
export const useGetPrices = ({ active = true, serviceName: service }: Args) => {
  return useQuery({
    queryKey: [queryKeys.getPrices, active, service],
    queryFn: () => getPrices({ active, service }),
  });
};
