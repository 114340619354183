import { useState } from 'react';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { IconButton } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { format, parseISO } from 'date-fns';

import { getHandleableAlarms } from '@/api/alarms';
import { BasicAlert } from '@/components/BasicAlert';
import { getFormattedDate } from '@/helpers/dates';
import { usePrivateSettings } from '@/hooks';
import { HandleableAlarm } from '@/types/alarms';

import { AlarmsTable } from './AlarmsTable';

export const HandledAlarmsView = () => {
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(500);

  const { servicesNames, servicesStatuses } = usePrivateSettings();

  const inactiveAlarmsColumns: Array<{
    label: string;
    renderer: (row: HandleableAlarm) => React.ReactNode;
  }> = [
    {
      label: 'Enhetstyp',
      renderer: (row) =>
        row.sinne ? servicesNames.sinne : servicesNames.firetext,
    },
    {
      label: 'Status',
      renderer: (row) => servicesStatuses.handleableAlarm.statuses[row.status],
    },
    {
      label: 'Inkommen',
      renderer: (row) => getFormattedDate(row.occuredAt, 'yyyy-MM-dd HH:mm:ss'),
    },
    {
      label: 'Hantera',
      renderer: () => (
        <IconButton size="large">
          <ArrowForwardIcon />
        </IconButton>
      ),
    },
  ];

  const { data, isLoading, isError } = useQuery({
    queryKey: ['alarms', 'inactive', page, perPage],

    queryFn: async () =>
      getHandleableAlarms({
        pagination: true,
        page: page + 1,
        perPage,
        status: 3,
        order: { occuredAt: 'desc' },
      }),
  });

  return (
    <>
      {isError ? (
        <BasicAlert />
      ) : (
        <AlarmsTable
          page={page}
          rowsPerPage={perPage}
          onPageChange={(newPage) => setPage(newPage)}
          onRowsPerPageChange={(rows) => setPerPage(rows)}
          columns={inactiveAlarmsColumns}
          rows={data?.items || []}
          total={data?.count ?? 0}
          loading={isLoading}
        />
      )}
    </>
  );
};
