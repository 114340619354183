export const AutogiroSourceTypes = {
  DOES_NOT_EXIST: 0,
  REPLY_ENVELOPE: 1,
  AT_FIRST_SALE: 2,
  SUPPORT: 3,
  SVEA: 4,
  ANSWER_FROM_INVOICE: 5,
};

export function autogiroSourceTypeToString(autogiroSourceType: number) {
  switch (autogiroSourceType) {
    case AutogiroSourceTypes.DOES_NOT_EXIST:
      return 'Finns ej';

    case AutogiroSourceTypes.REPLY_ENVELOPE:
      return 'Svarskuvert';

    case AutogiroSourceTypes.AT_FIRST_SALE:
      return 'Vid införsäljning';

    case AutogiroSourceTypes.SUPPORT:
      return 'Kundservice';

    case AutogiroSourceTypes.SVEA:
      return 'Svea';

    case AutogiroSourceTypes.ANSWER_FROM_INVOICE:
      return 'Svarsblankett Faktura';

    default:
      return autogiroSourceType;
  }
}

// Supposed to be used with Dropdown fields
export const autogiroSourceTypeOptions = Object.values(AutogiroSourceTypes)
  .map((value) => ({
    value,
    text: autogiroSourceTypeToString(value),
  }))
  .sort((a, b) => {
    if (a.text < b.text) {
      return -1;
    }
    if (a.text > b.text) {
      return 1;
    }
    return 0;
  });
