import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  InputAdornment,
  LinearProgress,
  Typography,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { z } from 'zod';

import { sendPasswordRecoveryLink } from '@/api/forgotPassword';
import { UserIcon } from '@/icons';
import { Textfield } from '@/modules/components/TextField';

import { Box } from './Box';

type Values = {
  username: string;
};

const validationSchema = z.object({
  username: z.string().min(1, 'Fältet får inte vara tomt'),
});

export const ForgotPasswordForm = () => {
  const {
    handleSubmit,
    formState: { errors, isValid },
    register,
    setError,
  } = useForm({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      username: '',
    },
  });
  const recoverLinkMutation = useMutation({
    mutationFn: async ({ username }: Values) =>
      sendPasswordRecoveryLink(username),
    onError: (e) => {
      console.log('ERROR', e);
      setError(
        'username',
        { message: 'Ogiltiga uppgifter' },
        { shouldFocus: true },
      );
    },
  });

  const onSubmit = ({ username }: Values) => {
    recoverLinkMutation.mutate({ username });
  };

  return (
    <Box>
      {recoverLinkMutation.isSuccess && (
        <>
          <Typography variant="h3" component="h1">
            E-post om nollställning av lösenord har skickats!
          </Typography>
          <Typography variant="body1">
            Följ anvisningarna i e-postmeddelandet för att återställa ditt
            lösenord.
          </Typography>
        </>
      )}
      {!recoverLinkMutation.isSuccess && (
        <form onSubmit={handleSubmit((values) => onSubmit(values))}>
          <Typography variant="h3" component="h1">
            Ange användarnamn
          </Typography>
          <Textfield
            label="Användarnamn"
            error={errors.username}
            fullWidth
            inputProps={{
              ...register('username'),
              startAdornment: (
                <InputAdornment position="start">
                  <UserIcon />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            type="submit"
            sx={{ marginBottom: 1 }}
            disabled={!isValid || recoverLinkMutation.isPending}
          >
            Skicka återställningslänk
          </Button>
        </form>
      )}
      {recoverLinkMutation.isPending && <LinearProgress />}
      <Link to="/sign-in">Tillbaka</Link>
    </Box>
  );
};
