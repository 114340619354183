import React from 'react';

import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  LinearProgress,
} from '@mui/material';

import { BasicAlert } from '@/components/BasicAlert/BasicAlert';
import { SimpleTable, SimpleTableColumn } from '@/components/SimpleTable';

type Props<Row> = {
  title?: string;
  subheader?: string;
  rows?: Row[];
  columns: SimpleTableColumn[];
  loading: boolean;
  error: boolean;
  children?: React.ReactNode;
};

export const ListWithLoading = <Row extends {}>({
  title,
  subheader,
  rows,
  loading,
  error,
  columns,
  children,
}: Props<Row>) => {
  return loading ? (
    <LinearProgress />
  ) : error ? (
    <BasicAlert />
  ) : rows ? (
    <Card>
      <CardHeader title={title} subheader={subheader} />
      <Divider />
      <CardContent>
        {children}
        <SimpleTable columns={columns} rows={rows} />
      </CardContent>
    </Card>
  ) : null;
};
