import {
  Control,
  Controller,
  FieldValues,
  Path,
  PathValue,
} from 'react-hook-form';

import { Textfield, TextfieldProps } from '@/modules/components/TextField';

import { formatDisplayValue } from './formatDisplayValue';

type FloatInputProps<FormValues extends FieldValues> = {
  control: Control<FormValues>;
  name: Path<FormValues>;
  label: string;
  fullWidth?: boolean;
  inputProps?: TextfieldProps['inputProps'];
};

export const FloatInput = <FormValues extends FieldValues>({
  control,
  name,
  ...rest
}: FloatInputProps<FormValues>) => {
  const transformValue = (value: string): string => {
    // Replace comma with dot for internal processing
    return value.replace(',', '.');
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <Textfield
          type="text"
          {...field}
          label={rest.label}
          error={fieldState.error}
          fullWidth={rest.fullWidth}
          value={formatDisplayValue(field.value)}
          onChange={(e) => {
            const transformedValue = transformValue(
              e.target.value,
            ) as PathValue<FormValues, Path<FormValues>>;
            field.onChange(transformedValue);
          }}
          inputProps={{
            'data-testid': 'yourTestId',
            ...rest,
          }}
        />
      )}
    />
  );
};
