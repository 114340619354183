import React from 'react';

import { FormSpy } from 'react-final-form';

import { getObjectKeys } from '@/helpers/tsHelpers';

type Props<T> = {
  debounce: number;
  debounced: string[];
  save: (values: T) => void;
  values: T;
};

const Logic = <Values extends object>(props: Props<Values>) => {
  const [oldValues, setOldValues] = React.useState(props.values);
  const [savedTimeout, setSavedTimeout] = React.useState<NodeJS.Timeout>();

  const handleSave = async (values: Values) => {
    const { save } = props;

    const changedValues = getObjectKeys(values as object).reduce(
      (result, key) => {
        if (oldValues[key] !== values[key]) {
          result[key] = values[key];
        }
        return result;
      },
      {} as Values,
    );

    if (getObjectKeys(changedValues as object).length) {
      setOldValues({ ...values, ...changedValues });
      await save(changedValues);
    }
  };

  React.useEffect(() => {
    const { values, debounce, debounced } = props;
    const debouncedValues: Values = {} as Values;

    const immediateValues: Values = {} as Values;

    if (values !== oldValues) {
      getObjectKeys(values as object).forEach((key) => {
        if (~debounced.indexOf(key)) {
          debouncedValues[key] = values[key];
        } else {
          immediateValues[key] = values[key];
        }
      });

      if (getObjectKeys(immediateValues).length) {
        handleSave(immediateValues);
      }
      if (getObjectKeys(debouncedValues).length) {
        if (savedTimeout) {
          clearTimeout(savedTimeout);
        }
        const timeout = setTimeout(() => {
          handleSave(debouncedValues);
        }, debounce);
        setSavedTimeout(timeout);
      }
    }
  }, [props.values]);

  return null;
};

export const AutoSave = <T extends object>(props: Omit<Props<T>, 'values'>) => (
  <FormSpy<T> {...props} subscription={{ values: true }}>
    {({ values }) => <Logic<T> {...props} values={values} />}
  </FormSpy>
);
