import React from 'react';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Divider, IconButton, Drawer as MuiDrawer } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useWindowSize } from '@/hooks/useWindowHeight';

const rightSidebarWidth = 400;
const smallRightSidebarWidth = 56;
const topBarHeight = 64;

type Props = {
  children: React.ReactNode;
  open: boolean;
  setOpen: () => void;
};

export const Drawer = ({ children, open, setOpen }: Props) => {
  const { height } = useWindowSize();

  return (
    <StyledMuiDrawer
      anchor="right"
      variant="permanent"
      height={height}
      isOpened={open}
    >
      <div>
        <IconButton onClick={setOpen} size="large">
          {open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </div>
      <Divider />
      <DrawerContent>{open && children}</DrawerContent>
    </StyledMuiDrawer>
  );
};

const DrawerContent = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2, 0, 2),
}));

type StyledMuiDrawerProps = {
  height: number | undefined;
  isOpened: boolean;
};

const StyledMuiDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'height' && prop !== 'isOpened',
})<StyledMuiDrawerProps>(({ theme, height, isOpened }) => {
  const openedStyle = {
    marginTop: '64px',
    width: rightSidebarWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overscrollBehavior: 'contain',
    height: height ? height - topBarHeight : 0,
    marginRight: '0.1rem',
  };

  const closedStyle = {
    paddingTop: '64px',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: smallRightSidebarWidth,
  };

  return {
    '& .MuiDrawer-paper': isOpened ? openedStyle : closedStyle,
  };
});
