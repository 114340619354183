import React from 'react';

import { Alert, Grid, LinearProgress, Skeleton } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import isEqual from 'lodash/isEqual';
import { useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import { getCampaign, getCampaigns } from '@/api/campaigns';
import { getPrices } from '@/api/prices';
import { getResellers } from '@/api/reseller';
import { SimpleCard } from '@/components/SimpleCard';
import {
  DatePicker,
  Dropdown,
  PartnerManagerAutocomplete,
  TextInput,
} from '@/components/form';
import { CustomerCategoryTypes } from '@/constants';
import { queryKeys } from '@/constants/queryKeys';
import { useGetAccountManagersOptions } from '@/hooks/useGetAccountManagersOptions';
import { USER_TYPES } from '@/modules/common/constants/entitiyNamesMap';
import {
  prepareCampaignOptions,
  prepareFeesOptions,
  preparePricesOptions,
  prepareResellerOptions,
} from '@/modules/services';
import { ContactListSection } from '@/modules/services/components/sections/SalesInformationSection/Sinne/ContactListSection';
import { SinneTemplate } from '@/types/campaigns';
import { CreateSinneExcelValues } from '@/types/services';
import { isValidOption } from '@/utils/validation';

import { CampaignSection } from '../../CampaignSection';

type CampaignRelatedValues = {
  contractMonths: number;
  freeMonths: number;
  noticePeriod: number;
  withdrawalPeriodDays: number;
};
type Props = {
  values: Partial<
    CreateSinneExcelValues &
      CampaignRelatedValues & { templates: SinneTemplate[] }
  >;
  push: (name: string, value: SinneTemplate) => void;
  existingCustomerCategory: number | undefined;
};

export const SalesInformationFields = ({
  values,
  push,
  existingCustomerCategory,
}: Props) => {
  const service = 'sinne';
  const customerType = existingCustomerCategory ?? values?.category;

  const form = useForm();

  const { data: campaigns } = useQuery({
    queryKey: [queryKeys.getCampaigns, customerType, service],
    queryFn: async () => {
      const data = await getCampaigns(`${service}_campaigns`, {
        active: true,
        pagination: false,
        customerType,
        properties: {
          name: true,
          id: true,
          active: true,
        },
      });
      return { options: prepareCampaignOptions(data), data };
    },

    initialData: { options: [], data: [] },
    enabled: !!customerType,
  });

  const { data: resellers, isPending: isLoadingResellers } = useQuery({
    queryKey: [queryKeys.getResellers],
    queryFn: () => getResellers(),
    initialData: { data: [], totalCount: 0 },
  });
  const { data: prices } = useQuery({
    queryKey: [queryKeys.getPrices, service],
    queryFn: () => getPrices({ service }),
    initialData: [],
  });

  const {
    data: accountManagers,
    isLoading: loadingAccountManagers,
    isError: errorAccountManagers,
  } = useGetAccountManagersOptions();

  const { data: campaignData, isLoading: isLoadingCampaignData } = useQuery({
    queryKey: [queryKeys.getCampaign, values.campaign],
    queryFn: async () => getCampaign(values.campaign!),

    enabled: !!campaigns?.data?.find(
      (campaign) =>
        campaign['@id'] === values.campaign && values.campaign !== undefined,
    ),
  });
  React.useEffect(() => {
    form.batch(() => {
      form.change('freeMonths', campaignData?.freeMonths);
      form.change('noticePeriod', campaignData?.noticePeriod);
      form.change('contractMonths', campaignData?.contractMonths);
      form.change('withdrawalPeriodDays', campaignData?.withdrawalPeriodDays);
    });
  }, [isLoadingCampaignData, campaignData]);

  React.useEffect(() => {
    if (campaignData && !isEqual(values.templates, campaignData?.templates)) {
      form.change('templates', []);
      campaignData?.templates.forEach((template) =>
        push('templates', template),
      );
    } else {
      form.change('templates', []);
    }
  }, [campaignData?.templates]);

  return (
    <>
      <section style={{ marginBottom: '1.5rem' }}>
        <SimpleCard
          title="Sälj"
          cardContent={
            <>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <DatePicker label="Datum" name="regDate" required />
                  <TextInput label="Säljare" name="salesRep" required />

                  <Dropdown
                    label="Återförsäljare"
                    name="reseller"
                    options={prepareResellerOptions(resellers.data)}
                    fieldProps={{
                      validate: isValidOption(
                        prepareResellerOptions(resellers?.data),
                        isLoadingResellers,
                      ),
                    }}
                    required
                  />
                  <TextInput label="Adresslista" name="addressList" />

                  <TextInput
                    label="Täckningsbidrag"
                    name="tb"
                    fieldProps={{
                      format: (v) => (v ? parseInt(v, 10) : 0),
                      parse: (v) => parseInt(v, 10),
                    }}
                  />

                  <TextInput label="Leadkälla" name="leadSource" />
                  <TextInput label="Placering" name="placement" />

                  {loadingAccountManagers ? (
                    <Skeleton />
                  ) : errorAccountManagers ? (
                    <Alert title="Kunde inte hämta account managers" />
                  ) : (
                    <Dropdown
                      label="Account Manager"
                      name="accountManager"
                      options={accountManagers?.options}
                      fieldProps={{
                        validate: isValidOption(
                          accountManagers?.options,
                          loadingAccountManagers,
                        ),
                      }}
                    />
                  )}
                  <PartnerManagerAutocomplete />

                  <TextInput label="AB Test" name="abTest" />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <CampaignSection
                    service={service}
                    customerType={customerType}
                  />

                  <TextInput
                    label={`Info till ${USER_TYPES.admin}`}
                    name="adminNote"
                  />
                  <DatePicker label="Churndatum" name="churnDate" />
                </Grid>
              </Grid>
            </>
          }
        />
      </section>
      <section>
        <ContactListSection />
      </section>
      <section>
        {isLoadingCampaignData && <LinearProgress />}
        <Grid container spacing={2}>
          <FieldArray name="templates">
            {({ fields }) =>
              fields.map((name, i) => (
                <Grid
                  key={name}
                  item
                  xs={12}
                  sm={6}
                  data-testid={`template-${i}`}
                >
                  <SimpleCard
                    title={`Enhet ${++i}`}
                    cardContent={
                      <>
                        <Dropdown
                          disabled
                          label="Pris"
                          name={`${name}.price`}
                          options={preparePricesOptions(
                            prices,
                            values.category ?? CustomerCategoryTypes.PRIVATE,
                          )}
                        />
                        <Dropdown
                          disabled
                          label="Startavgift"
                          name={`${name}.startFee`}
                          options={prepareFeesOptions(
                            prices,
                            values.category ?? CustomerCategoryTypes.PRIVATE,
                          )}
                        />

                        <Dropdown
                          disabled
                          label="Installationsavgift"
                          name={`${name}.installationFee`}
                          options={prepareFeesOptions(
                            prices,
                            values.category ?? CustomerCategoryTypes.PRIVATE,
                          )}
                        />
                      </>
                    }
                  />
                </Grid>
              ))
            }
          </FieldArray>
        </Grid>
      </section>
    </>
  );
};
