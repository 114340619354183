import React from 'react';

import { Edit } from '@mui/icons-material';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  LinearProgress,
} from '@mui/material';
import { Link } from 'react-router-dom';

import { Alert } from '@/components/Alert';
import { BasicAlert } from '@/components/BasicAlert';
import { Pagination } from '@/components/Pagination';
import { SimpleTable } from '@/components/SimpleTable';
import { PER_PAGE } from '@/constants/pagination';
import { useGetResellers } from '@/modules/ResellerPortal/hooks/useGetResellers';
import { ResellerItem } from '@/types/resellers';

export const ResellerList = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(PER_PAGE);

  const props = { page: page + 1, perPage: rowsPerPage, pagination: true };

  const { data, status } = useGetResellers(props);

  if (status === 'pending') return <LinearProgress />;
  if (status === 'error') return <BasicAlert />;
  if (status === 'success' && data.totalCount === 0)
    return (
      <Alert severity="info">Det finns inga registrerade återförsäljare</Alert>
    );
  if (status === 'success' && data.totalCount > 0) {
    return (
      <Card>
        <CardHeader
          title="Alla återförsäljare"
          action={
            <Link to="/reseller-portal/accounts/create">
              <Button variant="contained">Skapa</Button>
            </Link>
          }
        />
        <Divider />
        <CardContent>
          <SimpleTable columns={columns} rows={data.data} />
        </CardContent>
        <Pagination
          count={data.totalCount}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={(_, page) => setPage(page)}
          onRowsPerPageChange={(event) =>
            setRowsPerPage(parseInt(event.target.value, 10))
          }
        />
      </Card>
    );
  }
  return null;
};

const columns = [
  { label: 'ID', renderer: (row: ResellerItem) => row.id },
  { label: 'Namn', renderer: (row: ResellerItem) => row.name },
  { label: 'Email', renderer: (row: ResellerItem) => row.email },
  { label: 'Username', renderer: (row: ResellerItem) => row.username },
  {
    label: 'Manager',
    renderer: (row: ResellerItem) =>
      row.businessDevelopmentManagerInfo?.name || '-',
  },
  {
    label: 'Redigera',
    renderer: (row: ResellerItem) => (
      <IconButton component={Link} to={`/reseller-portal/accounts/${row.id}`}>
        <Edit />
      </IconButton>
    ),
  },
];
