import cloneDeep from 'lodash/cloneDeep';

import { HandleableAlarmList, HandleableAlarmListItem } from '@/types/alarms';

function normalizeAsObjectAlarmsState(data: HandleableAlarmListItem[]) {
  return data.reduce<{ [key: number]: HandleableAlarmListItem }>(
    (previousValue, currentValue) => {
      previousValue[currentValue.id] = currentValue;

      return previousValue;
    },
    {},
  );
}

export function prepareUpdatedAlarmsList(
  data: HandleableAlarmList,
  alarms: Record<number, HandleableAlarmListItem>,
): Record<number, HandleableAlarmListItem> {
  let preparedAlarmsList: Record<number, HandleableAlarmListItem> =
    cloneDeep(alarms);

  if (data.alarms.length > 0) {
    if (Object.keys(alarms).length > 0) {
      const handledAlarms = data.alarms.filter((alarm) => {
        return alarm.status === 3;
      });

      const updatedAlarms = data.alarms.filter((alarm) => alarm.status !== 3);

      handledAlarms.forEach((alarm) => {
        delete preparedAlarmsList[alarm.id];
      });

      updatedAlarms.forEach((alarm) => {
        preparedAlarmsList[alarm.id] = alarm;
      });
    } else {
      preparedAlarmsList = normalizeAsObjectAlarmsState(data.alarms);
    }
  }

  return preparedAlarmsList;
}
