import {
  Divider,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material';

type Props = {
  hasBillableOption: boolean;
};

const BoldListItemText = ({ children }: { children: React.ReactNode }) => (
  <ListItemText primaryTypographyProps={{ variant: 'subtitle2' }}>
    {children}
  </ListItemText>
);

export const ExportAllExplanationCard = ({ hasBillableOption }: Props) => {
  return (
    <List
      dense
      component={Paper}
      subheader={
        <Typography variant="h6" sx={{ py: 1, px: 2 }}>
          Exportera från cache ger en snabb export. Filen skapas 06.00 varje
          morgon och gäller vid följande:
        </Typography>
      }
    >
      <Divider />
      <ListItem>
        <BoldListItemText>Du exporterar för full period</BoldListItemText>
      </ListItem>
      <ListItem>
        <BoldListItemText>Status är aktiv eller ingen vald</BoldListItemText>
      </ListItem>
      <ListItem>
        <BoldListItemText>Ingen återförsäljare är vald</BoldListItemText>
      </ListItem>
      {hasBillableOption && (
        <ListItem>
          <BoldListItemText>
            Fungerar med eller utan faktureras
          </BoldListItemText>
        </ListItem>
      )}
    </List>
  );
};
