import { Helmet } from 'react-helmet';

import { PageHeader } from '@/components/PageHeader';
import { Tabs } from '@/components/Tabs/Tabs';
import { useGetActiveServices, usePrivateSettings } from '@/hooks';
import { SupportChurnBreadcrumbs } from '@/modules/supportChurn/components/SupportChurnBreadcrumbs';

import { View } from '../modules/supportChurn/components/View';

function SupportChurn() {
  const { servicesNames } = usePrivateSettings();
  const activeServices = useGetActiveServices();

  const tabMap = activeServices.map((service) => ({
    title: servicesNames[service],
    view: () => <View />,
  }));

  return (
    <>
      <Helmet title="Churn | Kundservice" />
      <SupportChurnBreadcrumbs />
      <PageHeader title="Kundbortfall" margin />
      <Tabs tabs={tabMap} />
    </>
  );
}

export default SupportChurn;
