import React from 'react';

import { styled } from '@mui/system';

import { MS_TO_READ_INSTRUCTION } from '../constants';

const InstructionImg = styled('img')`
  object-fit: cover;
  width: 100%;
  height: auto;
`;

export const Instruction = ({
  setEnableNextStep,
}: {
  setEnableNextStep: (nextStepEnabled: boolean) => void;
}) => {
  React.useEffect(() => {
    setEnableNextStep(false);
    const timer = setTimeout(() => {
      setEnableNextStep(true);
    }, MS_TO_READ_INSTRUCTION);

    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <InstructionImg src={'/images/sinne/battery-step-1.png'} />

      <InstructionImg src={'/images/sinne/battery-step-2.png'} />
    </>
  );
};
