import snakeCase from 'lodash/snakeCase';

import { CamelCaseServiceName, ServiceName } from '@/types/services';
import { KebabCaseServiceNames } from '@/types/settings';

export function getAPIServiceName(
  serviceName: CamelCaseServiceName | KebabCaseServiceNames | ServiceName,
) {
  return `${snakeCase(serviceName)}s`;
}
