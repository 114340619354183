import React from 'react';

import { LinearProgress } from '@mui/material';

import { BasicAlert } from '@/components/BasicAlert';
import useUser from '@/hooks/useUser';
import { ResellerAccountSettingsForm } from '@/modules/ResellerPortal/components/Forms/ResellerAccountSettingsForm';
import { useGetResellerById } from '@/modules/ResellerPortal/hooks/useGetResellerById';

export const ResellerSettings = () => {
  const { id } = useUser();
  const { data, status } = useGetResellerById(id);

  if (status === 'pending') return <LinearProgress />;
  if (status === 'error') return <BasicAlert />;
  if (status === 'success') return <ResellerAccountSettingsForm data={data} />;
  return null;
};
