import { KebabCaseServiceName } from '@/modules/common/utils/services';

export const generateKeys = (key: string | number) => {
  const keys = {
    all: [key] as const,
    listAll: () => [...keys.all, 'list'] as const,
    filteredList: (filters: any) => [...keys.listAll(), { filters }] as const,
    details: () => [...keys.all, 'detail'] as const,
    detailById: (id: string | number) =>
      [
        ...keys.details(),
        typeof id === 'string' ? id : id?.toString(),
      ] as const,
  };

  return keys;
};

export const queryKeys = {
  campaigns: generateKeys('campaigns'),
  getAccountManagers: 'getAccountManagers',
  getAgreementsByCustomer: 'getAgreementsByCustomer',
  getAllCampaigns: 'getAllCampaigns',
  getAllCustomers: 'getAllCustomers',
  getCampaign: 'getCampaign',
  getCampaigns: 'getCampaigns',
  getCards: 'getCards',
  getCoProtections: 'getCoProtections',
  getCosts: 'getCosts',
  getCustomerById: 'getCustomerById',
  getCustomerByPin: 'getCustomerByPin',
  getCustomLetters: generateKeys('getCustomLetters'),
  getCustomerServices: 'getCustomerServices',
  getRetentionStatistics: 'getRetentionStatistics',
  getCustomerTasks: 'getCustomerTasks',
  getAdminTasks: 'getAdminTasks',
  getCustomerSupportTasks: 'getCustomerSupportTasks',
  getDeletedUsers: 'getDeletedUsers',
  getFailedBulkBatchItem: 'getFailedBulkBatchItem',
  getFailedBulkBatchItems: 'getFailedBulkBatchItems',
  getFees: 'getFees',
  getFiretextById: 'getFiretextById',
  getHomegateCampaign: 'getHomegateCampaign',
  getHomegateCampaigns: 'getHomegateCampaigns',
  getHomegateTemplate: 'getHomegateTemplate',
  getInvoiceList: 'getInvoiceList',
  getInvoiceListSummary: 'getInvoiceListSummary',
  getNextSveaInvoice: 'getNextSevaInvoice',
  getKeyTags: 'getKeyTags',
  getMobiles: 'getMobiles',
  getNotes: 'getNotes',
  getPartnerCompanies: 'getPartnerCompanies',
  getPartners: 'getPartners',
  getPrices: 'getPrices',
  getPricesById: 'getPriceById',
  getProtections: 'getProtections',
  getPrivateSettings: 'getPrivateSettings',
  getPublicSettings: 'getPublicSettings',
  getReseller: 'getReseller',
  getResellers: 'getResellers',
  getServiceConfigurationList: 'getServiceConfigurationList',
  getSinneCampaigns: 'getSinneCampaigns',
  getSinnesToConfigure: 'getSinnesToConfigure',
  getStickers: 'getStickers',
  getTasks: 'getTasks',
  getUser: 'getUser',
  getUserById: 'getUserById',
  sendPasswordRecoveryLink: 'sendPasswordRecoveryLink',
  updateCustomer: 'updateCustomer',
  fetchServiceDetails: ({
    serviceName,
    serviceId,
  }: {
    serviceName: KebabCaseServiceName;
    serviceId: string;
  }): Array<string | number | object> => [
    'serviceDetails',
    serviceName,
    serviceId,
  ],
  keyTags: generateKeys('keyTags'),
  reseller: {
    all: ['reseller'],
    lists: () => [...queryKeys.reseller.all, 'lists'] as const,
    list: (filters: any) => [...queryKeys.reseller.lists(), filters] as const,
    details: () => [...queryKeys.reseller.all, 'detail'] as const,
    detail: (id: number) => [...queryKeys.reseller.details(), id] as const,
    enabledServices: (id: number) => ['getResellersEnabledServices', id],
  },
  rma: generateKeys('rma'),
  user: {
    all: ['user'],
    lists: () => [...queryKeys.user.all, 'lists'] as const,
    list: (filters: any) => [...queryKeys.user.lists(), filters] as const,
    details: () => [...queryKeys.user.all, 'detail'] as const,
    detail: (id: number) => [...queryKeys.user.details(), id] as const,
  },
  statistics: {
    all: ['statistics'],
    lists: () => [...queryKeys.statistics.all, 'lists'] as const,
    list: (filters: any) => [...queryKeys.statistics.lists(), filters] as const,
    details: () => [...queryKeys.statistics.all, 'detail'] as const,
    detail: (id: number) => [...queryKeys.statistics.details(), id] as const,
  },
  economy: {
    arvatoInvoiceMigrationProcess: () => [
      'economy',
      'arvatoInvoiceMigrationProcess',
    ],
  },
  devices: {
    all: ['devices'],
    healthCheck: (deviceId: string) =>
      [...queryKeys.devices.all, 'healthCheck', deviceId] as const,
    missingDevices: (filters: any) =>
      [...queryKeys.devices.all, filters, 'missingDevices'] as const,
  },
};
