import { LinearProgress, Typography } from '@mui/material';

import { BasicAlert } from '@/components/BasicAlert';
import { SimpleTable } from '@/components/SimpleTable';
import { getFormattedDate } from '@/helpers/dates';
import { useGetRmaCase } from '@/modules/customer/hooks/useGetRmaCase';
import { ExistingCustomer } from '@/types/customers';
import { RmaCase } from '@/types/rma';

type Props = {
  customer: ExistingCustomer;
};

const columns = [
  {
    label: 'Skapad',
    renderer: (row: RmaCase) => getFormattedDate(row.createdAt, 'yyyy-MM-dd'),
  },
  {
    label: 'Uppdaterad',
    renderer: (row: RmaCase) => getFormattedDate(row.updatedAt, 'yyyy-MM-dd'),
  },
  {
    label: 'Mac Address',
    renderer: (row: RmaCase) => row.macAddress,
  },
  {
    label: 'Status',
    renderer: (row: RmaCase) => row.status,
  },
];

export const RmaCaseCard = ({ customer }: Props) => {
  const { data: rmaCases, isLoading, isError } = useGetRmaCase(customer.id);

  if (isLoading) return <LinearProgress />;
  if (isError) return <BasicAlert />;
  if (rmaCases?.data.length === 0)
    return <Typography variant="h5">Inga ärenden</Typography>;

  return (
    <>
      {rmaCases?.data.map((rmaCase, idx) => (
        <SimpleTable
          key={rmaCase.id}
          columns={columns}
          rows={[rmaCases.data[idx]]}
          isCollapsible
          tableTitle={`Ärendenummer: ${rmaCase.id}`}
        />
      ))}
    </>
  );
};
