import React from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { useSelector } from '@xstate/react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { Alert } from '@/components/Alert';
import { BasicAlert } from '@/components/BasicAlert';
import { usePrivateSettings } from '@/hooks/usePrivateSettings';
import { Textfield } from '@/modules/components';
import { RetryButton, StepRow } from '@/modules/sinne/components';
import { DiscardStateContext } from '@/modules/sinne/components/sinneDiscardFlow/context/';

const formSchema = z.object({
  fee: z.coerce
    .number({
      required_error: 'Obligatoriskt fält', // TODO: required does not seem to work, hence the min.(0)
      invalid_type_error: 'Imei består enbart av siffror',
    })
    .min(0, 'Obligatoriskt fält'),
});

type Form = z.infer<typeof formSchema>;

export const FeeForm = () => {
  const { sinne: sinnePrivateSettings } = usePrivateSettings();
  const discardFlowService = React.useContext(DiscardStateContext);

  const state = useSelector(discardFlowService, (state) => state);
  const formSubmitted = useSelector(discardFlowService, (state) =>
    Boolean(state.context.discardDeviceFee),
  );

  const onSubmit = ({ fee }: Form) => {
    discardFlowService.send('SET_FEE_VALUE', { value: fee });
  };

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<Form>({
    defaultValues: {
      fee: sinnePrivateSettings?.customerPrice || 0,
    },
    shouldUnregister: true,
    resolver: zodResolver(formSchema),
  });

  const { noClipData } = state.context;
  return (
    <>
      {typeof noClipData?.customerPrice === 'number' && (
        <Alert severity="info" sx={{ marginBottom: 2 }}>
          Ingen avgift kommer debiteras mot kund eftersom kund redan betalt för
          hårdvaran
        </Alert>
      )}
      {state.matches('FEE_HANDLER.FEE_FORM.loading') && (
        <StepRow>
          <CircularProgress size={14} />
          <Typography component="span" variant="h6">
            Hämtar inställningar
          </Typography>
        </StepRow>
      )}
      {state.matches('FEE_HANDLER.FEE_FORM.editing') && (
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="column">
            <Grid item>
              <Typography variant="h5">Ange avgift</Typography>
            </Grid>
            <Grid item>
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <Textfield
                    size="small"
                    label="Avgift exkl. moms"
                    error={errors.fee}
                    autoComplete="off"
                    autoFocus
                    type="step"
                    sx={{ marginBottom: 2 }}
                    inputProps={{
                      ...register('fee'),
                      'data-testid': 'fee',
                    }}
                    disabled={formSubmitted}
                  />
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    variant="outlined"
                    disabled={formSubmitted}
                  >
                    Spara
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
      {state.matches('FEE_HANDLER.ADD_CUSTOMER_FEE.loading') && (
        <StepRow>
          <CircularProgress size={14} />
          <Typography component="span" variant="h6">
            Skapar avgift
          </Typography>
        </StepRow>
      )}

      {state.context.isCustomerFeeCreated && (
        <StepRow>
          <CheckCircleOutlineIcon color="success" sx={{ fontSize: 20 }} />
          <Typography component="span" variant="h6" padding={0} margin={0}>
            Avgift skapad
          </Typography>
        </StepRow>
      )}

      {state.matches('FEE_HANDLER.ADD_CUSTOMER_FEE.error') && (
        <StepRow>
          <ErrorOutlineIcon color="error" sx={{ fontSize: 20 }} />
          <Typography component="span" variant="h6">
            Kunde inte skapa avgift
          </Typography>
          <RetryButton
            onClick={() =>
              discardFlowService.send({
                type: 'RETRY_ADD_FEE',
                value: state.context.discardDeviceFee!,
              })
            }
          />
        </StepRow>
      )}
      {state.matches('FEE_HANDLER.ADD_SVEA_FEE.loading') && (
        <StepRow>
          <CircularProgress size={14} />
          <Typography component="span" variant="h6">
            Lägger till avgift hos Svea
          </Typography>
        </StepRow>
      )}
      {state.context.isSveaFeeCreated && (
        <StepRow>
          <CheckCircleOutlineIcon color="success" sx={{ fontSize: 20 }} />
          <Typography component="span" variant="h6" padding={0} margin={0}>
            Avgift tillagd hos Svea
          </Typography>
        </StepRow>
      )}
      {state.matches('FEE_HANDLER.ADD_SVEA_FEE.error') && (
        <StepRow>
          <ErrorOutlineIcon color="error" sx={{ fontSize: 20 }} />
          <Typography component="span" variant="h6">
            Kunde inte lägga till avgift hos Svea
          </Typography>
          <RetryButton
            onClick={() =>
              discardFlowService.send({
                type: 'RETRY_ADD_SVEA_FEE',
                value: state.context.discardDeviceFee!,
              })
            }
          />
        </StepRow>
      )}
    </>
  );
};
