import React from 'react';

import { Button, ButtonProps as MuiButtonProps, styled } from '@mui/material';
import { NavLinkProps } from 'react-router-dom';

interface ButtonProps<ComponentElement> extends MuiButtonProps {
  active?: boolean;
  to?: string;
  component?: React.ForwardRefExoticComponent<
    NavLinkProps & React.RefAttributes<ComponentElement>
  >;
}

const CustomButton = styled(Button)(({ theme }) => ({
  width: '100%',
  justifyContent: 'space-between',
  padding: theme.spacing(1),
  textTransform: 'none',
  letterSpacing: 0,
  fontWeight: theme.typography.fontWeightMedium,
  '&.active': {
    color: theme.palette.primary.main,
    background: theme.palette.primary.light,
  },
}));

export const MenuButton = <ComponentElement extends HTMLElement>({
  children,
  ...rest
}: ButtonProps<ComponentElement>) => {
  return <CustomButton {...rest}>{children}</CustomButton>;
};
