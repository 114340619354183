import { Grid } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Form } from 'react-final-form';

import { protectedUpload } from '@/api/files';
import { protectionSecurityTemplateTypeOptions } from '@/constants/FileTypes';
import { useSnackbar } from '@/hooks';
import useUserRoles from '@/hooks/useUserRoles';
import { checkRolesAccess } from '@/utils/authorization';
import httpClient from '@/utils/httpClient';
import { generatePdfDownloadLink } from '@/utils/pdf';
import Yup, { makeValidation } from '@/utils/validation';

import { FileListCard } from '../../FileListCard';
import ProtectedFileCreation from '../Protection/ProtectedFileCreation';
import Uploads from '../Protection/Uploads';
import DocumentDownload from './DocumentDownload';

type AttachFileToPinHandlerProps = {
  templateType: string;
  protectedPin: string;
  serviceType: string;
  customerId?: number;
};

type FileUploadHandlerProps = {
  entityType: string;
  entityId: number;
  type: string;
  file: File[];
  protectedPin: string;
};

type SubmitHandlerProps = {
  action: string;
  templateType: string;
  protectedPin: string;
  serviceType: string;
  customerId?: number;
};

export const Documents = ({ serviceDetails }: Record<string, any>) => {
  const userRoles = useUserRoles();
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();
  const customerId = serviceDetails?.customer?.id;
  const downloadFileHandler = useMutation({
    mutationFn: (v: AttachFileToPinHandlerProps) =>
      httpClient.post('documentable/template/generate', v, {
        responseType: 'blob',
      }),
  });
  const showSnackbar = (success: boolean) => {
    snackbar({
      message: `Filuppladdning ${success ? 'lyckades' : 'misslyckades'}.`,
      type: success ? 'success' : 'error',
    });
  };
  const protectedFileUpload = useMutation({
    mutationFn: (v: FileUploadHandlerProps) => protectedUpload(v),
    onSuccess: () => {
      snackbar({ type: 'success', message: 'Uppladdning lyckades' });
    },
    onError: () => {
      snackbar({ type: 'error', message: 'Uppladdning misslyckades' });
    },
  });

  const attachFileToPinHandler = async ({
    templateType,
    protectedPin,
    serviceType,
    ...rest
  }: AttachFileToPinHandlerProps) => {
    await downloadFileHandler
      .mutateAsync({
        templateType,
        protectedPin,
        serviceType: serviceDetails['@type'].toLowerCase(),
        ...rest,
      })
      .then(({ data }) => {
        const payload = {
          entityType: serviceDetails['@type'],
          entityId: serviceDetails.id,
          type: templateType,
          file: [
            new File(
              [data],
              `${templateType}-${serviceDetails['@type']}-${serviceDetails.id}.pdf`,
            ),
          ],
          protectedPin,
        };
        protectedFileUpload.mutate(payload);
      });
  };
  const submitHandler = ({ action, ...values }: SubmitHandlerProps) => {
    if (action === 'attach') {
      attachFileToPinHandler({ ...values, customerId: customerId });
    } else {
      downloadFileHandler.mutate(
        {
          ...values,
          serviceType: serviceDetails['@type'].toLowerCase(),
          customerId: customerId,
        },
        {
          onSuccess: (response, data) => {
            generatePdfDownloadLink(
              response.data,
              protectionSecurityTemplateTypeOptions.find(
                (option) => option.value === data.templateType,
              )?.text || 'default_file_name',
            );
          },
          onError: () => {
            snackbar({ type: 'error', message: 'Misslyckades ladda ner fil.' });
          },
        },
      );
    }
  };
  const validationSchema = Yup.object({
    templateType: Yup.string().required(),
    protectedPin: Yup.string().required(),
    protectedName: Yup.string().required(),
    protectedPhone: Yup.string().required(),
    protectedEmail: Yup.string().email(),
    protectedAddress: Yup.string().required(),
    protectedZip: Yup.string().required(),
    protectedCity: Yup.string().required(),
  });
  const uploadMutation = useMutation({
    mutationFn: protectedUpload,
    onSuccess: () => {
      showSnackbar(true);
      queryClient.invalidateQueries({
        queryKey: ['protectedFilesList'],
      });
    },
    onError: (error) => {
      showSnackbar(false);
      // eslint-disable-next-line no-console
      console.log('Error', error);
    },
  });

  const validate = makeValidation(validationSchema);

  return (
    <>
      <DocumentDownload serviceDetails={serviceDetails} />
      {serviceDetails['@type'] === 'Protection' &&
        checkRolesAccess(userRoles, 'protectedFiles.view') && (
          <FileListCard serviceDetails={serviceDetails} />
        )}
      {serviceDetails['@type'] === 'Protection' && (
        <Grid container spacing={2}>
          {checkRolesAccess(userRoles, 'protectedFiles.upload') && (
            <Grid item xs={12} sm={6}>
              <Form
                onSubmit={(values) => uploadMutation.mutate(values)}
                initialValues={{
                  entityType: 'Protection',
                  entityId: serviceDetails.id,
                }}
                render={({ handleSubmit, values }) => (
                  <Uploads
                    serviceDetails={serviceDetails}
                    handleSubmit={handleSubmit}
                    values={values}
                    uploadMutation={uploadMutation}
                  />
                )}
              />
            </Grid>
          )}
          {checkRolesAccess(userRoles, 'protectedFiles.upload') &&
            checkRolesAccess(userRoles, 'protectedFiles.download') && (
              <Grid item xs={12} sm={6}>
                <Form
                  onSubmit={submitHandler}
                  validate={validate}
                  render={({ handleSubmit, form }) => (
                    <ProtectedFileCreation
                      serviceDetails={serviceDetails}
                      form={form}
                      isLoading={
                        downloadFileHandler.isPending ||
                        protectedFileUpload.isPending
                      }
                      isSuccess={
                        downloadFileHandler.isSuccess ||
                        protectedFileUpload.isSuccess
                      }
                      handleSubmit={handleSubmit}
                    />
                  )}
                />
              </Grid>
            )}
        </Grid>
      )}
    </>
  );
};
