import { NumberedListItem } from '@/components/NumberedListItem';

import { ListTitle } from '../../../commonStyles';

export function MessageCustomer() {
  return (
    <>
      <ListTitle variant="h6">Meddela kund:</ListTitle>

      <NumberedListItem
        number={1}
        text={`Be om ett telefonnummer samt kontrollera e-post så vi kan nå kund på utifall det behövs under processen samt. Om e-post ej finns, be om en.`}
      />
      <NumberedListItem
        number={2}
        text={`Meddela att kund behöver göra en Polisanmälan. Detta får inte vi göra.`}
      />
      <NumberedListItem
        number={3}
        text={
          <>
            {`Har kund förlorat `}
            <b>Körkort:</b>
            {`
Meddela kund att vi kommer maila en länk till kunden där kund via 3 enkla steg spärrar sitt Körkort och därmed får hem en blankett om förnyelse av körkort.

Har kund förlorat `}
            <b>ID-Kort:</b>
            {`
Vi behöver inte göra något annat än att förlustanmälan till Bisnode/UC, utan kund förlustanmäler själv till Skatteverket eller polisen (beroende på typ av Id-kort)`}
            <b>grönt id-kort</b>
            {` (utfärdades före september 2017), ring `}
            <b>010-331 1010</b>
            {`
Om kund har ett `}
            <b>gult eller blått id-kort</b>
            {` (utfärdades efter september 2017), ring `}
            <b>020-32 32 62</b>
            {`

Har kund förlorat `}
            <b>Pass:</b>
            {`
Meddela kund att när kunden gör sin polisanmälan på passet så blir det per automatik förlustanmält. Spärren lämnas vidare till Schengen Information System, SIS, och Interpol. Gränskontrollmyndigheter runt om i världen kontrollerar resehandlingar mot dessa register vid inresa i länder och därför kan ett upphittat pass eller nationellt id-kort aldrig användas efter att det har spärrats.`}

            <b>nationellt ID-Kort (Utfärdat hos polisen):</b>
            {`
Be kund ringa 11414 och spärra hos polisen (kund måste ringa själv då polisen ställer kontrollfrågor )  alt använd deras e-tjänst  https://etjanster.polisen.se/eanmalan/forlust `}
          </>
        }
      />
      <NumberedListItem
        number={4}
        text={`Berätta även att om kund får tillbaka passet,körkort eller id-kort är det viktigt att kund vänder sig till polisen så att polisen kan makulera detta. Har kund fått ett nytt pass eller nationellt id-kort är kund skyldig att lämna det gamla för makulering. Den gamla resehandlingen ska under inga omständigheter användas.`}
      />
      <NumberedListItem
        number={5}
        text={`Meddela att vi ringer Dun & Bradstreet (tidigare Bisnode) och UC och lägger en spärr i deras register. Detta är inte en generell spärr men dessa två aktörer är de största kreditupplysningsföretagen i Sverige. Om bedragaren använder id handlingen hos någon av anslutna företag till UC alt Bisnode så säger kreditupplysningen ifrån att denna id handling är spärrad. Kunden kan när som helst häva spärren. Då ringer kunden till spärrlinjen igen så hjälper vi till genom att skicka hem en blankett till folkbokföringsadressen.[Separat rutin]`}
      />
    </>
  );
}
