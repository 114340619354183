import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  ListItem,
  ListItemText,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { formatInTimeZone } from 'date-fns-tz';
import { pick } from 'lodash';
import { TextField } from 'mui-rff';
import { Form } from 'react-final-form';

import { Dropdown } from '@/components/form';
import { useModal } from '@/hooks/useModal';
import { ModalTypes } from '@/state/modalManagementAtom';
import { HandleableAlarm, UpdateAlarmStatusPayload } from '@/types/alarms';
import { PartialContactPerson, ServiceParams } from '@/types/services';

import {
  TransformedAlarmEvent,
  useGetAlarmReasonOptions,
  useGetAlarmRemedyOptions,
} from '../hooks';
import { ContactPeopleCard } from './ContactPeopleCard';
import { CustomerInformationCard } from './CustomInformationCard';

type Props = {
  alarmId: string;
  alarm: HandleableAlarm;
  deviceData: ServiceParams;
  contactPersonList: PartialContactPerson[];
  sortedEvents: TransformedAlarmEvent[];
  onUpdateAlarmStatus: (payload: UpdateAlarmStatusPayload) => void;
};

export const AlarmDetailsView = ({
  alarmId,
  alarm,
  deviceData,
  contactPersonList,
  sortedEvents,
  onUpdateAlarmStatus,
}: Props) => {
  const { openModal } = useModal();

  const alarmReasonOptions = useGetAlarmReasonOptions();
  const alarmRemedyOptions = useGetAlarmRemedyOptions();

  return (
    <Grid container spacing={2} alignItems="start">
      <Grid container sx={{ pt: 0, pl: '1rem' }} justifyContent="flex-end">
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() =>
            openModal({
              modalType: ModalTypes.CUSTOMER_TASK,
              extra: { customerId: deviceData.customer?.id },
            })
          }
        >
          Skapa kundärende
        </Button>
      </Grid>

      <Grid container item spacing={2} xs={12} md={4}>
        <Grid item xs={12}>
          <CustomerInformationCard customerId={deviceData.customer?.id} />
        </Grid>
        <Grid item xs={12}>
          <ContactPeopleCard contactPersonList={contactPersonList!} />
        </Grid>
      </Grid>

      <Grid container item spacing={2} xs={12} md={4}>
        <Grid item xs={12}>
          <Card>
            <FlexCardHeader
              title="Larm"
              subheader={
                <span>
                  <b>Placering:</b>{' '}
                  {'placement' in deviceData && deviceData.placement}
                </span>
              }
            />
            <Divider />

            <CardContent>
              {sortedEvents.map((event, idx) => (
                <Box key={event.occuredAt + idx}>
                  <ListItem disablePadding>
                    <ListItemText
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      disableTypography
                    >
                      <b>Larm:</b>
                      <span>{event.type}</span>
                    </ListItemText>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemText
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      disableTypography
                    >
                      <b>Startade:</b>
                      <span>
                        {formatInTimeZone(
                          new Date(event.occuredAt),
                          'Europe/Stockholm',
                          'yyyy-MM-dd HH:mm:ss',
                        )}
                      </span>
                    </ListItemText>
                  </ListItem>
                  <Divider sx={{ marginY: 2 }} />
                </Box>
              ))}
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid container item spacing={2} xs={12} md={4}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Rapport" />
            <Divider />
            <Form
              onSubmit={(values: UpdateAlarmStatusPayload) =>
                onUpdateAlarmStatus(values)
              }
              initialValues={{
                ...pick(alarm, [
                  'reason',
                  'reasonComment',
                  'remedy',
                  'remedyComment',
                ]),
                alarmId: parseInt(alarmId, 10),
                status: alarm.status,
              }}
            >
              {({ handleSubmit }) => (
                <CardContent component="form" onSubmit={handleSubmit}>
                  <Dropdown
                    name="reason"
                    label="Anledning"
                    options={alarmReasonOptions}
                  />

                  <TextField
                    name="reasonComment"
                    label="Skriv ditt meddelande här"
                    multiline
                    rows={4}
                  />
                  <Dropdown
                    name="remedy"
                    label="Åtgärd"
                    options={alarmRemedyOptions}
                  />
                  <TextField
                    name="remedyComment"
                    label="Skriv ditt meddelande här"
                    multiline
                    rows={4}
                  />
                  <Box display="flex" justifyContent="flex-end" marginTop={2}>
                    <Button variant="outlined" type="submit">
                      Skapa rapport
                    </Button>
                  </Box>
                </CardContent>
              )}
            </Form>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

const FlexCardHeader = styled(CardHeader)`
  & .MuiCardHeader-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
`;
