import { Breadcrumbs } from '@/components/Breadcrumbs';
import { PageHeader } from '@/components/PageHeader';
import { Tabs } from '@/components/Tabs/Tabs';
import { usePrivateSettings } from '@/hooks';
import { AddToSvea } from '@/modules/admin';
import { USER_TYPES } from '@/modules/common/constants/entitiyNamesMap';
import { Tabmap } from '@/types/tabs';

export const BREADCRUMBS_CONFIG = [
  { label: USER_TYPES.admin },
  { label: 'Svea' },
];

const SveaAdmin = () => {
  const tabMap: Tabmap[] = [
    {
      title: 'Lägg till kund hos Svea',
      view: () => <AddToSvea />,
      authRule: 'admin.tools.addToSvea',
    },
  ];

  return (
    <>
      <Breadcrumbs crumbs={BREADCRUMBS_CONFIG} />
      <PageHeader
        title={`Verktyg för ${USER_TYPES.admin.toLowerCase()}`}
        margin
      />

      <Tabs tabs={tabMap} />
    </>
  );
};

export default SveaAdmin;
