import { SetStateAction, useState } from 'react';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import {
  Box,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';

import * as CustomersApi from '@/api/customers';
import { ServiceListSchema } from '@/api/customers';
import { BasicAlert } from '@/components/BasicAlert';
import { Pagination } from '@/components/Pagination';
import { SimpleTable } from '@/components/SimpleTable';
import { PER_PAGE } from '@/constants/pagination';
import { hasAccessToAllCustomers } from '@/constants/roleTypes';
import { getServiceStatusKey } from '@/helpers';
import { usePrivateSettings } from '@/hooks';
import useUserRoles from '@/hooks/useUserRoles';
import { SearchField } from '@/modules/common/components';
import { getCamelServiceName } from '@/modules/common/utils/services';
import { ServiceName } from '@/types/services';

export const ServiceList = () => {
  const { serviceName } = useParams<{ serviceName: ServiceName }>();
  invariant(serviceName, 'Service name is required');
  const camelServiceName = getCamelServiceName(serviceName);
  const navigate = useNavigate();
  const userRoles = useUserRoles();

  const { servicesStatuses, getServiceStatusString } = usePrivateSettings();

  const canceldStatusKey = getServiceStatusKey(
    servicesStatuses,
    camelServiceName,
    'Makulerad',
  );

  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(PER_PAGE);

  const { data, isFetching, isError } = useQuery({
    queryKey: ['serviceCustomers', serviceName, page, perPage, search],

    queryFn: async () => {
      const data = await CustomersApi.fetchCustomersForService({
        serviceName: camelServiceName,
        page: page + 1,
        perPage,
        search,
      });
      return {
        services: data['hydra:member'],
        total: data['hydra:totalItems'],
      };
    },

    initialData: { services: [], total: 0 },
    enabled: !!serviceName,
  });

  const TABLE_COLUMNS = [
    {
      label: 'Kundnummer',
      renderer: (row: ServiceListSchema) => row?.customer?.id,
    },
    {
      label: 'Namn',
      renderer: (row: ServiceListSchema) => row?.customer?.fullName,
    },
    {
      label: 'Personnr',
      renderer: (row: ServiceListSchema) => row?.customer?.pin,
    },
    ...(serviceName === 'firetext'
      ? [{ label: 'ICC', renderer: (row: ServiceListSchema) => row?.icc }]
      : []),
    ...(serviceName === 'sticker'
      ? [{ label: 'Seriekod', renderer: (row: ServiceListSchema) => row?.code }]
      : []),
    { label: 'Tjänst ID', renderer: (row: ServiceListSchema) => row?.id },
    {
      label: 'Status',
      renderer: (row: ServiceListSchema) => (
        <span
          style={{
            color:
              canceldStatusKey && row.status === parseInt(canceldStatusKey, 10)
                ? '#e0175b'
                : 'inherit',
          }}
        >
          {getServiceStatusString(serviceName, row.status)}
        </span>
      ),
    },
    {
      label: 'Ändra',
      renderer: (row: ServiceListSchema) => (
        <div>
          <Link
            component={RouterLink}
            to={`/products/${serviceName}/${row.id}/edit`}
          >
            <IconButton color="primary" size="large">
              <ArrowForwardIcon />
            </IconButton>
          </Link>
          <Link
            target="_blank"
            href={`/products/${serviceName}/${row.id}/edit`}
            onClick={(e) => e.stopPropagation()}
          >
            <Tooltip title="Öppna i ny flik">
              <IconButton color="primary" size="large">
                <LaunchOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Link>
        </div>
      ),
    },
  ];

  const handlePageChange = (_: any, newPage: SetStateAction<number>) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: { target: { value: string } }) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    setPerPage(rowsPerPage);
  };

  const handleRowClick = (row: ServiceListSchema) => {
    navigate(`/products/${serviceName}/${row.id}/edit`);
  };

  return (
    <Box>
      {hasAccessToAllCustomers(userRoles) && (
        <Grid
          component={Paper}
          style={{ padding: '1rem', marginBottom: '0.5rem' }}
          container
        >
          <Grid item xs={12} sm={6}>
            <SearchField setDebouncedQueryString={setSearch} />
          </Grid>
        </Grid>
      )}

      {isFetching ? (
        <LinearProgress />
      ) : isError ? (
        <BasicAlert />
      ) : data.total === 0 ? (
        <Paper style={{ padding: '2rem' }}>
          <Typography>Inget resultat</Typography>
        </Paper>
      ) : (
        <>
          <SimpleTable
            columns={TABLE_COLUMNS}
            rows={data.services}
            onRowClick={handleRowClick}
          />
          <Paper square>
            <Pagination
              count={data.total}
              rowsPerPage={perPage}
              page={page}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </Paper>
        </>
      )}
    </Box>
  );
};
