import { Alert, Skeleton } from '@mui/material';

import { getTypeKey } from '@/helpers';
import { getFormattedDate } from '@/helpers/dates';
import { usePrivateSettings } from '@/hooks/usePrivateSettings';

import { useLatestNote } from '../../hooks';

export const LatestNote = ({ customer }: { customer: string }) => {
  const { servicesTypes } = usePrivateSettings();

  const accountManagerNoteType = getTypeKey(
    servicesTypes,
    'note',
    'Account manager',
  );
  const { data, isLoading, isError } = useLatestNote(
    customer,
    accountManagerNoteType,
  );
  return isLoading ? (
    <Skeleton />
  ) : isError ? (
    <Alert severity="error">Något gick fel</Alert>
  ) : data ? (
    <>
      {data.text}
      <br />
      {getFormattedDate(data.createdAt, 'yyyy-MM-dd HH:mm:ss')}
    </>
  ) : (
    <>'Saknas'</>
  );
};
