import { List, ListProps } from '@mui/material';

export const CollapsableMenuList = ({ children }: ListProps) => {
  return (
    <List
      component="div"
      disablePadding
      dense
      sx={{
        paddingLeft: (theme) => theme.spacing(2),
        '& > *': {
          margin: 0,
        },
      }}
    >
      {children}
    </List>
  );
};
