import { useMemo, useState } from 'react';

import { Alert, Box, Button, Typography } from '@mui/material';
import pick from 'lodash/pick';
import { Form } from 'react-final-form';
import { z } from 'zod';

import { useSnackbar } from '@/hooks/useSnackbar';
import { ProtectionParamSchema } from '@/types/services';
import {
  protectionPricePlanSchema,
  protectionStatusKey,
} from '@/types/settings';

import { mapInitialObjectValuesToStrings } from '../../../utils';
import { CardsSection } from './CardsSection';
import { MobilesSection } from './MobilesSection';
import { ProtectionListForm } from './ProtectionListForm';
import { SalesInformationForm } from './SalesInformationForm';
import { ServiceInfoForm } from './ServiceInfoForm';

const FIELDS_TO_PICK = [
  'abTest',
  'addressList',
  'adminNote',
  'antiregret',
  'billing',
  'campaign',
  'churnDate',
  'churnReason',
  'email1',
  'email2',
  'email3',
  'email4',
  'email5',
  'kamId',
  'leadSource',
  'name1',
  'name2',
  'name3',
  'name4',
  'name5',
  'phone1',
  'phone2',
  'phone3',
  'phone4',
  'phone5',
  'pin1',
  'pin2',
  'pin3',
  'pin4',
  'pin5',
  'pricePlanId',
  'protectionsCampaign',
  'regDate',
  'regretReason',
  'regretSource',
  'reseller',
  'salesRep',
  'status',
  'tb',
  'type',
  'winBack',
];

const protectionFormValuesSchema = z.object({
  abTest: z.string(),
  addressList: z.string(),
  adminNote: z.string().nullable(),
  antiregret: z.number().int(),
  billing: z.number().int(),
  campaign: z.string(),
  churnDate: z.string().nullable(),
  churnReason: z.number().int(), // TODO: Define enum in types/services.ts,
  email1: z.string().nullable(),
  email2: z.string().nullable(),
  email3: z.string().nullable(),
  email4: z.string().nullable(),
  email5: z.string().nullable(),
  kamId: z.number().nullable(),
  leadSource: z.string(),
  name1: z.string().nullable(),
  name2: z.string().nullable(),
  name3: z.string().nullable(),
  name4: z.string().nullable(),
  name5: z.string().nullable(),
  phone1: z.string().nullable(),
  phone2: z.string().nullable(),
  phone3: z.string().nullable(),
  phone4: z.string().nullable(),
  phone5: z.string().nullable(),
  pin1: z.string().nullable(),
  pin2: z.string().nullable(),
  pin3: z.string().nullable(),
  pin4: z.string().nullable(),
  pin5: z.string().nullable(),
  pricePlanId: protectionPricePlanSchema.keySchema,
  regDate: z.string(),
  regretReason: z.number().int(),
  regretSource: z.number().int(),
  reseller: z.string(),
  salesRep: z.string(),
  status: protectionStatusKey,
  tb: z.number().nullable(),
  type: z.number().int(),
  winBack: z.number().int(),
});

export type ProtectionFormValues = z.infer<typeof protectionFormValuesSchema>;

export type ProtectionEditProps = {
  serviceDetails: ProtectionParamSchema;
  isLoading: boolean;
  onSubmit: (v: ProtectionFormValues) => void;
};

export const ProtectionEdit = ({
  serviceDetails,
  isLoading,
  onSubmit,
}: ProtectionEditProps) => {
  const serviceName = 'protection';
  const snackbar = useSnackbar();

  const [formInitialValueErrors, setFormInitialValueErrors] = useState<
    z.ZodIssue[]
  >([]);

  const initialValues = useMemo(() => {
    const mappedInitialValues = mapInitialObjectValuesToStrings(
      pick(serviceDetails, FIELDS_TO_PICK),
    );

    const parsedInitialValues = protectionFormValuesSchema
      .passthrough()
      .safeParse(mappedInitialValues);

    if (!parsedInitialValues.success) {
      setFormInitialValueErrors(parsedInitialValues.error.errors);
      return mappedInitialValues;
    } else {
      return parsedInitialValues.data;
    }
  }, [serviceDetails]);

  function handleSubmit(values: ProtectionFormValues) {
    if (formInitialValueErrors.length === 0) {
      onSubmit(values);
    } else {
      snackbar({
        type: 'error',
        message:
          'Du kan inte skicka in formulärändringar på grund av felaktiga initiala värden. Var god kontakta supporten.',
      });
    }
  }

  return (
    <>
      {formInitialValueErrors.length > 0 && (
        <Alert icon={false} severity="warning" sx={{ my: 4 }}>
          <Typography>
            Varning! Det kan finnas vissa saknade eller felaktiga uppgifter i
            följande formulärfält. Vänligen gör inga ändringar i den här
            tjänsten för tillfället
          </Typography>
          <ul>
            {formInitialValueErrors.map((error) => (
              <li key={error.path.join('.')}>{error.path[0]}</li>
            ))}
          </ul>
        </Alert>
      )}

      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        // TODO: check if form can be disabled
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <Box display="flex" flexDirection="column" gap={2}>
              <ServiceInfoForm
                serviceDetails={serviceDetails}
                isLoading={isLoading}
                churnDateValue={values.churnDate}
                statusValue={values.status}
              />

              <ProtectionListForm />

              <SalesInformationForm
                serviceName={serviceName}
                serviceDetails={serviceDetails}
                isLoading={isLoading}
              />

              <Box>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={isLoading}
                >
                  Uppdatera
                </Button>
              </Box>
              {/* TODO: Refactor to not use redux and global modals */}
              <MobilesSection
                productIri={`/protections/${serviceDetails.id}`}
                productType="Protection"
              />

              {/* TODO: Refactor to not use redux and global modals */}
              <CardsSection
                productIri={`/protections/${serviceDetails.id}`}
                productType="Protection"
              />
            </Box>
          </form>
        )}
      />
    </>
  );
};
