import { Box, Divider, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { format, parseISO } from 'date-fns';

import { getElementFromArray } from '@/helpers/getElementFromArray';
import { usePrivateSettings } from '@/hooks';
import { HandleableAlarm, HandleableAlarmsReturnType } from '@/types/alarms';

type Props = {
  oldData: HandleableAlarmsReturnType<
    Pick<HandleableAlarm, '@id' | 'occuredAt' | 'status' | 'reasonComment'>
  >;
  data: HandleableAlarmsReturnType;
};

export const FiretextAlarmsHistory = ({ data, oldData }: Props) => {
  const { servicesStatuses } = usePrivateSettings();

  const alarmStatuses = servicesStatuses.handleableAlarm.statuses;
  const oldAlarmStatuses = servicesStatuses.alarm;

  return (
    <Grid container direction="column">
      {oldData.items.map((alarm, index) => (
        <AlarmDetails
          key={index}
          alarm={alarm}
          status={oldAlarmStatuses[alarm.status]}
          showDivider={index + 1 !== oldData.items.length}
          reasonNote={alarm.reasonComment}
        />
      ))}

      {oldData.items.length > 0 && data.items.length > 0 && (
        <Divider sx={{ marginY: 2, marginLeft: 0 }} flexItem />
      )}

      {data.items.map((alarm, index) => (
        <AlarmDetails
          key={index}
          alarm={alarm}
          status={getElementFromArray(alarmStatuses?.[alarm.status])}
          showDivider={index + 1 !== data.items.length}
          reasonNote={alarm.reasonComment}
        />
      ))}
    </Grid>
  );
};

const AlarmDetails = ({
  alarm,
  showDivider,
  status,
  reasonNote,
}: {
  alarm: Pick<HandleableAlarm, '@id' | 'occuredAt' | 'status'>;
  showDivider: boolean;
  status: string;
  reasonNote?: string | null;
}) => {
  return (
    <Box key={alarm['@id']}>
      <Grid item>
        <Grid container direction="column">
          <Grid item>
            <Typography>
              <Label>Datum:</Label>{' '}
              {format(parseISO(alarm.occuredAt), 'yyyy-MM-dd HH:mm:ss')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              <Label>Status:</Label> {status}
            </Typography>
          </Grid>
          {reasonNote && (
            <Grid item>
              <Typography>
                <Label>Notering:</Label> {reasonNote}
              </Typography>
            </Grid>
          )}
        </Grid>
        {showDivider && <Divider sx={{ marginY: 2, marginLeft: 0 }} flexItem />}
      </Grid>
    </Box>
  );
};

const Label = styled('span')`
  font-weight: 500;
`;
