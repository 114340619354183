import React from 'react';

import { Radios, RadiosProps } from 'mui-rff';

type Props = RadiosProps & {
  data: Array<{ value: string; label: string }>;
};

export const RadioButtons = ({ data, ...props }: Props) => {
  return (
    <Radios
      {...props}
      data={data}
      // @ts-expect-error data-testid is not available in the type definition
      radioGroupProps={{ row: true, 'data-testid': props.name }}
      color="primary"
    />
  );
};
