import { SvgIcon, SvgIconProps } from '@mui/material';

export const FiretextIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 96 96" width="96px" height="96px">
      <g id="surface196146030">
        <path d="M 47.902344 8 C 47.261719 8.023438 46.648438 8.246094 46.144531 8.644531 L 17.71875 31.039062 C 14.109375 33.882812 12 38.226562 12 42.820312 L 12 75 C 12 81.039062 16.960938 86 23 86 L 73 86 C 79.039062 86 84 81.039062 84 75 L 84 42.820312 C 84 38.226562 81.890625 33.882812 78.28125 31.039062 L 49.855469 8.644531 C 49.300781 8.207031 48.609375 7.976562 47.902344 8 Z M 48 14.820312 L 74.570312 35.753906 C 76.738281 37.460938 78 40.0625 78 42.820312 L 78 75 C 78 77.796875 75.796875 80 73 80 L 23 80 C 20.203125 80 18 77.796875 18 75 L 18 42.820312 C 18 40.0625 19.261719 37.460938 21.429688 35.753906 Z M 47.867188 34.003906 C 47.253906 34.03125 46.660156 34.246094 46.171875 34.621094 C 46.171875 34.621094 42.675781 37.300781 39.203125 41.445312 C 35.730469 45.589844 32 51.316406 32 58 C 32 66.800781 39.199219 74 48 74 C 56.800781 74 64 66.800781 64 58 C 64 51.316406 60.269531 45.589844 56.796875 41.445312 C 53.324219 37.300781 49.828125 34.621094 49.828125 34.621094 C 49.265625 34.191406 48.574219 33.972656 47.867188 34.003906 Z M 48 41.175781 C 49.078125 42.089844 50.09375 42.792969 52.203125 45.304688 C 55.230469 48.910156 58 53.683594 58 58 C 58 61.507812 56.222656 64.566406 53.519531 66.347656 C 53.828125 65.625 54 64.832031 54 64 C 54 59.304688 49.882812 55.054688 49.414062 54.585938 C 48.632812 53.804688 47.367188 53.804688 46.585938 54.585938 C 46.117188 55.054688 42 59.304688 42 64 C 42 64.832031 42.171875 65.625 42.480469 66.347656 C 39.777344 64.566406 38 61.507812 38 58 C 38 53.683594 40.769531 48.910156 43.796875 45.304688 C 45.90625 42.792969 46.921875 42.089844 48 41.175781 Z M 48 41.175781 " />
      </g>
    </SvgIcon>
  );
};
