import React from 'react';

import { Typography } from '@mui/material';

import { Customer } from '@/types/customers';
import { getCustomerName } from '@/utils/customer';

export const CustomerInfo = ({ customer }: { customer: Customer }) => {
  return (
    <Typography
      variant="h6"
      sx={(theme) => ({
        marginBottom: theme.spacing(2),
      })}
    >
      {customer.id} - {getCustomerName(customer)}
    </Typography>
  );
};
