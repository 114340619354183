import { NumberedListItem } from '@/components/NumberedListItem';

import { CustomerProps } from '../../../../../types';
import { ListTitle } from '../../../commonStyles';
import { EmailTemplate } from '../../EmailTemplate';

export function Todo({
  companyName,
  emailAddresses,
  phoneNumbers,
  customer,
  customerEmailAddresses,
}: CustomerProps) {
  return (
    <>
      <ListTitle variant="h6">Att göra</ListTitle>

      <NumberedListItem
        number={1}
        text={`Ring UC och gör en förlustanmälan av ID-Handling. Nr: 090 - 010 120 30. `}
      />
      <NumberedListItem
        number={2}
        text={`Ring Bisnode och gör en förlustanmälan av ID-Handling. Nr: 08-519 013 60`}
      />
      <NumberedListItem
        number={3}
        text={
          <>
            {`Om du behöver lägga en bedrägerispärr, kan du göra det direkt via vårt system. Om knappen inte fungerar, ring oss på 08-519 013 60. \n`}

            <b>Om de ej svarar, testa följande:</b>
            {`
UC: 08-670 90 00
Bisnode: 08-558 059 00
Creditsafe: 031-725 50 00
Decidas: 031-730 67 00
Syna: 040-25 85 00
`}
            <b>Spärras det hos en av ovan så spärras det hos alla.</b>
          </>
        }
      />
      <NumberedListItem
        number={4}
        text={
          <>
            {`Har kund förlorat `}
            <b>Körkort:</b>
            {`
Maila nedan text till kund:

`}
            <EmailTemplate
              customerId={customer.id}
              emailAddresses={customerEmailAddresses}
              template={`Hej,

Följ länken nedan för att göra en förlustanmälan på ditt körkort och beställa ett nytt:
https://korkorttjanster.transportstyrelsen.se/ForlustAnmalan

Mvh,
Spärrlinjen hos ${companyName}
`}
            />
            {`
Har kund förlorat `}
            <b>ID-Kort:</b>
            {`
Vi behöver inte göra något annat än att förlustanmälan till Bisnode/UC, utan kund förlustanmäler själv till Skatteverket eller polisen (beroende på typ av Id-kort)

`}
            {`
Har kund förlorat `}
            <b>Pass:</b>
            {`
Vi behöver inte göra något utan kund sköter detta genom sin Polisanmälan som vi förklarat för kund.
`}
          </>
        }
      />
      <NumberedListItem
        number={5}
        text={
          <>
            {`Maila nedan text till ${emailAddresses?.admin}

`}
            <EmailTemplate
              customerId={customer.id}
              emailAddresses={customerEmailAddresses}
              template={`
Skicka folder gällande bestridan av fakturor samt brev som skall skickas till Bisnode tillsammans med Polisanmälan för längre spärr. Avser kund med personnummer ${customer.pin}`}
            />
          </>
        }
      />

      <NumberedListItem
        number={6}
        text={
          <>
            {`Ring kund och meddela vilka åtgärder vi genomfört och att de kan kontakta kundtjänst vid övriga frågor på `}
            <b>{phoneNumbers.customer}</b>
            {` eller `}
            <b>{emailAddresses?.customerService}</b>
          </>
        }
      />
    </>
  );
}
