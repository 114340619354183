import { SvgIcon, SvgIconProps } from '@mui/material';

export const AddCustomerIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 96 96" width="96px" height="96px">
      <g id="surface196304983">
        <path d="M 25 8 C 20.039062 8 16 12.039062 16 17 L 16 79 C 16 83.960938 20.039062 88 25 88 L 51.265625 88 C 49.554688 86.222656 48.101562 84.207031 46.949219 82 L 25 82 C 23.347656 82 22 80.652344 22 79 L 22 78 L 45.269531 78 C 44.652344 76.089844 44.257812 74.078125 44.101562 72 L 22 72 L 22 17 C 22 15.347656 23.347656 14 25 14 L 71 14 C 72.652344 14 74 15.347656 74 17 L 74 44.339844 C 76.089844 44.664062 78.105469 45.207031 80 45.996094 L 80 17 C 80 12.039062 75.960938 8 71 8 Z M 35 28 C 33.917969 27.984375 32.910156 28.554688 32.367188 29.488281 C 31.820312 30.421875 31.820312 31.578125 32.367188 32.511719 C 32.910156 33.445312 33.917969 34.015625 35 34 L 61 34 C 62.082031 34.015625 63.089844 33.445312 63.632812 32.511719 C 64.179688 31.578125 64.179688 30.421875 63.632812 29.488281 C 63.089844 28.554688 62.082031 27.984375 61 28 Z M 70 48 C 57.851562 48 48 57.851562 48 70 C 48 82.148438 57.851562 92 70 92 C 82.148438 92 92 82.148438 92 70 C 92 57.851562 82.148438 48 70 48 Z M 70 54 C 71.105469 54 72 54.894531 72 56 L 72 68 L 84 68 C 85.105469 68 86 68.894531 86 70 C 86 71.105469 85.105469 72 84 72 L 72 72 L 72 84 C 72 85.105469 71.105469 86 70 86 C 68.894531 86 68 85.105469 68 84 L 68 72 L 56 72 C 54.894531 72 54 71.105469 54 70 C 54 68.894531 54.894531 68 56 68 L 68 68 L 68 56 C 68 54.894531 68.894531 54 70 54 Z M 70 54 " />
      </g>
    </SvgIcon>
  );
};
