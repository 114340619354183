import { useQuery } from '@tanstack/react-query';

import { fetchUsers } from '@/api';
import { queryKeys } from '@/constants/queryKeys';
import { userRolesEnum } from '@/types/auth';
import { UserItem } from '@/types/users';

export const useGetBDMs = () => {
  return useQuery({
    queryKey: queryKeys.user.all,

    queryFn: async () => {
      const response = await fetchUsers({
        pagination: false,
      });
      const bdms = response['hydra:member'].filter((user) =>
        user?.roles?.some(
          (role) =>
            role === userRolesEnum.Values.ROLE_BUSINESS_DEVELOPMENT_MANAGER,
        ),
      );

      return bdms as Pick<
        UserItem,
        '@id' | 'id' | 'name' | 'username' | 'email' | 'roles' | 'deleted'
      >[];
    },
  });
};
