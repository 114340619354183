import { usePrivateSettings } from '@/hooks';

import { TaskTeamTypes } from '../constants';

export const usePrependTaskTypeToText = () => {
  const { getTypesOptionsForDropdownInput } = usePrivateSettings();

  const prependType = (type: string, text: string) => {
    const types = getTypesOptionsForDropdownInput('task', {
      taskTeam: TaskTeamTypes.ADMINISTRATOR,
    });

    const selectedType = types.find((t) => t.value === type);

    if (!selectedType) return text;

    if (text.startsWith(`${selectedType?.text}:`)) {
      return text;
    } else {
      // if the selected type is not the same as the type in the text
      // replace it with the selected type
      return `${selectedType?.text}: ${text.split(':')[1] || ''}`;
    }
  };

  return prependType;
};
