import React from 'react';

import {
  Button,
  InputAdornment,
  LinearProgress,
  TextField,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import { findCustomersToDelete } from '@/api/customers';
import { SimpleCard } from '@/components/SimpleCard';
import { SimpleTable } from '@/components/SimpleTable';
import { SearchIcon } from '@/icons';
import { CustomerToDeleteData } from '@/types/customers';

type Props = {
  onSelectCustomer: (customer: CustomerToDeleteData | null) => void;
};

export const CustomerSearch = ({ onSelectCustomer }: Props) => {
  const [customerSearchParam, setCustomerSearchParam] = React.useState('');
  const [searchFieldValue, setSearchFieldValue] = React.useState('');

  const { isError, data, isFetching } = useQuery({
    queryKey: ['customersToDelete', customerSearchParam],
    queryFn: () => findCustomersToDelete({ search: customerSearchParam }),
    enabled: customerSearchParam !== '',
  });

  function submitSearchQuery() {
    setCustomerSearchParam(searchFieldValue);
    onSelectCustomer(null);
  }

  return (
    <SimpleCard
      title="Kundborttagning"
      cardContent={
        <form
          onSubmit={(e) => {
            e.preventDefault();
            submitSearchQuery();
          }}
        >
          <Typography variant="h5">
            Ange <b>personnummer</b> eller <b>kundnummer</b>
          </Typography>

          <TextField
            id="customer-search"
            label="Sök kund"
            variant="outlined"
            fullWidth
            value={searchFieldValue}
            onChange={(event) => setSearchFieldValue(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />

          {isFetching && <LinearProgress />}

          {isError && <Typography color="error">Något gick fel</Typography>}

          {data && data.length > 0 && (
            <SimpleTable
              columns={[
                { label: 'Kundnr', renderer: (row) => row.id },
                { label: 'Namn', renderer: (row) => row.fullName },
                {
                  label: '',
                  renderer: (row) => (
                    <Button
                      color="primary"
                      variant="text"
                      onClick={() => onSelectCustomer(row)}
                    >
                      Gå vidare
                    </Button>
                  ),
                },
              ]}
              rows={data}
            />
          )}

          {data?.length === 0 && (
            <Typography variant="h5">Ingen kund hittades</Typography>
          )}
        </form>
      }
      cardActions={
        <Button color="primary" onClick={submitSearchQuery} variant="outlined">
          Sök
        </Button>
      }
    />
  );
};

CustomerSearch.propTypes = {
  onSelectCustomer: PropTypes.func.isRequired,
};
