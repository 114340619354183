import { getElementFromArray } from '@/helpers/getElementFromArray';

function resizeCard(column: HTMLDivElement) {
  let siblingCardHeight = 0;
  try {
    siblingCardHeight = getElementFromArray(
      column?.previousElementSibling?.children?.[0]?.getBoundingClientRect()
        .height,
    );
  } catch (e) {
    console.error(e);
  }

  if (!column || !column.firstChild) {
    return;
  }

  (column.firstChild! as HTMLElement).style.height = `${siblingCardHeight}px`;
  (column.firstChild! as HTMLElement).style.overflow = 'auto';
}

export function resizeAllCards() {
  const allItems = document.getElementsByClassName('overview-card-right');
  for (let x = 0; x < allItems.length; x++) {
    setTimeout(() => {
      resizeCard(allItems[x] as HTMLDivElement);
    }, 100);
  }
}
