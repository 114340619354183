import React from 'react';

import { AlertTitle, Grid, Typography } from '@mui/material';

import { Alert } from '@/components/Alert';
import { HydraViolationError } from '@/types/errors';

type Props = {
  serverErrors: HydraViolationError[] | null;
  isUpdate: boolean;
};
export const ServerErrorAlert = ({ serverErrors, isUpdate }: Props) => {
  const errorAlertRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (serverErrors && errorAlertRef.current) {
      errorAlertRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [errorAlertRef, serverErrors]);

  if (serverErrors) {
    return (
      <Grid item xs={12} ref={errorAlertRef}>
        <Alert severity="error">
          <AlertTitle>{`Kunde inte ${isUpdate ? 'uppdatera' : 'skapa'} kampanj.`}</AlertTitle>
          {serverErrors.map((error) => (
            <Typography key={error.code}>
              {`${error.propertyPath}: ${error.message}`}
            </Typography>
          ))}
        </Alert>
      </Grid>
    );
  }

  return null;
};
