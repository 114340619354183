import React from 'react';

import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { BasicCustomerData } from '@/types/customers';

import { StickerItem } from '../../types/dto';

type Props = {
  stickers: StickerItem[] | [];
  customer: BasicCustomerData;
};

// TODO: Add translations
export const StickersList = ({ stickers, customer }: Props) => {
  const headerText =
    stickers.length === 0 ? 'Kund: ' : 'Skyddsdekaler inlagda på kund ';

  return (
    <StyledList dense>
      <ListItem>
        <Typography variant="h5">
          {headerText}
          <b>
            {customer.companyCategory
              ? customer?.companyName
              : customer?.fullName}{' '}
            ({customer?.id})
          </b>
        </Typography>
      </ListItem>
      {stickers.map((keyTag, index) => (
        <React.Fragment key={keyTag.id}>
          <ListItem>
            <ListItemIcon sx={{ minWidth: 2 }}>
              <Typography variant="h5">{++index}:</Typography>
            </ListItemIcon>
            <Typography
              variant="h5"
              sx={{
                color: 'rgba(0, 0, 0, 0.54)',
              }}
            >
              {keyTag.code}
            </Typography>
          </ListItem>
          <Divider />
        </React.Fragment>
      ))}
    </StyledList>
  );
};

const StyledList = styled(List)({
  '& li': {
    paddingLeft: 0,
  },
});
