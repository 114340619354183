import { Draft, produce } from 'immer';

const DEFAULT_RULES = {
  accountManagerSystem: {
    view: false, //Aiew Account Manager Pages
    antiChurn: {
      view: false, //Account Manager Anti-Churn Page
    },
    customer: {
      transfer: false, //Customer Management Transfer
    },
    onboarding: {
      view: false, //Account manager onboarding
    },
    overview: {
      view: false, //Account Manager Overview Page
    },
    stats: {
      view: false,
      HOB2B: false, //Account manager HOB2B statistics
      salesManager: false, //Sales Manager Statistics
    },
    teams: {
      view: false, //Account manager teams page
      create: false,
      edit: false,
    },
    tools: {
      view: false, //Account Manager System Tools Page
    },
    upsale: {
      view: false,
    },
    winback: {
      view: false,
    },
  },
  alarms: {
    view: false, //Alarms Page
    active: false,
    handled: false,
  },
  admin: {
    menu: {
      view: false, //View success team pages
    },
    tasks: false, //View Success Team Task page
    tools: {
      addToSvea: false, //Add customer to svea
      sinneReplacementList: false,
      sinneReturnUnit: false,
      sinneTools: false, //View Success Team Smart Smoke Tools Tabs
    },
  },
  billing: {
    view: false,
    overview: false,
    billing: false,
    directInvoicing: false,
    exporting: false,
  },
  customers: {
    billing: false,
    costDelete: false, //Delete a customer cost
    debtCollection: false, //Manage customers without debt collection
    delete: false, //Delete Customer(s)
    edit: false, //Edit Customer(s)
    editPartnerCompany: false, //Edit partner company
    editPin: false, //Edit customer(s) pin
    generateLetters: false,
    noteDelete: false, //Delete notes
    overview: false,
    services: false,
    tasks: false,
    view: false, //Customer(s) page
  },
  economy: {
    view: false, //Economy page
    exporting: false, //Export Smart Smoke Device Economy Information
  },
  notes: {
    savedButton: false,
    lostButton: false,
  },
  partners: {
    view: false, //Partner portal
    user: false,
    payments: false,
    partners: false,
  },
  prices: {
    view: false,
    create: false,
  },
  protectedFiles: {
    download: false, //Download Protected Files
    view: false, //Protected Files Page
    upload: false, //Upload protected files
    validate: false,
    invalidate: false,
  },
  resellerPortal: {
    bdmStatistics: false, //Business developer manager(s) statistics
    manageAccounts: false, //View, Edit And Create Reseller Account(s)
    resellerSettings: false, //Edit Personal Reseller Settings
    resellerStatistics: false, //Reseller Statistics
    view: false, //reseller portal
  },
  rma: {
    view: {
      list: false, //RMA List
    },
  },
  securityService: {
    view: false, //Security Service Page
    editCustomer: false,
    barringService: false, //View Barring Service Page
    barringServiceTask: false, //Create Barring Service Task
    foundObject: false, //Barring Service Found Object Page
    hardLock: false,
  },
  services: {
    changeStatus: false, //Change service status
    churn: false, //Set service status to churn
    configuration: false, //Configure Service(s)
    customers: false, //Service lists
    editSalesInfo: false, //Edit service sales information
    export: false, //Export services
    exporting: false,
    hardDelete: false, //Delete service(s)
    keyfigures: false,
    lostButton: false,
    registration: false, //Register service(s)
    savedButton: false, //Save service button
    stats: false, //Service statistics
    transfer: false, //Service Transfer
    view: false, //View services pages
    order_files: {
      upload: false,
    },
    coProtection: {
      watchList: false, //Edit Bolagsbevakning watchlist
    },
    homegate: {
      configuration: false,
    },
    keyTag: {
      bulkRegistration: false, //Nyckelbricka bulk registration
    },
    sinne: {
      missingDevices: {
        view: false, //Missing Smart Smoke Devices List
      },
      lostDeviceFlow: false, //Smart Smoke Lost Device Flow
    },
  },
  support: {
    view: false, //Customer support menu
    stats: false, //Customer support statistics
    tasks: false, //Customer Support Tasks
    churn: false, //Churn Service(s)
    notes: false, //Support notes page
    verifyCustomer: false, //Verify Customer(s)
  },
  task: {
    claim: false, //Claim task
    release: false, //Release task
  },
  tools: {
    view: false, //View Tools Menu Options
    sendOuts: false, //Sendings
    addressWash: false, //Address Wash
    agreementManagement: false, //Agreement Management
    longFraudProtection: false,
    campaignBuilder: false, //Campaign builder
    fileUpload: false, //Upload file templates
  },
  users: {
    view: false, //View User(s) Page
    delete: false, //Delete user(s)
    create: false, //Create user(s)
    isTaggable: false,
    reactivate: false, //Reactivate user(s)
    updatePermissions: false, //Update user permissions
    changePin: false, //Update User(s) Pin
  },
  whiteLabels: {
    view: false,
    cost: false, //Delete white-label costs
    invoiceDocumentation: false,
  },
};

type Concat<K extends string, P extends string> = P extends ''
  ? K
  : `${P}.${K}`;

type NestedKeys<T, P extends string = ''> = {
  [K in keyof T]-?: T[K] extends object
    ? NestedKeys<T[K], Concat<K & string, P>>
    : Concat<K & string, P>;
}[keyof T];

type DefaultRules = typeof DEFAULT_RULES;
export type AuthRule = NestedKeys<DefaultRules>;

export const authorizationRules = {
  ROLE_ACCOUNT_MANAGER: produce(DEFAULT_RULES, (draft) => {
    draft.accountManagerSystem.view = true;
    draft.accountManagerSystem.antiChurn.view = true;
    draft.accountManagerSystem.overview.view = true;
    draft.accountManagerSystem.tools.view = true;

    draft.services.changeStatus = true;
    draft.services.customers = true;
    draft.services.editSalesInfo = true;
    draft.services.registration = true;
    draft.services.view = true;
    draft.services.churn = true;
    draft.services.coProtection.watchList = true;
    draft.services.savedButton = true;
    draft.services.lostButton = true;

    draft.customers.billing = true;
    draft.customers.costDelete = true;
    draft.customers.edit = true;
    draft.customers.generateLetters = true;
    draft.customers.noteDelete = true;
    draft.customers.overview = true;
    draft.customers.services = true;
    draft.customers.tasks = true;
    draft.customers.view = true;

    draft.support.notes = true;
  }),
  ROLE_BUSINESS_DEVELOPMENT_MANAGER: produce(DEFAULT_RULES, (draft) => {
    draft.resellerPortal.view = true;
    draft.resellerPortal.bdmStatistics = true;

    draft.resellerPortal.manageAccounts = true;

    draft.partners.view = true;
  }),
  ROLE_ECONOMY: produce(DEFAULT_RULES, (draft) => {
    draft.economy.view = true;
    draft.economy.exporting = true;
  }),
  ROLE_ADMIN_MANAGER: produce(DEFAULT_RULES, (draft) => {
    applyAdminRules(draft);
    draft.accountManagerSystem.antiChurn.view = true;
    draft.accountManagerSystem.customer.transfer = true;
    draft.accountManagerSystem.overview.view = true;
    draft.accountManagerSystem.tools.view = true;
    draft.accountManagerSystem.view = true;

    draft.customers.debtCollection = true;
    draft.customers.editPin = true;

    draft.notes.savedButton = true;
    draft.notes.lostButton = true;

    draft.prices.create = true;
    draft.prices.view = true;

    draft.services.hardDelete = true;

    draft.support.stats = true;
    draft.support.verifyCustomer = true;

    draft.tools.addressWash = true;
    draft.tools.campaignBuilder = true;
    draft.tools.fileUpload = true;

    draft.whiteLabels.cost = true;

    draft.users.view = true;
    draft.users.create = true;
  }),
  ROLE_ADMINISTRATOR: produce(DEFAULT_RULES, (draft) => {
    applyAdminRules(draft);
  }),
  ROLE_MONITORING_STATION: produce(DEFAULT_RULES, (draft) => {
    draft.alarms.active = true;
    draft.alarms.handled = true;
    draft.alarms.view = true;
  }),
  ROLE_CUSTOMER_SUPPORT_MANAGER: produce(DEFAULT_RULES, (draft) => {
    draft.customers.billing = true;
    draft.customers.debtCollection = true;
    draft.customers.generateLetters = true;
    draft.customers.overview = true;
    draft.customers.services = true;
    draft.customers.tasks = true;
    draft.customers.view = true;
    draft.customers.noteDelete = true;

    draft.notes.savedButton = true;
    draft.notes.lostButton = true;

    draft.services.changeStatus = true;
    draft.services.churn = true;
    draft.services.coProtection.watchList = true;
    draft.services.customers = true;
    draft.services.homegate.configuration = true;
    draft.services.keyfigures = true;
    draft.services.lostButton = true;
    draft.services.savedButton = true;
    draft.services.view = true;
    draft.services.sinne.missingDevices.view = true;
    draft.services.sinne.lostDeviceFlow = true;
    draft.services.order_files.upload = true;

    draft.support.churn = true;
    draft.support.notes = true;
    draft.support.stats = true;
    draft.support.tasks = true;
    draft.support.verifyCustomer = true;
    draft.support.view = true;

    draft.admin.menu.view = true;
    draft.admin.tasks = true;

    draft.users.isTaggable = true;

    draft.tools.longFraudProtection = true;
    draft.tools.view = true;
    draft.tools.agreementManagement = true;
    draft.tools.sendOuts = true;
    draft.tools.fileUpload = true;

    draft.securityService.barringService = true;
    draft.securityService.barringServiceTask = true;
    draft.securityService.foundObject = true;
    draft.securityService.view = true;
  }),
  ROLE_CUSTOMER_SUPPORT: produce(DEFAULT_RULES, (draft) => {
    draft.customers.billing = true;
    draft.customers.generateLetters = true;
    draft.customers.overview = true;
    draft.customers.services = true;
    draft.customers.tasks = true;
    draft.customers.view = true;

    draft.notes.savedButton = true;
    draft.notes.lostButton = true;

    draft.admin.menu.view = true;
    draft.admin.tasks = true;

    draft.services.changeStatus = true;
    draft.services.churn = true;
    draft.services.coProtection.watchList = true;
    draft.services.customers = true;
    draft.services.homegate.configuration = true;
    draft.services.keyfigures = true;
    draft.services.lostButton = true;
    draft.services.savedButton = true;
    draft.services.view = true;
    draft.services.sinne.missingDevices.view = true;
    draft.services.sinne.lostDeviceFlow = true;

    draft.protectedFiles.download = true;
    draft.protectedFiles.view = true;
    draft.protectedFiles.upload = true;

    draft.support.churn = true;
    draft.support.notes = true;
    draft.support.stats = true;
    draft.support.tasks = true;
    draft.support.verifyCustomer = true;
    draft.support.view = true;

    draft.users.isTaggable = true;

    draft.task.claim = true;
    draft.task.release = true;

    draft.tools.view = true;
    draft.tools.agreementManagement = true;
    draft.tools.sendOuts = true;
  }),
  ROLE_BLOCK_SUPPORT: produce(DEFAULT_RULES, (draft) => {
    draft.securityService.barringService = true;
    draft.securityService.barringServiceTask = true;
    draft.securityService.foundObject = true;
    draft.securityService.view = true;

    draft.support.verifyCustomer = true;
  }),
  ROLE_GODMODE: produce(DEFAULT_RULES, (draft) => {
    draft.accountManagerSystem.view = true;
    draft.accountManagerSystem.antiChurn.view = true;
    draft.accountManagerSystem.onboarding.view = true;
    draft.accountManagerSystem.stats.HOB2B = true;
    draft.accountManagerSystem.stats.salesManager = true;
    draft.accountManagerSystem.winback.view = true;
    draft.accountManagerSystem.upsale.view = true;
    draft.accountManagerSystem.overview.view = true;
    draft.accountManagerSystem.tools.view = true;
    draft.accountManagerSystem.customer.transfer = true;

    draft.accountManagerSystem.teams.view = true;
    draft.accountManagerSystem.teams.create = true;
    draft.accountManagerSystem.teams.edit = true;

    draft.admin.menu.view = true;
    draft.admin.tools.addToSvea = true;
    draft.admin.tools.sinneReplacementList = true;
    draft.admin.tools.sinneReturnUnit = true;
    draft.admin.tools.sinneTools = true;
    draft.admin.tasks = true;

    draft.alarms.active = true;
    draft.alarms.handled = true;
    draft.alarms.view = true;

    draft.customers.billing = true;
    draft.customers.costDelete = true;
    draft.customers.debtCollection = true;
    draft.customers.delete = true;
    draft.customers.edit = true;
    draft.customers.editPartnerCompany = true;
    draft.customers.editPin = true;
    draft.customers.generateLetters = true;
    draft.customers.noteDelete = true;
    draft.customers.overview = true;
    draft.customers.services = true;
    draft.customers.tasks = true;
    draft.customers.view = true;

    draft.economy.view = true;
    draft.economy.exporting = true;

    draft.notes.savedButton = true;
    draft.notes.lostButton = true;

    draft.partners.partners = true;
    draft.partners.payments = true;
    draft.partners.user = true;
    draft.partners.view = true;

    draft.prices.create = true;
    draft.prices.view = true;

    draft.protectedFiles.download = true;
    draft.protectedFiles.view = true;
    draft.protectedFiles.upload = true;
    draft.protectedFiles.validate = true;
    draft.protectedFiles.invalidate = true;

    draft.resellerPortal.view = true;
    draft.resellerPortal.manageAccounts = true;
    draft.resellerPortal.bdmStatistics = true;

    draft.rma.view.list = true;

    draft.securityService.barringService = true;
    draft.securityService.barringServiceTask = true;
    draft.securityService.editCustomer = true;
    draft.securityService.foundObject = true;
    draft.securityService.view = true;

    draft.services.changeStatus = true;
    draft.services.churn = true;
    draft.services.configuration = true;
    draft.services.coProtection.watchList = true;
    draft.services.customers = true;
    draft.services.editSalesInfo = true;
    draft.services.export = true;
    draft.services.hardDelete = true;
    draft.services.homegate.configuration = true;
    draft.services.keyfigures = true;
    draft.services.keyTag.bulkRegistration = true;
    draft.services.lostButton = true;
    draft.services.registration = true;
    draft.services.savedButton = true;
    draft.services.stats = true;
    draft.services.transfer = true;
    draft.services.view = true;
    draft.services.sinne.missingDevices.view = true;
    draft.services.sinne.lostDeviceFlow = true;
    draft.services.order_files.upload = true;

    draft.support.churn = true;
    draft.support.notes = true;
    draft.support.stats = true;
    draft.support.tasks = true;
    draft.support.verifyCustomer = true;
    draft.support.view = true;

    draft.task.claim = true;
    draft.task.release = true;

    draft.tools.addressWash = true;
    draft.tools.agreementManagement = true;
    draft.tools.campaignBuilder = true;
    draft.tools.fileUpload = true;
    draft.tools.longFraudProtection = true;
    draft.tools.sendOuts = true;
    draft.tools.view = true;

    draft.users.create = true;
    draft.users.delete = true;
    draft.users.isTaggable = true;
    draft.users.updatePermissions = true;
    draft.users.reactivate = true;
    draft.users.view = true;

    draft.users.changePin = true;

    draft.whiteLabels.cost = true;
    draft.whiteLabels.invoiceDocumentation = true;
    draft.whiteLabels.view = true;
  }),
  ROLE_HEAD_OF_B2B: produce(DEFAULT_RULES, (draft) => {
    draft.accountManagerSystem.view = true;
    draft.accountManagerSystem.antiChurn.view = true;
    draft.accountManagerSystem.tools.view = true;
    draft.accountManagerSystem.customer.transfer = true;
    draft.accountManagerSystem.teams.view = true;
    draft.accountManagerSystem.teams.create = true;
    draft.accountManagerSystem.teams.edit = true;
    draft.accountManagerSystem.stats.HOB2B = true;

    draft.services.coProtection.watchList = true;
    draft.services.savedButton = true;
    draft.services.lostButton = true;

    draft.customers.billing = true;
    draft.customers.costDelete = true;
    draft.customers.edit = true;
    draft.customers.generateLetters = true;
    draft.customers.noteDelete = true;
    draft.customers.overview = true;
    draft.customers.services = true;
    draft.customers.tasks = true;
    draft.customers.view = true;

    draft.support.notes = true;

    draft.users.create = true;
    draft.users.delete = true;
    draft.users.updatePermissions = true;
    draft.users.view = true;
  }),
  ROLE_OWNER: produce(DEFAULT_RULES, (draft) => {
    draft.accountManagerSystem.view = true;
    draft.accountManagerSystem.antiChurn.view = true;
    draft.accountManagerSystem.overview.view = true;
    draft.accountManagerSystem.tools.view = true;
    draft.accountManagerSystem.customer.transfer = true;

    draft.admin.menu.view = true;
    draft.admin.tools.addToSvea = true;
    draft.admin.tools.sinneReplacementList = true;
    draft.admin.tools.sinneReturnUnit = true;
    draft.admin.tools.sinneTools = true;
    draft.admin.tasks = true;

    draft.alarms.handled = true;
    draft.alarms.view = true;

    draft.customers.billing = true;
    draft.customers.debtCollection = true;
    draft.customers.delete = true;
    draft.customers.editPartnerCompany = true;
    draft.customers.generateLetters = true;
    draft.customers.overview = true;
    draft.customers.services = true;
    draft.customers.tasks = true;
    draft.customers.view = true;
    draft.customers.noteDelete = true;

    draft.economy.view = true;
    draft.economy.exporting = true;

    draft.notes.savedButton = true;
    draft.notes.lostButton = true;

    draft.prices.create = true;
    draft.prices.view = true;

    draft.partners.partners = true;
    draft.partners.payments = true;
    draft.partners.user = true;
    draft.partners.view = true;

    draft.resellerPortal.view = true;
    draft.resellerPortal.manageAccounts = true;

    draft.rma.view.list = true;

    draft.services.export = true;
    draft.services.configuration = true;
    draft.services.coProtection.watchList = true;
    draft.services.savedButton = true;
    draft.services.lostButton = true;
    draft.services.customers = true;
    draft.services.stats = true;
    draft.services.view = true;
    draft.services.homegate.configuration = true;
    draft.services.keyfigures = true;
    draft.services.keyTag.bulkRegistration = true;
    draft.services.sinne.missingDevices.view = true;
    draft.services.sinne.lostDeviceFlow = true;
    draft.services.order_files.upload = true;

    draft.support.churn = true;
    draft.support.notes = true;
    draft.support.stats = true;
    draft.support.tasks = true;
    draft.support.verifyCustomer = true;
    draft.support.view = true;

    draft.tools.addressWash = true;
    draft.tools.view = true;
    draft.tools.campaignBuilder = true;
    draft.tools.sendOuts = true;
    draft.tools.fileUpload = true;

    draft.users.delete = true;
    draft.users.isTaggable = true;
    draft.users.view = true;
    draft.users.create = true;
    draft.users.reactivate = true;
    draft.users.updatePermissions = true;

    draft.users.changePin = true;
  }),
  ROLE_RESELLER: produce(DEFAULT_RULES, (draft) => {
    draft.resellerPortal.resellerSettings = true;
    draft.resellerPortal.resellerStatistics = true;
    draft.resellerPortal.view = true;
  }),
  ROLE_SALES_MANAGER: produce(DEFAULT_RULES, (draft) => {
    draft.accountManagerSystem.view = true;
    draft.accountManagerSystem.antiChurn.view = true;
    draft.accountManagerSystem.overview.view = true;
    draft.accountManagerSystem.tools.view = true;
    draft.accountManagerSystem.stats.salesManager = true;

    draft.services.coProtection.watchList = true;
    draft.services.savedButton = true;
    draft.services.lostButton = true;

    draft.customers.billing = true;
    draft.customers.costDelete = true;
    draft.customers.edit = true;
    draft.customers.generateLetters = true;
    draft.customers.noteDelete = true;
    draft.customers.overview = true;
    draft.customers.services = true;
    draft.customers.tasks = true;
    draft.customers.view = true;

    draft.support.notes = true;
  }),
  ROLE_FRAUD_ASSISTANCE: produce(DEFAULT_RULES, (draft) => {
    draft.customers.billing = true;
    draft.customers.generateLetters = true;
    draft.customers.overview = true;
    draft.customers.services = true;
    draft.customers.tasks = true;
    draft.customers.view = true;

    draft.notes.lostButton = true;
    draft.notes.savedButton = true;

    draft.protectedFiles.download = true;
    draft.protectedFiles.view = true;
    draft.protectedFiles.upload = true;

    draft.securityService.barringService = true;
    draft.securityService.barringServiceTask = true;
    draft.securityService.foundObject = true;
    draft.securityService.hardLock = true;
    draft.securityService.view = true;

    draft.services.changeStatus = true;
    draft.services.churn = true;
    draft.services.coProtection.watchList = true;
    draft.services.customers = true;
    draft.services.homegate.configuration = true;
    draft.services.view = true;

    draft.support.churn = true;
    draft.support.notes = true;
    draft.support.stats = true;
    draft.support.tasks = true;
    draft.support.verifyCustomer = true;
    draft.support.view = true;

    draft.task.claim = true;
    draft.task.release = true;

    draft.tools.agreementManagement = true;
    draft.tools.sendOuts = true;
    draft.tools.view = true;

    draft.users.isTaggable = true;
  }),
};

function applyAdminRules(draft: Draft<DefaultRules>) {
  draft.alarms.active = true;
  draft.alarms.handled = true;
  draft.alarms.view = true;

  draft.admin.menu.view = true;
  draft.admin.tools.addToSvea = true;
  draft.admin.tools.sinneReplacementList = true;
  draft.admin.tools.sinneReturnUnit = true;
  draft.admin.tools.sinneTools = true;
  draft.admin.tasks = true;

  draft.services.order_files.upload = true;
  draft.services.coProtection.watchList = true;

  draft.protectedFiles.download = true;
  draft.protectedFiles.view = true;
  draft.protectedFiles.upload = true;

  draft.customers.billing = true;
  draft.customers.costDelete = true;
  draft.customers.edit = true;
  draft.customers.generateLetters = true;
  draft.customers.overview = true;
  draft.customers.services = true;
  draft.customers.tasks = true;
  draft.customers.view = true;
  draft.customers.noteDelete = true;

  draft.partners.partners = true;
  draft.partners.payments = true;
  draft.partners.user = true;

  draft.services.changeStatus = true;
  draft.services.configuration = true;
  draft.services.churn = true;
  draft.services.customers = true;
  draft.services.editSalesInfo = true;
  draft.services.registration = true;
  draft.services.stats = true;
  draft.services.transfer = true;
  draft.services.view = true;
  draft.services.homegate.configuration = true;
  draft.services.keyfigures = true;
  draft.services.keyTag.bulkRegistration = true;

  draft.support.churn = true;
  draft.support.notes = true;
  draft.support.tasks = true;
  draft.support.view = true;

  draft.task.claim = true;
  draft.task.release = true;

  draft.tools.agreementManagement = true;
  draft.tools.view = true;
  draft.tools.sendOuts = true;
  draft.users.isTaggable = true;
  draft.whiteLabels.view = true;
}
