import { ServiceName } from '@/types/services';

const getCreateServiceBreadcrumbs = (
  serviceName: ServiceName,
  mappedServiceName: string,
  customerId?: number | string,
) => [
  { label: 'Tjänster' },
  { label: mappedServiceName, url: `/products/${serviceName}` },
  { label: customerId ? 'Befintlig kund' : 'Ny kund' },
];

export default getCreateServiceBreadcrumbs;
