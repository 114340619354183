import { PageWrapper } from '@/components';
import { MissingDevicesList } from '@/modules/sinne/components/MissingDevices/MissingDevicesList';

const MissingSinneDevicesPage = () => {
  const breadCrumbs = [
    { label: 'Kundservice' },
    { label: `Ej returnerade enheter` },
  ];
  return (
    <PageWrapper
      maxWidth="md"
      pageTitle="Ej returnerade enheter"
      breadCrumbs={breadCrumbs}
      children={<MissingDevicesList />}
    />
  );
};

export default MissingSinneDevicesPage;
