import { useQuery } from '@tanstack/react-query';

import { getServiceDetails } from '@/api/services';
import { queryKeys } from '@/constants/queryKeys';
import { KebabCaseServiceNames } from '@/types/settings';

type Args = {
  serviceName: KebabCaseServiceNames;
  serviceId: string;
};

export const useFetchServiceDetails = ({ serviceName, serviceId }: Args) => {
  return useQuery({
    queryKey: queryKeys.fetchServiceDetails({
      serviceName,
      serviceId,
    }),
    queryFn: () =>
      getServiceDetails({
        serviceName: serviceName!,
        serviceId: parseInt(serviceId!, 10),
      }),
    enabled: !!serviceName && !!serviceId,
  });
};
