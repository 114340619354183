import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Form } from 'react-final-form';

import { sendBulkSms, sendCoProtectionActivationSms } from '@/api/sms';
import { RadioButtons, TextInput } from '@/components/form';
import { queryKeys } from '@/constants/queryKeys';
import { MAX_SMS_LENGTH } from '@/constants/sms';
import { useSnackbar } from '@/hooks';
import { useModal } from '@/hooks/useModal';
import usePublicSettings from '@/hooks/usePublicSettings';
import Yup, { makeValidation } from '@/utils/validation';

const typeOptions = [
  { value: 'oneWay', label: '1-vägs' },
  { value: 'twoWay', label: '2-vägs' },
];

type Props = {
  modal: {
    extra: {
      customerId: string;
      phoneNumbers: string[];
      isCoProtection: boolean;
      serviceId: string;
      modalTitle: string;
      messageText: string;
      disable2WayOption: boolean;
    };
  };
};

export const SmsCustomerModal = (props: Props) => {
  const { closeModal } = useModal();
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();

  const { publicSettings } = usePublicSettings();
  const companyName = publicSettings?.companyNames?.standard;

  const mutationFunction = props?.modal?.extra?.isCoProtection
    ? sendCoProtectionActivationSms
    : sendBulkSms;

  const mutation = useMutation({
    // @ts-expect-error mutationFunction types mismatch
    mutationFn: mutationFunction,
    onSuccess: () => {
      snackbar({ type: 'success', message: 'SMS skickat' });

      queryClient.refetchQueries({
        queryKey: [queryKeys.getNotes],
      });

      closeModal();
    },
    onError: () => {
      snackbar({
        type: 'error',
        message: 'Det gick inte att skicka SMS',
      });
    },
  });

  async function onSubmit({
    message,
    type,
  }: {
    message: string;
    type: string;
  }) {
    if (props?.modal?.extra?.isCoProtection) {
      mutation.mutate({
        message,
        coProtectionId: props?.modal?.extra?.serviceId,
      });
    } else {
      mutation.mutate({
        message,
        // @ts-ignore
        isTwoWay: type !== 'oneWay',
        recipients: props?.modal?.extra?.phoneNumbers
          ? props?.modal?.extra?.phoneNumbers
          : [props?.modal?.extra?.customerId],
        listType: props?.modal?.extra?.phoneNumbers ? 'phone' : 'id',
      });
    }
  }

  const validationSchema = Yup.object({
    message: Yup.string()
      .required()
      .max(
        MAX_SMS_LENGTH,
        `Meddelandet får inte vara längre än ${MAX_SMS_LENGTH} tecken.`,
      ),
  });

  const validate = makeValidation(validationSchema);

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={{
        type: 'oneWay',
        message: props?.modal?.extra?.messageText
          ? props?.modal?.extra?.messageText
          : `Hej,


Vänligen,
${companyName}`,
      }}
      render={({ handleSubmit, values }) => (
        <StyledDialog onClose={() => closeModal()} open>
          <form onSubmit={handleSubmit}>
            <DialogTitle>
              {props?.modal?.extra?.modalTitle
                ? props?.modal?.extra?.modalTitle
                : 'SMS till kunds mobiltelefon'}
            </DialogTitle>

            <DialogContent>
              {!props?.modal?.extra?.disable2WayOption && (
                <RadioButtons name="type" data={typeOptions} />
              )}

              <TextInput multiline label="Meddelande" name="message" rows={8} />

              {values.message.length >= 0 && (
                <CharsLeftFormHelperText>
                  {MAX_SMS_LENGTH - values.message.length} tecken kvar
                </CharsLeftFormHelperText>
              )}
            </DialogContent>
            <DialogActions>
              <StyledSubmitButton
                color="primary"
                variant="outlined"
                onClick={handleSubmit}
                disabled={mutation.isPending}
              >
                Skicka
              </StyledSubmitButton>
            </DialogActions>
          </form>
        </StyledDialog>
      )}
    />
  );
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: theme.breakpoints.values.sm,
  },
}));

const StyledSubmitButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(2, 0, 0, 0),
}));

const CharsLeftFormHelperText = styled(FormHelperText)(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));
