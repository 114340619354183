import uniq from 'lodash/uniq';

import { CreateCustomerPayload } from '@/types/customers';

import { getElementFromArray } from './getElementFromArray';

const validSveaChars =
  `!"#$%&'()*+,-./0123456789:;<=>?@ABCDEFGHIJKLMNOPQRSTUVWXYZ[]^ \`abcdefghijklmnopqrstuvwxyz{|}~ÀÁÄÅÆÈÉÊËÏÖØÙÚÛÜßàáäåæèéêëöøùúûü`.split(
    '',
  );

function getFieldChars(
  field: string,
  replacementRegex: RegExp,
  replacementConfig: { [key: string]: string },
) {
  return field
    .replace(replacementRegex, (c) => getElementFromArray(replacementConfig[c]))
    .split('');
}

function getFieldCharsDiff(fieldChars: string[]) {
  return uniq(fieldChars.filter((char) => !validSveaChars.includes(char)));
}

export function getFieldsWithIllegalSveaCharacters(
  values: CreateCustomerPayload,
  replacementConfig: { [key: string]: string },
) {
  if (!replacementConfig || !values) {
    return [];
  }

  const replacementRegex = new RegExp(
    Object.keys(replacementConfig).join('|'),
    'gi',
  );

  let errorFields = [];

  if (values.category === 2 && values.companyName) {
    const companyNameChars = getFieldChars(
      values.companyName,
      replacementRegex,
      replacementConfig,
    );

    const companyNameCharsDiff = getFieldCharsDiff(companyNameChars);

    if (companyNameCharsDiff.length > 0) {
      errorFields.push({
        fieldName: 'companyName',
        wrongChars: companyNameCharsDiff,
      });
    }
  } else {
    const firstNameChars = getFieldChars(
      values.firstName,
      replacementRegex,
      replacementConfig,
    );
    const lastNameChars = getFieldChars(
      values.lastName,
      replacementRegex,
      replacementConfig,
    );

    const firstNameCharsDiff = getFieldCharsDiff(firstNameChars);
    const lastNameCharsDiff = getFieldCharsDiff(lastNameChars);

    if (firstNameCharsDiff.length > 0) {
      errorFields.push({
        fieldName: 'firstName',
        wrongChars: firstNameCharsDiff,
      });
    }

    if (lastNameCharsDiff.length > 0) {
      errorFields.push({
        fieldName: 'lastName',
        wrongChars: lastNameCharsDiff,
      });
    }
  }

  return errorFields;
}
