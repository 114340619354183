import React, { useEffect, useState } from 'react';

import { Grid, LinearProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import camelCase from 'lodash/camelCase';
import { Navigate, useLocation, useParams } from 'react-router-dom';

import { getServiceDetails } from '@/api';
import { getCustomerById } from '@/api/customers';
import { BasicAlert } from '@/components/BasicAlert';
import { Breadcrumbs } from '@/components/Breadcrumbs';
import { Can } from '@/components/Can';
import { CardTable } from '@/components/CardTable';
import { MobileTable } from '@/components/MobileTable';
import { PageWithDrawer } from '@/components/PageWithDrawer';
import { queryKeys } from '@/constants/queryKeys';
import { getObjectKeys } from '@/helpers/tsHelpers';
import { usePrivateSettings } from '@/hooks';
import useUserRoles from '@/hooks/useUserRoles';
import {
  AssistantTable,
  BarringServiceToolbar,
  BlockedTable,
  ChecklistTable,
  CustomerInfo,
  FraudProtectionModal,
} from '@/modules/barringService/components';
import {
  BarringServiceCustomer,
  CallerInfo,
} from '@/modules/barringService/types';
import { CommunicationDrawer } from '@/modules/common/components';
import { CreateTaskModal } from '@/modules/tasks';
import {
  CamelCaseServiceName,
  CoProtectionParamSchema,
  ProtectionParamSchema,
} from '@/types/services';

const BarringService = () => {
  const { productType, productId } = useParams();
  const { servicesNames } = usePrivateSettings();
  const userRoles = useUserRoles();
  const location = useLocation();

  const [isFraudModalOpen, setIsFraudModalOpen] = useState(false);
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);

  const callerPin = location?.state?.callerPin;
  const [callerInfo, setCallerInfo] = useState<CallerInfo | null>(null);

  const {
    data: service,
    isPending: loadingService,
    isError: serviceError,
  } = useQuery({
    queryKey: [`get${productType}`, parseInt(productId!, 10)],

    queryFn: async () => {
      if (!productType) {
        throw new Error('Service name missing in params');
      }

      if (!productId) {
        throw new Error('ProductID missing in params');
      }

      const serviceName = camelCase(productType) as
        | 'protection'
        | 'coProtection';
      const serviceId = parseInt(productId, 10);

      const service = await getServiceDetails({
        serviceName,
        serviceId,
      });

      return service as ProtectionParamSchema | CoProtectionParamSchema;
    },

    enabled: !!callerPin,
  });

  const customerId = service?.customer?.id;

  const {
    data: customer,
    isPending: loadingCustomer,
    isError: customerError,
  } = useQuery({
    queryKey: [queryKeys.getCustomerById, customerId!],
    queryFn: () => getCustomerById<BarringServiceCustomer>(customerId!),
    enabled: !!customerId,
  });

  useEffect(() => {
    if (service && callerPin) {
      const index =
        getObjectKeys(service)
          ?.find((v) => service[v] === callerPin)
          ?.match(/\d/g) ?? '';

      setCallerInfo({
        data: {
          // @ts-expect-error - string can't be used to index service
          pin: service[`pin${index}`],
          // @ts-expect-error - string can't be used to index service
          name: service[`name${index}`],
          // @ts-expect-error - string can't be used to index service
          phone: service[`phone${index}`],
          // @ts-expect-error - string can't be used to index service
          email: service[`email${index}`],
        },
        disabledFields: ['pin'],
        index,
      });
    }
  }, [callerPin, service]);

  if (!callerPin) return <Navigate to="/security-service" />;

  if (loadingService || loadingCustomer || !callerInfo || !service) {
    return <LinearProgress />;
  }

  if (serviceError || customerError) return <BasicAlert />;

  return (
    <PageWithDrawer
      pageContent={
        <>
          <Breadcrumbs
            crumbs={[
              { label: 'Spärrlinje', url: '/security-service' },
              { label: 'Spärrservice' },
              {
                label:
                  servicesNames[camelCase(productType) as CamelCaseServiceName],
              },
            ]}
          />

          <BarringServiceToolbar
            service={service}
            handleFraudBlockModal={() => setIsFraudModalOpen(true)}
            handleTaskModal={() => setIsTaskModalOpen(true)}
          />

          <Grid container spacing={3}>
            <CustomerInfo customer={service.customer} callerPin={callerPin} />
            <AssistantTable service={service} callerInfo={callerInfo} />
            <Grid item xs={12}>
              <Can
                userRoles={userRoles}
                action="securityService.editCustomer"
                yes={() => (
                  <MobileTable
                    productType={service['@type']}
                    productIri={service['@id']}
                    callerPin={callerPin}
                    callerName={callerInfo.data.name}
                  />
                )}
                no={() => (
                  <MobileTable
                    productType={service['@type']}
                    productIri={service['@id']}
                    editDisabled
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Can
                userRoles={userRoles}
                action="securityService.editCustomer"
                yes={() => (
                  <CardTable
                    productType={service['@type']}
                    productIri={service['@id']}
                    callerPin={callerPin}
                    callerName={callerInfo.data.name}
                  />
                )}
                no={() => (
                  <CardTable
                    productType={service['@type']}
                    productIri={service['@id']}
                    editDisabled
                  />
                )}
              />
            </Grid>
            {customer && (
              <ChecklistTable customer={customer} service={service} />
            )}
            <BlockedTable />
          </Grid>

          {isFraudModalOpen && (
            <FraudProtectionModal
              open={isFraudModalOpen}
              callerInfo={callerInfo}
              handleCloseModal={() => setIsFraudModalOpen(false)}
              service={service}
            />
          )}

          {customer && (
            <CreateTaskModal
              customer={customer}
              open={isTaskModalOpen}
              handleVisibility={() => setIsTaskModalOpen(!isTaskModalOpen)}
              isBarringService
            />
          )}
        </>
      }
      drawerContent={
        <CommunicationDrawer
          customerId={service.customer.id}
          isInSecurityService
        />
      }
    />
  );
};

export default BarringService;
