import { Container, Typography } from '@mui/material';

export const ErrorFallback = (props: { error: unknown; eventId: string }) => {
  const sentryError = props.error as Error;

  return (
    <Container maxWidth="lg">
      <Typography variant="h1" style={{ marginBottom: '1rem' }}>
        Något gick fel! 🙁
      </Typography>
      <Typography variant="h2" style={{ marginBottom: '1rem' }}>
        Vi jobbar på att lösa det så fort som möjligt 🤓
      </Typography>

      <details style={{ whiteSpace: 'pre-wrap' }}>
        <h1>{sentryError && sentryError.toString()}</h1>
        <br />
        <Typography variant="h3">Spara id't och skicka det till IT.</Typography>
        <Typography variant="h4">ERROR-ID:</Typography>
        <Typography variant="h4">{props.eventId}</Typography>
        <br />
      </details>
    </Container>
  );
};
