import React from 'react';

import { Button, Grid, TextField, Typography } from '@mui/material';
import { FormApi } from 'final-form';

import { SimpleCard } from '@/components/SimpleCard';
import { ExcelRowInput } from '@/components/form/ExcelRowInput';
import { CustomerCategoryTypes } from '@/constants';
import { getObjectKeys } from '@/helpers/tsHelpers';
import { CustomerInformationSection } from '@/modules/services/components/sections';
import { SalesInformationFields } from '@/modules/services/components/sections/SalesInformationSection/Sinne/SalesInformationFields';
import { SinneTemplate } from '@/types/campaigns';
import {
  CreateSinneExcelValues,
  CreateSinneFormValues,
} from '@/types/services';

type Props = {
  isExistingCustomer: boolean;
  form: FormApi<CreateSinneFormValues>;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  customerData: any;
  push: (name: string, value: SinneTemplate) => void;
  values: any;
};
export const FormFields = ({
  isExistingCustomer,
  form,
  handleSubmit,
  customerData,
  push,
  values,
}: Props) => {
  const [valuesFromExcel, setValuesFromExcel] = React.useState<
    CreateSinneExcelValues | undefined
  >();

  const serviceName = 'sinne';
  const { change } = form;

  React.useEffect(() => {
    if (valuesFromExcel) {
      getObjectKeys(valuesFromExcel).forEach((key) => {
        change(key, valuesFromExcel[key]);
      });
    }
  }, [valuesFromExcel, change]);

  return (
    <>
      {!isExistingCustomer && (
        <SimpleCard
          title="Data från Excel"
          cardContent={
            <ExcelRowInput<CreateSinneExcelValues>
              serviceName={serviceName}
              setPastedData={setValuesFromExcel}
            />
          }
        />
      )}

      <form onSubmit={handleSubmit} id="sinneForm">
        <SimpleCard
          title={
            isExistingCustomer
              ? customerData?.category === CustomerCategoryTypes.COMPANY
                ? customerData?.companyName
                : customerData?.fullName
              : 'Kundregistrering'
          }
          cardContent={
            <Grid container spacing={3}>
              {isExistingCustomer ? (
                <Grid item>
                  <TextField
                    margin="none"
                    value={customerData.pin}
                    label="PIN"
                    variant="outlined"
                    disabled
                  />
                </Grid>
              ) : (
                <>
                  <Grid item xs>
                    <Typography variant="h6">Kundinformation</Typography>
                    <CustomerInformationSection
                      valuesFromExcel={valuesFromExcel}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          }
        />

        <section>
          <SalesInformationFields
            values={values}
            push={push}
            existingCustomerCategory={customerData?.category}
          />
        </section>

        <Button
          data-testid="submit-btn"
          color="primary"
          variant="contained"
          type="submit"
        >
          Spara
        </Button>
      </form>
    </>
  );
};
