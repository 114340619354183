import React from 'react';

import {
  Alert,
  Card,
  CardHeader,
  Divider,
  List,
  ListItem,
  Skeleton,
} from '@mui/material';

import { getObjectKeys } from '@/helpers/tsHelpers';
import { FlatSinneDeviceInfo } from '@/types/services';

import { ListItemText } from './ListItemText';

type Props = {
  deviceInfo: FlatSinneDeviceInfo | undefined;
  deviceInfoError: boolean;
};

const deviceInfoTranslation = {
  deviceManufactureDate: 'Tillverkningsdatum',
  imei: 'IMEI',
  mac: 'MAC',
  operator: 'Operatör',
  MSISDN: 'MSISDN',
  IMSI: 'IMSI',
  ICC: 'ICC',
  deviceType: 'Enhetstyp',
  lastTemperature: 'Senaste temperatur',
  signalLevel: 'Signalstyrka',
  batteryLevel: 'Batterinivå',
  connected: 'Ansluten',
  syncedOn: 'Senaste synkning',
  firmwareVersion: 'Firmware version',
  sinneEntityId: 'Sinne Entity ID',
};

export const DeviceInformation = ({ deviceInfo, deviceInfoError }: Props) => {
  if (!deviceInfo)
    return <Skeleton height={508} sx={{ transform: 'scale(1,1)' }} />;

  const { sim, ...deviceInfoToDisplay } = deviceInfo;
  return (
    <Card>
      <CardHeader title="Enhetsinformation" />
      <Divider />

      {deviceInfoError && (
        <Alert severity="error">
          Ett fel uppstod när vi hämtade information om enheten.
        </Alert>
      )}

      <List dense disablePadding>
        {getObjectKeys(deviceInfoToDisplay).map((key) => (
          <React.Fragment key={key}>
            <ListItem>
              <ListItemText
                primary={deviceInfoTranslation[key]}
                secondary={deviceInfoToDisplay[key]?.toString()}
              />
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>
    </Card>
  );
};
