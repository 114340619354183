import { useQuery } from '@tanstack/react-query';

import { getResellerServices } from '@/api/reseller';
import { queryKeys } from '@/constants/queryKeys';
import useUser from '@/hooks/useUser';
import { userRolesEnum } from '@/types/auth';

export const useGetResellersEnabledServices = (id: number) => {
  const { roles } = useUser();
  return useQuery({
    queryFn: () => getResellerServices(id),
    queryKey: queryKeys.reseller.enabledServices(id),
    enabled: roles.includes(userRolesEnum.Values.ROLE_RESELLER),
  });
};
