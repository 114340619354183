import { PageWrapper } from '@/components';
import { CreatePartnerForm } from '@/modules/PartnerPortal';

const CreatePartnerAccount = () => {
  return (
    <PageWrapper
      pageTitle="Partnerkonton"
      breadCrumbs={[
        { label: 'Partners', url: '/partner/accounts' },
        { label: 'Konton' },
        { label: 'Skapa' },
      ]}
      maxWidth="md"
    >
      <CreatePartnerForm />
    </PageWrapper>
  );
};

export default CreatePartnerAccount;
