import React from 'react';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button, IconButton, LinearProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import camelCase from 'lodash/camelCase';
import snakeCase from 'lodash/snakeCase';

import { Alert } from '@/components/Alert';
import { SimpleCard } from '@/components/SimpleCard';
import { SimpleTable, SimpleTableColumn } from '@/components/SimpleTable';
import { queryKeys } from '@/constants/queryKeys';
import { RegisteredCardsAndMobilesModal } from '@/modules/services/components';
import { PascalCasedServiceNames } from '@/types/services';
import httpClient from '@/utils/httpClient';

// TODO: Refactor the accepted format of productType here and where this component is used
// also we should stick to calling it serviceName
type Props = {
  productType: PascalCasedServiceNames;
  productIri: string;
  editDisabled?: boolean;
  callerPin?: string;
  callerName?: string | null;
  marginBottom?: string;
};

export const MobileTable = ({
  productType,
  productIri,
  editDisabled,
  callerPin,
  callerName,
  marginBottom,
}: Props) => {
  const [modalProps, setModalProps] = React.useState({});
  const [modalOpen, setModalOpen] = React.useState(false);

  let extra: {
    '@type'?: string;
    params: {
      [key: string]: string;
    };
  };

  if (productType === 'Protection') {
    extra = {
      '@type': 'RegisteredMobile',
      params: {
        protection: productIri,
      },
    };
  } else {
    extra = {
      '@type': 'CoRegisteredMobile',
      params: { coProtection: productIri },
    };
  }

  const {
    data: mobiles,
    isLoading,
    isError,
  } = useQuery({
    queryKey: [queryKeys.getMobiles, productIri],
    queryFn: async () =>
      await httpClient
        .get(snakeCase(extra['@type']) + 's', {
          params: {
            pagination: false,
            [camelCase(productType)]: productIri,
          },
        })
        .then((res) => res.data['hydra:member']),
  });

  const columns: SimpleTableColumn[] = [
    { label: 'Namn', renderer: (row) => row.fullName },
    { label: 'Personnummer', renderer: (row) => row.pin },
    { label: 'IMEI', renderer: (row) => row.imei },
    { label: 'Mobil', renderer: (row) => row.msisdn },
    { label: 'Operatör', renderer: (row) => row.operator },
    ...(editDisabled
      ? []
      : [
          {
            label: 'Ändra',
            renderer: (row: any) => (
              <IconButton
                onClick={() => {
                  const { fullName, imei, msisdn, operator, pin } = row;
                  setModalProps({
                    productIri,
                    registerType: 'Mobil',
                    extra: {
                      ...extra,
                      '@id': row['@id'],
                    },
                    initialValues: {
                      fullName,
                      imei,
                      msisdn,
                      operator,
                      pin,
                    },
                  });
                  setModalOpen(true);
                }}
                size="large"
              >
                <ArrowForwardIcon />
              </IconButton>
            ),
            align: 'center' as const,
          },
        ]),
  ];

  return (
    <>
      <SimpleCard
        title={`Mobiler (${mobiles?.length || 0})`}
        marginBottom={marginBottom}
        cardContent={
          isLoading ? (
            <LinearProgress />
          ) : isError ? (
            <Alert>Något gick fel</Alert>
          ) : (
            <SimpleTable columns={columns} rows={mobiles} />
          )
        }
        cardActions={
          <>
            {editDisabled ? null : (
              <Button
                color="primary"
                variant="outlined"
                onClick={() => {
                  setModalProps({
                    extra,
                    create: true,
                    productIri,
                    registerType: 'Mobil',
                    ...(callerPin &&
                      callerName && {
                        initialValues: {
                          pin: callerPin,
                          fullName: callerName,
                        },
                      }),
                  });
                  setModalOpen(true);
                }}
              >
                Lägg till mobil
              </Button>
            )}
          </>
        }
      />
      {modalOpen && (
        <RegisteredCardsAndMobilesModal
          {...modalProps}
          open={modalOpen}
          onClose={() => setModalOpen(!modalOpen)}
        />
      )}
    </>
  );
};
