import { CampaignModalProps } from '../CampaignModal';
import { HomegateCampaignForm } from '../components/Homegate/HomegateCampaignForm';
import { ProtectionCampaignFormWrapper } from '../components/Protection/ProtectionCampaignFormWrapper';
import { SinneCampaignForm } from '../components/Sinne/SinneCampaignForm';

export const renderCampaignForm = (props: CampaignModalProps) => {
  switch (props.selectedCampaignType) {
    case 'protections_campaigns':
      return <ProtectionCampaignFormWrapper {...props} />;
    case 'homegate_campaigns':
      return <HomegateCampaignForm {...props} />;
    case 'sinne_campaigns':
      return <SinneCampaignForm {...props} />;
    default:
      return null;
  }
};
