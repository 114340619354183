import { useMutation } from '@tanstack/react-query';

import { updateReseller } from '@/api';
import { GenericHttpError } from '@/types/errors';
import { ResellerItem, UpdateResellerParams } from '@/types/resellers';

export const useUpdateReseller = () => {
  return useMutation<ResellerItem, GenericHttpError, UpdateResellerParams>({
    mutationFn: (values) => {
      const { businessDevelopmentManager, ...rest } = values;
      if (businessDevelopmentManager === '') {
        return updateReseller({ ...rest, businessDevelopmentManager: null });
      }
      return updateReseller(values);
    },
  });
};
