import pick from 'lodash/pick';

import { PartnerResponse } from '@/types/partnerCompany';

import { tickFieldNames } from './getTickFields';

export const getFormattedTickFieldValues = (partner: PartnerResponse) => {
  const tickValues = pick(partner, tickFieldNames);

  return tickFieldNames.reduce((formattedFields, fieldName) => {
    const value = tickValues[fieldName];
    if (value) {
      const formattedValue = parseFloat(value).toFixed(2);
      return { ...formattedFields, [fieldName]: formattedValue };
    }
    return formattedFields;
  }, {});
};
