import { useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import { queryKeys } from '@/constants/queryKeys';
import { FilterValues } from '@/modules/tasks/types/filters';
import { TasksResponse } from '@/types/tasks';
import httpClient from '@/utils/httpClient';

import { TaskTeamType } from '../types/team';

type Params = {
  filter: FilterValues;
  isWinback: boolean;
  page: number;
  perPage: number;
  team: TaskTeamType;
  sortKey: string | null;
  sortDirection: 'asc' | 'desc' | null;
};

export const useTasks = ({
  filter,
  isWinback,
  page,
  perPage,
  team,
  sortKey,
  sortDirection,
}: Params) => {
  const [total, setTotal] = useState(0);

  const {
    data: tasks,
    isFetching: loading,
    isError: error,
  } = useQuery({
    queryKey: [
      queryKeys.getTasks,
      filter,
      isWinback,
      page,
      perPage,
      team,
      sortKey,
      sortDirection,
    ],
    queryFn: async () => {
      const { deadline, type, search, claimedBy } = filter;
      const status = isWinback ? [5] : filter.status;

      const params: Record<string, any> = {
        pagination: true,
        search: search && search.length > 0 ? search : undefined,
        status,
        type,
        team: [team],
        perPage,
        page: page + 1,
        claimedBy,
        'exists[deadline]': deadline,
      };

      if (sortKey && sortDirection) {
        params[`order[${sortKey}]`] = sortDirection;
      } else {
        params[`order[deadline]`] = 'asc';
      }

      const { data } = await httpClient.get<TasksResponse>(`/tasks`, {
        params,
      });
      setTotal(data['hydra:totalItems']);
      return data['hydra:member'];
    },
  });

  return {
    tasks,
    total,
    loading,
    error,
  };
};
