import React, { useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useQuery } from '@tanstack/react-query';

import { getCustomerById } from '@/api/customers';
import { getFiretextById } from '@/api/firetext';
import { queryKeys } from '@/constants/queryKeys';
import { usePrivateSettings } from '@/hooks';

import { Step1, Step2, Step3, Step4, Step5 } from './steps';

type Props = {
  serviceId: number;
  handleCloseModal: () => void;
};

export const ConfigureFiretextModal = ({
  serviceId,
  handleCloseModal,
}: Props) => {
  const { servicesNames } = usePrivateSettings();

  const { data: service } = useQuery({
    queryKey: [queryKeys.getFiretextById, serviceId],

    queryFn: async () => {
      return await getFiretextById(serviceId);
    },
  });

  const customerId = service?.customerId;
  const {
    data: customer,
    isInitialLoading: isLoading,
    isError,
    isSuccess,
  } = useQuery({
    queryKey: [queryKeys.getCustomerById, customerId],

    queryFn: async () => {
      return await getCustomerById(customerId!);
    },

    enabled: !!customerId,
  });

  const [step, setStep] = useState(1);

  const goToNextStep = () => {
    setStep(step + 1);
  };

  const goBack = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const stepTitles = {
    1: `Steg 1 - Konfigurera ${servicesNames?.firetext}`,
    2: `Steg 2 - Skicka konfigurations-SMS`,
    3: 'Steg 3 - Förbered posten',
    4: 'Steg 4 - Filer',
    5: 'Steg 5 - Testanordning',
  } as const;

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <Step1
            goToNextStep={goToNextStep}
            service={service!}
            customer={customer!}
          />
        );
      case 2:
        return (
          <Step2
            goToNextStep={goToNextStep}
            goBack={goBack}
            service={service!}
            customer={customer!}
          />
        );
      case 3:
        return (
          <Step3
            goToNextStep={goToNextStep}
            goBack={goBack}
            service={service!}
            customer={customer!}
          />
        );
      case 4:
        return (
          <Step4
            goToNextStep={goToNextStep}
            goBack={goBack}
            service={service!}
            customer={customer!}
          />
        );
      case 5:
        return (
          <Step5
            service={service!}
            customer={customer!}
            onClose={handleCloseModal}
            queryKey={[queryKeys.getFiretextById, serviceId]}
          />
        );
      default:
        return null;
    }
  };

  return (
    <DialogContainer open={true} onClose={handleCloseModal}>
      <StyledDialogTitle>
        <Typography component="span" variant="h5">
          {stepTitles[step as keyof typeof stepTitles]}
        </Typography>
        <CloseButton onClick={handleCloseModal} size="large">
          <CloseIcon />
        </CloseButton>
      </StyledDialogTitle>
      <Divider />
      {isError && (
        <Typography variant="h3" component="h1" color="error">
          Kunde inte hämta data. Kontrollera internetanslutningen och försök
          igen.
        </Typography>
      )}
      {isLoading && <LinearProgress />}
      {isSuccess && renderStep()}
    </DialogContainer>
  );
};

const DialogContainer = styled(Dialog)(() => ({
  '& .MuiDialog-paper': {
    minHeight: 640,
    width: 480,
  },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  margin: 0,
  padding: theme.spacing(2, 3),
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
  padding: theme.spacing(1),
  color: theme.palette.text.primary,
}));
