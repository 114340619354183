import React, { useState } from 'react';

import { Box, styled, useMediaQuery, useTheme } from '@mui/material';

import { Sidebar, Topbar } from './components';

const MainWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'shiftContent',
})<{ shiftContent: boolean }>(({ theme, shiftContent }) => ({
  backgroundColor: theme.palette.background.default,
  paddingTop: 56,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('sm')]: {
    paddingTop: 64,
  },
  paddingLeft: shiftContent ? 240 : 0,
}));

export const MainLayout = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const shouldOpenSidebar = isDesktop ? true : openSidebar;

  return (
    <MainWrapper shiftContent={isDesktop}>
      <Topbar onSidebarOpen={handleSidebarOpen} />
      <Sidebar
        onClose={handleSidebarClose}
        open={shouldOpenSidebar}
        variant={isDesktop ? 'persistent' : 'temporary'}
      />
      <Box p={3}>{children}</Box>
    </MainWrapper>
  );
};
