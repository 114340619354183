import { Button, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import truncate from 'lodash/truncate';
import { useNavigate } from 'react-router-dom';

import { getFormattedDate } from '@/helpers/dates';
import { USER_TYPES } from '@/modules/common/constants/entitiyNamesMap';
import { getInfoAboutAgreements } from '@/modules/services';
import { SinneItem } from '@/types/services';
import { ServiceName } from '@/types/services';
import { ServiceStatuses } from '@/types/settings';

type SinneTableConfigProps = {
  serviceName: ServiceName;
  servicesStatuses: ServiceStatuses;
  isReplacementFlow?: boolean;
};

const StyledAgreementRow = styled('div')<{ isSuccess?: boolean }>`
  color: ${({ theme, isSuccess }) =>
    isSuccess ? theme.palette.success.main : theme.palette.error.main};
`;
const StyledRegretRow = styled('div')<{ isSuccess?: boolean }>`
  color: ${({ theme, isSuccess }) =>
    isSuccess ? theme.palette.success.main : theme.palette.error.main};
`;

export const useSinneTableConfig = ({
  serviceName,
  servicesStatuses,
  isReplacementFlow = false,
}: SinneTableConfigProps) => {
  const navigate = useNavigate();

  const agreementColumn = {
    label: 'Avtal',
    order: 1,
    renderer: (row: SinneItem) => {
      const agreements = getInfoAboutAgreements(row.agreements);

      return (
        <div>
          {agreements.audio ? (
            <StyledAgreementRow isSuccess>Ljudfil</StyledAgreementRow>
          ) : (
            <StyledAgreementRow>Ljudfil</StyledAgreementRow>
          )}
          /
          {agreements.document ? (
            <StyledAgreementRow isSuccess>Skriftligt</StyledAgreementRow>
          ) : (
            <StyledAgreementRow>Skriftligt</StyledAgreementRow>
          )}
        </div>
      );
    },
  };

  const statusColumn = {
    label: 'Status',
    order: 5,
    renderer: (row: SinneItem) => {
      if (serviceName === 'sinne') {
        return servicesStatuses?.[serviceName][row.status];
      }
    },
  };

  const sinneColumns = [
    {
      label: 'Personnr',
      order: 3,
      renderer: (row: SinneItem) => row.customer?.pin,
    },
    statusColumn,
    {
      label: 'Konfigurera',
      order: 8,
      align: 'center',
      renderer: (row: SinneItem) => {
        const configFlowUrl = `/products/sinne/${row.id}/configure`;
        const replacemenFlowUrl = `/products/sinne/${row.id}/configure?isReplacementFlow=true`;
        const urlToNavigateTo = isReplacementFlow
          ? replacemenFlowUrl
          : configFlowUrl;
        return (
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              navigate(urlToNavigateTo);
            }}
          >
            Konfigurera
          </Button>
        );
      },
    },
  ];

  const commonColumns = [
    {
      label: 'Kund-ID',
      order: 4,
      renderer: (row: SinneItem) => row.customer?.id,
    },
    {
      label: 'Orderdatum',
      order: 6,
      renderer: (row: SinneItem) => getFormattedDate(row.regDate, 'yyyy-MM-dd'),
    },
    {
      label: `Info till ${USER_TYPES.admin}`,
      order: 7,
      renderer: (row: SinneItem) => {
        return (
          <Tooltip
            title={<Typography variant="body1">{row.adminNote}</Typography>}
          >
            <Typography>
              {truncate(row.adminNote as string, {
                length: 90,
              })}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      label: 'Ångerrätt',
      order: 2,
      renderer: (row: SinneItem) => {
        return (
          <div>
            {row.regretTimeExpired ? (
              <StyledRegretRow isSuccess>Skicka</StyledRegretRow>
            ) : (
              <StyledRegretRow>Skicka ej</StyledRegretRow>
            )}
          </div>
        );
      },
    },
  ];

  const columnMap = {
    sinne: [agreementColumn, ...sinneColumns, ...commonColumns],
  };
  if (serviceName === 'sinne') {
    return columnMap[serviceName].sort((a, b) => a.order - b.order);
  }
};
