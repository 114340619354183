import React from 'react';

import { Alert, AlertTitle, Typography } from '@mui/material';

import { getTemplateComponentForType } from './helpers/getTemplateForTaskType';

type Props = {
  type: string;
};
export const TaskTypeTemplateAlert = ({ type }: Props) => {
  return (
    <Alert
      severity="info"
      sx={{ my: 1 }}
      children={
        <>
          <AlertTitle sx={{ mb: 1 }}>
            <Typography variant="h5">
              Information som ska anges för vald kategori
            </Typography>
          </AlertTitle>
          {getTemplateComponentForType(type)}
        </>
      }
    />
  );
};
