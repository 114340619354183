import { PageWrapper } from '@/components';
import { Tabs } from '@/components/Tabs/Tabs';
import {
  AllAccountManagers,
  HeadOfB2BOnboardingStatistics,
  TeamList,
} from '@/modules/accountManagerSystem/components/HOB2B';

const HOB2BStatistics = () => {
  const tabMap = [
    { title: 'Account manager', view: () => <AllAccountManagers /> },
    { title: 'Teams', view: () => <TeamList /> },
    { title: 'Onboarding', view: () => <HeadOfB2BOnboardingStatistics /> },
  ];

  return (
    <PageWrapper
      pageTitle="Kundhantering"
      breadCrumbs={[
        { label: 'Account Manager System' },
        { label: 'HOB2B' },
        { label: 'Statistik' },
      ]}
    >
      <Tabs tabs={tabMap} />
    </PageWrapper>
  );
};

export default HOB2BStatistics;
