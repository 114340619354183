import React, { useEffect, useState } from 'react';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useQuery } from '@tanstack/react-query';
import { formatDuration, intervalToDuration } from 'date-fns';
import { sv } from 'date-fns/locale';
import { upperFirst } from 'lodash';

import { pollHandleableAlarms } from '@/api/alarms';
import { BasicAlert } from '@/components/BasicAlert';
import { LabelBadge } from '@/components/LabelBadge';
import { getFormattedDate } from '@/helpers/dates';
import { usePrivateSettings } from '@/hooks';
import { HandleableAlarmListItem } from '@/types/alarms';

import { prepareUpdatedAlarmsList } from '../utils';
import { AlarmsTable } from './AlarmsTable';

export const ActiveAlarmsView = () => {
  const { servicesNames, servicesStatuses, sinneEvent } = usePrivateSettings();
  const [timestamp, setTimestamp] = useState('');
  const [alarms, setAlarms] = useState<Record<number, HandleableAlarmListItem>>(
    {},
  );
  const [normalizedAlarms, setNormalizedAlarms] = useState<
    HandleableAlarmListItem[]
  >([]);

  useEffect(() => {
    const normalizedAlarms = Object.values(alarms)
      .sort((a, b) => {
        const aDate = new Date(a.occuredAt);
        const bDate = new Date(b.occuredAt);

        return aDate.getTime() - bDate.getTime();
      })
      .map((alarm) => ({
        ...alarm,
        showWarning: alarm.status === 1,
      }));

    setNormalizedAlarms(normalizedAlarms);
  }, [alarms]);

  const { isError, isPending } = useQuery({
    queryKey: ['activeAlarms'],

    queryFn: async () => {
      const data = await pollHandleableAlarms({ timestamp });
      setTimestamp(data.timestamp);

      const newAlarmList = prepareUpdatedAlarmsList(data, alarms);
      setAlarms(newAlarmList);
      return data;
    },

    refetchInterval: 5000,
    refetchIntervalInBackground: true,
  });

  const activeAlarmsColumns: Array<{
    label: string;
    renderer: (row: HandleableAlarmListItem) => React.ReactNode;
  }> = [
    {
      label: 'Enhetstyp',
      renderer: (row) => {
        return <span>{row && servicesNames[row.unitType]}</span>;
      },
    },
    {
      label: 'Larmstatus',
      renderer: (row) => (
        <LabelBadge type="warning">
          {servicesStatuses.handleableAlarm.statuses[row.status]}
        </LabelBadge>
      ),
    },
    {
      label: 'Larmtyp',
      renderer: (row) => {
        if (row.unitType === 'firetext') {
          return <span>{'Rökutveckling'}</span>;
        } else {
          return <span>{sinneEvent && sinneEvent[row.alarmOrigin]}</span>;
        }
      },
    },
    {
      label: 'Aktiv Alarmtid',
      renderer: (row) => {
        const duration = upperFirst(
          formatDuration(
            intervalToDuration({
              start: new Date(row.occuredAt),
              end: new Date(),
            }),
            { locale: sv },
          ),
        );

        return <span>{duration}</span>;
      },
    },
    {
      label: 'Inkommen',
      renderer: (row) =>
        row.occuredAt
          ? getFormattedDate(row.occuredAt, 'yyyy-MM-dd HH:mm:ss')
          : null,
    },
    {
      label: 'Antal händelser',
      renderer: (row) => (
        <>
          <CustomBadge>
            {row.unitType === 'sinne' ? row.sinneEvents.length : 1}
          </CustomBadge>
        </>
      ),
    },
    {
      label: 'Hanteras av',
      renderer: (row) => row?.handledBy?.name,
    },
    {
      label: 'Hantera',
      renderer: (row) => (
        <IconButton size="large">
          <ArrowForwardIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <>
      {isError ? (
        <BasicAlert />
      ) : (
        <AlarmsTable
          noPagination
          columns={activeAlarmsColumns}
          rows={normalizedAlarms}
          loading={isPending}
        />
      )}
    </>
  );
};

const CustomBadge = styled('p')`
  border: ${({ theme }) => `12px solid ${theme.palette.error.main}`};
  border-radius: 50%;
  width: 0.5rem;
  display: inline-flex;
  height: 0.5rem;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.palette.error.contrastText};
  font-weight: bold;
`;
