import { useAtom } from 'jotai';

import { darkModeAtom } from '@/state/darkModeAtom';

export const useDarkMode = () => {
  const [darkModeState, setDarkModeState] = useAtom(darkModeAtom);

  const toggleDarkMode = () => {
    setDarkModeState((prev) => ({
      ...prev,
      paletteType: prev.paletteType === 'light' ? 'dark' : 'light',
      darkMode: !prev.darkMode,
    }));
  };

  return { darkModeState, toggleDarkMode };
};
