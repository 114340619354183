import React, { Fragment } from 'react';

import { Box, ListItem, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

type NumberedListItemProps = {
  number: number;
  text: string | React.ReactNode;
  isLast?: boolean;
  children?: JSX.Element;
};

export const NumberedListItem = ({
  number,
  text,
  children,
  isLast,
  ...props
}: NumberedListItemProps) => {
  return (
    <Fragment>
      <StyledListItem>
        <StyledNumber variant="h6">{number + '.'}</StyledNumber>
        <StyledText variant="body1">{text}</StyledText>
      </StyledListItem>
      {children && <StyledBox>{children}</StyledBox>}
    </Fragment>
  );
};

const StyledNumber = styled(Typography)({
  marginRight: '5px',
});

const StyledText = styled(Typography)({
  whiteSpace: 'break-spaces',
  component: 'div',
});

const StyledBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  padding: '0px',
  marginBottom: theme.spacing(4),
}));
