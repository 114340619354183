import React from 'react';

import {
  Box,
  Button,
  LinearProgress,
  TextField,
  Typography,
} from '@mui/material';
import { add, format, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { usePrivateSettings } from '@/hooks/usePrivateSettings';
import { KEY_TAG_AND_STICKER_CODE_VALIDATION_REGEX } from '@/modules/services/constants';
import { BasicCustomerData } from '@/types/customers';
import { CoProtectionItem } from '@/types/services';

import { useCreateSticker } from '../../api-hooks/useCreateSticker';
import { useFetchCoProtections } from '../../api-hooks/useFetchCoProtections';
import { useGetStickerPricePlanIds } from '../../hooks/useGetStickerPricePlanIds';
import { StickerItem } from '../../types/dto';
import { getStickerConfigStep2ErrorMessage } from '../../utils/getStickerConfigStep2ErrorMessage';

type Props = {
  onGoBack: () => void;
  onFinish: (createdStickers: StickerItem[]) => void;
  customer: BasicCustomerData;
};

type CoProtection = Pick<CoProtectionItem, 'type' | 'regDate'>;

export const Step2CoProtection = ({ onGoBack, onFinish, customer }: Props) => {
  const { t } = useTranslation();
  const fetchCoProtections = useFetchCoProtections();
  const createSticker = useCreateSticker();
  const { getPricePlanId } = useGetStickerPricePlanIds();

  const [errorMessage, setErrorMessage] = React.useState('');
  const [customerCoProtection, setCustomerCoProtection] =
    React.useState<CoProtection | null>(null);
  const [stickersCreated, setStickersCreated] = React.useState<StickerItem[]>(
    [],
  );

  const { servicesNames } = usePrivateSettings();

  //#region Effects
  React.useEffect(() => {
    if (stickersCreated.length > 0) {
      onFinish(stickersCreated);
    }
  }, [stickersCreated]);

  React.useEffect(() => {
    if (customer?.id) {
      checkCustomerStickers(customer.id);
    }
  }, [customer]);
  //#endregion

  //#region Async functions
  async function checkCustomerStickers(customerId: number | string) {
    fetchCoProtections.mutate(
      { customerId },
      {
        onSuccess: (data) => {
          setCustomerCoProtection(data[0] ?? null);
          setErrorMessage('');
        },
        onError: () => {
          // TODO: Add translations
          setErrorMessage(t('apiErrors.generic'));
        },
      },
    );
  }

  async function createStickerHandler(code: string) {
    const { regDate } = customerCoProtection!;

    const billingMonth = parseInt(
      format(add(parseISO(regDate), { months: 1 }), 'M'),
      10,
    );

    const payload = {
      code,
      regDate,
      billingMonth,
      status: 1,
      billing: 0,
      kamId: 0,
      tb: 0,
      pricePlanId: getPricePlanId(10),
      customer: `/customers/${customer.id}`,
    };

    createSticker.mutate(payload, {
      onSuccess: (data) => {
        setStickersCreated((prevState) => [...prevState, data]);
      },
      onError: (error: any) => {
        const message = getStickerConfigStep2ErrorMessage(error);
        setErrorMessage(message);
      },
    });
  }
  //#endregion

  //#region Functions
  function handleSubmit(e: React.SyntheticEvent) {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      code: { value: string };
    };
    const {
      code: { value },
    } = target;

    if (value) {
      if (KEY_TAG_AND_STICKER_CODE_VALIDATION_REGEX.test(value)) {
        setErrorMessage('');
        createStickerHandler(value);
      } else {
        setErrorMessage('Fel kodformat');
      }
    }
  }
  //#endregion

  // TODO: Add translations
  return (
    <form onSubmit={handleSubmit} noValidate autoComplete="off">
      <Typography gutterBottom variant="h5">
        Steg 2: Skanna välkomstbrev
      </Typography>

      <Typography gutterBottom>
        Kund:{' '}
        {customer.companyCategory ? customer.companyName : customer.fullName}
      </Typography>

      {!customerCoProtection ? (
        <Typography>Ingen aktivt {servicesNames.coProtection}</Typography>
      ) : (
        <>
          <TextField
            autoFocus
            label="Serienummer"
            name="code"
            margin="normal"
            size="small"
            variant="outlined"
            fullWidth
          />

          {errorMessage && (
            <Typography gutterBottom color="error">
              {errorMessage}
            </Typography>
          )}
        </>
      )}

      <Box display="flex" justifyContent="space-between" marginTop={2}>
        <Button
          type="button"
          color="primary"
          size="small"
          variant="contained"
          onClick={onGoBack}
        >
          Gå tillbaka
        </Button>

        <Button
          type="submit"
          color="primary"
          size="small"
          variant="contained"
          disabled={fetchCoProtections.isPending}
        >
          Nästa
        </Button>
      </Box>

      {fetchCoProtections.isPending && <LinearProgress />}
    </form>
  );
};
