import { z } from 'zod';

export type LoginPayload = {
  username: string;
  password: string;
};

export type ChangePasswordPayload = {
  oldPassword: string;
  newPassword: string;
};

export type LoginCheckResponse = {
  token: string;
};

export const userRolesEnum = z.enum([
  'ROLE_ACCOUNT_MANAGER',
  'ROLE_ADMIN_MANAGER',
  'ROLE_ADMINISTRATOR',
  'ROLE_BUSINESS_DEVELOPMENT_MANAGER',
  'ROLE_BLOCK_SUPPORT',
  'ROLE_CUSTOMER_SUPPORT_MANAGER',
  'ROLE_CUSTOMER_SUPPORT',
  'ROLE_ECONOMY',
  'ROLE_FRAUD_ASSISTANCE',
  'ROLE_GODMODE',
  'ROLE_HEAD_OF_B2B',
  'ROLE_MONITORING_STATION',
  'ROLE_OWNER',
  'ROLE_RESELLER',
  'ROLE_SALES_MANAGER',
]);

export type UserRole = z.infer<typeof userRolesEnum>;

export interface ManagedCustomersArr {
  firetext: string;
  protection: string;
  'co-protection': string;
  sticker: string;
  'key-tag': string;
  homegate: string;
  sinne: string;
  totalARR: string;
  meanARR: string;
}

export const authenticatedUserSchema = z.object({
  id: z.number(),
  email: z.string().email(),
  level: z.number(),
  name: z.string(),
  roles: z.array(userRolesEnum),
  username: z.string(),
  pin: z.string().nullable(),
});

export type AuthenticatedUser = z.infer<typeof authenticatedUserSchema>;

export const jwtTokenSchema = z.object({
  iat: z.number(),
  exp: z.number(),
  roles: z.array(userRolesEnum),
  username: z.string(),
  id: z.number(),
});

export type JwtToken = z.infer<typeof jwtTokenSchema>;
