import { Box } from '@mui/material';

import { SignInForm } from '@/modules/auth';

const SignIn = () => {
  return (
    <Box>
      <SignInForm />
    </Box>
  );
};

export default SignIn;
