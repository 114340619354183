import React from 'react';

import { Button, Grid, TextField, Typography } from '@mui/material';
import { FormApi } from 'final-form';

import { SimpleCard } from '@/components/SimpleCard';
import { ExcelRowInput } from '@/components/form/ExcelRowInput';
import { CustomerCategoryTypes } from '@/constants';
import { getObjectKeys } from '@/helpers/tsHelpers';
import { CreateHomegateFormValues } from '@/modules/services/components/Create/Homegate/CreateHomegateForm';
import {
  CustomerBankInformationSection,
  CustomerInformationSection,
} from '@/modules/services/components/sections';
import { SalesInformationFields } from '@/modules/services/components/sections/SalesInformationSection/Homegate/SalesInformationFields';
import { SinneTemplate } from '@/types/campaigns';

type Props = {
  isExistingCustomer: boolean;
  form: FormApi<CreateHomegateFormValues>;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  customerData: any;
  push: (name: string, value: SinneTemplate) => void;
  values: any;
};

export const FormFields = ({
  isExistingCustomer,
  form,
  handleSubmit,
  customerData,
  push,
  values,
}: Props) => {
  const [valuesFromExcel, setValuesFromExcel] =
    React.useState<CreateHomegateFormValues>();
  const serviceName = 'homegate';

  React.useEffect(() => {
    if (valuesFromExcel) {
      getObjectKeys(valuesFromExcel).forEach((key) => {
        form.change(key, valuesFromExcel[key]);
      });
    }
  }, [valuesFromExcel]);

  return (
    <>
      {!isExistingCustomer && (
        <SimpleCard
          title="Data från Excel"
          cardContent={
            <ExcelRowInput<CreateHomegateFormValues>
              serviceName={serviceName}
              setPastedData={setValuesFromExcel}
            />
          }
        />
      )}
      <form onSubmit={handleSubmit} id="homegate">
        <SimpleCard
          title={
            isExistingCustomer
              ? customerData?.category === CustomerCategoryTypes.COMPANY
                ? customerData?.companyName
                : customerData?.fullName
              : 'Kundregistrering'
          }
          cardContent={
            <Grid container spacing={3}>
              {isExistingCustomer ? (
                <Grid item>
                  <TextField
                    margin="none"
                    value={customerData.pin}
                    label="PIN"
                    variant="outlined"
                    disabled
                  />
                </Grid>
              ) : (
                <>
                  <Grid item xs>
                    <Typography variant="h6">Kundinformation</Typography>
                    <CustomerInformationSection />
                  </Grid>
                  <Grid item xs>
                    <Typography variant="h6">Autogiro</Typography>
                    <CustomerBankInformationSection />
                  </Grid>
                </>
              )}
            </Grid>
          }
        />

        <section>
          <SalesInformationFields
            values={values}
            push={push}
            existingCustomerCategory={customerData?.category}
          />
        </section>

        <Button color="primary" variant="contained" type="submit">
          Spara
        </Button>
      </form>
    </>
  );
};
