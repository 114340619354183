import {
  Button,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
} from '@mui/material';
import { formatISO } from 'date-fns';
import { Form } from 'react-final-form';

import { DatePicker, Dropdown, TextInput } from '@/components/form';

export type AddFeeFormValues = {
  description: string;
  invoiceAt: string;
  price: string;
  title: string;
};

type Props = {
  onSubmit: (values: AddFeeFormValues) => void;
};

export const AddFee = ({ onSubmit }: Props) => {
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ invoiceAt: formatISO(new Date()) }}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <CardHeader title="Lägg till avgift" />
          <Divider />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <TextInput margin="none" required label="Namn" name="title" />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextInput
                  margin="none"
                  required
                  label="Beskrivning"
                  name="description"
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextInput
                  margin="none"
                  required
                  type="number"
                  label="Avgift (exkl moms)"
                  name="price"
                />
              </Grid>
              <Grid item sm={6} xs={12} sx={{ '& > *': { margin: 0 } }}>
                <DatePicker
                  label="Avgiftsdatum"
                  name="invoiceAt"
                  showClearButton
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <Button color="primary" type="submit" variant="outlined">
              Skicka avgift
            </Button>
          </CardActions>
        </form>
      )}
    />
  );
};
