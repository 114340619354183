import React, { createContext } from 'react';

import { useInterpret } from '@xstate/react';
import { InterpreterFrom } from 'xstate';

import { discardFlowMachine } from './discardFlowMachine';

export const DiscardStateContext = createContext(
  {} as InterpreterFrom<typeof discardFlowMachine>,
);

export const DiscardStateProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const discardFlowService = useInterpret(discardFlowMachine, {
    devTools: true,
  }).start();

  return (
    <DiscardStateContext.Provider value={discardFlowService}>
      {children}
    </DiscardStateContext.Provider>
  );
};
