import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  colors,
} from '@mui/material';
import { ArcElement, DoughnutController, Tooltip } from 'chart.js';
import { ChartProps, ReactChart } from 'chartjs-react';
import round from 'lodash/round';

import { LoadingSpinner } from '@/components/LoadingSpinner';
import { SupportRetetionStatistics } from '@/types/stats';

ReactChart.register(DoughnutController, ArcElement, Tooltip);

const options: ChartProps['options'] = {
  animation: false,
  layout: { padding: 0 },
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    tooltip: {
      displayColors: false,
    },
  },
};

type Props = {
  loading: boolean;
  data:
    | {
        totalLost: number;
        totalSaved: number;
        total: number;
      }
    | undefined;
};

const Doughnut = ({ loading, data }: Props) => {
  if (!data) {
    return null;
  }

  const chartData = {
    labels: ['Förlorade', 'Räddade'],
    datasets: [
      {
        label: '',
        data: [data.totalLost, data.totalSaved],
        backgroundColor: [colors.red[600], colors.green[700]],
      },
    ],
  };

  if (loading) {
    return <LoadingSpinner size={220} thickness={2.5} />;
  }

  return (
    <Box>
      <ReactChart data={chartData} options={options} type="doughnut" />
    </Box>
  );
};

type DegreeOfRescueProps = {
  loading: boolean;
  data: SupportRetetionStatistics | undefined;
};

export const DegreeOfRescue = ({ loading, data }: DegreeOfRescueProps) => {
  if (!data) return null;

  const doughnutData = Object.values(data).reduce(
    (acc, { totalLost, totalSaved }) => {
      return {
        totalLost: acc.totalLost + totalLost,
        totalSaved: acc.totalSaved + totalSaved,
        total: acc.total + totalLost + totalSaved,
      };
    },
    { totalLost: 0, totalSaved: 0, total: 0 },
  );

  return (
    <Card>
      <CardHeader title="Räddningsgrad" />
      <Divider />
      <CardContent sx={{ px: 0 }}>
        {doughnutData.total > 0 ? (
          <>
            <Doughnut loading={loading} data={doughnutData} />
            <Box display="flex" justifyContent="space-around">
              <Typography variant="h5" color={colors.green[700]}>
                {doughnutData.total
                  ? `${round(
                      (doughnutData.totalSaved / doughnutData.total) * 100,
                      2,
                    )}%`
                  : null}
              </Typography>

              <Typography variant="h5" color={colors.red[600]}>
                {doughnutData.total
                  ? `${round(
                      (doughnutData.totalLost / doughnutData.total) * 100,
                      2,
                    )}%`
                  : null}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="space-around">
              <div>
                <Typography variant="h6">Räddade</Typography>
                <Typography variant="h6">
                  {doughnutData.totalSaved} st
                </Typography>
              </div>
              <div>
                <Typography variant="h6">Förlorade</Typography>
                <Typography variant="h6">
                  {doughnutData.totalLost} st
                </Typography>
              </div>
            </Box>
          </>
        ) : (
          <Box
            sx={{
              height: 300,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography variant="h4" color="gray">
              Ingen data tillgänglig
            </Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};
