import React from 'react';

import { TextField, TextFieldProps } from '@mui/material';

import { formatPinInput } from '@/helpers/parsing';

type Meta = {
  dirty: boolean;
  valid: boolean;
  invalid: boolean;
  submitFailed: boolean;
  error: any;
};

type Input = {
  name: string;
  value: string;
  onChange: (e: object) => void;
};

type Props = TextFieldProps & {
  input: Input;
  meta: Meta;
  isOrgNr: boolean;
};

export const PinTextFieldAdapter = ({
  input,
  meta,
  label,
  isOrgNr,
  ...rest
}: Props) => {
  const getFormattedValue = (value: string, isOrgNr: boolean) => {
    if (value) {
      return formatPinInput(value, isOrgNr);
    } else {
      return '';
    }
  };

  const showError = (meta: Meta) => {
    if (meta.dirty && meta.invalid) {
      return true;
    }

    if (meta.submitFailed && meta.invalid) {
      return true;
    }

    if (meta.valid) {
      return false;
    }
  };

  return (
    <TextField
      name={input.name}
      label={label}
      value={getFormattedValue(input.value, isOrgNr)}
      onChange={input.onChange}
      error={showError(meta)}
      helperText={
        ((meta.dirty && meta.invalid) || meta.submitFailed) &&
        meta.error &&
        meta?.error[0]
      }
      {...rest}
    />
  );
};
