import { atomWithStorage } from 'jotai/utils';

import { PrivateSettings } from '@/types/settings';

export const privateSettingsAtom = atomWithStorage<PrivateSettings | null>(
  'privateSettings',
  null,
  undefined,
  {
    getOnInit: true,
  },
);
