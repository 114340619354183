import { HomegateResponse } from '@/types/services';
import httpClient from '@/utils/httpClient';

type GetHomegatesToConfigureProps = {
  page: number;
  perPage: number;
  search: string;
};

export async function getHomegatesToConfigure({
  page,
  perPage,
  search,
}: GetHomegatesToConfigureProps) {
  const properties = {
    agreements: {
      id: true,
      fileType: true,
      name: true,
    },
    customer: {
      id: true,
      pin: true,
      fullName: true,
      companyName: true,
      category: true,
      company: true,
      city: true,
      address: true,
      address2: true,
      zip: true,
      email: true,
      mobile: true,
    },
    type: true,
    id: true,
    status: true,
    hasRegretTimeExpired: true,
    campaign: true,
    antiregret: true,
    adminNote: true,
    reseller: {
      id: true,
      name: true,
    },
    salesRep: true,
    regDate: true,
    pricePlanId: true,
  };

  const { data } = await httpClient.get<
    HomegateResponse<keyof typeof properties>
  >('homegates', {
    params: {
      page,
      perPage,
      search: search !== '' ? search : undefined,
      pagination: true,
      status: [6],
      billing: false,
      order: {
        regDate: 'asc',
      },
      properties,
    },
  });

  return data;
}

export async function getHomegateById(id: string) {
  const { data } = await httpClient.get<HomegateResponse>(id);

  return data;
}

export type GetHomegatesParams = {
  customerPin: string;
  page?: number;
  perPage?: number;
  churns: any;
  sortOrder: 'asc' | 'desc';
};

export async function getHomegates({
  customerPin,
  page = 1,
  perPage = 100,
  churns,
  sortOrder,
}: GetHomegatesParams) {
  const properties = {
    id: true,
    churnDate: true,
    status: true,
    customer: {
      id: true,
      fullName: true,
      companyName: true,
      pin: true,
    },
  };

  const { data } = await httpClient.get<
    HomegateResponse<keyof typeof properties>
  >('/homegates', {
    params: {
      'order[churnDate]': churns ? sortOrder : undefined,
      'exists[churnDate]': !!churns,
      status: churns ? [1, 2] : null,
      'customer.pin': customerPin,
      pagination: true,
      page,
      perPage,
      properties,
    },
  });

  return data;
}
