import camelCase from 'lodash/camelCase';

import { ServiceIcon } from '@/components/icons';
import { CamelCaseServiceName, ServiceName } from '@/types/services';

import { getObjectKeys } from '../tsHelpers';

export const renderServiceIcons = (row: {
  serviceTypes: {
    [key in ServiceName]: boolean;
  };
}) => {
  console.log(row);

  return getObjectKeys(row.serviceTypes).map((type, i) => {
    return (
      <ServiceIcon
        key={i}
        serviceName={camelCase(type) as CamelCaseServiceName}
        isActive={row.serviceTypes[type]}
      />
    );
  });
};
