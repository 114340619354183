import {
  CardContent,
  CardHeader,
  Divider,
  LinearProgress,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { getFees } from '@/api/fees';
import { Alert } from '@/components/Alert';
import { SimpleTable } from '@/components/SimpleTable';
import { queryKeys } from '@/constants/queryKeys';
import { getFormattedDate } from '@/helpers/dates';
import { Fee } from '@/types/fees';

type Props = {
  companyCustomer: boolean;
};

const EarlierSpecialFees = ({ companyCustomer }: Props) => {
  const { id: customerId } = useParams();

  const billedAtExists = true;

  const {
    data: pastSpecialFees,
    isLoading,
    isError,
  } = useQuery({
    queryKey: [queryKeys.getFees, customerId, billedAtExists],

    queryFn: async () => {
      const { data } = await getFees({
        customerId: parseInt(customerId!, 10),
        billedAtExists,
      });

      return data['hydra:member'];
    },
  });

  const feeDateRenderer = (fee: Fee) =>
    fee.invoiceAt ? getFormattedDate(fee.invoiceAt, 'yyyy-MM-dd') : '';

  const nameRenderer = (fee: Fee) => fee.title;

  const priceRenderer = (fee: Fee) =>
    companyCustomer ? fee.price : fee.priceWithSalesTax;

  const descriptionRenderer = (fee: Fee) => fee.description;

  const billDateRenderer = (fee: Fee) =>
    fee.billedAt ? getFormattedDate(fee.billedAt, 'yyyy-MM-dd') : '';

  const columns = [
    { label: 'Avgiftsdatum', renderer: feeDateRenderer },
    { label: 'Namn', renderer: nameRenderer },
    {
      label: companyCustomer ? 'Pris (exkl moms)' : 'Pris (inkl moms)',
      renderer: priceRenderer,
    },
    { label: 'Beskrivning', renderer: descriptionRenderer },
    { label: 'Fakturerad', renderer: billDateRenderer },
  ];

  return (
    <>
      <CardHeader title="Tidigare engångsavgifter" />
      <Divider />
      <CardContent>
        {isLoading ? (
          <LinearProgress />
        ) : isError ? (
          <Alert>Något gick fel</Alert>
        ) : (
          <SimpleTable columns={columns} rows={pastSpecialFees || []} />
        )}
      </CardContent>
    </>
  );
};

export { EarlierSpecialFees };
