import isNil from 'lodash/isNil';

import { TaskTeamType } from '@/modules/tasks/types/team';
import {
  AccountManagersResponse,
  UserItem,
  UsersResponse,
} from '@/types/users';
import httpClient from '@/utils/httpClient';

export type FetchUsersParams = {
  page?: number;
  perPage?: number;
  pagination?: boolean;
  taggable?: boolean;
  computer?: boolean;
  properties?: any;
  onboardingPercentages?: boolean;
  search?: string;
  taskTeam?: TaskTeamType | TaskTeamType[];
};

export async function fetchUsers<Response = UsersResponse>({
  page = 1,
  perPage = 100,
  pagination = true,
  taggable,
  computer,
  properties,
  onboardingPercentages = false,
  search,
  taskTeam,
}: FetchUsersParams) {
  const { data } = await httpClient.get<Response>('/users', {
    params: {
      pagination,
      ...(pagination && { page }),
      ...(pagination && { perPage }),
      ...(!isNil(taggable) && { taggable }),
      ...(!isNil(computer) && { computer }),
      ...(search && { search }),
      ...(taskTeam && { taskTeam }),
      properties: {
        id: true,
        name: true,
        email: true,
        username: true,
        deleted: true,
        roles: true,
        ...(onboardingPercentages && {
          coProtectionOnboardingPercentage: true,
          firetextOnboardingPercentage: true,
        }),
        ...properties,
      },
    },
  });
  return data;
}

export async function fetchDeletedUsers({
  page = 1,
  perPage = 100,
  pagination = true,
}) {
  const { data } = await httpClient.get<UsersResponse['hydra:member'] | null>(
    '/deleted-users',
    {
      params: {
        page,
        perPage,
        pagination: pagination,
      },
    },
  );
  return data;
}

export async function fetchUser({ id }: { id: number }) {
  const { data } = await httpClient.get<UserItem>(`/users/${id}`, {
    params: {
      properties: {
        id: true,
        name: true,
        email: true,
        username: true,
        managedCustomers: true,
        coProtectionOnboardingPercentage: true,
        firetextOnboardingPercentage: true,
      },
    },
  });

  return data;
}

interface FieldsToFetch {
  [key: string]: boolean | FieldsToFetch;
}

export async function getUserById<Response = UserItem>(
  id: number,
  fieldsToFetch: FieldsToFetch = {},
  onboardingPercentages: boolean = false,
) {
  const params = {
    taggable: true,
    ...(onboardingPercentages && {
      coProtectionOnboardingPercentage: true,
      firetextOnboardingPercentage: true,
    }),
    properties: {
      ...fieldsToFetch,
    },
  };

  const { data } = await httpClient.get<Response>(`/users/${id}`, {
    params,
  });

  return data;
}

export async function createUser(user: Partial<UserItem>) {
  const { data } = await httpClient.post<UserItem>('/users', user);

  return data;
}

export async function updateUser({
  userId,
  fields,
}: {
  userId: number;
  fields: Partial<UserItem> & { plainPassword?: string };
}) {
  const { data } = await httpClient.put<UserItem>(`/users/${userId}`, fields);

  return data;
}

export async function reactivateUser(userId: number) {
  const { data } = await httpClient.put<UserItem>(
    `deleted-users/${userId}/recover`,
  );

  return data;
}

type DeleteUserResponse = {
  error: boolean;
  message: string;
};

export async function deleteUser(id: number) {
  const { data } = await httpClient.get<DeleteUserResponse>(
    `/users/${id}/delete`,
  );

  return data;
}

export const getAccountManagers = async () => {
  const { data } = await httpClient.get<AccountManagersResponse>('/users', {
    params: {
      pagination: false,
      properties: {
        roles: true,
        name: true,
        username: true,
        level: true,
        managedCustomers: {
          id: true,
        },
      },
    },
  });

  return data['hydra:member'].filter((user) =>
    user.roles.includes('ROLE_ACCOUNT_MANAGER'),
  );
};
