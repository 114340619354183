import { Grid } from '@mui/material';

import {
  EmailInput,
  PhoneInput,
  TextInput,
  normalizeToNumbersOnly,
} from '@/components/form';
import {
  composeValidators,
  fieldIsPersonalNumber,
  fieldRequired,
} from '@/utils/validation';

type Props = {
  editMode: boolean;
};
export const AntiTheftDecalInformationSection = ({ editMode }: Props) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm>
        <TextInput label="Namn" name={editMode ? 'name' : 'protectedName'} />
      </Grid>
      <Grid item xs={12} sm>
        <TextInput
          label="Personnummer 1"
          name={editMode ? 'pin' : 'protectedPin'}
          required
          fieldProps={{
            parse: normalizeToNumbersOnly,
            validate: composeValidators(fieldRequired, fieldIsPersonalNumber),
          }}
        />
      </Grid>

      <Grid item xs={12} sm>
        <PhoneInput
          required={false}
          label="Larm till mobil"
          name={editMode ? 'phone' : 'protectedPhone'}
        />
      </Grid>

      <Grid item xs={12} sm>
        <EmailInput
          required={false}
          label="Larm till e-post"
          name={editMode ? 'email' : 'protectedEmail'}
        />
      </Grid>
    </Grid>
  );
};
