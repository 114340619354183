import React from 'react';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse } from '@mui/material';

import { Can } from '@/components/Can';
import useUserRoles from '@/hooks/useUserRoles';

import { MenuItem } from '../types';
import { CollapsableMenuList } from './CollapsableMenuList';
import { MenuListItem } from './MenuListItem';
import { MenuListItemButton } from './MenuListItemButton';
import { SubMenuListItem } from './SubMenuListItem';

export const PartnerMenu = () => {
  const [open, setOpen] = React.useState(false);
  const userRoles = useUserRoles();

  const menuItems: Required<Pick<MenuItem, 'title' | 'href' | 'authRule'>>[] = [
    {
      title: 'Konton',
      href: `/partner/accounts`,
      authRule: 'partners.view',
    },
    {
      title: 'Statistik',
      href: `/partner/statistics`,
      authRule: 'partners.view',
    },
  ];

  return (
    <>
      <MenuListItem>
        <MenuListItemButton onClick={() => setOpen(!open)}>
          Partners
          {open ? <ExpandLess /> : <ExpandMore />}
        </MenuListItemButton>
      </MenuListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <CollapsableMenuList>
          {menuItems.map(({ href, title, authRule }) => (
            <Can
              key={href}
              userRoles={userRoles}
              action={authRule}
              yes={() => (
                <SubMenuListItem
                  key={encodeURI(href)}
                  href={href}
                  title={title}
                />
              )}
            />
          ))}
        </CollapsableMenuList>
      </Collapse>
    </>
  );
};
