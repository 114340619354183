import { PageWrapper } from '@/components';
import { PartnerList } from '@/modules/PartnerPortal';

const PartnerAccounts = () => {
  return (
    <PageWrapper
      pageTitle="Partnerkonton"
      breadCrumbs={[{ label: 'Partners' }, { label: 'Konton' }]}
      maxWidth="md"
    >
      <PartnerList />
    </PageWrapper>
  );
};

export default PartnerAccounts;
