import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
} from '@mui/material';

import { PartnerForm } from './PartnerForm';
import { PartnerFormFields } from './PartnerFormFields';
import { SubmitButton } from './SubmitButton';

export const CreatePartnerForm = () => {
  return (
    <Box maxWidth="md">
      <PartnerForm isEdit={false}>
        <Card>
          <CardHeader title="Skapa partner" />
          <Divider />
          <CardContent>
            <PartnerFormFields />
          </CardContent>
          <Divider />
          <CardActions sx={{ justifyContent: 'end' }}>
            <SubmitButton />
          </CardActions>
        </Card>
      </PartnerForm>
    </Box>
  );
};
