import { TextFieldProps } from 'mui-rff';
import { ValidationError } from 'yup';

import { identity } from '@/helpers';
import Yup from '@/utils/validation';

import { TextInput } from '../TextInput';

type Props = TextFieldProps & {
  name: string;
  label?: string;
  required?: boolean;
  customValidator?: Function;
};

export const EmailInput = ({
  name = 'email',
  label = 'E-Post',
  required = true,
  customValidator,
  ...rest
}: Props) => {
  const validate = async (value: unknown, allValues: unknown) => {
    const schema = Yup.string()
      .email()
      .when('$required', (required, schema) =>
        required ? schema.required() : schema,
      );

    try {
      await schema.validate(value, { context: { required } });

      if (customValidator && value) {
        const result = customValidator(value, allValues);
        if (result && result.then) {
          await result;
        }
      }
      return undefined;
    } catch (e: unknown) {
      if (e instanceof ValidationError) {
        return e.errors.map((error: string) => error);
      } else {
        return '';
      }
    }
  };

  return (
    <TextInput
      name={name}
      label={label}
      required={required}
      fieldProps={{ validate, parse: identity }}
      inputProps={{
        'data-testid': name,
      }}
      {...rest}
    />
  );
};
