import { format } from 'date-fns';

import { ServiceName } from '@/types/services';
import { Products } from '@/types/settings';
import httpClient from '@/utils/httpClient';

export type DownloadExportFileParams = {
  type:
    | 'co_protection'
    | 'customer'
    | 'firetext'
    | 'homegate'
    | 'key_tag'
    | 'keytag.billable'
    | 'keytag.onboarding'
    | 'protection'
    | 'sinne.economy'
    | 'sinne'
    | 'sticker.billable'
    | 'sticker';
  params: any;
  whiteLableExportName: string;
};

export type ExportDownloadError = {
  message: string;
  errors: Record<string, string>;
};

export async function downloadExportFile({
  type,
  params,
  whiteLableExportName,
}: DownloadExportFileParams) {
  return await httpClient
    .get<Blob>(`rpc/${type}.export`, {
      params,
      responseType: 'blob',
    })
    .then((response) => {
      if (response.status === 200) {
        const today = format(new Date(), 'yyyy-MM-dd');
        const file = new Blob([response.data], {
          type: response.headers['content-type'],
        });
        const url = window.URL.createObjectURL(file);
        const link = document.createElement('a');
        const fileType =
          file.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            ? '.xlsx'
            : '.csv';
        link.href = url;
        link.setAttribute(
          'download',
          `${today} ${whiteLableExportName}${fileType}`,
        );
        link.click();
        return response;
      }

      return response;
    })
    .catch((error) => {
      throw new Error(error.status);
    });
}
