import React from 'react';

import { useMutation } from '@tanstack/react-query';
import { Form } from 'react-final-form';

import { bulkWelcomeLetterDownload } from '@/api/files';
import { mapDateFieldsValues } from '@/helpers/dates';
import { usePrivateSettings } from '@/hooks';
import { useSnackbar } from '@/hooks';
import { GenericHttpError } from '@/types/errors';
import { ServiceNamesPrivateSetting } from '@/types/settings';
import { generatePdfDownloadLink } from '@/utils/pdf';

import { FormValues, WelcomeLetterForm } from './WelcomeLetterForm';

type Values = {
  product: string;
  regDate: string;
  campaignIds: Array<number>;
};

export const WelcomeLetter = () => {
  const snackBar = useSnackbar();
  const { servicesNames } = usePrivateSettings();

  const pdfMutation = useMutation({
    mutationFn: bulkWelcomeLetterDownload,
    onSuccess: (response, variables) => {
      if (response.status === 204) {
        snackBar({ type: 'info', message: 'Ingen data för vald period.' });
      } else {
        snackBar({ type: 'success', message: 'Välkomstbrev skapade.' });
        generatePdfDownloadLink(
          response.data,
          `Välkomstbrev-${variables.regDate}`,
        );
      }
    },
    onError: async (error: GenericHttpError) => {
      snackBar({
        type: 'error',
        message: `${error.status}: ${error.statusText}`,
      });
    },
  });

  function onSubmit(values: Values) {
    const mappedValues = mapDateFieldsValues(values);
    pdfMutation.mutateAsync(mappedValues);
  }

  return (
    <Form<FormValues>
      onSubmit={onSubmit}
      initialValues={{ campaignIds: [] }}
      render={({ handleSubmit, values, form }) => {
        return (
          <WelcomeLetterForm
            handleSubmit={handleSubmit}
            submitting={pdfMutation.isPending}
            values={values}
            form={form}
          />
        );
      }}
    />
  );
};
