import {
  Alert,
  Autocomplete,
  CircularProgress,
  FormControl,
  TextField,
} from '@mui/material';
import { Field } from 'react-final-form';

import { useGetBDMs } from '@/modules/ResellerPortal/hooks/useGetBDMs';
import { isValidOption } from '@/utils/validation';

import { prepareKamIdOptions } from './utils/prepareKamIdOptions';

type Props = {
  disabled?: boolean;
};

// For all services except Sinne, kamId should be a user id of a user that has role business development manager
// https://monitum.atlassian.net/browse/MDE-12

export const KamIdAutocomplete = ({ disabled = false }: Props) => {
  const { data: bdms, isFetching, isError } = useGetBDMs();

  const options = prepareKamIdOptions(bdms);

  const getOptionLabel = (option: string) => {
    if (bdms?.length) {
      const bdm = bdms.find((bdm) => bdm.id.toString() === option);
      return bdm?.name ? `${bdm?.id} - ${bdm?.name}` : '';
    }
    return 'Ingen';
  };

  if (isError)
    return (
      <FormControl fullWidth>
        <Alert severity="error">Något gick fel</Alert>
      </FormControl>
    );

  const fieldName = 'kamId';

  return (
    <Field name={fieldName}>
      {({ input }) => {
        return (
          <Autocomplete
            onChange={(_, value) => input.onChange(value)}
            value={input?.value?.toString() || null}
            options={options}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={(option, value) =>
              option.toString() === value.toString()
            }
            disabled={disabled || isFetching}
            loading={isFetching}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Partner Manager"
                error={
                  isValidOption(options, isFetching)(input.value) !== undefined
                }
                helperText={isValidOption(options, isFetching)(input.value)}
                inputProps={{
                  'data-testid': fieldName,
                  ...params.inputProps,
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isFetching ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            renderOption={(props, option) => (
              <li {...props} key={option}>
                {option}
              </li>
            )}
          />
        );
      }}
    </Field>
  );
};
