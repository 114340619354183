import usePublicSettings from '@/hooks/usePublicSettings';

export enum CALL_INITIATOR_ENUM {
  'RP' = 'RP', // RP = relying partner === the WL
  'user' = 'user',
}

export type CallInitiator =
  (typeof CALL_INITIATOR_ENUM)[keyof typeof CALL_INITIATOR_ENUM];

export const useGetBankIdCallInitiatorOptions = () => {
  const { publicSettings } = usePublicSettings();
  const WlName = publicSettings?.companyNames?.standard ?? 'Vi';

  const CALL_INITIATOR_RADIO_OPTIONS = [
    {
      value: CALL_INITIATOR_ENUM.RP,
      label: WlName,
    },
    {
      value: CALL_INITIATOR_ENUM.user,
      label: 'Kund',
    },
  ];

  return { CALL_INITIATOR_RADIO_OPTIONS, CALL_INITIATOR_ENUM };
};
