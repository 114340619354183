import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material';
import Link from '@mui/material/Link';
import truncate from 'lodash/truncate';
import { Link as RouterLink } from 'react-router-dom';
import { z } from 'zod';

import { getFormattedDate } from '@/helpers/dates';
import {
  getCamelCaseServiceName,
  getKebabCaseServiceName,
} from '@/helpers/tsHelpers';
import { USER_TYPES } from '@/modules/common/constants/entitiyNamesMap';
import { servicesWithConfigEnum } from '@/modules/services/constants';
import {
  CoProtectionItem,
  FiretextParamSchema,
  HomegateParamSchema,
  ProtectionItem,
} from '@/types/services';
import { ServiceStatuses } from '@/types/settings';
import { getCustomerName } from '@/utils/customer';
import { hasOwnProperty } from '@/utils/typeHelpers';
import { getInfoAboutAgreements } from '../../utils';

type ServiceName = Exclude<z.infer<typeof servicesWithConfigEnum>, 'sinne'>;

type Args = {
  serviceName: ServiceName;
  servicesStatuses: ServiceStatuses;
  serviceTypes: Record<number, string> | false;
};

type ProtectionRow =
  | (ProtectionItem & { type: number })
  | (CoProtectionItem & { type: number });

type Row = HomegateParamSchema;

export function getTableConfig({
  serviceName: anyCaseServiceName,
  servicesStatuses,
  serviceTypes,
}: Args) {
  const serviceName = getCamelCaseServiceName(anyCaseServiceName);
  const agreementColumn = {
    label: 'Avtal',
    order: 1,
    renderer: (row: Row) => {
      const agreements = getInfoAboutAgreements(row.agreements);

      return (
        <div>
          <Typography
            variant="body2"
            component="span"
            color={(theme) =>
              !agreements.audio
                ? theme.palette.error.main
                : theme.palette.success.main
            }
          >
            Ljudfil
          </Typography>{' '}
          /{' '}
          <Typography
            variant="body2"
            component="span"
            color={(theme) =>
              !agreements.document
                ? theme.palette.error.main
                : theme.palette.success.main
            }
          >
            Skriftligt
          </Typography>
        </div>
      );
    },
  };

  const statusColumn = {
    label: 'Status',
    order: 5,
    renderer: (row: Row) => {
      // @ts-ignore TODO: fix this
      return servicesStatuses?.[serviceName][row.status];
    },
  };

  const actionColumn = {
    label: 'Ändra',
    order: 8,
    align: 'center',
    renderer: (row: Row) => (
      <Box display="flex" justifyContent="center">
        <Link
          component={RouterLink}
          to={`/products/${getKebabCaseServiceName(serviceName)}/${row.id
            }/edit?tab=Översikt`}
        >
          <IconButton color="primary" size="large">
            <ArrowForwardIcon />
          </IconButton>
        </Link>
        <Link
          target="_blank"
          href={`/products/${getKebabCaseServiceName(serviceName)}/${row.id
            }/edit?tab=Översikt`}
          onClick={(e) => e.stopPropagation()}
        >
          <Tooltip title="Öppna i ny flik">
            <IconButton color="primary" size="large">
              <LaunchOutlinedIcon />
            </IconButton>
          </Tooltip>
        </Link>
      </Box>
    ),
  };

  const protectionColumns = [
    actionColumn,
    {
      label: 'Kampanj',
      order: 5,
      renderer: (row: ProtectionRow) => row.campaign,
    },
    {
      label: 'Paket',
      order: 4,
      renderer: (row: ProtectionRow) => {
        if (serviceTypes && hasOwnProperty(row, 'type')) {
          return serviceTypes[row.type];
        }
      },
    },
  ];

  const homegateColumns = [
    {
      label: 'Konfigurera',
      order: 9,
      align: 'center',
      renderer: () => {
        return <Button color="primary">Konfigurera</Button>;
      },
    },
    { label: 'Personnr', order: 3, renderer: (row: Row) => row.customer?.pin },
    statusColumn,
  ];

  const firetextColumns = [
    {
      label: 'Konfigurera',
      order: 9,
      align: 'center',
      renderer: () => {
        return <Button color="primary">Konfigurera</Button>;
      },
    },
    {
      label: 'MSISDN',
      order: 6,
      renderer: (row: FiretextParamSchema) => row.msisdn,
    },
    {
      label: 'Namn',
      order: 3,
      renderer: (row: FiretextParamSchema) => getCustomerName(row.customer),
    },
    statusColumn,
  ];

  const commonColumns = [
    { label: 'Kund-ID', order: 3, renderer: (row: Row) => row.customer?.id },
    {
      label: 'Orderdatum',
      order: 6,
      renderer: (row: Row) => getFormattedDate(row.regDate, 'yyyy-MM-dd'),
    },
    {
      label: `Info till ${USER_TYPES.admin}`,
      order: 7,
      renderer: (row: Row) => {
        return (
          <Tooltip
            title={<Typography variant="body1">{row.adminNote}</Typography>}
          >
            <Typography>
              {truncate(row.adminNote || '', {
                length: 90,
              })}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      label: 'Ångerrätt',
      order: 2,
      renderer: (row: ProtectionRow | HomegateParamSchema) => {
        return (
          <Typography
            color={(theme) => theme.palette[getRegretTimeColor(row)].main}
          >
            {getRegretTimeText(row)}
          </Typography>
        );
      },
    },
  ];

  const getRegretTimeColor = (row: ProtectionRow | HomegateParamSchema) => {
    if ('regretTimeExpired' in row && !row.regretTimeExpired) {
      return 'error';
    }
    if ('hasRegretTimeExpired' in row && !row.hasRegretTimeExpired) {
      return 'error';
    }
    return 'success';
  };

  const getRegretTimeText = (row: ProtectionRow | HomegateParamSchema) => {
    if ('regretTimeExpired' in row && !row.regretTimeExpired) {
      return 'Skicka ej';
    }
    if ('hasRegretTimeExpired' in row && !row.hasRegretTimeExpired) {
      return 'Skicka ej';
    }
    return 'Skicka';
  };

  const columnMap = {
    firetext: [agreementColumn, ...firetextColumns, ...commonColumns],
    protection: [agreementColumn, ...commonColumns, ...protectionColumns],
    coProtection: [agreementColumn, ...commonColumns, ...protectionColumns],
    homegate: [agreementColumn, ...homegateColumns, ...commonColumns],
  };

  return columnMap[serviceName as keyof typeof columnMap].sort(
    (a, b) => a.order - b.order,
  );
}
