import React, { Fragment, useState } from 'react';

import { Card, CardProps, Divider, Table } from '@mui/material';

import { BodySection } from './BodySection';
import { CollapsibleContainer } from './CollapsibleContainer';
import { HeadSection } from './HeadSection';
import { TitleSection } from './TitleSection';

export type SimpleTableColumn<Item = any> = {
  label: string | React.ReactNode;
  renderer: (row: Item) => string | React.ReactNode;
  align?: 'inherit' | 'left' | 'right' | 'center' | 'justify';
  alignTop?: boolean;
};

export type SimpleTableHeadVariant = 'white' | 'default';

type Props = {
  columns: SimpleTableColumn[];
  rows: any[];
  onRowClick?: (row: any) => void;
  tableTitle?: string;
  isCollapsible?: boolean;
  headVariant?: SimpleTableHeadVariant;
  cardProps?: CardProps;
};

export const SimpleTable = ({
  columns,
  rows,
  onRowClick,
  tableTitle,
  isCollapsible,
  headVariant,
  cardProps,
}: Props) => {
  const [isOpened, setIsOpened] = useState<boolean>(false);

  return (
    <Fragment>
      {tableTitle && (
        <>
          <TitleSection
            titleText={tableTitle}
            isOpened={isOpened}
            isCollapsible={!!isCollapsible}
            onToggleCollapse={() => setIsOpened((prevValue) => !prevValue)}
          />
          <Divider />
        </>
      )}

      <CollapsibleContainer isCollapsible={!!isCollapsible} isOpened={isOpened}>
        <Card {...cardProps}>
          <Table data-testid="table-body">
            <HeadSection
              columns={columns}
              headVariant={headVariant || 'default'}
            />
            <BodySection
              columns={columns}
              rows={rows}
              onRowClick={onRowClick}
            />
          </Table>
        </Card>
      </CollapsibleContainer>
    </Fragment>
  );
};
