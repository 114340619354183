import { Button } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import pick from 'lodash/pick';
import { Form } from 'react-final-form';
import invariant from 'tiny-invariant';
import { z } from 'zod';

import { CreateNotePayload, createNote } from '@/api/notes';
import { getTypeKey } from '@/helpers';
import { getElementFromArray } from '@/helpers/getElementFromArray';
import { usePrivateSettings } from '@/hooks';
import { prepareInitialValues } from '@/modules/services/utils';
import { HomegateParamSchema } from '@/types/services';
import { homegateStatusKey } from '@/types/settings';
import httpClient from '@/utils/httpClient';

import { queryKeys } from '../../../../../constants/queryKeys';
import { SalesInformationFields } from './SalesInformationFields';
import { ServiceInfoFields } from './ServiceInfoFields';

const FIELDS_TO_PICK = [
  'abTest',
  'addressList',
  'adminNote',
  'antiregret',
  'batchId',
  'campaign',
  'churnDate',
  'churnReason',
  'hardwareSurcharge',
  'id',
  'installationFee',
  'kamId',
  'leadSource',
  'price',
  'regDate',
  'regretReason',
  'regretSource',
  'reseller',
  'returnLog',
  'salesRep',
  'sentDate',
  'startFee',
  'status',
  'tb',
  'unitReturned',
  'winback',
];

const homegateFormValuesSchema = z.object({
  abTest: z.string().nullable(),
  addressList: z.string().nullable(),
  adminNote: z.string().nullable(),
  antiregret: z.boolean(),
  batchId: z.string().nullable(),
  campaign: z.string(),
  churnDate: z.string().nullable(),
  churnReason: z.number().int().nullable(),
  contractMonths: z.string(),
  freeMonths: z.string(),
  hardwareSurcharge: z.string(),
  id: z.number().int().positive(),
  installationFee: z.string(),
  kamId: z.number().int().nullable(),
  leadSource: z.string().nullable(),
  noticePeriod: z.string(),
  price: z.string(),
  regDate: z.string(),
  regretReason: z.number().int().nullable(),
  regretSource: z.number().int().nullable(),
  reseller: z.string(),
  returnLog: z.string(), // FROM NOTE
  salesRep: z.string(),
  sentDate: z.string().nullable(),
  startFee: z.string(),
  status: homegateStatusKey,
  tb: z.number().nullable(),
  unitReturned: z.boolean(),
  winback: z.boolean(),
});

type FormValues = z.infer<typeof homegateFormValuesSchema>;

export type HomegateEditProps = {
  serviceDetails: HomegateParamSchema;
  isLoading: boolean;
  onSubmit: (v: Omit<FormValues, 'returnLog'>) => void;
};
export const HomegateEdit = ({
  serviceDetails,
  isLoading,
  onSubmit,
}: HomegateEditProps) => {
  const { servicesTypes } = usePrivateSettings();
  const queryClient = useQueryClient();
  const initialValues = prepareInitialValues(
    pick(serviceDetails, FIELDS_TO_PICK),
  );

  const returnNoteIri =
    serviceDetails?.notes?.[0]?.['@id'] ?? serviceDetails?.notes?.[0];

  const { data: latestReturnLogNote, isInitialLoading: loadingLatestNote } =
    useQuery({
      queryKey: ['note', serviceDetails.id, returnNoteIri],

      queryFn: async () => {
        const { data } = await httpClient.get(
          getElementFromArray(serviceDetails?.notes?.[0]?.['@id']),
        );
        return data;
      },

      enabled: !!returnNoteIri,
    });

  const createNoteMutation = useMutation({
    mutationFn: ({
      notePayload,
    }: {
      notePayload: CreateNotePayload;
      homegatePayload: Omit<FormValues, 'returnLog'>;
    }) => {
      return createNote(notePayload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.getNotes],
      });
      queryClient.invalidateQueries({
        queryKey: ['note', serviceDetails.id],
      });
      queryClient.invalidateQueries({
        queryKey: ['serviceDetails', 'homegate', serviceDetails.id.toString()],
      });
    },
    onSettled: (data, error, { homegatePayload }) => {
      if (homegatePayload) {
        onSubmit(homegatePayload);
      }
    },
  });

  const submitHandler = (values: FormValues) => {
    const { returnLog, ...fields } = values;

    if (returnLog) {
      const unitReturnedNoteType = getTypeKey(
        servicesTypes,
        'note',
        'Enhet returnerad',
      );

      try {
        invariant(unitReturnedNoteType, 'Unit returned note type not found');

        const notePayload = {
          customerId: serviceDetails.customer.id,
          type: unitReturnedNoteType,
          text: returnLog,
          homegate: `/homegates/${serviceDetails.id}`,
        };

        createNoteMutation.mutate({ notePayload, homegatePayload: fields });
      } catch (error) {
        console.error('Error creating note', error);
      }
    } else {
      onSubmit(fields);
    }
  };

  return (
    <Form
      onSubmit={(values: FormValues) => submitHandler(values)}
      initialValues={{ ...initialValues, returnLog: latestReturnLogNote?.text }}
      render={({ handleSubmit, values, submitting }) => (
        <form onSubmit={handleSubmit} noValidate>
          <ServiceInfoFields
            serviceDetails={serviceDetails}
            loadingLatestNote={loadingLatestNote}
          />
          <SalesInformationFields
            price={serviceDetails.price}
            customer={serviceDetails.customer}
            campaign={values.campaign}
          />
          <section style={{ marginTop: '1rem' }}>
            <Button
              disabled={submitting || isLoading}
              type="submit"
              color="primary"
              variant="contained"
            >
              Uppdatera
            </Button>
          </section>
        </form>
      )}
    />
  );
};
