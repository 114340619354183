import { Dropdown, TextInput } from '@/components/form';
import {
  autogiroAgreementTypeOptions,
  autogiroSourceTypeOptions,
} from '@/constants';

export const DirectDebitFields = () => {
  return (
    <>
      <TextInput label="Bank" name="bankName" />
      <TextInput label="Kontonummer" name="bankAccount" />
      <Dropdown
        label="Källa"
        name="autogiroSource"
        options={autogiroSourceTypeOptions}
      />
      <Dropdown
        label="Status"
        name="autogiroAgreement"
        options={autogiroAgreementTypeOptions}
      />
    </>
  );
};
