import { useContext } from 'react';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { CircularProgress, Typography } from '@mui/material';
import { useSelector } from '@xstate/react';

import { LinkToCustomer } from '../../LinkToCustomer';
import { SinneReturnStateContext } from '../context';
import { RetryButton } from './RetryButton';
import { StepRow } from './StepRow';

export const CustomerCheck = () => {
  const service = useContext(SinneReturnStateContext);

  const state = useSelector(service, (state) => state);
  const { customerId, customerName } = state.context;

  return (
    <>
      {state.matches('STATUS_CHECKS.CUSTOMER.loading') && (
        <StepRow>
          <CircularProgress size={14} />
          <Typography component="span" variant="h6">
            Hämtar information om senaste kund...
          </Typography>
        </StepRow>
      )}
      {customerId && customerName && (
        <StepRow>
          <CheckCircleOutlineIcon color="success" sx={{ fontSize: 20 }} />
          <Typography component="span" variant="h6" padding={0} margin={0}>
            Kund hittad: {customerName}
          </Typography>
          <LinkToCustomer customerId={customerId} />
        </StepRow>
      )}
      {state.matches('STATUS_CHECKS.CUSTOMER.error') && (
        <StepRow>
          <ErrorOutlineIcon color="error" sx={{ fontSize: 20 }} />
          <Typography component="span" variant="h6" padding={0} margin={0}>
            Ingen kund hittad
          </Typography>
          <RetryButton
            onClick={() => service.send({ type: 'RETRY_CUSTOMER_CHECK' })}
          />
        </StepRow>
      )}
    </>
  );
};
