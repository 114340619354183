import { FileCopyOutlined } from '@mui/icons-material';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Paper,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useMutation } from '@tanstack/react-query';
import copy from 'copy-to-clipboard';
import { Switches } from 'mui-rff';
import { Form } from 'react-final-form';

import { sendDirectCustomerEmail } from '@/api/sendEmail';
import { MutationLoadingSpinner } from '@/components/MutationLoadingSpinner';
import { Checkboxes, Dropdown, TextArea, TextInput } from '@/components/form';
import { Condition } from '@/components/form/Condition';
import { useSnackbar } from '@/hooks';
import { SelectFieldOptions } from '@/types/common';
import Yup, { makeValidation } from '@/utils/validation';

type FormValues = {
  message: string;
  manualReceiver: boolean;
  receiver: string;
  confirm: boolean;
};

type Props = {
  template: string;
  emailAddresses: SelectFieldOptions;
  customerId: string | number;
};

export const EmailTemplate = ({
  template,
  emailAddresses,
  customerId,
}: Props) => {
  const sendEmailMutation = useMutation({
    mutationFn: (values: FormValues) =>
      sendDirectCustomerEmail({
        ...values,
        customerId:
          typeof customerId === 'string' ? customerId : customerId.toString(),
      }),
  });

  const snackbar = useSnackbar();

  const onCopyToClipboard = () => {
    const toPaste = template;

    copy(toPaste, { format: 'text/plain' });

    snackbar({ type: 'success', message: 'Kopierad till urklipp!' });
  };

  const onSubmit = (values: FormValues) => {
    sendEmailMutation.mutate(values, {
      onSuccess: () => {
        snackbar({
          type: 'success',
          message: 'Meddelandet skickat!',
        });
      },
      onError: () => {
        snackbar({
          type: 'error',
          message: 'Meddelandet kunde inte skickas!',
        });
      },
    });
  };

  const validationSchema = Yup.object({
    receiver: Yup.string().required(),
  });

  const validate = makeValidation(validationSchema);

  const { isPending, isError, isIdle, isSuccess } = sendEmailMutation;
  return (
    <StyledPaper square>
      <Card raised>
        <CardHeader
          titleTypographyProps={{ className: 'red' }}
          title="Mall"
          action={
            <Tooltip title="Kopiera mallen till urklipp">
              <IconButton onClick={onCopyToClipboard} size="large">
                <FileCopyOutlined />
              </IconButton>
            </Tooltip>
          }
        ></CardHeader>
        <CardContent sx={{ pt: 0 }}>
          <Grid container direction="column">
            <MutationLoadingSpinner
              loading={isPending}
              error={isError}
              idle={isIdle}
              success={isSuccess}
              successMessage="Meddelandet skickat"
              errorMessage="Meddelandet kunde inte skickas"
            />
            {isIdle && (
              <Form
                initialValues={{
                  message: template,
                  manualReceiver: false,
                  confirm: false,
                }}
                validate={validate}
                onSubmit={onSubmit}
                render={({ handleSubmit, pristine, submitting, values }) => (
                  <form onSubmit={handleSubmit}>
                    <Grid container item xs={12}>
                      <Grid item sm={9} xs={12}>
                        <Condition when="manualReceiver" is={true}>
                          <TextInput name="receiver" label="Mottagare" />
                        </Condition>
                        <Condition when="manualReceiver" is={false}>
                          <Dropdown
                            name="receiver"
                            label="Mottagare"
                            options={emailAddresses}
                          />
                        </Condition>
                      </Grid>
                      <Grid item sm={3} xs={12}>
                        <Switches
                          color="primary"
                          name="manualReceiver"
                          formControlLabelProps={{ labelPlacement: 'bottom' }}
                          data={{
                            label: 'Manuell mottagare',
                            value: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                    <TextArea rows={10} name="message" label="Meddelande" />
                    <Checkboxes
                      color="primary"
                      name="confirm"
                      options={{ label: 'Bekräfta', value: true }}
                    />
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={pristine || submitting || !values.confirm}
                    >
                      Skicka mail
                    </Button>
                  </form>
                )}
              />
            )}
          </Grid>
        </CardContent>
      </Card>
    </StyledPaper>
  );
};

const StyledPaper = styled(Paper)`
  white-space: pre-line;
  margin-bottom: 2rem;
  width: 100%;
`;
