import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Form } from 'react-final-form';

import { createCost } from '@/api/costs';
import { Dropdown, TextInput } from '@/components/form';
import { queryKeys } from '@/constants/queryKeys';
import { getObjectKeys } from '@/helpers/tsHelpers';
import { usePrivateSettings, useSnackbar } from '@/hooks';
import { useModal } from '@/hooks/useModal';
import { CostTypes } from '@/types/settings';
import { fieldRequired } from '@/utils/validation';

type FormValues = {
  costType: string;
  comment: string;
};

type Props = {
  modal: {
    extra: {
      customerId: number;
    };
  };
};

export const AddCostModal = ({
  modal: {
    extra: { customerId },
  },
}: Props) => {
  const snackbar = useSnackbar();
  const { closeModal } = useModal();

  const queryClient = useQueryClient();

  const { privateSettings } = usePrivateSettings();
  const costTypes = privateSettings?.types.cost ?? ({} as CostTypes);

  const costTypesOptions = getObjectKeys(costTypes).map((key) => ({
    value: key,
    text: costTypes[key],
  }));

  const costMutation = useMutation({
    mutationFn: createCost,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['getNotes'],
      });
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.getCosts, customerId.toString()],
      });
      snackbar({
        type: 'success',
        message: 'Kostnad tillagd.',
      });
      closeModal();
    },
    onError: (error) => {
      snackbar({
        type: 'error',
        message: 'Det gick inte att lägga till en kostnad.',
      });

      console.error('Something went wrong', error);
    },
  });

  async function onSubmit(values: FormValues) {
    const payload = {
      type: parseInt(values.costType, 10),
      comment: values.comment,
      customer: `customers/${customerId}`,
    };

    costMutation.mutate(payload);
  }

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, pristine }) => (
        <StyledDialog onClose={() => closeModal()} open={true}>
          <DialogTitle>Lägg till kostnad</DialogTitle>
          <DialogContent>
            <Dropdown
              label="Typ av kostnad"
              name="costType"
              options={costTypesOptions}
              fieldProps={{ validate: fieldRequired }}
            />
            <TextInput multiline label="Kommentar" name="comment" rows={8} />
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={handleSubmit}
              disabled={pristine || costMutation.isPending}
            >
              Lägg till
            </Button>
          </DialogActions>
        </StyledDialog>
      )}
    />
  );
};

const StyledDialog = styled(Dialog)(() => ({
  '& .MuiDialog-paper': {
    width: 500,
  },
}));
