import { GlobalStyles } from '@mui/material';

const styles = {
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: '0',
      padding: '0',
      scrollbarWidth: 'thin',
    },
    html: {
      height: '100%',
    },
    body: {
      height: '100%',
    },
    a: {
      textDecoration: 'none',
    },
    '#root': {
      height: '100%',
    },
  },
};

const AppGlobalStyles = () => {
  return <GlobalStyles styles={styles} />;
};

export default AppGlobalStyles;
