import React from 'react';

import { TextInput, normalizeToNumbersOnly } from '@/components/form';
import {
  composeValidators,
  fieldIsPersonalNumber,
  fieldRequired,
} from '@/utils/validation';

export const MobileFormFields = () => {
  return (
    <>
      <TextInput
        label="För-/Efternamn"
        name="fullName"
        required
        fieldProps={{
          validate: fieldRequired,
        }}
      />
      <TextInput
        label="Personnummer"
        name="pin"
        required
        fieldProps={{
          validate: composeValidators(fieldRequired, fieldIsPersonalNumber),
          parse: normalizeToNumbersOnly,
          format: (value) => (value ? normalizeToNumbersOnly(value) : ''),
        }}
      />
      <TextInput
        label="IMEI"
        name="imei"
        required
        fieldProps={{
          validate: fieldRequired,
        }}
      />
      <TextInput
        label="Mobilnummer"
        name="msisdn"
        required
        fieldProps={{
          validate: fieldRequired,
        }}
      />
      <TextInput
        label="Operatör"
        name="operator"
        required
        fieldProps={{
          validate: fieldRequired,
        }}
      />
    </>
  );
};
