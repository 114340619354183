import { z } from 'zod';

import { getObjectKeys } from '@/helpers/tsHelpers';

import { CustomerAgreement } from './agreements';
import { ApiCollectionResponse, CommonResponseFields } from './common';
import { CustomerDTO, basicCustomerDataSchema } from './customers';
import { priceItem } from './prices';
import {
  coProtectionStatusKey,
  firetextPricePlanSchema,
  firetextStatusKey,
  homegateStatusKey,
  keyTagStatusKey,
  protectionPricePlanSchema,
  protectionStatusKey,
  sinneEventStatus,
  sinneStatusKey,
  stickerPricePlanSchema,
  stickerStatusKey,
} from './settings';

export const enum Service {
  CO_PROTECTION = 'co-protection',
  FIRETEXT = 'firetext',
  HOMEGATE = 'homegate',
  KEY_TAG = 'key-tag',
  PROTECTION = 'protection',
  SINNE = 'sinne',
  STICKER = 'sticker',
}

const CONFIGURABLE_SERVICE_NAMES = [
  'firetext',
  'protection',
  'co-protection',
  'homegate',
  'sinne',
] as const;
export const ConfigurabelServiceNamesEnum = z.enum(CONFIGURABLE_SERVICE_NAMES);

const CAMEL_CASE_CONFIGURABLE_SERVICE_NAMES = [
  'firetext',
  'protection',
  'coProtection',
  'homegate',
  'sinne',
] as const;

export const CamelCasedConfigurabelServiceNamesEnum = z.enum(
  CAMEL_CASE_CONFIGURABLE_SERVICE_NAMES,
);

const PASCAL_CASE_SERVICE_NAMES = [
  'Firetext',
  'KeyTag',
  'Sticker',
  'Protection',
  'CoProtection',
  'Homegate',
  'Sinne',
] as const;

export type PascalCasedServiceNames =
  (typeof PASCAL_CASE_SERVICE_NAMES)[number];

const SERVICE_NAMES = [
  'firetext',
  'key-tag',
  'sticker',
  'protection',
  'co-protection',
  'homegate',
  'sinne',
] as const;
export const ServiceNamesEnum = z.enum(SERVICE_NAMES);

export const CAMEL_CASE_SERVICE_NAMES = [
  'coProtection',
  'firetext',
  'homegate',
  'keyTag',
  'protection',
  'sinne',
  'sticker',
] as const;
export const CamelCaseServiceNamesEnum = z.enum(CAMEL_CASE_SERVICE_NAMES);
export type CamelCaseServiceName = z.infer<typeof CamelCaseServiceNamesEnum>;

const SNAKE_CASE_SERVICE_NAMES = [
  'co_protection',
  'firetext',
  'homegate',
  'key_tag',
  'protection',
  'sinne',
  'sticker',
] as const;
export const SnakeCaseServiceNamesEnum = z.enum(SNAKE_CASE_SERVICE_NAMES);
export type SnakeCaseServiceName = z.infer<typeof SnakeCaseServiceNamesEnum>;

export type ServiceName =
  | 'co-protection'
  | 'firetext'
  | 'homegate'
  | 'key-tag'
  | 'protection'
  | 'sinne'
  | 'sticker';

export type ServiceDetailsResponse =
  | FiretextItem
  | CoProtectionItem
  | ProtectionItem
  | KeyTagItem
  | HomegateItem
  | SinneItem;

//#region CoProtection
export type CoProtectionItem = {
  '@type': 'CoProtection';
  id: number;
  type: number;
  pin: string;
  phone: string | null;
  email: string | null;
  name: string | null;
  status: number;
  regDate: string;
  churnDate: string | null;
  pricePlanId: number;
  campaign: string;
  salesRep: string;
  salesChannel: number;
  addressList: string;
  leadSource: string;
  abTest: string;
  antiregret: number;
  winBack: number;
  regretSource: number;
  regretReason: number;
  churnReason: number;
  billing: number;
  adminNote: string | null;
  tb: number | null;
  kamId: number | null;
  renewalLetter: string | null;
  lastCheck: string | null;
  addedCompanyWatch: number;
  coRegisteredMobiles: string[];
  coRegisteredCards: string[];
  reseller: Reseller | null;
  customer: Partial<CustomerDTO> | null;
  agreements: CustomerAgreement[] | string[];
  savedBy: string | null;
  lostBy: string | null;
  savedAt: string | null;
  lostAt: string | null;
  manuallyAdded: number;
  automaticallyAdded: number;
  onboardedAt: string | null;
  deletedAt: string | null;
  comment: string | null;
  regretTimeExpired: boolean;
  remainingRegretDays: number;
  customerId: number;
  code: string | null;
  aRR: string;
  deleted: boolean;
} & CommonResponseFields;

export type AllCoProtectionsResponse<
  Properties extends keyof CoProtectionItem,
> = ApiCollectionResponse<Pick<CoProtectionItem, Properties>>;
//#endregion

//#region Firetext
export type FiretextItem = {
  '@type': 'Firetext';
  id: number;
  abTest: string;
  addressList: string;
  adminNote: string;
  antiregret: number;
  campaign: string;
  churnFiretextActive: number;
  churnFiretextReason: number;
  contact1: string;
  contact2: string;
  contact3: string;
  contact4: string;
  contact5: string;
  icc: string;
  icc2: string;
  imsi: string;
  imsi2: string;
  kamId: number | null;
  leadSource: string;
  msisdn: string;
  msisdn2: string;
  msg: string | null;
  operatorId: number;
  operatorId2: number;
  phone1: string;
  phone2: string;
  phone3: string;
  phone4: string;
  phone5: string;
  placement: string;
  pricePlanId: number;
  regDate: string;
  regretReason: number;
  regretSource: number;
  salesChannel: number | null;
  salesRep: string;
  sid: number;
  status: number;
  tb: number | null;
  unitReturned: number;
  upgradePolicy: number;
  winBack: number;
  sentDate: string | null;
  churnDate: string | null;
  manufactureDate: string | null;
  alarms: string[];
  handleableAlarms: string[];
  reseller: string | { id: number; name: string } | null;
  customer: Partial<CustomerDTO> | null;
  agreements: CustomerAgreement[] | string[];
  onboardedAt: string | null;
  deletedAt: string | null;
  comment: string | null;
  regretTimeExpired: boolean;
  remainingRegretDays: number;
  customerId: number;
  type: number | null;
  code: string | null;
  aRR: string;
  deleted: boolean;
} & CommonResponseFields;

export type FiretextsResponse<Properties extends keyof FiretextItem> =
  ApiCollectionResponse<Pick<FiretextItem, Properties>>;

export type FiretextHistoryResponse = ApiCollectionResponse<{
  id: number;
  dateTime: string;
  note: string;
  status: string;
  type: string;
  totalProcessTimeInSeconds: number;
  createdAt: string | null;
}>;
//#endregion

//#region KeyTag
export type KeyTagItem = {
  '@type': 'KeyTag';
  id: number;
  code: string | null;
  status: number;
  regDate: string;
  churnDate: string | null;
  pricePlanId: number;
  campaign: string | null;
  salesRep: string | null;
  addressList: string | null;
  leadSource: string | null;
  billing: number | null;
  billingMonth: number | null;
  lockChangeService: number | null;
  adminNote: string | null;
  tb: number | null;
  kamId: number | null;
  reseller: Reseller | null;
  customer: Partial<CustomerDTO> | null;
  deletedAt: string | null;
  regretTimeExpired: boolean;
  remainingRegretDays: number;
  customerId: number;
  type: number | null;
  aRR: string;
  agreements: CustomerAgreement[] | string[];
  deleted: boolean;
} & CommonResponseFields;

export type KeyTagsResponse<Properties extends keyof KeyTagItem> =
  ApiCollectionResponse<Pick<KeyTagItem, Properties>>;

export type KeyTagResponse<
  Properties extends keyof KeyTagItem = keyof KeyTagItem,
> = Pick<KeyTagItem, Properties>;
//#endregion

//#region Sticker

//#endregion

//#region Homegate
export type HomegatePrice = {
  '@id': string;
  vat: number;
  currency: string;
  priceInCurrency: number;
  priceWithVatInCurrency: number;
};

export type HomegateItem = {
  '@id': string;
  '@type': 'Homegate';
  id: number;
  status: number;
  regDate: string;
  salesRep: string;
  antiregret: boolean;
  winback: boolean;
  addressList: string | null;
  leadSource: string | null;
  abTest: string | null;
  regretSource: number | null;
  regretReason: number | null;
  churnDate: string | null;
  churnReason: number | null;
  adminNote: string | null;
  tb: number | null;
  kamId: number | null;
  sentDate: string | null;
  unitReturned: boolean;
  subscriptionForm: string | null;
  batchId: string | null;
  price: HomegatePrice | null;
  startFee: HomegatePrice | null;
  installationFee: HomegatePrice | null;
  hardwareSurcharge: HomegatePrice | null;
  customer: Partial<CustomerDTO> | null;
  reseller: Reseller | null;
  campaign: { '@id': string } | string | null;
  notes: string[];
  agreements: CustomerAgreement[] | string[];
  hasRegretTimeExpired: boolean;
  remainingRegretDays: number;
  closingDate: string;
  endDate: string;
  finalInvoiceAmount: string[];
  aRR: unknown[];
  type: number | null;
  pricePlanId: number;
};

export type HomegateResponse<
  Properties extends keyof HomegateItem = keyof HomegateItem,
> = ApiCollectionResponse<Pick<HomegateItem, Properties>>;
//#endregion

//#region Protection
// These types are for protection_campaigns,
// which is a legacy entity for protection campaigns
// TODO: Protection campaign types should be moved to types/campaigns.ts
export type ProtectionCampaignItem = {
  id: number;
  name: string;
  active: boolean;
  pricePlanId: number | null;
  type: number;
  startFee: string;
  subscriptionFee: string;
  contractMonths: number;
  units: number;
  specialHeader: string;
  specialRow1: string;
  specialRow2: string;
  specialRow3: string;
  products: [string];
  customerType?: number;
} & CommonResponseFields;

export type ProtectionCampaignsResponse =
  ApiCollectionResponse<ProtectionCampaignItem>;

export type ProtectionItem = {
  id: number;
  ['@type']: 'Protection';
  type: number;
  abTest: string;
  addressList: string;
  adminNote: string | null;
  agreements: CustomerAgreement[] | string[];
  antiregret: number;
  billing: number;
  campaign: string;
  churnActive: number;
  churnDate: string | null;
  churnReason: number;
  customer: Partial<CustomerDTO> | null;
  customerId: number;
  email1: string | null;
  email2: string | null;
  email3: string | null;
  email4: string | null;
  email5: string | null;
  kamId: number | null;
  leadSource: string;
  name1: string | null;
  name2: string | null;
  name3: string | null;
  name4: string | null;
  name5: string | null;
  phone1: string | null;
  phone2: string | null;
  phone3: string | null;
  phone4: string | null;
  phone5: string | null;
  pin1: string;
  pin2: string | null;
  pin3: string | null;
  pin4: string | null;
  pin5: string | null;
  pricePlanId: number;
  protectionCampaign: string;
  regDate: string;
  registerCards: RegisteredCard[];
  registeredMobiles: RegisteredMobile[];
  regretReason: number;
  regretSource: number;
  regretTimeExpired: boolean;
  remainingRegretDays: number;
  renewalLetter: string | null;
  reseller: Reseller | null;
  salesChannel: number;
  salesRep: string;
  sentCodeBisnode: number;
  status: number;
  tb: number | null;
  winBack: number;
};

export type ProtectionsResponse<
  Properties extends keyof ProtectionItem = keyof ProtectionItem,
> = ApiCollectionResponse<Pick<ProtectionItem, Properties>>;
//#endregion

//#region Sinne
export const contactPerson = z.object({
  name: z.string().nullable(),
  phone: z.string().nullable(),
  relation: z.string().optional().nullable(),
  id: z.string().optional().nullable(),
  alarmNotification: z.boolean(),
  isPrimaryContact: z.boolean(),
});

export type ContactPerson = z.infer<typeof contactPerson>;
export type PartialContactPerson = Pick<
  ContactPerson,
  'name' | 'phone' | 'id' | 'relation'
>;

export type FiretextContactPerson = { name: string; phone: string };
//#region Sinne

export const sinneDeviceStatusMap = {
  IN_STORAGE: 0,
  WITH_CUSTOMER: 1,
  REFURBISHMENT: 2,
  LOST: 3,
  DISCARDED: 4,
  REPORTED_BROKEN: 5,
};
export type DeviceStatusMap = typeof sinneDeviceStatusMap;
export type DeviceStatus = keyof DeviceStatusMap;
export type DeviceStatusCode = DeviceStatusMap[DeviceStatus];

export const templateSchema = z.object({
  '@id': z.string().optional(),
  id: z.string().optional(),
  hardwareSurcharge: z.string(),
  installationFee: z.string(),
  price: z.string(),
  startFee: z.string(),
});

export type Template = z.infer<typeof templateSchema>;

export enum SinneRegretSourceMap {
  'Ej ånger',
  Samtal,
  Brev,
  'E-post',
  Återförsäljar,
}

export const sinneRegretSourceMap = z.tuple([
  z.literal('Ej ånger'),
  z.literal('Samtal'),
  z.literal('Brev'),
  z.literal('E-post'),
  z.literal('Återförsäljare'),
]);

export const sinneStatusMap = z.object({
  1: z.literal('Aktiv'),
  2: z.literal('Ånger'),
  3: z.literal('Makulerad'),
  4: z.literal('Churn'),
  5: z.literal('Goodwill'),
  6: z.literal('Nyregistrerad'),
  7: z.literal('Produkten är en kampanj-mall'),
  8: z.literal('Konfigurerar enhet'),
  10: z.literal('Enhet rapporterad: Sönder'),
});

export const sinneRegretReasonMap = z.object({
  '1': z.literal('Ej ånger'),
  '2': z.literal('Okänt'),
  '3': z.literal('Övrigt'),
  '4': z.literal('Ångerblankett'),
  '5': z.literal('Innan välkomstbrev'),
  '6': z.literal('Svarsvägrare'),
  '7': z.literal('Förstår ej tjänsten'),
  '8': z.literal('Ej avtalsmedveten'),
  '9': z.literal('Info. Sälj'),
  '10': z.literal('Sambo'),
  '11': z.literal('Ekonomiskt'),
  '12': z.literal('Har annan aktör'),
  '13': z.literal('Planerar annan aktör'),
  '14': z.literal('Har via försäkring'),
  '15': z.literal('Ånger efter dag 14'),
});

export type SinneRegretReasonMap = z.infer<typeof sinneRegretReasonMap>;

export const sinneChurnReasonMap = z.object({
  '1': z.literal('Ej churn'),
  '2': z.literal('Okänt'),
  '3': z.literal('Övrigt'),
  '4': z.literal('Har ej råd'),
  '5': z.literal('Utebliven betalning'),
  '6': z.literal('Missade ångra'),
  '7': z.literal('Förstår ej tjänsten'),
  '8': z.literal('Ej avtalsmedveten'),
  '9': z.literal('Avliden'),
  '10': z.literal('Sambo'),
  '11': z.literal('Vill ej ha'),
  '12': z.literal('Skaffat via annan aktör'),
  '13': z.literal('Nedlagd verksamhet'),
  '14': z.literal('Har via försäkring'),
  '15': z.literal('Ej behov'),
  '16': z.literal('Missnöjd'),
  '17': z.literal('God man eller förvaltare'),
  '18': z.literal('Flyttrelaterat'),
});
export type sinneChurnReasonMap = z.infer<typeof sinneChurnReasonMap>;

export const sinneEventSchema = z.object({
  deviceId: z.string(),
  event: sinneEventStatus,
  occuredAt: z.string(),
  sinne: z.string(),
  handleableAlarm: z.string().nullable(),
});
export type SinneEvent = z.infer<typeof sinneEventSchema>;

/* When doing getting sinnes notes consists of {@id: "", @type: ""}, from a put an Array<string> is returned  */
export const sinneNotesSchema = z.array(
  z.union([z.object({ '@id': z.string(), '@type': z.string() }), z.string()]),
);
export type SinneNotes = z.infer<typeof sinneNotesSchema>;
export const sinneSchema = z.object({
  '@id': z.string(),
  '@type': z.literal('Sinne'),
  abTest: z.string().nullable(),
  accountManager: z
    .object({ '@id': z.string(), '@type': z.string() })
    .nullable(),
  addressList: z.string().nullable(),
  adminNote: z.string().nullable(),
  agreements: z.array(
    z.object({
      '@id': z.string(),
      '@type': z.string(),
      id: z.number(),
      type: z.literal('sinne'),
      name: z.string(),
      uri: z.string(),
      fileType: z.string(),
    }),
  ),
  antiregret: z.boolean(),
  aRR: z.string(),
  billing: z.boolean(),
  campaign: z.object({
    '@id': z.string(),
    '@type': z.string(),
    id: z.number().int(),
    contractMonths: z.number().int(),
    freeMonths: z.number().int(),
    noticePeriod: z.number().int(),
    name: z.string(),
  }),
  churnDate: z.string().nullable(),
  churnReason: z.number().int().nullable(),
  churnReasonMap: sinneChurnReasonMap,
  contactPeople: z.array(contactPerson),
  customer: basicCustomerDataSchema,
  deviceId: z.string().nullable(),
  handleableAlarms: z.array(
    z.object({ '@id': z.string(), '@type': z.string() }),
  ),
  id: z.number().int().positive(),
  installationFee: priceItem,
  isTemplate: z.boolean(),
  leadSource: z.string().nullable(),
  notes: z.array(
    z.union([z.object({ '@id': z.string(), '@type': z.string() }), z.string()]),
  ),
  // notes: z.union([z.array(z.object({ '@id': z.string(), '@type': z.string() })), z.array(z.string())]),
  onboardedAt: z.string().nullable(),
  partnerManager: z
    .object({ '@id': z.string(), '@type': z.string() })
    .nullable(),
  placement: z.string().nullable(),
  price: priceItem,
  regDate: z.string(),
  regretReason: z.number().int().nullable(),
  regretReasonMap: sinneRegretReasonMap,
  regretSource: z.number().int().nullable(),
  regretSourceMap: sinneRegretSourceMap,
  regretTimeExpired: z.boolean(),
  remainingRegretDays: z.number().int(),
  reseller: z.object({ '@id': z.string(), '@type': z.string() }).nullable(),
  salesRep: z.string(),
  sentDate: z.string().nullable(),
  sinneEvents: z.array(z.object({ '@id': z.string(), '@type': z.string() })), // TODO: api should be able to responde with sinneEventSchema here
  startFee: priceItem,
  status: sinneStatusKey,
  statusMap: sinneStatusMap,
  tb: z.number().nullable(),
  testedAt: z.string().nullable(),
  testedBy: z.string().nullable(),
  unitReturned: z.boolean(),
  winback: z.boolean(),
});

export type SinneItem = z.infer<typeof sinneSchema>;

const excelContactPersonsFields = z.object({
  name1: z.string(),
  name2: z.string(),
  name3: z.string(),
  name4: z.string(),
  name5: z.string(),
  phone1: z.string(),
  phone2: z.string(),
  phone3: z.string(),
  phone4: z.string(),
  phone5: z.string(),
  relation1: z.string(),
  relation2: z.string(),
  relation3: z.string(),
  relation4: z.string(),
  relation5: z.string(),
});
export type ExcelContactPersonFields = z.infer<
  typeof excelContactPersonsFields
>;
export const sinneWithouthContactPersonSchema = z.object({
  abTest: z.string(),
  accountManager: z.string(),
  address: z.string(),
  address2: z.string(),
  addressList: z.string(),
  adminNote: z.string(),
  campaign: z.string(),
  category: z.union([z.literal(2), z.literal(3)]),
  city: z.string(),
  companyName: z.string(),
  email: z.string().email(),
  firstName: z.string(),
  lastName: z.string(),
  leadSource: z.string(),
  mobile: z.string(),
  partnerCompany: z.string(),
  partnerManager: z.string(),
  phone: z.string(),
  pin: z.string(),
  project: z.string(),
  provision: z.number(),
  regDate: z.string(),
  reseller: z.string(),
  salesRep: z.string(),
  tb: z.number(),
  zip: z.string(),
  churnDate: z.string().optional(),
  churnReason: z.number().optional(),
  placement: z.string().optional().nullable(),
});

export const createSinneExcelValues = sinneWithouthContactPersonSchema.merge(
  excelContactPersonsFields,
);

export type CreateSinneExcelValues = z.infer<typeof createSinneExcelValues>;
export type CreateSinneFormValues = CreateSinneExcelValues & {
  templates: Template[];
};
export type CreateSinneDTO = {
  contactPeople: PartialContactPerson[];
} & z.infer<typeof sinneWithouthContactPersonSchema> & {
    customer: string;
    templates: Template[];
  };

export type FlatSinneDeviceInfo = {
  batteryLevel: string;
  connected: boolean;
  deviceManufactureDate: string;
  deviceType: string;
  firmwareVersion: string;
  imei: string;
  lastTemperature: number;
  mac: string;
  signalLevel: string;
  syncedOn: string;
  sinneEntityId: number | null;
  sim: {
    operator: string;
    MSISDN: string;
    IMSI: string;
    ICC: string;
  };
};
export type SinneDeviceInfo = {
  device: FlatSinneDeviceInfo;
};

export type SimSubscriptionStatus =
  | 'ACTIVE'
  | 'Deactivated'
  | 'Pause'
  | 'Terminated';

export type SinneDeviceSimInfo = {
  error: boolean;
  message: string;
  data: {
    simSubscriptionStatus: SimSubscriptionStatus;
    imei: string;
  };
};

export type SinneDeviceSimInfoV2 = {
  error: boolean;
  message: string;
  simSubscriptionStatus: SimSubscriptionStatus;
  imei: string;
};

export type SinnesResponse<
  Properties extends keyof SinneItem = keyof SinneItem,
> = ApiCollectionResponse<Pick<SinneItem, Properties>>;

//#endregion

export type Reseller = {
  '@id': string;
  id: number;
  reseller: number;
  name: string;
};

export type RegisteredCard = {
  id: number;
  fullName: string;
  pin: string;
  type: string;
  brand: string;
  ks: string;
};

export type RegisteredMobile = {
  id: number;
  fullName: string;
  pin: string;
  imei: string;
  msisdn: string;
  operator: string;
};

const agreementsParamSchema = z.array(
  z.object({
    '@id': z.string(),
    '@type': z.string(),
    id: z.number().int().positive().optional(),
    name: z.string().optional(),
    fileType: z.string().optional(),
  }),
);

const resellerParamSchema = z.object({
  id: z.number().int(),
  name: z.string(),
});

const currencySchema = z.enum(['SEK']);
const priceSchema = z.object({
  '@id': z.string(),
  '@type': z.string(),
  vat: z.number(),
  currency: currencySchema,
  priceInCurrency: z.number(),
  priceWithVatInCurrency: z.number(),
  type: z.string(),
});

const homeGatePriceSchema = priceSchema.omit({ type: true });

//#region schemas used (mostly) in getServiceDetails

export const stickersParamSchema = z.object({
  '@id': z.string(),
  '@type': z.literal('Sticker'),
  adminNote: z.string().nullable(),
  billing: z.union([z.literal(0), z.literal(1)]),
  billingMonth: z.number().int().min(0).max(12).nullable(),
  churnDate: z.string().nullable(),
  code: z.string(),
  customer: basicCustomerDataSchema,
  id: z.number(),
  kamId: z.number().int().nullable(),
  pricePlanId: stickerPricePlanSchema.keySchema,
  regDate: z.string().nullable(),
  regretTimeExpired: z.boolean(),
  status: stickerStatusKey,
  tb: z.number().nullable(),
});
export type StickersParamSchema = z.infer<typeof stickersParamSchema>;

export const keyTagParamSchema = z.object({
  '@id': z.string(),
  '@type': z.literal('KeyTag'),
  addressList: z.string().nullable(),
  adminNote: z.string().nullable(),
  billing: z.number().int().nullable(),
  billingMonth: z.number().int().nullable(),
  campaign: z.string().nullable(),
  churnDate: z.string().nullable(),
  code: z.string().nullable(),
  id: z.number().int().positive(),
  kamId: z.number().int().nullable(),
  leadSource: z.string().nullable(),
  pricePlanId: z.number().int(),
  regDate: z.string(),
  regretTimeExpired: z.boolean(),
  salesRep: z.string().nullable(),
  status: keyTagStatusKey,
  tb: z.number().int().nullable(),
  customer: basicCustomerDataSchema,
  reseller: resellerParamSchema,
});
export type KeyTagParamSchema = z.infer<typeof keyTagParamSchema>;

export const coProtectionParamSchema = z.object({
  '@id': z.string(),
  '@type': z.literal('CoProtection'),
  abTest: z.string(),
  addressList: z.string(),
  adminNote: z.string().nullable(),
  agreements: agreementsParamSchema,
  antiregret: z.number(),
  billing: z.number().int().min(0).max(1),
  campaign: z.string(),
  churnDate: z.string().nullable(),
  churnReason: z.number().int(),
  coRegisteredCards: z.array(
    z.object({
      brand: z.string(),
      fullName: z.string(),
      id: z.number().int().positive(),
      ks: z.string(),
      pin: z.string(),
      type: z.string(),
    }),
  ),
  coRegisteredMobiles: z.array(
    z.object({
      fullName: z.string(),
      id: z.number().int().positive(),
      imei: z.string(),
      msisdn: z.string(),
      operator: z.string(),
      pin: z.string(),
    }),
  ),
  customer: basicCustomerDataSchema,
  email: z.string().nullable(),
  id: z.number().int().positive(),
  kamId: z.number().int().nullable(),
  leadSource: z.string(),
  name: z.string().nullable(),
  onboardedAt: z.string().nullable(),
  phone: z.string().nullable(),
  pin: z.string(),
  pricePlanId: z.number().int(),
  monitoringPlan: z.number().int(),
  regDate: z.string(),
  regretReason: z.number().int().min(0).max(12),
  regretSource: z.number().int().min(0).max(4),
  regretTimeExpired: z.boolean(),
  reseller: resellerParamSchema,
  salesRep: z.string(),
  status: coProtectionStatusKey,
  tb: z.number().nullable(),
  type: z.number().int(),
  winBack: z.number(),
});
export type CoProtectionParamSchema = z.infer<typeof coProtectionParamSchema>;

export const firetextsParamSchema = z.object({
  '@id': z.string(),
  '@type': z.literal('Firetext'),
  abTest: z.string(),
  addressList: z.string(),
  adminNote: z.string().nullable(),
  agreements: agreementsParamSchema,
  antiregret: z.number().int(),
  campaign: z.string(),
  churnDate: z.string().nullable(),
  churnFiretextActive: z.number().int(), // TODO: Define enum
  churnFiretextReason: z.number().int(), // TODO: Define enum
  contact1: z.string(),
  contact2: z.string(),
  contact3: z.string(),
  contact4: z.string(),
  contact5: z.string(),
  customer: basicCustomerDataSchema,
  icc: z.string(),
  icc2: z.string(),
  id: z.number().int().positive(),
  kamId: z.number().nullable(),
  leadSource: z.string(),
  manufactureDate: z.string().nullable(),
  msg: z.string().nullable(),
  msisdn: z.string(),
  msisdn2: z.string(),
  operatorId: z.number().int(),
  operatorId2: z.number().int(),
  phone1: z.string(),
  phone2: z.string(),
  phone3: z.string(),
  phone4: z.string(),
  phone5: z.string(),
  placement: z.string(),
  pricePlanId: firetextPricePlanSchema.keySchema,
  regDate: z.string(),
  regretReason: z.number().int(),
  regretSource: z.number().int(),
  regretTimeExpired: z.boolean(),
  remainingRegretDays: z.number().int(),
  reseller: resellerParamSchema,
  salesRep: z.string(),
  sentDate: z.string().nullable(),
  status: firetextStatusKey,
  tb: z.number().nullable(),
  unitReturned: z.number().int(),
  upgradePolicy: z.number().int(),
  winBack: z.number().int(),
});
export type FiretextParamSchema = z.infer<typeof firetextsParamSchema>;

export const protectionParamSchema = z.object({
  '@id': z.string(),
  '@type': z.literal('Protection'),
  abTest: z.string(),
  addressList: z.string(),
  adminNote: z.string().nullable(),
  agreements: agreementsParamSchema,
  antiregret: z.number().int(),
  billing: z.number().int(),
  campaign: z.string(),
  churnDate: z.string().nullable(),
  churnReason: z.number().int(), // TODO: Define enum,
  customer: basicCustomerDataSchema,
  email1: z.string().nullable(),
  email2: z.string().nullable(),
  email3: z.string().nullable(),
  email4: z.string().nullable(),
  email5: z.string().nullable(),
  id: z.number().int().positive(),
  kamId: z.number().nullable(),
  leadSource: z.string(),
  name1: z.string().nullable(),
  name2: z.string().nullable(),
  name3: z.string().nullable(),
  name4: z.string().nullable(),
  name5: z.string().nullable(),
  phone1: z.string().nullable(),
  phone2: z.string().nullable(),
  phone3: z.string().nullable(),
  phone4: z.string().nullable(),
  phone5: z.string().nullable(),
  pin1: z.string().nullable(),
  pin2: z.string().nullable(),
  pin3: z.string().nullable(),
  pin4: z.string().nullable(),
  pin5: z.string().nullable(),
  pricePlanId: protectionPricePlanSchema.keySchema,
  protectionCampaign: z.string().or(
    z.object({
      '@id': z.string(),
      '@type': z.string(),
    }),
  ),
  protectionsCampaign: z.object({
    '@id': z.string(),
    '@type': z.string(),
    id: z.number().int(),
    contractMonths: z.number().int(),
    freeMonths: z.number().int(),
    name: z.string(),
    noticePeriod: z.number().int(),
  }),
  regDate: z.string(),
  regretReason: z.number().int(),
  regretSource: z.number().int(),
  regretTimeExpired: z.boolean(),
  remainingRegretDays: z.number().int(),
  salesRep: z.string(),
  status: protectionStatusKey,
  tb: z.number().nullable(),
  type: z.number().int().nullable(),
  winBack: z.number().int(),
  registeredMobiles: z.array(
    z.object({
      fullName: z.string(),
      id: z.number().int().positive(),
      imei: z.string(),
      msisdn: z.string(),
      operator: z.string(),
      pin: z.string(),
    }),
  ),
  registeredCards: z.array(
    z.object({
      brand: z.string(),
      fullName: z.string(),
      id: z.number().int().positive(),
      ks: z.string(),
      pin: z.string(),
      type: z.string(),
    }),
  ),
  reseller: resellerParamSchema,
});
export type ProtectionParamSchema = z.infer<typeof protectionParamSchema>;
export const homegateParamSchema = z.object({
  '@id': z.string(),
  '@type': z.literal('Homegate'),
  abTest: z.string().nullable(),
  addressList: z.string().nullable(),
  adminNote: z.string().nullable(),
  agreements: agreementsParamSchema,
  antiregret: z.boolean(),
  batchId: z.string().nullable(),
  campaign: z.object({
    '@id': z.string(),
    '@type': z.string(),
    id: z.number().int(),
    contractMonths: z.number().int(),
    freeMonths: z.number().int(),
    noticePeriod: z.number().int(),
    name: z.string(),
  }),
  churnDate: z.string().nullable(),
  churnReason: z.number().int().nullable(),
  closingDate: z.string(),
  customer: basicCustomerDataSchema,
  endDate: z.string(),
  finalInvoiceAmount: z.object({
    price: z.number(),
    currency: currencySchema,
  }),
  hardwareSurcharge: homeGatePriceSchema,
  hasRegretTimeExpired: z.boolean(),
  id: z.number().int().positive(),
  installationFee: homeGatePriceSchema,
  kamId: z.number().int().nullable(),
  leadSource: z.string().nullable(),
  notes: z.array(z.object({ '@id': z.string(), '@type': z.string() })),
  price: homeGatePriceSchema,
  regDate: z.string(),
  regretReason: z.number().int().nullable(), // Define enum
  regretSource: z.number().int().nullable(), // Define enum
  remainingRegretDays: z.number().int(),
  reseller: z.object({
    '@id': z.string(),
    '@type': z.string(),
  }),
  salesRep: z.string(),
  sentDate: z.string().nullable(),
  startFee: homeGatePriceSchema,
  status: homegateStatusKey,
  subscriptionForm: z.string().nullable(),
  tb: z.number().nullable(),
  unitReturned: z.boolean(),
  winback: z.boolean(),
});
export type HomegateParamSchema = z.infer<typeof homegateParamSchema>;

export type ServiceParams =
  | FiretextParamSchema
  | CoProtectionParamSchema
  | KeyTagParamSchema
  | ProtectionParamSchema
  | StickersParamSchema
  | HomegateParamSchema
  | SinneItem;

export type ServicesWithAgreements =
  | CoProtectionParamSchema
  | FiretextParamSchema
  | HomegateParamSchema
  | ProtectionParamSchema
  | SinneItem;

export const serviceDetailsParams = {
  firetexts: firetextsParamSchema,
  co_protections: coProtectionParamSchema,
  homegates: homegateParamSchema,
  key_tags: keyTagParamSchema,
  protections: protectionParamSchema,
  sinnes: sinneSchema,
  stickers: stickersParamSchema,
};

//#endregion

const createFiretextDTOSchema = firetextsParamSchema.omit({
  id: true,
  '@id': true,
  '@type': true,
});

export type CreateFiretextDTO = z.infer<typeof createFiretextDTOSchema>;
const createProtectionDTOSchema = protectionParamSchema.omit({
  id: true,
  '@id': true,
  '@type': true,
});
export type CreateProtectionDTO = z.infer<typeof createProtectionDTOSchema>;

const createCoProtectionDTOSchema = coProtectionParamSchema.omit({
  id: true,
  '@id': true,
  '@type': true,
});
export type CreateCoProtectionDTO = z.infer<typeof createCoProtectionDTOSchema>;

const createKeyTagDTOSchema = keyTagParamSchema.omit({
  id: true,
  '@id': true,
  '@type': true,
});
export type CreateKeyTagDTO = z.infer<typeof createKeyTagDTOSchema>;

const createStickerDTOSchema = stickersParamSchema.omit({
  id: true,
  '@id': true,
  '@type': true,
});
export type CreateStickerDTO = z.infer<typeof createStickerDTOSchema>;

export type CreateDTOUnion =
  | CreateFiretextDTO
  | CreateProtectionDTO
  | CreateCoProtectionDTO
  | CreateKeyTagDTO
  | CreateStickerDTO;

export const servicesWithCampaigns = z.enum([
  'homegate',
  'protections', // V2 of protection campaign. Because of the naming discrepancy we can't just map the service name to the campaign name. other services map service_campaign
  'protection',
  'sinne',
]);

export type ServiceWithCampaigns = z.infer<typeof servicesWithCampaigns>;

export type BulkServiceRegistrationResult = {
  '@id': string;
  '@type': string;
  id: number;
  uuid: string;
  rowCount: number;
  insertedRows: number;
  notInsertedRows: number;
  errors: {
    pin: string;
    error: string;
  }[];
  status: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
};
