import { Divider, Paper } from '@mui/material';

import { ExistingCustomer } from '@/types/customers';

import { AddService } from './AddService';
import { CustomerServicesList } from './CustomerServicesList';

type Props = {
  customer: ExistingCustomer;
};

export const CustomerServicesTab = ({ customer }: Props) => {
  return (
    <Paper>
      <CustomerServicesList customerId={customer.id} />
      <Divider />
      <AddService customer={customer} />
    </Paper>
  );
};
