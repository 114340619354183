import React from 'react';

import { Alert, Box, Card, LinearProgress, styled } from '@mui/material';
import { endOfMonth, startOfMonth } from 'date-fns';
import { useParams } from 'react-router-dom';

import { PageWrapper } from '@/components';
import { BasicAlert } from '@/components/BasicAlert';
import { Crumb } from '@/components/Breadcrumbs';
import useUser from '@/hooks/useUser';
import { BdmView } from '@/modules/ResellerPortal/components/BdmView';
import { ResellerView } from '@/modules/ResellerPortal/components/ResellerView';
import { ToolBar } from '@/modules/ResellerPortal/components/ToolBar';
import { useGetResellerById } from '@/modules/ResellerPortal/hooks/useGetResellerById';
import {
  GetStatsParams,
  useGetStats,
} from '@/modules/ResellerPortal/hooks/useGetStats';

const ResellerPortalStats = () => {
  const { id, roles } = useUser();
  const params = useParams<{ id: string }>();
  const isReseller = roles.includes('ROLE_RESELLER');
  const resellerId = params.id;

  const [fetchParams, setFetchParams] = React.useState<GetStatsParams>({
    start: startOfMonth(new Date()),
    end: endOfMonth(new Date()),
    resolution: 'daily',
    serviceName: '',
  });

  const [pageTitle, setPageTitle] = React.useState<string>('Statistik');
  const [crumb, setCrumb] = React.useState<Crumb[]>([]);

  const { data, status, isFetching } = useGetStats(fetchParams);
  const { data: reseller } = useGetResellerById(resellerId || id, {
    enabled: !!resellerId || !!(isReseller && id),
  });

  React.useEffect(() => {
    if (isReseller) {
      // If a reseller is logged in and viewing their own stats (same view as when a bdm is watching a specific reseller)
      setPageTitle(`Min statistik - ${reseller?.name || ''}`);
      setCrumb([{ label: 'Statistik' }]);
      setFetchParams((prevState) => {
        delete prevState.bdm;
        return {
          ...prevState,
          reseller: id,
        };
      });
    }
    if (!isReseller && resellerId) {
      // If a BDM is logged in and a resellerId is provided in the url (details about a specific reseller)
      setPageTitle(`Detaljerad statistik - ${reseller?.name || ''}`);
      setCrumb([
        { label: 'Återförsäljare', url: '/reseller-portal/bdm/statistics' },
        { label: 'Statistik' },
      ]);
      setFetchParams((prevState) => {
        delete prevState.bdm;
        return {
          ...prevState,
          reseller: parseInt(resellerId, 10),
        };
      });
    }
    if (!isReseller && !resellerId) {
      // If a BDM is logged in and viewing overview for all resellers
      setPageTitle('Statistik - Mina återförsäljare');
      setCrumb([{ label: 'Återförsäljare' }, { label: 'Statistik' }]);
      setFetchParams((prevState) => {
        delete prevState.reseller;
        return {
          ...prevState,
          bdm: id,
        };
      });
    }
  }, [isReseller, resellerId, reseller]);

  return (
    <PageWrapper margin={false} pageTitle={pageTitle} breadCrumbs={crumb}>
      <StyledBox maxWidth="lg">
        <ToolBar setFetchParams={setFetchParams} fetchParams={fetchParams} />
        {isFetching && <LinearProgress />}
        {!isFetching && data === undefined && (
          <Card>
            <Alert severity="info">Ingen data</Alert>
          </Card>
        )}
        {status === 'error' && <BasicAlert />}
        {status === 'success' && (
          <>
            {!isReseller && !resellerId && <BdmView data={data} />}
            {(isReseller || resellerId) && <ResellerView data={data} />}
          </>
        )}
      </StyledBox>
    </PageWrapper>
  );
};

const StyledBox = styled(Box)(({ theme }) => ({
  '& > *': {
    marginBottom: theme.spacing(2),
  },
}));

export default ResellerPortalStats;
