import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
} from '@mui/material';
import upperFirst from 'lodash/upperFirst';

import { BasicAlert } from '@/components/BasicAlert';
import { SimpleTable } from '@/components/SimpleTable';

import { SalesManagerUser } from '../../types';
import { useGetCurrentSalesManager } from './useGetCurrentSalesManager';

type Row = {
  name: string;
  coProtectionOnboardingPercentage: string;
};
type Column = {
  label: string;
  renderer: (row: Row) => string;
};

export const SalesManagerOnboardingStatistics = () => {
  const { data, status } = useGetCurrentSalesManager();

  const currentView = {
    pending: <LinearProgress />,
    error: <BasicAlert />,
    success: <SuccessView data={data} />,
  };

  return currentView[status];
};

const SuccessView = ({ data }: { data: SalesManagerUser | undefined }) => {
  const columns: Column[] = [
    {
      label: 'Namn',
      renderer: ({ name }) => name,
    },
    {
      label: 'Bolagsbevakning',
      renderer: ({ coProtectionOnboardingPercentage }) =>
        coProtectionOnboardingPercentage,
    },
  ];

  const hasTeams = data?.managedTeams?.length ?? 0;
  if (hasTeams) {
    return (
      <Grid container spacing={2}>
        {data?.managedTeams.map((team) => (
          <Grid item xs={12} key={team.name}>
            <Card>
              <CardHeader title={`Team: ${upperFirst(team.name)}`} />
              <CardContent>
                <SimpleTable rows={team.members} columns={columns} />
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  }
  return <Alert severity="info">Du har inga team</Alert>;
};
