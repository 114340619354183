import { useQuery } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

import { getPublicSettings } from '@/api';
import { queryKeys } from '@/constants/queryKeys';
import { PublicSettings } from '@/types/publicSettings';

const publicSettingsAtom = atomWithStorage<PublicSettings | null>(
  'publicSettings',
  null,
);
export default function usePublicSettings() {
  const [publicSettings, setPublicSettings] = useAtom(publicSettingsAtom);
  const { data, isSuccess, ...rest } = useQuery({
    queryKey: [queryKeys.getPublicSettings],
    queryFn: getPublicSettings,
    staleTime: Infinity,
  });

  if (isSuccess) {
    setPublicSettings(data);
  }

  return { publicSettings, isSuccess, ...rest };
}
