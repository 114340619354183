import React, { Fragment, useEffect, useState } from 'react';

import { Button, Grid } from '@mui/material';
import { useHotkeys } from 'react-hotkeys-hook';
import { useNavigate } from 'react-router-dom';

import { Can } from '@/components/Can';
import { ContactButton } from '@/components/ContactButton';
import { useAuth } from '@/hooks/useAuth';
import { useModal } from '@/hooks/useModal';
import { KebabCaseServiceName } from '@/modules/common/utils/services';
import { DeleteServiceModal } from '@/modules/services/components/Edit/DeleteServiceModal';
import { SERVICES_WITH_CONFIG } from '@/modules/services/constants';
import { CreateTaskModal } from '@/modules/tasks';
import { TaskTeamTypes } from '@/modules/tasks/constants/tasksModalsConstants';
import { ModalTypes } from '@/state/modalManagementAtom';
import { BasicCustomerData } from '@/types/customers';

type Props = {
  customer: BasicCustomerData;
  serviceId: number | string;
  serviceName: KebabCaseServiceName;
  onOpenServiceTransfer: () => void;
  onOpenAgreementsModal: () => void;
};

export const ServiceEditNavigation = ({
  customer,
  serviceId,
  serviceName,
  onOpenServiceTransfer,
  onOpenAgreementsModal,
}: Props) => {
  const { openModal } = useModal();

  const navigate = useNavigate();

  const { getAuthenticatedUserRoles } = useAuth();
  const userRoles = getAuthenticatedUserRoles();

  const [deleteServiceModalOpen, setDeleteServiceModalOpen] =
    React.useState(false);
  const [createTaskModalOpen, setCreateTaskModalOpen] = React.useState(false);
  const [createTaskModalDefaults, setCreateTaskModalDefaults] = useState<
    | {
        team: number;
        status: number;
      }
    | undefined
  >(undefined);

  useEffect(() => {
    if (createTaskModalOpen === false) {
      setCreateTaskModalDefaults(undefined);
    }
  }, [createTaskModalOpen]);

  //#region hotkeys
  useHotkeys(
    'alt+s',
    () => {
      if (customer.id) {
        openModal({
          modalType: ModalTypes.SMS_CUSTOMER,
          extra: { customerId: customer.id },
        });
      }
    },
    [customer],
  );

  useHotkeys(
    'alt+m',
    () => {
      openModal({
        modalType: ModalTypes.MAIL_CUSTOMER,
        extra: { customerId: customer.id },
      });
    },
    {},
    [customer],
  );

  useHotkeys(
    'alt+k',
    () => {
      openModal({
        modalType: ModalTypes.ADD_COST,
        extra: { customerId: customer.id },
      });
    },
    {},
    [customer],
  );

  useHotkeys(
    'alt+t',
    () => {
      if (customer) {
        setCreateTaskModalDefaults({
          team: TaskTeamTypes.CUSTOMER_SUPPORT,
          status: 0,
        });
        setCreateTaskModalOpen(true);
      }
    },
    {},
    [customer],
  );

  useHotkeys(
    'alt+h',
    () => {
      if (customer) {
        openModal({
          modalType: ModalTypes.CREATE_ADMIN_TASK,
          extra: { customerId: customer.id, pin: customer.pin },
        });
      }
    },
    {},
    [customer],
  );

  //#endregion

  function navigateToUserPage() {
    navigate(`/customer/${customer.id}`);
  }

  return (
    <Fragment>
      <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
        <Grid item>
          {SERVICES_WITH_CONFIG.includes(serviceName) && (
            <Button
              color="primary"
              variant="contained"
              onClick={onOpenAgreementsModal}
            >
              Avtal
            </Button>
          )}
        </Grid>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            onClick={navigateToUserPage}
          >
            Gå till kundkort
          </Button>
        </Grid>
        <Can
          userRoles={userRoles!}
          action="services.transfer"
          yes={() => (
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={onOpenServiceTransfer}
              >
                Överlåt
              </Button>
            </Grid>
          )}
        />
        <Grid item>
          {customer.id && (
            <ContactButton
              customerId={customer.id}
              customerPin={customer.pin}
            />
          )}
        </Grid>
        <Can
          userRoles={userRoles!}
          action="services.hardDelete"
          yes={() => (
            <Grid item>
              <Button
                color="error"
                variant="contained"
                onClick={() => setDeleteServiceModalOpen(true)}
              >
                Ta bort
              </Button>
            </Grid>
          )}
        />
        <CreateTaskModal
          open={createTaskModalOpen}
          handleVisibility={() => setCreateTaskModalOpen(!createTaskModalOpen)}
          customer={customer}
          isBarringService={false}
          defaults={createTaskModalDefaults}
        />
        <DeleteServiceModal
          open={deleteServiceModalOpen}
          onClose={() => setDeleteServiceModalOpen(false)}
          serviceId={serviceId}
          serviceName={serviceName}
        />
      </Grid>
    </Fragment>
  );
};
