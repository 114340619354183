import React, { useMemo } from 'react';

import { ArrowForwardOutlined, InfoOutlined } from '@mui/icons-material';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import {
  Card,
  CardHeader,
  Divider,
  LinearProgress,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import camelCase from 'lodash/camelCase';
import kebabCase from 'lodash/kebabCase';
import { useNavigate } from 'react-router-dom';

import { BasicAlert } from '@/components/BasicAlert';
import { ServiceIcon } from '@/components/icons';
import { usePrivateSettings } from '@/hooks';
import { useActiveServices } from '@/modules/common/hooks/useActiveServices';
import { TooltipContent } from '@/modules/customer/components/CustomerOverview/Tooltip/TooltipContent';
import { ExistingCustomer } from '@/types/customers';
import { CamelCaseServiceName } from '@/types/services';

import { resizeAllCards } from '../../utils/resizeCards';
import { ChurnButton } from './ChurnButton';

type Props = {
  customer: ExistingCustomer;
};

// TODO: Use the component from ServiceInfoTooltip.tsx
const ServiceInfoTooltip = styled(Tooltip)(({ theme }) => ({
  tooltip: {
    backgroundColor: theme.palette.background.paper,
    padding: 0,
    fontSize: theme.typography.pxToRem(12),
  },
}));

export const ActiveServicesSummaryList = ({ customer }: Props) => {
  const { servicesNames } = usePrivateSettings();
  const navigate = useNavigate();

  const { services, refetch, status } = useActiveServices({
    customerId: customer.id,
  });

  React.useLayoutEffect(() => {
    if (status === 'success') {
      resizeAllCards();
    }
  }, [status]);

  const activeServices = useMemo(() => {
    if (services) return services.active;
  }, [services]);

  const onRowClick = (to: string) => {
    navigate(to);
  };

  const currentView = {
    pending: <LinearProgress />,
    success: activeServices?.length ? (
      activeServices.map((service, i) => (
        <React.Fragment key={i}>
          <ListItem
            onClick={() =>
              onRowClick(
                `/products/${kebabCase(service['@type']).toLowerCase()}/${
                  service.id
                }/edit`,
              )
            }
          >
            <ListItemIcon>
              <ServiceIcon
                fontSize="large"
                serviceName={
                  camelCase(service['@type']) as CamelCaseServiceName
                }
                isActive
              />
            </ListItemIcon>
            <ListItemText
              primary={
                servicesNames[
                  camelCase(service['@type']) as CamelCaseServiceName
                ]
              }
              secondary={service?.code && service.code}
            />
            <StyledListItemIcon>
              <ChurnButton service={service} onChurnConfirm={refetch} />
              <ServiceInfoTooltip
                title={<TooltipContent service={service} customer={customer} />}
              >
                <InfoOutlined color="primary" />
              </ServiceInfoTooltip>

              <Link
                target="_blank"
                href={`/products/${kebabCase(service['@type']).toLowerCase()}/${
                  service.id
                }/edit`}
                onClick={(e) => e.stopPropagation()}
              >
                <Tooltip title="Öppna i ny flik">
                  <LaunchOutlinedIcon color="primary" />
                </Tooltip>
              </Link>
              <ArrowForwardOutlined color="primary" />
            </StyledListItemIcon>
          </ListItem>
          <Divider />
        </React.Fragment>
      ))
    ) : (
      <Typography variant="h5" p={2}>
        Inga aktiva tjänster
      </Typography>
    ),
    error: <BasicAlert />,
  };

  return (
    <Card style={{ overflow: 'unset' }}>
      <StickyHeader title="Aktiva tjänster" />
      <Divider />
      <StyledList>{currentView[status]}</StyledList>
    </Card>
  );
};

const StickyHeader = styled(CardHeader)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  backgroundColor: theme.palette.background.paper,
  zIndex: 1,
}));

const StyledList = styled(List)(() => ({
  paddingTop: 0,
}));

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  '& > *': {
    marginLeft: theme.spacing(1),
  },
}));
