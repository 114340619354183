import React from 'react';

import {
  Button,
  Card,
  CardHeader,
  Divider,
  Grid,
  LinearProgress,
  Skeleton,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { deleteCampaign, getCampaigns } from '@/api/campaigns';
import { Pagination } from '@/components/Pagination';
import { SimpleTable } from '@/components/SimpleTable';
import { PureDropdown } from '@/components/form';
import { queryKeys } from '@/constants/queryKeys';
import { useGetCampaignServiceOptions } from '@/hooks/useGetCampaignServiceOptions';
import { Campaign, CampaignTypes } from '@/types/campaigns';

import { getCampaignTableColumns } from '../helpers/getCampaignTableColumns';
import { CampaignModal } from './CampaignModal';
import { useGenerateMockedWelcomeLetter } from './hooks/useGenerateMockedWelcomeLetter';

export const CampaignList = () => {
  const campaignServiceOptions = useGetCampaignServiceOptions();

  const [modalOpen, setModalOpen] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(100);
  const sorting = {
    active: 'desc',
    createdAt: 'desc',
  } as const;

  const [anchorEl, setAnchorEl] = React.useState<
    null | (EventTarget & HTMLButtonElement)
  >(null);

  const [campaignType, setCampaignType] = React.useState<CampaignTypes>(
    campaignServiceOptions[0]?.value ?? 'sinne_campaigns',
  );
  const [campaignToEdit, setCampaignToEdit] = React.useState<string | null>(
    null,
  );

  const queryClient = useQueryClient();

  const {
    data: campaigns,
    isLoading,
    isSuccess,
  } = useQuery({
    queryKey: queryKeys.campaigns.filteredList({
      campaignType,
      page,
      perPage,
      sorting,
    }),
    queryFn: async () =>
      await getCampaigns(campaignType, {
        page: page + 1,
        perPage,
        'order[active]': sorting.active,
        'order[createdAt]': sorting.createdAt,
      }),
    enabled: !!campaignType,
  });

  const deleteCampaignMutation = useMutation({
    mutationFn: (iri: string) => {
      return deleteCampaign(iri);
    },
    onSuccess: (_, data) => {
      queryClient.setQueryData(
        [queryKeys.getCampaigns, campaignType, page, perPage],
        (oldData) => {
          return (oldData as Campaign[]).filter(
            (campaign) => campaign['@id'] !== data,
          );
        },
      );
    },
    onSettled: () => setAnchorEl(null),
  });
  const generateMockedWelcomeLetterMutation = useGenerateMockedWelcomeLetter();
  const { isPending: deleting } = deleteCampaignMutation;

  const handleDeleteOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDeleteClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    handleDeleteClose();
    if (anchorEl) {
      deleteCampaignMutation.mutate(anchorEl.value);
    }
  };

  const handlePageChange = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    setPerPage(rowsPerPage);
  };

  const handleEditCampaign = (campaign: string) => {
    setCampaignToEdit(campaign);
    setModalOpen(true);
  };

  const columns = getCampaignTableColumns({
    campaignType,
    handleEditCampaign,
    deleting,
    deleteCampaignMutation,
    handleDeleteOpen,
    anchorEl,
    handleDeleteClose,
    handleDelete,
    generateMockedWelcomeLetterMutation,
  });

  return (
    <>
      <Card>
        <CardHeader
          title="Kampanjer"
          action={
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setCampaignToEdit(null);
                setModalOpen(true);
              }}
            >
              Skapa kampanj
            </Button>
          }
        />
        <Divider />
        <Grid container style={{ padding: '0.5rem' }}>
          <Grid item xs={12} md={4}>
            {campaignServiceOptions ? (
              <PureDropdown
                value={campaignType}
                onChange={(e) => {
                  const target = e.target as HTMLSelectElement;
                  setCampaignType(target.value as CampaignTypes);
                }}
                label="Tjänst"
                labelId="service"
                options={campaignServiceOptions}
                inputProps={{
                  'data-testid': 'campaign-service-options',
                }}
              />
            ) : (
              <Skeleton height={54} />
            )}
          </Grid>
        </Grid>

        <Divider />
        {isLoading ? (
          <LinearProgress />
        ) : (
          isSuccess && (
            <>
              <Pagination
                count={campaigns.length}
                rowsPerPage={perPage}
                page={page}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
              />
              <SimpleTable columns={columns} rows={campaigns} />
            </>
          )
        )}
      </Card>
      {modalOpen && (
        <CampaignModal
          handleVisibility={() => {
            setModalOpen(!modalOpen);
          }}
          campaignToEdit={campaignToEdit}
          selectedCampaignType={campaignType}
        />
      )}
    </>
  );
};
