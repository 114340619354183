import { useQuery } from '@tanstack/react-query';

import { getCampaign } from '@/api/campaigns';
import { queryKeys } from '@/constants/queryKeys';

export const useGetCampaignData = (campaignIri?: string | null) => {
  const { data: campaignData, isLoading: isLoadingCampaign } = useQuery({
    queryKey: [queryKeys.getAllCampaigns, campaignIri],
    queryFn: async () => {
      const { units, ...data } = await getCampaign(campaignIri!);

      data.service = data['@id'].split('/')[1];

      return data;
    },
    enabled: campaignIri !== '' && !!campaignIri,
  });

  const templateData = !campaignData ? [] : campaignData.templates;

  return {
    templateData,
    isLoadingCampaign,
    campaignData,
  };
};
