import { MobileTable } from '@/components/MobileTable';

export const MobilesSection = ({ serviceId }: { serviceId: number }) => {
  return (
    <MobileTable
      marginBottom="0"
      productType={'CoProtection'}
      productIri={`/co_protections/${serviceId}}`}
    />
  );
};
