import { ActivateLongFraudProtection } from './ActivateLongFraudProtection';
import { ActivateTemporaryFraudProtection } from './ActivateTemporaryFraudProtection';

type Props = {
  pin: string;
  customerId: string;
  chosenAction: number;
  hasPhoneVerification: boolean;
};

export const FraudProtectionNotFound = ({
  pin,
  customerId,
  chosenAction,
  hasPhoneVerification,
}: Props) => {
  return (
    <>
      {chosenAction === 3 ? (
        <ActivateLongFraudProtection pin={pin} customerId={customerId} />
      ) : (
        <ActivateTemporaryFraudProtection
          pin={pin}
          customerId={customerId}
          hasPhoneVerification={hasPhoneVerification}
        />
      )}
    </>
  );
};
