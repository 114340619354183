import { useQuery } from '@tanstack/react-query';

import { getResellers } from '@/api/reseller';
import { queryKeys } from '@/constants/queryKeys';
import { GetResellerParams } from '@/types/resellers';

export const useGetResellers = (props: GetResellerParams) => {
  return useQuery({
    queryKey: queryKeys.reseller.list(JSON.stringify(props)),

    queryFn: () => getResellers(props),
  });
};
