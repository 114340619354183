import { Alert, AlertTitle, LinearProgress, List } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { format, parseISO } from 'date-fns';
import { startCase } from 'lodash';
import { useParams } from 'react-router-dom';

import { getSinneDeviceDetails } from '@/api/services';
import { LabelAndContentRow } from '@/components';
import { BasicAlert } from '@/components/BasicAlert';
import { SimpleCard } from '@/components/SimpleCard';
import { GenericHttpError } from '@/types/errors';
import { SinneDeviceInfo } from '@/types/services';

export const DeviceInformationCard = () => {
  const { serviceId } = useParams();

  const { data, isInitialLoading, error } = useQuery({
    queryKey: ['deviceInfo', serviceId],

    queryFn: () =>
      getSinneDeviceDetails({
        serviceId: serviceId!,
      }),

    enabled: !!serviceId,
    retry: false,
  });

  const getSinneDeviceInfo = (
    deviceInformation: SinneDeviceInfo | undefined,
  ) => {
    if (!deviceInformation?.device) return [];
    return [
      {
        label: 'Tillverkningsdatum',
        value: deviceInformation?.device?.deviceManufactureDate
          ? format(
              parseISO(deviceInformation?.device?.deviceManufactureDate),
              'yyyy-MM-dd HH:mm:ss',
            )
          : 'Okänt',
      },

      {
        label: 'IMEI',
        value: deviceInformation.device.imei,
      },

      {
        label: 'MAC',
        value: deviceInformation.device.mac,
      },

      {
        label: 'Operatör',
        value: startCase(deviceInformation.device.sim.operator),
      },

      {
        label: 'MSISDN',
        value: deviceInformation.device.sim.MSISDN,
      },

      {
        label: 'IMSI',
        value: deviceInformation.device.sim.IMSI,
      },

      {
        label: 'ICC',
        value: deviceInformation.device.sim.ICC,
      },

      {
        label: 'Enhetstyp',
        value: deviceInformation.device.deviceType,
      },

      {
        label: 'Senaste temperatur',
        value: <>{deviceInformation.device.lastTemperature} &#8451;</>,
      },
      {
        label: 'Signalstyrka',
        value: deviceInformation.device.signalLevel,
      },
      {
        label: 'Batterinivå',
        value: deviceInformation.device.batteryLevel,
      },
      {
        label: 'Status på enheten',
        value: deviceInformation.device.connected ? 'Ansluten' : 'Frånkopplad',
      },
      {
        label: 'Senaste synkning',
        value: format(
          parseISO(deviceInformation.device.syncedOn),
          'yyyy-MM-dd HH:mm:ss',
        ),
      },
      {
        label: 'Firmware version',
        value: deviceInformation.device.firmwareVersion,
      },
    ];
  };

  return (
    <SimpleCard
      title="Enhetsinformation"
      marginBottom="0"
      contentPadding={0}
      cardContent={
        isInitialLoading ? (
          <LinearProgress />
        ) : // @ts-ignore
        error?.status === 422 ? (
          <Alert severity="info">
            <AlertTitle>Ingen enhet är kopplad till tjänsten</AlertTitle>
          </Alert>
        ) : error ? (
          <BasicAlert />
        ) : (
          <>
            <List>
              {getSinneDeviceInfo(data).map((row) => (
                <LabelAndContentRow
                  label={row.label}
                  content={row.value}
                  divider
                />
                // <ListItem key={row.label}>
                //   <ListItemText primary={row.label} secondary={row.value} />
                // </ListItem>
              ))}
            </List>
          </>
        )
      }
    />
  );
};
