import React from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

type Props = {
  label: React.ReactNode;
  content: React.ReactNode;
  action?: React.ReactNode;
};

export function LabelContentAndAction({ label, content, action }: Props) {
  return (
    <Container alignItems="center" container>
      <Grid item xs={5}>
        <Typography fontWeight="bold">{label}</Typography>
      </Grid>
      <Grid item xs={5}>
        {content}
      </Grid>
      <Grid item xs={2}>
        <Box display="flex" justifyContent="flex-end">
          {action}
        </Box>
      </Grid>
    </Container>
  );
}

const Container = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(0, 3, 0, 3),
  minHeight: theme.spacing(5),
}));
