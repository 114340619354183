import { SvgIcon, SvgIconProps } from '@mui/material';

export const AddIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 172 172" width="48px" height="48px">
      <g>
        <path d="M0,172v-172h172v172z" fill="none"></path>
        <g>
          <path d="M86,14.33333c-39.51676,0 -71.66667,32.14993 -71.66667,71.66667c0,39.51673 32.1499,71.66667 71.66667,71.66667c39.51676,0 71.66667,-32.14993 71.66667,-71.66667c0,-39.51673 -32.1499,-71.66667 -71.66667,-71.66667zM86,25.08333c33.70704,0 60.91667,27.20965 60.91667,60.91667c0,33.70702 -27.20963,60.91667 -60.91667,60.91667c-33.70704,0 -60.91667,-27.20965 -60.91667,-60.91667c0,-33.70702 27.20963,-60.91667 60.91667,-60.91667zM85.91601,50.08968c-2.96578,0.04633 -5.33356,2.48614 -5.29101,5.45198v25.08333h-25.08333c-1.93842,-0.02741 -3.74144,0.99102 -4.71865,2.66532c-0.97721,1.6743 -0.97721,3.74507 0,5.41937c0.97721,1.6743 2.78023,2.69273 4.71865,2.66532h25.08333v25.08333c-0.02741,1.93842 0.99102,3.74144 2.66532,4.71865c1.6743,0.97721 3.74507,0.97721 5.41937,0c1.6743,-0.97721 2.69273,-2.78023 2.66532,-4.71865v-25.08333h25.08333c1.93842,0.02741 3.74144,-0.99102 4.71865,-2.66532c0.97721,-1.6743 0.97721,-3.74507 0,-5.41937c-0.97721,-1.6743 -2.78023,-2.69273 -4.71865,-2.66532h-25.08333v-25.08333c0.02085,-1.45347 -0.54782,-2.85342 -1.57635,-3.88062c-1.02852,-1.0272 -2.4292,-1.59408 -3.88264,-1.57136z"></path>
        </g>
      </g>
    </SvgIcon>
  );
};
