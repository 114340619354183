import * as Sentry from '@sentry/browser';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router';

import { deleteCustomer } from '@/api/customers';
import { useSnackbar } from '@/hooks/useSnackbar';
import { GenericHttpError } from '@/types/errors';

export const useDeleteCustomer = () => {
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  const deleteCustomerMutation = useMutation({
    mutationFn: (customerId: number) => deleteCustomer(customerId),
    onSuccess: () => {
      snackbar({
        message: 'Kunden har tagits bort',
        type: 'success',
      });
      navigate(`/customers`);
    },
    onError: (error: unknown) => {
      Sentry.captureMessage('Kunde inte ta bort kund: ', {
        level: 'error',
        extra: {
          error: error,
        },
      });

      const errorMessage = (error as GenericHttpError).data.message;

      snackbar({
        type: 'error',
        message: `Kunde inte ta bort kund: ${errorMessage}`,
      });
    },
  });

  return deleteCustomerMutation;
};
