import { DetailedStatsDistributionToDisplay } from '@/types/stats';

import { SERVICE_STATUS_COLORS_MAP } from '../../constants';

type ChartData = {
  label: DetailedStatsDistributionToDisplay;
  data: number[];
  fill: boolean;
};

export function enhanceChartDataWithColors(data: ChartData[]) {
  return data.map((item) => {
    const color = SERVICE_STATUS_COLORS_MAP[item.label];

    return {
      ...item,
      backgroundColor: color,
      borderColor: color,
    };
  });
}
