import { RouterOutlined } from '@mui/icons-material';
import { SvgIconProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import {
  CoProtectionIcon,
  FiretextIcon,
  KeyTagIcon,
  ProtectionIcon,
  SinneIcon,
  StickerIcon,
} from '@/icons';
import { CamelCaseServiceName } from '@/types/services';

type Props = {
  serviceName: CamelCaseServiceName;
  isActive?: boolean;
} & SvgIconProps;

function getIconSx(theme: Theme, isActive?: boolean) {
  return {
    color: isActive
      ? '#3B873E'
      : theme.palette.mode === 'light'
        ? theme.palette.action.disabled
        : theme.palette.common.white,
    fontSize: 20,
  };
}

export const ServiceIcon = ({ serviceName, isActive, ...rest }: Props) => {
  return (
    <>
      {serviceName === 'firetext' && (
        <FiretextIcon sx={(theme) => getIconSx(theme, isActive)} {...rest} />
      )}

      {serviceName === 'protection' && (
        <ProtectionIcon sx={(theme) => getIconSx(theme, isActive)} {...rest} />
      )}

      {serviceName === 'coProtection' && (
        <CoProtectionIcon
          sx={(theme) => getIconSx(theme, isActive)}
          {...rest}
        />
      )}

      {serviceName === 'keyTag' && (
        <KeyTagIcon sx={(theme) => getIconSx(theme, isActive)} {...rest} />
      )}

      {serviceName === 'sticker' && (
        <StickerIcon sx={(theme) => getIconSx(theme, isActive)} {...rest} />
      )}
      {/* Placeholder icon for homegate */}
      {serviceName === 'homegate' && (
        <RouterOutlined sx={(theme) => getIconSx(theme, isActive)} {...rest} />
      )}
      {serviceName === 'sinne' && (
        <SinneIcon sx={(theme) => getIconSx(theme, isActive)} {...rest} />
      )}
    </>
  );
};

ServiceIcon.propTypes = {
  serviceName: PropTypes.oneOf([
    'firetext',
    'protection',
    'coProtection',
    'keyTag',
    'sticker',
    'homegate',
    'sinne',
  ]).isRequired,
  isActive: PropTypes.bool.isRequired,
};
