import { LinearProgress } from '@mui/material';

import { Can } from '@/components/Can';
import { SimpleCard } from '@/components/SimpleCard';
import useUserRoles from '@/hooks/useUserRoles';
import { SalesInformationEditModeSection } from '@/modules/services/components/sections';
import { KeyTagParamSchema } from '@/types/services';

type Props = {
  serviceName: 'keyTag';
  serviceDetails: KeyTagParamSchema;
  isLoading: boolean;
};

export const SalesInformationForm = ({
  serviceName,
  serviceDetails,
  isLoading,
}: Props) => {
  const userRoles = useUserRoles();

  return (
    <SimpleCard
      title="Sälj"
      marginBottom="0"
      cardContent={
        <Can
          userRoles={userRoles}
          action="services.editSalesInfo"
          yes={() => (
            <>
              <section>
                <SalesInformationEditModeSection
                  serviceName={serviceName}
                  serviceDetails={serviceDetails}
                />
              </section>

              {isLoading && <LinearProgress />}
            </>
          )}
          no={() => (
            <section>
              <SalesInformationEditModeSection
                serviceName={serviceName}
                serviceDetails={serviceDetails}
                disabled
              />
            </section>
          )}
        />
      }
    />
  );
};
