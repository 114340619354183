import React from 'react';

import { Box, LinearProgress, Typography } from '@mui/material';

import { useSnackbar } from '@/hooks';
import { useBulkRegistrationState } from '@/hooks/useBulkRegistrationState';
import { useGetKeyTagBulkRegisteringResult } from '@/modules/services/components/KeyTag/hooks/useGetKeyTagBulkRegisteringResult';

export const KeyTagBulkServiceRegistrationPending = () => {
  const { bulkRegistrationState, setBulkRegistrationState } =
    useBulkRegistrationState();

  const snackbar = useSnackbar();
  const [refetch, setRefetch] = React.useState(true);

  const { serviceRegistrationUuid } = bulkRegistrationState;

  const { data, error } = useGetKeyTagBulkRegisteringResult(
    serviceRegistrationUuid,
    refetch,
  );

  const firstDataRow = data?.[0];

  React.useEffect(() => {
    if (error) {
      setRefetch(false);
      setBulkRegistrationState({
        ...bulkRegistrationState,
        serviceRegistrationStatus: 'failed',
        bulkRegistrationStep: 'serviceRegistrationComplete',
      });
      snackbar({
        message: 'Något gick fel vid registreringen av tjänsterna',
        type: 'error',
      });
    }
  }, [error, bulkRegistrationState, setBulkRegistrationState, snackbar]);

  React.useEffect(() => {
    if (firstDataRow && data[0]?.uuid === serviceRegistrationUuid) {
      setRefetch(false);
      setBulkRegistrationState({
        ...bulkRegistrationState,
        serviceRegistrationStatus: 'complete',
        bulkRegistrationStep: 'serviceRegistrationComplete',
      });
    }
  }, [
    bulkRegistrationState,
    data,
    serviceRegistrationUuid,
    firstDataRow,
    setBulkRegistrationState,
  ]);

  if (error) return null;
  return (
    <Box>
      <LinearProgress />
      <Typography variant="h5">Registrering pågår</Typography>
    </Box>
  );
};
