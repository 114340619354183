import {
  getAutogiroPdf,
  getC5H5Pdf,
  getSimpleLetterPdf,
  getWelcomeLetterPdf,
} from '@/api/files';
import { usePrivateSettings } from '@/hooks';

export function generatePDFDownloadFile(data: any) {
  const file = new Blob([data], { type: 'application/pdf' });
  const url = window.URL.createObjectURL(file);
  window.open(url);
}

export function generatePdfDownloadLink(data: any, fileName: string) {
  const file = new Blob([data], {
    type: 'application/pdf',
  });

  const url = window.URL.createObjectURL(file);
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
}

export async function downloadAutogiroPdf(customerId: number) {
  const data = await getAutogiroPdf(customerId);

  generatePDFDownloadFile(data);
}

export async function downloadC5H5Pdf(customerId: number) {
  const data = await getC5H5Pdf(customerId);

  generatePDFDownloadFile(data);
}

export async function downloadSimpleLetterPdf(
  customerId: number,
  message: string,
) {
  const data = await getSimpleLetterPdf(customerId, message);

  generatePDFDownloadFile(data);
}

export const useDownloadWelcomeLetter = () => {
  const { servicesNames } = usePrivateSettings();

  return async ({
    customerId,
    product,
    productId,
  }: {
    customerId: number;
    product: string;
    productId: number;
  }) => {
    const data = await getWelcomeLetterPdf(customerId, product, productId);

    generatePdfDownloadLink(
      data,
      // @ts-ignore
      `Välkomstbrev-${servicesNames[product]}-Kundnr-${customerId}.pdf`,
    );
  };
};
