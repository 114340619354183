import React from 'react';

import {
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { getFailedBulkBatchItems } from '@/api/bulkBatch';
import { Pagination } from '@/components/Pagination';
import { queryKeys } from '@/constants/queryKeys';
import { useSendingsState } from '@/hooks/useSendingsState';

export const FailedItemsTable = () => {
  const { sendingsState } = useSendingsState();
  const { numberOfFails, batchUUID } = sendingsState;

  const [total, setTotal] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(100);

  const handlePageChange = (_: any, page: React.SetStateAction<number>) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event: { target: { value: string } }) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    setPerPage(rowsPerPage);
  };

  const {
    data: failedItemsList,
    isInitialLoading: isLoadingFailedItems,
    isError: isLoadingFailedItemsError,
    isSuccess: failedItemsListSuccess,
  } = useQuery({
    queryKey: [queryKeys.getFailedBulkBatchItems, batchUUID, page, perPage],
    queryFn: async () => {
      const data = await getFailedBulkBatchItems({
        bulkBatch: batchUUID,
        page: page + 1,
        perPage: perPage,
      });
      setTotal(data['hydra:totalItems']);

      return data['hydra:member'];
    },
    enabled: !!numberOfFails && !!batchUUID,
  });

  if (numberOfFails) {
    return (
      <>
        <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Kund</TableCell>
                <TableCell>Batch</TableCell>
                <TableCell>Orsak</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {failedItemsListSuccess &&
                failedItemsList.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>{item.id}</TableCell>
                    <TableCell>{item.customer ?? 'Saknas'}</TableCell>
                    <TableCell>{item.bulkBatch}</TableCell>
                    <TableCell>{item.failedReason}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {isLoadingFailedItems && <LinearProgress />}
        {isLoadingFailedItemsError && (
          <Typography color="error">Kunde inte hämta data</Typography>
        )}
        <Pagination
          count={total}
          page={page}
          rowsPerPage={perPage}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </>
    );
  }
  return <Typography variant="h5">Inga utskick misslyckades</Typography>;
};
