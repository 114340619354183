import { getObjectKeys } from '@/helpers/tsHelpers';
import { ApiCollectionResponse } from '@/types/common';
import {
  BulkServiceRegistrationResult,
  KeyTagItem,
  KeyTagResponse,
  KeyTagsResponse,
} from '@/types/services';
import httpClient from '@/utils/httpClient';

export async function checkCustomerKeyTagConfig(customerId: number) {
  const properties = {
    id: true,
    ['@type']: true,
    code: true,
    customer: {
      id: true,
      pin: true,
      fullName: true,
    },
  };

  const { data } = await httpClient.get<
    KeyTagsResponse<keyof typeof properties>
  >('/key_tags', {
    params: {
      'customer.id': customerId,
      status: [1],
      exists: {
        code: false,
      },
      properties,
    },
  });

  return data['hydra:member'];
}

export async function updateKeyTagCode({
  id,
  code,
}: {
  id: number;
  code: string;
}) {
  const { data } = await httpClient.put<KeyTagResponse>(`/key_tags/${id}`, {
    code,
  });

  return data;
}

export type KeyTagSortOrders = 'asc' | 'desc';

export type GetKeyTagsParams = {
  customerPin?: string;
  page?: number;
  perPage?: number;
  churns?: boolean | null;
  code?: string;
  sortOrder?: KeyTagSortOrders;
  properties?: Record<string, any>;
  params?: Record<string, any>;
};
export async function getKeyTags({
  customerPin,
  page = 1,
  perPage = 100,
  churns = null,
  code,
  sortOrder = 'asc',
  properties,
  params,
}: GetKeyTagsParams) {
  const props = {
    id: true,
    churnDate: true,
    ['@type']: true,
    status: true,
    code: true,
    customer: {
      id: true,
      fullName: true,
      companyName: true,
      pin: true,
    },
    ...properties,
  };

  type KeyTagsResponseProps = Pick<KeyTagItem, keyof typeof props>;

  const { search, status, ...restParams } = params || {};
  const { data } = await httpClient.get<
    ApiCollectionResponse<KeyTagsResponseProps>
  >('/key_tags', {
    params: {
      'order[churnDate]': churns === true ? sortOrder : undefined,
      'exists[churnDate]': churns !== null ? !!churns : undefined,
      status: churns ? 1 : status,
      'customer.pin': customerPin,
      pagination: true,
      page,
      perPage,
      code,
      properties: props,
      ...(search ? { search } : {}),
      ...restParams,
    },
  });

  return data;
}

export type KeyTagBulkRegistrationParams = {
  file: File;
  keyTagDuplicate: boolean;
  keyTagBilling: boolean;
};

type KeyTagBulkRegistrationResponse = {
  message: string;
  type: '/key_tags/register';
  uuid: string;
};

export async function keyTagBulkRegistration(
  params: KeyTagBulkRegistrationParams,
) {
  const formData = new FormData();
  getObjectKeys(params).forEach((key) => {
    const value = params[key];

    if (value instanceof Blob) {
      formData.append(key, value);
    } else {
      formData.append(key, String(value));
    }
  });

  return httpClient.post<KeyTagBulkRegistrationResponse>(
    '/key_tags/register',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
}

type ValidateKeyTagBulkRegistrationFileResponse = {
  message: string;
  type: string;
  uuid: string;
};

export async function validateKeyTagBulkRegistrationFile(file: File) {
  const formData = new FormData();
  formData.append('file', file);

  const { data } =
    await httpClient.post<ValidateKeyTagBulkRegistrationFileResponse>(
      '/key_tags/register/validation',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

  return data;
}

export async function getKeyTagRegisterResults(uuid: string) {
  const { data } = await httpClient.get<
    ApiCollectionResponse<BulkServiceRegistrationResult>
  >(`/key_tag_register_results/`, {
    params: {
      uuid,
    },
  });
  return data['hydra:member'];
}
