import { Card, CardContent } from '@mui/material';

import { NumberedListItem } from '@/components/NumberedListItem';

import { ChecklistTitle, ListTitle } from '../../commonStyles';

// Third tab for CoProtection Checklist
export const ThirdCoTab = () => {
  return (
    <Card>
      <ChecklistTitle variant="h6">
        Kund har frågor gällande Kreditrating/Kreditbetyget på ett företag
      </ChecklistTitle>

      <CardContent>
        <ListTitle variant="h6">Meddela kund:</ListTitle>
        <NumberedListItem
          number={1}
          text={`Kreditbetyget kommer ifrån Dun & Bradstreet (tidigare Bisnode). Kund kan kontakta dem för att få mer information kring värderingen.`}
        />
        <ListTitle variant="h6">Att göra:</ListTitle>
        <NumberedListItem
          number={1}
          text={'Anteckna i Kundnoteringar om samtalet med kund.'}
        />
      </CardContent>
    </Card>
  );
};
