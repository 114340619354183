import { z } from 'zod';
import zodToJsonSchema from 'zod-to-json-schema';

import { createApiPropertiesFromJsonSchema } from '@/helpers/createApiPropertiesFromJsonSchema';
import { AllCoProtectionsResponse } from '@/types/services';
import httpClient from '@/utils/httpClient';

export async function checkCoProtectionsForCustomer({
  customerId,
}: {
  customerId: string | number;
}) {
  const schema = z.object({
    type: z.number(),
    regDate: z.string(),
  });
  const collection = z.array(schema);

  const properties = createApiPropertiesFromJsonSchema(zodToJsonSchema(schema));

  const { data } = await httpClient.get('/co_protections', {
    params: {
      'customer.id': customerId,
      status: 1,
      properties,
    },
  });

  return collection.parse(data['hydra:member']);
}

export type GetCoProtectionsParams = {
  pin?: string;
  customerPin?: string;
  sortOrder?: 'asc' | 'desc';
  page?: number;
  perPage?: number;
  churns?: boolean;
};

const getCoProtectionsParams = ({
  churns,
  sortOrder,
  customerPin,
  pin,
  page,
  perPage,
}: GetCoProtectionsParams) => {
  if (churns) {
    return {
      'order[churnDate]': sortOrder,
      status: 1,
      'exists[churnDate]': true,
      'customer.pin': customerPin,
      pin,
      pagination: true,
      page,
      perPage,
      properties: {
        id: true,
        ['@type']: true,
        churnDate: true,
        status: true,
        customer: {
          id: true,
          fullName: true,
          company: true,
          companyName: true,
          pin: true,
        },
      },
    };
  }

  return {
    'customer.pin': customerPin,
    pin,
    pagination: true,
    page,
    perPage,
    status: 1,
    properties: {
      id: true,
      customer: {
        id: true,
        fullName: true,
        companyName: true,
      },
    },
  };
};

export async function getCoProtections(payload: GetCoProtectionsParams) {
  const params = getCoProtectionsParams(payload);

  const { data } = await httpClient.get<
    AllCoProtectionsResponse<keyof typeof params.properties>
  >('/co_protections', {
    params,
  });

  return data;
}

export type LostOrSavedCoProtectionParams = {
  action: 'lost' | 'saved';
  serviceId: string | number;
};
export async function lostOrSavedCoProtection({
  action,
  serviceId,
}: LostOrSavedCoProtectionParams) {
  return httpClient.get(`/co_protections/${serviceId}/${action}`);
}

export async function getCoProtectionMonitorCount(serviceId: string) {
  const { data } = await httpClient.get(
    `co_protections/${serviceId}/monitor/count`,
  );

  const returnData = data?.message
    ? { message: data.message }
    : {
        addedCompanyWatch: data.addedCompanyWatch,
        automaticallyAdded: data.automaticallyAdded,
        manuallyAdded: data.manuallyAdded,
      };

  return returnData;
}
