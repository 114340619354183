import { Stack, Typography } from '@mui/material';

const NotFound = () => {
  return (
    <Stack justifyContent="center" alignItems="center" p={2}>
      <Typography variant="h1">
        404: The page you are looking for isn't here
      </Typography>
      <Typography variant="subtitle2" gutterBottom>
        You either tried some shady route or you came here by mistake. Whichever
        it is, try using the navigation
      </Typography>
      <img
        style={{ width: '100%', maxWidth: '1000px', height: 'auto' }}
        alt="Under development"
        src="/images/undraw_page_not_found_su7k.svg"
      />
    </Stack>
  );
};

export default NotFound;
