import pick from 'lodash/pick';

import { getObjectKeys } from '@/helpers/tsHelpers';
import { StatsDistribution } from '@/types/stats';

import {
  SERVICE_STATUS_CHART_COLOR_MAP,
  StatusesToDisplay,
} from '../constants/SERVICE_STATUS_CHART_COLOR_MAP';

export function pickFieldsForProductStats(
  data: Pick<StatsDistribution, StatusesToDisplay>,
) {
  return pick(data, getObjectKeys(SERVICE_STATUS_CHART_COLOR_MAP));
}
