import { LinearProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';

import { PageWrapper } from '@/components';
import { BasicAlert } from '@/components/BasicAlert';
import { EditPartnerForm } from '@/modules/PartnerPortal';
import { PartnerForm } from '@/modules/PartnerPortal/components/forms/PartnerForm';
import { useGetPartnerCompanyById } from '@/modules/PartnerPortal/hooks/useGetPartnerCompanyById';

type Params = {
  partnerId: string;
};

const EditPartnerAccount = () => {
  const { partnerId } = useParams<Params>();
  invariant(partnerId, 'id is required');

  const { data, status } = useGetPartnerCompanyById({ id: partnerId });

  const breadCrumbs = [
    { label: 'Partners', url: '/partner/accounts' },
    { label: data?.name || '' },
  ];

  if (status === 'pending') return <LinearProgress />;
  if (status === 'error') return <BasicAlert />;
  if (status === 'success') {
    return (
      <PageWrapper
        pageTitle="Redigera partnerkonto"
        breadCrumbs={breadCrumbs}
        maxWidth="md"
      >
        <PartnerForm isEdit partner={data}>
          <EditPartnerForm />
        </PartnerForm>
      </PageWrapper>
    );
  }
  return null;
};

export default EditPartnerAccount;
