import httpClient from '@/utils/httpClient';

export async function marketingCheck({
  pins,
  type,
}: {
  pins: string[];
  type: string;
}) {
  const batchSize = 200;
  const totalPins = pins.length;
  const batches = Math.ceil(totalPins / batchSize);
  const results = [];
  for (let i = 0; i < batches; i++) {
    const startIndex = i * batchSize;
    const endIndex = Math.min((i + 1) * batchSize, totalPins);
    const batch = pins.slice(startIndex, endIndex);

    const { data } = await httpClient.post<
      Array<{ pin: string; canContact: boolean }>
    >('/rpc/marketing.check', {
      pins: batch,
      type,
    });

    results.push(...data);
  }

  return results;
}
