import CallMadeIcon from '@mui/icons-material/CallMade';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import { Box, Button, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import { SimpleCard } from '@/components/SimpleCard';
import { usePrivateSettings } from '@/hooks';

type Props = {
  customerId: string;
  mappedServiceName: string; // TODO FIX
};
export const SuccessPage = ({ customerId, mappedServiceName }: Props) => {
  const { invoiceProvider } = usePrivateSettings();

  const LinkToCustomer = () => {
    return (
      <Link
        style={{ textDecoration: 'underline' }}
        to={`/customer/${customerId}`}
      >
        {customerId}
      </Link>
    );
  };

  return (
    <Box maxWidth="md">
      <SimpleCard
        title="Tjänsten har registrerats"
        cardActions={
          <Box display="flex" justifyContent="space-around">
            <Button
              color="primary"
              variant="contained"
              component={Link}
              to={`/customer/${customerId}?tab=SERVICES`}
              startIcon={<CallMadeIcon />}
            >
              Gå till kundens tjänster
            </Button>

            <Button
              color="primary"
              variant="contained"
              onClick={() => window.location.reload()}
              startIcon={<PersonAddOutlinedIcon />}
              sx={{ marginLeft: '1rem' }}
            >
              Registrera ny produkt
            </Button>
          </Box>
        }
        cardContent={
          <Grid container>
            <Grid item xs={12} md={6}>
              {invoiceProvider?.name ? (
                <Typography>
                  Tjänsten {mappedServiceName} har skapats för kundnr{' '}
                  <LinkToCustomer /> i <strong>Monitum</strong> och även
                  registrerats hos <strong>{invoiceProvider?.name}</strong>.
                </Typography>
              ) : (
                <Typography>
                  Tjänsten {mappedServiceName} har skapats för kundnr
                  <LinkToCustomer /> i <strong>Monitum</strong>.
                </Typography>
              )}
            </Grid>
          </Grid>
        }
      />
    </Box>
  );
};
