import { useMutation } from '@tanstack/react-query';

import { login } from '@/api/auth';

type SignInPayload = {
  username: string;
  password: string;
};
export const useSignIn = () => {
  return useMutation({
    mutationFn: async (payload: SignInPayload) => {
      const { token } = await login(payload);
      localStorage.setItem('jwt-token', token);

      return token;
    },
  });
};
