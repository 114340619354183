import { AxiosResponse } from 'axios';
import { format } from 'date-fns';

import {
  Alarm,
  AlarmsResponse,
  FiretextTestStatusResponse,
  HandleableAlarm,
  HandleableAlarmCollectionResponse,
  HandleableAlarmIntegerStatuses,
  HandleableAlarmList,
  HandleableAlarmsReturnType,
  OldHandleableAlarmCollectionResponse,
  UpdateAlarmStatusPayload,
  UpdateAlarmStatusResponse,
} from '@/types/alarms';
import httpClient from '@/utils/httpClient';

export async function getFiretextTestStatus(firetextId: number) {
  const { data } = await httpClient.get<FiretextTestStatusResponse>('alarms', {
    params: {
      createdAt: {
        after: format(new Date(), 'yyyy-MM-dd'),
      },
      status: [0],
      firetext: firetextId,
    },
  });

  return data['hydra:member'];
}

export async function getActiveAlarms(): Promise<{
  total: number;
  data: Alarm[];
}> {
  const { data } = await httpClient.get<AlarmsResponse>(`/alarms`, {
    params: {
      status: [0, 1],
      pagination: true,
      page: 1,
      perPage: 100,
      order: {
        createdAt: 'asc',
        time: 'desc',
      },
    },
  });

  return {
    total: data['hydra:totalItems'],
    data: data['hydra:member'].map((alarm) => {
      const firetext = alarm.firetext;
      // @ts-ignore
      delete alarm.firetext;

      return {
        ...alarm,
        msisdn: firetext.msisdn,
        type: firetext['@type'],
      };
    }),
  };
}

export async function getInactiveAlarms(): Promise<{
  total: number;
  data: Alarm[];
}> {
  const { data } = await httpClient.get<AlarmsResponse>('/alarms', {
    params: {
      status: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      pagination: true,
      page: 1,
      perPage: 100,
      order: {
        createdAt: 'desc',
        time: 'desc',
      },
    },
  });

  return {
    total: data['hydra:totalItems'],
    data: data['hydra:member'].map((alarm) => {
      const firetext = alarm.firetext;
      // @ts-ignore
      delete alarm.firetext;

      return {
        ...alarm,
        msisdn: firetext.msisdn,
        firetextStatus: alarm.firetext.status,
      };
    }),
  };
}

export async function updateAlarmStatus({
  alarmId,
  status,
  note,
}: {
  alarmId: number;
  status: number;
  note: string;
}) {
  return await httpClient.put<
    Omit<UpdateAlarmStatusPayload, 'alarmId'>,
    UpdateAlarmStatusResponse
  >(`alarms/${alarmId}`, {
    status,
    note,
  });
}

export type HandleableAlarmParams = {
  page?: number;
  perPage?: number;
  pagination?: boolean;
  status: number | number[];
  order?: { occuredAt: 'asc' | 'desc' };
  'firetext.id'?: number | number[];
  'sinne.id'?: number | number[];
};

export async function getOldHandleableAlarms(
  params: Omit<HandleableAlarmParams, 'sinne.id'>,
): Promise<
  HandleableAlarmsReturnType<
    Pick<HandleableAlarm, '@id' | 'occuredAt' | 'status' | 'reasonComment'>
  >
> {
  const { data } = await httpClient.get<OldHandleableAlarmCollectionResponse>(
    'alarms',
    {
      params: {
        ...params,
        'firetext.id': undefined,
        firetext: params['firetext.id'],
      },
    },
  );

  const mappedOldData: Pick<
    HandleableAlarm,
    '@id' | 'occuredAt' | 'status' | 'reasonComment'
  >[] = data['hydra:member'].map((alarm) => {
    return {
      '@id': alarm['@id'],
      occuredAt: alarm.dateTime,
      status: parseInt(
        alarm.status,
        10,
      ) as keyof HandleableAlarmIntegerStatuses,
      reasonComment: alarm.note,
    };
  });

  return {
    items: mappedOldData,
    count: data['hydra:totalItems'],
  };
}

export async function getHandleableAlarms(
  params: HandleableAlarmParams,
): Promise<HandleableAlarmsReturnType> {
  const { data } = await httpClient.get<HandleableAlarmCollectionResponse>(
    'handleable_alarms',
    { params },
  );

  return { items: data['hydra:member'], count: data['hydra:totalItems'] };
}

export async function getHandleableAlarm(id: string) {
  const { data } = await httpClient.get<HandleableAlarm>(
    `handleable_alarms/${id}`,
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

  return data;
}

export async function updateHandleableAlarm(params: UpdateAlarmStatusPayload) {
  const { alarmId, ...payload } = params;
  return httpClient.put<
    Omit<UpdateAlarmStatusPayload, 'alarmId'>,
    AxiosResponse<UpdateAlarmStatusResponse>
  >(`handleable_alarms/${alarmId}`, payload, {
    headers: {
      Accept: 'application/json',
    },
  });
}

export type HandleableAlarmPollingPayload = {
  timestamp?: string;
};

export async function pollHandleableAlarms(
  payload: HandleableAlarmPollingPayload,
) {
  const { data } = await httpClient.post<HandleableAlarmList>(
    '/handleable_alarm/polling',
    payload,
  );

  return data;
}
