import { USER_TYPES } from '@/modules/common/constants/entitiyNamesMap';
import { UserRole, userRolesEnum } from '@/types/auth';

export const RoleTypes = {
  ROLE_ACCOUNT_MANAGER: 'Account Manager',
  ROLE_ADMIN_MANAGER: 'Administratörschef',
  ROLE_ADMINISTRATOR: USER_TYPES.admin,
  ROLE_BUSINESS_DEVELOPMENT_MANAGER: 'Business Development Manager',
  ROLE_BLOCK_SUPPORT: 'Spärrlinje',
  ROLE_CUSTOMER_SUPPORT_MANAGER: 'Kundtjänstchef',
  ROLE_CUSTOMER_SUPPORT: 'Kundtjänst',
  ROLE_FRAUD_ASSISTANCE: 'Bedrägeriassistans',
  ROLE_GODMODE: 'Godmode',
  ROLE_HEAD_OF_B2B: 'Affärschef B2B',
  ROLE_MONITORING_STATION: 'Larmcentral',
  ROLE_OWNER: 'Ägare',
  ROLE_SALES_MANAGER: 'Sales Manager',
  ROLE_RESELLER: 'Återförsäljare',
  ROLE_ECONOMY: 'Ekonomi',
} as const;

export const availableRoles = [
  'ROLE_ACCOUNT_MANAGER',
  'ROLE_ADMIN_MANAGER',
  'ROLE_ADMINISTRATOR',
  'ROLE_BUSINESS_DEVELOPMENT_MANAGER',
  'ROLE_BLOCK_SUPPORT',
  'ROLE_CUSTOMER_SUPPORT_MANAGER',
  'ROLE_CUSTOMER_SUPPORT',
  'ROLE_ECONOMY',
  'ROLE_FRAUD_ASSISTANCE',
  'ROLE_GODMODE',
  'ROLE_HEAD_OF_B2B',
  'ROLE_MONITORING_STATION',
  'ROLE_OWNER',
  'ROLE_RESELLER',
  'ROLE_SALES_MANAGER',
] as const;

export const displayAvailableRoles = () => {
  return availableRoles
    .filter((role) => role !== userRolesEnum.Enum.ROLE_RESELLER)
    .map((role) => RoleTypes[role]);
};

export const rolesOptions = availableRoles.filter(
  (role) => role !== userRolesEnum.Enum.ROLE_RESELLER,
);

export const ROLES_WITHOUT_ACCESS_TO_ALL_CUSTOMERS: Array<
  keyof typeof RoleTypes
> = ['ROLE_ACCOUNT_MANAGER'];

const isAccessToAllCustomersRestrictedRole = (role: UserRole) =>
  ROLES_WITHOUT_ACCESS_TO_ALL_CUSTOMERS.includes(role);

export const hasAccessToAllCustomers = (userRoles: UserRole[]) =>
  !userRoles?.every(isAccessToAllCustomersRestrictedRole);
