import React from 'react';

import { useInterpret } from '@xstate/react';
import { InterpreterFrom } from 'xstate';

import { sinneConfigStateMachine } from '@/modules/sinne/components/sinneConfigFlow/context/sinneConfigStateMachine';

export const SinneConfigContext = React.createContext(
  {} as InterpreterFrom<typeof sinneConfigStateMachine>,
);

type Props = {
  children: React.ReactNode;
};

export const SinneConfigContextProvider = ({ children }: Props) => {
  const service = useInterpret(sinneConfigStateMachine);

  return (
    <SinneConfigContext.Provider value={service}>
      {children}
    </SinneConfigContext.Provider>
  );
};
