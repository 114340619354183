import { Box } from '@mui/material';

import { DeviceCheck } from '@/modules/sinne/components/sinneReturnFlow/components/DeviceCheck';

import { CustomerCheck } from './CustomerCheck';
import { SimCardCheck } from './SimCardCheck';

export const StatusChecks = () => {
  return (
    <Box>
      <SimCardCheck />
      <DeviceCheck />
      <CustomerCheck />
    </Box>
  );
};
