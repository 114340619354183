import { Box, Button } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { FormApi } from 'final-form';
import arrayMutators from 'final-form-arrays';
import pick from 'lodash/pick';
import { Form } from 'react-final-form';
import invariant from 'tiny-invariant';
import { z } from 'zod';

import { CreateNotePayload, createNote, getNote } from '@/api/notes';
import { queryKeys } from '@/constants/queryKeys';
import { getChangedFormFields, getTypeKey } from '@/helpers';
import { usePrivateSettings } from '@/hooks';
import { prepareInitialValues } from '@/modules/services/utils';
import { SinneItem, SinneNotes, contactPerson } from '@/types/services';
import { sinneStatusKey } from '@/types/settings';

import { AlarmLocationForm } from './AlarmLocationForm';
import { ContactListSection } from './ContactListSection';
import { SalesInformationFields } from './SalesInformationFields';
import { ServiceInfoFields } from './ServiceInfoFields';

const FIELDS_TO_PICK = [
  'abTest',
  'accountManager',
  'addressList',
  'adminNote',
  'antiregret',
  'campaign',
  'churnDate',
  'churnReason',
  'contactPeople',
  'contractMonths', // from campaign
  'deviceId',
  'firmwareVersion',
  'freeMonths', // from campaign
  'leadSource',
  'noticePeriod', // from campaign
  'operatorId2',
  'partnerManager',
  'placement',
  'price',
  'regDate',
  'regretReason',
  'regretSource',
  'reseller',
  'salesRep',
  'sentDate',
  'status',
  'tb',
  'unitReturned',
  'winback',
  'withdrawalPeriodDays',
];

const sinneFormValuesSchema = z.object({
  abTest: z.string().nullable().optional(),
  accountManager: z.string().optional(),
  addressList: z.string().nullable().optional(),
  adminNote: z.string().nullable().optional(),
  antiregret: z.boolean().optional(),
  campaign: z.string().optional(),
  churnDate: z.string().nullable().optional(),
  churnReason: z.number().int().nullable().optional(),
  contactPeople: z.array(contactPerson).optional(),
  contractMonths: z.string().optional(),
  deviceId: z.string().nullable().optional(),
  leadSource: z.string().nullable().optional(),
  placement: z.string().nullable().optional(),
  price: z.string().optional(),
  regDate: z.string().optional(),
  regretReason: z.number().int().nullable().optional(),
  regretSource: z.number().int().nullable().optional(),
  reseller: z.string().nullable().optional(),
  salesRep: z.string().optional(),
  sentDate: z.string().nullable().optional(),
  status: sinneStatusKey.optional(),
  tb: z.number().nullable().optional(),
  unitReturned: z.boolean().optional(),
  winback: z.string().optional(),
});

type FormValues = z.infer<typeof sinneFormValuesSchema> & { returnLog: string };

export type SinneEditProps = {
  serviceDetails: SinneItem;
  isLoading: boolean;
  onSubmit: (v: Omit<FormValues, 'returnLog'>) => void;
};

const mapNoteIris = (notes: SinneNotes) => {
  return notes.map((note) => {
    if (typeof note === 'string') {
      return note;
    }

    return note['@id'];
  });
};

export const SinneEdit = ({
  serviceDetails,
  isLoading,
  onSubmit,
}: SinneEditProps) => {
  const queryClient = useQueryClient();
  const { servicesTypes } = usePrivateSettings();
  const initialValues = prepareInitialValues(
    pick(serviceDetails, FIELDS_TO_PICK),
  );

  const returnNoteIri = mapNoteIris(serviceDetails.notes)[0];

  const serviceId = serviceDetails?.id;
  const { data: latestReturnLogNote, isInitialLoading: loadingLatestNote } =
    useQuery({
      queryKey: [queryKeys.getNotes, returnNoteIri, serviceId],

      queryFn: async () => {
        const { data } = await getNote({
          noteId: returnNoteIri!, // at this point returnNoteIri exist because it is checked in enabled react-query option
          sinne: `/sinnes/${serviceId}`,
        });
        return data;
      },

      enabled: serviceDetails?.unitReturned && !!returnNoteIri,
      initialData: null,
    });

  const createNoteMutation = useMutation({
    mutationFn: ({
      notePayload,
    }: {
      notePayload: CreateNotePayload;
      sinnePayload: Partial<FormValues>;
    }) => {
      return createNote(notePayload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.getNotes],
      });
    },
    onSettled: (data, error, { sinnePayload }) => {
      if (sinnePayload) {
        onSubmit(sinnePayload);
      }
    },
  });

  const submitHandler = (values: FormValues, form: FormApi) => {
    if (!values.contactPeople) {
      values.contactPeople = [];
    }

    const dirtyFields = getChangedFormFields(values, form);

    const { returnLog, ...fields } = dirtyFields;

    if (returnLog) {
      const unitReturnedNoteType = getTypeKey(
        servicesTypes,
        'note',
        'Enhet returnerad',
      );

      try {
        invariant(
          unitReturnedNoteType,
          'Could not find unit returned note type',
        );

        const notePayload = {
          customerId: serviceDetails.customer.id,
          type: unitReturnedNoteType,
          text: returnLog,
          sinne: `/sinnes/${serviceId}`,
        };

        createNoteMutation.mutate({ notePayload, sinnePayload: fields });
      } catch (error) {
        console.error('Error creating note', error);
      }
    } else {
      onSubmit(fields);
    }
  };

  return (
    <Form
      onSubmit={(values, form) => submitHandler(values, form)}
      mutators={{
        ...arrayMutators,
      }}
      initialValues={{
        ...initialValues,
        returnLog: latestReturnLogNote?.text,
      }}
      render={({
        handleSubmit,
        values,
        submitting,
        form: {
          mutators: { push, remove },
        },
      }) => (
        <form onSubmit={handleSubmit} noValidate>
          <Box display="flex" flexDirection="column" gap={2}>
            <ServiceInfoFields
              churnDate={values.churnDate}
              status={values.status}
              serviceDetails={serviceDetails}
              loadingLatestNote={loadingLatestNote}
            />

            <SalesInformationFields
              serviceDetails={serviceDetails}
              campaign={values.campaign}
              isCreateMode={false}
            />

            <ContactListSection
              isLoading={isLoading}
              push={push!}
              remove={remove!}
              contactPeople={values.contactPeople}
            />

            <AlarmLocationForm isLoading={isLoading} />
          </Box>
          <Button
            sx={{ marginTop: 2 }}
            disabled={submitting || isLoading}
            type="submit"
            color="primary"
            variant="contained"
          >
            Uppdatera
          </Button>
        </form>
      )}
    />
  );
};
