import React from 'react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Typography } from '@mui/material';

type Props = {
  children: React.ReactNode;
  sx?: any;
};

export const InfoMessage = ({ children, sx }: Props) => {
  return (
    <Box display="flex" alignItems="center" sx={sx}>
      <InfoOutlinedIcon sx={{ mr: 1 }} />
      <Typography>{children}</Typography>
    </Box>
  );
};
