import { Button, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

import useUserRoles from '@/hooks/useUserRoles';
import { checkRolesAccess } from '@/utils/authorization';

type Props = {
  onClickSaved: () => void;
  onClickLost: () => void;
};
export const SavedLostSection = ({ onClickSaved, onClickLost }: Props) => {
  const userRoles = useUserRoles();

  //TODO authorize with services.lostButton for "Förlorad" button
  if (!checkRolesAccess(userRoles, 'services.savedButton')) {
    return null;
  }

  return (
    <section>
      <Grid container spacing={2} justifyContent="flex-end">
        <>
          <Grid item>
            <SavedButton variant="outlined" size="small" onClick={onClickSaved}>
              Räddad
            </SavedButton>
          </Grid>
          <Grid item>
            <LostButton variant="outlined" size="small" onClick={onClickLost}>
              Förlorad
            </LostButton>
          </Grid>
        </>
      </Grid>
    </section>
  );
};

const SavedButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.button.saved.default,
  '&:hover': {
    backgroundColor: theme.palette.button.saved.hover,
  },
}));

const LostButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.button.lost.default,
  '&:hover': {
    backgroundColor: theme.palette.button.lost.hover,
  },
}));
