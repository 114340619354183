import React from 'react';

import { EditOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import isArray from 'lodash/isArray';
import { Form } from 'react-final-form';

import { updateCustomer } from '@/api/customers';
import { fetchPartnersList } from '@/api/partners';
import { LabelAndContentRow } from '@/components';
import { Can } from '@/components/Can';
import { Dropdown, TextInput } from '@/components/form';
import { queryKeys } from '@/constants/queryKeys';
import { getFormattedDate } from '@/helpers/dates';
import { useSnackbar } from '@/hooks';
import { useAuth } from '@/hooks/useAuth';
import { PartnerCompany } from '@/types/customers';

type Props = {
  customerId: number;
  partnerDate: string;
  partnerCompany: PartnerCompany;
};

export const PartnerCooperationCard = ({
  customerId,
  partnerDate,
  partnerCompany,
}: Props) => {
  const [showEdit, setShowEdit] = React.useState(false);
  const snackbar = useSnackbar();

  const queryClient = useQueryClient();

  const { getAuthenticatedUserRoles } = useAuth();
  const userRoles = getAuthenticatedUserRoles();

  const { data: partnerCompanies, isError: isErrorPartners } = useQuery({
    queryKey: [queryKeys.getPartnerCompanies],
    queryFn: fetchPartnersList,
  });

  const updateCustomerMutation = useMutation({
    mutationFn: (payload: { partnerDate?: string; partnerCompany?: string }) =>
      updateCustomer({
        partnerCompany: payload.partnerCompany,
        partnerDate: payload.partnerDate,
        id: customerId,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.getCustomerById],
      });
      snackbar({
        type: 'success',
        message: 'Kundens partnersamarbete har uppdaterats.',
      });
    },
    onError: (err: any) => {
      snackbar({
        type: 'error',
        message: `Kunde inte uppdatera kund. Felmeddelande: "${err.data.message}"`,
      });
    },
  });

  const partnerSelectOptions = isArray(partnerCompanies)
    ? [
        { value: '', text: 'Ingen' },
        ...partnerCompanies.map((partner) => ({
          value: `/partner_companies/${partner.id}`,
          text: `${partner.id} - ${partner.name}`,
        })),
      ]
    : [];

  const PartnerForm = () =>
    isErrorPartners ? (
      <Typography variant="h4" color="error">
        Kunde inte hämta partners
      </Typography>
    ) : (
      <Form
        onSubmit={(values) => {
          setShowEdit(false);
          const company = values.partnerCompany ? values.partnerCompany! : null;
          const date = !company
            ? undefined
            : values.partnerDate !== getFormattedDate(partnerDate)
              ? values.partnerDate!
              : undefined;

          updateCustomerMutation.mutate({
            partnerCompany: company,
            partnerDate: date,
          });
        }}
        subscription={{ values: true }}
        initialValues={{
          partnerCompany: partnerCompany?.['@id'],
          partnerDate: partnerDate ? getFormattedDate(partnerDate) : null,
        }}
      >
        {({ handleSubmit }) => (
          <Box marginTop={2} marginBottom={2}>
            <form onSubmit={handleSubmit}>
              <Dropdown
                label="Partner"
                name="partnerCompany"
                options={partnerSelectOptions}
              />
              <TextInput
                variant="outlined"
                type="date"
                label="Partnerdatum"
                name="partnerDate"
                disabled={true}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Box display="flex" justifyContent="space-between" marginTop={1}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setShowEdit(!showEdit)}
                >
                  Avbryt
                </Button>
                <Button variant="contained" color="primary" type="submit">
                  Spara
                </Button>
              </Box>
            </form>
          </Box>
        )}
      </Form>
    );

  return (
    <Card>
      <CardHeader
        title="Partnersamarbete"
        style={{
          height: '56px',
          borderBottom: 'solid 1px rgba(0, 0, 0, 0.12)',
        }}
        action={
          <Can
            userRoles={userRoles}
            action="customers.editPartnerCompany"
            yes={() => (
              <IconButton
                color="primary"
                onClick={() => setShowEdit(!showEdit)}
                size="large"
                style={{ padding: '5px' }}
              >
                <EditOutlined />
              </IconButton>
            )}
            no={() => null}
          />
        }
      />
      {showEdit ? (
        <>
          <CardContent style={{ padding: '0 1rem 0 1rem' }}>
            <PartnerForm />
          </CardContent>
          <Divider />
        </>
      ) : (
        <>
          <LabelAndContentRow
            divider
            content={
              <Typography>{partnerCompany?.name || 'Saknas'}</Typography>
            }
            label="Partner"
          />
          <LabelAndContentRow
            content={
              <Typography>
                {partnerDate ? getFormattedDate(partnerDate) : 'Saknas'}
              </Typography>
            }
            label="Registreringsdatum"
          />
        </>
      )}
    </Card>
  );
};
