import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

import { Pagination } from '@/components/Pagination';
import { SimpleTable } from '@/components/SimpleTable';
import { extractIdFromIRI } from '@/utils/common';

type Props = {
  noPagination?: boolean;
  columns: any[];
  rows: any[];
  total?: number;
  loading?: boolean;
  page?: number;
  rowsPerPage?: number;
  onPageChange?: (page: number) => void;
  onRowsPerPageChange?: (rowsPerPage: number) => void;
};

export const AlarmsTable = ({
  noPagination = false,
  columns,
  rows,
  page,
  rowsPerPage,
  total = 0,
  loading,
  onPageChange,
  onRowsPerPageChange,
}: Props) => {
  const navigate = useNavigate();

  const handleChangePage = (event: any, newPage: number) => {
    onPageChange && onPageChange(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    onRowsPerPageChange &&
      onRowsPerPageChange(parseInt(event.target.value, 10));
  };

  const handleRowClick = (row: any) => {
    const id = row.id ?? extractIdFromIRI(row['@id']);
    navigate(`/alarms/${id}`);
  };

  return (
    <Container>
      <SimpleTable columns={columns} rows={rows} onRowClick={handleRowClick} />

      {loading && <LinearProgress />}

      {rows.length === 0 && !loading && (
        <EmptyStateContainer>
          <Typography variant="h2" component="h2" align="center">
            Inga larm
          </Typography>
        </EmptyStateContainer>
      )}

      {noPagination ? null : (
        <>
          {typeof page === 'number' && rowsPerPage && (
            <Pagination
              count={total}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={undefined}
            />
          )}
        </>
      )}
    </Container>
  );
};

const Container = styled('div')`
  margin-top: ${({ theme }) => theme.spacing(3)};
`;

const EmptyStateContainer = styled('div')(
  ({ theme }) => `
  padding-top: ${theme.spacing(4)};
  padding-bottom: ${theme.spacing(4)};

  & > h2 {
    font-weight: 400;
  }
`,
);
