import { Typography } from '@mui/material';
import { Skeleton } from '@mui/material';

import { BasicAlert } from '@/components/BasicAlert';
import { formatCurrency } from '@/modules/common/utils/currency';

import { useManagedCustomersArr } from '../hooks';
import { ManagedCustomer } from '../types';

type Props = {
  managedCustomers: ManagedCustomer[];
};

export const ManagerTotalArr = ({ managedCustomers }: Props) => {
  const { data, isLoading, isError } = useManagedCustomersArr(managedCustomers);

  const arr = data.map((obj) => obj?.message?.ARR);
  const administeredArr = arr.reduce((pVal, cVal) => pVal + cVal, 0);

  if (isLoading) return <Skeleton />;
  if (isError) return <BasicAlert />;
  return (
    <Typography variant="body2">
      {formatCurrency(Number.parseFloat(administeredArr))}
    </Typography>
  );
};
