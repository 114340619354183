import {
  AuthenticatedUser,
  ChangePasswordPayload,
  LoginCheckResponse,
  LoginPayload,
} from '@/types/auth';
import { GenericHttpError } from '@/types/errors';
import httpClient from '@/utils/httpClient';

export async function login({ username, password }: LoginPayload) {
  const { data } = await httpClient.post<LoginCheckResponse>('/login_check', {
    username,
    password,
  });

  return data;
}

export async function fetchAuthenticatedUserDetails(userId: number) {
  const { data } = await httpClient.get<AuthenticatedUser>(`/users/${userId}`, {
    params: {
      properties: {
        id: true,
        email: true,
        level: true,
        name: true,
        roles: true,
        username: true,
        pin: true,
      },
    },
  });

  return data;
}

export async function changePassword({
  oldPassword,
  newPassword,
}: ChangePasswordPayload) {
  const { data } = await httpClient.post('/rpc/change-password', {
    oldPassword,
    newPassword,
  });

  return data;
}
