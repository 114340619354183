import isObject from 'lodash/isObject';

import { getObjectKeys } from '@/helpers/tsHelpers';
import { SelectFieldOptions } from '@/types/common';

type PrepareTypeOptionsArg = Record<number, string>;

export function prepareTypeOptions(types: PrepareTypeOptionsArg) {
  if (!types) {
    return [];
  }

  if (isObject(types)) {
    return getObjectKeys(types).reduce((arr, cVal) => {
      const text = types[cVal];
      if (!text) return arr;
      arr.push({ text, value: cVal });
      return arr;
    }, [] as SelectFieldOptions);
  }

  return [];
}
