import { Box } from '@mui/material';

export function Spacing({ spacing = 2 }) {
  return (
    <Box
      sx={(theme) => ({
        height: theme.spacing(spacing),
      })}
    />
  );
}
