import { ArrowForwardOutlined, LaunchOutlined } from '@mui/icons-material';
import { Box, Link as NativeLink, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';

import { getElementFromArray } from '@/helpers/getElementFromArray';
import { getObjectKeys } from '@/helpers/tsHelpers';
import {
  ResellerPortalStatsDistribution,
  StatsDistribution,
} from '@/types/stats';

import { StatusesToDisplay } from '../constants/SERVICE_STATUS_CHART_COLOR_MAP';

export type StatsRawData = Record<
  string,
  Pick<StatsDistribution, StatusesToDisplay>
>;

type Args = {
  rawData: StatsRawData;
  firstHeaderName: string;
  linkProps?: {
    linkKey: keyof ResellerPortalStatsDistribution;
    href: string;
  };
};

export type StatsDetailedTableData = {
  headers: string[];
  data: Array<Array<React.ReactNode>>;
};

const LinkIcons = ({ href }: { href: string }) => {
  return (
    <Box display="flex" justifyContent="space-around">
      <NativeLink
        href={href}
        target="_blank"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Tooltip title="Öppna i ny flik">
          <LaunchOutlined color="primary" style={{ cursor: 'pointer' }} />
        </Tooltip>
      </NativeLink>
      <Link to={href}>
        <Tooltip title="Öppna i denna flik">
          <ArrowForwardOutlined color="primary" />
        </Tooltip>
      </Link>
    </Box>
  );
};

export function prepareDetailedInfoTablesData({
  rawData,
  firstHeaderName,
  linkProps,
}: Args) {
  const result = getObjectKeys(rawData).reduce(
    (prev, current, idx) => {
      const brutto = getElementFromArray(rawData?.[current]?.['Brutto']);
      const anger = getElementFromArray(rawData?.[current]?.['Ånger']);
      const cancelled = getElementFromArray(rawData?.[current]?.['Makulerad']);
      const netto = getElementFromArray(rawData?.[current]?.['Netto']);

      const angerPercent = (anger / brutto) * 100;
      const nettoPercent = (netto / brutto) * 100;
      const cancelledPercent = (cancelled / brutto) * 100;

      prev.data.push([
        current,
        brutto,
        anger,
        `(${angerPercent.toFixed()}%)`,
        cancelled,
        `(${cancelledPercent.toFixed()}%)`,
        netto,
        `(${nettoPercent.toFixed()}%)`,
      ]);

      if (linkProps) {
        prev.data[prev.data.length - 1]?.push(
          <LinkIcons
            href={`${linkProps.href}/${
              // @ts-ignore
              rawData?.[current]?.[linkProps.linkKey]
            }`}
          />,
        );
        if (idx === 0) {
          prev.headers.push('');
        }
      }

      return prev;
    },
    {
      headers: [
        firstHeaderName,
        'Brutto',
        'Ånger (% av brutto)',
        'Makulerad (% av brutto)',
        'Netto (% av brutto)',
      ],
      data: [],
    } as StatsDetailedTableData,
  );
  return result;
}
