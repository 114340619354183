import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { getFormattedDate } from '@/helpers/dates';
import { usePrivateSettings } from '@/hooks';
import { Customer } from '@/types/customers';

type Props = {
  isOpened: boolean;
  customer: Customer;
  onClose: () => void;
  onConfirm: () => void;
};

export const MultipleProtectionsWarningModal = ({
  isOpened,
  customer,
  onClose,
  onConfirm,
}: Props) => {
  const { servicesNames } = usePrivateSettings();

  return (
    <Dialog open={isOpened} onClose={onClose}>
      <DialogTitle>Varning!</DialogTitle>
      <DialogContent>
        {customer.activeProtections &&
        customer.activeProtections.length === 1 ? (
          <>
            <Typography>
              Aktiva {servicesNames.protection} finns redan registrerade på
              kundnummer {customer.id}
            </Typography>

            <StyledExistingProtectionList>
              {customer.activeProtections.map((protectionItem) => (
                <li key={protectionItem.id}>
                  <Typography>
                    {protectionItem.id}: Registrerad{' '}
                    {getFormattedDate(protectionItem.regDate, 'yyyy-MM-dd')}
                  </Typography>
                </li>
              ))}
            </StyledExistingProtectionList>

            <Typography>Vill du fortsätta?</Typography>
          </>
        ) : (
          <>
            <Typography>
              Aktiva {servicesNames.protection} finns redan registrerade på
              kundnummer {customer.id}.
            </Typography>

            <StyledExistingProtectionList>
              {customer.activeProtections?.map((protectionItem) => (
                <li key={protectionItem.id}>
                  <Typography>
                    {protectionItem.id}: Registrerad{' '}
                    {getFormattedDate(protectionItem.regDate, 'yyyy-MM-dd')}
                  </Typography>
                </li>
              ))}
            </StyledExistingProtectionList>

            <Typography>Vill du fortsätta?</Typography>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Avbryt
        </Button>
        <Button variant="contained" onClick={onConfirm} color="primary">
          Fortsätt
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const StyledExistingProtectionList = styled('ul')(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));
