import { useMutation } from '@tanstack/react-query';

import { customTemplateUpload } from '@/api/files';
import { useSnackbar } from '@/hooks';
import { GenericHttpError } from '@/types/errors';

export const useUploadCustomTemplate = () => {
  const snackbar = useSnackbar();

  return useMutation({
    mutationFn: customTemplateUpload,
    onSuccess: () => {
      snackbar({ type: 'success', message: 'Uppladdning lyckades.' });
    },
    onError: (error: GenericHttpError) => {
      snackbar({
        type: 'error',
        message: `${error.status}: ${error.statusText}`,
        autoHide: false,
      });
    },
  });
};
