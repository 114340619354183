import LinearProgress from '@mui/material/LinearProgress';

import { SimpleCard } from '@/components/SimpleCard';
import { AlarmLocationSection } from '@/modules/services/components/sections';

export const AlarmLocationForm = ({ isLoading }: { isLoading: boolean }) => {
  return (
    <SimpleCard
      marginBottom="0"
      title="Placering av brandlarmet"
      cardContent={
        <>
          <section>
            <AlarmLocationSection />
          </section>

          {isLoading && <LinearProgress />}
        </>
      }
    />
  );
};
