import React from 'react';

import { Button, List } from '@mui/material';

import * as AgreementsApi from '@/api/agreements';
import { useSnackbar } from '@/hooks';
import { BaseModal } from '@/modules/common/components';
import { CustomerAgreement } from '@/types/agreements';

import { AgreementListItem } from './AgreementsListItem';

type Props = {
  agreements: Pick<CustomerAgreement, '@id'>[];
  open: boolean;
  onClose: () => void;
};
export const AgreementsModal = ({ agreements, open, onClose }: Props) => {
  const snackbar = useSnackbar();

  const [loading, setLoading] = React.useState(false);

  async function handleAgreementDownload(
    agreement: Partial<CustomerAgreement>,
  ) {
    setLoading(true);

    try {
      await AgreementsApi.downloadFile({
        id: agreement.id!,
        filename: agreement.name!,
      });
      setLoading(false);
    } catch {
      setLoading(false);
      snackbar({
        type: 'error',
        message: 'Det gick inte att ladda ner avtalet',
      });
    }
  }

  return (
    <BaseModal
      title="Avtal"
      onClose={onClose}
      open={open}
      buttons={
        <Button color="primary" onClick={onClose}>
          Stäng
        </Button>
      }
    >
      <List>
        {agreements?.map((agreement) => (
          <AgreementListItem
            downloading={loading}
            key={agreement['@id']}
            iri={agreement['@id']}
            handleAgreementDownload={handleAgreementDownload}
          />
        ))}
      </List>
    </BaseModal>
  );
};
