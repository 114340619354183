import React from 'react';

import { Alert, AlertTitle } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useForm } from 'react-final-form';

import { getCampaign } from '@/api';
import { TextInput } from '@/components/form';
import { CampaignAutoComplete } from '@/components/form/CampaignAutocomplete/CampaignAutoComplete';
import { queryKeys } from '@/constants/queryKeys';

type Props = {
  customerType: number;
};

export const ProtectionCampaignSection = ({ customerType }: Props) => {
  const { change, getFieldState } = useForm();

  const selectedCampaign = getFieldState('protectionsCampaign')?.value;

  const {
    data: campaignData,
    isError,
    error,
  } = useQuery({
    queryKey: [queryKeys.getAllCampaigns, selectedCampaign],
    queryFn: () => getCampaign(selectedCampaign),
    enabled: !!selectedCampaign,
  });

  React.useEffect(() => {
    if (campaignData) {
      change('freeMonths', campaignData?.['freeMonths']);
      change('noticePeriod', campaignData?.['noticePeriod']);
      change('contractMonths', campaignData?.['contractMonths']);
      change('withdrawalPeriodDays', campaignData?.['withdrawalPeriodDays']);
    }
  }, [campaignData, change]);

  if (isError) {
    return (
      <Alert>
        <AlertTitle>Något gick fel</AlertTitle>
        {error?.message}
      </Alert>
    );
  }

  return (
    <>
      <CampaignAutoComplete
        service="protection"
        customerType={customerType}
        name="protectionsCampaign"
      />
      <TextInput disabled label="Fria månader" name="freeMonths" />
      <TextInput disabled label="Uppsägningstid" name="noticePeriod" />
      <TextInput disabled label="Bindningstid" name="contractMonths" />
      <TextInput disabled label="Ångerrätt" name="withdrawalPeriodDays" />
    </>
  );
};
