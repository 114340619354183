import { useQuery } from '@tanstack/react-query';

import { getNextSveaInvoice } from '@/api/customers';
import { queryKeys } from '@/constants/queryKeys';
import { usePrivateSettings } from '@/hooks';

export const useGetNextSveaInvoice = (customerId?: string | number) => {
  const { invoiceProvider } = usePrivateSettings();
  const hasSveaInvoiceProvider = invoiceProvider?.name.toLowerCase() == 'svea';

  return useQuery({
    queryKey: [queryKeys.getNextSveaInvoice, customerId],
    queryFn: async () => await getNextSveaInvoice(customerId!),
    enabled: !!customerId && hasSveaInvoiceProvider,
  });
};
