import { Error } from '@mui/icons-material';
import { Alert, Badge, Button, Checkbox, LinearProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { UseMutationResult, useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { makeValidate } from 'mui-rff';
import { Form } from 'react-final-form';
import invariant from 'tiny-invariant';
import * as Yup from 'yup';

import { CreateNotePayload } from '@/api/notes';
import { fetchUsers } from '@/api/users';
import { Can } from '@/components/Can';
import { Autocomplete, Dropdown, TextArea } from '@/components/form';
import { getNoteTypeOptions } from '@/constants';
import { usePrivateSettings } from '@/hooks';
import { useAuth } from '@/hooks/useAuth';
import { MailIcon } from '@/icons';
import { NoteItem } from '@/types/notes';
import { checkRolesAccess } from '@/utils/authorization';
import { getPrioritizedNoteTypes } from '@/utils/customer';

const validationSchema = Yup.object().shape({
  text: Yup.string().required(),
  type: Yup.string().required(),
});

type Props = {
  customerId: number;
  isInSecurityService: boolean;
  createNote: UseMutationResult<
    AxiosResponse<NoteItem, any>,
    unknown,
    CreateNotePayload,
    unknown
  >;
};

export const CustomerNotesForm = ({
  customerId,
  isInSecurityService,
  createNote,
}: Props) => {
  // @ts-ignore
  const validate = makeValidate(validationSchema);

  const { getAuthenticatedUserRoles } = useAuth();
  const { privateSettings } = usePrivateSettings();
  const userRoles = getAuthenticatedUserRoles();
  const noteTypes = privateSettings?.types.note || {};

  const isCustomerServiceUser =
    userRoles?.includes('ROLE_CUSTOMER_SUPPORT') ||
    userRoles?.includes('ROLE_CUSTOMER_SUPPORT_MANAGER');

  const isTaggable = checkRolesAccess(userRoles, 'users.isTaggable');

  const noteTypesOptions = getNoteTypeOptions(noteTypes).filter((type) => {
    invariant(typeof type.value === 'string', 'Type value should be a string');

    const BLOCK_SUPPORT_TYPE = 14;
    const TO_ADMIN_TYPE = 21;

    if (isInSecurityService) {
      return parseInt(type.value, 10) === BLOCK_SUPPORT_TYPE;
    } else {
      return parseInt(type.value, 10) !== TO_ADMIN_TYPE;
    }
  });

  const {
    data: usersList,
    isFetching: fetchingUsersList,
    isSuccess: fetchUsersSuccess,
    isError: fetchUsersError,
  } = useQuery({
    queryKey: ['usersToTag'],

    queryFn: async () => {
      const response = await fetchUsers({
        taggable: true,
        computer: false,
        pagination: false,
      });

      return response['hydra:member']
        .filter((user) => user.deleted === false)
        .map((user) => ({
          id: user.id,
          name: user.name,
          email: user.email,
        }));
    },

    enabled: isTaggable,
  });

  return (
    <Form
      initialValues={{
        type: isInSecurityService ? 14 : 2,
        taggedUsers: [],
      }}
      validate={validate}
      onSubmit={async (values, form) => {
        const { text, type, taggedUsers } = values;

        try {
          invariant(text, 'Note text should be defined');
          invariant(type, 'Note type should be defined');

          createNote.mutate({
            text,
            type,
            customerId,
            taggedUsers,
          });

          form.restart();
        } catch (error) {
          console.error(error);
        }
      }}
    >
      {({ handleSubmit }) => (
        <StyledForm onSubmit={handleSubmit}>
          <TextArea label="Kundnoteringar" name="text" rows={4} required />

          <Dropdown
            label="Typ"
            name="type"
            required
            options={getPrioritizedNoteTypes(
              noteTypesOptions,
              isCustomerServiceUser,
            )}
            disabled={isInSecurityService}
          />

          <Can
            userRoles={userRoles}
            action="users.isTaggable"
            yes={() => {
              if (fetchUsersError) {
                return (
                  <Alert severity="error">
                    Kunde inte hämta tagbara användare
                  </Alert>
                );
              }

              if (fetchingUsersList) {
                return <LinearProgress />;
              }

              if (fetchUsersSuccess) {
                return (
                  <Autocomplete
                    label="Tagga"
                    name="taggedUsers"
                    options={usersList}
                    multiple
                    disableCloseOnSelect
                    textFieldProps={{ variant: 'outlined' }}
                    getOptionValue={(option) => `/users/${option.id}`}
                    getOptionLabel={(option) =>
                      typeof option === 'string' ? option : option.name!
                    }
                    getOptionDisabled={(option) => option.email === ''}
                    renderOption={(props, option, { selected }) => (
                      <li {...props} key={option.id! + option.name!}>
                        <Checkbox checked={selected} />
                        {option.name}
                        {option.email === '' && (
                          <Badge
                            sx={{
                              margin: '0 1rem 0 auto',
                            }}
                            badgeContent={<Error color="error" />}
                          >
                            <MailIcon />
                          </Badge>
                        )}
                      </li>
                    )}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  />
                );
              }
            }}
          />

          <SubmitButton variant="outlined" type="submit">
            Skicka
          </SubmitButton>
        </StyledForm>
      )}
    </Form>
  );
};

const StyledForm = styled('form')(({ theme }) => ({
  flex: '0 1 auto',
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.button.send.default,
  color: 'white',
  margin: theme.spacing(2, 0, 0, 0),
  '&:hover': {
    backgroundColor: theme.palette.button.send.hover,
  },
}));
