import { TableHead, TableRow, TableCell } from '@mui/material';
import { styled } from '@mui/material/styles';

import { SimpleTableColumn, SimpleTableHeadVariant } from './SimpleTable';

type Props = {
  headVariant: SimpleTableHeadVariant;
  columns: SimpleTableColumn[];
};

const StyledTableHead = styled(TableHead)<{ variant: SimpleTableHeadVariant }>`
  background-color: ${({ variant, theme }) =>
    variant === 'white' ? theme.palette.common.white : 'inherit'};
`;

export const HeadSection = ({ columns, headVariant }: Props) => {
  return (
    <StyledTableHead variant={headVariant}>
      <TableRow>
        {columns.map((column, index) => (
          <TableCell key={index} align={column.align ? column.align : 'left'}>
            {column.label}
          </TableCell>
        ))}
      </TableRow>
    </StyledTableHead>
  );
};
