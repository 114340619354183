import { Box, Button } from '@mui/material';
import pick from 'lodash/pick';
import { Form } from 'react-final-form';
import { z } from 'zod';

import { getTypeKey } from '@/helpers';
import { usePrivateSettings } from '@/hooks';
import useUserRoles from '@/hooks/useUserRoles';
import { mapInitialObjectValuesToStrings } from '@/modules/services/utils';
import { CoProtectionParamSchema } from '@/types/services';
import { coProtectionStatusKey } from '@/types/settings';
import { checkRolesAccess } from '@/utils/authorization';

import { AntiTheftDecalInformation } from './AntiTheftDecalInformation';
import { CardsSection } from './CardsSection';
import { MobilesSection } from './MobilesSection';
import { SalesInformationForm } from './SalesInformationForm';
import { ServiceInfoForm } from './ServiceInfoForm';
import { WatchListCard } from './WatchList/WatchListCard';

export const FIELDS_TO_PICK = [
  'abTest',
  'addressList',
  'adminNote',
  'antiregret',
  'billing',
  'campaign',
  'churnDate',
  'churnReason',
  'email',
  'kamId',
  'leadSource',
  'monitoringPlan',
  'name',
  'phone',
  'pin',
  'pricePlanId',
  'regDate',
  'regretReason',
  'regretSource',
  'reseller',
  'salesRep',
  'status',
  'tb',
  'type',
  'winBack',
];

const coProtectionFormValuesSchema = z.object({
  abTest: z.string(),
  addressList: z.string(),
  adminNote: z.string().nullable(),
  antiregret: z.number().int(),
  billing: z.number().int().min(0).max(1),
  campaign: z.string(),
  churnDate: z.date().nullable(),
  churnReason: z.number().int(),
  email: z.string().nullable(),
  kamId: z.number().int().nullable(),
  leadSource: z.string(),
  name: z.string().nullable(),
  monitoringPlan: z.number().int(),
  phone: z.string().nullable(),
  pin: z.string(),
  pricePlanId: z.number().int(),
  regDate: z.string(),
  regretReason: z.number().int().min(0).max(12),
  regretSource: z.number().int().min(0).max(4),
  reseller: z.string(),
  salesRep: z.string(),
  status: coProtectionStatusKey,
  tb: z.number().nullable(),
  type: z.union([z.literal(0), z.literal(1)]),
  winBack: z.number(),
});

type FormValues = z.infer<typeof coProtectionFormValuesSchema>;

export type CoProtectionEditProps = {
  serviceDetails: CoProtectionParamSchema;
  isLoading: boolean;
  onSubmit: (v: FormValues) => void;
};

export const CoProtectionEdit = ({
  serviceDetails,
  isLoading,
  onSubmit,
}: CoProtectionEditProps) => {
  const serviceName = 'coProtection';
  const { servicesStatuses } = usePrivateSettings();
  const userRoles = useUserRoles();
  const initialValues = mapInitialObjectValuesToStrings(
    pick(serviceDetails, FIELDS_TO_PICK),
  );

  const activeStatus =
    getTypeKey(servicesStatuses, 'coProtection', 'aktiv') ?? '';
  const isActive = parseInt(activeStatus, 10) === serviceDetails.status;
  const canEditWatchList = checkRolesAccess(
    userRoles,
    'services.coProtection.watchList',
  );

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <Box display="flex" flexDirection="column" gap={2}>
              <ServiceInfoForm
                serviceDetails={serviceDetails}
                updateInProgress={isLoading}
                churnDateValue={values.churnDate} //TODO: is type correct?
                statusValue={values.status}
                protectedPhoneNr={values.phone}
              />

              <AntiTheftDecalInformation
                updateInProgress={isLoading}
                editMode
              />

              <SalesInformationForm
                serviceName={serviceName}
                serviceDetails={serviceDetails}
                updateInProgress={isLoading}
              />
            </Box>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={isLoading}
              sx={{ marginTop: 2 }}
            >
              Uppdatera
            </Button>
          </form>
        )}
      />
      <MobilesSection serviceId={serviceDetails.id} />
      <CardsSection serviceId={serviceDetails.id} />

      {canEditWatchList && isActive && (
        <WatchListCard
          pin={serviceDetails.pin}
          onboardedAt={serviceDetails.onboardedAt}
          onSubmit={onSubmit}
        />
      )}
    </Box>
  );
};
