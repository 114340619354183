import { Card, CardContent, Divider, List } from '@mui/material';

import { CustomerProps } from '../../../../../types';
import { ChecklistTitle } from '../../../commonStyles';
import { MessageCustomer } from './MessageCustomer';
import { Todo } from './Todo';

// Fourth tab for Protection Checklist
export const FourthTab = (props: CustomerProps) => {
  return (
    <Card>
      <ChecklistTitle variant="h6">
        Kund har blivit Id-kapad men har kvar sina Id-handlingar
      </ChecklistTitle>
      <Divider />
      <CardContent>
        <List>
          <MessageCustomer />
          <Todo {...props} />
        </List>
      </CardContent>
    </Card>
  );
};
