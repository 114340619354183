import { SvgIcon, SvgIconProps } from '@mui/material';

export const WhiteLabelIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 96 96" width="96px" height="96px">
      <g xmlns="http://www.w3.org/2000/svg" id="surface40582829">
        <path d="M 47.902344 8 C 47.261719 8.023438 46.648438 8.246094 46.144531 8.644531 L 17.71875 31.039062 C 14.109375 33.882812 12 38.226562 12 42.820312 L 12 81 C 12 83.726562 14.273438 86 17 86 L 79 86 C 81.726562 86 84 83.726562 84 81 L 84 42.820312 C 84 38.226562 81.890625 33.882812 78.28125 31.039062 L 49.855469 8.644531 C 49.300781 8.207031 48.609375 7.976562 47.902344 8 Z M 48 14.820312 L 74.570312 35.753906 C 76.738281 37.460938 78 40.0625 78 42.820312 L 78 80 L 18 80 L 18 42.820312 C 18 40.0625 19.261719 37.460938 21.429688 35.753906 Z M 31 40 C 29.917969 39.984375 28.910156 40.554688 28.367188 41.488281 C 27.820312 42.421875 27.820312 43.578125 28.367188 44.511719 C 28.910156 45.445312 29.917969 46.015625 31 46 L 65 46 C 66.082031 46.015625 67.089844 45.445312 67.632812 44.511719 C 68.179688 43.578125 68.179688 42.421875 67.632812 41.488281 C 67.089844 40.554688 66.082031 39.984375 65 40 Z M 31 52 C 29.917969 51.984375 28.910156 52.554688 28.367188 53.488281 C 27.820312 54.421875 27.820312 55.578125 28.367188 56.511719 C 28.910156 57.445312 29.917969 58.015625 31 58 L 65 58 C 66.082031 58.015625 67.089844 57.445312 67.632812 56.511719 C 68.179688 55.578125 68.179688 54.421875 67.632812 53.488281 C 67.089844 52.554688 66.082031 51.984375 65 52 Z M 31 64 C 29.917969 63.984375 28.910156 64.554688 28.367188 65.488281 C 27.820312 66.421875 27.820312 67.578125 28.367188 68.511719 C 28.910156 69.445312 29.917969 70.015625 31 70 L 65 70 C 66.082031 70.015625 67.089844 69.445312 67.632812 68.511719 C 68.179688 67.578125 68.179688 66.421875 67.632812 65.488281 C 67.089844 64.554688 66.082031 63.984375 65 64 Z M 31 64 " />
      </g>
    </SvgIcon>
  );
};
