import { ListItemIcon } from '@mui/material';

import { getObjectKeys } from '@/helpers/tsHelpers';
import * as Icons from '@/icons';

export const renderIcon = (icon: string) => {
  const iconKeys = getObjectKeys(Icons);
  const i = iconKeys.find((k) => k === icon);

  if (i) {
    const IconComponent = Icons[i];
    return (
      <ListItemIcon sx={{ minWidth: '2rem' }}>
        <IconComponent color="primary" />
      </ListItemIcon>
    );
  }
  console.error('Icon does not exist');
  return null;
};
