import {
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

export type ResellerStatsDetailedTableData = {
  headers: string[];
  data: Array<Array<React.ReactNode>>;
};

type Props = {
  data: ResellerStatsDetailedTableData | null;
};

export const DetailedInfoTable = ({ data }: Props) => {
  if (!data) {
    return <LinearProgress />;
  }

  const columnsWithOneColSpanCount = 1;

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {data.headers.map((item, index) => (
              <TableCell
                key={index}
                colSpan={index <= columnsWithOneColSpanCount ? 1 : 2}
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {data.data.length > 0 ? (
            <>
              {data.data.map((item, index) => (
                <TableRow hover key={index}>
                  {item.map((cellData, index) => (
                    <TableCell key={index}>{cellData}</TableCell>
                  ))}
                </TableRow>
              ))}
            </>
          ) : (
            <TableRow>
              <TableCell>
                <Typography>Ingen Data</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
