import React from 'react';

import { Button, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { Form } from 'react-final-form';

import { singleTemplateUpload } from '@/api/files';
import {
  ChosenFilesList,
  FileUploadInputButton,
} from '@/components/FileUpload';
import { Checkboxes, Dropdown } from '@/components/form';
import { templateTypeOptions } from '@/constants/FileTypes';
import { usePrivateSettings, useSnackbar } from '@/hooks';
import { TemplateTypes } from '@/types/files';
import { CamelCaseServiceName } from '@/types/services';

type FormValues = {
  file: Array<File>;
  templateType: TemplateTypes;
  serviceType: CamelCaseServiceName;
};
export const PredefinedTemplatesUploadForm = () => {
  const snackbar = useSnackbar();

  const { services, servicesNames } = usePrivateSettings();

  const servicesOptions = Object.keys(services).map((key) => ({
    value: key,
    text: servicesNames[key as keyof typeof servicesNames],
  }));

  const uploadMutation = useMutation({
    mutationFn: (values: FormValues) =>
      singleTemplateUpload('documentable/template', values),
    onSuccess: () => {
      snackbar({ type: 'success', message: 'Uppladdning lyckades.' });
    },
    onError: () => {
      snackbar({ type: 'error', message: 'Uppladdning misslyckades.' });
    },
  });
  return (
    <Form
      onSubmit={(values: FormValues, { restart }) => {
        uploadMutation.mutateAsync(values).then(() => restart());
      }}
    >
      {({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <Typography>Brevhuvud</Typography>{' '}
          <Checkboxes
            name="c5h2Address"
            options={{
              label:
                'Översta delen av brevet (före texten), med plats för namn och annan data',
              value: true,
            }}
            formControlProps={{ margin: 'none' }}
          />
          <Dropdown
            options={templateTypeOptions}
            name="templateType"
            label="Typ av mall"
          />
          <Dropdown
            options={servicesOptions}
            name="serviceType"
            label="Tjänst"
          />
          <FileUploadInputButton
            multiple={false}
            name="file"
            buttonText="Välj fil"
            fullWidth
            mimeTypes={[
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            ]}
            buttonId="predefined-template-file-upload-button"
          />
          <ChosenFilesList files={values?.file} title="Vald fil" />
          <Button
            sx={{ mt: 1 }}
            type="submit"
            variant="contained"
            disabled={uploadMutation.isPending}
          >
            Ladda upp
          </Button>
        </form>
      )}
    </Form>
  );
};
