import React from 'react';

import { Button, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Can } from '@/components/Can';
import { ContactButton } from '@/components/ContactButton';
import { usePrivateSettings } from '@/hooks';
import useUserRoles from '@/hooks/useUserRoles';
import { CustomerDeleteModal } from '@/modules/CustomersDelete/components/CustomerDeleteModal';
import { hasOwnProperty } from '@/utils/typeHelpers';

export function CustomerToolbar({
  customerId,
  customerPin,
}: {
  customerId: number;
  customerPin?: string;
}) {
  const userRoles = useUserRoles();
  const { privateSettings } = usePrivateSettings();

  const [deleteCustomerModalOpen, setDeleteCustomerModalOpen] =
    React.useState(false);
  const invoiceProviderSettings = privateSettings?.invoiceProvider;

  function redirectToSvea() {
    if (
      invoiceProviderSettings &&
      typeof invoiceProviderSettings.settings === 'object' &&
      hasOwnProperty(invoiceProviderSettings.settings, 'client')
    ) {
      const sveaCustomerId = invoiceProviderSettings?.settings?.client;

      if (sveaCustomerId) {
        window.open(
          `https://s5.svea.com/#views/client/${sveaCustomerId}/customer/${customerId}`,
          '_blank',
          'noopener, noreferrer',
        );
      }
    }
  }

  return (
    <Grid container justifyContent="flex-end" alignItems="center">
      <ButtonContainer>
        <Can
          userRoles={userRoles!}
          action="customers.delete"
          yes={() => (
            <Button
              color="error"
              variant="contained"
              onClick={() => setDeleteCustomerModalOpen(true)}
            >
              Ta bort
            </Button>
          )}
        />
        {invoiceProviderSettings?.name === 'svea' && (
          <Button variant="contained" color="primary" onClick={redirectToSvea}>
            Svea
          </Button>
        )}
        <ContactButton customerId={customerId} customerPin={customerPin} />
      </ButtonContainer>
      <CustomerDeleteModal
        isOpened={deleteCustomerModalOpen}
        onClose={() => setDeleteCustomerModalOpen(false)}
        customerId={customerId}
      />
    </Grid>
  );
}

const ButtonContainer = styled(Grid)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'right',
  gap: theme.spacing(2),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
}));
