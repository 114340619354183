import React from 'react';

import { AlertTitle, LinearProgress, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import arrayMutators from 'final-form-arrays';
import omit from 'lodash/omit';
import { Form } from 'react-final-form';
import { useLocation, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';

import { getCustomerById } from '@/api/customers';
import { Alert } from '@/components/Alert';
import { PageHeader } from '@/components/PageHeader';
import { normalizeToPersonNumberShort } from '@/components/form';
import { CustomerCategoryTypes } from '@/constants';
import { getChurnReasonKey } from '@/helpers/Types';
import { mapDateFieldsValues } from '@/helpers/dates';
import { getCamelCaseServiceName } from '@/helpers/tsHelpers';
import { useAddServiceToCustomer, usePrivateSettings } from '@/hooks';
import { getCamelServiceName } from '@/modules/common/utils/services/getServicesNames';
import handleCampaignReset from '@/modules/services/components/Create/utils/handleCampaignReset';
import { Customer } from '@/types/customers';
import {
  CreateSinneDTO,
  CreateSinneFormValues,
  ExcelContactPersonFields,
  PartialContactPerson,
} from '@/types/services';
import Yup, { makeValidation } from '@/utils/validation';

import { queryKeys } from '../../../../../constants/queryKeys';
import { SuccessPage } from '../SuccessPage';
import { FormFields } from './FormFields';

type ExistinCustomerInfo = {
  id: string;
  pin: string;
};
export const CreateSinneForm = () => {
  const location = useLocation();
  const { serviceName } = useParams<{ serviceName: 'sinne' }>();
  invariant(serviceName === 'sinne', 'Invalid service name');

  const [isExistingCustomer, setIsExistingCustomer] = React.useState(false);
  const [existingCustomerInfo, setExistingCustomerInfo] =
    React.useState<ExistinCustomerInfo>();
  const { servicesNames, churnReasons } = usePrivateSettings();

  const getHeaderText = () =>
    existingCustomerInfo?.id ? 'Befintlig kund' : 'Ny Kund';
  const mappedServiceName = servicesNames[getCamelCaseServiceName('sinne')];
  const {
    handleSubmit,
    isLoading,
    allSuccess,
    mutatedCustomer,
    createServiceFromTemplates,
    createServiceSuccess,
    errors: addServiceErrors,
  } = useAddServiceToCustomer(serviceName);

  const existingCustomerId = existingCustomerInfo?.id;

  const { data: customerData, isLoading: loadingCustomerData } = useQuery({
    queryKey: [queryKeys.getCustomerById, existingCustomerId],
    queryFn: () => getCustomerById(existingCustomerId!),
    enabled: !!existingCustomerId,
  });

  const onSubmit = (
    values: CreateSinneFormValues,
    customerData: Customer | undefined,
  ) => {
    values = mapDateFieldsValues(values);

    const contactObjKeys = Object.keys(values);
    const contactNumbersAdded: string[] = [];

    type ContactPerson = PartialContactPerson & { '@id': string };

    const contactsArray = contactObjKeys.reduce((acc, key) => {
      if (
        key.match(/^name\d+$/) ||
        key.match(/^phone\d+$/) ||
        key.match(/^relation\d+$/) ||
        key.match(/^id\d+$/)
      ) {
        const fieldNumber = key.slice(-1);
        const nameKey = `name${fieldNumber}` as keyof ExcelContactPersonFields;
        const name = values[nameKey] || '';

        const phoneKey =
          `phone${fieldNumber}` as keyof ExcelContactPersonFields;
        const phone = values[phoneKey] || '';

        const relationKey =
          `relation${fieldNumber}` as keyof ExcelContactPersonFields;
        const relation = values[relationKey] || '';

        const idKey = `@id${fieldNumber}` as keyof ExcelContactPersonFields;
        const id = values[idKey];

        if (contactNumbersAdded.indexOf(fieldNumber) === -1) {
          const obj = {
            name,
            phone,
            relation,
            '@id': '',
          };
          if (id) {
            obj['@id'] = id;
          }
          acc.push(obj);
          contactNumbersAdded.push(fieldNumber);
        }
      }

      return acc;
    }, [] as ContactPerson[]);

    if (values.category === CustomerCategoryTypes.PRIVATE) {
      values.pin = normalizeToPersonNumberShort(values.pin);
    }

    const fieldsToPost = omit(values, [
      'name1',
      'name2',
      'name3',
      'name4',
      'name5',
      'phone1',
      'phone2',
      'phone3',
      'phone4',
      'phone5',
      'relation1',
      'relation2',
      'relation3',
      'relation4',
      'relation5',
    ]);

    const payload: CreateSinneDTO = {
      ...fieldsToPost,
      contactPeople: contactsArray,
      customer: '',
      churnReason:
        values.churnDate && churnReasons
          ? getChurnReasonKey(
              churnReasons,
              getCamelServiceName(serviceName),
              'Auto-churn',
            )
          : undefined,
    };

    if (isExistingCustomer && customerData) {
      const { templates, ...salesDataFields } = payload;
      salesDataFields.customer = customerData['@id'];
      createServiceFromTemplates(templates, salesDataFields);
    } else {
      handleSubmit(payload);
    }
  };

  React.useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const customerId = queryParams.get('customerId');
    const pin = queryParams.get('pin');

    if (customerId && pin) {
      setIsExistingCustomer(true);
      setExistingCustomerInfo({
        id: customerId,
        pin,
      });
    }
  }, [location]);

  const customerId = isExistingCustomer
    ? existingCustomerInfo?.id
    : mutatedCustomer?.id;

  const validationSchema = Yup.object({
    zip: Yup.string()
      .required()
      .matches(/^\d+$/, 'Enbart siffror accepteras')
      .min(5, 'Måste bestå av exakt 5 siffror')
      .max(5, 'Måste bestå av exakt 5 siffror'),
    mobile: Yup.string().matches(/^\d+$/, {
      excludeEmptyString: true,
      message: 'Enbart siffror accepteras',
    }),
    phone: Yup.string().matches(/^\d+$/, {
      excludeEmptyString: true,
      message: 'Enbart siffror accepteras',
    }),
    name1: Yup.string().required('Förnamn krävs'),
    phone1: Yup.string().required('Mobilnummer krävs'),
    relation1: Yup.string().required('Relation krävs'),
  });

  const validateContactInformation = makeValidation(validationSchema);

  if (
    customerId &&
    (allSuccess || (isExistingCustomer && createServiceSuccess))
  ) {
    return (
      <SuccessPage
        customerId={customerId}
        mappedServiceName={mappedServiceName}
      />
    );
  }

  return (
    <>
      <PageHeader margin title={`${getHeaderText()} - ${mappedServiceName}`} />
      {addServiceErrors.length > 0 &&
        addServiceErrors.map((error) => (
          <Alert
            sx={{ mb: 2 }}
            severity="error"
            key={error['hydra:description']}
          >
            <AlertTitle>Ett fel uppstod</AlertTitle>
            <>
              <Typography variant="body1" key={error['hydra:title']}>
                Fel: {error['hydra:title']}
              </Typography>
              <Typography variant="body1" key={error['hydra:description']}>
                Beskrivning: {error['hydra:description']}
              </Typography>
            </>
          </Alert>
        ))}
      <Form
        onSubmit={(values: CreateSinneFormValues) =>
          onSubmit(values, customerData)
        }
        mutators={{
          ...arrayMutators,
        }}
        validate={isExistingCustomer ? undefined : validateContactInformation}
        decorators={[handleCampaignReset]}
        subscription={{ submitting: true, pristine: true, values: true }}
        render={({
          handleSubmit,
          values,
          form,
          form: {
            mutators: { push },
          },
        }) => (
          <>
            {isLoading || loadingCustomerData ? (
              <LinearProgress />
            ) : (
              <FormFields
                isExistingCustomer={isExistingCustomer}
                form={form}
                handleSubmit={handleSubmit}
                values={values}
                push={push!}
                customerData={customerData}
              />
            )}
          </>
        )}
      />
    </>
  );
};
