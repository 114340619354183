import { Box } from '@mui/material';

import { PageWrapper } from '@/components';
import { PartnerStatistics } from '@/modules/PartnerPortal';

const PartnerAccountStats = () => {
  return (
    <PageWrapper
      pageTitle={'Statistik'}
      breadCrumbs={[
        { label: 'Partners', url: '/partner/accounts' },
        { label: 'Statistik' },
      ]}
      maxWidth="lg"
    >
      <Box>
        <PartnerStatistics />
      </Box>
    </PageWrapper>
  );
};

export default PartnerAccountStats;
