import React from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button, Grid, Typography } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';
import { useQueryClient } from '@tanstack/react-query';
import { FormApi } from 'final-form';
import { Form } from 'react-final-form';
import { useNavigate } from 'react-router-dom';

import { LoadingSpinner } from '@/components/LoadingSpinner';
import { SimpleCard } from '@/components/SimpleCard';
import { TextInput } from '@/components/form';
import { KEYTAG_STICKER_STATUSES_ENUM } from '@/constants/KeyTagAndStickerStatuses';
import {
  getCamelCaseServiceName,
  getKebabCaseServiceName,
} from '@/helpers/tsHelpers';
import { usePrivateSettings } from '@/hooks';
import { fieldRequired } from '@/utils/validation';

import { useGetKeyTags } from '../hooks/useGetKeyTags';
import { useGetStickers } from '../hooks/useGetStickers';

export const FoundItemCard = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { servicesNames } = usePrivateSettings();
  const [code, setCode] = React.useState('');

  const {
    data: keyTags,
    isLoading: loadingKeyTags,
    isError: keyTagsError,
    isSuccess: keyTagsSuccess,
  } = useGetKeyTags(code);

  const {
    data: stickers,
    isLoading: loadingStickers,
    isError: stickersError,
    isSuccess: stickersSuccess,
  } = useGetStickers(code);

  const loading = loadingKeyTags || loadingStickers;
  const error = keyTagsError || stickersError;
  const success = keyTagsSuccess && stickersSuccess;

  const foundSticker = stickers && stickers[0];
  const foundKeyTag = keyTags && keyTags[0];

  const foundItem = foundSticker ? foundSticker : foundKeyTag;

  const typeNameCamel =
    (foundSticker && getCamelCaseServiceName(foundSticker.type)) ||
    (foundKeyTag && getCamelCaseServiceName(foundKeyTag.type));

  const status =
    (foundSticker && foundSticker.status) ||
    (foundKeyTag && foundKeyTag.status);

  const kebabCasedServiceName = typeNameCamel
    ? getKebabCaseServiceName(typeNameCamel)
    : null;

  const handleReset = (form: FormApi) => {
    setCode('');
    queryClient.resetQueries({
      queryKey: ['findProduct'],
    });
    form.resetFieldState('code');
  };

  return (
    <Grid item lg={4} md={6} xs={12}>
      <Form onSubmit={({ code }) => setCode(code)}>
        {({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <SimpleCard
              title="Upphittat föremål"
              cardContent={
                <>
                  <StyledInputDescription variant="body1">
                    Ange <strong>föremålets</strong> seriekod.
                  </StyledInputDescription>
                  <TextInput
                    label="Seriekod"
                    name="code"
                    fieldProps={{ validate: fieldRequired }}
                    InputProps={{
                      endAdornment:
                        loading || !success ? null : (
                          <InputAdornment position="end">
                            <StyledCircleIcon variant={status || 0} />
                          </InputAdornment>
                        ),
                    }}
                    onKeyDown={() => (success || error) && handleReset(form)}
                  />

                  {loading && <LoadingSpinner />}

                  {success &&
                    foundItem &&
                    status === KEYTAG_STICKER_STATUSES_ENUM.ACTIVE &&
                    typeNameCamel && (
                      <StyledStatusDescription variant="body1">
                        Kund har en {servicesNames[typeNameCamel].toLowerCase()}{' '}
                        aktiv, hittelön är möjlig.
                        <br />
                        <strong>Gå vidare</strong> för att forsätta processen.
                      </StyledStatusDescription>
                    )}

                  {success &&
                    foundItem &&
                    status !== KEYTAG_STICKER_STATUSES_ENUM.ACTIVE &&
                    typeNameCamel && (
                      <StyledStatusDescription variant="body1">
                        {servicesNames[typeNameCamel]} funnen men inte aktiv.
                        <br />
                        Berätta att kunden med denna seriekod inte längre är
                        kund och vi kan därför inte hjälpa till med hittelön.
                        <br />
                        <strong>Be upphittaren</strong> lämna objektet till
                        Polisen.
                      </StyledStatusDescription>
                    )}

                  {success && !foundItem && (
                    <StyledStatusDescription variant="body1">
                      Ingen seriekod matchade våra kunder.
                      <br />
                      <strong>Informera uppringare</strong> att kontakta
                      polisens hittegodsavdelning.
                    </StyledStatusDescription>
                  )}
                </>
              }
              cardActions={
                success &&
                foundItem &&
                kebabCasedServiceName &&
                status === 1 ? (
                  <Button
                    color="primary"
                    onClick={() =>
                      navigate(
                        `/security-service/found-object/${kebabCasedServiceName}/${foundItem.code}`,
                      )
                    }
                    variant="contained"
                  >
                    Gå vidare
                  </Button>
                ) : (
                  <Button color="primary" type="submit" variant="outlined">
                    Sök
                  </Button>
                )
              }
            />
          </form>
        )}
      </Form>
    </Grid>
  );
};

const StyledInputDescription = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const StyledStatusDescription = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const StyledCircleIcon = styled(CheckCircleIcon)<{ variant: number }>`
  color: ${({ theme, variant }) => {
    switch (variant) {
      case KEYTAG_STICKER_STATUSES_ENUM.ACTIVE:
        return theme.palette.success.main;

      default:
        return theme.palette.error.main;
    }
  }};
`;
