import { SimpleCard } from '@/components/SimpleCard';
import { ProtectionListSection } from '@/modules/services/components/sections';

export const ProtectionListForm = () => {
  return (
    <SimpleCard
      marginBottom="0"
      title="Skyddad"
      cardContent={
        <>
          <section>
            <ProtectionListSection />
          </section>
        </>
      }
    />
  );
};
