import { Button } from '@mui/material';

import { useSnackbar } from '@/hooks';

import { useExportBillingData } from '../../hooks/useExportBillingData';
import { generateAndClickDownloadExcelLink } from '../../utils/generateAndClickDownloadLink';

export const ExportButton = () => {
  const snackbar = useSnackbar();

  const exportBillingMutation = useExportBillingData();

  const handleExportBilling = () => {
    exportBillingMutation.mutate(undefined, {
      onSuccess: (response) => {
        generateAndClickDownloadExcelLink(response);
      },
      onError: () => {
        snackbar({
          type: 'error',
          message: 'Misslyckades ladda ner faktureringsunderlag',
        });
      },
    });
  };

  return (
    <Button
      color="primary"
      variant="outlined"
      disabled={exportBillingMutation.isPending}
      onClick={handleExportBilling}
    >
      EXPORTERA
    </Button>
  );
};
