import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  ListItem,
  ListItemText,
  Skeleton,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { getCustomerById } from '@/api/customers';
import { BasicAlert } from '@/components/BasicAlert';

type CustomerDataResponse = {
  address: string;
  fullName: string;
  companyCategory: boolean;
  companyName: string;
};
export const CustomerInformationCard = ({
  customerId,
}: {
  customerId: number | undefined;
}) => {
  const {
    data: customer,
    isInitialLoading: isLoading,
    isError,
  } = useQuery({
    queryKey: ['getCustomer', customerId],

    queryFn: () =>
      getCustomerById(customerId!, {
        fullName: true,
        companyName: true,
        address: true,
        companyCategory: true,
      }),

    enabled: !!customerId,
  });

  return (
    <Card sx={{ '& .MuiCardContent-root:last-child': { padding: 0 } }}>
      <CardHeader title="Kundinformation" />
      <Divider />

      <CardContent sx={{ p: 0 }}>
        {isLoading ? (
          <>
            <Skeleton height={45} />
            <Skeleton height={45} />
          </>
        ) : isError ? (
          <BasicAlert />
        ) : (
          <>
            <ListItem>
              <ListItemText
                primary={
                  <b>{customer?.companyCategory ? 'Företag' : 'Namn'}</b>
                }
              />
              <ListItemText
                primary={
                  customer?.companyCategory
                    ? customer.companyName
                    : customer?.fullName
                }
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText primary={<b>Adress</b>} />
              <ListItemText primary={customer?.address} />
            </ListItem>
          </>
        )}
      </CardContent>
    </Card>
  );
};
