import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField as MuiTextField,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { z } from 'zod';

import { InfoMessage } from '@/components/InfoMessage';
import { getFormattedDate } from '@/helpers/dates';
import { useSnackbar } from '@/hooks';
import useUser from '@/hooks/useUser';
import { Textfield } from '@/modules/components';
import { FloatInput } from '@/modules/components/FloatInput/FloatInput';
import { useCreateSveaBulkFees } from '@/modules/economy/hooks/useCreateSveaBulkFees';

const required_error = 'Fältet är obligatoriskt';
const validationSchema = z.object({
  amount_excl_vat: z
    .string()
    .nonempty({ message: required_error })
    .refine(
      (value) => {
        const normalizedValue = value.replace(',', '.');
        return (
          !isNaN(parseFloat(normalizedValue)) &&
          isFinite(parseFloat(normalizedValue))
        );
      },
      {
        message: 'Fel format',
      },
    ),
  customers: z
    .string()
    .nonempty({ message: required_error })
    .refine((val) => {
      const numbers = val.split('\n').filter((num) => !isNaN(Number(num)));
      const notNumbers = val.split('\n').filter((num) => isNaN(Number(num)));
      if (notNumbers.length > 0) {
        return false;
      }
      return numbers.join('\n');
    }, 'Endast nummer tillåtna'),
  description: z
    .string({ required_error })
    .nonempty({ message: required_error }),
  start_date: z
    .string()
    .refine(
      (val) => /^\d{4}-\d{2}-\d{2}$/.test(val),
      'Måste ha formatet 2023-01-01',
    ),
});

type FormValues = z.infer<typeof validationSchema>;
export const SveaBulkFeeFormCard = () => {
  const snackbar = useSnackbar();
  const bulkFeeMutation = useCreateSveaBulkFees();

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm<FormValues>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      start_date: getFormattedDate(new Date())!,
    },
    mode: 'onChange',
  });

  const onSubmit = (values: FormValues) => {
    const { amount_excl_vat } = values;
    const parsedCustomers = values.customers.split('\n');

    const amount = parseFloat(amount_excl_vat.replace(',', '.'));

    const payload = {
      ...values,
      customers: parsedCustomers,
      amount_excl_vat: amount,
    };

    bulkFeeMutation.mutate(payload, {
      onSuccess: () => {
        snackbar({ message: 'Avgifter skapade', type: 'success' });
        reset();
      },
      onError: () => {
        snackbar({ message: 'Misslyckades skapa avgifter', type: 'error' });
      },
    });
  };

  const { email } = useUser();

  return (
    <Card component="form" onSubmit={handleSubmit(onSubmit)}>
      <CardHeader title="Här kan ni skicka in avgifter ni önskar fakturera era kunder genom Fenix. Ett e-post meddelande skickas till er när importen är slutförd." />
      <Divider />
      <CardContent sx={{ '& .MuiFormControl-root': { m: 0 } }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Textfield
              label="Avgiftstext"
              error={errors.description}
              fullWidth
              inputProps={{
                ...register('description'),
                'data-testid': 'svea-bank-bulk-fee-description',
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FloatInput
              control={control}
              name="amount_excl_vat"
              label="Pris (exkl. moms)"
              fullWidth
              inputProps={{
                'data-testid': 'svea-bank-bulk-fee-amount_excl_vat',
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="start_date"
              control={control}
              render={({ field }) => (
                <DatePicker
                  label="Avgiftsdatum (åååå-mm-dd)"
                  inputFormat="yyyy-MM-dd"
                  value={field.value}
                  onChange={(date) => {
                    const formattedDate = getFormattedDate(date);
                    if (formattedDate) {
                      field.onChange(formattedDate);
                    }
                  }}
                  InputProps={{
                    fullWidth: true,
                    error: Boolean(errors.start_date),
                    // @ts-ignore
                    'data-testid': 'svea-bank-bulk-fee-start_date',
                  }}
                  renderInput={(params) => <MuiTextField {...params} />}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Textfield
              label="Kundnummer"
              error={errors.customers}
              fullWidth
              multiline
              rows={10}
              inputProps={{
                ...register('customers'),
                'data-testid': 'svea-bank-bulk-fee-customers',
              }}
            />
            <InfoMessage sx={{ mt: 1 }}>
              Ange ett kundnummer per rad
            </InfoMessage>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardActions sx={{ p: 2, justifyContent: 'space-between' }}>
        <Grid>
          <Button
            type="submit"
            variant="outlined"
            disabled={bulkFeeMutation.isPending || !email}
          >
            Skapa
          </Button>
          {!email && (
            <Typography sx={{ marginTop: '1rem' }} color="error">
              En e-post på din användarprofil krävs. Uppdatera den{' '}
              <Link to="/settings/me">här</Link>. Logga sedan ut och in igen.
            </Typography>
          )}
        </Grid>
      </CardActions>
    </Card>
  );
};
