import { SvgIcon, SvgIconProps } from '@mui/material';

export const UploadIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 96 96" width="96px" height="96px">
      <g id="surface192591794">
        <path d="M 47.957031 12 C 47.175781 12.011719 46.429688 12.328125 45.878906 12.878906 L 33.878906 24.878906 C 33.09375 25.632812 32.78125 26.75 33.054688 27.800781 C 33.328125 28.851562 34.148438 29.671875 35.199219 29.945312 C 36.25 30.21875 37.367188 29.90625 38.121094 29.121094 L 45 22.242188 L 45 65 C 44.984375 66.082031 45.554688 67.089844 46.488281 67.632812 C 47.421875 68.179688 48.578125 68.179688 49.511719 67.632812 C 50.445312 67.089844 51.015625 66.082031 51 65 L 51 22.242188 L 57.878906 29.121094 C 58.632812 29.90625 59.75 30.21875 60.800781 29.945312 C 61.851562 29.671875 62.671875 28.851562 62.945312 27.800781 C 63.21875 26.75 62.90625 25.632812 62.121094 24.878906 L 50.121094 12.878906 C 49.546875 12.304688 48.765625 11.988281 47.957031 12 Z M 23 34 C 16.960938 34 12 38.960938 12 45 L 12 73 C 12 79.039062 16.960938 84 23 84 L 73 84 C 79.039062 84 84 79.039062 84 73 L 84 45 C 84 38.960938 79.039062 34 73 34 L 69 34 C 67.917969 33.984375 66.910156 34.554688 66.367188 35.488281 C 65.820312 36.421875 65.820312 37.578125 66.367188 38.511719 C 66.910156 39.445312 67.917969 40.015625 69 40 L 73 40 C 75.796875 40 78 42.203125 78 45 L 78 73 C 78 75.796875 75.796875 78 73 78 L 23 78 C 20.203125 78 18 75.796875 18 73 L 18 45 C 18 42.203125 20.203125 40 23 40 L 27 40 C 28.082031 40.015625 29.089844 39.445312 29.632812 38.511719 C 30.179688 37.578125 30.179688 36.421875 29.632812 35.488281 C 29.089844 34.554688 28.082031 33.984375 27 34 Z M 23 34 " />
      </g>
    </SvgIcon>
  );
};
