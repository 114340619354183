import { zodResolver } from '@hookform/resolvers/zod';
import { LaunchOutlined } from '@mui/icons-material';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  LinearProgress,
  Tooltip,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { z } from 'zod';

import { handleLostDevice } from '@/api';
import { Alert } from '@/components/Alert';
import { DEFAULT_VAT } from '@/constants/vat';
import { getFeeWithVat, getFeeWithoutVat } from '@/helpers/calculation';
import { useSnackbar } from '@/hooks';
import { usePrivateSettings } from '@/hooks/usePrivateSettings';
import useUser from '@/hooks/useUser';
import { FloatInput } from '@/modules/components/FloatInput/FloatInput';
import { Textfield } from '@/modules/components/TextField';

const formSchema = z.object({
  fee: z.coerce.number({ invalid_type_error: 'Fel format' }),
  note: z.string().min(10, { message: 'För kort notering' }),
  macAddress: z.string(),
  imei: z.number(),
});

type FormValues = z.infer<typeof formSchema>;

export const LostDeviceFlow = () => {
  const user = useUser();
  const snackbar = useSnackbar();
  const { sinne, servicesNames } = usePrivateSettings();

  const naviagate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const macAddress = params.deviceId;
  const imei = Number(searchParams.get('imei'));
  const serviceId = Number(searchParams.get('serviceId'));

  const successMsg =
    'Enheten har registrerats som förlorad och kunden har debiterats';
  const errorMsg =
    'Åtgärden kan inte genomföras för tillfället, var vänlig försök igen senare. Monitum har informerats om problemet.';

  const today = new Date().toLocaleDateString('sv-SE');
  const defaultNoteText = `En avgift för förlorad ${servicesNames.sinne}-enhet med mac-address: ${macAddress} har skapats av ${user.name} för kunden ${today} eftersom enheten inte returnerades.`;
  const defaultFee = Number(getFeeWithVat(sinne.customerPrice, DEFAULT_VAT));

  const { mutate, isPending } = useMutation({
    mutationFn: handleLostDevice,
    onSuccess() {
      snackbar({
        type: 'success',
        message: successMsg,
      });
      naviagate('/support/sinne/missing-devices');
    },
    onError() {
      snackbar({
        type: 'error',
        message: errorMsg,
      });
    },
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
  } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      fee: defaultFee,
      note: defaultNoteText,
      macAddress,
      imei,
    },
    mode: 'onChange',
  });

  if (!macAddress || !imei || !serviceId) {
    return <Alert severity="error">Parametrar saknas i URL</Alert>;
  }

  const onSubmit = (data: FormValues) => {
    const payload = {
      ...data,
      fee: Number(getFeeWithoutVat(data.fee)),
    };
    mutate(payload);
  };

  return (
    <Card component="form" onSubmit={handleSubmit(onSubmit)}>
      {isPending && <LinearProgress />}
      <CardHeader
        title="Registrera enheten som förlorad och debitera kunden"
        action={
          <Tooltip title="Öppna tjänsten i ny flik">
            <LaunchOutlined
              color="primary"
              onClick={(e) => {
                e.stopPropagation();
                window.open(`/products/sinne/${serviceId}/edit`);
              }}
              style={{ cursor: 'pointer' }}
            />
          </Tooltip>
        }
      />
      <Divider />
      <CardContent>
        <FloatInput
          control={control}
          fullWidth
          label="Avgift inkl. moms"
          name="fee"
          inputProps={{
            autoComplete: 'off',
            'data-testid': 'lost-flow-fee',
          }}
        />
        <Textfield
          autoComplete="off"
          error={errors.note}
          fullWidth
          label="Text på noteringen"
          multiline
          rows={4}
          inputProps={{
            ...register('note'),
            'data-testid': 'lost-flow-note-text',
          }}
        />
        <Textfield
          disabled
          error={errors.macAddress}
          fullWidth
          label={servicesNames.sinne + ' MAC'}
          value={macAddress}
          inputProps={{
            ...register('macAddress'),
            'data-testid': 'lost-flow-mac-address',
          }}
        />
        <Textfield
          disabled
          error={errors.imei}
          fullWidth
          label={servicesNames.sinne + ' IMEI'}
          value={imei}
          inputProps={{
            ...register('imei'),
            'data-testid': 'lost-flow-imei',
          }}
        />
      </CardContent>
      <Divider />
      <CardActions>
        <Button type="submit" variant="contained" disabled={isPending}>
          Spara och skapa avgift
        </Button>
      </CardActions>
    </Card>
  );
};
