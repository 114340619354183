import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import { Can } from '@/components/Can';
import { Spacing } from '@/components/Spacing';
import { getFormattedDate } from '@/helpers/dates';
import { usePrivateSettings } from '@/hooks';
import { CommonCreatedBy } from '@/types/common';
import { NoteItem } from '@/types/notes';

import { useAuth } from '../../hooks/useAuth';

type Props = {
  note: NoteItem;
  removalInProgress: boolean;
  onDelete: (note: NoteItem) => void;
};

export const Note = ({ note, removalInProgress, onDelete }: Props) => {
  const { servicesTypes } = usePrivateSettings();
  const { getAuthenticatedUserRoles } = useAuth();
  const userRoles = getAuthenticatedUserRoles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleDeleteOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDeleteClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteConfirm = () => {
    handleDeleteClose();
    onDelete(note);
  };

  const customer = note.customer!;

  const getCreatedByName = (createdBy?: CommonCreatedBy) => {
    if (!createdBy) {
      return '';
    }

    if (typeof createdBy === 'string') {
      return createdBy;
    }

    return createdBy.name;
  };

  return (
    <StyledCard noteType={note.type}>
      {removalInProgress && <LinearProgress />}
      <StyledCardHeader
        noteType={note.type}
        title={servicesTypes['note'][note.type]}
        action={
          <Can
            userRoles={userRoles}
            action="customers.noteDelete"
            yes={() => (
              <>
                <IconButton
                  aria-label="delete"
                  size="small"
                  disabled={removalInProgress}
                  onClick={(e) => {
                    handleDeleteOpen(e);
                  }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
                <Menu
                  id="delete-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleDeleteClose}
                >
                  <MenuItem onClick={handleDeleteClose} disabled>
                    Ta bort?
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={handleDeleteConfirm}>Ja</MenuItem>
                  <MenuItem onClick={handleDeleteClose}>Nej</MenuItem>
                </Menu>
              </>
            )}
          />
        }
      />
      <Divider />
      <StyledCardContent noteType={note.type}>
        <StyledLink to={`/customer/${customer.id}`}>
          <Typography variant="subtitle1">
            {customer.id} -{' '}
            {customer.company ? customer.companyName : customer.fullName}
          </Typography>
        </StyledLink>
        <Spacing />
        <Typography sx={{ whiteSpace: 'pre-line' }}>{note.text}</Typography>
        <Spacing />
        <Typography component="div" fontStyle="italic">
          Noterad av{' '}
          <Typography fontWeight="bold">
            {getCreatedByName(note.createdBy)}
          </Typography>{' '}
          den{' '}
          <Typography fontWeight="bold">
            {getFormattedDate(note?.createdAt, 'yyyy-MM-dd HH:mm:ss')}
          </Typography>
        </Typography>
        {note.taggedUsers?.length > 0 && (
          <>
            <Spacing />
            <Typography>------------------</Typography>
            {note.taggedUsers.map((user, index) => (
              <Typography
                key={typeof user !== 'string' ? user.id : user}
                component="span"
              >
                {typeof user !== 'string' && user.name}
                {index !== note.taggedUsers.length - 1 && ', '}
              </Typography>
            ))}
          </>
        )}
      </StyledCardContent>
    </StyledCard>
  );
};

const StyledCard = styled(Card, {
  shouldForwardProp: (propName) => propName !== 'noteType',
})<{ noteType: number }>(({ theme, noteType }) => ({
  margin: theme.spacing(2, 0, 0, 0),
  boxSizing: 'border-box',
  border:
    theme.palette.mode === 'dark' && !theme.palette.notes[noteType]
      ? `1px solid ${theme.palette.primary.main}`
      : 'initial',
  backgroundColor: theme.palette.notes[noteType]
    ? theme.palette.notes[noteType]?.header
    : 'initial',
  color: theme.palette.notes[noteType]
    ? theme.palette.getContrastText(theme.palette.notes[noteType]?.header!)
    : 'initial',
}));

const StyledCardHeader = styled(CardHeader, {
  shouldForwardProp: (propName) => propName !== 'noteType',
})<{ noteType: number }>(({ theme, noteType }) => ({
  '&.MuiCardHeader-action': {
    alignSelf: 'auto',
    marginRight: theme.spacing(2),
    '& .MuiIconButton-root': {
      color:
        theme.palette.notes[noteType] &&
        theme.palette.getContrastText(theme.palette.notes[noteType]?.header!),
    },
  },
}));

const StyledCardContent = styled(CardContent, {
  shouldForwardProp: (propName) => propName !== 'noteType',
})<{ noteType: number }>(({ theme, noteType }) => ({
  backgroundColor: theme.palette.notes?.[noteType]
    ? theme.palette.notes[noteType]?.content
    : 'inherit',
  color: theme.palette.notes?.[noteType]
    ? theme.palette.getContrastText(theme.palette.notes[noteType]?.content!)
    : 'inherit',
}));

const StyledLink = styled(Link)({
  color: 'inherit',
  textDecoration: 'underline',
});
