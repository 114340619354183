import { DEFAULT_VAT } from '@/constants/vat';

export const getFeeWithVat = (
  fee: number | string | null = 0,
  vat: number | string | null = 0,
) => {
  if (fee === null || vat === null) {
    return null;
  }

  let parsedFee = parseFloat(fee.toString());
  let parsedVat = parseFloat(vat.toString());

  if (isNaN(parsedFee) || isNaN(parsedVat)) {
    return null;
  }

  return (parsedFee * (1 + parsedVat / 100)).toFixed(2);
};

export const getFeeWithoutVat = (
  value: number | string | null,
  vat: number | string = DEFAULT_VAT,
) => {
  if (value === null) {
    return null;
  }
  let parsedValue = parseFloat(value.toString());
  let parsedVat = parseFloat(vat.toString());

  if (isNaN(parsedValue) || isNaN(parsedVat)) {
    console.error(
      `Invalid input: Value (${value}) or VAT ((${vat})) could not be parsed to valid numbers.`,
    );
    return null;
  }

  const valueWithoutVat = (parsedValue / (1 + parsedVat / 100)).toFixed(2);

  return valueWithoutVat;
};
