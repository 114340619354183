import { PartnerResponse } from '@/types/partnerCompany';

export const showKickbackStats = (
  partner?: Partial<PartnerResponse> | null,
) => {
  if (!partner) return false;

  const isKickbackEnabled = partner?.kickback;

  return isKickbackEnabled;
};
