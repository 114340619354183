import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  Alert,
  AlertTitle,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  LinearProgress,
  Stack,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';

import { SimpleTable, SimpleTableColumn } from '@/components/SimpleTable';
import { getFormattedDate } from '@/helpers/dates';
import {
  ArvatoInvoiceMigrationProcess,
  ArvatoInvoiceMigrationStatus,
} from '@/types/costs';

import { useArvatoInvoice } from '../../hooks/useArvatoInvoice';
import { useInsertArvatoInvoices } from '../../hooks/useInsertArvatoInvoices';

const statusTranslation: Record<ArvatoInvoiceMigrationStatus, string> = {
  Finished: 'Klar',
  Processing: 'Bearbetas',
};

export const ArvatoBillingStatus = () => {
  const { data, isLoading, isError } = useArvatoInvoice();
  const insertArvatoInvoicesMutation = useInsertArvatoInvoices();

  const columns: SimpleTableColumn<ArvatoInvoiceMigrationProcess>[] = [
    {
      label: 'ID',
      renderer: (row) => row.id,
    },
    {
      label: 'Status',
      renderer: (row) => (
        <Chip
          color={row.status === 'Finished' ? 'success' : 'warning'}
          variant="outlined"
          label={statusTranslation[row.status]}
        />
      ),
    },
    {
      label: 'Skapad',
      renderer: (row) => getFormattedDate(row.createdAt, 'yyyy-MM-dd HH:mm'),
    },
    {
      label: 'Uppdaterad',
      renderer: (row) => getFormattedDate(row.updatedAt, 'yyyy-MM-dd HH:mm'),
    },
    {
      label: 'Felaktiga användar-ID:n',
      renderer: (row) => {
        const failedCustomerIds = row.errors?.failedCustomerIds || [];

        if (failedCustomerIds.length === 0) {
          return (
            <Typography
              color={(theme) => theme.palette.success.main}
              fontWeight={500}
            >
              Inga fel
            </Typography>
          );
        }

        if (failedCustomerIds.length > 0 && failedCustomerIds.length <= 5) {
          return row.errors.failedCustomerIds.map((customerId, index) => (
            <Chip
              key={customerId}
              label={customerId}
              sx={{ marginLeft: index === 0 ? 0 : 1 }}
            />
          ));
        }

        if (failedCustomerIds.length > 5) {
          return <Chip label="Mer än 5 fel" />;
        }
      },
    },
    {
      label: (
        <Stack
          direction="row"
          spacing={1}
          justifyContent="center"
          alignItems="center"
        >
          <TableCellTypography>Åtgärd</TableCellTypography>
          <Tooltip title="Enbart kunder där faktureringen inte lyckades kommer att köras igen">
            <HelpOutlineIcon />
          </Tooltip>
        </Stack>
      ),
      align: 'right',
      renderer: (row) => {
        // @ts-ignore
        const emptyOldStructureErrorArray = row.errors?.length === 0;
        const maxRetryCount = row.retry <= 2;
        const disabled =
          insertArvatoInvoicesMutation.isPending ||
          row.errors.failedCustomerIds?.length === 0 ||
          row.status === 'Processing' ||
          emptyOldStructureErrorArray ||
          maxRetryCount;

        return (
          <Button
            variant="contained"
            color="primary"
            size="small"
            disabled={disabled}
            onClick={() => {
              insertArvatoInvoicesMutation.mutate({
                customerIds: row.errors.failedCustomerIds,
              });
            }}
          >
            {insertArvatoInvoicesMutation.isPending
              ? 'Bearbetas...'
              : `Fakturera misslyckade kund-ID'n`}
          </Button>
        );
      },
    },
  ];

  return (
    <Card style={{ marginBottom: '1rem' }}>
      <CardHeader title="Status på senaste körning" />
      <Divider />
      <CardContent>
        {isLoading ? (
          <LinearProgress />
        ) : isError ? (
          <Alert severity="error">
            <AlertTitle>Någit gick fel</AlertTitle>
          </Alert>
        ) : (
          <>
            {data && data.length > 0 ? (
              <SimpleTable rows={data || []} columns={columns} />
            ) : (
              <Typography variant="h4" align="center">
                Ingen information tillgänglig
              </Typography>
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
};

const TableCellTypography = styled(Typography)(() => ({
  fontSize: '12px',
  letterSpacing: '-0.04px',
  lineheight: '1.5rem',
  fontWeight: 500,
}));
