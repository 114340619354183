import React from 'react';

import { Grid, LinearProgress, Skeleton } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import isEqual from 'lodash/isEqual';
import { useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import invariant from 'tiny-invariant';

import { getCampaigns } from '@/api/campaigns';
import { getPrices } from '@/api/prices';
import { getResellers } from '@/api/reseller';
import { BasicAlert } from '@/components/BasicAlert';
import { SimpleCard } from '@/components/SimpleCard';
import { DatePicker, Dropdown, PhoneInput, TextInput } from '@/components/form';
import { CustomerCategoryTypes } from '@/constants';
import { queryKeys } from '@/constants/queryKeys';
import { useGetCampaignData } from '@/hooks';
import { USER_TYPES } from '@/modules/common/constants/entitiyNamesMap';
import {
  prepareCampaignOptions,
  prepareFeesOptions,
  preparePricesOptions,
  prepareResellerOptions,
} from '@/modules/services';
import { CreateHomegateFormValues } from '@/modules/services/components/Create/Homegate/CreateHomegateForm';
import { HomegateTemplate } from '@/types/campaigns';
import {
  composeValidators,
  fieldRequired,
  isValidOption,
} from '@/utils/validation';

type Props = {
  values: Partial<CreateHomegateFormValues>;
  push: (name: string, value: HomegateTemplate) => void;
  existingCustomerCategory: number | undefined;
};

export const SalesInformationFields = ({
  values,
  push,
  existingCustomerCategory,
}: Props) => {
  const customerType = existingCustomerCategory ?? values?.category;
  const service = 'homegate';

  const { batch, change } = useForm();

  const {
    data: campaignOptions,
    isPending: loadingCampaignOptions,
    isError: errorCampaignOptions,
  } = useQuery({
    queryKey: [queryKeys.getCampaigns, customerType, service],
    queryFn: async () => {
      const data = await getCampaigns(`${service}_campaigns`, {
        active: true,
        customerType,
        properties: {
          name: true,
          id: true,
          active: true,
        },
      });

      return prepareCampaignOptions(data);
    },

    initialData: [],
    enabled: !!customerType,
  });

  const { data: resellers, isPending: isLoadingResellers } = useQuery({
    queryKey: [queryKeys.getResellers],
    queryFn: () => getResellers(),
    initialData: { data: [], totalCount: 0 },
  });
  const { data: prices } = useQuery({
    queryKey: [queryKeys.getPrices],
    queryFn: getPrices,
    initialData: [],
  });

  const validCampaignIri = React.useMemo(() => {
    return campaignOptions.find(
      (campaign) => campaign.value === values?.campaign,
    )?.value;
  }, [campaignOptions, values?.campaign]);

  const { templateData, isLoadingCampaign, campaignData } =
    useGetCampaignData(validCampaignIri);

  React.useEffect(() => {
    batch(() => {
      change('freeMonths', campaignData?.freeMonths);
      change('noticePeriod', campaignData?.noticePeriod);
      change('contractMonths', campaignData?.contractMonths);
    });
  }, [isLoadingCampaign, campaignData]);

  React.useEffect(() => {
    if (!isEqual(values?.templates, templateData)) {
      change('templates', []);

      templateData.forEach((template) => {
        if (template !== undefined) {
          try {
            invariant(
              template.hardwareSurcharge,
              'Hardware surcharge is required',
            );
            push('templates', template);
          } catch (e) {
            console.error(e);
          }
        }
      });
    }
  }, [templateData, push]);

  return (
    <>
      <section style={{ marginBottom: '1.5rem' }}>
        <SimpleCard
          title="Sälj"
          cardContent={
            <>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label="Datum"
                    name="regDate"
                    required
                    fieldProps={{ validate: fieldRequired }}
                  />
                  <TextInput
                    label="Säljare"
                    name="salesRep"
                    required
                    fieldProps={{ validate: fieldRequired }}
                  />

                  <Dropdown
                    label="Återförsäljare"
                    name="reseller"
                    options={prepareResellerOptions(resellers.data)}
                    required
                    fieldProps={{
                      validate: composeValidators(
                        fieldRequired,
                        isValidOption(
                          prepareResellerOptions(
                            resellers.data,
                            isLoadingResellers,
                          ),
                        ),
                      ),
                    }}
                  />
                  <TextInput label="Adresslista" name="addressList" />

                  <TextInput
                    label="Täckningsbidrag"
                    name="tb"
                    fieldProps={{
                      format: (v) => (v ? parseInt(v, 10) : 0),
                      parse: (v) => parseInt(v, 10),
                    }}
                  />

                  <TextInput label="Leadkälla" name="leadSource" />
                  <PhoneInput
                    label="Key Account Manager"
                    name="kamId"
                    required={false}
                  />

                  <TextInput label="AB Test" name="abTest" />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <>
                    {loadingCampaignOptions ? (
                      <>
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                      </>
                    ) : errorCampaignOptions ? (
                      <BasicAlert />
                    ) : (
                      <>
                        <Dropdown
                          label="Kampanj"
                          required
                          fieldProps={{
                            validate: composeValidators(
                              fieldRequired,
                              isValidOption(
                                campaignOptions,
                                loadingCampaignOptions,
                              ),
                            ),
                          }}
                          name="campaign"
                          noOptionText="Ingen kampanj finns för vald kundtyp"
                          options={campaignOptions}
                        />
                        <TextInput
                          disabled
                          label="Fria månader"
                          name="freeMonths"
                        />
                        <TextInput
                          disabled
                          label="Uppsägningstid"
                          name="noticePeriod"
                        />
                        <TextInput
                          disabled
                          label="Bindningstid"
                          name="contractMonths"
                        />
                      </>
                    )}
                    <TextInput
                      label={`Info till ${USER_TYPES.admin}`}
                      name="adminNote"
                    />
                    <DatePicker label="Churndatum" name="churnDate" />
                  </>
                </Grid>
              </Grid>
            </>
          }
        />
      </section>
      <section>
        {isLoadingCampaign && <LinearProgress />}
        <Grid container spacing={2}>
          <FieldArray name="templates">
            {({ fields }) =>
              fields.map((name, i) => (
                <Grid
                  key={name}
                  item
                  xs={12}
                  sm={6}
                  data-testid={`template-${i}`}
                >
                  <SimpleCard
                    title={`Enhet ${++i}`}
                    cardContent={
                      <>
                        <Dropdown
                          disabled
                          label="Pris"
                          name={`${name}.price`}
                          options={preparePricesOptions(
                            prices,
                            values.category ?? CustomerCategoryTypes.PRIVATE,
                          )}
                        />
                        <Dropdown
                          disabled
                          label="Startavgift"
                          name={`${name}.startFee`}
                          options={prepareFeesOptions(
                            prices,
                            values.category ?? CustomerCategoryTypes.PRIVATE,
                          )}
                        />

                        <Dropdown
                          disabled
                          label="Installationsavgift"
                          name={`${name}.installationFee`}
                          options={prepareFeesOptions(
                            prices,
                            values.category ?? CustomerCategoryTypes.PRIVATE,
                          )}
                        />

                        <Dropdown
                          disabled
                          label="Hårdvarutillägg"
                          name={`${name}.hardwareSurcharge`}
                          options={prepareFeesOptions(
                            prices,
                            values.category ?? CustomerCategoryTypes.PRIVATE,
                          )}
                        />
                      </>
                    }
                  />
                </Grid>
              ))
            }
          </FieldArray>
        </Grid>
      </section>
    </>
  );
};
