import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';
import { Button, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useHotkeys } from 'react-hotkeys-hook';

import { Can } from '@/components/Can';
import { CustomerCategoryTypes } from '@/constants';
import { getCamelCaseServiceName } from '@/helpers/tsHelpers';
import { usePrivateSettings } from '@/hooks';
import useUserRoles from '@/hooks/useUserRoles';
import {
  CoProtectionParamSchema,
  ProtectionParamSchema,
} from '@/types/services';

type Props = {
  service: ProtectionParamSchema | CoProtectionParamSchema;
  handleFraudBlockModal: () => void;
  handleTaskModal: () => void;
};

export const BarringServiceToolbar = ({
  service,
  handleFraudBlockModal,
  handleTaskModal,
}: Props) => {
  const { servicesNames } = usePrivateSettings();
  const userRoles = useUserRoles();

  const customer = service.customer;

  const displayName = servicesNames[getCamelCaseServiceName(service['@type'])];

  useHotkeys('alt+t', handleTaskModal);

  return (
    <Toolbar container>
      <Grid item sm={6}>
        <Typography variant="h3">
          {displayName} -{' '}
          {customer?.category === CustomerCategoryTypes.COMPANY
            ? customer?.companyName
            : customer?.fullName}
        </Typography>
      </Grid>
      <ButtonContainer container item xs={12} justifyContent="flex-end">
        <Can
          userRoles={userRoles}
          action="securityService.barringServiceTask"
          yes={() => (
            <Button
              variant="contained"
              color="primary"
              onClick={handleTaskModal}
              startIcon={<PlaylistAddCheckOutlinedIcon />}
            >
              Skapa ärende till kundservice
            </Button>
          )}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleFraudBlockModal}
        >
          Bedrägerispärr
        </Button>
      </ButtonContainer>
    </Toolbar>
  );
};

const Toolbar = styled(Grid)`
  margin-top: ${({ theme }) => theme.spacing(1)};
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

const ButtonContainer = styled(Grid)`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
`;
