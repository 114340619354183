import React from 'react';

import isPropValid from '@emotion/is-prop-valid';
import { Box, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

import { config } from '@/helpers/config';
import usePublicSettings from '@/hooks/usePublicSettings';

export const AuthLayout = ({ children }: { children: React.ReactNode }) => {
  const whitelabelId = config.whitelabelId;
  const { publicSettings } = usePublicSettings();
  const companyName = publicSettings?.companyNames?.standard;

  return (
    <Box sx={{ height: '100vh', display: 'flex', justifyContent: 'center' }}>
      <FormWrapper>
        <FormGridContainer
          container
          height="100%"
          alignContent="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <ImageLink href={config.siteUri}>
              <WhiteLabelImage
                alt={companyName + 'logo'}
                src={`/images/${whitelabelId}/logo_color.png`}
              />
            </ImageLink>
          </Grid>
          <Grid item xs={12}>
            {children}
          </Grid>
        </FormGridContainer>
      </FormWrapper>
    </Box>
  );
};

const WhiteLabelImage = styled('img')(() => ({
  width: '100%',
}));

const ImageLink = styled('a', {
  shouldForwardProp: (prop: string) => isPropValid(prop),
})(() => ({
  height: 0,
  width: '100%',
}));

const FormWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  height: '100vh',
  width: '50vw',
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(4),
    width: '100vw',
  },
}));

const FormGridContainer = styled(Grid)(({ theme }) => ({
  maxWidth: '300px',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
  },
}));
