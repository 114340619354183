import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  BoxProps,
  Breakpoint,
  CardHeader,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
} from '@mui/material';

import { DialogActions } from '@/components/DialogActions';

type Props = BoxProps & {
  title: string;
  subheader?: React.ReactNode;
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  buttons?: React.ReactNode;
  height?: string | number;
  width?: Breakpoint;
};

export const BaseModal = ({
  title,
  subheader,
  open,
  onClose,
  children,
  buttons,
  height = '100%',
  width = undefined,
  component = 'div',
  ...rest
}: Props) => {
  return (
    <Dialog maxWidth={width ?? 'sm'} fullWidth open={open}>
      <CardHeader
        sx={{
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          padding: '1rem 1.5rem',
        }}
        title={title}
        subheader={subheader}
        data-testid="basemodal-header-div"
      />
      <IconButton
        sx={(theme) => ({
          position: 'absolute',
          right: theme.spacing(2),
          top: theme.spacing(1),
          padding: theme.spacing(1),
          color: theme.palette.text.primary,
        })}
        onClick={onClose}
        size="large"
      >
        <CloseIcon />
      </IconButton>
      <Box overflow="auto" component={component} {...rest}>
        <Divider flexItem />
        <DialogContent sx={{ minHeight: 'inherit', height }}>
          {children}
        </DialogContent>
        {buttons && (
          <>
            <Divider />
            <DialogActions>{buttons}</DialogActions>
          </>
        )}
      </Box>
    </Dialog>
  );
};
