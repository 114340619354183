import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  styled,
} from '@mui/material';

import { getObjectKeys } from '@/helpers/tsHelpers';
import {
  ResellerPortalStatsDistribution,
  TResellerPortalStats,
} from '@/types/stats';

import { prepareDetailedInfoTablesData } from '../utils/prepareDetailedInfoTablesData';
import { ChartCard } from './ChartCard';
import { DetailedInfoTable } from './DetailedInfoTable';
import { TotalCard } from './TotalCard';

type Props = {
  data: TResellerPortalStats;
};

export const ResellerView = ({ data }: Props) => {
  const salesRepTableData = data?.salesRep
    ? prepareDetailedInfoTablesData({
        rawData: data.salesRep,
        firstHeaderName: 'Säljare',
      })
    : null;

  const pricePlanTableData = data?.pricePlanId
    ? prepareDetailedInfoTablesData({
        rawData: data.pricePlanId,
        firstHeaderName: 'Prisplan',
      })
    : null;

  const sortedResellerSummary = getObjectKeys(data?.reseller_summary)
    .sort((a, b) => (a === 'Brutto' ? -1 : 1))
    .reduce((acc, cVal) => {
      acc[cVal] = data?.reseller_summary[cVal];
      return acc;
    }, {} as Record<keyof ResellerPortalStatsDistribution, number>);

  if (!data) {
    return (
      <Card>
        <CardHeader title="Ingen data" />
      </Card>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ChartCard periodStats={data?.basic?.period} />
      </Grid>
      <Grid item xs={12}>
        <TotalCard summaryStats={sortedResellerSummary} />
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardHeader title="Detaljerad information" />
          <Divider />
          <StyledCardContent>
            <DetailedInfoTable data={salesRepTableData} />
            <DetailedInfoTable data={pricePlanTableData} />
          </StyledCardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
  '& > *': {
    marginBottom: theme.spacing(2),
  },
}));
