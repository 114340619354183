import { useContext } from 'react';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Button, CircularProgress, Typography } from '@mui/material';
import { useSelector } from '@xstate/react';

import { SinneReturnStateContext } from '../context';
import { StepRow } from './StepRow';

export const UpdateNoclip = () => {
  const sinneReturnFlowService = useContext(SinneReturnStateContext);
  const state = useSelector(sinneReturnFlowService, (state) => state);

  if (state.matches('UPDATE_NOCLIP.loading')) {
    return (
      <StepRow>
        <CircularProgress size={14} />
        <Typography component="span" variant="h6">
          Uppdaterar enheten...
        </Typography>
      </StepRow>
    );
  }

  if (state.context.isNoClipUpdated) {
    return (
      <StepRow>
        <CheckCircleOutlineIcon color="success" sx={{ fontSize: 20 }} />
        <Typography component="span" variant="h6">
          Enheten har kopplats från tjänsten och dess status har uppdaterats
          till <b>i lager</b>
        </Typography>
      </StepRow>
    );
  }

  if (state.matches('UPDATE_NOCLIP.error')) {
    return (
      <StepRow>
        <ErrorOutlineIcon color="error" sx={{ fontSize: 20 }} />
        <Typography component="span" variant="h6" padding={0} margin={0}>
          Något gick fel när enhetens status skulle uppdateras
        </Typography>

        <Button>Försök igen</Button>
      </StepRow>
    );
  }
  return null;
};
