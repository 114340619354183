import * as Sentry from '@sentry/react';
import { z } from 'zod';
import zodToJsonSchema from 'zod-to-json-schema';

import { createApiPropertiesFromJsonSchema } from '@/helpers/createApiPropertiesFromJsonSchema';
import { getAPIServiceName } from '@/modules/services';
import {
  CamelCaseServiceName,
  ServiceDetailsResponse,
  ServiceName,
  ServiceParams,
  SinneDeviceInfo,
  SnakeCaseServiceName,
  serviceDetailsParams,
} from '@/types/services';
import { KebabCaseServiceNames } from '@/types/settings';
import httpClient from '@/utils/httpClient';

export async function getServiceDetails({
  serviceName,
  serviceId,
}: {
  serviceName: CamelCaseServiceName | KebabCaseServiceNames | ServiceName;
  serviceId: number;
}) {
  // TODO: Improve typing to be automatic
  const mappedServiceName = getAPIServiceName(
    serviceName,
  ) as keyof typeof serviceDetailsParams;

  const paramSchema = serviceDetailsParams[mappedServiceName];
  const properties = createApiPropertiesFromJsonSchema(
    zodToJsonSchema(paramSchema),
  );

  const { data } = await httpClient.get<ServiceDetailsResponse>(
    `/${mappedServiceName}/${serviceId}`,
    {
      params: {
        properties,
      },
    },
  );

  const parsedData = paramSchema.passthrough().safeParse(data);

  if (!parsedData.success) {
    console.error(parsedData.error);
    Sentry.captureException(parsedData.error);
    return data as z.infer<typeof paramSchema>;
  } else {
    return parsedData.data;
  }
}

export async function getSinneDeviceDetails({
  serviceId,
}: {
  serviceId: number | string;
}) {
  const { data } = await httpClient.get<SinneDeviceInfo>(
    `/sinnes/${serviceId}/device_info`,
  );
  return data;
}

export async function addService({
  serviceName,
  payload,
}: {
  serviceName: CamelCaseServiceName;
  payload: any;
}) {
  const mappedServiceName = getAPIServiceName(serviceName);
  const { data } = await httpClient.post<ServiceParams>(
    `/${mappedServiceName}`,
    payload,
  );
  return data;
}

export type TransferServiceParams = {
  serviceName: CamelCaseServiceName;
  serviceId: number;
  newCustomer: string;
};

export async function transferService({
  serviceName,
  serviceId,
  newCustomer,
}: TransferServiceParams) {
  const mappedServiceName = getAPIServiceName(serviceName);
  const { data } = await httpClient.put(`${mappedServiceName}/${serviceId}`, {
    customer: newCustomer,
  });

  return data;
}

type GetServicesByCodeParams = {
  serviceName: SnakeCaseServiceName;
  code: string;
};

export async function getServiceDetailsByCode({
  serviceName,
  code,
}: GetServicesByCodeParams) {
  const { data } = await httpClient.get(`/${serviceName}s`, {
    params: {
      code,
    },
  });

  return data;
}

export type Resolution = 'daily' | 'weekly' | 'monthly' | 'quarter' | 'yearly';

export type GetServiceStatsParams = {
  serviceName: CamelCaseServiceName;
  start: string;
  end: string;
  resolution: Resolution;
  bdm?: number;
  reseller?: number;
};

export async function getServiceStats({
  serviceName,
  ...params
}: GetServiceStatsParams) {
  const apiServiceName = getAPIServiceName(serviceName);

  const { data } = await httpClient.get(`${apiServiceName}/statistics`, {
    params,
  });

  return data;
}

export type ExportStatsParams = GetServiceStatsParams & {
  filename: string;
  format?: 'xlsx';
};

export async function exportServiceStats({
  serviceName,
  filename,
  ...params
}: ExportStatsParams) {
  const apiServiceName = getAPIServiceName(serviceName);

  return httpClient({
    url: `${apiServiceName}/statistics`,
    params,
    method: 'GET',
    responseType: 'blob',
  }).then((response) => {
    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: response.headers['content-type'] }),
    );

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${filename}`);
    document.body.appendChild(link);
    link.click();
    return url;
  });
}

// TODO: Improve typing
export type UpdateServiceParams = {
  serviceName: CamelCaseServiceName;
  serviceId: number;
  values: any;
};

export async function updateService<ReturnedService = any>({
  serviceName,
  serviceId,
  values,
}: UpdateServiceParams): Promise<ReturnedService> {
  const apiServiceName = getAPIServiceName(serviceName);

  const { data } = await httpClient.put(
    `${apiServiceName}/${serviceId}`,
    values,
    {
      headers: {
        'Content-Type': 'application/ld+json',
      },
    },
  );

  return data as ReturnedService;
}

export async function markServiceAsSavedOrLost({
  serviceName,
  id,
  type,
}: {
  serviceName: CamelCaseServiceName;
  id: string | number;
  type: 'saved' | 'lost';
}) {
  const apiServiceName = getAPIServiceName(serviceName);

  const { data } = await httpClient.post(
    `/${apiServiceName}/${id}/${type}`,
    {},
  );

  return data;
}

export async function deleteService({
  serviceName,
  serviceId,
}: {
  serviceName: CamelCaseServiceName | KebabCaseServiceNames | ServiceName;
  serviceId: number;
}) {
  const mappedServiceName = getAPIServiceName(
    serviceName,
  ) as keyof typeof serviceDetailsParams;

  return await httpClient.delete(`/${mappedServiceName}/${serviceId}`);
}
