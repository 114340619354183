import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  LinearProgress,
} from '@mui/material';

import { BasicAlert } from '@/components/BasicAlert';
import { Pagination } from '@/components/Pagination';
import { SimpleTable } from '@/components/SimpleTable';

import { useFetchAccountManagers } from '../../hooks/useFetchAccountManagers';

type Row = {
  name: string;
  coProtectionOnboardingPercentage: string;
};

const columns = [
  { label: 'Namn', renderer: ({ name }: Row) => name },

  {
    label: 'Bolagsbevakning',
    renderer: ({ coProtectionOnboardingPercentage }: Row) =>
      coProtectionOnboardingPercentage,
  },
];

export const HeadOfB2BOnboardingStatistics = () => {
  const { data, status, page, perPage, setPage, setPerPage } =
    useFetchAccountManagers();

  const currentView = {
    loading: <LinearProgress />,
    error: <BasicAlert />,
    success: (
      <Card>
        <CardHeader title="Onboarding" />
        <Divider />
        <CardContent>
          <SimpleTable rows={data} columns={columns} />
        </CardContent>
        <Pagination
          count={data.length}
          rowsPerPage={perPage}
          page={page}
          onPageChange={(_, page) => setPage(page)}
          onRowsPerPageChange={(event) =>
            setPerPage(parseInt(event.target.value))
          }
        />
      </Card>
    ),
  };

  return currentView[status];
};
