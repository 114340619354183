import React, { useRef } from 'react';

import { Alert, AlertTitle } from '@mui/material';
import { Button, CardActions, CardContent, Divider } from '@mui/material';
import { useSelector } from '@xstate/react';
import { Form } from 'react-final-form';

import { PhoneInput, TextInput } from '@/components/form';
import { SinneConfigContext } from '@/modules/sinne/components/sinneConfigFlow/context/SinneConfigContextProvider';
import {
  composeValidators,
  fieldIs64BitMacAddress,
  fieldRequired,
} from '@/utils/validation';

type FormValues = {
  imei: string;
  macAddress: string;
  placement: string;
};

export const DeviceInfoForm = () => {
  const machine = React.useContext(SinneConfigContext);
  const state = useSelector(machine, (state) => state);

  const adminNote = state.context.sinneServiceData?.adminNote;
  const defaultPlacement =
    state.context.sinneServiceData?.customer.address || '';

  const macRef = useRef<HTMLInputElement>(null);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && macRef.current) {
      event.preventDefault();
      macRef.current.focus();
    }
  };

  return (
    <Form<FormValues>
      onSubmit={(values) => {
        machine.send({ type: 'SUBMIT_DEVICE_INFO', ...values });
      }}
      initialValues={{
        placement: defaultPlacement,
      }}
      render={({ handleSubmit, valid }) => (
        <>
          <CardContent>
            <form onSubmit={handleSubmit}>
              <PhoneInput
                autoFocus
                name="imei"
                label="IMEI"
                onKeyDown={handleKeyDown}
              />
              <TextInput
                inputRef={macRef}
                name="macAddress" // deviceId
                label="MAC"
                required
                fieldProps={{
                  validate: composeValidators(
                    fieldRequired,
                    fieldIs64BitMacAddress,
                  ),
                  parse: (v) => v.replaceAll(':', '').trim(),
                }}
              />
              <TextInput name="placement" label="Placering" />
            </form>
            {adminNote && (
              <Alert sx={{ marginTop: '1rem' }} severity="info">
                <AlertTitle>{adminNote}</AlertTitle>
              </Alert>
            )}
          </CardContent>
          <Divider />
          <CardActions>
            <Button disabled={!valid} onClick={handleSubmit}>
              Nästa
            </Button>
          </CardActions>
        </>
      )}
    />
  );
};
