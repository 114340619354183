import { Grid, LinearProgress } from '@mui/material';

import { Alert } from '@/components/Alert';
import { INVOICE_PROVIDERS } from '@/constants/InvoiceProviders';
import { usePrivateSettings } from '@/hooks';

import { useGetInvoiceListSummary } from '../../hooks/useGetInvoiceListSummary';
import { ArvatoBillingStatus } from '../ArvatoBillingStatus';
import { BillogramInfo } from './BillogramInfo';
import { UpcomingBilling } from './UpcomingBilling';

export const Billing = () => {
  const { invoiceProvider } = usePrivateSettings();

  const shouldRenderBillogramInfo =
    invoiceProvider.name === INVOICE_PROVIDERS.billogram; // TODO: Check layout for this provider, <Info />
  const shouldRenderArvatoBillingStatus =
    invoiceProvider.name.toLowerCase() ===
    INVOICE_PROVIDERS.arvato.toLowerCase();

  const { data, isLoading, isError } = useGetInvoiceListSummary();
  return (
    <Grid container direction="column" gap={2}>
      {shouldRenderBillogramInfo && (
        <Grid item>
          <BillogramInfo />
        </Grid>
      )}

      <Grid item>
        {isLoading ? (
          <LinearProgress />
        ) : isError ? (
          <Alert severity="error">Något gick fel</Alert>
        ) : (
          <UpcomingBilling
            invoiceListSummary={data!}
            arvatoIntegrationEnabled={shouldRenderArvatoBillingStatus}
          />
        )}
      </Grid>
      {shouldRenderArvatoBillingStatus && (
        <Grid item>
          <ArvatoBillingStatus />
        </Grid>
      )}
    </Grid>
  );
};
