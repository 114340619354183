import { Helmet } from 'react-helmet';

import { Breadcrumbs } from '@/components/Breadcrumbs';
import { PageHeader } from '@/components/PageHeader';
import { Tabs } from '@/components/Tabs/Tabs';
import { getObjectKeys } from '@/helpers/tsHelpers';
import { usePrivateSettings } from '@/hooks';
import { AntiChurnTable } from '@/modules/accountManagerSystem/components';
import { CamelCaseServiceName } from '@/types/services';

const AntiChurn = () => {
  const { services: serviceList, servicesNames } = usePrivateSettings();

  const activeServices = getObjectKeys(serviceList).filter(
    (service) => serviceList[service],
  ) as CamelCaseServiceName[];

  const tabMap = activeServices.map((service) => ({
    title: servicesNames[service],
    view: () => <AntiChurnTable />,
  }));

  return (
    <>
      <Helmet title="Anti-churn" />
      <Breadcrumbs
        crumbs={[{ label: 'Account Manager System' }, { label: 'Anti-churn' }]}
      />
      <PageHeader margin title="Anti-churn" />
      <Tabs tabs={tabMap} />
    </>
  );
};

export default AntiChurn;
