import { SvgIcon, SvgIconProps } from '@mui/material';

export const CrmIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 172 172" width="48px" height="48px">
      <g>
        <path d="M0,172v-172h172v172z" fill="none"></path>
        <g>
          <path d="M136.14567,14.33333c-9.84139,0 -17.93067,8.09627 -17.93067,17.93766v107.479c0,9.84139 8.08928,17.93766 17.93067,17.93766c9.84139,0 17.93766,-8.09627 17.93766,-17.93766v-107.479c0,-9.84139 -8.09627,-17.93766 -17.93766,-17.93766zM136.14567,25.08333c4.03328,0 7.18766,3.15438 7.18766,7.18766v107.479c0,4.03328 -3.15438,7.18766 -7.18766,7.18766c-4.03328,0 -7.18067,-3.15438 -7.18067,-7.18766v-107.479c0,-4.03328 3.14739,-7.18766 7.18067,-7.18766zM35.81234,46.60433c-9.84139,0 -17.93066,8.08928 -17.93066,17.93067v75.21501c0,9.84139 8.08928,17.93766 17.93066,17.93766c9.84139,0 17.93766,-8.09627 17.93766,-17.93766v-75.21501c0,-9.84139 -8.09627,-17.93067 -17.93766,-17.93067zM35.81234,57.35433c4.03328,0 7.18766,3.14739 7.18766,7.18067v75.21501c0,4.03328 -3.15438,7.18766 -7.18766,7.18766c-4.03328,0 -7.18066,-3.15438 -7.18066,-7.18766v-75.21501c0,-4.03328 3.14739,-7.18067 7.18066,-7.18067zM86,78.83333c-9.84139,0 -17.93766,8.09627 -17.93766,17.93766v42.97901c0,9.84139 8.09627,17.93766 17.93766,17.93766c9.84139,0 17.93766,-8.09627 17.93766,-17.93766v-42.97901c0,-9.84139 -8.09627,-17.93766 -17.93766,-17.93766zM86,89.58333c4.03328,0 7.18766,3.15438 7.18766,7.18766v42.97901c0,4.03328 -3.15438,7.18766 -7.18766,7.18766c-4.03328,0 -7.18766,-3.15438 -7.18766,-7.18766v-42.97901c0,-4.03328 3.15438,-7.18766 7.18766,-7.18766z"></path>
        </g>
      </g>
    </SvgIcon>
  );
};
