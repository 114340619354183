import React from 'react';

import {
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
} from '@mui/icons-material';
import { CardActions, IconButton, TablePagination } from '@mui/material';
import Box from '@mui/material/Box';

const NR_PAGE_OPTIONS = 5; // should be odd since we want the same nr of options before and after current page nr

function getPageNumberOptions(page: number, lastPage: number) {
  const prevList: number[] = [];
  const nextList: number[] = [];

  const addPrevious = () => {
    const prevPage = (prevList.at(-1) ?? page) - 1;

    if (prevPage >= 0) {
      prevList.push(prevPage);
    }
  };

  const addNext = () => {
    const nextPage = (nextList.at(-1) ?? page) + 1;

    if (nextPage <= lastPage) {
      nextList.push(nextPage);
    }
  };

  for (let i = 1; i < (NR_PAGE_OPTIONS + 1) / 2; i++) {
    addPrevious();
    addNext();
  }

  // negative => we can try to add more to nextList
  // positive => we can try to add more to prevList
  const unusedSpots = prevList.length - nextList.length;

  for (let i = 0; i < unusedSpots; i++) {
    addPrevious();
  }

  for (let i = 0; i > unusedSpots; i--) {
    addNext();
  }

  return [...prevList.reverse(), page, ...nextList];
}

function TablePaginationActions(
  props: Pick<Props, 'count' | 'page' | 'rowsPerPage' | 'onPageChange'>,
) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const lastPage = Math.max(0, Math.ceil(count / rowsPerPage) - 1);

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    onPageChange(event, 0);
  };

  const handlePageNumberButtonClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    page: number,
  ) => {
    onPageChange(event, page);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    onPageChange(event, lastPage);
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="första sida"
        size="large"
      >
        <FirstPage />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="föregående sida"
        size="large"
      >
        <KeyboardArrowLeft />
      </IconButton>
      {getPageNumberOptions(page, lastPage).map((p) => {
        return (
          <IconButton
            key={p}
            disabled={page === p}
            onClick={(e) => handlePageNumberButtonClick(e, p)}
            size={'small'}
          >
            {p + 1}
          </IconButton>
        );
      })}
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="nästa sida"
        size="large"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="sista sida"
        size="large"
      >
        <LastPage />
      </IconButton>
    </Box>
  );
}

type Props = {
  count: number;
  page: number;
  rowsPerPage: number;
  rowsPerPageOptions?: Array<number | { label: string; value: number }>;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number,
  ) => void;
  onRowsPerPageChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
};

export const Pagination = ({
  count,
  onPageChange,
  onRowsPerPageChange,
  page,
  rowsPerPage,
  rowsPerPageOptions,
}: Props) => {
  return (
    <CardActions
      sx={{
        justifyContent: 'flex-end',
      }}
    >
      <TablePagination
        component="div"
        count={count}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        labelRowsPerPage="Rader per sida"
        rowsPerPageOptions={
          rowsPerPageOptions ? rowsPerPageOptions : [100, 250, 500]
        }
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} av ${count}`
        }
        ActionsComponent={TablePaginationActions}
      />
    </CardActions>
  );
};
