import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  LinearProgress,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { Form } from 'react-final-form';
import invariant from 'tiny-invariant';

import { sendBulkCosts } from '@/api/costs';
import { InfoMessage } from '@/components/InfoMessage';
import { Spacing } from '@/components/Spacing';
import { Dropdown, TextArea, TextInput } from '@/components/form';
import { usePrivateSettings, useSnackbar } from '@/hooks';
import { CostTypes } from '@/types/settings';
import Yup, { makeValidation } from '@/utils/validation';

const validationSchema = Yup.object().shape({
  type: Yup.string().required(),
  customerIds: Yup.string().required(),
  quantity: Yup.number().positive().required(),
});

const validate = makeValidation(validationSchema);

export function getCostTypesOptions(costTypes: CostTypes) {
  return Object.keys(costTypes).map((key) => ({
    value: key,
    text: costTypes[key],
  }));
}

type Values = {
  customerIds?: string;
  type?: string;
  quantity?: string;
  comment?: string;
};

export const Costs = () => {
  const snackbar = useSnackbar();

  const sendBulkCostsMutation = useMutation({ mutationFn: sendBulkCosts });
  const { privateSettings } = usePrivateSettings();

  const costTypes = privateSettings?.types.cost ?? ({} as CostTypes);
  const costTypesOptions = getCostTypesOptions(costTypes);

  async function onSubmit(values: Values) {
    const { type, quantity, comment } = values;
    invariant(type, 'Type is required');
    invariant(quantity, 'Quantity is required');
    const customerIds = values.customerIds
      ?.split('\n')
      .map((id: string) => id.trim());

    return sendBulkCostsMutation.mutateAsync(
      {
        type: parseInt(type, 10),
        quantity: parseInt(quantity, 10),
        comment: comment ?? '',
        customerIds: customerIds ?? [],
      },
      {
        onError: () => {
          snackbar({
            type: 'error',
            message: 'Det gick inte att lägga till kostnader',
          });
        },

        onSuccess: () => {
          snackbar({
            type: 'success',
            message: 'Kostnader skapade',
          });
        },
      },
    );
  }

  return (
    <Form
      onSubmit={async (values, form) => {
        await onSubmit(values);
        form.restart();
      }}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <Card>
            <CardHeader title="Kostnader - Bulk" />
            <Divider />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Dropdown
                    name="type"
                    label="Typ"
                    options={costTypesOptions}
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    required
                    name="quantity"
                    type="number"
                    label="Antal"
                    inputProps={{ min: '0' }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextArea
                    label="Mottagare"
                    placeholder="Klistra in mottagare"
                    name="customerIds"
                    required
                    rows={12}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextArea
                    label="Meddelande"
                    placeholder="Skriv ditt meddelande här..."
                    name="comment"
                    rows={12}
                  />
                </Grid>
              </Grid>
              <Spacing />
              <InfoMessage>Ange en person/org per rad</InfoMessage>
            </CardContent>
            <Divider />
            <Spacing />
            <CardActions>
              <Button
                color="primary"
                variant="outlined"
                type="submit"
                onClick={handleSubmit}
                disabled={sendBulkCostsMutation.isPending}
              >
                Skapa
              </Button>
              {sendBulkCostsMutation.isPending && <LinearProgress />}
            </CardActions>
          </Card>
        </form>
      )}
    />
  );
};
