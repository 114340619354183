export function formatCurrency(value: number | string, currency?: string) {
  const numberValue = typeof value === 'string' ? parseFloat(value) : value;

  const currencySymbol = new Intl.NumberFormat('sv-Se', {
    style: 'currency',
    currency: currency || 'SEK',
  }).format(numberValue);

  return currencySymbol;
}

export function formatValueTotal(value: number | string) {
  const numberValue = typeof value === 'string' ? parseFloat(value) : value;

  const formattedValue = numberValue.toLocaleString('sv-Se', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formattedValue;
}
