import { Button, FormControl, FormHelperText } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Field } from 'react-final-form';

import { UploadIcon } from '@/icons';

const FileInput = styled('input')({
  display: 'none',
});

type Props = {
  name?: string;
  mimeTypes?: string[];
  multiple?: boolean;
  fullWidth?: boolean;
  buttonText?: string;
  buttonId: string;
};

export const FileUploadInputButton = ({
  name = 'files',
  multiple = true,
  fullWidth = false,
  buttonText = 'Välj filer',
  mimeTypes,
  buttonId,
}: Props) => {
  const allowedExtensions = mimeTypes ?? [
    'application/pdf',
    'audio/m4a',
    'audio/mp3',
    'audio/mpeg',
    'audio/ogg',
    'audio/wav',
    'image/gif',
    'image/jpeg',
    'image/jpg',
    'image/png',
  ];
  const isSupportedFileType = (files: File[]) => {
    if (
      files !== undefined &&
      files.length > 0 &&
      Array.from(files).every((file) => !allowedExtensions.includes(file?.type))
    ) {
      return 'Filformatet stöds inte';
    }

    if (files === undefined) {
      return 'Obligatoriskt fält';
    }

    return undefined;
  };

  return (
    <Field name={name} validate={isSupportedFileType}>
      {({ input, meta }) => (
        <FormControl
          fullWidth={fullWidth}
          margin="normal"
          sx={{ mb: meta.error ? 2 : 0 }}
          data-testid={buttonId}
        >
          <FileInput
            multiple={multiple}
            type="file"
            accept={allowedExtensions.join(',')}
            name={input.name}
            onChange={(e) => {
              input.onChange(Array.from(e.target.files!));
              input.onBlur();
            }}
            id={buttonId}
          />
          <label htmlFor={buttonId}>
            <Button
              size="large"
              sx={(theme) => ({
                color:
                  meta.touched && meta.error
                    ? theme.palette.error.main
                    : 'initial',
                borderColor:
                  meta.touched && meta.error
                    ? theme.palette.error.main
                    : 'initial',
              })}
              startIcon={<UploadIcon />}
              variant="outlined"
              color="primary"
              component="span"
              fullWidth={fullWidth}
            >
              {buttonText}
            </Button>
          </label>
          {meta.touched && meta.error && (
            <FormHelperText error={!!meta.error}>{meta.error}</FormHelperText>
          )}
        </FormControl>
      )}
    </Field>
  );
};
