import { Dialog, DialogContent } from '@mui/material';

import { Customer } from '@/types/customers';

import { EditCustomerForm } from './EditCustomerForm';

type Props = {
  onClose: () => void;
  open: boolean;
  initialValues: Customer;
};

export const EditCustomerModal = ({ onClose, initialValues, open }: Props) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl">
      <DialogContent style={{ padding: 0 }}>
        <EditCustomerForm initialValues={initialValues} onClose={onClose} />
      </DialogContent>
    </Dialog>
  );
};
