import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { format } from 'date-fns';

import { useAuth } from '@/hooks/useAuth';

const Details = styled('div')`
  display: flex;
`;

const StyledAvatar = styled(Avatar)`
  margin-left: auto;
  height: 110px;
  width: 100px;
  flex-shrink: 0;
  flex-grow: 0;
`;

const UploadButton = styled(Button)`
  margin-right: ${({ theme }) => theme.spacing(2)};
`;

export const AccountProfile = () => {
  const { authenticatedUser: me } = useAuth();

  const user = {
    name: me?.name,
    avatar: '',
  };

  return (
    <Card>
      <CardContent>
        <Details>
          <div>
            <Typography gutterBottom variant="h2">
              {user.name}
            </Typography>
            <Typography color="textSecondary" variant="body1" />
            <Typography color="textSecondary" variant="body1">
              {format(new Date(), 'hh:mm aaa')}
            </Typography>
          </div>
          <StyledAvatar src={user.avatar} />
        </Details>
      </CardContent>

      <Divider />

      <CardActions>
        <UploadButton color="primary" variant="text">
          Upload picture
        </UploadButton>
        <Button variant="text">Remove picture</Button>
      </CardActions>
    </Card>
  );
};
