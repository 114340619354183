import { usePrivateSettings } from '@/hooks';

export const useGetAlarmReasonOptions = () => {
  const { servicesStatuses } = usePrivateSettings();

  return Object.keys(servicesStatuses.handleableAlarm.reasons).map((key) => {
    const keyAsNumber = parseInt(key, 10);

    return {
      text: servicesStatuses.handleableAlarm.reasons[keyAsNumber],
      value: keyAsNumber,
    };
  });
};
