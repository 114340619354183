import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
} from '@mui/material';
import { ReactChart } from 'chartjs-react';

import { StatsRawData, TResellerPortalStats } from '@/types/stats';

import { prepareBasicStatsChartData } from '../utils/prepareBasicStatsChartData';

type Props = {
  periodStats: TResellerPortalStats['basic']['period'];
};

export const ChartCard = ({ periodStats }: Props) => {
  const chartData = prepareBasicStatsChartData(
    periodStats as unknown as StatsRawData,
  ); // BdmResellerStats includes more StatsDistributionKeys than the StatsRawData type

  return (
    <Grid item xs={12}>
      <Card>
        <CardHeader title="Summering" />
        <Divider />
        <CardContent>
          <Box height={230} sx={{ mt: 1 }}>
            <ReactChart
              type="line"
              data={chartData}
              options={{
                maintainAspectRatio: false,
              }}
              height={230}
            />
          </Box>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-evenly' }}>
            {chartData.datasets.map((dataset, idx) => (
              <Box key={idx} sx={{ display: 'flex', alignItems: 'center' }}>
                <Box
                  sx={{
                    width: 12,
                    height: 12,
                    borderRadius: 4,
                    marginRight: 1,
                    backgroundColor: dataset.backgroundColor,
                  }}
                />
                <span>{dataset.label}</span>
              </Box>
            ))}
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};
