import React from 'react';

import { Box, LinearProgress } from '@mui/material';

import httpClient from '@/utils/httpClient';

export const BankIdVerification = React.memo(() => {
  async function initializeBankIdCheck() {
    const { data } = await httpClient.get('/grandid/initiate');

    window.location.href = data.redirectUrl;
  }

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      initializeBankIdCheck();
    }, 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <Box>
      <LinearProgress />
    </Box>
  );
});

export default BankIdVerification;
