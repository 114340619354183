export const churnFiretextActiveOptions = [
  { value: 0, text: 'Ej Churn' },
  { value: 1, text: 'Ja' },
  { value: 2, text: 'Nej' },
];

export const regretSourceOptions = [
  {
    value: 0,
    text: 'Ej ånger',
  },
  {
    value: 1,
    text: 'Samtal',
  },
  {
    value: 2,
    text: 'Brev',
  },
  {
    value: 3,
    text: 'E-post',
  },
  {
    value: 4,
    text: 'Återförsäljare',
  },
];

export const regretReasonOptions = [
  {
    value: 0,
    text: 'Ej ånger',
  },
  {
    value: 1,
    text: 'Svarsvägrare',
  },
  {
    value: 2,
    text: 'Sambo',
  },
  {
    value: 3,
    text: 'Ekonomiskt',
  },
  {
    value: 4,
    text: 'Förstår inte tjänsten',
  },
  {
    value: 5,
    text: 'Innan välkomstbrev',
  },
  {
    value: 6,
    text: 'Har larm',
  },
  {
    value: 7,
    text: 'Info. Sälj',
  },
  {
    value: 8,
    text: 'Planerar annat larm',
  },
  {
    value: 9,
    text: 'Okänt',
  },
  {
    value: 10,
    text: 'Ångerblankett',
  },
  {
    value: 11,
    text: 'Övrigt',
  },
  {
    value: 12,
    text: 'Ej avtalsmedveten',
  },
];
