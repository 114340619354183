import { FeatureFlag, featureFlagsLocalStorageKey } from '@/types/featureFlags';

export const getFeatureFlagsFromLocalStorage = (): FeatureFlag[] => {
  const featureFlags = localStorage.getItem(featureFlagsLocalStorageKey);

  if (!featureFlags) {
    return [];
  }

  return JSON.parse(featureFlags) as FeatureFlag[];
};
