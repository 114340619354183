import { useFeatureFlags } from '@/hooks/useFeatureFlags';
import { FEATURE_FLAGS } from '@/utils/featureFlags';

type Args = {
  name: (typeof FEATURE_FLAGS)[keyof typeof FEATURE_FLAGS]['name'];
};
export const useFeatureFlag = ({ name }: Args) => {
  const { data: flags } = useFeatureFlags();

  if (!flags || !Array.isArray(flags)) return false;

  const flag = flags.find((flag) => flag.name === name);

  if (!flag) return false;

  return flag.enabled;
};
