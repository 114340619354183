import {
  getCoProtections,
  getFiretexts,
  getHomegates,
  getKeyTags,
  getProtections,
  getSinnes,
  getStickers,
} from '@/api';
import { CamelCaseServiceName } from '@/types/services';

type ApiCallParams = {
  page: number;
  perPage: number;
  customerPin: string;
  sortOrder: 'asc' | 'desc';
  churns: boolean;
  status?: number[];
  search?: string;
};

export const getServiceApiFunction = (
  service: CamelCaseServiceName,
  props: ApiCallParams,
) => {
  const services = {
    coProtection: getCoProtections,
    firetext: getFiretexts,
    homegate: getHomegates,
    keyTag: getKeyTags,
    protection: getProtections,
    sinne: (params: ApiCallParams) => {
      params.status = [1];
      return getSinnes(params);
    },
    sticker: getStickers,
  };

  return services[service](props) ?? new Error('Tjänsten finns inte.');
};
