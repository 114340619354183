import React from 'react';

import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';

import { getCoProtectionMonitorCount } from '@/api/coProtection';
import { Alert } from '@/components/Alert';
import { getObjectKeys } from '@/helpers/tsHelpers';

export const WatchListInfo = () => {
  const { serviceId } = useParams();
  invariant(serviceId, 'serviceId is required in the url');

  const queryClient = useQueryClient();
  const [fetchStats, setFetchStats] = React.useState(false);

  const {
    data,
    isInitialLoading: isLoading,
    isError,
    isSuccess,
  } = useQuery({
    queryKey: ['watchList', serviceId],

    queryFn: async () => {
      const data = await getCoProtectionMonitorCount(serviceId);
      setFetchStats(false);

      if (data?.message === 'CoProtection has no monitored companies') {
        return false;
      } else {
        return {
          'Manuellt tillagda': data.manuallyAdded,
          'Automatiskt tillagda': data.automaticallyAdded,
          'Ackumulerat antal manuellt tillagda bolag': data.addedCompanyWatch,
        };
      }
    },

    enabled: fetchStats,
  });

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            variant="outlined"
            disabled={isLoading}
            onClick={() => {
              if (isSuccess || isError) {
                queryClient.refetchQueries({
                  queryKey: ['watchList', serviceId],
                });
              } else {
                setFetchStats(true);
              }
            }}
          >
            Hämta information
          </Button>
        </Grid>
        <Grid item xs={12}>
          {isLoading ? (
            <LinearProgress />
          ) : isError ? (
            <Alert severity="error">Något gick fel</Alert>
          ) : (
            isSuccess && (
              <Card>
                <CardHeader title="Företag under bevakning just nu" />
                <Divider />
                {data ? (
                  <List style={{ paddingBottom: 0 }}>
                    {getObjectKeys(data).map((key) => (
                      <ListItem key={key} divider>
                        <ListItemText
                          primary={key}
                          secondary={`${data[key]} st`}
                        />
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <Alert severity="info">Inga bolag under bevakning</Alert>
                )}
              </Card>
            )
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
