import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from '@mui/material';

type Props = {
  actionButtons?: React.ReactNode;
  closeButtonText?: string;
  content: React.ReactNode;
  onClose: () => void;
  open: boolean;
  title: string;
};

export function SimpleModal({
  actionButtons,
  closeButtonText = 'Stäng',
  content,
  onClose,
  open,
  title,
}: Props) {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
      <Divider />
      <DialogContent>{content}</DialogContent>
      <Divider />
      <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button onClick={handleClose} color="primary" variant="outlined">
          {closeButtonText}
        </Button>
        {actionButtons}
      </DialogActions>
    </Dialog>
  );
}
