import React from 'react';

import { useMutation } from '@tanstack/react-query';

import { deleteService } from '@/api';

export const useDeleteService = () => {
  return useMutation({
    mutationFn: deleteService,
  });
};
