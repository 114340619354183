import { useEffect } from 'react';

import { config } from '@/helpers/config';
import { getElementFromArray } from '@/helpers/getElementFromArray';

export const useWhiteLabelFavicon = () => {
  useEffect(() => {
    const link: HTMLLinkElement =
      document.querySelector("link[rel*='icon']") ||
      document.createElement('link');

    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = `/images/${config.whitelabelId}/favicon.png`;

    getElementFromArray(
      document.getElementsByTagName?.('head')[0]?.appendChild(link),
    );
  }, []);
};
