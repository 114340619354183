import { Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router';

import { SimpleModal } from '@/components/SimpleModal';
import { getKebabCaseServiceName } from '@/helpers/tsHelpers';
import { useSnackbar } from '@/hooks/useSnackbar';
import { useDeleteService } from '@/modules/services/hooks/useDeleteService';
import { GenericHttpError } from '@/types/errors';
import { CamelCaseServiceName, ServiceName } from '@/types/services';
import { KebabCaseServiceNames } from '@/types/settings';

type Props = {
  open: boolean;
  onClose: () => void;
  serviceId: number | string;
  serviceName: CamelCaseServiceName | KebabCaseServiceNames | ServiceName;
};
export const DeleteServiceModal = ({
  open,
  onClose,
  serviceId,
  serviceName,
}: Props) => {
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const deleteServiceMutation = useDeleteService();

  const serviceIdAsNumber = Number(serviceId);

  if (!serviceIdAsNumber) {
    throw new Error('Service ID must be present in url params');
  }

  return (
    <SimpleModal
      actionButtons={
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            deleteServiceMutation.mutate(
              { serviceId: serviceIdAsNumber, serviceName },
              {
                onSuccess: () => {
                  snackbar({
                    message: 'Tjänsten har tagits bort',
                    type: 'success',
                  });
                  navigate(`/products/${getKebabCaseServiceName(serviceName)}`);
                },
                onError: (error: unknown) => {
                  console.error(error);
                  const typedError = error as GenericHttpError<{
                    message: string;
                  }>;

                  let errorMessage = typedError.data.message.toLowerCase();
                  const activeServiceErrorMessage =
                    'Service is active and cannot be deleted'.toLowerCase();

                  if (errorMessage.includes(activeServiceErrorMessage)) {
                    errorMessage =
                      'Tjänsten är aktiv och kan inte tas bort. Avaktivera tjänsten innan du försöker ta bort den.';
                  }

                  snackbar({
                    type: 'error',
                    message: `Kunde inte ta bort tjänst: ${errorMessage}`,
                  });
                },
              },
            );
          }}
        >
          Ta bort
        </Button>
      }
      content={
        <Typography variant="h5" color="error">
          Är du säker på att du vill ta bort tjänsten? Detta kan inte ångras!
        </Typography>
      }
      closeButtonText="Avbryt"
      onClose={onClose}
      open={open}
      title="Bekräfta att du vill ta bort tjänsten"
    />
  );
};
