import { useGetBDMs } from './useGetBDMs';

export const useGetBdmOptions = () => {
  const { data: bdms, status } = useGetBDMs();

  const options = bdms?.map((bdm) => ({
    label: bdm?.name,
    value: bdm?.['@id'],
  }));

  options?.unshift({ label: 'Ingen', value: '' });

  return { options, status };
};
