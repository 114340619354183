import React from 'react';

export function getValidChildren(children: React.ReactNode) {
  return React.Children.toArray(children).filter((child) =>
    React.isValidElement(child),
  ) as React.ReactElement[];
}

export function getNumberOfChildren(
  children: React.ReactNode | [] | undefined,
) {
  if (Array.isArray(children)) {
    return getValidChildren(children).length;
  } else if (React.isValidElement(children)) {
    return getValidChildren(children?.props?.children).length;
  }
}
